import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './ThresholdInfoTooltip.module.css';
import { classNames, isDefined } from '@energybox/react-ui-library/dist/utils';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { LightReadingsHistoricData } from '@energybox/react-ui-library/dist/types';

type Props = {
  astroEventLuxAverages: LightReadingsHistoricData | undefined;
  isOrgLevel?: boolean;
};

const ThresholdInfoTooltip: React.FC<Props> = ({
  astroEventLuxAverages,
  isOrgLevel,
}) => {
  return (
    <Tooltip
      underline={isOrgLevel ? true : false}
      arrowDirection={isOrgLevel ? 'topLeft' : 'right'}
      tooltipTextClassName={isOrgLevel ? styles.text : ''}
      extraClassNames={[styles.tooltip]}
      disableAutoAdjust
      content={
        <div
          className={classNames(
            styles.tooltipRoot,
            isOrgLevel ? styles.title : ''
          )}
        >
          <div className={styles.tooltipTitle}>Threshold</div>
          {isOrgLevel ? (
            <div
              className={classNames(
                styles.tooltipTitle,
                isOrgLevel ? styles.title : ''
              )}
            >
              Lux Level below which the equipment circuit(s) will be switched on
            </div>
          ) : (
            <div
              className={classNames(
                styles.tooltipTitle,
                isOrgLevel ? styles.title : ''
              )}
            >
              Site Values: 7 Day Average
            </div>
          )}
          {!isDefined(astroEventLuxAverages) && !isOrgLevel && (
            <div>Historical light sensor data is needed</div>
          )}
          {isDefined(astroEventLuxAverages) && (
            <table className={styles.tableText}>
              <thead>
                <tr>
                  <th className={styles.tooltipTableWidth}></th>
                  <th className={styles.tooltipTableWidth}>Time</th>
                  <th className={styles.tooltipTableWidth}>Brightness</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dawn</td>
                  <td>{astroEventLuxAverages.dawn.time}</td>
                  <td>{astroEventLuxAverages.dawn.lux} lux</td>
                </tr>
                <tr>
                  <td>Sunrise</td>
                  <td>{astroEventLuxAverages.sunrise.time}</td>
                  <td>{astroEventLuxAverages.sunrise.lux} lux</td>
                </tr>
                <tr>
                  <td>Sunset</td>
                  <td>{astroEventLuxAverages.sunset.time}</td>
                  <td>{astroEventLuxAverages.sunset.lux} lux</td>
                </tr>
                <tr>
                  <td>Dusk</td>
                  <td>{astroEventLuxAverages.dusk.time}</td>
                  <td>{astroEventLuxAverages.dusk.lux} lux</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      }
    >
      {isOrgLevel ? (
        <div className={styles.title}> Threshold </div>
      ) : (
        <IoIosInformationCircleOutline
          size={20}
          className={styles.marginLeft}
        />
      )}
    </Tooltip>
  );
};

export default ThresholdInfoTooltip;
