import { DateTime } from 'luxon';
import styles from './HvacSopTimetableTooltipContent.module.css';

type props = {
  timetableTitle: string;
  timeRange: string;
  overflowTimestamp: DateTime | null;
};

const SchedulerTimetableTooltipContent: React.FC<props> = ({
  timetableTitle,
  timeRange,
  overflowTimestamp,
}) => {
  return (
    <div>
      <div className={styles.tooltipTitle}>{timetableTitle}</div>
      <div className={`${styles.tooltipTime} ${styles.noBorder}`}>
        {timeRange}
        <span className={styles.overflowTag}>{overflowTimestamp && ' +1'}</span>
      </div>
    </div>
  );
};

export default SchedulerTimetableTooltipContent;
