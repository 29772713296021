import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import {
  AllActions,
  ApiError,
  renderAPIerror,
} from '../../../utils/apiErrorFeedback';
import styles from './UpdateModal.module.css';

const UpdateModal = ({
  onCancelModal,
  onConfirmUpdate,
  modalText,
  apiError,
  apiErrorAction,
}: {
  onCancelModal: () => void;
  onConfirmUpdate: () => void;
  modalText: React.ReactNode;
  apiError?: ApiError;
  apiErrorAction: AllActions;
}) => {
  const actions = (
    <span>
      <Button variant="text" onClick={onCancelModal}>
        Cancel
      </Button>
      <Button onClick={onConfirmUpdate}>Confirm</Button>
    </span>
  );

  return (
    <Modal actions={actions}>
      <div className={styles.updateBreakerModalContent}>{modalText}</div>
      {isDefined(apiError) && renderAPIerror(apiError, apiErrorAction)}
    </Modal>
  );
};

export default UpdateModal;
