import { IconSensorTypeFactory } from '@energybox/react-ui-library/dist/components';
import {
  defaultDoorParameters,
  IconSensorTypeId,
  ParametersSwitch,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import Switch from '../../../ui/Switch';
import styles from './ConditionDoor.module.css';
import StepConditionItem from './StepConditionItem';

type Props = {
  onChange: (arg0: string, arg1: string) => void;
  parameters?: ParametersSwitch;
};

const ConditionDoor = ({
  onChange,
  parameters = defaultDoorParameters,
}: Props) => (
  <StepConditionItem
    label={
      <span
        className={classNames(
          styles.icon,
          parameters && parameters.openClosed === 'CLOSED'
            ? styles.doorIconStateClosed
            : null
        )}
      >
        <IconSensorTypeFactory
          size={80}
          id={(SentinelType.BINARY as unknown) as IconSensorTypeId}
        />
      </span>
    }
    condition={
      <div className={styles.switch}>
        <Switch
          id="doorAccess"
          onChange={() =>
            onChange(
              'openClosed',
              parameters && parameters.openClosed === 'OPEN' ? 'CLOSED' : 'OPEN'
            )
          }
          checked={parameters && parameters.openClosed === 'OPEN'}
        />
        <span className={styles.switchLabel}>
          {`Door ${parameters.openClosed === 'OPEN' ? 'opened' : 'closed'}`}
        </span>
      </div>
    }
    description="The notifications will be triggered when the door is opened"
  />
);

export default ConditionDoor;
