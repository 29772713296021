import { MainBreaker } from '@energybox/react-ui-library/dist/types';
import { ApplicationState } from '../reducers';
import { getDistributionPanel } from './distribution_panel';

export enum Actions {
  SET_EDIT_MAIN_BREAKER = '@mainBreaker/SET_EDIT_MAIN_BREAKER',
  UPDATE_FIELD = '@mainBreaker/UPDATE_FIELD',

  PATCH_MAIN_BREAKER_LOADING = '@mainBreaker/PATCH_MAIN_BREAKER_LOADING',
  PATCH_MAIN_BREAKER_ERROR = '@mainBreaker/PATCH_MAIN_BREAKER_ERROR',
  PATCH_MAIN_BREAKER_SUCCESS = '@mainBreaker/PATCH_MAIN_BREAKER_SUCCESS',

  DISPLAY_FORM_ERRORS = '@mainBreaker/DISPLAY_FORM_ERRORS',
}

export const setEditMainBreaker = (mainBreaker: MainBreaker) => ({
  type: Actions.SET_EDIT_MAIN_BREAKER,
  mainBreaker,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const patch = (
  mainBreakerId: string | number,
  panelId: string | number,
  payload?
) => (dispatch, getState) => {
  dispatch({
    type: 'API_PATCH',
    path: `/api/v1/distribution-panels/${panelId}/main-breaker`,
    payload: payload
      ? payload
      : (getState() as ApplicationState).mainBreakers.editById[mainBreakerId]
          .fields,
    loading: { type: Actions.PATCH_MAIN_BREAKER_LOADING, mainBreakerId },
    success: [
      { type: Actions.PATCH_MAIN_BREAKER_SUCCESS, mainBreakerId },
      getDistributionPanel(panelId),
    ],
    error: { type: Actions.PATCH_MAIN_BREAKER_ERROR, mainBreakerId },
  });
};
