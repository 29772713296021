import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions,
  clearAuthenErrorDuringUpdatePassword,
  updateUserPassword,
} from '../../actions/users';
import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';
import { ApplicationState } from '../../reducers';
import { NewPasswordPage } from '@energybox/react-ui-library/dist/components/NewPassword';
import { CurrentUserPlus } from '../../reducers/app';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import { getCurrentUser } from '../../actions/app';
import { PasswordChangeError } from '@energybox/react-ui-library/dist/components/NewPassword/NewPasswordPage/NewPasswordPage';

type Props = {
  enforced?: boolean;
};

const ChangePasswordPage: React.FunctionComponent<Props> = ({ enforced }) => {
  const { user, didPasswordChangeFail } = useSelector<
    ApplicationState,
    {
      user: CurrentUserPlus | undefined;
      didPasswordChangeFail: PasswordChangeError;
    }
  >(({ app, users }) => ({
    user: app.currentUser,
    didPasswordChangeFail: users.didPasswordChangeFail,
  }));

  const [state, setState] = React.useState({
    currentPassword: '',
    password: '',
    passwordVerify: '',
  });

  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  const onFormSubmit = ev => {
    ev.preventDefault();
    dispatch(
      updateUserPassword(
        user.id.toString(),
        state.currentPassword,
        state.password,
        state.passwordVerify,
        () => {
          dispatch(getCurrentUser());
        }
      )
    );
  };

  const onChange = (...args: string[]) => {
    let newState = { ...state };
    for (let i = 1; i < args.length; i += 2) {
      const field = args[i - 1];
      newState[field] = args[i];
      if (field === 'currentPassword') {
        dispatch(clearAuthenErrorDuringUpdatePassword);
      }
    }
    setState(newState);
  };

  return (
    <IndexManagementPageLayout
      tabs={[]}
      content={
        <NewPasswordPage
          email={user.email || ''}
          currentPassword={state.currentPassword}
          password={state.password}
          passwordVerify={state.passwordVerify}
          currentPasswordIncorrect={didPasswordChangeFail === 'authError'}
          apiError={renderAPIerror(
            didPasswordChangeFail === 'error'
              ? {
                  type: Actions.USER_CHANGE_PASSWORD_ERROR,
                  status: 0,
                  message: 'Password change failed. Try again.',
                }
              : {},
            Actions.USER_CHANGE_PASSWORD_ERROR
          )}
          handleSubmit={onFormSubmit}
          handleChange={onChange}
          changeOldPassword
          enforced={enforced}
        />
      }
    />
  );
};

export default ChangePasswordPage;
