import { UtilityBill } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToUploadProgress,
  unsubscribeFromUploadProgress,
} from '../actions/streamApi';
import {
  editFile as editFileAction,
  cancelEdit as cancelEditAction,
  sendEdit as sendEditAction,
  getUtilityBillsBySiteId,
  deleteFileBySiteIdAndFileId,
} from '../actions/utilityBills';
import { ApplicationState } from '../reducers';
import { BillEdit, EditByFileId } from '../reducers/utilityBills';
import { StreamData } from '../types/upload';
import { ApiError } from '../utils/apiErrorFeedback';

export const useUtilityBills = (siteId: number | string | undefined) => {
  const dispatch = useDispatch();

  const getUtilityBills = () => dispatch(getUtilityBillsBySiteId(siteId || ''));

  const deleteFileById = (fileId: string) =>
    dispatch(deleteFileBySiteIdAndFileId('' + siteId, fileId));

  const editFile = (edit: BillEdit) => dispatch(editFileAction(edit));

  const cancelEdit = (edit: BillEdit) => dispatch(cancelEditAction(edit));

  const sendEdit = (edit: BillEdit) => dispatch(sendEditAction(edit));

  const selected =
    useSelector<
      ApplicationState,
      {
        utilityBills: UtilityBill[];
        editByFileId: EditByFileId;
        isLoading: boolean;
        isDeleting: boolean;
        apiError?: ApiError;
      }
    >(({ utilityBills }) => ({
      utilityBills: utilityBills.utilityBillsBySiteId['' + siteId] || [],
      editByFileId: utilityBills.editByFileId,
      isLoading: utilityBills.utilityBillIsLoading,
      isDeleting: utilityBills.utilityBillIsDeleting,
      apiError: utilityBills.apiError,
    })) || {};

  useEffect(() => {
    getUtilityBills();
  }, [siteId]);

  return {
    ...selected,
    getUtilityBills,
    deleteFileById,
    editFile,
    cancelEdit,
    sendEdit,
  };
};

export const useUploadProgress = (
  uuid: string | undefined
): {
  streamData?: StreamData;
  subscribe: () => any;
  unsubscribe: () => any;
} => {
  const dispatch = useDispatch();

  const streamData = useSelector<ApplicationState, StreamData>(
    ({ utilityBills }) => utilityBills.progressByFileId[uuid || '']
  );

  return {
    streamData,

    subscribe: () => (uuid ? dispatch(subscribeToUploadProgress(uuid)) : {}),
    unsubscribe: () =>
      uuid ? dispatch(unsubscribeFromUploadProgress(uuid)) : {},
  };
};
