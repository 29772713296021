import { ApplicationState } from '../../reducers';
import { Actions } from '../../actions/spaces';

export const spacesByIdSelector = ({ spaces }: ApplicationState) =>
  spaces.spacesById;

export const spaceIdsBySiteIdSelector = ({ spaces }: ApplicationState) =>
  spaces.spaceIdsBySiteId;

export const spacesLoadingBySiteIdSelector = ({ spaces }: ApplicationState) =>
  spaces.loadingStatusByAction[Actions.GET_SPACES_BY_SITE_ID_LOADING];
