import { Button } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { showNewGatewayModal } from '../actions/gateways';
import { showNewSensorModal } from '../actions/sensors';
import IndexManagementPageLayout from '../components/IndexManagementPageLayout';
import TabLink from '../components/TabLink';
import { Routes } from '../routes';
import { CreateNewText } from '../types/global';
import NewDeviceModal from './Devices/NewDeviceModal';
import GatewaysTable from './Gateways/GatewaysTable';
import NewSensorModal from './Sensors/NewSensorModal';
import SensorsTable from './Sensors/SensorsTable';
import OrgNetworkGroupPage from '../components/OrgNetworkGroupPage/OrgNewtworkGroupPage';
import NewNetworkGroupModal from './NetworkGroups/NewNetworkGroupModal';
import { showNewNetworkGroupModal } from '../actions/network_groups';

interface Props extends RouteComponentProps {
  showNewGatewayModal: typeof showNewGatewayModal;
  showNewSensorModal: typeof showNewSensorModal;
  showNewNetworkGroupModal: typeof showNewNetworkGroupModal;
}

class DevicesContainer extends React.Component<Props> {
  renderAddButton = () => {
    const {
      location,
      showNewSensorModal,
      showNewGatewayModal,
      showNewNetworkGroupModal,
    } = this.props;

    let clickFn;
    let text;

    if (location.pathname) {
      if (location.pathname.includes(Routes.SENSORS)) {
        clickFn = showNewSensorModal;
        text = CreateNewText.SENSOR;
      } else if (location.pathname.includes(Routes.GATEWAYS)) {
        clickFn = showNewGatewayModal;
        text = CreateNewText.GATEWAY;
      } else if (location.pathname.includes(Routes.NETWORK_GROUPS)) {
        clickFn = showNewNetworkGroupModal;
        text = CreateNewText.NETWORK_GROUP;
      }
    }

    return <Button onClick={clickFn}> {text} </Button>;
  };

  render() {
    const searchQuery = this.props?.location?.search;

    return (
      <>
        <IndexManagementPageLayout
          actions={this.renderAddButton()}
          tabs={
            <>
              <TabLink
                search={searchQuery}
                to={`${Routes.DEVICES}${Routes.GATEWAYS}`}
              >
                Devices
              </TabLink>
              <TabLink
                search={searchQuery}
                to={`${Routes.DEVICES}${Routes.SENSORS}`}
              >
                Sensors
              </TabLink>
              <TabLink
                search={searchQuery}
                to={`${Routes.DEVICES}${Routes.NETWORK_GROUPS}`}
              >
                Network Groups
              </TabLink>
            </>
          }
          content={
            <Switch>
              <Route
                path={`${Routes.DEVICES}${Routes.SENSORS}`}
                render={({ match }) =>
                  match && <SensorsTable hasSearch {...match.params} />
                }
              />
              <Route
                path={`${Routes.DEVICES}${Routes.GATEWAYS}`}
                render={({ match }) =>
                  match && <GatewaysTable {...match.params} />
                }
              />
              <Route
                path={`${Routes.DEVICES}${Routes.NETWORK_GROUPS}`}
                render={({ match }) => match && <OrgNetworkGroupPage />}
              />
            </Switch>
          }
        />

        <NewDeviceModal />
        <NewSensorModal />
        <NewNetworkGroupModal />
      </>
    );
  }
}

const mapDispatchToProps = {
  showNewGatewayModal,
  showNewSensorModal,
  showNewNetworkGroupModal,
};

export default withRouter(connect(null, mapDispatchToProps)(DevicesContainer));
