import {
  IconEquipmentFactory,
  IconSensorTypeFactory,
} from '@energybox/react-ui-library/dist/components';
import { Sites as SitesIcon } from '@energybox/react-ui-library/dist/icons';
import { IconSensorTypeId } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import styles from './StepRollout.module.css';

type Props = {
  id?: string;
  size?: number;
  title: string;
};

export function EquipmentChip(props: Props) {
  const { id = '', size = 16, title } = props;
  return (
    <div className={styles.iconContainer}>
      <IconEquipmentFactory
        id={id}
        size={size}
        classes={{ icon: styles.icon }}
      />
      {title}
    </div>
  );
}

export function SensorChip(props: Props) {
  const { id, size = 16, title } = props;

  return (
    <div className={styles.iconContainer}>
      <IconSensorTypeFactory
        id={id as IconSensorTypeId}
        size={size}
        classes={{ icon: styles.icon }}
      />
      {title}
    </div>
  );
}

export function SpaceChip(props: Props) {
  const { id, size = 16, title } = props;

  return (
    <div className={styles.iconContainer}>
      <SitesIcon id={id} size={size} />
      {title}
    </div>
  );
}
