import {
  DeviceBatteryState,
  DeviceSignalState,
  IconSensorTypeFactory as SensorIcon,
} from '@energybox/react-ui-library/dist/components';
import {
  SensorStatus,
  SensorType,
  SensorTypesText,
  IconSensorTypeId,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import { Plug } from '@energybox/react-ui-library/dist/icons';

import React from 'react';
import { Routes } from '../routes';
import SignalStrength from '../components/SignalStrength/SignalStrength';

type SensorStatusSubset = {
  signalStrengthLevel?: number;
  signalStrength?: number;
  batteryVoltage?: number;
  batteryLevel?: any;
};

export const BatteryStatus: React.FunctionComponent<{
  sensorStatus?: SensorStatus | SensorStatusSubset;
  vendor?: string;
  isSensorDetailsPage?: boolean;
  showValue?: boolean;
}> = ({ sensorStatus, vendor, isSensorDetailsPage, showValue }) => {
  if (!sensorStatus || !isDefined(sensorStatus?.batteryLevel)) {
    return <>{global.NOT_AVAILABLE}</>;
  }
  return (
    <>
      <span>
        {showValue && sensorStatus.batteryVoltage
          ? `${sensorStatus.batteryVoltage}mV`
          : ''}
      </span>
      <span
        title={
          sensorStatus.batteryVoltage ? `${sensorStatus.batteryVoltage}mV` : ''
        }
      >
        {vendor === 'energybox' && sensorStatus.batteryVoltage === 5000 ? (
          <Plug size={20} variant="small" />
        ) : (
          <div>
            <DeviceBatteryState
              level={sensorStatus.batteryLevel * 100}
              size={16}
            />
            {isSensorDetailsPage && (
              <span
                style={{
                  paddingLeft: '0.3rem',
                  verticalAlign: 'super',
                }}
              >{`${sensorStatus.batteryVoltage}mV`}</span>
            )}
          </div>
        )}
      </span>
    </>
  );
};

export const SignalStatus: React.FunctionComponent<{
  sensorStatus?: SensorStatus | SensorStatusSubset;
  vendor?: string;
  showIcon?: boolean;
  showLabel?: boolean;
  showSignalStrength?: boolean;
}> = ({
  vendor,
  sensorStatus,
  showIcon = false,
  showLabel = true,
  showSignalStrength = false,
}) => {
  if (!sensorStatus) {
    return <>{global.NOT_AVAILABLE}</>;
  }
  const normaliseSignal = (value: number) =>
    ((-value - -99) * 100) / (-1 - -99);

  // TODO For monnit sensors we need to be able to plug the level directly.
  const signalStrength =
    vendor === 'monnit'
      ? sensorStatus.signalStrengthLevel
      : sensorStatus.signalStrength;

  if (!isDefined(signalStrength)) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  const label = vendor === 'monnit' ? '' : `${signalStrength}dBm`;
  if (!showIcon && !showSignalStrength) {
    return <span>{label}</span>;
  }

  if (showSignalStrength) {
    return (
      <>
        <span>{showLabel && label}</span>
        <SignalStrength value={-signalStrength} showValue={false} />
      </>
    );
  }

  return (
    <span title={label}>
      <DeviceSignalState
        vendor={vendor}
        signalQuality={signalStrength}
        size={16}
      />
    </span>
  );
};

export const SensorTypeIconWithHoverText: React.FunctionComponent<{
  sensorType: SensorType;
}> = ({ sensorType }) => {
  return (
    <span title={SensorTypesText[sensorType]}>
      <SensorIcon size={16} id={(sensorType as unknown) as IconSensorTypeId} />
    </span>
  );
};

export const getSensorRoute = (sensorId: number) => {
  return `${Routes.DEVICES}${Routes.SENSORS}/${sensorId}`;
};

export const convertEnergyProSensorIndex = (index: number) => {
  let text = '';

  if (index >= 0 && index <= 9) {
    text = `EnergyPro Port ${index + 1}`;
  } else if (index >= 10 && index <= 99) {
    text = `Sensorbus 1 Port ${index - 10 + 1}`;
  } else if (index >= 100 && index <= 199) {
    text = `Sensorbus 2 Port ${index - 100 + 1}`;
  }

  return text;
};
