import Tooltip from '@energybox/react-ui-library/dist/components/Tooltip';
import { HvacControlLabel } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import styles from './EditControlsHvacForm.module.css';

type Props = {
  hasError?: boolean;
  arrowDirection?:
    | 'right'
    | 'top'
    | 'topRight'
    | 'left'
    | 'bottom'
    | 'bottomRight'
    | 'closeBottom';
};

const EditControlsHvacFormAdvancedSettingsTooltip: React.FC<Props> = ({
  hasError,
  arrowDirection = 'right',
}) => {
  return (
    <Tooltip
      underline={false}
      arrowDirection={arrowDirection}
      extraClassNames={[styles.tooltip]}
      simpleContent={{
        title: `What is ${HvacControlLabel.REVERT_ADJUSTMENT}?`,
        description: `Any change on the physical thermostat beyond the configured Base
          Temperature Setpoint(s) will be reverted after the specified
          time period.`,
      }}
    >
      <IoIosInformationCircleOutline
        color={hasError ? 'var(--pink-base)' : 'black'}
        size={20}
      />
    </Tooltip>
  );
};

export default EditControlsHvacFormAdvancedSettingsTooltip;
