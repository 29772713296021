import { Gateway } from '@energybox/react-ui-library/dist/types';

export function compareVersion(v1: string, v2: string) {
  const v1Parts = v1.split('.').map(Number);
  const v2Parts = v2.split('.').map(Number);

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const part1 = v1Parts[i] || 0;
    const part2 = v2Parts[i] || 0;
    if (part1 > part2) return 1;
    if (part1 < part2) return -1;
  }
  return 0;
}

export function filterControlBoardsByHardwareVersion(
  filterByDeviceTypes: string[],
  gateways: Gateway[]
) {
  let filteredGateways = gateways;
  if (filterByDeviceTypes.length > 0) {
    filteredGateways = filteredGateways.filter(gateway => {
      const hwVersion = gateway.gatewayInfo?.hardwareVersion;

      // If filtering by SITE_CONTROLLER_ILO
      const isSCILO =
        filterByDeviceTypes.includes(
          SiteControllerDeviceType.SITE_CONTROLLER_ILO
        ) &&
        (!hwVersion || compareVersion(hwVersion, '2.0.0') >= 0);

      // If filtering by SITE_CONTROLLER
      const isSCLegacy =
        filterByDeviceTypes.includes(
          SiteControllerDeviceType.SITE_CONTROLLER
        ) &&
        hwVersion &&
        compareVersion(hwVersion, '2.0.0') < 0;

      // Return true if either condition is satisfied
      return isSCILO || isSCLegacy;
    });
  }

  return filteredGateways;
}

//SiteController (legacy) / SiteController-ILO
export enum SiteControllerDeviceType {
  SITE_CONTROLLER = 'SITE_CONTROLLER',
  SITE_CONTROLLER_ILO = 'SITE_CONTROLLER_ILO',
}

export interface FilteredDeviceType {
  id: SiteControllerDeviceType;
  name: string;
}

export default compareVersion;
