import { Button } from '@energybox/react-ui-library/dist/components';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useForceRerender from '../../../hooks/useForceRerender';
import { useGetEdgeConfigUpdateByEdgeId } from '../../../hooks/useGetEdgeConfigUpdate';
import { ApplicationState } from '../../../reducers';
import { IsConfigAcceptedByNetworkGroupId } from '../../../reducers/edge_devices';
import styles from './NetworkGroupTable.module.css';
import { hasDaResults } from '../../../util';

type Props = {
  networkGroup: NetworkGroup;
  openConfirmationModal: (networkGroupId: string | number) => void;
};

/* Logic flow: When the user clicks on "Update config" button, we send an IAM endpoint call 
  IAM produces a yaml config, and then it updates the edgeApp remotely.

  Upon successful IAM endpoint response, we store the edgeApp updating status in local storage (see localStorageMiddleware.ts)
  When we get a new streamAPI message from subscribedNetworkGroup, 
  we check for hashCodes-- if they are equal (isHashEqual), then edgeApp has successfully updated
  if they are not equal, then it failed
  then we remove the relevant edgeApp uuid in local storage

  StreamAPI sends new message when the edgeApp has updated (via hash codes)
  if hashCodes are equal (isHashEqual), then 
  */
const UpdateEdgeConfigButton: React.FC<Props> = ({
  networkGroup,
  openConfirmationModal,
}) => {
  const networkGroupId = networkGroup.id;
  const edgeId = networkGroup.edge?.id!;
  const forceRerender = useForceRerender();

  //every minute, force rerender so that we can update config button message depending on local storage
  useEffect(() => {
    setInterval(() => {
      forceRerender();
    }, 60000);
  }, [forceRerender]);

  const {
    isEdgeConfigUpdating,
    shouldEdgeConfigStillBeUpdating,
  } = useGetEdgeConfigUpdateByEdgeId(edgeId);

  const isConfigAcceptedByNetworkGroupId = useSelector<
    ApplicationState,
    IsConfigAcceptedByNetworkGroupId
  >(({ edgeDevices }) => {
    return edgeDevices.isConfigAcceptedByNetworkGroupId;
  });

  const site = useSelector(
    ({ sites }: ApplicationState) => sites.sitesById[networkGroup.siteId]
  );

  //To display the test-inprogress-warning
  const daResultsExist: boolean = hasDaResults(site?.installerTestResults);
  const renderButtonText = (networkGroup: NetworkGroup) => {
    if (isEdgeConfigUpdating && shouldEdgeConfigStillBeUpdating) {
      return 'Update sent';
    } else {
      switch (isConfigAcceptedByNetworkGroupId[networkGroup.id]) {
        case false:
          return 'Error';
        case undefined:
        default:
          return 'Update config';
      }
    }
  };

  return (
    <div className={styles.updateConfigButtonContainer}>
      <Button
        size="small"
        onClick={() => openConfirmationModal(networkGroupId)}
        disabled={
          shouldEdgeConfigStillBeUpdating ||
          isConfigAcceptedByNetworkGroupId[networkGroupId] === false ||
          daResultsExist
        }
      >
        {renderButtonText(networkGroup)}
      </Button>
    </div>
  );
};

export default UpdateEdgeConfigButton;
