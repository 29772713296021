import {
  EnergyApiFilter,
  ZippedTSEnergySeriesData,
} from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import pathOr from 'ramda/src/pathOr';
import { getTSEnergyBySensorId } from '../../actions/energyTS';

export const useGetEnergyBySensorId = (
  sensorId: number | string | undefined,
  queryParams: EnergyApiFilter
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (sensorId) {
      dispatch(getTSEnergyBySensorId(sensorId, queryParams));
    }
  }, [sensorId, queryParams]);

  const data = useSelector<
    ApplicationState,
    ZippedTSEnergySeriesData[] | undefined
  >(({ energy }) => {
    return pathOr(undefined, ['energyBySensorId', sensorId], energy);
  });

  const isLoading = useSelector<ApplicationState, boolean>(({ energy }) => {
    return pathOr(false, ['isLoadingByResourceId', sensorId], energy);
  });

  return { data, isLoading };
};
