import {
  getWebInstrumentations,
  initializeFaro,
  LogLevel,
} from '@grafana/faro-web-sdk';
import { context, trace } from '@opentelemetry/api';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { Resource } from '@opentelemetry/resources';
import {
  BatchSpanProcessor,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import {
  FaroSessionSpanProcessor,
  FaroTraceExporter,
} from '@grafana/faro-web-tracing';
import { Faro } from '@grafana/faro-react';

export default function initTracing() {
  const VERSION = '1.0.0';
  const NAME = 'connect';
  const COLLECTOR_URL = process.env.REACT_APP_SERVICE_TELEMETRY_URL || '';

  if (COLLECTOR_URL == '') {
    return;
  }

  // initialize faro
  const faro = initializeFaro({
    url: COLLECTOR_URL,
    apiKey: 'secret',
    app: {
      name: NAME,
      version: VERSION,
    },
    instrumentations: [
      ...getWebInstrumentations({
        captureConsole: false,
        captureConsoleDisabledLevels: [
          LogLevel.LOG,
          LogLevel.TRACE,
          LogLevel.DEBUG,
        ],
      }),
    ],
  });

  // set up otel
  const resource = Resource.default().merge(
    new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: NAME,
      [SemanticResourceAttributes.SERVICE_VERSION]: VERSION,
    })
  );

  const provider = new WebTracerProvider({ resource });

  const metas = faro.metas;

  provider.addSpanProcessor(
    new FaroSessionSpanProcessor(
      new BatchSpanProcessor(new FaroTraceExporter({ ...faro })),
      metas
    )
  );

  provider.register({
    propagator: new W3CTraceContextPropagator(),
  });

  registerInstrumentations({
    instrumentations: [new DocumentLoadInstrumentation()],
  });

  // register OTel with Faro
  faro.api.initOTEL(trace, context);

  initLogging(faro);
}

let _pushLogContext = {};

function initLogging(faro: Faro) {
  var console = window.console,
    _log = console ? console.log : function() {};

  console.warn = function(...data) {
    _log.apply(console, data);
    faro.api.pushLog(data, {
      context: { level: 'WARNING', ..._pushLogContext },
    });
  };

  console.info = function(...data) {
    _log.apply(console, data);
    faro.api.pushLog(data, { context: { level: 'INFO', ..._pushLogContext } });
  };

  console.error = function(...data) {
    _log.apply(console, data);
    faro.api.pushLog(data, { context: { level: 'ERROR', ..._pushLogContext } });
  };
}
//////////////////////////////////////

export function updateLoggingContext(ctx: Object) {
  _pushLogContext = { ...ctx };
}
