import React from 'react';
import { PageContentHeader } from '../../components/Page';
import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';
import styles from './InstallationManual.module.css';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

const InstallationManual: React.FC = () => {
  const [lastUpdated, setLastUpdated] = React.useState<Date | null>(null);
  const installationManualPath = '/static/installation-manual/';

  React.useEffect(() => {
    fetch(installationManualPath)
      .then(response => response.text())
      .then(html => {
        // parse the redirect url from the response html, it's in the href of link tag with rel canonical
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const redirectUrl = doc
          .querySelector('link[rel="canonical"]')
          ?.getAttribute('href');
        if (isDefined(redirectUrl)) {
          return redirectUrl;
        } else {
          throw new Error('No redirect url found');
        }
      })
      .then(redirectUrl => fetch(`${installationManualPath}${redirectUrl}`))
      .then(response => response.text())
      .then(html => {
        // from the html, parse the last updated timestamp from the content of meta tag with name exp-export-timestamp
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const lastUpdatedString = doc
          .querySelector('meta[name="exp-export-timestamp"]')
          ?.getAttribute('content');
        if (lastUpdatedString) {
          setLastUpdated(new Date(lastUpdatedString));
        }
      });
  }, [installationManualPath]);

  return (
    <IndexManagementPageLayout>
      <PageContentHeader header="Installation Manual" />
      {lastUpdated && (
        <div className={styles.lastUpdated}>
          Last Updated:{' '}
          {lastUpdated
            .toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            .replace(/ /g, '-')}
        </div>
      )}
      <iframe src={installationManualPath} className={styles.iframe}></iframe>
    </IndexManagementPageLayout>
  );
};

export default InstallationManual;
