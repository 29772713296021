import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import { Actuator } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as ActuatorActions,
  destroy,
  hideDeleteActuatorModal,
} from '../../actions/control_boards';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface OwnProps {
  equipmentId: number;
  actuatorId: number;
}

interface Props extends OwnProps {
  isVisible: boolean;
  onDelete: (actuator: Actuator) => void;
  onClose: () => void;
  apiError: ApiError;
  isLoading: boolean;
  actuator: Actuator;
}

class DeleteActuatorModal extends React.Component<Props> {
  render() {
    const {
      onDelete,
      onClose,
      apiError,
      actuator,
      isVisible,
      isLoading,
    } = this.props;

    if (!isVisible) return null;

    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>

        <Button
          onClick={() => onDelete(actuator)}
          disabled={isLoading || !Object.values(actuator).length}
        >
          Delete
        </Button>
      </>
    );
    return (
      <Modal actions={actions}>
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ textAlign: 'justify', textAlignLast: 'center' }}>
            <div style={{ padding: '1rem 0' }}>
              Are you sure you want to delete{' '}
              {actuator.title ? (
                <span style={{ fontWeight: 'bold' }}>{actuator.title}</span>
              ) : (
                'this actuator'
              )}
              ?
            </div>
          </div>
        </div>
        {renderAPIerror(apiError, ActuatorActions.DELETE_ACTUATOR_ERROR)}
      </Modal>
    );
  }
}

const mapStateToProps = (
  { controlBoards }: ApplicationState,
  { actuatorId, equipmentId }: OwnProps
) => {
  return {
    actuator:
      R.pathOr([], [equipmentId], controlBoards.actuatorsByEquipmentId).find(
        (a: Actuator) => a.id === actuatorId
      ) || {},
    isVisible: controlBoards.showDeleteActuatorModal,
    apiError: R.pathOr({}, [actuatorId], controlBoards.editById).apiError,
    isLoading: R.pathOr({}, [actuatorId], controlBoards.editById).isLoading,
  };
};

const mapDispatchtoProps = (dispatch: any) => ({
  onDelete: (actuator: Actuator) => dispatch(destroy(actuator)),
  onClose: () => dispatch(hideDeleteActuatorModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(DeleteActuatorModal);
