import {
  Button,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  Cell,
  CardListRowData,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  CircuitBreaker,
  DistributionPanel,
  EnergyDeviceFromApi,
  EnergyPro,
  EnergySensor,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { connect } from 'react-redux';
import {
  displayFormErrors,
  patch,
  primeForDelete,
  showDeleteCircuitBreakerModal,
  updateField,
} from '../../../actions/circuit_breakers';
import { moveBreakerInPanel } from '../../../actions/distribution_panel';
import EditCircuitBreakerForm from '../../../components/EditCircuitBreakerForm';
import { ApplicationState } from '../../../reducers';
import { EditById } from '../../../reducers/circuit_breakers';
import DeleteCircuitBreakerModal from '../DeleteCircuitBreakerModal';
import styles from './CircuitBreakersTab.module.css';

interface OwnProps {
  panelId: number;
  currentSiteId: number;
  selectedBreakerId: number;
  resetSelectedBreakerId: () => void;
  panelColumns: number;
  energyPro: EnergyPro;
}

interface Props extends OwnProps {
  editCircuitBreakers: EditById;
  circuitBreakers: CircuitBreaker[];
  onChange: (breakerId: string, field: string, value: string | number) => void;
  showDeleteCircuitBreakerModal: typeof showDeleteCircuitBreakerModal;
  primeBreakerForDelete: typeof primeForDelete;
  breakerSlotRows: number;
  patchBreaker: (breakerId: number) => void;
  moveBreakerInPanel: (breakerId: number) => void;
  displayBreakerFormErrors: typeof displayFormErrors;
  distributionPanel?: DistributionPanel;
}

class CircuitBreakersTab extends React.Component<Props> {
  onUpdateBreaker = (breaker: CircuitBreaker) => {
    const {
      patchBreaker,
      moveBreakerInPanel,
      editCircuitBreakers,
      displayBreakerFormErrors,
    } = this.props;

    if (hasKeys(editCircuitBreakers[breaker.id].formErrors)) {
      displayBreakerFormErrors(String(breaker.id));
    } else {
      patchBreaker(breaker.id);
      if (
        editCircuitBreakers[breaker.id].fields.breakerColumn !==
          breaker.breakerColumn ||
        editCircuitBreakers[breaker.id].fields.breakerSlot !==
          breaker.breakerSlot
      ) {
        moveBreakerInPanel(breaker.id);
      }
    }
  };

  sortCircuitBreakersList = () => {
    const { circuitBreakers } = this.props;

    //sort breakers by the DP editor visual,
    //i.e. from top to bottom of each column
    return circuitBreakers.sort((breakerA, breakerB) => {
      const breakerAColumn = breakerA.breakerColumn;
      const breakerBColumn = breakerB.breakerColumn;
      if (breakerAColumn === breakerBColumn) {
        return breakerA.breakerSlot - breakerB.breakerSlot;
      }
      return breakerAColumn - breakerBColumn;
    });
  };

  render() {
    const {
      editCircuitBreakers,
      showDeleteCircuitBreakerModal,
      primeBreakerForDelete,
      onChange,
      currentSiteId,
      breakerSlotRows,
      panelId,
      selectedBreakerId,
      resetSelectedBreakerId,
      panelColumns,
      distributionPanel,
      energyPro,
    } = this.props;
    const breakerToEnergySensorsMapping = getBreakerToEnergySensorsMappng(
      energyPro
    );
    const sortedCircuitBreakers = this.sortCircuitBreakersList();

    const data: CardListRowData[] = sortedCircuitBreakers.map(
      (breaker: CircuitBreaker) => ({
        key: breaker.id.toString(),
        content: (
          <>
            <Cell width="11">
              <span>{breaker.title}</span>
            </Cell>
            <Cell width="1" cellAlign="right">
              <MenuDropdown>
                <MenuDropdownItem
                  isRed
                  onSelect={() => {
                    primeBreakerForDelete(breaker.id);
                    showDeleteCircuitBreakerModal();
                  }}
                >
                  Delete Circuit Breaker
                </MenuDropdownItem>
              </MenuDropdown>
            </Cell>
          </>
        ),
        extraContent: [
          <>
            {distributionPanel && (
              <EditCircuitBreakerForm
                distributionPanel={distributionPanel}
                energySensors={breakerToEnergySensorsMapping[breaker.id]}
                fields={editCircuitBreakers[breaker.id].fields}
                onChange={(field: string, value: string) =>
                  onChange(breaker.id.toString(), field, value)
                }
                currentSiteId={currentSiteId}
                formErrorsVisible={
                  editCircuitBreakers[breaker.id].formErrorsVisible
                }
                formErrors={editCircuitBreakers[breaker.id].formErrors}
                breakerSlotRows={breakerSlotRows}
                apiError={editCircuitBreakers[breaker.id].apiError}
                patchingBreakerId={breaker.id}
                panelColumns={panelColumns}
                energyPro={energyPro}
              />
            )}
          </>,
          <Cell width="12" cellAlign="right" className={styles.actionContainer}>
            <Button
              disabled={!editCircuitBreakers[breaker.id].isChanged}
              variant="solid"
              onClick={() => this.onUpdateBreaker(breaker)}
              children="Save"
            />
          </Cell>,
        ],
      })
    );

    return (
      <>
        <CardList
          data={data}
          featureCardId={selectedBreakerId}
          resetFeatureCard={resetSelectedBreakerId}
        />
        <DeleteCircuitBreakerModal panelId={panelId} />
      </>
    );
  }
}

const mapStateToProps = (
  { distributionPanels, circuitBreakers }: ApplicationState,
  { panelId }: OwnProps
) => {
  return {
    breakerSlotRows:
      distributionPanels.distributionPanelsById[panelId].breakerSlots,
    editCircuitBreakers: circuitBreakers.editById,
    distributionPanel: distributionPanels.distributionPanelsById[panelId],
    circuitBreakers: pathOr(
      [],
      [panelId, 'breakers'],
      distributionPanels.distributionPanelsById
    )
      .map(breaker => circuitBreakers.circuitBreakersById[breaker.breaker.id])
      .filter(breaker => breaker !== undefined),
  };
};

const mapDispatchToProps = (dispatch, { panelId }: OwnProps) => ({
  onChange: (breakerId: string, field: string, value: string | number) =>
    dispatch(updateField(breakerId, field, value)),
  showDeleteCircuitBreakerModal: () =>
    dispatch(showDeleteCircuitBreakerModal()),
  primeBreakerForDelete: (id: number) => dispatch(primeForDelete(id)),

  patchBreaker: (breakerId: number) => dispatch(patch(panelId, breakerId)),
  moveBreakerInPanel: (breakerId: number) =>
    dispatch(moveBreakerInPanel(panelId, breakerId)),
  displayBreakerFormErrors: (breakerId: string) =>
    dispatch(displayFormErrors(breakerId)),
});

const getBreakerToEnergySensorsMappng = (energyPro: EnergyPro) => {
  const bus1Device = energyPro.bus1Device;
  const bus2Device = energyPro.bus2Device;

  const energySensorsByBreakerId: {
    [breakerId: string]: EnergySensor[];
  } = {};

  getBusDeviceSensors(bus1Device, energySensorsByBreakerId);
  getBusDeviceSensors(bus2Device, energySensorsByBreakerId);

  return energySensorsByBreakerId;
};

const getBusDeviceSensors = (
  device: EnergyDeviceFromApi | null | undefined,
  mapping: {
    [breakerId: string]: EnergySensor[];
  }
) => {
  if (!device) return;
  getBusDeviceSensors(device.busDevice, mapping);
  device.sensors.forEach(sensor => {
    const energySensorsForThisBreaker = mapping[sensor.breakerId] || [];
    energySensorsForThisBreaker.push(sensor);
    mapping[sensor.breakerId] = energySensorsForThisBreaker;
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(CircuitBreakersTab);
