import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as SiteActions } from '../actions/sites';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as UserActions } from '../actions/users';
import { Actions as SpaceActions } from '../actions/spaces';
import { Actions as UserGroupActions } from '../actions/user_groups';
import { Actions as SiteGroupActions } from '../actions/site_groups';
import { Actions as DistributionPanelActions } from '../actions/distribution_panel';
import { Actions as CircuitBreakerActions } from '../actions/circuit_breakers';
import { Actions as EnergyProActions } from '../actions/energy_pros';

const deleteMiddleware = store => next => action => {
  switch (action.type) {
    case SiteActions.DELETE_SITE_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: SiteActions.DELETE_SITE_ERROR,
          id: action.id,
          data: {
            status: 403,
            message:
              'Delete failed: unable to delete a site with space attached',
          },
        });
      }
      break;

    case EquipmentActions.DELETE_EQUIPMENT_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: EquipmentActions.DELETE_EQUIPMENT_ERROR,
          id: action.id,
          data: {
            status: 403,
            message:
              'Delete failed: unable to delete an equipment with sensor attached',
          },
        });
      }
      break;

    case GatewayActions.DELETE_GATEWAY_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: GatewayActions.DELETE_GATEWAY_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case SensorActions.DELETE_SENSOR_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: SensorActions.DELETE_SENSOR_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case UserActions.DELETE_USER_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: UserActions.DELETE_USER_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case UserActions.REMOVE_API_KEY_FROM_USER_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: UserActions.REMOVE_API_KEY_FROM_USER_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case SpaceActions.DELETE_SPACE_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: SpaceActions.DELETE_SPACE_ERROR,
          id: action.id,
          data: {
            status: 403,
            message:
              'Delete failed: unable to delete a space with resources attached \n (i.e. sub space, equipment, device, sensor, distribution panel, etc.)',
          },
        });
      }
      break;

    case UserGroupActions.DELETE_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: UserGroupActions.DELETE_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case SiteGroupActions.DELETE_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: SiteGroupActions.DELETE_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case DistributionPanelActions.DELETE_DISTRIBUTION_PANEL_SUCCESS:
      if (!action.data) {
        store.dispatch({
          type: DistributionPanelActions.DELETE_DISTRIBUTION_PANEL_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case EnergyProActions.DELETE_ENERGY_PRO_SUCCESS:
      if (!action.data.result) {
        store.dispatch({
          type: EnergyProActions.DELETE_ENERGY_PRO_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    case CircuitBreakerActions.DELETE_CIRCUIT_BREAKER_SUCCESS:
      if (!action.data.result) {
        store.dispatch({
          type: CircuitBreakerActions.DELETE_CIRCUIT_BREAKER_ERROR,
          id: action.id,
          data: {
            status: 403,
            message: 'Delete failed',
          },
        });
      }
      break;

    default:
      break;
  }

  return next(action);
};

export default deleteMiddleware;
