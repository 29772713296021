import { Table } from '@energybox/react-ui-library/dist/components';
import { Site } from '@energybox/react-ui-library/dist/types';
import { useGetSites } from '../../hooks/useSites';
import styles from './NewGroupModal.module.css';

type SelectedSitesTableProps = {
  selectedSiteIds: number[];
};

function SelectedSitesTable({ selectedSiteIds }: SelectedSitesTableProps) {
  const { sitesById } = useGetSites();
  const selectedSites = selectedSiteIds.map(siteId => {
    return sitesById[siteId];
  });
  const columns = [
    {
      header: 'Site',
      width: '40%',
      cellContent: (site: Site) => (
        <>
          <div>{site.title}</div>
        </>
      ),
    },
    {
      header: 'Address',
      width: '60%',
      cellContent: (site: Site) => (
        <>
          <div>{site.address}</div>
        </>
      ),
    },
  ];
  return (
    <div className={styles.selectedSitesTable}>
      <Table
        columns={columns}
        stickyHeader={true}
        headerWrap={true}
        data={selectedSites}
        highlightAlternateRows={true}
      />
    </div>
  );
}

export default SelectedSitesTable;
