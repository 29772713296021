import { WizardCard } from '@energybox/react-ui-library/dist/components';
import {
  SentinelConfiguration,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import FeatureFlag from '../../../containers/FeatureFlag';
import { SentinelTypeIcon } from '../../../utils/sentinel';
import styles from './StepSentinelType.module.css';

type StepSensorTypeCardProps = {
  onChange: (sentinelType: SentinelType) => void;
  sentinelType: SentinelType;
  sentinelConfiguration: SentinelConfiguration;
};

const StepSensorTypeCard = ({
  sentinelConfiguration,
  sentinelType,
  onChange,
}: StepSensorTypeCardProps) => (
  <>
    <WizardCard
      className={styles.wizardCard}
      onClick={() => onChange(sentinelConfiguration.type)}
      icon={<SentinelTypeIcon type={sentinelConfiguration.type} size={80} />}
      title={sentinelConfiguration.title}
      description={sentinelConfiguration.description}
      active={sentinelType === sentinelConfiguration.type}
    />
  </>
);

type StepTypeProps = {
  onChange: (sentinelType: SentinelType) => void;
  sentinelType: SentinelType;
  sentinelTypesList: SentinelConfiguration[];
};

const ebySystemHealthSentinels = [
  SentinelType.BATTERY_LEVEL_CHECK,
  SentinelType.CONNECTIVITY,
  SentinelType.CONTROLS_HEALTH_CHECK,
];

const StepSentinelType = ({
  onChange,
  sentinelType,
  sentinelTypesList,
}: StepTypeProps) => {
  const renderSentinelConfig = (sentinelConfig: SentinelConfiguration) => {
    if (sentinelConfig.featureFlag === true) {
      return (
        <FeatureFlag key={`StepType${sentinelConfig.type}`}>
          <StepSensorTypeCard
            sentinelType={sentinelType}
            sentinelConfiguration={sentinelConfig}
            onChange={onChange}
          />
        </FeatureFlag>
      );
    }

    return (
      <StepSensorTypeCard
        key={`StepType${sentinelConfig.type}`}
        sentinelType={sentinelType}
        sentinelConfiguration={sentinelConfig}
        onChange={onChange}
      />
    );
  };

  const operationalSentinelsToRender = sentinelTypesList.filter(
    ({ type }) => !ebySystemHealthSentinels.includes(type)
  );
  const systemHealthSentinelsToRender = sentinelTypesList.filter(({ type }) =>
    ebySystemHealthSentinels.includes(type)
  );

  return (
    <div className={styles.root}>
      <div className={styles.operationalContainer}>
        <span className={styles.operationalTitle}>Operations</span>
        <div className={styles.operationalTiles}>
          {operationalSentinelsToRender.map(renderSentinelConfig)}
        </div>
      </div>
      <div className={styles.verticalDivider} />
      <div className={styles.systemHealthContainer}>
        <span className={styles.systemHealthTitle}>System Health</span>
        <div className={styles.systemHealthTiles}>
          {systemHealthSentinelsToRender.map(renderSentinelConfig)}
        </div>
      </div>
    </div>
  );
};

export default StepSentinelType;
