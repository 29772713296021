import {
  EnergyApiFilter,
  EnergyDashboard,
} from '@energybox/react-ui-library/dist/types';
import pathOr from 'ramda/src/pathOr';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnergyDashboardBySiteId } from '../actions/energy';
import { getTSEnergyDashboardBySiteId } from '../actions/energyTS';
import { Energy, EnergyDashboardStatus } from '../reducers/energy';

export default function useSiteEnergyDashboard(
  siteId: string | number,
  queryParams: EnergyApiFilter,
  isNewTsdb: boolean
): EnergyDashboardStatus {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      isNewTsdb
        ? dispatch(getTSEnergyDashboardBySiteId(siteId, queryParams))
        : dispatch(getEnergyDashboardBySiteId(siteId, queryParams));
    }
  }, [isNewTsdb, siteId, queryParams]);

  const energyDashboard: EnergyDashboard = useSelector(
    (state: { energy: Energy }) => {
      return pathOr(
        undefined,
        [siteId.toString(), 'data'],
        state.energy.energyDashboardBySiteId
      );
    }
  );

  const isLoading: boolean = useSelector((state: { energy: Energy }) => {
    return pathOr(
      false,
      [siteId.toString(), 'isLoading'],
      state.energy.energyDashboardBySiteId
    );
  });

  return {
    isLoading,
    data: energyDashboard,
  };
}
