import { Actions } from '../actions/analytics';
import { Status } from '../types/status';
import * as R from 'ramda';
import assocPath from 'ramda/src/assocPath';
import { TemperatureRecommendationBySiteId } from '../types/analytics';
import { EDM } from '@energybox/react-ui-library/dist/types';

export type Analytics = {
  pageStatus: PageStatus;
  temperatureRecommendation: TemperatureRecommendationBySiteId;
  report_key: string;
  edmReportData: EDM | undefined;
  isReportKeyLoading: boolean;
  siteId: string | undefined;
};

export interface PageStatus {
  [key: string]: Status;
}

const initialState: Analytics = {
  pageStatus: {},
  temperatureRecommendation: {},
  edmReportData: undefined,
  report_key: '',
  isReportKeyLoading: false,
  siteId: undefined,
};

const analytics = (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.RE_DO_REPORT_LOADING:
      return {
        ...state,
        [action.page]: Status.LOADING,
      };
    case Actions.RE_DO_REPORT_SUCCESS:
      return {
        ...state,
        [action.page]: Status.SUCCESS,
      };
    case Actions.RE_DO_REPORT_ERROR:
      return {
        ...state,
        [action.page]: Status.SUCCESS,
      };
    case Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING:
      return assocPath(
        ['temperatureRecommendation', action.siteId, 'isLoading'],
        true,
        state
      );
    case Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS:
      return assocPath(
        ['temperatureRecommendation', action.siteId],
        {
          isLoading: false,
          data: action.data.data,
        },
        state
      );
    case Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR:
      return assocPath(
        ['temperatureRecommendation', action.siteId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_EDM_REPORT_KEY_SUCCESS:
      return R.pipe(
        R.assocPath(['report_key', action.orgId], action.data.report_key),
        R.assocPath(['isReportKeyLoading'], false)
      )(state);
    case Actions.GET_EDM_REPORT_KEY_LOADING:
      return R.pipe(R.assocPath(['isReportKeyLoading'], true))(state);
    case Actions.GET_EDM_REPORT_KEY_ERROR:
      return R.pipe(R.assocPath(['isReportKeyLoading'], false))(state);

    case Actions.GET_EDM_REPORT_DATA_SUCCESS:
      return R.pipe(
        R.assocPath(['edmReportData'], action.data),
        R.assocPath(['isEdmDataLoading', action.orgId], true)
      )(state);
    case Actions.GET_EDM_REPORT_DATA_LOADING:
      return R.pipe(R.assoc(['isEdmDataLoading', action.orgId], true))(state);
    case Actions.GET_EDM_REPORT_DATA_ERROR:
      return R.pipe(R.assoc(['isEdmDataLoading', action.orgId], true))(state);

    default:
      return state;
  }
};

export default analytics;
