import {
  Button,
  FormText,
  InputField,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
  Tab,
  Tabs,
  WeekdayPicker,
} from '@energybox/react-ui-library/dist/components';
import { useEffect, useState } from 'react';
import ModalFormContent from '../../components/ModalFormContent';
import {
  Equipment,
  GenericFunction,
  Sop,
} from '@energybox/react-ui-library/dist/types';
import { PropertyToLabel } from '../../types/global';
import styles from './NewOrEditTaskSopModal.module.css';
import MultiSelectEquipmentType from '../Selects/MultiSelectEquipmentType';
import TimeTableTimeInput from '../../components/EditTimeTableForm/TimeTableTimeInput';
import {
  createTaskSop,
  updateTaskSop,
  Actions as SopActions,
} from '../../actions/taskSop';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { ApplicationState } from '../../reducers';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import { TaskSOP } from '../../reducers/taskSop';
import { getEquipments } from '../../actions/equipment';

export type Props = {
  className?: string;
  formData?: {
    id?: number | string;
    title?: string;
    description?: string;
    taskType?: string;
    attachTo?: string;
    entityType?: number[];
    dayOfWeek?: [];
    timeOfDay?: string;
  };
  organizationUnitId: number | string;
  isVisible: boolean;
  onClose: GenericFunction;
  onChange: GenericFunction;
};

type FormError = {
  1: {
    title: string | null;
  };
  2: {
    entityTypeId: {
      errorIds: number[];
      message: string | null;
    };
    dayOfWeek: string | null;
    timeOfDay: string | null;
  };
};

const NewOrEditTaskSopModal = (props: Props) => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();
  const { formData, organizationUnitId, onClose } = props;
  const isOrgLevel = organizationUnitId === orgId;
  const [sopId] = useState<number | string>(formData?.id || 'new');
  const [title, setTitle] = useState<string>(formData?.title || '');
  const [description, setDescription] = useState<string>(
    formData?.description || ''
  );
  const [taskType] = useState<string>(formData?.taskType || 'Daily Checks');
  const [attachTo] = useState<string>(formData?.attachTo || 'Equipment Types');
  const [entityTypeId, setEntityTypeId] = useState<number[]>(
    formData?.entityType || []
  );
  const [dayOfWeek, setDayOfWeek] = useState<number[]>(
    formData?.dayOfWeek || []
  );
  const [timeOfDay, setTimeOfDay] = useState<string>(formData?.timeOfDay || '');
  const [formStep, setFormStep] = useState<number>(1);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [formErrorsVisible, setFormErrorsVisible] = useState<boolean>(false);
  const [formError, setFormError] = useState<FormError>({
    1: {
      title: 'Title cannot be empty.',
    },
    2: {
      entityTypeId: {
        errorIds: [],
        message: 'At least 1 equipment type is needed.',
      },
      dayOfWeek: 'At least 1 day should be selected.',
      timeOfDay: 'Please pick a time or input time directly.',
    },
  });
  const [step1IsValid, setStep1IsValid] = useState<boolean>(false);
  const [step2IsValid, setStep2IsValid] = useState<boolean>(false);

  const isNew = sopId === 'new';
  const isCustomize = sopId === 'customize';
  const isEdit = !isNew && !isCustomize;

  const taskSops = useSelector<ApplicationState, Sop[]>(({ sops }) => {
    return Object.values(sops.sopsById).filter(item => {
      if (isOrgLevel) {
        return (
          item.components[0]?.type === 'TASK' &&
          item.organizationUnitId === orgId
        );
      } else {
        return (
          item.components[0]?.type === 'TASK' &&
          (item.organizationUnitId === organizationUnitId ||
            item.organizationUnitId === orgId)
        );
      }
    });
  });

  const equipmentsByIdWithinSite: Equipment[] | undefined = useSelector<
    ApplicationState,
    Equipment[] | undefined
  >(({ equipment }) => equipment.equipmentPayloadArray);

  const siteEquipmentTypeIds = [
    ...new Set(equipmentsByIdWithinSite?.map(equip => equip.typeId) || []),
  ];

  useEffect(() => {
    if (!isOrgLevel)
      dispatch(
        getEquipments({ siteIds: [+organizationUnitId] }, +organizationUnitId)
      );
  }, []);

  useEffect(() => {
    if (!isOrgLevel)
      setEntityTypeId(
        entityTypeId.filter(id => siteEquipmentTypeIds.includes(id))
      );
  }, [isOrgLevel, formData?.entityType]);

  const { createSuccess, apiError } = useSelector<ApplicationState, TaskSOP>(
    ({ taskSop }) => {
      return taskSop;
    }
  );

  const validateForm = () => {
    const equipmentTypeIdInUse = [
      ...new Set(
        taskSops
          .filter(item => {
            if (isOrgLevel) {
              return (
                item.components[0]?.type === 'TASK' &&
                item.organizationUnitId === orgId
              );
            } else {
              return (
                item.components[0]?.type === 'TASK' &&
                item.organizationUnitId === organizationUnitId &&
                item.id !== sopId
              );
            }
          })
          .reduce(
            (result: number[], sop) => result.concat(sop.equipmentTypeIds),
            []
          )
      ),
    ];
    const errorEquipmentTypeIds = entityTypeId.filter(
      id => equipmentTypeIdInUse.indexOf(id) !== -1
    );

    const entityTypeMessage = () => {
      if (entityTypeId.length === 0) {
        return 'At least 1 equipment type is needed.';
      } else if (!isOrgLevel && errorEquipmentTypeIds.length !== 0) {
        return 'The selected Equipment type(s) are already in use with this SOP. Please update the existing assignment or delete it to create a new one.';
      } else {
        return null;
      }
    };
    setFormError({
      1: {
        title:
          title.trim() === '' && formStep === 1
            ? 'Title cannot be empty.'
            : null,
      },
      2: {
        entityTypeId: {
          errorIds: errorEquipmentTypeIds,
          message: entityTypeMessage(),
        },
        dayOfWeek:
          dayOfWeek.length === 0 && formStep === 2
            ? 'Days can not be empty.'
            : null,
        timeOfDay:
          timeOfDay.length === 0 && formStep === 2
            ? 'Invalid time, please pick a time or input a correct time.'
            : null,
      },
    });
  };

  useEffect(() => {
    if (createSuccess) {
      onClose();
    }
  }, [createSuccess]);

  useEffect(() => {
    validateForm();
  }, [title, entityTypeId, dayOfWeek, timeOfDay, formStep]);

  useEffect(() => {
    if (submitClicked) {
      setFormErrorsVisible(chcekFormError(formError, formStep));
    }
  }, [formError, submitClicked]);

  useEffect(() => {
    if (formStep === 1) {
      setStep1IsValid(!chcekFormError(formError, formStep));
    }
    if (formStep === 2) {
      setStep2IsValid(!chcekFormError(formError, formStep));
    }
  }, [formError, formErrorsVisible, formStep]);

  // if return value = false, there is no error
  const chcekFormError = (formErrorObject: FormError, step): boolean => {
    const errorValues: (
      | string
      | { errorIds: number[]; message: string }
    )[] = Object.values(formErrorObject[step]);
    return errorValues.reduce((hasError: boolean, value, _i) => {
      if (value !== null && typeof value === 'object') {
        return hasError || value?.message !== null;
      }
      return hasError || value !== null;
    }, false);
  };

  const mapDayOfWeek = () => {
    const weekInShort = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    return dayOfWeek.map(item => weekInShort[item]);
  };

  const handleSelectEquipType = (equipmentTypeId: number) => {
    setEntityTypeId([...entityTypeId, equipmentTypeId]);
  };

  const submitButtonAction = () => {
    setSubmitClicked(true);
    validateForm();

    if (formStep === 1 && step1IsValid) {
      setFormStep(2);
      setSubmitClicked(false);
    }

    if (formStep === 2 && step2IsValid) {
      // submitAction
      if (sopId === 'new' || sopId === 'customize') {
        dispatch(
          createTaskSop({
            title,
            description,
            organizationUnitId,
            equipmentTypeIds: entityTypeId,
            components: [
              {
                type: 'TASK',
                taskSettings: {
                  taskType: 'DAILY_CHECK',
                  weekdays: mapDayOfWeek(),
                  time: timeOfDay,
                },
              },
            ],
          })
        );
      } else {
        dispatch(
          updateTaskSop(sopId, {
            title,
            description,
            organizationUnitId,
            equipmentTypeIds: entityTypeId,
            components: [
              {
                type: 'TASK',
                taskSettings: {
                  taskType: 'DAILY_CHECK',
                  weekdays: mapDayOfWeek(),
                  time: timeOfDay,
                },
              },
            ],
          })
        );
      }
      setSubmitClicked(false);
    }
  };

  const getActionText = (step, isEdit) => {
    if (step === 1) {
      return 'Next';
    } else if (!isEdit) {
      return 'Add';
    } else {
      return 'Save';
    }
  };

  const getFormTitle = (isNew, isCustomize) => {
    if (isNew) {
      return 'New Task SOP';
    } else if (isCustomize) {
      return 'Customize Task SOP';
    } else {
      return 'Update Task SOP';
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={submitButtonAction}>
        {getActionText(formStep, isEdit)}
      </Button>
    </>
  );
  const formTitle = getFormTitle(isNew, isCustomize);

  return (
    <Modal onClose={onClose} actions={actions} disableEscapeClose>
      <ModalTitle>{formTitle}</ModalTitle>
      <ModalContent>
        <Tabs className={styles.tabs}>
          <Tab
            key={`TaskSopFormTab1`}
            active={formStep === 1}
            onClick={() => {
              setFormStep(1);
            }}
          >
            {'Step 1'}
          </Tab>
          <Tab
            key={`TaskSopFormTab2`}
            active={formStep === 2}
            disabled={!step1IsValid}
            onClick={() => {
              setFormStep(2);
            }}
          >
            {'Step 2'}
          </Tab>
        </Tabs>
        <ModalFormContent>
          {formStep === 1 && (
            <>
              <div className={styles.field}>
                <Label required>
                  <b>{PropertyToLabel.title}</b>
                </Label>
              </div>
              <div className={styles.field}>
                <InputField
                  type="text"
                  name="title"
                  autoComplete="title"
                  value={title}
                  onChange={({ currentTarget }) =>
                    // onChange(currentTarget.name, currentTarget.value)
                    setTitle(currentTarget.value)
                  }
                  error={formErrorsVisible && !!formError[1].title}
                  customErrorText={formError[1].title || ''}
                />
              </div>
              <div>
                <Label>
                  <b>{PropertyToLabel.description}</b>
                </Label>
              </div>
              <div>
                <InputField
                  type="text"
                  name="description"
                  autoComplete="description"
                  value={description}
                  onChange={({ currentTarget }) =>
                    // onChange(currentTarget.name, currentTarget.value)
                    setDescription(currentTarget.value)
                  }
                />
              </div>
              <div>
                <Label required>
                  <b>{PropertyToLabel.taskType}</b>
                </Label>
              </div>
              <div>
                <div className={styles.disabled}>
                  <Label>{taskType}</Label>
                </div>
              </div>
              <div>
                <Label required>
                  <b>{PropertyToLabel.attachTo}</b>
                </Label>
              </div>
              <div>
                <div className={styles.disabled}>
                  <Label>{attachTo}</Label>
                </div>
              </div>
            </>
          )}

          {formStep === 2 && (
            <>
              <div className={styles.field}>
                <Label required>
                  <b>{PropertyToLabel.equipmentTypeIds}</b>
                </Label>
              </div>
              <div className={styles.field}>
                <MultiSelectEquipmentType
                  organizationUnitId={+organizationUnitId}
                  onSelect={handleSelectEquipType}
                  onToggleSelectAll={(ids: number[]) => {
                    setEntityTypeId(ids);
                  }}
                  onRemoveEquipmentType={(equipmentTypeId: number) => {
                    setEntityTypeId(
                      entityTypeId.filter(id => id !== equipmentTypeId)
                    );
                  }}
                  equipmentTypeFilter={(equipmentType): boolean => {
                    if (isOrgLevel) {
                      const result = taskSops
                        .filter(item => {
                          return (
                            item.components[0]?.type === 'TASK' &&
                            item.organizationUnitId === orgId
                          );
                        })
                        .reduce((hasEquipType, sop) => {
                          return (
                            (!sop.equipmentTypeIds.includes(
                              equipmentType?.id || 0
                            ) &&
                              hasEquipType) ||
                            sop.id === sopId
                          );
                        }, true);
                      return result;
                    }
                    const result = taskSops
                      .filter(item => {
                        return (
                          item.components[0]?.type === 'TASK' &&
                          item.organizationUnitId === organizationUnitId
                        );
                      })
                      .reduce((hasEquipType, sop) => {
                        return (
                          (!sop.equipmentTypeIds.includes(
                            equipmentType?.id || 0
                          ) &&
                            hasEquipType) ||
                          sop.id === sopId
                        );
                      }, true);
                    return result;
                  }}
                  error={
                    formErrorsVisible && !!formError[2].entityTypeId.message
                  }
                  customErrorText={formError[2].entityTypeId.message || ''}
                  errorEquipmentTypeIds={formError[2].entityTypeId.errorIds}
                  selectedEquipmentTypeIds={
                    isOrgLevel
                      ? entityTypeId
                      : entityTypeId.filter(id =>
                          siteEquipmentTypeIds.includes(id)
                        )
                  }
                />
              </div>
              <div>
                <Label required>
                  <b>{PropertyToLabel.dayOfWeek}</b>
                </Label>
              </div>
              <div>
                <WeekdayPicker
                  onChange={(weekday: number) => {
                    if (dayOfWeek.includes(weekday)) {
                      setDayOfWeek(dayOfWeek.filter(day => day !== weekday));
                    } else {
                      setDayOfWeek([...dayOfWeek, weekday]);
                    }
                  }}
                  id={'dayOfWeek'}
                  selectedWeekdays={dayOfWeek}
                  error={formErrorsVisible && !!formError[2].dayOfWeek}
                  customErrorText={formError[2].dayOfWeek || ''}
                />
              </div>
              <div>
                <Label required>
                  <b>{PropertyToLabel.timeOfDay}</b>
                </Label>
              </div>
              <div>
                <TimeTableTimeInput
                  className={''}
                  id="timeOfDay"
                  name={`timeOfDay`}
                  value={timeOfDay}
                  type={'CLOCK'}
                  onChange={(name, value) => {
                    setTimeOfDay(value);
                  }}
                  error={formErrorsVisible && !!formError[2].timeOfDay}
                  customErrorText={formError[2].timeOfDay || 'Invalid time'}
                />
              </div>
            </>
          )}
        </ModalFormContent>

        <div className={styles.smallFont}>
          <FormText>
            <Label>* Mandatory fields</Label>
          </FormText>
        </div>

        {renderAPIerror(apiError, SopActions.CREATE_TASK_SOP_ERROR)}
      </ModalContent>
    </Modal>
  );
};

export default NewOrEditTaskSopModal;
