export const apiBase = '/api/v1/edm/config';

export enum Actions {
  GET_EDM_CONFIG_BY_SITE_ID_LOADING = '@edm/GET_EDM_CONFIG_BY_SITE_ID_LOADING',
  GET_EDM_CONFIG_BY_SITE_ID_SUCCESS = '@edm/GET_EDM_CONFIG_BY_SITE_ID_SUCCESS',
  GET_EDM_CONFIG_BY_SITE_ID_ERROR = '@edm/GET_EDM_CONFIG_BY_SITE_ID_ERROR',
}

export const getEdmConfigBySiteId = (siteId: number | string | undefined) => ({
  type: 'API_GET',
  path: `${apiBase}/${siteId}`,
  success: { type: Actions.GET_EDM_CONFIG_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_EDM_CONFIG_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_EDM_CONFIG_BY_SITE_ID_LOADING, siteId },
});
