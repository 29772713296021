import * as R from 'ramda';
import { ApplicationState } from '../reducers';
import { KioskSettings } from '@energybox/react-ui-library/dist/types';
import { DEFAULT_HALT_KEYS } from '../constants/sentinels';
import { Dispatch } from 'react';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';

export const apiBase = `/api/v1/users`;

export enum Actions {
  GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS',
  GET_USERS_ERROR = '@users/GET_USERS_ERROR',
  GET_USERS_LOADING = '@users/GET_USERS_LOADING',

  GET_USER_SUCCESS = '@users/GET_USER_SUCCESS',
  GET_USER_ERROR = '@users/GET_USER_ERROR',
  GET_USER_LOADING = '@users/GET_USER_LOADING',

  TOGGLE_NEW_USER_MODAL = '@users/TOGGLE_NEW_USER_MODAL',

  TOGGLE_NEW_API_KEY_MODAL = '@users/TOGGLE_NEW_API_KEY_MODAL',

  TOGGLE_API_KEY_CREATED_MODAL = '@users/TOGGLE_API_KEY_CREATED_MODAL',

  TOGGLE_API_KEY_DELETE_MODAL = '@users/TOGGLE_API_KEY_DELETE_MODAL',

  UPDATE_FIELD = '@users/UPDATE_FIELD',
  RESET_EDIT_USER = '@users/RESET_EDIT_USER',

  UPDATE_API_KEY_FIELD = '@users/UPDATE_API_KEY_FIELD',

  CREATE_USER_LOADING = '@users/CREATE_USER_LOADING',
  CREATE_USER_ERROR = '@users/CREATE_USER_ERROR',
  CREATE_USER_SUCCESS = '@users/CREATE_USER_SUCCESS',

  PATCH_USER_LOADING = '@users/PATCH_USER_LOADING',
  PATCH_USER_ERROR = '@users/PATCH_USER_ERROR',
  PATCH_USER_SUCCESS = '@users/PATCH_USER_SUCCESS',

  DELETE_USER_LOADING = '@users/DELETE_USER_LOADING',
  DELETE_USER_ERROR = '@users/DELETE_USER_ERROR',
  DELETE_USER_SUCCESS = '@users/DELETE_USER_SUCCESS',

  GET_USER_POSITIONS_LOADING = '@users/GET_USER_POSITIONS_LOADING',
  GET_USER_POSITIONS_ERROR = '@users/GET_USER_POSITIONS_ERROR',
  GET_USER_POSITIONS_SUCCESS = '@users/GET_USER_POSITIONS_SUCCESS',

  ADD_USER_POSITION = '@users/ADD_USER_POSITION',

  ADD_USER_SCOPE_LOADING = '@users/ADD_USER_SCOPE_LOADING',
  ADD_USER_SCOPE_ERROR = '@users/ADD_USER_SCOPE_ERROR',
  ADD_USER_SCOPE_SUCCESS = '@users/ADD_USER_SCOPE_SUCCESS',

  DELETE_USER_SCOPE_LOADING = '@users/DELETE_USER_SCOPE_LOADING',
  DELETE_USER_SCOPE_ERROR = '@users/DELETE_USER_SCOPE_ERROR',
  DELETE_USER_SCOPE_SUCCESS = '@users/DELETE_USER_SCOPE_SUCCESS',

  PUT_USER_SCOPE_LOADING = '@users/PUT_USER_SCOPE_LOADING',
  PUT_USER_SCOPE_ERROR = '@users/PUT_USER_SCOPE_ERROR',
  PUT_USER_SCOPE_SUCCESS = '@users/PUT_USER_SCOPE_SUCCESS',

  ADD_USER_TO_GROUP_LOADING = '@users/ADD_USER_TO_GROUP_LOADING ',
  ADD_USER_TO_GROUP_ERROR = '@users/ADD_USER_TO_GROUP_ERROR',
  ADD_USER_TO_GROUP_SUCCESS = '@users/ADD_USER_TO_GROUP_SUCCESS',

  REMOVE_USER_FROM_GROUP_LOADING = '@users/REMOVE_USER_FROM_GROUP_LOADING',
  REMOVE_USER_FROM_GROUP_ERROR = '@users/REMOVE_USER_FROM_GROUP_ERROR',
  REMOVE_USER_FROM_GROUP_SUCCESS = '@users/REMOVE_USER_FROM_GROUP_SUCCESS',

  GET_USER_API_KEYS_LOADING = '@users/GET_USER_API_KEYS_LOADING ',
  GET_USER_API_KEYS_ERROR = '@users/GET_USER_API_KEYS_ERROR',
  GET_USER_API_KEYS_SUCCESS = '@users/GET_USER_API_KEYS_SUCCESS',

  ADD_API_KEY_TO_USER_LOADING = '@users/ADD_API_KEY_TO_USER_LOADING ',
  ADD_API_KEY_TO_USER_ERROR = '@users/ADD_API_KEY_TO_USER_ERROR',
  ADD_API_KEY_TO_USER_SUCCESS = '@users/ADD_API_KEY_TO_USER_SUCCESS',

  REMOVE_API_KEY_FROM_USER_LOADING = '@users/REMOVE_API_KEY_FROM_USER_LOADING ',
  REMOVE_API_KEY_FROM_USER_ERROR = '@users/REMOVE_API_KEY_FROM_USER_ERROR',
  REMOVE_API_KEY_FROM_USER_SUCCESS = '@users/REMOVE_API_KEY_FROM_USER_SUCCESS',

  USER_CHANGE_PASSWORD_LOADING = '@users/USER_CHANGE_PASSWORD_LOADING',
  USER_CHANGE_PASSWORD_ERROR = '@users/USER_CHANGE_PASSWORD_ERROR',
  USER_CHANGE_PASSWORD_SUCCESS = '@users/USER_CHANGE_PASSWORD_SUCCESS',
  USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR = '@users/USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR',

  DISPLAY_FORM_ERRORS = '@users/DISPLAY_FORM_ERRORS',

  USER_CREATE_KIOSK_SETTINGS_SUCCESS = '@users/USER_CREATE_KIOSK_SETTINGS_SUCCESS',
  USER_CREATE_KIOSK_SETTINGS_ERROR = '@users/USER_CREATE_KIOSK_SETTINGS_ERROR',
  USER_CREATE_KIOSK_SETTINGS_LOADING = '@users/USER_CREATE_KIOSK_SETTINGS_LOADING',

  USER_UPDATE_KIOSK_SETTINGS_SUCCESS = '@users/UPDATE_KIOSK_SETTINGS_SUCCESS',
  USER_UPDATE_KIOSK_SETTINGS_ERROR = '@users/UPDATE_KIOSK_SETTINGS_ERROR',
  USER_UPDATE_KIOSK_SETTINGS_LOADING = '@users/UPDATE_KIOSK_SETTINGS_LOADING',
}

export const getUsers = () => ({
  type: 'API_GET',
  path: `${apiBase}`,
  success: Actions.GET_USERS_SUCCESS,
  error: Actions.GET_USERS_ERROR,
  loading: Actions.GET_USERS_LOADING,
});

export const getUser = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_USER_SUCCESS,
  error: Actions.GET_USER_ERROR,
  loading: Actions.GET_USER_LOADING,
});

export const updateUserPassword = (
  id: string,
  oldPassword: string,
  newPassword: string,
  newPasswordVerify: string,
  callback?: () => void
) => ({
  type: 'API_PATCH',
  path: `${apiBase}/${id}/password`,
  payload: {
    oldPassword,
    newPassword,
    newPasswordVerify,
  },
  success: Actions.USER_CHANGE_PASSWORD_SUCCESS,
  error: Actions.USER_CHANGE_PASSWORD_ERROR,
  loading: Actions.USER_CHANGE_PASSWORD_LOADING,
  callback,
});

export const clearAuthenErrorDuringUpdatePassword = {
  type: Actions.USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR,
};

export const showNewUserModal = () => ({
  type: Actions.TOGGLE_NEW_USER_MODAL,
  value: true,
});

export const hideNewUserModal = () => ({
  type: Actions.TOGGLE_NEW_USER_MODAL,
  value: false,
});

export const showNewApiKeyModal = () => ({
  type: Actions.TOGGLE_NEW_API_KEY_MODAL,
  value: true,
});

export const hideNewApiKeyModal = () => ({
  type: Actions.TOGGLE_NEW_API_KEY_MODAL,
  value: false,
});

export const showApiKeyDeleteModal = () => ({
  type: Actions.TOGGLE_API_KEY_DELETE_MODAL,
  value: true,
});

export const hideApiKeyDeleteModal = () => ({
  type: Actions.TOGGLE_API_KEY_DELETE_MODAL,
  value: false,
});

export const hideApiKeyCreatedModal = () => ({
  type: Actions.TOGGLE_API_KEY_CREATED_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const updateApiKeyField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_API_KEY_FIELD,
  id,
  field,
  value,
});

export const create = (
  opts: {
    installer?: boolean;
    callback?: (response: { id?: number }) => void;
  } = {}
) => (dispatch: Dispatch<any>, getState: () => ApplicationState) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { installer, callback } = opts;
  const id = installer ? 'installer' : 'new';
  const payload = {
    ...getState().users.editById[id].fields,
    timeZone,
  };
  dispatch({
    type: 'API_POST',
    path: `${apiBase}`,
    payload: payload,
    loading: { type: Actions.CREATE_USER_LOADING, id },
    success: {
      type: Actions.CREATE_USER_SUCCESS,
      id,
      explicitlyCloseModal: installer,
    },
    error: { type: Actions.CREATE_USER_ERROR, id },
    callback,
  });
};

export const patch = (id: string) => (dispatch, getState) => {
  let sendingData: any;
  if (
    (getState() as ApplicationState).users.editById[id].fields.phone === '' ||
    (getState() as ApplicationState).users.editById[id].fields.phone === null
  ) {
    sendingData = R.omit(
      ['email', DEFAULT_HALT_KEYS],
      (getState() as ApplicationState).users.editById[id].fields
    );
  } else if (
    (getState() as ApplicationState).users.editById[id].fields.phone.length <= 6
  ) {
    sendingData = R.omit(
      ['email', DEFAULT_HALT_KEYS],
      (getState() as ApplicationState).users.editById[id].fields
    );
  } else {
    sendingData = R.omit(
      ['email'],
      (getState() as ApplicationState).users.editById[id].fields
    );
  }

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: sendingData,
    loading: { type: Actions.PATCH_USER_LOADING, id },
    success: { type: Actions.PATCH_USER_SUCCESS, id },
    error: { type: Actions.PATCH_USER_ERROR, id },
  });
};

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_USER_LOADING, id },
  success: { type: Actions.DELETE_USER_SUCCESS, id },
  error: { type: Actions.DELETE_USER_ERROR, id },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_USER,
  id,
});

export const addUserPosition = (position: string) => ({
  type: Actions.ADD_USER_POSITION,
  position,
});

// TODO: this is in the wrong location, should be in the basics actions
export const getUserPositions = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/working-positions',
  loading: Actions.GET_USER_POSITIONS_LOADING,
  success: Actions.GET_USER_POSITIONS_SUCCESS,
  error: Actions.GET_USER_POSITIONS_ERROR,
});

export const addScopeToUser = (
  userId: string,
  scope: { resourceId: string[]; role: string },
  callback?: (response: { id: number }) => void
) => ({
  type: 'API_POST',
  path: `${apiBase}/${userId}/scope`,
  payload: scope,
  loading: { type: Actions.ADD_USER_SCOPE_LOADING, id: userId },
  success: { type: Actions.ADD_USER_SCOPE_SUCCESS, id: userId },
  error: { type: Actions.ADD_USER_SCOPE_ERROR, id: userId },
  callback,
});

export const deleteScopeFromUser = (userId: string, scopeId) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${userId}/scope`,
  payload: { resourceId: scopeId },
  loading: { type: Actions.DELETE_USER_SCOPE_LOADING, id: userId },
  success: { type: Actions.DELETE_USER_SCOPE_SUCCESS, id: userId },
  error: { type: Actions.DELETE_USER_SCOPE_ERROR, id: userId },
});

export const updateUserScope = (userId: string, scope) => ({
  type: 'API_PUT',
  path: `${apiBase}/${userId}/scope`,
  payload: scope,
  loading: { type: Actions.PUT_USER_SCOPE_LOADING, id: userId },
  success: { type: Actions.PUT_USER_SCOPE_SUCCESS, id: userId },
  error: { type: Actions.PUT_USER_SCOPE_ERROR, id: userId },
});

export const addUserToGroup = (
  userId: number,
  groupId: number,
  callback?: (response: { id: number }) => void
) => ({
  type: 'API_PUT',
  path: `${apiBase}/${userId}/groups`,
  payload: {
    groupIds: [groupId],
  },
  success: { type: Actions.ADD_USER_TO_GROUP_SUCCESS, userId, groupId },
  loading: { type: Actions.ADD_USER_TO_GROUP_LOADING, userId, groupId },
  error: { type: Actions.ADD_USER_TO_GROUP_ERROR, userId, groupId },
  callback,
});

export const removeUserFromGroup = (userId: number, groupId: number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${userId}/groups`,
  payload: {
    groupIds: [groupId],
  },
  success: { type: Actions.REMOVE_USER_FROM_GROUP_SUCCESS, userId, groupId },
  loading: { type: Actions.REMOVE_USER_FROM_GROUP_LOADING, userId, groupId },
  error: { type: Actions.REMOVE_USER_FROM_GROUP_ERROR, userId, groupId },
});

export const getUserApiTokens = (userId: number) => ({
  type: 'API_GET',
  path: `${apiBase}/${userId}/apikeys/`,
  success: { type: Actions.GET_USER_API_KEYS_SUCCESS, userId },
  loading: { type: Actions.GET_USER_API_KEYS_LOADING, userId },
  error: { type: Actions.GET_USER_API_KEYS_ERROR, userId },
});

export const addApiTokenToUser = (userId: number) => (dispatch, getState) =>
  dispatch({
    type: 'API_PUT',
    path: `${apiBase}/${userId}/apikeys/`,
    payload: (getState() as ApplicationState).users.editApiKeyById['new']
      .fields,
    success: [
      { type: Actions.ADD_API_KEY_TO_USER_SUCCESS, userId },
      getUserApiTokens(userId),
    ],
    loading: { type: Actions.ADD_API_KEY_TO_USER_LOADING, userId },
    error: { type: Actions.ADD_API_KEY_TO_USER_ERROR, userId },
  });

export const removeApiTokenFromUser = (userId: number, id: number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${userId}/apikeys/${id}`,
  success: { type: Actions.REMOVE_API_KEY_FROM_USER_SUCCESS, id },
  loading: { type: Actions.REMOVE_API_KEY_FROM_USER_LOADING, id },
  error: { type: Actions.REMOVE_API_KEY_FROM_USER_ERROR, id },
});

export const createUserKioskSettings = (
  userId: number,
  payload: KioskSettings
) => ({
  type: 'API_POST',
  path: `${apiBase}/${userId}/kiosk`,
  payload: payload,
  loading: { type: Actions.USER_CREATE_KIOSK_SETTINGS_LOADING, id: userId },
  success: { type: Actions.USER_CREATE_KIOSK_SETTINGS_SUCCESS, id: userId },
  error: { type: Actions.USER_UPDATE_KIOSK_SETTINGS_ERROR, id: userId },
});

export const updateUserKioskSettings = (
  userId: number,
  payload: KioskSettings
) => ({
  type: 'API_PUT',
  path: `${apiBase}/${userId}/kiosk`,
  payload: payload,
  loading: { type: Actions.USER_UPDATE_KIOSK_SETTINGS_LOADING, id: userId },
  success: { type: Actions.USER_UPDATE_KIOSK_SETTINGS_SUCCESS, id: userId },
  error: { type: Actions.USER_UPDATE_KIOSK_SETTINGS_ERROR, id: userId },
});
