import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTemperatureRecommendationsBySiteId } from '../actions/analytics';
import { ApplicationState } from '../reducers';
import {
  EquipmentTemperatureRecommendation,
  SpaceTemperatureRecommendation,
} from '@energybox/react-ui-library/dist/types';
import {
  EquipmentSOP,
  SetPointRecommendation,
  TemperatureRecommendationBySiteId,
  TemperatureRecommendationBySiteIdResponse,
} from '../types/analytics';
import * as R from 'ramda';

export const useSetpointRecommendation = (
  siteId: string | number,
  date?: Date
): {
  setpointRecommendation: SetPointRecommendation[];
  predictionStartDate: string;
  predictionEndDate: string;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();

  const tempRecommendationBySiteId = useSelector<
    ApplicationState,
    TemperatureRecommendationBySiteId
  >(({ analytics }) => analytics.temperatureRecommendation);

  const data: {
    spaceId?: string;
    spaceName: string;
    comfortTemp: number;
    equipment: EquipmentSOP[];
    status: string;
  }[] = [];

  const bySpaceData: SpaceTemperatureRecommendation =
    tempRecommendationBySiteId[siteId]?.data?.by_space || {};
  const byEquipmentData: EquipmentTemperatureRecommendation =
    tempRecommendationBySiteId[siteId]?.data?.by_hvac_equipment || {};

  if (Object.keys(bySpaceData).length !== 0) {
    for (const key in bySpaceData) {
      data.push({
        spaceId: key,
        spaceName: bySpaceData[key].space_name,
        comfortTemp: bySpaceData[key].comfort_temp,
        equipment: [
          {
            equipmentName: bySpaceData[key].equipment,
          },
        ],
        status: bySpaceData[key].status,
      });
    }
  }

  if (Object.keys(byEquipmentData).length !== 0) {
    const tempData: any[] = [];
    for (const key in byEquipmentData) {
      tempData.push({
        spaceName: byEquipmentData[key].space_name,
        equipment: [
          {
            equipmentId: key,
            equipmentName: byEquipmentData[key].equipment_name,
            sop: byEquipmentData[key].sop,
          },
        ],
        comfortTemp: byEquipmentData[key].comfort_temp,
        status: byEquipmentData[key].status,
      });
    }
    const bySpace = R.groupBy(equip => equip.spaceName);
    const bySpaceEquipments = bySpace(tempData);
    const equipment: EquipmentSOP[] = [];
    for (const k in bySpaceEquipments) {
      const space = bySpaceEquipments[k];
      for (const i in space) {
        equipment.push(space[i].equipment[0]);
      }
      data.push({
        spaceName: space[0].spaceName,
        comfortTemp: space[0].comfortTemp,
        equipment,
        status: space[0].status,
      });
    }
  }

  useEffect(() => {
    if (!isDefined(tempRecommendationBySiteId[siteId])) {
      dispatch(getTemperatureRecommendationsBySiteId(siteId, date));
    }
  }, [tempRecommendationBySiteId]);

  return {
    setpointRecommendation: data || [],
    predictionStartDate:
      tempRecommendationBySiteId[siteId]?.data?.predict_day_start || '',
    predictionEndDate:
      tempRecommendationBySiteId[siteId]?.data?.predict_day_end || '',
    isLoading: tempRecommendationBySiteId[siteId]?.isLoading || false,
  };
};
