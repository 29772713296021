import {
  Button,
  Card,
  CardContent,
  CardTitle,
  Loader,
  Modal,
  Table,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import {
  Locale,
  Certification,
  CertificationTypes,
  ResourceType,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  genericTableSort,
  SORT_IGNORED_VALUES,
  formatIanaTimeZone,
} from '@energybox/react-ui-library/dist/utils';
import { parseISO } from 'date-fns';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Actions as CertificationAnctions,
  destroy as deleteCertification,
  getCertificateDownloadUrlById,
  getCertificatesOfResource,
  hideDeleteCertificationModal,
  showDeleteCertificationModal,
  showNewCertificationModal,
} from '../../actions/certifications';
import { getUsers } from '../../actions/users';
import { ApplicationState } from '../../reducers';
import {
  CertificateDownloadUrlById,
  CertificationIdsByResourceId,
  CertificationsById,
  EditCertificateById,
} from '../../reducers/certifications';
import { UsersById } from '../../reducers/users';
import { PropertyToLabel } from '../../types/global';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import { formatCertificateDate } from '../../utils/dates';
import styles from './CertificationsCard.module.css';
import NewCertificationModal from './NewCertificationModal';

interface OwnProps {
  resourceId?: string | number;
  resourceType?: ResourceType;
  certificationTypes?: CertificationTypes[];
  vendor?: string;
  uuid?: string;
  ianaTimeZoneCode: string;
}
interface Props extends OwnProps {
  locale: Locale;
  certificatesById: CertificationsById;
  idPrimedForDelete?: number | string;
  certificationIdsByResourceId: CertificationIdsByResourceId;
  certificateDownloadUrlById: CertificateDownloadUrlById;
  editCertificateById: EditCertificateById;
  showDeleteModal: boolean;
  usersById: UsersById;
  getUsers: typeof getUsers;
  deleteCertification: typeof deleteCertification;
  showNewCertificationModal: typeof showNewCertificationModal;
  showDeleteCertificationModal: typeof showDeleteCertificationModal;
  hideDeleteCertificationModal: typeof hideDeleteCertificationModal;
  getCertificatesOfResource: typeof getCertificatesOfResource;
  getCertificateDownloadUrlById: typeof getCertificateDownloadUrlById;
}

class CertificationsCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // Fetch certs
    const { resourceId, getCertificatesOfResource, getUsers } = this.props;
    getUsers();
    if (resourceId) {
      getCertificatesOfResource(resourceId);
    }
  }

  deleteModal = () => {
    const {
      certificatesById,
      idPrimedForDelete,
      editCertificateById,
      deleteCertification,
      hideDeleteCertificationModal,
    } = this.props;
    if (!idPrimedForDelete && idPrimedForDelete !== 0) return '';

    const actions = (
      <>
        <Button variant="text" onClick={hideDeleteCertificationModal}>
          Cancel
        </Button>
        <Button onClick={() => deleteCertification(idPrimedForDelete)}>
          Delete
        </Button>
      </>
    );
    const certificate =
      idPrimedForDelete || idPrimedForDelete === 0
        ? certificatesById[idPrimedForDelete]
        : undefined;
    const editCertificate =
      idPrimedForDelete || idPrimedForDelete === 0
        ? editCertificateById[idPrimedForDelete]
        : undefined;

    return (
      <Modal onClose={hideDeleteCertificationModal} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete{' '}
          {certificate ? (
            <b>{certificate.certificationId}</b>
          ) : (
            'this certificate'
          )}
          ?
        </p>
        {editCertificate &&
          renderAPIerror(
            editCertificate.apiError,
            CertificationAnctions.DELETE_CERTIFICATION_ERROR
          )}
      </Modal>
    );
  };

  renderTableOrText() {
    const {
      locale,
      resourceId,
      usersById,
      certificatesById,
      certificationIdsByResourceId,
      certificateDownloadUrlById,
      getCertificateDownloadUrlById,
      ianaTimeZoneCode,
    } = this.props;

    if (!resourceId) return '';

    const certifIds = certificationIdsByResourceId[resourceId];

    if (!certifIds) return '';
    if (certifIds.isLoading) return 'Loading...';
    if (
      !certifIds.certificationIds ||
      certifIds?.certificationIds?.length === 0
    )
      return 'No certificates found.';

    const certifications = certifIds.certificationIds
      .map(id => certificatesById[id])
      .filter(c => c);

    const columns = [
      {
        header: PropertyToLabel.certificationBody,
        cellContent: (certificate: Certification) => (
          <span>{certificate.certificationBody}</span>
        ),
      },
      {
        header: PropertyToLabel.certificationId,
        cellContent: (certificate: Certification) => (
          <span>{certificate.certificationId}</span>
        ),
      },
      {
        header: PropertyToLabel.certificationCompany,
        cellContent: (certificate: Certification) => (
          <span>{certificate.certificationCompany}</span>
        ),
      },
      {
        header: PropertyToLabel.validFrom,
        comparator: (
          a: Certification,
          b: Certification,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(
            a.validFrom,
            b.validFrom,
            sortDirection,
            SORT_IGNORED_VALUES,
            parseISO
          );
        },
        cellContent: (certificate: Certification) => (
          <span>
            {formatCertificateDate(certificate.validFrom, locale.dateFormat)}
          </span>
        ),
      },
      {
        header: PropertyToLabel.validTo,
        comparator: (
          a: Certification,
          b: Certification,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(
            a.validTo,
            b.validTo,
            sortDirection,
            SORT_IGNORED_VALUES,
            parseISO
          );
        },
        cellContent: (certificate: Certification) => (
          <span>
            {formatCertificateDate(certificate.validTo, locale.dateFormat)}
          </span>
        ),
      },
      {
        header: 'Download File',
        cellContent: (certificate: Certification) =>
          certificateDownloadUrlById[certificate.id] ? (
            certificateDownloadUrlById[certificate.id].isLoading ? (
              <Loader size={5} />
            ) : (
              <a
                target="_blank"
                href={certificateDownloadUrlById[certificate.id].url}
              >
                Download Certificate
              </a>
            )
          ) : (
            <span
              className={styles.generateLink}
              onClick={() => getCertificateDownloadUrlById(certificate.id)}
            >
              Generate
            </span>
          ),
      },
      {
        header: 'Added By',
        cellContent: (certificate: Certification) => {
          const user = usersById[certificate.createdBy];
          if (!!user) {
            return <Link to={`/users/${user.id}`}>{user.email}</Link>;
          } else {
            return global.NOT_AVAILABLE;
          }
        },
      },
      {
        header: 'Created At',
        defaultSortDirection: SortDirection.DESC,
        isDefaultSort: true,
        comparator: (
          a: Certification,
          b: Certification,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(
            a.createdAt,
            b.createdAt,
            sortDirection,
            SORT_IGNORED_VALUES,
            parseISO
          );
        },
        cellContent: (certificate: Certification) => (
          <span>
            {formatIanaTimeZone(
              parseISO(certificate.createdAt),
              locale.dateTimeFormat,
              ianaTimeZoneCode,
              true
            )}
          </span>
        ),
      },
      {
        header: '',
        cellContent: (certificate: Certification) => (
          <MenuDropdown>
            <MenuDropdownItem
              isRed
              onSelect={this.props.showDeleteCertificationModal.bind(
                this,
                certificate.id
              )}
            >
              Delete
            </MenuDropdownItem>
          </MenuDropdown>
        ),
      },
    ];

    return (
      <Table
        className={styles.tableStyle}
        columns={columns}
        data={certifications}
      />
    );
  }

  render() {
    const {
      showNewCertificationModal,
      showDeleteModal,
      vendor,
      uuid,
      ianaTimeZoneCode,
    } = this.props;
    return (
      <>
        <Card>
          <CardContent>
            <div className={styles.root}>
              <div className={styles.header}>
                <CardTitle className={styles.cardTitleExtraPadding}>
                  Calibration Certificates
                </CardTitle>
                <Button
                  onClick={() =>
                    showNewCertificationModal(
                      CertificationTypes.TEMPERATURE_PROBE_CALIBRATION,
                      uuid,
                      vendor
                    )
                  }
                >
                  New Certificate
                </Button>
              </div>
              <div className={styles.body}>{this.renderTableOrText()}</div>
            </div>
          </CardContent>
        </Card>
        <NewCertificationModal
          resourceId={this.props.resourceId}
          lockUuid={true}
          lockVendor={true}
        />
        {showDeleteModal && this.deleteModal()}
      </>
    );
  }
}

const mapStateToProps = ({ certifications, users, app }: ApplicationState) => ({
  locale: app.locale,
  usersById: users.usersById,
  idPrimedForDelete: certifications.idPrimedForDelete,
  editCertificateById: certifications.editCertificateById,
  certificatesById: certifications.certificationsById,
  certificationIdsByResourceId: certifications.certificationIdsByResourceId,
  certificateDownloadUrlById: certifications.certificateDownloadUrlById,
  showDeleteModal: certifications.showDeleteCertificateModal,
});

const mapDispatchToProps = {
  getUsers,
  showNewCertificationModal,
  showDeleteCertificationModal,
  hideDeleteCertificationModal,
  getCertificatesOfResource,
  getCertificateDownloadUrlById,
  deleteCertification,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificationsCard);
