import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { EnergyProCt } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getEnergyProCts } from '../../actions/app';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  onSelect: (energyProCtId: string) => void;
  value?: string;
  error: boolean;
  disabled?: boolean;
  noBottomLine?: boolean;
  customErrorText?: string;
}

interface Props extends OwnProps {
  getEnergyProCts: typeof getEnergyProCts;
  energyProCts?: EnergyProCt[];
}

interface State {
  search: string;
}

class SelectEnergyProCt extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { energyProCts, getEnergyProCts } = this.props;
    if (!energyProCts) getEnergyProCts();
  }

  render() {
    const {
      energyProCts,
      value,
      onSelect,
      error,
      disabled,
      noBottomLine = false,
      customErrorText,
    } = this.props;

    if (!energyProCts) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;

      const listedEnergyProCts = (energyProCts || []).filter(
        (eq: EnergyProCt) => {
          if (search.length <= 2) return true;
          return hasSubstr(eq.title, search);
        }
      );

      const selectedEnergyProCt = energyProCts.find(eq => eq.id === value);

      return (
        <Select
          disabled={disabled}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedEnergyProCt?.title}
          value={selectedEnergyProCt?.id}
          error={error}
          noBottomLine={noBottomLine}
          customErrorText={customErrorText}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
          />
          {listedEnergyProCts.map((eq: EnergyProCt) => (
            <SelectItem
              key={eq.id}
              isSelected={eq.id === selectedEnergyProCt?.id}
              onSelect={() => onSelect(eq.id)}
            >
              {eq.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  energyProCts: app.energyProCts,
});

const mapDispatchToProps = {
  getEnergyProCts,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectEnergyProCt);
