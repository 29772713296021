import {
  Site,
  UserRole,
  User,
  UserGroup,
  Sentinel,
} from '@energybox/react-ui-library/dist/types';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchEquipment } from '../actions/equipmentFetch';
import { fetchGateways } from '../actions/gatewaysFetch';
import { openModal } from '../actions/modal';
import { fetchSensors } from '../actions/sensorsFetch';
import {
  createSentinel,
  getSentinelById,
  updateSentinel,
} from '../actions/sentinels';
import { getSites } from '../actions/sites';
import { fetchSpaces } from '../actions/spacesFetch';
import { getUsers } from '../actions/users';
import { getGroups as getUserGroups } from '../actions/user_groups';
import SentinelWizard from '../components/SentinelWizard';
import history from '../history';
import { ApplicationState } from '../reducers';
import { StepType } from '../types/sentinelWizard';

type Props = {
  getSentinelById: typeof getSentinelById;
  sentinel: Sentinel;
  userRole: UserRole;
  getUsers: typeof getUsers;
  getUserGroups: typeof getUserGroups;
  getSites: typeof getSites;
  users: User[];
  userGroups: UserGroup[];
  step?: StepType;
  id: string;
  sites: Site[];
  createSentinel: typeof createSentinel;
  updateSentinel: typeof updateSentinel;
  openModal: typeof openModal;
};

function ConnectedSentinelWizard(props: Props) {
  const {
    id,
    sentinel,
    step = StepType.NONE,
    userRole,
    getSentinelById,
    getUsers,
    getUserGroups,
    getSites,
    createSentinel,
    updateSentinel,
    users = [],
    userGroups,
    sites = [],
    openModal,
  } = props;

  useEffect(() => {
    if (id && id !== 'new') {
      getSentinelById(id);
    }
    getUsers();
    getUserGroups();
    getSites();
  }, [id, getSentinelById, getUsers, getUserGroups, getSites]);

  // wait until sentinel was loaded
  if (id !== 'new' && !sentinel) {
    return null;
  }

  if (id !== 'new' && sentinel && step === StepType.NONE) {
    history.push(`/sentinels/${id}/${StepType.condition}`);
    return null;
  }

  if (id === 'new' && step === StepType.NONE) {
    history.push(`/sentinels/new/${StepType.type}`);
    return null;
  }

  return (
    <SentinelWizard
      id={id}
      sentinel={sentinel}
      step={step}
      userRole={userRole}
      users={users}
      userGroups={userGroups}
      sites={sites}
      createSentinel={createSentinel}
      updateSentinel={updateSentinel}
      openModal={openModal}
      fetchEquipment={fetchEquipment}
      fetchSpaces={fetchSpaces}
      fetchGateways={fetchGateways}
      fetchSensors={fetchSensors}
    />
  );
}

const mapStateToProps = (
  { app, sentinels, users, userGroups, sites }: ApplicationState,
  ownProps: { id: string }
) => ({
  sentinel: sentinels.byId[ownProps.id],
  userRole: app.currentUser?.role ?? UserRole.VIEWER,
  users: Object.values(users.usersById),
  userGroups: Object.values(userGroups.userGroupsById),
  sites: Object.values(sites.sitesById),
});

const mapDispatchToProps = (dispatch: any) => ({
  getSentinelById: id => dispatch(getSentinelById(id)),
  getUsers: () => dispatch(getUsers()),
  getUserGroups: () => dispatch(getUserGroups()),
  getSites: () => dispatch(getSites()),
  createSentinel: sentinel => dispatch(createSentinel(sentinel)),
  updateSentinel: sentinel => dispatch(updateSentinel(sentinel)),
  openModal: modal => dispatch(openModal(modal)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSentinelWizard);
