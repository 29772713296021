import { ApplicationState } from '../reducers';
const apiBase = '/api/v1/certifications';
import { omit } from 'ramda';

export enum Actions {
  CREATE_CERTIFICATION_LOADING = '@certification/CREATE_CERTIFICATION_LOADING',
  CREATE_CERTIFICATION_SUCCESS = '@certification/CREATE_CERTIFICATION_SUCCESS',
  CREATE_CERTIFICATION_ERROR = '@certification/CREATE_CERTIFICATION_ERROR',

  GET_CERTIFICATIONS_OF_RESOURCE_LOADING = '@certification/GET_CERTIFICATIONS_OF_RESOURCE_LOADING',
  GET_CERTIFICATIONS_OF_RESOURCE_SUCCESS = '@certification/GET_CERTIFICATIONS_OF_RESOURCE_SUCCESS',
  GET_CERTIFICATIONS_OF_RESOURCE_ERROR = '@certification/GET_CERTIFICATIONS_OF_RESOURCE_ERROR',

  GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_LOADING = '@certification/GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_LOADING',
  GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_SUCCESS = '@certification/GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_SUCCESS',
  GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_ERROR = '@certification/GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_ERROR',

  DELETE_CERTIFICATION_LOADING = '@certification/DELETE_CERTIFICATION_LOADING',
  DELETE_CERTIFICATION_SUCCESS = '@certification/DELETE_CERTIFICATION_SUCCESS',
  DELETE_CERTIFICATION_ERROR = '@certification/DELETE_CERTIFICATION_ERROR',

  TOGGLE_NEW_CERTIFICATION_MODAL = '@certifications/TOGGLE_NEW_CERTIFICATION_MODAL',
  TOGGLE_DELETE_CERTIFICATION_MODAL = '@certifications/TOGGLE_DELETE_CERTIFICATION_MODAL',

  UPDATE_FIELD = '@certifications/UPDATE_FIELD',
  DISPLAY_FORM_ERRORS = '@certifications/DISPLAY_FORM_ERRORS',
}

export const showNewCertificationModal = (
  certificationType,
  deviceUuid,
  deviceVendor
) => ({
  type: Actions.TOGGLE_NEW_CERTIFICATION_MODAL,
  value: true,
  deviceUuid,
  deviceVendor,
  certificationType,
});

export const hideNewCertificationModal = () => ({
  type: Actions.TOGGLE_NEW_CERTIFICATION_MODAL,
  value: false,
});

export const showDeleteCertificationModal = (id: string | number) => ({
  type: Actions.TOGGLE_DELETE_CERTIFICATION_MODAL,
  value: true,
  id,
});

export const hideDeleteCertificationModal = () => ({
  type: Actions.TOGGLE_DELETE_CERTIFICATION_MODAL,
  value: false,
});

export const displayFormErrors = () => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
});

export const updateField = (field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  field,
  value,
});

export const getCertificatesOfResource = (resourceId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/device/${resourceId}`,
  loading: { type: Actions.GET_CERTIFICATIONS_OF_RESOURCE_LOADING, resourceId },
  success: { type: Actions.GET_CERTIFICATIONS_OF_RESOURCE_SUCCESS, resourceId },
  error: { type: Actions.GET_CERTIFICATIONS_OF_RESOURCE_ERROR, resourceId },
});

export const create = (resourceId?: string | number) => (
  dispatch,
  getState
) => {
  const fields = (getState() as ApplicationState).certifications.newCertificate
    .fields;

  const file = fields.certificate;

  const payload = new FormData();

  payload.append('file', file as Blob);
  payload.append('metadata', JSON.stringify(omit(['certificate'], fields)));

  const successActions: any[] = [
    { type: Actions.CREATE_CERTIFICATION_SUCCESS },
  ];

  // TS is dumb and can't figure out how to properly type this thus why it's being put into any[];
  if (resourceId || resourceId === 0)
    successActions.push(getCertificatesOfResource(resourceId));

  dispatch({
    type: 'API_POST',
    path: apiBase,
    payload,
    ignoreContentType: true,
    loading: Actions.CREATE_CERTIFICATION_LOADING,
    success: successActions,
    error: Actions.CREATE_CERTIFICATION_ERROR,
  });
};

export const destroy = (certificateId: string | number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${certificateId}`,
  loading: { type: Actions.DELETE_CERTIFICATION_LOADING, certificateId },
  success: { type: Actions.DELETE_CERTIFICATION_SUCCESS, certificateId },
  error: { type: Actions.DELETE_CERTIFICATION_ERROR, certificateId },
});

export const getCertificateDownloadUrlById = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/certificationUrl/${id}`,
  loading: {
    type: Actions.GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_LOADING,
    id,
  },
  success: {
    type: Actions.GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_SUCCESS,
    id,
  },
  error: {
    type: Actions.GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_ERROR,
    id,
  },
});
