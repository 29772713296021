export function handlePlatformSwitch(
  e,
  accessToken,
  baseUrl,
  openInNewPage = false
) {
  e.preventDefault(); // Prevent default link behavior
  const redirectUrl = `${baseUrl}#accessToken=${accessToken}`;
  if (openInNewPage) {
    window.open(redirectUrl, '_blank');
  } else {
    window.location.replace(redirectUrl); // Use replace to prevent adding it to the history stack
  }
}
