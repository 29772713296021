import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import styles from '../HvacControls/HvacControls.module.css';
import { PageContentHeader } from '../../../components/Page';
import { classNames, hasSubstr } from '@energybox/react-ui-library/dist/utils';
import { IoIosArrowForward } from 'react-icons/io';
import {
  Loader,
  PageSelectControl,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import {
  EquipmentTypesById,
  WorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { useCurrentOrganizationId } from '../../../hooks/useCurrentOrganization';
import { getOrganizationHvacData } from '../../../actions/sops';
import { SiteHvacData } from '../../../reducers/organizations';
import { useEquipmentTypesById } from '../../../hooks/useEquipmentTypes';
import { Placeholder } from '../../../types/global';
import theme from '../../../theme';
import { useUrlState } from '../../../hooks/utils';
import HvacControlsTable from './HvacControlsTable';
import { useGetSites } from '../../../hooks/useSites';
import { useCurrentUser } from '../../../hooks/useAppDetails';
import { getNetworkGroupsByOrgId } from '../../../actions/network_groups';
import { getWeatherByMultiSitesIds } from '../../../actions/weather';
import NewOrEditHvacSopModal from '../../Sop/NewOrEditHvacSopModal';
import { useHvacSopDataToEdit } from '../../../components/EditHvacSopForm/EditHvacSopForm';
import FiltersContainer from '../../../components/Filters/FiltersContainer/FiltersContainer';
import SiteFilter from '../../../components/SiteFilter';
import SiteGroupFilter from '../../../components/SiteGroupFilter';
import EquipmentMenuFilter from '../../../components/Filters/EquipmentMenuFilter';
import ControlModeFilter from '../../../components/ControlModeFilter';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import useSiteFilter from '../../../hooks/useSiteFilter';
import useDynamicFilter from '../../../hooks/useDynamicFilter';
import { handleScrollToTop } from '../../../utils/string';
import usePaginationFilter from '../../../hooks/usePaginationFilter';
import { filterBySiteGroups } from '../../../utils/siteGroups/siteGroups';
import { filteredData } from '../../../utils/filters';
import TopPagination from '../../../utils/TopPagination/TopPagination';
import { NewDashboard } from '@energybox/react-ui-library/dist/icons';
import { handlePlatformSwitch } from '../../../utils/platformRedirection';

//this page will not use siteID's, equipment types inside equipments array of response
// as the BE need to cleanup the endpoint by removing them.

const HvacControls = () => {
  //hook calls
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const orgId = useCurrentOrganizationId();
  const [count, setCount] = useState(0);
  const { sitesById } = useGetSites();
  const allEquipmentTypes = useEquipmentTypesById();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();
  const { selectedSiteFilters } = useSiteFilter();
  const {
    selectedSiteGroups,
    selectedSitesBySiteGroups,
  } = useSiteGroupsFilter();
  const { selectedFilters: equipmentTypeFilters } = useDynamicFilter<number>(
    'eqType',
    value => parseInt(value)
  );
  const { selectedFilters: controlModeFilters } = useDynamicFilter<
    WorkingModeLabel[]
  >('controlMode');

  const [query, setQuery] = useUrlState<string>('query', '');
  const [equipmentAffectedCount, setEquipmentAffectedCount] = useState(0);

  const controllableHvacTypes: EquipmentTypesById = Object.entries(
    allEquipmentTypes
  ).reduce((result, [id, equipment]) => {
    if (equipment.controllable) {
      result[id] = equipment;
    }
    return result;
  }, {});

  const orgHvacData = useSelector(({ sops }: ApplicationState) => {
    return sops.orgHvacData;
  });
  const isOrgHvacLoading = useSelector(({ sops }: ApplicationState) => {
    return sops.isOrgHvacLoading;
  });
  const controlsData: SiteHvacData[] = Object.values(orgHvacData);

  const siteIdList = controlsData.map(site => {
    return Number(site.siteId);
  });

  const isModalVisible = useSelector<ApplicationState, boolean>(({ sops }) => {
    return sops.showNewOrEditHvacSopModal;
  });

  const accessToken = useSelector(({ app }: ApplicationState) => {
    return app.accessToken;
  });
  const sopToEdit = useHvacSopDataToEdit();
  const siteIdToEdit = sopToEdit?.fields.organizationUnitId;
  const currentUrl = `${location.search}${location.hash}`;
  const platformSwitchUrl = `${process.env.REACT_APP_PLATFORM_SWITCH_URL}/hvac-controls-operations${currentUrl}`;

  const PageHeader = (
    <div className={styles.alignHeader}>
      <span className={styles.mainHead}> Management </span>
      <a
        onClick={e => {
          handlePlatformSwitch(e, accessToken, platformSwitchUrl, true);
        }}
        href={platformSwitchUrl}
        className={classNames(styles.linkTo, styles.leftContainer)}
        target="_blank"
      >
        <NewDashboard size={24} />
        {'Control Operations'}
        <IoIosArrowForward size={15} />
      </a>
    </div>
  );

  //Pagination Logic
  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    controlsData.length
  );
  const startIndex = (currentPage - 1) * rowLimit;
  const endIndex = startIndex + rowLimit;

  //Filters Logic
  const filterByQuickSearch = data => {
    return data.filter(siteData => hasSubstr(`${siteData.title}`, query));
  };
  const filterBySites = data => {
    return data.filter(equipmentData => {
      return selectedSiteFilters.includes(Number(equipmentData.siteId));
    });
  };
  const filterByEquipmentType = data => {
    return data
      .map(equipmentData => {
        const filteredEquipment = equipmentData.equipment?.filter(
          ({ equipment }) => {
            return equipmentTypeFilters.includes(equipment.typeId);
          }
        );

        if (filteredEquipment.length > 0) {
          const filteredSiteData = { ...equipmentData };
          filteredSiteData.equipment = filteredEquipment;
          return filteredSiteData;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const filterByWorkingMode = data => {
    return data
      .map(equipmentData => {
        const filteredEquipment = equipmentData.equipment?.filter(
          ({ equipment }) => {
            return controlModeFilters.includes(
              WorkingModeLabel?.[equipment.activeControl?.workingMode!]
            );
          }
        );
        if (filteredEquipment.length > 0) {
          const filteredSiteData = { ...equipmentData };
          filteredSiteData.equipment = filteredEquipment;
          return filteredSiteData;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const filteredCardData = filteredData(
    controlsData,
    query,
    selectedSiteFilters,
    equipmentTypeFilters,
    controlModeFilters,
    selectedSiteGroups,
    selectedSitesBySiteGroups,
    filterByQuickSearch,
    filterBySites,
    filterByEquipmentType,
    filterByWorkingMode,
    filterBySiteGroups
  );

  const filteredHvacData = filteredCardData
    .sort((a, b) => {
      return a.title.localeCompare(b.title);
    })
    .slice(startIndex, endIndex);

  useEffect(() => {
    if (siteIdList.length && count < 1) {
      dispatch(getWeatherByMultiSitesIds(siteIdList));
      setCount(1);
    }
  }, [siteIdList]);

  useEffect(() => {
    if (orgId) {
      dispatch(getNetworkGroupsByOrgId(orgId!));
      dispatch(getOrganizationHvacData(orgId));
    }
  }, [orgId]);

  const handleSearchChange = (query: string) => {
    setQuery(query);
  };

  return (
    <>
      <PageContentHeader header={PageHeader}>
        <SearchBox
          placeholder={Placeholder.seachBox}
          onChange={handleSearchChange}
          query={query}
          width={theme.size.table.searchBox.web}
          widthActive={theme.size.table.searchBox.web}
          error={filteredCardData?.length === 0}
        />
      </PageContentHeader>
      <div className={styles.filtersContainer} id={'HvacControls'}>
        <FiltersContainer>
          <SiteFilter />
          <SiteGroupFilter />
          <EquipmentMenuFilter label="Equipment Type" isOrglevelHvac={true} />
          <ControlModeFilter />
        </FiltersContainer>
      </div>
      {siteGroupWithoutSites ? (
        <></>
      ) : (
        <>
          <TopPagination
            data={filteredCardData}
            setPagination={setPagination}
            rowLimit={rowLimit}
            currentPage={currentPage}
            numberOfSites={filteredHvacData.length}
          />

          {controlsData.length > 0
            ? filteredHvacData.map(item => {
                const { siteId, equipment, sop, networkGroupIds } = item;
                return (
                  <HvacControlsTable
                    controllableHvacTypes={controllableHvacTypes}
                    equipment={equipment}
                    sop={sop}
                    siteId={siteId}
                    sitesById={sitesById}
                    networkGroupIds={networkGroupIds}
                    user={user}
                    orgId={orgId!}
                    setEquipmentAffectedCount={setEquipmentAffectedCount}
                  />
                );
              })
            : isOrgHvacLoading === true && (
                <div className={styles.Loader}>
                  <Loader />
                </div>
              )}
        </>
      )}
      <div className={styles.pagination}>
        {filteredCardData.length > 0 && !siteGroupWithoutSites && (
          <PageSelectControl
            pageCount={Math.ceil(filteredCardData.length / rowLimit)}
            currentPage={currentPage}
            setCurrentPage={currentPage => {
              setPagination(currentPage, rowLimit);
              handleScrollToTop('HvacControls');
            }}
          ></PageSelectControl>
        )}
      </div>

      {isModalVisible && siteIdToEdit && (
        <NewOrEditHvacSopModal
          organizationUnitId={siteIdToEdit.toString()}
          equipmentAffectedCount={equipmentAffectedCount}
          isOrgLevelEdit={true}
        />
      )}
    </>
  );
};

export default HvacControls;
