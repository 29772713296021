import {
  Button,
  InputField,
  Label,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';
import {
  Equipment,
  GatewayVendorLabel,
  GenericErrors,
  Sensor,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SensorActions,
  create,
  patchDummySensor,
  displayFormErrors,
  getPreloadedSensorsBySiteId,
  hideNewSensorModal,
  updateField,
} from '../../actions/sensors';
import styles from './NewSensorModal.module.css';
import ModalFormContent from '../../components/ModalFormContent';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/sensors';
import { CreateNewText, PropertyToLabel } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import SelectEquipment from '../Selects/SelectEquipment';
import SelectSite from '../Selects/SelectSite';
import SelectSpace from '../Selects/SelectSpace';
import SelectVendor from '../Selects/SelectVendor';
import LookupWhitelistedButton from '../../components/LookupWhitelistedButton/LookupWhitelistedButton';

enum AttachedTo {
  EQUIPMENT = 'Equipment',
  SPACE = 'Space',
}

type OwnProps = {
  defaultSiteId?: number;
  sensorUuid?: string;
  isSuperHubPage?: boolean;
};

interface Props extends OwnProps {
  isVisible: boolean;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onCreate: (id: number) => void;
  onPatch: (id: string) => void;
  formErrors: GenericErrors;
  fields: EditableFields;
  isLoading: boolean;
  disabled?: boolean;
  equipmentId?: number;
  apiError: ApiError;
  displayFormErrors: typeof displayFormErrors;
  formErrorsVisible: boolean;
  lockSiteId?: number;
  preselectedFields?: EditableFields;
  getPreloadedSensorsBySiteId: (siteId: string | number) => void;
  preloadedSensorsBySite: Sensor[];
}
interface State {
  siteId: number;
  attachedTo: AttachedTo;
  formattedUuid: string;
  preloadedEquipment?: Equipment;
  selectedDummySensorId?: number;
}
// TODO this thing is a mess, we need to clean it up.
// The complications start when you're limiting to an equipment vs limit to a site.

class NewSensorModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      siteId: props.lockSiteId || props.defaultSiteId || -1,
      attachedTo: AttachedTo.EQUIPMENT,
      formattedUuid: !!props.preselectedFields?.uuid
        ? props.preselectedFields.uuid
        : props.fields?.uuid || props.sensorUuid || '',
    };
  }

  componentDidMount() {
    const { defaultSiteId } = this.props;

    if (defaultSiteId) {
      this.setState({ siteId: defaultSiteId });
      this.props.getPreloadedSensorsBySiteId(defaultSiteId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      defaultSiteId,
      disabled,
      onChange,
      equipmentId,
      fields,
      sensorUuid,
      isSuperHubPage,
    } = this.props;
    if (
      this.props?.fields?.uuid === undefined &&
      this.state.formattedUuid &&
      this.props?.preselectedFields?.uuid === undefined &&
      this.props.sensorUuid === undefined
    ) {
      this.setState({ formattedUuid: '' });
    }

    if (!prevProps.isVisible && this.props.isVisible) {
      const siteId =
        this.props.lockSiteId || this.props.defaultSiteId || this.state.siteId;
      this.props.getPreloadedSensorsBySiteId(siteId);

      if (isSuperHubPage) {
        this.setState({
          preloadedEquipment: undefined,
          selectedDummySensorId: undefined,
          siteId: siteId,
        });
      }
    }

    if (defaultSiteId && defaultSiteId !== prevProps.defaultSiteId) {
      this.setState({ siteId: defaultSiteId });
    }
    if (disabled && fields && fields.resourceId === -1) {
      onChange('resourceId', equipmentId);
    }
    if (isSuperHubPage && prevProps.sensorUuid !== this.props.sensorUuid) {
      onChange('uuid', sensorUuid);
      onChange('vendor', GatewayVendorLabel.energybox.toLowerCase());
    }
    if (!prevProps.isVisible && this.props.isVisible) {
      const siteId =
        this.props.lockSiteId || this.props.defaultSiteId || this.state.siteId;
      this.props.getPreloadedSensorsBySiteId(siteId);
    }
  }

  onSensorCreate = () => {
    const {
      fields,
      lockSiteId,
      formErrors,
      displayFormErrors,
      onCreate,
      onPatch,
      isSuperHubPage,
      onClose,
    } = this.props;

    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      if (isSuperHubPage && this.state.selectedDummySensorId) {
        onPatch(this.state.selectedDummySensorId.toString());
        onClose();
      } else {
        onCreate(lockSiteId || fields.resourceId);
      }
    }
  };

  onSensorModalCancel = () => {
    if (!this.props.disabled) {
      this.setState({ siteId: -1 });
    }

    if (this.props.isSuperHubPage) {
      this.setState({
        preloadedEquipment: undefined,
        selectedDummySensorId: undefined,
      });
    }

    this.setState({ formattedUuid: '' });
    this.props.onClose();
  };

  onRadioButtonClick = (newValue: AttachedTo) => {
    if (!this.props.disabled) {
      this.props.onChange('resourceId', -1);
      this.setState({ attachedTo: newValue });
    }
  };

  onUUIDChange = (value: string) => {
    const { onChange, isSuperHubPage, sensorUuid } = this.props;
    if (isSuperHubPage && typeof sensorUuid === 'string') {
      this.setState({ formattedUuid: sensorUuid });
      onChange('uuid', sensorUuid);
    } else {
      const formattedUuid =
        value
          .replace(/[^0-9A-Fa-f]/g, '')
          ?.slice(0, 12)
          ?.toUpperCase()
          ?.match(/.{1,2}/g)
          ?.join(':') || '';
      this.setState({ formattedUuid });
      onChange('uuid', formattedUuid);
    }
  };

  onDeviceSelect = (selectedUuid: string) => {
    const { onChange, sensorUuid, isSuperHubPage } = this.props;
    const selectedSensor = this.props.preloadedSensorsBySite.find(
      sensor => sensor.uuid === selectedUuid
    );
    if (selectedSensor) {
      const uuidToUse =
        isSuperHubPage && typeof sensorUuid === 'string'
          ? sensorUuid
          : selectedSensor.uuid;

      this.onUUIDChange(uuidToUse);
      onChange('title', selectedSensor.title);
      onChange(
        'vendor',
        isSuperHubPage
          ? GatewayVendorLabel.energybox.toLowerCase()
          : selectedSensor.vendor
      );
      if (selectedSensor.resource) {
        onChange('resourceId', selectedSensor.resource.id);
        this.setState({
          attachedTo: AttachedTo.EQUIPMENT,
          siteId: this.state.siteId,
          preloadedEquipment: selectedSensor.resource as Equipment,
          selectedDummySensorId: selectedSensor.id,
        });
      }
    }
  };

  render() {
    if (!this.props.isVisible) return null;

    const {
      onClose,
      onChange,
      isLoading,
      formErrors,
      formErrorsVisible,
      fields,
      disabled,
      defaultSiteId,
      equipmentId,
      apiError,
      lockSiteId,
      preselectedFields,
      isSuperHubPage,
      sensorUuid,
    } = this.props;

    const { title, description, uuid, vendor, resourceId } = fields;
    const { siteId, attachedTo, formattedUuid } = this.state;

    const currentVendor = isSuperHubPage
      ? GatewayVendorLabel.energybox.toLowerCase()
      : vendor;

    const devices = this.props.preloadedSensorsBySite
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(sensor => ({
        uuid: sensor.uuid,
        title: sensor.title,
      }));

    const actions = (
      <>
        <Button variant="text" onClick={this.onSensorModalCancel}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={this.onSensorCreate}>
          {isLoading ? <Loader size={12} /> : 'Add'}
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
        <ModalTitle>{CreateNewText.SENSOR}</ModalTitle>
        <ModalContent>
          <ModalFormContent>
            <div>
              <Label required> Sensor name</Label>
            </div>
            <div className={styles.inputButtonContainer}>
              <div className={styles.inputContainer}>
                <InputField
                  type="text"
                  name="title"
                  autoComplete="title"
                  value={title}
                  onChange={e => onChange('title', e.currentTarget.value)}
                  error={formErrorsVisible && !!formErrors.title}
                  customErrorText={formErrors.title && formErrors.title[0]}
                />
              </div>
              {isSuperHubPage && (
                <div>
                  <LookupWhitelistedButton
                    dropdownTitle=""
                    devices={devices}
                    currentUuid={sensorUuid}
                    onDeviceClick={this.onDeviceSelect}
                    buttonLabel="Lookup Sensor"
                    onButtonClick={() => {
                      const siteId =
                        this.props.lockSiteId ||
                        this.props.defaultSiteId ||
                        this.state.siteId;
                      this.props.getPreloadedSensorsBySiteId(siteId);
                    }}
                    isLoading={false}
                    active={true}
                    isSuperHubWhitelist={true}
                  />
                </div>
              )}
            </div>

            <div>
              <Label>{PropertyToLabel.description}</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="description"
                value={description}
                autoComplete="description"
                onChange={e => onChange('description', e.currentTarget.value)}
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.uuid}</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="uuid"
                value={isSuperHubPage ? sensorUuid : formattedUuid}
                autoComplete="uuid"
                onChange={e => this.onUUIDChange(e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.uuid}
                customErrorText={formErrors.uuid && formErrors.uuid[0]}
                disabled={!!preselectedFields?.uuid || isSuperHubPage}
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.vendor}</Label>
            </div>
            <div>
              <SelectVendor
                onSelect={value => onChange('vendor', value)}
                value={currentVendor}
                error={formErrorsVisible && !!formErrors.vendor}
                customErrorText={formErrors.vendor && formErrors.vendor[0]}
                disabled={isSuperHubPage}
              />
            </div>
          </ModalFormContent>

          <ModalFormContent>
            <div>
              <Label required>{PropertyToLabel.siteId}</Label>
            </div>
            <div>
              <SelectSite
                disabled={
                  !!lockSiteId || disabled || !!preselectedFields?.resourceId
                }
                onSelect={value => {
                  this.setState({ siteId: value });
                  onChange('resourceId', -1);
                }}
                value={
                  !!preselectedFields?.resourceId
                    ? preselectedFields.resourceId
                    : disabled
                    ? defaultSiteId
                    : siteId
                }
              />
            </div>

            <div>
              <Label>Attached To</Label>
            </div>
            <RadioGroup>
              <RadioButton
                label={AttachedTo.EQUIPMENT}
                value={AttachedTo.EQUIPMENT}
                checked={attachedTo === AttachedTo.EQUIPMENT}
                onChange={() => this.onRadioButtonClick(AttachedTo.EQUIPMENT)}
              />
              {disabled ? null : (
                <RadioButton
                  label={AttachedTo.SPACE}
                  value={AttachedTo.SPACE}
                  checked={attachedTo === AttachedTo.SPACE}
                  onChange={() => this.onRadioButtonClick(AttachedTo.SPACE)}
                />
              )}
            </RadioGroup>

            {attachedTo === AttachedTo.EQUIPMENT ? (
              <>
                <div>
                  <Label>Equipment</Label>
                </div>
                <div>
                  <SelectEquipment
                    onSelect={value => onChange('resourceId', value)}
                    value={
                      isSuperHubPage
                        ? resourceId
                        : disabled
                        ? equipmentId
                        : resourceId
                    }
                    siteId={
                      !!preselectedFields?.resourceId
                        ? preselectedFields.resourceId
                        : disabled && defaultSiteId
                        ? defaultSiteId
                        : siteId
                    }
                    disabled={
                      isSuperHubPage
                        ? !!this.state.preloadedEquipment
                        : disabled
                    }
                    error={formErrorsVisible && !!formErrors.resourceId}
                    customErrorText={
                      formErrors.resourceId && formErrors.resourceId[0]
                    }
                    isSuperHubPage={isSuperHubPage}
                    preSelectedEquipment={this.state.preloadedEquipment}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <Label required>Space</Label>
                </div>
                <div>
                  <SelectSpace
                    onSelect={value => onChange('resourceId', value)}
                    value={resourceId}
                    siteId={
                      !!preselectedFields?.resourceId
                        ? preselectedFields.resourceId
                        : siteId
                    }
                    error={formErrorsVisible && !!formErrors.resourceId}
                    customErrorText={
                      formErrors.resourceId && formErrors.resourceId[0]
                    }
                  />
                </div>
              </>
            )}
          </ModalFormContent>

          <ModalFormContent>
            <Label>* Mandatory fields</Label>
          </ModalFormContent>
          {renderAPIerror(apiError, SensorActions.CREATE_SENSOR_ERROR)}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ sensors }: ApplicationState) => ({
  isVisible: sensors.showNewSensorModal,
  ...sensors.editById['new'],
  preloadedSensorsBySite: sensors.preloadedSensorsBySite,
});

const mapDispatchToProps = {
  onClose: () => hideNewSensorModal(),
  onChange: (field: string, value: string) => updateField('new', field, value),
  onCreate: (lockSiteId?: number) => create(lockSiteId),
  onPatch: (id: string) => patchDummySensor(id),
  displayFormErrors,
  getPreloadedSensorsBySiteId,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSensorModal);
