import {
  Button,
  Card,
  CardContent,
  CardTitle,
  Modal,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  DistributionPanelType,
  EnergyPro,
  EnergySensor,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  isDefined,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  patch as patchBreaker,
  reset as resetBreakerField,
  updateField as updateBreakerField,
} from '../../../actions/circuit_breakers';
import {
  editEnergyDeviceSensorPort,
  mapSensorsInEnergyDeviceToEdit,
  resetEnergyDeviceSensors,
  updateEnergyDeviceSensorField,
} from '../../../actions/energy_devices';
import {
  getEnergyDeviceSensorsOfEnergyPro,
  processSubscribedEnergyProSensors,
} from '../../../utils/energyPro';
import {
  Actions as EnergyProActions,
  updateEnergyProConfiguration,
} from '../../../actions/energy_pros';
import { useAppLocale } from '../../../hooks/useAppDetails';
import { ApplicationState } from '../../../reducers';
import { EditById } from '../../../reducers/circuit_breakers';
import {
  EditEnergyDeviceSensorsByEnergyDeviceId,
  EnergyDevicesById,
} from '../../../reducers/energy_devices';
import { EditEnergyPro, EnergyProsById } from '../../../reducers/energy_pros';
import { EquipmentById } from '../../../reducers/equipment';
import { SubscribedEnergyPro } from '../../../reducers/subscribedEnergyPros';
import { formatFullDateTime } from '../../../utils/dates';
import {
  useEditEnergyPro,
  useHideUpdateEnergyProConfigModal,
  useIsUpdateEnergyProConfigModalShowing,
  useShowUpdateEnergyProConfigModal,
} from '../../Gateways/GatewayDetailPages/ShowEnergyProPage/ShowEnergyProPage';
import SelectCtPolarity from '../../Selects/SelectCtPolarity';
import SelectEnergyProCt from '../../Selects/SelectEnergyProCt';
import SelectEnergySpiderCt from '../../Selects/SelectEnergySpiderCt';
import SelectEnergySpiderFlexCt from '../../Selects/SelectEnergySpiderFlexCt';
import SelectEquipment from '../../Selects/SelectEquipment';
import SelectPhase from '../../Selects/SelectPhase';
import SelectActiveEnergyPro from '../SelectActiveEnergyPro';
import UpdateModal from '../UpdateModal';
import styles from './DistributionPanelPageLiveReadingTable.module.css';
import { ProcessedEnergySensorsById } from '../../../types/energyDevice';

type ProcessedSensorReading = {
  indexString: string;
  powerActive: number;
  powerReactive: number;
  current: number;
  powerFactor: number;
  voltage: number;
  sensorId: number;
  breakerId: number;
  breakerName: string;
  isMainBreaker: boolean;
  energyDeviceId: number;
  port: number;
};

const energyProPortDisplayMapping = {
  1: 'L1-L2',
  2: 'L2-L3',
  3: 'L3-L1',
};

type Props = {
  siteId: number;
  panelId: number;
  energyPros: EnergyPro[];
  activeEnergyPro: EnergyPro | undefined;
  handleActiveEnergyProChange: (value: number) => void;
};

const DeltaPanelPageLiveReadingTable: React.FC<Props> = ({
  siteId,
  panelId,
  energyPros,
  activeEnergyPro,
  handleActiveEnergyProChange,
}) => {
  const locale = useAppLocale();

  const [isBreakerUpdateModalOpen, setIsBreakerUpdateModalOpen] = useState(
    false
  );
  const [
    isEnergyDeviceSensorUpdateModalOpen,
    setIsEnergyDeviceSensorUpdateModalOpen,
  ] = useState(false);
  const activeEnergyProId = isDefined(activeEnergyPro)
    ? String(activeEnergyPro.id)
    : undefined;
  const isUpdateEnergyProConfigModalShowing = useIsUpdateEnergyProConfigModalShowing(
    activeEnergyProId
  );

  const [primedBreakerIdToUpdate, setPrimedBreakerIdToUpdate] = useState<
    number
  >(-1);
  const [
    primedEnergyDeviceSensorToUpdate,
    setPrimedEnergyDeviceSensorToUpdate,
  ] = useState<ProcessedSensorReading | undefined>(undefined);

  const energyProId = activeEnergyPro?.id;

  //*** useSelect redux ***///
  const editEnergyPro: EditEnergyPro | undefined = useEditEnergyPro(
    activeEnergyProId
  );
  //The actual subscription happens in the parent component: ShowDistributionPanelPage
  const subscribedEnergyPro = useSelector<
    ApplicationState,
    SubscribedEnergyPro
  >(({ subscribedEnergyPros }) => {
    return pathOr('', [energyProId], subscribedEnergyPros.byId);
  }, equals);

  const editBreakersById = useSelector<ApplicationState, EditById>(
    ({ circuitBreakers }) => {
      return circuitBreakers.editById;
    }
  );

  const energyDeviceById = useSelector<ApplicationState, EnergyDevicesById>(
    ({ energyDevices }) => {
      return energyDevices.energyDevicesById;
    }
  );

  const energyProsById = useSelector<ApplicationState, EnergyProsById>(
    ({ energyPros }) => {
      return energyPros.energyProsById;
    }
  );

  const editEnergyDeviceSensorsByDeviceId = useSelector<
    ApplicationState,
    EditEnergyDeviceSensorsByEnergyDeviceId
  >(({ energyDevices }) => {
    return energyDevices.editEnergyDeviceSensorsByEnergyDeviceId;
  });

  const equipmentById = useSelector<ApplicationState, EquipmentById>(
    ({ equipment }) => {
      return equipment.equipmentById;
    }
  );

  const primedEquipmentNameForUpdateBreaker = useSelector<
    ApplicationState,
    string | undefined
  >(({ circuitBreakers }) => {
    const equipmentId =
      circuitBreakers.editById[primedBreakerIdToUpdate]?.fields?.equipmentId;

    return equipmentById[equipmentId]?.title;
  });
  //*** useSelect redux end ***///

  ///*** useCallback ***///
  const dispatch = useDispatch();
  const onUpdateEnergyProConfig = useCallback(() => {
    if (activeEnergyPro) {
      dispatch(updateEnergyProConfiguration(String(activeEnergyPro.id)));
    }
  }, [dispatch, activeEnergyPro]);

  const showUpdateEnergyProConfigModal = useShowUpdateEnergyProConfigModal(
    dispatch,
    activeEnergyProId
  );
  const hideUpdateEnergyProConfigModal = useHideUpdateEnergyProConfigModal(
    dispatch,
    activeEnergyProId
  );

  const mapEnergyDeviceSensorsInRedux = useCallback(
    (sensors: EnergySensor[], energyDeviceId: number) => {
      dispatch(mapSensorsInEnergyDeviceToEdit(sensors, energyDeviceId));
    },
    [dispatch]
  );

  const onBreakerEdit = useCallback(
    (breakerId: string, field: string, value: string) => {
      dispatch(updateBreakerField(breakerId, field, value));
    },
    [dispatch]
  );

  const resetBreakerEdit = useCallback(
    (breakerId: number) => {
      dispatch(resetBreakerField(breakerId));
    },
    [dispatch]
  );

  const onPatchBreaker = useCallback(
    (breakerId: number) => {
      dispatch(patchBreaker(panelId, breakerId));
    },
    [dispatch]
  );

  const onEnergyDeviceSensorEdit = useCallback(
    (
      port: string,
      field: string,
      value: string | number,
      energyDeviceId: number
    ) => {
      dispatch(
        updateEnergyDeviceSensorField(port, field, value, energyDeviceId)
      );
    },
    [dispatch]
  );

  const resetEnergyDeviceSensor = useCallback(
    (energyDeviceId: number, isEnergyPro: boolean) => {
      dispatch(resetEnergyDeviceSensors(energyDeviceId, isEnergyPro));
    },
    [dispatch]
  );

  const onPatchEnergyDeviceSensor = useCallback(
    (energyDeviceId: number | string, port: number | string) => {
      dispatch(editEnergyDeviceSensorPort(energyDeviceId, port));
    },
    [dispatch]
  );
  ///*** useCallback end ***///

  ///*** useMemo values ***///
  const energySensorsById: ProcessedEnergySensorsById = useMemo(() => {
    const energyDeviceSensors = getEnergyDeviceSensorsOfEnergyPro(
      activeEnergyPro,
      {
        mapEnergyDeviceSensorsReduxAction: mapEnergyDeviceSensorsInRedux,
      }
    );

    return mapArrayToObject(energyDeviceSensors || []);
  }, [activeEnergyPro, mapEnergyDeviceSensorsInRedux]);

  const processedData = useMemo(() => {
    return processSubscribedEnergyProSensors(
      subscribedEnergyPro,
      energySensorsById
    );
  }, [subscribedEnergyPro, energySensorsById]);
  ///*** useMemo Values end ***///

  ///*** Local Functions ***///
  const onOpenUpdateBreakerModal = (equipmentId: number, breakerId: number) => {
    onBreakerEdit(String(breakerId), 'equipmentId', String(equipmentId));
    setPrimedBreakerIdToUpdate(breakerId);
    setIsBreakerUpdateModalOpen(true);
  };

  const onCancelUpdateBreakerModal = () => {
    resetBreakerEdit(primedBreakerIdToUpdate);
    setIsBreakerUpdateModalOpen(false);
    setPrimedBreakerIdToUpdate(-1);
  };

  const onConfirmUpdateBreakerModal = () => {
    onPatchBreaker(primedBreakerIdToUpdate);
    setIsBreakerUpdateModalOpen(false);
    setPrimedBreakerIdToUpdate(-1);
  };

  const onOpenUpdateEnergyDeviceSensorModal = (
    s: ProcessedSensorReading,
    field: string,
    value: any
  ) => {
    onEnergyDeviceSensorEdit(String(s.port), field, value, s.energyDeviceId);
    setPrimedEnergyDeviceSensorToUpdate(s);
    setIsEnergyDeviceSensorUpdateModalOpen(true);
  };

  const onCancelUpdateEnergyDeviceSensorModal = () => {
    const { energyDeviceId } = primedEnergyDeviceSensorToUpdate || {};
    if (energyDeviceId) {
      const isEnergyPro =
        energyProsById[energyDeviceId]?.resourceType === ResourceType.ENERGYPRO;

      resetEnergyDeviceSensor(energyDeviceId, isEnergyPro);
      setIsEnergyDeviceSensorUpdateModalOpen(false);
      setPrimedEnergyDeviceSensorToUpdate(undefined);
    }
  };

  const onConfirmUpdateEnergyDeviceSensorModal = () => {
    const { energyDeviceId, port } = primedEnergyDeviceSensorToUpdate || {};
    if (energyDeviceId && port) {
      onPatchEnergyDeviceSensor(energyDeviceId, port);
      setIsEnergyDeviceSensorUpdateModalOpen(false);
      setPrimedEnergyDeviceSensorToUpdate(undefined);
    }
  };

  const onConfirmUpdateEnergyProConfigModal = () => {
    onUpdateEnergyProConfig();
  };

  const energyProConfigModalText = (
    <span>
      Are you sure you want to update the configuration of{' '}
      {activeEnergyPro ? (
        <span className={styles.bold}>{activeEnergyPro.title}</span>
      ) : (
        'this Energy Pro'
      )}
      ?
    </span>
  );

  const breakerModalText = (
    <span>
      Are you sure you want to add{' '}
      {primedEquipmentNameForUpdateBreaker ? (
        <span className={styles.bold}>
          {primedEquipmentNameForUpdateBreaker}
        </span>
      ) : (
        'this equipment'
      )}{' '}
      to this circuit breaker?
    </span>
  );

  const energyDeviceSensorTitle = primedEnergyDeviceSensorToUpdate?.indexString;
  const energyDeviceSensorModalText = (
    <span>
      Are you sure you want to update{' '}
      {energyDeviceSensorTitle ? (
        <span className={styles.bold}>{energyDeviceSensorTitle}</span>
      ) : (
        'this Energy Device sensor'
      )}
      ?
    </span>
  );

  const renderUpdateModal = (
    onCancelModal: () => void,
    onConfirmUpdate: () => void,
    modalText: React.ReactNode
  ) => {
    const actions = (
      <span>
        <Button variant="text" onClick={onCancelModal}>
          Cancel
        </Button>
        <Button onClick={onConfirmUpdate}>Confirm</Button>
      </span>
    );

    return (
      <Modal actions={actions}>
        <div className={styles.updateBreakerModalContent}>{modalText}</div>
      </Modal>
    );
  };
  ///*** Local Functions end ***///

  ///*** Table Columns ***///
  const columns: Columns<ProcessedSensorReading>[] = useMemo(() => {
    return [
      {
        header: 'Index',
        width: '12%',
        cellContent: (s: ProcessedSensorReading) => {
          const isEnergyPro = isEnergyProRow(s);
          if (!isEnergyPro) {
            return <span>{s.indexString}</span>;
          }
          const energyProPort = getEnergyProPort(s);
          return (
            <div>
              <div>{s.indexString}</div>
              <div className={styles.deltaPanelEnergyProSecondaryRowDisplay}>
                EnergyPro Port {energyProPortDisplayMapping[energyProPort]}
              </div>
            </div>
          );
        },
      },
      {
        header: 'Breaker',
        width: '12%',
        cellContent: (s: ProcessedSensorReading) => (
          <span>{s.breakerName || global.NOT_AVAILABLE}</span>
        ),
      },
      {
        header: 'Equipment',
        width: '16%',
        cellContent: (s: ProcessedSensorReading) => (
          <div className={styles.selectContainer}>
            <SelectEquipment
              disabled={!s.breakerId || s.isMainBreaker}
              onSelect={equipmentId =>
                onOpenUpdateBreakerModal(equipmentId, s.breakerId)
              }
              value={Number(
                pathOr(
                  -1,
                  [s.breakerId, 'fields', 'equipmentId'],
                  editBreakersById
                )
              )}
              siteId={siteId}
              noBottomLine
            />
          </div>
        ),
      },
      {
        header: 'CT Polarity',
        width: '8%',
        cellContent: (s: ProcessedSensorReading) => {
          const reversePolarityValue: boolean | undefined = pathOr(
            undefined,
            [s.energyDeviceId, s.port, 'fields', 'reversePolarity'],
            editEnergyDeviceSensorsByDeviceId
          );

          if (reversePolarityValue === undefined) {
            return global.NOT_AVAILABLE;
          }
          return (
            <div className={styles.selectContainer}>
              <SelectCtPolarity
                value={reversePolarityValue}
                onSelect={value =>
                  onOpenUpdateEnergyDeviceSensorModal(
                    s,
                    'reversePolarity',
                    value
                  )
                }
                noBottomLine
              />
            </div>
          );
        },
      },
      {
        header: 'CT Type',
        width: '11%',
        cellContent: (s: ProcessedSensorReading) => {
          const isEnergyPro =
            energyProsById[s.energyDeviceId]?.resourceType ===
            ResourceType.ENERGYPRO;
          const isEnergySpider =
            energyDeviceById[s.energyDeviceId]?.resourceType ===
            ResourceType.ENERGYSPIDER;
          const isEnergySpider2 =
            energyDeviceById[s.energyDeviceId]?.resourceType ===
            ResourceType.ENERGYSPIDER2;
          const isEnergySpiderFlex =
            energyDeviceById[s.energyDeviceId]?.resourceType ===
            ResourceType.ENERGYSPIDERFLEX;

          const ctTypeValue = pathOr(
            undefined,
            [s.energyDeviceId, s.port, 'fields', 'ct'],
            editEnergyDeviceSensorsByDeviceId
          );

          if (
            !isEnergyPro &&
            !isEnergySpider &&
            !isEnergySpider2 &&
            !isEnergySpiderFlex
          )
            return global.NOT_AVAILABLE;
          return (
            <div className={styles.selectContainer}>
              {isEnergyPro && (
                <SelectEnergyProCt
                  error={false}
                  value={ctTypeValue}
                  onSelect={selectedCT =>
                    onOpenUpdateEnergyDeviceSensorModal(s, 'ct', selectedCT)
                  }
                  noBottomLine
                />
              )}
              {(isEnergySpider || isEnergySpider2) && (
                <SelectEnergySpiderCt
                  error={false}
                  value={ctTypeValue}
                  onSelect={selectedCT =>
                    onOpenUpdateEnergyDeviceSensorModal(s, 'ct', selectedCT)
                  }
                  noBottomLine
                  isGenII={isEnergySpider2}
                />
              )}
              {isEnergySpiderFlex && (
                <SelectEnergySpiderFlexCt
                  error={false}
                  value={ctTypeValue}
                  onSelect={selectedCT =>
                    onOpenUpdateEnergyDeviceSensorModal(s, 'ct', selectedCT)
                  }
                  noBottomLine
                />
              )}
            </div>
          );
        },
      },
      {
        header: 'Phase',
        width: '6%',
        cellContent: (s: ProcessedSensorReading) => {
          const phaseValue = pathOr(
            undefined,
            [s.energyDeviceId, s.port, 'fields', 'phase'],
            editEnergyDeviceSensorsByDeviceId
          );

          if (phaseValue === undefined) return global.NOT_AVAILABLE;
          const isEnergyPro = isEnergyProRow(s);
          return (
            <div className={styles.selectContainer}>
              <SelectPhase
                isEnergyPro={isEnergyPro}
                panelType={DistributionPanelType.HIGH_LEG_DELTA_NETWORK}
                value={phaseValue}
                onSelect={value =>
                  onOpenUpdateEnergyDeviceSensorModal(s, 'phase', value)
                }
                noBottomLine
              />
            </div>
          );
        },
      },
      {
        align: 'right',
        rightAlignContent: true,
        header: (
          <div>
            <div>Active Power</div>
            <div>(kW)</div>
          </div>
        ),
        width: '6%',
        cellContent: (s: ProcessedSensorReading) => {
          const isEnergyPro = isEnergyProRow(s);
          if (!isEnergyPro) {
            return <span>{s.powerActive}</span>;
          }
          const energyProPort = getEnergyProPort(s);
          return (
            <>
              <div>{global.NOT_AVAILABLE}</div>
              <div className={styles.deltaPanelEnergyProSecondaryRowDisplay}>
                {energyProPort !== 3 ? s.powerActive : global.NOT_AVAILABLE}
              </div>
            </>
          );
        },
      },
      {
        align: 'right',
        rightAlignContent: true,
        header: (
          <div>
            <div>Current</div>
            <div>(A)</div>
          </div>
        ),
        width: '6%',
        cellContent: (s: ProcessedSensorReading) => <span>{s.current}</span>,
      },
      {
        align: 'right',
        rightAlignContent: true,
        header: (
          <div>
            <div>Voltage</div>
            <div>(V)</div>
          </div>
        ),
        width: '6%',
        cellContent: (s: ProcessedSensorReading) => {
          const isEnergyPro = isEnergyProRow(s);
          if (!isEnergyPro) {
            return <span>{s.voltage}</span>;
          }
          return (
            <>
              <div>{global.NOT_AVAILABLE}</div>
              <div className={styles.deltaPanelEnergyProSecondaryRowDisplay}>
                {s.voltage}
              </div>
            </>
          );
        },
      },
    ];
  }, [editBreakersById, editEnergyDeviceSensorsByDeviceId, siteId]);
  ///*** Table Columns end ***///

  return (
    <>
      <Card className={styles.cardContainer}>
        <CardContent>
          <CardTitle className={styles.cardTitle}>
            <div>
              <div className={styles.headerLeftAlign}>
                <span className={styles.headerTitle}>
                  EnergyPro Live Readings:{' '}
                </span>
                <SelectActiveEnergyPro
                  className={styles.energyProSelector}
                  energyPros={energyPros}
                  activeEnergyPro={activeEnergyPro}
                  onChange={handleActiveEnergyProChange}
                />
              </div>
              <div className={styles.headerRightAlign}>
                {subscribedEnergyPro ? (
                  <span
                    title={formatFullDateTime(
                      subscribedEnergyPro.timestamp,
                      locale.fullDateTimeFormat
                    )}
                  >
                    <TimeDistance timestamp={subscribedEnergyPro.timestamp} />
                  </span>
                ) : (
                  global.NOT_AVAILABLE
                )}
              </div>
            </div>
            <div>
              <Button onClick={showUpdateEnergyProConfigModal}>
                Update Configuration To Edge
              </Button>
            </div>
          </CardTitle>

          <Table
            highlightAlternateRows
            data={processedData}
            columns={columns}
          />
        </CardContent>
      </Card>

      {isBreakerUpdateModalOpen &&
        renderUpdateModal(
          onCancelUpdateBreakerModal,
          onConfirmUpdateBreakerModal,
          breakerModalText
        )}
      {isEnergyDeviceSensorUpdateModalOpen &&
        renderUpdateModal(
          onCancelUpdateEnergyDeviceSensorModal,
          onConfirmUpdateEnergyDeviceSensorModal,
          energyDeviceSensorModalText
        )}
      {isUpdateEnergyProConfigModalShowing && (
        <UpdateModal
          onCancelModal={hideUpdateEnergyProConfigModal}
          onConfirmUpdate={onConfirmUpdateEnergyProConfigModal}
          modalText={energyProConfigModalText}
          apiError={editEnergyPro?.apiError}
          apiErrorAction={
            EnergyProActions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR
          }
        />
      )}
    </>
  );
};

const isEnergyProRow = (s: ProcessedSensorReading) => {
  return s.indexString.includes('EnergyPro');
};

const getEnergyProPort = (s: ProcessedSensorReading) => {
  const regexMatchNumber = s.indexString.match(/\d+/);
  if (!regexMatchNumber) return -1;
  const energyProPort = regexMatchNumber[0];
  return +energyProPort;
};

export default DeltaPanelPageLiveReadingTable;
