import { Actions } from '../actions/taskSop';
import * as R from 'ramda';

export interface TaskSOP {
  showNewModal: boolean;
  showDeleteModal: boolean;
  editingTaskSop: number;
  createSuccess: boolean;
  apiError: Object;
}

export const initialState = {
  showNewModal: false,
  showDeleteModal: false,
  editingTaskSop: -1,
  createSuccess: false,
  apiError: {},
};

const taskSop = (state: TaskSOP = initialState, action: any) => {
  switch (action.type) {
    case Actions.TOGGLE_EDIT_MODAL:
      return R.assoc('editingTaskSop', action.id, state);

    case Actions.TOGGLE_NEW_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new']),
        R.assoc('showNewModal', action.value)
      )(state);

    case Actions.TOGGLE_DELETE_MODAL:
      return R.pipe(
        R.assoc('showDeleteModal', action.value),
        R.assoc('apiError', {})
      )(state);

    case Actions.UPDATE_TASK_SOP_ERROR:
    case Actions.CREATE_TASK_SOP_ERROR:
      return R.pipe(
        R.assoc('apiError', {
          type: action?.type || '',
          status: action?.data?.status || 0,
          message: action?.data?.errors[0]?.message || '',
        })
      )(state);

    case Actions.UPDATE_TASK_SOP_LOADING:
    case Actions.CREATE_TASK_SOP_LOADING:
      return R.pipe(R.assoc('createSuccess', false))(state);

    case Actions.UPDATE_TASK_SOP_SUCCESS:
    case Actions.CREATE_TASK_SOP_SUCCESS:
      return R.pipe(R.assoc('createSuccess', true))(state);

    case Actions.GET_ALL_TASK_SOP_SUCCESS:

    case Actions.GET_ALL_TASK_SOP_LOADING:

    case Actions.GET_ALL_TASK_SOP_ERROR:

    default:
      return state;
  }
};

export default taskSop;
