import {
  FormText,
  InputField,
  Label,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  GenericErrors,
  LightSensorPort,
  SchedulerLabel,
  TimeTable,
} from '@energybox/react-ui-library/dist/types';
import { isDefined, global } from '@energybox/react-ui-library/dist/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FeatureFlag from '../../containers/FeatureFlag';
import SelectControlBoard from '../../containers/Selects/SelectControlBoard';
import SelectLightSensorDelay from '../../containers/Selects/SelectLightSensorDelay';
import SelectTimeTable from '../../containers/Selects/SelectTimeTable';
import { useLightLiveReadings } from '../../hooks/streamApi/deviceReadings';
import { useCalculateLast7DayAvgAstronomicalLuxValues } from '../../hooks/useSiteAstroClock';
import { ApplicationState } from '../../reducers';
import {
  ControlBoardsById,
  ControlBoardsBySiteId,
} from '../../reducers/control_boards';
import { EditableFields } from '../../reducers/schedulers';
import { getTimetablesRoute } from '../../utils/timeTables';
import BiasInfoTooltip from '../BiasInfoTooltip';
import ModalFormContent from '../ModalFormContent';
import SchedulerTimetableInfoTooltip from '../SchedulerTimetableInfoTooltip';
import styles from './EditSchedulerForm.module.css';
import { SiteController } from '@energybox/react-ui-library/dist/types/Device';
import { getControlBoardsBySiteId } from '../../actions/control_boards';
import ThresholdInfoTooltip from '../ThresholdInfoTooltip/ThresholdInfoTooltip';
import HysteresisInfoTooltip from '../HysteresisInfoTooltip/HysteresisInfoTooltip';
import DelayInfoTooltip from '../DelayInfoTooltip/DelayInfoTooltip';

enum TabNames {
  SCHEDULE = 'Schedule',
  LIGHT_SENSOR = 'Light Sensor',
}

type onChangeType = (field: string | string[], value: any) => void;
interface Props {
  timeTables: TimeTable[];
  siteId: number;
  equipmentId: number;
  onChange: onChangeType;
  fields: EditableFields;
  formErrorsVisible: boolean;
  formErrors: GenericErrors;
  timezone?: string;
  numberOfTimesClickedOnSave: number;
}

const EditSchedulerForm = ({
  timeTables,
  siteId,
  equipmentId,
  onChange,
  fields,
  formErrors,
  formErrorsVisible,
  timezone,
  numberOfTimesClickedOnSave,
}: Props) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TabNames.SCHEDULE);
  const {
    timetableId,
    lightSensorSettings: { timetableId: lightTimetableId, controlBoardId },
  } = fields;
  const selectedScheduleTimeTable = timeTables.find(
    (t: TimeTable) => t.id === timetableId
  );
  const selectedLightTimeTable = timeTables.find(
    (t: TimeTable) => t.id === lightTimetableId
  );
  const hasAtLeastOneTimetableBeenSelected =
    isDefined(selectedScheduleTimeTable) || isDefined(selectedLightTimeTable);

  const isNoScheduleTimetableOptionSelected =
    timetableId === 'null' || timetableId === null;

  const hasInvalidScheduleTimetable =
    (!isDefined(selectedScheduleTimeTable) &&
      !isNoScheduleTimetableOptionSelected) ||
    !hasAtLeastOneTimetableBeenSelected;

  const isControlBoardSelected = controlBoardId !== -1;

  const hasInvalidLightTimetable =
    !hasAtLeastOneTimetableBeenSelected ||
    (isControlBoardSelected && !isDefined(selectedLightTimeTable));

  const controlBoardsBySiteId = useSelector<
    ApplicationState,
    ControlBoardsBySiteId
  >(state => state.controlBoards.controlBoardsBySiteId);

  const isControlBoardsBySiteExist =
    Object.keys(controlBoardsBySiteId).length > 0;
  const isControlBoardModelEU = Object.values(controlBoardsBySiteId).some(
    controlBoard => controlBoard.model !== SiteController.ENERGYBOX_CB_EU_6
  );

  useEffect(() => {
    const hasErrorInScheduleTab =
      //global form validation for formErrors.hasOwnProperty('timetableId')
      //doesn't apply b/c there are other parts of platform
      //where timetableId field is not mandatory
      //therefore we have to do manual check
      hasInvalidScheduleTimetable ||
      formErrors.hasOwnProperty('beginDelta') ||
      formErrors.hasOwnProperty('endDelta');

    const hasErrorInLightSensorTab =
      hasInvalidLightTimetable ||
      formErrors.hasOwnProperty('lightSensorSettings.controlBoardId') ||
      formErrors.hasOwnProperty('lightSensorSettings.actionInterval') ||
      formErrors.hasOwnProperty('lightSensorSettings.threshold') ||
      formErrors.hasOwnProperty('lightSensorSettings.hysteresis') ||
      formErrors.hasOwnProperty('lightSensorSettings.beginDelta') ||
      formErrors.hasOwnProperty('lightSensorSettings.endDelta');

    if (
      formErrorsVisible &&
      hasErrorInScheduleTab &&
      !hasErrorInLightSensorTab &&
      activeTab === TabNames.LIGHT_SENSOR
    ) {
      setActiveTab(TabNames.SCHEDULE);
    } else if (
      formErrorsVisible &&
      hasErrorInLightSensorTab &&
      !hasErrorInScheduleTab &&
      activeTab === TabNames.SCHEDULE
    ) {
      setActiveTab(TabNames.LIGHT_SENSOR);
    }
  }, [
    formErrorsVisible,
    numberOfTimesClickedOnSave,
    hasInvalidScheduleTimetable,
  ]);

  useEffect(() => {
    dispatch(getControlBoardsBySiteId(siteId));
  }, []);

  if (timeTables.length === 0) {
    return (
      <div>
        <Label>
          No timetables. Please create one
          <Link to={getTimetablesRoute({ orgUnitId: siteId })}> here</Link>
        </Label>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <FormTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isControlBoardModelEU={
          !isControlBoardModelEU || !isControlBoardsBySiteExist
        }
      />
      {activeTab === TabNames.SCHEDULE && (
        <ScheduleContent
          siteId={siteId}
          formErrors={formErrors}
          formErrorsVisible={formErrorsVisible}
          selectedTimeTable={selectedScheduleTimeTable}
          fields={fields}
          onChange={onChange}
          hasInvalidScheduleTimetable={hasInvalidScheduleTimetable}
        />
      )}
      {activeTab === TabNames.LIGHT_SENSOR && (
        <LightSensorContent
          timezone={timezone}
          formErrors={formErrors}
          formErrorsVisible={formErrorsVisible}
          selectedTimeTable={selectedLightTimeTable}
          fields={fields}
          onChange={onChange}
          equipmentId={equipmentId}
          siteId={siteId}
          hasInvalidLightTimetable={hasInvalidLightTimetable}
        />
      )}
    </div>
  );
};

const FormTabs = ({
  activeTab,
  setActiveTab,
  isControlBoardModelEU,
}: {
  activeTab: TabNames;
  setActiveTab: React.Dispatch<React.SetStateAction<TabNames>>;
  isControlBoardModelEU: boolean;
}) => {
  return (
    <Tabs className={styles.marginBottom}>
      <Tab
        active={activeTab === TabNames.SCHEDULE}
        onClick={() => {
          setActiveTab(TabNames.SCHEDULE);
        }}
      >
        {TabNames.SCHEDULE}
      </Tab>
      {!isControlBoardModelEU && (
        <Tab
          active={activeTab === TabNames.LIGHT_SENSOR}
          onClick={() => {
            setActiveTab(TabNames.LIGHT_SENSOR);
          }}
        >
          {TabNames.LIGHT_SENSOR}
        </Tab>
      )}
    </Tabs>
  );
};

type ScheduleFormContentProps = {
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  selectedTimeTable?: TimeTable;
  fields: EditableFields;
  onChange: onChangeType;
  siteId: number;
  hasInvalidScheduleTimetable: boolean;
};

type LightFormContentProps = Omit<
  ScheduleFormContentProps,
  'hasInvalidScheduleTimetable'
> & {
  equipmentId: number;
  timezone?: string;
  hasInvalidLightTimetable: boolean;
};

const ScheduleContent = ({
  formErrors,
  formErrorsVisible,
  selectedTimeTable,
  fields,
  onChange,
  siteId,
  hasInvalidScheduleTimetable,
}: ScheduleFormContentProps) => {
  const { beginDelta, endDelta, timetableId } = fields;
  const isTimetableSelected = selectedTimeTable !== undefined;

  return (
    <ModalFormContent className={styles.modalFormContent}>
      <div>
        <Label required>{SchedulerLabel.TIMETABLE}</Label>
      </div>
      <div className={styles.selectTimetableContainer}>
        <div className={styles.selectTimetable}>
          <SelectTimeTable
            includeNullSelectOption
            siteId={+siteId}
            value={String(timetableId)}
            onSelect={(value: string | number) =>
              onChange('timetableId', value)
            }
            error={
              formErrorsVisible &&
              (!!formErrors.timetableId || hasInvalidScheduleTimetable)
            }
            customErrorText={
              hasInvalidScheduleTimetable
                ? 'Invalid Timetable'
                : formErrors.timetableId && formErrors.timetableId.join(', ')
            }
          />
        </div>

        <SchedulerTimetableInfoTooltip
          type="Schedule"
          hasError={
            formErrorsVisible &&
            (!!formErrors.timetableId || hasInvalidScheduleTimetable)
          }
        />
      </div>

      <div>
        <Label required>{SchedulerLabel.BIAS}</Label>
      </div>
      <div className={styles.biasFieldContainer}>
        <div className={styles.biasField}>
          <span className={styles.marginRight}>Start</span>
          <div>
            <InputField
              className={styles.biasInput}
              type="number"
              value={beginDelta}
              onChange={({ currentTarget }) =>
                onChange('beginDelta', currentTarget.value)
              }
              error={formErrorsVisible && !!formErrors.beginDelta}
              customErrorText={'Invalid'}
              disabled={!isTimetableSelected}
            />
          </div>
          <span className={styles.smallFont}>min(s)</span>
        </div>

        <div className={styles.biasColon}> : </div>

        <div className={styles.biasField}>
          <span className={styles.marginRight}>End</span>
          <div>
            <InputField
              value={endDelta}
              className={styles.biasInput}
              type="number"
              onChange={({ currentTarget }) =>
                onChange('endDelta', currentTarget.value)
              }
              error={formErrorsVisible && !!formErrors.endDelta}
              customErrorText={'Invalid'}
              disabled={!isTimetableSelected}
            />
          </div>
          <span className={styles.smallFont}>min(s)</span>

          <span>
            <BiasInfoTooltip
              hasError={
                formErrorsVisible &&
                (!!formErrors.beginDelta || !!formErrors.endDelta)
              }
            />
          </span>
        </div>
      </div>

      <FormText>
        <Label>* Mandatory fields</Label>
      </FormText>
    </ModalFormContent>
  );
};

const LightSensorContent = ({
  formErrors,
  formErrorsVisible,
  fields,
  onChange,
  equipmentId,
  siteId,
  timezone,
  hasInvalidLightTimetable,
}: LightFormContentProps) => {
  const {
    lightSensorSettings: {
      beginDelta: lightBeginDelta,
      endDelta: lightEndDelta,
      timetableId: lightTimetableId,
      controlBoardId,
      threshold,
      hysteresis,
      actionInterval,
    },
  } = fields;

  const controlBoardsById = useSelector<ApplicationState, ControlBoardsById>(
    ({ controlBoards }) => {
      return controlBoards.controlBoardsById;
    }
  );

  const lightLiveReading = useLightLiveReadings(controlBoardId!);

  const {
    lightReadingsLast7DaysAvg: astroEventLuxAverages,
  } = useCalculateLast7DayAvgAstronomicalLuxValues(
    siteId,
    controlBoardId,
    timezone
  );

  if (!controlBoardId) return null;

  const selectedControlBoard = isDefined(controlBoardId)
    ? controlBoardsById[controlBoardId]
    : undefined;

  const luxRange =
    selectedControlBoard?.lightSensorPort === LightSensorPort.PORT_1
      ? lightLiveReading?.lux1Range
      : lightLiveReading?.lux2Range;

  const formattedLuxRange = luxRange?.toLocaleString('en-US');

  const isControlBoardSelected = isDefined(selectedControlBoard);

  const isTimetableSelected =
    isDefined(lightTimetableId) && lightTimetableId > 0;

  const isDelayFieldValid: boolean = !(
    formErrorsVisible && !!formErrors['lightSensorSettings.actionInterval']
  );

  const updateActionIntervalOnControlBoardSelect = (
    newlySelectedControlBoard: ControlBoard
  ) => {
    const sensorPublishingInterval =
      newlySelectedControlBoard.lightSensorPublishingInterval;

    let newActionIntervalValue = 5 * sensorPublishingInterval;

    while (newActionIntervalValue > 15) {
      newActionIntervalValue -= sensorPublishingInterval;
    }

    onChange(['lightSensorSettings', 'actionInterval'], newActionIntervalValue);
  };

  return (
    <ModalFormContent className={styles.modalFormContent}>
      <div>
        <Label required>{SchedulerLabel.LIGHT_SENSOR_SOURCE}</Label>
      </div>
      <div className={styles.luxValueContainer}>
        <SelectControlBoard
          equipmentId={equipmentId}
          value={isDefined(controlBoardId) ? controlBoardId : undefined}
          onSelect={(controlBoardId: number) => {
            onChange(['lightSensorSettings', 'controlBoardId'], controlBoardId);

            const newlySelectedControlBoard = controlBoardsById[controlBoardId];
            if (isDefined(newlySelectedControlBoard)) {
              updateActionIntervalOnControlBoardSelect(
                newlySelectedControlBoard
              );
            }

            //if user selects the none option
            if (controlBoardId === -1) {
              onChange(['lightSensorSettings', 'timetableId'], -1);
            }
          }}
          includeNone
          filterControlBoardsWithoutLightSensor
        />
        <div className={styles.luxValue}>
          {luxRange ? (
            <div> ( {formattedLuxRange}lux Sensor ) </div>
          ) : (
            <div> ( {global.NOT_AVAILABLE} ) </div>
          )}
        </div>
      </div>

      <div>
        <Label>{SchedulerLabel.THRESHOLD}</Label>
      </div>
      <div>
        <div className={styles.biasField}>
          <div>
            <InputField
              className={styles.biasInput}
              type="number"
              min={0}
              max={9900}
              value={threshold}
              disabled={!isControlBoardSelected}
              onChange={({ currentTarget }) =>
                onChange(
                  ['lightSensorSettings', 'threshold'],
                  currentTarget.value
                )
              }
              error={
                formErrorsVisible &&
                !!formErrors['lightSensorSettings.threshold']
              }
              customErrorText={
                formErrors['lightSensorSettings.threshold'] &&
                formErrors['lightSensorSettings.threshold'].join(', ')
              }
            />
          </div>
          <span className={styles.smallFont}>lux</span>
          <span>
            <ThresholdInfoTooltip
              astroEventLuxAverages={astroEventLuxAverages}
            />
          </span>
        </div>
      </div>

      <div>
        <Label>{SchedulerLabel.HYSTERESIS}</Label>
      </div>
      <div>
        <div className={styles.biasField}>
          <div>
            <InputField
              className={styles.biasInput}
              type="number"
              min={10}
              max={100}
              value={hysteresis}
              disabled={!isControlBoardSelected}
              onChange={({ currentTarget }) =>
                onChange(
                  ['lightSensorSettings', 'hysteresis'],
                  currentTarget.value
                )
              }
              error={
                formErrorsVisible &&
                !!formErrors['lightSensorSettings.hysteresis']
              }
              customErrorText={
                formErrors['lightSensorSettings.hysteresis'] &&
                formErrors['lightSensorSettings.hysteresis'].join(', ')
              }
            />
          </div>
          <span className={styles.smallFont}>%</span>
          <span>
            <HysteresisInfoTooltip
              threshold={threshold}
              hysteresis={hysteresis}
            />
          </span>
        </div>
      </div>

      <FeatureFlag>
        <>
          <div>
            <Label>{SchedulerLabel.DELAY}</Label>
          </div>
          <div className={styles.delaySelector}>
            <SelectLightSensorDelay
              value={actionInterval}
              controlBoard={selectedControlBoard}
              onSelect={value => {
                onChange(['lightSensorSettings', 'actionInterval'], value);
              }}
              disabled={!isControlBoardSelected}
              error={!isDelayFieldValid}
              customErrorText={
                formErrors['lightSensorSettings.actionInterval'] &&
                formErrors['lightSensorSettings.actionInterval'].join(', ')
              }
            />
            <span>
              <DelayInfoTooltip
                hasError={formErrorsVisible && !!formErrors.delay}
              />
            </span>
          </div>
        </>
      </FeatureFlag>

      <div>
        <Label>{SchedulerLabel.TIMETABLE}</Label>
      </div>
      <div className={styles.selectTimetableContainer}>
        <div className={styles.selectTimetable}>
          <SelectTimeTable
            siteId={+siteId}
            value={String(lightTimetableId)}
            disabled={!isControlBoardSelected}
            onSelect={(value: string | number) =>
              onChange(['lightSensorSettings', 'timetableId'], value)
            }
            error={
              formErrorsVisible &&
              (!!formErrors['lightSensorSettings.timetableId'] ||
                hasInvalidLightTimetable)
            }
            customErrorText={
              hasInvalidLightTimetable
                ? 'Invalid Timetable'
                : formErrors['lightSensorSettings.timetableId'] &&
                  formErrors.formErrors['lightSensorSettings.timetableId'].join(
                    ', '
                  )
            }
          />
        </div>

        <SchedulerTimetableInfoTooltip
          type="Light Sensor"
          hasError={
            formErrorsVisible &&
            (!!formErrors['lightSensorSettings.timetableId'] ||
              hasInvalidLightTimetable)
          }
        />
      </div>

      <div>
        <Label>{SchedulerLabel.BIAS}</Label>
      </div>
      <div className={styles.biasFieldContainer}>
        <div className={styles.biasField}>
          <span className={styles.marginRight}>Start</span>
          <div>
            <InputField
              className={styles.biasInput}
              type="number"
              value={lightBeginDelta}
              disabled={!isTimetableSelected || !isControlBoardSelected}
              onChange={({ currentTarget }) =>
                onChange(
                  ['lightSensorSettings', 'beginDelta'],
                  currentTarget.value
                )
              }
              error={
                formErrorsVisible &&
                !!formErrors['lightSensorSettings.beginDelta']
              }
              customErrorText={'Invalid'}
            />
          </div>
          <span className={styles.smallFont}>min(s)</span>
        </div>

        <div className={styles.biasColon}> : </div>

        <div className={styles.biasField}>
          <span className={styles.marginRight}>End</span>
          <div>
            <InputField
              className={styles.biasInput}
              type="number"
              value={lightEndDelta}
              disabled={!isTimetableSelected || !isControlBoardSelected}
              onChange={({ currentTarget }) =>
                onChange(
                  ['lightSensorSettings', 'endDelta'],
                  currentTarget.value
                )
              }
              error={
                formErrorsVisible &&
                !!formErrors['lightSensorSettings.endDelta']
              }
              customErrorText={'Invalid'}
            />
          </div>
          <span className={styles.smallFont}>min(s)</span>

          <span>
            <BiasInfoTooltip
              hasError={
                formErrorsVisible &&
                (!!formErrors['lightSensorSettings.beginDelta'] ||
                  !!formErrors['lightSensorSettings.endDelta'])
              }
            />
          </span>
        </div>
      </div>

      <FormText>
        <Label>* Mandatory fields</Label>
      </FormText>
    </ModalFormContent>
  );
};

export default EditSchedulerForm;
