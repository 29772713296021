import { AtlassianImgDom } from '@energybox/react-ui-library/dist/types/ParsedHTML';
import { EnergyboxService } from '../config';
import { fetchApi } from '../utils/apiUtils';

export const apiBase = '/api/release';

export enum Actions {
  GET_FEATURE_LOADING = '@whats_new/GET_FEATURE_LOADING',
  GET_FEATURE_SUCCESS = '@whats_new/GET_FEATURE_SUCCESS',
  GET_FEATURE_ERROR = '@whats_new/GET_FEATURE_ERROR',
  GET_FEATURE_IS_NEW_LOADING = '@whats_new/GET_FEATURE_IS_NEW_LOADING',
  GET_FEATURE_IS_NEW_SUCCESS = '@whats_new/GET_FEATURE_IS_NEW_SUCCESS',
  GET_FEATURE_IS_NEW_ERROR = '@whats_new/GET_FEATURE_IS_NEW_ERROR',
  VIEW_FEATURE_SUCCESS = '@whats_new/VIEW_FEATURE_SUCCESS',
  DISMISS_NEW_FEATURE = '@whats_new/DISMISS_NEW_FEATURE',
}

export const getNewFeature = () => ({
  service: EnergyboxService.help,
  type: 'API_GET',
  path: `${apiBase}/all`,
  loading: Actions.GET_FEATURE_LOADING,
  success: Actions.GET_FEATURE_SUCCESS,
  error: Actions.GET_FEATURE_ERROR,
});

export const getAttachmentUrl = (dom: AtlassianImgDom) => {
  const endpoint = `${apiBase}/page/${dom.attributes['data-linked-resource-container-id']}/attachment/${dom.attributes['data-linked-resource-id']}`;

  return fetchApi({
    service: EnergyboxService.help,
    endpoint,
  });
};

// true = new feature is viewed, no pop-up should be shown
export const getNewFeatureVisited = (userId: number) => ({
  service: EnergyboxService.help,
  type: 'API_GET',
  path: `${apiBase}/visit/${userId}`,
  loading: Actions.GET_FEATURE_IS_NEW_LOADING,
  success: Actions.GET_FEATURE_IS_NEW_SUCCESS,
  error: Actions.GET_FEATURE_IS_NEW_ERROR,
});

export const viewNewFeature = (userId: number) => ({
  service: EnergyboxService.help,
  type: 'API_POST',
  path: `${apiBase}/visit/${userId}`,
  success: Actions.VIEW_FEATURE_SUCCESS,
});

export const dismissNewFeature = () => ({ type: Actions.DISMISS_NEW_FEATURE });
