import {
  Card,
  CardContent,
  CardTitle,
  InfoChip,
} from '@energybox/react-ui-library/dist/components';
import { ControlBoard, Gateway } from '@energybox/react-ui-library/dist/types';

import React from 'react';
import DeviceOnlineState, {
  DisplayType,
} from '../../containers/DeviceStatus/DeviceOnlineState';
import { ConnectionType } from '../../utils/gateway';
import styles from './GatewayCommonCardStyle.module.css';

interface Props {
  gateway: Gateway | ControlBoard;
  version?: 'HUB' | 'CONTROL_BOARD';
  ianaTimeZoneCode?: string;
}

export default ({ gateway, ianaTimeZoneCode, version = 'HUB' }: Props) => {
  return (
    <Card>
      <CardContent>
        <div className={styles.generalCardGrid}>
          <CardTitle className={styles.cardTitleExtraPadding}>
            Connection
          </CardTitle>
          <div>
            <div>
              <InfoChip
                title="Status"
                fields={[
                  gateway && (
                    <DeviceOnlineState
                      displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
                      devices={[
                        {
                          id: gateway.id,
                          uuid: gateway.uuid,
                          vendor: gateway.vendor,
                        },
                      ]}
                    />
                  ),
                ]}
              />
              <InfoChip
                title="Last update"
                fields={[
                  <DeviceOnlineState
                    displayType={DisplayType.TIME_DISTANCE_ONLY}
                    ianaTimeZoneCode={ianaTimeZoneCode}
                    devices={[
                      {
                        id: gateway.id,
                        uuid: gateway.uuid,
                        vendor: gateway.vendor,
                      },
                    ]}
                  />,
                ]}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
