import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { useDispatch } from 'react-redux';
import styles from './ConfirmReportDialog.module.css';
import history from '../../history';
import { toggleShowHideIcon } from '../../actions/reportworker';

type Props = {
  isCreateDashboardDialogOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const ConfirmReportDialog = ({
  isCreateDashboardDialogOpen,
  onClose,
  onSubmit,
}: Props) => {
  const dispatch = useDispatch();

  const closeWithResetName = () => {
    dispatch(toggleShowHideIcon(false));
    onClose();
  };

  const onHandleSubmit = () => {
    onSubmit();
    onClose();
  };

  const onHandleLink = () => {
    onSubmit();
    onClose();
    setTimeout(() => {
      window.open('/my-downloads', '_blank');
    }, 300);
  };

  return isCreateDashboardDialogOpen ? (
    <Modal
      onClose={onHandleSubmit}
      actions={[
        <Button key="submit" onClick={onHandleSubmit}>
          Ok
        </Button>,
      ]}
    >
      <ModalTitle>The report is being generated.</ModalTitle>
      <button className={styles.closeIcon} onClick={onHandleSubmit}>
        <span></span>
      </button>
      <ModalContent className={styles.modalContent}>
        <span>
          A green badge on the top right corner will indicate that the report is
          ready. To download the report, click on the green badge to be directed
          to the My Downloads page. Alternatively, click on the User Account
          Name on the top right corner and click&nbsp;
          <Button className={styles.btnLink} onClick={onHandleLink}>
            My Downloads
          </Button>
          .
        </span>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
};

export default ConfirmReportDialog;
