import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEdgeConfigFileBySerialNumber } from '../actions/edge_devices';
import { ApplicationState } from '../reducers';

export const useGetValidationError = (serialNumber: string) => {
  const dispatch = useDispatch();
  const getEdgeConfigFile = useCallback(() => {
    dispatch(getEdgeConfigFileBySerialNumber(serialNumber));
  }, [dispatch, serialNumber]);

  // ************ useEffect ************ //
  useEffect(() => {
    getEdgeConfigFile();
  }, [getEdgeConfigFile]);

  const edgeConfigFile = useSelector(({ edgeDevices }: ApplicationState) => {
    return edgeDevices.edgeConfigFileBySerialNumber[serialNumber];
  });

  if (!edgeConfigFile) return null;
  const { data } = edgeConfigFile;

  const validationError = data?.yamlValidationErrors;

  return validationError;
};
