import { EnergyLineChartType } from '@energybox/react-ui-library/dist/components/EnergyLineChart';
import {
  CircuitBreakerFromApiResponse,
  EnergyDeviceFromApi,
  EnergyPro,
  AggregationFilter,
} from '@energybox/react-ui-library/dist/types';
import {
  Card,
  CardContent,
  CardTitle,
  DateFilter,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import {
  isDefined,
  mapArrayToObject,
  TIME_IN_MILLISECONDS,
} from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';
import uniqBy from 'ramda/src/uniqBy';

import React, { useMemo, useState } from 'react';
import ChartResolutionFilter from '../../../components/Filters/ChartResolutionFilter';
import { useAppLocale } from '../../../hooks/useAppDetails';
import useChartDetails from '../../../hooks/useChartDetails';
import { useGetSite } from '../../../hooks/useSites';
import SelectActiveEnergyPro from '../SelectActiveEnergyPro';
import EnergyChartTypeToggle from '../../../components/EnergyChartTypeToggle';
import styles from './DistributionPanelCharts.module.css';
import EquipmentChartViewToggle, {
  ChartViewType,
} from '../../../components/EquipmentChartViewToggle/EquipmentChartViewToggle';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { ProcessedEnergySensorsById } from '../../../types/energyDevice';
import { getEnergyDeviceSensorsOfEnergyPro } from '../../../utils/energyPro';
import { SensorEnergyChartCard } from '../../Chart';
import { EquipmentEnergyChartCard } from '../../../components/EquipmentEnergyChart';

type Props = {
  siteId: number;
  energyPros: {
    id: number;
    title: string;
  }[];
  activeEnergyPro: EnergyPro | undefined;
  handleActiveEnergyProChange: (selectedEnergyProId: number) => void;
};

const DistributionPanelCharts: React.FC<Props> = ({
  siteId,
  energyPros,
  activeEnergyPro,
  handleActiveEnergyProChange,
}) => {
  const [selectedView, setSelectedView] = useState<ChartViewType>('EQUIPMENT');

  const [chartType, setChartType] = useState<EnergyLineChartType>(
    EnergyLineChartType.POWER
  );
  const site = useGetSite(siteId);
  const locale = useAppLocale();
  const chartDetails = useChartDetails(locale, {
    includeAggregationFilter: true,
    timezone: site?.timeZone,
    useCurrentTime: true,
    useZoomUtils: true,
  });

  const activeEnergyProBreakers: CircuitBreakerFromApiResponse[] = useMemo(() => {
    let breakers: CircuitBreakerFromApiResponse[] = [];
    if (!activeEnergyPro) return [];

    const recursivelyCollectBreakers = (
      energyDevice: EnergyDeviceFromApi | null
    ) => {
      if (!energyDevice) return;

      energyDevice.sensors.forEach(s => {
        breakers.push(s.breaker);
      });

      if (energyDevice.busDevice) {
        recursivelyCollectBreakers(energyDevice.busDevice);
      }
    };

    activeEnergyPro.sensors.forEach(s => {
      breakers.push(s.breaker);
    });
    recursivelyCollectBreakers(activeEnergyPro.bus1Device);
    recursivelyCollectBreakers(activeEnergyPro.bus2Device);

    return uniqBy(b => b.id, breakers);
  }, [activeEnergyPro]);

  const subscribedEnergyPro = useSelector(
    ({ subscribedEnergyPros }: ApplicationState) => {
      return activeEnergyPro
        ? subscribedEnergyPros.byId[activeEnergyPro?.id]
        : null;
    },
    (prevVal, newVal) => prevVal?.id === newVal?.id
  );

  const energySensorsById: ProcessedEnergySensorsById = useMemo(() => {
    const energyDeviceSensors = getEnergyDeviceSensorsOfEnergyPro(
      activeEnergyPro
    );

    return mapArrayToObject(energyDeviceSensors || []);
  }, [activeEnergyPro]);

  const breakersWithEquipment = activeEnergyProBreakers.filter(
    b => !!b.equipmentId
  );

  // per requirement, only show charts by equipment, not by breakers
  const breakersByEquipmentId = breakersWithEquipment.reduce<{
    [equipmentId: number]: CircuitBreakerFromApiResponse[];
  }>((breakers, breaker) => {
    const equipmentId = breaker.equipmentId;
    if (isDefined(equipmentId)) {
      if (!isDefined(breakers[equipmentId]?.length)) {
        breakers[equipmentId] = [];
      }
      const foundBreaker = breakers[equipmentId]?.find(
        b => b.id === breaker.id
      );
      if (!foundBreaker) {
        breakers[equipmentId].push(breaker);
      }
    }
    return breakers;
  }, {});

  const {
    dateFilterTitle,
    dateFilterFromDate,
    dateFilterToDate,
    setDateFilter,
    zoomOut,
    isZoomedIn,
    aggregationFilter,
    setAggregationFilter,
    fromDate,
    toDate,
    isMonthly,
    isCustomRange,
    zoomRange,
  } = chartDetails;
  const fromDateMs = zoomRange.from;
  const toDateMs = zoomRange.to;
  const dateFilterFromDateJS = DateTime.fromMillis(fromDateMs).toJSDate();
  const dateFilterToDateJS = DateTime.fromMillis(toDateMs).toJSDate();

  if (!site) return null;

  const timeDelta = toDateMs - fromDateMs;
  const disableOneMinuteResolution = timeDelta > TIME_IN_MILLISECONDS.ONE_DAY;

  return (
    <Card className={styles.cardContainer}>
      <CardContent>
        <CardTitle className={styles.cardTitle}>
          <div className={styles.headerLeftAlign}>
            <span className={styles.headerTitle}>EnergyPro Charts: </span>

            <SelectActiveEnergyPro
              className={styles.energyProSelector}
              energyPros={energyPros}
              activeEnergyPro={activeEnergyPro}
              onChange={handleActiveEnergyProChange}
            />
          </div>

          <div className={styles.headerRightAlign}>
            <EquipmentChartViewToggle
              selectedView={selectedView}
              setSelectedView={setSelectedView}
            />

            <EnergyChartTypeToggle
              chartType={chartType}
              setChartType={setChartType}
            />

            {aggregationFilter && (
              <ChartResolutionFilter
                disableOneMinuteResolution={disableOneMinuteResolution}
                title={aggregationFilter.title}
                onChange={(aggFilter: AggregationFilter) =>
                  setAggregationFilter(aggFilter)
                }
              />
            )}

            <DateFilter
              value={{
                title: dateFilterTitle,
                toDate: dateFilterToDateJS,
                fromDate: dateFilterFromDateJS,
                isZoomingIn: isZoomedIn,
                isMonthly,
                isCustomRange,
              }}
              alignItemsRight
              dateFormat="datetime"
              customPickerVariant="datetime"
              setFilter={setDateFilter}
              ianaTimeZoneCode={site.timeZone}
              displayDatesAsTitle
              locale={locale}
            />

            <ResetButton
              customText="Reset Zoom"
              displayInfoTooltip
              onClick={zoomOut}
              disabled={!isZoomedIn}
            />
          </div>
        </CardTitle>

        <div>
          {selectedView === 'EQUIPMENT' &&
            Object.values(breakersByEquipmentId).map(breakers => (
              <EquipmentEnergyChartCard
                chartDetails={chartDetails}
                circuitBreaker={breakers[0]}
                site={site}
                chartType={chartType}
              />
            ))}
          {selectedView === 'CIRCUIT' &&
            subscribedEnergyPro?.sensors.map(s => {
              if (s.id === null) {
                // sensor is not setup, therefore it doesn't have an id
                return null;
              }
              return (
                <SensorEnergyChartCard
                  chartDetails={chartDetails}
                  sensor={s}
                  breaker={energySensorsById[s.id]?.breaker}
                  site={site}
                  chartType={chartType}
                />
              );
            })}
        </div>
      </CardContent>
    </Card>
  );
};

export default React.memo(DistributionPanelCharts);
