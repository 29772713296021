import {
  BreakerType,
  CircuitBreakerFromApiResponse,
  CircuitBreakerInPanel,
} from '@energybox/react-ui-library/dist/types';
import {
  ActionPayLoad,
  UpdateEnergyDeviceAction,
  UpdateEntity,
} from './DPSetUpTable';
import { useDispatch } from 'react-redux';
import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import { global } from '@energybox/react-ui-library/dist/utils';
import React, { ReactElement } from 'react';
import { showNewCircuitBreakerModal } from '../../../actions/circuit_breakers';
import styles from './DPSetUpTable.module.css';

type BreakerDropdownProps = {
  setupMode?: boolean;
  disabled?: boolean;
  deviceTitle: string;
  breakers: CircuitBreakerInPanel[];
  selectedBreaker: CircuitBreakerFromApiResponse | undefined;
  selectedBreakerPole: number | undefined;
  energyDeviceId: number;
  port: number;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
};

const BreakerDropdown = ({
  setupMode,
  disabled,
  selectedBreaker,
  selectedBreakerPole,
  deviceTitle,
  energyDeviceId,
  port,
  breakers,
  setUnconfirmedUpdateAction,
}: BreakerDropdownProps) => {
  const reduxDispatch = useDispatch();
  return (
    <div className={setupMode ? styles.breakerDropdownSetupMode : undefined}>
      <Select
        disabled={disabled}
        noBottomLine
        title={
          selectedBreaker
            ? `${selectedBreaker.title} pole ${selectedBreakerPole}` || ''
            : global.NOT_AVAILABLE
        }
        variant="select"
        value={selectedBreaker?.title}
        className={styles.selectButtonText}
      >
        <SelectItem
          isSelected={false}
          onSelect={() => reduxDispatch(showNewCircuitBreakerModal())}
        >
          Add New Circuit Breaker
        </SelectItem>
        {breakers.map(breaker => {
          const {
            breaker: { id: breakerId, type: breakerType },
          } = breaker;
          let breakerPoleCount = 1;
          switch (breakerType) {
            case BreakerType.SINGLE_POLE:
              breakerPoleCount = 1;
              break;
            case BreakerType.DOUBLE_POLE:
              breakerPoleCount = 2;
              break;
            case BreakerType.THREE_POLE:
              breakerPoleCount = 3;
              break;
          }
          let breakerPoleOptions: ReactElement[] = [];
          for (let i = 1; i <= breakerPoleCount; i++) {
            breakerPoleOptions.push(
              <SelectItem
                key={`${energyDeviceId}${port}${breakerId}pole${i}`}
                isSelected={
                  breakerId === selectedBreaker?.id && i === selectedBreakerPole
                }
                onSelect={() => {
                  if (breakerId !== selectedBreaker?.id) {
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.BREAKER_ID,
                      deviceTitle,
                      id: energyDeviceId,
                      port,
                      value: breaker.breaker.id,
                      entity: UpdateEntity.BREAKER,
                    });
                  }
                  setUnconfirmedUpdateAction({
                    type: UpdateEnergyDeviceAction.BREAKER_POLE,
                    deviceTitle,
                    id: energyDeviceId,
                    port,
                    value: i,
                    entity: UpdateEntity.ENERGY_DEVICE,
                  });
                }}
              >
                {breaker.breaker.title}
                {breakerPoleCount > 1 ? ` pole ${i}` : ''}
              </SelectItem>
            );
          }
          return (
            <React.Fragment
              key={`${energyDeviceId}${port}${breaker.breaker.id}`}
            >
              {breakerPoleOptions}
            </React.Fragment>
          );
        })}
      </Select>
    </div>
  );
};

export default BreakerDropdown;
