import { classNames, isDefined } from '@energybox/react-ui-library/dist/utils';
import { usePrevious } from '@energybox/react-ui-library/dist/hooks';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import styles from './EditHvacSopForm.module.css';

const ToggleRow = ({
  title,
  titleRowAction = undefined,
  minimizedContent,
  className,
  // infoIcon,
  children,
  forceMinimizeCounter,
}: {
  title: string | undefined;
  titleRowAction?: JSX.Element;
  minimizedContent?: JSX.Element;
  className?: string;
  // infoIcon: JSX.Element;
  children?: JSX.Element;
  forceMinimizeCounter?: number;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const prevCounter = usePrevious(forceMinimizeCounter);

  useEffect(() => {
    const isForceMinimizeValid =
      isDefined(prevCounter) && forceMinimizeCounter === prevCounter + 1;

    if (isForceMinimizeValid) {
      setIsExpanded(false);
    }
  }, [prevCounter, forceMinimizeCounter]);

  return (
    <div className={className}>
      <div className={classNames(styles.row, styles.toggleRowTitle)}>
        <div className={styles.row}>
          {isExpanded ? (
            <IoIosArrowDown
              size="16"
              className={styles.arrowIcon}
              onClick={() => setIsExpanded(false)}
            />
          ) : (
            <IoIosArrowForward
              size="16"
              className={styles.arrowIcon}
              onClick={() => setIsExpanded(true)}
            />
          )}
          <span>{title}</span>
          {/* {infoIcon} */}
        </div>
        {isDefined(titleRowAction) ? titleRowAction : <></>}
      </div>
      {!isExpanded && (
        <div className={styles.toggleRowMinimizedContent}>
          {minimizedContent}
        </div>
      )}
      {isExpanded && children}
    </div>
  );
};

export default ToggleRow;
