import {
  ControlOverridesDisplay,
  ShortenedSpan,
} from '@energybox/react-ui-library/dist/components';
import { Equipment, HvacControl } from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import styles from './HvacSopTable.module.css';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';

type Props = {
  equipmentsMatchingSite: Equipment[];
  isInactiveSop?: boolean;
  index?: Number;
};
const EquipmentCellData: React.FC<Props> = ({
  equipmentsMatchingSite,
  isInactiveSop,
  index,
}) => {
  return (
    <div>
      {isInactiveSop
        ? global.NOT_AVAILABLE
        : index === 0
        ? renderCorrespondingEquipment(equipmentsMatchingSite)
        : ''}
    </div>
  );
};
export const renderCorrespondingEquipment = (
  equipmentsMatchingSite: Equipment[]
) => {
  if (equipmentsMatchingSite.length === 0) {
    return <span>{global.NOT_AVAILABLE}</span>;
  }

  return equipmentsMatchingSite.map(equipment => {
    return <EquipmentAndWorkingMode key={equipment.id} equipment={equipment} />;
  });
};
const EquipmentAndWorkingMode = ({ equipment }: { equipment: Equipment }) => {
  const workingMode = equipment.activeControl?.workingMode;
  const thermostatId = (equipment.activeControl as HvacControl)?.thermostatId;

  if (!isDefined(equipment.title)) {
    return null;
  }

  const renderSecondaryInfo = () => {
    if (!isDefined(thermostatId)) {
      return (
        <span className={`${styles.secondaryInfoText} ${styles.redText}`}>
          No Thermostat
        </span>
      );
    }

    return (
      <span className={styles.secondaryInfoText}>
        <span className={styles.paddingRight}>Control Mode: </span>

        {isDefined(workingMode) ? (
          <ControlOverridesDisplay
            controlWorkingMode={workingMode}
            // Jan & Ananya don't want to show local override here - so rather than editing the component, just always send false
            isLocalOverrideActive={false}
          />
        ) : (
          global.NOT_AVAILABLE
        )}
      </span>
    );
  };

  return (
    <div className={styles.equipmentDetails}>
      <Link to={`${Routes.EQUIPMENT}/${equipment.id}`} target="_blank">
        <ShortenedSpan content={equipment.title} maxStringLength={20} />
      </Link>
      {renderSecondaryInfo()}
    </div>
  );
};
export default EquipmentCellData;
