import {
  Card,
  CardContent,
  CardTitle,
  InfoChip,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  Gateway,
  GatewayNetworkInterfaceHumanReadable,
  StaticIp,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import styles from './GatewayCommonCardStyle.module.css';

interface Props {
  gateway: Gateway | ControlBoard;
}

export default ({ gateway }: Props) => {
  return (
    <Card>
      <CardContent>
        <div className={styles.generalCardGrid}>
          <CardTitle className={styles.cardTitleExtraPadding}>
            Configuration
          </CardTitle>

          <div>
            <InfoChip
              title="IP Address"
              fields={[
                gateway &&
                  gateway.gatewayConfig &&
                  gateway.gatewayConfig.ipAddress,
              ]}
            />
            <InfoChip
              title="Default Gateway"
              fields={[
                gateway &&
                  gateway.gatewayConfig &&
                  gateway.gatewayConfig.defaultGateway,
              ]}
            />
            <InfoChip
              title="Subnet Mask"
              fields={[
                gateway &&
                  gateway.gatewayConfig &&
                  gateway.gatewayConfig.subnetMask,
              ]}
            />

            <InfoChip
              title="DNS 1"
              fields={[
                gateway && gateway.gatewayConfig && gateway.gatewayConfig.dns1,
              ]}
            />

            <InfoChip
              title="DNS 2"
              fields={[
                gateway && gateway.gatewayConfig && gateway.gatewayConfig.dns2,
              ]}
            />
          </div>

          <div>
            {/* staticIp true/false represents the inverse of dhcp status. So we ! the value to get the correct label */}
            <InfoChip
              title="DHCP status"
              fields={[
                gateway &&
                  gateway.gatewayConfig &&
                  (gateway.gatewayConfig.staticIp === undefined
                    ? StaticIp[String(undefined)]
                    : StaticIp[String(!gateway.gatewayConfig.staticIp)]),
              ]}
            />
            <InfoChip
              title="Network Allowance"
              fields={[
                gateway && gateway.gatewayConfig
                  ? GatewayNetworkInterfaceHumanReadable[
                      gateway.gatewayConfig.networkInterface
                    ]
                  : '',
              ]}
            />
            <InfoChip
              title="PanID"
              fields={[gateway.gatewayConfig?.rfPanId ?? global.NOT_AVAILABLE]}
            />
            <InfoChip
              title="RF Frequency Range (MHz)"
              fields={[
                gateway.gatewayConfig?.rfFrequencyRange ?? global.NOT_AVAILABLE,
              ]}
            />
          </div>
          <div>
            <InfoChip
              title="NTP Pool List"
              fields={[
                gateway && gateway.gatewayConfig && gateway.gatewayConfig.ntp,
              ]}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
