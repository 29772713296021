import {
  DistributionPanel,
  DistributionPanelType,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { EditDistributionPanel } from '../../reducers/distribution_panels';
import { useEffect } from 'react';
import {
  getDistributionPanel,
  updateField,
} from '../../actions/distribution_panel';

export const useDistributionPanelType = (
  panelId: number | string | undefined
) => {
  const panelType = useSelector<
    ApplicationState,
    DistributionPanelType | undefined
  >(({ distributionPanels }) => {
    if (isDefined(panelId)) {
      return distributionPanels.distributionPanelsById[panelId]?.type;
    }
    return undefined;
  });
  return panelType;
};

export const useDistributionPanelEditById = (
  panelId: number | string,
  isEditMode: boolean
) => {
  const dispatch = useDispatch();

  const panel = useSelector<ApplicationState, EditDistributionPanel>(
    ({ distributionPanels }) => {
      return distributionPanels.editById[panelId] || undefined;
    }
  );

  useEffect(() => {
    if (!panel) {
      dispatch(getDistributionPanel(panelId));
    }
  }, [panel, panelId, dispatch]);

  useEffect(() => {
    if (!isEditMode) {
      dispatch(getDistributionPanel(panelId));
    }
  }, [isEditMode, panelId, dispatch]);

  return (
    panel || {
      fields: {},
      formErrors: {},
      formErrorsVisible: false,
      apiError: {},
    }
  );
};
