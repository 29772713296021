import {
  IconEquipmentFactory,
  LongExtraLongSkeletonCell,
  MediaElement,
  MediumLongSkeletonCell,
  ResourceSentinelsQuickInfo,
  SearchBox,
  ShortSkeletonCell,
  Table,
} from '@energybox/react-ui-library/dist/components';
import {
  Equipment,
  OpacityIndex,
  ResourcePath,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  displayEquipmentActiveControlType,
  genericTableSort,
  hasSubstr,
  SORT_IGNORED_VALUES,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getEquipmentGroups, getEquipmentTypes } from '../../actions/app';
import {
  Actions,
  getEquipmentBySiteId,
  getEquipments,
  clearEquipment,
} from '../../actions/equipment';
import { resetFilters, setEquipmentTypeFilter } from '../../actions/filters';
import {
  getResourcePathsByIds,
  validateResourcePath,
} from '../../actions/paths';
import { getSentinelsByEquipmentMapping } from '../../actions/sentinels';
import { getTimeTables } from '../../actions/time_tables';
import EquipmentMenuFilter from '../../components/Filters/EquipmentMenuFilter';
import SiteFilter from '../../components/SiteFilter';
import { TableWrapper } from '../../components/ui/Table';
import { EquipmentGroupsById, EquipmentTypesById } from '../../reducers/app';
import { PathsById } from '../../reducers/paths';
import { SentinelsByResourceId } from '../../reducers/sentinels';
import { Routes } from '../../routes';
import { PropertyToLabel, Placeholder } from '../../types/global';
import SopInfo from '../Sop/SopInfo';
import history from '../../history';
import theme from '../../theme';
import FiltersContainer from '../../components/Filters/FiltersContainer/FiltersContainer';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';
import SiteGroupFilter from '../../components/SiteGroupFilter';
import useSiteGroupsFilter from '../../hooks/useSiteGroupsFilter';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import useDynamicFilter from '../../hooks/useDynamicFilter';
import useSiteFilter from '../../hooks/useSiteFilter';
import usePaginationFilter from '../../hooks/usePaginationFilter';
import { displaySchedulerDetails } from '@energybox/react-ui-library/dist/utils/controls';
import { getSopComponents } from '../../actions/sops';
import { SopComponentsByResourceId } from '../../reducers/sop';
import { filterDataBySiteId } from '../../util';
import EquipmentResourcePaths from './EquipmentResourcePaths';

interface OwnProps {
  siteId?: number;
}

interface Props extends OwnProps {
  getEquipmentBySiteId: typeof getEquipmentBySiteId;
  getEquipments: typeof getEquipments;
  getEquipmentTypes: typeof getEquipmentTypes;
  getEquipmentGroups: typeof getEquipmentGroups;
  getSopComponents: typeof getSopComponents;
  getResourcePathsByIds: typeof getResourcePathsByIds;
  getTimeTables: typeof getTimeTables;
  getSentinelsByEquipmentMapping: typeof getSentinelsByEquipmentMapping;
  resetFilters: () => void;
  clearEquipment: typeof clearEquipment;
  setEquipmentTypeFilter: typeof setEquipmentTypeFilter;
  setPagination?: (
    page: number | undefined,
    rowLimit: number | undefined
  ) => void;
  currentPage: number | undefined;
  rowLimit: number | undefined;
  orgId?: number;
  getEquipmentsIsLoading?: boolean;
  equipmentTypesById: EquipmentTypesById;
  equipmentGroupsById: EquipmentGroupsById;
  equipments: Equipment[];
  resourcePathsById: PathsById;
  sentinelsByResourceId: SentinelsByResourceId;
  selectedEquipmentTypeIds: number[];
  viewportType: ViewportTypes;
  selectedSiteFilters: string[];
  siteGroupWithoutSites: boolean;
  selectedSiteGroups: string[];
  equipmentSopData: SopComponentsByResourceId;
}

interface State {
  query: string;
  equipmentLoadingGuard: boolean;
}

const withDynamicFilter = WrappedComponent => {
  return props => {
    const dataLength = useMemo(() => props.equipments?.length, [
      props?.equipments?.length,
    ]);

    const { currentPage, rowLimit, setPagination } = usePaginationFilter(
      dataLength
    );

    const {
      selectedFilters: selectedEquipmentTypeIds,
      setFilter: setEQTypeFilter,
    } = useDynamicFilter<number>('eqType', value => parseInt(value));

    const { siteGroupWithoutSites, selectedSiteGroups } = useSiteGroupsFilter();
    const { selectedSiteFilters } = useSiteFilter();

    return (
      <WrappedComponent
        selectedSiteGroups={selectedSiteGroups}
        setPagination={setPagination}
        rowLimit={rowLimit}
        currentPage={currentPage}
        siteGroupWithoutSites={siteGroupWithoutSites}
        selectedSiteFilters={selectedSiteFilters}
        setEQTypeFilter={setEQTypeFilter}
        selectedEquipmentTypeIds={selectedEquipmentTypeIds}
        {...props}
      />
    );
  };
};

class EquipmentTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: '',
      equipmentLoadingGuard: true,
    };
  }

  componentDidMount() {
    const {
      orgId,
      equipmentTypesById,
      equipmentGroupsById,
      getEquipmentGroups,
      getEquipmentTypes,
      getTimeTables,
      getSentinelsByEquipmentMapping,
      selectedEquipmentTypeIds,
      selectedSiteFilters,
      equipments,
      getSopComponents,
      getResourcePathsByIds,
    } = this.props;

    getSentinelsByEquipmentMapping();

    if (!Object.values(equipmentTypesById).length) {
      getEquipmentTypes();
    }
    if (!Object.values(equipmentGroupsById).length) {
      getEquipmentGroups();
    }

    setTimeout(() => {
      this.setState({ equipmentLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams<string>(history, 'query', '');

    this.setState({ query: savedQuery as string });

    if (
      (!selectedSiteFilters?.length && !selectedEquipmentTypeIds?.length) ||
      !equipments?.length
    ) {
      this.updateEquipmentList();
    }

    if (!selectedSiteFilters.length && !selectedEquipmentTypeIds.length) {
      this.updateEquipmentList();
    } else {
      if (selectedEquipmentTypeIds?.length) {
        setEquipmentTypeFilter(selectedEquipmentTypeIds);
      }
    }

    orgId && getTimeTables(orgId);
    getSopComponents('EQUIPMENT');

    if (equipments.length > 0) {
      const equipmentIds = equipments.map(equipment => equipment.id);
      getResourcePathsByIds(equipmentIds);
    }
  }

  componentDidUpdate = prevProps => {
    const {
      siteId,
      selectedEquipmentTypeIds,
      selectedSiteFilters,
      selectedSiteGroups,
      getResourcePathsByIds,
      equipments,
    } = this.props;

    if (
      selectedSiteGroups.join('') !== prevProps.selectedSiteGroups.join('') ||
      selectedSiteFilters.join('') !== prevProps.selectedSiteFilters.join('') ||
      selectedEquipmentTypeIds.join('') !==
        prevProps.selectedEquipmentTypeIds.join('') ||
      siteId !== prevProps.siteId ||
      !this.props.equipments?.length
    ) {
      this.updateEquipmentList();
    }

    if (
      prevProps.equipments !== equipments &&
      equipments.length > 0 &&
      prevProps.equipments.length !== equipments.length
    ) {
      const equipmentIds = equipments.map(equipment => equipment.id);
      getResourcePathsByIds(equipmentIds);
    }
  };

  componentWillUnmount = () => {
    this.props.resetFilters();
    this.props.clearEquipment();
  };

  getEquipmentTypesAvailable = (equipmentList: Equipment[]) => {
    const equipmentTypeIds = equipmentList.reduce((acc, curr) => {
      if (curr.type) {
        acc.add(curr.type.id);
      }
      return acc;
    }, new Set<number>());

    return Array.from(equipmentTypeIds);
  };

  updateEquipmentList = () => {
    const {
      siteId,
      getEquipments,
      selectedEquipmentTypeIds,
      selectedSiteFilters,
    } = this.props;

    if (siteId) {
      getEquipments(
        {
          siteIds: [siteId],
          equipmentTypeIds: selectedEquipmentTypeIds?.length
            ? selectedEquipmentTypeIds
            : undefined,
        },
        siteId
      );
      return;
    }

    if (!selectedSiteFilters?.length && !selectedEquipmentTypeIds?.length) {
      getEquipments();
      return;
    }

    getEquipments({
      siteIds: selectedSiteFilters?.length ? selectedSiteFilters : undefined,
      equipmentTypeIds: selectedEquipmentTypeIds.length
        ? selectedEquipmentTypeIds
        : undefined,
    });
  };

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  filterByEquipments = (data, query) => {
    return data?.filter((equipment: Equipment) =>
      hasSubstr(`${equipment.title}`, query)
    );
  };

  filterByEquipmentIds = (data, selectedEquipmentTypeIds) => {
    return data?.filter(equipments =>
      selectedEquipmentTypeIds.includes(equipments?.typeId)
    );
  };

  render() {
    const {
      orgId,
      siteId,
      equipments,
      equipmentTypesById,
      equipmentGroupsById,
      getEquipmentsIsLoading,
      resourcePathsById,
      sentinelsByResourceId,
      viewportType,
      siteGroupWithoutSites,
      currentPage,
      setPagination,
      rowLimit,
      selectedEquipmentTypeIds,
      equipmentSopData,
    } = this.props;
    const isMobile = viewportType === ViewportTypes.MOBILE;
    const { query, equipmentLoadingGuard } = this.state;
    const isLoading = getEquipmentsIsLoading || equipmentLoadingGuard;
    let filteredData = equipments;
    const equipmentFiltersTypesAvailable = this.getEquipmentTypesAvailable(
      filteredData
    );

    if (query && query.length >= 3) {
      filteredData = this.filterByEquipments(filteredData, query);
    }

    if (selectedEquipmentTypeIds?.length) {
      filteredData = this.filterByEquipmentIds(
        filteredData,
        selectedEquipmentTypeIds
      );
    }

    const columns = [
      {
        width: '15%',
        header: 'Equipment',
        cellContent: (equipment: Equipment) => (
          <MediaElement
            icon={
              equipmentTypesById &&
              !!equipment.typeId && (
                <IconEquipmentFactory
                  id={(equipmentTypesById[equipment.typeId] || {}).alias}
                  size={40}
                />
              )
            }
            title={
              <Link to={`${Routes.EQUIPMENT}/${equipment.id}`}>
                {equipment.title}
              </Link>
            }
            description={equipment.description}
          />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: Equipment,
          b: Equipment,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '10%',
        header: `${PropertyToLabel.siteId} / ${PropertyToLabel.spaceId}`,
        cellContent: (equipment: Equipment) => (
          <EquipmentResourcePaths key={equipment.id} id={equipment.id} />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: Equipment,
          b: Equipment,
          sortDirection: SortDirection
        ) => {
          const getLocation = (eq: Equipment) => {
            return (resourcePathsById[eq.id] || [])
              .map((p: ResourcePath) => p.title)
              .join();
          };
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getLocation,
            ['title']
          );
        },
      },
      {
        width: '10%',
        header: 'Equipment Group',
        cellContent: (equipment: Equipment) => (
          <span>
            {equipmentGroupsById &&
            (equipment.groupId || equipment.groupId === 0)
              ? (equipmentGroupsById[equipment.groupId] || {}).title || '-'
              : '-'}
          </span>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: Equipment,
          b: Equipment,
          sortDirection: SortDirection
        ) => {
          const getEquipmentGroupTitle = (eq: Equipment) => {
            return pathOr(
              undefined,
              [eq.groupId, 'title'],
              equipmentGroupsById
            );
          };

          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getEquipmentGroupTitle
          );
        },
      },
      {
        width: '10%',
        header: 'Equipment Type',
        cellContent: (equipment: Equipment) => (
          <span>
            {' '}
            {equipmentTypesById && (equipment.typeId || equipment.typeId === 0)
              ? (equipmentTypesById[equipment.typeId] || {}).title || '-'
              : '-'}
          </span>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: Equipment,
          b: Equipment,
          sortDirection: SortDirection
        ) => {
          const getEquipmentTypeTitle = (eq: Equipment) => {
            return pathOr(undefined, [eq.typeId, 'title'], equipmentTypesById);
          };

          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getEquipmentTypeTitle
          );
        },
      },
      {
        width: '15%',
        header: 'Controls',
        cellContent: (equipment: Equipment) => (
          <span>
            {displayEquipmentActiveControlType(equipment.activeControl)} <br />
            {displaySchedulerDetails(equipment.activeControl)}
          </span>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: Equipment,
          b: Equipment,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            (equipment: Equipment) =>
              displayEquipmentActiveControlType(equipment.activeControl)
          );
        },
      },
      {
        width: '15%',
        header: 'SOP Policies',
        cellContent: (equipment: Equipment) => {
          const sopData =
            equipmentSopData &&
            filterDataBySiteId(equipmentSopData, equipment.id);
          return (
            <SopInfo
              resourceId={String(equipment.id)}
              sopTypeCategory={'policy'}
              isEquipment={true}
              sopData={sopData}
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '15%',
        header: 'SOP Cost',
        cellContent: (equipment: Equipment) => {
          const sopData =
            equipmentSopData &&
            filterDataBySiteId(equipmentSopData, equipment.id);
          return (
            <SopInfo
              resourceId={String(equipment.id)}
              sopTypeCategory={'cost'}
              isEquipment={true}
              sopData={sopData}
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '10%',
        header: 'Sentinels',
        cellContent: (equipment: Equipment) => {
          const sentinels = sentinelsByResourceId[equipment.id] || [];
          if (sentinels.length === 0) return global.NOT_AVAILABLE;
          return (
            <Link to={`${Routes.EQUIPMENT}/${equipment.id}`}>
              <ResourceSentinelsQuickInfo
                resourceId={equipment.id}
                sentinels={sentinels}
                arrowDirection="left"
              />
            </Link>
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: Equipment,
          b: Equipment,
          sortDirection: SortDirection
        ) => {
          const getLocation = (equipment: Equipment) => {
            const sentinels = sentinelsByResourceId[equipment.id] || [];
            return sentinels.length;
          };
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getLocation
          );
        },
      },
    ];

    const hasSiteFilter = !isDefined(siteId);
    return (
      <DynamicContentWrapper>
        <PageContentHeader header={'All Equipments'}>
          <SearchBox
            placeholder={Placeholder.seachBox}
            onChange={this.handleSearchChange}
            query={query}
            width={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            widthActive={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            error={filteredData?.length === 0}
          />
        </PageContentHeader>
        <FiltersContainer>
          {hasSiteFilter && <SiteFilter />}
          {hasSiteFilter && <SiteGroupFilter />}
          <EquipmentMenuFilter
            orgId={orgId}
            siteId={siteId}
            label="Equipment Type"
            relevantItemTypeIds={equipmentFiltersTypesAvailable}
          />
        </FiltersContainer>
        <TableWrapper
          isLoading={isLoading}
          header={`${filteredData?.length} Equipment`}
          pageNavHidden={filteredData?.length == 0 ? true : false}
        >
          <Table
            listView
            uniqueKeyField="id"
            columns={columns}
            data={!siteGroupWithoutSites ? filteredData : []}
            dataIsLoading={isLoading}
            rowLimitFromPaginationHook={rowLimit}
            currentPageFromPaginationHook={currentPage}
            setPagination={setPagination}
          />
        </TableWrapper>
      </DynamicContentWrapper>
    );
  }
}

const mapStateToProps = (
  { app, equipment, resourcePaths, sentinels, sops }: ApplicationState,
  { siteId }: OwnProps
) => {
  return {
    sentinelsByResourceId: sentinels.byResourceId,
    orgId: app.currentOrganizationId,
    equipments: (siteId
      ? (equipment.equipmentIdsBySiteId[siteId] || []).map(
          id => equipment.equipmentById[id]
        )
      : equipment.equipmentPayloadArray
    ).filter(item => item),
    equipmentTypesById: app.equipmentTypesById || {},
    equipmentGroupsById: app.equipmentGroupsById || {},
    resourcePathsById: resourcePaths.byIds,
    getEquipmentsIsLoading:
      equipment.loadingStatusByAction[Actions.GET_EQUIPMENTS_LOADING],
    equipmentSopData: sops.sopComponentData,
  };
};

const mapDispatchToProps = {
  getSentinelsByEquipmentMapping,
  getEquipmentTypes,
  getEquipmentGroups,
  getEquipments,
  validateResourcePath,
  getEquipmentBySiteId,
  getTimeTables,
  resetFilters,
  setEquipmentTypeFilter,
  clearEquipment,
  getSopComponents,
  getResourcePathsByIds,
};

export default withViewportType(
  withDynamicFilter(
    connect(mapStateToProps, mapDispatchToProps)(EquipmentTable)
  )
);
