const API_KEY =
  'AsYmWCFPsQuOdVix5qJDtSCJcxhl_uXxGYCNrTzhVU95PljStzkMOcFz_hx5vh3r';

function limitDecimals(n: number): number {
  return parseFloat(n.toFixed(7));
}

export function resolveAddressToCoordinates(
  onSuccess: (latitude: number, longitude: number) => void,
  country: string,
  street: string,
  state: string,
  city: string,
  postalCode: string
) {
  const queryString = `${country}, ${street}, ${postalCode}, ${city}, ${state}`;

  fetch(
    `https://dev.virtualearth.net/REST/v1/Locations?query=${encodeURIComponent(
      queryString
    )}&maxResults=1&key=${API_KEY}`
  )
    .then(r => r.json())
    .then((r: any) => {
      if (r.resourceSets.length === 0) {
        return;
      }
      const result = r.resourceSets[0];
      if (result.resources.length === 0) {
        return;
      }

      const resource = result.resources[0];

      const {
        point: { coordinates },
      } = resource;

      if (coordinates.length === 0) {
        return;
      }

      const [latitude, longitude] = coordinates;

      onSuccess(limitDecimals(latitude), limitDecimals(longitude));
    })
    .catch(err => console.log(err));
}

export const getTimezoneFromCity = async (
  {
    city,
    countryName,
  }: {
    city: string;
    countryName: string;
  },
  onSuccess: (field: string, value: any) => void
) => {
  try {
    const res = await fetch(
      `https://dev.virtualearth.net/REST/v1/TimeZone/query=${city},${countryName}?key=${API_KEY}`
    );
    const dataObject = await res.json();
    const timeZoneData =
      dataObject.resourceSets[0].resources[0].timeZoneAtLocation[0];
    onSuccess('timeZone', timeZoneData.timeZone[0].ianaTimeZoneId);
  } catch (err) {
    console.log(err);
  }
};
