import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getResourcePathsByIds } from '../../actions/paths';
import { ApplicationState } from '../../reducers';
import { PathsById, IsPathLoadingById } from '../../reducers/paths';
import { ResourceType } from '@energybox/react-ui-library/dist/types';
import { Routes } from '../../routes';

type OwnProps = {
  id: number;
};

type StateProps = {
  resourcePathsById: PathsById;
  resourcePathsIsLoadingById: IsPathLoadingById;
};

type DispatchProps = {
  getResourcePathsByIds: typeof getResourcePathsByIds;
};

type Props = OwnProps & StateProps & DispatchProps;

const EquipmentResourcePaths: React.FC<Props> = ({
  id,
  resourcePathsById,
  resourcePathsIsLoadingById,
  getResourcePathsByIds,
}) => {
  const resourcePath = resourcePathsById[id] || [];
  const isLoading = resourcePathsIsLoadingById[id];

  React.useEffect(() => {
    if (!resourcePath.length && !isLoading) {
      getResourcePathsByIds([id]);
    }
  }, [id, resourcePath, isLoading, getResourcePathsByIds]);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (resourcePath.length) {
    const [site, ...rest] = resourcePath;
    return (
      <>
        {site && (
          <div>
            <Link to={`${Routes.SITES}/${site.id}`}>
              <strong>{site.title} </strong>
            </Link>
          </div>
        )}
        {rest.map(r => r.title).join(' > ')}
      </>
    );
  }

  return <>Not Available</>;
};

const mapStateToProps = ({ resourcePaths }: ApplicationState): StateProps => ({
  resourcePathsById: resourcePaths.byIds,
  resourcePathsIsLoadingById: resourcePaths.isLoadingById,
});

const mapDispatchToProps: DispatchProps = {
  getResourcePathsByIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentResourcePaths);
