import { Button } from '@energybox/react-ui-library/dist/components';
import { SentinelScheduleWeekDay } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './ScheduleWeekDayButton.module.css';

type Props = {
  day: SentinelScheduleWeekDay;
  onClick: any;
  isActive?: boolean;
};

function ScheduleWeekDayButton(props: Props) {
  const { day, onClick, isActive } = props;

  return (
    <Button onClick={() => onClick()}>
      <span
        className={classNames(
          styles.root,
          isActive ? styles.active : undefined
        )}
      >
        {day.substr(0, 2).toLowerCase()}
      </span>
    </Button>
  );
}

export default ScheduleWeekDayButton;
