import {
  Equipment,
  GenericErrors,
  ReportType,
  ReportTypeLabel,
  ReportTypeSteps,
} from '@energybox/react-ui-library/dist/types';
import { Steps } from '@energybox/react-ui-library/dist/components';

import React from 'react';
import ReportSummary from '../../../components/Reports/ReportSummary';
import SelectEquipment from '../../../components/Reports/SelectEquipment';
import SelectReportType from '../../../components/Reports/SelectReportType';
import SetupTemperatureChecks from '../../../components/Reports/SetupTemperatureChecks';
import { EditableFields } from '../../../reducers/reports';
import styles from './ReportContent.module.css';

type Props = {
  stepNumber: number;
  fields: EditableFields;
  equipmentList: Equipment[];
  onReportTypeClick: (reportType: ReportType) => void;
  updateReportField: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
};

const ReportContent: React.FC<Props> = ({
  stepNumber,
  fields,
  equipmentList,
  updateReportField,
  onReportTypeClick,
  formErrors,
  formErrorsVisible,
}) => {
  const {
    title,
    description,
    resourceIds,
    periods,
    reportType,
    useAverageOfReadings,
  } = fields;

  const renderStepContent = () => {
    switch (stepNumber) {
      case 0:
        return <SelectReportType onReportTypeClick={onReportTypeClick} />;

      case 1:
        return (
          <SetupTemperatureChecks
            title={title}
            description={description}
            periods={periods}
            useAverageOfReadings={useAverageOfReadings}
            updateReportField={updateReportField}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
          />
        );

      case 2:
        return (
          <SelectEquipment
            equipmentList={equipmentList}
            resourceIds={resourceIds}
            updateReportField={updateReportField}
          />
        );

      case 3:
        return <ReportSummary fields={fields} equipmentList={equipmentList} />;

      default:
        return <div />;
    }
  };

  return (
    <div className={styles.root}>
      {reportType !== undefined && stepNumber !== 0 && (
        <div className={styles.stepsContainer}>
          <div className={styles.title}>{ReportTypeLabel[reportType]}</div>
          <div>
            <Steps
              stepsList={ReportTypeSteps[reportType]}
              currentIndex={stepNumber - 1}
            />
          </div>
        </div>
      )}
      <div className={styles.content}>{renderStepContent()}</div>
    </div>
  );
};

export default ReportContent;
