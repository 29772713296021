import {
  GenericErrors,
  Scheduler,
  SchedulerDataStatus,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions as ControlsActions } from '../actions/controls';
import { Actions } from '../actions/schedulers';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import {
  DEFAULT_ACTION_INTERVAL,
  DEFAULT_HYSTERESIS,
  DEFAULT_LUX_THRESHOLD,
} from '../constants/scheduler';

const initialState: Schedulers = {
  schedulerByEquipmentId: {},
  schedulerLoading: false,
  editById: {},
  apiError: {},
  schedulerBatchByEquipmentId: [],
};

type SchedulerByEquipmentId = {
  [id: string]: SchedulerDataStatus;
};

export type EditSchedulerById = {
  [scheduleId: string]: EditScheduler;
};

export type EditScheduler = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};
export type EditableFields = {
  beginDelta: string;
  endDelta: string;
  timetableId: number | string | null;
  workingMode: WorkingMode | undefined;
  lightSensorSettings: {
    controlBoardId: number | null;
    threshold: number;
    hysteresis: number;
    timetableId: number | null;
    beginDelta: string;
    endDelta: string;
    actionInterval: number;
  };
};

const editableFields = (scheduler: Scheduler): EditableFields => ({
  beginDelta: Number(scheduler.beginDelta.slice(0, -1)).toString(),
  endDelta: Number(scheduler.endDelta.slice(0, -1)).toString(),
  timetableId: scheduler.timetableId,
  workingMode: scheduler.workingMode,
  lightSensorSettings: isDefined(scheduler.lightSensorSettings)
    ? {
        beginDelta: Number(
          scheduler.lightSensorSettings.beginDelta.slice(0, -1)
        ).toString(),
        endDelta: Number(
          scheduler.lightSensorSettings.endDelta.slice(0, -1)
        ).toString(),
        timetableId: scheduler.lightSensorSettings.timetableId,
        controlBoardId: scheduler.lightSensorSettings.controlBoardId,
        threshold: scheduler.lightSensorSettings.threshold,
        hysteresis: scheduler.lightSensorSettings.hysteresis,
        actionInterval: scheduler.lightSensorSettings.actionInterval,
      }
    : {
        beginDelta: '0',
        endDelta: '0',
        timetableId: -1,
        controlBoardId: -1,
        threshold: DEFAULT_LUX_THRESHOLD,
        hysteresis: DEFAULT_HYSTERESIS,
        actionInterval: DEFAULT_ACTION_INTERVAL,
      },
});

const newSchedulerFields: EditableFields = {
  beginDelta: '0',
  endDelta: '0',
  timetableId: -1,
  workingMode: undefined,
  lightSensorSettings: {
    beginDelta: '0',
    endDelta: '0',
    timetableId: -1,
    controlBoardId: -1,
    threshold: DEFAULT_LUX_THRESHOLD,
    hysteresis: DEFAULT_HYSTERESIS,
    actionInterval: DEFAULT_ACTION_INTERVAL,
  },
};

const createNewScheduler = () => {
  const fieldCopy = Object.assign({}, newSchedulerFields);
  return {
    isLoading: false,
    isChanged: false,
    fields: fieldCopy,
    formErrors: formValidationErrors('scheduler', fieldCopy),
    formErrorsVisible: false,
    apiError: {},
  };
};

export interface Schedulers {
  schedulerByEquipmentId: SchedulerByEquipmentId;
  schedulerLoading: boolean;
  editById: EditSchedulerById;
  apiError: ApiError;
  schedulerBatchByEquipmentId: SchedulerByEquipmentId[];
}

const schedulers = (state: Schedulers = initialState, action: any) => {
  switch (action.type) {
    case Actions.CREATE_NEW_SCHEDULER_IN_STORE:
      return R.pipe(R.assocPath(['editById', 'new'], createNewScheduler()))(
        state
      );

    case Actions.PATCH_SCHEDULER_CONTROL_MODE_LOADING:
      return R.pipe(R.assocPath(['schedulerLoading'], true))(state);

    case Actions.PATCH_SCHEDULER_CONTROL_MODE_ERROR:
      return R.pipe(
        R.assocPath(['schedulerLoading'], false),
        R.assoc('apiError', storeAPIerror(action))
      )(state);

    case ControlsActions.CLEAR_UPDATE_CONTROL_MODE_MODAL_ERROR_MESSAGE:
      return R.assoc('apiError', {}, state);

    case Actions.PATCH_SCHEDULER_CONTROL_MODE_SUCCESS:
      return R.pipe(
        R.assocPath(['schedulerLoading'], false),
        R.dissocPath(['editById', action.schedulerId, 'apiError'])
      )(state);

    case Actions.UPDATE_FIELD:
      const path =
        typeof action.field === 'string' ? [action.field] : action.field;
      let updatedField = R.assocPath(
        path,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('scheduler', updatedField)
        )
      )(state);

    case Actions.RESET_EDIT_SCHEDULER: {
      const fields = editableFields(
        R.path(['schedulerByEquipmentId', action.equipmentId, 'data'], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('scheduler', fields)
        )
      )(state);
    }

    case Actions.RESET_BATCH_EDIT_SCHEDULER: {
      return R.pipe(R.assocPath(['editById'], action.value))(state);
    }

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.CREATE_SCHEDULER_LOADING:
      return R.pipe(R.assocPath(['editById', 'new', 'isLoading'], true))(state);

    case Actions.CREATE_SCHEDULER_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.CREATE_SCHEDULER_TEMPERATURE_CONTROLS_SUCCESS:
    case Actions.CREATE_SCHEDULER_SUCCESS:
      if (!!action.data) {
        return R.pipe(
          R.assocPath(
            ['schedulerByEquipmentId', action.data.equipmentId, 'data'],
            action.data
          ),
          R.assocPath(['editById', action.data.id], {
            isLoading: false,
            formErrorsVisible: false,
            formErrors: formValidationErrors('scheduler', newSchedulerFields),
            fields: editableFields(action.data),
            apiError: {},
          }),
          R.assocPath(['editById', 'new'], createNewScheduler())
        )(state);
      } else {
        return state;
      }

    case Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING:
      return R.assocPath(
        ['schedulersByEquipmentId', action.equipmentId, 'isLoading'],
        true
      )(state);

    case Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR:
      return R.assocPath(
        ['schedulersByEquipmentId', action.equipmentId, 'isLoading'],
        false
      )(state);

    case Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS:
      if (!!action.data) {
        const editFields = editableFields(action.data);
        const formErrors = formValidationErrors('scheduler', editFields);
        return R.pipe(
          R.assocPath(
            ['schedulerByEquipmentId', action.data.equipmentId, 'data'],
            action.data
          ),
          R.assocPath(
            ['schedulerByEquipmentId', action.data.equipmentId, 'isLoading'],
            false
          ),
          R.assocPath(['editById', action.data.id], {
            isLoading: false,
            formErrorsVisible: false,
            formErrors,
            fields: editFields,
            apiError: {},
          })
        )(state);
      } else {
        const editFields = newSchedulerFields;
        const formErrors = formValidationErrors('scheduler', editFields);
        return R.pipe(
          R.assocPath(
            ['schedulerByEquipmentId', action.equipmentId, 'data'],
            undefined
          ),
          R.assocPath(
            ['schedulerByEquipmentId', action.equipmentId, 'isLoading'],
            false
          ),
          R.assocPath(['editById', action.equipmentId], {
            isLoading: false,
            formErrorsVisible: false,
            formErrors,
            fields: editFields,
            apiError: {},
          })
        )(state);
      }

    case Actions.PATCH_SCHEDULER_LOADING:
      return R.pipe(
        R.assocPath(['editById', action.schedulerId, 'isLoading'], true)
      )(state);

    case Actions.PATCH_SCHEDULER_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', action.schedulerId, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', action.schedulerId, 'isLoading'], false)
      )(state);

    case Actions.PATCH_SCHEDULER_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['schedulerByEquipmentId', action.data.equipmentId, 'data'],
          action.data
        ),
        R.assocPath(['editById', action.schedulerId, 'isLoading'], false)
      )(state);

    case Actions.PATCH_SCHEDULER_BATCH_ERROR:
      return R.pipe(R.assocPath(['apiError'], storeAPIerror(action)))(state);

    case Actions.PATCH_SCHEDULER_BATCH_SUCCESS:
      return R.pipe(R.assocPath(['schedulerBatchByEquipmentId'], action.data))(
        state
      );

    case Actions.DELETE_SCHEDULER_LOADING:
      return R.assocPath(
        ['editById', action.schedulerId, 'isLoading'],
        true,
        state
      );

    case Actions.DELETE_SCHEDULER_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.schedulerId, 'isLoading'], false),
        R.assocPath(
          ['editById', action.schedulerId, 'apiError'],
          storeAPIerror(action)
        )
      )(state);

    case Actions.DELETE_SCHEDULER_SUCCESS:
      return R.pipe(
        R.dissocPath(['schedulerByEquipmentId', action.equipmentId]),
        R.dissocPath(['editById', action.schedulerId])
      )(state);

    default:
      return state;
  }
};

export default schedulers;
