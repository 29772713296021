import React from 'react';
import {
  Select,
  SelectItem,
  Loader,
} from '@energybox/react-ui-library/dist/components';

import { GatewayVendorLabel } from '@energybox/react-ui-library/dist/types';

interface Props {
  onSelect: (vendor: string) => void;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  customErrorText?: string;
}

const vendors = Object.entries(GatewayVendorLabel).filter(
  vendor => vendor[1] !== ''
);

class SelectVendor extends React.Component<Props> {
  render() {
    const { value, onSelect, disabled, customErrorText } = this.props;

    if (!GatewayVendorLabel) {
      return <Loader size={12} />;
    } else {
      return (
        <Select
          variant={'select'}
          title={value ? GatewayVendorLabel[value] : undefined}
          disabled={disabled}
          customErrorText={customErrorText}
        >
          {vendors.map(vendor => (
            <SelectItem
              key={vendor[0]}
              isSelected={vendor[0] === value}
              onSelect={() => onSelect(vendor[0])}
            >
              {vendor[1]}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

export default SelectVendor;
