import { FetchArgument } from '../utils/apiUtils';

const apiBase = '/api/v1';

export enum Actions {
  GET_DEVICE_STATUS_SUCCESS = '@balena/GET_DEVICE_STATUS_SUCCESS',
  GET_DEVICE_STATUS_ERROR = '@balena/GET_DEVICE_STATUS_ERROR',
  GET_DEVICE_STATUS_LOADING = '@balena/GET_DEVICE_STATUS_LOADING',
  GET_DEVICE_STATUS_BY_SERIAL_NUMBER_SUCCESS = '@balena/GET_DEVICE_STATUS_BY_SERIAL_NUMBER_SUCCESS',
  GET_DEVICE_STATUS_BY_SERIAL_NUMBER_ERROR = '@balena/GET_DEVICE_STATUS_BY_SERIAL_NUMBER_ERROR',
  GET_DEVICE_STATUS_BY_SERIAL_NUMBER_LOADING = '@balena/GET_DEVICE_STATUS_BY_SERIAL_NUMBER_LOADING',
  GET_FIRMWARE_RELEASE_SUCCESS = '@balena/GET_FIRMWARE_RELEASE_SUCCESS',
  GET_FIRMWARE_RELEASE_ERROR = '@balena/GET_FIRMWARE_RELEASE_ERROR',
  GET_FIRMWARE_RELEASE_LOADING = '@balena/GET_FIRMWARE_RELEASE_LOADING',
  PATCH_RELEASE_TO_DEVICE_SUCCESS = '@balena/PATCH_RELEASE_TO_DEVICE_SUCCESS',
  PATCH_RELEASE_TO_DEVICE_ERROR = '@balena/PATCH_RELEASE_TO_DEVICE_ERROR',
  PATCH_RELEASE_TO_DEVICE_LOADING = '@balena/PATCH_RELEASE_TO_DEVICE_LOADING',
  GET_DEVICE_INFO_BY_UUID_SUCCESS = '@balena/GET_DEVICE_INFO_BY_UUID_SUCCESS',
  GET_DEVICE_INFO_BY_UUID_ERROR = '@balena/GET_DEVICE_INFO_BY_UUID_ERROR',
  GET_DEVICE_INFO_BY_UUID_LOADING = '@balena/GET_DEVICE_INFO_BY_UUID_LOADING',
}

export const getBalenaStatusBySiteId = (siteId: number) => ({
  type: 'API_GET',
  path: `${apiBase}/balena/fetchDeviceListByTag?tagKey=SITE_ID&tagValue=${siteId}`,
  success: { type: Actions.GET_DEVICE_STATUS_SUCCESS, siteId },
  error: { type: Actions.GET_DEVICE_STATUS_ERROR, siteId },
  loading: { type: Actions.GET_DEVICE_STATUS_LOADING, siteId },
});

export const getBalenaStatusByOrgId = (orgId: number) => ({
  type: 'API_GET',
  path: `${apiBase}/balena/fetchDeviceListByTag?tagKey=ORGANIZATION_ID&tagValue=${orgId}`,
  success: { type: Actions.GET_DEVICE_STATUS_SUCCESS, orgId },
  error: { type: Actions.GET_DEVICE_STATUS_ERROR, orgId },
  loading: { type: Actions.GET_DEVICE_STATUS_LOADING, orgId },
});

export const getBalenaStatusBySerialNumber = (serialNumber: string) => ({
  type: 'API_GET',
  path: `${apiBase}/balena/fetchDeviceInfoBySerialNumber?serialNumber=${serialNumber}`,
  success: {
    type: Actions.GET_DEVICE_STATUS_BY_SERIAL_NUMBER_SUCCESS,
    serialNumber,
  },
  error: {
    type: Actions.GET_DEVICE_STATUS_BY_SERIAL_NUMBER_ERROR,
    serialNumber,
  },
  loading: {
    type: Actions.GET_DEVICE_STATUS_BY_SERIAL_NUMBER_LOADING,
    serialNumber,
  },
});

export const fetchRemoteAccessToken = (
  serialNumber: string
): FetchArgument => ({
  endpoint: `${apiBase}/super-hub/auth/generate-token`,
  method: 'POST',
  payload: { serialNumber },
});

export const fetchRelease = () => ({
  type: 'API_GET',
  path: `${apiBase}/balena/fetchRelease`,
  success: { type: Actions.GET_FIRMWARE_RELEASE_SUCCESS },
  error: { type: Actions.GET_FIRMWARE_RELEASE_ERROR },
  loading: { type: Actions.GET_FIRMWARE_RELEASE_LOADING },
});

export const pushReleaseToDevice = (
  release: string,
  releaseId: number,
  serialNumber: string
) => ({
  type: 'API_PATCH',
  path: `${apiBase}/balena/pushReleaseToDevice`,
  payload: { releaseId, serialNumber },
  success: {
    type: Actions.PATCH_RELEASE_TO_DEVICE_SUCCESS,
    release,
    releaseId,
  },
  error: { type: Actions.PATCH_RELEASE_TO_DEVICE_ERROR },
  loading: { type: Actions.PATCH_RELEASE_TO_DEVICE_LOADING },
});

export const getBalenaDeviceByUUID = (uuid: string, siteId: number) => ({
  type: 'API_GET',
  path: `${apiBase}/balena/fetchDeviceByMacAddress?macAddress=${uuid}&siteId=${siteId}`,
  success: {
    type: Actions.GET_DEVICE_INFO_BY_UUID_SUCCESS,
    uuid,
    siteId,
  },
  error: {
    type: Actions.GET_DEVICE_INFO_BY_UUID_ERROR,
    uuid,
    siteId,
  },
  loading: {
    type: Actions.GET_DEVICE_INFO_BY_UUID_LOADING,
    uuid,
    siteId,
  },
});
