import { TimeZone } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeZones } from '../actions/app';
import { ApplicationState } from '../reducers';

const useGetTimezones = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTimeZones());
  }, []);

  const timezones = useSelector<ApplicationState, TimeZone[] | undefined>(
    ({ app }) => app.timezones
  );

  return timezones;
};

export default useGetTimezones;
