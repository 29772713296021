import {
  format,
  parseISO,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  roundToNearestMinutes,
  subMinutes,
} from 'date-fns';
import {
  formatIanaTimeZone,
  global,
} from '@energybox/react-ui-library/dist/utils';

export const formatFullDateTime = (
  isoDateString: string,
  fullDateTimeFormat: string
) => {
  const date = new Date(isoDateString);
  if (date.toString() === 'Invalid Date') return global.NOT_AVAILABLE;

  return format(date, fullDateTimeFormat);
};

export const formatEpochDate = (
  epochDateNumber: number,
  fullDateTimeFormat: string
) => {
  var date = new Date(0);
  date.setUTCSeconds(epochDateNumber);
  if (date.toString() === 'Invalid Date') return global.NOT_AVAILABLE;
  return format(date, fullDateTimeFormat);
};

export const toUnixTimestamp = (value: string): number =>
  parseInt(format(parseISO(value), 't'), 10);

export const generateDateLabel = (dateString: string): string => {
  const date = parseISO(dateString);

  if (date.toString() === 'Invalid Date') {
    return dateString;
  }

  return format(date, 'd MMM');
};

export const formatCertificateDate = (
  dateString: string,
  dateFormat: string
) => {
  if (!dateString) return global.NOT_AVAILABLE;
  const date = parseISO(dateString);
  return format(date, dateFormat);
};

export const getDateLast15MinuteStep = (
  timeToRoundDown: Date = new Date()
): Date => {
  const roundedTime = roundToNearestMinutes(timeToRoundDown, { nearestTo: 15 });
  // So that our time isn't in the future
  const ensureRoundDown =
    timeToRoundDown > roundedTime ? roundedTime : subMinutes(roundedTime, 15);
  return ensureRoundDown;
};

export function generateEpochFromDate(date: Date) {
  return setMilliseconds(date, 0).getTime() / 1000;
}

export const getMidday = (date: Date | number): Date => {
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 12);
};

export const getUnixTimestamp = (date: string | Date): number => {
  const newDate: Date = typeof date === 'string' ? parseISO(date) : date;
  return setMilliseconds(newDate, 0).getTime() / 1000;
};
