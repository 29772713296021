import {
  ControlsType,
  ControlTypesToDisplayTextsMapping,
} from '@energybox/react-ui-library/dist/types';
import {
  ThermostatIcon,
  Scheduler as IconScheduler,
} from '@energybox/react-ui-library/dist/icons';
import { WizardCard } from '@energybox/react-ui-library/dist/components';

import React from 'react';
import FeatureFlag from '../../containers/FeatureFlag';
import styles from './SelectControlsForm.module.css';

interface Props {
  onControlsTypeClick: (type: ControlsType) => void;
}

const controlsTypesInfo: ControlsTypeInfo[] = [
  {
    key: ControlsType.SCHEDULER,
    title: ControlTypesToDisplayTextsMapping[ControlsType.SCHEDULER],
    icon: (
      <div className={styles.iconWrapper}>
        <IconScheduler size="100%" />
      </div>
    ),
  },
  // {
  //   key: ControlsType.COOLING,
  //   title: ControlTypesToDisplayTextsMapping[ControlsType.COOLING],
  //   icon: (
  //     <div className={styles.iconWrapper}>
  //       <IconCooling size={80} />
  //     </div>
  //   ),
  // },
  // {
  //   key: ControlsType.HEATING,
  //   title: ControlTypesToDisplayTextsMapping[ControlsType.HEATING],
  //   icon: (
  //     <div className={styles.iconWrapper}>
  //       <IconHeating size={80} />
  //     </div>
  //   ),
  // },
  // HVAC controls should now be made through hvac-sops
  {
    key: ControlsType.HVAC,
    title: ControlTypesToDisplayTextsMapping[ControlsType.HVAC],
    descriprion: (
      <div>
        <div className={styles.hvacNote1}>To Add Controls for HVAC.</div>
        <div className={styles.hvacNote2}>
          Please update the Equipment Type to "HVAC_CONTROLLED"
        </div>
      </div>
    ),
    icon: (
      <div className={styles.iconWrapper}>
        <ThermostatIcon size="100%" />
      </div>
    ),
    disabled: true,
  },
  // {
  //   title: ControlsType.ENERGY_DEMAND_MANAGEMENT,
  //   icon: (
  //     <div className={styles.iconWrapper}>
  //       <IconEDM size="100%" />
  //     </div>
  //   ),
  // },
];

type ControlsTypeInfo = {
  key: ControlsType;
  title: string;
  icon: React.ReactNode;
  descriprion?: string | React.ReactNode;
  disabled?: boolean;
  featureFlag?: boolean;
};

const SelectControlsForm = ({ onControlsTypeClick }: Props) => {
  return (
    <div className={styles.root}>
      {controlsTypesInfo.map((controlsType: ControlsTypeInfo) => {
        const wizard = (
          <WizardCard
            key={controlsType.key}
            title={controlsType.title}
            description={controlsType.descriprion}
            onClick={() => onControlsTypeClick(controlsType.key)}
            icon={controlsType.icon}
            disabled={controlsType.disabled}
          />
        );
        if (controlsType.featureFlag) {
          return <FeatureFlag key={controlsType.title}>{wizard}</FeatureFlag>;
        }
        return wizard;
      })}
    </div>
  );
};

export default SelectControlsForm;
