import React from 'react';
import styles from './EnergyComponentsVerticalBar.module.css';

import { MdAdd } from 'react-icons/md';
import { classNames } from '@energybox/react-ui-library/dist/utils';

export const VerticalNavBarButton: React.FunctionComponent<{
  icon: React.ReactNode;
  onClick: () => void;
  className?: string;
}> = ({ icon, children, onClick, className }) => (
  <button className={classNames(styles.button, className)} onClick={onClick}>
    {icon}
    <span>{children}</span>
  </button>
);

const EnergyComponentsVerticalBar: React.FunctionComponent = ({ children }) => {
  return (
    <div className={styles.root}>
      <button className={styles.addButton}>
        <MdAdd size={32} strokeWidth={0.1} />
      </button>

      <div className={styles.menu}>{children}</div>
    </div>
  );
};

export default EnergyComponentsVerticalBar;
