import { ApplicationState } from '../reducers';
const apiBase = '/api/v1/report';

export enum Actions {
  GET_REPORTS_LOADING = '@report/GET_REPORTS_LOADING',
  GET_REPORTS_SUCCESS = '@report/GET_REPORTS_SUCCESS',
  GET_REPORTS_ERROR = '@report/GET_REPORTS_ERROR',

  CREATE_REPORT_LOADING = '@report/CREATE_REPORT_LOADING',
  CREATE_REPORT_SUCCESS = '@report/CREATE_REPORT_SUCCESS',
  CREATE_REPORT_ERROR = '@report/CREATE_REPORT_ERROR',

  PATCH_REPORT_LOADING = '@report/PATCH_REPORT_LOADING',
  PATCH_REPORT_SUCCESS = '@report/PATCH_REPORT_SUCCESS',
  PATCH_REPORT_ERROR = '@report/PATCH_REPORT_ERROR',

  DELETE_REPORT_LOADING = '@report/DELETE_REPORT_LOADING',
  DELETE_REPORT_SUCCESS = '@report/DELETE_REPORT_SUCCESS',
  DELETE_REPORT_ERROR = '@report/DELETE_REPORT_ERROR',

  GET_REPORT_LOADING = '@report/GET_REPORT_LOADING',
  GET_REPORT_SUCCESS = '@report/GET_REPORT_SUCCESS',
  GET_REPORT_ERROR = '@report/GET_REPORT_ERROR',

  GET_REPORTS_BY_SITE_ID_LOADING = '@report/GET_REPORTS_BY_SITE_ID_LOADING',
  GET_REPORTS_BY_SITE_ID_SUCCESS = '@report/GET_REPORTS_BY_SITE_ID_SUCCESS',
  GET_REPORTS_BY_SITE_ID_ERROR = '@report/GET_REPORTS_BY_SITE_ID_ERROR',

  TOGGLE_NEW_REPORT_MODAL = '@reports/TOGGLE_NEW_REPORT_MODAL',
  TOGGLE_DELETE_REPORT_MODAL = '@reports/TOGGLE_DELETE_REPORT_MODAL',
  UPDATE_FIELD = '@reports/UPDATE_FIELD',
  DISPLAY_FORM_ERRORS = '@reports/DISPLAY_FORM_ERRORS',
}

export const showNewReportModal = () => ({
  type: Actions.TOGGLE_NEW_REPORT_MODAL,
  value: true,
});

export const hideNewReportModal = () => ({
  type: Actions.TOGGLE_NEW_REPORT_MODAL,
  value: false,
});

export const showDeleteReportModal = () => ({
  type: Actions.TOGGLE_DELETE_REPORT_MODAL,
  value: true,
});

export const hideDeleteReportModal = () => ({
  type: Actions.TOGGLE_DELETE_REPORT_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const getReportsBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/organization-unit/${siteId}`,
  success: { type: Actions.GET_REPORTS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_REPORTS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_REPORTS_BY_SITE_ID_LOADING, siteId },
});

export const getReportById = (reportId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/${reportId}`,
  success: { type: Actions.GET_REPORT_SUCCESS, reportId },
  error: { type: Actions.GET_REPORT_ERROR, reportId },
  loading: { type: Actions.GET_REPORT_LOADING, reportId },
});

export const create = (params: { callback?: (res: any) => void }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: 'API_POST',
    path: apiBase,
    payload: (getState() as ApplicationState).reports.editReportById['new']
      .fields,
    loading: Actions.CREATE_REPORT_LOADING,
    success: {
      type: Actions.CREATE_REPORT_SUCCESS,
      callback: params.callback,
    },
    error: Actions.CREATE_REPORT_ERROR,
    callback: params.callback,
  });
};

export const patch = (id: string) => (dispatch, getState) => {
  dispatch({
    type: 'API_PUT',
    path: `${apiBase}/${id}`,
    payload: (getState() as ApplicationState).reports.editReportById[id].fields,
    loading: { type: Actions.PATCH_REPORT_LOADING, id },
    success: { type: Actions.PATCH_REPORT_SUCCESS, id },
    error: { type: Actions.PATCH_REPORT_ERROR, id },
  });
};

export const destroy = (reportId: string | number, siteId: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${reportId}`,
  loading: { type: Actions.DELETE_REPORT_LOADING, reportId, siteId },
  success: { type: Actions.DELETE_REPORT_SUCCESS, reportId, siteId },
  error: { type: Actions.DELETE_REPORT_ERROR, reportId, siteId },
});
