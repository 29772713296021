import { Button } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { MdAddCircle as PlusIcon } from 'react-icons/md';
import styles from './AddEntry.module.css';

type Props = {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
};

function AddEntry(props: Props) {
  const { onClick, title = '' } = props;

  return (
    <div className={styles.root}>
      <Button variant="outlined" onClick={onClick}>
        <PlusIcon className={styles.editIcon} /> {title || 'Add new action'}
      </Button>
    </div>
  );
}

export default AddEntry;
