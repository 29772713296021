import {
  EnergyDevicePorts,
  EnergyDeviceType,
  EnergyPro,
} from '@energybox/react-ui-library/dist/types';
import {
  Loader,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { connect } from 'react-redux';
import { getEnergyProsByDistributionPanelId } from '../../actions/energy_pros';
import { ApplicationState } from '../../reducers';
import { AvailablePortsByEnergyProId } from '../../reducers/energy_pros';

interface OwnProps {
  onSelect: (energyProId: number) => void;
  value?: number;
  panelId: number;
  error?: boolean;
  disabled?: boolean;
  newEnergyDeviceType?: EnergyDeviceType;
}

interface Props extends OwnProps {
  getEnergyProsByDistributionPanelId: (id: number) => void;
  energyPros: EnergyPro[];
  availablePortsByEnergyProId: AvailablePortsByEnergyProId;
}

class SelectEnergyPro extends React.Component<Props> {
  componentDidMount() {
    const { getEnergyProsByDistributionPanelId, panelId } = this.props;
    getEnergyProsByDistributionPanelId(panelId);
  }

  filterOutEnergyProsByAvailablePorts = (energyPro: EnergyPro) => {
    // filter out energyPro if the newEnergyDeviceType will exceed the max allotted 48 ports per energyPro
    const { availablePortsByEnergyProId, newEnergyDeviceType } = this.props;

    if (!newEnergyDeviceType) return true;
    return (
      availablePortsByEnergyProId[energyPro.id].value -
        EnergyDevicePorts[newEnergyDeviceType] >=
      0
    );
  };

  render() {
    const { energyPros, value, onSelect, error, disabled } = this.props;

    if (!energyPros.length) {
      return <Loader size={12} />;
    } else {
      const selectedEnergyPro = energyPros.find(
        (energyPro: EnergyPro) => energyPro.id === value
      );

      return (
        <Select
          variant={'select'}
          title={selectedEnergyPro?.title}
          error={error}
          disabled={disabled}
        >
          {energyPros
            .filter(this.filterOutEnergyProsByAvailablePorts)
            .map((energyPro: EnergyPro) => (
              <SelectItem
                key={energyPro.id}
                isSelected={energyPro.id === selectedEnergyPro?.id}
                onSelect={() => onSelect(energyPro.id)}
              >
                {energyPro.title}
              </SelectItem>
            ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = (
  { energyPros }: ApplicationState,
  { panelId }: OwnProps
) => ({
  energyPros: energyPros.energyProIdsByDistributionPanelId[panelId]
    ? energyPros.energyProIdsByDistributionPanelId[panelId].map(
        (energyProId: string) => energyPros.energyProsById[energyProId]
      )
    : [],
  availablePortsByEnergyProId: energyPros.availablePortsByEnergyProId,
});

const mapDispatchToProps = (dispatch, { panelId }: OwnProps) => ({
  getEnergyProsByDistributionPanelId: () =>
    dispatch(getEnergyProsByDistributionPanelId(panelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectEnergyPro);
