import React, { useEffect } from 'react';

//this is specifically used for the useState inside the ControlsCard component
//and its child components
export const useSetIsLocalOverrideActive = (
  isLocalOverrideActive: boolean | null | undefined,
  setIsLocalOverrideActive: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return useEffect(() => {
    if (typeof isLocalOverrideActive === 'boolean') {
      setIsLocalOverrideActive(isLocalOverrideActive);
    }
  }, [setIsLocalOverrideActive, isLocalOverrideActive]);
};
