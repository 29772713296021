import React from 'react';

import NewEquipmentModal from '../Equipment/NewEquipmentModal';
import EquipmentTable from '../Equipment/EquipmentTable';

interface OwnProps {
  id: string;
}

function ShowSitePageEquipment(props: OwnProps) {
  return (
    <>
      <NewEquipmentModal lockSiteId={parseInt(props.id)} />
      <EquipmentTable siteId={parseInt(props.id)} />
    </>
  );
}

export default ShowSitePageEquipment;
