export enum InspectionReportKey {
  EB_THERMOSTATS = 'eb_thermostats',
  VENSTAR_THERMOSTATS = 'thermostats',
  SITE_CONTROLLERS = 'site_controllers',
  SUPER_EDGE_CONTROLLERS = 'super_edge_controllers',
  EDGE_CONTROLLERS = 'edge_controllers',
  SUPER_HUBS = 'super_hubs',
  HUBS = 'hubs',
  SENSORS = 'sensors',
  ENERGY_PROS = 'energy_pros',
  ENERGY_PRO2S = 'energy_pro2s',
}

export enum DAThermostatTileKey {
  HVAC_TYPE = 'HVAC_type',
}
