import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconEquipmentFactory,
  Loader,
  Modal,
  ModalTitle,
  ModalContent,
} from '@energybox/react-ui-library/dist/components';
import {
  Actuator,
  Equipment,
  Scheduler,
  TemperatureControl,
  HvacControl,
  SopComponent,
  SopTypes,
  TimeTablesById,
  NetworkGroup,
  Site,
  ApiError,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys, isDefined } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import React from 'react';
import { connect } from 'react-redux';
import {
  getActuatorsByEquipment,
  showNewActuatorModal,
} from '../../actions/control_boards';
import {
  Actions as EquipmentActions,
  destroy,
  displayFormErrors,
  getEquipment,
  patch,
  reset,
  updateField,
} from '../../actions/equipment';
import { produceEdgeConfig } from '../../actions/edge_devices';
import { getNetworkGroupByEquipmentId } from '../../actions/network_groups';
import { getHvacControlByEquipmentId } from '../../actions/hvacControls';
import { getSchedulerByEquipmentId } from '../../actions/schedulers';
import { showNewSensorModal } from '../../actions/sensors';
import { getSiteByResourceId } from '../../actions/sites';
import { getSopComponentsByResourceId } from '../../actions/sops';
import { getTemperatureControlsByEquipmentId } from '../../actions/temperature_controls';
import { getTimeTables } from '../../actions/time_tables';
import EditEquipmentForm from '../../components/EditEquipmentForm';
import ShowDetailPageHeader from '../../components/ShowDetailPageHeader';
import SopTable from '../../components/SopTable/SopTable';
import { ApplicationState } from '../../reducers';
import { EquipmentTypesById } from '../../reducers/app';
import { EditEquipment } from '../../reducers/equipment';
import { HVAC_CONTROL_EQUIPMENT_TYPES } from '../../types/hvacControl';
import { CreateNewText } from '../../types/global';
import { hasDaResults, getObjectWithSelectedKeys } from '../../util';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import ActuatorsTable from '../Actuators/ActuatorsTable';
import DeleteActuatorModal from '../Actuators/DeleteActuatorModal';
import EditActuatorModal from '../Actuators/EditActuatorModal';
import NewActuatorModal from '../Actuators/NewActuatorModal';
import GenericControlsCard from '../Controls/GenericControlsCard';
import HvacSopControlsCards from '../Controls/HvacSopControlsCards';
import ResourceFullPath from '../ResourceFullPath';
import NewSensorModal from '../Sensors/NewSensorModal';
import SensorsTable from '../Sensors/SensorsTable';
import EditControlModal from './EditControlModal';
import NewControlModal from './NewControlModal';
import ShowEquipmentPageSentinels from './ShowEquipmentPageSentinels';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import { DEFAULT_PAGINATION_ROWS_COUNT } from '../../constants/pagination';
import { accessDeniedError } from '../../utils/ApiError/accessDeniedError';

interface OwnProps {
  id: string;
  orgId?: number;
  siteId: number;
}

interface Props extends OwnProps {
  schedulerLoading: boolean;
  equipmentLoading: boolean;
  load: () => void;
  loadTimeTables: (siteId: number) => void;
  onChange: (field: string, value: number) => void;
  patch: () => void;
  produceEdgeConfig: (
    networkGroupId: string | number,
    edgeUuid?: string | undefined,
    edgeId?: number
  ) => void;
  onDelete: () => void;
  onCancel: () => void;
  equipment?: Equipment;
  editEquipment?: EditEquipment;
  displayFormErrors: () => void;
  equipmentTypesById?: EquipmentTypesById;
  scheduler: Scheduler | undefined;
  temperatureControl: TemperatureControl | undefined;
  hvacControl: HvacControl | undefined;
  showingEditActuatorModal: boolean;
  showingDeleteActuatorModal: boolean;
  showingNewActuatorModal: boolean;
  showingNewControlModal: boolean;
  showingEditControlModal: boolean;
  showNewSensorModal: typeof showNewSensorModal;
  showNewActuatorModal: typeof showNewActuatorModal;
  getSopComponentsByResourceId: typeof getSopComponentsByResourceId;
  getNetworkGroupByEquipmentId: () => void;
  equipmentSopComponents: SopComponent[];
  equipmentTimeTablesById: TimeTablesById;
  actuators: Actuator[] | undefined;
  networkGroups: NetworkGroup[];
  site: Site;
  siteApiError: ApiError;
}

interface State {
  showDeletePrompt: boolean;
  showSavePrompt: boolean;
  actuatorIdToEdit: number;
}

class ShowEquipmentPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeletePrompt: false,
      showSavePrompt: false,
      actuatorIdToEdit: -1,
    };
  }

  componentDidMount() {
    this.props.load();
  }

  componentDidUpdate(prevProps) {
    const { siteId, equipment, loadTimeTables, load } = this.props;
    if (!prevProps.siteId && siteId) {
      loadTimeTables(siteId);
    }
    if (prevProps.equipment?.id !== equipment?.id) {
      load();
    }
  }

  setActuatorIdToEdit = (id: number) => {
    this.setState({ actuatorIdToEdit: id });
  };

  openDeletePrompt = () => {
    this.setState({ showDeletePrompt: true });
  };

  closeDeletePrompt = () => {
    this.setState({ showDeletePrompt: false });
  };

  openSavePrompt = () => {
    this.setState({ showSavePrompt: true });
  };

  closeSavePrompt = () => {
    this.setState({ showSavePrompt: false });
  };

  deletePrompt() {
    const { equipment, onDelete, editEquipment } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={this.closeDeletePrompt}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal onClose={this.closeDeletePrompt} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete{' '}
          {equipment ? <b>{equipment.title}</b> : 'this equipment'}?
        </p>
        {editEquipment &&
          renderAPIerror(
            editEquipment.apiError,
            EquipmentActions.DELETE_EQUIPMENT_ERROR
          )}
      </Modal>
    );
  }

  headerDescription(): string {
    return '';
  }

  addSensor = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Button onClick={this.props.showNewSensorModal}>
        {CreateNewText.SENSOR}
      </Button>
    </div>
  );

  addActuator = (daResultsExist: boolean) => (
    <Button
      onClick={this.props.showNewActuatorModal}
      disabled={daResultsExist ? true : false}
    >
      {CreateNewText.ACTUATOR}
    </Button>
  );

  // Save the equipment type change with a warning message
  onSaveType = () => {
    const { patch, produceEdgeConfig, networkGroups } = this.props;

    if (networkGroups.length) {
      networkGroups.forEach(networkGroup => {
        produceEdgeConfig(
          networkGroup.id,
          networkGroup.edge?.uuid,
          networkGroup.edge?.id
        );
      });
      patch();
    } else {
      patch();
    }
    this.closeSavePrompt();
  };

  onSave = () => {
    const { patch, editEquipment, equipment, displayFormErrors } = this.props;
    if (editEquipment && hasKeys(editEquipment.formErrors)) {
      displayFormErrors();
    } else {
      if (editEquipment?.fields.typeId !== equipment?.typeId) {
        this.openSavePrompt();
      } else {
        if (editEquipment?.fields.typeId !== equipment?.typeId) {
          this.openSavePrompt();
        } else {
          patch();
        }
      }
    }
  };

  equipmentTypeUpdatePrompt = () => {
    const { equipment, editEquipment, equipmentTypesById } = this.props;

    const eqTitle = typeId => {
      const type = equipmentTypesById![typeId];
      return type !== undefined ? type.title : '';
    };

    // checking the current equipment Type is Controlled or not
    const equipmentTypeTitle = () => {
      const title = equipment?.type?.title!;
      const typeId = editEquipment?.fields.typeId;

      if (HVAC_CONTROL_EQUIPMENT_TYPES.includes(title)) {
        return true;
      } else if (HVAC_CONTROL_EQUIPMENT_TYPES.includes(eqTitle(typeId))) {
        return true;
      }
      return false;
    };

    const actions = (
      <>
        <Button variant="text" onClick={this.closeSavePrompt}>
          Cancel
        </Button>
        <Button onClick={this.onSaveType}>
          {equipmentTypeTitle() ? 'Update' : 'Save'}
        </Button>
      </>
    );

    return (
      <Modal onClose={this.closeSavePrompt} actions={actions}>
        <ModalTitle>
          <WarningIcon style={{ width: '1.6rem', height: '1.6rem' }} /> Warning
          Message
        </ModalTitle>
        <ModalContent>
          Any applied SOP/Configurations linked to the previous{' '}
          <b>equipment type</b> will be lost
        </ModalContent>
      </Modal>
    );
  };

  renderControlCards = (
    isEquipmentHvacControlledType: boolean,
    networkGroupIdViaActuator: number | undefined,
    controlBoardIdViaActuator: number | undefined
  ) => {
    const {
      id,
      siteId,
      scheduler,
      temperatureControl,
      schedulerLoading,
      equipment,
    } = this.props;

    if (!isDefined(equipment) || !isDefined(siteId)) {
      return null;
    }
    const equipmentHasNonHvacControls = scheduler || temperatureControl;
    const displayGenericControlsCard =
      !isEquipmentHvacControlledType ||
      (isEquipmentHvacControlledType && equipmentHasNonHvacControls);

    return (
      <>
        {isEquipmentHvacControlledType ? (
          <HvacSopControlsCards
            siteId={siteId}
            equipment={equipment}
            controlBoardIdViaActuator={controlBoardIdViaActuator}
          />
        ) : (
          <></>
        )}
        {displayGenericControlsCard && (
          <div style={{ marginTop: '1rem' }}>
            <GenericControlsCard
              scheduler={scheduler}
              siteId={siteId}
              equipmentId={id}
              loading={schedulerLoading}
              equipmentTitle={equipment.title}
              networkGroupIdViaActuator={networkGroupIdViaActuator}
              controlBoardIdViaActuator={controlBoardIdViaActuator}
              isEquipmentHvacControlledType={isEquipmentHvacControlledType}
            />
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      id,
      equipment,
      editEquipment,
      onChange,
      onCancel,
      siteId,
      orgId,
      equipmentTypesById,
      scheduler,
      showingEditActuatorModal,
      showingDeleteActuatorModal,
      showingNewActuatorModal,
      showingNewControlModal,
      showingEditControlModal,
      schedulerLoading,
      equipmentLoading,
      equipmentSopComponents,
      equipmentTimeTablesById,
      actuators,
      site,
      siteApiError,
    } = this.props;
    const { showDeletePrompt, actuatorIdToEdit, showSavePrompt } = this.state;

    if (!equipment || !editEquipment || equipmentLoading) {
      if (Object.keys(siteApiError).length) {
        return accessDeniedError(siteApiError);
      } else {
        return <div>Loading...</div>;
      }
    }

    const spaceId = equipment !== undefined ? equipment.spaceId : undefined;

    const isEquipmentHvacControlledType = HVAC_CONTROL_EQUIPMENT_TYPES.includes(
      equipment.type?.title || ''
    );

    function filterNonEquipmentSpecific(sopComponent) {
      return !(
        sopComponent.component.type === SopTypes.ENERGY_TARIFF ||
        sopComponent.component.type === SopTypes.MAINTENANCE_VISIT_RATE
      );
    }

    let equipmentSpecificSopComponents = equipmentSopComponents.filter(
      filterNonEquipmentSpecific
    );
    const testResults = site && site.installerTestResults;
    const daResultsExist: boolean = hasDaResults(testResults);
    //each equipment should only have one associated control board
    //so when a user creates an actuator with controlBoard,
    //user can only use that controlBoard for all future new actuators
    const controlBoardIdViaActuator = actuators?.[0]?.controlBoardId;
    const networkGroupIdViaActuator =
      actuators?.[0]?.controlBoard?.networkGroupId;
    return (
      <>
        {equipment && (
          <ShowDetailPageHeader
            name={equipment.title}
            description={<ResourceFullPath resourceId={equipment.id} />}
            resourceName="Equipment"
            onDelete={this.openDeletePrompt}
            siteId={site?.id}
          />
        )}
        <div style={{ padding: '3rem' }}>
          {editEquipment && (
            <>
              <Card>
                <CardContent>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '.2fr .8fr',
                    }}
                  >
                    <div
                      style={{
                        color: 'var(--accent-base)',
                        paddingLeft: '1.25rem',
                      }}
                    >
                      <IconEquipmentFactory
                        id={
                          equipment &&
                          R.pathOr(
                            '',
                            [equipment.typeId, 'alias'],
                            equipmentTypesById
                          )
                        }
                        size={120}
                      />
                    </div>

                    <EditEquipmentForm
                      onEquipmentGroupChange={equipmentGroup => {
                        onChange('typeId', -1);
                        onChange('groupId', equipmentGroup);
                      }}
                      onChange={onChange}
                      formErrors={editEquipment.formErrors}
                      formErrorsVisible={editEquipment.formErrorsVisible}
                      {...editEquipment.fields}
                      equipmentId={equipment.id}
                      siteId={siteId}
                    />
                  </div>
                  {renderAPIerror(
                    editEquipment.apiError,
                    EquipmentActions.PATCH_EQUIPMENT_ERROR
                  )}
                </CardContent>

                {editEquipment.isChanged && (
                  <CardActions>
                    <Button
                      variant="text"
                      onClick={onCancel}
                      children="Cancel"
                    />

                    <Button
                      disabled={editEquipment.isLoading}
                      onClick={this.onSave}
                    >
                      {editEquipment.isLoading ? (
                        <Loader size={16} variant="secondary" />
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </CardActions>
                )}
              </Card>
            </>
          )}

          {this.renderControlCards(
            isEquipmentHvacControlledType,
            networkGroupIdViaActuator,
            controlBoardIdViaActuator
          )}

          <div style={{ marginTop: '1rem' }}>
            <Card>
              <CardContent>
                <div
                  style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }}
                >
                  <SopTable
                    orgId={orgId}
                    siteId={siteId}
                    sopComponents={[...equipmentSpecificSopComponents]}
                    timeTablesById={equipmentTimeTablesById}
                    isEquipmentSops={true}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <ShowEquipmentPageSentinels id={id} />
          </div>
        </div>
        <div style={{ padding: '3rem' }}>
          {equipment && (
            <>
              <SensorsTable
                hideSensorTypeFilter
                secondaryAction={this.addSensor()}
                resourceId={equipment.id}
                renderLimit={DEFAULT_PAGINATION_ROWS_COUNT}
                listView={false}
                siteId={siteId}
              />
              <NewSensorModal defaultSiteId={siteId} />
            </>
          )}
        </div>
        <div>
          {equipment && (
            <>
              <div style={{ padding: '3rem 3rem 5rem 3rem' }}>
                <ActuatorsTable
                  equipmentId={equipment.id}
                  secondaryAction={this.addActuator(daResultsExist)}
                  setActuatorIdToEdit={this.setActuatorIdToEdit}
                  daResultsExist={daResultsExist}
                />
              </div>
              {showingNewActuatorModal && (
                <NewActuatorModal
                  equipmentId={equipment.id}
                  preselectedControlBoardId={controlBoardIdViaActuator}
                />
              )}
              {showingEditActuatorModal && (
                <EditActuatorModal
                  equipmentId={equipment.id}
                  actuatorId={actuatorIdToEdit}
                  preselectedControlBoardId={controlBoardIdViaActuator}
                />
              )}
              {showingDeleteActuatorModal && (
                <DeleteActuatorModal
                  equipmentId={equipment.id}
                  actuatorId={actuatorIdToEdit}
                />
              )}
              {showingNewControlModal && (
                <NewControlModal
                  equipmentId={equipment.id}
                  spaceId={spaceId}
                  equipmentTitle={equipment.title}
                />
              )}
              {showingEditControlModal && (
                <EditControlModal
                  equipmentId={equipment.id}
                  schedulerId={
                    scheduler !== undefined ? String(scheduler.id) : undefined
                  }
                  spaceId={spaceId}
                  equipmentTitle={equipment.title}
                />
              )}
            </>
          )}
        </div>
        {showDeletePrompt && this.deletePrompt()}
        {showSavePrompt && this.equipmentTypeUpdatePrompt()}
      </>
    );
  }
}

const mapStateToProps = (
  {
    equipment,
    sites,
    app,
    schedulers,
    controlBoards,
    controls,
    sops,
    timeTables,
    temperatureControls,
    hvacControls,
    networkGroups,
  }: ApplicationState,
  { id }: OwnProps
) => {
  const siteId = sites.resourceIdToSiteId[parseInt(id)];
  return {
    showingEditActuatorModal: controlBoards.showEditActuatorModal,
    showingNewActuatorModal: controlBoards.showNewActuatorModal,
    showingDeleteActuatorModal: controlBoards.showDeleteActuatorModal,
    showingNewControlModal: controls.showNewControlModal,
    showingEditControlModal: controls.showEditControlModal,
    equipment: equipment.equipmentById[parseInt(id)],
    editEquipment: equipment.editById[parseInt(id)],
    siteId,
    equipmentTypesById: app.equipmentTypesById,
    scheduler: schedulers.schedulerByEquipmentId[id]?.data,
    temperatureControl:
      temperatureControls.temperatureControlsByEquipmentId[id],
    hvacControl: hvacControls.hvacControlsByEquipmentId[id],
    schedulerLoading: schedulers.schedulerLoading,
    equipmentLoading: equipment.equipmentLoading,
    orgId: app.currentOrganizationId,
    equipmentSopComponents: sops.sopComponentsByResourceId[id] || [],
    timeTables,
    equipmentTimeTablesById: getObjectWithSelectedKeys(
      timeTables.timeTablesById,
      timeTables.timeTableIdsByParentId[sites.resourceIdToSiteId[parseInt(id)]]
    ),
    actuators: controlBoards.actuatorsByEquipmentId[id],
    networkGroups: networkGroups.networkGroupsByEquipmentId[id] || [],
    site: sites.sitesById[siteId],
    siteApiError: sites.siteApiError,
  };
};

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => {
    dispatch(getEquipment(id));
    dispatch(getSiteByResourceId(id));
    dispatch(getSchedulerByEquipmentId(id));
    dispatch(getTemperatureControlsByEquipmentId(id));
    dispatch(getHvacControlByEquipmentId(id));
    dispatch(getSopComponentsByResourceId(id));
    dispatch(getActuatorsByEquipment(id));
    dispatch(getNetworkGroupByEquipmentId(Number(id)));
  },
  loadTimeTables: (siteId: number) => {
    dispatch(getTimeTables(siteId));
  },
  onChange: (f: string, v: string | number) => dispatch(updateField(id, f, v)),
  produceEdgeConfig: (
    networkGroupId: string | number,
    edgeUuid?: string | undefined,
    edgeId?: number
  ) => dispatch(produceEdgeConfig(networkGroupId, edgeUuid, edgeId)),
  patch: () => dispatch(patch(id)),
  onDelete: () => dispatch(destroy(id)),
  onCancel: () => dispatch(reset(id)),
  getNetworkGroupByEquipmentId: () =>
    dispatch(getNetworkGroupByEquipmentId(+id)),
  getTemperatureControlsByEquipmentId: () =>
    dispatch(getTemperatureControlsByEquipmentId(id)),
  showNewSensorModal: () => dispatch(showNewSensorModal()),
  showNewActuatorModal: () => dispatch(showNewActuatorModal(Number(id))),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowEquipmentPage);
