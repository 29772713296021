import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import history from '../history';
import { ApplicationState } from '../reducers';

import Route from './SecureRoute';
import LoginPageContainer from './LoginPageContainer';
import ForgotPasswordPageContainer from './Password/ForgotPasswordPageContainer';
import NewPasswordPageContainer from './Password/NewPasswordPageContainer';
import PrimaryLayout from '../components/PrimaryLayout';
import { Routes } from '../routes';
import { ConnectedRouter } from 'connected-react-router';
import { useGetCurrentOrganization } from '../hooks/useCurrentOrganization';
import { isInSitesPage } from '../util';

const App = ({ isInitialised }) => {
  const org = useGetCurrentOrganization();
  const hasPattern = isInSitesPage();

  useEffect(() => {
    if (org && !hasPattern) document.title = org?.title || '';
  }, [org]);

  return isInitialised ? (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={Routes.LOGIN} component={LoginPageContainer} />
        <Route
          path={Routes.FORGOT_PASSWORD}
          component={ForgotPasswordPageContainer}
        />
        <Route
          path={Routes.NEW_PASSWORD}
          component={NewPasswordPageContainer}
        />
        <Route
          path={Routes.USER_INVITE_EMAIL}
          component={NewPasswordPageContainer}
        />
        <Route path={Routes.BASE} component={PrimaryLayout} />
      </Switch>
    </ConnectedRouter>
  ) : (
    <div>Loading...</div>
  );
};

const mapStateToProps = ({ app }: ApplicationState) => ({
  isInitialised: app.isInitialised,
});

export default connect(mapStateToProps)(App);
