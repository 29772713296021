import {
  Actions as DistributionPanelActions,
  getDistributionPanel,
} from '../actions/distribution_panel';
import { Actions as CircuitBreakerActions } from '../actions/circuit_breakers';
import {
  Actions as EnergyProActions,
  getEnergyPro,
} from '../actions/energy_pros';
import { Actions as EnergyDeviceActions } from '../actions/energy_devices';

const energyMiddleware = store => next => action => {
  switch (action.type) {
    case CircuitBreakerActions.PATCH_CIRCUIT_BREAKER_SUCCESS:
      store.dispatch(getDistributionPanel(action.panelId));
      return next(action);

    case CircuitBreakerActions.DELETE_CIRCUIT_BREAKER_SUCCESS:
      store.dispatch({
        type:
          EnergyDeviceActions.DELETE_ENERGY_DEVICE_SENSORS_LINKED_TO_DELETED_CIRCUIT_BREAKER,
        breakerId: action.breakerId,
      });

      store.dispatch(getDistributionPanel(action.panelId));
      return next(action);

    case DistributionPanelActions.MOVE_BREAKER_IN_PANEL_LOADING:
      store.dispatch({
        type: CircuitBreakerActions.MOVE_BREAKER_IN_PANEL_LOADING,
        breakerId: action.breakerId,
      });
      return next(action);

    case DistributionPanelActions.MOVE_BREAKER_IN_PANEL_SUCCESS:
      store.dispatch({
        type: CircuitBreakerActions.MOVE_BREAKER_IN_PANEL_SUCCESS,
        data: action.data,
        breakerId: action.breakerId,
      });
      return next(action);

    case DistributionPanelActions.MOVE_BREAKER_IN_PANEL_ERROR:
      store.dispatch({
        type: CircuitBreakerActions.MOVE_BREAKER_IN_PANEL_ERROR,
        data: action.data,
        breakerId: action.breakerId,
      });
      return next(action);

    case DistributionPanelActions.INSTALL_CIRCUIT_BREAKER_LOADING:
      store.dispatch({
        type: CircuitBreakerActions.INSTALL_CIRCUIT_BREAKER_LOADING,
      });
      return next(action);

    case DistributionPanelActions.INSTALL_CIRCUIT_BREAKER_SUCCESS:
      store.dispatch({
        type: CircuitBreakerActions.INSTALL_CIRCUIT_BREAKER_SUCCESS,
        data: action.data,
      });
      return next(action);

    case DistributionPanelActions.INSTALL_CIRCUIT_BREAKER_ERROR:
      store.dispatch({
        type: CircuitBreakerActions.INSTALL_CIRCUIT_BREAKER_ERROR,
        data: action.data,
      });
      return next(action);

    case EnergyProActions.REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_SUCCESS:
      store.dispatch(getEnergyPro(action.energyProId));
      return next(action);

    case EnergyProActions.REMOVE_ALL_DEVICES_FROM_ENERGY_PRO_BUS_SUCCESS:
      store.dispatch(getEnergyPro(action.id));
      return next(action);

    default:
      return next(action);
  }
};

export default energyMiddleware;
