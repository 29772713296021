import {
  Equipment,
  Space,
  User,
  UserGroup,
  ComparisonType,
  defaultActivePowerParams,
  defaultBatteryCheckParameters,
  defaultBinaryCounterParameters,
  defaultCustomerComfortParameters,
  defaultDoorParameters,
  defaultHumidityParams,
  defaultTemperatureParams,
  initialSentinelDelay,
  initialSentinelEscalation,
  IntervalUnit,
  Sentinel,
  sentinelComparisonDefaults,
  SentinelEscalation,
  SentinelParameters,
  SentinelSchedule,
  SentinelTarget,
  SentinelTargetType,
  SentinelType,
  sentinelTypeToSentinelTargetType,
  Site,
  UserRole,
  defaultLocalOverrideParams,
  customDelaySentinels,
  defaultControlsHealthCheckParameters,
  SentinelTargetEntities,
  SentinelTargetEntitiesById,
  sentinelTypesList,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  ErrorBoundary,
  InputField,
  Label,
  Tabs,
} from '@energybox/react-ui-library/dist/components';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEquipments,
  updatedEquipmentList,
  upDatedSentiel,
  isFirstTimeCopy,
} from '../../actions/equipment';
import { fetchEquipment } from '../../actions/equipmentFetch';
import { fetchGateways } from '../../actions/gatewaysFetch';
import { openModal } from '../../actions/modal';
import { fetchSensors } from '../../actions/sensorsFetch';
import { createSentinel, updateSentinel } from '../../actions/sentinels';
import { getSites, getSitesBySentinel } from '../../actions/sites';
import { getSpaces } from '../../actions/spaces';
import { fetchSpaces } from '../../actions/spacesFetch';
import history from '../../history';
import { ApplicationState } from '../../reducers';
import { SitesById, SitesBySentinelId } from '../../reducers/sites';
import { SpacesById } from '../../reducers/spaces';
import { Routes } from '../../routes';
import { sentinelSteps, StepType } from '../../types/sentinelWizard';
import ModalFormContent from '../ModalFormContent';
import TabLink from '../TabLink';
import styles from './SentinelWizard.module.css';
import SentinelWizardNavigation from './SentinelWizardNavigation';
import StepAdditions from './steps/StepAdditions/StepAdditions';
import StepCondition from './steps/StepCondition/StepCondition';
import StepHandling from './steps/StepHandling/StepHandling';
import StepRolloutContainer from './steps/StepRollout/StepRolloutContainer';
import StepSchedule from './steps/StepSchedule/StepSchedule';
import StepSentinelType from './steps/StepSentinelType';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useCurrentUser } from '../../hooks/useAppDetails';
import * as R from 'ramda';
import { createAndUpdateSentinel } from '../../util';
const stepsKeys = Object.keys(sentinelSteps);

type Props = {
  id?: string;
  sentinel?: Sentinel;
  step: StepType;
  userRole: UserRole;
  sites: Site[];
  users: User[];
  userGroups: UserGroup[];
  createSentinel: typeof createSentinel;
  updateSentinel: typeof updateSentinel;
  openModal: typeof openModal;
  fetchEquipment: typeof fetchEquipment;
  fetchSpaces: typeof fetchSpaces;
  fetchGateways: typeof fetchGateways;
  fetchSensors: typeof fetchSensors;
};

export type TargetedEntitiesMapping = {
  equipment: Equipment[];
  spaces: Space[];
};

export type TargetedEntitiesMappingBySiteId = {
  [siteId: number]: TargetedEntitiesMapping;
};

export interface Errors {
  title: {
    onSave?: string;
  };
  condition?: string;
  handling: {
    onSave?: string;
  };
  rollOut?: string;
}

const initErrors: Errors = {
  title: {},
  handling: {},
};

function SentinelWizard(props: Props) {
  const currentUser = useCurrentUser();
  const {
    id = 'new',
    step,
    sentinel,
    users,
    userGroups,
    sites = [],
    createSentinel,
    updateSentinel,
    openModal,
  } = props;

  const dispatch = useDispatch();
  const stepHandlingRef = useRef<{ handleItemValidation: () => void }>();

  useTargetEntitiesInitialFetch(sentinel);
  const dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');

  const equipmentById = useSelector(
    ({ equipment }: ApplicationState) => equipment.equipmentById
  );

  const spacesById = useSelector(
    ({ spaces }: ApplicationState) => spaces.spacesById
  );

  const sitesById = useSelector(
    ({ sites }: ApplicationState) => sites.sitesById
  );

  const sitesBySentinelId: SitesBySentinelId = useSelector(
    ({ sites }: ApplicationState) => sites.sitesBySentinelId
  );

  // const errorEmail = useSelector(
  //   ({ equipment }: ApplicationState) => equipment.errorEmailFields
  // );

  const copyCount = useSelector(
    ({ equipment }: ApplicationState) => equipment.isFirstTimeCount
  );

  const newListEquipment = useSelector(
    ({ equipment }: ApplicationState) => equipment.getUpdatedEquipmentList
  );

  const sentinelTargatedType = useSelector(
    ({ equipment }: ApplicationState) => equipment.sentinelTargetType
  );

  let setTitleValue: string = '';
  let count: number = 0;
  if (dublicateSentinelkey) {
    count = (
      `${dublicateSentinelkey} ${sentinel && sentinel.title}`.match(
        /Copy Of/g
      ) || []
    ).length;
    if (count === 1) {
      setTitleValue = `${dublicateSentinelkey} ${sentinel && sentinel.title}`;
    } else if (count > 1) {
      setTitleValue = `${sentinel && sentinel.title} (${count})`;
    }
  } else {
    setTitleValue = (sentinel && sentinel.title) || '';
  }

  const [title, setTitle] = useState(setTitleValue);

  const [nextToRollout, setNextToRollout] = useState(false);
  const [nextToAddions, setNextToAddions] = useState(false);
  const [nextToSchedule, setNextToSchedule] = useState(false);
  const [nextToCondition, setNextToCondition] = useState(false);
  const [nextToHandling, setNextToHandling] = useState(false);
  const [description, setDescription] = useState(
    (sentinel && sentinel.description) || ''
  );

  const [sentinelType, setType] = useState(
    (sentinel && sentinel.sentinelType) || SentinelType.NONE
  );

  const [parameters, setParameters] = useState(sentinel && sentinel.parameters);

  const [errors, setErrors] = useState<Errors>(initErrors);
  const [isErrorField, isSetErrorField] = useState(false);
  const [isEmailErrorField, isSetEmailErrorField] = useState(false);
  const [action, setAction] = useState('');
  const [email, setEmail] = useState('');
  const [headerCounter, setHeaderCounter] = useState(0);
  const [isFormSaveError, isSetFormSaveError] = useState(false);
  const [selectSite, setSelectSite] = useState('');
  const [checkBoxType, setCheckBoxType] = useState('');
  const [isSelectedSiteError, setIsSelectedSiteError] = useState(false);
  const [editBtnEnable, setEditBtnEnable] = useState<boolean[]>([false]);
  const [selectedTargetType, setSelectedTargetType] = useState('EQUIPMENT');

  useEffect(() => {
    if (!parameters) return;
    const {
      comparisonType,
      rangeLowerBound,
      rangeUpperBound,
      targetsTolerance,
    } = parameters;
    if (
      comparisonType !== ComparisonType.inner ||
      rangeLowerBound === undefined ||
      rangeUpperBound === undefined
    )
      return;

    const unroundedMaxTolerance = (rangeUpperBound - rangeLowerBound) / 2;
    const roundedMaxTolerance =
      Math.round((unroundedMaxTolerance + Number.EPSILON) * 10) / 10;

    const targetIds = Object.keys(targetsTolerance || {});
    const targetIdsWithInvalidTolerance = targetIds.filter(
      targetId => (targetsTolerance || {})[targetId] > roundedMaxTolerance
    );
    if (targetIdsWithInvalidTolerance.length === 0) return;
    const updatedMapping = { ...targetsTolerance };
    targetIdsWithInvalidTolerance.forEach(
      targetId => (updatedMapping[targetId] = roundedMaxTolerance)
    );

    setParameters({
      ...parameters,
      targetsTolerance: updatedMapping,
    });
  }, [parameters, setParameters]);

  const [escalations, setEscalations] = useState(
    (sentinel && sentinel.escalations) || [initialSentinelEscalation]
  );

  const [schedules, setSchedules] = useState(
    (sentinel && sentinel.schedules) || []
  );

  const [persistBeyondSchedule, setPersistBeyondSchedule] = useState(
    (sentinel && sentinel.persistBeyondSchedule) || false
  );

  const [targets, setTargets] = useState((sentinel && sentinel.targets) || []);
  const [targetedSites, setTargetedSites] = useState<Site[]>([]);
  const [targetedSitesLoading, setTargetedSitesLoading] = useState<boolean>(
    false
  );

  const [targetedEntitiesBySiteId, setTargetedEntitiesBySiteId] = useState<
    TargetedEntitiesMappingBySiteId
  >({});

  useEffect(() => {
    const targetedEquipmentList: Equipment[] = getTargetsEntityList(
      targets,
      SentinelTargetType.EQUIPMENT,
      equipmentById
    );
    const targetedSpacesList: Space[] = getTargetsEntityList(
      targets,
      SentinelTargetType.SPACE,
      spacesById
    );

    const targetedEquipmentBySiteId: {
      [siteId: number]: Equipment[];
    } = {};

    const targetedSpacesBySiteId: {
      [siteId: number]: Space[];
    } = {};

    targetedEquipmentList.forEach(equipment => {
      const { space } = equipment;
      if (
        !space ||
        Object.keys(spacesById).length === 0 ||
        Object.keys(sitesById).length === 0
      )
        return;

      const siteId = findSiteForSpace(space, spacesById, sitesById);
      const targetedEquipment = targetedEquipmentBySiteId[siteId];
      if (targetedEquipment === undefined) {
        targetedEquipmentBySiteId[siteId] = [equipment];
      } else {
        targetedEquipmentBySiteId[siteId].push(equipment);
      }
    });

    targetedSpacesList.forEach(space => {
      if (
        Object.keys(spacesById).length === 0 ||
        Object.keys(sitesById).length === 0
      )
        return;
      const siteId = findSiteForSpace(space, spacesById, sitesById);
      const targetedSpace = targetedSpacesBySiteId[siteId];
      if (targetedSpace === undefined) {
        targetedSpacesBySiteId[siteId] = [space];
      } else {
        targetedSpacesBySiteId[siteId].push(space);
      }
    });

    const entitiesMappingBySiteId: TargetedEntitiesMappingBySiteId = {};

    targetedSites.forEach(site => {
      entitiesMappingBySiteId[site.id] = {
        equipment: targetedEquipmentBySiteId[site.id] || [],
        spaces: targetedSpacesBySiteId[site.id] || [],
      };
    });

    if (!R.equals(targetedEntitiesBySiteId, entitiesMappingBySiteId)) {
      setTargetedEntitiesBySiteId(entitiesMappingBySiteId);
    }
  }, [equipmentById, sitesById, spacesById, targets, targetedSites]);

  let initialdelay = initialSentinelDelay;
  if (isDefined(sentinel) && isDefined(sentinel.delay)) {
    initialdelay = sentinel.delay;
  }
  const [delay, setDelay] = useState(initialdelay);

  useEffect(() => {
    if (isDefined(parameters) && isDefined(parameters.delay)) {
      setDelay(parameters.delay);
    }
  }, [parameters]);

  useEffect(() => {
    if (!sentinel || !sentinel.targets || sentinel.targets.length === 0) return;
    dispatch(getSitesBySentinel(sentinel));
  }, [sentinel, dispatch]);

  useEffect(() => {
    if (sitesBySentinelId?.[id]?.isLoading) {
      setTargetedSites([]);
      setTargetedSitesLoading(true);
    }
    if (
      !R.isEmpty(sitesBySentinelId) &&
      sitesBySentinelId?.[id]?.data?.error !== undefined
    ) {
      let errors: Errors = initErrors;
      errors.rollOut = 'There is an error when loading, please try again.';
      setErrors({ ...errors });
      setIsSelectedSiteError(true);
      setTargetedSites([]);
      setTargetedSitesLoading(false);
    }
    if (!R.isEmpty(sitesBySentinelId) && sitesBySentinelId[id]?.data.length) {
      setTargetedSites(sitesBySentinelId[id].data || []);
      setTargetedSitesLoading(false);
    }
  }, [sitesBySentinelId, id]);

  function validateStepType() {
    return sentinelType !== SentinelType.NONE;
  }

  function validateStepCondition() {
    if (!validateStepType()) {
      return false;
    }

    if (!parameters) {
      return false;
    }

    if (
      sentinelType === SentinelType.BATTERY_LEVEL_CHECK ||
      sentinelType === SentinelType.CONNECTIVITY ||
      sentinelType === SentinelType.CUSTOMER_COMFORT ||
      sentinelType === SentinelType.CONTROLS_HEALTH_CHECK ||
      sentinelType === SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK ||
      sentinelType === SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK
    ) {
      return true;
    }

    if (sentinelType === SentinelType.BINARY_COUNTER) {
      return !!parameters.maxOpeningCount && parameters.maxOpeningCount > 0;
    }

    if (
      sentinelType === SentinelType.BINARY ||
      sentinelType === SentinelType.DOOR_SIREN_CHECK
    ) {
      return (
        parameters.openClosed === 'OPEN' || parameters.openClosed === 'CLOSED'
      );
    }

    if (
      sentinelType === SentinelType.TEMPERATURE ||
      sentinelType === SentinelType.TEMPERATURE_AND_DOOR ||
      sentinelType === SentinelType.ACTIVE_POWER_THRESHOLD ||
      sentinelType === SentinelType.HUMIDITY
    ) {
      if (
        parameters.comparisonType === ComparisonType.inner ||
        parameters.comparisonType === ComparisonType.outer
      ) {
        return (
          typeof parameters.rangeLowerBound === 'number' &&
          typeof parameters.rangeUpperBound === 'number' &&
          parameters.rangeLowerBound < parameters.rangeUpperBound
        );
      }

      return typeof parameters.threshold === 'number';
    }

    return false;
  }

  function validateStepSchedule() {
    if (!(validateStepType() && validateStepCondition())) {
      return false;
    }

    return true;
  }

  function validateStepHandling() {
    if (
      !(validateStepType() && validateStepCondition() && validateStepSchedule())
    ) {
      return false;
    }

    return escalations.length > 0 && escalations[0].recipients.length > 0;
  }

  function validateStepRollout() {
    if (
      !(
        validateStepType() &&
        validateStepCondition() &&
        validateStepSchedule() &&
        validateStepHandling()
      )
    ) {
      return false;
    }

    return targets.length > 0;
  }

  function validateStepAdditions() {
    if (
      !(
        validateStepType() &&
        validateStepCondition() &&
        validateStepSchedule() &&
        validateStepHandling() &&
        validateStepRollout()
      )
    ) {
      return false;
    }

    return true;
  }

  const stepsCompleted = {
    [StepType.type]: validateStepType(),
    [StepType.condition]: validateStepCondition(),
    [StepType.schedule]: validateStepSchedule(),
    [StepType.handling]: validateStepHandling(),
    [StepType.rollout]: validateStepRollout(),
    [StepType.additions]: validateStepAdditions(),
  };

  function create() {
    if (!parameters) {
      return;
    }
    if (handleCreateValidation()) {
      createAndUpdateSentinel(escalations);
      createSentinel({
        title,
        description,
        parameters,
        delay,
        targets,
        sentinelType,
        escalations,
        schedules,
        persistBeyondSchedule,
      });
    }
  }

  function update() {
    if (!parameters) {
      return;
    }
    createAndUpdateSentinel(escalations);
    updateSentinel({
      id,
      title,
      description,
      parameters,
      delay,
      targets,
      sentinelType,
      escalations,
      schedules,
      persistBeyondSchedule,
    });
  }

  function handleOnSave() {
    openModal({
      title: 'Save Changes',
      description: `Are you sure you would like to save the changes on this configuration?`,
      actionFn: update,
    });
  }

  function handleCancelNew() {
    openModal({
      title: 'Cancel Sentinel Setup',
      description: `Are you sure you would like to cancel this new configuration?`,
      actionFn: () => history.push('/sentinels'),
    });
  }

  function handleDiscard() {
    openModal({
      title: 'Discard Sentinel Changes',
      description: `Are you sure you would like to discard any changes to this configuration?`,
      actionFn: () => history.push('/sentinels'),
    });
  }

  function handleFinishLater() {
    openModal({
      title: 'Create Unfinished Sentinel',
      description: `Are you sure you would like to create an unfinished sentinel (Notifications will not be activated)`,
      actionFn: create,
    });
  }

  function onTypeChange(sentinelType: SentinelType) {
    switch (sentinelType) {
      case SentinelType.TEMPERATURE:
      case SentinelType.TEMPERATURE_AND_DOOR:
        setParameters({ ...defaultTemperatureParams });
        break;

      case SentinelType.ACTIVE_POWER_THRESHOLD:
        setParameters({ ...defaultActivePowerParams });
        break;

      case SentinelType.BINARY_COUNTER:
        setParameters({ ...defaultBinaryCounterParameters });
        break;

      case SentinelType.DOOR_SIREN_CHECK:
      case SentinelType.BINARY:
        setParameters({ ...defaultDoorParameters });
        break;

      case SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK:
      case SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK:
        setParameters({ ...defaultLocalOverrideParams });
        break;

      case SentinelType.CONNECTIVITY:
      case SentinelType.BATTERY_LEVEL_CHECK:
        setParameters({ ...defaultBatteryCheckParameters });
        break;

      case SentinelType.CUSTOMER_COMFORT:
        setParameters({ ...defaultCustomerComfortParameters });
        break;

      case SentinelType.HUMIDITY:
        setParameters({ ...defaultHumidityParams });
        break;

      case SentinelType.CONTROLS_HEALTH_CHECK:
        setParameters({ ...defaultControlsHealthCheckParameters });
        break;

      default:
        throw new Error('Unknown sentinel type');
    }

    setType(sentinelType);
    setTargets([]);
  }

  function onStepConditionChange(name: string, value: string | number) {
    let extraArgs: SentinelParameters = {};
    let errors: Errors = initErrors;

    if (!validateStepCondition()) {
      errors.condition =
        'The Temperature Upper bound must be higher than the Temperature Lower Bound.';
      setErrors({ ...errors });
    }

    if (sentinelComparisonDefaults.hasOwnProperty(sentinelType)) {
      if (value === ComparisonType.inner || value === ComparisonType.outer) {
        extraArgs = {
          ...extraArgs,
          ...sentinelComparisonDefaults[sentinelType].range,
        };
      }
      if (
        value === ComparisonType.lt ||
        value === ComparisonType.eq ||
        value === ComparisonType.gt
      ) {
        extraArgs = {
          ...extraArgs,
          ...sentinelComparisonDefaults[sentinelType].fixed,
        };
      }
    }

    setParameters({
      ...parameters,
      ...extraArgs,
      [name]: value,
    });
  }

  // max delay set as 23 hours 59 minutes
  const MAX_DELAY = 23 * 60 * 60 * 1000 + 59 * 60 * 1000;
  function onDelayChange(intervalUnit: IntervalUnit, value: string) {
    if (intervalUnit === IntervalUnit.MILLISECOND) {
      const isNumber = /^[0-9]*$/.test(value);
      let number = isNumber ? parseInt(value, 10) * 60 * 1000 : 0;
      if (number > MAX_DELAY) {
        number = MAX_DELAY;
      }

      setDelay({ ...delay, value: number });
    }

    if (intervalUnit === IntervalUnit.DEVICEINTERVAL) {
      const isNumber = /^[0-9]*$/.test(value);
      const number = isNumber ? parseInt(value, 10) : 0;
      // smaller then 15
      if (number > 15) {
        return;
      }

      setDelay({ ...delay, value: number });
    }
  }

  function onDelayUnitChange(unit: IntervalUnit) {
    setDelay({ value: 0, unit });
  }

  function onAddSchedule(sentinelSchedule: SentinelSchedule) {
    setSchedules([...schedules, sentinelSchedule]);
  }

  function onUpdateSchedule(scheduleIndex: number) {
    return function(updatedSchedule: SentinelSchedule) {
      setSchedules(
        schedules.map((schedule, index) =>
          index === scheduleIndex ? { ...updatedSchedule } : schedule
        )
      );
    };
  }

  function onRemoveSchedule(scheduleIndex: number) {
    setSchedules(schedules.filter((_, index) => index !== scheduleIndex));
  }

  function onAddHandling(escalation: SentinelEscalation) {
    setEscalations([...escalations, { ...escalation, ix: escalations.length }]);
  }

  function onRemoveHandling(escalationIndex: number) {
    setEscalations(
      escalations
        .filter((_, index) => index !== escalationIndex)
        .map((handling, index) => ({ ...handling, ix: index }))
    );
    setEditBtnEnable(prev =>
      prev.filter((_, index) => index !== escalationIndex)
    );
    isSetFormSaveError(false);
  }

  function onUpdateHandling(escalationIndex: number) {
    return function(sentinelEscalation: SentinelEscalation) {
      const { recurrenceLimit, recurrenceLimitChecked } = sentinelEscalation;
      setEscalations(
        escalations.map((escalation, index) =>
          index === escalationIndex
            ? {
                ...sentinelEscalation,
                ix: index,
                recurrenceLimit: recurrenceLimitChecked
                  ? recurrenceLimit
                  : null,
              }
            : { ...escalation, ix: index }
        )
      );
    };
  }

  function onAddRemoveTarget(sentinelTarget: SentinelTarget) {
    const hasTarget = targets.find(
      (t: any) => t.targetId === sentinelTarget.targetId
    );

    setTargets(
      hasTarget
        ? targets.filter((t: any) => t.targetId !== sentinelTarget.targetId)
        : [...targets, sentinelTarget]
    );
    setIsSelectedSiteError(false);
  }

  function onAddTargets(
    sentinelTargets: SentinelTarget[],
    siteId?: number | string,
    selectedEntities?: SentinelTargetEntities[]
  ) {
    setTargets(R.uniq([...targets, ...sentinelTargets]));
    setIsSelectedSiteError(false);
  }

  function onChangeTargets(
    sentinelTargets: SentinelTarget[],
    siteId?: number | string,
    selectedEntities?: SentinelTargetEntities[]
  ) {
    setTargets(R.uniq([...sentinelTargets]));
  }

  function onSelectAllTargets(
    sentinelTargets: SentinelTarget[],
    siteId?: number | string,
    selectedEntities?: SentinelTargetEntities[]
  ) {
    setTargets(R.uniq([...targets, ...sentinelTargets]));
  }

  function onDeleteTargets(
    deselectedTargetIds: number[] = [],
    deselectedEntityType?: SentinelTargetType,
    siteId?: number | string
  ) {
    setTargets(
      targets.filter(
        (t: SentinelTarget) =>
          !(
            deselectedTargetIds.includes(t.targetId) &&
            ((deselectedEntityType !== undefined &&
              deselectedEntityType === t.targetType) ||
              deselectedEntityType === undefined)
          )
      )
    );
    setIsSelectedSiteError(false);
  }

  const currentStepIndex = stepsKeys.findIndex(s => s === step.toString());

  const prevStepKeyIndex = currentStepIndex - 1;
  const nextStepKeyIndex = currentStepIndex + 1;

  const prevStep = sentinelSteps[stepsKeys[prevStepKeyIndex]];
  let nextStep = sentinelSteps[stepsKeys[nextStepKeyIndex]];

  const comparisonType = parameters ? parameters.comparisonType : undefined;

  if (
    nextStep &&
    nextStep.key === StepType.additions &&
    (comparisonType === ComparisonType.eq ||
      !(
        sentinelType === SentinelType.TEMPERATURE ||
        sentinelType === SentinelType.TEMPERATURE_AND_DOOR
      ))
  ) {
    nextStep = sentinelSteps[StepType.NONE];
  }

  const prevAction = () => {
    if (prevStep) {
      history.push(`/sentinels/${id}/${prevStep.key}`);
      isSetFormSaveError(false);
    } else {
      {
      }
    }
  };

  const nextAction = () => {
    if (nextStep) {
      escalations.forEach(item => {
        if (nextStep.key === 'rollout' && item.name === '') {
          if (handleValidation(item)) {
            history.push(`/sentinels/${id}/${nextStep.key}`);
          }
        } else if (nextStep.key === 'rollout') {
          if (handleValidation(item)) {
            history.push(`/sentinels/${id}/${nextStep.key}`);
          }
        } else {
          if (nextStep['title'] === 'Handling') {
            setNextToHandling(true);
          }
          if (nextStep['title'] === 'Rollout') {
            setNextToRollout(true);
          }
          if (nextStep['title'] === 'Addions') {
            setNextToAddions(true);
          }
          if (nextStep['title'] === 'Schedule') {
            setNextToSchedule(true);
          }
          if (nextStep['title'] === 'Condition') {
            setNextToCondition(true);
          }
          history.push(`/sentinels/${id}/${nextStep.key}`);
        }
      });
    } else {
      create();
    }
  };

  const handleValidation = item => {
    let errors: Errors = initErrors;
    let formIsValid = true;

    stepHandlingRef.current?.handleItemValidation();

    escalations.forEach(item => {
      if (item.message === '' && item['recipients'].length === 0) {
        formIsValid = false;
        isSetFormSaveError(true);
        errors.handling.onSave =
          'Please save or cancel the changes you made to the action above';
      }
    });

    let newData = Object.values(action);
    newData.forEach(data => {
      if (data['recipients'].length === 0 || editBtnEnable.includes(false)) {
        formIsValid = false;
        isSetFormSaveError(true);
        errors.handling.onSave =
          'Please save or cancel the changes you made to the action above';
      }
    });

    setErrors({ ...errors });
    return formIsValid;
  };

  const reRenderChild = useCallback(() => {
    setErrors({ ...errors });
  }, [errors]);

  const handleCreateValidation = () => {
    let errors: Errors = initErrors;
    let formIsValid = true;
    if (selectSite === '' && targets.length <= 0) {
      formIsValid = false;
      setIsSelectedSiteError(true);
      errors.rollOut = `Please attach the sentinel to the ${selectedTargetType.toLowerCase()}`;
    }
    if (title === '') {
      formIsValid = false;
      isSetFormSaveError(true);
      errors.title.onSave = 'Please fill in a title for the sentinel';
    }
    setErrors({ ...errors });
    return formIsValid;
  };

  if (!currentUser) return null;

  function updateEquipmentList(list) {
    dispatch(updatedEquipmentList(list));
  }

  function upDatedSentinelId(sentinelId) {
    dispatch(upDatedSentiel(sentinelId));
  }

  const isFirstTimeCountCopy = count => {
    dispatch(isFirstTimeCopy(count));
  };

  // get duplicate sentinel key and we have conditioned if duplicate sentinel key exist we have concat the key with title
  return (
    <div>
      <ModalFormContent>
        <Label>Title</Label>
        <InputField
          type="text"
          onChange={ev => setTitle(ev.currentTarget.value)}
          value={title}
          name="title"
        />
        <div className={styles.error}>
          <span className={styles.errorContainer}>
            {isFormSaveError ? errors.title.onSave : ''}
          </span>
        </div>

        <Label>Description</Label>
        <InputField
          type="text"
          onChange={ev => setDescription(ev.currentTarget.value)}
          value={description}
          name="description"
        />
      </ModalFormContent>

      <Tabs className={styles.tabs}>
        {(sentinelType === SentinelType.NONE ||
          id === 'new' ||
          dublicateSentinelkey) && (
          <TabLink to={`${Routes.SENTINELS}/${id}/type`}>Type</TabLink>
        )}
        <TabLink
          disabled={
            (id === 'new' && nextToCondition === false) ||
            (dublicateSentinelkey && nextToCondition === false)
          }
          to={`${Routes.SENTINELS}/${id}/condition`}
        >
          Condition
        </TabLink>
        <TabLink
          disabled={
            (id === 'new' && nextToSchedule === false) ||
            (dublicateSentinelkey && nextToSchedule === false)
          }
          to={`${Routes.SENTINELS}/${id}/schedule`}
        >
          Schedule
        </TabLink>
        <TabLink
          disabled={
            (id === 'new' && !stepsCompleted[StepType.handling]) ||
            (dublicateSentinelkey && nextToHandling === false)
          }
          to={`${Routes.SENTINELS}/${id}/handling`}
        >
          Handling
        </TabLink>
        <TabLink
          disabled={
            (id === 'new' && !stepsCompleted[StepType.rollout]) ||
            (dublicateSentinelkey && nextToRollout === false)
          }
          to={`${Routes.SENTINELS}/${id}/rollout`}
        >
          Rollout
        </TabLink>
        {comparisonType !== ComparisonType.eq &&
          (sentinelType === SentinelType.TEMPERATURE ||
            sentinelType === SentinelType.TEMPERATURE_AND_DOOR) && (
            <TabLink
              disabled={
                (id === 'new' && !stepsCompleted[StepType.rollout]) ||
                (dublicateSentinelkey && nextToAddions === false)
              }
              to={`${Routes.SENTINELS}/${id}/additions`}
            >
              Additions
            </TabLink>
          )}
      </Tabs>

      <Card>
        <CardContent>
          {(step === StepType.type || sentinelType === SentinelType.NONE) && (
            <StepSentinelType
              onChange={onTypeChange}
              sentinelType={sentinelType}
              sentinelTypesList={sentinelTypesList}
            />
          )}

          {step === StepType.condition && (
            <StepCondition
              currentUser={currentUser}
              sentinelType={sentinelType}
              delay={delay}
              parameters={parameters}
              customDelaySentinels={customDelaySentinels}
              onChange={onStepConditionChange}
              onDelayChange={onDelayChange}
              onUnitChange={onDelayUnitChange}
            />
          )}

          {step === StepType.schedule && (
            <StepSchedule
              handleAddSchedule={onAddSchedule}
              handleRemoveSchedule={onRemoveSchedule}
              handleUpdateSchedule={onUpdateSchedule}
              handlePersistBeyondSchedule={(value: boolean) =>
                setPersistBeyondSchedule(value)
              }
              persistBeyondSchedule={persistBeyondSchedule}
              schedules={schedules}
              sentinelType={sentinelType}
            />
          )}

          {step === StepType.handling && (
            <StepHandling
              ref={stepHandlingRef}
              escalations={escalations}
              handleAddStepHandling={onAddHandling}
              handleRemoveStepHandling={onRemoveHandling}
              handleUpdateStepHandling={onUpdateHandling}
              users={users}
              userGroups={userGroups}
              errors={errors}
              setErrors={setErrors}
              isSetErrorField={isSetErrorField}
              isErrorField={isErrorField}
              isSetEmailErrorField={isSetEmailErrorField}
              isEmailErrorField={isEmailErrorField}
              setAction={setAction}
              setEmail={setEmail}
              headerCounter={headerCounter}
              reRenderChild={reRenderChild}
              isFormSaveError={isFormSaveError}
              isSetFormSaveError={isSetFormSaveError}
              handleValidation={handleValidation}
              // onEmailValidation={onEmailValidation}
              isFirstTimeCountCopy={isFirstTimeCountCopy}
              copyCount={copyCount}
              setEditBtnEnable={setEditBtnEnable}
              editBtnEnable={editBtnEnable}
            />
          )}

          {step === StepType.rollout && (
            <ErrorBoundary>
              <StepRolloutContainer
                setTargetedSites={setTargetedSites}
                targetedSites={targetedSites}
                targetedSitesLoading={targetedSitesLoading}
                sentinelType={sentinelType}
                equipments={Object.values(equipmentById)}
                onTargetChange={onAddRemoveTarget} // Select single target checkbox
                onTargetsChange={onChangeTargets} // Select multiple targets checkbox
                onTargetsAdd={onAddTargets}
                onTargetsDelete={onDeleteTargets}
                targets={targets || []}
                targetTypes={sentinelTypeToSentinelTargetType(sentinelType)}
                sites={sites}
                openModal={openModal}
                selectSite={selectSite}
                setSelectSite={setSelectSite}
                setCheckBoxType={setCheckBoxType}
                errors={errors}
                setIsSelectedSiteError={setIsSelectedSiteError}
                isSelectedSiteError={isSelectedSiteError}
                onSelectAllTargets={onSelectAllTargets}
                updateEquipmentList={updateEquipmentList}
                newListEquipment={newListEquipment}
                handleRemoveStepHandling={onRemoveHandling}
                upDatedSentielId={upDatedSentinelId}
                sentinelTargatedType={sentinelTargatedType}
                setSelectedTargetType={setSelectedTargetType}
              />
            </ErrorBoundary>
          )}

          {step === StepType.additions && (
            <StepAdditions
              targetedEntitiesBySiteId={targetedEntitiesBySiteId}
              targetTypes={[
                SentinelTargetType.EQUIPMENT,
                SentinelTargetType.SPACE,
              ]}
              openModal={openModal}
              targetedSites={targetedSites}
              sentinelParameters={parameters}
              setSentinelParameters={setParameters}
            />
          )}
        </CardContent>
        <CardActions>
          {id === 'new' || (dublicateSentinelkey && step) ? (
            <SentinelWizardNavigation
              setStep={step => history.push(`/sentinels/new/${step}`)}
              handleFinishLater={handleFinishLater}
              handleSubmit={() => {}}
              handleCancel={handleCancelNew}
              loading={false}
              stepsCompleted={stepsCompleted}
              currentStep={step}
              prevStep={prevStep}
              nextStep={nextStep}
              prevAction={prevAction}
              nextAction={nextAction}
            />
          ) : (
            <>
              <Button variant="text" onClick={handleDiscard}>
                Cancel
              </Button>
              <Button
                onClick={
                  !dublicateSentinelkey ? handleOnSave : handleFinishLater
                }
                disabled={
                  !(
                    validateStepCondition() &&
                    validateStepSchedule() &&
                    validateStepHandling() &&
                    validateStepRollout() &&
                    validateStepAdditions()
                  )
                }
              >
                Save
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    </div>
  );
}

export const useTargetEntitiesInitialFetch = (sentinel?: Sentinel) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEquipments());

    dispatch(getSpaces({}));
    dispatch(getSites());
  }, [sentinel, dispatch]);
};

const findSiteForSpace = (
  space: Space,
  spacesById: SpacesById,
  sitesById: SitesById
): number => {
  const { parentId } = space;
  if (sitesById[parentId] !== undefined) return parentId;
  const parentSpace = spacesById[parentId];
  return findSiteForSpace(parentSpace, spacesById, sitesById);
};

const getTargetTypeEntityList = (
  currentTargetType: SentinelTargetType,
  targetTypeEntityById: SentinelTargetEntitiesById
) => (result, { targetId, targetType }) => {
  if (targetType !== currentTargetType) return result;
  const entity = targetTypeEntityById[targetId];
  if (entity !== undefined) result.push(entity);
  return result;
};

const getTargetsEntityList = (
  targets: SentinelTarget[],
  currentTargetType: SentinelTargetType,
  targetTypeEntityById: SentinelTargetEntitiesById
) => {
  return targets.reduce(
    getTargetTypeEntityList(currentTargetType, targetTypeEntityById),
    [] as SentinelTargetEntities[]
  );
};

export default SentinelWizard;
