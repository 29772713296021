import { List, Map } from 'immutable';
import { Routes } from '../../routes';
import { tabMapFn } from '../Sop/SopDetailTabs';
import styles from '../Documents/DocumentTabs.module.css';
import { Tabs } from '@energybox/react-ui-library/dist/components';

interface Props {
  siteId: number;
  children?: React.ReactNode;
}

interface Tab {
  to: string;
  displayName: string;
  featureFlag?: boolean;
}

const getTabs = (
  siteId: number
): [List<Tab>, Map<string, { index: number; displayName: string }>] => {
  const tabs = [
    {
      to: `${Routes.SITES}/${siteId}${Routes.DOCUMENTS}${Routes.UTILITY_BILLS}`,
      displayName: 'Utility Bills',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.DOCUMENTS}${Routes.INSTALLATION_DOCS}`,
      displayName: 'Installation Document',
      featureFlag: true,
    },
  ];

  let tabMap = Map<string, { index: number; displayName: string }>();

  tabs.forEach(({ to, displayName }, index) => {
    const splitRoute = to.split('/');
    tabMap = tabMap.set(`/${splitRoute[splitRoute.length - 1]}`, {
      index,
      displayName,
    });
  });
  return [List<Tab>(tabs), tabMap];
};
const getRenderedTabs = (siteId: number) => {
  let tabs: List<Tab> | undefined;

  const [defaultTabs] = getTabs(siteId);

  tabs = defaultTabs;
  return tabs.map(tabMapFn);
};

const DocumentTabs = ({ siteId, children }: Props) => {
  const renderedTabs = getRenderedTabs(siteId);

  return (
    <div>
      <Tabs className={styles.tabs}>{renderedTabs}</Tabs>

      <div className={styles.content}> {children} </div>
    </div>
  );
};

export default DocumentTabs;
