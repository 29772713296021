import { SensorsWhitelist } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions as StreamActions, GatewayAction } from '../actions/streamApi';
import { Status } from '../types/status';

export type SensorWhitelistByGatewayId = {
  [id: string]: SensorsWhitelist;
};

export type SensorWhitelist = {
  sensorWhitelistByGatewayId: SensorWhitelistByGatewayId;
  status: Status;
};

const initialState = {
  sensorWhitelistByGatewayId: {},
  status: Status.INIT,
};

const sensorWhitelist = (state = initialState, action: any) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      if (action.data && action.data.deviceType === 'gateway') {
        if (action.data.sensorsWhitelist) {
          return R.pipe(
            R.assocPath(
              ['sensorWhitelistByGatewayId', action.data.id.toString()],
              action.data.sensorsWhitelist
            ),
            R.assoc('status', Status.SUCCESS)
          )(state);
        }
      }
      return state;
    }
    case StreamActions.SEND_MESSAGE: {
      if (action.data.action === GatewayAction.EMIT_SENSORS_WHITELIST) {
        return {
          ...state,
          status: Status.LOADING,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default sensorWhitelist;
