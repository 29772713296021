import { WorkingMode } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import {
  hideEditControlModal,
  hideNewControlModal,
  hideDeleteControlModal,
} from './controls';
import { formatDeltas } from '../utils/delta';
import { getNonHvacEquipments } from './aggregate_views';
const apiBase = '/api/v1';

export enum Actions {
  CREATE_SCHEDULER_SUCCESS = '@schedulers/CREATE_SCHEDULER_SUCCESS',
  CREATE_SCHEDULER_ERROR = '@schedulers/CREATE_SCHEDULER_ERROR',
  CREATE_SCHEDULER_LOADING = '@schedulers/CREATE_SCHEDULER_LOADING',

  CREATE_SCHEDULER_TEMPERATURE_CONTROLS_SUCCESS = '@schedulers/CREATE_SCHEDULER_TEMPERATURE_CONTROLS_SUCCESS',
  CREATE_SCHEDULER_TEMPERATURE_CONTROLS_ERROR = '@schedulers/CREATE_SCHEDULER_TEMPERATURE_CONTROLS_ERROR',
  CREATE_SCHEDULER_TEMPERATURE_CONTROLS_LOADING = '@schedulers/CREATE_SCHEDULER_TEMPERATURE_CONTROLS_LOADING',

  CREATE_SCHEDULER_EXISTING_TEMPERATURE_CONTROLS_SUCCESS = '@schedulers/CREATE_SCHEDULER_EXISTING_TEMPERATURE_CONTROLS_SUCCESS',
  CREATE_SCHEDULER_EXISTING_TEMPERATURE_CONTROLS_ERROR = '@schedulers/CREATE_SCHEDULER_EXISTING_TEMPERATURE_CONTROLS_ERROR',
  CREATE_SCHEDULER_EXISTING_TEMPERATURE_CONTROLS_LOADING = '@schedulers/CREATE_SCHEDULER_EXISTING_TEMPERATURE_CONTROLS_LOADING',

  GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS = '@schedulers/GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS',
  GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR = '@schedulers/GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR',
  GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING = '@schedulers/GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING',

  PATCH_SCHEDULER_LOADING = '@schedulers/PATCH_SCHEDULER_LOADING',
  PATCH_SCHEDULER_SUCCESS = '@schedulers/PATCH_SCHEDULER_SUCCESS',
  PATCH_SCHEDULER_ERROR = '@schedulers/PATCH_SCHEDULER_ERROR',

  PATCH_SCHEDULER_BATCH_LOADING = '@schedulers/PATCH_SCHEDULER_BATCH_LOADING',
  PATCH_SCHEDULER_BATCH_SUCCESS = '@schedulers/PATCH_SCHEDULER_BATCH_SUCCESS',
  PATCH_SCHEDULER_BATCH_ERROR = '@schedulers/PATCH_SCHEDULER_BATCH_ERROR',

  PATCH_SCHEDULER_BATCH_ADD_LOADING = '@schedulers/PATCH_SCHEDULER_BATCH_ADD_LOADING',
  PATCH_SCHEDULER_BATCH_ADD_SUCCESS = '@schedulers/PATCH_SCHEDULER_BATCH_ADD_SUCCESS',
  PATCH_SCHEDULER_BATCH_ADD_ERROR = '@schedulers/PATCH_SCHEDULER_ADD_BATCH_ERROR',

  PATCH_SCHEDULER_TEMPERATURE_CONTROLS_LOADING = '@schedulers/PATCH_SCHEDULER_TEMPERATURE_CONTROLS_LOADING',
  PATCH_SCHEDULER_TEMPERATURE_CONTROLS_SUCCESS = '@schedulers/PATCH_SCHEDULER_TEMPERATURE_CONTROLS_SUCCESS',
  PATCH_SCHEDULER_TEMPERATURE_CONTROLS_ERROR = '@schedulers/PATCH_SCHEDULER_TEMPERATURE_CONTROLS_ERROR',

  DELETE_SCHEDULER_SUCCESS = '@schedulers/DELETE_SCHEDULER_SUCCESS',
  DELETE_SCHEDULER_ERROR = '@schedulers/DELETE_SCHEDULER_ERROR',
  DELETE_SCHEDULER_LOADING = '@schedulers/DELETE_SCHEDULER_LOADING',

  CREATE_NEW_SCHEDULER_IN_STORE = '@schedulers/CREATE_NEW_SCHEDULER_IN_STORE',
  UPDATE_FIELD = '@schedulers/UPDATE_FIELD',
  RESET_EDIT_SCHEDULER = '@schedulers/RESET_EDIT_SCHEDULER',
  RESET_BATCH_EDIT_SCHEDULER = '@schedulers/RESET_BATCH_EDIT_SCHEDULER',
  DISPLAY_FORM_ERRORS = '@schedulers/DISPLAY_FORM_ERRORS',

  PATCH_SCHEDULER_CONTROL_MODE_LOADING = '@schedulers/PATCH_SCHEDULER_CONTROL_MODE_LOADING',
  PATCH_SCHEDULER_CONTROL_MODE_SUCCESS = '@schedulers/PATCH_SCHEDULER_CONTROL_MODE_SUCCESS',
  PATCH_SCHEDULER_CONTROL_MODE_ERROR = '@schedulers/PATCH_SCHEDULER_CONTROL_MODE_ERROR',
}

export type PostSchedulerParams = {
  beginDelta: number;
  endDelta: number;
  equipmentId: number;
  timetableId: number | string | null;
  lightSensorSettings: null | {
    controlBoardId: number;
    threshold: number;
    hysteresis: number;
    timetableId: number | null;
    beginDelta: number;
    endDelta: number;
    actionInterval: number;
  };
  workingMode?: WorkingMode;
};

export type PostBatchSchedulerParams = {
  [id: number]: PostSchedulerParams;
};

export const formatSchedulerDeltas = (params: any) => {
  if (
    isDefined(params.lightSensorSettings) &&
    isDefined(params.lightSensorSettings.beginDelta) &&
    isDefined(params.lightSensorSettings.endDelta)
  ) {
    return {
      ...params,
      beginDelta:
        params.beginDelta >= 0
          ? `+${params.beginDelta}m`
          : `${params.beginDelta}m`,
      endDelta:
        params.endDelta >= 0 ? `+${params.endDelta}m` : `${params.endDelta}m`,
      lightSensorSettings: {
        ...params.lightSensorSettings,
        actionInterval: Number.parseInt(
          params.lightSensorSettings.actionInterval
        ),
        beginDelta:
          params.lightSensorSettings.beginDelta >= 0
            ? `+${params.lightSensorSettings.beginDelta}m`
            : `${params.lightSensorSettings.beginDelta}m`,
        endDelta:
          params.lightSensorSettings.endDelta >= 0
            ? `+${params.lightSensorSettings.endDelta}m`
            : `${params.lightSensorSettings.endDelta}m`,
      },
    };
  } else {
    return {
      ...params,
      beginDelta:
        params.beginDelta >= 0
          ? `+${params.beginDelta}m`
          : `${params.beginDelta}m`,
      endDelta:
        params.endDelta >= 0 ? `+${params.endDelta}m` : `${params.endDelta}m`,
    };
  }
};

const formatBatchSchedulerDeltas = payload => {
  const formattedPayload = {};
  for (const key in payload) {
    if (payload.hasOwnProperty(key)) {
      formattedPayload[key] = formatSchedulerDeltas(payload[key]);
    }
  }
  return formattedPayload;
};

export const patchSchedulerControlWorkingMode = (
  equipmentId: string,
  schedulerId: number,
  workingMode: WorkingMode,
  edgeUuid: string | undefined
) => ({
  type: 'API_PATCH',
  path: `${apiBase}/schedulers/${schedulerId}/working-mode`,
  payload: { workingMode, propagateConfig: true },
  success: [
    {
      type: Actions.PATCH_SCHEDULER_CONTROL_MODE_SUCCESS,
      schedulerId,
      edgeUuid,
    },
    getSchedulerByEquipmentId(equipmentId),
  ],
  error: {
    type: Actions.PATCH_SCHEDULER_CONTROL_MODE_ERROR,
    schedulerId,
    edgeUuid,
  },
  loading: {
    type: Actions.PATCH_SCHEDULER_CONTROL_MODE_LOADING,
    schedulerId,
    edgeUuid,
  },
});

export const createScheduler = (params: PostSchedulerParams) => ({
  type: 'API_POST',
  path: `${apiBase}/schedulers`,
  payload: formatSchedulerDeltas(params),
  success: [Actions.CREATE_SCHEDULER_SUCCESS, hideNewControlModal()],
  error: Actions.CREATE_SCHEDULER_ERROR,
  loading: Actions.CREATE_SCHEDULER_LOADING,
});

export const patchScheduler = (
  schedulerId: string,
  params: PostSchedulerParams
) => ({
  type: 'API_PUT',
  path: `${apiBase}/schedulers/${schedulerId}`,
  payload: formatSchedulerDeltas(params),
  success: [
    {
      type: Actions.PATCH_SCHEDULER_SUCCESS,
      schedulerId,
    },
    hideEditControlModal(),
  ],
  error: {
    type: Actions.PATCH_SCHEDULER_ERROR,
    schedulerId,
  },
  loading: {
    type: Actions.PATCH_SCHEDULER_LOADING,
    schedulerId,
  },
});

export const patchSchedulerBatch = (
  schedulerParamsList: PostBatchSchedulerParams,
  orgId: number
) => ({
  type: 'API_PUT',
  path: `${apiBase}/schedulers/batch`,
  payload: formatBatchSchedulerDeltas(schedulerParamsList),
  success: [Actions.PATCH_SCHEDULER_BATCH_SUCCESS, getNonHvacEquipments(orgId)],
  error: Actions.PATCH_SCHEDULER_BATCH_ERROR,
  loading: Actions.PATCH_SCHEDULER_BATCH_LOADING,
});

export const patchSchedulerBatchAdd = (
  schedulerParamsList: PostBatchSchedulerParams[],
  orgId: number
) => ({
  type: 'API_POST',
  path: `${apiBase}/schedulers/batch`,
  payload: schedulerParamsList.map(params => formatSchedulerDeltas(params)),
  success: [
    Actions.PATCH_SCHEDULER_BATCH_ADD_SUCCESS,
    getNonHvacEquipments(orgId),
  ],
  error: Actions.PATCH_SCHEDULER_BATCH_ADD_ERROR,
  loading: Actions.PATCH_SCHEDULER_BATCH_ADD_LOADING,
});

export const patchSchedulerForTemperatureControls = (
  schedulerId: string | number,
  temperatureControlsId: string | number,
  params: PostSchedulerParams
) => ({
  type: 'API_PUT',
  path: `${apiBase}/schedulers/${schedulerId}`,
  payload: formatDeltas(params),
  success: [
    {
      type: Actions.PATCH_SCHEDULER_TEMPERATURE_CONTROLS_SUCCESS,
      temperatureControlsId: `${temperatureControlsId}`,
    },
    getSchedulerByEquipmentId(params.equipmentId.toString()),
  ],
  error: {
    type: Actions.PATCH_SCHEDULER_TEMPERATURE_CONTROLS_ERROR,
    schedulerId,
  },
  loading: {
    type: Actions.PATCH_SCHEDULER_TEMPERATURE_CONTROLS_LOADING,
    schedulerId,
  },
});

export const getSchedulerByEquipmentId = (equipmentId: string) => ({
  type: 'API_GET',
  path: `${apiBase}/schedulers/equipment/${equipmentId}`,
  success: { type: Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS, equipmentId },
  error: { type: Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR, equipmentId },
  loading: { type: Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING, equipmentId },
});

export const deleteScheduler = (
  schedulerId: number,
  equipmentId: number,
  orgId?: number
) => ({
  type: 'API_DELETE',
  path: `${apiBase}/schedulers/${schedulerId}`,
  success: [
    { type: Actions.DELETE_SCHEDULER_SUCCESS, equipmentId, schedulerId },
    hideDeleteControlModal(),
    getNonHvacEquipments(orgId),
  ],
  error: { type: Actions.DELETE_SCHEDULER_ERROR, equipmentId, schedulerId },
  loading: { type: Actions.DELETE_SCHEDULER_LOADING, equipmentId, schedulerId },
});

export const createNewSchedulerInStoreAndOpenControlModal = () => ({
  type: Actions.CREATE_NEW_SCHEDULER_IN_STORE,
});

export const updateField = (
  id: string,
  field: string | string[],
  value: any
) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const reset = (id: string | number, equipmentId: string | number) => ({
  type: Actions.RESET_EDIT_SCHEDULER,
  id,
  equipmentId,
});

export const batchReset = () => ({
  type: Actions.RESET_BATCH_EDIT_SCHEDULER,
  value: {},
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});
