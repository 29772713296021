import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getNetworkGroupBySiteId } from '../../actions/network_groups';
import { ApplicationState } from '../../reducers';
import { networkGroupsFromSiteId } from '../../reducers/network_groups';

interface OwnProps {
  onSelect: (networkGroupId: number) => void;
  siteId?: number | string;
  value?: number;
  error?: boolean;
  customErrorText?: string;
  disabled?: boolean;
  setSelectedNetworkGroup?: (networkGroup: NetworkGroup) => void;
}

interface Props extends OwnProps {
  getNetworkGroupBySiteId: typeof getNetworkGroupBySiteId;
  networkGroups?: NetworkGroup[];
  testResultsExist?: boolean;
}

interface State {
  search: string;
}

class SelectNetworkGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { siteId, getNetworkGroupBySiteId } = this.props;
    if (siteId && siteId !== -1) {
      getNetworkGroupBySiteId(String(siteId));
    }
  }

  componentDidUpdate(prevProps) {
    const { siteId, getNetworkGroupBySiteId } = this.props;
    if (siteId && siteId !== -1 && prevProps.siteId !== siteId) {
      getNetworkGroupBySiteId(String(siteId));
    }

    const { value, networkGroups, setSelectedNetworkGroup } = this.props;
    const { value: prevValue, networkGroups: prevNetworkGroups } = prevProps;
    if (
      value &&
      setSelectedNetworkGroup &&
      (value !== prevValue ||
        networkGroups?.length !== prevNetworkGroups?.length)
    ) {
      const selectedNetworkGroup = this.props.networkGroups?.find(
        networkGroup => networkGroup.id === value
      );
      selectedNetworkGroup && setSelectedNetworkGroup(selectedNetworkGroup);
    }
  }

  filteredNetworkGroups = (networkGroups: NetworkGroup[]) => {
    const { search } = this.state;
    return networkGroups.filter((networkGroup: NetworkGroup) => {
      if (search.length <= 2) return true;
      return hasSubstr(networkGroup.title, search);
    });
  };

  isSiteSelected = () => {
    if (this.props.siteId !== -1) {
      return true;
    }
    return false;
  };

  render() {
    const {
      networkGroups,
      value,
      onSelect,
      error,
      customErrorText,
      testResultsExist,
      disabled,
      setSelectedNetworkGroup,
    } = this.props;

    if (!networkGroups) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;
      const selectedNetworkGroup = networkGroups.find(
        networkGroup => networkGroup.id === value
      );
      selectedNetworkGroup &&
        setSelectedNetworkGroup &&
        setSelectedNetworkGroup(selectedNetworkGroup);
      const filteredNetworkGroups = this.filteredNetworkGroups(networkGroups);

      return (
        <Select
          disabled={disabled || !this.isSiteSelected() || testResultsExist}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedNetworkGroup?.title}
          value={selectedNetworkGroup?.id}
          error={error}
          customErrorText={customErrorText}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
            error={filteredNetworkGroups.length === 0}
          />
          {filteredNetworkGroups.map((networkGroup: NetworkGroup) => (
            <SelectItem
              key={networkGroup.id}
              isSelected={networkGroup.id === selectedNetworkGroup?.id}
              onSelect={() => onSelect(networkGroup.id)}
            >
              {networkGroup.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}
const mapStateToProps = (
  { networkGroups }: ApplicationState,
  { siteId }: OwnProps
) => ({
  networkGroups: networkGroupsFromSiteId(networkGroups, String(siteId)),
});

const mapDispatchToProps = {
  getNetworkGroupBySiteId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectNetworkGroup);
