import Button from '@energybox/react-ui-library/dist/components/Button';
import Modal from '@energybox/react-ui-library/dist/components/Modal';
import ModalContent from '@energybox/react-ui-library/dist/components/ModalContent';
import ModalTitle from '@energybox/react-ui-library/dist/components/ModalTitle';
import Loader from '@energybox/react-ui-library/dist/components/Loader';
import { hasKeys, isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectSopToEditId } from '../../hooks/useHvacSops';
import {
  create,
  displayFormErrors,
  hideNewOrEditHvacSopModal,
  update,
} from '../../actions/sops';
import EditHvacSopForm from '../../components/EditHvacSopForm';
import { useHvacSopDataToEdit } from '../../components/EditHvacSopForm/EditHvacSopForm';
import { useGetNetworkGroupsBySopId } from '../../hooks/useGetNetworkGroup';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { Actions as SopActions } from '../../actions/sops';
import { ApplicationState } from '../../reducers';
import { CreateNewText } from '../../types/global';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import styles from './NewOrEditHvacSopModal.module.css';
import { getEdgeConfigFileBySerialNumber } from '../../actions/edge_devices';
import { resetRecords } from '../../actions/records';

interface Props {
  organizationUnitId: string;
  equipmentAffectedCount: number;
  isOrgLevelEdit?: boolean;
}

const NewOrEditHvacSopModal: React.FC<Props> = ({
  organizationUnitId,
  equipmentAffectedCount,
  isOrgLevelEdit,
}) => {
  const dispatch = useDispatch();
  const [formStep, setFormStep] = useState<'stepOne' | 'stepTwo' | 'stepThree'>(
    'stepOne'
  );
  const orgId = useCurrentOrganizationId();
  const sopToEditId = useSelectSopToEditId();
  const sopToEdit = useHvacSopDataToEdit();
  const networkGroups = useGetNetworkGroupsBySopId(sopToEditId, orgId);

  const networkGroupIds = useMemo(() => {
    if (!isDefined(networkGroups)) {
      return [];
    }
    return networkGroups.map(ng => ng.id);
  }, [networkGroups]);

  const serialNumbers = useMemo(() => {
    if (!isDefined(networkGroups)) {
      return undefined;
    }
    return networkGroups.map(ng => ng.edge?.serialNumber!);
  }, [networkGroups]);

  const getEdgeConfigFile = useCallback(() => {
    serialNumbers?.map(ng => {
      if (ng !== undefined) {
        dispatch(getEdgeConfigFileBySerialNumber(ng));
      }
    });
  }, [dispatch, serialNumbers]);

  useEffect(() => {
    getEdgeConfigFile();
  }, [getEdgeConfigFile]);

  const isNewSop = sopToEditId === 'new';
  const hvacSopFields = sopToEdit?.fields;
  const formErrors = sopToEdit?.formErrors;
  const isChanged = sopToEdit?.isChanged;
  const apiError = sopToEdit?.apiError || {};

  const isVisible = useSelector<ApplicationState, boolean>(({ sops }) => {
    return sops.showNewOrEditHvacSopModal;
  });

  const isLoading = useSelector<ApplicationState, boolean>(({ sops }) => {
    return sops.isLoading;
  });

  let array: Array<string> = [];
  const validationError: string[] = useSelector(
    ({ edgeDevices }: ApplicationState) => {
      serialNumbers?.map(serialNumber => {
        const edgeConfigFile =
          edgeDevices.edgeConfigFileBySerialNumber[serialNumber];
        if (!edgeConfigFile) return null;
        const { data } = edgeConfigFile;
        const error = data?.yamlValidationErrors;
        if (error) {
          array = error.slice();
        }
      });
      return array;
    }
  );

  const determineActionButtonTitle = () => {
    if (isLoading) {
      return <Loader size={12} />;
    }

    switch (formStep) {
      case 'stepOne': {
        return 'Next';
      }

      case 'stepTwo': {
        return isNewSop ? 'Add' : 'Confirm';
      }

      case 'stepThree': {
        return 'Update';
      }

      default:
        return '';
    }
  };

  const determineModalTitle = () => {
    switch (formStep) {
      case 'stepOne':
      case 'stepTwo': {
        return isNewSop ? CreateNewText.SOP_HVAC : 'Update HVAC SOP';
      }

      case 'stepThree': {
        return isNewSop
          ? 'Confirmation for creating HVAC SOP'
          : 'Confirmation for updating HVAC SOP';
      }

      default:
        return '';
    }
  };

  const onActionButtonClick = () => {
    switch (formStep) {
      case 'stepOne': {
        setFormStep('stepTwo');
        break;
      }

      case 'stepTwo': {
        const isValid = validateHvacSop();
        if (isValid) {
          setFormStep('stepThree');
        }
        break;
      }

      case 'stepThree': {
        createOrUpdateHvacSop();
        break;
      }

      default:
        break;
    }
  };

  const onClose = () => {
    // dispatch( resetTimeTable())
    dispatch(hideNewOrEditHvacSopModal(sopToEditId));
    setFormStep('stepOne');
  };

  /**
   * return true if HvacSop fields are valid.
   * Otherwise returns false
   */
  const validateHvacSop = () => {
    if (!isDefined(hvacSopFields)) return false;

    if (isDefined(formErrors) && hasKeys(formErrors)) {
      dispatch(displayFormErrors(sopToEditId));
      if (
        isDefined(formErrors.title) ||
        isDefined(formErrors.equipmentTypeIds) ||
        isDefined(formErrors.thermostatDisplayUnits)
      ) {
        // if validation for something fails in step one, set to step one
        setFormStep('stepOne');
      }

      return false;
    }

    return true;
  };

  const createOrUpdateHvacSop = () => {
    // Reset "Last Edited By" information
    dispatch(resetRecords());
    if (isNewSop) {
      dispatch(create('HVAC', orgId, isOrgLevelEdit));
    } else {
      dispatch(
        update(sopToEditId, 'HVAC', {
          produceEdgeConfigByNetworkGroupIds: networkGroupIds,
          orgId: isOrgLevelEdit ? orgId : undefined,
        })
      );
    }
  };

  if (isVisible === false) {
    return null;
  }

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button
        disabled={formStep === 'stepTwo' && !isChanged}
        onClick={onActionButtonClick}
      >
        {determineActionButtonTitle()}
      </Button>
    </>
  );

  const isModalStepThree = formStep === 'stepThree';
  return (
    <Modal
      containerClassName={
        isModalStepThree ? styles.confirmationModal : styles.modal
      }
      onClose={onClose}
      actions={actions}
      disableEscapeClose
    >
      <ModalTitle
        className={isModalStepThree ? styles.confirmationModalTitle : ''}
      >
        {/* There doesn't seem to be a CreateNewText equivalent for Update */}
        {determineModalTitle()}
      </ModalTitle>
      <ModalContent
        className={isModalStepThree ? styles.confirmationModalContent : ''}
      >
        <EditHvacSopForm
          isNewSop={isNewSop}
          formStep={formStep}
          setFormStep={setFormStep}
          siteId={organizationUnitId}
          equipmentAffectedCount={equipmentAffectedCount}
          isOrgLevelEdit={isOrgLevelEdit}
        />
        <div className={styles.apiErrorMessage}>
          {renderAPIerror(
            apiError,
            SopActions.CREATE_SOP_ERROR,
            SopActions.UPDATE_SOP_ERROR
          )}
          <div className={styles.pinkText}>
            {isModalStepThree && validationError.length
              ? `Configuration Warning: ${validationError}`
              : ''}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default NewOrEditHvacSopModal;
