import {
  AmbientSensor as AmbientSensorIcon,
  ControlsHealth as ControlsHealthIcon,
  DoorSensor as DoorIcon,
  DoorSirenOn,
  EnergySensor as IconEnergySensor,
  GasStation as GasStationIcon,
  GatewayLowBattery as GatewayLowBatteryIcon,
  GatewayOffline as GatewayOfflineIcon,
  HumiditySensor as HumidityIcon,
  LocalOverride as SiteControllerLocalOverrideIcon,
  ThermostatOverride as ThermostatLocalOverrideIcon,
  TemperatureSensor as TemperatureIcon,
} from '@energybox/react-ui-library/dist/icons';
import { Sentinel, SentinelType } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { openModal } from '../actions/modal';
import { deleteSentinel } from '../actions/sentinels';
import history from '../history';
import { Routes } from '../routes';
import { StepType } from '../types/sentinelWizard';

type Props = {
  type: SentinelType;
  size: number;
};

export function SentinelTypeIcon({ type, size }: Props) {
  switch (type) {
    case SentinelType.TEMPERATURE:
      return <TemperatureIcon size={size} />;
    case SentinelType.BINARY_COUNTER:
    case SentinelType.BINARY:
      return <DoorIcon size={size} />;
    case SentinelType.DOOR_SIREN_CHECK:
      return <DoorSirenOn size={size} />;
    case SentinelType.HUMIDITY:
      return <HumidityIcon size={size} />;
    case SentinelType.BATTERY_LEVEL_CHECK:
      return <GatewayLowBatteryIcon size={size} />;
    case SentinelType.CONNECTIVITY:
      return <GatewayOfflineIcon size={size} />;
    case SentinelType.CUSTOMER_COMFORT:
      return <AmbientSensorIcon size={size} />;
    case SentinelType.TEMPERATURE_AND_DOOR:
      return <GasStationIcon size={size} />;
    case SentinelType.ACTIVE_POWER_THRESHOLD:
      return <IconEnergySensor size={size} />;
    case SentinelType.CONTROLS_HEALTH_CHECK:
      return <ControlsHealthIcon size={size} />;
    case SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK:
      return <SiteControllerLocalOverrideIcon size={size} />;
    case SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK:
      return <ThermostatLocalOverrideIcon size={size} />;
    case SentinelType.NONE:
    default:
      return null;
  }
}

export const determineWizardStep = (sentinel: Sentinel): StepType => {
  if (!sentinel.sentinelType) {
    return StepType.type;
  }

  if (!sentinel.parameters || Object.keys(sentinel.parameters).length === 0) {
    return StepType.condition;
  }

  // if(sentinel.schedule){
  //   return stepKeys.schedule
  // }

  if (
    (sentinel.escalations && sentinel.escalations.length === 0) ||
    // @ts-ignore todo: ts-fix
    [].concat(...sentinel.escalations.map(escalation => escalation.recipients))
      .length === 0
  ) {
    return StepType.handling;
  }

  if (sentinel.targets && sentinel.targets.length === 0) {
    return StepType.rollout;
  }

  return StepType.type;
};

export const countRecipients = (sentinel: Sentinel) => {
  let newArr: any = [];
  for (let i = 0; i < sentinel.escalations.length; i++) {
    for (let j = 0; j < sentinel.escalations[i].recipients.length; j++) {
      newArr.push(sentinel.escalations[i].recipients[j].identifier);
    }
  }
  const filteredItems = [
    ...new Set(
      newArr.filter((value, index, self) => self.indexOf(value) === index)
    ),
  ];
  return filteredItems.length;
};

export const countTargets = (sentinel: Sentinel) => {
  return sentinel.targets.length;
};

export const countMutedTargets = (sentinel: Sentinel) => {
  return sentinel.mutedTargets ? sentinel.mutedTargets.length : 0;
};

export const deleteSentinelModal = (
  openModalFn: typeof openModal,
  actionFn: typeof deleteSentinel,
  targetId: string,
  targetTitle: string
) => {
  openModalFn({
    title: 'Delete configuration',
    description: `Do you really want to delete the configuration ${targetTitle}?`,
    actionFn: () => {
      actionFn(targetId);
      history.push(Routes.SENTINELS);
    },
  });
};

export const channel = (sentinel: Sentinel) => {
  let newArr: any = [];
  // for (let i = 0; i < sentinel.escalations.length; i++) {
  //   if (sentinel.escalations[i].channelType !== null) {
  //     for (let j = 0; j < sentinel.escalations[i].channelType.length; j++) {
  //       newArr.push(sentinel.escalations[i].channelType[j]);
  //     }
  //   }
  // }

  if (!newArr.includes('PLATFORM')) newArr.push('PLATFORM');

  const filteredItems = [
    ...new Set(
      newArr.filter((value, index, self) => self.indexOf(value) === index)
    ),
  ];

  return filteredItems.length === 0 ? 4 : filteredItems.length;
};
