import {
  NetworkGroup,
  EnergyPro,
} from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/apiUtils';

export const fetchEnergyProsBySiteId = (
  siteId: string | number
): Promise<EnergyPro[]> =>
  fetchApi({
    endpoint: `/api/v1/energy-pro/site/${siteId}`,
  });

export const fetchNetworkGroupsBySiteId = (
  siteId: string | number
): Promise<NetworkGroup[]> =>
  fetchApi({
    endpoint: `/api/v1/network-groups/site/${siteId}`,
  });
