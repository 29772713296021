import {
  Equipment,
  EquipmentType,
  SensorType,
  SentinelTarget,
  SentinelTargetType,
  SentinelType,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import {
  fetchEnergyEquipmentBySiteId,
  fetchEquipment,
} from '../../../../actions/equipmentFetch';
import RolloutSelector from './RolloutSelector';
import {
  filterRolloutEquipmentByEquipmentType,
  filterRolloutEquipmentBySentinelType,
} from './StepRolloutEquipment';
import styles from './StepRollout.module.css';

type Props = {
  site: Site;
  sensorTypes: SensorType[];
  sentinelType?: SentinelType;
  handleTargetsAdd: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (
    deselectedTargetIds: number[],
    deselectedEntityType?: SentinelTargetType
  ) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  isCollapsed: boolean;
  updateEquipmentList: (list: any) => void;
  upDatedSentielId: (sentinelId: any) => void;
  equipmentTypeFilter?: EquipmentType[];
};

type State = {
  equipment: Equipment[];
  isLoading: boolean;
};

class StepRolloutEquipmentHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      equipment: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    if (this.props.sentinelType === SentinelType.ACTIVE_POWER_THRESHOLD) {
      this.loadEnergyEquipment();
    } else {
      this.onLoad();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { equipmentTypeFilter } = this.props;
    if (
      equipmentTypeFilter &&
      prevProps.equipmentTypeFilter &&
      equipmentTypeFilter.map((et: EquipmentType) => et.id).join() !==
        prevProps.equipmentTypeFilter.map((et: EquipmentType) => et.id).join()
    ) {
      this.onLoad();
    }
  }

  loadEnergyEquipment = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site } = this.props;
      const equipment = await fetchEnergyEquipmentBySiteId(site.id);
      handleSetResourceRelation(
        site.id,
        equipment.map((e: Equipment) => e.id)
      );
      const filteredEquipmentList = filterRolloutEquipmentBySentinelType(
        equipment,
        this.props.sentinelType
      );
      const sentinelTargetTypeID = SentinelTargetType.EQUIPMENT;

      this.props.updateEquipmentList(filteredEquipmentList);
      this.props.upDatedSentielId(sentinelTargetTypeID);
      this.setState({
        equipment,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site, sensorTypes } = this.props;

      const equipment = await fetchEquipment({
        siteIds: [site.id],
        sensorTypes: sensorTypes.length === 0 ? undefined : sensorTypes,
      });

      handleSetResourceRelation(
        site.id,
        equipment.map((e: Equipment) => e.id)
      );

      const filteredEquipmentList = filterRolloutEquipmentBySentinelType(
        filterRolloutEquipmentByEquipmentType(
          equipment,
          this.props.equipmentTypeFilter || []
        ),
        this.props.sentinelType
      );
      const sentinelTargetTypeID = SentinelTargetType.EQUIPMENT;

      this.props.updateEquipmentList(filteredEquipmentList);
      this.props.upDatedSentielId(sentinelTargetTypeID);
      this.setState({
        equipment,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { equipment = [], isLoading } = this.state;
    const {
      sentinelType,
      selected,
      isCollapsed,
      handleTargetsAdd,
      handleTargetDelete,
      equipmentTypeFilter,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const filteredEquipmentList = filterRolloutEquipmentBySentinelType(
      filterRolloutEquipmentByEquipmentType(
        equipment,
        equipmentTypeFilter || []
      ),
      sentinelType
    );
    const selectedBySite = equipment
      ? filteredEquipmentList.reduce(
          (sum, item) => (selected.includes(item.id) ? sum + 1 : sum),
          0
        )
      : 0;

    const total = filteredEquipmentList.length;

    if (total === 0 && !isCollapsed) {
      return (
        <div style={{ marginBottom: 6 }}>
          The site does not have the required equipment type.
        </div>
      );
    }

    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Equipment</th>
              <th style={{ width: '50%' }}>{isCollapsed && 'Location'}</th>
              <th
                style={{ width: '20%' }}
                className={classNames(styles.actionHeader, styles.action)}
              >
                {total > 0 && (
                  <RolloutSelector
                    isCollapsed={isCollapsed}
                    selected={selectedBySite}
                    totalSelected={total}
                    onDelete={() =>
                      handleTargetDelete(
                        filteredEquipmentList.map(item => item.id),
                        SentinelTargetType.EQUIPMENT
                      )
                    }
                    onSelect={() =>
                      handleTargetsAdd(
                        filteredEquipmentList.map(item => ({
                          targetId: item.id,
                          targetType: SentinelTargetType.EQUIPMENT,
                        }))
                      )
                    }
                  />
                )}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

export default StepRolloutEquipmentHeader;
