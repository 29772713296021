import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { assocPath, pipe } from 'ramda';
import { Actions as StreamActions } from '../actions/streamApi';

export type SubscribedNetworkGroups = {
  byEdgeUuid: SubscribedNetworkGroupsByEdgeUuid;
};

export type SubscribedNetworkGroupsByEdgeUuid = {
  [edgeUuid: string]: SubscribedNetworkGroup;
};

type SubscribedNetworkGroupStatus = {
  appVersion: string;
  bootStep: string;
  configLastModified: string;
  configSource: string;
  configurationHash: string;
  isHashEqual: boolean;
  lastSentConfigurationHash: string;
  osArchitecture: string;
  osName: string;
  osVersion: string;
  timestamp: string;
  updatedAt: string;
};

export type SubscribedNetworkGroup = {
  deviceType: 'edge';
  //id and uuid will be the same, functionally it's the edge uuid
  id: string;
  uuid: string;
  status: SubscribedNetworkGroupStatus;
  vendor: 'cisco';
};

const initialState = {
  byEdgeUuid: {},
};

const normalizeSubscribedNetworkGroup = (data: any) => ({
  deviceType: data.deviceType,
  id: data.id,
  status: data.status,
  uuid: data.uuid,
  vendor: data.vendor,
});

const subscribedNetworkGroups = (
  state: SubscribedNetworkGroups = initialState,
  action: any
) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      const isValidSubscribedNetworkGroup =
        action.data?.deviceType === 'edge' &&
        action.data?.vendor === 'energybox' &&
        isDefined(action.data?.status) &&
        isDefined(action.data?.uuid);

      if (isValidSubscribedNetworkGroup) {
        return pipe(
          assocPath(
            ['byEdgeUuid', action.data.uuid],
            normalizeSubscribedNetworkGroup(action.data)
          )
        )(state);
      }

      return state;
    }

    default:
      return state;
  }
};

export default subscribedNetworkGroups;
