import {
  GenericErrors,
  TimeTable,
  TimeTableCounts,
  TimeTablePreview,
  TimeTableRow,
  TimeTableRowStore,
  TimeTableSpecialRow,
  TimeTableSpecialStore,
} from '@energybox/react-ui-library/dist/types';
import {
  RawTimeTablePreview,
  TimeTableEvent,
  TimeTableEventUnit,
  TimetableOverrideType,
} from '@energybox/react-ui-library/dist/types/TimeTable';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';
import * as R from 'ramda';

import { Actions } from '../actions/time_tables';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export type EditableFields = {
  title: string;
  rows: TimeTableRowStore[];
  specials: TimeTableSpecialStore[];
  formErrors: GenericErrors;
};

export type EditTimeTable = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const emptyRow = {
  weekdays: [],
  beginTime: '',
  beginType: 'CLOCK',
  endTime: '',
  time: null,
  endType: 'CLOCK',
  overrideType: TimetableOverrideType.AUTO,
};

const newRow = {
  ...emptyRow,
  formErrors: formValidationErrors('timeTableRow', emptyRow),
};

const emptySpecialRow = {
  dateRanges: [],
  rows: [newRow],
  title: '',
};

const newSpecialRow = {
  ...emptySpecialRow,
  formErrors: formValidationErrors('specialTimeTableRow', emptySpecialRow),
};

const emptyTimeTableFields = {
  title: '',
  rows: [newRow],
  specials: [],
};

const newTimeTableFields = {
  ...emptyTimeTableFields,
  formErrors: formValidationErrors('timeTable', emptyTimeTableFields),
};

const newTimeTable = {
  isLoading: false,
  isChanged: false,
  fields: newTimeTableFields,
  formErrorsVisible: false,
  apiError: {},
};

export type TimeTablesById = {
  [id: string]: TimeTable;
};

export type TimeTableIdsByParentId = {
  [id: string]: number[];
};

export type TimeTableCountsByOrgUnitId = {
  [id: number]: TimeTableCounts[];
};

export type TimeTablePreviewByEquipmentId = {
  [equipmentId: string]: TimeTablePreview;
};

export type TimetablePreviewsByEquipmentId = {
  [equipmentId: string]: TimeTablePreview[];
};

export type EditById = {
  [id: string]: EditTimeTable;
};

export type LoadingStatusByAction = {
  [Actions.GET_TIME_TABLES_LOADING]?: boolean;
  [Actions.GET_TIME_TABLE_COUNTS_LOADING]?: boolean;
};

export type IsTimetableActive = {
  [timetableId: string]: {
    [siteId: string]: boolean;
  };
};

export interface TimeTables {
  timeTableIdsByParentId: TimeTableIdsByParentId;
  timeTablesById: TimeTablesById;
  timeTableCountsByOrgUnitId: TimeTableCountsByOrgUnitId;
  showNewModal: boolean;
  isTimePickerOpen: boolean;
  showDeleteModal: boolean;
  primedForDelete: number;
  editingTimeTable: number;
  lastCreatedIdOnSelectTimetable: number;
  editById: EditById;
  apiError: ApiError;
  loadingStatusByAction: LoadingStatusByAction;
  timeTablePreviewByEquipmentId: TimeTablePreviewByEquipmentId;
  timetablePreviewsByEquipmentId: TimetablePreviewsByEquipmentId;
  isTimetableActive: IsTimetableActive;
}

export const normalizeTimeTableFormRow = (rows: TimeTableRow[]) => {
  return rows.map(row => {
    return {
      weekdays: row.weekdays,
      beginTime: row.begin.time || row.begin.delta,
      beginType: row.begin.type,
      endTime: row.end.time || row.end.delta,
      endType: row.end.type,
      overrideType: row.overrideType,
      formErrors: {},
    };
  });
};

export const normalizeTimeTableFormSpecial = (
  specials: TimeTableSpecialRow[]
) => {
  return specials.map(special => {
    return {
      title: special.title,
      dateRanges: special.dateRanges,
      rows: normalizeTimeTableFormRow(special.rows),
      formErrors: {},
    };
  });
};

export const normalizeTimeTablePreview = data => {
  if (!data) return undefined;
  return {
    controlType: data.controlType,
    date: data.date,
    equipment: data.equipment,
    events: data.events,
    localOverride: data.localOverride,
    localOverrideValue: data.localOverrideValue,
    maxTemp: data.maxTemp,
    minTemp: data.minTemp,
    outsideMaxTemp: data.outsideMaxTemp,
    outsideMinTemp: data.outsideMinTemp,
    outsideSetPoint: data.outsideSetPoint,
    schedulerWorkingMode: data.schedulerWorkingMode,
    setPoint: data.setPoint,
    timetableId: data.timetableId,
    timetableTitle: data.timetableTitle,
  };
};

export const timeTablesFromApiResponse = (data: any) => ({
  id: data.id,
  organizationUnitId: data.organizationUnitId,
  title: data.title,
  rows: data.rows,
  specials: data.specials,
});

export const timeTableFormDataFromApiResponse = (data: any) => ({
  title: data.title,
  rows: normalizeTimeTableFormRow(data.rows),
  specials: normalizeTimeTableFormSpecial(data.specials),
  formErrors: {},
});

export const processTimetablePreview = (timezone: string) => (
  d: RawTimeTablePreview
): TimeTablePreview | undefined => {
  if (!d.equipment) {
    return undefined;
  }

  if (d.events === null || d.events === undefined) {
    return {
      setPoint: null,
      ...d,
      events: null,
    } as TimeTablePreview;
  }

  if (d.events && d.events.length === 0) {
    return {
      setPoint: null,
      ...d,
    } as TimeTablePreview;
  }

  let index = 0;

  // The first event must have STATE 1 (event starts)
  while (d.events[index].setAction !== 1) {
    ++index;
  }

  d.events = d.events.slice(index);
  const processedEvents: TimeTableEventUnit[][] = Array.from(
    { length: Math.ceil(d.events.length / 2) },
    (_: TimeTableEventUnit, i: number) => {
      return (d.events || []).slice(i * 2, i * 2 + 2);
    }
  ).map(eventPair => {
    return eventPair.map(eventUnit => {
      const dateString = d.date;
      const utcTime = DateTime.fromISO(`${dateString}T${eventUnit.time}`, {
        zone: timezone,
      }).toMillis();

      return {
        ...eventUnit,
        unixTimeInMs: utcTime,
      };
    });
  });

  return {
    setPoint: null,
    ...d,
    events: processedEvents as TimeTableEvent[],
  } as TimeTablePreview;
};

export const processAndRemoveBuggyTimetablePreviews = (
  t: RawTimeTablePreview[],
  timezone: string
): TimeTablePreview[] => {
  return t
    .map(processTimetablePreview(timezone))
    .filter(e => e !== undefined) as TimeTablePreview[];
};

export const initialState = {
  showNewModal: false,
  isTimePickerOpen: false,
  timeTableIdsByParentId: {},
  timeTablesById: {},
  timeTableCountsByOrgUnitId: {},
  showDeleteModal: false,
  primedForDelete: -1,
  editingTimeTable: -1,
  lastCreatedIdOnSelectTimetable: -1,
  editById: {},
  apiError: {},
  loadingStatusByAction: {},
  timeTablePreviewByEquipmentId: {},
  timetablePreviewsByEquipmentId: {},
  isTimetableActive: {},
};
const timeTables = (state: TimeTables = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_TIME_TABLE_BY_ID_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.GET_TIME_TABLE_BY_ID_SUCCESS: {
      let timeTable = timeTableFormDataFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: timeTable,
        })
      )(state);
    }

    case Actions.GET_TIME_TABLE_COUNTS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_TIME_TABLE_COUNTS_LOADING],
        true,
        state
      );

    case Actions.GET_TIME_TABLE_COUNTS_SUCCESS:
      return R.pipe(
        R.assocPath(['timeTableCountsByOrgUnitId', action.id], action.data),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_TIME_TABLE_COUNTS_LOADING],
          false
        )
      )(state);

    case Actions.GET_TIME_TABLE_COUNTS_ERROR:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_TIME_TABLE_COUNTS_LOADING],
        false,
        state
      );

    case Actions.GET_TIME_TABLES_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_TIME_TABLES_LOADING],
        true,
        state
      );

    case Actions.GET_TIME_TABLES_SUCCESS:
      return R.pipe(
        R.assoc(
          'timeTableIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('timeTableIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ organizationUnitId }) => organizationUnitId,
              action.data
            )
          )
        ),
        R.assoc(
          'timeTablesById',
          R.mergeRight(
            R.view(R.lensProp('timeTablesById'), state),
            mapArrayToObject(mapValues(action.data, timeTablesFromApiResponse))
          )
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_TIME_TABLES_LOADING],
          false
        )
      )(state);

    case Actions.PATCH_TIME_TABLE_LOADING:
      return R.assocPath(
        ['editById', action.timeTableId, 'isLoading'],
        true,
        state
      );

    case Actions.PATCH_TIME_TABLE_SUCCESS: {
      const timeTable = timeTablesFromApiResponse(action.data);
      const oldParent =
        state.timeTablesById[action.timeTableId].organizationUnitId;
      const newParent = timeTable.organizationUnitId;
      const pathToByOldParentIdStore = ['timeTableIdsByParentId', oldParent];
      const pathToNewByParentIdStore = ['timeTableIdsByParentId', newParent];

      const oldParentList = R.path(pathToByOldParentIdStore, state).filter(
        id => id !== action.timeTableId
      );

      const newParentList = [
        ...new Set(
          (R.path(pathToNewByParentIdStore, state) || []).concat([
            parseInt(action.timeTableId),
          ])
        ),
      ];

      return R.pipe(
        R.assocPath(['editById', action.timeTableId, 'isChanged'], false),
        R.assocPath(['editById', action.timeTableId, 'isLoading'], false),
        R.assocPath(['editById', action.timeTableId, 'apiError'], {}),
        R.assocPath(['timeTablesById', action.timeTableId], timeTable),
        R.assocPath(pathToByOldParentIdStore, oldParentList),
        R.assocPath(pathToNewByParentIdStore, newParentList),
        R.assoc('editingTimeTable', -1)
      )(state);
    }

    case Actions.PATCH_TIME_TABLE_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', action.timeTableId, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', action.timeTableId, 'isLoading'], false)
      )(state);

    case Actions.TOGGLE_EDIT_MODAL:
      return R.assoc('editingTimeTable', action.id, state);

    case Actions.TOGGLE_NEW_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newTimeTable),
        R.assoc('showNewModal', action.value)
      )(state);

    case Actions.TOGGLE_DELETE_MODAL:
      return R.pipe(
        R.assoc('showDeleteModal', action.value),
        R.assoc('apiError', {})
      )(state);

    case Actions.ADD_TIME_TABLE_ROW:
      if (action.specialRowIndex === 0 || !!action.specialRowIndex) {
        return R.pipe(
          R.path([
            'editById',
            action.timeTableId,
            'fields',
            'specials',
            action.specialRowIndex,
            'rows',
          ]),
          R.concat(R.__, [newRow]),
          R.assocPath(
            [
              'editById',
              action.timeTableId,
              'fields',
              'specials',
              action.specialRowIndex,
              'rows',
            ],
            R.__,
            state
          )
        )(state);
      }

      return R.pipe(
        R.path(['editById', action.timeTableId, 'fields', 'rows']),
        R.concat(R.__, [newRow]),
        R.assocPath(
          ['editById', action.timeTableId, 'fields', 'rows'],
          R.__,
          state
        )
      )(state);

    case Actions.ADD_TIME_TABLE_SPECIAL_ROW:
      return R.pipe(
        R.path(['editById', action.timeTableId, 'fields', 'specials']),
        R.concat(R.__, [newSpecialRow]),
        R.assocPath(
          ['editById', action.timeTableId, 'fields', 'specials'],
          R.__,
          state
        )
      )(state);

    case Actions.CREATE_TIME_TABLE_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_TIME_TABLE_SUCCESS:
      return R.pipe(
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewModal', false)
      )(state);

    case Actions.SET_LAST_CREATED_ON_SELECT_TIMETABLE:
      return R.assoc(
        'lastCreatedIdOnSelectTimetable',
        action.justCreatedTimetableId,
        state
      );

    case Actions.RESET_LAST_CREATED_ON_SELECT_TIMETABLE:
      return R.assoc('lastCreatedIdOnSelectTimetable', -1, state);

    case Actions.CREATE_TIME_TABLE_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PRIME_FOR_DELETE:
      return R.assoc('primedForDelete', action.id, state);

    case Actions.UNSTAGE_DELETE:
      return R.assoc('primedForDelete', -1, state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.timeTableId, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let fieldErrors;

      if (action.validationType === 'timeTableRow') {
        fieldErrors = R.assocPath(
          [
            'editById',
            action.timeTableId,
            'fields',
            ...action.path,
            action.field,
            'formErrors',
          ],
          formValidationErrors(action.validationType, action.value)
        );
      } else {
        fieldErrors = R.assocPath(
          [
            'editById',
            action.timeTableId,
            'fields',
            ...action.path,
            'formErrors',
          ],
          formValidationErrors(action.validationType, {
            dateRanges: R.path(
              [
                'editById',
                action.timeTableId,
                'fields',
                ...action.path,
                'dateRanges',
              ],
              state
            ),
            title: R.path(
              [
                'editById',
                action.timeTableId,
                'fields',
                ...action.path,
                'title',
              ],
              state
            ),
            [action.field]: action.value,
          })
        );
      }

      return R.pipe(
        R.assocPath(
          [
            'editById',
            action.timeTableId,
            'fields',
            ...action.path,
            action.field,
          ],
          action.value
        ),
        fieldErrors
      )(state);

    case Actions.REMOVE_TIME_TABLE_ROW:
      if (action.specialRowIndex === 0 || !!action.specialRowIndex) {
        return R.dissocPath(
          [
            'editById',
            action.timeTableId,
            'fields',
            'specials',
            action.specialRowIndex,
            'rows',
            action.rowIndex,
          ],
          state
        );
      }

      return R.dissocPath(
        ['editById', action.timeTableId, 'fields', 'rows', action.rowIndex],
        state
      );

    case Actions.REMOVE_SPECIAL_ROW:
      return R.dissocPath(
        ['editById', action.timeTableId, 'fields', 'specials', action.rowIndex],
        state
      );

    case Actions.RESET_TIME_TABLE: {
      return R.assocPath(['editById', 'new'], newTimeTable, state);
    }

    case Actions.DELETE_TIME_TABLE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_TIME_TABLE_SUCCESS: {
      if (!!action.data) {
        const timeTableToDelete = state['timeTablesById'][action.id];
        const organizationUnitId = timeTableToDelete.organizationUnitId || -1;
        const filteredArray = R.filter(
          id => id !== parseInt(action.id),
          state['timeTableIdsByParentId'][organizationUnitId]
        );
        return R.pipe(
          R.assocPath(
            ['timeTableIdsByParentId', organizationUnitId],
            filteredArray
          ),
          R.dissocPath(['timeTablesById', action.id]),
          R.dissocPath(['editById', action.id]),
          R.assoc('showDeleteModal', false),
          R.assoc('primedForDelete', -1)
        )(state);
      } else {
        return state;
      }
    }

    case Actions.DELETE_TIME_TABLE_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DUPLICATE_TIME_TABLE: {
      const timeTableFieldsCopy = {
        ...newTimeTableFields,
        title: action.isCopyToSite
          ? `${action.timeTable.title} (Site)`
          : `${action.timeTable.title} (Copy)`,
        rows: normalizeTimeTableFormRow(action.timeTable.rows),
        specials: normalizeTimeTableFormSpecial(action.timeTable.specials),
      };
      return R.pipe(
        R.assocPath(['editById', 'new'], {
          ...newTimeTable,
          fields: {
            ...timeTableFieldsCopy,
            formErrors: formValidationErrors('timeTable', timeTableFieldsCopy),
          },
        }),
        R.assoc('showNewModal', true)
      )(state);
    }

    case Actions.GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['timeTablePreviewByEquipmentId', action.equipmentId],
          normalizeTimeTablePreview(
            R.pathOr(undefined, ['data', 'timetablePreview', 0], action)
          )
        )
      )(state);

    case Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS: {
      return R.assocPath(
        ['timetablePreviewsByEquipmentId', action.equipmentId],
        processAndRemoveBuggyTimetablePreviews(
          action.data.timetablePreview || [],
          action.timezone
        ),
        state
      );
    }

    case Actions.TOGGLE_TIME_PICKER:
      return R.assoc('isTimePickerOpen', action.value, state);

    case Actions.GET_IS_TIMETABLE_CURRENTLY_ACTIVE_SUCCESS: {
      return R.assocPath(
        ['isTimetableActive', action.timetableId, action.siteId],
        action.data,
        state
      );
    }

    default:
      return state;
  }
};

export default timeTables;
