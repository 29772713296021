import { EnergyboxService } from '../config';

const apiBase = '/api/v1/records';
export enum Actions {
  GET_RECORDS_SUCCESS = '@@records/GET_RECORDS_SUCCESS',
  GET_RECORDS_LOADING = '@@records/GET_RECORDS_LOADING',
  GET_RECORDS_ERROR = '@@records/GET_RECORDS_ERROR',
  RESET_RECORDS = '@@records/RESET_RECORDS',
}

export const getRecords = (resourceId: number | string) => {
  return {
    type: 'API_GET',
    service: EnergyboxService.logging,
    path: `${apiBase}/resource/${resourceId}`,
    loading: {
      type: Actions.GET_RECORDS_LOADING,
      resourceId,
    },
    success: {
      type: Actions.GET_RECORDS_SUCCESS,
      resourceId,
    },
    error: {
      type: Actions.GET_RECORDS_ERROR,
      resourceId,
    },
  };
};

export const resetRecords = () => {
  return {
    type: Actions.RESET_RECORDS,
  };
};
