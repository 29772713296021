import { Site } from '@energybox/react-ui-library/dist/types';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { hasDaResults } from '../../util';
import { Weather } from '../../reducers/weather';
import {
  Loader,
  RunConfigTestWarning,
} from '@energybox/react-ui-library/dist/components';
import {
  classNames,
  createTemperatureString,
} from '@energybox/react-ui-library/dist/utils';
import SiteDateAndTimeInfo from './SiteDateAndTimeInfo';
import styles from './OrgControlsCardHeader.module.css';
import {
  HumiditySensor as HumidityIcon,
  TemperatureSensor as TemperatureIcon,
} from '@energybox/react-ui-library/dist/icons';
import UpdateNetworkGroupsButton from '../../components/HvacSopTable/UpdateNetworkGroupsButton';
import { Routes } from '../../routes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';

type props = {
  site: Site;
  networkGroupIds: number[];
  weatherData: Weather;
  orgId?: number | undefined;
};

const OrgControlsCardHeader: React.FC<props> = ({
  site,
  networkGroupIds,
  weatherData,
  orgId,
}) => {
  const currentUser = useCurrentUser();

  const networkGroupsArray = useSelector(
    ({ networkGroups }: ApplicationState) => {
      return networkGroups?.networkGroupsByOrgId[orgId ?? ''];
    }
  );
  const filteredNetworkGroupsArray = networkGroupsArray?.filter(
    networkGroup => {
      return networkGroupIds.includes(networkGroup.id);
    }
  );

  const resultsExist = hasDaResults(site?.installerTestResults);
  const { weatherBySiteId, isLoading } = weatherData;
  const { temperature, humidity } = weatherBySiteId?.[site?.id] ?? {};

  if (!currentUser) {
    return null;
  }

  const renderTemperatureInfo = () => {
    if (isLoading) {
      return <Loader className={styles.loader} size={12} />;
    }

    return (
      <>
        {temperature ? (
          <div className={styles.alignIconAndText}>
            <span className={styles.sensorIcons}>
              <TemperatureIcon size={16} />
            </span>
            <span className={styles.iconText}>
              {createTemperatureString(temperature, currentUser)}
            </span>
          </div>
        ) : (
          <></>
        )}

        {humidity ? (
          <div className={styles.alignIconAndText}>
            <span className={styles.sensorIcons}>
              <HumidityIcon size={16} />
            </span>
            <span className={styles.iconText}>{+humidity.toFixed(1)}%</span>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderSiteDetails = () => (
    <>
      <div className={styles.siteDetailsContainer}>
        <div className={styles.title}>{site?.title}</div>
        <div className={styles.sensorReadingsContainer}>
          <div className={styles.siteDateAndTimeInfo}>
            <SiteDateAndTimeInfo site={site} className={styles.alignDate} />
          </div>

          <div className={styles.temperatureContainer}>
            {renderTemperatureInfo()}
          </div>
        </div>
      </div>
      <div className={styles.lastConfigUpdateStatus}>
        <UpdateNetworkGroupsButton
          siteId={Number(site?.id)}
          networkGroups={filteredNetworkGroupsArray}
        />
      </div>
    </>
  );

  return (
    <>
      <div className={styles.siteContainer}>
        <div>
          {resultsExist && (
            <RunConfigTestWarning
              configLink={`${Routes.RUN_CONFIGURATION_TEST}/${Number(
                site?.id
              )}`}
            />
          )}
        </div>
        <div
          className={classNames(
            styles.siteDetails,
            resultsExist ? styles.testBannerExist : ''
          )}
        >
          {renderSiteDetails()}
        </div>
      </div>
    </>
  );
};

export default OrgControlsCardHeader;
