import { SingleDatePicker } from '@energybox/react-ui-library/dist/components';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import styles from './UploadCell.module.css';
import {
  uploadSingleSiteUtilityBill,
  downloadUtilityBill,
} from '../../actions/utilityBills';
import { useAppLocale } from '../../hooks/useAppDetails';
import { UploadFile, UploadStatus, BillMetadata } from '../../types/upload';
import { useUpload } from '../../hooks/fileUpload/useUpload';
import FileUploadCell from '../../components/FileUploader/FileUploadCell';
import { SetStateAction, useEffect } from 'react';

type UploadCellProps = {
  upload: UploadFile<BillMetadata>;
  fileIdx: number;
  isUndeletable?: boolean;
  uploadDidChange: (idx: number, newUpload: UploadFile<BillMetadata>) => void;
  siteId?: number;
  remove: (idx: number) => void;
  isUploadFromEdmPage?: boolean;
  setStatusDone?: React.Dispatch<SetStateAction<boolean>>;
};

const UploadCell = ({
  upload: uploadFromTable,
  fileIdx,
  isUndeletable,
  uploadDidChange,
  siteId,
  remove,
  isUploadFromEdmPage,
  setStatusDone,
}: UploadCellProps) => {
  const { pending, ready, uploading, failed, done } = UploadStatus;
  // // pending: either startDate of endDate is not set
  // // ready: both startDate and endDate are set, ready to start upload
  // // uploading: Uploader component is rendered to start upload logic
  // //            All actions are locked when ANY files are uploading
  // // failed: upload failed in the process
  // // success: upload succeeded and url of the uploaded file is set to "View File"

  const {
    retry,
    upload,
    uploadDidFinish,
    status,
    uploadResult,
    viewFile,
  } = useUpload<BillMetadata>({
    uploadFromProp: uploadFromTable,
    fileIdx,
    uploadDidChange,
    downloadFile: downloadUtilityBill,
  });

  const { file, startDate, endDate } = upload;
  const disableDatePicker = [uploading, failed, done].includes(status);
  const dim = [pending, failed].includes(status);
  const locale = useAppLocale();
  useEffect(() => {
    if (status == done && setStatusDone) setStatusDone(true);
    else setStatusDone && setStatusDone(false);
  }, [status, siteId]);

  return (
    <table
      className={classNames(styles.rootcell, dim ? styles.disabled : null)}
    >
      <tbody>
        <tr>
          <th
            className={
              isUploadFromEdmPage
                ? styles.tableHeaderDatePickerEdm
                : styles.tableHeaderDatePicker
            }
            rowSpan={2}
          >
            <div
              className={
                isUploadFromEdmPage
                  ? styles.datePickersContainerInModal
                  : styles.datePickersContinaer
              }
            >
              <SingleDatePicker
                className={styles.datePicker}
                locale={locale}
                label="Start Date"
                value={startDate}
                setDate={newStartDate => {
                  uploadDidChange(fileIdx, {
                    file,
                    startDate: newStartDate,
                    endDate,
                  });
                }}
                disabled={disableDatePicker}
                disableAfter={endDate}
                openedeFromPopup={isUploadFromEdmPage ? true : false}
              />
              <SingleDatePicker
                className={styles.datePicker}
                locale={locale}
                label="End Date"
                value={endDate}
                setDate={newEndDate => {
                  uploadDidChange(fileIdx, {
                    file,
                    startDate,
                    endDate: newEndDate,
                  });
                }}
                disabled={disableDatePicker}
                disableBefore={startDate}
                openedeFromPopup={isUploadFromEdmPage ? true : false}
              />
            </div>
          </th>
          <td className={styles.cellRoot}>
            <FileUploadCell
              upload={upload}
              uploadFile={upload => uploadSingleSiteUtilityBill(upload, siteId)}
              status={status}
              uploadDidFinish={uploadDidFinish}
              retry={retry}
              viewFile={viewFile}
              remove={() => remove(fileIdx)}
              streamDataPath={['utilityBills', 'progressByFileId']}
              isUndeletable={isUndeletable}
              uploadResult={uploadResult}
              isUploadFromEdmPage={isUploadFromEdmPage}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default UploadCell;
