import { TextChip } from '@energybox/react-ui-library/dist/components';
import {
  Scheduler,
  TimeTable,
  TimeTableDateRange,
  TimeTableRow,
} from '@energybox/react-ui-library/dist/types';
import { HvacSopTimeSlot } from '@energybox/react-ui-library/dist/types/Sop';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useIs24HrTimeFormat } from '../../../hooks/useAppDetails';
import { generateDateLabel } from '../../../utils/dates';
import {
  parseDelta,
  reduceTimetablesByDay,
  combineReducedTimetableMaps,
} from '../../../utils/hvacSop';
import styles from './TimetableVisualization.module.css';
import TimetableVisualizationBlock from './TimetableVisualizationBlock';

type props = {
  hvacSchedules?: HvacSopTimeSlot[];
  scheduler?: Scheduler;
  specialIndex?: number;
  lightSensorSettingsSpecialIndex?: number;
  numberOfColumns: number;
  className?: string;
  timeTable?: TimeTable;
};

const TimetableVisualization: React.FC<props> = ({
  hvacSchedules,
  scheduler,
  specialIndex,
  lightSensorSettingsSpecialIndex,
  numberOfColumns,
  className,
  timeTable,
}) => {
  if (!hvacSchedules && !scheduler && !timeTable) return <></>;
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const daysOfTheWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const user24HrTimeFomat = useIs24HrTimeFormat();

  let schedulerTimetable: TimeTable | null;
  let schedulerBeginDelta: number;
  let schedulerEndDelta: number;
  let schedulerTimetableLSS: TimeTable | null = null;
  let schedulerBeginDeltaLSS: number = 0;
  let schedulerEndDeltaLSS: number = 0;
  if (scheduler) {
    if (scheduler?.lightSensorSettings) {
      schedulerTimetableLSS = scheduler.lightSensorSettings?.timetable!;
      schedulerBeginDeltaLSS = parseDelta(
        scheduler.lightSensorSettings?.beginDelta!
      );
      schedulerEndDeltaLSS = parseDelta(
        scheduler.lightSensorSettings?.endDelta!
      );
    }
    schedulerTimetable = scheduler?.timetable;
    schedulerBeginDelta = parseDelta(scheduler?.beginDelta!);
    schedulerEndDelta = parseDelta(scheduler?.endDelta!);
  }

  useEffect(() => {
    setWidth(ref.current?.clientWidth!);
    setHeight(ref.current?.clientHeight!);
  });

  // Listen to window resize events to force re-renders of the visualization
  useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current?.clientWidth!);
      setHeight(ref.current?.clientHeight!);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let timetableTitles: string[] = [];
  let occupiedTimetablesCount = 1;
  if (hvacSchedules) {
    hvacSchedules.forEach(hvacSchedule => {
      if (hvacSchedule.hvacScheduleType == 'OCCUPIED') {
        timetableTitles.push('Occupied ' + occupiedTimetablesCount);
        occupiedTimetablesCount++;
      } else {
        timetableTitles.push('Unoccupied');
      }
    });
  } else if (scheduler) {
    if (scheduler.timetable) {
      timetableTitles.push(scheduler.timetable.title);
    } else {
      timetableTitles.push('');
    }
    if (schedulerTimetableLSS) {
      timetableTitles.push(
        schedulerTimetableLSS!.title ? schedulerTimetableLSS!.title : ''
      );
    }
  }

  const renderVerticalLabels = () => {
    let startOfDay = DateTime.now().startOf('day');
    return (
      <>
        {[...Array(12)].map((_, i) => {
          const columnTime = startOfDay.plus({ hours: 2 * i });
          return (
            <div className={styles.columnLabel}>
              {user24HrTimeFomat
                ? columnTime.toFormat('HH')
                : columnTime.toFormat('ha').toLocaleLowerCase()}
            </div>
          );
        })}
      </>
    );
  };

  const renderVerticalLines = () => {
    return (
      <>
        {[...Array(24)].map((_, i) => {
          return (i + 1) % 2 == 0 ? (
            <div className={styles.verticalLineWithDash}></div>
          ) : (
            <div className={styles.verticalLineNoDash}></div>
          );
        })}
      </>
    );
  };

  const renderHvacSchedule = (
    hvacSchedule: HvacSopTimeSlot,
    day: string,
    index: number
  ) => {
    const timetableRows = hvacSchedule.timetable.rows;
    const reducedRows = reduceTimetablesByDay(timetableRows);
    const dateTimePairsInDay = reducedRows.get(day);

    return dateTimePairsInDay?.map(dateTimePair => {
      return (
        <TimetableVisualizationBlock
          canvasWidth={width}
          canvasHeight={height}
          datetimePair={dateTimePair[0]}
          dayOfTheWeek={day}
          hvacSchedule={hvacSchedule}
          timetableTitle={timetableTitles[index]}
          user24HrTimeFomat={user24HrTimeFomat}
          overflowTimestamp={dateTimePair[1]}
          isLightSensor={false}
        />
      );
    });
  };

  const renderSchedulerDefault = (day: string) => {
    if (!scheduler) return;
    const timetableRows = schedulerTimetable ? schedulerTimetable.rows : [];
    let timetableRowsLSS = schedulerTimetableLSS
      ? schedulerTimetableLSS.rows
      : [];
    // only need to calculate offsets for scheduler default schedules
    const reducedRows = reduceTimetablesByDay(
      timetableRows,
      schedulerBeginDelta,
      schedulerEndDelta
    );
    const reducedRowsLSS = reduceTimetablesByDay(
      timetableRowsLSS,
      schedulerBeginDeltaLSS,
      schedulerEndDeltaLSS
    );
    // Peforms combine operations on reducedRowsLSS in place
    combineReducedTimetableMaps(reducedRows, reducedRowsLSS);

    // Need a copy of reducedRowsLSS timetable ranges for tooltips
    const reducedRowsLSSCopy = reduceTimetablesByDay(
      timetableRowsLSS,
      schedulerBeginDeltaLSS,
      schedulerEndDeltaLSS
    );
    const dateTimePairsInDay = reducedRows.get(day);
    const dateTimePairsInDayLSS = reducedRowsLSS.get(day);

    const defaultBlocks = dateTimePairsInDay?.map(dateTimePair => {
      const timetableTitle = timetableTitles[0];
      return (
        <TimetableVisualizationBlock
          canvasWidth={width}
          canvasHeight={height}
          datetimePair={dateTimePair[0]}
          dayOfTheWeek={day}
          timetableTitle={timetableTitle}
          user24HrTimeFomat={user24HrTimeFomat}
          overflowTimestamp={dateTimePair[1]}
          isLightSensor={false}
        />
      );
    });
    const lightSensorSettingsBlocks = dateTimePairsInDayLSS?.map(
      (dateTimePair, index) => {
        // calculate tooltip time range
        const timetableTitle = timetableTitles[1];
        const originalReducedRows = reducedRowsLSSCopy.get(day);
        const formattedBeginTimestamp = user24HrTimeFomat
          ? originalReducedRows![index][0][0].toFormat('HH:mm')
          : originalReducedRows![index][0][0].toFormat('t').toLowerCase();
        const formattedEndTimestamp = user24HrTimeFomat
          ? originalReducedRows![index][0][1].toFormat('HH:mm')
          : originalReducedRows![index][0][1].toFormat('t').toLowerCase();
        const tooltipOverride =
          formattedBeginTimestamp + ' - ' + formattedEndTimestamp;
        return (
          <TimetableVisualizationBlock
            canvasWidth={width}
            canvasHeight={height}
            datetimePair={dateTimePair[0]}
            dayOfTheWeek={day}
            timetableTitle={timetableTitle}
            user24HrTimeFomat={user24HrTimeFomat}
            overflowTimestamp={dateTimePair[1]}
            isLightSensor={true}
            tooltipOverride={tooltipOverride}
          />
        );
      }
    );
    if (lightSensorSettingsBlocks) {
      return lightSensorSettingsBlocks?.concat(defaultBlocks!);
    }
    return defaultBlocks;
  };

  const renderSchedulerSpecials = (day: string) => {
    if (!scheduler || !schedulerTimetable) return;
    let timetableRows: TimeTableRow[];
    let titleIndex: number;
    if (specialIndex !== undefined) {
      timetableRows = schedulerTimetable.specials[specialIndex].rows;
      titleIndex = 0;
    } else if (lightSensorSettingsSpecialIndex !== undefined) {
      if (!schedulerTimetableLSS) return;
      timetableRows =
        schedulerTimetableLSS?.specials[lightSensorSettingsSpecialIndex].rows;
      titleIndex = 1;
    } else {
      return;
    }
    const reducedRows = reduceTimetablesByDay(timetableRows);
    const dateTimePairsInDay = reducedRows.get(day);

    return dateTimePairsInDay?.map(dateTimePair => {
      const timetableTitle = timetableTitles[titleIndex];
      return (
        <TimetableVisualizationBlock
          canvasWidth={width}
          canvasHeight={height}
          datetimePair={dateTimePair[0]}
          dayOfTheWeek={day}
          timetableTitle={timetableTitle}
          user24HrTimeFomat={user24HrTimeFomat}
          overflowTimestamp={dateTimePair[1]}
          isLightSensor={lightSensorSettingsSpecialIndex !== undefined}
        />
      );
    });
  };

  const renderTimetable = (day: string) => {
    if (!timeTable) return;
    let timetableSpecialRows: TimeTableRow[] | undefined;
    if (specialIndex !== undefined) {
      timetableSpecialRows = timeTable.specials?.[specialIndex].rows;
    }
    const timetableDefaultRows = timeTable ? timeTable.rows : [];
    const timeTableRows =
      specialIndex !== undefined ? timetableSpecialRows : timetableDefaultRows;
    const reducedRows = reduceTimetablesByDay(timeTableRows!);

    const dateTimePairsInDay = reducedRows.get(day);

    const defaultBlocks = dateTimePairsInDay?.map(dateTimePair => {
      const timetableTitle = timetableTitles[0];
      return (
        <TimetableVisualizationBlock
          canvasWidth={width}
          canvasHeight={height}
          datetimePair={dateTimePair[0]}
          dayOfTheWeek={day}
          timetableTitle={timetableTitle}
          user24HrTimeFomat={user24HrTimeFomat}
          overflowTimestamp={dateTimePair[1]}
          isLightSensor={false}
        />
      );
    });
    return defaultBlocks;
  };

  const renderScheduler = (day: string) => {
    if (
      specialIndex !== undefined ||
      lightSensorSettingsSpecialIndex !== undefined
    ) {
      return renderSchedulerSpecials(day);
    } else {
      return renderSchedulerDefault(day);
    }
  };

  const renderDayRows = (day: string) => {
    return (
      <div className={styles.dayRow}>
        <div className={styles.dayRowLabel}>{day}</div>
        {hvacSchedules &&
          hvacSchedules.map((hvacSchedule, index) => {
            return renderHvacSchedule(hvacSchedule, day, index);
          })}
        {scheduler && renderScheduler(day)}
        {timeTable && renderTimetable(day)}
      </div>
    );
  };

  const generateSpecialsDateRangeChip = (dateRange: TimeTableDateRange) => {
    const label = `${generateDateLabel(dateRange.begin)} ${
      dateRange.end ? ' - ' + generateDateLabel(dateRange.end) : ''
    }`;
    return (
      <span className={styles.specialRowDateChip}>
        <TextChip itemId="" label={label} variant="light" />
      </span>
    );
  };

  const generateTitle = () => {
    if (hvacSchedules) return <></>;
    if (specialIndex !== undefined) {
      return (
        <div className={styles.visualizationTitle}>
          {schedulerTimetable?.specials[specialIndex].title}
          {schedulerTimetable?.specials[specialIndex].dateRanges.map(
            dateRange => {
              return generateSpecialsDateRangeChip(dateRange);
            }
          )}
          {timeTable?.specials[specialIndex].title}
          {timeTable?.specials[specialIndex].dateRanges.map(dateRange => {
            return generateSpecialsDateRangeChip(dateRange);
          })}
        </div>
      );
    } else if (lightSensorSettingsSpecialIndex !== undefined) {
      return (
        <div className={styles.visualizationTitle}>
          {
            schedulerTimetableLSS?.specials[lightSensorSettingsSpecialIndex]
              .title
          }
          {schedulerTimetableLSS?.specials[
            lightSensorSettingsSpecialIndex
          ].dateRanges.map(dateRange => {
            return generateSpecialsDateRangeChip(dateRange);
          })}
        </div>
      );
    }
    if (scheduler || timeTable)
      return <div className={styles.visualizationTitle}>Default</div>;
  };

  return (
    <td
      id={styles.timetableVisualizationContainer}
      colSpan={numberOfColumns}
      className={className}
    >
      {generateTitle()}
      <div id={styles.timetableVisualizationGridHorizontal} ref={ref}>
        <div id={styles.timetableVisualizationGridVertical}>
          {renderVerticalLabels()}
        </div>
        <div id={styles.timetableVisualizationGridVertical}>
          {renderVerticalLines()}
        </div>
        <>
          {daysOfTheWeek.map(day => {
            return renderDayRows(day);
          })}
        </>
      </div>
    </td>
  );
};

export default TimetableVisualization;
