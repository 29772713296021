import Tooltip from '@energybox/react-ui-library/dist/components/Tooltip';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import styles from './HvacSopSchedulesTable.module.css';

type Props = {
  hasError?: boolean;
  arrowDirection?:
    | 'left'
    | 'top'
    | 'topRight'
    | 'bottom'
    | 'bottomRight'
    | 'right'
    | 'closeBottom';
};

const HvacEdmSchedulesTooltip: React.FC<Props> = ({
  hasError,
  arrowDirection,
}) => {
  return (
    <Tooltip
      underline={false}
      disableAutoAdjust={isDefined(arrowDirection) ? true : false}
      arrowDirection={arrowDirection || 'left'}
      extraClassNames={[styles.tooltip]}
      simpleContent={{
        title: 'Energy Demand Management',
        description:
          'During this period the system will override HVAC setpoints to reduce demand charges. Local adjustment will be disabled.',
      }}
    >
      <IoIosInformationCircleOutline
        color={hasError ? 'var(--pink-base)' : 'black'}
        size={20}
      />
    </Tooltip>
  );
};

export default HvacEdmSchedulesTooltip;
