enum Actions {
  GET_FILES_BY_SITE_ID_LOADING = '@files/GET_FILES_BY_SITE_ID_LOADING',
  GET_FILES_BY_SITE_ID_SUCCESS = '@files/GET_FILES_BY_SITE_ID_SUCCESS',
  GET_FILES_BY_SITE_ID_ERROR = '@files/GET_FILES_BY_SITE_ID_ERROR',

  DELETE_FILES_BY_ID_LOADING = '@files/DELETE_FILES_BY_ID_LOADING',
  DELETE_FILES_BY_ID_SUCCESS = '@files/DELETE_FILES_BY_ID_SUCCESS',
  DELETE_FILES_BY_ID_ERROR = '@files/DELETE_FILES_BY_ID_ERROR',

  MY_DOWNLOADS_SUCCESS = '@@app/MY_DOWNLOADS_SUCCESS',
  MY_DOWNLOADS_LOADING = '@@app/MY_DOWNLOADS_LOADING',
  MY_DOWNLOADS_ERROR = '@@app/MY_DOWNLOADS_ERROR',

  DELETE_FILES_SUCCESS = '@@app/DELETE_FILES_SUCCESS',
  DELETE_FILES_LOADING = '@@app/DELETE_FILES_LOADING',
  DELETE_FILES_ERROR = '@@app/DELETE_FILES_ERROR',

  TOGGLE_DELETE_STATE = '@@app/TOGGLE_DELETE_STATE',
  INITIATE_DOWNLOAD_FILE = '@@app/INITIATE_DOWNLOAD_FILE',

  DOWNLOAD_FILE_LOADING = '@app/DOWNLOAD_FILE_LOADING',
  DOWNLOAD_FILE_SUCCESS = '@app/DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_ERROR = '@app/DOWNLOAD_FILE_ERROR',
  DOWNLOAD_FILE_DONE = '@app/DOWNLOAD_FILE_DONE',
}

export default Actions;
