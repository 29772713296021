import {
  Button,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  createControlBoard,
  displayControlBoardFormErrors,
  updateControlBoardFields,
  hideControlboardModal,
} from '../../actions/control_boards';
import EditControlBoardForm from '../../components/EditControlBoardForm';
import { ApplicationState } from '../../reducers';
import { CreateNewText } from '../../types/global';

type Props = {
  lockSiteId?: number;
  networkGroupId?: number;
  uuid: string;
};

const NewControlboardModal: React.FC<Props> = ({
  lockSiteId,
  networkGroupId,
  uuid,
}) => {
  const dispatch = useDispatch();
  const controlBoard = useSelector(({ controlBoards }: ApplicationState) => {
    return controlBoards.editControlBoardById['new'];
  });
  const showControlboardModal = useSelector(
    ({ controlBoards }: ApplicationState) => {
      return controlBoards.showNewControlboardModal;
    }
  );

  const onClose = () => {
    dispatch(hideControlboardModal());
  };

  const onControlBoardChange = (field: string, value: string) => {
    dispatch(updateControlBoardFields('new', field, value));
  };

  const onDeviceCreate = () => {
    if (hasKeys(controlBoard.formErrors)) {
      dispatch(displayControlBoardFormErrors('new'));
    } else {
      dispatch(createControlBoard(lockSiteId, networkGroupId));
      onClose();
    }
  };

  useEffect(() => {
    if (controlBoard && uuid !== '0') {
      onControlBoardChange('uuid', uuid);
      onControlBoardChange('networkGroupId', `${networkGroupId}`);
    }
  }, [uuid]);

  const isLoading = controlBoard.isLoading;

  const actions = () => (
    <div>
      <div>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={onDeviceCreate}>
          {isLoading ? <Loader size={12} /> : 'Add'}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {showControlboardModal && (
        <Modal onClose={onClose} actions={actions()} disableEscapeClose={true}>
          <ModalTitle>{CreateNewText.CONTROL_BOARD}</ModalTitle>
          <ModalContent>
            <EditControlBoardForm
              {...controlBoard.fields}
              isNew
              lockSiteId={lockSiteId}
              networkGroupId={networkGroupId!}
              uuid={uuid}
              onChange={onControlBoardChange}
              formErrors={controlBoard.formErrors}
              formErrorsVisible={controlBoard.formErrorsVisible}
              isSiteControllerCard={true}
            />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default NewControlboardModal;
