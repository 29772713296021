import React from 'react';
import { Routes } from '../../routes';
import IndexManagementPageLayout from '../IndexManagementPageLayout';
import TabLink from '../TabLink';

interface Props {
  actions?: React.ReactNode;
  showInstallers?: boolean;
}

const UsersManagementLayout: React.FunctionComponent<Props> = ({
  actions,
  showInstallers,
  children,
}) => (
  <IndexManagementPageLayout
    actions={actions}
    tabs={
      showInstallers ? (
        <>
          <TabLink to={Routes.INSTALLERS}>Installers</TabLink>
          <TabLink to={Routes.INSTALLER_GROUPS}>Installer Groups</TabLink>
        </>
      ) : (
        <>
          <TabLink to={Routes.USERS}>Users</TabLink>
          <TabLink to={Routes.USER_GROUPS}>User Groups</TabLink>
        </>
      )
    }
    content={children}
  />
);

export default UsersManagementLayout;
