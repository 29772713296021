import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlsType,
  OutsideOfTimeTable,
  TemperatureControl,
  TimeTable,
  ControlTypesToDisplayTextsMapping,
} from '@energybox/react-ui-library/dist/types';
import {
  capitalizeFirstLetterOnly,
  hasKeys,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { hideEditControlModal } from '../../../actions/controls';
import {
  Actions as SchedulerActions,
  displayFormErrors as displaySchedulerFormErrors,
  patchScheduler,
  PostSchedulerParams,
  reset as resetEditScheduler,
  updateField as updateSchedulerField,
} from '../../../actions/schedulers';
import {
  Actions as TemperatureActions,
  displayFormErrors as displayTemperatureControlErrors,
  patchTemperatureControls,
  reset as resetEditTemperatureControl,
  updateField as updateTemperatureControlField,
} from '../../../actions/temperature_controls';
import { getTimeTables } from '../../../actions/time_tables';
import EditControlsTemperatureForm from '../../../components/EditControlsTemperatureForm';
import EditSchedulerForm from '../../../components/EditSchedulerForm';
import { ApplicationState } from '../../../reducers';
import { EditableFields, EditScheduler } from '../../../reducers/schedulers';
import { EditTemperatureControl } from '../../../reducers/temperature_controls';
import { ApiError, renderAPIerror } from '../../../utils/apiErrorFeedback';
import styles from './EditControlModal.module.css';

interface OwnProps {
  equipmentId: number;
  equipmentTitle: string;
  schedulerId?: string;
  spaceId?: number;
}

interface Props extends OwnProps {
  siteId?: number;
  orgId?: number;
  timeTables: TimeTable[];
  temperatureApiError: ApiError;
  schedulerApiError: ApiError;
  hideEditControlModal: () => void;
  resetEditTemperatureControl: (id: string | number) => void;
  resetEditScheduler: (id: string | number) => void;
  onChangeScheduler: (
    schedulerId: string,
    field: string | string[],
    value: any
  ) => void;
  onChangeTemperatureControls: (
    temperatureControlId: number,
    field: string,
    value: any
  ) => void;
  getTimeTables: (orgId: string) => void;
  patchScheduler: (schedulerId: string, params: PostSchedulerParams) => void;
  patchTemperatureControls: (tempControls: TemperatureControl) => void;
  displaySchedulerFormErrors: (schedulerId: string) => void;
  displayTemperatureControlErrors: (temperatureControlId: string) => void;
  schedulerControls?: EditScheduler;
  tempControlId?: number;
  tempControls?: EditTemperatureControl;
  typeOfControl?: ControlsType;
  timezone?: string;
}

//TO NOTE: this component is for scheduler and temperature controls
//edit hvac controls is in a separate component (EditHvacControlsModal)
const EditControlModal = ({
  siteId,
  orgId,
  schedulerId: schedulerIdProp,
  equipmentId,
  equipmentTitle,
  spaceId,
  timeTables,
  hideEditControlModal,
  resetEditTemperatureControl,
  resetEditScheduler,
  onChangeScheduler,
  onChangeTemperatureControls,
  getTimeTables,
  patchScheduler,
  patchTemperatureControls,
  displaySchedulerFormErrors,
  displayTemperatureControlErrors,
  schedulerControls,
  tempControlId,
  tempControls,
  typeOfControl,
  schedulerApiError,
  temperatureApiError,
  timezone,
}: Props) => {
  useEffect(() => {
    if (isDefined(orgId)) {
      getTimeTables(orgId.toString());
    }
  }, [getTimeTables, orgId]);

  const [numberOfTimesClickedOnSave, setNumberOfTimesClickedOnSave] = useState(
    0
  );

  const isScheduleControls = typeOfControl === ControlsType.SCHEDULER;
  const isTempControls =
    typeOfControl === ControlsType.HEATING ||
    typeOfControl === ControlsType.COOLING;

  let controlObject:
    | EditTemperatureControl
    | EditScheduler
    | undefined = undefined;

  let modalTitle: string = '';
  if (tempControls && tempControlId && isTempControls) {
    controlObject = tempControls;
    modalTitle = ControlTypesToDisplayTextsMapping[tempControls.fields.type];
  } else {
    controlObject = schedulerControls;
    modalTitle = ControlTypesToDisplayTextsMapping[ControlsType.SCHEDULER];
  }
  modalTitle = capitalizeFirstLetterOnly(modalTitle);

  useEffect(() => {
    if (tempControls && tempControlId && isTempControls) {
      onChangeTemperatureControls(
        tempControlId,
        'beginDelta',
        tempControls.fields.beginDelta
          ? parseInt(tempControls.fields.beginDelta)
          : 0
      );
      onChangeTemperatureControls(
        tempControlId,
        'endDelta',
        tempControls.fields.endDelta
          ? parseInt(tempControls.fields.endDelta)
          : 0
      );
      onChangeTemperatureControls(
        tempControlId,
        'outsideTimeTable',
        tempControls.fields.outsideSetPoint === null
          ? OutsideOfTimeTable.OFF
          : OutsideOfTimeTable.ALTERNATIVE_SET_POINTS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // We want to only run this on mount

  // This should never happen, but I don't know how to type it better. If the
  // user hit edit they had to have hit edit on a control that existed in the
  // interface, but not sure how to type it such that it recognizes
  // schedulerControls and tempControls might be undefined but not at the same
  // time
  if (controlObject === undefined) {
    return <></>;
  }

  const { fields, formErrors, formErrorsVisible, isChanged } = controlObject;

  const onSave = () => {
    if (isScheduleControls && isDefined(schedulerIdProp)) {
      const {
        beginDelta,
        endDelta,
        timetableId,
        workingMode,
        lightSensorSettings: {
          beginDelta: lightBeginDelta,
          endDelta: lightEndDelta,
          timetableId: lightTimetableId,
          controlBoardId,
          threshold,
          hysteresis,
          actionInterval,
        },
      } = fields as EditableFields;

      const selectedScheduleTimeTable = timeTables.find(
        (t: TimeTable) => t.id === timetableId
      );
      const selectedLightTimeTable = timeTables.find(
        (t: TimeTable) => t.id === lightTimetableId
      );

      const hasAtLeastOneTimetableBeenSelected =
        isDefined(selectedScheduleTimeTable) ||
        isDefined(selectedLightTimeTable);

      const isNoScheduleTimetableOptionSelected =
        timetableId === 'null' || timetableId === null;

      const hasInvalidScheduleTimetable =
        (!isDefined(selectedScheduleTimeTable) &&
          !isNoScheduleTimetableOptionSelected) ||
        !hasAtLeastOneTimetableBeenSelected;

      const isControlBoardSelected = controlBoardId !== -1;

      const hasInvalidLightTimetable =
        !hasAtLeastOneTimetableBeenSelected ||
        (isControlBoardSelected && !isDefined(selectedLightTimeTable));

      if (isDefined(controlBoardId) && controlBoardId > 0) {
        if (
          hasKeys(formErrors) ||
          hasInvalidScheduleTimetable ||
          hasInvalidLightTimetable
        ) {
          displaySchedulerFormErrors(schedulerIdProp);
        } else {
          patchScheduler(schedulerIdProp, {
            workingMode,
            beginDelta: parseInt(beginDelta),
            endDelta: parseInt(endDelta),
            timetableId,
            equipmentId,
            lightSensorSettings: {
              beginDelta: parseInt(lightBeginDelta),
              endDelta: parseInt(lightEndDelta),
              timetableId:
                isDefined(lightTimetableId) && lightTimetableId > 0
                  ? lightTimetableId
                  : null,
              controlBoardId,
              threshold,
              hysteresis,
              actionInterval,
            },
          });
        }
      } else {
        if (
          Object.keys(formErrors).some(
            key => !key.includes('lightSensorSettings')
          ) ||
          hasInvalidScheduleTimetable
        ) {
          displaySchedulerFormErrors(schedulerIdProp);
        } else {
          patchScheduler(schedulerIdProp, {
            workingMode,
            beginDelta: parseInt(beginDelta),
            endDelta: parseInt(endDelta),
            timetableId,
            equipmentId,
            lightSensorSettings: null,
          });
        }
      }
    } else if (isTempControls && isDefined(tempControlId)) {
      if (hasKeys(formErrors)) {
        displayTemperatureControlErrors(`${tempControlId}`);
      } else {
        patchTemperatureControls({
          ...(fields as TemperatureControl),
          id: tempControlId,
        });
      }
    }

    setNumberOfTimesClickedOnSave((prevNum: number) => {
      return prevNum + 1;
    });
  };

  const onClose = () => {
    if (isTempControls && tempControlId) {
      resetEditTemperatureControl(tempControlId);
    }

    if (isScheduleControls && schedulerIdProp) {
      resetEditScheduler(schedulerIdProp);
    }

    hideEditControlModal();
  };

  const actions = (
    <div>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={onSave} disabled={!isChanged}>
        Update
      </Button>
    </div>
  );

  return (
    <Modal onClose={onClose} actions={actions} disableEscapeClose>
      <div className={styles.modal}>
        <ModalTitle>
          <div>{`Edit ${modalTitle}`}</div>
          <div
            style={{ color: 'var(--ambient-basePlus25)', fontSize: '0.875rem' }}
          >
            {equipmentTitle}
          </div>
        </ModalTitle>
        <ModalContent>
          {isTempControls && tempControlId && siteId && (
            <EditControlsTemperatureForm
              siteId={siteId}
              spaceId={spaceId}
              equipmentId={equipmentId}
              fields={fields as TemperatureControl}
              onChange={(field: string, value: any) =>
                onChangeTemperatureControls(tempControlId, field, value)
              }
              formErrors={formErrors}
              formErrorsVisible={formErrorsVisible}
              numberOfTimesClickedOnSave={numberOfTimesClickedOnSave}
            />
          )}
          {isScheduleControls && schedulerIdProp && siteId && (
            <EditSchedulerForm
              timezone={timezone}
              timeTables={timeTables}
              siteId={siteId}
              equipmentId={equipmentId}
              onChange={(field: string | string[], value: any) =>
                onChangeScheduler(schedulerIdProp, field, value)
              }
              fields={fields as EditableFields}
              formErrors={formErrors}
              formErrorsVisible={formErrorsVisible}
              numberOfTimesClickedOnSave={numberOfTimesClickedOnSave}
            />
          )}
        </ModalContent>
        {Object.values(schedulerApiError).length > 0 &&
          isScheduleControls &&
          renderAPIerror(
            schedulerApiError,
            SchedulerActions.PATCH_SCHEDULER_ERROR
          )}
        {Object.values(temperatureApiError).length > 0 &&
          isTempControls &&
          renderAPIerror(
            temperatureApiError,
            TemperatureActions.PATCH_TEMPERATURE_CONTROLS_ERROR
          )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (
  {
    timeTables,
    sites,
    schedulers,
    controls,
    temperatureControls,
    app,
  }: ApplicationState,
  { equipmentId, schedulerId }: OwnProps
) => {
  const tempControlId = temperatureControls.temperatureControlsByEquipmentId[
    equipmentId
  ]
    ? temperatureControls.temperatureControlsByEquipmentId[equipmentId].id
    : undefined;
  const tempControls = tempControlId
    ? temperatureControls.editById[tempControlId]
    : undefined;
  const schedulerControls = schedulerId
    ? schedulers.editById[schedulerId]
    : undefined;

  const siteId: number | undefined = sites.resourceIdToSiteId[equipmentId];
  const orgId: number | undefined = app.currentOrganizationId;

  const siteTimeTableIds: number[] = pathOr(
    [],
    [siteId],
    timeTables.timeTableIdsByParentId
  );

  const orgTimeTableIds: number[] = pathOr(
    [],
    [orgId],
    timeTables.timeTableIdsByParentId
  );

  return {
    siteId,
    orgId,
    timezone: sites.sitesById[siteId]?.timeZone,
    timeTables: [...siteTimeTableIds, ...orgTimeTableIds].map(
      timetableId => timeTables.timeTablesById[timetableId]
    ),
    typeOfControl: controls.typeOfControl,
    schedulerControls,
    tempControlId,
    tempControls,
    schedulerApiError: pathOr(
      {},
      [schedulerId, 'apiError'],
      schedulers.editById
    ),
    temperatureApiError: temperatureControls.apiError,
  };
};

const mapDispatchToProps = (dispatch: any, { equipmentId }: OwnProps) => ({
  hideEditControlModal: () => dispatch(hideEditControlModal()),
  resetEditTemperatureControl: (id: string | number) =>
    dispatch(resetEditTemperatureControl(id, equipmentId)),
  resetEditScheduler: (id: string | number) =>
    dispatch(resetEditScheduler(id, equipmentId)),
  getTimeTables: (orgId: string) => dispatch(getTimeTables(orgId)),
  patchScheduler: (schedulerId: string, params: PostSchedulerParams) =>
    dispatch(patchScheduler(schedulerId, params)),
  onChangeScheduler: (
    schedulerId: string,
    field: string | string[],
    value: string
  ) => dispatch(updateSchedulerField(schedulerId, field, value)),
  onChangeTemperatureControls: (
    temperatureControlId: number,
    field: string,
    value: string
  ) =>
    dispatch(updateTemperatureControlField(temperatureControlId, field, value)),
  displaySchedulerFormErrors: (schedulerId: string) =>
    dispatch(displaySchedulerFormErrors(schedulerId)),
  displayTemperatureControlErrors: (temperatureControlId: string) =>
    dispatch(displayTemperatureControlErrors(temperatureControlId)),
  patchTemperatureControls: (tempControls: TemperatureControl) =>
    dispatch(patchTemperatureControls(tempControls)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditControlModal);
