import { Button } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { Step, StepType } from '../../types/sentinelWizard';
import styles from './SentinelWizardNavigation.module.css';

type Props = {
  setStep: (stepType: StepType) => void;
  stepsCompleted: { [key: string]: boolean };
  loading?: boolean;
  tipComponent?: any;
  handleSubmit: any;
  handleCancel: any;
  handleFinishLater: () => void;
  currentStep: StepType;
  prevStep: Step;
  nextStep: Step;
  nextAction: any;
  prevAction: any;
};

const SentinelWizardNavigation = ({
  currentStep,
  stepsCompleted,
  tipComponent,
  handleCancel,
  handleFinishLater,
  prevStep,
  nextStep,
  nextAction,
  prevAction,
}: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.tip}>{tipComponent}</div>
      <div className={styles.controls}>
        {prevStep ? (
          <Button fullWidth onClick={() => prevAction()}>
            Previous
          </Button>
        ) : (
          <span />
        )}

        <Button
          fullWidth
          onClick={() => nextAction()}
          disabled={
            currentStep === 'handling' ? false : !stepsCompleted[currentStep]
          }
        >
          {nextStep ? `Next` : `Finish`}
        </Button>
      </div>

      <div className={styles.finishLaterButtonContainer}>
        <Button onClick={handleCancel} variant="text">
          Cancel
        </Button>
        <Button onClick={handleFinishLater} variant="text">
          Finish later
        </Button>
      </div>
    </div>
  );
};

export default SentinelWizardNavigation;
