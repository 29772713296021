import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  DeviceType,
  SiteController,
  SiteControllerDisplayText,
} from '@energybox/react-ui-library/dist/types/Device';
import { useState } from 'react';
import { useGetSite } from '../../hooks/useSites';

export enum CountryCode {
  US = 'US',
  MX = 'MX',
  CA = 'CA',
}
interface Props {
  value?: DeviceType | SiteController;
  onSelect: (value: SiteController) => void;
  error?: boolean;
  customErrorText?: string;
  testResultsExist?: boolean;
  siteId: number;
  isNew: boolean;
}

const SelectSiteController: React.FC<Props> = ({
  value,
  onSelect,
  error,
  customErrorText,
  testResultsExist,
  siteId,
  isNew,
}) => {
  const [model, setModel] = useState('None');
  const site = useGetSite(siteId);
  const isSiteLocatedInNorthAmerica = () => {
    const location = site?.country;
    if (
      location === CountryCode.US ||
      location === CountryCode.MX ||
      location === CountryCode.CA
    ) {
      return true;
    }
    return false;
  };
  const isSiteSelected = () => {
    if (siteId !== -1) {
      return true;
    }
    return false;
  };
  const selectModel = model => {
    onSelect(model as SiteController);
    setModel(model);
  };
  const siteControllerEUModel = SiteController.ENERGYBOX_CB_EU_6;
  const siteControllerModels = Object.keys(SiteController)?.filter(model => {
    if (isSiteLocatedInNorthAmerica()) {
      return model !== siteControllerEUModel;
    } else {
      return model === siteControllerEUModel;
    }
  });

  return (
    <Select
      title={
        isNew
          ? SiteControllerDisplayText[model]
          : SiteControllerDisplayText[value!]
      }
      disabled={!isSiteSelected() || testResultsExist}
      error={error}
      customErrorText={customErrorText}
    >
      {siteControllerModels?.map(model => (
        <SelectItem
          key={model}
          isSelected={model === value}
          onSelect={() => selectModel(model)}
        >
          {SiteControllerDisplayText[model]}
        </SelectItem>
      ))}
    </Select>
  );
};
export default SelectSiteController;
