import { Equipment, Site, Sop } from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import NewOrEditTaskSopModal from '../Sop/NewOrEditTaskSopModal';
import {
  Button,
  CardList,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import {
  getSopByOrganizationalUnitId,
  destroy as deleteSop,
} from '../../actions/sops';
import styles from './ShowSitePageTaskSops.module.css';
import { hideNewModal, showNewModal } from '../../actions/taskSop';
import { FixedContentWrapper } from '../../components/Page';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { useUrlState } from '../../hooks/utils';
import { getEquipments } from '../../actions/equipment';
import { useCurrentUser } from '../../hooks/useAppDetails';
import {
  getCommonPageHeader,
  getDisplayRowData,
  getFormData,
  getHeader,
} from '../Sop/ShowTaskSopHelper';

interface Props {
  siteId: string;
  site: Site;
}

const ShowSitePageTaskSops: React.FC<Props> = ({ siteId, site }) => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();
  const user = useCurrentUser();
  const [query, setQuery] = useUrlState<string>('query', '');
  const [idToEdit, setIdToEdit] = useState<null | number>(null);
  const [idToDelete, setIdToDelete] = useState<null | number>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(
    false
  );
  const isModalVisible = useSelector<ApplicationState, boolean>(
    ({ taskSop }) => {
      return taskSop.showNewModal;
    }
  );

  const taskSops = useSelector<ApplicationState, Sop[]>(({ sops }) => {
    return Object.values(sops.sopsById).filter(item => {
      return (
        item?.components[0]?.type === 'TASK' &&
        (item?.organizationUnitId === +siteId ||
          item?.organizationUnitId === orgId)
      );
    });
  });

  const equipmentsByIdWithinSite: Equipment[] | undefined = useSelector<
    ApplicationState,
    Equipment[] | undefined
  >(({ equipment }) => equipment.equipmentPayloadArray);

  const siteEquipmentTypeIds = [
    ...new Set(equipmentsByIdWithinSite?.map(equip => equip.typeId) || []),
  ];

  useEffect(() => {
    dispatch(getEquipments({ siteIds: [+siteId] }, +siteId));
  }, []);

  const filteredTaskSop =
    query && query.length >= 3
      ? taskSops.filter(sop =>
          hasSubstr(`${sop.title}${sop.description}`, query)
        )
      : taskSops;

  const isMobile = ViewportTypes.MOBILE;

  const handleSearchChange = (query: string) => {
    setQuery(query);
  };

  const handleDeleteSop = (sopId: number) => {
    setIdToDelete(sopId);
    setIsDeleteModalVisible(true);
  };

  const handleCloseSopDeletePrompt = () => {
    setIsDeleteModalVisible(false);
  };

  const handleEditSop = (sopId: number) => {
    setIdToEdit(sopId);
    dispatch(showNewModal());
  };

  const getUserAccess = (user, organizationUnitId) => {
    return user.accessResources.reduce((hasAccess, resourceAccess) => {
      return (
        (resourceAccess.resourceId === organizationUnitId &&
          (resourceAccess.role === 'Admin' ||
            resourceAccess.role === 'Manager')) ||
        hasAccess
      );
    }, false);
  };

  const hasSiteAccess = getUserAccess(user, siteId);
  const hasOrgAccess = getUserAccess(user, orgId);

  const displayItems = getDisplayRowData('site', filteredTaskSop, styles, {
    siteLevel: {
      siteEquipmentTypeIds,
      hasSiteAccess,
      hasOrgAccess,
      handleEditSop,
      handleDeleteSop,
    },
  });

  const headerSopText = filteredTaskSop.length > 1 ? 'SOPs' : 'SOP';

  const actions = (
    <>
      <Button variant="text" onClick={handleCloseSopDeletePrompt}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          dispatch(deleteSop(idToDelete || ''));
          setTimeout(() => {
            if (orgId) dispatch(getSopByOrganizationalUnitId(orgId));
            if (siteId) dispatch(getSopByOrganizationalUnitId(siteId));
          }, 50);
          handleCloseSopDeletePrompt();
        }}
      >
        Delete
      </Button>
    </>
  );

  return (
    <>
      {isModalVisible && (
        <NewOrEditTaskSopModal
          isVisible={false}
          formData={getFormData('site', idToEdit, taskSops, orgId)}
          organizationUnitId={siteId}
          onClose={() => {
            setTimeout(() => {
              if (orgId) dispatch(getSopByOrganizationalUnitId(orgId));
              if (siteId) dispatch(getSopByOrganizationalUnitId(siteId));
            }, 50);
            dispatch(hideNewModal());
            setIdToEdit(null);
          }}
          onChange={() => {}}
        />
      )}
      {isDeleteModalVisible && (
        <Modal onClose={handleCloseSopDeletePrompt} actions={actions}>
          <p style={{ textAlign: 'center' }}>
            Are you sure you want to delete this SOP?
          </p>
        </Modal>
      )}
      <FixedContentWrapper>
        {getCommonPageHeader(
          filteredTaskSop,
          headerSopText,
          query,
          handleSearchChange,
          isMobile
        )}
        <CardList
          headerClassName={styles.headerGrid}
          header={getHeader('site')}
          data={displayItems}
        />
      </FixedContentWrapper>
    </>
  );
};

export default ShowSitePageTaskSops;
