import { EnergyPro } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToEnergyProSensorReadings,
  unsubscribeFromEnergyProSensorReadings,
} from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';

const useSubscribeToSiteEnergyPros = (siteEnergyPros: EnergyPro[]) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribeFns: (() => void)[] = [];

    siteEnergyPros.forEach(ePro => {
      const { uuid, vendor, id } = ePro;

      dispatch(subscribeToEnergyProSensorReadings(vendor, uuid, id));

      unsubscribeFns.push(() =>
        dispatch(unsubscribeFromEnergyProSensorReadings(vendor, uuid, id))
      );
    });

    return () => {
      if (unsubscribeFns.length > 0) {
        unsubscribeFns.forEach(unsubscribeFns => unsubscribeFns());
      }
    };
  }, [dispatch, siteEnergyPros]);

  const subscribedSiteEnergyPros = useSelector(
    ({ subscribedEnergyPros }: ApplicationState) => {
      const subscribedSiteEnergyPros = siteEnergyPros
        .map(energyPro => subscribedEnergyPros.byId[energyPro.id])
        .filter(subscribedEnergyPro => isDefined(subscribedEnergyPro));

      return subscribedSiteEnergyPros;
    }
  );

  return subscribedSiteEnergyPros;
};

export default useSubscribeToSiteEnergyPros;
