import {
  Loader,
  Select,
  SelectEmptyItem,
  SelectHeader,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { Organization, Site } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getCurrentOrganization } from '../actions/organizations';
import { getSites } from '../actions/sites';
import { Type } from '../components/SiteSelectItem';
import { ApplicationState } from '../reducers';
import { sortedSites } from '../reducers/sites';

interface OwnProps {
  onSelect: (siteId: number) => void;
  value?: number;
  error?: boolean;
  disabled?: boolean;
}

interface SelectItem {
  id: number;
  type: Type;
  title: string;
  address?: string;
}

interface Props extends OwnProps {
  getSites: typeof getSites;
  getCurrentOrganization: typeof getCurrentOrganization;
  currentOrganization?: Organization;
  sites?: Site[];
}

interface State {
  search: string;
}

class SiteSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { search: '' };
  }

  componentDidMount() {
    const { sites, getSites, getCurrentOrganization } = this.props;
    if (!sites || sites.length === 0) getSites();
    getCurrentOrganization();
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  render() {
    const {
      currentOrganization,
      sites,
      value,
      onSelect,
      disabled,
      error,
    } = this.props;

    if (!currentOrganization || !sites) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;
      const organizationItems = [
        {
          type: Type.ORGANIZATION,
          id: currentOrganization.id,
          title: currentOrganization.title,
        },
      ];
      const siteItems = sites.map(({ id, title }: Site) => ({
        type: Type.SITE,
        id: id,
        title,
      }));

      const allItems = ([] as SelectItem[]).concat(
        organizationItems,
        siteItems
      );
      const selectedItem = allItems.find(({ id }: SelectItem) => value === id);

      const selectOrganizationItems = organizationItems.filter(({ title }) =>
        hasSubstr(title, search)
      );
      const selectSiteItems = siteItems.filter(({ title }) =>
        hasSubstr(title, search)
      );

      return (
        <Select
          disabled={disabled}
          title={selectedItem?.title}
          value={selectedItem?.id}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
            error={error}
          />

          {selectOrganizationItems.length > 0 ? (
            //@ts-ignore
            <SelectHeader>Organization</SelectHeader>
          ) : (
            <SelectEmptyItem />
          )}
          {selectOrganizationItems.map(({ id, title }: SelectItem) => (
            <SelectItem
              key={id}
              isSelected={id === selectedItem?.id}
              onSelect={() => onSelect(id)}
            >
              {title}
            </SelectItem>
          ))}

          {selectSiteItems.length > 0 ? (
            //@ts-ignore
            <SelectHeader>Sites</SelectHeader>
          ) : (
            <SelectEmptyItem />
          )}
          {selectSiteItems.map(({ id, title }: SelectItem) => (
            <SelectItem
              key={id}
              isSelected={id === selectedItem?.id}
              onSelect={() => onSelect(id)}
            >
              {title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ sites, organizations }: ApplicationState) => ({
  sites: sortedSites(sites),
  currentOrganization: organizations.currentOrganization,
});

const mapDispatchToProps = {
  getSites,
  getCurrentOrganization,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelect);
