import { Button } from '@energybox/react-ui-library/dist/components';
import CardList, {
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import { InspectionSubmission } from '../../../../reducers/reportworker';
import { tileStyles } from '../InspectionTile';
import styles from './SubmitTile.module.css';

type Props = {
  canSubmit: boolean;
  onSubmit: () => void;
  siteId: string;
};

const SubmitTile: React.FC<Props> = ({ canSubmit, onSubmit, siteId }) => {
  return (
    <CardList
      data={[
        {
          key: 'submitCard',
          content: (
            <>
              <Cell width="8">
                <div className={styles.messageContainer}>
                  <span className={tileStyles.title}>
                    Submit report for installation closeout
                  </span>
                </div>
              </Cell>
              <Cell width="4">
                <div className={styles.submitButtonContainer}>
                  <Button
                    className={styles.submitButton}
                    onClick={onSubmit}
                    disabled={!canSubmit}
                  >
                    Submit
                  </Button>
                  <div className={styles.trailingSpace} />
                </div>
              </Cell>
            </>
          ),
        },
      ]}
      backgroundColor={'var(--ambient-basePlus90)'}
    />
  );
};

export default React.memo(SubmitTile);
