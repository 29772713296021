export enum StepType {
  'NONE' = 'NONE',
  'type' = 'type',
  'rollout' = 'rollout',
  'handling' = 'handling',
}

export type Step = {
  key: StepType;
  title: string;
  isValid: boolean;
};

export type Steps = {
  [key: string]: Step;
};

export type Condition = 'lt' | 'eq' | 'gt';

export const reminderSteps: Steps = {
  [StepType.type]: {
    key: StepType.type,
    title: 'Type',
    isValid: false,
  },
  [StepType.rollout]: {
    key: StepType.rollout,
    title: 'Rollout',
    isValid: false,
  },
  [StepType.handling]: {
    key: StepType.handling,
    title: 'Handling',
    isValid: false,
  },
};
