import {
  Button,
  Card,
  CardContent,
  CardList,
  CardTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  ChevronDown,
  ChevronUp,
  RunConfig,
} from '@energybox/react-ui-library/dist/icons';
import {
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import { useEffect, useState } from 'react';
import { useActuatorsBySiteId } from '../../../hooks/useControlBoard';
import { RunTestButtonText } from '../../../types/global';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { renderOutputStatus } from '../../Gateways/GatewayDetailPages/ShowControlBoardPage/ShowControlBoardPage';
import styles from './RunConfigurationTest.module.css';
import RunConfigurationTestResults from './RunConfigurationTestResults';

type Props = {
  siteId: string;
  controlBoardId: number;
  equipmentIdsArray: number[];
};
export const UnmappedRelaysConfiguration: React.FC<Props> = ({
  siteId,
  controlBoardId,
  equipmentIdsArray,
}) => {
  const [showTestResults, setShowTestResults] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [testResultsExist, setTestResultsExist] = useState(false);
  const [compareId, setCompareId] = useState(0);
  const [isRunTest, setIsRunTest] = useState(false);

  const actuators = useActuatorsBySiteId(siteId)!;
  const actuatorsUsedByControlBoard = actuators.filter(actuator => {
    return controlBoardId === actuator.controlBoardId;
  });
  const mappedRelays = actuatorsUsedByControlBoard.map(actuator => {
    return actuator.port;
  });
  const unmappedRelayIndices = Array.from(Array(16).keys())
    .map(portNum => {
      return portNum + 1;
    })
    .filter(portNum => {
      return !mappedRelays.includes(portNum);
    });

  const startSequence = useSelector(
    ({ runConfiguration }: ApplicationState) => {
      return runConfiguration.disableStartSequence;
    }
  );
  const runTest = useSelector(({ runConfiguration }: ApplicationState) => {
    return runConfiguration.disableRunTestButton;
  });

  useEffect(() => {
    runTest;
    startSequence;
  }, [runTest, startSequence]);

  useEffect(() => {
    const hasEquipmnet = equipmentIdsArray.find(id => {
      return id === controlBoardId;
    });
    hasEquipmnet ? setTestResultsExist(true) : setTestResultsExist(false);
  }, [controlBoardId, equipmentIdsArray]);

  const subscribedControlBoardOutputStates = useSelector(
    ({ subscribedControlBoardOutputStates }: ApplicationState) =>
      subscribedControlBoardOutputStates
  );

  const subscribedActuatorStates =
    subscribedControlBoardOutputStates[controlBoardId]?.state;

  const data: CardListRowData[] = unmappedRelayIndices
    .sort((itemA, itemB) => itemA - itemB)
    .map(port => {
      return {
        key: `${port}`,
        content: (
          <>
            <Cell width="1">Port {port}</Cell>
            <Cell width="2" className={styles.outputStatus}>
              {renderOutputStatus(port, subscribedActuatorStates, false)}
            </Cell>
          </>
        ),
      };
    });
  const header: CardListHeader[] = [
    {
      width: '1',
      content: 'Port',
    },
    {
      width: '2',
      content: 'Output Status',
    },
  ];

  const runConfigurationTest = id => {
    setCompareId(id);
    setShowDetails(true);
    setShowTestResults(true);
    setIsRunTest(true);
  };

  const showDetialsTab = () => {
    setShowTestResults(prevState => !prevState);
    setIsRunTest(false);
  };

  return (
    <>
      <Card>
        <CardContent className={styles.cardContent}>
          <CardTitle>
            <div className={styles.runTest}>
              <div className={styles.unmappedRelayTitle}> Unmapped Relays </div>
              <Button
                onClick={() => runConfigurationTest(siteId)}
                disabled={startSequence !== runTest ? false : true}
                className={startSequence !== runTest ? '' : styles.disableText}
              >
                <RunConfig width="23" height="20" color="white" />
                <span className={styles.buttonText}>
                  {RunTestButtonText.RUN_TEST}
                </span>
              </Button>
            </div>
          </CardTitle>
          <div className={styles.addRelay}>
            <div>
              <div className={styles.controlBoardTitle}>
                {actuatorsUsedByControlBoard[0].controlBoard.title}{' '}
              </div>
            </div>
            <div className={styles.actuatorTable}>
              <CardList
                data={data}
                header={header}
                headerClassName={styles.cardListHeader}
                numberOfGridColumns={14}
              />
            </div>
          </div>
          <div>
            {(showDetails || testResultsExist) && (
              <Button variant="text" onClick={showDetialsTab}>
                {showTestResults ? (
                  <span className={styles.icon}>
                    Hide Details <ChevronUp size={15} />
                  </span>
                ) : (
                  <span className={styles.icon}>
                    Show Details <ChevronDown size={15} />
                  </span>
                )}
              </Button>
            )}
          </div>
        </CardContent>
        <div>
          {showTestResults && (
            <RunConfigurationTestResults
              resourceId={controlBoardId}
              siteId={siteId}
              actuatorsPerEquipment={[]}
              isUnmappedRelays={true}
              isRunTest={isRunTest}
              setIsRunTest={setIsRunTest}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default UnmappedRelaysConfiguration;
