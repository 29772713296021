import { Link } from 'react-router-dom';
import { useControlBoardById } from '../../../hooks/useControlBoard';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { global } from '@energybox/react-ui-library/dist/utils';
import styles from './EditModeOffset.module.css';

type Props = {
  controlBoardId: number;
};

const SiteControllerData: React.FC<Props> = ({ controlBoardId }) => {
  const controlBoard = useControlBoardById(controlBoardId);

  return isDefined(controlBoardId) ? (
    <div>
      <Link to={`/devices/gateways/${controlBoardId}`} target="_blank">
        {controlBoard?.title}
      </Link>
    </div>
  ) : (
    <div className={styles.noData}> {global.NOT_AVAILABLE}</div>
  );
};
export default SiteControllerData;
