import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { edgeConfigStatusUpdate } from '../actions/edge_devices';
import { ApplicationState } from '../reducers';

export const TEN_MINUTES_IN_MILLISECONDS = 600000;

export const useGetEdgeConfigUpdateByEdgeId = (edgeId: number) => {
  const dispatch = useDispatch();

  const getEdgeConfigFile = useCallback(() => {
    if (edgeId) {
      dispatch(edgeConfigStatusUpdate(edgeId));
    }
  }, [dispatch, edgeId]);

  useEffect(() => {
    getEdgeConfigFile();
  }, []);

  const edgeConfigUpdate = useSelector(({ edgeDevices }: ApplicationState) => {
    return edgeDevices.edgeConfigUpdateStatusByEdgeId[edgeId];
  });

  const { isHashEqual, lastRunningHashTimeStamp, lastSentHashTimeStamp } =
    edgeConfigUpdate || {};

  const isEdgeConfigUpdating =
    DateTime.fromISO(lastSentHashTimeStamp as string).toMillis() >
    DateTime.fromISO(lastRunningHashTimeStamp as string).toMillis();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isEdgeConfigUpdating) {
      timer = setTimeout(() => {
        getEdgeConfigFile();
      }, 30000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [edgeConfigUpdate]);

  const shouldEdgeConfigStillBeUpdating = isEdgeConfigUpdating
    ? DateTime.now().toMillis() -
        DateTime.fromISO(lastSentHashTimeStamp as string).toMillis() <
      TEN_MINUTES_IN_MILLISECONDS
    : false;

  return {
    isHashEqual,
    lastRunningHashTimeStamp,
    lastSentHashTimeStamp,
    isEdgeConfigUpdating,
    shouldEdgeConfigStillBeUpdating,
  };
};
