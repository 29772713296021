export const isValidSerialNumber = (characters: string) => {
  if (characters?.length !== 11) return false;
  const regex = /^[a-z\s\W]+$/;

  for (let character of characters) {
    if (character.match(regex)) {
      return false;
    }
  }

  return true;
};
