import {
  TimeTableTimeSlot,
  TimeTableRow,
  TimeTableSpecialRow,
} from '@energybox/react-ui-library/dist/types';
import { WEEKDAYS, isDefined } from '@energybox/react-ui-library/dist/utils';
import { warn } from 'console';
import { DateTime } from 'luxon';
import { Routes } from '../routes';

export type ReducedHvacSopTimeSlots = {
  [key: string]: {
    begin: TimeTableTimeSlot;
    end: TimeTableTimeSlot;
  }[];
};

export const reduceHvacSopTimetableRows = (rows: TimeTableRow[]) => {
  // Need alreadyAddedSet because BE returns multiple timeslots corresponding to the same times
  const alreadyAddedSet = new Set();

  return rows.reduce<{
    [key: string]: {
      begin: TimeTableTimeSlot;
      end: TimeTableTimeSlot;
    }[];
  }>((acc, row) => {
    const newAcc = { ...acc };
    const { begin, end, weekdays } = row;
    const sortedDays = weekdays.sort(
      (str1, str2) => WEEKDAYS[str1] - WEEKDAYS[str2]
    );
    const key = sortedDays.join(', ');
    const setKey = `${key}-${begin.time}-${end.time}`;
    if (newAcc.hasOwnProperty(key) && !alreadyAddedSet.has(setKey)) {
      alreadyAddedSet.add(setKey);
      newAcc[key].push({ begin, end });
    } else {
      alreadyAddedSet.add(setKey);
      newAcc[key] = [{ begin, end }];
    }
    return newAcc;
  }, {});
};

export const parseDelta = (delta: string): number => {
  const sign = delta.charAt(0) == '-' ? -1 : 1;
  const deltaValue = parseInt(delta.substring(1, delta.length - 1));
  return deltaValue * sign;
};

export const reduceTimetablesByDay = (
  rows: TimeTableRow[],
  beginDelta: number = 0,
  endDelta: number = 0
) => {
  // Boolean value below holds whether the current DateTime pair is part of an overflow
  // e.g. MON 10pm to TUE 2am requires a split into two different [begin, end] pairs
  let dayToTimetableMap = new Map<string, [DateTime[], DateTime | null][]>();

  rows.forEach(row => {
    if (row.begin.time && row.end.time) {
      // Need to add/subtract offset first, then convert to DateTimes to get final ranges
      let begin = DateTime.fromISO(
        DateTime.fromISO(row.begin.time)
          .plus({ minutes: beginDelta })
          .toISOTime()
      );
      let end = DateTime.fromISO(
        DateTime.fromISO(row.end.time)
          .plus({ minutes: endDelta })
          .toISOTime()
      );

      if (begin < end) {
        row.weekdays.forEach(weekday => {
          addDateRangeToMap(dayToTimetableMap, weekday, begin, end, null);
        });
      } else {
        end = end.plus({ days: 1 });
        row.weekdays.forEach(weekday => {
          const nextDayOfTheWeek = WEEKDAYS[(WEEKDAYS[weekday] + 1) % 7];
          addDateRangeToMap(
            dayToTimetableMap,
            weekday,
            begin,
            begin.endOf('day'),
            end
          );
          addDateRangeToMap(
            dayToTimetableMap,
            nextDayOfTheWeek,
            end.startOf('day'),
            end,
            begin
          );
        });
      }
    }
  });

  return dayToTimetableMap;
};

const addDateRangeToMap = (
  dayToTimetableMap: Map<string, [DateTime[], DateTime | null][]>,
  weekday: string,
  begin: DateTime,
  end: DateTime,
  overflowTimestamp: DateTime | null
) => {
  if (dayToTimetableMap.has(weekday)) {
    dayToTimetableMap.get(weekday)?.push([[begin, end], overflowTimestamp]);
  } else {
    dayToTimetableMap.set(weekday, [[[begin, end], overflowTimestamp]]);
  }
};

// Adjusts one day to timetable map, resolving overlaps by adjusting begin/end timestamps
export const combineReducedTimetableMaps = (
  existingMap: Map<string, [DateTime[], DateTime | null][]>,
  mapToChange: Map<string, [DateTime[], DateTime | null][]>
) => {
  let mapToChangeCopy = new Map(mapToChange);
  const days = Object.keys(WEEKDAYS);
  days.forEach(day => {
    if (!isNaN(Number(day))) {
      return;
    }
    let existingTimeBlocks = timetableMapToBlocks(existingMap, day);
    mapToChangeCopy.get(day)?.forEach(entry => {
      let begin = entry[0][0];
      let end = entry[0][1];
      // adjust the begin and end timestamp of each entry of mapToChangeCopy if there's overlap
      existingTimeBlocks.forEach(block => {
        if (isTimestampInBlock(begin, block)) {
          entry[0][0] = block[1];
        }
        if (isTimestampInBlock(end, block)) {
          entry[0][1] = block[0];
        }
      });
    });
  });
  return mapToChangeCopy;
};

const timetableMapToBlocks = (
  existingMap: Map<string, [DateTime[], DateTime | null][]>,
  day: string
) => {
  let existingTimeBlocks = new Array<DateTime[]>();
  existingMap.get(day)?.map(dateTimeEntry => {
    let begin = dateTimeEntry[0][0];
    let end = dateTimeEntry[0][1];
    existingTimeBlocks.push([begin, end]);
  });
  return existingTimeBlocks;
};

const isTimestampInBlock = (timestamp: DateTime, block: DateTime[]) => {
  return block[0] <= timestamp && timestamp <= block[1];
};

export const getHvacSopRoute = ({
  orgUnitId,
  hvacSopId,
  isOrgLevelTimetable = false,
}: {
  orgUnitId?: string | number;
  hvacSopId?: string | number | null;
  isOrgLevelTimetable?: boolean;
}) => {
  if (orgUnitId === undefined) {
    return '';
  }
  const hvacSopFragment = isDefined(hvacSopId) ? `#${hvacSopId}` : '';

  if (isOrgLevelTimetable) {
    return `${Routes.SOPS}${Routes.HVAC}${hvacSopFragment}`;
  }

  return `${Routes.SITES}/${orgUnitId}${Routes.SOPS}${Routes.HVAC}${hvacSopFragment}`;
};
