import {
  Card,
  CardTitle,
  CardContent,
  DateFilter,
  ResetButton,
  EnergyLineChart,
} from '@energybox/react-ui-library/dist/components';
import { EnergyLineChartType } from '@energybox/react-ui-library/dist/components/EnergyLineChart';
import {
  Site,
  AggregationFilter,
} from '@energybox/react-ui-library/dist/types';
import { TIME_IN_MILLISECONDS } from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';

import React, { useState, useMemo } from 'react';
import { useAppLocale } from '../../hooks/useAppDetails';
import useChartDetails from '../../hooks/useChartDetails';
import useSiteEnergyDashboard from '../../hooks/useSiteEnergyDashboard';
import ChartResolutionFilter from '../../components/Filters/ChartResolutionFilter';
import EnergyChartTypeToggle from '../EnergyChartTypeToggle';
import styles from './SiteEnergyChart.module.css';

type Props = {
  site: Site;
};

const SiteEnergyChart: React.FC<Props> = ({ site }) => {
  const [chartType, setChartType] = useState<EnergyLineChartType>(
    EnergyLineChartType.POWER
  );

  const locale = useAppLocale();
  const chartDetails = useChartDetails(locale, {
    includeAggregationFilter: true,
    timezone: site.timeZone,
  });

  const {
    dateFilterTitle,
    dateFilterFromDate,
    dateFilterToDate,
    fromDate,
    toDate,
    xTicks,
    setDateFilter,
    zoomIn,
    zoomOut,
    isZoomedIn,
    setRefAreaStart,
    setRefAreaEnd,
    refAreaStart,
    refAreaEnd,
    aggregationFilter,
    setAggregationFilter,
    isMonthly,
    isCustomRange,
  } = chartDetails;
  const fromDateMs = DateTime.fromISO(fromDate).toMillis();
  const toDateMs = DateTime.fromISO(toDate).toMillis();
  const dateFilterFromDateJS = DateTime.fromISO(dateFilterFromDate).toJSDate();
  const dateFilterToDateJS = DateTime.fromISO(dateFilterToDate).toJSDate();

  const energyQueryParams = useMemo(
    () => ({
      id: site.id,
      from: fromDate,
      to: toDate,
      aggregationLevel: aggregationFilter?.aggregationLevel,
      seeSpilloverPoint: true,
    }),
    [site, fromDate, toDate, aggregationFilter?.aggregationLevel]
  );

  const { isLoading, data } = useSiteEnergyDashboard(
    site.id,
    energyQueryParams,
    site.newTsdb
  );

  const processedData = useMemo(() => {
    if (!data) return [];

    const { consumption, spilloverPoint } = data;
    const readings = [...consumption.readings];
    if (spilloverPoint) readings.push(spilloverPoint);

    return readings.map(d => ({
      timestamp: d.timestamp * 1000,
      value: d.power,
      current: d.current,
    }));
  }, [data]);

  const timeDelta = toDateMs - fromDateMs;
  const disableOneMinuteResolution = timeDelta > TIME_IN_MILLISECONDS.ONE_DAY;

  return (
    <Card className={styles.cardContainer}>
      <CardContent className={styles.cardContent}>
        <header className={styles.header}>
          <CardTitle className={styles.title}>Site Total Demand</CardTitle>

          <div className={styles.headerRightAlign}>
            <EnergyChartTypeToggle
              chartType={chartType}
              setChartType={setChartType}
            />

            {aggregationFilter && (
              <ChartResolutionFilter
                disableOneMinuteResolution={disableOneMinuteResolution}
                title={aggregationFilter.title}
                onChange={(aggFilter: AggregationFilter) =>
                  setAggregationFilter(aggFilter)
                }
              />
            )}

            <DateFilter
              alignItemsRight
              setFilter={setDateFilter}
              value={{
                title: dateFilterTitle,
                toDate: dateFilterToDateJS,
                fromDate: dateFilterFromDateJS,
                isMonthly,
                isCustomRange,
              }}
              dateFormat="datetime"
              customPickerVariant="datetime"
              ianaTimeZoneCode={site.timeZone}
              displayDatesAsTitle
              locale={locale}
            />

            <ResetButton
              customText="Reset Zoom"
              displayInfoTooltip
              onClick={zoomOut}
              disabled={!isZoomedIn}
            />
          </div>
        </header>

        <EnergyLineChart
          isLoading={isLoading}
          locale={locale}
          ianaTimeZoneCode={site.timeZone}
          fromDate={fromDateMs}
          toDate={toDateMs}
          ticks={xTicks}
          data={processedData}
          zoomIn={zoomIn}
          refAreaStart={refAreaStart}
          refAreaEnd={refAreaEnd}
          updateRefAreaStart={setRefAreaStart}
          updateRefAreaEnd={setRefAreaEnd}
          type={chartType}
          thresholdLines={[]}
          hideThresholdLinesCheckbox
          hideNotificationsCheckbox
          hideCommentsCheckbox
          displayCurrentInLegend
        />
      </CardContent>
    </Card>
  );
};

export default SiteEnergyChart;
