import { WeatherData } from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import { Actions } from '../actions/weather';

export type Weather = {
  weatherBySiteId: WeatherBySiteId;
  isLoading: boolean;
};

export type WeatherBySiteId = {
  [siteId: string]: WeatherData;
};

const initialState = {
  weatherBySiteId: {},
  isLoading: false,
};

const normalizeWeatherResponse = apiResponse => ({
  siteId: apiResponse.id,
  humidity: apiResponse.humidity,
  temperature: apiResponse.temperature,
  timestamp: apiResponse.timestamp,
  windchill: apiResponse.windchill,
});

const weather = (state: Weather = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS:
      const data = action.payload;
      const updatedWeatherBySiteId = data.reduce(
        (acc: WeatherBySiteId, weather) => ({
          ...acc,
          [weather.id]: normalizeWeatherResponse(weather),
        }),
        {}
      );
      return {
        ...state,
        weatherBySiteId: {
          ...state.weatherBySiteId,
          ...updatedWeatherBySiteId,
        },
        isLoading: false,
      };
    case Actions.GET_WEATHER_BY_MULTI_SITES_IDS_LOADING:
      return assoc('isLoading', true, state);
    case Actions.GET_WEATHER_BY_MULTI_SITES_IDS_ERROR:
      return assoc('isLoading', false, state);

    default:
      return state;
  }
};

export default weather;
