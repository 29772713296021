export enum Routes {
  BASE = '/',

  LOGIN = '/login',

  USERS = '/users',
  INSTALLERS = '/installers',
  USER_GROUPS = '/user-groups',
  SITE_GROUPS = '/site-groups',
  NETWORK_GROUPS = '/network-groups',
  INSTALLER_GROUPS = '/installer-groups',

  SITES = '/sites',
  ORGANIZATIONS = '/organizations',
  SPACES = '/spaces',
  EB_SETTINGS = '/eb-settings',

  EQUIPMENT = '/equipment',
  HVAC_EDITOR = '#hvac-editor',
  HVAC_CONTROLS_CARD = '#hvac-controls-card',
  GATEWAYS = '/gateways',
  TIME_TABLES = '/time-tables',
  SENSORS = '/sensors',
  PAIRED_SENSOR_ACTIONS = '#paired-sensor-actions',
  DEVICES = '/devices',
  SENTINELS = '/sentinels',
  REMINDER = '/reminder',
  DISTRIBUTION_PANELS = '/panels',
  RUN_CONFIGURATION_TEST = '/run-configuration-test',
  ENERGY_PRO_SETTINGS = '#energy-pro-settings',
  ENERGY_PRO_LIVE_READINGS = '#energy-pro-live-readings',
  ENERGY_PRO_MAINS = '#energy-pro-mains',
  ENERGY_DEVICE_PORTS = '#energy-device-ports',
  REPORTS = '/reports',
  INSPECTION = '/inspection',
  SOPS = '/sops',
  POLICIES = '/policies',
  COSTS = '/costs',
  HVAC = '/hvac',
  CONTROLS = '/controls',
  EDM = '/edm',
  HVAC_CONTROLS = '/hvac-controls',
  LIGHTING = '/lighting',
  OTHERS = '/others',
  DOCUMENTS = '/documents',
  UTILITY_BILLS = '/utility-bills',
  INSTALLATION_DOCS = '/installation-docs',
  TASKS = '/tasks',

  SETTINGS = '/settings',
  IMAGES = '/images',
  PASSWORD_CHANGE = '/settings/password-change',

  NEW_PASSWORD = '/new-password',
  FORGOT_PASSWORD = '/forgot-password',
  FIND_MY_SENSOR = '/find-my-sensor',
  MY_DOWNLOADS_PAGE = `/my-downloads`,

  USER_INVITE_EMAIL = '/user-invite-email',

  INSTALLATION_MANUAL = '/installation-manual',

  OPEN_SOURCE_LICENSE = '/license',
}
