const apiBase = '/api/v1/aggregate-views';

export enum Actions {
  GET_NONHVAC_EQUIPMENTS_SUCCESS = '@equipment/GET_NONHVAC_EQUIPMENTS_SUCCESS',
  GET_NONHVAC_EQUIPMENTS_ERROR = '@equipment/GET_NONHVAC_EQUIPMENTS_ERROR',
  GET_NONHVAC_EQUIPMENTS_LOADING = '@equipment/GET_NONHVAC_EQUIPMENTS_LOADING',
}

export const getNonHvacEquipments = (orgId: number | undefined) => ({
  type: 'API_GET',
  path: `${apiBase}/non-hvacs/organization/${orgId}`,
  success: Actions.GET_NONHVAC_EQUIPMENTS_SUCCESS,
  error: Actions.GET_NONHVAC_EQUIPMENTS_ERROR,
  loading: Actions.GET_NONHVAC_EQUIPMENTS_LOADING,
});
