import { LegacyRef, useEffect, useRef } from 'react';
import { DynamicContentWrapper, PageContentHeader } from '../components/Page';
import IndexManagementPageLayout from '../components/IndexManagementPageLayout/IndexManagementPageLayout';

const License = () => {
  const ref = useRef<HTMLIFrameElement>();

  useEffect(() => {
    if (ref.current && ref.current.contentDocument) {
      ref.current.style.height =
        ref.current?.contentDocument?.body.scrollHeight + 'px';
    }
  }, [ref.current?.contentDocument?.body.clientHeight]);
  return (
    <>
      <IndexManagementPageLayout>
        <DynamicContentWrapper>
          <PageContentHeader header="Open Source Notices" />
          <iframe
            ref={ref as LegacyRef<HTMLIFrameElement>}
            title={'License'}
            style={{
              width: '100%',
              height: '100%',
              minHeight: '35rem',
              border: 'none',
              boxShadow: 'var(--shadow10)',
            }}
            src={`${process.env.REACT_APP_PLATFORM_SWITCH_URL}/public/license`}
          />
        </DynamicContentWrapper>
      </IndexManagementPageLayout>
    </>
  );
};

export default License;
