import { global } from '@energybox/react-ui-library/dist/utils';

export const formatDecimalValue = (
  value: number | undefined,
  decimals: number = 2,
  unit?: string
) => {
  return value !== undefined
    ? `${value.toFixed(decimals)}${unit ? ` ${unit}` : ''}`
    : global.NOT_AVAILABLE;
};
