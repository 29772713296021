import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFiles } from '../../actions/files';
import {
  notifyDownload,
  removeOutdatedReportWorkerJobs,
  saveFileIDs,
  toggleShowHideIcon,
  updateReportWorkerJobs,
} from '../../actions/reportworker';
import { unsubscribeFromWorkerStatus } from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';
import useSubscribeToReportStatus from '../useSubscribeToReportStatus';

export const useDownloadStatus = () => {
  const dispatch = useDispatch();

  let reportStatus: any;
  reportStatus = useSubscribeToReportStatus();

  const reportWorker = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.reportWorker
  );

  const saveFileId = useSelector(({ reportworker }: ApplicationState) =>
    reportworker.saveFileIds.filter(item => item)
  );

  useEffect(() => {
    dispatch(removeOutdatedReportWorkerJobs());
    toggleDownloadIcon(
      reportWorker instanceof Array
        ? reportWorker.filter(
            data =>
              data.errorStatusResponse &&
              data.errorStatusResponse.errorCode === 0 &&
              !data.error
          )
        : []
    );
  }, []);

  useEffect(() => {
    if (
      reportStatus !== undefined &&
      isDefined(reportStatus.jobId) &&
      isDefined(reportStatus.jobState)
    ) {
      const reportWorkerData =
        reportWorker instanceof Array
          ? reportWorker.filter(
              data =>
                data.jobId !== reportStatus.jobId &&
                data.errorStatusResponse &&
                data.errorStatusResponse.errorCode === 0 &&
                !data.error
            )
          : [];

      if (reportStatus.jobState === 'SUCCESS') {
        if (!saveFileId.includes(reportStatus.jobId)) {
          dispatch(saveFileIDs(reportStatus.jobId));
        }
        toggleDownloadIcon(reportWorkerData);
        dispatch(updateReportWorkerJobs(reportWorkerData));
        dispatch(unsubscribeFromWorkerStatus(reportStatus.jobId));
        dispatch(getFiles());
      } else if (reportStatus.jobState === 'FAILURE') {
        const updatedReportWorkerData =
          reportWorker instanceof Array
            ? reportWorker.map(data => {
                if (
                  data.jobId === reportStatus.jobId &&
                  data.errorStatusResponse
                ) {
                  data.errorStatusResponse.errorCode = 500;
                  data.errorStatusResponse.message = 'Please try again later';
                  data.failedAt = DateTime.now().toMillis();
                }
                return data;
              })
            : [];
        toggleDownloadIcon(
          updatedReportWorkerData.filter(
            data =>
              data.jobId !== reportStatus.jobId &&
              data.errorStatusResponse &&
              data.errorStatusResponse.errorCode === 0 &&
              !data.error
          )
        );
        dispatch(updateReportWorkerJobs(updatedReportWorkerData));
        dispatch(unsubscribeFromWorkerStatus(reportStatus.jobId));
      }
    }
  }, [reportStatus]);

  const toggleDownloadIcon = reportWorkerData => {
    if (reportWorkerData && reportWorkerData.length === 0) {
      dispatch(toggleShowHideIcon(false));
      dispatch(notifyDownload(false));
    } else {
      dispatch(toggleShowHideIcon(true));
      dispatch(notifyDownload(true));
    }
  };
};
