import { InputField, Label } from '@energybox/react-ui-library/dist/components';
import { GenericErrors, Region } from '@energybox/react-ui-library/dist/types';
import SelectRegion from '../../containers/Selects/SelectRegion';
import styles from './EditGroupForm.module.css';

interface Props {
  onChange: (field: string, value: any) => void;
  title: string;
  description: string;
  region?: Region;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  isInstallerGroup?: boolean;
}

const EditGroupForm = ({
  onChange,
  title,
  description,
  region,
  formErrors = {},
  formErrorsVisible,
  isInstallerGroup,
}: Props) => (
  <form className={styles.form}>
    <div>
      <div className={styles.inputGroup}>
        <div>
          <Label required htmlFor="groupName">
            Group Name
          </Label>
        </div>
        <div>
          <InputField
            id="groupName"
            type="text"
            name="title"
            autoComplete="title"
            value={title}
            onChange={({ currentTarget }) =>
              onChange('title', currentTarget.value)
            }
            error={formErrorsVisible && !!formErrors.title}
            customErrorText={formErrors.title && formErrors.title[0]}
          />
        </div>
      </div>

      <div className={styles.inputGroup}>
        <Label htmlFor="description">Description</Label>
        <div>
          <InputField
            type="text"
            id="description"
            name="description"
            autoComplete="description"
            value={description}
            onChange={({ currentTarget }) =>
              onChange('description', currentTarget.value)
            }
          />
        </div>
      </div>
    </div>

    {isInstallerGroup && (
      <div>
        <div className={styles.inputGroup}>
          <Label required htmlFor="description">
            EB Region
          </Label>
          <div>
            <SelectRegion
              onSelect={value => onChange('region', value)}
              value={region}
              error={formErrorsVisible && !!formErrors.region}
              customErrorText={formErrors?.region?.[0]}
            />
          </div>
        </div>
      </div>
    )}
  </form>
);

export default EditGroupForm;
