import { EnergyboxService } from '../config';

export enum Actions {
  GET_NOTIFICATIONS_SUCCESS = '@notifications/GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_LOADING = '@notifications/GET_NOTIFICATIONS_LOADING',
  GET_NOTIFICATIONS_ERROR = '@notifications/GET_NOTIFICATIONS_ERROR',
}

export type GetNotificationsParams = {
  sensorIds?: string[];
  fromDate?: string;
  toDate?: string;
  state?: string[];
};

const createQueryString = (params: GetNotificationsParams) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getNotifications = (params: GetNotificationsParams) => ({
  type: 'API_GET',
  path: `/api/v1/notifications?${createQueryString(params)}`,
  service: EnergyboxService.sentinels,
  loading: Actions.GET_NOTIFICATIONS_LOADING,
  success: Actions.GET_NOTIFICATIONS_SUCCESS,
  error: Actions.GET_NOTIFICATIONS_ERROR,
});
