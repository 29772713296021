import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { TimeTableTimeSlot } from '@energybox/react-ui-library/dist/types';

import { Actions } from '../actions/edm';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { sortTimeTableTimeSlot } from '../utils/sorting';

type RawSchedule = {
  timetable: {
    rows: {
      begin: TimeTableTimeSlot;
      end: TimeTableTimeSlot;
    }[];
  };
};

export type EdmSchedule = {
  scheduledDate: string;
  begin: TimeTableTimeSlot;
  end: TimeTableTimeSlot;
};

export type EdmScheduleByEquipmentId = {
  [id: string]: EdmSchedule;
};

export type EdmSchedules = {
  scheduleIsLoading: boolean;
  scheduleByEquipmentId: EdmScheduleByEquipmentId;
  apiError: ApiError;
};

const initialState: EdmSchedules = {
  scheduleIsLoading: false,
  scheduleByEquipmentId: {},
  apiError: {},
};

const addForecastsToState = (state, forecast) => {
  const {
    scheduleDate: scheduledDateFull,
    siteSchedule: { schedules },
  } = forecast;

  const scheduledDate = scheduledDateFull.split('T')[0];

  Object.keys(schedules).forEach(equipmentId => {
    const { sop } = schedules[equipmentId];

    const timetables = sop.components[0].hvacSettings[0]
      .hvacSchedules as RawSchedule[];

    const finalSchedule: EdmSchedule = timetables.reduce(
      (tentativeConfig, timetable) => {
        const { scheduledDate, begin, end } = tentativeConfig;
        const { begin: newBegin, end: newEnd } = timetable.timetable.rows[0];

        return {
          scheduledDate,
          begin: newBegin.time
            ? [begin, newBegin].sort(sortTimeTableTimeSlot)[0]
            : begin,
          end: newEnd.time ? [end, newEnd].sort(sortTimeTableTimeSlot)[1] : end,
        };
      },
      {
        scheduledDate,
        begin: {
          time: '23:59:59',
          type: 'Clock',
          delta: null,
        } as TimeTableTimeSlot,
        end: {
          time: '00:00:00',
          type: 'Clock',
          delta: null,
        } as TimeTableTimeSlot,
      }
    );
    state = assocPath(
      ['scheduleByEquipmentId', equipmentId],
      finalSchedule
    )(state);
  });
  return state;
};

const edm = (state: EdmSchedules = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_EDM_CONFIG_BY_SITE_ID_SUCCESS:
      const edmForecast = action.data.data;

      state = addForecastsToState(state, edmForecast);
      return assoc('scheduleIsLoading', false)(state);

    case Actions.GET_EDM_CONFIG_BY_SITE_ID_LOADING:
      return assoc('scheduleIsLoading', true)(state);

    case Actions.GET_EDM_CONFIG_BY_SITE_ID_ERROR:
      return pipe(
        assoc('apiError', storeAPIerror(action)),
        assoc('scheduleIsLoading', false)
      )(state);

    default:
      return state;
  }
};

export default edm;
