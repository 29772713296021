import { global } from '@energybox/react-ui-library/dist/utils';
import {
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';

export function toCelsius(fahrenheit: number): number {
  return ((fahrenheit - 32) * 5) / 9;
}

export function toFahrenheit(celsius: number): number {
  return (celsius * 9) / 5 + 32;
}

export default function convertTemp(
  value: number | string,
  from: 'c' | 'f',
  decimals: number = 2
) {
  let valCopy = value;
  if (isNaN(Number(valCopy))) {
    return global.NOT_AVAILABLE;
  }

  if (typeof valCopy !== 'number') valCopy = Number(valCopy);

  if (from === 'c') {
    return +toFahrenheit(valCopy).toFixed(decimals);
  }

  return +toCelsius(valCopy).toFixed(decimals);
}
export function convertHysteresis(
  value: number | string,
  from: 'c' | 'f',
  decimals: number = 2
) {
  let valCopy = value;
  /* eslint-disable */
  if (isNaN(Number(valCopy))) {
    return 0;
  }
  /* eslint-enable */

  if (typeof valCopy !== 'number') valCopy = Number(valCopy);

  if (from === 'c') {
    return (
      parseInput(toFahrenheit, value, 3) - parseInput(toFahrenheit, 0, 3)
    ).toFixed(decimals);
  }

  return (
    parseInput(toCelsius, value, 3) - parseInput(toCelsius, 0, 3)
  ).toFixed(decimals);
}

export function parseInput(
  fn: (v: number) => number,
  value: any,
  decimalPlaces: number
): any {
  if (value === '' || value === '-') {
    return value;
  }

  if (isNaN(value)) {
    return '';
  }

  return parseFloat(fn(parseFloat(value)).toFixed(decimalPlaces));
}

export function fixIfDecimal(num: number | string) {
  if (typeof num === 'string' || Number.isInteger(num)) {
    return num;
  } else {
    return num.toFixed(1);
  }
}

export function parseHysteresisValueForDisplay(
  temperatureUnit: TemperatureUnit,
  value: number | undefined | null
) {
  if (value === null || value === undefined) return '';

  if (temperatureUnit === TemperatureUnit.C) {
    if (value === undefined) {
      return '';
    } else {
      return fixIfDecimal(value);
    }
  } else {
    return fixIfDecimal(
      parseInput(toFahrenheit, value, 1) - parseInput(toFahrenheit, 0, 1)
    );
  }
}

export const getTempDifferenceInCelsius = (difference: number) => {
  return difference / 1.8;
};

export const getTempDifferenceInFahrenheit = (difference: number) => {
  return difference * 1.8;
};
