import React from 'react';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import styles from './ModalFormContent.module.css';

interface Props {
  className?: string;
}

const ModalFormContent: React.FunctionComponent<Props> = ({
  children,
  className,
}) => <div className={classNames(styles.root, className)}>{children}</div>;

export default ModalFormContent;
