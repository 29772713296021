import {
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';

import { useState } from 'react';
import ConfirmReportDialog from '../../../containers/ShowEquipmentPage/ConfirmReportDialog';

import {
  globalReport,
  toggleShowHideIcon,
  notifyDownload,
} from '../../../actions/reportworker';
import ReportGenerateButton from './ReportGenerateButton';
import { useDispatch } from 'react-redux';

const ReportGenerateDropDownButton = props => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSelect = () => {
    const params = {
      reportTemplate: 'HVAC_SOP',
      organizationId: props.orgId,
      periodType: '',
      reportFromDate: '',
    };
    setDialogOpen(true);
    dispatch(globalReport(params));
    dispatch(toggleShowHideIcon(true));
    dispatch(notifyDownload(true));
  };

  return (
    <>
      <MenuDropdown
        disabled={false}
        icon={<ReportGenerateButton disabled={false} />}
      >
        <MenuDropdownItem onSelect={() => handleSelect()}>
          Generate HVAC SOP
        </MenuDropdownItem>
      </MenuDropdown>

      <ConfirmReportDialog
        isCreateDashboardDialogOpen={dialogOpen}
        onSubmit={() => setDialogOpen(false)}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default ReportGenerateDropDownButton;
