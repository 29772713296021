import Button from '@energybox/react-ui-library/dist/components/Button';
import React from 'react';
import styles from './ReportGenerateButton.module.css';
import {
  ReportGenerateIcon,
  ReportBulkGenerateIcon,
} from '@energybox/react-ui-library/dist/icons';

interface Props {
  disabled?: boolean;
  isBulk?: boolean;
}

const ReportGenerateButton: React.FC<Props> = ({
  disabled = false,
  isBulk = false,
}) => {
  return (
    <>
      <Button
        disabled={disabled}
        className={styles.buttonContainer}
        roundedCorners
      >
        {isBulk ? (
          <ReportBulkGenerateIcon size={20} />
        ) : (
          <ReportGenerateIcon size={16} />
        )}
      </Button>
    </>
  );
};

export default ReportGenerateButton;
