import {
  Button,
  InputField,
  Table,
  MediaElement,
  TimeDistance,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  CurrentUser,
  EnergyPro,
  Gateway,
  LightSensorPort,
  ResourceType,
  Sensor,
  SensorsWhitelist,
  SensorType,
  SubscribedThermostats,
  Thermostat,
} from '@energybox/react-ui-library/dist/types';
import {
  createTemperatureString,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { connect } from 'react-redux';
import { getSensors } from '../../actions/sensors';
import {
  subscribeToDeviceReadings,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import ValueChip from '../../components/ValueChip';
import DeviceOnlineState, {
  DisplayType,
} from '../../containers/DeviceStatus/DeviceOnlineState';
import { ApplicationState } from '../../reducers';
import { DeviceStatusById } from '../../reducers/deviceStatus';
import { PairedSensor } from '../../reducers/pairedSensors';
import { SensorReading, SensorsById } from '../../reducers/sensors';
import {
  BatteryStatus,
  SensorTypeIconWithHoverText,
  SignalStatus,
} from '../../utils/sensor';
import FeatureFlag from '../FeatureFlag';
import ResourcePath from '../ResourcePath/ResourcePath';
import styles from './WhitelistTable.module.css';
import ChainIcon from './ChainIcon';
import { isEBThermostat } from '../../utils/ebThermostat';
import {
  EthernetIcon,
  ThermostatIcon,
} from '@energybox/react-ui-library/dist/icons';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import { getSpaces } from '../../actions/spaces';
import { Spaces } from '../../reducers/spaces';
import { EditControlBoardById } from '../../reducers/control_boards';
import { fetchThermostat } from '../../actions/thermostatsFetch';
import { editableFields } from './GatewayDetailPages/ShowThermostatPage/ShowThermostatPage';
import {
  DeviceInstallInfo,
  isInstalled,
  SiteControllerStatus,
} from '../../reducers/subscribedDeviceList';
import UninstalledValueChip from '../../components/UninstalledValueChip/UninstalledValueChip';
import DeviceConnectionStatus from '../../components/DeviceConnectionStatus';

type OwnProps = {
  list?: SensorsWhitelist['uuids'];
  sensorsPairedList?: PairedSensor[];
  onRemoveSensor: (sensorUuid: string) => void;
  isLoading: boolean;
  onSensorAdd: (uuid: string) => void;
  ianaTimeZoneCode?: string;
  openNewModal: (uuid: string, type: string) => void;
  user?: CurrentUser;
  isSiteControllerCard?: boolean;
  isEnergyproCard?: boolean;
  pairedSensors?: string[];
  additionalDeviceInfo?: {
    [uuid: string]: DeviceInstallInfo;
  };
};

type Props = {
  subscribeToDeviceStatus: typeof subscribeToDeviceStatus;
  unsubscribeFromDeviceStatus: typeof unsubscribeFromDeviceStatus;
  subscribeToDeviceReadings: typeof subscribeToDeviceReadings;
  unsubscribeFromDeviceReadings: typeof unsubscribeFromDeviceReadings;
  subscribedThermostats: SubscribedThermostats;
  getSensors: typeof getSensors;
  getSpaces: (id: string) => void;
  spaces: Spaces;
  deviceStatusById: DeviceStatusById;
  sensorsById: SensorsById;
  isThermostatSensorPage?: boolean;
  isDotsCard?: boolean;
  isThermostatCard?: boolean;
  isNetworkGroupPage?: boolean;
  gateways?: (Gateway | ControlBoard | EnergyPro)[];
  editControlBoardById: EditControlBoardById;
} & OwnProps;

type State = {
  addSensorUuid: string;
  thermostats: Thermostat[];
  additionalDeviceInfo?: {
    [uuid: string]: DeviceInstallInfo;
  };
};

class WhitelistTable extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      addSensorUuid: '',
      thermostats: [],
    };
  }
  componentDidMount() {
    const { list, gateways } = this.props;
    const currentList = list || [];
    if (currentList.length) {
      this.getSensorsFromUuids('energybox', currentList);

      currentList.forEach(uuid => {
        this.props.subscribeToDeviceStatus('energybox', uuid, uuid);
        this.props.subscribeToDeviceReadings('energybox', uuid);
      });
    }

    if (gateways && gateways.length > 0) {
      const thermostatIdsAndTypes = gateways
        .filter(
          gateway => (gateway as Gateway)._entity === 'EnergyboxThermostat'
        )
        .map(gateway => ({ id: gateway.id, type: gateway.model }));

      if (thermostatIdsAndTypes.length > 0) {
        this.fetchAllThermostats(thermostatIdsAndTypes);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const addSub = R.difference(this.props.list, prevProps.list);
    if (addSub.length > 0) {
      this.getSensorsFromUuids('energybox', addSub);
    }

    if (this.props.sensorsById !== prevProps.sensorsById) {
      this.forceUpdate();
    }
  }

  componentWillReceiveProps(nextProps) {
    const currentList = this.props.list || [];
    const newList = nextProps.list || [];
    const addSub = R.difference(newList, currentList);
    const removeSub = R.difference(currentList, newList);

    addSub.forEach(uuid => {
      this.props.subscribeToDeviceStatus('energybox', uuid, uuid);
      this.props.subscribeToDeviceReadings('energybox', uuid);
    });
    removeSub.forEach(uuid => {
      this.props.unsubscribeFromDeviceStatus('energybox', uuid, uuid);
      this.props.unsubscribeFromDeviceReadings('energybox', uuid);
    });
  }

  componentWillUnmount() {
    (this.props.list || []).forEach(uuid => {
      this.props.unsubscribeFromDeviceStatus('energybox', uuid, uuid);
    });
    (this.props.list || []).forEach(uuid => {
      this.props.unsubscribeFromDeviceReadings('energybox', uuid);
    });
  }

  getSensorsFromUuids = async (vendor: string, uuids: string[]) => {
    const iam = process.env.REACT_APP_API_BASE_URL;
    const apiBase = '/api/v1/sensors';
    const promises = uuids.map(uuid =>
      fetch(`${iam}${apiBase}/lookup/${vendor}/${uuid}`)
    );
    const responses = await Promise.all(promises);
    const sensorIds = await Promise.all(responses.map(res => res.text()));
    this.props.getSensors({ ids: sensorIds });
  };

  fetchAllThermostats = async (
    idsAndTypes: Array<{ id: string | number; type: string }>
  ) => {
    try {
      const fetchPromises = idsAndTypes.map(({ id, type }) =>
        fetchThermostat(id.toString(), type)
      );

      const responses = await Promise.all(fetchPromises);

      const thermostats = responses.map(res => ({
        ...editableFields(res),
        equipment: res.equipment,
        siteId: res.networkGroup?.siteId,
        venstarThermostatModel: res.venstarThermostatModel,
        model: res.model,
      }));

      this.setState(prevState => ({
        thermostats: [...prevState.thermostats, ...thermostats],
      }));
    } catch (error) {
      console.error('Error fetching thermostats:', error);
    }
  };

  render() {
    const {
      list = [],
      onRemoveSensor,
      sensorsPairedList = [],
      isLoading,
      deviceStatusById,
      sensorsById,
      ianaTimeZoneCode,
      isThermostatSensorPage = false,
      isDotsCard = false,
      isThermostatCard = false,
      isNetworkGroupPage = false,
      openNewModal,
      subscribedThermostats,
      gateways,
      user,
      isEnergyproCard,
      isSiteControllerCard,
      spaces,
      pairedSensors,
      editControlBoardById,
      additionalDeviceInfo,
    } = this.props;

    const { addSensorUuid, thermostats } = this.state;
    const isSitecontrollerOrEnergypro = isEnergyproCard || isSiteControllerCard;

    const isDeviceInstalled = id => {
      if (isNetworkGroupPage) {
        const isInstalledDevicesIam =
          gateways?.map(gateway => gateway.uuid) || [];
        const isInstalledSensorIam = Object.values(sensorsById || {}).map(
          sensor => sensor.uuid
        );

        const installedIamDevices = [
          ...isInstalledDevicesIam,
          ...isInstalledSensorIam,
        ];

        if (additionalDeviceInfo && additionalDeviceInfo[id]) {
          const deviceStatus =
            additionalDeviceInfo[id].device_specific_status[
              'com.energybox.DotStatus'
            ] ||
            additionalDeviceInfo[id].device_specific_status[
              'com.energybox.ThermostatDeviceStatus'
            ] ||
            additionalDeviceInfo[id].device_specific_status[
              'com.energybox.SiteControllerStatus'
            ] ||
            additionalDeviceInfo[id].device_specific_status[
              'com.energybox.EnergyProStatus'
            ];

          if (
            deviceStatus &&
            deviceStatus.status &&
            deviceStatus.status.installed
          ) {
            // Only consider it installed if it's also in sensorsById
            if (installedIamDevices.includes(id)) {
              return true;
            } else {
              return false;
            }
          }
        }
        if (installedIamDevices.includes(id)) {
          return true;
        }

        return false;
      }
      return false;
    };

    const installButtonTitle = () => {
      if (isDotsCard) return 'Install Sensor';
      if (isThermostatCard || isSiteControllerCard) return 'Install Device';
      return '';
    };

    const findObject = uuid => gateways?.find(gateway => gateway.uuid === uuid);

    const subscribedThermostat = uuid =>
      Object.values(subscribedThermostats.byRFId!).find(
        obj => obj.uuid === uuid
      );

    const renderThermostatTemperature = subscribedRFThermostat => {
      let readingIsInvalid =
        subscribedRFThermostat?.temperature == 0 ||
        subscribedRFThermostat?.temperature == 0.0;
      if (readingIsInvalid)
        readingIsInvalid = [1, 2, 4].includes(
          Number(subscribedRFThermostat?.temperatureSensorStatus) % 32
        );

      return isDefined(subscribedRFThermostat) &&
        isDefined(user) &&
        isDefined(subscribedRFThermostat?.temperature) &&
        !readingIsInvalid
        ? createTemperatureString(subscribedRFThermostat.temperature, user)
        : global.NOT_AVAILABLE;
    };

    const getHumidityString = humidity => {
      if (isDefined(humidity)) {
        return ' @ ' + humidity.toFixed(1) + ' % ';
      }
      return '';
    };

    const columns = [
      ...(isThermostatCard
        ? [
            {
              header: 'Display Name',
              width: '8%',
              cellContent: uuid => {
                if (additionalDeviceInfo && additionalDeviceInfo[uuid]) {
                  const deviceInfo = additionalDeviceInfo[uuid];
                  const thermostatStatus =
                    deviceInfo.device_specific_status[
                      'com.energybox.ThermostatDeviceStatus'
                    ];
                  return thermostatStatus?.display_name || global.NOT_AVAILABLE;
                }
              },
            },
          ]
        : [
            {
              header: 'Type',
              width: '8%',
              cellContent: uuid => {
                const renderSensorTypesFromAdditionalInfo = (
                  uuid,
                  additionalDeviceInfo
                ) => {
                  const deviceInfo = additionalDeviceInfo[uuid];
                  const dotStatus =
                    deviceInfo.device_specific_status[
                      'com.energybox.DotStatus'
                    ];
                  const sensorTypes = dotStatus?.reading_types;

                  return (
                    sensorTypes && (
                      <MediaElement
                        icon={sensorTypes.map(sensorType => (
                          <SensorTypeIconWithHoverText
                            key={sensorType}
                            sensorType={sensorType as SensorType}
                          />
                        ))}
                        title={''}
                      />
                    )
                  );
                };

                if (
                  !isDeviceInstalled(uuid) &&
                  additionalDeviceInfo &&
                  additionalDeviceInfo[uuid]
                ) {
                  return renderSensorTypesFromAdditionalInfo(
                    uuid,
                    additionalDeviceInfo
                  );
                } else {
                  const status = deviceStatusById[uuid]!;
                  if (isEBThermostat(uuid)) {
                    return (
                      <MediaElement
                        icon={<ThermostatIcon variant="small" size="20" />}
                        title={''}
                      />
                    );
                  }
                  let sensor;
                  if (isDefined(status?.id)) {
                    sensor = sensorsById[status.id.toString()];
                  } else {
                    sensor = Object.values(sensorsById).find(
                      s => s.uuid == uuid
                    );
                  }

                  let sensorTypes;
                  if (!sensor && status?.types) {
                    sensorTypes = status?.types;
                  } else if (sensor) {
                    sensorTypes = sensor?.types;
                  } else {
                    // sensor was just uninstalled but existing data from stream
                    // may still be null in which case we grab from here
                    if (additionalDeviceInfo && additionalDeviceInfo[uuid])
                      return renderSensorTypesFromAdditionalInfo(
                        uuid,
                        additionalDeviceInfo
                      );
                  }
                  return (
                    sensorTypes && (
                      <MediaElement
                        icon={sensorTypes.map(sensorType => (
                          <SensorTypeIconWithHoverText
                            key={sensorType}
                            sensorType={sensorType}
                          />
                        ))}
                        title={''}
                      />
                    )
                  );
                }
              },
            },
          ]),
      {
        header: isNetworkGroupPage ? 'Name/UUID' : 'Sensor UUID',
        width: '15%',
        cellContent: uuid => {
          const status = deviceStatusById[uuid]!;
          const sensor = isDefined(status?.id)
            ? sensorsById[status?.id.toString()]
            : status;

          if (!isNetworkGroupPage && !isThermostatSensorPage) {
            return uuid;
          }
          const thermostat = findObject(uuid);
          const sensorObject = Object.values(sensorsById).find(
            s => s.uuid == uuid
          );
          const sensorTitle = sensorObject?.title;
          return sensorObject || thermostat ? (
            <div className={styles.sensorTitleComponent}>
              <span>
                {isNetworkGroupPage &&
                  (sensorTitle ? (
                    <Link
                      to={`${Routes.DEVICES}${Routes.SENSORS}/${sensorObject.id}`}
                      target="_blank"
                    >
                      {sensorTitle}
                    </Link>
                  ) : thermostat ? (
                    <Link
                      to={`${Routes.DEVICES}${Routes.GATEWAYS}/${thermostat.id}`}
                      target="_blank"
                    >
                      {thermostat.title}
                    </Link>
                  ) : (
                    global.NOT_AVAILABLE
                  ))}
              </span>
              <span>
                {uuid}{' '}
                {isThermostatSensorPage && isDefined(sensor)
                  ? sensor?.firmwareVersion
                  : ''}
              </span>
            </div>
          ) : (
            <div className={styles.sensorTitleComponent}>
              <span>{isNetworkGroupPage ? global.NOT_AVAILABLE : ''}</span>
              <span>{uuid}</span>
            </div>
          );
        },
      },
      ...(isNetworkGroupPage
        ? [
            {
              header: 'Version',
              cellContent: uuid => {
                if (additionalDeviceInfo && additionalDeviceInfo[uuid]) {
                  return additionalDeviceInfo[uuid].version;
                } else {
                  return global.NOT_AVAILABLE;
                }
              },
            },
          ]
        : []),
      ...(isThermostatSensorPage == false
        ? [
            {
              header: 'Status',
              width: '12%',
              cellContent: uuid => (
                // TODO REMOVE THIS INFAVOR FOR A COMPONENT THAT DOESNT SUB AGAIN
                <DeviceOnlineState
                  displayType={DisplayType.STATUS_WITH_TEXT_AND_TIME}
                  ianaTimeZoneCode={ianaTimeZoneCode}
                  devices={[
                    {
                      id: uuid,
                      uuid,
                      vendor: 'energybox',
                    },
                  ]}
                />
              ),
            },
          ]
        : []),

      ...(isThermostatCard
        ? [
            {
              header: 'Last Reading',
              cellContent: uuid => {
                const status = deviceStatusById[uuid]!;
                if (!status?.onlineState) {
                  return global.NOT_AVAILABLE;
                }

                // data from device_install_list channel
                const renderThermostatData = uuid => {
                  if (!additionalDeviceInfo) return global.NOT_AVAILABLE;

                  const deviceInfo = additionalDeviceInfo[uuid];
                  const thermostatStatus =
                    deviceInfo.device_specific_status[
                      'com.energybox.ThermostatDeviceStatus'
                    ];
                  return (
                    <div className={styles.thermostatFormLabel}>
                      {renderThermostatTemperature(thermostatStatus)}
                      {getHumidityString(thermostatStatus?.humidity)}
                    </div>
                  );
                };

                // where we get the data when its not insatlled
                if (
                  !isDeviceInstalled(uuid) &&
                  additionalDeviceInfo &&
                  additionalDeviceInfo[uuid]
                ) {
                  return renderThermostatData(uuid);
                }

                // tsat is installed
                const thermostat = subscribedThermostat(uuid)!;
                return (
                  <div className={styles.thermostatFormLabel}>
                    {isDefined(thermostat) ? (
                      <>
                        {renderThermostatTemperature(thermostat)}
                        {getHumidityString(thermostat?.humidity)}
                      </>
                    ) : additionalDeviceInfo && additionalDeviceInfo[uuid] ? (
                      // bug where device is installed but tsat is flaky undefined
                      // so we fall back on data from device_install_list channel
                      renderThermostatData(uuid)
                    ) : (
                      global.NOT_AVAILABLE
                    )}
                  </div>
                );
              },
            },
          ]
        : [
            {
              header: 'Last Reading',
              cellContent: uuid => {
                const status = deviceStatusById[uuid];
                if (!status?.onlineState) {
                  return global.NOT_AVAILABLE;
                }
                const isInstalled = isDeviceInstalled(uuid);

                if (
                  !isInstalled &&
                  additionalDeviceInfo &&
                  additionalDeviceInfo[uuid]
                ) {
                  return (
                    <UninstalledValueChip
                      uuid={uuid}
                      deviceInfo={additionalDeviceInfo[uuid]}
                      types={
                        additionalDeviceInfo[uuid].device_specific_status[
                          'com.energybox.DotStatus'
                        ].reading_types
                      }
                    />
                  );
                }

                const sensor = isDefined(status.id)
                  ? sensorsById[status.id.toString()]
                  : null;

                let valueChipOutput;
                if (sensor && isDefined(sensor.id)) {
                  valueChipOutput = (
                    <ValueChip
                      sensorId={sensor.id.toString()}
                      types={sensor.types || []}
                      updatedAt={
                        sensor.sensorStatus && sensor.sensorStatus.receivedAt
                      }
                    />
                  );
                } else {
                  valueChipOutput = global.NOT_AVAILABLE;
                }
                // if we got "—" then device status may have been flaky and we should have got some reading
                // we fall back on using data that came from device install list schema
                if (
                  valueChipOutput === '—' ||
                  valueChipOutput === global.NOT_AVAILABLE
                ) {
                  if (additionalDeviceInfo && additionalDeviceInfo[uuid]) {
                    return (
                      <UninstalledValueChip
                        uuid={uuid}
                        deviceInfo={additionalDeviceInfo[uuid]}
                        types={
                          additionalDeviceInfo[uuid].device_specific_status[
                            'com.energybox.DotStatus'
                          ].reading_types
                        }
                      />
                    );
                  }
                }

                return (
                  <>
                    {valueChipOutput}
                    {isThermostatSensorPage && status?.onlineState && (
                      <span className={styles.timestampStyle}>
                        <TimeDistance timestamp={status?.timestamp} />
                      </span>
                    )}
                  </>
                );
              },
            },
          ]),

      ...(!isThermostatSensorPage && !isThermostatCard
        ? [
            {
              header: isDotsCard ? 'Space/Equipment' : 'Location',
              cellContent: uuid => {
                if (!isDeviceInstalled(uuid)) return global.NOT_AVAILABLE;
                const sensor = Object.values(sensorsById).find(
                  s => s.uuid == uuid
                );
                const resourceType = sensor?.resource?.resourceType;
                return (
                  <>
                    {deviceStatusById[uuid] && deviceStatusById[uuid].id && (
                      <div>
                        <ResourcePath
                          resourceType={ResourceType.SPACE}
                          resourceId={Number(deviceStatusById[uuid].id)}
                          isNetworkGroupPage={isDotsCard ? true : false}
                        />
                      </div>
                    )}
                    <div>
                      {isDotsCard &&
                        (isDefined(sensor) &&
                        resourceType === ResourceType.EQUIPMENT ? (
                          <Link
                            to={`${Routes.EQUIPMENT}/${sensor?.resourceId}`}
                            target="_blank"
                          >
                            {sensor.resource?.title || global.NOT_AVAILABLE}
                          </Link>
                        ) : (
                          global.NOT_AVAILABLE
                        ))}
                    </div>
                  </>
                );
              },
            },
          ]
        : []),
      ...(isThermostatSensorPage == false && !isDotsCard
        ? [
            {
              header: 'Equipment',
              width: '20%',
              cellContent: uuid => {
                if (!isDeviceInstalled(uuid)) return global.NOT_AVAILABLE;
                const thermostat = thermostats.find(t => {
                  if (t.uuid === uuid) return t.equipment;
                });
                return (
                  <>
                    {isDefined(thermostat) ? (
                      <Link
                        to={`${Routes.EQUIPMENT}/${thermostat?.equipmentId}`}
                        target="_blank"
                      >
                        {thermostat.equipment.title || global.NOT_AVAILABLE}
                      </Link>
                    ) : (
                      global.NOT_AVAILABLE
                    )}
                  </>
                );
              },
            },
          ]
        : []),

      ...(isThermostatSensorPage === false
        ? [
            {
              // TODO FIND REAL PAIRING ICON
              header: 'Paired',
              cellContent: uuid => {
                return pairedSensors?.includes(String(uuid)) ||
                  sensorsPairedList.find(sen => sen.uuid === uuid) ? (
                  <ChainIcon size={12} />
                ) : (
                  <ChainIcon size={12} disabled />
                );
              },
            },
          ]
        : []),

      {
        header: 'Signal',
        cellContent: uuid =>
          (
            <SignalStatus
              vendor={deviceStatusById[uuid]?.vendor}
              sensorStatus={deviceStatusById[uuid]}
              showSignalStrength={true}
            />
          ) || global.NOT_AVAILABLE,
      },
      ...(isThermostatCard
        ? []
        : [
            {
              header: 'Power',
              cellContent: uuid => {
                return (
                  (!isEBThermostat(uuid) && (
                    <BatteryStatus
                      vendor={deviceStatusById[uuid]?.vendor}
                      sensorStatus={deviceStatusById[uuid]}
                      showValue={true}
                    />
                  )) ||
                  (deviceStatusById[uuid]?.onlineState &&
                    isEBThermostat(uuid) && <EthernetIcon size={16} />) ||
                  global.NOT_AVAILABLE
                );
              },
            },
          ]),
      ...(!isNetworkGroupPage
        ? [
            {
              header: '',
              cellContent: uuid =>
                !isThermostatSensorPage && (
                  <Button
                    disabled={isLoading}
                    variant="text"
                    onClick={() => onRemoveSensor(uuid)}
                  >
                    {' '}
                    Remove from list <MdExitToApp size={16} />
                  </Button>
                ),
            },
          ]
        : [
            {
              header: '',
              width: '10%',
              cellContent: uuid =>
                !isDeviceInstalled(uuid) ? (
                  <Button
                    disabled={isLoading}
                    variant="outlined"
                    onClick={() =>
                      openNewModal(uuid, isDotsCard ? 'sensor' : 'thermostat')
                    }
                    roundedCorners={true}
                    size="small"
                  >
                    {installButtonTitle()}
                  </Button>
                ) : (
                  ''
                ),
            },
          ]),
    ];

    const newCardColumns = [
      {
        header: 'Name/UUID',
        className: styles.boldHeader,
        width: '25%',
        cellContent: uuid => {
          const object = findObject(uuid);
          return (
            <div className={styles.sensorTitleComponent}>
              <span>
                {object ? (
                  <Link
                    to={`${Routes.DEVICES}${Routes.GATEWAYS}/${object.id}`}
                    target="_blank"
                  >
                    {object.title}
                  </Link>
                ) : (
                  global.NOT_AVAILABLE
                )}
              </span>
              <span>{uuid}</span>
            </div>
          );
        },
      },
      {
        header: 'Version',
        width: '15%',
        cellContent: uuid => {
          if (
            isSiteControllerCard &&
            additionalDeviceInfo &&
            additionalDeviceInfo[uuid]
          ) {
            const deviceInfo = additionalDeviceInfo[uuid];
            return deviceInfo ? deviceInfo.version : global.NOT_AVAILABLE;
          } else if (
            isEnergyproCard &&
            additionalDeviceInfo &&
            additionalDeviceInfo[uuid]
          ) {
            const deviceInfo = additionalDeviceInfo[uuid];
            return deviceInfo ? deviceInfo.version : global.NOT_AVAILABLE;
          } else {
            return global.NOT_AVAILABLE;
          }
        },
      },
      {
        header: 'Status',
        width: '15%',
        cellContent: uuid => {
          // TODO REMOVE THIS INFAVOR FOR A COMPONENT THAT DOESNT SUB AGAIN
          if (!isDeviceInstalled(uuid)) {
            if (
              isSiteControllerCard &&
              additionalDeviceInfo &&
              additionalDeviceInfo[uuid]
            ) {
              const deviceInfo = additionalDeviceInfo[uuid];
              const siteControllerStatus =
                deviceInfo.device_specific_status[
                  'com.energybox.SiteControllerStatus'
                ];
              return (
                <DeviceConnectionStatus
                  connectionStatus={siteControllerStatus.status.connected}
                />
              );
            } else if (
              isEnergyproCard &&
              additionalDeviceInfo &&
              additionalDeviceInfo[uuid]
            ) {
              const deviceInfo = additionalDeviceInfo[uuid];
              const energyProStatus =
                deviceInfo.device_specific_status[
                  'com.energybox.EnergyProStatus'
                ];
              return (
                <DeviceConnectionStatus
                  connectionStatus={energyProStatus.status.connected}
                />
              );
            } else {
              return global.NOT_AVAILABLE;
            }
          } else {
            return (
              <DeviceOnlineState
                displayType={DisplayType.STATUS_WITH_TEXT_AND_TIME}
                ianaTimeZoneCode={ianaTimeZoneCode}
                devices={[
                  {
                    id: uuid,
                    uuid,
                    vendor: 'energybox',
                  },
                ]}
              />
            );
          }
        },
      },
      {
        header: 'Space',
        width: '15%',
        cellContent: uuid => {
          const object = findObject(uuid);
          const spaceId = isSiteControllerCard
            ? (object as ControlBoard)?.spaceId!
            : (object as EnergyPro)?.distributionPanel?.spaceId!;
          const space = spaceId && spaces.spacesById?.[spaceId];
          return <>{space ? space?.title : global.NOT_AVAILABLE}</>;
        },
      },
      ...(isSiteControllerCard
        ? [
            {
              header: 'Light Sensor Reading',
              width: '20%',
              cellContent: uuid => {
                if (additionalDeviceInfo && additionalDeviceInfo[uuid]) {
                  const deviceInfo = additionalDeviceInfo[uuid];
                  const siteControllerStatus =
                    deviceInfo.device_specific_status[
                      'com.energybox.SiteControllerStatus'
                    ];

                  const { port1Lux, port2Lux } = siteControllerStatus;
                  return (
                    <div>
                      <div>Port 1: {port1Lux} lux</div>
                      <div>Port 2: {port2Lux} lux</div>
                    </div>
                  );
                }
              },
            },
            {
              header: '',
              cellContent: uuid =>
                !isDeviceInstalled(uuid) ? (
                  <Button
                    disabled={isLoading}
                    variant="outlined"
                    onClick={() => openNewModal(uuid, 'Site Controller')}
                    roundedCorners={true}
                    size="small"
                  >
                    {installButtonTitle()}
                  </Button>
                ) : (
                  ''
                ),
            },
          ]
        : [
            {
              header: 'Panel Name',
              cellContent: uuid => {
                const object = findObject(uuid);
                const panelId = (object as EnergyPro)?.distributionPanelId;
                return (
                  <>
                    {object ? (
                      <Link
                        to={`${Routes.DISTRIBUTION_PANELS}/${panelId}`}
                        target="_blank"
                      >
                        {(object as EnergyPro).distributionPanel?.title}
                      </Link>
                    ) : (
                      global.NOT_AVAILABLE
                    )}
                  </>
                );
              },
            },
          ]),
    ];

    return (
      <div>
        {!isThermostatSensorPage && !isNetworkGroupPage && (
          <FeatureFlag>
            <div className={styles.addSensorContainer}>
              <div className={styles.inputStyle}>
                <InputField
                  placeholder={'AA:BB:CC:DD:EE:FF'}
                  type="text"
                  value={addSensorUuid}
                  onChange={e =>
                    this.setState({ addSensorUuid: e.currentTarget.value })
                  }
                />
              </div>
              <div className={styles.buttonStyle}>
                <Button
                  onClick={() => {
                    this.props.onSensorAdd(addSensorUuid);
                    this.setState({ addSensorUuid: '' });
                  }}
                >
                  {' '}
                  Add Sensor{' '}
                </Button>
              </div>
            </div>
          </FeatureFlag>
        )}
        <Table
          columns={isSitecontrollerOrEnergypro ? newCardColumns : columns}
          data={list}
          highlightAlternateRows={isThermostatSensorPage || isNetworkGroupPage}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  deviceStatusById,
  resourcePaths,
  sensors: { sensorsById },
  subscribedThermostats,
  spaces,
  controlBoards: { editControlBoardById },
}: ApplicationState) => ({
  deviceStatusById,
  sensorsById,
  subscribedThermostats,
  spaces,
  editControlBoardById,
});

const mapDispatchToProps = {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
  getSensors,
  subscribeToDeviceReadings,
  unsubscribeFromDeviceReadings,
  getSpaces: id => getSpaces({ siteIds: [id] }),
};

export default connect(mapStateToProps, mapDispatchToProps)(WhitelistTable);
