import {
  TimeSeries,
  TSEnergyByEquipmentIdApiReturn,
  TSEnergyDashboardBySiteIdApiReturn,
  TSEquipmentPowerBySiteIdApiReturn,
  TSRecentPowerFromEquipmentApiReturn,
  TSSiteEnergyConsumptionApiReturn,
  ZippedTSEnergySeriesData,
  ZippedTSEquipmentGroupEnergyData,
  ZippedTSEquipmentPowerBySiteIdData,
  ZippedTSEquipmentTypeEnergyData,
  EnergyPowerApiResponse,
} from '@energybox/react-ui-library/dist/types';
import {
  getUnixTimestamp,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import { getTime } from 'date-fns';
import zipObj from 'ramda/src/zipObj';

export const formatDateForTimeSeriesApi = (date: Date) => {
  return date.toISOString().split('.')[0] + 'Z';
};

export const determineWhichEnergyApiFunctionToUse = (
  useNewTsdb: boolean,
  iamApiFunction: (...args: any[]) => any,
  tsApiFunction: (...args: any[]) => any
) => {
  if (useNewTsdb) {
    return tsApiFunction;
  }
  return iamApiFunction;
};

export const zipTSApiData = (data: any) => {
  if (!data) return [];
  return data.values.map((dataPoint: (string | number)[]) => {
    // zipObj(['a', 'b', 'c'], [1, 2, 3]) => {a: 1, b: 2, c: 3}
    return zipObj(data.columns, dataPoint);
  });
};

export const normalizeTSEnergyByEquipmentId = (
  data: TSEnergyByEquipmentIdApiReturn
) => {
  const zippedData = data && data.series ? zipTSApiData(data.series) : [];
  const totalPower = zippedData.reduce((total: number, { powerActive }) => {
    return (powerActive || 0) + total;
  }, 0);

  return {
    powerAverage: totalPower / data.consumption.samples,
    energyTotal: data.consumption.energy,
    readings: zippedData.map((data: ZippedTSEnergySeriesData) => ({
      //power returns in W, convert to kW
      power: data.powerActive !== null ? data.powerActive / 1000 : null,
      energy: data.energy,
      //TODO: unsure if records === samples, waiting on confirmation from Alex
      records: data.samples,
      //time returns in ISO 8601, convert to unix timestamp
      timestamp: getUnixTimestamp(data.time),
      //current is a new required field,
      //the old related equipment energy endpoint does not include current
      current: data.current,
    })),
  };
};

export const normalizeTSFetchRecentPowerByEquipment = (
  data: TSRecentPowerFromEquipmentApiReturn
) => {
  const zippedData = zipTSApiData(data)[0];
  return {
    power:
      zippedData && zippedData.powerActive !== null
        ? zippedData.powerActive / 1000
        : null,
    resourceId: zippedData.equipmentId,
  };
};

export const normalizeTSEnergyDashboardBySiteId = (
  data: TSEnergyDashboardBySiteIdApiReturn
) => {
  const zippedSeriesData = data.series ? zipTSApiData(data.series) : [];
  const zippedEquipmentGroupsData = zipTSApiData(
    data.consumptionByEquipmentGroups
  ).map((d: ZippedTSEquipmentGroupEnergyData) => ({
    id: d.equipmentGroupId,
    energyTotal: d.energy,
  }));
  const zippedEquipmentTypesData = zipTSApiData(
    data.consumptionByEquipmentTypes
  ).map((d: ZippedTSEquipmentTypeEnergyData) => ({
    id: d.equipmentTypeId,
    energyTotal: d.energy,
  }));

  const totalPower = zippedSeriesData.reduce(
    (total: number, { powerActive }) => {
      return (powerActive || 0) + total;
    },
    0
  );

  return {
    consumption: {
      energyTotal: data.consumption.energy,
      //we don't use powerAverage, but keeping it to be consistent with IAM return
      powerAverage: totalPower / data.consumption.samples,
      readings: zippedSeriesData.map((data: ZippedTSEnergySeriesData) => ({
        //power returns in W, convert to kW
        power: data.powerActive !== null ? data.powerActive / 1000 : null,
        energy: data.energy,
        //TODO: unsure if records === samples, waiting on confirmation from Alex
        records: data.samples,
        //time returns in ISO 8601, convert to unix timestamp
        timestamp: getUnixTimestamp(data.time),
      })),
    },
    consumptionByGroups: mapArrayToObject(zippedEquipmentGroupsData),
    consumptionByTypes: mapArrayToObject(zippedEquipmentTypesData),
  };
};

export const normalizeTSEquipmentPowerBySiteId = (
  data: TSEquipmentPowerBySiteIdApiReturn
) => {
  const zippedData = zipTSApiData(data);
  const processedData = zippedData.map(
    (d: ZippedTSEquipmentPowerBySiteIdData) => ({
      id: d.equipmentId,
      powerAverage: d.powerActive !== null ? d.powerActive / 1000 : null,
    })
  );

  return mapArrayToObject(processedData);
};

export const normalizeTSSiteEnergyConsumption = (
  data: TSSiteEnergyConsumptionApiReturn
) => {
  return {
    energyTotal: data.energy,
  };
};

export const getTimeSeriesMinMax = (
  timeSeries: TimeSeries[],
  comparisonKey: string
) => {
  let max = Number.MIN_SAFE_INTEGER;
  let min = Number.MAX_SAFE_INTEGER;
  let tsHasData = false;

  timeSeries.forEach(datapoint => {
    const value = datapoint[comparisonKey];
    if (typeof value !== 'number') return;
    tsHasData = true;
    if (value < min) min = value;
    if (value > max) max = value;
  });
  if (!tsHasData) return { min: undefined, max: undefined };
  return { min, max };
};

export const processedDataFromEnergyPowerApiResp = (
  data: EnergyPowerApiResponse
) => {
  const { readings, spilloverPoint } = data;
  const processedReadings = [...readings];
  if (spilloverPoint) processedReadings.push(spilloverPoint);

  return processedReadings.map(d => ({
    timestamp: d.timestamp * 1000,
    value: d.power,
    current: d.current,
  }));
};

export const processedDataFromZippedTSEnergySeriesData = (
  data: ZippedTSEnergySeriesData[]
) => {
  return data.map(d => {
    return {
      timestamp: getTime(new Date(d.time)),
      value: d.powerActive !== null ? d.powerActive / 1000 : null,
      current: d.current,
    };
  });
};
