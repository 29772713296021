import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEquipmentTypes,
  getEquipmentTypesByOrgId,
  getEquipmentTypesBySiteId,
} from '../actions/app';
import {
  EquipmentType,
  EquipmentTypesById,
} from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import { ApplicationState } from '../reducers';

//need to be removed when all eqType filters are independent on site/org ID
export const useEquipmentTypes = (groupId?: number) => {
  const dispatch = useDispatch();

  const equipmentTypes = useSelector(({ app }: ApplicationState) => {
    const allTypes = app.equipmentTypes?.sort(
      ({ title: aTitle }, { title: bTitle }) => aTitle.localeCompare(bTitle)
    );
    return groupId !== undefined
      ? allTypes?.filter(eq => eq.groupId === groupId)
      : allTypes;
  });

  useEffect(() => {
    if (!equipmentTypes) dispatch(getEquipmentTypes());
  }, [equipmentTypes]);

  return equipmentTypes || [];
};
export const useEquipmentTypesByOrgOrSiteId = (
  siteId?: number,
  orgId?: number
) => {
  const dispatch = useDispatch();

  const equipmentTypesByOrgOrSiteID = useSelector(
    ({ app }: ApplicationState) => {
      if (siteId) return app.equipmentTypesBySite;
      else if (orgId) return app.equipmentTypesByOrg;
      return app.equipmentTypes;
    }
  );

  useEffect(() => {
    if (!equipmentTypesByOrgOrSiteID) {
      if (siteId) {
        dispatch(getEquipmentTypesBySiteId(siteId));
      } else if (orgId) {
        dispatch(getEquipmentTypesByOrgId(orgId));
      } else {
        dispatch(getEquipmentTypes());
      }
    }
  }, [equipmentTypesByOrgOrSiteID]);

  return equipmentTypesByOrgOrSiteID || [];
};

export const useEquipmentTypesById = () => {
  const dispatch = useDispatch();

  const equipmentTypesById = useSelector(({ app }: ApplicationState) => {
    return app.equipmentTypesById;
  });

  useEffect(() => {
    if (!equipmentTypesById) dispatch(getEquipmentTypes());
  }, []);

  return equipmentTypesById || {};
};

export const useEquipmentTypesByIdWithinSite = (id?: number) => {
  const dispatch = useDispatch();

  const equipmentTypesByIdWithinSite = useSelector(
    ({ app }: ApplicationState) => {
      return app.equipmentTypesByIdWithinSite;
    }
  );

  useEffect(() => {
    if (id) dispatch(getEquipmentTypesBySiteId(id));
    else dispatch(getEquipmentTypes);
  }, [id]);

  return equipmentTypesByIdWithinSite || {};
};

export const useEquipmentTypesByIdWithinOrg = (id?: number) => {
  const dispatch = useDispatch();

  const equipmentTypesByIdWithinOrg = useSelector(
    ({ app }: ApplicationState) => {
      return app.equipmentTypesByIdWithinOrg;
    }
  );

  useEffect(() => {
    if (!equipmentTypesByIdWithinOrg) dispatch(getEquipmentTypesByOrgId(id!));
  }, []);

  return equipmentTypesByIdWithinOrg || {};
};

export default useEquipmentTypes;
