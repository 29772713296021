import { connect } from 'react-redux';
import Modals from '../../components/Modals';
import { closeModal } from '../../actions/modal';
import { ApplicationState } from '../../reducers';

const mapStateToProps = (state: ApplicationState) => ({
  modal: state.modal,
});

const mapDispatchToProps = {
  onClose: closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modals);
