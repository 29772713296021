import {
  ActuatorCircuitActivityChart,
  DateFilter,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import {
  Actuator,
  ControlsTypeEntities,
  Equipment,
  TimetableControlTypes,
} from '@energybox/react-ui-library/dist/types';
import { useGraphTouchEvents } from '@energybox/react-ui-library/dist/hooks';
import { DateTime } from 'luxon';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActuatorCircuitActivityById } from '../../actions/controls';
import { getEquipment } from '../../actions/equipment';
import {
  useLightReadingByControlBoardId,
  useLightReadingBySchedulerControlId,
} from '../../hooks/controls/scheduler';
import useGetLocalOverrideActivity from '../../hooks/useGetLocalOverrideActivity';
import useGetSchedulerByEquipmentId from '../../hooks/useGetSchedulerByEquipmentId';
import useGetSystemOverrideActivity from '../../hooks/useGetSystemOverrideActivity';
import { useGetSite } from '../../hooks/useSites';
import { ApplicationState } from '../../reducers';
import { useTimetableEventsByEquipmentId } from '../../hooks/useTimetableEventsByEquipmentId';
import useChartDetails, {
  ChartDetailOptions,
} from '../../hooks/useChartDetails';
import { useAppLocale } from '../../hooks/useAppDetails';
import styles from './ActuatorCircuitActivityChartContainer.module.css';

interface Props {
  siteId: number | string;
  actuator: Actuator;
  equipment: Equipment;
  timezone: string;
  showLightSensorDetails: boolean;
}

const ActuatorCircuitActivityChartContainer: React.FC<Props> = ({
  siteId,
  actuator,
  equipment,
  timezone,
  showLightSensorDetails,
}) => {
  const chartDetailOptions: ChartDetailOptions = useMemo(() => {
    return {
      useCurrentTime: true,
      timezone,
    };
  }, [timezone]);

  const locale = useAppLocale();
  const {
    fromDate,
    toDate,
    refAreaStart,
    setRefAreaStart,
    refAreaEnd,
    setRefAreaEnd,
    zoomIn,
    dateFilterFromDate,
    dateFilterToDate,
    dateFilterTitle,
    setDateFilter,
    zoomOut,
    isZoomedIn,
    xTicks,
    isMonthly,
    isCustomRange,
  } = useChartDetails(locale, chartDetailOptions);
  const fromDateMs = DateTime.fromISO(fromDate).toMillis();
  const toDateMs = DateTime.fromISO(toDate).toMillis();
  const dateFilterFromDateJS = DateTime.fromISO(dateFilterFromDate).toJSDate();
  const dateFilterToDateJS = DateTime.fromISO(dateFilterToDate).toJSDate();

  // *********** useDispatch ************ //
  const dispatch = useDispatch();
  const getActuatorCircuitActivity = useGetActuatorCircuitActivity(
    dispatch,
    actuator.id,
    fromDateMs,
    toDateMs
  );

  const equipmentFetched = useSelector(
    ({ equipment: equipmentState }: ApplicationState) =>
      equipmentState.equipmentById[equipment.id]
  );
  const systemOverrideActivityStatus = useGetSystemOverrideActivity(
    equipment.activeControl?._entity === ControlsTypeEntities.SCHEDULER
      ? equipment.activeControl?.id
      : equipmentFetched?.activeControl?._entity ===
        ControlsTypeEntities.SCHEDULER
      ? equipmentFetched.activeControl?.id
      : undefined,
    fromDateMs,
    toDateMs
  );

  const site = useGetSite(siteId);

  const localOverrideActivityStatus = useGetLocalOverrideActivity(
    actuator.controlBoardId,
    fromDateMs,
    toDateMs
  );

  const schedulerDataStatus = useGetSchedulerByEquipmentId(equipment.id);
  const lightThresholdTimeSeriesStatus = useLightReadingBySchedulerControlId(
    showLightSensorDetails ? schedulerDataStatus?.data?.id : null,
    fromDateMs,
    toDateMs
  );
  const lightReadingTimeSeriesStatus = useLightReadingByControlBoardId(
    showLightSensorDetails
      ? schedulerDataStatus?.data?.lightSensorSettings?.controlBoardId
      : null,
    fromDate,
    toDate
  );

  const lightSensorEvents = useTimetableEventsByEquipmentId(
    showLightSensorDetails ? equipment.id : null,
    site?.timeZone,
    fromDateMs,
    toDateMs,
    TimetableControlTypes.LIGHT_SENSOR
  );
  const schedulerEvents = useTimetableEventsByEquipmentId(
    showLightSensorDetails ? equipment.id : null,
    site?.timeZone,
    fromDateMs,
    toDateMs,
    TimetableControlTypes.SCHEDULER
  );

  const graphTouchEvents = useGraphTouchEvents(
    true,
    setRefAreaStart,
    setRefAreaEnd,
    zoomIn
  );

  // *********** useEffect ************ //
  useEffect(() => {
    getActuatorCircuitActivity();
  }, [getActuatorCircuitActivity]);

  useEffect(() => {
    dispatch(getEquipment(String(equipment.id)));
  }, [equipment.id]);

  // *********** useSelector ************ //
  const actuatorCircuitActivityStatus = useSelector(
    ({ controls }: ApplicationState) => {
      return controls.actuatorCircuitActivityById[actuator.id] || {};
    }
  );

  return (
    <div className={styles.root}>
      <div className={styles.chartFilter}>
        <DateFilter
          value={{
            title: dateFilterTitle,
            toDate: dateFilterToDateJS,
            fromDate: dateFilterFromDateJS,
            isMonthly,
            isCustomRange,
          }}
          alignItemsRight
          dateFormat="datetime"
          customPickerVariant="datetime"
          setFilter={setDateFilter}
          ianaTimeZoneCode={timezone}
          displayDatesAsTitle
          locale={locale}
        />
        <div className={styles.zoomResetContainer}>
          <ResetButton
            customText="Reset Zoom"
            displayInfoTooltip
            onClick={zoomOut}
            disabled={!isZoomedIn}
          />
        </div>
      </div>
      <ActuatorCircuitActivityChart
        syncId={`${actuator.id}`}
        site={site}
        actuator={actuator}
        actuatorCircuitActivityStatus={actuatorCircuitActivityStatus}
        localOverrideActivityStatus={localOverrideActivityStatus}
        systemOverrideActivityStatus={systemOverrideActivityStatus}
        schedulerDataStatus={schedulerDataStatus}
        lightReadingTimeSeriesStatus={lightReadingTimeSeriesStatus}
        lightThresholdTimeSeriesStatus={lightThresholdTimeSeriesStatus}
        lightSensorEvents={lightSensorEvents}
        schedulerEvents={schedulerEvents}
        locale={locale}
        fromDate={fromDateMs}
        toDate={toDateMs}
        ticks={xTicks}
        refAreaStart={refAreaStart}
        refAreaEnd={refAreaEnd}
        startZoom={graphTouchEvents.startZoom}
        onTouchStart={graphTouchEvents.onTouchStart}
        onTouchMove={graphTouchEvents.onTouchMove}
        onTouchEnd={graphTouchEvents.onTouchEnd}
        zoomIn={zoomIn}
        updateRefAreaStart={setRefAreaStart}
        updateRefAreaEnd={setRefAreaEnd}
        showLightSensorDetails={showLightSensorDetails}
      />
    </div>
  );
};

export const useGetActuatorCircuitActivity = (
  dispatch,
  actuatorId: number,
  fromDate: number,
  toDate: number
) => {
  return useCallback(() => {
    dispatch(getActuatorCircuitActivityById(actuatorId, fromDate, toDate));
  }, [dispatch, actuatorId, fromDate, toDate]);
};

export default ActuatorCircuitActivityChartContainer;
