import {
  ExtraShortSkeletonCell,
  ShortMediumSkeletonCell,
} from '@energybox/react-ui-library/dist/components';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import { SiteController } from '@energybox/react-ui-library/dist/icons';
import {
  ActuatorCircuitStatus,
  ActuatorPortTypeText,
  FirmwareGatewayModel,
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionStatus,
  LightSensorPortText,
  OpacityIndex,
  SortDirection,
  Vendor,
  WorkingModeLabel,
  InspectionJumpToRef,
  OpenFirmwareUpdateModalFunc,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  countDecimals,
  genericTableSort,
  global,
  isDefined,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  doesItemContainErrorOrWarning,
  getDetailFields,
  getId,
  getSensorsOrActuatorsSummaryFields,
  getSummaryFields,
  getTitle,
  isVersionRequirementFulfilled,
  transformDhcpStatusValue,
} from '@energybox/react-ui-library/dist/utils/inspection';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../routes';
import { formatDecimalValue } from '../../../../utils/numbers';
import {
  circuitStatusVisual,
  renderOutputStatusByState,
} from '../../../Gateways/GatewayDetailPages/ShowControlBoardPage/ShowControlBoardPage';
import InfoIconTooltip from '../InfoIconTooltip';
import InspectionTile, {
  getErrorOrWarningIconForField,
  InspectionHeader,
} from '../InspectionTile';
import tileStyles from '../InspectionTile/InspectionTile.module.css';
import TimestampTooltip from '../TimestampTooltip';
import styles from './SiteControllerTile.module.css';

type Props = {
  siteId: string;
  canUpdateFirmware: boolean;
  data: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  onFirmwareVersionLinkClick: OpenFirmwareUpdateModalFunc;
  jumpToRef: InspectionJumpToRef;
};

const SiteControllerTile: React.FC<Props> = ({
  siteId,
  canUpdateFirmware,
  data,
  detailLevel,
  onFirmwareVersionLinkClick,
  jumpToRef,
}) => {
  const detailsPageLink = `${Routes.DEVICES}${Routes.GATEWAYS}/${getId(data)}`;
  const [isLocalOverrideActive, setIsLocalOverrideActive] = useState(false);
  useEffect(() => {
    const isActive: boolean =
      (data.override_switch as InspectionDataField)?.field === true;
    setIsLocalOverrideActive(isActive);
  }, [data.override_switch]);

  const lightSensorRange = data.light_sensor_range?.['field'];
  const lightSensorRangeValue =
    lightSensorRange !== null ? lightSensorRange : global.NOT_AVAILABLE;

  const fields = [
    {
      name: 'IP Address',
      key: 'IP',
    },
    {
      name: 'Local Override',
      key: 'override_switch',
      transformValue: value => (value ? 'ON' : 'OFF'),
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
      link: detailsPageLink,
    },
    {
      name: 'Relay Release Timing',
    },
    {
      name: 'Firmware Version',
      key: 'firmware_version',
      onClick: canUpdateFirmware
        ? () =>
            onFirmwareVersionLinkClick(
              {
                id: ((data.id as InspectionDataField)?.field as number) || -1,
                vendor:
                  ((data.vendor as InspectionDataField)?.field as Vendor) || '',
              },
              FirmwareGatewayModel.ENERGYBOX_CB
            )
        : undefined,
    },
    {
      name: 'DHCP Status',
      key: 'ip_setting',
      transformValue: transformDhcpStatusValue,
    },
    {
      name: 'MQTT Broker',
      key: 'mqtt_broker',
      description: !isVersionRequirementFulfilled(
        (data.firmware_version as InspectionDataField)?.field as string,
        '1.1.213'
      ) ? (
        <InfoIconTooltip content="MQTT broker information only available from FW v1.1.213 onwards" />
      ) : (
        undefined
      ),
    },
    {
      name: 'Default Gateway',
      key: 'default_gateway',
    },
    {
      isHr: true,
    },
    {
      name: 'Subnet Mask',
      key: 'subnet_mask',
    },
    {
      name: 'Light Sensor Config',
      key: 'light_sensor_port',
      link: detailsPageLink,
      transformValue: value =>
        LightSensorPortText[value] || value || global.NOT_AVAILABLE,
      description: `( ${lightSensorRangeValue} )`,
    },
    {
      name: 'DNS 1',
      key: 'DNS_1',
    },
    {
      name: 'Light Sensor Reading',
      key: 'light_sensor_reading',
      transformValue: value => {
        let formattedValue;
        if (isNaN(value) || !isDefined(value)) {
          formattedValue = value;
        } else {
          const valueFloat = parseFloat(value);
          if (countDecimals(valueFloat) > 2) {
            formattedValue = formatDecimalValue(valueFloat, 2, 'lux');
          } else {
            formattedValue = `${value} lux`;
          }
        }
        return (
          <TimestampTooltip
            timestamp={
              (data.reading_timestamp as InspectionDataField)?.field as number
            }
            siteId={siteId}
          >
            {formattedValue}
          </TimestampTooltip>
        );
      },
    },
    {
      name: 'DNS 2',
      key: 'DNS_2',
    },
    {
      name: 'Interval',
      key: 'light_sensor_interval',
      transformValue: value => `${value} min`,
    },
  ];

  const subtitle = getTitle(data);
  const actuators = (data.relays as InspectionDataFieldsByKey[]) || [];
  const siteControllerSummaryFields = getSummaryFields(data, detailsPageLink);
  const actuatorsSummaryFields = getSensorsOrActuatorsSummaryFields(actuators);
  const actuatorsColumns = [
    {
      header: 'Port',
      width: '5%',
      defaultSortDirection: SortDirection.ASC,
      isDefaultSort: true,
      cellContent: ({ port_number }) => (
        <div className={tileStyles.tableFieldValue}>
          {port_number?.field || global.NOT_AVAILABLE}
          {getErrorOrWarningIconForField(port_number)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'port_number',
          'field',
        ]);
      },
    },
    {
      header: 'Relay Name',
      width: '10%',
      defaultSortDirection: SortDirection.ASC,
      cellContent: ({ title }) => (
        <div className={tileStyles.tableFieldValue}>
          {title?.field || global.NOT_AVAILABLE}
          {getErrorOrWarningIconForField(title)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
          'field',
        ]);
      },
    },
    {
      header: 'Equipment',
      width: '10%',
      cellContent: ({ equipment_title }) => (
        <div className={tileStyles.tableFieldValue}>
          {equipment_title?.field || global.NOT_AVAILABLE}
          {getErrorOrWarningIconForField(equipment_title)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: 'Type',
      width: '5%',
      defaultSortDirection: SortDirection.ASC,
      cellContent: ({ port_type, equipment_id }) => {
        const equipmentId = equipment_id?.field;
        const value =
          ActuatorPortTypeText[port_type?.field] || global.NOT_AVAILABLE;
        return (
          <div className={tileStyles.tableFieldValue}>
            {equipmentId ? (
              <Link to={`${Routes.EQUIPMENT}/${equipmentId}`} target="_blank">
                {value}
              </Link>
            ) : (
              value
            )}
            {getErrorOrWarningIconForField(port_type)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: InspectionDataFieldsByKey,
        b: InspectionDataFieldsByKey,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'port_type',
          'field',
        ]);
      },
    },
    {
      header: 'Output Status',
      width: '10%',
      align: 'center',
      cellContent: ({ output_status }) => (
        <div
          className={classNames(
            tileStyles.tableFieldValue,
            tileStyles.centeredTableFieldValue
          )}
        >
          {renderOutputStatusByState(
            output_status?.field,
            isLocalOverrideActive
          )}
          {getErrorOrWarningIconForField(output_status)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: 'Circuit Status',
      width: '10%',
      align: 'center',
      cellStyle: {
        padding: '0.25rem',
      },
      cellContent: ({ circuit_status, reading_timestamp }) => (
        <div
          className={classNames(
            !circuit_status ? styles.circuitStatusNotAvailable : '',
            tileStyles.tableFieldValue,
            tileStyles.centeredTableFieldValue
          )}
        >
          <TimestampTooltip
            timestamp={reading_timestamp?.field}
            siteId={siteId}
          >
            {circuit_status?.field === true || circuit_status?.field === false
              ? circuitStatusVisual(
                  circuit_status.field
                    ? ActuatorCircuitStatus.ON
                    : ActuatorCircuitStatus.OFF,
                  isLocalOverrideActive
                )
              : global.NOT_AVAILABLE}
          </TimestampTooltip>
          {getErrorOrWarningIconForField(circuit_status)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: 'Control Mode',
      width: '10%',
      cellContent: ({ control_mode }) => (
        <div className={tileStyles.tableFieldValue}>
          <span
            className={
              isLocalOverrideActive ? styles.localOverrideActiveText : ''
            }
          >
            {renderControlMode(control_mode?.field)}
          </span>
          {getErrorOrWarningIconForField(control_mode)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
    },
  ] as Columns<any>[];
  const tableData = actuators.filter(
    item =>
      item &&
      (detailLevel === InspectionDetailLevel.ALL ||
        doesItemContainErrorOrWarning(item))
  );
  const shouldHideTable =
    detailLevel === InspectionDetailLevel.ISSUES && !tableData.length;
  if (
    detailLevel === InspectionDetailLevel.ISSUES &&
    siteControllerSummaryFields.status === InspectionStatus.GOOD &&
    (!actuatorsSummaryFields.status ||
      actuatorsSummaryFields.status === InspectionStatus.GOOD)
  ) {
    return null;
  }

  return (
    <InspectionTile
      title={InspectionComponentName.SITE_CONTROLLER}
      titleIcon={<SiteController variant="small" size="20" />}
      subtitle={
        !!subtitle && (
          <Link to={detailsPageLink} target="_blank">
            {subtitle}
          </Link>
        )
      }
      summaryFields={siteControllerSummaryFields}
      detailFields={getDetailFields(fields, data, detailLevel)}
      detailTableHeader={
        !shouldHideTable && (
          <InspectionHeader
            title={InspectionComponentName.RELAYS}
            summaryFields={actuatorsSummaryFields}
          />
        )
      }
      showDetailTableHeaderWhenCollapsed
      detailTable={
        shouldHideTable
          ? undefined
          : {
              dataIsLoading: false,
              columns: actuatorsColumns,
              data: tableData,
            }
      }
      noTableDataMessage="No Relays Configured"
      jumpToRef={jumpToRef}
    />
  );
};

const renderControlMode = (controlMode: string | undefined) => {
  if (!isDefined(controlMode)) return '';

  switch (controlMode) {
    /* Change the control_mode.field text here to be consistent with other parts of the platforms */
    case 'Normal':
      return WorkingModeLabel.NORMAL;

    case undefined:
    case null:
      return global.NOT_AVAILABLE;

    default:
      return controlMode;
  }
};

export default SiteControllerTile;
