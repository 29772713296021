import { InputField } from '@energybox/react-ui-library/dist/components';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './TextField.module.css';

type Props = {
  type?: string;
  containerClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string | number;
  label?: string;
  fullWidth?: boolean;
  error?: boolean;
  onChange?: any;
  onFocus?: any;
  autoFocus?: boolean;
} & React.HTMLProps<HTMLInputElement>;

const TextField = ({
  type = 'text',
  containerClassName,
  label,
  fullWidth,
  value,
  placeholder = '',
  disabled = false,
  error = false,
  autoFocus = false,
  ...rest
}: Props) => (
  <div
    className={classNames(
      fullWidth ? styles.fullWidth : null,
      containerClassName || ''
    )}
  >
    {label && <span className={styles.label}> {label}</span>}
    <InputField
      type={type}
      placeholder={placeholder}
      value={
        typeof value === 'number' && value !== 0 ? value.toString() : value
      }
      disabled={disabled}
      error={error}
      autoFocus={autoFocus}
      {...rest}
    />
  </div>
);

export default TextField;
