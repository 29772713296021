import {
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import countryNames from '../../utils/countryNames';

interface Props {
  onSelect: (country: string) => void;
  value?: string | null;
  error: boolean;
  customErrorText?: string;
}

type Country = {
  name: string;
  code: string;
};

interface State {
  query: string;
  countries: Country[];
}

class CountrySelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
      countries: countryNames(),
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      query: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ query: '' });
  }

  render() {
    const { onSelect, value, error, customErrorText } = this.props;
    const { countries, query } = this.state;

    const filteredCountries = countries.filter((country: Country) => {
      return hasSubstr(country.name, query) || hasSubstr(country.code, query);
    });

    const selectedCountry =
      (countries.find(({ code }) => code === value) || {}).name || value;

    return (
      <Select
        onClick={this.resetSearch.bind(this)}
        title={selectedCountry}
        value={selectedCountry}
        variant={'select'}
        error={error}
        customErrorText={customErrorText}
      >
        <SelectSearch
          error={filteredCountries.length === 0}
          onChange={this.searchChange.bind(this)}
          value={query}
        />

        {filteredCountries.map((country: Country) => (
          <SelectItem
            key={country.name}
            isSelected={country.code === value}
            onSelect={() => {
              onSelect(country.code);
            }}
          >
            {country.name}
          </SelectItem>
        ))}
      </Select>
    );
  }
}

export default CountrySelect;
