import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import {
  Gateway,
  GatewayVendorLabel,
  Vendor,
  FirmwareGatewayModel,
  Site,
  CurrentUser,
  DeviceType,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys, classNames } from '@energybox/react-ui-library/dist/utils';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as GatewayActions,
  clearGatewayBuffer as clearBuffer,
  destroy,
  displayFormErrors,
  patch,
  reboot,
  reset,
  testBuzzer,
  updateField,
} from '../../../../actions/gateways';
import { getSiteByResourceId } from '../../../../actions/sites';
import EditHubForm from '../../../../components/EditHubForm';
import ConfigurationCard from '../../../../components/GatewayCommonCards/ConfigurationCard';
import ConnectionCard from '../../../../components/GatewayCommonCards/ConnectionCard';
import HardwareCard from '../../../../components/GatewayCommonCards/HardwareCard';
import ShowDetailPageHeader from '../../../../components/ShowDetailPageHeader';
import UpdateFirmwareModal from '../../../../components/UpdateFirmwareModal';
import { GATEWAY_SIREN_WHITELIST } from '../../../../featureFlagSettings';
import { ApplicationState } from '../../../../reducers';
import { EditGateway } from '../../../../reducers/gateways';
import { renderAPIerror } from '../../../../utils/apiErrorFeedback';
import FeatureFlag from '../../../FeatureFlag';
import FirmwareFlag from '../../../FirmwareFlag';
import ResourceFullPath from '../../../ResourceFullPath';
import GatewaySensors from '../../GatewaySensors';
import GatewaySirenActions from '../../GatewaySirenActions';
import styles from './ShowGatewayPage.module.css';
import { UserPlatformAccess } from '../../../../types/user';
import { determineUserRoleInPlatform } from '../../../../utils/user';

interface OwnProps {
  gateway: Gateway;
}

interface Props extends OwnProps {
  load: () => void;
  onChange: (field: string, value: number) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  testBuzzer: () => void;
  reboot: () => void;
  clearBuffer: () => void;
  editGateway?: EditGateway;
  siteId?: number;
  site?: Site;
  displayFormErrors: () => void;
  currentUser: CurrentUser | undefined;
}

interface State {
  showDeletePrompt: boolean;
  showUpdateFirmwareModal: boolean;
  isLatestFirmware: boolean;
}

class ShowGatewayPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeletePrompt: false,
      showUpdateFirmwareModal: false,
      isLatestFirmware: true,
    };
  }

  handleOpenDeletePrompt = () => {
    this.setState({ showDeletePrompt: true });
  };

  handleCloseDeletePrompt = () => {
    this.setState({ showDeletePrompt: false });
  };

  handleOpenUpdateFirmwareModal = () => {
    this.setState({ showUpdateFirmwareModal: true });
  };

  handleCloseUpdateFirmwareModal = () => {
    this.setState({ showUpdateFirmwareModal: false });
  };

  updateIsLatestFirmware = (isLatestFirmware: boolean) => {
    this.setState({ isLatestFirmware });
  };

  componentDidMount() {
    this.props.load();
  }

  deletePrompt() {
    const { gateway, onDelete, editGateway } = this.props;

    const actions = (
      <>
        <Button
          variant="text"
          onClick={this.handleCloseDeletePrompt.bind(this)}
        >
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal
        onClose={this.handleCloseDeletePrompt.bind(this)}
        actions={actions}
      >
        <p className={styles.textAlignCenter}>
          Are you sure you want to delete{' '}
          {gateway ? <b>{gateway.title}</b> : 'this gateway'}?
        </p>
        {editGateway &&
          renderAPIerror(
            editGateway.apiError,
            GatewayActions.DELETE_GATEWAY_ERROR
          )}
      </Modal>
    );
  }

  onSave = () => {
    const { patch, editGateway, displayFormErrors } = this.props;
    if (editGateway && hasKeys(editGateway.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  render() {
    const {
      gateway,
      editGateway,
      siteId,
      onChange,
      onCancel,
      site,
      currentUser,
    } = this.props;

    const {
      showDeletePrompt,
      showUpdateFirmwareModal,
      isLatestFirmware,
    } = this.state;
    if (!editGateway) return <div>Loading...</div>;
    const firmwareGatewayModel =
      gateway.vendor === Vendor.MONNIT
        ? FirmwareGatewayModel.GHUB
        : FirmwareGatewayModel.ENERGYBOX_HUB;

    const userPlatformAccess = determineUserRoleInPlatform(
      currentUser,
      site?.id,
      site?.organizationId
    );

    const listOptions = [
      {
        onSelect: this.handleOpenUpdateFirmwareModal,
        title: 'Update Firmware',
        access: [
          UserPlatformAccess.GLOBAL_ADMIN,
          UserPlatformAccess.ORG_ADMIN,
          UserPlatformAccess.INSTALLER,
        ],
        type: [GatewayVendorLabel.energybox.toString()],
        nonSuperHubOnly: true,
        icon: isLatestFirmware ? null : <WarningIcon size={16} />,
      },
      {
        onSelect: this.props.reboot,
        title: 'Reboot',
        type: [GatewayVendorLabel.energybox.toString()],
        nonSuperHubOnly: true,
      },
      {
        onSelect: this.props.clearBuffer,
        title: 'Clear Buffer',
        type: [GatewayVendorLabel.energybox.toString()],
        nonSuperHubOnly: true,
      },
      {
        onSelect: this.props.testBuzzer,
        title: 'Test Gateway Buzzer',
        type: [GatewayVendorLabel.energybox.toString()],
      },
      {
        onSelect: this.handleOpenDeletePrompt,
        title: 'Delete Gateway',
        isDeleteItem: true,
        type: [''],
      },
    ];

    return (
      <>
        {gateway && (
          <UpdateFirmwareModal
            device={gateway}
            onClose={this.handleCloseUpdateFirmwareModal}
            isVisible={showUpdateFirmwareModal}
            firmwareGatewayModel={firmwareGatewayModel}
          />
        )}

        {gateway && (
          <ShowDetailPageHeader
            name={gateway.title}
            description={<ResourceFullPath resourceId={gateway.id} />}
            resourceName="Gateway"
            siteId={siteId}
            listOptions={listOptions.filter(
              option =>
                (option.type[0] === '' ||
                  option.type.indexOf(GatewayVendorLabel[gateway.vendor]) >
                    -1) &&
                (option.access === undefined ||
                  option.access.includes(userPlatformAccess)) &&
                !(
                  option.nonSuperHubOnly &&
                  gateway.model === DeviceType.EB_SUPER_HUB
                )
              // Do not show option if it is SuperHub device type
            )}
          />
        )}

        <div
          className={classNames(
            styles.paddingBetweenCards,
            styles.mainCardTopPadding
          )}
        >
          {editGateway && (
            <>
              <Card>
                <CardContent>
                  <div className={styles.mainCardGrid}>
                    <CardTitle className={styles.mainCardTitle}>
                      General Information
                    </CardTitle>
                    <div>
                      {siteId && (
                        <EditHubForm
                          {...editGateway.fields}
                          isChanged={editGateway.isChanged}
                          formErrors={editGateway.formErrors}
                          formErrorsVisible={editGateway.formErrorsVisible}
                          onChange={onChange}
                          siteId={siteId}
                          lockSiteId={
                            editGateway.fields.model === DeviceType.EB_SUPER_HUB
                              ? siteId
                              : undefined
                          }
                          firmwareVersion={
                            gateway.gatewayInfo
                              ? gateway.gatewayInfo.firmwareVersion
                              : undefined
                          }
                          setIsLatestFirmware={this.updateIsLatestFirmware}
                        />
                      )}
                      {renderAPIerror(
                        editGateway.apiError,
                        GatewayActions.PATCH_GATEWAY_ERROR
                      )}
                    </div>
                  </div>
                </CardContent>

                {editGateway.isChanged && (
                  <CardActions>
                    <Button
                      variant="text"
                      onClick={onCancel}
                      children="Cancel"
                    />

                    <Button
                      disabled={editGateway.isLoading}
                      onClick={this.onSave}
                    >
                      {editGateway.isLoading ? (
                        <Loader size={16} variant="secondary" />
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </CardActions>
                )}
              </Card>
            </>
          )}
        </div>

        <div className={styles.paddingBetweenCards}>
          {gateway && gateway.vendor === 'energybox' && (
            <GatewaySensors
              ianaTimeZoneCode={site?.timeZone}
              gateway={gateway}
            />
          )}
        </div>

        {/* {gateway && (
          <div className={styles.paddingBetweenCards}>
            <GatewayConfig gateway={gateway} />
          </div>
        )} */}
        {gateway && gateway.vendor === 'energybox' && (
          <FeatureFlag orgWhiteList={GATEWAY_SIREN_WHITELIST}>
            <FirmwareFlag
              firmwareVersion={
                gateway.gatewayInfo
                  ? gateway.gatewayInfo.firmwareVersion
                  : 'n/a'
              }
              operator={'>='}
              supportedFirmwareVersion={'1.1.479'}
            >
              <div className={styles.paddingBetweenCards}>
                <GatewaySirenActions gateway={gateway} />
              </div>
            </FirmwareFlag>
          </FeatureFlag>
        )}

        <div className={styles.paddingBetweenCards}>
          <ConnectionCard gateway={gateway} ianaTimeZoneCode={site?.timeZone} />
        </div>

        {gateway.model !== DeviceType.EB_SUPER_HUB && (
          <>
            <div className={styles.paddingBetweenCards}>
              <HardwareCard
                gateway={gateway}
                ianaTimeZoneCode={site?.timeZone}
              />
            </div>

            <div className={styles.paddingBetweenCards}>
              <ConfigurationCard gateway={gateway} />
            </div>
          </>
        )}

        {showDeletePrompt && this.deletePrompt()}
      </>
    );
  }
}

const mapStateToProps = (
  { app, gateways, sites }: ApplicationState,
  { gateway }: OwnProps
) => {
  const siteId = sites.resourceIdToSiteId[gateway.id];
  return {
    editGateway: gateways.editById[gateway.id],
    siteId,
    site: sites.sitesById[siteId],
    currentUser: app.currentUser,
  };
};

const mapDispatchToProps = (dispatch: any, { gateway }: OwnProps) => {
  const id = String(gateway.id);
  return {
    load: () => {
      dispatch(getSiteByResourceId(id));
    },
    // getPath: () => dispatch( getPath(id) ),
    onChange: (f: string, v: string | number) =>
      dispatch(updateField(id, f, v)),
    patch: () => {
      dispatch(patch(id));
    },
    onDelete: () => {
      dispatch(destroy(id));
    },
    onCancel: () => dispatch(reset(id)),
    // Gateway Commands,
    testBuzzer: () => dispatch(testBuzzer(id)),
    reboot: () => dispatch(reboot(id)),
    clearBuffer: () => dispatch(clearBuffer(id)),
    displayFormErrors: () => dispatch(displayFormErrors(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowGatewayPage);
