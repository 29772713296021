import { EnergyboxService, getServiceUrl } from '../config';
import store from '../store';
import { Actions as AppActions } from '../actions/app';

const publicPaths = ['/api/v1/auth/login'];

const requiresAuthorization = (path: string): boolean => {
  return publicPaths.indexOf(path) === -1;
};

export type FetchArgument = {
  endpoint: string;
  method?: string;
  payload?: any;
  service?: EnergyboxService;
  sendingFormData?: boolean;
};

export const genHeaders = (store, action, serviceUrl) => {
  const { accessToken } = store.getState().app;
  const { path, ignoreContentType } = action;

  const headers = ignoreContentType
    ? {}
    : {
        'Content-Type': 'application/json',
      };

  if (requiresAuthorization(path) && accessToken && accessToken !== '') {
    headers['authorization'] = `Bearer ${accessToken}`;
  }

  if (action.headers) {
    Object.keys(action.headers).forEach(key => {
      headers[key] = action.headers[key];
    });
  }

  if (
    serviceUrl == getServiceUrl(EnergyboxService.timeSeries) ||
    serviceUrl === getServiceUrl(EnergyboxService.iam)
  ) {
    headers['client'] = 'connect-front-end';
  }

  return headers as Headers;
};

export async function fetchApi({
  endpoint = '',
  method = 'GET',
  payload = {},
  service,
  sendingFormData,
}: FetchArgument): Promise<any> {
  const serviceUrl = getServiceUrl(service);
  const fullUrl = `${serviceUrl}${endpoint}`;
  const headers = genHeaders(
    store,
    sendingFormData
      ? {
          ignoreContentType: true,
        }
      : '',
    serviceUrl
  );

  const body = Object.keys(payload).length
    ? JSON.stringify(payload)
    : undefined;

  try {
    const response = await fetch(fullUrl, {
      method,
      headers,
      body: sendingFormData ? payload : body,
    });

    if (response.status === 401) {
      store.dispatch({ type: AppActions.LOGOUT });
      return Promise.reject(Error('Unauthorized'));
    }

    if (response.status === 403) {
      return Promise.reject(new Error('Forbidden'));
    }

    if (response.status === 503) {
      return Promise.reject(new Error('Server busy'));
    }

    if (!response.ok) {
      return Promise.reject(new Error(`API request failed ${response.status}`));
    }

    if (response.status === 204) {
      return Promise.resolve();
    }

    const responseContentType = response.headers.get('content-type');

    if (responseContentType) {
      if (
        responseContentType.startsWith('application/json') ||
        responseContentType.startsWith('application/vnd.api+json')
      ) {
        return Promise.resolve(response.json());
      }
    }

    return Promise.resolve(response.text());
  } catch (err) {
    return Promise.reject(err);
  }
}
