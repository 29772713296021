import { EnergyApiFilter } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';

export enum Actions {
  ENERGY_BY_EQUIPMENT_ID_SUCCESS = '@@energy/ENERGY_BY_EQUIPMENT_ID_SUCCESS',
  ENERGY_BY_EQUIPMENT_ID_LOADING = '@@energy/ENERGY_BY_EQUIPMENT_ID_LOADING',
  ENERGY_BY_EQUIPMENT_ID_ERROR = '@@energy/ENERGY_BY_EQUIPMENT_ID_ERROR',

  GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING = '@@energy/GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING',
  GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR = '@@energy/GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR',
  GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS = '@@energy/GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS',
}
const baseUrl = '/api/v1/ioe-energy';

export const setEnergyFilter = (filter?: EnergyApiFilter): string => {
  const queryParams = new URLSearchParams();

  //filter by equipmentId
  if (filter && filter.id) {
    queryParams.set('id', filter.id.toString());
  }

  if (filter && filter.from) {
    if (typeof filter.from === 'string') {
      queryParams.set(
        'from',
        DateTime.fromISO(filter.from)
          .toSeconds()
          .toFixed(0)
      );
    } else {
      queryParams.set('from', filter.from.toString());
    }
  }

  if (filter && filter.to) {
    if (typeof filter.to === 'string') {
      queryParams.set(
        'to',
        DateTime.fromISO(filter.to)
          .toSeconds()
          .toFixed(0)
      );
    } else {
      queryParams.set('to', filter.to.toString());
    }
  }

  if (filter && filter.aggregationLevel) {
    queryParams.set('aggregationLevel', filter.aggregationLevel.toString());
  }

  return queryParams.toString();
};

//IMPORTANT: IAM IOE endpoints receive from/to timestamps in seconds

export const getEnergyByEquipmentId = (
  equipmentId: number | string,
  queryParams: EnergyApiFilter,
  secondaryId?: string
) => {
  const id = isDefined(secondaryId)
    ? equipmentId + `_${secondaryId}`
    : equipmentId;

  // secondaryId is actually unnecessary for api call, but
  // need it so multiple endpoint calls can be sent to be stored
  // in redux for each id (equipmentId_secondaryId)
  const unnecessarySecondaryIdParam = secondaryId
    ? `&secondaryId=${secondaryId}`
    : '';

  return {
    type: 'API_GET',
    path: `${baseUrl}/consumption/equipment/${equipmentId}?${setEnergyFilter(
      queryParams
    )}${unnecessarySecondaryIdParam}`,
    loading: {
      type: Actions.ENERGY_BY_EQUIPMENT_ID_LOADING,
      id,
    },
    success: {
      type: Actions.ENERGY_BY_EQUIPMENT_ID_SUCCESS,
      id,
    },
    error: {
      type: Actions.ENERGY_BY_EQUIPMENT_ID_ERROR,
      id,
    },
  };
};

export const getEnergyDashboardBySiteId = (
  siteId: string | number,
  queryParams: EnergyApiFilter
) => ({
  type: 'API_GET',
  path: `${baseUrl}/dashboard/site/${siteId}?${setEnergyFilter(queryParams)}`,
  loading: {
    type: Actions.GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR,
    siteId,
  },
});
