import { useLightLiveReadings } from '../../../hooks/streamApi/deviceReadings';
import { formatDecimalValue } from '@energybox/react-ui-library/dist/utils/number';
import { DeviceStatusById } from '../../../reducers/deviceStatus';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { global } from '@energybox/react-ui-library/dist/utils';
import { Button } from '@energybox/react-ui-library/dist/components';
import { showNewActuatorModal } from '../../../actions/control_boards';
import styles from './EditModeOffset.module.css';

type Props = {
  controlBoardId: number;
  relayControlBoardId: number | undefined;
  equipmentId: number;
  siteId: string | number;
};
const LightSensorLiveValue: React.FC<Props> = ({
  controlBoardId,
  relayControlBoardId,
  equipmentId,
}) => {
  const dispatch = useDispatch();
  const lightLiveReading = useLightLiveReadings(controlBoardId);

  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);

  const isDeviceOnline = deviceStatusById[controlBoardId]?.onlineState;
  const renderLightSensorValue = () => {
    return isDefined(controlBoardId) && isDeviceOnline ? (
      <div>{formatDecimalValue(lightLiveReading?.lux, 0, 'lux')}</div>
    ) : (
      <div className={styles.noData}>{global.NOT_AVAILABLE}</div>
    );
  };
  const addActuator = () => {
    return (
      <Button
        onClick={() => {
          dispatch(showNewActuatorModal(equipmentId));
        }}
        type="button"
        variant="outlined"
        className={styles.relayButton}
      >
        Map Relay
      </Button>
    );
  };
  return !isDefined(relayControlBoardId)
    ? addActuator()
    : renderLightSensorValue();
};

export default LightSensorLiveValue;
