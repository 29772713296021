import {
  ApiKey,
  DateFormat,
  GenericErrors,
  User,
  MeasurementSystem,
  NewKeyResponse,
  TimeFormat,
  Role,
  CurrentUser,
} from '@energybox/react-ui-library/dist/types';
import {
  mapScopeToAccessResource,
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/users';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import { PasswordChangeError } from '@energybox/react-ui-library/dist/components/NewPassword/NewPasswordPage/NewPasswordPage';

export interface EditableFields {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  groupIds?: number[];
  position?: string;
  organizationUnitId?: number;
  organizationUnitTitle?: string;
  timeZone?: string;
  temperature?: 'CELSIUS' | 'FAHRENHEIT' | null;
  area: 'M2' | 'FT2' | null;
  measurementSystem?: MeasurementSystem;
  timeFormat?: TimeFormat;
  dateFormat?: DateFormat;

  password?: string;
}

export interface EditableApiKeyFields {
  title: string;
  description: string;
}

export type UsersById = {
  [id: string]: User;
};

export type ApiKeysById = {
  [id: string]: ApiKey;
};

const newUserFields = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  dateFormat: '',
  timeFormat: '',
  timeZone: '',
};

const newInstallerFields = R.omit(['password'], newUserFields);

const newApiKeyFields = {
  title: '',
  description: '',
};

const newApiKey = {
  isLoading: false,
  isChanged: false,
  formErrorsVisible: false,
  fields: newApiKeyFields,
  formErrors: formValidationErrors('apiKey', newApiKeyFields),
  apiError: {},
};

const newUser = {
  isLoading: false,
  isChanged: false,
  formErrorsVisible: false,
  fields: newUserFields,
  formErrors: formValidationErrors('newUser', newUserFields),
  apiError: {},
};

const newInstaller = {
  isLoading: false,
  isChanged: false,
  formErrorsVisible: false,
  fields: newInstallerFields,
  formErrors: formValidationErrors('newInstaller', newInstallerFields),
  apiError: {},
};

export type EditUser = {
  isLoading: boolean;
  isChanged: boolean;
  formErrorsVisible: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  apiError: ApiError;
};

export type EditApiKey = {
  isLoading: boolean;
  isChanged: boolean;
  formErrorsVisible: boolean;
  fields: EditableApiKeyFields;
  formErrors: GenericErrors;
  apiError: ApiError;
};

export type EditScope = {
  isLoading: boolean;
  scopeId: string;
  error: GenericErrors;
};

export type EditById = {
  [id: string]: EditUser;
};

export type EditApiKeyById = {
  [id: string]: EditApiKey;
};

export type ApiKeyIdsByUserId = {
  [id: string]: number[];
};

export interface Users {
  usersById: UsersById;
  apiKeysById: ApiKeysById;
  apiKeyIdsByUserId: ApiKeyIdsByUserId;
  editById: EditById;
  editApiKeyById: EditApiKeyById;
  showNewApiKeyModal: boolean;
  showApiKeyCreatedModal: boolean;
  showNewUserModal: boolean;
  showDeleteApiKeyModal: boolean;
  userPositions?: string[];
  loadingStatusByAction: ActionsLoadingStatus;
  didPasswordChangeFail: PasswordChangeError;
  temporaryNewKeyStore?: NewKeyResponse;
  userApiError: ApiError;
}

export type ActionsLoadingStatus = {
  [Actions.GET_USERS_LOADING]?: boolean;
};

function userFromApiResponse(data: any): User | CurrentUser {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone,
    lastLogin: data.lastLoginAt,
    role: Role[data.role],
    position: data.position,
    timeZone: data.timeZone,
    measurementSystem: data.measurementSystem,
    temperature: data.temperature,
    area: data.area,
    timeFormat: data.timeFormat,
    dateFormat: data.dateFormat,
    organizationUnitTitle: data.organizationUnit && data.organizationUnit.title,
    organizationUnitId: data.organizationUnit && data.organizationUnit.id,
    organizationUnitType:
      (data.organizationUnit && data.organizationUnit.organizationType) ||
      OrganizationType.CUSTOMER,
    accessResources: data.scopes.map(mapScopeToAccessResource),
    groupIds: data.groups ? data.groups.map(g => g.id) : [],
    kiosk: data.kiosk || null,
    userStatus: data.userStatus,
    invitationEmailSentAt: data.invitationEmailSentAt,
  };
}

function apiKeyFromApiResponse(data: any): ApiKey {
  return {
    description: data.description,
    title: data.title,
    id: data.id,
    userId: data.userId,
  };
}

const editableFields = (user: User | CurrentUser) => {
  return R.pick(
    [
      'firstName',
      'lastName',
      'phone',
      'email',
      'position',
      'timeZone',
      'organizationUnitId',
      'measurementSystem',
      'timeFormat',
      'dateFormat',
      'area',
      'temperature',
    ],
    user
  );
};

export const initialState: Users = {
  usersById: {},
  apiKeyIdsByUserId: {},
  apiKeysById: {},
  editById: {},
  editApiKeyById: {},
  showNewUserModal: false,
  showApiKeyCreatedModal: false,
  showNewApiKeyModal: false,
  showDeleteApiKeyModal: false,
  loadingStatusByAction: {},
  didPasswordChangeFail: 'no',
  userApiError: {},
};

export const users = (state: Users = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_USERS_SUCCESS:
      return R.pipe(
        R.assoc(
          'usersById',
          R.mergeRight(
            R.view(R.lensProp('usersById'), state),
            mapArrayToObject(mapValues(action.data, userFromApiResponse))
          )
        ),
        R.assocPath(['loadingStatusByAction', Actions.GET_USERS_LOADING], false)
      )(state);

    case Actions.GET_USERS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_USERS_LOADING],
        true,
        state
      );

    case Actions.GET_USER_LOADING:
      return R.assoc('userApiError', {}, state);

    case Actions.GET_USER_SUCCESS:
      let user = userFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['usersById', action.data.id], user),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(user),
        }),
        R.assoc('userApiError', {})
      )(state);

    case Actions.GET_USER_ERROR:
      return R.assoc('userApiError', action.data, state);

    case Actions.TOGGLE_NEW_USER_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'installer'], newInstaller),
        R.assocPath(['editById', 'new'], newUser),
        R.assoc('showNewUserModal', action.value)
      )(state);

    case Actions.TOGGLE_NEW_API_KEY_MODAL:
      return R.pipe(
        R.assocPath(['editApiKeyById', 'new'], newApiKey),
        R.assoc('showNewApiKeyModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedFields = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(['editById', action.id, 'fields'], updatedFields),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors(
            action.id === 'installer'
              ? 'newInstaller'
              : action.id === 'new'
              ? 'newUser'
              : 'editUser',
            updatedFields
          )
        )
      )(state);

    case Actions.UPDATE_API_KEY_FIELD: {
      let updatedFields = R.assoc(
        action.field,
        action.value,
        R.path(['editApiKeyById', action.id, 'fields'], state)
      );

      return R.pipe(
        R.assocPath(['editApiKeyById', action.id, 'isChanged'], true),
        R.assocPath(['editApiKeyById', action.id, 'fields'], updatedFields),
        R.assocPath(
          ['editApiKeyById', action.id, 'formErrors'],
          formValidationErrors('apiKey', updatedFields)
        )
      )(state);
    }

    case Actions.RESET_EDIT_USER:
      var fields = editableFields(R.path(['usersById', action.id], state));

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors(
            action.id === 'new' ? 'newUser' : 'editUser',
            fields
          )
        )
      )(state);

    case Actions.CREATE_USER_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.CREATE_USER_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['usersById', action.data.id],
          userFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        ...(action.explicitlyCloseModal
          ? []
          : [R.assoc('showNewUserModal', false)])
      )(state);

    case Actions.CREATE_USER_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.ADD_API_KEY_TO_USER_SUCCESS:
      return R.pipe(
        R.assocPath(['temporaryNewKeyStore'], action.data),
        R.assocPath(['showNewApiKeyModal'], false),
        R.assocPath(['showApiKeyCreatedModal'], true)
      )(state);

    case Actions.ADD_API_KEY_TO_USER_ERROR:
      return R.pipe(
        R.assocPath(
          ['editApiKeyById', 'new', 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editApiKeyById', 'new', 'isLoading'], false)
      )(state);

    case Actions.REMOVE_API_KEY_FROM_USER_SUCCESS:
      return R.pipe(
        R.dissocPath(['apiKeysById', action.id.toString()]),
        R.assoc('showDeleteApiKeyModal', false)
      )(state);

    case Actions.REMOVE_API_KEY_FROM_USER_ERROR:
      return R.pipe(
        R.assocPath(
          ['editApiKeyById', action.id.toString(), 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(
          ['editApiKeyById', action.id.toString(), 'isLoading'],
          false
        )
      )(state);

    case Actions.TOGGLE_API_KEY_CREATED_MODAL:
      return R.assocPath(['showApiKeyCreatedModal'], action.value, state);

    case Actions.TOGGLE_API_KEY_DELETE_MODAL:
      return R.assocPath(['showDeleteApiKeyModal'], action.value, state);

    case Actions.GET_USER_API_KEYS_SUCCESS: {
      const apiKeysSanitized = mapValues(action.data, apiKeyFromApiResponse);
      return R.pipe(
        R.assocPath(
          ['apiKeyIdsByUserId', action.userId.toString()],
          apiKeysSanitized.map(({ id }) => id)
        ),
        R.assocPath(
          ['apiKeysById'],
          mapArrayToObject(mapValues(action.data, apiKeyFromApiResponse))
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_USER_API_KEYS_LOADING],
          false
        )
      )(state);
    }

    case Actions.PATCH_USER_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_USER_SUCCESS:
      const patchedUser = userFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['usersById', action.id], patchedUser),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_USER_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.GET_USER_POSITIONS_SUCCESS:
      return R.assoc('userPositions', action.data, state);

    case Actions.ADD_USER_POSITION:
      return R.assoc(
        'userPositions',
        R.append(action.position, state.userPositions),
        state
      );

    case Actions.ADD_USER_SCOPE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.ADD_USER_SCOPE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['usersById', action.data.id],
          userFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.PUT_USER_SCOPE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PUT_USER_SCOPE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['usersById', action.data.id],
          userFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DELETE_USER_SCOPE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_USER_SCOPE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['usersById', action.data.id],
          userFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.ADD_USER_TO_GROUP_SUCCESS:
      return R.over(
        R.lensPath(['usersById', action.userId, 'groupIds']),
        R.append(action.groupId),
        state
      );

    case Actions.REMOVE_USER_FROM_GROUP_SUCCESS:
      return R.over(
        R.lensPath(['usersById', action.userId, 'groupIds']),
        R.filter((id: number) => id !== action.groupId),
        state
      );

    case Actions.DELETE_USER_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_USER_SUCCESS:
      return R.pipe(
        R.dissocPath(['usersById', action.id]),
        R.dissocPath(['editById', action.id])
      )(state);

    case Actions.DELETE_USER_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.USER_CHANGE_PASSWORD_LOADING:
    case Actions.USER_CHANGE_PASSWORD_SUCCESS:
      return R.assoc('didPasswordChangeFail', 'no', state);

    case Actions.USER_CHANGE_PASSWORD_ERROR:
      let error: PasswordChangeError = 'error';
      if (action.data.message === 'your old password is not correct')
        error = 'authError';
      return R.assoc('didPasswordChangeFail', error, state);

    case Actions.USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR:
      if (state.didPasswordChangeFail === 'authError')
        return R.assoc('didPasswordChangeFail', 'no', state);
      return state;

    case Actions.USER_CREATE_KIOSK_SETTINGS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.USER_CREATE_KIOSK_SETTINGS_LOADING],
        true,
        state
      );

    case Actions.USER_CREATE_KIOSK_SETTINGS_SUCCESS:
      return R.pipe(
        R.assocPath(['usersById', action.id], userFromApiResponse(action.data)),
        R.assocPath(
          ['loadingStatusByAction', Actions.USER_CREATE_KIOSK_SETTINGS_LOADING],
          false
        )
      )(state);

    case Actions.USER_UPDATE_KIOSK_SETTINGS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.USER_UPDATE_KIOSK_SETTINGS_LOADING],
        true,
        state
      );

    case Actions.USER_UPDATE_KIOSK_SETTINGS_SUCCESS:
      return R.pipe(
        R.assocPath(['usersById', action.id], userFromApiResponse(action.data)),
        R.assocPath(
          ['loadingStatusByAction', Actions.USER_UPDATE_KIOSK_SETTINGS_LOADING],
          false
        )
      )(state);

    default:
      return state;
  }
};

export default users;
