import {
  FormText,
  ListItem,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import {
  Role,
  SentinelEscalation,
  Site,
  Sop,
  User,
  UserGroup,
} from '@energybox/react-ui-library/dist/types';
import { Trash } from '@energybox/react-ui-library/dist/icons';

import React, { useEffect, useState } from 'react';
import { Recipient } from './StepHandling';
import styles from './StepHandlingItem.module.css';
import multiSelectStyle from '../../../../containers/Selects/MultiSelects.module.css';
import { Errors, TargetedEntitiesMappingBySiteId } from '../../ReminderWizard';
import {
  convertWeekdays,
  sortWeekdays,
} from '../../../../utils/getWeekdayRange';
import { uniqBy, path } from 'ramda';
import { useCurrentOrganizationId } from '../../../../hooks/useCurrentOrganization';

export interface ActionError {
  name?: string;
  recipients?: string;
}

function SelectRecipient(props: {
  options: Recipient[];
  selected: number[];
  showSelectAll: boolean;
  onChange: (recipient: Recipient) => void;
  toggleSelectAll: (recipients: Recipient[]) => void;
}) {
  const { options, selected, showSelectAll, onChange, toggleSelectAll } = props;

  const [value, setValue] = useState('');

  let filteredOptions = [...options];
  if (value !== '') {
    filteredOptions = filteredOptions.filter((recipient: Recipient) =>
      recipient.title.toLowerCase().includes(value.toLowerCase())
    );
  }

  if (selected.length > 0) {
    filteredOptions = filteredOptions.filter(
      (recipient: Recipient) => !selected.includes(recipient.id)
    );
  }

  const handleSelectAllToggle = options => {
    if (selected.length > 0) {
      toggleSelectAll([]);
    } else {
      toggleSelectAll(options);
    }
  };

  return (
    <MultiSelect
      doNotIncludeActions
      selectionComponentType="portalDropdown"
      items={filteredOptions
        .filter(i => selected.includes(i.id))
        .map(i => {
          return {
            id: i.id,
            value: i.title,
            className: '',
          };
        })}
      selectionComponent={
        <div className={multiSelectStyle.dropdown}>
          <div className={multiSelectStyle.searchBox}>
            <SelectSearch
              onChange={ev => setValue(ev.currentTarget.value)}
              value={value}
            />
          </div>
          <div className={multiSelectStyle.dropdownList}>
            {showSelectAll && (
              <div className={multiSelectStyle.selectAll}>
                <span
                  tabIndex={0}
                  onKeyPress={() => handleSelectAllToggle(filteredOptions)}
                  role="button"
                  onClick={() => handleSelectAllToggle(filteredOptions)}
                >
                  {selected.length > 0 ? 'Deselect All' : 'Select All'}
                </span>
              </div>
            )}
            {filteredOptions.map((recipient: Recipient) => (
              <MultiSelectItem
                key={recipient.id}
                isSelected={selected.includes(recipient.id)}
                onSelect={() => onChange(recipient)}
              >
                {recipient.title}
              </MultiSelectItem>
            ))}
          </div>
        </div>
      }
      onSelection={onChange}
      error={false}
      customErrorText={''}
    />
  );
}

type Props = {
  sop: Sop;
  site: Site;
  isDeleteAble: boolean;
  options: User[];
  targets: TargetedEntitiesMappingBySiteId;
  handleAddEscalation: (siteId, sopId, recipients) => void;
  handleUpdateEscalation: (sentinelEscalation: SentinelEscalation) => void;
  handleDeleteEscalation: (siteId, sopId, recipients) => void;
  index: number;
  isLoading: boolean;
  isSetErrorField: (value: boolean) => void;
  isErrorField: boolean;
  isSetEmailErrorField: (value: boolean) => void;
  isEmailErrorField: boolean;
  errors: Errors;
  setAction: (value: any) => void;
  isSetFormSaveError: (value: boolean) => void;
  handleValidation: (value: any) => void;
  isFormSaveError: boolean;
  setEditBtnEnable: (status: boolean[]) => any;
  editBtnEnable: boolean[];
};

type State = {
  isEditable: boolean;
  escalation: SentinelEscalation;
  isDublicateKeyExist: boolean;
  showSelectAll: boolean;
  filteredEquipmentTypes: any;
  errors: ActionError;
};

const allowedAccessRoles = [Role.ADMIN, Role.MANAGER];

const StepHandlingItem = (props: Props) => {
  const {
    sop,
    site,
    targets,
    errors,
    setAction,
    isSetFormSaveError,
    handleAddEscalation,
    handleDeleteEscalation,
  } = props;

  const orgId = useCurrentOrganizationId();
  const [itemErrors, setItemErrors] = useState<ActionError>({});
  const [recipients, setRecipients] = useState<Recipient[]>(
    uniqBy(path(['id']), targets[site?.id]?.map(i => i.recipients).flat() || [])
  );

  useEffect(() => {
    if (recipients.length > 0 && itemErrors.recipients) {
      setItemErrors({ ...itemErrors, recipients: undefined });
    }
  }, [recipients]);

  useEffect(() => {
    setRecipients(
      targets[site?.id] !== undefined
        ? targets[site?.id]
            .filter(i => i.sop?.id === sop.id)
            .map(i => i.recipients)
            .flat()
        : []
    );
  }, [targets, sop]);

  const handleAddRecipient = (siteId, sopId, recipient: Recipient) => {
    handleAddEscalation(siteId, sopId, [...recipients, recipient]);
    setRecipients([...recipients, recipient]);
  };

  const handleToggleSelectAllRecipient = (
    siteId,
    sopId,
    recipients: Recipient[]
  ) => {
    if (recipients.length > 0) {
      handleAddEscalation(siteId, sopId, [...recipients]);
      setRecipients([...recipients]);
    } else {
      handleDeleteEscalation(siteId, sopId, []);
      setRecipients([]);
    }
    isSetFormSaveError(false);
  };

  const handleRemoveRecipient = (siteId, sopId, recipient: Recipient) => {
    const newRecipients = recipients.filter(
      r => r.identifier !== recipient.identifier
    );
    handleDeleteEscalation(siteId, sopId, newRecipients);
    setRecipients(newRecipients);
    setAction([newRecipients]);
    isSetFormSaveError(false);
  };

  const { options, isEmailErrorField } = props;
  const weekdays =
    sop?.components[0]?.type === 'TASK'
      ? sop?.components[0]?.taskSettings.weekdays
      : [];
  const weekdayString = convertWeekdays(weekdays);
  const filteredOptions = options
    ?.filter(
      i =>
        i?.accessResources.find(
          a =>
            (a?.resourceId === site?.id || a?.resourceId === orgId) &&
            allowedAccessRoles.includes(a?.role)
        ) !== undefined
    )
    .map(o => {
      return {
        id: o.id,
        identifier: String(o.id),
        title: o?.email,
      };
    });

  return (
    <div className={`${styles.root}`}>
      <div className={styles.handling}>
        <div>
          <FormText>{site?.title || ''}</FormText>
        </div>

        <div>
          <FormText>{sop?.title}</FormText>
          <br />
          <FormText>{sop?.description}</FormText>
        </div>

        <div>
          <FormText>
            {sop?.equipmentTypes?.map(i => (
              <div key={`equipType_${i.id}`}>{i.title}</div>
            ))}
          </FormText>
        </div>

        <div>
          <FormText>
            <div>
              {sop?.components[0].type === 'TASK' &&
              typeof weekdayString === 'string'
                ? weekdayString
                : sortWeekdays(weekdays).join(', ')}
            </div>
            <div>
              <span style={{ fontWeight: 600 }}>Check 1: </span>
              {sop?.components[0].type === 'TASK' &&
                sop?.components[0].taskSettings.time}
            </div>
          </FormText>
        </div>

        <div>
          <SelectRecipient
            onChange={(recipient: Recipient) =>
              handleAddRecipient(site?.id, sop?.id, recipient)
            }
            selected={recipients.map(e => Number(e.identifier))}
            options={filteredOptions}
            showSelectAll={true}
            toggleSelectAll={(recipients: Recipient[]) =>
              handleToggleSelectAllRecipient(site?.id, sop?.id, recipients)
            }
          />
          {recipients.map(r => (
            <ListItem
              key={`ListItem${r.identifier}`}
              primaryText={r.title}
              actions={
                <span className={styles.trashIcon}>
                  <Trash
                    color={'var(--pink-base)'}
                    onClick={() => handleRemoveRecipient(site?.id, sop?.id, r)}
                  />
                </span>
              }
            />
          ))}
          <span className={styles.errorContainer}>
            {isEmailErrorField === true &&
            errors.handling.sop?.includes(sop?.id)
              ? errors.handling.onSave
              : ''}
          </span>
          <span className={styles.errorContainer}>
            {itemErrors.recipients ?? ''}
          </span>
        </div>
      </div>
    </div>
  );
};

export default StepHandlingItem;
