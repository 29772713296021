import * as R from 'ramda';
import { EnergySensor } from '@energybox/react-ui-library/dist/types';
import { ApplicationState } from '../reducers';
import {
  EditableSensorFields,
  StagingSensorConfig,
} from '../reducers/energy_devices';
import { getEnergyPro } from './energy_pros';

const apiBase = '/api/v1/energy-devices';

export enum Actions {
  GET_ENERGY_DEVICE_SUCCESS = '@energy_devices/GET_ENERGY_DEVICE_SUCCESS',
  GET_ENERGY_DEVICE_ERROR = '@energy_devices/GET_ENERGY_DEVICE_ERROR',
  GET_ENERGY_DEVICE_LOADING = '@energy_devices/GET_ENERGY_DEVICE_LOADING',

  PATCH_ENERGY_DEVICE_LOADING = '@energy_devices/PATCH_ENERGY_DEVICE_LOADING',
  PATCH_ENERGY_DEVICE_ERROR = '@energy_devices/PATCH_ENERGY_DEVICE_ERROR',
  PATCH_ENERGY_DEVICE_SUCCESS = '@energy_devices/PATCH_ENERGY_DEVICE_SUCCESS',

  ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_LOADING = '@energy_devices/ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_LOADING',
  ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_ERROR = '@energy_devices/ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_ERROR',
  ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_SUCCESS = '@energy_devices/ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_SUCCESS',

  ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_LOADING = '@energy_devices/ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_LOADING',
  ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR = '@energy_devices/ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR',
  ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS = '@energy_devices/ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS',

  EDIT_ENERGY_DEVICE_SENSOR_PORT_LOADING = '@energy_devices/EDIT_ENERGY_DEVICE_SENSOR_PORT_LOADING',
  EDIT_ENERGY_DEVICE_SENSOR_PORT_ERROR = '@energy_devices/EDIT_ENERGY_DEVICE_SENSOR_PORT_ERROR',
  EDIT_ENERGY_DEVICE_SENSOR_PORT_SUCCESS = '@energy_devices/EDIT_ENERGY_DEVICE_SENSOR_PORT_SUCCESS',

  REMOVE_SENSOR_FROM_ENERGY_DEVICE_LOADING = '@energy_devices/REMOVE_SENSOR_FROM_ENERGY_DEVICE_LOADING',
  REMOVE_SENSOR_FROM_ENERGY_DEVICE_ERROR = '@energy_devices/REMOVE_SENSOR_FROM_ENERGY_DEVICE_ERROR',
  REMOVE_SENSOR_FROM_ENERGY_DEVICE_SUCCESS = '@energy_devices/REMOVE_SENSOR_FROM_ENERGY_DEVICE_SUCCESS',

  REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_LOADING = '@energy_devices/REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_LOADING',
  REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_ERROR = '@energy_devices/REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_ERROR',
  REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS = '@energy_devices/REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS',

  REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_LOADING = '@energy_devices/REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_LOADING',
  REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_ERROR = '@energy_devices/REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_ERROR',
  REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS = '@energy_devices/REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS',

  TOGGLE_NEW_ENERGY_DEVICE_MODAL = '@energy_devices/TOGGLE_NEW_ENERGY_DEVICE_MODAL',
  TOGGLE_DELETE_ENERGY_DEVICE_MODAL = '@energy_devices/TOGGLE_DELETE_ENERGY_DEVICE_MODAL',

  UPDATE_FIELD = '@energy_devices/UPDATE_FIELD',
  RESET_EDIT_ENERGY_DEVICE = '@energy_devices/RESET_EDIT_ENERGY_DEVICE',

  DISPLAY_FORM_ERRORS = '@energy_devices/DISPLAY_FORM_ERRORS',

  TOGGLE_NEW_ENERGY_DEVICE_SENSOR_MODAL = '@energy_devices/TOGGLE_NEW_ENERGY_DEVICE_SENSOR_MODAL',
  TOGGLE_REMOVE_ENERGY_DEVICE_SENSOR_MODAL = '@energy_devices/TOGGLE_REMOVE_ENERGY_DEVICE_SENSOR_MODAL',
  TOGGLE_REMOVE_ALL_ENERGY_DEVICE_SENSORS_MODAL = '@energy_devices/TOGGLE_REMOVE_ALL_ENERGY_DEVICE_SENSORS_MODAL',
  UPDATE_ENERGY_DEVICE_SENSOR_FIELD = '@energy_devices/UPDATE_ENERGY_DEVICE_SENSOR_FIELD',
  RESET_ENERGY_DEVICE_SENSORS = '@energy_devices/RESET_ENERGY_DEVICE_SENSORS',
  DISPLAY_ENERGY_DEVICE_SENSOR_FORM_ERRORS = '@energy_devices/DISPLAY_ENERGY_DEVICE_SENSOR_FORM_ERRORS',
  MAP_SENSORS_IN_ENERGY_DEVICE_TO_EDIT = '@energy_devices/MAP_SENSORS_IN_ENERGY_DEVICE_TO_EDIT',

  DELETE_ENERGY_DEVICE_SENSORS_LINKED_TO_DELETED_CIRCUIT_BREAKER = '@energy_devices/DELETE_ENERGY_DEVICE_SENSORS_LINKED_TO_DELETED_CIRCUIT_BREAKER',

  ADD_STAGING_SENSOR_CONFIG = '@energy_devices/ADD_STAGING_SENSOR_CONFIG',
  CLEAR_STAGING_SENSOR_CONFIG = '@energy_devices/CLEAR_STAGING_SENSOR_CONFIG',

  PATCH_STAGING_SENSOR_CONFIGS_LOADING = '@energy_devices/UPDATE_STAGING_SENSOR_CONFIGS_LOADING',
  PATCH_STAGING_SENSOR_CONFIGS_ERROR = '@energy_devices/UPDATE_STAGING_SENSOR_CONFIGS_ERROR',
  PATCH_STAGING_SENSOR_CONFIGS_SUCCESS = '@energy_devices/UPDATE_STAGING_SENSOR_CONFIGS_SUCCESS',
}

export const getEnergyDevice = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_ENERGY_DEVICE_SUCCESS,
  error: Actions.GET_ENERGY_DEVICE_ERROR,
  loading: Actions.GET_ENERGY_DEVICE_LOADING,
});

export const patch = (id: string) => (dispatch, getState) => {
  const patchableFields = ['description', 'title'];

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: R.pick(
      patchableFields,
      (getState() as ApplicationState).energyDevices.editById[id].fields
    ),
    loading: { type: Actions.PATCH_ENERGY_DEVICE_LOADING, id },
    success: { type: Actions.PATCH_ENERGY_DEVICE_SUCCESS, id },
    error: { type: Actions.PATCH_ENERGY_DEVICE_ERROR, id },
  });
};

export const addEnergyDeviceSensorToCircuitBreaker = (
  energyDeviceId: string,
  energyProId?: number
) => (dispatch, getState) => {
  dispatch({
    type: 'API_POST',
    path: `${apiBase}/${energyDeviceId}/sensor`,
    payload: (getState() as ApplicationState).energyDevices
      .editEnergyDeviceSensorsByEnergyDeviceId[energyDeviceId]['new'].fields,
    loading: {
      type: Actions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_LOADING,
      energyDeviceId,
    },
    success: energyProId
      ? [
          {
            type: Actions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_SUCCESS,
            energyDeviceId,
          },
          getEnergyPro(energyProId),
        ]
      : {
          type: Actions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_SUCCESS,
          energyDeviceId,
        },
    error: {
      type: Actions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_ERROR,
      energyDeviceId,
    },
  });
};

export const addEnergyDeviceSensorToPanelMainBreaker = (
  energyDeviceId: string,
  energyProId?: number
) => (dispatch, getState) => {
  const fields = (getState() as ApplicationState).energyDevices
    .editEnergyDeviceSensorsByEnergyDeviceId[energyDeviceId]['new'].fields;

  const payload = {
    ct: fields.ct,
    energyDevicePort: fields.energyDevicePort,
    mainBreakerPole: fields.breakerPole,
    phase: fields.phase,
    reversePolarity: fields.reversePolarity,
  };

  dispatch({
    type: 'API_POST',
    path: `${apiBase}/${energyDeviceId}/sensor/mains`,
    payload: payload,
    loading: {
      type: Actions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_LOADING,
      energyDeviceId,
    },
    success: energyProId
      ? [
          {
            type:
              Actions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS,
            energyDeviceId,
          },
          getEnergyPro(energyProId),
        ]
      : {
          type: Actions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS,
          energyDeviceId,
        },
    error: {
      type: Actions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR,
      energyDeviceId,
    },
  });
};

export const editEnergyDeviceSensorPort = (
  energyDeviceId: number | string,
  port: number | string,
  payload?: EditableSensorFields
) => (dispatch, getState) => {
  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${energyDeviceId}/sensor/port/${port}`,
    payload: payload
      ? payload
      : (getState() as ApplicationState).energyDevices
          .editEnergyDeviceSensorsByEnergyDeviceId[energyDeviceId][port].fields,
    loading: {
      type: Actions.EDIT_ENERGY_DEVICE_SENSOR_PORT_LOADING,
      energyDeviceId,
      port,
    },
    success: {
      type: Actions.EDIT_ENERGY_DEVICE_SENSOR_PORT_SUCCESS,
      energyDeviceId,
      port,
    },
    error: {
      type: Actions.EDIT_ENERGY_DEVICE_SENSOR_PORT_ERROR,
      energyDeviceId,
      port,
    },
  });
};

export const removeSensorFromEnergyDevice = (
  energyDeviceId: string,
  port: number
) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${energyDeviceId}/sensor/${port}`,
  success: {
    type: Actions.REMOVE_SENSOR_FROM_ENERGY_DEVICE_SUCCESS,
    energyDeviceId,
    port,
  },
  error: {
    type: Actions.REMOVE_SENSOR_FROM_ENERGY_DEVICE_ERROR,
    energyDeviceId,
    port,
  },
  loading: {
    type: Actions.REMOVE_SENSOR_FROM_ENERGY_DEVICE_LOADING,
    energyDeviceId,
    port,
  },
});

export const removeSeveralSensorsFromPorts = (id: string, ports: number[]) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}/sensors`,
  payload: {
    ports,
  },
  success: Actions.REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS,
  error: Actions.REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_ERROR,
  loading: Actions.REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_LOADING,
});

export const removeAllSensors = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}/sensors-all`,
  success: Actions.REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS,
  error: Actions.REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_ERROR,
  loading: Actions.REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_LOADING,
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_ENERGY_DEVICE,
  id,
});

export const showNewEnergyDeviceModal = () => ({
  type: Actions.TOGGLE_NEW_ENERGY_DEVICE_MODAL,
  value: true,
});

export const hideNewEnergyDeviceModal = () => ({
  type: Actions.TOGGLE_NEW_ENERGY_DEVICE_MODAL,
  value: false,
});

export const showDeleteEnergyDeviceModal = (id: string) => ({
  type: Actions.TOGGLE_DELETE_ENERGY_DEVICE_MODAL,
  id,
  value: true,
});

export const hideDeleteEnergyDeviceModal = (id: string) => ({
  type: Actions.TOGGLE_DELETE_ENERGY_DEVICE_MODAL,
  id,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const showNewEnergyDeviceSensorModal = (
  energyDeviceId: string | number
) => ({
  type: Actions.TOGGLE_NEW_ENERGY_DEVICE_SENSOR_MODAL,
  value: true,
  energyDeviceId,
});

export const hideNewEnergyDeviceSensorModal = (energyDeviceId: string) => ({
  type: Actions.TOGGLE_NEW_ENERGY_DEVICE_SENSOR_MODAL,
  value: false,
  energyDeviceId,
});

export const showRemoveEnergyDeviceSensorModal = (
  id: string,
  port: number
) => ({
  type: Actions.TOGGLE_REMOVE_ENERGY_DEVICE_SENSOR_MODAL,
  id,
  port,
  value: true,
});

export const hideRemoveEnergyDeviceSensorModal = (id: string) => ({
  type: Actions.TOGGLE_REMOVE_ENERGY_DEVICE_SENSOR_MODAL,
  id,
  value: false,
});

export const showRemoveAllEnergyDeviceSensorsModal = () => ({
  type: Actions.TOGGLE_REMOVE_ALL_ENERGY_DEVICE_SENSORS_MODAL,
  value: true,
});

export const hideRemoveAllEnergyDeviceSensorsModal = () => ({
  type: Actions.TOGGLE_REMOVE_ALL_ENERGY_DEVICE_SENSORS_MODAL,
  value: false,
});

export const updateEnergyDeviceSensorField = (
  port: string,
  field: string,
  value: string | number | null,
  energyDeviceId: number
) => ({
  type: Actions.UPDATE_ENERGY_DEVICE_SENSOR_FIELD,
  port,
  field,
  value,
  energyDeviceId,
});

export const displayEnergyDeviceSensorFormErrors = (
  id: string,
  energyDeviceId: number
) => ({
  type: Actions.DISPLAY_ENERGY_DEVICE_SENSOR_FORM_ERRORS,
  value: true,
  id,
  energyDeviceId,
});

export const mapSensorsInEnergyDeviceToEdit = (
  sensors: EnergySensor[],
  energyDeviceId: number
) => ({
  type: Actions.MAP_SENSORS_IN_ENERGY_DEVICE_TO_EDIT,
  value: sensors,
  energyDeviceId,
});

export const resetEnergyDeviceSensors = (
  energyDeviceId: number,
  isEnergyPro?: boolean
) => (dispatch: any, getState: () => ApplicationState) => {
  let energyPro;
  if (isEnergyPro) {
    energyPro = getState().energyPros.energyProsById[energyDeviceId];
  }

  dispatch({
    type: Actions.RESET_ENERGY_DEVICE_SENSORS,
    energyDeviceId,
    energyPro,
  });
};

export const addStagingSensorConfig = (
  key: string,
  sensorConfig: StagingSensorConfig
) => ({
  type: Actions.ADD_STAGING_SENSOR_CONFIG,
  key: key,
  value: sensorConfig,
});

export const clearStagingSensorConfig = () => ({
  type: Actions.CLEAR_STAGING_SENSOR_CONFIG,
});

export const patchSensorConfigs = (energyProId: number | string) => (
  dispatch,
  getState
) => {
  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/sensors`,
    payload: {
      energyProId: energyProId,
      sensors: (getState() as ApplicationState).energyDevices
        .stagingSensorConfigs,
    },
    success: Actions.PATCH_STAGING_SENSOR_CONFIGS_SUCCESS,
    error: Actions.PATCH_STAGING_SENSOR_CONFIGS_ERROR,
    loading: Actions.PATCH_STAGING_SENSOR_CONFIGS_LOADING,
  });
};
