import {
  GenericErrors,
  EnergyDeviceType,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  displayFormErrors,
  hideNewEnergyDeviceModal,
  updateField,
} from '../../actions/energy_devices';
import { appendEnergyDevice } from '../../actions/energy_pros';
import EditEnergyDeviceForm from '../../components/EditEnergyDeviceForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/energy_devices';
import { CreateNewText } from '../../types/global';
import { ApiError } from '../../utils/apiErrorFeedback';

interface OwnProps {
  panelId: number;
  newEnergyDeviceType: EnergyDeviceType;
}

interface Props extends OwnProps {
  onChange: (field: string, value: string | number) => void;
  onCreate: (energyProId: string, bus: number) => void;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  formErrorsVisible: boolean;
  displayFormErrors: (id: string) => void;
  apiError: ApiError;
}

function NewEnergyDeviceModal(props: Props) {
  const {
    isVisible,
    fields,
    onChange,
    onClose,
    onCreate,
    isLoading,
    formErrors,
    formErrorsVisible,
    displayFormErrors,
    apiError,
    newEnergyDeviceType,
    panelId,
  } = props;

  React.useEffect(() => {
    if (isVisible && fields.model === '') {
      onChange('model', newEnergyDeviceType);
    }
  });

  if (!props.isVisible) return null;

  const onEnergyDeviceCreate = () => {
    const { energyProId, bus } = fields;

    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate(String(energyProId), bus);
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onEnergyDeviceCreate}>
        Create
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalTitle>{CreateNewText[newEnergyDeviceType]}</ModalTitle>
      <ModalContent>
        <EditEnergyDeviceForm
          fields={fields}
          onChange={onChange}
          formErrorsVisible={formErrorsVisible}
          formErrors={formErrors}
          panelId={panelId}
          apiError={apiError}
          newEnergyDeviceType={newEnergyDeviceType}
        />
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({ energyDevices }: ApplicationState) => {
  return {
    isVisible: energyDevices.showNewEnergyDeviceModal,
    ...energyDevices.editById['new'],
  };
};

const mapDispatchToProps = {
  onClose: hideNewEnergyDeviceModal,
  onChange: (field: string, value: string | number) =>
    updateField('new', field, value),
  onCreate: (energyProId: string, bus: number) =>
    appendEnergyDevice(energyProId, bus),
  displayFormErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEnergyDeviceModal);
