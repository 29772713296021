import React from 'react';
import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import { edgeDeviceTextEnum } from '@energybox/react-ui-library/dist/types';
import { EdgeDeviceModel } from '@energybox/react-ui-library/dist/types/NetworkGroup';

const edgeControlDevices: {
  id: EdgeDeviceModel;
  title: edgeDeviceTextEnum;
}[] = [
  {
    id: 'IR809',
    title: edgeDeviceTextEnum['IR809'],
  },
  {
    id: 'IR1101',
    title: edgeDeviceTextEnum['IR1101'],
  },
  {
    id: 'EB_SUPER_HUB',
    title: edgeDeviceTextEnum['EB_SUPER_HUB'],
  },
];

interface OwnProps {
  onSelect: (edgeControlDevice: EdgeDeviceModel) => void;
  value?: EdgeDeviceModel;
  error?: boolean;
  disabled?: boolean;
}

interface Props extends OwnProps {}

class SelectEdgeControlDevice extends React.Component<Props> {
  render() {
    const { value, onSelect, error, disabled } = this.props;
    const selectedEdgeController = edgeControlDevices.find(
      edgeControlDevice => edgeControlDevice.id === value
    );

    return (
      <Select
        variant={'select'}
        title={selectedEdgeController?.title}
        error={error}
        customErrorText={error ? 'You must select a device' : undefined}
        disabled={disabled}
      >
        {edgeControlDevices.map(edgeControlDevice => (
          <SelectItem
            key={edgeControlDevice.id}
            isSelected={edgeControlDevice.id === selectedEdgeController?.id}
            onSelect={() => onSelect(edgeControlDevice.id)}
          >
            {edgeControlDevice.title}
          </SelectItem>
        ))}
      </Select>
    );
  }
}

export default SelectEdgeControlDevice;
