import {
  LightReadingsHistoricData,
  SiteAstronomicalClock,
  TimeFilterMappingArguments,
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  createTimeFilterMapping,
  formatApiDate,
} from '@energybox/react-ui-library/dist/utils';

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLightReadings7DayAvgByControlBoardId,
  getLightReadingsYesterdayByControlBoardId,
} from '../actions/controls';
import { getSiteAstroClock } from '../actions/sites';
import { ApplicationState } from '../reducers';
import { LightReadingsHistoricDataset } from '../reducers/controls';
import {
  SiteAstroClockByDateRange,
  SiteAstroClockById,
} from '../reducers/sites';

/**
 *
 * @param siteId
 * @param startDate yyyy-MM-dd
 * @param endDate
 */
export const useSiteAstroClock = (
  siteId: number | string,
  startDate: string,
  endDate?: string
): SiteAstronomicalClock[] | undefined => {
  const dispatch = useDispatch();
  const astroClockBySiteId = useSelector<ApplicationState, SiteAstroClockById>(
    ({ sites }) => sites.siteAstroClockById
  );

  const siteAstroClockByDateRange: SiteAstroClockByDateRange =
    astroClockBySiteId[siteId] || {};
  const dateRangeKey = endDate ? `${startDate}_${endDate}` : `${startDate}`;

  const astroClockArray: SiteAstronomicalClock[] | undefined =
    siteAstroClockByDateRange[dateRangeKey];

  useEffect(() => {
    if (!isDefined(astroClockArray)) {
      dispatch(getSiteAstroClock(siteId, startDate, endDate));
    }
  }, [astroClockArray]);

  return astroClockArray;
};

export const useCalculateLast7DayAvgAstronomicalLuxValues = (
  siteId: number | string,
  controlBoardId?: number | string | null,
  timezone?: string
) => {
  const dispatch = useDispatch();

  const timeFiltersMapping = useMemo(() => {
    const timeFilterArguments: TimeFilterMappingArguments = {
      useCurrentTime: true,
      ianaTimeZoneCode: timezone,
    };
    return createTimeFilterMapping(timeFilterArguments);
  }, [timezone]);

  const endDate = timeFiltersMapping.last7Days.toDate;
  const startDate = timeFiltersMapping.last7Days.fromDate;

  const isLoading = useSelector(({ controls }: ApplicationState) => {
    const historicDataStore =
      controls.historicDataByControlBoardId.lightReadings;
    if (!isDefined(controlBoardId)) return true;
    const historicData = historicDataStore[controlBoardId];
    if (!isDefined(historicData)) return true;
    return historicDataStore[controlBoardId].avg7DaysIsLoading;
  });

  const lightReadingsLast7DaysAvg:
    | LightReadingsHistoricData
    | undefined = useSelector(({ controls }: ApplicationState) => {
    const historicDataStore =
      controls.historicDataByControlBoardId.lightReadings;
    if (!isDefined(controlBoardId)) return undefined;
    const historicData: LightReadingsHistoricDataset =
      historicDataStore[controlBoardId];
    if (
      !isDefined(historicData) ||
      historicData.avg7DaysIsLoading ||
      !isDefined(historicData.avg7Days)
    )
      return undefined;
    return historicData.avg7Days;
  });

  const astroClockArrayLast7Days = useSiteAstroClock(
    siteId,
    formatApiDate(startDate, timezone),
    formatApiDate(endDate, timezone)
  );

  useEffect(() => {
    if (astroClockArrayLast7Days && controlBoardId && timezone) {
      dispatch(
        getLightReadings7DayAvgByControlBoardId(
          controlBoardId,
          astroClockArrayLast7Days,
          timezone
        )
      );
    }
  }, [astroClockArrayLast7Days, timezone]);

  return {
    isLoading,
    lightReadingsLast7DaysAvg,
  };
};

export const useCalculateYesterdayAstronomicalLuxValues = (
  siteId: number | string,
  controlBoardId: number | string | null,
  timezone?: string
) => {
  const dispatch = useDispatch();

  const timeFiltersMapping = useMemo(() => {
    const timeFilterArguments: TimeFilterMappingArguments = {
      useCurrentTime: true,
      ianaTimeZoneCode: timezone,
    };
    return createTimeFilterMapping(timeFilterArguments);
  }, [timezone]);

  const endDate = timeFiltersMapping.yesterday.toDate;
  const startDate = timeFiltersMapping.yesterday.fromDate;

  const isLoading = useSelector(({ controls }: ApplicationState) => {
    const historicDataStore =
      controls.historicDataByControlBoardId.lightReadings;
    if (!isDefined(controlBoardId)) return true;
    const historicData = historicDataStore[controlBoardId];
    if (!isDefined(historicData)) return true;
    return historicDataStore[controlBoardId].yesterdayIsLoading;
  });

  const lightReadingsYesterday:
    | LightReadingsHistoricData
    | undefined = useSelector(({ controls }: ApplicationState) => {
    const historicDataStore =
      controls.historicDataByControlBoardId.lightReadings;
    if (!isDefined(controlBoardId)) return undefined;
    const historicData = historicDataStore[controlBoardId];
    if (!isDefined(historicData)) return undefined;
    return historicDataStore[controlBoardId].yesterday;
  });

  const astroClockArrayYesterday = useSiteAstroClock(
    siteId,
    formatApiDate(startDate, timezone),
    formatApiDate(endDate, timezone)
  );

  useEffect(() => {
    if (astroClockArrayYesterday && controlBoardId && timezone) {
      dispatch(
        getLightReadingsYesterdayByControlBoardId(
          controlBoardId,
          astroClockArrayYesterday,
          timezone
        )
      );
    }
  }, [astroClockArrayYesterday, timezone]);

  return {
    isLoading,
    lightReadingsYesterday,
  };
};

export default useSiteAstroClock;
