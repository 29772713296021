import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import {
  GenericFunction,
  HvacControl,
  Thermostat,
} from '@energybox/react-ui-library/dist/types';
import { classNames, hasSubstr } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { connect } from 'react-redux';
import { getHvacControlsBySiteId } from '../../actions/hvacControls';
import { ApplicationState } from '../../reducers';
import { IoIosAddCircle } from 'react-icons/io';
import {
  create as createThermostat,
  clearFormErrors,
  getThermostatsBySiteId,
  showNewThermostatModal,
  hideNewThermostatModal,
} from '../../actions/thermostats';
import styles from '../Selects/SelectThermostat.module.css';
import { EditThermostat } from '../../types/Thermostat';
import NewThermostatModal from '../Devices/NewThermostatModal';

interface OwnProps {
  siteId: number;
  value: string;
  onSelect: GenericFunction;
  disabled?: boolean;
  error?: boolean;
  customErrorText?: string;
  initialThermostatId?: number;
  IsEditFromControls?: boolean;
}

interface Props extends OwnProps {
  getThermostatsBySiteId: (siteId: number | string) => void;
  getHvacControlsBySiteId: (siteId: number | string) => void;
  thermostats: Thermostat[];
  hvacControls: HvacControl[];
  onClose: () => void;
  createThermostat: (payload: EditThermostat) => void;
  showNewThermostatModal: () => void;
  hideNewThermostatModal: () => void;
}

interface State {
  search: string;
}

class SelectThermostat extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const {
      getThermostatsBySiteId,
      getHvacControlsBySiteId,
      siteId,
    } = this.props;

    getThermostatsBySiteId(siteId);
    getHvacControlsBySiteId(siteId);
  }

  filterThermostatsByQuery = (thermostats: Thermostat[]) => {
    const { search } = this.state;

    return thermostats.filter((t: Thermostat) => {
      if (search.length <= 2) return true;
      return hasSubstr(t.title, search);
    });
  };

  render() {
    const {
      thermostats,
      hvacControls,
      value,
      onSelect,
      error,
      customErrorText,
      initialThermostatId,
      IsEditFromControls,
      onClose,
      siteId,
    } = this.props;
    const { search } = this.state;
    if (!thermostats) return <Loader size={12} />;
    //no multi-mapping of 1 thermostat to multiple venstar control agent modules allowed
    const thermostatIdsToFilterOut = hvacControls
      .filter(hvacControl => {
        return (
          hvacControl.thermostatId !== initialThermostatId &&
          hvacControl.thermostatId !== Number(value)
        );
      })
      .map((hvacControl: HvacControl) => hvacControl.thermostatId);

    const filteredThermostats = thermostats.filter(
      (t: Thermostat) => !thermostatIdsToFilterOut.includes(t.id)
    );

    const filteredAndQueriedThermostats = this.filterThermostatsByQuery(
      filteredThermostats
    );

    const selectedThermostat = thermostats.find(
      (t: Thermostat) => t.id === Number(value)
    );

    return (
      <>
        <Select
          variant={'select'}
          onClick={() => {
            this.resetSearch.bind(this);
            this.props.hideNewThermostatModal();
          }}
          title={
            selectedThermostat?.id ? selectedThermostat?.title : 'No Thermostat'
          }
          value={
            selectedThermostat?.id ? selectedThermostat?.id : 'No Thermostat'
          }
          error={error}
          customErrorText={customErrorText}
          className={classNames(
            IsEditFromControls ? styles.customSelectStyle : ''
          )}
          listClassName={styles.selectItem}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
            error={filteredAndQueriedThermostats.length === 0}
          />
          {filteredAndQueriedThermostats.map((t: Thermostat) => (
            <SelectItem
              key={t.id}
              isSelected={t.id === selectedThermostat?.id}
              onSelect={() => onSelect(t.id)}
            >
              {t.title}
            </SelectItem>
          ))}
          {IsEditFromControls && (
            <>
              <SelectItem
                key={'None'}
                isSelected={!selectedThermostat?.id && true}
                onSelect={() => onSelect(-1)}
              >
                <span style={{ color: 'var(--pink-base)' }}>No Thermostat</span>
              </SelectItem>
              <div>
                <button
                  className={styles.addThermostatButton}
                  onClick={() => {
                    this.props.showNewThermostatModal();
                  }}
                >
                  <IoIosAddCircle size="20" />
                  <span className={styles.thermostatText}>Add Thermostat</span>
                </button>
              </div>
            </>
          )}
        </Select>

        <NewThermostatModal siteId={siteId} />
      </>
    );
  }
}

const mapStateToProps = (
  { thermostats, hvacControls }: ApplicationState,
  { siteId }: OwnProps
) => ({
  thermostats: pathOr([], [siteId], thermostats.thermostatsBySiteId),
  hvacControls: pathOr([], [siteId], hvacControls.hvacControlsBySiteId),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    getThermostatsBySiteId: siteId => dispatch(getThermostatsBySiteId(siteId)),
    getHvacControlsBySiteId: siteId =>
      dispatch(getHvacControlsBySiteId(siteId)),
    onClose: () => dispatch(clearFormErrors()),
    createThermostat: updatedFields =>
      dispatch(createThermostat(updatedFields)),
    showNewThermostatModal: () => dispatch(showNewThermostatModal()),
    hideNewThermostatModal: () => dispatch(hideNewThermostatModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectThermostat);
