import {
  FormText,
  InputField,
  Label,
  Select,
  SelectItem,
  PhoneCodeDropdown,
} from '@energybox/react-ui-library/dist/components';
import {
  DateFormat,
  DateFormatLabel,
  GenericErrors,
  MeasurementSystem,
  MeasurementSystemLabel,
  TimeFormat,
  TimeFormatLabel,
} from '@energybox/react-ui-library/dist/types';
import {
  AreaUnitLabels,
  TemperatureUnitLabels,
} from '@energybox/react-ui-library/dist/types/Global';
import { values } from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import SiteSelect from '../../containers/SiteSelect';
import TimeZoneSelect from '../../containers/TimeZoneSelect';
import UserPositionSelect from '../../containers/User/UserPositionSelect';
import { PropertyToLabel } from '../../types/global';
import styles from './EditUserForm.module.css';

import { renderAPIerror } from '../../utils/apiErrorFeedback';
import { Actions as UserActions } from '../../actions/users';

interface Props {
  onChange: (field: string, value: any) => void;
  firstName: string;
  lastName: string;
  email: string;
  position?: string | null;
  timeZone?: string;
  temperature?: 'CELSIUS' | 'FAHRENHEIT' | null;
  area: 'M2' | 'FT2' | null;
  measurementSystem?: MeasurementSystem;
  timeFormat?: string | null;
  dateFormat?: string | null;
  organizationUnitId?: number;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  phone: string;
  editUserProps: any;
  editInstallers?: boolean;
}

const MeasurementSystemOptions: ['METRIC', 'IMPERIAL'] = ['METRIC', 'IMPERIAL'];

const temperatureUnitOptions: ['CELSIUS', 'FAHRENHEIT'] = [
  'CELSIUS',
  'FAHRENHEIT',
];
const areaUnitOptions: ['M2', 'FT2'] = ['M2', 'FT2'];

const EditUserForm = ({
  onChange,
  firstName,
  lastName,
  email,
  position,
  timeZone,
  measurementSystem,
  area,
  temperature,
  timeFormat,
  dateFormat,
  organizationUnitId,
  formErrorsVisible,
  formErrors = {},
  phone,
  editUserProps,
  editInstallers,
}: Props) => {
  // separate phone country code from phone number
  const [parsedPhoneCode, parsedPhoneNumber] = useMemo(() => {
    let phoneCode: number | undefined = undefined;
    const hasPhoneCode = (phone || '').charAt(0) === '+';
    const phoneNumberStarts = hasPhoneCode ? phone.indexOf('-') + 1 : -1;
    if (hasPhoneCode) {
      phoneCode = +phone.slice(0, phoneNumberStarts - 1);
    }
    const phoneNumber =
      phoneNumberStarts === -1 ? phone : phone.slice(phoneNumberStarts);
    return [phoneCode, phoneNumber];
  }, [phone]);
  return (
    <form className={styles.form}>
      <div>
        <h4>Personal information</h4>
        <div className={styles.inputGroup}>
          <Label htmlFor="firstName" required>
            {PropertyToLabel.firstName}:
          </Label>
          <div>
            <InputField
              id="firstName"
              type="text"
              name="firstName"
              autoComplete="firstName"
              value={firstName}
              onChange={({ currentTarget }) =>
                onChange('firstName', currentTarget.value)
              }
              error={formErrorsVisible && !!formErrors.firstName}
              customErrorText={formErrors.firstName && formErrors.firstName[0]}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label htmlFor="lastName" required>
            {PropertyToLabel.lastName}:
          </Label>
          <div>
            <InputField
              id="lastName"
              type="text"
              name="lastName"
              autoComplete="lastName"
              value={lastName}
              onChange={({ currentTarget }) =>
                onChange('lastName', currentTarget.value)
              }
              error={formErrorsVisible && !!formErrors.lastName}
              customErrorText={formErrors.lastName && formErrors.lastName[0]}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label htmlFor="phone">{PropertyToLabel.phoneNumber}</Label>
          <div className={styles.phoneInputWrapper}>
            <PhoneCodeDropdown
              selectedPhoneCode={parsedPhoneCode}
              onSelect={value => {
                onChange('phone', `+${value}-${parsedPhoneNumber || ''}`);
              }}
            />
            <InputField
              className={styles.phoneNumberInput}
              id="phone"
              type="text"
              name="phone"
              placeholder="phone number"
              value={parsedPhoneNumber}
              error={formErrorsVisible && !!formErrors.phone}
              customErrorText={formErrors.phone && formErrors.phone[0]}
              onChange={e => {
                if (parsedPhoneCode) {
                  onChange(
                    'phone',
                    `+${parsedPhoneCode}-${e.currentTarget.value}`
                  );
                } else {
                  onChange('phone', e.currentTarget.value);
                }
              }}
            />
            {renderAPIerror(
              editUserProps.apiError,
              UserActions.PATCH_USER_ERROR
            )}
          </div>
        </div>

        <h4>Login Information</h4>
        <div className={styles.inputGroup}>
          <div>
            <Label required>{PropertyToLabel.email}:</Label>
          </div>
          <FormText>{email}</FormText>
        </div>
      </div>

      <div>
        <h4>Local settings</h4>

        <div className={styles.inputGroup}>
          <Label htmlFor="userPosition">{PropertyToLabel.position}</Label>
          <div>
            <UserPositionSelect
              disabled={editInstallers}
              onSelect={value => onChange('position', value)}
              value={position || ''}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label htmlFor="workplace">{PropertyToLabel.workplace}</Label>
          <div>
            <SiteSelect
              disabled
              onSelect={siteId => onChange('organizationUnitId', siteId)}
              value={organizationUnitId}
              error={formErrorsVisible && !!formErrors.organizationUnitId}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label required htmlFor="timezone">
            {PropertyToLabel.timeZone}
          </Label>
          <div>
            <TimeZoneSelect
              onSelect={timezone => onChange('timeZone', timezone)}
              value={timeZone}
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label required htmlFor="units">
            {PropertyToLabel.temperatureUnit}
          </Label>
          <div>
            <Select
              title={temperature ? TemperatureUnitLabels[temperature] : ''}
            >
              {temperatureUnitOptions.map(u => (
                <SelectItem
                  key={u}
                  isSelected={u === temperature}
                  onSelect={() => onChange('temperature', u)}
                >
                  {TemperatureUnitLabels[u]}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label required htmlFor="units">
            {PropertyToLabel.areaUnit}
          </Label>
          <div>
            <Select title={area ? AreaUnitLabels[area] : ''}>
              {areaUnitOptions.map(u => (
                <SelectItem
                  key={u}
                  isSelected={u === area}
                  onSelect={() => onChange('area', u)}
                >
                  {AreaUnitLabels[u]}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label required htmlFor="units">
            {PropertyToLabel.measurementSystem}
          </Label>
          <div>
            <Select
              title={
                measurementSystem
                  ? MeasurementSystemLabel[measurementSystem]
                  : undefined
              }
            >
              {MeasurementSystemOptions.map(ms => (
                <SelectItem
                  key={ms}
                  isSelected={ms === measurementSystem}
                  onSelect={() => onChange('measurementSystem', ms)}
                >
                  {MeasurementSystemLabel[ms]}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label required htmlFor="units">
            {PropertyToLabel.dateFormat}
          </Label>
          <div>
            <Select
              title={dateFormat ? DateFormatLabel[dateFormat] : undefined}
            >
              {values(DateFormat).map(tf => (
                <SelectItem
                  key={tf}
                  isSelected={tf === dateFormat}
                  onSelect={() => onChange('dateFormat', tf)}
                >
                  {DateFormatLabel[tf]}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <Label required htmlFor="units">
            {PropertyToLabel.timeFormat}
          </Label>
          <div>
            <Select
              title={timeFormat ? TimeFormatLabel[timeFormat] : undefined}
            >
              {values(TimeFormat).map(df => (
                <SelectItem
                  key={df}
                  isSelected={df === timeFormat}
                  onSelect={() => onChange('timeFormat', df)}
                >
                  {TimeFormatLabel[df]}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditUserForm;
