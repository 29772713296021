import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSentinelsByResourceId } from '../../actions/sentinels';
import { ApplicationState } from '../../reducers';

import SentinelsTable, {
  SentinelTableColumns,
} from '../Sentinels/SentinelsTable';

interface OwnProps {
  id: string;
  redirect: string;
}

const sentinelTableColumns = [
  SentinelTableColumns.CONFIG,
  SentinelTableColumns.RECIPIENTS,
  SentinelTableColumns.TARGETS,
  SentinelTableColumns.MUTED_TARGETS,
  // SentinelTableColumns.CHANNEL_TYPE,
  SentinelTableColumns.RESUME_CONFIG,
  SentinelTableColumns.MUTE,
];

function ShowSitePageSentinels({ redirect, id: siteId }: OwnProps) {
  const dispatch = useDispatch();
  const [shouldRefetchSentinels, setShouldRefetchSentinels] = useState(true);
  const sentinels = useSelector(
    ({ sentinels }: ApplicationState) => sentinels.byResourceId[siteId] || []
  );
  const getSentinelsIsLoading = useSelector(
    ({ sentinels }: ApplicationState) => sentinels.isLoadingByResourceId[siteId]
  );
  const pendingMuteTargetsUpdate = useSelector(
    ({ sentinels }: ApplicationState) => sentinels.pendingMuteTargetsUpdates
  );

  useEffect(() => {
    if (pendingMuteTargetsUpdate === 0) {
      setShouldRefetchSentinels(true);
    }
  }, [pendingMuteTargetsUpdate, setShouldRefetchSentinels]);

  useEffect(() => {
    if (!shouldRefetchSentinels) return;
    dispatch(
      getSentinelsByResourceId(siteId, {
        siteIds: [siteId],
      })
    );
    setShouldRefetchSentinels(false);
  }, [siteId, dispatch, shouldRefetchSentinels, setShouldRefetchSentinels]);
  return (
    <>
      <SentinelsTable
        dataIsLoading={getSentinelsIsLoading}
        sentinels={sentinels}
        columns={sentinelTableColumns}
        redirect={redirect}
      />
    </>
  );
}

export default ShowSitePageSentinels;
