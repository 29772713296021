import React from 'react';
import styles from './InputConditionField.module.css';
import { classNames } from '@energybox/react-ui-library/dist/utils';

type Props = {
  value: React.ReactNode;
  unit: React.ReactNode;
  inputClassName?: string;
};

const StepConditionItemInput = ({ value, unit, inputClassName }: Props) => (
  <div className={styles.container}>
    <div className={classNames(styles.input, inputClassName)}>{value}</div>
    {unit}
  </div>
);

export default StepConditionItemInput;
