import {
  DeviceType,
  GatewayApiFilter,
  SirenConfig,
} from '@energybox/react-ui-library/dist/types';
import { omit } from 'ramda';
import { ApplicationState } from '../reducers';
import { excludeFromPatchFields } from '../reducers/gateways';
import { getSiteByResourceId } from './sites';
import {
  ConfigAction,
  enqueueConfigUpdate,
  enqueueFirmwareUpdateAction,
  enqueueGatewayAction,
  GatewayAction,
} from './streamApi';

const apiBase = '/api/v1/gateways';

export enum Actions {
  GET_GATEWAYS_SUCCESS = '@gateways/GET_GATEWAYS_SUCCESS',
  GET_GATEWAYS_ERROR = '@gateways/GET_GATEWAYS_ERROR',
  GET_GATEWAYS_LOADING = '@gateways/GET_GATEWAYS_LOADING',

  GET_GATEWAYS_BY_SITE_ID_SUCCESS = '@gateways/GET_GATEWAYS_BY_SITE_ID_SUCCESS',
  GET_GATEWAYS_BY_SITE_ID_ERROR = '@gateways/GET_GATEWAYS_BY_SITE_ID_ERROR',
  GET_GATEWAYS_BY_SITE_ID_LOADING = '@gateways/GET_GATEWAYS_BY_SITE_ID_LOADING',

  GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS = '@gateways/GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS',
  GET_GATEWAYS_BY_NETWORK_GROUP_ID_ERROR = '@gateways/GET_GATEWAYS_BY_NETWORK_GROUP_ID_ERROR',
  GET_GATEWAYS_BY_NETWORK_GROUP_ID_LOADING = '@gateways/GET_GATEWAYS_BY_NETWORK_GROUP_ID_LOADING',

  GET_GATEWAY_SUCCESS = '@gateways/GET_GATEWAY_SUCCESS',
  GET_GATEWAY_ERROR = '@gateways/GET_GATEWAY_ERROR',
  GET_GATEWAY_LOADING = '@gateways/GET_GATEWAY_LOADING',

  GET_GATEWAY_ID_FROM_UUID_SUCCESS = '@gateways/GET_GATEWAY_ID_FROM_UUID_SUCCESS',
  GET_GATEWAY_ID_FROM_UUID_ERROR = '@gateways/GET_GATEWAY_ID_FROM_UUID_ERROR',
  GET_GATEWAY_ID_FROM_UUID_LOADING = '@gateways/GET_GATEWAY_ID_FROM_UUID_LOADING',

  GET_HUB_BY_PAIRED_SENSOR_ID_SUCCESS = '@gateways/GET_HUB_BY_PAIRED_SENSOR_ID_SUCCESS',
  GET_HUB_BY_PAIRED_SENSOR_ID_ERROR = '@gateways/GET_HUB_BY_PAIRED_SENSOR_ID_ERROR',
  GET_HUB_BY_PAIRED_SENSOR_ID_LOADING = '@gateways/GET_HUB_BY_PAIRED_SENSOR_ID_LOADING',

  GET_GATEWAYS_BY_RESOURCE_ID_SUCCESS = '@gateways/GET_GATEWAYS_BY_RESOURCE_ID_SUCCESS',
  GET_GATEWAYS_BY_RESOURCE_ID_ERROR = '@gateways/GET_GATEWAYS_BY_RESOURCE_ID_ERROR',
  GET_GATEWAYS_BY_RESOURCE_ID_LOADING = '@gateways/GET_GATEWAYS_BY_RESOURCE_ID_LOADING',

  GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_SUCCESS = '@gateways/GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_SUCCESS',
  GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_ERROR = '@gateways/GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_ERROR',
  GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_LOADING = '@gateways/GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_LOADING',

  GET_EDGE_DEVICES_SUCCESS = '@gateways/GET_EDGE_DEVICES_SUCCESS',
  GET_EDGE_DEVICES_ERROR = '@gateways/GET_EDGE_DEVICES_ERROR',
  GET_EDGE_DEVICES_LOADING = '@gateways/GET_EDGE_DEVICES_LOADING',

  PATCH_GATEWAY_LOADING = '@gateways/PATCH_GATEWAY_LOADING',
  PATCH_GATEWAY_ERROR = '@gateways/PATCH_GATEWAY_ERROR',
  PATCH_GATEWAY_SUCCESS = '@gateways/PATCH_GATEWAY_SUCCESS',

  DELETE_GATEWAY_SUCCESS = '@gateways/DELETE_GATEWAY_SUCCESS',
  DELETE_GATEWAY_ERROR = '@gateways/DELETE_GATEWAY_ERROR',
  DELETE_GATEWAY_LOADING = '@gateways/DELETE_GATEWAY_LOADING',

  UPDATED_QUERY = '@gateways/UPDATED_QUERY',

  TOGGLE_NEW_GATEWAY_MODAL = '@gateways/TOGGLE_NEW_GATEWAY_MODAL',
  NEW_GATEWAY = '@gateways/NEW_GATEWAY',
  UPDATE_FIELD = '@gateways/UPDATE_FIELD',
  RESET_EDIT_GATEWAY = '@gateways/RESET_EDIT_GATEWAY',

  CREATE_GATEWAY_LOADING = '@gateways/CREATE_GATEWAY_LOADING',
  CREATE_GATEWAY_SUCCESS = '@gateways/CREATE_GATEWAY_SUCCESS',
  CREATE_GATEWAY_ERROR = '@gateways/CREATE_GATEWAY_ERROR',

  DISPLAY_FORM_ERRORS = '@gateways/DISPLAY_FORM_ERRORS',
  CLEAR_FORM_ERRORS = '@gateways/CLEAR_FORM_ERRORS',

  //**** COMMAND RELATED ****///
  REFRESH_STATUS_STARTED = '@@gateways/REFRESH_STATUS_STARTED',
  REFRESH_INFO_STARTED = '@@gateways/REFRESH_INFO_STARTED',
  REFRESH_CONFIG_STARTED = '@@gateways/REFRESH_CONFIG_STARTED',
  REFRESH_SCANNED_SENSORS_STARTED = '@@gateways/REFRESH_SCANNED_SENSORS_STARTED',
  REFRESH_PAIRED_SENSORS_STARTED = '@@gateways/REFRESH_PAIRED_SENSORS_STARTED',
  REFRESH_WHITELIST_STARTED = '@@gateways/REFRESH_WHITELIST_STARTED',
  UPDATE_FIRMWARE_STARTED = '@@gateway/UPDATE_FIRMWARE_STARTED',
}

export type GetGatewayParams = {
  ids?: string[] | number[];
  organizationIds?: string[];
  siteIds?: string[];
  spaceIds?: string[];
  limit?: number;
  vendors?: string[];
  skip?: number;
  withPath?: boolean;
};

export const createQueryString = (params: GetGatewayParams = {}) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getGateway = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_GATEWAY_SUCCESS,
  error: Actions.GET_GATEWAY_ERROR,
  loading: Actions.GET_GATEWAY_LOADING,
});

export const getGatewayIdFromUuid = (
  uuid: string,
  vendor: string = 'energybox'
) => ({
  type: 'API_GET',
  path: `${apiBase}/lookup/${vendor}/${uuid}`,
  success: { type: Actions.GET_GATEWAY_ID_FROM_UUID_SUCCESS, id: uuid },
  error: Actions.GET_GATEWAY_ID_FROM_UUID_ERROR,
  loading: Actions.GET_GATEWAY_ID_FROM_UUID_LOADING,
});

export const getGateways = (params?: GetGatewayParams) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString(params)}`,
  success: Actions.GET_GATEWAYS_SUCCESS,
  error: Actions.GET_GATEWAYS_ERROR,
  loading: Actions.GET_GATEWAYS_LOADING,
});

export const getGatewaysBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString({
    siteIds: [siteId],
  } as GetGatewayParams)}`,
  success: { type: Actions.GET_GATEWAYS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_GATEWAYS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_GATEWAYS_BY_SITE_ID_LOADING, siteId },
});

export const filterGatewaysBySiteIds = (siteIds: (number | string)[]) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString({
    siteIds,
  } as GetGatewayParams)}`,
  success: { type: Actions.GET_GATEWAYS_BY_SITE_ID_SUCCESS },
  error: { type: Actions.GET_GATEWAYS_BY_SITE_ID_ERROR },
  loading: { type: Actions.GET_GATEWAYS_BY_SITE_ID_LOADING },
});

export const getGatewaysByNetworkGroupId = (
  networkGroupId: number | string
) => ({
  type: 'API_GET',
  path: `${apiBase}/network-group/${networkGroupId}`,
  success: {
    type: Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS,
    networkGroupId,
  },
  error: {
    type: Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_ERROR,
    networkGroupId,
  },
  loading: {
    type: Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_LOADING,
    networkGroupId,
  },
});

export const getUnifiedEdgeDevices = (
  organizationIds?: number[],
  siteIds?: number[] | string[]
) => {
  const params = new URLSearchParams();

  if (organizationIds && organizationIds.length > 0) {
    params.append('organizationIds', organizationIds.join(','));
  }

  if (siteIds && siteIds.length > 0) {
    params.append('siteIds', siteIds.join(','));
  }

  const queryString = params.toString();
  const url = `${apiBase}/edge-devices${queryString ? `?${queryString}` : ''}`;

  return {
    type: 'API_GET',
    path: url,
    success: Actions.GET_EDGE_DEVICES_SUCCESS,
    error: Actions.GET_EDGE_DEVICES_ERROR,
    loading: Actions.GET_EDGE_DEVICES_LOADING,
  };
};

export const patch = (id: string) => (dispatch, getState) => {
  const gateways = (getState() as ApplicationState).gateways;
  let editGatewayFields = gateways.editById[id].fields;

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: omit(
      [
        ...excludeFromPatchFields,
        // Keep network group unchanged when editing SuperHub
        editGatewayFields.model === DeviceType.EB_SUPER_HUB
          ? ['networkGroupId']
          : [],
      ],
      editGatewayFields
    ),
    loading: {
      type: Actions.PATCH_GATEWAY_LOADING,
      id,
      networkGroupId: editGatewayFields.networkGroupId,
    },
    success: [
      {
        type: Actions.PATCH_GATEWAY_SUCCESS,
        id,
        networkGroupId: editGatewayFields.networkGroupId,
      },
      getSiteByResourceId(id),
    ],
    error: {
      type: Actions.PATCH_GATEWAY_ERROR,
      id,
      networkGroupId: editGatewayFields.networkGroupId,
    },
  });
};

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_GATEWAY_LOADING, id },
  success: { type: Actions.DELETE_GATEWAY_SUCCESS, id },
  error: { type: Actions.DELETE_GATEWAY_ERROR, id },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_GATEWAY,
  id,
});

export const updateQuery = (query: string) => ({
  type: Actions.UPDATED_QUERY,
  query,
});

export const showNewGatewayModal = () => ({
  type: Actions.TOGGLE_NEW_GATEWAY_MODAL,
  value: true,
  isNewSuperHub: false,
  siteId: '',
});

export const showNewGatewayModalForSuperHub = (siteId: string) => ({
  type: Actions.TOGGLE_NEW_GATEWAY_MODAL,
  value: true,
  isNewSuperHub: true,
  siteId,
});

export const hideNewGatewayModal = () => ({
  type: Actions.TOGGLE_NEW_GATEWAY_MODAL,
  value: false,
  isNewSuperHub: false,
  siteId: '',
});

export const newGateway = (siteId: string) => ({
  type: Actions.NEW_GATEWAY,
  isNewSuperHub: true,
  siteId,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const clearFormErrors = () => {
  return {
    type: Actions.CLEAR_FORM_ERRORS,
  };
};

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const create = (lockSiteId?: number) => (dispatch, getState) => {
  dispatch({
    type: 'API_POST',
    path: apiBase,
    payload: (getState() as ApplicationState).gateways.editById['new'].fields,
    loading: Actions.CREATE_GATEWAY_LOADING,
    success: [
      Actions.CREATE_GATEWAY_SUCCESS,
      lockSiteId ? getByResourceId(lockSiteId) : '',
    ],
    error: Actions.CREATE_GATEWAY_ERROR,
  });
};

export const getHubByPairedSensorId = (pairedSensorId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/by-paired-sensor/${pairedSensorId}`,
  success: {
    type: Actions.GET_HUB_BY_PAIRED_SENSOR_ID_SUCCESS,
    pairedSensorId,
  },
  error: {
    type: Actions.GET_HUB_BY_PAIRED_SENSOR_ID_ERROR,
    pairedSensorId,
  },
  loading: {
    type: Actions.GET_HUB_BY_PAIRED_SENSOR_ID_LOADING,
    pairedSensorId,
  },
});

export const getByResourceId = (id: number) => ({
  type: 'API_GET',
  path: `${apiBase}/resource/${id}`,
  success: { type: Actions.GET_GATEWAYS_BY_RESOURCE_ID_SUCCESS, id },
  error: Actions.GET_GATEWAYS_BY_RESOURCE_ID_ERROR,
  loading: Actions.GET_GATEWAYS_BY_RESOURCE_ID_LOADING,
});

export const getPairedHubsByResourceId = (resourceId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/resource/${resourceId}`,
  success: Actions.GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_SUCCESS,
  error: Actions.GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_ERROR,
  loading: Actions.GET_SENSOR_PAIRED_HUBS_UNDER_RESOURCE_ID_LOADING,
});

/****** Gateway Config Update   *******/
export const changeLogLevel = (id: string, minSeverityAll: number) => async (
  dispatch: any
) => {
  const params = { minSeverityAll };
  dispatch(enqueueConfigUpdate(ConfigAction.LOG_CONFIG, id, params));
};

export const changeSirenConfig = (
  id: string,
  { max_time, mute_time, default_armed, events, ev_delay }: SirenConfig
) => async (dispatch: any) => {
  const params = { max_time, mute_time, default_armed, events, ev_delay };

  dispatch(enqueueConfigUpdate(ConfigAction.SIREN_CONFIG, id, params));
};

export const changeNetworkInterface = (id: string, value: number) => async (
  dispatch: any
) => {
  const params = { value };
  dispatch(enqueueConfigUpdate(ConfigAction.NETWORK_INTERFACE, id, params));
};
/****** Gateway Comands  *******/

export const testLED = (id: string) => async (dispatch: any) => {
  dispatch(enqueueGatewayAction(GatewayAction.TOGGLE_LED, id));
};

export const testBuzzer = (id: string) => async (dispatch: any) => {
  dispatch(enqueueGatewayAction(GatewayAction.TOGGLE_BUZZER, id));
};
export const refreshScannedSensors = (id: string) => async (dispatch: any) => {
  // dispatch({ type: Actions.REFRESH_SCANNED_SENSORS_STARTED, id });
  dispatch(enqueueGatewayAction(GatewayAction.EMIT_SCANNED_SENSOR_LIST, id));
};

export const refreshPairedSensors = (id: string) => async (dispatch: any) => {
  // dispatch({ type: Actions.REFRESH_PAIRED_SENSORS_STARTED, id });
  dispatch(enqueueGatewayAction(GatewayAction.EMIT_PAIRED_SENSOR_LIST, id));
};

export const refreshWhitelist = (id: string) => async (dispatch: any) => {
  // dispatch({ type: Actions.REFRESH_WHITELIST_STARTED, id });
  dispatch(enqueueGatewayAction(GatewayAction.EMIT_SENSORS_WHITELIST, id));
};

export const refreshGatewayStatus = (id: string) => async (dispatch: any) => {
  // dispatch({ type: Actions.REFRESH_STATUS_STARTED, id });
  dispatch(enqueueGatewayAction(GatewayAction.EMIT_DEVICE_STATUS, id));
};

export const refreshGatewayInfo = (id: string) => async (dispatch: any) => {
  // dispatch({ type: Actions.REFRESH_INFO_STARTED, id });
  dispatch(enqueueGatewayAction(GatewayAction.EMIT_DEVICE_INFO, id));
};

export const refreshGatewayConfig = (id: string) => async (dispatch: any) => {
  // dispatch({ type: Actions.REFRESH_CONFIG_STARTED, id });
  dispatch(enqueueGatewayAction(GatewayAction.EMIT_DEVICE_CONFIG, id));
};

export const clearGatewayBuffer = (id: string) => async (dispatch: any) => {
  // dispatch({ type: Actions.REFRESH_CONFIG_STARTED, id });
  dispatch(enqueueGatewayAction(GatewayAction.CLEAR_BUFFER, id));
};

/***
 * Siren Related Commands
 */
export const activateSiren = (id: string) => async (dispatch: any) => {
  dispatch(enqueueGatewayAction(GatewayAction.SIREN_ACTIVATE, id));
};

export const deactivateSiren = (id: string) => async (dispatch: any) => {
  dispatch(enqueueGatewayAction(GatewayAction.SIREN_DEACTIVATE, id));
};

export const sirenOff = (id: string) => async (dispatch: any) => {
  dispatch(enqueueGatewayAction(GatewayAction.SIREN_OFF, id));
};

export const sirenOn = (id: string, expireSeconds: number) => async (
  dispatch: any
) => {
  dispatch(enqueueGatewayAction(GatewayAction.SIREN_ON, id, { expireSeconds }));
};

export const sirenMute = (id: string, expireSeconds: number) => async (
  dispatch: any
) => {
  dispatch(
    enqueueGatewayAction(GatewayAction.SIREN_MUTE, id, { expireSeconds })
  );
};

/*****************************/

export const reboot = (id: string) => async (dispatch: any) => {
  dispatch(enqueueGatewayAction(GatewayAction.REBOOT, id));
  // dispatch({ type: Actions.REFRESH_STATUS_STARTED, id });
  // dispatch({ type: Actions.REFRESH_INFO_STARTED, id });
  // dispatch({ type: Actions.REFRESH_CONFIG_STARTED, id });
};

export const addToWhitelist = (id: string, uuid: string) => async (
  dispatch: any
) => {
  setTimeout(() => refreshScannedSensors(id)(dispatch), 1000);
  setTimeout(() => refreshWhitelist(id)(dispatch), 1000);
  dispatch(
    enqueueGatewayAction(GatewayAction.ADD_SENSOR_TO_WHITELIST, id, {
      sensorId: uuid,
    })
  );
};

export const removeFromWhitelist = (id: string, uuid: string) => async (
  dispatch: any
) => {
  setTimeout(() => refreshWhitelist(id)(dispatch), 1000);
  dispatch(
    enqueueGatewayAction(GatewayAction.REMOVE_SENSOR_FROM_WHITELIST, id, {
      sensorId: uuid,
    })
  );
};

type FirmwareUpdateProps = {
  vendor: string;
  id: string;
  version: string;
  filePath: string;
  useTLS?: boolean;
};

export const updateFirmware = ({
  vendor,
  id,
  version,
  filePath,
  useTLS = true,
}: FirmwareUpdateProps) => async (dispatch: any) => {
  dispatch({ type: Actions.UPDATE_FIRMWARE_STARTED, id });
  dispatch(enqueueFirmwareUpdateAction(vendor, id, version, filePath, useTLS));
};

export const setGatewaysApiFilter = (filter?: GatewayApiFilter) => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return `/api/v1/gateways?${queryParams.toString()}`;
};
