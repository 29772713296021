import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions as StreamActions } from '../actions/streamApi';

const initialState = {
  subscribeReportById: {},
  reportStatusById: {},
};
export type ReportStatusById = {
  id: {};
  jobState: string;
};

export type SubscribedReportWorker = {
  subscribeReportById: any;
  reportStatusById: any;
};

const subscribedReportWorker = (state = initialState, action: any) => {
  switch (action.type) {
    case StreamActions.RECEIVED_SUBSCRIBED_REPORT: {
      return pipe(
        assocPath(['subscribeReportById', action.payload], action.payload)
      )(state);
    }

    case StreamActions.RECEIVED_REPORT_STATUS: {
      return {
        ...state,
        reportStatusById: action.payload,
      };
    }

    default:
      return state;
  }
};

export default subscribedReportWorker;
