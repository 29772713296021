import {
  Site,
  UserRole,
  User,
  UserGroup,
} from '@energybox/react-ui-library/dist/types';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchEquipment } from '../actions/equipmentFetch';
import { fetchGateways } from '../actions/gatewaysFetch';
import { openModal } from '../actions/modal';
import { fetchSensors } from '../actions/sensorsFetch';
import { getSites } from '../actions/sites';
import { fetchSpaces } from '../actions/spacesFetch';
import { getUsers } from '../actions/users';
import { getGroups as getUserGroups } from '../actions/user_groups';
import history from '../history';
import { ApplicationState } from '../reducers';
import { StepType } from '../types/reminderWizard';
import ReminderWizard from '../components/ReminderWizard/ReminderWizard';
import { getReminderById } from '../actions/reminder';
import { Loader } from '@energybox/react-ui-library/dist/components';
import styles from '../components/ReminderWizard/ReminderWizard.module.css';

type Props = {
  getReminderById: typeof getReminderById;
  userRole: UserRole;
  getUsers: typeof getUsers;
  getUserGroups: typeof getUserGroups;
  getSites: typeof getSites;
  users: User[];
  userGroups: UserGroup[];
  step?: StepType;
  id: string;
  sites: Site[];
  openModal: typeof openModal;
};

function ConnectedReminderWizard(props: Props) {
  const {
    id,
    step = StepType.NONE,
    userRole,
    getReminderById,
    getUsers,
    getUserGroups,
    getSites,
    users = [],
    userGroups,
    sites = [],
    openModal,
  } = props;

  useEffect(() => {
    if (id && id !== 'new') {
      getReminderById(+id);
    }
    getUsers();
    getUserGroups();
    getSites();
  }, [id, getReminderById, getUsers, getUserGroups, getSites]);

  if (id !== 'new' && step === StepType.NONE) {
    history.push(`/reminder/${id}/${StepType.rollout}`);
    return null;
  }

  if (id === 'new' && step === StepType.NONE) {
    history.push(`/reminder/new/${StepType.type}`);
    return null;
  }

  return (
    <>
      {sites.length !== 0 ? (
        <ReminderWizard
          id={id}
          step={step}
          userRole={userRole}
          users={users}
          userGroups={userGroups}
          sites={sites}
          openModal={openModal}
          fetchEquipment={fetchEquipment}
          fetchSpaces={fetchSpaces}
          fetchGateways={fetchGateways}
          fetchSensors={fetchSensors}
        />
      ) : (
        <Loader className={styles.loader} />
      )}
    </>
  );
}

const mapStateToProps = (
  { app, users, userGroups, sites }: ApplicationState,
  ownProps: { id: string }
) => ({
  userRole: app.currentUser?.role ?? UserRole.VIEWER,
  users: Object.values(users.usersById),
  userGroups: Object.values(userGroups.userGroupsById),
  sites: Object.values(sites.sitesById),
});

const mapDispatchToProps = (dispatch: any) => ({
  getReminderById: id => dispatch(getReminderById(id)),
  getUsers: () => dispatch(getUsers()),
  getUserGroups: () => dispatch(getUserGroups()),
  getSites: () => dispatch(getSites()),
  openModal: modal => dispatch(openModal(modal)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedReminderWizard);
