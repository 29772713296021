import {
  defaultLocale,
  EnergyProCt,
  EnergySpiderCt,
  EnergySpiderFlexCt,
  EquipmentGroup,
  EquipmentType,
  Locale,
  MeasurementSystem,
  ResourceType,
  DateFormat,
  MapDateFormat,
  MapFullTimeFormat,
  MapTimeFormat,
  TimeFormat,
  SiteEnergyCalculationMode,
  CurrentUser,
  TimeZone,
  Firmware,
  AcPowerSystem,
  BreakerRatings,
  ElectricalPhase,
  EnergyDeviceModel,
  Role,
  AccessResource,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';

import * as R from 'ramda';
import { Actions } from '../actions/app';
import { Actions as StreamApiActions } from '../actions/streamApi';
import { Actions as UserActions } from '../actions/users';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import { shouldPasswordUpdateEnforced } from '@energybox/react-ui-library/dist/utils/security';

export interface LoginForm {
  email: string;
  password: string;
}

export interface ForgotPasswordForm {
  email: string;
}

export interface ResetPasswordForm {
  email: string;
  password: string;
  passwordVerify: string;
}

export interface BreakerArrangement {
  id: string;
  title: string;
}

export interface BreakerType {
  id: string;
  poles: number;
  title: string;
}

export interface DistributionPanelType {
  id: string;
  energySource: string;
  title: string;
  columns: number;
}

export interface EnergyCalculationMode {
  id: SiteEnergyCalculationMode;
  title: string;
}

export interface EnergySource {
  id: string;
  phases: number;
  title: string;
}

const mapScopeToAccessResource = scope => ({
  id: scope.id,
  role: scope.role ? Role[scope.role] : undefined,
  resourceType: scope.resource?._entity
    ? ResourceType[scope.resource._entity.toUpperCase()]
    : undefined,
  resourceId: scope.resource?.id || scope.resourceId,
  title: scope.resource?.title,
});

const firmwareFromApiResponse = (data: any) => ({
  id: data.id,
  createdAt: data.createdAt,
  description: data.description,
  filePath: data.filePath,
  originalFilename: data.originalFilename,
  releaseDate: data.releaseDate,
  tags: data.tags,
  type: data.type,
  updatedAt: data.updatedAt,
  version: data.version,
  gatewayModels: data.gatewayModels,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
});

export type EquipmentGroupsById = {
  [id: number]: EquipmentGroup;
};

export type EquipmentTypesById = {
  [id: number]: EquipmentType;
};

export interface App {
  isInitialised: boolean;
  accessToken?: string;
  streamConnected: boolean;
  locale: Locale;
  currentOrganizationId?: number;
  currentUser?: CurrentUserPlus;
  loginForm: LoginForm;
  forgotPasswordForm: ForgotPasswordForm;
  resetPasswordForm: ResetPasswordForm;
  isForgotPasswordLoading: boolean;
  isResetPasswordLoading: boolean;
  isResetPasswordEmailSent: boolean;
  isPasswordReset: boolean;
  lastResetEmail?: string;
  timezones?: TimeZone[];
  equipmentTypes?: EquipmentType[];
  equipmentTypesById?: EquipmentTypesById;
  equipmentTypesBySite?: EquipmentType[];
  equipmentTypesByIdWithinSite?: EquipmentTypesById;
  equipmentTypesByOrg?: EquipmentType[];
  equipmentTypesByIdWithinOrg?: EquipmentTypesById;
  equipmentGroups?: EquipmentGroup[];
  equipmentGroupsById?: EquipmentGroupsById;
  firmwares?: Firmware[];
  acPowerSystems?: AcPowerSystem[];
  breakerArrangements?: BreakerArrangement[];
  breakerRatings?: BreakerRatings;
  breakerTypes?: BreakerType[];
  distributionPanelTypes?: DistributionPanelType[];
  energyCalculationModes?: EnergyCalculationMode[];
  electricalPhases?: ElectricalPhase[];
  energySources?: EnergySource[];
  energyDeviceModels?: EnergyDeviceModel[];
  energyProCts?: EnergyProCt[];
  energySpiderCts?: EnergySpiderCt[];
  energySpiderFlexCts?: EnergySpiderFlexCt[];
  apiError: ApiError;
  isMobileMenuOpen: boolean;
  lastServerTimeFromApi?: string;
  passwordUpdateEnforced?: boolean;
  newPasswordPopupDismissed: boolean;
}

const sanitizedApiTimezone = (data: any) => ({
  title: data.title,
  commonName: data.title.replace(/_/g, ' '),
  offset: data.offset,
  offsetMinutes: data.offsetMinutes,
});

const defaultLoginForm = {
  email: '',
  password: '',
};

const defaultForgotPasswordForm = {
  email: '',
};

const defaultResetPasswordForm = {
  email: '',
  password: '',
  passwordVerify: '',
};

export const initialState: App = {
  locale: defaultLocale,
  isInitialised: false,
  streamConnected: false,
  loginForm: defaultLoginForm,
  forgotPasswordForm: defaultForgotPasswordForm,
  resetPasswordForm: defaultResetPasswordForm,
  isResetPasswordEmailSent: false,
  isPasswordReset: false,
  isForgotPasswordLoading: false,
  isResetPasswordLoading: false,
  apiError: {},
  isMobileMenuOpen: false,
  newPasswordPopupDismissed: false,
};

//Using this temporarily for prod hotfix
//can't update RUL to use new type
export interface CurrentUserPlus extends CurrentUser {
  accessResources?: AccessResource[];
}

const currentUserFromApiResponse = (data: any): CurrentUserPlus => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  phone: data.phone,
  role: data.role || undefined,
  accessResources: data.scopes?.map(mapScopeToAccessResource),
  temperature: data.temperature,
  area: data.area,
  measurementSystem: data.measurementSystem,
  organizationId: data.organization && data.organization.id,
  organizationType:
    (data.organization && data.organization.organizationType) ||
    OrganizationType.CUSTOMER,
  // unify currentUser object typing between Connect and ONE
  // unused for now, change when needed in the future
  organizationTitle: '',
  dashboardProfiles: [],
  email: data.email,
  userStatus: data.userStatus,
  passwordUpdatedAt: data.passwordUpdatedAt,
  position: data.position,
});

const tokenMeta = (token: string) => {
  if (!token) return;

  const tokenData = JSON.parse(atob(token.split('.')[1]));

  return {
    currentOrganizationId: tokenData.org,
  };
};

const determineLocale = (
  measurementSystem: MeasurementSystem | undefined,
  dateFormat: DateFormat | undefined,
  timeFormat: TimeFormat | undefined
): Locale => {
  const {
    measurementSystem: defaultMeasurementSystem,
    dateFormat: defaultDateFormat,
    timeFormat: defaultTimeFormat,
    dateTimeFormat: defaultDateTimeFormat,
    fullTimeFormat: defaultFullTimeFormat,
    fullDateTimeFormat: defaultFullDateTimeFormat,
  } = defaultLocale;

  return {
    measurementSystem: measurementSystem || defaultMeasurementSystem,
    timeFormat: timeFormat ? MapTimeFormat[timeFormat] : defaultTimeFormat,
    dateFormat: dateFormat ? MapDateFormat[dateFormat] : defaultDateFormat,
    dateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapTimeFormat[timeFormat]}`
        : defaultDateTimeFormat,
    fullTimeFormat: timeFormat
      ? MapFullTimeFormat[timeFormat]
      : defaultFullTimeFormat,
    fullDateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapFullTimeFormat[timeFormat]}`
        : defaultFullDateTimeFormat,
  };
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.INIT:
    case Actions.INIT_WITH_TOKEN:
      return {
        ...state,
        isInitialised: true,
      };

    case Actions.ACCESS_TOKEN_UPDATED:
      return {
        ...state,
        accessToken: action.accessToken,
        ...tokenMeta(action.accessToken),
      };

    case Actions.SET_LAST_SERVER_TIME:
      return R.assoc('lastServerTimeFromApi', action.time)(state);

    case Actions.LOGIN_SUCCESS:
      return R.pipe(
        R.assoc('loginForm', defaultLoginForm),
        R.assoc('apiError', {}),
        R.assoc('isResetPasswordEmailSent', false),
        R.assoc('isPasswordReset', false)
      )(state);

    case Actions.LOGIN_ERROR:
      return {
        ...state,
        apiError: storeAPIerror(action),
      };

    case Actions.FORGOT_PASSWORD_SUCCESS:
      return R.pipe(
        R.assoc('apiError', {}),
        R.assoc('isResetPasswordEmailSent', true),
        R.assoc('isForgotPasswordLoading', false),
        R.assoc('lastResetEmail', action.email)
      )(state);

    case Actions.FORGOT_PASSWORD_LOADING:
      return R.assoc('isForgotPasswordLoading', true, state);

    case Actions.FORGOT_PASSWORD_ERROR:
      return R.pipe(
        R.assoc('apiError', storeAPIerror(action)),
        R.assoc('isForgotPasswordLoading', false)
      )(state);

    case Actions.RESET_PASSWORD_SUCCESS:
      return R.pipe(
        R.assoc('isInitialised', true),
        R.assoc('apiError', {}),
        R.assoc('isPasswordReset', true),
        R.assoc('isResetPasswordLoading', false)
      )(state);

    case Actions.RESET_PASSWORD_LOADING:
      return R.assoc('isResetPasswordLoading', true, state);

    case Actions.RESET_PASSWORD_ERROR:
      return R.pipe(
        R.assoc('apiError', storeAPIerror(action)),
        R.assoc('isResetPasswordLoading', false)
      )(state);

    case UserActions.PATCH_USER_SUCCESS: {
      const { id, measurementSystem, timeFormat, dateFormat } = action.data;
      if (id === state.currentUser?.id) {
        return R.pipe(
          R.assoc('currentUser', currentUserFromApiResponse(action.data)),
          R.assoc(
            'locale',
            determineLocale(measurementSystem, dateFormat, timeFormat)
          )
        )(state);
      } else return state;
    }

    case Actions.CURRENT_USER_SUCCESS:
      const { measurementSystem, timeFormat, dateFormat } = action.data;
      const currentUser = currentUserFromApiResponse(action.data);
      return R.pipe(
        R.assoc('currentUser', currentUser),
        R.assoc(
          'passwordUpdateEnforced',
          shouldPasswordUpdateEnforced(
            currentUser,
            process.env.REACT_APP_ENFORCE_PASSWORD_CHANGE_DATE || '2023-07-12',
            state.lastServerTimeFromApi
          )
        ),
        R.assoc(
          'locale',
          determineLocale(measurementSystem, dateFormat, timeFormat)
        )
      )(state);

    case Actions.DISMISS_NEW_PASSWORD_POPUP:
      return R.assoc('newPasswordPopupDismissed', true)(state);

    case Actions.GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action.data.map(sanitizedApiTimezone),
      };

    case Actions.GET_EQUIPMENT_TYPES_SUCCESS:
      return {
        ...state,
        equipmentTypes: action.data,
        equipmentTypesById: mapArrayToObject(action.data),
      };

    case Actions.GET_EQUIPMENT_TYPES_BY_SITE_SUCCESS:
      return {
        ...state,
        equipmentTypesBySite: action.data,
        equipmentTypesByIdWithinSite: mapArrayToObject(action.data),
      };

    case Actions.GET_EQUIPMENT_TYPES_BY_ORG_SUCCESS:
      return {
        ...state,
        equipmentTypesByOrg: action.data,
        equipmentTypesByIdWithinOrg: mapArrayToObject(action.data),
      };

    case Actions.GET_EQUIPMENT_GROUPS_SUCCESS:
      return {
        ...state,
        equipmentGroups: action.data,
        equipmentGroupsById: mapArrayToObject(action.data),
      };
    case Actions.GET_FIRMWARES_SUCCESS:
      return {
        ...state,
        firmwares: mapValues(action.data, firmwareFromApiResponse),
      };

    case Actions.GET_AC_POWER_SYSTEMS_SUCCESS:
      return {
        ...state,
        acPowerSystems: action.data,
      };

    case Actions.GET_BREAKER_ARRANGEMENTS_SUCCESS:
      return {
        ...state,
        breakerArrangements: action.data,
      };
    case Actions.GET_BREAKER_RATINGS_SUCCESS:
      return {
        ...state,
        breakerRatings: action.data,
      };
    case Actions.GET_BREAKER_TYPES_SUCCESS:
      return {
        ...state,
        breakerTypes: action.data,
      };
    case Actions.GET_DISTRIBUTION_PANEL_TYPES_SUCCESS:
      return {
        ...state,
        distributionPanelTypes: action.data,
      };

    case Actions.GET_ENERGY_CALCULATION_MODES_SUCCESS:
      return {
        ...state,
        energyCalculationModes: action.data,
      };
    case Actions.GET_ELECTRICAL_PHASES_SUCCESS:
      return {
        ...state,
        electricalPhases: action.data,
      };
    case Actions.GET_ENERGY_SOURCES_SUCCESS:
      return {
        ...state,
        energySources: action.data,
      };
    case Actions.GET_ENERGY_DEVICE_MODELS_SUCCESS:
      return {
        ...state,
        energyDeviceModels: action.data,
      };
    case Actions.GET_ENERGY_PRO_CTS_SUCCESS:
      return {
        ...state,
        energyProCts: action.data,
      };
    case Actions.GET_ENERGY_SPIDER_CTS_SUCCESS:
      return {
        ...state,
        energySpiderCts: action.data,
      };
    case Actions.GET_ENERGY_SPIDER_FLEX_CTS_SUCCESS:
      return {
        ...state,
        energySpiderFlexCts: action.data,
      };
    case Actions.UPDATE_LOGIN_FIELD:
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          [action.field]: action.value,
        },
      };
    case Actions.UPDATE_FORGOT_PASSWORD_FIELD:
      return {
        ...state,
        forgotPasswordForm: {
          ...state.forgotPasswordForm,
          [action.field]: action.value,
        },
      };
    case Actions.UPDATE_RESET_PASSWORD_FIELD:
      return {
        ...state,
        resetPasswordForm: {
          ...state.resetPasswordForm,
          [action.field]: action.value,
        },
      };

    case Actions.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen,
      };

    case StreamApiActions.CONNECTED:
      return {
        ...state,
        streamConnected: true,
      };

    case StreamApiActions.CLOSED:
      return {
        ...state,
        streamConnected: false,
      };

    default:
      return state;
  }
};

export const isSignedIn = (state: App) => {
  return !!state.accessToken;
};
