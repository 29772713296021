import {
  hideNewControlModal,
  hideDeleteControlModal,
  hideEditControlModal,
} from './controls';
import {
  WorkingMode,
  TemperatureControl,
} from '@energybox/react-ui-library/dist/types';
import { formatDeltas } from '../utils/delta';

const apiBase = '/api/v1';

export enum Actions {
  CREATE_TEMPERATURE_CONTROLS_SUCCESS = '@temperature_controls/CREATE_TEMPERATURE_CONTROLS_SUCCESS',
  CREATE_TEMPERATURE_CONTROLS_ERROR = '@temperature_controls/CREATE_TEMPERATURE_CONTROLS_ERROR',
  CREATE_TEMPERATURE_CONTROLS_LOADING = '@temperature_controls/CREATE_TEMPERATURE_CONTROLS_LOADING',

  GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_SUCCESS = '@temperature_controls/GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_SUCCESS',
  GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_ERROR = '@temperature_controls/GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_ERROR',
  GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_LOADING = '@temperature_controls/GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_LOADING',

  PATCH_TEMPERATURE_CONTROLS_LOADING = '@temperature_controls/PATCH_TEMPERATURE_CONTROLS_LOADING',
  PATCH_TEMPERATURE_CONTROLS_SUCCESS = '@temperature_controls/PATCH_TEMPERATURE_CONTROLS_SUCCESS',
  PATCH_TEMPERATURE_CONTROLS_ERROR = '@temperature_controls/PATCH_TEMPERATURE_CONTROLS_ERROR',

  DELETE_TEMPERATURE_CONTROLS_SUCCESS = '@temperature_controls/DELETE_TEMPERATURE_CONTROLS_SUCCESS',
  DELETE_TEMPERATURE_CONTROLS_ERROR = '@temperature_controls/DELETE_TEMPERATURE_CONTROLS_ERROR',
  DELETE_TEMPERATURE_CONTROLS_LOADING = '@temperature_controls/DELETE_TEMPERATURE_CONTROLS_LOADING',

  PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_LOADING = '@temperature_controls/PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_LOADING',
  PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_SUCCESS = '@temperature_controls/PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_SUCCESS',
  PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_ERROR = '@temperature_controls/PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_ERROR',

  UPDATE_FIELD = '@temperature_controls/UPDATE_FIELD',
  RESET_EDIT_TEMPERATURE_CONTROLS = '@temperature_controls/RESET_EDIT_TEMPERATURE_CONTROLS',
  DISPLAY_FORM_ERRORS = '@temperature_controls/DISPLAY_FORM_ERRORS',
}

export const getTemperatureControlsByEquipmentId = (
  equipmentId: string | number
) => ({
  type: 'API_GET',
  path: `${apiBase}/temperature/equipment/${equipmentId}`,
  success: {
    type: Actions.GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId,
  },
  error: {
    type: Actions.GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_ERROR,
    equipmentId,
  },
  loading: {
    type: Actions.GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_LOADING,
    equipmentId,
  },
});

export const patchTemperatureControls = (params: TemperatureControl) => ({
  type: 'API_PUT',
  path: `${apiBase}/temperature/${params.id}`,
  payload: formatDeltas(params),
  success: [Actions.PATCH_TEMPERATURE_CONTROLS_SUCCESS, hideEditControlModal()],
  error: Actions.PATCH_TEMPERATURE_CONTROLS_ERROR,
  loading: Actions.PATCH_TEMPERATURE_CONTROLS_LOADING,
});

export const patchTemperatureControlWorkingMode = (
  equipmentId: string,
  temperatureControlId: number,
  workingMode: WorkingMode,
  edgeUuid: string | undefined
) => ({
  type: 'API_PATCH',
  path: `${apiBase}/temperature/${temperatureControlId}/working-mode`,
  payload: { workingMode, propagateConfig: true },
  success: [
    {
      type: Actions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_SUCCESS,
      temperatureControlId,
      edgeUuid,
    },
    getTemperatureControlsByEquipmentId(equipmentId),
  ],
  error: {
    type: Actions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_ERROR,
    temperatureControlId,
    edgeUuid,
  },
  loading: {
    type: Actions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_LOADING,
    temperatureControlId,
    edgeUuid,
  },
});

export const createTemperatureControls = (params: TemperatureControl) => ({
  type: 'API_POST',
  path: `${apiBase}/temperature`,
  payload: formatDeltas(params),
  success: [
    Actions.CREATE_TEMPERATURE_CONTROLS_SUCCESS,
    getTemperatureControlsByEquipmentId(params.equipmentId),
    hideNewControlModal(),
  ],
  error: Actions.CREATE_TEMPERATURE_CONTROLS_ERROR,
  loading: Actions.CREATE_TEMPERATURE_CONTROLS_LOADING,
});

export const deleteTemperatureControls = (temperatureControlId: number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/temperature/${temperatureControlId}`,
  success: [
    {
      type: Actions.DELETE_TEMPERATURE_CONTROLS_SUCCESS,
      temperatureControlId,
    },
    hideDeleteControlModal(),
  ],
  error: Actions.DELETE_TEMPERATURE_CONTROLS_ERROR,
  loading: Actions.DELETE_TEMPERATURE_CONTROLS_LOADING,
});

export const updateField = (
  id: string | number,
  field: string,
  value: any
) => ({
  type: Actions.UPDATE_FIELD,
  id: `${id}`,
  field,
  value,
});

export const reset = (id: string | number, equipmentId: string | number) => ({
  type: Actions.RESET_EDIT_TEMPERATURE_CONTROLS,
  id,
  equipmentId,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});
