import { Space } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import {
  getCounts,
  getSpace,
  primeForDelete,
  showDeleteModal,
  showEditModal,
  showNewModal,
} from '../../actions/spaces';
import SpaceCard from '../../components/SpaceCard';
import { ApplicationState } from '../../reducers';
import { Counts } from '../../reducers/spaces';

interface OwnProps {
  id: string;
  paddingLeft: number;
}

interface State {
  expanded: boolean;
}

interface Props extends OwnProps {
  load: () => void;
  counts: Counts;
  space: Space;
  nestedSpaces: string[];
  showDeleteModal: typeof showDeleteModal;
  showNewModal: typeof showNewModal;
  showEditModal: typeof showEditModal;
  primeForDelete: typeof primeForDelete;
  getSpace: typeof getSpace;
}

class SpaceCardContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { expanded: false };
  }

  componentDidMount = () => {
    this.props.load();
  };

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const {
      space,
      nestedSpaces,
      counts,
      id,
      showDeleteModal,
      showNewModal,
      showEditModal,
      primeForDelete,
      getSpace,
      paddingLeft,
    } = this.props;
    const { expanded } = this.state;

    return (
      <SpaceCard
        id={id}
        title={space.title}
        nestedSpaces={nestedSpaces}
        equipmentCount={counts.equipment}
        gatewayCount={counts.gateways}
        sensorCount={counts.sensors}
        showDeleteModal={showDeleteModal}
        showNewModal={showNewModal}
        showEditModal={showEditModal}
        primeForDelete={primeForDelete}
        getSpace={getSpace}
        toggleExpand={this.toggleExpand}
        expanded={expanded}
        paddingLeft={paddingLeft}
      />
    );
  }
}
// This will have to change to a call to the api later
const mapStateToProps = (
  { sensors, equipment, gateways, spaces }: ApplicationState,
  { id }: OwnProps
) => {
  return {
    space: spaces.spacesById[id],
    nestedSpaces: (spaces.spaceIdsByParentId[id] || []).map(id =>
      id.toString()
    ),
    counts: spaces.countsById[id] || {
      spaces: 'Loading...',
      sensors: 'Loading...',
      equipment: 'Loading...',
      gateways: 'Loading...',
    },
  };
};

const mapDispatchToProps = (dispatch, { id }: OwnProps) => ({
  load: () => dispatch(getCounts(id)),
  showDeleteModal: () => dispatch(showDeleteModal()),
  showNewModal: id => dispatch(showNewModal(id)),
  showEditModal: id => dispatch(showEditModal(id)),
  primeForDelete: id => dispatch(primeForDelete(id)),
  getSpace: id => dispatch(getSpace(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpaceCardContainer);
