import {
  Button,
  FormText,
  InputField,
  Label,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  ComponentTypes,
  GenericErrors,
  Site,
  Sop,
  SopTypeCategory,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SopActions,
  changeSopType,
  create,
  displayFormErrors,
  getOrgSopAppliedCounts,
  getOrgSopAppliedCountsByEquipmentType,
  getSop,
  getSopByOrganizationalUnitId,
  hideNewOrEditCostSopModal,
  hideNewOrEditPolicySopModal,
  update,
  updateField,
} from '../../actions/sops';
import ModalFormContent from '../../components/ModalFormContent';
import history from '../../history';
import { ApplicationState } from '../../reducers';
import {
  EditableFields,
  OrgSopAppliedCountsByEquipmentType,
} from '../../reducers/sop';
import { CreateNewText, PropertyToLabel } from '../../types/global';
import {
  doorOpenMaxDurationFields,
  energyTariffFields,
  equipmentOperatingHoursFields,
  humidityRangeFields,
  laborRateFields,
  maintenanceVisitRateFields,
  normalPowerConsumption,
  OrgSopAppliedCounts,
  OrgSopAppliedCountType,
  productValueFields,
  siteOperatingHoursFields,
  SopTypeMapping,
  temperatureRangeFields,
} from '../../types/sop';
import { HVAC_CONTROL_EQUIPMENT_TYPES } from '../../types/hvacControl';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import { sopComponentTypeAppliesToEquipment } from '../../utils/sops';
import MultiSelectEquipmentType from '../Selects/MultiSelectEquipmentType';
import SelectSopComponentType from '../Selects/SelectSopComponentType';
import styles from './NewOrEditSopModal.module.css';

type OwnProps = {
  setOrgUnitId?: number;
  sopId: string;
  resetSop: () => void;
  sopTypeCategory?: SopTypeCategory;
  disableTypeSelect?: boolean;
};

interface Props extends OwnProps {
  isVisible: boolean;
  isCustomizing?: boolean;
  getSop: () => void;
  getSopByOrganizationalUnitId: () => void;
  getOrgSopAppliedCounts: () => void;
  getOrgSopAppliedCountsByEquipmentType: (equipmentTypeIds: number[]) => void;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onCreate: (category: string | undefined) => void;
  onUpdate: (category: string | undefined) => void;
  changeSopType: (sopType: SopTypes) => void;
  formErrors: GenericErrors;
  fields?: EditableFields;
  isLoading: boolean;
  apiError: ApiError;
  displayFormErrors: (id: string) => void;
  formErrorsVisible: boolean;
  site?: Site;
  currentSops: Sop[];
  orgSopAppliedCounts?: OrgSopAppliedCounts;
  isOrgSopCountsLoading: boolean;
  orgSopAppliedCountsByEquipmentType: OrgSopAppliedCountsByEquipmentType;
  isOrgSopCountsByEquipmentTypeLoading: boolean;
}

interface State {
  hasRepeatingSiteSopTypeError: boolean;
  hasRepeatingEquipmentSopTypeError: boolean;
  repeatingEquipmentTypeIds: number[];
  isShowingPrompt: boolean;
}

class NewOrEditSopModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasRepeatingSiteSopTypeError: false,
      hasRepeatingEquipmentSopTypeError: false,
      repeatingEquipmentTypeIds: [],
      isShowingPrompt: false,
    };
  }

  componentDidMount() {
    this.props.getSopByOrganizationalUnitId();
    this.props.getOrgSopAppliedCounts();
  }

  componentDidUpdate(prevProps) {
    const {
      sopId,
      setOrgUnitId,
      fields,
      onChange,
      getSop,
      isVisible,
      getSopByOrganizationalUnitId,
      getOrgSopAppliedCounts,
    } = this.props;

    if (
      sopId === 'new' &&
      setOrgUnitId &&
      fields &&
      fields.organizationUnitId === -1
    ) {
      onChange('organizationUnitId', setOrgUnitId);
    }

    if (setOrgUnitId !== prevProps.setOrgUnitId) {
      getSopByOrganizationalUnitId();
      getOrgSopAppliedCounts();
    }

    if (sopId !== prevProps.sopId && sopId !== 'new') {
      getSop();
    }

    if (
      (isVisible !== prevProps.isVisible && isVisible) ||
      (fields && !prevProps.fields)
    ) {
      this.checkRepeatingSiteSopTypeError(
        this.props.fields?.components[0].type
      );
      this.checkRepeatingEquipmentSopTypeError(
        this.props.fields?.components[0].type,
        this.props.fields?.equipmentTypeIds
      );
    }
  }

  render() {
    const {
      isVisible,
      onClose,
      onChange,
      isLoading,
      isOrgSopCountsLoading,
      isCustomizing,
      formErrors,
      fields,
      apiError,
      sopId,
      formErrorsVisible,
      disableTypeSelect,
    } = this.props;

    const { hasRepeatingSiteSopTypeError, isShowingPrompt } = this.state;

    if (!isVisible || !fields) {
      return null;
    }

    if (isShowingPrompt) {
      return this.renderConfirmPrompt();
    }

    const {
      title,
      description,
      components,
      resourceIds,
      equipmentTypeIds,
    } = fields;

    const actions = isOrgSopCountsLoading ? (
      <Loader size={12} />
    ) : (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={this.onSopSubmit}>
          {sopId === 'new' ? 'Add' : 'Save'}
        </Button>
      </>
    );

    const shouldDisableSopComponentTypeSelect =
      sopId !== 'new' || isCustomizing || disableTypeSelect;

    const isOrgLevel = this.determineParentIdFromPath() === -1;

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose>
        <ModalTitle>{this.determineFormTitle(sopId)}</ModalTitle>
        <ModalContent>
          <ModalFormContent>
            <div>
              <Label required>
                <b>{PropertyToLabel.title}</b>
              </Label>
            </div>
            <div>
              <InputField
                type="text"
                name="title"
                autoComplete="title"
                value={title}
                onChange={({ currentTarget }) =>
                  onChange(currentTarget.name, currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.title}
                customErrorText={formErrors.title && formErrors.title[0]}
              />
            </div>
            <div>
              <Label>
                <b>{PropertyToLabel.description}</b>
              </Label>
            </div>
            <div>
              <InputField
                type="text"
                name="description"
                autoComplete="description"
                value={description}
                onChange={({ currentTarget }) =>
                  onChange(currentTarget.name, currentTarget.value)
                }
              />
            </div>
            <div>
              <Label required>
                <b>
                  {this.props.sopTypeCategory === 'cost'
                    ? PropertyToLabel.cost
                    : PropertyToLabel.policy}
                </b>
              </Label>
            </div>
            <div>
              <SelectSopComponentType
                isOrgLevel={isOrgLevel}
                sopTypeCategory={this.props.sopTypeCategory}
                error={hasRepeatingSiteSopTypeError}
                customErrorText={
                  'The selected SOP type is already in use. Please update the existing assignment or delete it to create a new one.'
                }
                disabled={shouldDisableSopComponentTypeSelect}
                value={components[0] ? components[0].type : undefined}
                onSelect={sopType => this.onSopSelect(sopType)}
              />

              <div className={styles.description}>
                {!hasRepeatingSiteSopTypeError && (
                  <Label disabled={shouldDisableSopComponentTypeSelect}>
                    {components[0]
                      ? SopTypeMapping[components[0].type].hint
                      : 'Description'}
                  </Label>
                )}
              </div>
            </div>
            {this.renderComponentFields(
              components[0],
              0,
              equipmentTypeIds,
              fields
            )}
          </ModalFormContent>

          <div className={styles.smallFont}>
            <FormText>
              <Label>* Mandatory fields</Label>
            </FormText>
          </div>

          {renderAPIerror(apiError, SopActions.CREATE_SOP_ERROR)}
        </ModalContent>
      </Modal>
    );
  }

  renderComponentFields(
    component: ComponentTypes,
    index,
    equipmentTypeIds,
    fields
  ) {
    if (!component) return <></>;

    const { onChange, formErrors, formErrorsVisible, site } = this.props;

    const {
      hasRepeatingEquipmentSopTypeError,
      repeatingEquipmentTypeIds,
    } = this.state;

    const { type } = component;

    let componentFields;
    componentFields = this.determineFieldsForSopType(type);

    componentFields = componentFields.concat(
      this.determineAttachedToValue(type)
    );

    if (sopComponentTypeAppliesToEquipment(type)) {
      const equipmentTypeFields = [
        {
          name: 'equipmentTypeIds',
          component: (onChange, value, index) => (
            <div className={styles.equipmentTypeMultiSelectContainer}>
              <MultiSelectEquipmentType
                onSelect={id => {
                  const selectedEquipmentTypeIds = [...equipmentTypeIds, id];
                  onChange('equipmentTypeIds', selectedEquipmentTypeIds);
                  this.checkRepeatingEquipmentSopTypeError(
                    type,
                    selectedEquipmentTypeIds
                  );
                }}
                onToggleSelectAll={selectedEquipmentTypeIds => {
                  onChange('equipmentTypeIds', selectedEquipmentTypeIds);
                  this.checkRepeatingEquipmentSopTypeError(
                    type,
                    selectedEquipmentTypeIds
                  );
                }}
                onRemoveEquipmentType={removeId => {
                  const selectedEquipmentTypeIds = equipmentTypeIds.filter(
                    id => id !== removeId
                  );
                  onChange('equipmentTypeIds', selectedEquipmentTypeIds);
                  this.checkRepeatingEquipmentSopTypeError(
                    type,
                    selectedEquipmentTypeIds
                  );
                }}
                selectedEquipmentTypeIds={equipmentTypeIds}
                error={
                  (formErrorsVisible && !!formErrors.equipmentTypeIds) ||
                  hasRepeatingEquipmentSopTypeError
                }
                errorEquipmentTypeIds={repeatingEquipmentTypeIds}
                customErrorText={
                  formErrors.equipmentTypeIds?.[0] ||
                  'The selected Equipment type(s) are already in use with this SOP type. Please update the existing assignment or delete it to create a new one.'
                }
                disabledEquipmentTypes={
                  type === SopTypes.TEMPERATURE_RANGE
                    ? HVAC_CONTROL_EQUIPMENT_TYPES
                    : undefined
                }
              />
            </div>
          ),
        },
      ];
      componentFields = componentFields.concat(equipmentTypeFields);
    }

    const isOperatingHoursSop =
      type === SopTypes.EQUIPMENT_OPERATING_HOURS ||
      type === SopTypes.SITE_OPERATING_HOURS;
    return componentFields.map(item => (
      <React.Fragment key={item.name}>
        <div>
          <Label required>
            <b>{PropertyToLabel[item.name]}</b>
          </Label>
        </div>
        <div>
          {['value', 'tariff'].includes(item.name) ? (
            <>
              {this.renderCostSopValue(
                type,
                item,
                component[item.name],
                index,
                onChange,
                formErrorsVisible && !!formErrors.components,
                formErrors.components?.[0]
              )}
            </>
          ) : (
            <div>
              {item.component(
                onChange,
                isOperatingHoursSop ? fields[item.name] : component[item.name],
                index,
                formErrorsVisible &&
                  (item.name === 'timetableId'
                    ? !!formErrors.timetableId
                    : !!formErrors.components),
                item.name === 'timetableId'
                  ? formErrors.timetableId?.[0]
                  : formErrors.components?.[0],
                site?.id
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    ));
  }

  renderCostSopValue(
    type,
    item,
    componentName,
    index,
    onChange,
    error,
    customErrorText
  ) {
    return (
      <div className={styles.inputFieldContainer}>
        <div className={styles.inputField}>
          <span className={styles.marginRight}>
            {item.component(
              onChange,
              componentName,
              index,
              error,
              customErrorText
            )}
          </span>
          <span className={styles.smallFont}>
            {this.determineSopRateLabel(type)}
          </span>
        </div>
      </div>
    );
  }

  renderConfirmPrompt() {
    const {
      orgSopAppliedCounts,
      orgSopAppliedCountsByEquipmentType,
      isOrgSopCountsByEquipmentTypeLoading,
      fields,
      sopId,
    } = this.props;
    const { siteCount } = orgSopAppliedCounts || {};
    let siteGenericCount,
      siteLocalisedCount,
      equipmentGenericCount,
      equipmentLocalisedCount;

    if (!orgSopAppliedCounts) {
      return null;
    }

    if (orgSopAppliedCounts.countType === OrgSopAppliedCountType.SITE) {
      siteGenericCount = siteCount?.generic.length;
      siteLocalisedCount = siteCount?.localised.length;
    } else if (
      orgSopAppliedCounts.countType === OrgSopAppliedCountType.EQUIPMENT
    ) {
      /*
       * Equipment count can increase/decrease if new equipment type(s) are chosen or existing equipment type(s) are removed,
       * That's why we have to call extra API to get the possible attachment numbers
       */
      if (isOrgSopCountsByEquipmentTypeLoading) {
        siteGenericCount = siteLocalisedCount = equipmentGenericCount = equipmentLocalisedCount = (
          <Loader size={8} className={styles.countLoader} />
        );
      } else {
        (equipmentGenericCount = 0), (equipmentLocalisedCount = 0);
        const matchingGenericSiteIds: number[] = [];
        const matchingLocalisedSiteIds: number[] = [];
        Object.keys(orgSopAppliedCountsByEquipmentType)
          .filter(
            eqTypeId =>
              fields && fields.equipmentTypeIds.indexOf(Number(eqTypeId)) > -1
          )
          .forEach(eqTypeId => {
            orgSopAppliedCountsByEquipmentType[eqTypeId].forEach(equipment => {
              const matchingGenericSop = equipment.generic.find(
                sop => String(sop.sopId) === String(sopId)
              );
              const matchingLocalisedSop = equipment.localised.find(
                sop => sop.sopComponentType === fields?.components?.[0]?.type
              );
              equipmentGenericCount += matchingGenericSop ? 1 : 0;
              equipmentLocalisedCount += matchingLocalisedSop ? 1 : 0;
              matchingGenericSop &&
                matchingGenericSiteIds.push(equipment.siteId);
              matchingLocalisedSop &&
                matchingLocalisedSiteIds.push(equipment.siteId);
            });
          });
        siteGenericCount = [...new Set(matchingGenericSiteIds)].length;
        siteLocalisedCount = [...new Set(matchingLocalisedSiteIds)].length;
      }
    }

    return (
      <Modal
        onClose={this.handleClosePrompt}
        actions={
          <>
            <Button variant="text" onClick={this.handleClosePrompt}>
              Cancel
            </Button>
            <Button onClick={this.handleConfirmPrompt}>Update</Button>
          </>
        }
      >
        <div className={styles.prompt}>
          <div className={styles.promptTitle}>SOP Confirmation Message</div>
          {(siteGenericCount !== undefined ||
            equipmentGenericCount !== undefined) && (
            <div className={styles.promptContent}>
              This SOP update will be implemented to{' '}
              <b>
                {siteGenericCount}
                <span> Sites</span>
              </b>
              {equipmentGenericCount ? (
                <>
                  <span> and to </span>
                  <b>
                    {equipmentGenericCount}
                    <span> Equipment</span>
                  </b>
                </>
              ) : (
                ''
              )}
              .
            </div>
          )}
          {siteLocalisedCount !== undefined && (
            <div className={styles.promptContent}>
              <b>
                {siteLocalisedCount}
                <span> Sites</span>
                {equipmentLocalisedCount ? (
                  <>
                    <span> and </span>
                    {equipmentLocalisedCount}
                    <span> Equipment</span>
                  </>
                ) : (
                  ''
                )}
                <span> will remain with local SOP Preferences.</span>
              </b>
            </div>
          )}
        </div>
      </Modal>
    );
  }

  determineFormTitle(sopId) {
    if (sopId === 'new') {
      switch (this.props.sopTypeCategory) {
        case 'cost':
          return CreateNewText.SOP_COST;
        case 'policy':
          return CreateNewText.SOP_POLICY;
        default:
          return CreateNewText.SOP;
      }
    } else {
      switch (this.props.sopTypeCategory) {
        case 'cost':
          return 'Update Cost SOP';
        case 'policy':
          return 'Update Policy SOP';
        default:
          return 'Update SOP';
      }
    }
  }

  determineFieldsForSopType(type) {
    switch (type) {
      case SopTypes.PRODUCT_VALUE:
        return productValueFields;
      case SopTypes.MAINTENANCE_VISIT_RATE:
        return maintenanceVisitRateFields;
      case SopTypes.LABOR_RATE:
        return laborRateFields;
      case SopTypes.ENERGY_TARIFF:
        return energyTariffFields;
      case SopTypes.DOOR_OPENED_MAX_DURATION:
        return doorOpenMaxDurationFields;
      case SopTypes.HUMIDITY_RANGE:
        return humidityRangeFields;
      case SopTypes.NORMAL_POWER_CONSUMPTION:
        return normalPowerConsumption;
      case SopTypes.TEMPERATURE_RANGE:
        return temperatureRangeFields;
      case SopTypes.SITE_OPERATING_HOURS:
        return siteOperatingHoursFields;
      case SopTypes.EQUIPMENT_OPERATING_HOURS:
        return equipmentOperatingHoursFields;
      default: {
        return <></>;
      }
    }
  }

  determineSopRateLabel(type) {
    switch (type) {
      case SopTypes.ENERGY_TARIFF:
        return '/kWh';
      case SopTypes.PRODUCT_VALUE:
        return '/Content';
      case SopTypes.MAINTENANCE_VISIT_RATE:
        return '/Visit';
      case SopTypes.LABOR_RATE:
        return '/Hour';
      default:
        return '';
    }
  }

  determineAttachedToValue(sopType) {
    let parentId = this.determineParentIdFromPath();

    let label: string | undefined;

    if (sopComponentTypeAppliesToEquipment(sopType)) {
      label = 'Equipment Types';
    } else {
      label =
        Number(parentId) > -1
          ? this.props.site?.title.toString()
          : 'Organization';
    }

    return [
      {
        name: 'organizationUnit',
        component: () => (
          <div className={styles.disabled}>
            <Label>{label}</Label>
          </div>
        ),
      },
    ];
  }

  determineParentIdFromPath() {
    const parentIdMatch = history.location.pathname.match(/\d+/);
    return parentIdMatch !== null ? parentIdMatch[0] : -1;
  }

  onSopSubmit = () => {
    const {
      formErrors,
      displayFormErrors,
      onCreate,
      onUpdate,
      sopId,
      sopTypeCategory,
      fields,
      onChange,
      setOrgUnitId,
      orgSopAppliedCounts,
      getOrgSopAppliedCountsByEquipmentType,
    } = this.props;
    const hasRepeatingSiteSopTypeError = this.checkRepeatingSiteSopTypeError(
      fields?.components[0].type
    );
    const hasRepeatingEquipmentSopTypeError = this.checkRepeatingEquipmentSopTypeError(
      fields?.components[0].type,
      fields?.equipmentTypeIds
    );

    if (
      hasKeys(formErrors) ||
      hasRepeatingSiteSopTypeError ||
      hasRepeatingEquipmentSopTypeError
    ) {
      displayFormErrors(sopId);
    } else {
      if (
        fields?.resourceIds.length === 0 &&
        !sopComponentTypeAppliesToEquipment(fields?.components[0].type)
      ) {
        onChange('resourceIds', [setOrgUnitId]);
      }

      if (sopId === 'new') {
        onCreate(sopTypeCategory);
      } else {
        // only updating Org level SOPs would trigger the confirm box, not site level ones
        if (orgSopAppliedCounts) {
          fields?.equipmentTypeIds.length &&
            getOrgSopAppliedCountsByEquipmentType(fields.equipmentTypeIds);
          this.setState({ isShowingPrompt: true });
        } else {
          onUpdate(sopTypeCategory);
        }
      }
    }
  };

  handleClosePrompt = () => {
    const { onClose } = this.props;
    onClose();
    this.setState({ isShowingPrompt: false });
  };

  handleConfirmPrompt = () => {
    const { onUpdate, sopTypeCategory } = this.props;
    onUpdate(sopTypeCategory);
    this.setState({ isShowingPrompt: false });
  };

  onSopSelect = sopType => {
    const { changeSopType } = this.props;
    changeSopType(sopType);
    this.checkRepeatingSiteSopTypeError(sopType);
  };

  checkRepeatingSiteSopTypeError = sopType => {
    const { currentSops, sopId, setOrgUnitId } = this.props;
    const existingSopTypes = [
      ...new Set(
        currentSops
          .filter(
            sop =>
              String(sop.id) !== String(sopId) &&
              sop.organizationUnitId === setOrgUnitId
          )
          .map(sop => sop.components?.[0]?.type)
      ),
    ];
    const hasRepeatingSiteSopTypeError =
      existingSopTypes.indexOf(sopType) > -1 &&
      !sopComponentTypeAppliesToEquipment(sopType);
    this.setState({
      hasRepeatingSiteSopTypeError,
    });
    return hasRepeatingSiteSopTypeError;
  };

  checkRepeatingEquipmentSopTypeError = (sopType, selectedEquipmentTypeIds) => {
    const { currentSops, sopId, setOrgUnitId } = this.props;
    const sameTypeSops = currentSops.filter(
      sop =>
        String(sop.id) !== String(sopId) &&
        sop.components?.[0]?.type === sopType &&
        sop.organizationUnitId === setOrgUnitId
    );
    const usedEquipmentTypeIds = [
      ...new Set(
        sameTypeSops
          .map(sop => sop.equipmentTypeIds || [])
          .reduce((acc, ids) => acc.concat(ids), [])
      ),
    ];
    let repeatingEquipmentTypeIds = [];
    if (usedEquipmentTypeIds.length > 0) {
      repeatingEquipmentTypeIds =
        selectedEquipmentTypeIds?.filter(
          id => usedEquipmentTypeIds.indexOf(id) > -1
        ) || [];
    }
    const hasRepeatingEquipmentSopTypeError =
      repeatingEquipmentTypeIds.length > 0;
    this.setState({
      hasRepeatingEquipmentSopTypeError,
      repeatingEquipmentTypeIds,
    });
    return hasRepeatingEquipmentSopTypeError;
  };
}

const isVisible = (sops, sopCategoryType) => {
  switch (sopCategoryType) {
    case 'policy':
      return sops.showNewOrEditPolicyModal;
    case 'cost':
      return sops.showNewOrEditCostModal;
    default:
      return false;
  }
};

const mapStateToProps = (
  { sops, app }: ApplicationState,
  { sopId, sopTypeCategory, setOrgUnitId }: OwnProps
) => ({
  isVisible: isVisible(sops, sopTypeCategory),
  ...sops.editById[sopId],
  currentSops: (
    (setOrgUnitId && sops.organizationUnitIdToSopIds[setOrgUnitId]) ||
    []
  )
    .map(id => sops.sopsById[String(id)])
    .filter(s => !!s)
    .filter(s => String(s.organizationUnitId) === String(setOrgUnitId)),
  orgSopAppliedCounts: sops.orgSopAppliedCountsBySopId?.[sopId],
  isOrgSopCountsLoading: sops.isOrgSopCountsLoading,
  orgSopAppliedCountsByEquipmentType: sops.orgSopAppliedCountsByEquipmentType,
  isOrgSopCountsByEquipmentTypeLoading:
    sops.isOrgSopCountsByEquipmentTypeLoading,
});

const mapDispatchToProps = (
  dispatch,
  { sopId, resetSop, sopTypeCategory, setOrgUnitId }: OwnProps
) => ({
  onClose: () => {
    if (sopId !== 'new') {
      resetSop();
    }

    switch (sopTypeCategory) {
      case 'policy':
        return dispatch(hideNewOrEditPolicySopModal());
      case 'cost':
        return dispatch(hideNewOrEditCostSopModal());
      default:
        return;
    }
  },
  getSop: () => dispatch(getSop(String(sopId))),
  getSopByOrganizationalUnitId: () =>
    setOrgUnitId && dispatch(getSopByOrganizationalUnitId(setOrgUnitId)),
  getOrgSopAppliedCounts: () =>
    setOrgUnitId && dispatch(getOrgSopAppliedCounts(setOrgUnitId)),
  getOrgSopAppliedCountsByEquipmentType: equipmentTypeIds =>
    setOrgUnitId &&
    dispatch(
      getOrgSopAppliedCountsByEquipmentType(setOrgUnitId, equipmentTypeIds)
    ),
  changeSopType: (value: SopTypes) => dispatch(changeSopType(sopId, value)),
  onChange: (field: string, value: string) =>
    dispatch(updateField(sopId, field, value)),
  onCreate: (category: string | undefined) =>
    category && dispatch(create(category, setOrgUnitId)),
  onUpdate: (category: string | undefined) =>
    category && dispatch(update(sopId, category)),
  displayFormErrors: id => dispatch(displayFormErrors(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOrEditSopModal);
