import { global } from '@energybox/react-ui-library/dist/utils';

export const HumdityState = {
  0: 'HUMIDITY_OFF',
  1: 'HUMIDIFY',
  2: 'DEHUMIDIFY',
};

export const HumidityMode = {
  0: 'HUMIDITY_OFF',
  1: 'HUMIDIFY',
  2: 'DEHUMIDIFY',
  3: 'HUMIDITY_AUTO',
};

export const FAN_STATE = {
  FAN_ACTIVE: 'Fan Active',
  FAN_INACTIVE: 'Fan Idle',
};

export const HeatingLabel = {
  Stage1: 'Heating Active - Stage 1',
  Stage2: 'Heating Active - Stage 2',
};

export const CoolingLabel = {
  Stage1: 'Cooling Active - Stage 1',
  Stage2: 'Cooling Active - Stage 2',
};

export const IdleLabel = 'Idle';

export const SensorSourceMap: { [key: number]: string } = {
  4: 'Wireless',
  2: 'Wired', // external
  1: 'Internal', // onboard
  0: 'Not configured',
};

export const HumidityModeLabel = (humidityMode: string): string | undefined => {
  switch (humidityMode) {
    case 'HUMIDIFY':
      return 'Humidity Mode : Humidify'; // external
    case 'DEHUMIDIFY':
      return 'Humidity Mode : Dehumidify'; // onboard
    case 'HUMIDITY_OFF':
      return 'Humidity Mode : OFF';
    case 'HUMIDITY_AUTO':
      return 'Humidity Mode : AUTO';
    default:
      return global.NOT_AVAILABLE;
  }
};

export const HumidityStateLabel = (
  humidityMode: string
): string | undefined => {
  switch (humidityMode) {
    case 'HUMIDIFY':
      return 'Humidifying'; // external
    case 'DEHUMIDIFY':
      return 'Dehumidifying'; // onboard
    case 'HUMIDITY_OFF':
      return 'Idle';
  }
};

export const formatHumidRange = (
  humidityMode,
  humidifySetPoint,
  dehumidifySetPoint
): string | undefined => {
  switch (humidityMode) {
    case 'HUMIDITY_AUTO':
      return (
        ' Humidity SetPoints : ' +
        humidifySetPoint +
        '% - ' +
        dehumidifySetPoint +
        '%'
      );
    case 'HUMIDIFY':
      return ' Humidify SetPoint - ' + humidifySetPoint + '%';
    case 'DEHUMIDIFY':
      return ' DeHumidify SetPoint - ' + dehumidifySetPoint + '%';
    case 'HUMIDITY_OFF':
      return '';
  }
};
