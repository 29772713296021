import CardList, {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  Site,
  Equipment,
  EquipmentType,
  Sop,
} from '@energybox/react-ui-library/dist/types';
import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import { Trash as TrashIcon } from '@energybox/react-ui-library/dist/icons';

import React from 'react';
import { openModal } from '../../../../actions/modal';
import SiteSelector from '../../SiteSelector';
import StepTitle from '../StepTitle';
import styles from './StepRollout.module.css';
import { Errors, TargetedEntitiesMappingBySiteId } from '../../ReminderWizard';
import { ReminderType } from '@energybox/react-ui-library/dist/types/Reminder';
import StepRolloutEquipmentHeader from './StepRolloutEquipmentHeader';
import StepRolloutEquipment from './StepRolloutEquipment';
import { uniqBy, path } from 'ramda';
import { processSamplesData } from '@energybox/react-ui-library/dist/utils';

type Props = {
  orgId: number;
  sites: Site[];
  sops: Sop[];
  equipments: Equipment[];
  targets: TargetedEntitiesMappingBySiteId;
  reminderType: ReminderType;
  equipmentTypes: EquipmentType[];
  selectedEquipmentType: EquipmentType[];
  setSelectedEquipmentType: (equipmentTypes: EquipmentType[]) => void;
  onTargetChange: (siteId: string | number, sopId: string | number) => void;
  onTargetsChange: (sops: Sop[], sites: Site[]) => void;
  onTargetsDelete: (sops: Sop[], sites: Site[]) => void;
  openModal: typeof openModal;
  targetedSites: Site[] | undefined;
  targetedSitesLoading: boolean;
  setTargetedSites: (sitesList: Site[]) => void;
  errors: Errors;
  setErrors: (errors: Errors) => void;
  setIsSelectedSiteError: (value) => void;
  isSelectedSiteError: boolean;
  setIsSelectedSopError: (value) => void;
  isSelectedSopError: boolean;
  updateEquipmentList: (list: any) => void;
  newListEquipment: any;
  upDatedSentielId: (sentinelId: any) => void;
};

type State = {
  sites: Site[];
  sitesExpanded: number[];
  isLoading: boolean;
  isSelectAll: boolean;
  allSelectedSites: Equipment[];
  selectedSiteList: any[];
  selectAllEquipData: any[];
  selectedSop: TargetedEntitiesMappingBySiteId;
  equipments: Equipment[];
  equipmentTypes: EquipmentType[];
  selectedEquipmentType: EquipmentType[];
};

function removeSiteFromReminderModal(
  openModalFn: typeof openModal,
  actionFn: any,
  targetId: number,
  targetTitle: string
) {
  openModalFn({
    title: 'Remove site',
    description: `Are you sure you would like to remove the site "${targetTitle}" from this configuration?`,
    actionFn: () => {
      actionFn(targetId);
    },
  });
}

class StepRolloutContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sites: props.targetedSites || [],
      sitesExpanded: [],
      isLoading: props.targetedSitesLoading,
      isSelectAll: false,
      allSelectedSites: [],
      selectedSiteList: [],
      selectAllEquipData: [],
      selectedSop: props.targets,
      equipments: props.equipments,
      equipmentTypes: props.equipmentTypes,
      selectedEquipmentType: props.selectedEquipmentType,
    };
  }

  componentDidMount() {
    const dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
    if (dublicateSentinelkey) {
      this.setState({
        sites: [],
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const equipmentTypeIds = props.equipmentTypes.map(i => i.id);
    const filteredSelectedEquipmentTypes = props.selectedEquipmentType.filter(
      i => equipmentTypeIds.includes(i.id)
    );
    if (
      filteredSelectedEquipmentTypes.length !==
      props.selectedEquipmentType.length
    ) {
      props.setSelectedEquipmentType(filteredSelectedEquipmentTypes);
      return {
        ...state,
        selectedSop: props.targets,
        sites: props.targetedSites,
        isLoading: props.targetedSitesLoading,
        equipments: props.equipments,
        equipmentTypes: props.equipmentTypes,
        selectedEquipmentType: filteredSelectedEquipmentTypes,
      };
    }
    return {
      ...state,
      selectedSop: props.targets,
      sites: props.targetedSites,
      isLoading: props.targetedSitesLoading,
      equipments: props.equipments,
      equipmentTypes: props.equipmentTypes,
      selectedEquipmentType: props.selectedEquipmentType,
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const {
      targetedSites: prevTargetedSites,
      targetedSitesLoading: prevTargetedSitesLoading,
    } = prevProps;

    const { targetedSites, targetedSitesLoading } = this.props;
    if (targetedSites !== prevTargetedSites) {
      this.setState({
        sites: targetedSites || [],
      });
    }
    if (targetedSitesLoading !== prevTargetedSitesLoading) {
      this.setState({ isLoading: targetedSitesLoading });
    }
    const selectedEnteries = Object.values(this.state.selectedSop)
      .map(sopList => sopList.map(i => i.sop))
      .flat();
    if (selectedEnteries.length !== 0 && !this.state.isSelectAll) {
      this.setState({
        isSelectAll: true,
      });
    } else if (selectedEnteries.length === 0 && this.state.isSelectAll) {
      this.setState({
        isSelectAll: false,
      });
    }
  };

  componentWillUnmount() {
    const { setTargetedSites } = this.props;
    const { sites } = this.state;
    setTargetedSites(sites);
  }

  handleSelectSite = site => {
    const { sites } = this.state;
    this.props.setTargetedSites(
      sites.find(s => s.id === site.id) ? sites : [...sites, site]
    );
    this.props.setIsSelectedSiteError(false);
    this.props.setIsSelectedSopError(false);
  };

  handleSelectAllSites = selectedSites => {
    this.props.setTargetedSites(selectedSites);
    this.props.setIsSelectedSiteError(false);
    this.props.setIsSelectedSopError(false);
  };

  handleCollapseSite = (siteId: number) =>
    this.setState({
      sitesExpanded: this.state.sitesExpanded.find(id => id === siteId)
        ? this.state.sitesExpanded.filter(id => id !== siteId)
        : [...this.state.sitesExpanded, siteId],
    });

  handleRemoveSite = siteId => {
    this.setState({ isLoading: true });
    const { sites } = this.state;
    this.props.setTargetedSites(sites.filter(s => s.id !== siteId));
    this.setState({ isLoading: false });
    if (this.state.sitesExpanded.includes(siteId)) {
      this.handleCollapseSite(siteId);
    }
  };

  selectAllSop = (sops: Sop[], sites: Site[]) => {
    this.props.onTargetsChange(sops, sites);
  };

  deselectAllSop = (sops: Sop[], sites: Site[]) => {
    this.props.onTargetsDelete(sops, sites);
  };

  findOverrideSop = (sop, taskSops, orgId) => {
    return taskSops.find(item => {
      return (
        item.organizationUnitId !== orgId &&
        // sop.equipmentTypeIds === item.equipmentTypeIds
        sop.equipmentTypeIds.reduce((match, equipTypeId) => {
          return item.equipmentTypeIds.includes(equipTypeId) || match;
        }, false)
      );
    });
  };

  render() {
    const {
      orgId,
      sops,
      errors,
      reminderType,
      openModal,
      isSelectedSiteError,
      setSelectedEquipmentType,
    } = this.props;
    const {
      sites,
      isLoading,
      selectedSop,
      isSelectAll,
      equipments,
      equipmentTypes,
      selectedEquipmentType,
    } = this.state;

    const matchSelectedEquipType = id =>
      selectedEquipmentType.length === 0
        ? equipmentTypes.map(i => i.id).includes(id)
        : selectedEquipmentType.map(i => i.id).includes(id);

    const filteredSops = sops.filter(sop =>
      sop.equipmentTypeIds.some(matchSelectedEquipType)
    );

    const selectedSiteIds = sites.map(i => i.id);
    const filteredEquipmentTypesBySelectedSite =
      sops
        .filter(i => {
          const selectedSiteEquipments = equipments.filter(a => {
            if (a.space) {
              return selectedSiteIds.includes(a.space?.parentId);
            } else {
              return false;
            }
          });
          const selectedSiteEquipmentTypeIds = selectedSiteEquipments.map(
            e => e.typeId
          );
          return (
            (selectedSiteIds?.includes(i.organizationUnitId) ||
              i.organizationUnitId === orgId) &&
            selectedSiteEquipmentTypeIds.some(e =>
              i.equipmentTypeIds.includes(e)
            )
          );
        })
        .map(i => i.equipmentTypes)
        .flat() || [];

    const data: CardListRowData[] = sites.map((site: Site) => {
      const filteredEquipmentsBySiteId = equipments.filter(
        i => i.space?.parentId === site.id
      );
      const filteredEquipmentTypesBySiteId = filteredEquipmentsBySiteId.map(
        i => i.typeId
      );
      const sopsBySiteByEquipment = sops.filter(item => {
        return (
          (item.organizationUnitId === site.id ||
            item.organizationUnitId === orgId) &&
          filteredEquipmentTypesBySiteId.some(i =>
            item.equipmentTypeIds.includes(i)
          )
        );
      });
      const sopsBySiteByEquipmentByOverride = sopsBySiteByEquipment.filter(
        item => {
          const hasOverride = this.findOverrideSop(
            item,
            sopsBySiteByEquipment,
            orgId
          );
          const isOrgLevelSop = item.organizationUnitId === orgId;
          if (isOrgLevelSop && hasOverride) {
            return false;
          }
          return true;
        }
      );
      return {
        key: String(site.id),
        content: (
          <>
            <Cell width="3">{site.title}</Cell>
            {
              <Cell
                width="8"
                render={({ isExpanded }) => (
                  <StepRolloutEquipmentHeader
                    isCollapsed={isExpanded}
                    site={site}
                    sops={sopsBySiteByEquipmentByOverride}
                    targets={selectedSop}
                    selectedEquipmentType={selectedEquipmentType}
                  />
                )}
              />
            }
            <Cell width="1">
              <div
                className={styles.removeSiteContainer}
                onClick={() =>
                  removeSiteFromReminderModal(
                    openModal,
                    this.handleRemoveSite,
                    site.id,
                    site.title
                  )
                }
              >
                <TrashIcon size={16} color="var(--pink-base)" />
              </div>
            </Cell>
          </>
        ),
        extraContent: [
          <>
            <Cell width="3">
              <p>{site.address}</p>
            </Cell>

            {
              <Cell width="8">
                <StepRolloutEquipment
                  orgId={orgId}
                  site={site}
                  sops={sopsBySiteByEquipment}
                  reminderType={reminderType}
                  selected={selectedSop[site.id]?.map(item => item.sop?.id)}
                  handleTargetSelect={this.props.onTargetChange}
                  selectedEquipmentType={selectedEquipmentType}
                />
              </Cell>
            }
          </>,
        ],
      };
    });

    return (
      <div>
        <div className={styles.actionContainer}>
          <div>Site: </div>
          <div className={styles.siteSelectorContainer}>
            <SiteSelector
              onSelect={this.handleSelectSite}
              onToggleSelectAll={this.handleSelectAllSites}
              onRemoveSite={this.handleRemoveSite}
              error={isSelectedSiteError}
              customErrorText={errors.rollout.site}
              errorSiteIds={undefined}
              sites={this.props.sites}
              selectedSiteIds={this.state.sites.map(s => s.id)}
            />
          </div>
          <div className={styles.actionTargetType}>
            <GenericFilter
              title="Equipment Type"
              transformItemName={item => item.title}
              setFilter={items => setSelectedEquipmentType(items)}
              items={uniqBy(path(['id']), filteredEquipmentTypesBySelectedSite)}
              selectedItems={selectedEquipmentType}
              disabled={this.state.sites.length === 0}
            />
          </div>
        </div>
        <div
          className={
            this.state.sites.length > 0
              ? `${styles.selectAllContainer} ${styles.btnEnalbed}`
              : `${styles.selectAllContainer} ${styles.btnDisabled}`
          }
          onClick={() =>
            !isSelectAll
              ? this.selectAllSop(filteredSops, sites)
              : this.deselectAllSop(filteredSops, sites)
          }
        >
          {!isSelectAll ? 'Select all' : 'Deselect all'}
        </div>

        {isLoading && (
          <div className={styles.messageContainer}>
            ...loading configuration
          </div>
        )}

        {!isLoading && this.state.sites.length === 0 && (
          <div className={styles.messageContainer}>
            <StepTitle>
              Please select the sites you would like to assign reminders to.
            </StepTitle>

            <div>
              <span>No sites selected</span>
            </div>
          </div>
        )}

        {!isLoading && (
          <CardList
            header={[
              {
                width: '3',
                content: 'Site',
              },
              {
                width: '4',
                content: 'Selection',
              },
            ]}
            data={data}
          />
        )}

        <div className={styles.errorCenterContainer}>
          <span className={styles.errorContainer}>
            {this.props.isSelectedSopError === true
              ? this.props.errors.rollout.onSave
              : ''}
          </span>
        </div>
      </div>
    );
  }
}

export default StepRolloutContainer;
