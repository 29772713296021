import { useSubscribeToThermostat } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import { Gateway, Thermostat } from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceReadings,
  unsubscribeFromDeviceReadings,
} from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';
import styles from './ThermostatLocalOverrideLabel.module.css';

type Props = {
  thermostat: Thermostat | Gateway;
};

const ThermostatLocalOverrideLabel: React.FC<Props> = ({ thermostat }) => {
  const dispatch = useDispatch();

  const thermostatsById = useSelector(
    ({ subscribedThermostats }: ApplicationState) => {
      if (thermostat) {
        return subscribedThermostats.byId[thermostat.id];
      }
      return undefined;
    }
  );

  const subscribedThermostat = useSubscribeToThermostat(
    thermostat,
    () => thermostatsById,
    dispatch,
    subscribeToDeviceReadings,
    unsubscribeFromDeviceReadings
  );

  const isLocalOverrideActive =
    subscribedThermostat?.localAdjustmentOverrideEnergybox;

  return isLocalOverrideActive ? (
    <div className={styles.localOverrideLabel}>Local Override</div>
  ) : null;
};

export default ThermostatLocalOverrideLabel;
