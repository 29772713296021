import React from 'react';
import IndexManagementPageLayout from '../IndexManagementPageLayout';

interface Props {
  children: React.ReactNode;
  actions?: React.ReactNode;
}

const EquipmentsManagementLayout = ({ actions, children }: Props) => (
  <IndexManagementPageLayout tabs={[]} actions={actions} content={children} />
);

export default EquipmentsManagementLayout;
