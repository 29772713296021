export enum Actions {
  TOGGLE_OPEN_START_SEQUENCE_MODAL = '@run_configuration/TOGGLE_OPEN_START_SEQUENCE_MODAL',
  TOGGLE_CLOSE_START_SEQUENCE_MODAL = '@run_configuration/TOGGLE_CLOSE_START_SEQUENCE_MODAL',

  TOGGLE_OPEN_END_SEQUENCE_MODAL = '@run_configuration/TOGGLE_OPEN_END_SEQUENCE_MODAL',
  TOGGLE_CLOSE_END_SEQUENCE_MODAL = '@run_configuration/TOGGLE_CLOSE_END_SEQUENCE_MODAL',

  TOGGLE_DISABLE_RUN_TEST_BUTTON = '@run_configuration/TOGGLE_DISABLE_RUN_TEST_BUTTON',
  TOGGLE_ENABLE_RUN_TEST_BUTTON = '@run_configuration/TOGGLE_ENABLE_RUN_TEST_BUTTON',

  TOGGLE_ENABLE_START_SEQUENCE = '@run_configuration/TOGGLE_ENABLE_START_SEQUENCE',
  TOGGLE_DISABLE_START_SEQUENCE = '@run_configuration/TOGGLE_DISABLE_START_SEQUENCE',
}

export const showStartSequenceModal = () => ({
  type: Actions.TOGGLE_OPEN_START_SEQUENCE_MODAL,
  value: true,
});

export const hideStartSequenceModal = () => ({
  type: Actions.TOGGLE_CLOSE_START_SEQUENCE_MODAL,
  value: false,
});

export const showEndSequenceModal = () => ({
  type: Actions.TOGGLE_OPEN_END_SEQUENCE_MODAL,
  value: true,
});

export const hideEndSequenceModal = () => ({
  type: Actions.TOGGLE_CLOSE_END_SEQUENCE_MODAL,
  value: false,
});

export const enableRunTestButton = () => ({
  type: Actions.TOGGLE_ENABLE_RUN_TEST_BUTTON,
  value: true,
});

export const disableRunTestButton = () => ({
  type: Actions.TOGGLE_DISABLE_RUN_TEST_BUTTON,
  value: false,
});

export const enableStartSequence = () => ({
  type: Actions.TOGGLE_ENABLE_START_SEQUENCE,
  value: true,
});

export const disableStartSequence = () => ({
  type: Actions.TOGGLE_DISABLE_START_SEQUENCE,
  value: false,
});
