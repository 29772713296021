import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { UserGroup } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getGroups } from '../../actions/user_groups';
import { ApplicationState } from '../../reducers';
import { UserGroupsById } from '../../reducers/user_groups';

interface OwnProps {
  onSelect: (value: number) => void;
  value?: number;
  disabled?: boolean;
}

interface Props extends OwnProps {
  getGroups: typeof getGroups;
  installationPartners: UserGroupsById;
  error?: boolean;
  customErrorText?: string;
}

interface State {
  search: string;
}

class InstallerGroupSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { search: '' };
  }

  componentDidMount() {
    const { installationPartners, getGroups } = this.props;
    if (!installationPartners) getGroups();
  }

  handleSearchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleResetSearch() {
    this.setState({
      search: '',
    });
  }

  render() {
    const {
      installationPartners,
      value,
      onSelect,
      disabled,
      error,
      customErrorText,
    } = this.props;
    const { search } = this.state;

    const filtered = Object.values(
      installationPartners
    ).filter(installationPartner =>
      hasSubstr(installationPartner.title, search)
    );

    if (!installationPartners) {
      return <Loader size={12} />;
    } else {
      return (
        <Select
          disabled={disabled}
          title={value && installationPartners[value]?.title}
          value={value && installationPartners[value]?.title}
          onClick={this.handleResetSearch.bind(this)}
          error={error}
          customErrorText={customErrorText}
        >
          <SelectSearch
            value={search}
            onChange={this.handleSearchChange.bind(this)}
            error={filtered.length === 0}
          />

          {filtered.map(installationPartner => (
            <SelectItem
              key={installationPartner.id}
              isSelected={installationPartner.id === value}
              onSelect={() => onSelect(installationPartner.id)}
            >
              {installationPartner.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ userGroups }: ApplicationState) => ({
  installationPartners: userGroups.userGroupsById,
});

const mapDispatchToProps = {
  getGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallerGroupSelect);
