import {
  MenuDropdown,
  MenuDropdownItem,
  Button,
} from '@energybox/react-ui-library/dist/components';
import {
  CircuitBreaker,
  EnergyDevicePorts,
  EnergySensor,
} from '@energybox/react-ui-library/dist/types';
import CardList, {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  editEnergyDeviceSensorPort,
  mapSensorsInEnergyDeviceToEdit,
  showRemoveEnergyDeviceSensorModal,
  updateEnergyDeviceSensorField,
} from '../../../actions/energy_devices';
import EditEnergyDeviceSensorForm from '../../../components/EditEnergyDeviceSensorForm';
import { ApplicationState } from '../../../reducers';
import { EditEnergyDeviceSensors } from '../../../reducers/energy_devices';
import RemoveEnergyDeviceSensorModal from '../RemoveEnergyDeviceSensorModal';
import history from '../../../history';
import { Routes } from '../../../routes';
import styles from './EnergyDeviceSensorsList.module.css';

interface OwnProps {
  deviceType: string;
  sensors: EnergySensor[];
  panelId?: string;
  energyDeviceId: number;
  circuitBreakers?: CircuitBreaker[];
}

interface Props extends OwnProps {
  editEnergyDeviceSensors: EditEnergyDeviceSensors;
  mapSensorsInEnergyDeviceToEdit: (sensors: EnergySensor[]) => void;
  onChange: (port: string, field: string, value: string | number) => void;
  showRemoveEnergyDeviceSensorModal: (port: number) => void;
  editEnergyDeviceSensorPort: (port: number) => void;
}

interface State {
  primedPortForRemoveSensor: number;
}

class EnergyDeviceSensorsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      primedPortForRemoveSensor: -1,
    };
  }

  componentDidMount() {
    const { mapSensorsInEnergyDeviceToEdit, sensors } = this.props;
    mapSensorsInEnergyDeviceToEdit(sensors);
  }

  renderEnergyDevicePorts = () => {
    const { deviceType } = this.props;
    let arr: number[] = [];

    for (let i = 1; i <= EnergyDevicePorts[deviceType]; i++) {
      arr.push(i);
    }

    return arr;
  };

  isSensorActive = (num: number) => {
    const { sensors } = this.props;
    return sensors.find((sensor: EnergySensor) => sensor.port === num);
  };

  resetPrimedPortForRemoveSensor = () => {
    this.setState({ primedPortForRemoveSensor: -1 });
  };

  determineIfConnectedToMainBreaker = (port: number) => {
    const { sensors } = this.props;
    const portToCheck = sensors.find(s => s.port === port);
    if (portToCheck && portToCheck.breaker._entity === 'MainBreaker') {
      return true;
    }
    return false;
  };

  render() {
    const {
      deviceType,
      editEnergyDeviceSensors,
      circuitBreakers,
      onChange,
      sensors,
      energyDeviceId,
      showRemoveEnergyDeviceSensorModal,
      editEnergyDeviceSensorPort,
      panelId,
    } = this.props;

    const { primedPortForRemoveSensor } = this.state;
    let initialExpandedCardPort;
    if (history.location.hash.indexOf(Routes.ENERGY_DEVICE_PORTS) > -1) {
      const paramTokens = history.location.hash.split('/');
      initialExpandedCardPort = paramTokens[2];
    }

    const data: CardListRowData[] = this.renderEnergyDevicePorts().map(
      (num: number) => ({
        key: `Port ${num}`,
        startExpanded: String(num) === initialExpandedCardPort,
        content: (
          <>
            <Cell width="11">
              <span
                className={
                  this.isSensorActive(num) ? styles.isActive : styles.isInactive
                }
              >
                Port 0{num}
              </span>
            </Cell>
            {this.isSensorActive(num) && (
              <Cell width="1" cellAlign="right">
                <MenuDropdown>
                  <MenuDropdownItem
                    isRed
                    onSelect={() => {
                      this.setState({ primedPortForRemoveSensor: num });
                      showRemoveEnergyDeviceSensorModal(num);
                    }}
                  >
                    Remove sensor from port
                  </MenuDropdownItem>
                </MenuDropdown>
              </Cell>
            )}
          </>
        ),
        extraContent:
          hasKeys(editEnergyDeviceSensors) &&
          Object.keys(editEnergyDeviceSensors).filter(key => key !== 'new')
            .length === sensors.length &&
          this.isSensorActive(num)
            ? [
                <Cell width="11">
                  <EditEnergyDeviceSensorForm
                    fields={editEnergyDeviceSensors[num].fields}
                    onChange={(field: string, value: string | number) =>
                      onChange(String(num), field, value)
                    }
                    formErrorsVisible={
                      editEnergyDeviceSensors[num].formErrorsVisible
                    }
                    formErrors={editEnergyDeviceSensors[num].formErrors}
                    apiError={editEnergyDeviceSensors[num].apiError}
                    circuitBreakers={circuitBreakers}
                    deviceType={deviceType}
                    occupiedPorts={sensors.map(s => s.port)}
                    isMainBreaker={this.determineIfConnectedToMainBreaker(num)}
                    panelId={panelId}
                  />
                </Cell>,
                <Cell width="11" cellAlign="right">
                  <Button
                    disabled={!editEnergyDeviceSensors[num].isChanged}
                    variant="solid"
                    onClick={() => editEnergyDeviceSensorPort(num)}
                    children="Save"
                  />
                </Cell>,
              ]
            : undefined,
      })
    );

    return (
      <>
        <CardList data={data} />
        {primedPortForRemoveSensor !== -1 &&
          editEnergyDeviceSensors[primedPortForRemoveSensor] &&
          editEnergyDeviceSensors[primedPortForRemoveSensor]
            .showDeleteEnergyDeviceSensorModal && (
            <RemoveEnergyDeviceSensorModal
              energyDeviceId={energyDeviceId}
              apiError={
                editEnergyDeviceSensors[primedPortForRemoveSensor].apiError
              }
              primedPortForRemoveSensor={primedPortForRemoveSensor}
              resetPrimedPortForRemoveSensor={
                this.resetPrimedPortForRemoveSensor
              }
              isEnergyPro={deviceType === 'Energy Pro'}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (
  { energyDevices }: ApplicationState,
  { energyDeviceId }: OwnProps
) => {
  return {
    editEnergyDeviceSensors:
      energyDevices.editEnergyDeviceSensorsByEnergyDeviceId[energyDeviceId],
  };
};

const mapDispatchToProps = (dispatch, { energyDeviceId }: OwnProps) => ({
  mapSensorsInEnergyDeviceToEdit: (sensors: EnergySensor[]) =>
    dispatch(mapSensorsInEnergyDeviceToEdit(sensors, energyDeviceId)),
  onChange: (port: string, field: string, value: string | number) =>
    dispatch(updateEnergyDeviceSensorField(port, field, value, energyDeviceId)),
  showRemoveEnergyDeviceSensorModal: (port: number) =>
    dispatch(showRemoveEnergyDeviceSensorModal(String(energyDeviceId), port)),
  editEnergyDeviceSensorPort: (port: number) =>
    dispatch(editEnergyDeviceSensorPort(energyDeviceId, port)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyDeviceSensorsList);
