import { UtilityBill } from '@energybox/react-ui-library/dist/types';
import { FileType } from '@energybox/react-ui-library/dist/types/FileType';
import {
  BillPeriod,
  BillStatus,
  UtilityBillMetadata,
} from '@energybox/react-ui-library/dist/types/UtilityBill';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import dissocPath from 'ramda/src/dissocPath';
import { Actions as StreamActions, CommandType } from '../actions/streamApi';
import Actions from '../actions/UtilityBillActions';
import { StreamData } from '../types/upload';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';

export type BillEdit = {
  file: UtilityBill;
  newBilling: BillPeriod;
};

export type UtilityBillsBySiteId = {
  [id: string]: UtilityBill[];
};

export type ProgressByFileId = {
  [id: string]: StreamData;
};

export type EditByFileId = {
  [id: string]: BillEdit & { isLoading: boolean };
};

export type UtilityBills = {
  utilityBillIsLoading: boolean;
  utilityBillIsDeleting: boolean;
  utilityBillsBySiteId: UtilityBillsBySiteId;
  progressByFileId: ProgressByFileId;
  editByFileId: EditByFileId;
  apiError?: ApiError;
};

const initialState: UtilityBills = {
  utilityBillIsLoading: false,
  utilityBillIsDeleting: false,
  utilityBillsBySiteId: {},
  progressByFileId: {},
  editByFileId: {},
};

const filetypeMapper = {
  jpg: FileType.JPEG,
  jpeg: FileType.JPEG,
  png: FileType.PNG,
  pdf: FileType.PDF,
  tif: FileType.TIFF,
  tiff: FileType.TIFF,
};

// Sample Response data
// {
//   "fileId": "09ad293f-3419-4538-af40-9baaa4286f80",
//   "fileName": "large-jpg2.png",
//   "fileSize": 31986,
//   "fileType": "UTILITY_BILL",
//   "fileLocation": "bills/1663731019996_7b7c_large-jpg2.png",
//   "resourceId": 13743,
//   "createdAt": "2022-09-21T03:30:20.019925Z",
//   "modifiedAt": "2022-09-21T03:30:20.020165Z",
//   "metadata": {
//       "parsedUrl": null,
//       "parsedAt": null,
//       "billType": "test",
//       "provider": "testprovider",
//       "status": "PENDING",
//       "startDate": "2022-01-01",
//       "endDate": "2022-01-01",
//       "siteId": null
//   },
//   "tags": {}
// }

const toUtilityBill = ({
  fileLocation,
  metadata,
  billing,
  ...other
}: UtilityBill): UtilityBill => ({
  billing: {
    startDate: metadata.startDate,
    endDate: metadata.endDate,
  },
  fileLocation,
  metadata,
  ...other,
  fileType: filetypeMapper[(fileLocation.split('.').pop() || '').toLowerCase()],
});

const toUtilityBills = (data: UtilityBill[]): UtilityBill[] =>
  data.filter(({ fileLocation }) => !!fileLocation).map(toUtilityBill);

const utilityBills = (state: UtilityBills = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_BILLS_BY_SITE_ID_SUCCESS:
      const attachments: UtilityBill[] = action.data;
      if (attachments.length > 0) {
        return pipe(
          assocPath(
            ['utilityBillsBySiteId', action.siteId],
            toUtilityBills(attachments)
          ),
          assoc('utilityBillIsLoading', false)
        )(state);
      } else return assoc('utilityBillIsLoading', false)(state);

    case Actions.GET_BILLS_BY_SITE_ID_LOADING:
      return assoc('utilityBillIsLoading', true)(state);

    case Actions.GET_BILLS_BY_SITE_ID_ERROR:
      return pipe(
        assoc('apiError', storeAPIerror(action)),
        assoc('utilityBillIsLoading', false)
      )(state);

    case Actions.DELETE_BILLS_SUCCESS:
      const { siteId, fileId: targetId } = action;
      const existingUtilityBills = state.utilityBillsBySiteId[siteId];
      const utilityBillWithoutDeleted = existingUtilityBills.filter(
        ({ fileId }) => targetId !== fileId
      );
      return pipe(
        assocPath(['utilityBillsBySiteId', siteId], utilityBillWithoutDeleted),
        assoc('utilityBillIsDeleting', false),
        dissocPath(['apiError'])
      )(state);

    case Actions.DELETE_BILLS_LOADING:
      return assoc('utilityBillIsDeleting', true)(state);

    case Actions.DELETE_BILLS_ERROR:
      const { data: error } = action;
      return pipe(
        assoc('apiError', {
          type: Actions.DELETE_BILLS_ERROR,
          status: 500,
          message: JSON.stringify(error),
        }),
        assoc('utilityBillIsDeleting', false)
      )(state);

    case Actions.EDIT_BILL_UPDATE: {
      const edit: BillEdit = action.edit;
      return assocPath(['editByFileId', edit.file.fileId], {
        ...edit,
        isLoading: false,
      })(state);
    }

    case Actions.EDIT_BILL_CANCEL: {
      const edit: BillEdit = action.edit;
      return dissocPath(['editByFileId', edit.file.fileId])(state);
    }

    case Actions.EDIT_BILL_SUCCESS: {
      const utilityBill = toUtilityBill(action.data);

      let oldList: UtilityBill[] =
        state.utilityBillsBySiteId['' + utilityBill.resourceId];

      const newList = oldList.findIndex(
        bill => bill.fileId == utilityBill.fileId
      );

      return pipe(
        assocPath(
          ['utilityBillsBySiteId', utilityBill.resourceId, newList],
          utilityBill
        ),
        dissocPath(['editByFileId', utilityBill.fileId])
      )(state);
    }

    case Actions.EDIT_BILL_LOADING:
      return assocPath(
        ['editByFileId', action.fileId, 'isLoading'],
        true
      )(state);

    case Actions.EDIT_BILL_ERROR:
      return pipe(
        assoc('apiError', storeAPIerror(action)),
        assocPath(['editByFileId', action.fileId, 'isLoading'], false)
      )(state);

    case StreamActions.RECEIVED_FILE_INFO: {
      if (action.data) {
        return pipe(
          assocPath(
            ['progressByFileId', action.data.fileUuid],
            action.data.status
          )
        )(state);
      }
      return state;
    }

    case StreamActions.SEND_MESSAGE: {
      if (action.data.type === CommandType.UNSUBSCRIBE) {
        return pipe(
          assocPath(['progressByFileId', action.data.fileUuid], undefined)
        )(state);
      }
      return state;
    }

    default:
      return state;
  }
};

export default utilityBills;
