import {
  MuteSentinelRequest,
  NewSentinel,
  Sentinel,
  SentinelApiFilter,
} from '@energybox/react-ui-library/dist/types';
import { EnergyboxService } from '../config';
import { formatRequestArrayParam } from '../util';

const apiBase = '/api/v1/sentinels';

export enum Actions {
  GET_SENTINEL_SUCCESS = '@@sentinels/GET_SENTINEL_SUCCESS',
  GET_SENTINEL_ERROR = '@@sentinels/GET_SENTINEL_ERROR',
  GET_SENTINEL_LOADING = '@@sentinels/GET_SENTINEL_LOADING',

  GET_SENTINELS_SUCCESS = '@@sentinels/GET_SENTINELS_SUCCESS ',
  GET_SENTINELS_ERROR = '@@sentinels/GET_SENTINELS_ERROR ',
  GET_SENTINELS_LOADING = '@@sentinels/GET_SENTINELS_LOADING ',

  GET_SENTINELS_BY_RESOURCE_ID_SUCCESS = '@@sentinels/GET_SENTINELS_BY_RESOURCE_ID_SUCCESS ',
  GET_SENTINELS_BY_RESOURCE_ID_ERROR = '@@sentinels/GET_SENTINELS_BY_RESOURCE_ID_ERROR ',
  GET_SENTINELS_BY_RESOURCE_ID_LOADING = '@@sentinels/GET_SENTINELS_BY_RESOURCE_ID_LOADING ',

  CLEAR_SENTINELS_STORE = '@@sentinels/CLEAR_SENTINELS_STORE ',

  CREATE_SENTINEL_SUCCESS = '@@sentinels/CREATE_SENTINEL_SUCCESS',
  CREATE_SENTINEL_ERROR = '@@sentinels/CREATE_SENTINEL_ERROR',
  CREATE_SENTINEL_LOADING = '@@sentinels/CREATE_SENTINEL_LOADING',

  UPDATE_SENTINEL_SUCCESS = '@@sentinels/UPDATE_SENTINEL_SUCCESS',
  UPDATE_SENTINEL_ERROR = '@@sentinels/UPDATE_SENTINEL_ERROR',
  UPDATE_SENTINEL_LOADING = '@@sentinels/UPDATE_SENTINEL_LOADING',

  DELETE_SENTINEL_SUCCESS = '@@sentinels/DELETE_SENTINEL_SUCCESS',
  DELETE_SENTINEL_ERROR = '@@sentinels/DELETE_SENTINEL_ERROR',
  DELETE_SENTINEL_LOADING = '@@sentinels/DELETE_SENTINEL_LOADING',

  MUTE_SENTINEL_SUCCESS = '@@sentinels/MUTE_SENTINEL_SUCCESS',
  MUTE_SENTINEL_ERROR = '@@sentinels/MUTE_SENTINEL_ERROR',
  MUTE_SENTINEL_LOADING = '@@sentinels/MUTE_SENTINEL_LOADING',

  RESUME_SENTINEL_SUCCESS = '@@sentinels/RESUME_SENTINEL_SUCCESS',
  RESUME_SENTINEL_ERROR = '@@sentinels/RESUME_SENTINEL_ERROR',
  RESUME_SENTINEL_LOADING = '@@sentinels/RESUME_SENTINEL_LOADING',

  GET_SENTINELS_BY_EQUIPMENT_MAP_SUCCESS = '@@sentinels/GET_SENTINELS_BY_EQUIPMENT_MAP_SUCCESS',
  GET_SENTINELS_BY_EQUIPMENT_MAP_ERROR = '@@sentinels/GET_SENTINELS_BY_EQUIPMENT_MAP_ERROR',
  GET_SENTINELS_BY_EQUIPMENT_MAP_LOADING = '@@sentinels/GET_SENTINELS_BY_EQUIPMENT_MAP_LOADING',
}

export const setSentinelFilter = (filter?: SentinelApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.siteIds) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  return queryParams.toString();
};

export const createSentinel = (payload: NewSentinel) => ({
  type: 'API_POST',
  service: EnergyboxService.sentinels,
  path: apiBase,
  payload,
  success: Actions.CREATE_SENTINEL_SUCCESS,
  error: Actions.CREATE_SENTINEL_ERROR,
  loading: Actions.CREATE_SENTINEL_LOADING,
});

export const updateSentinel = (payload: Sentinel) => ({
  type: 'API_PUT',
  service: EnergyboxService.sentinels,
  path: `${apiBase}/${payload.id}`,
  payload,
  success: Actions.UPDATE_SENTINEL_SUCCESS,
  error: Actions.UPDATE_SENTINEL_ERROR,
  loading: Actions.UPDATE_SENTINEL_LOADING,
});

export const deleteSentinel = (id: string) => ({
  type: 'API_DELETE',
  service: EnergyboxService.sentinels,
  path: `${apiBase}/${id}`,
  success: { type: Actions.DELETE_SENTINEL_SUCCESS, id },
  error: { type: Actions.DELETE_SENTINEL_ERROR, id },
  loading: { type: Actions.DELETE_SENTINEL_LOADING, id },
});

export const muteSentinel = (
  id: string | number,
  parameters: MuteSentinelRequest
) => ({
  type: 'API_POST',
  service: EnergyboxService.sentinels,
  path: `/api/v1/sentinels/${id}/mute`,
  payload: parameters,
  success: { type: Actions.MUTE_SENTINEL_SUCCESS, id },
  error: { type: Actions.MUTE_SENTINEL_ERROR, id },
  loading: { type: Actions.MUTE_SENTINEL_LOADING, id },
});

export const resumeSentinel = (id: string | number, targetIds?: number[]) => ({
  type: 'API_DELETE',
  service: EnergyboxService.sentinels,
  path: `/api/v1/sentinels/${id}/mute${
    targetIds ? `/${formatRequestArrayParam(targetIds)}` : ''
  }`,
  success: { type: Actions.RESUME_SENTINEL_SUCCESS, id, targetIds },
  error: { type: Actions.RESUME_SENTINEL_ERROR, id, targetIds },
  loading: { type: Actions.RESUME_SENTINEL_LOADING, id, targetIds },
});

export function getSentinels(filter?: SentinelApiFilter) {
  return {
    type: 'API_GET',
    service: EnergyboxService.sentinels,
    path: `${apiBase}?${setSentinelFilter(filter).toString()}`,
    success: Actions.GET_SENTINELS_SUCCESS,
    error: Actions.GET_SENTINELS_ERROR,
    loading: Actions.GET_SENTINELS_LOADING,
  };
}

export function getSentinelsByResourceId(
  resourceId: string | number,
  filter?: SentinelApiFilter
) {
  return {
    type: 'API_GET',
    service: EnergyboxService.sentinels,
    path: `${apiBase}?${setSentinelFilter(filter).toString()}`,
    success: {
      type: Actions.GET_SENTINELS_BY_RESOURCE_ID_SUCCESS,
      resourceId,
    },
    error: { type: Actions.GET_SENTINELS_BY_RESOURCE_ID_ERROR, resourceId },
    loading: {
      type: Actions.GET_SENTINELS_BY_RESOURCE_ID_LOADING,
      resourceId,
    },
  };
}

export function clearSentinelsStore() {
  return {
    type: Actions.CLEAR_SENTINELS_STORE,
  };
}

export function getSentinelById(id: string) {
  return {
    service: EnergyboxService.sentinels,
    type: 'API_GET',
    path: apiBase + `/${id}`,
    success: Actions.GET_SENTINEL_SUCCESS,
    error: Actions.GET_SENTINEL_ERROR,
    loading: Actions.GET_SENTINEL_LOADING,
  };
}

export const getSentinelsByEquipmentMapping = () => {
  return {
    service: EnergyboxService.sentinels,
    type: 'API_GET',
    path: `/api/v1/sentinels/by-equipment`,
    success: Actions.GET_SENTINELS_BY_EQUIPMENT_MAP_SUCCESS,
    error: Actions.GET_SENTINELS_BY_EQUIPMENT_MAP_ERROR,
    loading: Actions.GET_SENTINELS_BY_EQUIPMENT_MAP_LOADING,
  };
};
