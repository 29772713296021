import { Actions } from '../../actions/users';
import { ApplicationState } from '../../reducers';

export const kioskUpdateLoadingStatusSelector = ({
  users,
}: ApplicationState) => {
  return users.loadingStatusByAction[
    Actions.USER_UPDATE_KIOSK_SETTINGS_LOADING
  ];
};
