import {
  TimeTableRowStore,
  TimeTableSpecialStore,
  TimeTable,
  IsoDateFormat,
} from '@energybox/react-ui-library/dist/types';
import { format } from 'date-fns';
import { formatDeltas } from '../utils/delta';

const apiBase = '/api/v1/timetables';

export enum Actions {
  GET_TIME_TABLES_SUCCESS = '@time_table/GET_TIME_TABLES_SUCCESS',
  GET_TIME_TABLES_ERROR = '@time_table/GET_TIME_TABLES_ERROR',
  GET_TIME_TABLES_LOADING = '@time_table/GET_TIME_TABLES_LOADING',

  GET_TIME_TABLE_BY_ID_SUCCESS = '@time_table/GET_TIME_TABLE_BY_ID_SUCCESS',
  GET_TIME_TABLE_BY_ID_ERROR = '@time_table/GET_TIME_TABLE_BY_ID_ERROR',
  GET_TIME_TABLE_BY_ID_LOADING = '@time_table/GET_TIME_TABLE_BY_ID_LOADING',

  PATCH_TIME_TABLE_SUCCESS = '@time_table/PATCH_TIME_TABLE_SUCCESS',
  PATCH_TIME_TABLE_ERROR = '@time_table/PATCH_TIME_TABLE_ERROR',
  PATCH_TIME_TABLE_LOADING = '@time_table/PATCH_TIME_TABLE_LOADING',

  CREATE_TIME_TABLE_SUCCESS = '@time_table/CREATE_TIME_TABLE_SUCCESS',
  CREATE_TIME_TABLE_ERROR = '@time_table/CREATE_TIME_TABLE_ERROR',
  CREATE_TIME_TABLE_LOADING = '@time_table/CREATE_TIME_TABLE_LOADING',

  DELETE_TIME_TABLE_SUCCESS = '@time_table/DELETE_TIME_TABLE_SUCCESS',
  DELETE_TIME_TABLE_ERROR = '@time_table/DELETE_TIME_TABLE_ERROR',
  DELETE_TIME_TABLE_LOADING = '@time_table/DELETE_TIME_TABLE_LOADING',

  DISPLAY_FORM_ERRORS = '@time_table/DISPLAY_FORM_ERRORS',

  ADD_TIME_TABLE_ROW = '@time_table/ADD_TIME_TABLE_ROW',
  ADD_TIME_TABLE_SPECIAL_ROW = '@time_table/ADD_TIME_TABLE_SPECIAL_ROW',
  UPDATE_FIELD = '@time_table/UPDATE_FIELD',
  UPDATE_TIME_TABLE_FIELD = '@time_table/UPDATE_TIME_TABLE_FIELD',
  REMOVE_TIME_TABLE_ROW = '@time_table/REMOVE_TIME_TABLE_ROW',
  REMOVE_SPECIAL_ROW = '@time_table/REMOVE_SPECIAL_ROW',

  RESET_TIME_TABLE = '@time_table/RESET_TIME_TABLE',

  DUPLICATE_TIME_TABLE = '@time_table/DUPLICATE_TIME_TABLE',

  TOGGLE_NEW_MODAL = '@time_table/TOGGLE_NEW_MODAL',
  TOGGLE_DELETE_MODAL = '@time_table/TOGGLE_DELETE_MODAL',
  TOGGLE_EDIT_MODAL = '@time_table/TOGGLE_EDIT_MODAL',

  PRIME_FOR_DELETE = '@time_table/PRIME_FOR_DELETE',
  UNSTAGE_DELETE = '@time_table/UNSTAGE_DELETE',

  GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_SUCCESS = '@time_table/GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_SUCCESS',
  GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_ERROR = '@time_table/GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_ERROR',
  GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_LOADING = '@time_table/GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_LOADING',

  GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS',
  GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR',
  GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING',

  GET_TIME_TABLE_COUNTS_SUCCESS = '@time_table/GET_TIME_TABLE_COUNTS_SUCCESS',
  GET_TIME_TABLE_COUNTS_ERROR = '@time_table/GET_TIME_TABLE_COUNTS_ERROR',
  GET_TIME_TABLE_COUNTS_LOADING = '@time_table/GET_TIME_TABLE_COUNTS_LOADING',

  TOGGLE_TIME_PICKER = '@time_table/TOGGLE_TIME_PICKER',

  SET_LAST_CREATED_ON_SELECT_TIMETABLE = '@time_table/SET_LAST_CREATED_ON_SELECT_TIMETABLE',
  RESET_LAST_CREATED_ON_SELECT_TIMETABLE = '@time_table/RESET_LAST_CREATED_ON_SELECT_TIMETABLE',

  GET_IS_TIMETABLE_CURRENTLY_ACTIVE_SUCCESS = '@time_table/GET_IS_TIMETABLE_CURRENTLY_ACTIVE_SUCCESS',
  GET_IS_TIMETABLE_CURRENTLY_ACTIVE_ERROR = '@time_table/GET_IS_TIMETABLE_CURRENTLY_ACTIVE_ERROR',
  GET_IS_TIMETABLE_CURRENTLY_ACTIVE_LOADING = '@time_table/GET_IS_TIMETABLE_CURRENTLY_ACTIVE_LOADING',
}

export const getTimeTables = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/organization-unit/${id}`,
  success: Actions.GET_TIME_TABLES_SUCCESS,
  error: Actions.GET_TIME_TABLES_ERROR,
  loading: Actions.GET_TIME_TABLES_LOADING,
});

export const getTimeTableById = (id: string | number | null) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_TIME_TABLE_BY_ID_SUCCESS,
  error: Actions.GET_TIME_TABLE_BY_ID_ERROR,
  loading: { type: Actions.GET_TIME_TABLE_BY_ID_LOADING, id },
});

export const getTimeTableCountsByOrgUnitId = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/resource/count/${id}`,
  success: { type: Actions.GET_TIME_TABLE_COUNTS_SUCCESS, id },
  error: { type: Actions.GET_TIME_TABLE_COUNTS_ERROR, id },
  loading: { type: Actions.GET_TIME_TABLE_COUNTS_LOADING, id },
});

export const patch = (timeTableId: number, data) => dispatch => {
  dispatch({
    type: 'API_PUT',
    path: `/api/v1/timetables/${timeTableId}`,
    payload: data,
    loading: { type: Actions.PATCH_TIME_TABLE_LOADING, timeTableId },
    success: { type: Actions.PATCH_TIME_TABLE_SUCCESS, timeTableId },
    error: { type: Actions.PATCH_TIME_TABLE_ERROR, timeTableId },
  });
};

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_TIME_TABLE_LOADING, id },
  success: { type: Actions.DELETE_TIME_TABLE_SUCCESS, id },
  error: { type: Actions.DELETE_TIME_TABLE_ERROR, id },
});

export const showEditModal = (timetableId: number | string | null) => ({
  type: Actions.TOGGLE_EDIT_MODAL,
  id: timetableId,
});

export const hideEditModal = () => ({
  type: Actions.TOGGLE_EDIT_MODAL,
  id: -1,
});

export const showNewModal = () => ({
  type: Actions.TOGGLE_NEW_MODAL,
  value: true,
});

export const hideNewModal = () => ({
  type: Actions.TOGGLE_NEW_MODAL,
  value: false,
});

export const showDeleteModal = () => ({
  type: Actions.TOGGLE_DELETE_MODAL,
  value: true,
});

export const hideDeleteModal = () => ({
  type: Actions.TOGGLE_DELETE_MODAL,
  value: false,
});

export const setLastCreatedIdOnSelectTimetable = (
  justCreatedTimetableId: number
) => ({
  type: Actions.SET_LAST_CREATED_ON_SELECT_TIMETABLE,
  justCreatedTimetableId,
});

export const resetLastCreatedIdOnSelectTimetable = () => ({
  type: Actions.RESET_LAST_CREATED_ON_SELECT_TIMETABLE,
});

export const createTimeTable = (
  payload: TimeTable,
  options?: {
    createdOnSelectTimetable?: boolean;
  }
) => dispatch => {
  dispatch({
    type: 'API_POST',
    path: `${apiBase}`,
    payload,
    loading: Actions.CREATE_TIME_TABLE_LOADING,
    success: [
      {
        type: Actions.CREATE_TIME_TABLE_SUCCESS,
        createdOnSelectTimetable: options?.createdOnSelectTimetable,
      },
      payload.organizationUnitId
        ? getTimeTables(payload.organizationUnitId)
        : '',
      hideNewModal(),
    ],
    error: Actions.CREATE_TIME_TABLE_ERROR,
  });
};

export const primeForDelete = (id: string) => ({
  type: Actions.PRIME_FOR_DELETE,
  id,
});

export const unstageDelete = () => ({
  type: Actions.UNSTAGE_DELETE,
});

export const displayFormErrors = (timeTableId?: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  timeTableId: timeTableId ? timeTableId : 'new',
});

export const addTimeTableRow = (
  specialRowIndex: number | boolean = false,
  timeTableId?: number
) => {
  return {
    type: Actions.ADD_TIME_TABLE_ROW,
    specialRowIndex,
    timeTableId: timeTableId ? timeTableId : 'new',
  };
};

export const addTimeTableSpecialRow = (timeTableId?: number) => ({
  type: Actions.ADD_TIME_TABLE_SPECIAL_ROW,
  timeTableId: timeTableId ? timeTableId : 'new',
});

export const updateField = (
  path: any[],
  field: string,
  value: string | TimeTableRowStore | TimeTableSpecialStore,
  validationType: 'timeTable' | 'timeTableRow' | 'specialTimeTableRow',
  timeTableId?: number
) => ({
  type: Actions.UPDATE_FIELD,
  path,
  field,
  value,
  validationType,
  timeTableId: timeTableId ? timeTableId : 'new',
});

export const removeTimeTableRow = (
  rowIndex: number,
  specialRowIndex?: number | boolean,
  timeTableId?: number
) => ({
  type: Actions.REMOVE_TIME_TABLE_ROW,
  specialRowIndex,
  rowIndex,
  timeTableId: timeTableId ? timeTableId : 'new',
});

export const removeSpecialRow = (rowIndex: number, timeTableId?: number) => ({
  type: Actions.REMOVE_SPECIAL_ROW,
  rowIndex,
  timeTableId: timeTableId ? timeTableId : 'new',
});

export const resetTimeTable = () => ({
  type: Actions.RESET_TIME_TABLE,
});

export const duplicateTimeTable = (
  timeTable: TimeTable,
  isCopyToSite?: boolean
) => ({
  type: Actions.DUPLICATE_TIME_TABLE,
  timeTable,
  isCopyToSite,
});

export const getTimeTablePreviewByEquipmentId = (
  equipmentId: number,
  dateString: string
) => {
  return {
    type: 'API_GET',
    path: `${apiBase}/previews/equipment/${equipmentId}?startDate=${dateString}&endDate=${dateString}`,
    success: {
      type: Actions.GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
    },
    error: {
      type: Actions.GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
    loading: {
      type: Actions.GET_TIME_TABLE_PREVIEW_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
  };
};

export const getTimeTablePreviewsByEquipmentId = (
  equipmentId: string | number,
  timezone: string,
  fromDate: Date,
  toDate?: Date
) => {
  const formattedFromDate = format(fromDate, IsoDateFormat);
  const formattedToDate = toDate
    ? format(toDate, IsoDateFormat)
    : formattedFromDate;

  return {
    type: 'API_GET',
    path: `${apiBase}/previews/equipment/${equipmentId}?startDate=${formattedFromDate}&endDate=${formattedToDate}`,
    success: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
      timezone,
    },
    error: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
    loading: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
  };
};

export const toggleTimePicker = (value: boolean) => ({
  type: Actions.TOGGLE_TIME_PICKER,
  value,
});

// TODO: this is temporary, needed for hotfix
// delete this later, as it's in RUL (we just can't update the version for prod yet)
function createGenericQueryString<T extends {}>(params: T) {
  return (
    '?' +
    Object.keys(params)
      .map(key => {
        return `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`;
      })
      .join('&')
  );
}

// TODO: this is temporary, needed for hotfix
// delete this later, as it's in RUL (we just can't update the version for prod yet)
type GetIsTimetableActiveParams = {
  dateTime: string;
  endDelta: string;
  startDelta: string;
};

export const getIsTimetableCurrentlyActive = (
  timetableId: string | number,
  siteId: string | number,
  startDelta: number,
  endDelta: number
) => {
  const formattedParams = formatDeltas({
    beginDelta: startDelta,
    endDelta,
    dateTime: new Date().toISOString(),
  });

  const paramsForQuery = {
    startDelta: encodeURIComponent(formattedParams.beginDelta),
    endDelta: encodeURIComponent(formattedParams.endDelta),
    dateTime: formattedParams.dateTime,
  };

  const queryString = createGenericQueryString<GetIsTimetableActiveParams>(
    paramsForQuery
  );

  return {
    type: 'API_GET',
    path: `${apiBase}/${timetableId}/site/${siteId}/isActive${queryString}`,
    success: {
      type: Actions.GET_IS_TIMETABLE_CURRENTLY_ACTIVE_SUCCESS,
      timetableId,
      siteId,
    },
    error: {
      type: Actions.GET_IS_TIMETABLE_CURRENTLY_ACTIVE_ERROR,
      timetableId,
      siteId,
    },
    loading: {
      type: Actions.GET_IS_TIMETABLE_CURRENTLY_ACTIVE_LOADING,
      timetableId,
      siteId,
    },
  };
};
