import { Scheduler } from '@energybox/react-ui-library/dist/types';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { ControlBoardsById } from '../../../reducers/control_boards';
import SelectLightSensorDelay from '../../Selects/SelectLightSensorDelay';
import { DEFAULT_ACTION_INTERVAL } from '../../../constants/scheduler';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

type Props = {
  activeControl: Scheduler;
  onChange: onChange;
  controlBoardsById: ControlBoardsById;
  equipmentId: number;
};

const EditModeDelay: React.FC<Props> = ({
  activeControl,
  onChange,
  controlBoardsById,
  equipmentId,
}) => {
  const schedulerId = activeControl?.id;
  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });
  const fields = schedulerControls?.fields;
  const controlBoardId = fields?.lightSensorSettings?.controlBoardId!;
  const formErrorsVisible = schedulerControls?.formErrorsVisible;
  const formErrors = schedulerControls?.formErrors;

  const isControlBoardSelected =
    isDefined(controlBoardId) && controlBoardId !== -1;

  const isDelayFieldValid: boolean = !(
    formErrorsVisible && !!formErrors?.['lightSensorSettings.actionInterval']
  );

  return (
    <SelectLightSensorDelay
      value={
        fields?.lightSensorSettings?.actionInterval ?? DEFAULT_ACTION_INTERVAL
      }
      controlBoard={controlBoardsById[controlBoardId]}
      onSelect={value => {
        onChange(
          String(schedulerId),
          ['lightSensorSettings', 'actionInterval'],
          value
        );
      }}
      disabled={!isControlBoardSelected}
      error={!isDelayFieldValid && isDefined(controlBoardId)}
      customErrorText={
        formErrors?.['lightSensorSettings.actionInterval'] &&
        formErrors?.['lightSensorSettings.actionInterval'].join(', ')
      }
      isOrgLevel={true}
    />
  );
};

export default EditModeDelay;
