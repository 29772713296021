import {
  Button,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  ApiError,
  DeviceType,
  HvacControl,
  Thermostat,
} from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { connect } from 'react-redux';
import { getHvacControlsBySiteId } from '../../actions/hvacControls';
import { ApplicationState } from '../../reducers';
import {
  create as createThermostat,
  Actions as ThermostatActions,
  clearFormErrors,
  getThermostatsBySiteId,
  hideNewThermostatModal,
} from '../../actions/thermostats';
import styles from '../Selects/SelectThermostat.module.css';
import { EditThermostat, ThermostatFormErrors } from '../../types/Thermostat';
import { CreateNewText } from '../../types/global';
import EditThermostatForm from '../../components/EditThermostatForm';
import {
  areThereFormErrors,
  validateThermostatForm,
} from '../../utils/formValidation';
import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import { isEBThermostat } from '../../utils/ebThermostat';

interface OwnProps {
  siteId: number;
  uuid?: string;
  networkGroupId?: number;
  disabled?: boolean;
  error?: boolean;
  customErrorText?: string;
  IsEditFromControls?: boolean;
  isThermostatCard?: boolean;
}

interface Props extends OwnProps {
  getThermostatsBySiteId: (siteId: number | string) => void;
  getHvacControlsBySiteId: (siteId: number | string) => void;
  thermostats: Thermostat[];
  hvacControls: HvacControl[];
  onClose: () => void;
  isThermostatLoading: boolean;
  createThermostat: (payload: EditThermostat) => void;
  thermostatApiError: ApiError;
  hideNewThermostatModal: () => void;
  openNewThermostatModal: boolean;
}

const initialThermostatFields = (
  lockSiteId: number,
  networkGroupId: number,
  uuid: string
): EditThermostat => ({
  title: '',
  description: '',
  uuid: uuid,
  siteId: lockSiteId || -1,
  spaceId: -1,
  networkGroupId: networkGroupId ?? -1,
  wirelessTemperatureSensorsCount: 0,
  model: DeviceType.THERMOSTAT,
});

interface State {
  search: string;
  thermostatFields: EditThermostat;
  thermostatFormErrors: ThermostatFormErrors;
  thermostatFormErrorsVisible: boolean;
}

class NewThermostatModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialThermostat = initialThermostatFields(
      props.siteId,
      props.networkGroupId || -1,
      props.uuid || ''
    );

    this.state = {
      search: '',
      thermostatFormErrorsVisible: false,
      thermostatFormErrors: validateThermostatForm(initialThermostat),
      thermostatFields: initialThermostat,
    };
  }

  onThermostatFieldChange = (field: string, value: string | number) => {
    const { thermostatFields } = this.state;
    const updatedFields = {
      ...thermostatFields,
      [field]: value,
    };

    this.setState({
      thermostatFields: updatedFields,
      thermostatFormErrors: validateThermostatForm(updatedFields),
    });
  };
  onThermostatCreate = () => {
    const { createThermostat } = this.props;
    const { thermostatFields, thermostatFormErrors } = this.state;
    const areThereErrors = areThereFormErrors(thermostatFormErrors);
    if (areThereErrors) {
      this.setState({
        thermostatFormErrorsVisible: true,
      });
    } else {
      //validate uuid to create venstar or EB thermostat
      if (isEBThermostat(thermostatFields.uuid)) {
        const updatedFields = {
          ...this.state.thermostatFields,
          model: ThermostatModelType.ENERGYBOX_THERMOSTAT,
        };
        createThermostat(updatedFields);
      } else {
        const updatedFields = {
          ...this.state.thermostatFields,
          model: ThermostatModelType.VENSTAR_THERMOSTAT,
        };
        createThermostat(updatedFields);
      }
    }
  };

  onThermostatSelectSite = (value: number) => {
    const updatedFields = {
      ...this.state.thermostatFields,
      siteId: value,
      spaceId: -1,
      networkGroupId: -1,
    };

    this.setState({
      thermostatFields: updatedFields,
      thermostatFormErrors: validateThermostatForm(updatedFields),
    });
  };

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  resetThermostatForm = () => {
    const { siteId, networkGroupId, uuid } = this.props;
    const initialThermostat = initialThermostatFields(
      siteId,
      networkGroupId || -1,
      uuid! || ''
    );
    this.setState({
      thermostatFormErrorsVisible: false,
      thermostatFormErrors: validateThermostatForm(initialThermostat),
      thermostatFields: initialThermostat,
    });
  };

  onDeviceModalClose = () => {
    this.resetThermostatForm();
    this.props.onClose();
  };

  componentDidMount() {
    const {
      getThermostatsBySiteId,
      getHvacControlsBySiteId,
      siteId,
    } = this.props;

    getThermostatsBySiteId(siteId);
    getHvacControlsBySiteId(siteId);
  }

  componentDidUpdate = (
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) => {
    if (
      prevProps.openNewThermostatModal !== this.props.openNewThermostatModal
    ) {
      if (!this.props.openNewThermostatModal) {
        this.resetThermostatForm();
      }
    }
    if (prevProps.uuid !== this.props.uuid) {
      const updatedFields = {
        ...this.state.thermostatFields,
        uuid: this.props.uuid || '',
      };

      this.setState({
        thermostatFields: updatedFields,
      });
    }
  };

  filterThermostatsByQuery = (thermostats: Thermostat[]) => {
    const { search } = this.state;

    return thermostats.filter((t: Thermostat) => {
      if (search.length <= 2) return true;
      return hasSubstr(t.title, search);
    });
  };

  render() {
    const {
      onClose,
      siteId,
      isThermostatLoading,
      thermostatApiError,
      openNewThermostatModal,
      isThermostatCard,
    } = this.props;
    const {
      thermostatFields,
      thermostatFormErrors,
      thermostatFormErrorsVisible,
    } = this.state;

    const actions = () => {
      return (
        <div className={styles.actionWrapper}>
          <div>
            <Button
              variant="text"
              onClick={() => {
                this.onDeviceModalClose();
                this.props.hideNewThermostatModal();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isThermostatLoading}
              onClick={() => {
                this.onThermostatCreate();
              }}
            >
              {isThermostatLoading ? <Loader size={12} /> : 'Add'}
            </Button>
          </div>
        </div>
      );
    };
    return (
      <>
        {openNewThermostatModal && (
          <div>
            <Modal
              onClose={onClose}
              actions={actions()}
              className={styles.modalClass}
            >
              <ModalTitle>{CreateNewText.THERMOSTAT}</ModalTitle>
              <ModalContent>
                <div className={styles.formBody}>
                  <EditThermostatForm
                    isNew
                    lockSiteId={siteId}
                    onChange={this.onThermostatFieldChange}
                    onSelectSite={this.onThermostatSelectSite}
                    fields={thermostatFields}
                    formErrorsVisible={thermostatFormErrorsVisible}
                    formErrors={thermostatFormErrors}
                    isThermostatCard={isThermostatCard}
                    isEbThermostat={true}
                  />
                </div>
                <div className={styles.apiError}>
                  {renderAPIerror(
                    thermostatApiError,
                    ThermostatActions.CREATE_THERMOSTAT_DEVICE_ERROR
                  )}
                </div>
              </ModalContent>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (
  { thermostats, hvacControls }: ApplicationState,
  { siteId }: OwnProps
) => ({
  thermostats: pathOr([], [siteId], thermostats.thermostatsBySiteId),
  hvacControls: pathOr([], [siteId], hvacControls.hvacControlsBySiteId),
  isThermostatLoading: thermostats.createThermostatIsLoading,
  thermostatApiError: thermostats.apiError,
  openNewThermostatModal: thermostats.showNewThermostatModal,
});

const mapDispatchToProps = (dispatch: any, { networkGroupId }: OwnProps) => {
  return {
    getThermostatsBySiteId: siteId => dispatch(getThermostatsBySiteId(siteId)),
    getHvacControlsBySiteId: siteId =>
      dispatch(getHvacControlsBySiteId(siteId)),
    onClose: () => dispatch(clearFormErrors()),
    createThermostat: updatedFields =>
      dispatch(createThermostat(updatedFields, networkGroupId)),
    hideNewThermostatModal: () => dispatch(hideNewThermostatModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewThermostatModal);
