import {
  Button,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  Actions as EdgeDeviceActions,
  getEdgeConfigFileBySerialNumber,
} from '../../actions/edge_devices';
import { ApplicationState } from '../../reducers';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import styles from './EdgeConfigFileModal.module.css';

type Props = {
  onCancel: () => void;
  serialNumber: string;
};

const EdgeConfigFileModal: React.FC<Props> = ({ onCancel, serialNumber }) => {
  // ************ useDispatch ************ //
  const dispatch = useDispatch();
  const getEdgeConfigFile = useCallback(() => {
    dispatch(getEdgeConfigFileBySerialNumber(serialNumber));
  }, [dispatch, serialNumber]);

  // ************ useEffect ************ //
  useEffect(() => {
    getEdgeConfigFile();
  }, [getEdgeConfigFile]);

  // ************ useSelector ************ //
  const edgeConfigFile = useSelector(({ edgeDevices }: ApplicationState) => {
    return edgeDevices.edgeConfigFileBySerialNumber[serialNumber];
  });
  if (!edgeConfigFile) return null;
  const { isLoading, data, apiError } = edgeConfigFile;
  const yaml = data?.configYaml;

  // ********** Custom Functions *********** //
  const renderContent = () => {
    if (isLoading) {
      return <Loader size={18} />;
    }
    return (
      <SyntaxHighlighter language="yaml" className={styles.syntaxHighlighter}>
        {yaml ? yaml : '< Yet to be calculated >'}
      </SyntaxHighlighter>
    );
  };

  // ********** Custom Function for hash code *********** //
  const renderHashCodes = () => {
    if (!isLoading) {
      const lastRunningConfigHash = data.lastRunningConfigHash
        ? data.lastRunningConfigHash
        : '< Yet to be calculated >';
      const lastSentConfigHash = data.lastSentConfigHash
        ? data.lastSentConfigHash
        : '< Yet to be calculated >';
      return (
        <div className={styles.hashCode}>
          <div>
            <strong>Last Running Config: </strong>
            <span>{lastRunningConfigHash}</span>
          </div>
          <div>
            <strong>Last Sent Config (below): </strong>
            <span>{lastSentConfigHash}</span>
          </div>
        </div>
      );
    }
  };

  const actions = <Button onClick={onCancel}>Close</Button>;

  return (
    <Modal actions={actions}>
      <ModalTitle className={styles.title}>Edge Configuration</ModalTitle>
      <ModalContent
        className={
          yaml ? styles.contentContainer : styles.contentContainerWithoutYml
        }
      >
        {renderHashCodes()}
        {renderContent()}
        {apiError &&
          Object.values(apiError).length > 0 &&
          renderAPIerror(
            apiError,
            EdgeDeviceActions.GET_EDGE_CONFIG_FILE_ERROR
          )}
      </ModalContent>
    </Modal>
  );
};

export default EdgeConfigFileModal;
