import { ControlBoard } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceReadings,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';
import {
  NormalizedActuatorStates,
  SubscribedControlBoardOutputStates,
} from '../../reducers/subscribedControlBoardOutputStates';
import { useControlBoardById } from '../useControlBoard';
import { SubscribedNetworkGroup } from '../../reducers/subscribedNetworkGroups';

//Knowledge check: functionally networkGroup and edgeApp are the same
//(at least from the FE perspective-- networkGroup houses edgeApp information)
export const useSubscribeToNetworkGroup = (
  edgeUuid: string | undefined,
  edgeId: number | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(edgeUuid)) {
      dispatch(subscribeToDeviceStatus('energybox', edgeUuid, edgeId));
    }

    return () => {
      if (isDefined(edgeUuid)) {
        dispatch(unsubscribeFromDeviceStatus('energybox', edgeUuid, edgeId));
      }
    };
  }, [dispatch, edgeUuid, edgeId]);

  const subscribedNetworkGroup = useSelector<
    ApplicationState,
    SubscribedNetworkGroup | undefined
  >(({ subscribedNetworkGroups }) => {
    return pathOr(undefined, [edgeUuid], subscribedNetworkGroups.byEdgeUuid);
  });

  return subscribedNetworkGroup;
};

export const useLightLiveReadings = (
  controlBoardId: string | number | undefined
) => {
  const dispatch = useDispatch();
  const controlBoard: ControlBoard | undefined = useControlBoardById(
    controlBoardId
  );
  const lightLiveReading = useSelector(
    ({ controlBoards }: ApplicationState) => {
      if (!isDefined(controlBoardId)) return undefined;
      return controlBoards.lightLiveReadingByControlBoardId[controlBoardId];
    }
  );

  useEffect(() => {
    let unsubscribe: (() => void) | null = null;

    if (isDefined(controlBoard)) {
      dispatch(
        subscribeToDeviceReadings(
          controlBoard.vendor,
          controlBoard.uuid,
          controlBoard.id
        )
      );

      unsubscribe = () => {
        dispatch(
          unsubscribeFromDeviceReadings(
            controlBoard.vendor,
            controlBoard.uuid,
            controlBoard.id
          )
        );
      };
    }

    return () => {
      if (isDefined(unsubscribe)) {
        unsubscribe();
      }
    };
  }, [dispatch, controlBoard]);

  return lightLiveReading;
};

export const useControlBoardState = (
  controlBoardForLightSensor: ControlBoard | undefined
): NormalizedActuatorStates | undefined => {
  const dispatch = useDispatch();
  useEffect(() => {
    let unsubscribe: (() => void) | null = null;
    if (isDefined(controlBoardForLightSensor)) {
      const { vendor, uuid, id } = controlBoardForLightSensor;
      dispatch(subscribeToDeviceStatus(vendor, uuid, id));
      unsubscribe = () =>
        dispatch(unsubscribeFromDeviceStatus(vendor, uuid, id));
    }
    return () => {
      if (isDefined(unsubscribe)) unsubscribe();
    };
  }, [
    controlBoardForLightSensor,
    subscribeToDeviceStatus,
    unsubscribeFromDeviceReadings,
  ]);

  const subscribedControlBoardOutputStates = useSelector<
    ApplicationState,
    SubscribedControlBoardOutputStates
  >(state => state.subscribedControlBoardOutputStates);

  if (isDefined(controlBoardForLightSensor)) {
    return subscribedControlBoardOutputStates[controlBoardForLightSensor.id];
  }
  return undefined;
};
