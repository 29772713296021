import {
  Button,
  Modal,
  ModalContent,
} from '@energybox/react-ui-library/dist/components';
import {
  GenericErrors
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  displayFormErrors,
  hideEditModal,
  patch,
  reset,
  updateField,
} from '../../actions/spaces';
import EditSpaceForm from '../../components/EditSpaceForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/spaces';
import SpaceResources from './SpaceResources';

interface Props {
  onChange: (id: string, field: string, value: string) => void;
  patch: (spaceId: string) => void;
  onClose: typeof hideEditModal;
  onReset: typeof reset;
  spaceId: string;
  isVisible: boolean;
  isLoading: boolean;
  isChanged: boolean;
  formErrors: GenericErrors;
  fields: EditableFields;
  currentSiteId: string;
  formErrorsVisible: boolean;
  displayFormErrors: (spaceId: string) => void;
}

class EditSpaceModal extends React.Component<Props> {
  onSave = () => {
    const {
      spaceId,
      formErrors,
      displayFormErrors,
      patch,
      onClose,
    } = this.props;

    if (hasKeys(formErrors)) {
      displayFormErrors(spaceId);
    } else {
      patch(spaceId);
      onClose();
    }
  };

  render() {
    if (!this.props.isVisible || this.props.isLoading) return null;

    const {
      onChange,
      onReset,
      onClose,
      spaceId,
      isChanged,
      fields,
      currentSiteId,
      formErrors,
      formErrorsVisible,
    } = this.props;

    const actions = (
      <>
        <Button
          variant="text"
          onClick={() => {
            onReset(spaceId);
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button disabled={!isChanged} onClick={this.onSave}>
          Save
        </Button>
      </>
    );

    return (
      <Modal actions={actions} onClose={onClose}>
        <ModalContent>
          <div
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              padding: '2rem 0 1rem 0',
            }}
          >
            Space Details
          </div>
          <EditSpaceForm
            ownerId={spaceId}
            fields={fields}
            onChange={(field, value) =>
              onChange(this.props.spaceId, field, value)
            }
            currentSiteId={currentSiteId}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
          />
          <div style={{ paddingTop: '3rem' }}>
            <SpaceResources id={spaceId} />
          </div>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ spaces }: ApplicationState) => {
  const spaceId = spaces.editingSpace;
  return {
    isVisible: spaces.editingSpace !== '-1',
    spaceId,
    ...spaces.editById[spaceId],
  };
};

const mapDispatchtoProps = (dispatch: any) => {
  return {
    onReset: spaceId => dispatch(reset(spaceId)),
    onClose: () => dispatch(hideEditModal()),
    patch: spaceId => dispatch(patch(spaceId)),
    onChange: (spaceId: string, field: string, value: string) =>
      dispatch(updateField(spaceId, field, value)),
    displayFormErrors: spaceId => dispatch(displayFormErrors(spaceId)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(EditSpaceModal);
