import { Tabs } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import styles from './IndexManagementPageLayout.module.css';
import history from '../../history';
import { IoIosArrowBack } from 'react-icons/io';

interface Props {
  actions?: React.ReactNode;
  tabs?: React.ReactNode;
  id?: string;
  content?: React.ReactNode;
  children?: React.ReactNode;
  backTitle?: string;
}

const IndexManagementPageLayout: React.FunctionComponent<Props> = ({
  actions,
  content,
  tabs,
  id,
  children,
  backTitle,
}) => (
  <div className={styles.root} id={id}>
    <div className={styles.header}>
      {backTitle && (
        <>
          <div
            role="link"
            onClick={() => history.goBack()}
            className={styles.button}
          >
            <IoIosArrowBack />
          </div>
          <div className={styles.title}>{backTitle}</div>
        </>
      )}
      <Tabs className={styles.tabs}>{tabs}</Tabs>

      <div className={styles.actions}>{actions}</div>
    </div>
    <div className={styles.content}>{content || children}</div>
  </div>
);

export default IndexManagementPageLayout;
