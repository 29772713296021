import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './PhaseTooltip.module.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { TooltipArrowDirection } from '@energybox/react-ui-library/dist/types/util';

type Props = {
  arrowDirection?: TooltipArrowDirection;
};

const PhaseTooltip = ({ arrowDirection = 'top' }: Props) => (
  <Tooltip
    underline={false}
    arrowDirection={arrowDirection}
    content={
      <div className={styles.tooltipContent}>
        <div className={styles.tooltipTitle}>
          The letter below the phase shows how the phase has been determined.
        </div>
        <div>
          <b>A</b> = Automatically determined by EnergyPro
          <br />
          <b>C</b> = Calibrating
          <br />
          <b>M</b> = Manually set from Platform
        </div>
      </div>
    }
  >
    <IoIosInformationCircleOutline
      color="var(--accent-base)"
      size={20}
      className={styles.tooltipIcon}
    />
  </Tooltip>
);

export default PhaseTooltip;
