import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  DistributionPanelType,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import React, { useMemo } from 'react';

type Props = {
  isEnergyPro?: boolean;
  value?: number;
  onSelect: (value: number) => void;
  noBottomLine?: boolean;
  error?: boolean;
  customErrorText?: string;
  panelType?: DistributionPanelType;
};

const SelectPhase: React.FC<Props> = ({
  isEnergyPro,
  value,
  onSelect,
  error,
  customErrorText,
  noBottomLine = false,
  panelType,
}) => {
  const processedPhases = useMemo(() => {
    switch (panelType) {
      case DistributionPanelType.EU_HORIZONTAL_SINGLE_PHASE:
        return [0, 1];

      case DistributionPanelType.US_VERTICAL_SPLIT_PHASE:
        return [0, 1, 2];

      case DistributionPanelType.HIGH_LEG_DELTA_NETWORK: {
        if (isEnergyPro) {
          return [1, 2, 3];
        }
        return [1, 2, 3, 4, 5, 6];
      }

      case DistributionPanelType.US_VERTICAL_THREE_PHASE:
      default:
        return [0, 1, 2, 3];
    }
  }, [panelType]);

  return (
    <Select
      title={value !== undefined ? PHASE_LABEL[value] : value}
      error={error}
      customErrorText={customErrorText}
      noBottomLine={noBottomLine}
    >
      {processedPhases.map(v => (
        <SelectItem
          key={v}
          isSelected={v === value}
          onSelect={() => onSelect(v)}
        >
          {PHASE_LABEL[v]}
        </SelectItem>
      ))}
    </Select>
  );
};

export const PHASE_LABEL = {
  0: 'AUTO',
  1: 'L1',
  2: 'L2',
  3: 'L3',
  4: 'L1-L2',
  5: 'L2-L3',
  6: 'L3-L1',
};

export default SelectPhase;
