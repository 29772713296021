import React from 'react';

import styles from './SiteGroupDescription.module.css';

interface Props {
  description: string;
}

const SiteGroupDescription = ({ description }: Props) => (
  <div className={styles.root}>{description}</div>
);

export default SiteGroupDescription;
