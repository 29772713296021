import {
  Button,
  FilterDropdown,
  FilterDropdownBasicItem,
  Tabs,
  Tab,
  RunConfigTestWarning,
} from '@energybox/react-ui-library/dist/components';
import { List, Map } from 'immutable';

import React from 'react';
import { showNewDistributionPanelModal } from '../../actions/distribution_panel';
import { showNewEquipmentModal } from '../../actions/equipment';
import { showNewGatewayModal } from '../../actions/gateways';
import { showNewNetworkGroupModal } from '../../actions/network_groups';
import { showNewReportModal } from '../../actions/reports';
import { showNewSensorModal } from '../../actions/sensors';
import { showNewModal } from '../../actions/spaces';
import TabLink from '../../components/TabLink';
import history from '../../history';
import useMedia from '../../hooks/useMedia';
import { Routes } from '../../routes';
import { CreateNewText } from '../../types/global';
import FeatureFlag from '../FeatureFlag';
import styles from './SiteDetailTabs.module.css';
import { useDispatch } from 'react-redux';
import { useInstallerAccess } from '../../hooks/useInstallerAccess';
import { InstallerAccess } from '../../types/user';
import { useGetSite } from '../../hooks/useSites';
import { hasDaResults } from '../../util';

interface Props {
  siteId: number;
  children?: React.ReactNode;
}

interface InstallerFeatureFlagged {
  requiredForInstaller?: boolean;
  featureFlag?: boolean;
}

interface Tab extends InstallerFeatureFlagged {
  to: string;
  displayName: string;
}

enum SizeVariants {
  FULL = 'full',
  COMPACT = 'compact',
}

const shouldWrapInFeatureFlag = (
  obj: InstallerFeatureFlagged,
  installerAccess?: InstallerAccess
) =>
  obj.featureFlag &&
  !(obj.requiredForInstaller && installerAccess === InstallerAccess.INSTALLER);

const InstallerFeatureFlagWrapped = (
  obj: InstallerFeatureFlagged,
  installerAccess?: InstallerAccess
) => component => {
  if (shouldWrapInFeatureFlag(obj, installerAccess))
    return <FeatureFlag key={component.key}>{component}</FeatureFlag>;
  return component;
};

const useDetermineActionButton = () => {
  const dispatch = useDispatch();
  const pathname =
    (history && history.location && history.location.pathname) || '';
  let title = '';
  let onClick;

  const parentIdMatch = history.location.pathname.match(/\d+/);
  const parentId = parentIdMatch !== null ? parentIdMatch[0] : -1;

  // Matches with switches are kinda crap, so let's just use a big ugly mess of if statements
  if (pathname.indexOf(Routes.SPACES) > -1) {
    title = CreateNewText.SPACE;
    onClick = showNewModal;
  } else if (pathname.indexOf(Routes.NETWORK_GROUPS) > -1) {
    title = CreateNewText.NETWORK_GROUP;
    onClick = showNewNetworkGroupModal;
  } else if (pathname.indexOf(Routes.EQUIPMENT) > -1) {
    title = CreateNewText.EQUIPMENT;
    onClick = showNewEquipmentModal;
  } else if (pathname.indexOf(Routes.GATEWAYS) > -1) {
    title = CreateNewText.GATEWAY;
    onClick = showNewGatewayModal;
  } else if (pathname.indexOf(Routes.SENSORS) > -1) {
    title = CreateNewText.SENSOR;
    onClick = showNewSensorModal;
  } else if (pathname.indexOf(Routes.DISTRIBUTION_PANELS) > -1) {
    title = CreateNewText.DISTRIBUTION_PANEL;
    onClick = showNewDistributionPanelModal;
  } else if (pathname.indexOf(Routes.REPORTS) > -1) {
    title = CreateNewText.REPORT;
    onClick = showNewReportModal;
  }

  if (title && onClick) {
    return (
      <div className={styles.actionButtonContainer}>
        <Button onClick={() => dispatch(onClick(parentId))}>{title}</Button>
      </div>
    );
  } else {
    return '';
  }
};

const useTabs = (
  siteId: number,
  installerAccess?: InstallerAccess
): [List<Tab>, Map<string, { index: number; displayName: string }>] => {
  const tabs: Tab[] = [
    {
      to: `${Routes.SITES}/${siteId}/general`,
      displayName: 'General',
      featureFlag: false,
    },
    ...(installerAccess === InstallerAccess.INSTALLER
      ? []
      : [
          {
            to: `${Routes.SITES}/${siteId}${Routes.DOCUMENTS}`,
            displayName: 'Documents',
            featureFlag: false,
          },
          {
            to: `${Routes.SITES}/${siteId}${Routes.SOPS}`,
            displayName: 'SOP',
            featureFlag: false,
          },
        ]),
    {
      to: `${Routes.SITES}/${siteId}${Routes.SPACES}`,
      displayName: 'Spaces',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.EQUIPMENT}`,
      displayName: 'Equipment',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.GATEWAYS}`,
      displayName: 'Devices',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.SENSORS}`,
      displayName: 'Sensors',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.NETWORK_GROUPS}`,
      displayName: 'Network Groups',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.DISTRIBUTION_PANELS}`,
      displayName: 'Distribution Panels',
      featureFlag: false,
    },
    ...(installerAccess === InstallerAccess.INSTALLER
      ? []
      : [
          {
            to: `${Routes.SITES}/${siteId}${Routes.SENTINELS}`,
            displayName: 'Alerts',
            featureFlag: false,
          },
          {
            to: `${Routes.SITES}/${siteId}${Routes.REPORTS}`,
            displayName: 'Reports',
            featureFlag: false,
          },
        ]),
    {
      to: `${Routes.SITES}/${siteId}${Routes.INSPECTION}`,
      displayName: 'Inspection',
      featureFlag: true,
      requiredForInstaller: true,
    },
  ];

  let tabMap = Map<string, { index: number; displayName: string }>();
  tabs.forEach(({ to, displayName }, index) => {
    const splitRoute = to.split('/');
    tabMap = tabMap.set(`/${splitRoute[splitRoute.length - 1]}`, {
      index,
      displayName,
    });
  });
  return [List<Tab>(tabs), tabMap];
};

const tabMapFn = (tab: Tab, installerAccess?: InstallerAccess) => {
  return InstallerFeatureFlagWrapped(
    tab,
    installerAccess
  )(
    <TabLink key={tab.to} to={tab.to}>
      <b>{tab.displayName}</b>
    </TabLink>
  );
};

const useRenderedTabs = (siteId: number, displayType: string) => {
  let tabs: List<Tab>, restOfTabs: List<Tab> | undefined;

  const { installerAccess } = useInstallerAccess();

  const [defaultTabs, defaultTabMap] = useTabs(siteId, installerAccess);
  let activeTab: string;
  if (displayType === SizeVariants.FULL) {
    tabs = defaultTabs;
    return tabs.map(tab => tabMapFn(tab, installerAccess));
  } else {
    const pathname =
      (history && history.location && history.location.pathname) || '';
    const additionalTabs = 5;

    const defaultName = { displayName: 'General' };
    if (pathname.indexOf(Routes.NETWORK_GROUPS) > -1) {
      activeTab = defaultTabMap.get(Routes.NETWORK_GROUPS, defaultName)
        .displayName;
    } else if (pathname.indexOf(Routes.DISTRIBUTION_PANELS) > -1) {
      activeTab = defaultTabMap.get(Routes.DISTRIBUTION_PANELS, defaultName)
        .displayName;
    } else if (pathname.indexOf(Routes.SENTINELS) > -1) {
      activeTab = defaultTabMap.get(Routes.SENTINELS, defaultName).displayName;
    } else {
      activeTab = 'More...';
    }

    tabs = defaultTabs.take(additionalTabs);
    restOfTabs = defaultTabs.takeLast(defaultTabs.size - additionalTabs);
    let renderedTabs: List<JSX.Element> = tabs.map(tab =>
      tabMapFn(tab, installerAccess)
    );
    renderedTabs = renderedTabs.push(
      <div className={styles.filterDropdownContainer} key="siteTabDropdown">
        <FilterDropdown title={activeTab} dropdownClassName={styles.moreTabs}>
          {restOfTabs.map((tab: Tab) => {
            return InstallerFeatureFlagWrapped(
              tab,
              installerAccess
            )(
              <FilterDropdownBasicItem
                key={tab.to}
                className={styles.moreTabsItem}
                closeOnClick
              >
                <TabLink key={tab.to} to={tab.to}>
                  <b>{tab.displayName}</b>
                </TabLink>
              </FilterDropdownBasicItem>
            );
          })}
        </FilterDropdown>
      </div>
    );
    return renderedTabs;
  }
};

const SiteDetailTabs = ({ siteId, children }: Props) => {
  const columnCount = useMedia(
    ['only screen and (min-width : 1440px)'],
    [SizeVariants.FULL],
    SizeVariants.COMPACT
  );
  //To display the test-inprogress-warning.
  const site = useGetSite(siteId);
  const daResultsExist: boolean = hasDaResults(site?.installerTestResults);
  const button = useDetermineActionButton();
  const renderedTabs = useRenderedTabs(siteId, columnCount);
  return (
    <div className={styles.root}>
      {daResultsExist && (
        <RunConfigTestWarning
          configLink={`${Routes.RUN_CONFIGURATION_TEST}/${siteId}`}
        />
      )}
      <Tabs className={styles.tabs}>
        {renderedTabs}
        {button}
      </Tabs>
      <div className={styles.content}> {children} </div>
    </div>
  );
};

export default SiteDetailTabs;
