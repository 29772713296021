import { Table } from '@energybox/react-ui-library/dist/components';
import {
  SopComponent,
  SopTypes,
  SopTypeToLabel,
  SortDirection,
  TimeTablesById,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';

import { Link } from 'react-router-dom';
import SopUnit from '../../containers/Sop/SopUnit';
import SopValue from '../../containers/Sop/SopValue';
import { Routes } from '../../routes';
import SopOverviewTooltip from './SopOverviewTooltip';
import styles from './SopTable.module.css';
import { useInstallerAccess } from '../../hooks/useInstallerAccess';
import { InstallerAccess } from '../../types/user';

type Props = {
  orgId?: number;
  siteId?: number;
  sopComponents: SopComponent[];
  timeTablesById?: TimeTablesById;
  isEquipmentSops: boolean;
};

const SopTable = ({
  orgId,
  siteId,
  sopComponents,
  timeTablesById,
  isEquipmentSops,
}: Props) => {
  const isOrgLevelSop = (parentId: string | number) => {
    if (parentId === orgId) return true;
    return false;
  };

  const { installerAccess } = useInstallerAccess();

  const columns = [
    {
      header: 'Name/Description',
      width: '20%',
      cellContent: (sopComponent: SopComponent) => (
        <>
          <div className={styles.sopTitle}>{sopComponent.title}</div>
          <div>{sopComponent.description}</div>
        </>
      ),
      comparator: (
        a: SopComponent,
        b: SopComponent,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
        ]);
      },
    },
    {
      header: 'SOP Type',
      width: '25%',
      cellContent: (sopComponent: SopComponent) => {
        const component = sopComponent.component;
        if (!component) return global.NOT_AVAILABLE;
        return (
          <>
            <div>{SopTypeToLabel[component.type] || global.NOT_AVAILABLE}</div>
          </>
        );
      },
    },

    {
      header: 'Value',
      width: '20%',
      cellContent: (sopComponent: SopComponent) => {
        const timeTable =
          (sopComponent.component.type === SopTypes.SITE_OPERATING_HOURS ||
            sopComponent.component.type ===
              SopTypes.EQUIPMENT_OPERATING_HOURS) &&
          timeTablesById &&
          sopComponent.timetableId
            ? timeTablesById[sopComponent.timetableId]
            : undefined;
        return sopComponent.component ? (
          <SopValue component={sopComponent.component} timeTable={timeTable} />
        ) : (
          global.NOT_AVAILABLE
        );
      },
    },

    {
      header: 'Unit',
      width: '20%',
      cellContent: (sopComponent: SopComponent) =>
        sopComponent.component ? (
          <SopUnit component={sopComponent.component} siteId={siteId} />
        ) : (
          global.NOT_AVAILABLE
        ),
    },

    {
      header: 'SOP Level',
      width: '15%',
      cellContent: (sopComponent: SopComponent) => {
        const isOrgLevel = isOrgLevelSop(sopComponent.organizationUnitId);
        if (isOrgLevel) return 'Organization';
        return 'Site';
      },
      comparator: (
        a: SopComponent,
        b: SopComponent,
        sortDirection: SortDirection
      ) => {
        const determineOrgOrSiteLevel = (sopComponent: SopComponent) => {
          const isOrgLevel = isOrgLevelSop(sopComponent.organizationUnitId);
          return isOrgLevel ? 'Organization' : 'Site';
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          determineOrgOrSiteLevel
        );
      },
    },
  ];

  const tooltipMessage = () => {
    if (isEquipmentSops) {
      return 'This overview is showing SOP\'s that are applied to this specific equipment.';
    }

    return 'This overview is showing SOP\'s that are applied for the whole site.';
  };

  return (
    <>
      <div className={styles.tableGrid}>
        <div className={styles.cardTitle}>SOP</div>
        <div className={styles.buttonContainer}>
          {installerAccess !== InstallerAccess.INSTALLER && (
            <>
              <Link
                className={styles.button}
                to={`${Routes.SITES}/${siteId}${Routes.SOPS}${Routes.COSTS}`}
              >
                SOP Overview
              </Link>
              <div>
                <SopOverviewTooltip tooltipEntry={tooltipMessage()} />
              </div>
            </>
          )}
        </div>
      </div>
      <Table
        highlightAlternateRows={true}
        columns={columns}
        data={sopComponents}
      />
    </>
  );
};

export default SopTable;
