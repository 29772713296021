import { EnergyboxService } from '../config';
import * as R from 'ramda';

const apiBase = '/api/v1/reminders';

export enum Actions {
  GET_REMINDERS_SUCCESS = '@@reminder/GET_REMINDERS_SUCCESS',
  GET_REMINDERS_LOADING = '@@reminder/GET_REMINDERS_LOADING',
  GET_REMINDERS_ERROR = '@@reminder/GET_REMINDERS_ERROR',

  GET_REMINDER_SUCCESS = '@@reminder/GET_REMINDER_SUCCESS',
  GET_REMINDER_LOADING = '@@reminder/GET_REMINDER_LOADING',
  GET_REMINDER_ERROR = '@@reminder/GET_REMINDER_ERROR',

  CREATE_REMINDER_SUCCESS = '@@reminder/CREATE_REMINDER_SUCCESS',
  CREATE_REMINDER_LOADING = '@@reminder/CREATE_REMINDER_LOADING',
  CREATE_REMINDER_ERROR = '@@reminder/CREATE_REMINDER_ERROR',

  UPDATE_REMINDER_SUCCESS = '@@reminder/UPDATE_REMINDER_SUCCESS',
  UPDATE_REMINDER_LOADING = '@@reminder/UPDATE_REMINDER_LOADING',
  UPDATE_REMINDER_ERROR = '@@reminder/UPDATE_REMINDER_ERROR',

  DELETE_REMINDER_SUCCESS = '@@reminder/DELETE_REMINDER_SUCCESS',
  DELETE_REMINDER_LOADING = '@@reminder/DELETE_REMINDER_LOADING',
  DELETE_REMINDER_ERROR = '@@reminder/DELETE_REMINDER_ERROR',
}

export type ReminderRecipient = {
  id: number;
  email: string;
};

export type ReminderComponent = {
  siteId: number;
  sopId: number;
  recipients: ReminderRecipient[];
};

export type Reminder = {
  id?: number;
  title: string;
  description: string;
  type: string;
  components: ReminderComponent[];
  muted?: boolean;
  createdAt?: string;
  updatedAt?: string;
  completed: boolean;
};

export const getReminders = () => {
  return {
    type: 'API_GET',
    service: EnergyboxService.reminder,
    path: `${apiBase}`,
    loading: {
      type: Actions.GET_REMINDERS_LOADING,
    },
    success: {
      type: Actions.GET_REMINDERS_SUCCESS,
    },
    error: {
      type: Actions.GET_REMINDERS_ERROR,
    },
  };
};

export const getReminderById = (reminderId: number) => {
  return {
    type: 'API_GET',
    service: EnergyboxService.reminder,
    path: `${apiBase}/${reminderId}`,
    loading: {
      type: Actions.GET_REMINDER_LOADING,
      id: reminderId,
    },
    success: {
      type: Actions.GET_REMINDER_SUCCESS,
      id: reminderId,
    },
    error: {
      type: Actions.GET_REMINDER_ERROR,
      id: reminderId,
    },
  };
};

export const createReminder = (reminder: Reminder) => {
  return {
    type: 'API_POST',
    service: EnergyboxService.reminder,
    path: `${apiBase}`,
    payload: reminder,
    loading: {
      type: Actions.CREATE_REMINDER_LOADING,
    },
    success: {
      type: Actions.CREATE_REMINDER_SUCCESS,
    },
    error: {
      type: Actions.CREATE_REMINDER_ERROR,
    },
  };
};

export const updateReminder = (reminder: Reminder) => {
  return {
    type: 'API_PUT',
    service: EnergyboxService.reminder,
    path: `${apiBase}/${reminder.id}`,
    payload: R.omit(['id', 'createdAt', 'updatedAt'], reminder),
    loading: {
      type: Actions.UPDATE_REMINDER_LOADING,
      id: reminder.id,
    },
    success: {
      type: Actions.UPDATE_REMINDER_SUCCESS,
      id: reminder.id,
    },
    error: {
      type: Actions.UPDATE_REMINDER_ERROR,
      id: reminder.id,
    },
  };
};

export const deleteReminder = (reminder: Reminder) => {
  return {
    type: 'API_DELETE',
    service: EnergyboxService.reminder,
    path: `${apiBase}/${reminder.id}`,
    loading: {
      type: Actions.DELETE_REMINDER_LOADING,
      id: reminder.id,
    },
    success: {
      type: Actions.DELETE_REMINDER_SUCCESS,
      id: reminder.id,
    },
    error: {
      type: Actions.DELETE_REMINDER_ERROR,
      id: reminder.id,
    },
  };
};
