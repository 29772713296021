import {
  Button,
  Loader,
  PageSelectControl,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import { PageContentHeader } from '../../components/Page';
import { Placeholder } from '../../types/global';
import theme from '../../theme';
import { useUrlState } from '../../hooks/utils';
import styles from './LightingEquipmentContorls/LightingControls.module.css';
import FiltersContainer from '../../components/Filters/FiltersContainer/FiltersContainer';
import SiteFilter from '../../components/SiteFilter';
import SiteGroupFilter from '../../components/SiteGroupFilter';
import EquipmentMenuFilter from '../../components/Filters/EquipmentMenuFilter';
import { useState } from 'react';
import {
  Equipment,
  WorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { CbToEquipmentIds } from '../../reducers/aggregate_views';
import { useGetSites } from '../../hooks/useSites';
import useSiteGroupsFilter from '../../hooks/useSiteGroupsFilter';
import useSiteFilter from '../../hooks/useSiteFilter';
import NewActuatorModal from '../Actuators/NewActuatorModal';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import LightingEquipmentData from './LightingEquipmentContorls/LightingEquipmentData';
import { handleScrollToTop } from '../../utils/string';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import usePaginationFilter from '../../hooks/usePaginationFilter';
import ControlModeFilter from '../../components/ControlModeFilter';
import useDynamicFilter from '../../hooks/useDynamicFilter';
import { filterBySiteGroups } from '../../utils/siteGroups/siteGroups';
import { filteredData } from '../../utils/filters';
import TopPagination from '../../utils/TopPagination/TopPagination';

type Props = {
  data: {
    equipment: Equipment[];
    siteId: string;
    networkGroupIds: number[];
    cbToEquipmentIds: CbToEquipmentIds[];
  }[];
  isLighting: boolean;
  equipmentTypes?: Object;
  orgId: number | undefined;
  equipmentTypeIds: number[];
};

const NonHvacPageLayout: React.FC<Props> = ({
  data,
  isLighting,
  equipmentTypes,
  orgId,
  equipmentTypeIds,
}) => {
  const [query, setQuery] = useUrlState<string>('query', '');
  const [advancedView, setAdvancedView] = useState<boolean>(false);
  const { sitesById } = useGetSites();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();
  const { selectedSiteFilters } = useSiteFilter();
  const {
    selectedSiteGroups,
    selectedSitesBySiteGroups,
  } = useSiteGroupsFilter();
  const { selectedFilters: equipmentTypeFilters } = useDynamicFilter<number>(
    'eqType',
    value => parseInt(value)
  );
  const { selectedFilters: controlModeFilters } = useDynamicFilter<
    WorkingModeLabel[]
  >('controlMode');

  const isLoading = useSelector(({ aggregate_views }: ApplicationState) => {
    return aggregate_views.isLoadingNonHvacEquipments;
  });

  //To display new Acturator Modal
  const isNewActuatorModalOpen = useSelector(
    ({ controlBoards }: ApplicationState) => {
      return controlBoards.showNewActuatorModal;
    }
  );
  const editById = useSelector(({ controlBoards }: ApplicationState) => {
    return controlBoards.editById;
  });

  let siteIdForEquipment;

  for (const item of data) {
    for (const equipmentItem of item.equipment) {
      if (equipmentItem.id === editById.new?.fields?.equipmentId) {
        siteIdForEquipment = item.siteId;
        break;
      }
    }
    if (siteIdForEquipment) {
      break; // Exit the loop once the siteId is found
    }
  }

  //Pagination Logic
  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    data.length
  );
  const startIndex = (currentPage - 1) * rowLimit;
  const endIndex = startIndex + rowLimit;

  //Quick Search functionality
  const filterByQuickSearch = data => {
    return data.filter(data => {
      const site = sitesById[data.siteId];
      return hasSubstr(`${site.title}`, query);
    });
  };

  const handleSearchChange = (query: string) => {
    setQuery(query);
  };

  //Filters related code
  const filterBySites = data => {
    return data.filter(siteData =>
      selectedSiteFilters.includes(Number(siteData.siteId))
    );
  };
  const filterByEquipmentType = data => {
    return data
      .map(siteData => {
        // Filter the equipment array for each siteData
        const filteredEquipment = siteData.equipment.filter(equipment => {
          return equipmentTypeFilters.includes(equipment.typeId);
        });

        if (filteredEquipment.length > 0) {
          const filteredSiteData = { ...siteData };
          filteredSiteData.equipment = filteredEquipment;
          return filteredSiteData;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };
  const filterByWorkingMode = data => {
    return data
      .map(siteData => {
        const filteredEquipments = siteData.equipment.filter(equipment => {
          return controlModeFilters.includes(
            WorkingModeLabel?.[equipment.activeControl?.workingMode!]
          );
        });

        if (filteredEquipments.length > 0) {
          const filteredSiteData = { ...siteData };
          filteredSiteData.equipment = filteredEquipments;
          return filteredSiteData;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const filteredCardData = filteredData(
    data,
    query,
    selectedSiteFilters,
    equipmentTypeFilters,
    controlModeFilters,
    selectedSiteGroups,
    selectedSitesBySiteGroups,
    filterByQuickSearch,
    filterBySites,
    filterByEquipmentType,
    filterByWorkingMode,
    filterBySiteGroups
  );

  const filteredNonHvacData = filteredCardData
    .sort((a, b) => {
      const siteA = sitesById[a.siteId];
      const siteB = sitesById[b.siteId];
      return siteA.title.localeCompare(siteB.title);
    })
    .slice(startIndex, endIndex);

  return (
    <>
      <PageContentHeader header={'Management'}>
        <SearchBox
          placeholder={Placeholder.seachBox}
          onChange={handleSearchChange}
          query={query}
          width={theme.size.table.searchBox.web}
          widthActive={theme.size.table.searchBox.web}
          error={data?.length === 0}
        />
      </PageContentHeader>
      <div
        className={styles.buttonContainer}
        id={isLighting ? 'Lighting' : 'Others'}
      >
        <FiltersContainer>
          <SiteFilter />
          <SiteGroupFilter />
          <EquipmentMenuFilter
            label="Equipment Type"
            relevantItemTypeIds={equipmentTypeIds}
            orgId={orgId}
          />
          <ControlModeFilter />
        </FiltersContainer>
        <div className={styles.button}>
          <Button
            onClick={() => setAdvancedView(!advancedView)}
            variant="outlined"
            roundedCorners={true}
            className={advancedView ? styles.hideButton : ''}
          >
            {advancedView ? 'Hide Columns' : 'Advanced View'}
          </Button>
        </div>
      </div>
      {siteGroupWithoutSites ? (
        <></>
      ) : (
        <>
          <TopPagination
            data={filteredCardData}
            setPagination={setPagination}
            rowLimit={rowLimit}
            currentPage={currentPage}
            numberOfSites={filteredNonHvacData.length}
          />
          {data.length > 0 ? (
            <>
              {filteredNonHvacData?.map(data => {
                const {
                  siteId,
                  equipment,
                  networkGroupIds,
                  cbToEquipmentIds,
                } = data;
                return (
                  <LightingEquipmentData
                    siteId={siteId}
                    equipments={equipment}
                    networkGroupIds={networkGroupIds}
                    lightingTypes={equipmentTypes!}
                    sitesById={sitesById}
                    controlBoardIds={cbToEquipmentIds}
                    orgId={orgId}
                    advancedView={advancedView}
                  />
                );
              })}
            </>
          ) : (
            isLoading === true && (
              <div className={styles.Loader}>
                <Loader />
              </div>
            )
          )}
        </>
      )}
      <div className={styles.pagination}>
        {filteredCardData.length > 0 && !siteGroupWithoutSites && (
          <PageSelectControl
            pageCount={Math.ceil(filteredCardData.length / rowLimit)}
            currentPage={currentPage}
            setCurrentPage={currentPage => {
              setPagination(currentPage, rowLimit);
              handleScrollToTop(isLighting ? 'Lighting' : 'Others');
            }}
          ></PageSelectControl>
        )}
      </div>
      {isNewActuatorModalOpen && (
        <NewActuatorModal
          equipmentId={editById.new.fields.equipmentId}
          siteId={siteIdForEquipment}
          orgId={orgId}
        />
      )}
    </>
  );
};

export default NonHvacPageLayout;
