import { ResourceType } from '@energybox/react-ui-library/dist/types';
import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import * as R from 'ramda';

import React from 'react';
import { connect } from 'react-redux';
import {
  deletePicture as deleteDistributionPanelPicture,
  uploadPicture as uploadDistributionPanelPicture,
} from '../../actions/distribution_panel';
import {
  deletePicture as deleteOrgPicture,
  uploadPicture as uploadOrgPicture,
} from '../../actions/organizations';
import {
  deletePicture as deleteSitePicture,
  uploadPicture as uploadSitePicture,
} from '../../actions/sites';
import ImageSquare from '../../components/ImageSquare';
import UploadImageButton from '../../components/UploadImageButton';
import { ApplicationState } from '../../reducers';
import styles from './ImagesContainer.module.css';

interface OwnProps {
  id: string;
  type: ResourceType;
}

interface Props extends OwnProps {
  images: string[];
  loading: boolean;
  uploadPicture:
    | typeof uploadDistributionPanelPicture
    | typeof uploadSitePicture;
  deletePicture:
    | typeof deleteDistributionPanelPicture
    | typeof deleteSitePicture;
}

interface State {
  showDeleteModal: boolean;
  deleteImagePath: string;
  errorMessage: string;
}

const defaultErrorMessage =
  'Image width and height must be between 128px and 2048px';

class ImagesContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      deleteImagePath: '',
      errorMessage: '',
    };
  }

  handleImageUpload = async e => {
    const { id, uploadPicture } = this.props;
    const formData = new FormData();
    const imageToUpload: File = e.target.files[0];
    let isImageValid = await this.validateImageDimensions(imageToUpload);

    if (!isImageValid) {
      this.setState({
        errorMessage: defaultErrorMessage,
      });
    } else {
      formData.append('file', imageToUpload);
      uploadPicture(id, formData);
    }
  };

  validateImageDimensions = async (imageToUpload: File) => {
    let mockImg = new Image();
    mockImg.src = window.URL.createObjectURL(imageToUpload);

    const mockImgDimensions: {
      width: number;
      height: number;
    } = await new Promise(resolve => {
      mockImg.onload = () =>
        resolve({
          width: mockImg.width,
          height: mockImg.height,
        });
    });
    const isWidthValid =
      mockImgDimensions.width >= 128 && mockImgDimensions.width <= 2048;
    const isHeightValid =
      mockImgDimensions.height >= 128 && mockImgDimensions.height <= 2048;

    if (!isWidthValid || !isHeightValid) {
      return false;
    }
    return true;
  };

  handleCloseDeletePrompt = () => {
    this.setState({
      deleteImagePath: '',
      showDeleteModal: false,
    });
  };

  handleOpenDeleteModal = imagePath => {
    this.setState({
      deleteImagePath: imagePath,
      showDeleteModal: true,
    });
  };

  deleteModal = () => {
    const { id, deletePicture } = this.props;
    const { deleteImagePath } = this.state;
    const actions = (
      <>
        <Button variant="text" onClick={this.handleCloseDeletePrompt}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deletePicture(id, deleteImagePath);
            this.setState({
              deleteImagePath: '',
              showDeleteModal: false,
            });
          }}
        >
          Delete
        </Button>
      </>
    );

    return (
      <Modal onClose={this.handleCloseDeletePrompt} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete {deleteImagePath}?
        </p>
      </Modal>
    );
  };

  shouldDisplayImageUploadButton = () => {
    const { images, type } = this.props;

    switch (type) {
      case ResourceType.ORGANIZATION: {
        if (images.length > 0) {
          return false;
        }
        return true;
      }

      case ResourceType.DISTRIBUTIONPANEL:
      case ResourceType.SITE:
      default: {
        return true;
      }
    }
  };

  render() {
    const { showDeleteModal, errorMessage } = this.state;
    const { images, loading } = this.props;
    return (
      <>
        <div className={styles.imagesContainer}>
          {images.map(imagePath => (
            <ImageSquare
              key={imagePath}
              baseUrl={process.env.REACT_APP_CDN_BASE_URL || ''}
              imagePath={imagePath}
              handleDelete={this.handleOpenDeleteModal}
            />
          ))}
          {!loading && this.shouldDisplayImageUploadButton() && (
            <UploadImageButton handleSubmit={this.handleImageUpload} />
          )}
          {showDeleteModal ? this.deleteModal() : ''}
        </div>

        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </>
    );
  }
}

const typeToFunc = {
  [ResourceType.DISTRIBUTIONPANEL]: {
    uploadPicture: uploadDistributionPanelPicture,
    deletePicture: deleteDistributionPanelPicture,
    imagesLocation: ['distributionPanels', 'distributionPanelsById'],
    editLocation: ['distributionPanels', 'editById'],
  },
  [ResourceType.SITE]: {
    uploadPicture: uploadSitePicture,
    deletePicture: deleteSitePicture,
    imagesLocation: ['sites', 'sitesById'],
    editLocation: ['sites', 'editById'],
  },
  [ResourceType.ORGANIZATION]: {
    uploadPicture: uploadOrgPicture,
    deletePicture: deleteOrgPicture,
    imagesLocation: ['organizations', 'organizationsById'],
    editLocation: ['organizations', 'editById'],
  },
};

const mapStateToProps = (
  appState: ApplicationState,
  { id, type }: OwnProps
) => ({
  images: R.pathOr(
    [],
    typeToFunc[type].imagesLocation.concat([id, 'images']),
    appState
  ),
  loading: R.pathOr(
    false,
    typeToFunc[type].editLocation.concat([id, 'isLoading']),
    appState
  ),
});

const mapDispatchToProps = (dispatch, { type }: OwnProps) => ({
  uploadPicture: (id, formData) => {
    dispatch(typeToFunc[type].uploadPicture(id, formData));
  },
  deletePicture: (id, deleteImagePath) => {
    dispatch(typeToFunc[type].deletePicture(id, deleteImagePath));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesContainer);
