import {
  Button,
  FormText,
  InputField,
  Label,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import {
  AccessResource,
  GenericErrors,
  Role,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as UserActions,
  addScopeToUser,
  create,
  displayFormErrors,
  hideNewUserModal,
  updateField,
} from '../../actions/users';
import ModalFormContent from '../../components/ModalFormContent';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/users';
import { CreateNewText, PropertyToLabel } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import UserPositionSelect from './UserPositionSelect';

import styles from './ShowUserPage.module.css';
import { Steps } from './NewInstallerModal';
import MultiSelectSiteAccessButton from '../Selects/MultiSelectSiteAccessButton';
import { renderAccessRow } from '../../components/ResourceAccess/ResourceAccess';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onCreate: (callback: (response: { id?: number }) => void) => void;
  addScopeToUser: typeof addScopeToUser;
  formErrors: GenericErrors;
  fields: EditableFields;
  isLoading: boolean;
  apiError: ApiError;
  displayFormErrors: typeof displayFormErrors;
  formErrorsVisible: boolean;
}

interface State {
  step: Steps;
  scopes: AccessResource[];
}

class NewUserModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      step: Steps.information,
      scopes: [],
    };
  }

  setStep = (step: Steps) => this.setState({ step });

  onUserCreate = () => {
    const {
      displayFormErrors,
      formErrors,
      onCreate,
      addScopeToUser,
    } = this.props;
    const { scopes: tentativeScopes, step } = this.state;

    const { information, access } = Steps;
    if (step === information) {
      this.setStep(access);
    } else if (step === access) {
      if (hasKeys(formErrors)) {
        displayFormErrors('new');
      } else {
        onCreate(async ({ id }) => {
          if (id) {
            // group tentative AccessResource by Role
            // to reduce number of requests
            const finalizedScopes = tentativeScopes.reduce<Map<Role, string[]>>(
              (prevValue, tentativeScope) => {
                let resourceIds = prevValue.get(tentativeScope.role) || [];

                return prevValue.set(tentativeScope.role, [
                  ...resourceIds,
                  '' + tentativeScope.resourceId,
                ]);
              },
              new Map<Role, string[]>()
            );

            // Define an array for sending add scope requests one by one
            let iteratingRoles = [Role.ADMIN, Role.MANAGER, Role.VIEWER];
            do {
              const role = iteratingRoles.pop();
              if (role) {
                const resourceId = finalizedScopes.get(role);
                if (resourceId) {
                  // blocking
                  await new Promise<void>(resolve =>
                    addScopeToUser(
                      '' + id,
                      {
                        resourceId,
                        role: role.toUpperCase(),
                      },
                      () => resolve()
                    )
                  );
                }
              }
            } while (iteratingRoles.length > 0);
            this.setState({ scopes: [] });
          } else {
            this.setStep(information);
          }
        });
      }
    }
  };

  addScope = (scope: AccessResource) => {
    this.setState({
      scopes: [...this.state.scopes, scope],
    });
  };

  editScope = (resourceId: number, value: string) => {
    this.setState({
      scopes: this.state.scopes.map(scope =>
        scope.resourceId === resourceId
          ? (() => {
              let newScope = { ...scope };
              newScope.role = Role[value];
              return newScope;
            })()
          : scope
      ),
    });
  };

  deleteScope = (deleteId: number) => {
    this.setState({
      scopes: this.state.scopes.filter(
        ({ resourceId }) => resourceId !== deleteId
      ),
    });
  };

  onClose = () => {
    this.setState({ scopes: [] });
    this.props.onClose();
  };

  componentDidUpdate(prevProps: Props) {
    const { formErrors, formErrorsVisible } = this.props;
    if (
      !(
        equals(prevProps.formErrors, formErrors) &&
        equals(prevProps.formErrorsVisible, formErrorsVisible)
      ) &&
      formErrorsVisible &&
      hasKeys(formErrors) &&
      this.state.step === Steps.access
    )
      this.setStep(Steps.information);
  }

  render() {
    if (!this.props.isVisible) return null;

    const {
      onChange,
      isLoading,
      formErrorsVisible,
      formErrors,
      fields,
      apiError,
    } = this.props;
    const { step, scopes } = this.state;
    const { information, access } = Steps;
    const { firstName, lastName, email, password, position } = fields;

    const actions = (
      <>
        <Button variant="text" onClick={this.onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={this.onUserCreate}>
          {step === information ? (
            'Next'
          ) : isLoading ? (
            <Loader size={16} variant="secondary" />
          ) : (
            'Create'
          )}
        </Button>
      </>
    );

    return (
      <Modal onClose={this.onClose} actions={actions} disableEscapeClose={true}>
        <ModalTitle>{CreateNewText.USER}</ModalTitle>
        <ModalContent>
          <Tabs className={styles.installerModalTabs}>
            <Tab
              active={step === information}
              onClick={() => this.setStep(information)}
            >
              User Information
            </Tab>
            <Tab active={step === access} onClick={() => this.setStep(access)}>
              User Access
            </Tab>
          </Tabs>

          {step === information && (
            <>
              <ModalFormContent>
                <div>
                  <b>
                    <Label required>{PropertyToLabel.firstName}</Label>
                  </b>
                </div>
                <div>
                  <InputField
                    type="text"
                    name="firstName"
                    // autoComplete="new-password" is to remove any auto complete behaviour
                    // this work on FireFox, Safari and Chrome
                    // see more in https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
                    autoComplete="new-password"
                    value={firstName}
                    onChange={e =>
                      onChange(e.currentTarget.name, e.currentTarget.value)
                    }
                    error={formErrorsVisible && !!formErrors.firstName}
                    customErrorText={
                      formErrors.firstName && formErrors.firstName[0]
                    }
                  />
                </div>

                <div>
                  <b>
                    <Label required>{PropertyToLabel.lastName}</Label>
                  </b>
                </div>
                <div>
                  <InputField
                    type="text"
                    name="lastName"
                    value={lastName}
                    autoComplete="new-password"
                    onChange={e =>
                      onChange(e.currentTarget.name, e.currentTarget.value)
                    }
                    error={formErrorsVisible && !!formErrors.lastName}
                    customErrorText={
                      formErrors.lastName && formErrors.lastName[0]
                    }
                  />
                </div>

                <div>
                  <b>
                    <Label required>{PropertyToLabel.email}</Label>
                  </b>
                </div>
                <div>
                  <InputField
                    type="email"
                    name="email"
                    value={email}
                    autoComplete="new-password"
                    onChange={e =>
                      onChange(e.currentTarget.name, e.currentTarget.value)
                    }
                    error={formErrorsVisible && !!formErrors.email}
                    customErrorText={formErrors.email && formErrors.email[0]}
                  />
                </div>

                <div>
                  <b>
                    <Label>{PropertyToLabel.position}</Label>
                  </b>
                </div>
                <div>
                  <UserPositionSelect
                    onSelect={value => onChange('position', value)}
                    value={position || ''}
                  />
                </div>
              </ModalFormContent>
              <ModalFormContent>
                <FormText>* Mandatory fields</FormText>
              </ModalFormContent>
              {renderAPIerror(apiError, UserActions.CREATE_USER_ERROR)}
            </>
          )}
          {step === access && (
            <>
              <ModalFormContent className={styles.userAccess}>
                <b>
                  <Label>Access to Sites</Label>
                </b>
                <MultiSelectSiteAccessButton
                  onSelect={scope => this.addScope(scope)}
                  onDeselect={this.deleteScope}
                  selectedResourceIds={scopes.map(s => s.resourceId)}
                />
                {scopes.length > 0 && (
                  <div className={styles.userAccessTableContainer}>
                    <div className={styles.userAccessTableHeader}>
                      <div>Sites</div>
                      <div>User Rights</div>
                    </div>
                    <div className={styles.userAccessTableContent}>
                      {scopes.map((resource, idx) =>
                        renderAccessRow(
                          resource,
                          {
                            onResourceAccessDelete: this.deleteScope,
                            onResourceAccessChange: this.editScope,
                          },
                          idx % 2 === 0
                          // UI requirement of highlighting alternate rows
                        )
                      )}
                    </div>
                  </div>
                )}
              </ModalFormContent>
            </>
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ users }: ApplicationState) => ({
  isVisible: users.showNewUserModal,
  ...users.editById['new'],
});

const mapDispatchToProps = {
  onClose: hideNewUserModal,
  onChange: (field: string, value: string) => updateField('new', field, value),
  onCreate: callback => create({ callback }),
  displayFormErrors,
  addScopeToUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserModal);
