import {
  FilterDropdown,
  FilterDropdownTextItem,
} from '@energybox/react-ui-library/dist/components';
import { AggregationFilter } from '@energybox/react-ui-library/dist/types';
import { createChartResolutionAggregationFilter } from '@energybox/react-ui-library/dist/utils';
import React from 'react';

type Props = {
  title: string;
  onChange: (aggregationFilter: AggregationFilter) => void;
  className?: string;
  options?: AggregationFilter[];
  secondary?: boolean;
  disableOneMinuteResolution: boolean;
};

const ChartResolutionFilter: React.FC<Props> = ({
  className,
  title,
  onChange,
  options = createChartResolutionAggregationFilter(),
  secondary = false,
  disableOneMinuteResolution,
}) => {
  let alteredOptions = options;
  if (disableOneMinuteResolution) {
    alteredOptions = options.slice(1);
  }

  return (
    <div className={className}>
      <FilterDropdown title={`Aggregation: ${title}`} secondary={secondary}>
        {alteredOptions.map(({ title, aggregationLevel }) => (
          <FilterDropdownTextItem
            key={title}
            title={title}
            onClick={() => {
              onChange({ title, aggregationLevel });
            }}
            closeOnClick
          />
        ))}
      </FilterDropdown>
    </div>
  );
};

export default ChartResolutionFilter;
