import {
  EnergySource,
  FirmwareGatewayModel,
  GenericErrors,
  NetworkGroup,
  PanelEnergySourceToAcPowerSystemId,
  PowerMeteringType,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import {
  InputField,
  Label,
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect } from 'react';
import SelectAcPowerSystem from '../../containers/Selects/SelectAcPowerSystem';
import SelectNetworkGroup from '../../containers/Selects/SelectNetworkGroup';
import { EditableFields } from '../../reducers/energy_pros';
import ModalFormContent from '../ModalFormContent';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getFirmwares } from '../../actions/app';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import LookupButton from '../LookupButton/LookupButton';
import { useLookupEnergyPro } from '../../hooks/useEnergyPro';
import { formatUuid } from '../../utils/devices';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import FeatureFlag from '../../containers/FeatureFlag';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import styles from './EditEnergyProForm.module.css';

interface Props {
  siteId: string | number;
  fields: EditableFields;
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError?: ApiError;
  isNew?: boolean;
  distributionPanelEnergySource: EnergySource;
  firmwareVersion?: string;
  setIsLatestFirmware?: (isLatestFirmware: boolean) => void;
}
/*
  Notes, there's some not so nice logic going on related to AC power system,
  I figure once we do a second iteration we can clean it up.

  If the form is new the user has the option to select real or virtual power systems.
  If virtual is selected you are able to choose one of the 3 virtual types, filtered from
  the connected select for power types.

  If it is nothing is selected or Real is selected, the e-pro will have it's power 
  point to the same power as the distribution panel it is attached to.
*/
const EditEnergyProForm = ({
  siteId,
  fields,
  onChange,
  formErrors,
  formErrorsVisible,
  apiError,
  isNew,
  distributionPanelEnergySource,
  firmwareVersion,
  setIsLatestFirmware,
}: Props) => {
  const {
    title,
    identifier,
    description,
    sampleFrequency,
    uuid,
    acPowerSystem,
    poweredFromDistributionPanel,
    networkGroupId,
    energyProModel,
  } = fields;

  const dispatch = useDispatch();
  const firmwares = useSelector(
    (state: ApplicationState) => state.app.firmwares
  );
  const [formattedUuid, setFormattedUuid] = React.useState<string>(uuid);
  const [
    selectedNetworkGroup,
    setSelectedNetworkGroup,
  ] = React.useState<NetworkGroup | null>(null);

  const {
    isLoading: isLookupLoading,
    energyPros: lookupEnergyPro,
  } = useLookupEnergyPro(
    energyProModel,
    Number(siteId),
    selectedNetworkGroup?.edge?.serialNumber
  );

  const isLatestFirmware = React.useMemo(() => {
    if (!firmwareVersion || !firmwares) {
      return true;
    }

    const releaseFirmwares = firmwares.filter(
      firmware => firmware.type === 'RELEASE'
    );

    if (releaseFirmwares.length === 0) return true;

    const latestFirmware = releaseFirmwares.reduce((prev, current) =>
      prev.version > current.version ? prev : current
    );

    return latestFirmware?.version === firmwareVersion;
  }, [firmwareVersion, firmwares]);

  useEffect(() => {
    dispatch(getFirmwares({ gatewayModels: [FirmwareGatewayModel.ENERGYPRO] }));
  }, []);

  useEffect(() => {
    if (setIsLatestFirmware) {
      setIsLatestFirmware(isLatestFirmware);
    }
  }, [isLatestFirmware]);

  useEffect(() => {
    if (isNew && poweredFromDistributionPanel) {
      onChange(
        'acPowerSystem',
        PanelEnergySourceToAcPowerSystemId[distributionPanelEnergySource]
      );
    }
  }, [isNew, poweredFromDistributionPanel]);

  useEffect(() => {
    setFormattedUuid(uuid);
  }, [uuid]);

  /*
    if Power Metering Type REAL, auto selects ac power system (powered by DP)
    and disables the ac power system dropdown
  */

  const onPowerMeteringTypeChange = (type: PowerMeteringType) => {
    const isPoweredFromDistributionPanel = type === PowerMeteringType.REAL;

    onChange('poweredFromDistributionPanel', isPoweredFromDistributionPanel);

    if (isPoweredFromDistributionPanel) {
      onChange(
        'acPowerSystem',
        PanelEnergySourceToAcPowerSystemId[distributionPanelEnergySource]
      );
    }
  };

  const onChangeUuid = value => {
    const formattedUuid = formatUuid(value);
    setFormattedUuid(formattedUuid);
    onChange('uuid', formattedUuid);
  };

  const isGenII = energyProModel === 'ENERGY_PRO2';

  return (
    <div>
      <ModalFormContent>
        <div>
          <Label required htmlFor="title">
            EnergyPro Name
          </Label>
        </div>
        <div>
          <InputField
            id="title"
            type="text"
            name="title"
            value={title}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
          />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
        </div>
        <div>
          <InputField
            id="description"
            type="text"
            name="description"
            placeholder=""
            value={description}
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        <div>
          <Label required htmlFor="type">
            Network Group
          </Label>
        </div>
        <div>
          <SelectNetworkGroup
            onSelect={(networkGroupId: number) =>
              onChange('networkGroupId', networkGroupId)
            }
            setSelectedNetworkGroup={setSelectedNetworkGroup}
            siteId={siteId}
            value={Number(networkGroupId)}
            error={formErrorsVisible && !!formErrors.networkGroupId}
            customErrorText="Please select Network Group"
          />
        </div>

        {isNew && (
          <>
            <div className={styles.checkboxContainer}>
              <Label htmlFor="type">EnergyPro I</Label>
              <RadioButton
                checked={energyProModel === 'ENERGY_PRO1'}
                onChange={() => onChange('energyProModel', 'ENERGY_PRO1')}
                variant="traditional"
                size={22}
                label=""
                value=""
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FeatureFlag orgType={OrganizationType.DISCONTINUED}>
                <>
                  <Label htmlFor="type">EnergyPro II</Label>
                  <RadioButton
                    checked={energyProModel === 'ENERGY_PRO2'}
                    onChange={() => onChange('energyProModel', 'ENERGY_PRO2')}
                    variant="traditional"
                    size={22}
                    label=""
                    value=""
                  />
                </>
              </FeatureFlag>
            </div>
          </>
        )}

        <div>
          <Label required htmlFor="type">
            UUID
          </Label>
        </div>
        <div className={styles.checkboxContainer}>
          <div className={styles.inputContainer}>
            <InputField
              id="uuid"
              type="text"
              name="uuid"
              placeholder=""
              value={formattedUuid}
              onChange={e => onChangeUuid(e.currentTarget.value)}
              error={formErrorsVisible && !!formErrors.uuid}
              style={{ width: '100%' }}
            />
          </div>
          <LookupButton
            currentUuid={formattedUuid}
            buttonLabel="Lookup UUID"
            dropdownTitle={
              isGenII ? 'Whitelisted EnergyPro II' : 'Connected EnergyPro I'
            }
            devices={lookupEnergyPro}
            onDeviceClick={uuid => onChange('uuid', formatUuid(uuid))}
            onButtonClick={() => {}}
            active={
              selectedNetworkGroup?.edge?.edgeDevice === 'EB_SUPER_HUB' &&
              !!selectedNetworkGroup?.edge?.serialNumber
            }
            showSignalStrength={isGenII}
            isLoading={isLookupLoading}
          />
        </div>

        {formErrorsVisible && !!formErrors.uuid && (
          <>
            <div />
            <div
              style={{
                fontSize: '0.75rem',
                color: 'var(--pink-base)',
                marginTop: '-0.6rem',
              }}
            >
              {formErrors.uuid[0]}
            </div>
          </>
        )}

        {!isNew && (
          <>
            <div>
              <Label htmlFor="type">Firmware</Label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                fontSize: '0.75rem',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  padding: '0.5rem 0rem',
                }}
              >
                v{firmwareVersion || global.NOT_AVAILABLE}
              </div>
              {!isGenII && !isLatestFirmware && (
                <div className={styles.updateFirmwareWarning}>
                  <WarningIcon size={16} />
                  <span>
                    <b>Update Firmware</b> Not the latest release version
                  </span>
                </div>
              )}
            </div>
          </>
        )}

        <div>
          <Label htmlFor="type">Power Metering Type</Label>
        </div>
        <div>
          <RadioGroup>
            <RadioButton
              onChange={() => onPowerMeteringTypeChange(PowerMeteringType.REAL)}
              checked={poweredFromDistributionPanel}
              label={PowerMeteringType.REAL}
              value={PowerMeteringType.REAL}
            />
            <RadioButton
              onChange={() =>
                onPowerMeteringTypeChange(PowerMeteringType.VIRTUAL)
              }
              checked={!poweredFromDistributionPanel}
              label={PowerMeteringType.VIRTUAL}
              value={PowerMeteringType.VIRTUAL}
            />
          </RadioGroup>
        </div>

        <div>
          <Label htmlFor="type">AC Power System</Label>
        </div>
        <div>
          <SelectAcPowerSystem
            powerMeteringType={
              poweredFromDistributionPanel
                ? PowerMeteringType.REAL
                : PowerMeteringType.VIRTUAL
            }
            disabled={poweredFromDistributionPanel}
            error={formErrorsVisible && !!formErrors.acPowerSystem}
            value={acPowerSystem}
            onSelect={id => onChange('acPowerSystem', id)}
          />
        </div>
        {!isNew && (
          <>
            <div>
              <Label required htmlFor="sampleFrequency">
                Sample Frequency (Seconds)
              </Label>
            </div>
            <div>
              <InputField
                id="sampleFrequency"
                type="number"
                name="sampleFrequency"
                min="2"
                placeholder=""
                value={sampleFrequency}
                onChange={e =>
                  onChange('sampleFrequency', e.currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.sampleFrequency}
                customErrorText="Sample Frequency must be 2 or above"
                disabled={fields.energyProModel === 'ENERGY_PRO2'}
              />
            </div>
          </>
        )}
      </ModalFormContent>
      <ModalFormContent>
        <Label>* Mandatory fields</Label>
      </ModalFormContent>
      {apiError && renderAPIerror(apiError)}
    </div>
  );
};

export default EditEnergyProForm;
