import {
  IconCheckboxOutlined as IconChecked,
  IconUnchecked,
} from '@energybox/react-ui-library/dist/icons';
import { Equipment } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';

import React from 'react';
import ReportContentSection from '../ReportContentSection';
import styles from './SelectEquipment.module.css';

type Props = {
  equipmentList: Equipment[];
  resourceIds: number[];
  updateReportField: (field: string, value: any) => void;
};

const SelectEquipment: React.FC<Props> = ({
  equipmentList,
  resourceIds,
  updateReportField,
}) => {
  const equipmentListIds = equipmentList.map(eq => eq.id);

  const areAllEquipmentSelected = () => {
    const sortNumbers = (a: number, b: number) => a - b;
    const sortedEquipmentIds = equipmentListIds.sort(sortNumbers);
    const sortedResourceIds = resourceIds.sort(sortNumbers);

    return equals(sortedEquipmentIds, sortedResourceIds);
  };

  const isEquipmentSelected = (eq: Equipment) => {
    return resourceIds.includes(eq.id);
  };

  const renderEquipmentActions = () => {
    return (
      <div className={styles.actionButton} onClick={onAllEquipmentClick}>
        {areAllEquipmentSelected() ? 'Deselect All' : 'Select All'}
      </div>
    );
  };

  const onAllEquipmentClick = () => {
    updateReportField(
      'resourceIds',
      areAllEquipmentSelected() ? [] : equipmentListIds
    );
  };

  const onIconClick = (isSelected: boolean, equipmentId: number) => {
    const updatedResourceIds = [...resourceIds];
    if (isSelected) {
      const index = updatedResourceIds.indexOf(equipmentId);
      if (index > -1) {
        updatedResourceIds.splice(index, 1);
      }
    } else {
      updatedResourceIds.push(equipmentId);
    }
    updateReportField('resourceIds', updatedResourceIds);
  };

  if (equipmentList.length === 0)
    return (
      <ReportContentSection title="No equipment with temperature sensors in this site" />
    );

  return (
    <ReportContentSection title="Please select the appropriate equipment">
      <ReportContentSection
        isSubSection
        title="Equipment"
        action={renderEquipmentActions()}
      >
        {equipmentList.map(eq => {
          const isEquipmentChecked = isEquipmentSelected(eq);
          return (
            <div
              className={classNames(
                styles.equipmentContainer,
                isEquipmentChecked ? styles.selectedEquipment : ''
              )}
            >
              <div>{eq.title}</div>
              <div
                className={styles.icon}
                onClick={() => onIconClick(isEquipmentChecked, eq.id)}
              >
                {isEquipmentChecked ? (
                  <IconChecked size="16" />
                ) : (
                  <IconUnchecked size="16" />
                )}
              </div>
            </div>
          );
        })}
      </ReportContentSection>
    </ReportContentSection>
  );
};

export default SelectEquipment;
