import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import useDynamicFilter from '../../hooks/useDynamicFilter';
import { WorkingModeLabel } from '@energybox/react-ui-library/dist/types';

const ControlModeFilter = () => {
  const { selectedFilters, setFilter } = useDynamicFilter('controlMode');

  return (
    <GenericFilter
      title="Control Mode"
      setFilter={setFilter}
      items={Object.values(WorkingModeLabel)}
      selectedItems={selectedFilters}
    />
  );
};

export default ControlModeFilter;
