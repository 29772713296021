import {
  Thermostat,
  ThermostatDetail,
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import { assoc, assocPath, pipe, mergeRight, view, lensProp } from 'ramda';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions } from '../actions/thermostats';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';

export const initialState = {
  createThermostatIsLoading: false,
  patchThermostatIsLoading: false,
  thermostatsById: {},
  thermostatsBySiteId: {},
  apiError: {},
  showNewThermostatModal: false,
  thermostatDetailById: {},
  thermostatDisplayNames: {},
};

export type Thermostats = {
  thermostatsById: ThermostatsById;
  thermostatsBySiteId: ThermostatsBySiteId;
  createThermostatIsLoading: boolean;
  patchThermostatIsLoading: boolean;
  apiError: ApiError;
  showNewThermostatModal: boolean;
  thermostatDetailById: ThermostatDetailById;
  thermostatDisplayNames: ThermostatDisplayNames;
};

export type ThermostatDisplayNames = {
  [id: string]: string;
};

export type ThermostatsBySiteId = {
  [siteId: string]: Thermostat[];
};

export type ThermostatsById = {
  [id: string]: Thermostat;
};

export type ThermostatsByUuid = {
  [uuid: string]: {
    id: number;
    organizationId: number;
    siteId: number;
    title: string;
    venstarThermostatModel: string;
    uuid: string;
    wirelessTemperatureSensorsCount: number;
  };
};

export type ThermostatDetailById = {
  [id: string]: ThermostatDetail;
};

// TODO: need more detailed normalizing
const normalizeVenstarThermostat = data => ({
  ...data,
  venstarThermostatStatus: data.venstarThermostatStatus
    ? {
        ...data.venstarThermostatStatus,
        additionalTemperatureReadingsList: isDefined(
          data.venstarThermostatStatus?.additionalTemperatureReadingsList
        )
          ? JSON.parse(
              data.venstarThermostatStatus?.additionalTemperatureReadingsList
            )
          : null,
      }
    : null,
  venstarThermostatModel: data.venstarThermostatModel
    ? `Venstar ${data.venstarThermostatModel}`
    : undefined,
  thermostatType: ThermostatModelType.VENSTAR_THERMOSTAT,
});

const normalizeEBThermostat = data => ({
  ...data,
  ebThermostatStatus: data.ebThermostatStatus
    ? {
        ...data.ebThermostatStatus,
        additionalTemperatureReadingsList: isDefined(
          data.ebThermostatStatus?.additionalTemperatureReadingsList
        )
          ? JSON.parse(
              data.ebThermostatStatus?.additionalTemperatureReadingsList
            )
          : null,
      }
    : null,
  thermostatType: ThermostatModelType.ENERGYBOX_THERMOSTAT,
});

const normalizeThermostat = (data: any): any => {
  if (
    data &&
    data.model &&
    data.model == ThermostatModelType.ENERGYBOX_THERMOSTAT
  ) {
    return normalizeEBThermostat(data);
  } else if (
    data &&
    data.model &&
    data.model == ThermostatModelType.VENSTAR_THERMOSTAT
  ) {
    return normalizeVenstarThermostat(data);
  } else {
    return data;
  }
};

const thermostats = (state: Thermostats = initialState, action: any) => {
  switch (action.type) {
    case Actions.CREATE_THERMOSTAT_DEVICE_SUCCESS:
      return pipe(
        assocPath(
          ['thermostatsById', action.data.id],
          normalizeThermostat(action.data)
        ),
        assoc('createThermostatIsLoading', false),
        assoc('apiError', {})
      )(state);

    case Actions.CREATE_THERMOSTAT_DEVICE_LOADING:
      return pipe(assoc('createThermostatIsLoading', true))(state);

    case Actions.CREATE_THERMOSTAT_DEVICE_ERROR:
      return pipe(
        assoc('apiError', storeAPIerror(action)),
        assoc('createThermostatIsLoading', false)
      )(state);

    case Actions.PATCH_THERMOSTAT_DEVICE_SUCCESS:
      return pipe(
        assoc('apiError', {}),
        assoc('patchThermostatIsLoading', false)
      )(state);

    case Actions.PATCH_THERMOSTAT_DEVICE_LOADING:
      return pipe(assoc('patchThermostatIsLoading', true))(state);

    case Actions.PATCH_THERMOSTAT_DEVICE_ERROR:
      return pipe(
        assoc('apiError', storeAPIerror(action)),
        assoc('patchThermostatIsLoading', false)
      )(state);

    case Actions.DELETE_THERMOSTAT_DEVICE_ERROR:
      return pipe(assoc('apiError', storeAPIerror(action)))(state);

    case Actions.DELETE_THERMOSTAT_DEVICE_SUCCESS:
      return pipe(assoc('apiError', {}))(state);

    case Actions.GET_THERMOSTAT_BY_ID_SUCCESS:
      return pipe(
        assoc('apiError', {}),
        assocPath(
          ['thermostatsById', action.id],
          normalizeThermostat(action.data)
        )
      )(state);

    case Actions.GET_THERMOSTATS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['thermostatsBySiteId', action.siteId],
          action.data.map((t: any) => normalizeThermostat(t))
        ),
        assoc(
          'thermostatsById',
          mergeRight(
            view(lensProp('thermostatsById'), state),
            mapArrayToObject(mapValues(action.data, normalizeThermostat))
          )
        )
      )(state);

    case Actions.GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS:
      return pipe(assocPath(['thermostatDetailById', action.id], action.data))(
        state
      );

    case Actions.GET_THERMOSTAT_DISPLAY_NAME_SUCCESS:
      return pipe(
        assoc('apiError', {}),
        assocPath(
          ['thermostatDisplayNames', action.id],
          action.data.displayName
        )
      )(state);

    case Actions.GET_THERMOSTAT_DISPLAY_NAME_ERROR:
      return pipe(assoc('apiError', storeAPIerror(action)))(state);

    case GatewayActions.TOGGLE_NEW_GATEWAY_MODAL:
      return pipe(assoc('apiError', {}))(state);

    case Actions.TOGGLE_NEW_THERMOSTAT_MODAL:
      return pipe(assoc('showNewThermostatModal', action.value))(state);

    case Actions.CLEAR_EDIT_THERMOSTAT_FORM_ERRORS:
      return pipe(assoc('apiError', {}))(state);

    default:
      return state;
  }
};

export default thermostats;
