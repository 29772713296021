import assoc from 'ramda/src/assoc';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/filters';

export type Filters = {
  sites: number[];
  equipmentTypeIds: number[];
};

const initialState = {
  sites: [],
  equipmentTypeIds: [],
};

const filters = (state: Filters = initialState, action: any) => {
  switch (action.type) {
    case Actions.SITE: {
      return assoc('sites', action.payload, state);
    }

    case Actions.EQUIPMENT_TYPE: {
      return assoc('equipmentTypeIds', action.payload, state);
    }

    case Actions.RESET: {
      return pipe(assoc('sites', []), assoc('equipmentTypeIds', []))(state);
    }

    default:
      return state;
  }
};

export default filters;
