import { EnergyboxService } from '../config';
import { ReDoRequestBody } from '../types/analytics';
import { formatDateForNotificationsAPI } from '@energybox/react-ui-library/dist/utils';

export enum Actions {
  RE_DO_REPORT_SUCCESS = '@analytics/RE_DO_REPORT_SUCCESS',
  RE_DO_REPORT_LOADING = '@analytics/RE_DO_REPORT_LOADING',
  RE_DO_REPORT_ERROR = '@analytics/RE_DO_REPORT_ERROR',

  GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS = '@@analytics/GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS',
  GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING = '@@analytics/GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING',
  GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR = '@@analytics/GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR',

  GET_EDM_REPORT_KEY_SUCCESS = '@analytics/GET_EDM_REPORT_KEY_SUCCESS',
  GET_EDM_REPORT_KEY_LOADING = '@analytics/GET_EDM_REPORT_KEY_LOADING',
  GET_EDM_REPORT_KEY_ERROR = '@analytics/GET_EDM_REPORT_KEY_ERROR',

  GET_EDM_REPORT_DATA_SUCCESS = '@analytics/GET_EDM_REPORT_DATA_SUCCESS',
  GET_EDM_REPORT_DATA_LOADING = '@analytics/GET_EDM_REPORT_DATA_LOADING',
  GET_EDM_REPORT_DATA_ERROR = '@analytics/GET_EDM_REPORT_DATA_ERROR',
}

const formatDateOrLatest = (date: Date | undefined, timeZone?: string) =>
  date ? formatDateForNotificationsAPI(date, timeZone) : 'latest';

export const reDoReport = (params: {
  page: string;
  requestBody: ReDoRequestBody;
}) => ({
  type: 'API_POST',
  path: `/re_do`,
  service: EnergyboxService.analytics,
  payload: { ...params.requestBody },
  loading: { type: Actions.RE_DO_REPORT_LOADING, page: params.page },
  success: { type: Actions.RE_DO_REPORT_SUCCESS, page: params.page },
  error: { type: Actions.RE_DO_REPORT_ERROR, page: params.page },
});

export const getTemperatureRecommendationsBySiteId = (
  siteId: string | number,
  date?: Date
) => ({
  type: 'API_GET',
  service: EnergyboxService.analytics,
  path: `/report/hvac_thermal_comfort/${siteId}/${formatDateOrLatest(date)}`,
  loading: {
    type: Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR,
    siteId,
  },
});

export const getEdmReportKey = (orgId: number | undefined) => ({
  type: 'API_GET',
  path: `/real_time_report/edm_management_report/${orgId}`,
  service: EnergyboxService.analytics,
  success: { type: Actions.GET_EDM_REPORT_KEY_SUCCESS, orgId },
  loading: { type: Actions.GET_EDM_REPORT_KEY_LOADING, orgId },
  error: { type: Actions.GET_EDM_REPORT_KEY_ERROR, orgId },
});

export const getEdmReportData = (
  orgId: number | undefined,
  reportDate: string
) => ({
  type: 'API_GET',
  path: `/report/edm_management_report/${orgId}/${reportDate}`,
  service: EnergyboxService.analytics,
  success: { type: Actions.GET_EDM_REPORT_DATA_SUCCESS, orgId, reportDate },
  loading: { type: Actions.GET_EDM_REPORT_DATA_LOADING, orgId, reportDate },
  error: { type: Actions.GET_EDM_REPORT_DATA_ERROR, orgId, reportDate },
});
