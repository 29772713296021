import { Actions as EdgeDeviceActions } from '../actions/edge_devices';
import { Actions as StreamActions } from '../actions/streamApi';
import { Actions as HvacControlsActions } from '../actions/hvacControls';
import { Actions as TemperatureControlsActions } from '../actions/temperature_controls';
import { Actions as SchedulerActions } from '../actions/schedulers';
import { Actions as SopActions } from '../actions/sops';
import { LocalStorageKey } from '../utils/localStorage';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';
import { pathOr } from 'ramda';

export const getEdgeConfigUpdatingLocalStorage = () => {
  return window.localStorage.getItem(LocalStorageKey.EDGE_CONFIG_UPDATING);
};

/**
 * expected edgeConfigUpdating localStorage typing:
 * {
 *  [edgeUuid: string]: string (ISO timestamp of when update request was sent)
 * }
 */
export const setEdgeConfigUpdatingByUuid = (edgeUuid: string | undefined) => {
  if (!isDefined(edgeUuid)) return;
  let currentEdgeConfigUpdatingByUuid = {};
  const currentEdgeConfigLocalStorage = getEdgeConfigUpdatingLocalStorage();

  if (isDefined(currentEdgeConfigLocalStorage)) {
    currentEdgeConfigUpdatingByUuid = JSON.parse(currentEdgeConfigLocalStorage);
  }

  const newEdgeConfigUpdatingByUuid = {
    ...currentEdgeConfigUpdatingByUuid,
    [edgeUuid]: DateTime.now().toISO(),
  };

  window.localStorage.setItem(
    LocalStorageKey.EDGE_CONFIG_UPDATING,
    JSON.stringify(newEdgeConfigUpdatingByUuid)
  );
};

export const removeEdgeConfigUpdatingByUuid = (edgeUuid: string) => {
  let newEdgeConfigUpdatingByUuid = {};
  const currentEdgeConfigLocalStorage = getEdgeConfigUpdatingLocalStorage();

  if (isDefined(currentEdgeConfigLocalStorage)) {
    newEdgeConfigUpdatingByUuid = JSON.parse(currentEdgeConfigLocalStorage);
  }

  delete newEdgeConfigUpdatingByUuid[edgeUuid];

  window.localStorage.setItem(
    LocalStorageKey.EDGE_CONFIG_UPDATING,
    JSON.stringify(newEdgeConfigUpdatingByUuid)
  );
};

const localStorageMiddleware = store => next => action => {
  switch (action.type) {
    case SchedulerActions.PATCH_SCHEDULER_CONTROL_MODE_SUCCESS:
    case TemperatureControlsActions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_SUCCESS:
    case HvacControlsActions.PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS:
    case SopActions.UPDATE_HVAC_SOP_SUCCESS:
    case EdgeDeviceActions.POST_NEW_EDGE_SUCCESS: {
      setEdgeConfigUpdatingByUuid(action.edgeUuid);
      break;
    }

    case StreamActions.RECEIVED_DEVICE_STATUS: {
      const isValidSubscribedNetworkGroup =
        action.data?.deviceType === 'edge' &&
        action.data?.vendor === 'energybox' &&
        isDefined(action.data?.status) &&
        isDefined(action.data?.uuid);

      const updateRequestsByEdgeUuid = JSON.parse(
        getEdgeConfigUpdatingLocalStorage() || '{}'
      );
      const updateRequestTimestamp = pathOr(
        undefined,
        [action.data?.uuid],
        updateRequestsByEdgeUuid
      );
      const newMessageTimestamp: string | undefined =
        action.data?.status?.updatedAt;

      const isNewMessageAfterUpdateRequest =
        isDefined(newMessageTimestamp) && isDefined(updateRequestTimestamp)
          ? DateTime.fromISO(newMessageTimestamp) >
            DateTime.fromISO(updateRequestTimestamp)
          : false;

      if (isValidSubscribedNetworkGroup && isNewMessageAfterUpdateRequest) {
        removeEdgeConfigUpdatingByUuid(action.data.uuid);
      }

      break;
    }

    default:
      break;
  }

  next(action);
};

export default localStorageMiddleware;
