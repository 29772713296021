import { classNames } from '@energybox/react-ui-library/dist/utils';
import { LocalOverride as LocalOverrideIcon } from '@energybox/react-ui-library/dist/icons';

import React from 'react';
import styles from './ConditionDoor.module.css';
import StepConditionItem, {
  StepConditionDescription,
  StepConditionTitle,
} from './StepConditionItem';

function ConditionSiteControllerOverrideCheck() {
  return (
    <React.Fragment>
      <StepConditionItem
        label={
          <span className={classNames(styles.icon)}>
            <LocalOverrideIcon size={80} />
          </span>
        }
        condition={
          <div>
            <StepConditionTitle>Device in Local Override</StepConditionTitle>
            <StepConditionDescription>
              When in Local Override, configured Schedules are rendered inactive
              <div className={styles.noteContainer}>
                <span className={styles.note}>Note :</span>
                <span>
                  SiteController-ILO models can be configured to auto-revert
                  Local Overrides after a set period of time
                </span>
              </div>
            </StepConditionDescription>
          </div>
        }
        conditionAlignedCenter
      />
    </React.Fragment>
  );
}

export default ConditionSiteControllerOverrideCheck;
