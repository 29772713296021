import {
  Button,
  Modal,
  ModalContent,
} from '@energybox/react-ui-library/dist/components';
import {
  TimeTable,
  TimeTableRowStore,
  TimeTableSpecialStore,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import { getTimeTableCount } from './TimeTablesTable';
import { TimetableConfirmationModal } from '../../components/Modals/ConfirmationModal';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as TimeTableActions,
  addTimeTableRow,
  addTimeTableSpecialRow,
  displayFormErrors,
  hideEditModal,
  patch,
  removeSpecialRow,
  removeTimeTableRow,
  updateField,
} from '../../actions/time_tables';
import EditTimeTableForm from '../../components/EditTimeTableForm';
import { ApplicationState } from '../../reducers';
import {
  EditableFields,
  TimeTableCountsByOrgUnitId,
} from '../../reducers/time_tables';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import {
  check24hrOptionSettings,
  denormalizeTimeTableRows,
  denormalizeTimeTableSpecialRows,
} from '../../utils/timeTables';
import styles from './TimeTableModal.module.css';

interface OwnProps {
  organizationUnitId: string;
  isOrgLevel: boolean | undefined;
}

interface Props extends OwnProps {
  isVisible: boolean;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  apiError: ApiError;
  formErrorsVisible: boolean;
  addTimeTableRow: (
    specialRowIndex: number | boolean,
    timeTableId: number
  ) => void;
  addTimeTableSpecialRow: (timeTableId: number) => void;
  patch: (timeTableId: number, data: TimeTable) => void;
  updateField: (
    path: any[],
    field: string | number,
    value: string | TimeTableRowStore | TimeTableSpecialStore,
    validationType: 'timeTable' | 'timeTableRow' | 'specialTimeTableRow',
    timeTableId: number
  ) => void;
  removeTimeTableRow: (
    rowIndex: number,
    specialRowIndex: number | boolean,
    timeTableId: number
  ) => void;
  removeSpecialRow: (rowIndex: number, timeTableId: number) => void;
  displayFormErrors: (timeTableId: number) => void;
  editingTimeTable: number;
  controlReferenceCount?: number;
  sopReferenceCount?: number;
  siteReferenceCount?: number;
  timeTableCountsByOrgUnitId: TimeTableCountsByOrgUnitId;
  propagateConfig?: boolean;
}
interface State {
  displayConfirmModal: boolean;
  showModal: boolean;
  controlCount: number;
  sopCount: number;
  siteCount: number;
  camCount: number;
}
class EditTimeTableModal extends React.Component<Props, State> {
  private rowConflictError: ApiError | undefined;
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      displayConfirmModal: false,
      controlCount: 0,
      sopCount: 0,
      camCount: 0,
      siteCount: 0,
    };
  }

  checkFormErrors = (): boolean => {
    /* TODO
          This needs to be done in a different way. For now this is just a quick fix.
          Lets discuss with the team for a good convention to handle these kind of nested formErrors.
        */
    const { fields } = this.props;
    let hasErrors = false;

    if (hasKeys(fields.formErrors)) {
      hasErrors = true;
    }

    hasErrors = check24hrOptionSettings(this.props.fields) !== null;

    if (!hasErrors) {
      fields.rows.forEach(row => {
        if (hasKeys(row.formErrors)) {
          hasErrors = true;
        }
      });
    }

    if (!hasErrors) {
      fields.specials.forEach(special => {
        if (hasKeys(special.formErrors)) {
          hasErrors = true;
        }
        if (!hasErrors) {
          special.rows.forEach(row => {
            if (hasKeys(row.formErrors)) {
              hasErrors = true;
            }
          });
        }
      });
    }

    return hasErrors;
  };

  checkTimeTableConflicts = () => {
    this.rowConflictError = undefined;
    let conflictingDays = check24hrOptionSettings(this.props.fields);
    if (conflictingDays !== null) {
      this.rowConflictError = {
        type: null,
        status: 0,
        message: `Timetable contains conflicting 24hr-Option setting for day(s): ${conflictingDays}`,
        force: true,
      };
    }
  };

  denormalizeFormData = () => {
    const { fields, organizationUnitId, propagateConfig = false } = this.props;

    let hasErrors = this.checkFormErrors();
    let data: TimeTable = {
      title: fields.title,
      organizationUnitId,
      rows: denormalizeTimeTableRows(fields.rows),
      specials: denormalizeTimeTableSpecialRows(fields.specials),
      propagateConfig: propagateConfig,
    };

    if (hasErrors) {
      return false;
    }

    return data;
  };

  onPatchTimeTable = () => {
    const data = this.denormalizeFormData();

    this.checkTimeTableConflicts();

    if (data) {
      this.props.patch(this.props.editingTimeTable, data);
    }

    return this.props.displayFormErrors(this.props.editingTimeTable);
  };

  render() {
    this.checkTimeTableConflicts();
    if (!this.props.isVisible || this.props.isLoading) return null;
    const {
      onClose,
      isLoading,
      fields,
      apiError,
      addTimeTableRow,
      addTimeTableSpecialRow,
      updateField,
      removeTimeTableRow,
      removeSpecialRow,
      formErrorsVisible,
      editingTimeTable,
      timeTableCountsByOrgUnitId,
      isOrgLevel,
    } = this.props;

    const { controlCount, siteCount, camCount, sopCount } = this.state;

    const updateButtonOnClick = () => {
      confirmModalOnOpen(true);
    };

    const confirmModalOnClose = () => {
      this.setState({
        displayConfirmModal: false,
        controlCount: 0,
        sopCount: 0,
        siteCount: 0,
        camCount: 0,
      });
      onClose();
    };

    const confirmModalOnOpen = (displayConfirmModal: boolean) => {
      const {
        sopReferenceCount = 0,
        controlReferenceCount = 0,
        siteReferenceCount = 0,
        camCount = 0,
      } = getTimeTableCount(
        timeTableCountsByOrgUnitId[Object.keys(timeTableCountsByOrgUnitId)[0]],
        editingTimeTable
      );
      this.setState({
        displayConfirmModal: displayConfirmModal,
        controlCount: controlReferenceCount,
        sopCount: sopReferenceCount,
        siteCount: siteReferenceCount,
        camCount: camCount,
      });
    };

    const confirmModalSuccess = () => {
      this.setState({
        displayConfirmModal: false,
        controlCount: 0,
        sopCount: 0,
        siteCount: 0,
        camCount: 0,
      });
      this.onPatchTimeTable();
    };

    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => updateButtonOnClick()}>
          Update
        </Button>
      </>
    );

    return (
      <>
        {this.state.displayConfirmModal && (
          <TimetableConfirmationModal
            controlCount={controlCount}
            sopCount={sopCount}
            siteCount={siteCount}
            onSuccess={confirmModalSuccess}
            onCancel={() => confirmModalOnClose()}
            camCount={camCount}
            isOrgLevel={isOrgLevel}
          />
        )}

        <Modal actions={actions} disableEscapeClose>
          <div
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              margin: '2rem 6.25rem 1rem 6.25rem',
            }}
          >
            Timetable Details
          </div>
          <ModalContent className={styles.modalContent}>
            <EditTimeTableForm
              formErrorsVisible={formErrorsVisible}
              updateField={(path, field, value, validationType) =>
                updateField(
                  path,
                  field,
                  value,
                  validationType,
                  editingTimeTable
                )
              }
              addTimeTableRow={(specialRowIndex = false) =>
                addTimeTableRow(specialRowIndex, editingTimeTable)
              }
              addTimeTableSpecialRow={() =>
                addTimeTableSpecialRow(editingTimeTable)
              }
              removeTimeTableRow={(rowIndex, specialRowIndex = false) =>
                removeTimeTableRow(rowIndex, specialRowIndex, editingTimeTable)
              }
              removeSpecialRow={rowIndex =>
                removeSpecialRow(rowIndex, editingTimeTable)
              }
              {...fields}
            />
            {renderAPIerror(
              this.rowConflictError || apiError,
              TimeTableActions.CREATE_TIME_TABLE_ERROR
            )}

            {renderAPIerror(apiError, TimeTableActions.PATCH_TIME_TABLE_ERROR)}
          </ModalContent>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ timeTables }: ApplicationState) => {
  const timeTableId = timeTables.editingTimeTable;
  return {
    isVisible: timeTables.editingTimeTable !== -1,
    ...timeTables.editById[timeTableId],
    editingTimeTable: timeTables.editingTimeTable,
    timeTableCountsByOrgUnitId: timeTables.timeTableCountsByOrgUnitId,
  };
};

const mapDispatchtoProps = (dispatch: any) => {
  return {
    onClose: () => dispatch(hideEditModal()),
    patch: (timeTableId, data) => dispatch(patch(timeTableId, data)),
    addTimeTableRow: (specialRowIndex, timeTableId) =>
      dispatch(addTimeTableRow(specialRowIndex, timeTableId)),
    addTimeTableSpecialRow: timeTableId =>
      dispatch(addTimeTableSpecialRow(timeTableId)),
    updateField: (path, field, value, validationType, timeTableId) =>
      dispatch(updateField(path, field, value, validationType, timeTableId)),
    removeTimeTableRow: (rowIndex, specialRowIndex, timeTableId) =>
      dispatch(removeTimeTableRow(rowIndex, specialRowIndex, timeTableId)),
    removeSpecialRow: (rowIndex, timeTableId) =>
      dispatch(removeSpecialRow(rowIndex, timeTableId)),
    displayFormErrors: timeTableId => dispatch(displayFormErrors(timeTableId)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(EditTimeTableModal);
