import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  Modal,
  ErrorBoundary,
  InfoChip,
} from '@energybox/react-ui-library/dist/components';
import {
  CertificationTypes,
  Locale,
  ResourceType,
  OffsetConfig,
  Sensor,
  SensorParentResourceType,
  SensorType,
  SensorVendors,
  Gateway,
  ApiError,
  DeviceType,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  formatIanaTimeZone,
  hasKeys,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SensorActions,
  destroy,
  displayFormErrors,
  getSensor,
  patch,
  reset,
  updateField,
  updateOffset,
} from '../../../actions/sensors';
import { getHubByPairedSensorId } from '../../../actions/gateways';
import { getSiteByResourceId } from '../../../actions/sites';
import EditSensorForm from '../../../components/EditSensorForm';
import ShowDetailPageHeader from '../../../components/ShowDetailPageHeader';
import { ApplicationState } from '../../../reducers';
import { EditSensor } from '../../../reducers/sensors';

import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import {
  BatteryStatus,
  SensorTypeIconWithHoverText,
  SignalStatus,
} from '../../../utils/sensor';
import CertificationsCard from '../../Certifications/CertificationsCard';
import DeviceOnlineState, {
  DisplayType,
} from '../../DeviceStatus/DeviceOnlineState';
import ResourceFullPath from '../../ResourceFullPath';
import SensorLineChartContainer from '../../SensorLineChartContainer';
import styles from './ShowSensorPage.module.css';
import UpdateSensorOffsetCard from './UpdateSensorOffsetCard';
import { Link } from 'react-router-dom';
import { Routes } from '../../../routes';
import { accessDeniedError } from '../../../utils/ApiError/accessDeniedError';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  attachedTo?: SensorParentResourceType;
  load: () => void;
  onChange: (field: string, value: number) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  sensor?: Sensor;
  editSensor?: EditSensor;
  siteId?: number;
  timezone?: string;
  displayFormErrors: () => void;
  locale: Locale;
  updateOffset: (id: string, offset: OffsetConfig) => void;
  pairedHub: Gateway | undefined;
  siteApiError: ApiError;
}

interface State {
  showDeletePrompt: boolean;
}

class ShowSensorPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showDeletePrompt: false,
    };
  }

  componentDidMount() {
    this.props.load();
  }

  handleOpenDeletePrompt() {
    this.setState({ showDeletePrompt: true });
  }

  handleCloseDeletePrompt() {
    this.setState({ showDeletePrompt: false });
  }

  deletePrompt() {
    const { sensor, onDelete } = this.props;

    const actions = (
      <>
        <Button
          variant="text"
          onClick={this.handleCloseDeletePrompt.bind(this)}
        >
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal
        onClose={this.handleCloseDeletePrompt.bind(this)}
        actions={actions}
      >
        <p className={styles.textAlignCenter}>
          Are you sure you want to delete{' '}
          {sensor ? <b>{sensor.title}</b> : 'this sensor'}?
        </p>
      </Modal>
    );
  }

  onSave = () => {
    const { patch, editSensor, displayFormErrors } = this.props;
    if (editSensor && hasKeys(editSensor.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  onSaveOffset = (offset: OffsetConfig) => {
    this.props.updateOffset(this.props.id, offset);
  };

  isTempOrHumiditySensor = (sensorType: SensorType[]) => {
    return (
      sensorType.includes(SensorType.TEMPERATURE) ||
      sensorType.includes(SensorType.HUMIDITY)
    );
  };

  render() {
    const {
      sensor,
      editSensor,
      onChange,
      onCancel,
      siteId,
      attachedTo,
      locale,
      timezone,
      pairedHub,
      siteApiError,
    } = this.props;

    const isMonnitSensor = sensor && sensor.vendor === 'monnit';
    const { showDeletePrompt } = this.state;
    if (!editSensor) {
      if (Object.keys(siteApiError).length) {
        return accessDeniedError(siteApiError);
      } else {
        return <div>Loading...</div>;
      }
    }

    return (
      <>
        {sensor && (
          <ShowDetailPageHeader
            name={sensor.title}
            description={<ResourceFullPath resourceId={sensor.id} />}
            resourceName="Sensor"
            onDelete={this.handleOpenDeletePrompt.bind(this)}
            siteId={siteId}
          />
        )}

        <div
          className={classNames(
            styles.paddingBetweenCards,
            styles.mainCardTopPadding
          )}
        >
          {editSensor && (
            <>
              <Card>
                <CardContent>
                  <div className={styles.mainCardGrid}>
                    <CardTitle className={styles.mainCardTitle}>
                      General Information
                    </CardTitle>
                    <div>
                      {siteId && (
                        <EditSensorForm
                          {...editSensor.fields}
                          siteId={siteId}
                          attachedTo={attachedTo}
                          isChanged={editSensor.isChanged}
                          formErrors={editSensor.formErrors}
                          formErrorsVisible={editSensor.formErrorsVisible}
                          onChange={onChange}
                        />
                      )}
                      {renderAPIerror(
                        editSensor.apiError,
                        SensorActions.PATCH_SENSOR_ERROR
                      )}
                    </div>
                  </div>
                </CardContent>

                {editSensor.isChanged && (
                  <CardActions>
                    <Button
                      variant="text"
                      onClick={onCancel}
                      children="Cancel"
                    />

                    <Button
                      disabled={editSensor.isLoading}
                      onClick={this.onSave}
                    >
                      {editSensor.isLoading ? (
                        <Loader size={16} variant="secondary" />
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </CardActions>
                )}
              </Card>
            </>
          )}
        </div>

        {sensor &&
          ((sensor.types && sensor.types.length > 0) || isMonnitSensor) && (
            <div className={styles.paddingBetweenCards}>
              <Card>
                <CardContent>
                  <ErrorBoundary>
                    {timezone ? (
                      <SensorLineChartContainer
                        ianaTimeZoneCode={timezone}
                        sensorId={sensor.id}
                        sensorType={
                          isMonnitSensor
                            ? [SensorType.TEMPERATURE]
                            : sensor.types
                        }
                        locale={locale}
                        resourceId={sensor.resourceId}
                      />
                    ) : (
                      <div />
                    )}
                  </ErrorBoundary>
                </CardContent>
              </Card>
            </div>
          )}

        {sensor && (
          <div className={styles.paddingBetweenCards}>
            {timezone && (
              <CertificationsCard
                ianaTimeZoneCode={timezone}
                resourceType={ResourceType.SENSOR}
                certificationTypes={[
                  CertificationTypes.TEMPERATURE_PROBE_CALIBRATION,
                ]}
                resourceId={sensor.id}
                uuid={sensor.uuid}
                vendor={sensor.vendor}
              />
            )}
          </div>
        )}

        {sensor &&
          sensor.types &&
          sensor.vendor === 'energybox' &&
          pairedHub?.model !== DeviceType.EB_SUPER_HUB &&
          this.isTempOrHumiditySensor(sensor.types) && (
            <div className={styles.paddingBetweenCards}>
              <UpdateSensorOffsetCard
                onSave={this.onSaveOffset}
                initialOffset={sensor.sensorOffset}
                sensorTypes={sensor.types}
              />
            </div>
          )}

        <div className={styles.paddingBetweenCards}>
          <Card>
            <CardContent>
              <div className={styles.generalCardGrid}>
                <CardTitle className={styles.cardTitleExtraPadding}>
                  Connection
                </CardTitle>
                <div>
                  <div>
                    <InfoChip
                      title="Sensor Name"
                      fields={[sensor && sensor.title]}
                    />
                    <InfoChip
                      title="Status"
                      fields={[
                        sensor && (
                          <DeviceOnlineState
                            displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
                            ianaTimeZoneCode={timezone}
                            devices={[
                              {
                                id: sensor.id,
                                uuid: sensor.uuid,
                                vendor: sensor.vendor,
                              },
                            ]}
                          />
                        ),
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <InfoChip
                    title="Last Check In"
                    fields={[
                      sensor && (
                        <DeviceOnlineState
                          ianaTimeZoneCode={timezone}
                          displayType={DisplayType.TIME_ONLY}
                          devices={[
                            {
                              id: sensor.id,
                              uuid: sensor.uuid,
                              vendor: sensor.vendor,
                            },
                          ]}
                        />
                      ),
                    ]}
                  />
                  <InfoChip
                    title="Interval"
                    fields={[
                      sensor &&
                        sensor.sensorStatus &&
                        sensor.sensorStatus.interval / 1000 / 60 + ' minutes',
                    ]}
                  />
                  {/* <InfoChip title="Expected Next Check In" fields={['TODO']} /> */}
                </div>
                <div>
                  <InfoChip
                    title="Signal Strength"
                    fields={[
                      sensor && (
                        <SignalStatus
                          vendor={sensor.vendor}
                          sensorStatus={sensor.sensorStatus}
                        />
                      ),
                    ]}
                  />
                  <InfoChip
                    title="Battery Level"
                    fields={[
                      sensor && (
                        <BatteryStatus
                          sensorStatus={sensor.sensorStatus}
                          isSensorDetailsPage={true}
                        />
                      ),
                    ]}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {pairedHub && (
          <div className={styles.paddingBetweenCards}>
            <Card>
              <CardContent>
                <div className={styles.generalCardGrid}>
                  <CardTitle className={styles.cardTitleExtraPadding}>
                    Paired Hub
                  </CardTitle>
                  <div>
                    <Link
                      to={`${Routes.DEVICES}${Routes.GATEWAYS}/${pairedHub.id}`}
                    >
                      <InfoChip
                        title="Device Name"
                        fields={[pairedHub.title]}
                      />
                    </Link>
                  </div>
                  <div>
                    <InfoChip title="UUID" fields={[pairedHub.uuid]} />
                  </div>
                  <div>
                    <InfoChip
                      title="Status"
                      fields={[
                        <DeviceOnlineState
                          displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
                          ianaTimeZoneCode={timezone}
                          devices={[
                            {
                              id: pairedHub.id,
                              uuid: pairedHub.uuid,
                              vendor: pairedHub.vendor,
                            },
                          ]}
                        />,
                      ]}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        )}

        <div className={styles.paddingBetweenCards}>
          <Card>
            <CardContent>
              <div className={styles.generalCardGrid}>
                <CardTitle className={styles.cardTitleExtraPadding}>
                  Hardware
                </CardTitle>
                <div>
                  <InfoChip title="UUID" fields={[sensor && sensor.uuid]} />
                  <InfoChip
                    title="Created At"
                    fields={[
                      sensor &&
                        timezone &&
                        formatIanaTimeZone(
                          new Date(sensor.createdAt),
                          locale.dateTimeFormat,
                          timezone,
                          true
                        ),
                    ]}
                  />
                </div>
                <div>
                  <InfoChip
                    title="Hardware Version"
                    fields={[
                      sensor &&
                        sensor.sensorInfo &&
                        sensor.sensorInfo.hardwareVersion,
                    ]}
                  />
                  <InfoChip
                    title="Firmware Version"
                    fields={[
                      sensor &&
                        sensor.sensorInfo &&
                        sensor.sensorInfo.firmwareVersion,
                    ]}
                  />
                </div>
                <div>
                  <InfoChip
                    title="Sensor Type"
                    fields={[
                      sensor &&
                        sensor.types &&
                        sensor.types.map(t => (
                          <SensorTypeIconWithHoverText key={t} sensorType={t} />
                        )),
                    ]}
                  />
                  <InfoChip
                    title="Vendor"
                    fields={[sensor && SensorVendors[sensor.vendor]]}
                  />
                  <InfoChip
                    title="Model"
                    fields={[
                      sensor &&
                        sensor.sensorInfo &&
                        sensor.sensorInfo.productType,
                    ]}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {showDeletePrompt && this.deletePrompt()}
      </>
    );
  }
}

const mapStateToProps = (
  { sensors, sites, app, gateways }: ApplicationState,
  { id }: OwnProps
) => {
  const siteId = sites.resourceIdToSiteId[parseInt(id)];
  return {
    sensor: sensors.sensorsById[parseInt(id)],
    editSensor: sensors.editById[parseInt(id)],
    siteId,
    timezone: sites.sitesById[siteId]?.timeZone,
    attachedTo: (sensors.sensorsById[parseInt(id)] || {}).parentResourceType,
    locale: app.locale,
    pairedHub: gateways.hubsByPairedSensorId[id],
    siteApiError: sites.siteApiError,
  };
};

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  updateOffset: (id: string, offset: OffsetConfig) =>
    dispatch(updateOffset(id, offset)),
  load: () => {
    dispatch(getSensor(id));
    dispatch(getSiteByResourceId(parseInt(id)));
    dispatch(getHubByPairedSensorId(id));
  },
  onChange: (f: string, v: string | number) => {
    dispatch(updateField(id, f, v));
  },
  patch: () => {
    dispatch(patch(id));
  },
  onDelete: () => dispatch(destroy(id)),
  onCancel: () => dispatch(reset(id)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowSensorPage);
