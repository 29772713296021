import React from 'react';
import { Link } from 'react-router-dom';
import {
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
  InspectionStatus,
  InspectionJumpToRef,
  InspectionDetailField,
  Vendor,
  FirmwareDeviceIdVendor,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import {
  getId,
  getTitle,
  getDetailFields,
  getSummaryFields,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { EdgeController } from '@energybox/react-ui-library/dist/icons';
import InspectionTile, { InspectionHeader } from '../InspectionTile';

import { Routes } from '../../../../routes';

import styles from '../InspectionTile/InspectionTile.module.css';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';

type Props = {
  data: InspectionDataFieldsByKey;
  detailLevel: InspectionDetailLevel;
  jumpToRef: InspectionJumpToRef;
  isSuperEdge?: boolean;
  canUpdateFirmware?: boolean;
  onBuildVersionLinkClick?: (
    device: FirmwareDeviceIdVendor,
    buildVersion: number,
    serialNumber: string
  ) => void;
};

const EdgeControllerTile: React.FC<Props> = ({
  data,
  detailLevel,
  jumpToRef,
  isSuperEdge,
  canUpdateFirmware = false,
  onBuildVersionLinkClick = (
    device: FirmwareDeviceIdVendor,
    buildVersion: number,
    serialNumber: string
  ) => {},
}) => {
  const detailsPageLink = `${Routes.DEVICES}${Routes.NETWORK_GROUPS}/${getId(
    data
  )}`;
  const superhubData = Object.entries(data)
    .filter(([key]) => key.startsWith('superhub_'))
    .reduce((acc, [key, value]) => {
      const newKey = key.replace('superhub_', '');
      acc[newKey] = value;
      return acc;
    }, {});
  const balenaStatusById = useSelector(
    ({ balena }: ApplicationState) => balena.statusByUuid
  );
  const hubDetailsPageLink = `${Routes.DEVICES}${Routes.GATEWAYS}/${getId(
    superhubData
  )}`;

  const fields: InspectionDetailField[] = [
    {
      name: 'Serial Number',
      key: 'serial_number',
      transformValue: value => (
        <span className={styles.longValue}>{value}</span>
      ),
    },
    {
      name: 'RSSI',
      key: 'rssi',
      transformValue: value =>
        !isNaN(value) && isDefined(value)
          ? `${value} dBm`
          : global.NOT_AVAILABLE,
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
      link: detailsPageLink,
    },
    {
      name: 'OS Version',
      key: 'ios_version',
    },
    {
      name: 'Gateway IP',
      key: 'northbound_ip',
      transformValue: value => (value === '--' ? global.NOT_AVAILABLE : value),
    },
    {
      name: 'Southbound IP',
      key: 'southbound_ip',
    },
    ...(isSuperEdge
      ? [
          //Super Edge controller array has the following additional keys
          {
            name: '(Balena) Gateway Status',
            key: 'balena_status',
          },
          {
            name: 'Build Version',
            key: 'app_version',
            transformValue: value => {
              const regex = /-rc/;
              if (isDefined(value)) {
                return regex.test(value) ? value : value.split('-')[0];
              } else {
                return global.NOT_AVAILABLE;
              }
            },
            onClick: canUpdateFirmware
              ? () => {
                  const serialNumber = (data?.serial_number as InspectionDataField)
                    ?.field as string;
                  onBuildVersionLinkClick(
                    {
                      id:
                        ((data.id as InspectionDataField)?.field as number) ||
                        -1,
                      vendor:
                        ((data.vendor as InspectionDataField)
                          ?.field as Vendor) || '',
                    },
                    balenaStatusById[serialNumber]?.runningRelease[0]?.id || 0,
                    ((data.serial_number as InspectionDataField)
                      ?.field as string) || ''
                  );
                }
              : undefined,
          },
          {
            name: 'Connection Type: 4G/LAN',
            key: 'connection_type',
          },
          {
            name: '4G Carrier/Network',
            key: 'carrier',
          },
          {
            name: '4G APN',
            key: 'apn',
          },
          {
            name: '4G Fallback Setting',
            key: 'fallback',
          },
          {
            name: 'Wifi Enabled',
            key: 'wifi',
          },
        ]
      : [
          //Edge controller array has the following additional keys
          {
            name: 'Local MQTT Host',
            key: 'mqtt_host',
          },
          {
            name: 'Edge App Version',
            key: 'app_version',
          },
          {
            name: 'Connectivity',
          },
          {
            name: 'APN Settings',
          },
        ]),
  ];

  const hubAppFields: InspectionDetailField[] = [
    {
      name: 'MCU Address (UUID)',
      key: 'UUID',
      link: hubDetailsPageLink,
    },
    {
      name: 'Firmware Version',
      key: 'firmware_version',
    },
    {
      name: 'RF Pan ID',
      key: 'rf_pan_id',
    },
    {
      name: 'Region',
      key: 'region',
    },
  ];

  const device = (data.device as InspectionDataField)?.field;
  const title =
    InspectionComponentName.EDGE_CONTROLLER + (device ? ` (${device})` : '');
  const subtitle = getTitle(data);
  const summaryFields = getSummaryFields(data, detailsPageLink);

  if (
    detailLevel === InspectionDetailLevel.ISSUES &&
    summaryFields.status === InspectionStatus.GOOD
  ) {
    return null;
  }
  return (
    <>
      <InspectionTile
        title={title}
        titleIcon={<EdgeController variant="small" size="20" />}
        subtitle={
          !!subtitle && (
            <Link to={detailsPageLink} target="_blank">
              {subtitle}
            </Link>
          )
        }
        summaryFields={summaryFields}
        detailFields={getDetailFields(fields, data, detailLevel)}
        jumpToRef={jumpToRef}
        detailTableHeader={
          !isSuperEdge ? (
            undefined
          ) : (
            <InspectionHeader
              title={'Hub App (Radio Network)'}
              summaryFields={getSummaryFields(superhubData)}
            />
          )
        }
        detailExtraFields={
          isSuperEdge ? getDetailFields(hubAppFields, superhubData) : undefined
        }
      />
    </>
  );
};

export default EdgeControllerTile;
