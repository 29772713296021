import {
  ResourcePath as ResourcePathType,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { connect } from 'react-redux';
import { getResourcePathById } from '../../actions/paths';
import { ApplicationState } from '../../reducers';
import { PathsById } from '../../reducers/paths';

type Props = {
  resourceId: number;
  resourcePathsById: PathsById;
  getResourcePathById: typeof getResourcePathById;
};

const ResourceFullPath: React.FunctionComponent<Props> = ({
  getResourcePathById,
  resourceId,
  resourcePathsById = [],
}) => {
  const path: ResourcePathType[] | undefined = resourcePathsById[resourceId];

  React.useEffect(() => {
    getResourcePathById(resourceId);
  }, [getResourcePathById, resourceId]);

  if (path && path.length) {
    const nodes = path
      .filter(p => p.id !== resourceId && p.type !== ResourceType.ORGANIZATION)
      .map((r, i, arr) => {
        if (r.type === ResourceType.SITE) {
          return <span key={r.title}>{r.title}: </span>;
        } else {
          return (
            <span key={r.title}>
              {r.title}{' '}
              {i !== arr.length - 1 ? (
                <span style={{ color: 'var(--accent-base' }}> &gt; </span>
              ) : (
                ''
              )}{' '}
            </span>
          );
        }
      });

    if (nodes.length) {
      return <>{nodes}</>;
    }

    return <>{global.NOT_AVAILABLE}</>;
  }

  return <>Loading...</>;
};

const mapStateToProps = ({ resourcePaths }: ApplicationState) => ({
  resourcePathsById: resourcePaths.byId,
});

const mapDispatchToProps = {
  getResourcePathById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceFullPath);
