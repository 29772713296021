import {
  DistributionPanel,
  EnergyPro,
  MainBreaker,
  ResourceType,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import {
  ActionPayLoad,
  DevicePortDisplayData,
  DPTableState,
  getActivePower,
  getCurrent,
  getPowerFactor,
  getVoltage,
  UpdateEnergyDeviceAction,
  UpdateEntity,
  useEnergyProStreamDataBySensorId,
} from './DPSetUpTable';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import React from 'react';
import {
  classNames,
  global,
  KW_UNIT,
} from '@energybox/react-ui-library/dist/utils';
import styles from './DPSetUpTable.module.css';
import DeviceOnlineState, {
  DisplayType,
} from '../../DeviceStatus/DeviceOnlineState';
import { Checkbox } from '@energybox/react-ui-library/dist/components';
import BreakerDropdown from './BreakerDropdown';
import { SelectEnergyDeviceCt } from '../../../components/EditEnergyDeviceSensorForm/EditEnergyDeviceSensorForm';
import SelectCtPolarity from '../../Selects/SelectCtPolarity';
import SelectPhase from '../../Selects/SelectPhase';
import { IoIosAddCircleOutline } from 'react-icons/io';
import {
  showNewEnergyDeviceSensorModal,
  updateEnergyDeviceSensorField,
} from '../../../actions/energy_devices';
import NewEnergyDeviceSensorModal from '../../EnergyDevices/NewEnergyDeviceSensorModal';
import { useGetSite } from '../../../hooks/useSites';
import { isSiteTotalChecked } from '../../../utils/energyPro';

type EnergyProConfigProps = {
  distributionPanel: DistributionPanel;
  siteId: number;
  configByDeviceId: DPTableState;
  energyPro: EnergyPro;
  energyProSubscriptionInfo: EntityInfoToSub[] | undefined;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
  setNewSensorDevice: (epro: EnergyPro) => void;
  editMode?: boolean;
};

const EnergyProConfig = ({
  distributionPanel,
  siteId,
  configByDeviceId,
  energyPro,
  energyProSubscriptionInfo,
  setUnconfirmedUpdateAction,
  setNewSensorDevice,
  editMode,
}: EnergyProConfigProps) => {
  const dispatch = useDispatch();
  const { breakers } = distributionPanel;
  const portsData = Object.values(configByDeviceId[energyPro.id] || {});

  // for portsData, first sort by port number, then fill up to the number of poles
  // say it's 1 and 3, and poles are 3, then fill 2
  const poles = distributionPanel.mainBreaker.poles;
  portsData.sort((a, b) => a.port - b.port);
  let processedPortsData: (DevicePortDisplayData | undefined)[] = [];
  for (let i = 0; i < poles; i++) {
    const sensor = portsData.find(data => data?.port === i + 1);
    processedPortsData.push(sensor);
  }

  const { energyCalculationMode } = useGetSite(siteId) || {};
  const energySensorsReadingById = useEnergyProStreamDataBySensorId(
    energyPro.id
  );

  const showingNewEnergyDeviceSensorModal = useSelector(
    ({ energyDevices }: ApplicationState) => {
      return energyDevices.showNewEnergyDeviceSensorModal;
    }
  );

  const { title, uuid, firmwareVersion } = energyPro;

  return (
    <>
      <div className={classNames(styles.busDeviceTable, styles.topSeparator)}>
        <div className={styles.tableHeader}>Device Name</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Index
        </div>
        <div className={styles.tableHeader}>Site Total</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Breakers Name
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Type
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Phase
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Polarity
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Current (A)</div>
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Voltage (V)</div>
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Power Factor</div>
        </div>
        <div className={styles.tableHeader}>
          <div className={styles.sensorReading}>Active Power ({KW_UNIT})</div>
        </div>
      </div>
      <div className={classNames(styles.busDeviceTable)}>
        <div style={{ gridRow: 'span 3', backgroundColor: 'white' }}>
          <div className={styles.eproDeviceName}>
            {energyProSubscriptionInfo && (
              <DeviceOnlineState
                displayType={DisplayType.STATUS_ONLY_WITHOUT_TEXT}
                devices={energyProSubscriptionInfo}
              />
            )}
            <div>
              <span className={styles.eproDeviceNameTitle}>{title}</span>
              <br />
              <span className={styles.eproDeviceNameSubTitle}>
                UUID: {uuid ? uuid : global.NOT_AVAILABLE}
                <br />
                FW: {firmwareVersion ? firmwareVersion : global.NOT_AVAILABLE}
              </span>
            </div>
          </div>
        </div>

        {processedPortsData.map((data, row, rows) => {
          if (data === undefined) {
            return (
              <React.Fragment key={`blankPort${row}`}>
                <div className={row % 2 === 0 ? styles.white : styles.gray}>
                  <div
                    className={classNames(
                      styles.centered,
                      styles.dropdownHeaderPadding
                    )}
                  >
                    Port {row + 1}
                    <IoIosAddCircleOutline
                      size={18}
                      color="var(--accent-base)"
                      onClick={() => {
                        setNewSensorDevice(energyPro);
                        dispatch(showNewEnergyDeviceSensorModal(energyPro.id));
                        dispatch(
                          updateEnergyDeviceSensorField(
                            'new',
                            'energyDevicePort',
                            row + 1,
                            energyPro.id
                          )
                        );
                        dispatch(
                          updateEnergyDeviceSensorField(
                            'new',
                            'breakerId',
                            -1,
                            energyPro.id
                          )
                        );
                        dispatch(
                          updateEnergyDeviceSensorField(
                            'new',
                            'breakerPole',
                            row + 1,
                            energyPro.id
                          )
                        );
                      }}
                      cursor={'pointer'}
                    />
                  </div>
                </div>
                {new Array(9).fill(0).map((_, index) => (
                  <div
                    className={classNames(
                      row % 2 === 0 ? styles.white : styles.gray,
                      index > 0 && index < 5 && styles.dropdownHeaderPadding
                    )}
                    key={`blankPort${index}`}
                  >
                    {global.NOT_AVAILABLE}
                  </div>
                ))}
              </React.Fragment>
            );
          }

          const {
            port,
            energyDeviceId,
            energySensorId,
            ct,
            reversePolarity,
            phase,
          } = data;

          const breaker: MainBreaker | undefined =
            distributionPanel.mainBreaker;

          const backgroundColor = row % 2 === 0 ? 'white' : 'gray';
          const siteTotalChecked =
            energyCalculationMode &&
            isSiteTotalChecked(
              distributionPanel.mdp,
              true,
              energyCalculationMode,
              breaker.siteTotal
            );
          const isCheckboxDisabled =
            breaker === undefined ||
            energyCalculationMode !==
              SiteEnergyCalculationMode.FLAGGED_BREAKERS;

          return (
            <React.Fragment key={`CT_Port${port}`}>
              <div
                className={classNames(
                  styles[backgroundColor],
                  styles.dropdownHeaderPadding
                )}
              >
                Port {port}
              </div>
              <div className={styles[backgroundColor]}>
                <Checkbox
                  checked={siteTotalChecked}
                  disabled={isCheckboxDisabled}
                  onChange={() => {
                    if (breaker)
                      setUnconfirmedUpdateAction({
                        type: UpdateEnergyDeviceAction.SITE_TOTAL,
                        deviceTitle: energyPro.title,
                        id: energyDeviceId,
                        breakerId: +breaker.id,
                        port,
                        value: !breaker.siteTotal,
                        entity: UpdateEntity.MAIN_BREAKER,
                      });
                  }}
                />
              </div>
              <div className={styles[backgroundColor]}>
                <BreakerDropdown
                  disabled
                  selectedBreaker={data.breaker}
                  selectedBreakerPole={data.breakerPole}
                  energyDeviceId={data.energyDeviceId}
                  port={data.port}
                  deviceTitle={energyPro.title}
                  breakers={breakers}
                  setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                />
              </div>
              <div className={styles[backgroundColor]}>
                <SelectEnergyDeviceCt
                  noBottomLine
                  deviceType={ResourceType.ENERGYPRO}
                  value={ct}
                  onSelect={updated =>
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.CT,
                      deviceTitle: energyPro.title,
                      id: energyDeviceId,
                      port,
                      value: updated,
                      entity: UpdateEntity.ENERGY_DEVICE,
                    })
                  }
                />
              </div>
              <div className={styles[backgroundColor]}>
                <SelectPhase
                  noBottomLine
                  value={phase}
                  onSelect={updated =>
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.PHASE,
                      deviceTitle: energyPro.title,
                      id: energyDeviceId,
                      port,
                      value: updated,
                      entity: UpdateEntity.ENERGY_DEVICE,
                    })
                  }
                  panelType={distributionPanel.type}
                  error={false}
                />
              </div>
              <div className={styles[backgroundColor]}>
                <SelectCtPolarity
                  noBottomLine
                  value={reversePolarity}
                  onSelect={updated =>
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.REVERSE_POLARITY,
                      deviceTitle: energyPro.title,
                      id: energyDeviceId,
                      port,
                      value: updated,
                      entity: UpdateEntity.ENERGY_DEVICE,
                    })
                  }
                  error={false}
                />
              </div>

              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getCurrent(energySensorId, energySensorsReadingById)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getVoltage(energySensorId, energySensorsReadingById)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getPowerFactor(energySensorId, energySensorsReadingById)}
                </div>
              </div>
              <div className={styles[backgroundColor]}>
                <div className={styles.sensorReading}>
                  {getActivePower(energySensorId, energySensorsReadingById)}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      {showingNewEnergyDeviceSensorModal && energyPro && (
        <NewEnergyDeviceSensorModal
          panelId={distributionPanel.id}
          energyDeviceId={energyPro.id}
          sensors={energyPro.sensors}
          energyProId={energyPro.id}
          breakerId={distributionPanel.mainBreaker.id}
          refetchEnergyPro={true}
        />
      )}
    </>
  );
};

export default EnergyProConfig;
