import {
  Organization as OrganizationIcon,
  Site as SiteIcon,
  SitesGroup as SitesGroupIcon,
} from '@energybox/react-ui-library/dist/icons';
import { ResourceType } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import styles from './AccessResourceRecord.module.css';

interface Props {
  id: number;
  role: string;
  title: string;
  resourceType: ResourceType;
}

const AccessResourceRecord: React.FC<Props> = ({
  role,
  title,
  resourceType,
}) => {
  let icon;

  switch (resourceType) {
    case 'Organization':
      icon = <OrganizationIcon size={16} />;
      break;
    case 'Site':
      icon = <SiteIcon size={16} />;
      break;
    case 'Site Group':
      icon = <SitesGroupIcon size={16} />;
      break;
    default:
      break;
  }

  return (
    <div className={styles.root}>
      <div>{role}</div>
      <div>{icon}</div>
      <div>{title}</div>
    </div>
  );
};

export default AccessResourceRecord;
