export enum FileType {
  UTILITY_BILL = 'UTILITY_BILL',
  REPORT = 'REPORT',
  IMAGE = 'IMAGE',
  FIRMWARE = 'FIRMWARE',
  DEVICE_CERT = 'DEVICE_CERT',
  EQUIPMENT_IMAGE = 'EQUIPMENT_IMAGE',
  SITE_INSTALLATION_REPORT = 'SITE_INSTALLATION_REPORT',
}

export interface Metadata {
  parsedUrl?: 'string';
  parsedAt?: 'string';
  billType?: 'string';
  provider?: 'string';
  status?: 'string';
  startDate?: 'string';
  endDate?: 'string';
  siteId?: 'string';
}
