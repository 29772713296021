import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSopComponentsByResourceId } from '../actions/sops';
import { ApplicationState } from '../reducers';
import { useCurrentOrganizationId } from './useCurrentOrganization';
import {
  Equipment,
  Sop,
  SopComponent,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { HvacSop } from '@energybox/react-ui-library/dist/types/Sop';

export const useGetHvacSopByEquipmentId = (
  equipment: Equipment
): SopComponent | undefined => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSopComponentsByResourceId(equipment.id));
  }, [equipment, dispatch]);

  const equipmentSops = useSelector<ApplicationState, SopComponent[]>(
    ({ sops }) => sops.sopComponentsByResourceId[equipment.id]
  );

  if (!isDefined(equipmentSops)) {
    return undefined;
  }

  return equipmentSops.find(sop => {
    return isDefined((sop.component as HvacSop).hvacSettings);
  });
};

export const useDetermineAlreadySelectedEquipmentTypeIds = (
  hvacSops: Sop[]
) => {
  const orgId = useCurrentOrganizationId();
  const sopToEditId = useSelectSopToEditId();

  const alreadySelectedEquipmentTypeIds = useMemo(() => {
    return hvacSops
      .filter(sop => {
        const isNotOrgLevelSop = sop.organizationUnitId !== orgId;
        const isNotSopBeingEdited = sopToEditId !== sop.id;

        return isNotOrgLevelSop && isNotSopBeingEdited;
      })
      .map(sop => sop.equipmentTypeIds[0]);
  }, [hvacSops, orgId, sopToEditId]);

  return alreadySelectedEquipmentTypeIds;
};

export const useSelectSopToEditId = () => {
  return useSelector<ApplicationState, 'new' | number>(({ sops }) => {
    return sops.idBeingEdited;
  });
};
