import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';

import styles from './SiteGroupTitle.module.css';

interface Props {
  id: number;
  title: string;
}

const SiteGroupTitle = ({ id, title }: Props) => (
  <div className={styles.root}>
    <Link to={`${Routes.SITE_GROUPS}/${id}`}>{title}</Link>
  </div>
);

export default SiteGroupTitle;
