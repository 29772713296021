import { Equipment, Sop } from '@energybox/react-ui-library/dist/types';
import { getHvacSopRoute } from '../../../../utils/hvacSop';
import {
  Button,
  ShortenedSpan,
} from '@energybox/react-ui-library/dist/components';
import { Link } from 'react-router-dom';
import styles from '../HvacControls.module.css';
import { showNewOrEditHvacSopModal } from '../../../../actions/sops';
import { useDispatch } from 'react-redux';
import { Dispatch, SetStateAction } from 'react';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useGetEquipmentBySiteId } from '../../../../hooks/useEquipment';

type Props = {
  siteId: number;
  equipment: Equipment;
  setEquipmentAffectedCount: Dispatch<SetStateAction<number>>;
};

const AddSopComponent: React.FC<Props> = ({
  siteId,
  equipment,
  setEquipmentAffectedCount,
}) => {
  const dispatch = useDispatch();
  const equipmentBySiteId = useGetEquipmentBySiteId(siteId);
  const equipmentTypeId = equipment.typeId;

  const equipmentsMatchingSite = isDefined(equipmentTypeId)
    ? Object.values(equipmentBySiteId).filter(
        equipment => equipment.typeId === equipmentTypeId
      )
    : [];

  return (
    <div>
      <span className={styles.pinkText}>No SOP</span>
      <span className={styles.addNewButton}>
        <Button
          type="button"
          variant="outlined"
          size="small"
          onClick={() => {
            setEquipmentAffectedCount(equipmentsMatchingSite.length || 0);
            dispatch(
              showNewOrEditHvacSopModal('new', siteId, equipment.typeId)
            );
          }}
        >
          Add HVAC SOP
        </Button>
      </span>
    </div>
  );
};
export default AddSopComponent;
