import { ControlBoard } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { ApplicationState } from '../../reducers';

export const controlBoardsByIdSelector = ({
  controlBoards,
}: ApplicationState) => controlBoards.controlBoardsById;

export const controlBoardSelector = (
  controlBoardId: number | string | undefined
) => ({ controlBoards }: ApplicationState): ControlBoard | undefined => {
  if (isDefined(controlBoardId)) {
    return controlBoards.controlBoardsById[controlBoardId];
  }
  return undefined;
};
