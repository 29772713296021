import {
  Button,
  Card,
  CardContent,
  Columns,
  Select,
  SelectItem,
  SelectSearch,
  Table,
} from '@energybox/react-ui-library/dist/components';
import { Site, SortDirection } from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  hasSubstr,
  SORT_IGNORED_VALUES,
  values,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  addSiteToGroup,
  getSites,
  removeSiteFromGroup,
} from '../../actions/sites';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';

interface OwnProps {
  groupId: number;
  siteIds: number[];
}

interface Props extends OwnProps {
  getAllSites: typeof getSites;
  addToGroup: (siteId: number) => void;
  removeFromGroup: (siteId: number) => void;
  allSites: Site[];
}

interface State {
  selectSearch: string;
}

class SiteGroupEditSites extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectSearch: '',
    };
  }

  componentDidMount() {
    this.props.getAllSites();
  }

  selectSearchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      selectSearch: e.currentTarget.value,
    });
  }

  resetSelectSearch() {
    this.setState({
      selectSearch: '',
    });
  }

  selectOptions() {
    const { allSites, siteIds } = this.props;
    const { selectSearch } = this.state;

    return allSites.filter(({ id, title }: Site) => {
      return !siteIds.includes(id) && hasSubstr(title, selectSearch);
    });
  }

  displayedSites() {
    const { allSites, siteIds } = this.props;
    return allSites.filter(({ id }: Site) => siteIds.includes(id));
  }

  render() {
    const { addToGroup, removeFromGroup } = this.props;
    const { selectSearch } = this.state;

    const sites = this.displayedSites();

    const tableColumns = [
      {
        header: 'Site Name',
        cellContent: ({ id, title }: Site) => (
          <Link to={`${Routes.SITES}/${id}`} children={title} />
        ),
        comparator: (a: Site, b: Site, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Location',
        cellContent: ({ address }: Site) => address,
      },
      {
        header: '',
        width: '180px',
        cellStyle: { textAlign: 'right' },
        cellContent: ({ id }: Site) => (
          <Button variant="text" onClick={() => removeFromGroup(id)}>
            <Columns template="auto 20px" gap="5px">
              <span>Remove</span>
              <FaRegTimesCircle size={16} />
            </Columns>
          </Button>
        ),
      },
    ];

    return (
      <Card>
        <CardContent>
          <Columns template="0.7fr 0.3fr">
            <h4 className="m0">Sites ({sites.length})</h4>
            <Select
              variant="add"
              title="Add to group"
              onClick={this.resetSelectSearch.bind(this)}
            >
              <SelectSearch
                onChange={this.selectSearchChange.bind(this)}
                value={selectSearch}
              />
              {this.selectOptions().map(({ id, title }: Site) => (
                <SelectItem
                  key={id}
                  onSelect={() => addToGroup(id)}
                  noRadioButton
                >
                  {title}
                </SelectItem>
              ))}
            </Select>
          </Columns>

          <div style={{ marginTop: '20px' }}>
            <Table columns={tableColumns} data={sites} />
          </div>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = ({ sites }: ApplicationState) => ({
  allSites: values(sites.sitesById).sort((a: Site, b: Site) =>
    a.title.localeCompare(b.title)
  ),
});

const mapDispatchToProps = (dispatch: any, { groupId }: OwnProps) => ({
  getAllSites: () => dispatch(getSites()),
  addToGroup: siteId => dispatch(addSiteToGroup(siteId, groupId)),
  removeFromGroup: siteId => dispatch(removeSiteFromGroup(siteId, groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteGroupEditSites);
