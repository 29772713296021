import {
  UserRole,
  Role,
  Site,
  Organization,
  AccessResource,
  UserGroup,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import { isCustomer, isDefined } from '@energybox/react-ui-library/dist/utils';
import { InstallerAccess, UserPlatformAccess } from '../types/user';
import { CurrentUserPlus } from '../reducers/app';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import { UsersById } from '../reducers/users';

// note that you can check if a user is a site admin
// by providing the specific siteId
// but it will return user's highest level of access like global/org admin
export const determineUserRoleInPlatform = (
  currentUser: CurrentUserPlus | undefined,
  siteId?: number,
  currentOrgId?: number
) => {
  if (!isDefined(currentUser)) return UserPlatformAccess.DEFAULT;

  if (currentUser.role === UserRole.ADMIN) {
    return UserPlatformAccess.GLOBAL_ADMIN;
  }

  if (
    isDefined(currentUser.organizationId) &&
    currentUser.accessResources?.find(accessResource => {
      return (
        accessResource.resourceId === currentUser.organizationId &&
        accessResource.role === Role.ADMIN
      );
    })
  ) {
    return UserPlatformAccess.ORG_ADMIN;
  }

  if (
    isDefined(siteId) &&
    currentUser.accessResources?.find(accessResource => {
      return (
        accessResource.resourceId === siteId &&
        accessResource.role === Role.ADMIN
      );
    })
  ) {
    return UserPlatformAccess.SITE_ADMIN;
  }

  if (
    isDefined(currentUser.organizationId) &&
    currentUser.accessResources?.find(accessResource => {
      return (
        accessResource.resourceId === currentUser.organizationId &&
        accessResource.role === Role.MANAGER
      );
    })
  ) {
    return UserPlatformAccess.ORG_MANAGER;
  }

  if (
    isDefined(siteId) &&
    currentUser.accessResources?.find(accessResource => {
      return (
        accessResource.resourceId === siteId &&
        accessResource.role === Role.MANAGER
      );
    })
  ) {
    return UserPlatformAccess.SITE_MANAGER;
  }

  if (
    isDefined(currentOrgId) &&
    currentUser.accessResources?.find(accessResource => {
      return (
        accessResource.resourceId === currentOrgId &&
        accessResource.role === Role.INSTALLER
      );
    })
  ) {
    return UserPlatformAccess.INSTALLER;
  }

  return UserPlatformAccess.DEFAULT;
};

export const determineIfUserCanModifySite = (
  currentUser: CurrentUserPlus | undefined,
  site?: Site
) => {
  if (!(isDefined(currentUser) && isDefined(site))) return undefined;

  if (isDefined(currentUser.role) && currentUser.role !== UserRole.VIEWER)
    return true;

  const resourceIds = [site.id, site.organizationId, ...(site.groupIds || [])];

  if (
    currentUser.accessResources?.find(({ resourceId: id, role }) => {
      return role !== Role.VIEWER && resourceIds.find(rid => rid === id);
    })
  ) {
    return true;
  }

  return false;
};

export const determineInstallerRoleInPlatform = (
  currentUser: CurrentUserPlus | undefined,
  organization?: Organization
): InstallerAccess | undefined => {
  if (!(isDefined(currentUser) && isDefined(organization))) return undefined;

  let role: UserPlatformAccess = UserPlatformAccess.DEFAULT;

  if (isDefined(currentUser.role)) {
    role = UserPlatformAccess.GLOBAL_ADMIN;
  } else if (
    currentUser.organizationType === OrganizationType.INSTALLER_PARTNER
  )
    role = UserPlatformAccess.INSTALLER;

  const orgType = isCustomer(organization.organizationType)
    ? OrganizationType.CUSTOMER
    : organization.organizationType;
  const combination = orgType + '_' + role;

  switch (combination) {
    case OrganizationType.CUSTOMER + '_' + UserPlatformAccess.GLOBAL_ADMIN:
    case OrganizationType.CUSTOMER + '_' + UserPlatformAccess.DEFAULT:
      return InstallerAccess.DEFAULT;
    case OrganizationType.CUSTOMER + '_' + UserPlatformAccess.INSTALLER:
      return InstallerAccess.INSTALLER;

    case OrganizationType.INSTALLER_PARTNER +
      '_' +
      UserPlatformAccess.GLOBAL_ADMIN:
      return InstallerAccess.ADMIN;
    case OrganizationType.INSTALLER_PARTNER +
      '_' +
      UserPlatformAccess.INSTALLER:
      return InstallerAccess.INSTALLER;
    case OrganizationType.INSTALLER_PARTNER + '_' + UserPlatformAccess.DEFAULT:
      return InstallerAccess.DENIED;

    case OrganizationType.EB_INTERNAL + '_' + UserPlatformAccess.GLOBAL_ADMIN:
      return InstallerAccess.DEFAULT;
    case OrganizationType.EB_INTERNAL + '_' + UserPlatformAccess.INSTALLER:
      return InstallerAccess.DENIED;
    case OrganizationType.EB_INTERNAL + '_' + UserPlatformAccess.DEFAULT:
      return InstallerAccess.DEFAULT;
  }
};

export const determineShouldHideFromInstaller = (
  currentUser: CurrentUserPlus | undefined,
  organization?: Organization
): boolean => {
  const installerAccess = determineInstallerRoleInPlatform(
    currentUser,
    organization
  );
  return (
    !installerAccess ||
    [InstallerAccess.INSTALLER, InstallerAccess.DENIED].includes(
      installerAccess
    )
  );
};

export const aggregateAccessForUserGroup = (
  userGroup: UserGroup,
  usersById: UsersById
): AccessResource[] => {
  const { users } = userGroup;

  const allAccesses = users.reduce<{
    [accessId: number]: AccessResource;
  }>((accesses, { id }) => {
    const { accessResources } = usersById[id] || { accessResources: [] };
    accessResources
      .filter(
        ({ resourceType, role }) =>
          resourceType === ResourceType.ORGANIZATION && role === Role.INSTALLER
      )
      .forEach(accessResource => {
        if (!accesses[accessResource.id])
          accesses[accessResource.id] = accessResource;
      });
    return accesses;
  }, {});

  return Object.values(allAccesses);
};

export const determineUserIsEBGlobalAdmin = (
  currentUser: CurrentUserPlus | undefined
): boolean => {
  if (!isDefined(currentUser)) return false;
  const org = currentUser?.organizationType;
  let access = UserPlatformAccess.DEFAULT;
  if (currentUser.role === UserRole.ADMIN) {
    access = UserPlatformAccess.GLOBAL_ADMIN;
  }
  if (
    org === OrganizationType.EB_INTERNAL &&
    access === UserPlatformAccess.GLOBAL_ADMIN
  ) {
    return true;
  }
  return false;
};
