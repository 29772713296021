import {
  EquipmentApiFilter,
  SensorType,
} from '@energybox/react-ui-library/dist/types';
import { Actions as CircuitBreakerActions } from '../actions/circuit_breakers';
import { ApplicationState } from '../reducers';
import { getSiteByResourceId } from './sites';

const apiBase = '/api/v1/equipment';

export enum Actions {
  GET_EQUIPMENTS_SUCCESS = '@equipment/GET_EQUIPMENTS_SUCCESS',
  GET_EQUIPMENTS_ERROR = '@equipment/GET_EQUIPMENTS_ERROR',
  GET_EQUIPMENTS_LOADING = '@equipment/GET_EQUIPMENTS_LOADING',

  GET_EQUIPMENT_SUCCESS = '@equipment/GET_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_ERROR = '@equipment/GET_EQUIPMENT_ERROR',
  GET_EQUIPMENT_LOADING = '@equipment/GET_EQUIPMENT_LOADING',

  GET_EQUIPMENT_BY_SITE_ID_SUCCESS = '@equipment/GET_EQUIPMENT_BY_SITE_ID_SUCCESS',
  GET_EQUIPMENT_BY_SITE_ID_ERROR = '@equipment/GET_EQUIPMENT_BY_SITE_ID_ERROR',
  GET_EQUIPMENT_BY_SITE_ID_LOADING = '@equipment/GET_EQUIPMENT_BY_SITE_ID_LOADING',

  GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_SUCCESS = '@equipment/GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_SUCCESS',
  GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_ERROR = '@equipment/GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_ERROR',
  GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_LOADING = '@equipment/GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_LOADING',

  PATCH_EQUIPMENT_LOADING = '@equipment/PATCH_EQUIPMENT_LOADING',
  PATCH_EQUIPMENT_ERROR = '@equipment/PATCH_EQUIPMENT_ERROR',
  PATCH_EQUIPMENT_SUCCESS = '@equipment/PATCH_EQUIPMENT_SUCCESS',

  DELETE_EQUIPMENT_SUCCESS = '@equipment/DELETE_EQUIPMENT_SUCCESS',
  DELETE_EQUIPMENT_ERROR = '@equipment/DELETE_EQUIPMENT_ERROR',
  DELETE_EQUIPMENT_LOADING = '@equipment/DELETE_EQUIPMENT_LOADING',

  UPDATED_QUERY = '@equipment/UPDATED_QUERY',

  TOGGLE_NEW_EQUIPMENT_MODAL = '@equipment/TOGGLE_NEW_EQUIPMENT_MODAL',

  UPDATE_FIELD = '@equipment/UPDATE_FIELD',
  RESET_EDIT_EQUIPMENT = '@equipment/RESET_EDIT_EQUIPMENT',

  CREATE_EQUIPMENT_LOADING = '@equipment/CREATE_EQUIPMENT_LOADING',
  CREATE_EQUIPMENT_SUCCESS = '@equipment/CREATE_EQUIPMENT_SUCCESS',
  CREATE_EQUIPMENT_ERROR = '@equipment/CREATE_EQUIPMENT_ERROR',

  DISPLAY_FORM_ERRORS = '@equipment/DISPLAY_FORM_ERRORS',

  CLEAR_EQUIPMENT = '@equipment/CLEAR_EQUIPMENT',

  EMAIL_FIELD_ERRORSS = '@equipment/EMAIL_FIELD_ERRORSS',
  IS_FIRST_TIME_COPY = '@equipment/IS_FIRST_TIME_COPY',
  // Equipment List
  GET_UPDATED_EQUIPMENT_LIST = '@equipment/GET_UPDATED_EQUIPMENT_LIST',
  GET_UPDATED_SENTINEL_ID = '@equipment/GET_UPDATED_SENTINEL_ID',
}

export type GetEquipmentParams = {
  ids?: string[] | number[];
  equipmentIds?: string[];
  limit?: number;
  sensorTypes?: SensorType[];
  siteIds?: string[] | number[];
  equipmentTypeIds?: string[] | number[];
  equipmentGroupIds?: string[] | number[];
  skip?: number;
  spaceIds?: string[];
  vendors?: string[];
  withPath?: boolean;
  textSearch?: string;
};

// Make this cleaner with mapping tomorrow
export const createQueryString = (params: GetEquipmentParams = {}) => {
  return Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const setEquipmentFilter = (filter?: EquipmentApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.equipmentTypeIds) {
    queryParams.set('equipmentTypeIds', filter.equipmentTypeIds.join(','));
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  queryParams.set('withPath', 'true');

  return `${apiBase}?${queryParams.toString()}`;
};

export const setGetEnergyEquipmentBySiteIdUrl = (siteId: string) => {
  return `${apiBase}/energy-monitored/site/${siteId}`;
};

export const clearEquipment = () => ({
  type: Actions.CLEAR_EQUIPMENT,
});

export const getEquipment = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_EQUIPMENT_SUCCESS,
  error: Actions.GET_EQUIPMENT_ERROR,
  loading: Actions.GET_EQUIPMENT_LOADING,
});

// Gramatically this is not correct, but I'd prefer to do this than do something like equipmentList
export const getEquipments = (
  params: GetEquipmentParams = {},
  siteId?: number | string
) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString(params)}`,
  success: { type: Actions.GET_EQUIPMENTS_SUCCESS, siteId },
  error: { type: Actions.GET_EQUIPMENTS_ERROR, siteId },
  loading: { type: Actions.GET_EQUIPMENTS_LOADING, siteId },
});

export const getEquipmentBySiteId = (
  siteId: number | string,
  sensorTypes: SensorType[] = []
) => ({
  type: 'API_GET',
  path: sensorTypes.length
    ? `${apiBase}/by-sensors/site/${siteId}?${createQueryString({
        sensorTypes,
      })}`
    : `${apiBase}/site/${siteId}`,
  success: { type: Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_EQUIPMENT_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING, siteId },
});

export const getEquipmentByEquipmentTypeId = (
  equipmentTypeId: number | string
) => ({
  type: 'API_GET',
  path: `${apiBase}?equipmentTypeIds=${equipmentTypeId}`,
  success: {
    type: Actions.GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_SUCCESS,
    equipmentTypeId,
  },
  error: Actions.GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_ERROR,
  loading: Actions.GET_EQUIPMENT_BY_EQUIPMENT_TYPE_ID_LOADING,
});

export const patch = (
  id: string,
  options?: { produceEdgeConfig?: number | string }
) => (dispatch, getState) => {
  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: (getState() as ApplicationState).equipment.editById[id].fields,
    loading: { type: Actions.PATCH_EQUIPMENT_LOADING, id },
    success: [
      { type: Actions.PATCH_EQUIPMENT_SUCCESS, id, options },
      getSiteByResourceId(id),
    ],
    error: { type: Actions.PATCH_EQUIPMENT_ERROR, id },
  });
};

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_EQUIPMENT_LOADING, id },
  success: { type: Actions.DELETE_EQUIPMENT_SUCCESS, id },
  error: { type: Actions.DELETE_EQUIPMENT_ERROR, id },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_EQUIPMENT,
  id,
});

export const updateQuery = (query: string) => ({
  type: Actions.UPDATED_QUERY,
  query,
});

export const showNewEquipmentModal = () => ({
  type: Actions.TOGGLE_NEW_EQUIPMENT_MODAL,
  value: true,
});

export const hideNewEquipmentModal = () => ({
  type: Actions.TOGGLE_NEW_EQUIPMENT_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const create = ({
  siteId,
  breakerIdToUpdate,
}: {
  siteId?: number;
  breakerIdToUpdate?: number | 'new';
}) => (dispatch, getState) => {
  dispatch({
    type: 'API_POST',
    path: apiBase,
    payload: (getState() as ApplicationState).equipment.editById['new'].fields,
    loading: Actions.CREATE_EQUIPMENT_LOADING,
    success: [
      Actions.CREATE_EQUIPMENT_SUCCESS,
      siteId ? getEquipmentBySiteId(siteId) : '',
      breakerIdToUpdate
        ? {
            type: CircuitBreakerActions.SELECT_NEWLY_CREATED_EQUIPMENT,
            breakerId: breakerIdToUpdate,
          }
        : '',
    ],
    error: Actions.CREATE_EQUIPMENT_ERROR,
  });
};

export const getByResourceId = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/resource/${id}`,
  success: Actions.GET_EQUIPMENTS_SUCCESS,
  error: Actions.GET_EQUIPMENTS_ERROR,
  loading: Actions.GET_EQUIPMENTS_LOADING,
});

export const errorEmailFieldsFunction = (data: any) => {
  return {
    type: Actions.EMAIL_FIELD_ERRORSS,
    payload: data,
  };
};

export const isFirstTimeCopy = (count: any) => {
  return {
    type: Actions.IS_FIRST_TIME_COPY,
    payload: count,
  };
};
export const updatedEquipmentList = (data: any) => {
  return {
    type: Actions.GET_UPDATED_EQUIPMENT_LIST,
    payload: data,
  };
};

export const upDatedSentiel = (data: any) => {
  return {
    type: Actions.GET_UPDATED_SENTINEL_ID,
    payload: data,
  };
};
