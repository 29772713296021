import {
  MeasurementSystem,
  WorkingMode,
  OutsideOfTimeTable,
  ThermostatWorkingMode,
  FanMode,
} from '@energybox/react-ui-library/dist/types';

export type HvacControlMapping = {
  equipmentId: number;
  thermostatId: number;
};

export type HvacControlBatchUpdate = {
  [id: number]: HvacControlBatch;
};

export type HvacControlBatch = {
  equipmentId: number;
  thermostatDisplayUnits?: MeasurementSystem;
  thermostatId: number;
  thermostatWorkingMode?: ThermostatWorkingMode;
  timetableId?: number;
  workingMode?: WorkingMode;
};

export type EditHvacSOPControl = {
  beginDelta: number;
  endDelta: number;
  equipmentId: number;
  maxTemp: number;
  minTemp: number;
  outsideMaxTemp?: number;
  outsideMinTemp?: number;
  thermostatDisplayUnits: MeasurementSystem;
  thermostatId: number;
  thermostatWorkingMode: ThermostatWorkingMode;
  timetableId?: number;
  workingMode: WorkingMode;
  fanMode: FanMode;
  outsideOfTimeTable?: OutsideOfTimeTable;
  outsideFanMode?: FanMode;
  // local adjustment fields
  enableLocalAdjustment: boolean;
  setPointLimitDelta: number;
  overrideTimer: number;
};

export type HvacControlFormErrors = {
  thermostatId?: string;
  maxTemp?: string;
  minTemp?: string;
  outsideMaxTemp?: string;
  outsideMinTemp?: string;
  outsideFanMode?: string;
  beginDelta?: string;
  endDelta?: string;
  setPointLimitDelta?: string;
};

/**
 * Array of equipmentType titles that are utilized for hvacControls
 *
 * Random fact: the equipmentType aliases differ between staging (i.e.equipmentHvacBoH) vs production (i.e. equipmentHVACBoH).
 * This is why we went with title rather than alias.
 
  * Also there are more equipmentTypes in prod vs staging. This should be fixed in IAM in the future
 * */
export const HVAC_CONTROL_EQUIPMENT_TYPES = [
  'HVAC_FoH (Controlled)',
  'HVAC_BoH (Controlled)',
  'HVAC_other (Controlled)',
];
