import {
  Card,
  CardContent,
  CardTitle,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import styles from './NewNetworkGroupModal.module.css';
import React, { useState } from 'react';
import { GatewaySensorTableTabs } from '../Gateways/GatewaySensors';

type Props = {
  title?: string;
  whiteListContent?: React.ReactNode;
  unpairedListContent?: React.ReactNode;
  unpairedListExist?: boolean;
  isThermostatCard?: boolean;
  remoteAccessButton?: React.ReactNode;
  isControlboardAndEproCard?: boolean;
};

const SuperHubCardStructure: React.FC<Props> = ({
  title,
  whiteListContent,
  unpairedListContent,
  unpairedListExist = false,
  isThermostatCard,
  remoteAccessButton,
  isControlboardAndEproCard,
}) => {
  const tab1Title = isControlboardAndEproCard
    ? GatewaySensorTableTabs.CONNECTED
    : GatewaySensorTableTabs.WHITE_LIST;

  const [activeTab, setActiveTab] = useState<GatewaySensorTableTabs>(tab1Title);

  const tabTitle = isThermostatCard
    ? GatewaySensorTableTabs.DEVICES_NEARBY
    : GatewaySensorTableTabs.SENSORS_NEARBY;

  return (
    <>
      <div className={styles.mainCardTopPadding}>
        <Card>
          <CardContent>
            <div className={styles.mainCardGrid}>
              <CardTitle className={styles.mainCardTitle}>
                <span>{title}</span>
                <span>{remoteAccessButton}</span>
              </CardTitle>
              <div className={styles.subCard}>
                <Tabs>
                  <Tab
                    active={activeTab === tab1Title}
                    onClick={() => setActiveTab(tab1Title)}
                  >
                    {tab1Title}
                  </Tab>
                  {unpairedListExist && (
                    <Tab
                      active={activeTab === tabTitle}
                      onClick={() => setActiveTab(tabTitle)}
                    >
                      {tabTitle}
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
            <div className={styles.subCard}>
              {activeTab === tab1Title && <>{whiteListContent}</>}
              {activeTab === tabTitle && <>{unpairedListContent}</>}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default SuperHubCardStructure;
