import { StreamData } from '../types/upload';
import { FileDetail } from '@energybox/react-ui-library/dist/types';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { Actions as StreamActions, CommandType } from '../actions/streamApi';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';

import FileActions from '../actions/FileActions';

export type FileInfo = {
  isLoading: boolean;
  files: FileDetail[];
  error: ApiError;
  deleteAction: DeleteInfo;
};

export type DeleteInfo = {
  isLoading: boolean;
  error: ApiError;
};

export type Files = {
  pages: {
    [page: string]: FileInfo;
  };
  progressByFileId: { [key: string]: StreamData };
};

const initialState: Files = {
  pages: {},
  progressByFileId: {},
};

const files = (state: Files = initialState, action: any) => {
  switch (action.type) {
    case FileActions.GET_FILES_BY_SITE_ID_LOADING:
      return pipe(assocPath(['pages', action.page, 'isLoading'], true))(state);
    case FileActions.GET_FILES_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(['pages', action.page, 'isLoading'], false),
        assocPath(['pages', action.page, 'files'], action.data)
      )(state);
    case FileActions.GET_FILES_BY_SITE_ID_ERROR:
      return pipe(
        assocPath(['pages', action.page, 'error'], storeAPIerror(action)),
        assocPath(['pages', action.page, 'isLoading'], false)
      )(state);

    case FileActions.DELETE_FILES_BY_ID_LOADING: {
      return pipe(
        assocPath(['pages', action.page, 'deleteAction', 'isLoading'], true),
        assocPath(['pages', action.page, 'deleteAction', 'error'], undefined)
      )(state);
    }

    case FileActions.DELETE_FILES_BY_ID_SUCCESS: {
      return pipe(
        assocPath(['pages', action.page, 'deleteAction', 'isLoading'], false),
        assocPath(['pages', action.page, 'deleteAction', 'error'], undefined)
      )(state);
    }

    case FileActions.DELETE_FILES_BY_ID_ERROR: {
      return pipe(
        assocPath(['pages', action.page, 'deleteAction', 'isLoading'], false),
        assocPath(['pages', action.page, 'deleteAction', 'error'], {
          type: action.type,
          status: action.data.status,
          message: action.data.error,
        } as ApiError)
      )(state);
    }

    case StreamActions.RECEIVED_FILE_INFO: {
      if (action.data) {
        return pipe(
          assocPath(
            ['progressByFileId', action.data.fileUuid],
            action.data.status
          )
        )(state);
      }
      return state;
    }

    case StreamActions.SEND_MESSAGE: {
      if (action.data.type === CommandType.UNSUBSCRIBE) {
        return pipe(
          assocPath(['progressByFileId', action.data.fileUuid], undefined)
        )(state);
      }
      return state;
    }
    default:
      return state;
  }
};

export default files;
