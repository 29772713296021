import { Checkbox } from '@energybox/react-ui-library/dist/components';
import {
  NetworkGroup,
  SentinelTarget,
  SentinelTargetType,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { fetchNetworkGroupsBySiteId } from '../../../../actions/legacyFetches';
import RolloutSelector from './RolloutSelector';
import styles from './StepRollout.module.css';
import { SensorChip } from './TargetChips';

type Props = {
  site: Site;
  handleTargetSelect: (sentinelTarget: SentinelTarget) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
};

type State = {
  networkGroups: NetworkGroup[];
  isLoading: boolean;
};

class StepRolloutNetworkGroups extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      networkGroups: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    let dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
    if (dublicateSentinelkey) {
      const { handleTargetSelect } = this.props;
      if (this.props.selected.length > 0) {
        handleTargetSelect({
          targetId: this.props.selected[0],
          targetType: SentinelTargetType.EDGEAPP,
        });
      }
    }
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site } = this.props;

      const networkGroups = await fetchNetworkGroupsBySiteId(site.id);
      const filteredNetworkGroups = networkGroups.filter(
        (networkGroup: NetworkGroup) => networkGroup.edge
      );

      handleSetResourceRelation(
        site.id,
        filteredNetworkGroups.map((g: NetworkGroup) => g.id)
      );

      const sortedNetworkGroups = filteredNetworkGroups.sort((a, b) =>
        a.title.localeCompare(b.title)
      );

      this.setState({
        networkGroups: sortedNetworkGroups,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleTargetSelectClick = (ev: any, item: NetworkGroup) => {
    const { handleTargetSelect } = this.props;
    ev.preventDefault();
    if (!item.edge) return;
    handleTargetSelect({
      targetId: item.id,
      targetType: SentinelTargetType.EDGEAPP,
    });
  };

  render() {
    const { networkGroups = [], isLoading } = this.state;
    const { site, selected } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    return (
      <div>
        <table className={styles.table}>
          <tbody>
            {networkGroups.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div>This site has no network groups.</div>
                </td>
              </tr>
            ) : (
              networkGroups
                .filter(({ edge }) => edge !== null)
                .map((item: NetworkGroup) => (
                  <tr
                    key={`rolloutSensorRow${item.id}`}
                    onClick={ev => this.handleTargetSelectClick(ev, item)}
                    className={styles.filterRow}
                  >
                    <td style={{ width: '25%' }}>
                      <SensorChip title={item.title} />
                    </td>
                    <td className={styles.action} style={{ width: '15%' }}>
                      <Checkbox
                        checked={item.edge ? selected.includes(item.id) : false}
                        onChange={ev => this.handleTargetSelectClick(ev, item)}
                        id={`checkbox_${site.id}_${item.id}`}
                        size={16}
                      />
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

type HeaderProps = {
  site: Site;
  handleTargetsSelect: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (sentinelTargetIds: number[]) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  isCollapsed: boolean;
  updateEquipmentList: (list: any) => void;
  upDatedSentielId: (sentinelId: any) => void;
};

type HeaderState = {
  networkGroups: NetworkGroup[];
  isLoading: boolean;
};

export class StepRolloutNetworkGroupsHeader extends React.Component<
  HeaderProps,
  HeaderState
> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      networkGroups: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site } = this.props;

      const networkGroups = await fetchNetworkGroupsBySiteId(site.id);
      const filteredNetworkGroups = networkGroups.filter(
        (networkGroup: NetworkGroup) => networkGroup.edge
      );

      handleSetResourceRelation(
        site.id,
        filteredNetworkGroups.map((g: NetworkGroup) => g.id)
      );

      const sentinelTargetTypeID = SentinelTargetType.EDGEAPP;

      this.props.updateEquipmentList(networkGroups);
      this.props.upDatedSentielId(sentinelTargetTypeID);

      this.setState({
        networkGroups: filteredNetworkGroups,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { networkGroups = [], isLoading } = this.state;
    const {
      selected,
      isCollapsed,
      handleTargetsSelect,
      handleTargetDelete,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedBySite = networkGroups
      ? networkGroups.reduce((sum, item) => {
          if (item.edge) {
            return selected.includes(item.id) ? sum + 1 : sum;
          }
          return sum;
        }, 0)
      : 0;

    const edgeIdList = networkGroups.reduce((result, { id, edge }) => {
      if (edge) result.push(id);
      return result;
    }, [] as number[]);
    const total = edgeIdList.length;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Network Groups</th>
              <th
                style={{ width: '15%' }}
                className={classNames(styles.actionHeader, styles.action)}
              >
                <RolloutSelector
                  isCollapsed={isCollapsed}
                  selected={selectedBySite}
                  totalSelected={total}
                  onDelete={() => handleTargetDelete(edgeIdList)}
                  onSelect={() =>
                    handleTargetsSelect(
                      edgeIdList.map(edgeId => ({
                        targetId: edgeId,
                        targetType: SentinelTargetType.EDGEAPP,
                      }))
                    )
                  }
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

export default StepRolloutNetworkGroups;
