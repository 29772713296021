import {
  Button,
  Checkbox,
  InputField,
  Label,
  Modal,
  RadioButton,
  RadioGroup,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  DistributionPanel,
  EnergyPro,
  EnergySensor,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Actions as CircuitBreakerActions } from '../../actions/circuit_breakers';
import { showNewEquipmentModal } from '../../actions/equipment';
import {
  subscribeToProposedPhaseReadings,
  unsubscribeToProposedPhaseReadings,
} from '../../actions/streamApi';
import NewEquipmentModal from '../../containers/Equipment/NewEquipmentModal';
import SelectBreakerRating from '../../containers/Selects/SelectBreakerRating';
import SelectBreakerType from '../../containers/Selects/SelectBreakerType';
import SelectEquipment from '../../containers/Selects/SelectEquipment';
import { EditableFields } from '../../reducers/circuit_breakers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import CircuitBreakerPhasorDiagram from '../CircuitBreaker/CircuitBreakerPhasorDiagram';
import CircuitBreakerReadings from '../CircuitBreaker/CircuitBreakerReadings';
import PowerReadings from '../CircuitBreaker/PowerReadings';
import ModalFormContent from '../ModalFormContent';
import styles from './EditCircuitBreakerForm.module.css';

interface Props {
  fields: EditableFields;
  onChange: (field: string, value: any) => void;
  currentSiteId: number;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  breakerSlotRows: number;
  apiError: ApiError;
  patchingBreakerId?: number;
  panelColumns: number;
  distributionPanel: DistributionPanel;
  energyPro?: EnergyPro;
  energySensors?: EnergySensor[];
}

const EditCircuitBreakerForm = ({
  fields,
  onChange,
  currentSiteId,
  formErrors,
  formErrorsVisible,
  breakerSlotRows,
  apiError,
  patchingBreakerId,
  panelColumns,
  distributionPanel,
  energyPro,
  energySensors,
}: Props) => {
  const {
    title,
    description,
    equipmentId,
    breakerSlot,
    breakerColumn: selectedBreakerColumn,
    type,
    subpanel,
    siteTotal,
    rating,
  } = fields;
  //currently, panel can only be 3 or 2 column;
  const isPanelThreeColumn = panelColumns === 3;
  const [isDetachEquipmentModalOpen, setIsDetachEquipmentModalOpen] = useState(
    false
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!energyPro) return;
    dispatch(
      subscribeToProposedPhaseReadings(
        energyPro.vendor,
        energyPro.uuid,
        energyPro.id
      )
    );

    return () => {
      dispatch(
        unsubscribeToProposedPhaseReadings(
          energyPro.vendor,
          energyPro.uuid,
          energyPro.id
        )
      );
    };
  }, [energyPro]);
  const openNewEquipmentModal = useCallback(() => {
    dispatch(showNewEquipmentModal());
  }, [dispatch]);

  const openDetachEquipmentModal = () => {
    setIsDetachEquipmentModalOpen(true);
  };

  const closeDetachEquipmentModal = () => {
    setIsDetachEquipmentModalOpen(false);
  };

  const resetEquipment = () => {
    onChange('equipmentId', null);
    closeDetachEquipmentModal();
  };

  const renderDetachEquipmentModal = () => {
    const actions = (
      <>
        <Button variant="text" onClick={closeDetachEquipmentModal}>
          Cancel
        </Button>

        <Button onClick={resetEquipment}>Confirm</Button>
      </>
    );

    return (
      <Modal actions={actions}>
        <div className={styles.removeEquipmentModalContent}>
          <div>Are you sure you want to detach this equipment</div>
          <div>from the circuit breaker?</div>
        </div>
      </Modal>
    );
  };

  const renderbreakerSlotSelect = () => {
    const availableSlots: number[] = [];

    const breakerSlotMapping = {};
    const { breakers } = distributionPanel;
    breakers
      .filter(({ breakerColumn }) => {
        return breakerColumn === selectedBreakerColumn;
      })
      .forEach(({ breakerSlots }) => {
        breakerSlots.forEach(slot => (breakerSlotMapping[slot] = true));
      });

    for (let i = 1; i <= breakerSlotRows; i++) {
      if (!breakerSlotMapping[i]) availableSlots.push(i);
    }
    return (
      <Select
        variant={'select'}
        title={breakerSlot}
        error={formErrorsVisible && !!formErrors.breakerSlot}
      >
        {availableSlots.map(num => (
          <SelectItem
            key={num}
            isSelected={num === breakerSlot}
            onSelect={() => onChange('breakerSlot', num)}
          >
            {num}
          </SelectItem>
        ))}
      </Select>
    );
  };

  const selectedRadioButton = () => {
    if (isPanelThreeColumn) {
      if (selectedBreakerColumn === 1) {
        return 'Left';
      } else if (selectedBreakerColumn === 2) {
        return 'Middle';
      } else if (selectedBreakerColumn === 3) {
        return 'Right';
      } else return '';
    } else {
      if (selectedBreakerColumn === 1) {
        return 'Left';
      } else if (selectedBreakerColumn === 2) {
        return 'Right';
      } else return '';
    }
  };

  const renderPanelAlignment = () => {
    if (isPanelThreeColumn) {
      return (
        <RadioGroup>
          <RadioButton
            label={'Left'}
            value={'Left'}
            checked={selectedRadioButton() === 'Left'}
            onChange={() => onChange('breakerColumn', 1)}
          />
          <RadioButton
            label={'Middle'}
            value={'Middle'}
            checked={selectedRadioButton() === 'Middle'}
            onChange={() => onChange('breakerColumn', 2)}
          />
          <RadioButton
            label={'Right'}
            value={'Right'}
            checked={selectedRadioButton() === 'Right'}
            onChange={() => onChange('breakerColumn', 3)}
          />
        </RadioGroup>
      );
    } else
      return (
        <RadioGroup>
          <RadioButton
            label={'Left'}
            value={'Left'}
            checked={selectedRadioButton() === 'Left'}
            onChange={() => onChange('breakerColumn', 1)}
          />
          <RadioButton
            label={'Right'}
            value={'Right'}
            checked={selectedRadioButton() === 'Right'}
            onChange={() => onChange('breakerColumn', 2)}
          />
        </RadioGroup>
      );
  };

  return (
    <div>
      <ModalFormContent>
        <div>
          <Label required htmlFor="title">
            Breaker Name
          </Label>
        </div>
        <div>
          <InputField
            id="title"
            type="text"
            name="title"
            value={title}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
          />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
        </div>
        <div>
          <InputField
            id="description"
            type="text"
            name="description"
            placeholder=""
            value={description}
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        <div>
          <Label htmlFor="equipmentId">Equipment</Label>
        </div>
        <div className={styles.verticalAlign}>
          <SelectEquipment
            onSelect={equipmentId => onChange('equipmentId', equipmentId)}
            value={equipmentId}
            siteId={currentSiteId}
          />

          <span className={styles.equipmentButtonsContainer}>
            {equipmentId !== null && equipmentId !== -1 && (
              <div
                className={styles.equipmentButton}
                onClick={openDetachEquipmentModal}
              >
                <span>-</span>
              </div>
            )}

            <div
              className={styles.equipmentButton}
              onClick={openNewEquipmentModal}
            >
              <span>+</span>
            </div>
          </span>
        </div>

        <div className={styles.verticalAlign}>
          <Label required>Subpanel feed</Label>
          <div className={styles.checkboxMargin}>
            <Checkbox
              checked={subpanel}
              onChange={() => {
                onChange('subpanel', !subpanel);
              }}
            />
          </div>
        </div>

        <div className={styles.verticalAlign}>
          <Label required>Site total</Label>
          <div className={styles.checkboxMargin}>
            <Checkbox
              checked={siteTotal}
              onChange={() => {
                onChange('siteTotal', !siteTotal);
              }}
            />
          </div>
        </div>

        <div>
          <Label required htmlFor="type">
            Breaker type
          </Label>
        </div>
        <div>
          <SelectBreakerType
            disabled={!!patchingBreakerId}
            value={type}
            error={formErrorsVisible && !!formErrors.type}
            onSelect={selectedType => onChange('type', selectedType)}
          />
        </div>

        {/* Per Aleks, will not need polePhases to create Breaker, might need it in future however? */}
        {/* <div>
          <Label required htmlFor="polePhases">
            Phases
          </Label>
        </div>
        <div>
          <Select
            variant={'select'}
            title={polePhases}
            error={formErrorsVisible && !!formErrors.polePhases}
          >
            {Object.values(PolePhase)
              .filter(phase => phase !== '')
              .map(phase => (
                <SelectItem
                  key={phase}
                  isSelected={phase === polePhases}
                  onSelect={() => onChange('polePhases', phase)}
                >
                  {phase}
                </SelectItem>
              ))}
          </Select>
        </div> */}

        <div>
          <Label required htmlFor="rating">
            Breaker rating (Amperes)
          </Label>
        </div>
        <div>
          <SelectBreakerRating
            value={rating}
            error={formErrorsVisible && !!formErrors.rating}
            onSelect={selectedRating => onChange('rating', selectedRating)}
          />
        </div>

        <div className={styles.compactColumn}>
          <Label required htmlFor="breakerColumn">
            Alignment on panel
          </Label>
          <div>{renderPanelAlignment()}</div>
        </div>

        <div className={styles.compactColumn}>
          <Label required htmlFor="breakerSlot">
            Slot Row
          </Label>
          <div>{renderbreakerSlotSelect()}</div>
        </div>
      </ModalFormContent>

      {renderAPIerror(
        apiError,
        CircuitBreakerActions.PATCH_CIRCUIT_BREAKER_ERROR,
        CircuitBreakerActions.INSTALL_CIRCUIT_BREAKER_ERROR,
        CircuitBreakerActions.MOVE_BREAKER_IN_PANEL_ERROR
      )}

      <CircuitBreakerReadings energySensors={energySensors} />

      <div className={styles.phasorDiagramContainer}>
        <CircuitBreakerPhasorDiagram energySensors={energySensors} />
        <PowerReadings energySensors={energySensors} />
      </div>

      <ModalFormContent>
        <Label>* Mandatory fields</Label>
      </ModalFormContent>

      <NewEquipmentModal
        lockSiteId={currentSiteId}
        breakerIdToUpdate={patchingBreakerId || 'new'}
      />

      {isDetachEquipmentModalOpen && renderDetachEquipmentModal()}
    </div>
  );
};

export default EditCircuitBreakerForm;
