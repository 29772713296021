import {
  ControlBoard,
  Scheduler,
} from '@energybox/react-ui-library/dist/types';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { useEffect, useState } from 'react';
import SelectControlBoard from '../../Selects/SelectControlBoard';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { ControlBoardsById } from '../../../reducers/control_boards';
import {
  DEFAULT_ACTION_INTERVAL,
  DEFAULT_HYSTERESIS,
  DEFAULT_LUX_THRESHOLD,
} from '../../../constants/scheduler';

type Props = {
  equipmentId: number;
  activeControl: Scheduler;
  onChange: onChange;
  controlBoardsById: ControlBoardsById;
  siteIdWithoutResourceId: string;
};

const EditModeSiteController: React.FC<Props> = ({
  activeControl,
  onChange,
  equipmentId,
  controlBoardsById,
  siteIdWithoutResourceId,
}) => {
  const [tempId, setTempId] = useState('');
  const schedulerId = activeControl?.id;
  const lightSensor = activeControl?.lightSensorSettings;

  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });
  const fields = schedulerControls?.fields;
  const controlBoardId = fields?.lightSensorSettings?.controlBoardId!;

  const beginDelta = lightSensor?.beginDelta;
  const endDelta = lightSensor?.endDelta;
  const lightBeginDelta = beginDelta
    ? Number(lightSensor?.beginDelta?.slice(0, -1))
    : 0;
  const lightEndDelta = endDelta
    ? Number(lightSensor?.endDelta?.slice(0, -1))
    : 0;

  const updateActionIntervalOnControlBoardSelect = (
    newlySelectedControlBoard: ControlBoard
  ) => {
    const sensorPublishingInterval =
      newlySelectedControlBoard.lightSensorPublishingInterval;

    let newActionIntervalValue = 5 * sensorPublishingInterval;

    while (newActionIntervalValue > 15) {
      newActionIntervalValue -= sensorPublishingInterval;
    }

    if (schedulerId) {
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'actionInterval'],
        newActionIntervalValue
      );
    } else {
      setTempId(`new${equipmentId}`);
      onChange(
        `new${equipmentId}`,
        ['lightSensorSettings', 'actionInterval'],
        newActionIntervalValue
      );
    }
  };

  useEffect(() => {
    if (lightSensor?.controlBoardId) {
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'controlBoardId'],
        lightSensor?.controlBoardId
      );
    } else if (schedulerId) {
      onChange(String(schedulerId), ['lightSensorSettings'], null);
    }
  }, []);

  useEffect(() => {
    if (schedulerId && (controlBoardId || lightSensor?.controlBoardId)) {
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'endDelta'],
        lightEndDelta
      );
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'beginDelta'],
        lightBeginDelta
      );
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'actionInterval'],
        lightSensor?.actionInterval ?? DEFAULT_ACTION_INTERVAL
      );
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'hysteresis'],
        lightSensor?.hysteresis ?? DEFAULT_HYSTERESIS
      );
    }
  }, [controlBoardId]);

  useEffect(() => {
    if (tempId.length) {
      const settingsToUpdate = [
        {
          fieldPath: ['lightSensorSettings', 'endDelta'],
          value: lightEndDelta ?? 0,
        },
        {
          fieldPath: ['lightSensorSettings', 'beginDelta'],
          value: lightBeginDelta ?? 0,
        },
        {
          fieldPath: ['lightSensorSettings', 'actionInterval'],
          value: lightSensor?.actionInterval ?? DEFAULT_ACTION_INTERVAL,
        },
        {
          fieldPath: ['lightSensorSettings', 'hysteresis'],
          value: lightSensor?.hysteresis ?? DEFAULT_HYSTERESIS,
        },
        {
          fieldPath: ['lightSensorSettings', 'threshold'],
          value: lightSensor?.threshold ?? DEFAULT_LUX_THRESHOLD,
        },
      ];

      settingsToUpdate.forEach(setting => {
        onChange(`new${equipmentId}`, setting.fieldPath, setting.value);
      });
    }
  }, [tempId]);

  return (
    <SelectControlBoard
      equipmentId={equipmentId}
      value={controlBoardId}
      onSelect={(controlBoardId: number) => {
        //if user selects the none option
        if (controlBoardId === -1) {
          if (schedulerId) {
            onChange(
              String(schedulerId),
              ['lightSensorSettings', 'timetableId'],
              -1
            );
          } else {
            setTempId(`new${equipmentId}`);
            onChange(
              `new${equipmentId}`,
              ['lightSensorSettings', 'timetableId'],
              -1
            );
          }
        }
        if (schedulerId) {
          onChange(
            String(schedulerId),
            ['lightSensorSettings', 'controlBoardId'],
            controlBoardId
          );
        } else {
          setTempId(`new${equipmentId}`);
          onChange(
            `new${equipmentId}`,
            ['lightSensorSettings', 'controlBoardId'],
            controlBoardId
          );
        }
        const newlySelectedControlBoard = controlBoardsById[controlBoardId];
        if (isDefined(newlySelectedControlBoard)) {
          updateActionIntervalOnControlBoardSelect(newlySelectedControlBoard);
        }
      }}
      isOrgLevel={true}
      includeNone
      filterControlBoardsWithoutLightSensor
      siteIdWithoutResourceId={Number(siteIdWithoutResourceId)}
    />
  );
};

export default EditModeSiteController;
