import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  GenericErrors,
  MeasurementSystem,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  create,
  displayFormErrors,
  getSpaces,
  hideNewModal,
  updateField,
} from '../../actions/spaces';
import EditSpaceForm from '../../components/EditSpaceForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/spaces';
import { CreateNewText } from '../../types/global';
import { ApiError } from '../../utils/apiErrorFeedback';

interface Props {
  onChange: (field: string, value: string) => void;
  onCreate: () => void;
  onClose: () => void;
  getSpaces: (id: string) => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  currentSiteId: string;
  formErrorsVisible: boolean;
  displayFormErrors: typeof displayFormErrors;
  apiError: ApiError;
}

function NewSpaceModal(props: Props) {
  if (!props.isVisible) return null;

  const {
    fields,
    onChange,
    onClose,
    onCreate,
    getSpaces,
    isLoading,
    formErrors,
    currentSiteId,
    formErrorsVisible,
    displayFormErrors,
  } = props;

  const onSpaceCreate = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate();
      getSpaces(currentSiteId);
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onSpaceCreate}>
        Create
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalContent>
        <ModalTitle>{CreateNewText.SPACE}</ModalTitle>
        <EditSpaceForm
          isNew
          fields={fields}
          onChange={onChange}
          currentSiteId={currentSiteId}
          formErrorsVisible={formErrorsVisible}
          formErrors={formErrors}
        />
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({ spaces }: ApplicationState) => {
  return {
    isVisible: spaces.showNewSpaceModal,
    ...spaces.editById['new'],
  };
};

const mapDispatchToProps = {
  onClose: hideNewModal,
  onChange: (field: string, value: string) => updateField('new', field, value),
  onCreate: create,
  getSpaces: id => getSpaces({ siteIds: [id] }),
  displayFormErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSpaceModal);
