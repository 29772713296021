import {
  User,
  UserGroup,
  SentinelEscalation,
  Site,
  Sop,
} from '@energybox/react-ui-library/dist/types';
import { FormText, Loader } from '@energybox/react-ui-library/dist/components';

import React, { ReactElement, useEffect, useState } from 'react';
import StepTitle from '../StepTitle';
import styles from './StepHandling.module.css';
import StepHandlingItem from './StepHandlingItem';
import { Errors, TargetedEntitiesMappingBySiteId } from '../../ReminderWizard';
import { equals, clone } from 'ramda';

type Props = {
  handleAddStepHandling: (
    siteId: number,
    sopId: number,
    recipients: Recipient[]
  ) => void;
  handleRemoveStepHandling: (
    siteId: number,
    sopId: number,
    recipients: Recipient[]
  ) => void;
  handleUpdateStepHandling: (
    index: number
  ) => (escalation: SentinelEscalation) => void;
  sites: Site[];
  selectedSops: Sop[];
  users: User[];
  userGroups: UserGroup[];
  targets: TargetedEntitiesMappingBySiteId;
  errors: Errors;
  isSetErrorField: (value: any) => void;
  isErrorField: boolean;
  isSetEmailErrorField: (value: any) => void;
  isEmailErrorField: boolean;
  setAction: (value: any) => void;
  isFormSaveError: boolean;
  isSetFormSaveError: (value: any) => void;
  handleValidation: (value: any) => void;
  setEditBtnEnable: (status: boolean[]) => any;
  editBtnEnable: boolean[];
};

export type Recipient = {
  title: string;
  identifier: string;
  id: number;
};

const StepHandling = (props: Props) => {
  const {
    sites,
    selectedSops,
    users,
    targets,
    isSetErrorField,
    isErrorField,
    isSetEmailErrorField,
    isEmailErrorField,
    setAction,
    isSetFormSaveError,
    handleValidation,
    isFormSaveError,
    setEditBtnEnable,
    editBtnEnable,
    errors,
  } = props;
  const [data, setData] = useState<User[]>([]);
  const [selectedSites, setSelectedSites] = useState<Site[]>([]);
  const [sops, setSops] = useState<Sop[]>([]);
  const [targetObj, setTargetObj] = useState<TargetedEntitiesMappingBySiteId>(
    targets
  );

  useEffect(() => {
    setData([...users]);
  }, [users]);

  useEffect(() => {
    if (!equals(sites, selectedSites)) {
      setSelectedSites(sites);
    }
  }, [sites]);

  useEffect(() => {
    if (!equals(sops, selectedSops)) {
      setSops(selectedSops);
    }
  }, [selectedSops]);

  useEffect(() => {
    if (!equals(targets, targetObj)) {
      setTargetObj(clone(targets));
    }
  }, [targets]);

  const handleAddEscalation = (siteId, sopId, recipients) =>
    props.handleAddStepHandling(siteId, sopId, recipients);

  const getHandlingItems = () => {
    const handlingItems: ReactElement[] = [];
    for (const [siteId, targetMaps] of Object.entries(targetObj)) {
      const site = sites.find(i => i.id === Number(siteId));
      targetMaps.forEach((targetMap, index) => {
        const { sop } = targetMap;
        if (site) {
          handlingItems.push(
            <div
              key={`StepHandlingElement_${site.id}_${sop?.id}}`}
              className={styles.stepHandling}
            >
              <StepHandlingItem
                handleAddEscalation={handleAddEscalation}
                handleUpdateEscalation={props.handleUpdateStepHandling(index)}
                handleDeleteEscalation={props.handleRemoveStepHandling}
                options={data}
                targets={targetObj}
                sop={sop}
                site={site}
                isDeleteAble={index !== 0}
                index={index}
                isLoading={false}
                isSetErrorField={isSetErrorField}
                isErrorField={isErrorField}
                isSetEmailErrorField={isSetEmailErrorField}
                isEmailErrorField={isEmailErrorField}
                errors={errors}
                setAction={setAction}
                isSetFormSaveError={isSetFormSaveError}
                handleValidation={handleValidation}
                isFormSaveError={isFormSaveError}
                setEditBtnEnable={setEditBtnEnable}
                editBtnEnable={editBtnEnable}
              />
            </div>
          );
        }
      });
    }
    return handlingItems;
  };

  return (
    <div className={styles.stepWrapper}>
      <StepTitle>
        Please define the staff handling the triggered reminders.
      </StepTitle>

      <div className={styles.formHeading}>
        <div>
          <FormText>Site</FormText>
        </div>
        <div>
          <FormText>SOP</FormText>
        </div>
        <div>
          <FormText>Equipment Type</FormText>
        </div>
        <div>
          <FormText>Days</FormText>
        </div>
        <div>
          <FormText>Assign *</FormText>
        </div>
      </div>

      {sops.length > 0 ? (
        getHandlingItems()
      ) : (
        <Loader className={styles.loader} />
      )}

      <div className={styles.errorCenterContainer}>
        <span className={styles.errorContainer}>
          {isEmailErrorField === true ? errors.handling.onSave : ''}
        </span>
      </div>
    </div>
  );
};

export default StepHandling;
