import { Tab, Tabs } from '@energybox/react-ui-library/dist/components';

import React, { useEffect, useState } from 'react';
import {
  EditHvacSOPControl,
  HvacControlFormErrors,
} from '../../types/hvacControl';
import EditControlsHvacFormBasicContent from './EditControlsHvacFormBasicContent';
import EditControlsHvacFormScheduleContent from './EditControlsHvacFormScheduleContent';
import styles from './EditControlsHvacForm.module.css';

enum TabNames {
  BASICS = 'Basics',
  SCHEDULE = 'Schedule',
}

type Props = {
  siteId: number;
  fields: EditHvacSOPControl;
  formErrors: HvacControlFormErrors;
  formErrorsVisible: boolean;
  onChange: (field: string, value: string | number | boolean | null) => void;
  numberOfTimesClickedOnSave: number;
};

const EditControlsHvacForm: React.FC<Props> = ({
  siteId,
  fields,
  formErrors,
  formErrorsVisible,
  onChange,
  numberOfTimesClickedOnSave,
}) => {
  const [activeTab, setActiveTab] = useState(TabNames.BASICS);

  useEffect(() => {
    const hasErrorInBasicsTab =
      formErrors.hasOwnProperty('thermostatId') ||
      formErrors.hasOwnProperty('minTemp') ||
      formErrors.hasOwnProperty('maxTemp');

    const hasErrorInScheduleTab =
      formErrors.hasOwnProperty('beginDelta') ||
      formErrors.hasOwnProperty('endDelta') ||
      formErrors.hasOwnProperty('outsideMinTemp') ||
      formErrors.hasOwnProperty('outsideMaxTemp');

    if (formErrorsVisible && hasErrorInBasicsTab) {
      setActiveTab(TabNames.BASICS);
    } else if (
      formErrorsVisible &&
      !hasErrorInBasicsTab &&
      hasErrorInScheduleTab
    ) {
      setActiveTab(TabNames.SCHEDULE);
    }
  }, [formErrorsVisible, numberOfTimesClickedOnSave]);

  return (
    <>
      <Tabs>
        <Tab
          active={activeTab === TabNames.BASICS}
          onClick={() => {
            setActiveTab(TabNames.BASICS);
          }}
        >
          {TabNames.BASICS}
        </Tab>

        <Tab
          active={activeTab === TabNames.SCHEDULE}
          onClick={() => {
            setActiveTab(TabNames.SCHEDULE);
          }}
        >
          {TabNames.SCHEDULE}
        </Tab>
      </Tabs>

      <div className={styles.tabContent}>
        {activeTab === TabNames.BASICS && (
          <EditControlsHvacFormBasicContent
            fields={fields}
            siteId={siteId}
            onChange={onChange}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
          />
        )}

        {activeTab === TabNames.SCHEDULE && (
          <EditControlsHvacFormScheduleContent
            fields={fields}
            siteId={siteId}
            onChange={onChange}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
          />
        )}
      </div>
    </>
  );
};

export default EditControlsHvacForm;
