import {
  Button,
  Card,
  CardActions,
  CardContent,
  Columns,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors, Region } from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as UserGroupActions,
  destroy,
  displayFormErrors,
  getGroup,
  patch,
  reset,
  updateField,
} from '../../actions/user_groups';
import EditGroupForm from '../../components/EditGroupForm';
import DeleteModal from '../../components/Modals/DeleteModal';
import ShowDetailPageHeader from '../../components/ShowDetailPageHeader';
import { ApplicationState } from '../../reducers';
import { UserGroup } from '../../reducers/user_groups';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import GroupSiteAccess from '../GroupSiteAccess';
import UserGroupUsersTable from './UserGroupUsersTable';
import { accessDeniedError } from '../../utils/ApiError/accessDeniedError';

interface OwnProps {
  id: string;
  isInstallerGroup?: boolean;
}

interface Props extends OwnProps {
  load: () => void;
  onChange: (field: string, value: string) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  group?: UserGroup;
  isChanged: boolean;
  title: string;
  description: string;
  region: Region;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  displayFormErrors: () => void;
  apiError: ApiError;
  userGroupApiError: ApiError;
}

interface State {
  showDeletePrompt: boolean;
}

class ShowUsersGroupPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeletePrompt: false,
    };
  }

  onSave = () => {
    const { formErrors, displayFormErrors, patch } = this.props;
    if (hasKeys(formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  componentDidMount() {
    this.props.load();
  }

  handleOpenDeletePrompt = () => {
    this.setState({ showDeletePrompt: true });
  };

  handleCloseDeletePrompt = () => {
    this.setState({ showDeletePrompt: false });
  };

  render() {
    const {
      group,
      title,
      description,
      region,
      isChanged,
      onChange,
      formErrors,
      formErrorsVisible,
      apiError,
      onDelete,
      isInstallerGroup,
      userGroupApiError,
    } = this.props;
    const { showDeletePrompt } = this.state;

    return !Object.keys(userGroupApiError).length ? (
      <>
        {group && (
          <ShowDetailPageHeader
            name={group.title}
            description={group.description}
            resourceName="Group"
            onDelete={this.handleOpenDeletePrompt.bind(this)}
          />
        )}

        <div style={{ padding: '3rem' }}>
          <Columns
            template={isInstallerGroup ? '1fr' : '0.7fr 0.3fr'}
            gap="50px"
          >
            <div>
              <div style={{ padding: '0 0 3rem 0' }}>
                <Card>
                  <CardContent>
                    <EditGroupForm
                      onChange={onChange}
                      title={title}
                      description={description}
                      region={region}
                      formErrors={formErrors}
                      formErrorsVisible={formErrorsVisible}
                      isInstallerGroup={isInstallerGroup}
                    />
                    {renderAPIerror(apiError, UserGroupActions.PATCH_ERROR)}
                  </CardContent>

                  {isChanged && (
                    <CardActions>
                      <Button
                        variant="text"
                        onClick={this.props.onCancel}
                        children="Cancel"
                      />
                      <Button onClick={this.onSave}>Save Changes</Button>
                    </CardActions>
                  )}
                </Card>
              </div>
              <div>
                {group &&
                  (isInstallerGroup ? (
                    <Card>
                      <CardContent>
                        <UserGroupUsersTable
                          groupId={group.id}
                          userIds={
                            group.users ? group.users.map(u => u.id) : []
                          }
                          isInstallerGroup
                        />
                      </CardContent>
                    </Card>
                  ) : (
                    <UserGroupUsersTable
                      groupId={group.id}
                      userIds={group.users ? group.users.map(u => u.id) : []}
                    />
                  ))}
              </div>
            </div>

            {!isInstallerGroup && (
              <div>
                <Card>
                  <CardContent>
                    {group && <GroupSiteAccess id={group.id.toString()} />}
                  </CardContent>
                </Card>
              </div>
            )}
          </Columns>
        </div>

        {showDeletePrompt && (
          <DeleteModal
            onDelete={onDelete}
            onClose={this.handleCloseDeletePrompt}
            apiError={renderAPIerror(apiError, UserGroupActions.DELETE_ERROR)}
            title={group ? group.title : 'this group'}
          />
        )}
      </>
    ) : (
      accessDeniedError(userGroupApiError)
    );
  }
}

const mapStateToProps = (
  { userGroups }: ApplicationState,
  { id }: OwnProps
) => {
  const userGroup = userGroups.userGroupsById[parseInt(id)];
  const editUserGroup = userGroups.editById[parseInt(id)] || {};
  const userGroupApiError = userGroups.userGroupApiError;
  const {
    isChanged,
    fields,
    formErrors,
    formErrorsVisible,
    apiError,
  } = editUserGroup;

  return {
    group: userGroup,
    isChanged,
    title: fields && fields.title,
    description: fields && fields.description,
    region: fields && fields.region,
    formErrors,
    formErrorsVisible,
    apiError,
    userGroupApiError: userGroupApiError,
  };
};

const mapDispatchToProps = (
  dispatch: any,
  { id, isInstallerGroup }: OwnProps
) => ({
  load: () => dispatch(getGroup(id)),
  onChange: (f: string, v: string) =>
    dispatch(
      updateField(id, f, v, isInstallerGroup ? 'installerGroup' : 'userGroup')
    ),
  patch: () => dispatch(patch(id)),
  onCancel: () => dispatch(reset(id)),
  onDelete: () => dispatch(destroy(id)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowUsersGroupPage);
