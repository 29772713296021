import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { reDoReport } from '../../actions/analytics';
import { ApplicationState } from '../../reducers';
import { Analytics, PageStatus } from '../../reducers/analytics';
import { ReportType, TempCheckInterval } from '../../types/analytics';
import { Status } from '../../types/status';
import dateFormat from 'date-fns/format';
import { useEffect, useState } from 'react';
import { sub } from 'date-fns';

export const useAnalyticsReDo = (
  siteId: string,
  reportType: ReportType,
  interval: TempCheckInterval
): {
  trigger: (from: Date, to: Date) => void;
  isLoading: boolean;
} => {
  const location = useLocation();
  const dispatch = useDispatch();

  const page = `${location.pathname}-${reportType}-${interval}`;

  const status = useSelector<ApplicationState, PageStatus>(
    ({ analytics }) => analytics.pageStatus
  );

  const trigger = (from: Date, to: Date) => {
    dispatch(
      reDoReport({
        page,
        requestBody: {
          report_type: reportType,
          report_key: siteId,
          start_date: dateFormat(from, 'yyyy-MM-dd'),
          end_date: dateFormat(to, 'yyyy-MM-dd'),
        },
      })
    );
  };

  return {
    trigger,
    isLoading: status[page] === Status.LOADING,
  };
};
