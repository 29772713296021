import {
  Actuator,
  ControlBoard,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import * as R from 'ramda';

import React from 'react';
import { connect } from 'react-redux';
import {
  displayFormErrors,
  hideEditActuatorModal,
  patch,
  updateField,
} from '../../actions/control_boards';
import EditActuatorForm from '../../components/EditActuatorForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/control_boards';
import { EditText } from '../../types/global';
import { ApiError } from '../../utils/apiErrorFeedback';

interface OwnProps {
  actuatorId: number;
  equipmentId: number;
  preselectedControlBoardId?: number;
}

interface Props extends OwnProps {
  isVisible: boolean;
  isChanged: boolean;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onEdit: (actuator: Actuator) => void;
  formErrors: GenericErrors;
  fields: EditableFields;
  controlBoards: { [id: string]: ControlBoard };
  apiError: ApiError;
  displayFormErrors: typeof displayFormErrors;
  formErrorsVisible: boolean;
  isLoading: boolean;
  actuator: Actuator;
}

class EditActuatorModal extends React.PureComponent<Props> {
  onActuatorEdit = () => {
    const {
      formErrors,
      displayFormErrors,
      onEdit,
      actuatorId,
      actuator,
    } = this.props;

    if (hasKeys(formErrors)) {
      displayFormErrors(actuatorId.toString());
    } else {
      onEdit(actuator);
    }
  };

  onActuatorModalCancel = () => {
    this.props.onClose();
  };

  renderContent = () => {
    const {
      onChange,
      formErrors,
      formErrorsVisible,
      fields,
      apiError,
      isLoading,
      controlBoards,
      preselectedControlBoardId,
    } = this.props;

    if (isLoading)
      return (
        <ModalContent>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size={16} />
          </div>
        </ModalContent>
      );

    return (
      <EditActuatorForm
        formErrorsVisible={formErrorsVisible}
        formErrors={formErrors}
        fields={fields}
        controlBoards={controlBoards}
        onChange={onChange}
        apiError={apiError}
        preselectedControlBoardId={preselectedControlBoardId}
      />
    );
  };

  render() {
    if (!this.props.isVisible) return null;

    const { onClose, isLoading, isChanged } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={this.onActuatorModalCancel}>
          Cancel
        </Button>
        <Button
          disabled={isLoading || !isChanged}
          onClick={this.onActuatorEdit}
        >
          Save
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
        <ModalTitle>{EditText.ACTUATOR}</ModalTitle>
        {this.renderContent()}
      </Modal>
    );
  }
}

const mapStateToProps = (
  { controlBoards }: ApplicationState,
  { actuatorId, equipmentId }: OwnProps
) => ({
  actuator:
    R.pathOr([], [equipmentId], controlBoards.actuatorsByEquipmentId).find(
      (a: Actuator) => a.id === actuatorId
    ) || {},
  isVisible: controlBoards.showEditActuatorModal,
  controlBoards: controlBoards.controlBoardsById,
  ...controlBoards.editById[actuatorId],
  isLoading: R.pathOr(false, [actuatorId, 'isLoading'], controlBoards.editById),
});

const mapDispatchToProps = (dispatch, { actuatorId }: OwnProps) => ({
  onClose: () => dispatch(hideEditActuatorModal()),
  onChange: (field: string, value: string) =>
    dispatch(updateField(actuatorId.toString(), field, value)),
  onEdit: (actuator: Actuator) => dispatch(patch(actuator)),
  displayFormErrors: () => dispatch(displayFormErrors(actuatorId.toString())),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActuatorModal);
