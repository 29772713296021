import {
  FilterTimePeriod,
  TimeTableTimeSlot,
} from '@energybox/react-ui-library/dist/types';
import {
  convert24hrTo12HrFormat,
  global,
} from '@energybox/react-ui-library/dist/utils';
import {
  addMinutes,
  endOfDay,
  format,
  setMilliseconds,
  startOfDay,
} from 'date-fns';

export const isWorldTime = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
export const isUsTime = /^(0?[1-9]|1[0-2]):([0-5]\d)\s?(?:AM|PM)$/i;

export function getUTCDate(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

// Given date, return the start of local date
// as UTC-0 as ISO timestamp without timezone
export function startOfDayUTC(date: Date | number) {
  const utcDate = getUTCDate(startOfDay(date));
  return asISO(utcDate);
}

// Given date, return the end of local date
// as UTC-0 as ISO timestamp without timezone
export function endOfDayUTC(date: Date | number) {
  const utcDate = getUTCDate(endOfDay(date));
  return asISO(utcDate);
}

export function asISO(date: Date | number) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss");
}

export function asIsoWithTz(date: Date) {
  const offset = date.getTimezoneOffset();
  const datePlusOffset = addMinutes(date, offset);
  return asISO(datePlusOffset);
}

export const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
export const roundUpTo15Minutes = roundUpTo(1000 * 60 * 15);

export function generateToAndFromEpoch(timePeriod: FilterTimePeriod) {
  const fifteenMinutesFromNow =
    new Date(roundUpTo15Minutes(new Date())).getTime() / 1000;
  let from: number, to: number;

  // setMs to 0 to avoid getting decimal numbers
  from = setMilliseconds(timePeriod.fromDate, 0).getTime() / 1000;
  to = setMilliseconds(timePeriod.toDate, 0).getTime() / 1000;
  if (to > fifteenMinutesFromNow) to = fifteenMinutesFromNow;

  return {
    to,
    from,
  };
}

const formatTimeDeltaString = (timeRow: TimeTableTimeSlot) => {
  if (!timeRow.delta) return global.NOT_AVAILABLE;

  const numericalDelta = parseInt(
    timeRow.delta.substring(0, timeRow.delta.length - 1)
  );
  return `${Math.abs(numericalDelta)} min ${
    numericalDelta > 0 ? 'after' : 'before'
  } ${timeRow.type.toLowerCase()}`;
};

export function formatTimeSlotToString(
  timeRow: TimeTableTimeSlot,
  is12HrFormat: boolean
): string {
  if (!timeRow.time || !timeRow.type) return global.NOT_AVAILABLE;
  const timeSubstr = (!!timeRow.time && timeRow.time.substring(0, 5)) || '';
  return !!timeRow.time
    ? is12HrFormat
      ? convert24hrTo12HrFormat(timeSubstr)
      : timeSubstr
    : formatTimeDeltaString(timeRow);
}
