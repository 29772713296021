import React from 'react';
import useGetNetworkGroupById, {
  useIsNetworkGroupOnline,
} from '../../../hooks/useGetNetworkGroup';
import { useGetGatewaysByNetworkGroupById } from '../../../hooks/useGetGateways';
import { determineOfflineGateways } from '../../../utils/gateway';

import {
  Modal,
  ModalContent,
  Button,
} from '@energybox/react-ui-library/dist/components';
import styles from './UpdateEdgeConfigConfirmationModal.module.css';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';

type Props = {
  networkGroupId: number;
  onConfirmEdgeConfigUpdate: (
    networkGroup: number,
    edgeUuid: string | undefined,
    edgeId: number | undefined
  ) => void;
  closeConfirmationModal: () => void;
};

const UpdateEdgeConfigConfirmationModal: React.FC<Props> = ({
  networkGroupId,
  onConfirmEdgeConfigUpdate,
  closeConfirmationModal,
}) => {
  const { networkGroup } = useGetNetworkGroupById(networkGroupId);
  const {
    isLoading: isNetworkGroupLoading,
    isOnline: isNetworkGroupOnline,
    edgeUuid,
  } = useIsNetworkGroupOnline(networkGroupId);
  const gateways = useGetGatewaysByNetworkGroupById(networkGroupId);

  const edgeId = networkGroup?.edge?.id;

  const offlineThermostats = [
    ...determineOfflineGateways(gateways, 'VenstarThermostat'),
    ...determineOfflineGateways(gateways, 'EnergyboxThermostat'),
  ];

  const offlineControlBoards = determineOfflineGateways(
    gateways,
    'ControlBoard'
  );

  const validationError: [] = useSelector(
    ({ edgeDevices }: ApplicationState) => {
      return edgeDevices?.edgeDeviceResponseTextByNetworkGroupId[
        networkGroupId
      ];
    }
  );

  if (networkGroupId === -1) return null;
  if (!networkGroup) return null;

  const actions = (
    <>
      <Button variant="text" onClick={closeConfirmationModal}>
        Cancel
      </Button>
      <Button
        disabled={!isNetworkGroupOnline}
        onClick={() =>
          onConfirmEdgeConfigUpdate(networkGroupId, edgeUuid, edgeId)
        }
      >
        Confirm
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalContent className={styles.content}>
        <div>
          Are you sure you want to update this Network Group configuration?
        </div>

        {!isNetworkGroupLoading && !isNetworkGroupOnline && (
          <div className={styles.pinkText}>
            Network Group is offline. Please try again later.
          </div>
        )}

        {offlineThermostats.map(t => (
          <div key={t.id} className={styles.pinkText}>
            Note: Thermostat is offline:{' '}
            <span className={styles.bold}>{t.title}</span>
          </div>
        ))}

        {offlineControlBoards.map(cb => (
          <div key={cb.id} className={styles.pinkText}>
            Note: SiteController is offline:{' '}
            <span className={styles.bold}>{cb.title}</span>
          </div>
        ))}

        <div className={styles.pinkText}>
          {validationError?.length
            ? `Configuration Warning: ${validationError}`
            : ''}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default UpdateEdgeConfigConfirmationModal;
