import {
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';
import styles from './EquipmentChartViewToggle.module.css';

interface Props {
  selectedView: keyof typeof EquipmentChartView;
  setSelectedView: (view: keyof typeof EquipmentChartView) => void;
}

export type ChartViewType = keyof typeof EquipmentChartView;

export enum EquipmentChartView {
  EQUIPMENT = 'Equipment',
  CIRCUIT = 'Circuit',
}

const EquipmentChartViewToggle: React.FC<Props> = props => {
  const { selectedView, setSelectedView } = props;

  return (
    <RadioGroup>
      {Object.keys(EquipmentChartView).map(viewKey => (
        <RadioButton
          label={EquipmentChartView[viewKey]}
          value={viewKey}
          checked={selectedView === viewKey}
          onChange={() =>
            setSelectedView(viewKey as keyof typeof EquipmentChartView)
          }
        />
      ))}
    </RadioGroup>
  );
};

export default EquipmentChartViewToggle;
