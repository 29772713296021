import React from 'react';

import styles from './BaseFilterBar.module.css';
import ChevronLeftTiny from '../ui/icons/ChevronLeftTiny';

type Props = {
  className?: string;
  navigateBack?: any;
  children?: React.ReactNode | Element;
};

const BaseFilterBar = ({ className, navigateBack, children }: Props) =>
  typeof navigateBack === 'function' ? (
    <div className={[styles.root, styles.withBackAction, className].join(' ')}>
      <div role="button" className={styles.backButton} onClick={navigateBack}>
        <ChevronLeftTiny size={20} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  ) : (
    <div className={[styles.root, styles.content, className].join(' ')}>
      {children}
    </div>
  );

export default BaseFilterBar;
