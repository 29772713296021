import {
  Organization as OrganizationIcon,
  Sites as SitesIcon,
} from '@energybox/react-ui-library/dist/icons';
import React from 'react';
import styles from './SiteSelectItem.module.css';

export enum Type {
  ORGANIZATION = 'Organization',
  SITE = 'Site',
}

export interface Props {
  title: string;
  address?: string;
  type: Type;
}

const IconForType = ({ type }: { type: Type }) => {
  switch (type) {
    case Type.ORGANIZATION:
      return <OrganizationIcon size={16} />;
    case Type.SITE:
      return <SitesIcon size={16} />;
    default:
      return null;
  }
};

const SiteSelectItem = ({ type, title, address }: Props) => (
  <div className={styles.root}>
    <div title={type}>
      <IconForType type={type} />
    </div>

    <div>
      <b>{title}</b>
      {address && (
        <div title={address} className={styles.address}>
          {address}
        </div>
      )}
    </div>
  </div>
);

export default SiteSelectItem;
