import {
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import styles from './UploadBillModal.module.css';
import {
  ChevronLeft,
  ChevronRight,
} from '@energybox/react-ui-library/dist/icons';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import UtilityBillCard from '../../UtilityBillCard';
import { useEffect, useState } from 'react';
import { SiteEdmData } from '@energybox/react-ui-library/dist/types';
import { IoIosInformationCircleOutline } from 'react-icons/io';

type Props = {
  siteId: number;
  setUploadSiteId: (siteId: number | undefined) => void;
  siteInfoReportData: [string, SiteEdmData][];
  setIsUploadExists: React.Dispatch<React.SetStateAction<boolean>>;
};
const UploadBillModal = ({
  siteId,
  setUploadSiteId,
  siteInfoReportData,
  setIsUploadExists,
}: Props) => {
  const [siteIdVal, setSiteIdVal] = useState(siteId);
  const [enableSiteNav, setEnableSiteNav] = useState(true);
  const [currentIdIndex, setCurrentIdIndex] = useState(0);
  const originalIndex = siteInfoReportData?.findIndex(siteData => {
    return siteData[0] === String(siteIdVal);
  });
  useEffect(() => {
    setCurrentIdIndex(originalIndex);
    setSiteIdVal(+siteInfoReportData[originalIndex][0]);
  }, [originalIndex]);
  useEffect(() => {
    for (let key in localStorage) {
      if (key.startsWith('UploadInfo')) {
        localStorage.removeItem(key);
      }
    }
  }, []);
  const previousIndex = currentIdIndex - 1;
  const nextIndex = currentIdIndex + 1;

  const siteData = siteInfoReportData?.map(([id, siteData]) => {
    return siteData;
  });
  const disableLeftNav = previousIndex == -1 ? true : false;
  const disableRightNav =
    nextIndex == siteInfoReportData?.length ? true : false;
  const switchToPreviousSite = () => {
    if (!enableSiteNav || disableLeftNav) {
      setCurrentIdIndex(previousIndex);
      setSiteIdVal(+siteInfoReportData[previousIndex][0]);
    }
  };
  const switchToNextSite = () => {
    if (!enableSiteNav || disableRightNav) {
      setCurrentIdIndex(nextIndex);
      setSiteIdVal(+siteInfoReportData[nextIndex][0]);
    }
  };

  return (
    <Modal>
      <ModalTitle className={styles.modalTitle}>
        Utility Bill Uploader
      </ModalTitle>
      <div className={styles.headerInfo}>
        Please select a utility bill and specify the billing period to start
        upload.
      </div>
      <ModalContent className={styles.modalContent}>
        <div className={styles.carouselBody}>
          <div
            className={classNames(
              styles.chevronIcon,
              styles.leftIcon,
              enableSiteNav || disableLeftNav ? styles.disabled : ''
            )}
            onClick={() => {
              switchToPreviousSite();
            }}
          >
            <ChevronLeft size={30} />
          </div>
          <div className={styles.siteInfo}>
            <div className={classNames(styles.siteTitle, styles.displayFlex)}>
              {siteData[currentIdIndex].site_info?.site_title}
            </div>
            <div className={styles.siteAddress}>
              {siteData[currentIdIndex].site_info?.site_address}
            </div>
          </div>
          <div
            className={classNames(
              styles.chevronIcon,
              styles.rightIcon,
              enableSiteNav || disableRightNav ? styles.disabled : ''
            )}
            onClick={() => {
              switchToNextSite();
            }}
          >
            <ChevronRight size={30} />
          </div>
        </div>

        <div className={styles.errorSection}>
          {localStorage.getItem('UploadInfo' + siteIdVal) ? (
            <>
              <IoIosInformationCircleOutline size={20} />
              <span className={styles.uploadInfo}>&nbsp;Changes made...</span>
            </>
          ) : (
            <div className={styles.displayFlex}>
              {!siteData[currentIdIndex].uploaded_bills.last_bill_name
                .field && (
                <span className={styles.errorText}>
                  Utility Bills not uploaded,
                </span>
              )}
              {siteData[currentIdIndex].uploaded_bills.last_bill_name.error && (
                <span
                  className={classNames(
                    styles.uploadedBillwarning,
                    styles.errorText
                  )}
                >
                  {siteData[currentIdIndex].uploaded_bills.last_bill_name.error}
                </span>
              )}
              {siteData[currentIdIndex].uploaded_bills.last_bill_name
                .warning && (
                <span
                  className={classNames(
                    styles.uploadedBillwarning,
                    styles.warningText
                  )}
                >
                  {
                    siteData[currentIdIndex].uploaded_bills.last_bill_name
                      .warning
                  }
                </span>
              )}
            </div>
          )}
        </div>
        <UtilityBillCard
          title={<div className={styles.cardTitle}>Utility Bill Config</div>}
          siteId={siteIdVal}
          isUploadFromEdmPage={true}
          setUploadSiteId={setUploadSiteId}
          setEnableSiteNav={setEnableSiteNav}
          setIsUploadExists={setIsUploadExists}
        />
      </ModalContent>
    </Modal>
  );
};

export default UploadBillModal;
