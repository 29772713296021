import * as R from 'ramda';
import { InspectionDataFieldsByKey } from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/inspection';
import { DateTime } from 'luxon';
import { Metadata } from '../types/files';
import { ResourceLimits } from 'worker_threads';
import { Equipment } from '@energybox/react-ui-library/dist/icons';

export type InspectionDataFields = {
  [component: string]: InspectionDataFieldsByKey[];
} & {
  unpaired_sensors?: {
    errors: number;
    warnings: number;
    result: string;
    sensors: InspectionDataFieldsByKey[];
  };
};

type InspectionReportGeneric = {
  key: number;
  report_date: string;
  report_version: number;
  reportFetchTime: Date;
};

export type InspectionReportLoading = InspectionReportGeneric & {
  data: InspectionLoading;
};

export type InspectionReportLoaded = InspectionReportGeneric & {
  data: InspectionDataFields;
};

export type InspectionReport = InspectionReportLoading | InspectionReportLoaded;

export type InspectionLoading = {
  loading: number;
};

export type InspectionReportBySiteId = {
  [siteId: number]: InspectionReport;
};

export type LoadingInspectionReportBySiteId = {
  [siteId: number]: string | undefined;
};

export type LoadingTestButtonReport = {
  [equipmentId: number]: string | undefined;
};

export type TestButtonReport = {
  [siteId: number]: TestButtonReportGeneric;
};

export type TestButtonReportGeneric = {
  flg_temp: boolean;
  key: number;
  report_date: DateTime;
  report_version: number;
  meta_data: MetadataForRunTest;
  data: RunTestData;
};

export type MetadataForRunTest = {
  complete_data: boolean;
  created_at: DateTime;
  try_counter: number;
  flg_exception: boolean;
  header: {
    report_time: DateTime;
    report_endtime: DateTime;
    flg_realtime: boolean;
    flg_async_agg: boolean;
    request_args: string;
  };
};

export type RunTestData = {
  by_energy_pro: {
    [proId: number]: {
      energy_pro_title: string;
      by_bus_device: {
        [busId: number]: RunTestDataResults;
      };
    };
  };
  error_message: '';
};

export type RunTestDataResults = {
  bus_device_title: string;
  circuit_results: Circuit_results[];
};

export type Circuit_results = {
  equipment_title: string;
  equipment_id: number;
  port_title: string;
  current: number;
  breaker_title: string;
  breaker_id: number;
  test_result: string;
};

export type Inspection = {
  inspectionReportBySiteId: InspectionReportBySiteId;
  loadingInspectionReportBySiteId: LoadingInspectionReportBySiteId;
  loadingTestButtonReport: LoadingTestButtonReport;
  testButtonReport: TestButtonReport;
};

const initialState = {
  inspectionReportBySiteId: {},
  loadingInspectionReportBySiteId: {},
  loadingTestButtonReport: {},
  testButtonReport: {},
};

const inspection = (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.START_SITE_INSPECTION_REPORT_LOADING: {
      return R.pipe(
        R.assocPath(['loadingInspectionReportBySiteId', action.siteId], true)
      )(state);
    }

    case Actions.START_SITE_INSPECTION_REPORT_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['loadingInspectionReportBySiteId', action.siteId],
          action.data.report_key
        )
      )(state);
    }

    case Actions.START_SITE_INSPECTION_REPORT_ERROR:
    case Actions.GET_SITE_INSPECTION_REPORT_ERROR: {
      return R.pipe(
        R.assocPath(
          ['loadingInspectionReportBySiteId', action.siteId],
          undefined
        )
      )(state);
    }

    case Actions.GET_SITE_INSPECTION_REPORT_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['loadingInspectionReportBySiteId', action.siteId],
          action.data.report_version > 0
            ? undefined
            : state.loadingInspectionReportBySiteId[action.siteId]
        ),
        R.assoc(
          'inspectionReportBySiteId',
          R.mergeRight(R.view(R.lensProp('inspectionReportBySiteId'), state), {
            [action.data.key]: {
              ...action.data,
              reportFetchTime: new Date(),
            },
          })
        )
      )(state);
    }

    case Actions.START_CONTROL_TEST_BUTTON_REPORT_ERROR:
    case Actions.GET_CONTROL_TEST_BUTTON_REPORT_ERROR:
      return R.pipe(
        R.assocPath(['loadingTestButtonReport', action.siteId], undefined)
      )(state);

    case Actions.START_CONTROL_TEST_BUTTON_REPORT_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['loadingTestButtonReport', action.siteId],
          action.data.report_key
        )
      )(state);

    case Actions.GET_CONTROL_TEST_BUTTON_REPORT_SUCCESS:
      return R.pipe(
        R.assocPath(['testButtonReport', action.siteId], action.data)
      )(state);

    default:
      return state;
  }
};

export default inspection;
