import {
  ExpandableRow,
  MenuDropdown,
  MenuDropdownItem,
  SearchBox,
  SkeletonDisplayText,
  TextChip,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListGroupHeader,
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import {
  IconLock,
  Scheduler as SchedulerIcon,
} from '@energybox/react-ui-library/dist/icons';
import {
  CurrentUser,
  Height,
  Length,
  Site,
  TimeTable,
  TimeTableCounts,
  TimetableReferenceControlsType,
  TimetableReferenceType,
  TimetableReferenceTypeToLabel,
  TimeTableRow,
  TimeTableSpecialRow,
} from '@energybox/react-ui-library/dist/types';
import {
  TimeTableAttachedResource,
  TimetableOverrideType,
  TimetableOverrideTypeLabel,
} from '@energybox/react-ui-library/dist/types/TimeTable';
import {
  classNames,
  global,
  hasSubstr,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';

import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { getEquipmentTypes } from '../../actions/app';
import {
  Actions,
  duplicateTimeTable,
  getTimeTableById,
  getTimeTableCountsByOrgUnitId,
  getTimeTables,
  primeForDelete,
  showDeleteModal,
  showEditModal,
  showNewModal,
} from '../../actions/time_tables';
import history from '../../history';
import { useIs12HrTimeFormat } from '../../hooks/useAppDetails';
import { ApplicationState } from '../../reducers';
import { EquipmentTypesById } from '../../reducers/app';
import { UserPlatformAccess } from '../../types/user';
import { checkCommonPlural } from '../../util';
import { generateDateLabel } from '../../utils/dates';
import { getWeekDayRange } from '../../utils/getWeekdayRange';
import { formatTimeSlotToString } from '../../utils/time';
import { determineUserRoleInPlatform } from '../../utils/user';
import DeleteTimeTableModal from './DeleteTimeTableModal';
import EditTimeTableModal from './EditTimeTableModal';
import styles from './TimeTablesTable.module.css';
import { Placeholder } from '../../types/global';

import theme from '../../theme';
import { PageContentHeader, FixedContentWrapper } from '../../components/Page';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import RecordInfoTooltip from '../../components/RecordBubble/RecordInfoTooltip';
import TimetableVisualization from '../OrgControlsManagement/TimetableVisualization/TimetableVisualization';
import { pageNotFoundError } from '../../utils/ApiError/pageNotFoundError';

interface OwnProps {
  organizationUnitId: string;
  isOrgLevel?: boolean;
  site?: Site;
}

interface Props extends OwnProps {
  load: (currentOrganizationId?: number) => void;
  currentOrganizationId?: number;
  showNewModal: typeof showNewModal;
  showDeleteModal: typeof showDeleteModal;
  showEditModal: typeof showEditModal;
  getTimeTableById: typeof getTimeTableById;
  timeTables: TimeTable[];
  timeTableCounts: TimeTableCounts[];
  primeForDelete: typeof primeForDelete;
  duplicateTimeTable: typeof duplicateTimeTable;
  getTimeTablesIsLoading?: boolean;
  isTimeTableCountsLoading?: boolean;
  equipmentTypesById: EquipmentTypesById;
  currentUser: CurrentUser | undefined;
  viewportType: ViewportTypes;
}

interface State {
  query: string;
  timeTableId: number;
  openedTimetableVisualizations: (JSX.Element | undefined)[][];
}

const SpecialRow = ({
  row,
  isOrgLevel,
  startOpen,
}: {
  row: TimeTableSpecialRow;
  isOrgLevel?: boolean;
  startOpen?: boolean;
}) => {
  const is12HrFormat = useIs12HrTimeFormat();

  const rowElements = row.rows.map((specialRow, i) => {
    return {
      key: `${row.title}-${i}`,
      row: (
        <>
          {<Cell />}
          {i === 0 && (
            <Cell width={isOrgLevel ? '3' : '2'} className={styles.rowTitle}>
              <span className={styles.rowTitle}>{row.title}</span>
              {row.dateRanges &&
                row.dateRanges.map((slot, index) => (
                  <span className={styles.timeChip}>
                    <TextChip
                      itemId={index}
                      label={`${generateDateLabel(slot.begin)} ${
                        slot.end ? ' - ' + generateDateLabel(slot.end) : ''
                      }`}
                    />
                  </span>
                ))}
            </Cell>
          )}
          {i > 0 && <Cell width={isOrgLevel ? '3' : '2'} />}
          <Cell width="2">{getWeekDayRange(specialRow.weekdays)}</Cell>
          <Cell width={isOrgLevel ? '2' : '1'}>
            {specialRow.overrideType === TimetableOverrideType.AUTO
              ? `${formatTimeSlotToString(
                  specialRow.begin,
                  is12HrFormat
                )} - ${formatTimeSlotToString(specialRow.end, is12HrFormat)}`
              : null}
          </Cell>
          <Cell width={isOrgLevel ? '2' : '1'}>
            {specialRow.overrideType !== TimetableOverrideType.AUTO
              ? specialRow.overrideType === TimetableOverrideType.ALWAYS_ON
                ? TimetableOverrideTypeLabel.ALWAYS_ON
                : TimetableOverrideTypeLabel.ALWAYS_OFF
              : null}
          </Cell>
        </>
      ),
    };
  });
  return (
    <>
      {rowElements.map((element, index) => {
        return (
          <>
            {<Cell />}
            <Cell width="12" className={styles.singleRow}>
              {element.row}
            </Cell>{' '}
          </>
        );
      })}
    </>
  );
};

const DefaultRow = ({
  rows,
  isOrgLevel,
  startOpen,
}: {
  rows: TimeTableRow[];
  isOrgLevel?: boolean;
  startOpen?: boolean;
}) => {
  const is12HrFormat = useIs12HrTimeFormat();

  const rowElements = rows.map((row, i) => {
    return {
      key: `${i} default`,
      row: (
        <>
          {<Cell />}
          <Cell width={isOrgLevel ? '3' : '2'} className={styles.rowTitle}>
            {i === 0 ? 'Default' : ''}
          </Cell>
          <Cell width="2">{getWeekDayRange(row.weekdays)}</Cell>
          <Cell width={isOrgLevel ? '2' : '1'}>
            {row.overrideType === TimetableOverrideType.AUTO
              ? `${formatTimeSlotToString(
                  row.begin,
                  is12HrFormat
                )} - ${formatTimeSlotToString(row.end, is12HrFormat)}`
              : null}
          </Cell>
          <Cell width={isOrgLevel ? '2' : '1'}>
            {row.overrideType !== TimetableOverrideType.AUTO
              ? row.overrideType === TimetableOverrideType.ALWAYS_ON
                ? TimetableOverrideTypeLabel.ALWAYS_ON
                : TimetableOverrideTypeLabel.ALWAYS_OFF
              : null}
          </Cell>
        </>
      ),
    };
  });
  return (
    <>
      {rowElements.map((element, index) => {
        return (
          <>
            {index !== 0 && <Cell />}
            <Cell
              width="12"
              className={classNames(styles.singleRow, styles.firstRow)}
            >
              {element.row}
            </Cell>{' '}
          </>
        );
      })}
    </>
  );
};

class TimeTablesTable extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      timeTableId: -1,
      openedTimetableVisualizations: new Array(this.props.timeTables.length),
    };
  }

  jumpToRef = React.createRef<HTMLElement>();

  componentDidMount() {
    const { load, currentOrganizationId } = this.props;

    load(currentOrganizationId);
    this.scrollToTimetable();
    window.addEventListener('hashchange', this.scrollToTimetable);
  }

  componentDidUpdate(prevProps) {
    const { timeTables, timeTableCounts, equipmentTypesById } = this.props;
    if (
      prevProps.timeTables.length !== timeTables.length ||
      prevProps.timeTableCounts.length !== timeTableCounts.length ||
      Object.keys(prevProps.equipmentTypesById).length !==
        Object.keys(equipmentTypesById).length
    ) {
      this.scrollToTimetable();
    }
    if (prevProps.timeTables.length !== timeTables.length) {
      this.setState({
        openedTimetableVisualizations: new Array(timeTables.length),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.scrollToTimetable);
  }

  scrollToTimetable = () => {
    this.jumpToRef.current?.scrollIntoView({
      behavior: 'auto',
    });
  };

  getGroupHeaders = isOrgLevel => {
    return isOrgLevel
      ? ([
          {
            width: '10',
            content: '',
          },
          {
            width: '2',
            content: 'Referenced By',
          },
        ] as CardListGroupHeader[])
      : undefined;
  };

  getHeader = isOrgLevel => {
    const commonColumns = [
      {
        width: isOrgLevel ? '3' : '2',
        content: 'Timetable Name',
      },
      {
        width: '1',
        content: '',
      },
      {
        width: '2',
        content: 'Schedules',
      },
      {
        width: isOrgLevel ? '2' : '1',
        content: 'Time',
      },
      {
        width: isOrgLevel ? '2' : '1',
        content: 'Operation Mode',
      },
    ];
    const orgLevelColumns = [
      {
        width: '1',
        content: 'SOP',
        align: 'center',
        cellClassName: styles.countHeaderCellAdjust,
      },
      {
        width: '1',
        content: ' Controls',
        align: 'center',
        cellClassName: styles.countHeaderCellAdjust,
      },
      {
        width: '1',
        content: '',
      },
    ];
    const siteLevelColumns = [
      {
        width: '1',
        content: 'Referenced By',
      },
      {
        width: '2',
        content: 'Attached To',
      },
      {
        width: '1',
        content: 'No. of Equipment',
        align: 'center',
      },
      {
        width: '1',
        content: 'Timetable Level',
      },
      {
        width: '1',
        content: '',
      },
    ];
    return commonColumns.concat(
      isOrgLevel ? orgLevelColumns : siteLevelColumns
    ) as CardListHeader[];
  };

  handleSearchChange = (value: string) => {
    this.setState({ query: value });
  };

  render() {
    const {
      isOrgLevel,
      site,
      timeTables,
      timeTableCounts,
      showEditModal,
      showDeleteModal,
      primeForDelete,
      organizationUnitId,
      getTimeTableById,
      duplicateTimeTable,
      getTimeTablesIsLoading,
      isTimeTableCountsLoading,
      equipmentTypesById,
      currentUser,
      viewportType,
    } = this.props;
    const isMobile = viewportType === ViewportTypes.MOBILE;
    const { query, openedTimetableVisualizations } = this.state;

    const urlTimetableId = Number(history.location.hash.slice(1));
    const urlTimetableIdExist = timeTables.filter(
      timetable => timetable.id === urlTimetableId
    );
    const userPlatformAccess: UserPlatformAccess = determineUserRoleInPlatform(
      currentUser,
      isOrgLevel ? undefined : site?.id
    );
    const showErrorPage =
      urlTimetableId > 0 && urlTimetableIdExist.length < 1 && timeTables.length;

    const onDeleteTimeTable = timeTableId => {
      primeForDelete(timeTableId);
      showDeleteModal();
    };

    const onToggleEditTimeTable = (
      timeTableId: number | string | null = null
    ) => {
      getTimeTableById(timeTableId);
      showEditModal(timeTableId);
    };

    const renderAutolockOrMenuDropdown = (
      isTimeTableCountsLoading: boolean | undefined,
      isMenuLocked: boolean,
      userPlatformAccess: UserPlatformAccess,
      isOrgLevel: boolean | undefined,
      timeTable: TimeTable
    ) => {
      if (isTimeTableCountsLoading) return null;

      const isGlobalAdmin =
        userPlatformAccess === UserPlatformAccess.GLOBAL_ADMIN;
      const isOrgLevelTimetable =
        timeTable.organizationUnitId !== organizationUnitId;
      const isOrgLevelTimetableInSiteTableView =
        isOrgLevelTimetable && !isOrgLevel;

      const determineIfUserCanSeeMenuDropdown = (
        userPlatformAccess: UserPlatformAccess
      ) => {
        switch (userPlatformAccess) {
          case UserPlatformAccess.GLOBAL_ADMIN:
            return true;

          case UserPlatformAccess.ORG_ADMIN:
            return !isMenuLocked;

          case UserPlatformAccess.SITE_ADMIN:
          case UserPlatformAccess.DEFAULT:
            return !isOrgLevel && !isMenuLocked;

          default:
            return false;
        }
      };

      if (!isGlobalAdmin && isMenuLocked) {
        return (
          <Tooltip
            underline={false}
            arrowDirection="topRight"
            content={
              <div className={styles.autolockTooltip}>
                <div className={styles.autolockTooltipTitle}>Autolock</div>
                <div className={styles.autolockTooltipContent}>
                  This timetable is locked on an organization level because it
                  is referenced by site level controls. Please update on site
                  level in line with local requirements.
                </div>
              </div>
            }
          >
            <IconLock size={16} className={styles.autoLockIcon} />
          </Tooltip>
        );
      } else {
        const canUserSeeMenuDropdown = determineIfUserCanSeeMenuDropdown(
          userPlatformAccess
        );
        const shouldDisplayEditOption =
          canUserSeeMenuDropdown && !isOrgLevelTimetableInSiteTableView;
        const shouldDisplayCopyOption = canUserSeeMenuDropdown;
        const shouldDisplayDeleteOption =
          canUserSeeMenuDropdown && !isOrgLevelTimetableInSiteTableView;

        const shouldNotDisplayMenuDropdown =
          !shouldDisplayEditOption &&
          !shouldDisplayCopyOption &&
          !shouldDisplayDeleteOption;

        if (shouldNotDisplayMenuDropdown) return null;
        return (
          <MenuDropdown>
            {shouldDisplayEditOption && (
              <MenuDropdownItem
                onSelect={() => onToggleEditTimeTable(timeTable.id)}
              >
                Edit
              </MenuDropdownItem>
            )}
            {shouldDisplayCopyOption && (
              <MenuDropdownItem
                onSelect={() =>
                  duplicateTimeTable(
                    timeTable,
                    isOrgLevelTimetableInSiteTableView
                  )
                }
              >
                {isOrgLevelTimetableInSiteTableView
                  ? 'Copy to Site'
                  : 'Duplicate'}
              </MenuDropdownItem>
            )}
            {shouldDisplayDeleteOption && (
              <MenuDropdownItem
                isRed
                onSelect={() => onDeleteTimeTable(timeTable.id)}
              >
                Delete
              </MenuDropdownItem>
            )}
          </MenuDropdown>
        );
      }
    };

    const filteredTimeTables =
      query && query.length >= 3
        ? timeTables.filter(item => hasSubstr(item.title, query))
        : timeTables;

    const handleSchedulerButtonClick = (data: TimeTable, index: number) => {
      let openedVisualizationsCopy = [...openedTimetableVisualizations];
      [...Array(openedTimetableVisualizations.length).keys()].forEach(i => {
        if (i === index) {
          if (
            openedVisualizationsCopy[i] === undefined ||
            openedVisualizationsCopy[i].length === 0
          ) {
            openedVisualizationsCopy[i] = [
              <TimetableVisualization
                timeTable={data}
                numberOfColumns={timeTables.length}
                className={styles.timeTableWidth}
              />,
            ].concat(
              data?.specials?.map((_, index: number) => {
                return (
                  <TimetableVisualization
                    timeTable={data}
                    numberOfColumns={timeTables.length}
                    className={styles.timeTableWidth}
                    specialIndex={index}
                  />
                );
              })
            );
          } else {
            openedVisualizationsCopy[i] = [];
          }
        }
      });
      this.setState({
        openedTimetableVisualizations: openedVisualizationsCopy,
      });
    };

    const data: CardListRowData[] = filteredTimeTables.map(
      (timeTable, index) => {
        const {
          attachedResources,
          sopReferenceCount,
          camCount,
        } = getTimeTableCount(timeTableCounts, Number(timeTable.id) || -1);

        const isFirstFocusedTimetable = urlTimetableId === timeTable.id;
        const isOrgLevelTimetable =
          timeTable.organizationUnitId !== organizationUnitId;

        let isMenuLocked = false;
        let groupedAttachedResources;
        let countsLoadingEle: null | ReactElement = null;

        if (isTimeTableCountsLoading) {
          countsLoadingEle = (
            <SkeletonDisplayText
              height={Height.EXTRA_SMALL}
              possibleLengths={[Length.EXTRA_SHORT]}
            />
          );
        } else {
          countsLoadingEle = null;
          if (isOrgLevel) {
            isMenuLocked = camCount > 0;
          } else {
            /*
             * Sample grouped format:
             *  {
             *    SITE_OPERATING_HOURS: [ {} ],
             *    LIGHT_SENSOR: {
             *      1234: [ {}, {} ],
             *      5678: [ {} ]
             *    }
             *  }
             */
            const groupedResources = attachedResources.reduce((acc, value) => {
              if (
                value.timetableReferenceType ===
                TimetableReferenceType.SITE_OPERATING_HOURS
              ) {
                if (!acc[value.timetableReferenceType]) {
                  acc[value.timetableReferenceType] = [];
                }
                acc[value.timetableReferenceType].push(value);
              } else {
                if (!acc[value.timetableReferenceType]) {
                  acc[value.timetableReferenceType] = {};
                }
                if (!acc[value.timetableReferenceType][value.equipmentTypeId]) {
                  acc[value.timetableReferenceType][value.equipmentTypeId] = [];
                }
                acc[value.timetableReferenceType][value.equipmentTypeId].push(
                  value
                );
              }
              return acc;
            }, {});

            groupedAttachedResources = Object.keys(groupedResources).map(
              refType => {
                const isSiteOperatingHours =
                  refType === TimetableReferenceType.SITE_OPERATING_HOURS;
                return (
                  <>
                    <Cell width="1" className={styles.specialColumnSpace}>
                      {TimetableReferenceTypeToLabel[refType]}
                    </Cell>
                    {isSiteOperatingHours && (
                      <>
                        <Cell width="2" className={styles.specialColumnSpace}>
                          {groupedResources[refType].map(
                            resource =>
                              resource.siteId === site?.id && (
                                <div>{site?.title}</div>
                              )
                          )}
                        </Cell>
                        <Cell width="1" cellAlign="center">
                          {global.NOT_AVAILABLE}
                        </Cell>
                      </>
                    )}
                    {!isSiteOperatingHours && (
                      <Cell
                        width="3"
                        className={styles.siteLevelReferenceInnerCell}
                      >
                        {Object.keys(groupedResources[refType]).map(
                          equipmentTypeId => {
                            const resources =
                              groupedResources[refType][equipmentTypeId];
                            return (
                              <>
                                <Cell
                                  width="2"
                                  className={styles.specialColumnSpace}
                                >
                                  {
                                    (equipmentTypesById[equipmentTypeId] || {})
                                      .title
                                  }
                                </Cell>
                                <Cell
                                  width="1"
                                  cellAlign="center"
                                  className={styles.specialColumnSpace}
                                >
                                  {resources.length}
                                </Cell>
                              </>
                            );
                          }
                        )}
                      </Cell>
                    )}
                  </>
                );
              }
            );
          }
        }

        return {
          key: String(timeTable.id),
          startExpanded: isFirstFocusedTimetable,
          content: (
            <>
              <Cell width={isOrgLevel ? '9' : '6'}>
                <span
                  ref={isFirstFocusedTimetable ? this.jumpToRef : null}
                  className={classNames(styles.rowTitle)}
                >
                  {timeTable.title}
                </span>
              </Cell>
              {isOrgLevel && (
                <>
                  <Cell
                    width="1"
                    cellAlign="center"
                    className={styles.headerCellAdjust}
                  >
                    {countsLoadingEle === null
                      ? sopReferenceCount
                      : countsLoadingEle}
                  </Cell>
                  <Cell
                    width="1"
                    cellAlign="center"
                    className={styles.headerCellAdjust}
                  >
                    {camCount}
                  </Cell>
                </>
              )}
              {!isOrgLevel && (
                <>
                  <Cell
                    width="4"
                    className={classNames(
                      styles.headerCellAdjust,
                      styles.siteLevelReferenceCell
                    )}
                  >
                    {countsLoadingEle === null
                      ? groupedAttachedResources
                      : countsLoadingEle}
                  </Cell>
                  <Cell width="1" className={styles.headerCellAdjust}>
                    {isOrgLevelTimetable ? 'Organization' : 'Site'}
                  </Cell>
                </>
              )}
              <Cell width="1" cellAlign="right">
                {renderAutolockOrMenuDropdown(
                  isTimeTableCountsLoading,
                  isMenuLocked,
                  userPlatformAccess,
                  isOrgLevel,
                  timeTable
                )}
              </Cell>
            </>
          ),
          extraContent: [
            <>
              <DefaultRow
                rows={timeTable.rows}
                isOrgLevel={isOrgLevel}
                startOpen={isFirstFocusedTimetable}
              />
              {timeTable.specials.length
                ? timeTable.specials
                    .map(
                      (row, i) =>
                        (row.rows?.length && (
                          <SpecialRow
                            key={`${i} special`}
                            row={row}
                            isOrgLevel={isOrgLevel}
                            startOpen={isFirstFocusedTimetable}
                          />
                        )) ||
                        null
                    )
                    .filter(row => row)
                : null}
              <Cell width="13" className={styles.iconsContainer}>
                <div className={styles.recordInfoTooltip}>
                  <RecordInfoTooltip
                    resourceId={parseInt(String(timeTable.id))}
                    ianaTimeZoneCode={site?.timeZone}
                  />{' '}
                </div>
                <div
                  onClick={() => handleSchedulerButtonClick(timeTable, index)}
                  className={styles.schedulerIcon}
                >
                  <SchedulerIcon size={30} />
                </div>{' '}
              </Cell>
            </>,
            <>
              <Cell width="12" className={styles.timetableContainer}>
                {openedTimetableVisualizations[index] &&
                  openedTimetableVisualizations[index].map(component => {
                    return (
                      <div className={styles.rowDropdownContent}>
                        {component}
                      </div>
                    );
                  })}
              </Cell>
            </>,
          ],
        };
      }
    );

    return showErrorPage ? (
      pageNotFoundError()
    ) : (
      <div className={styles.root}>
        <DeleteTimeTableModal
          organizationUnitId={organizationUnitId}
          isOrgLevel={isOrgLevel}
        />
        <EditTimeTableModal
          organizationUnitId={organizationUnitId}
          propagateConfig={true}
          isOrgLevel={isOrgLevel}
        />

        <FixedContentWrapper>
          <PageContentHeader
            header={checkCommonPlural('Timetable', filteredTimeTables.length)}
            isLoading={getTimeTablesIsLoading}
          >
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredTimeTables.length === 0}
            />
          </PageContentHeader>
          <CardList
            headerClassName={styles.headerGrid}
            groupHeaders={this.getGroupHeaders(isOrgLevel)}
            header={this.getHeader(isOrgLevel)}
            data={data}
            featureCardId={urlTimetableId}
          />
        </FixedContentWrapper>
      </div>
    );
  }
}

export const getTimeTableCount = (
  timeTableCounts: TimeTableCounts[] = [],
  timeTableId: number
) => {
  const attachedResources =
    (timeTableCounts.find(item => item.timetableId === timeTableId) || {})
      .referenceResources || [];

  // special case : EQ OP Hours SOP using org TT operates on all site equipments of a specifi eq type in which the policy is defined
  // within org
  // so the entries when grouped by siteId would be duplicate. Hence the additional filtering with sop id
  const eqOpHourSopReferenceCount =
    (attachedResources || [])
      .filter(
        resource =>
          resource.timetableReferenceType ===
          TimetableReferenceType.EQUIPMENT_OPERATING_HOURS
      )
      .reduce<TimeTableAttachedResource[]>((acc, curr) => {
        if (
          !acc.some(
            item => item.siteId === curr.siteId && item.sopId === curr.sopId
          )
        ) {
          acc.push(curr);
        }
        return acc;
      }, []).length || 0;

  const siteOpHourSopReferenceCount =
    (attachedResources || []).filter(
      resource =>
        resource.timetableReferenceType ===
        TimetableReferenceType.SITE_OPERATING_HOURS
    ).length || 0;

  const sopReferenceCount =
    eqOpHourSopReferenceCount + siteOpHourSopReferenceCount;

  // resource filters thru all control types
  // to match control type vs TT Reference Type
  const controlReferenceCount =
    attachedResources.filter(resource =>
      Object.values(TimetableReferenceControlsType).find(
        type =>
          ((type as unknown) as TimetableReferenceType) ===
          resource.timetableReferenceType
      )
    ).length || 0;

  const uniqueResourceIds = new Set();

  //Filter Light Sensors and Schedulers
  const filteredResources = attachedResources.filter(resource => {
    if (
      isDefined(resource.equipmentId) &&
      (resource.timetableReferenceType ===
        TimetableReferenceType.LIGHT_SENSOR ||
        resource.timetableReferenceType === TimetableReferenceType.SCHEDULER)
    ) {
      // Check if the equipmentId is already in the set
      if (!uniqueResourceIds.has(resource.equipmentId)) {
        uniqueResourceIds.add(resource.equipmentId);
        return true;
      }
    }
    return false;
  });

  const camCount = filteredResources.length;

  const siteReferenceCount = [
    ...new Set(
      attachedResources
        .filter(resource => resource.resourceSiteId)
        .map(resource => resource.resourceSiteId)
    ),
  ].length;

  return {
    attachedResources,
    sopReferenceCount,
    controlReferenceCount,
    siteReferenceCount,
    camCount,
  };
};

const mapStateToProps = (
  { app, timeTables }: ApplicationState,
  { organizationUnitId, isOrgLevel }: OwnProps
) => ({
  currentOrganizationId: app.currentOrganizationId,
  timeTables: (
    (!isOrgLevel &&
      app.currentOrganizationId &&
      timeTables.timeTableIdsByParentId[app.currentOrganizationId]) ||
    []
  )
    .concat(timeTables.timeTableIdsByParentId[organizationUnitId] || [])
    .map(id => timeTables.timeTablesById[id]),
  getTimeTablesIsLoading:
    timeTables.loadingStatusByAction[Actions.GET_TIME_TABLES_LOADING],
  timeTableCounts:
    timeTables.timeTableCountsByOrgUnitId[organizationUnitId] || [],
  isTimeTableCountsLoading:
    timeTables.loadingStatusByAction[Actions.GET_TIME_TABLE_COUNTS_LOADING],
  equipmentTypesById: app.equipmentTypesById || {},
  currentUser: app.currentUser,
});

const mapDispatchToProps = (
  dispatch: any,
  { organizationUnitId, isOrgLevel }: OwnProps
) => ({
  load: (currentOrganizationId?: number) => {
    dispatch(getTimeTables(organizationUnitId));
    dispatch(getTimeTableCountsByOrgUnitId(organizationUnitId));
    !isOrgLevel &&
      currentOrganizationId &&
      dispatch(getTimeTables(currentOrganizationId));
    dispatch(getEquipmentTypes());
  },
  showNewModal: () => dispatch(showNewModal()),
  getTimeTableById: (timeTableId: number | string | null) =>
    dispatch(getTimeTableById(timeTableId)),
  showEditModal: (timeTableId: number | string | null) =>
    dispatch(showEditModal(timeTableId)),
  primeForDelete: timeTableId => dispatch(primeForDelete(timeTableId)),
  showDeleteModal: () => dispatch(showDeleteModal()),
  duplicateTimeTable: (timeTable: TimeTable, isCopyToSite?: boolean) =>
    dispatch(duplicateTimeTable(timeTable, isCopyToSite)),
});
export default withViewportType(
  connect(mapStateToProps, mapDispatchToProps)(TimeTablesTable)
);
