import { Button, Checkbox } from '@energybox/react-ui-library/dist/components';
import { MagnifyIcon } from '@energybox/react-ui-library/dist/icons';
import { Actuator, Equipment } from '@energybox/react-ui-library/dist/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  endInstallerTest,
  startInstallerTest,
} from '../../../actions/control_boards';
import {
  showEndSequenceModal,
  showStartSequenceModal,
} from '../../../actions/run_configuration';
import { ApplicationState } from '../../../reducers';
import EndSequenceModal from './EndSequenceModal';
import styles from './RunConfigurationTest.module.css';
import StartSequenceModal from './StartSequenceModal';

type Props = {
  toggleStartSequence: boolean;
  siteId: string | number;
  actuators: Actuator[];
  equipments: Equipment[];
};
export const StartSequenceForRunTest: React.FC<Props> = ({
  toggleStartSequence,
  siteId,
  actuators,
  equipments,
}) => {
  const dispatch = useDispatch();

  const startSequence = id => {
    dispatch(showStartSequenceModal());
    dispatch(startInstallerTest(id));
  };
  const endSequence = id => {
    dispatch(showEndSequenceModal());
    dispatch(endInstallerTest(id));
  };
  const showStartSequence = useSelector(
    ({ runConfiguration }: ApplicationState) => {
      return runConfiguration.showStartSequenceModal;
    }
  );
  const showEndSequence = useSelector(
    ({ runConfiguration }: ApplicationState) => {
      return runConfiguration.showEndSequenceModal;
    }
  );

  return (
    <>
      <div className={styles.startSequence}>
        <div>
          <span className={styles.number}>1</span>
          <Button
            className={styles.startSequenceButton}
            onClick={() => startSequence(siteId)}
            disabled={toggleStartSequence ? true : false}
          >
            <MagnifyIcon width="25" height="25" fill={'white'} />
            <span className={styles.buttonText}>
              Prepare Equipments for Testing
            </span>
          </Button>
        </div>
        <div className={styles.line}></div>
        <div>
          <span className={styles.number}>2</span>
          <span className={styles.text}> Run Equipments Tests </span>
        </div>
        <div className={styles.line}></div>
        <div>
          <span className={styles.number}>3</span>
          <Button
            className={styles.startSequenceButton}
            disabled={toggleStartSequence ? false : true}
            onClick={() => endSequence(siteId)}
          >
            <Checkbox
              onChange={undefined}
              checked={true}
              variant={'outlined'}
              size={25}
              className={styles.iconColor}
            />
            <span className={styles.buttonText}>
              Complete Equipment Testing
            </span>
          </Button>
        </div>
      </div>
      {showStartSequence && (
        <StartSequenceModal
          siteId={siteId}
          equipments={equipments}
          actuators={actuators}
        />
      )}
      {showEndSequence && <EndSequenceModal />}
    </>
  );
};
