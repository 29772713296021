import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { openModal } from '../../actions/modal';
import ShowDetailPageHeader from '../../components/ShowDetailPageHeader';
import history from '../../history';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { StepType } from '../../types/reminderWizard';
import ReminderWizard from '../ReminderWizard';
import { Reminder, deleteReminder } from '../../actions/reminder';

type Props = {
  reminder?: Reminder;
  deleteReminder: typeof deleteReminder;
  openModal: typeof openModal;
};

type OwnProps = {
  id: number | string;
  step?: StepType;
};

function deleteReminderModal(
  openModalFn: typeof openModal,
  actionFn: typeof deleteReminder,
  reminder: Reminder,
  targetTitle: string
) {
  openModalFn({
    title: 'Delete configuration',
    description: `Do you really want to delete the configuration ${targetTitle}?`,
    actionFn: () => {
      actionFn(reminder);
      history.push(Routes.REMINDER);
    },
  });
}

function ShowReminderPage(props: Props & OwnProps) {
  const { reminder, id, step, openModal, deleteReminder } = props;

  return (
    <>
      <ShowDetailPageHeader
        name={reminder ? reminder.title : ''}
        description={reminder ? reminder.description : ''}
        resourceName="Reminder"
        pathBack={Routes.REMINDER}
        hideDelete={id === 'new'}
        onDelete={() =>
          reminder &&
          deleteReminderModal(
            openModal,
            deleteReminder,
            reminder,
            reminder.title
          )
        }
      />

      <div style={{ padding: '3rem' }}>
        <ReminderWizard id={String(id)} step={step} />
      </div>
    </>
  );
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
  reminder: state.reminder.reminders.filter(
    i => i.id === Number(ownProps.id)
  )[0],
});

const mapDispatchToProps = {
  deleteReminder,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowReminderPage);
