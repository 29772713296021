import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import React, { ReactElement } from 'react';

type ConfirmationModalProps = {
  onSuccess: () => void;
  onCancel: () => void;
  title: string;
  description: string | ReactElement;
};

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { onSuccess, onCancel, title, description } = props;

  const actions = (
    <>
      <Button variant="text" onClick={onCancel}>
        Cancel
      </Button>

      <Button onClick={onSuccess}>OK</Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalTitle>{title}</ModalTitle>
      <ModalContent>{description}</ModalContent>
    </Modal>
  );
}

type TTConfirmationModalProps = {
  onSuccess: () => void;
  onCancel: () => void;
  controlCount?: number;
  sopCount?: number;
  siteCount?: number;
  isOrgLevel: boolean | undefined;
  camCount?: number;
};

const equipmentLabel = count =>
  isDefined(count) && count === 1 ? 'Equipment Control' : 'Equipment Controls';

export function TimetableConfirmationModal(props: TTConfirmationModalProps) {
  const {
    onSuccess,
    onCancel,
    siteCount = 0,
    sopCount = 0,
    isOrgLevel,
    camCount = 0,
  } = props;

  const extraDescription = (
    <>
      {' '}
      across{' '}
      <b>
        {siteCount} {siteCount > 1 ? 'Sites' : 'Site'}
      </b>
      .
    </>
  );

  const description = (
    <>
      The Timetable update will be implemented to{' '}
      <b>
        {camCount} {equipmentLabel(camCount)}
      </b>{' '}
      and <b>{sopCount} SOP</b> configurations
      {isOrgLevel ? extraDescription : '.'}
    </>
  );
  return (
    <ConfirmationModal
      title="Timetable Confirmation Message"
      description={description}
      onSuccess={onSuccess}
      onCancel={onCancel}
    />
  );
}
