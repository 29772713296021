import { Equipment } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetHvacControlByEquipmentId } from '../../../hooks/useHvacControl';
import { getThermostatsBySiteId } from '../../../actions/thermostats';
import ThermostatMappingCard from './ThermostatMappingCard';
import HvacControlsInfoCard from './HvacControlsInfoCard';
import styles from './HvacSopControlsCards.module.css';

type Props = {
  siteId: number;
  equipment: Equipment;
  controlBoardIdViaActuator: number | undefined;
};

const HvacSopControlsCards: React.FC<Props> = ({
  siteId,
  equipment,
  controlBoardIdViaActuator,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getThermostatsBySiteId(siteId));
  }, [dispatch, siteId]);

  const hvacControl = useGetHvacControlByEquipmentId(equipment.id);
  const isThereHvacControl = isDefined(hvacControl);
  const isNew = !isThereHvacControl;
  return (
    <>
      <div className={styles.cardTopMargin}>
        <ThermostatMappingCard
          siteId={siteId}
          equipment={equipment}
          hvacControl={hvacControl}
          isNew={isNew}
        />
      </div>
      <div className={styles.cardTopMargin}>
        <HvacControlsInfoCard
          siteId={siteId}
          equipment={equipment}
          hvacControl={hvacControl}
          isNew={isNew}
          controlBoardIdViaActuator={controlBoardIdViaActuator}
        />
      </div>
    </>
  );
};

export default HvacSopControlsCards;
