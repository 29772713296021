import { WizardCard } from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  Hub,
  ThermostatIcon,
} from '@energybox/react-ui-library/dist/icons';
import {
  DeviceType,
  DeviceTypeDisplayText,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import styles from './SelectDeviceForm.module.css';

type Props = {
  onChange: (e: DeviceType) => void;
};

const SelectDeviceForm = ({ onChange }: Props) => (
  <div className={styles.root}>
    <WizardCard
      title={'Hub'}
      onClick={() => onChange(DeviceType.ENERGYBOX_HUB)}
      icon={<Hub size="80" />}
    />
    <WizardCard
      title={DeviceTypeDisplayText.ENERGYBOX_CB}
      onClick={() => onChange(DeviceType.ENERGYBOX_CB)}
      icon={<ControlBoard size="80" />}
    />
    <WizardCard
      title={DeviceTypeDisplayText.THERMOSTAT}
      onClick={() => onChange(DeviceType.THERMOSTAT)}
      icon={<ThermostatIcon size="80" />}
    />
    {/*<WizardCard*/}
    {/*  title="Modbus TCP Hub"*/}
    {/*  onClick={() => onChange(DeviceType.modBus)}*/}
    {/*  active={deviceType === DeviceType.modBus}*/}
    {/*  icon="ICON"*/}
    {/*/>*/}
  </div>
);

export default SelectDeviceForm;
