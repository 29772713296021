import {
  NewPasswordErrorPage,
  NewPasswordPage,
} from '@energybox/react-ui-library/dist/components/NewPassword';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

import {
  Actions as AppActions,
  resetPassword,
  updateResetPasswordField,
} from '../../actions/app';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import { fetchApi } from '../../utils/apiUtils';
import { SemiLoginLayout } from '@energybox/react-ui-library/dist/components';

interface Props extends RouteComponentProps {
  email: string;
  password: string;
  passwordVerify: string;
  apiError: ApiError;
  resetPassword: typeof resetPassword;
  onChange: (field: string, value: string) => void;
}

interface State {
  token: string;
  // Only /user-invite-email comes with invitedBy in the query string
  // Otherwise, it is /new-password
  invitedBy?: string;
  error?: string;
}

class NewPasswordPageContainer extends React.Component<Props, State> {
  validateForm = () => {
    const { password, passwordVerify } = this.props;
    return password !== '' && passwordVerify !== '';
  };

  componentDidMount(): void {
    const { token, invitedBy } = queryString.parse(location.search);
    let newState = { token };
    if (invitedBy) newState['invitedBy'] = invitedBy.replace(' ', '+');
    // queryString will parse '+' as ' ' per url standard
    this.setState(newState);
    this.setUserEmailFromToken(token);
  }

  async setUserEmailFromToken(token) {
    try {
      const email: string = await fetchApi({
        endpoint: `/api/v1/users/invite-user-token-valid?token=${token}`,
      });
      this.props.onChange('email', email);
    } catch (e) {
      const msg =
        e instanceof Error && e.message === 'Unauthorized'
          ? 'Your registration link has expired.'
          : 'Unexpected ' + e;
      this.setState({ error: msg });
      if (!this.state.invitedBy) this.props.onChange('email', 'Token expired');
    }
  }

  handleSubmit = (e: any) => {
    const { resetPassword, email, password, passwordVerify } = this.props;
    const token = this.state.token;

    e.preventDefault();
    resetPassword(email, token, password, passwordVerify);
  };

  render() {
    const { email, password, passwordVerify, onChange, apiError } = this.props;

    const { error, invitedBy } = this.state || {};

    if ((error || apiError['message']) && invitedBy)
      return (
        <SemiLoginLayout>
          <NewPasswordErrorPage
            message={error || apiError['message'] || 'Unexpected Error'}
            replyTo={invitedBy}
          />
        </SemiLoginLayout>
      );

    return (
      <SemiLoginLayout>
        <NewPasswordPage
          email={email}
          password={password}
          passwordVerify={passwordVerify}
          apiError={renderAPIerror(apiError, AppActions.RESET_PASSWORD_ERROR)}
          handleSubmit={this.handleSubmit}
          handleChange={onChange}
        />
      </SemiLoginLayout>
    );
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  email: app.resetPasswordForm.email,
  password: app.resetPasswordForm.password,
  passwordVerify: app.resetPasswordForm.passwordVerify,
  apiError: app.apiError,
});

const mapDispatchToProps = dispatch => {
  return {
    onChange: (field: string, value: string) =>
      dispatch(updateResetPasswordField(field, value)),
    resetPassword: (
      email: string,
      token: string,
      password: string,
      passwordVerify: string
    ) => dispatch(resetPassword(email, token, password, passwordVerify)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasswordPageContainer);
