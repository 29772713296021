import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../reducers';
import { canAccessFeatures } from '../utils/featureFlag';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';

type OwnProps = {
  children: React.ReactElement;
  orgType?: OrganizationType;
  orgWhiteList?: number[];
  legacy?: boolean;
};

interface Props extends OwnProps {
  isVisible: boolean;
}

const FeatureFlag = ({ children, isVisible, legacy }: Props) =>
  isVisible === !legacy ? children : null;

const mapStateToProps = (
  { app }: ApplicationState,
  { orgWhiteList, orgType }: OwnProps
) => ({
  isVisible: canAccessFeatures(app, orgWhiteList, orgType),
});

export default connect(mapStateToProps)(FeatureFlag);
