import {
  AccessResource,
  Organization,
  Site,
  SiteGroup,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { connect } from 'react-redux';
import { getOrganizations } from '../actions/organizations';
import { getSites, updateQuery } from '../actions/sites';
import {
  getSiteGroups,
  updateQuery as updateSiteGroupQuery,
} from '../actions/site_groups';
import {
  addScope,
  deleteScope,
  getGroup,
  updateScope,
} from '../actions/user_groups';
import ResourceAccess from '../components/ResourceAccess';
import { ApplicationState } from '../reducers';
import { siteList, Sites } from '../reducers/sites';
import { siteGroupsList } from '../reducers/site_groups';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  updateQuery: (query: string) => void;
  addScope: (resourceId: string, scope: any) => {};
  updateScope: (userId: string, scope: any) => {};
  deleteScope: (userId: string, scopeId: string) => void;
  sites: Site[];
  organizations?: Organization[];
  siteGroups: SiteGroup[];
  scopes: AccessResource[];
  query: Sites['query'];
}

interface State {
  searchOpen: boolean;
}

class GroupSiteAccess extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchOpen: false,
    };
  }

  componentDidMount() {
    this.props.load();
  }

  onNewResourceSelect = (resourceId: string[]) => {
    this.setState({ searchOpen: false });
    this.props.addScope(this.props.id, { resourceId, role: 'VIEWER' });
  };

  onResourceAccessChange = (scopeId, updatedPermission: string) => {
    const scope = {
      resourceId: scopeId,
      role: updatedPermission,
    };
    this.props.updateScope(this.props.id, scope);
  };

  render() {
    const { searchOpen = false } = this.state;
    const {
      id,
      query = '',
      organizations = [],
      sites = [],
      siteGroups = [],
      scopes = [],
      updateQuery = () => {},
    } = this.props;
    const headerTitle = 'Site Access',
      description =
        'The users of this group will have access to the following sites and groups of sites with defined roles.';
    return (
      <ResourceAccess
        headerTitle={headerTitle}
        description={description}
        searchOpen={searchOpen}
        query={query}
        orgs={organizations}
        sites={sites}
        siteGroups={siteGroups}
        scopes={scopes}
        onResourceAccessDelete={(resourceId: string) => {
          this.props.deleteScope(id, resourceId);
        }}
        onQueryChange={updateQuery}
        toggleSearch={() => {
          this.setState({ searchOpen: !this.state.searchOpen });
        }}
        onNewResourceSelect={this.onNewResourceSelect}
        onExistingResourceSelect={() => {}}
        onResourceAccessChange={this.onResourceAccessChange}
      />
    );
  }
}

const mapStateToProps = (
  { userGroups, sites, siteGroups, organizations }: ApplicationState,
  { id }: OwnProps
) => ({
  scopes: userGroups.userGroupsById[parseInt(id)].accessResources,
  sites: siteList(sites),
  siteGroups: siteGroupsList(siteGroups),
  organizations: organizations.currentOrganization
    ? [organizations.currentOrganization]
    : [],
  query: sites.query,
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => {
    dispatch(getGroup(id));
    dispatch(getSites());
    dispatch(getSiteGroups());
    dispatch(getOrganizations());
  },
  deleteScope: (userId, scopeId) => dispatch(deleteScope(userId, scopeId)),
  addScope: (groupId, scope) => dispatch(addScope(groupId, scope)),
  updateScope: (groupId, scope) => dispatch(updateScope(groupId, scope)),
  updateQuery: (query = '') => {
    dispatch(updateSiteGroupQuery(query));
    dispatch(updateQuery(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSiteAccess);
