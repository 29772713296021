import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEdmConfigBySiteId } from '../actions/edm';
import { ApplicationState } from '../reducers';
import { EdmScheduleByEquipmentId } from '../reducers/edm';
import { ApiError } from '../utils/apiErrorFeedback';

export const useEdmSchedules = () => {
  const dispatch = useDispatch();

  const getEdmSchedulesBySiteId = (siteId: number | string | undefined) =>
    dispatch(getEdmConfigBySiteId(siteId));

  const selected =
    useSelector<
      ApplicationState,
      {
        scheduleByEquipmentId: EdmScheduleByEquipmentId;
        isLoading: boolean;
        apiError: ApiError;
      }
    >(({ edmConfigs }) => ({
      scheduleByEquipmentId: edmConfigs.scheduleByEquipmentId,
      isLoading: edmConfigs.scheduleIsLoading,
      apiError: edmConfigs.apiError,
    })) || {};

  useEffect(() => {});

  return {
    ...selected,
    getEdmSchedulesBySiteId,
  };
};
