import {
  DistributionPanel,
  EnergyPro,
  EnergyDeviceFromApi,
  ObjectById,
  EnergyDevicePorts,
  CircuitBreakerFromApiResponse,
  SiteEnergyCalculationMode,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  classNames,
  KW_UNIT,
  global,
} from '@energybox/react-ui-library/dist/utils';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  showNewEnergyDeviceSensorModal,
  updateEnergyDeviceSensorField,
} from '../../../actions/energy_devices';
import { SelectEnergyDeviceCt } from '../../../components/EditEnergyDeviceSensorForm/EditEnergyDeviceSensorForm';
import { ApplicationState } from '../../../reducers';
import NewEquipmentModal from '../../Equipment/NewEquipmentModal';
import SelectCtPolarity from '../../Selects/SelectCtPolarity';
import SelectEquipment from '../../Selects/SelectEquipment';
import SelectPhase from '../../Selects/SelectPhase';
import BreakerDropdown from './BreakerDropdown';
import {
  DPTableState,
  ActionPayLoad,
  useEnergyProStreamDataBySensorId,
  UpdateEntity,
  UpdateEnergyDeviceAction,
  getPowerFactor,
  getCurrent,
  acceptedPatchBreakerFields,
  getActivePower,
  getVoltage,
} from './DPSetUpTable';
import rPick from 'ramda/src/pick';
import { patch as patchCircuitBreaker } from '../../../actions/circuit_breakers';
import styles from './DPSetUpTable.module.css';
import { Checkbox } from '@energybox/react-ui-library/dist/components';
import {
  IoIosAddCircleOutline,
  IoIosArrowDown,
  IoIosArrowUp,
} from 'react-icons/io';
import { useGetSite } from '../../../hooks/useSites';
import { isSiteTotalChecked } from '../../../utils/energyPro';

type SensorBusTreeProps = {
  busNumber: number;
  title: string;
  distributionPanel: DistributionPanel;
  siteId: number;
  configByDeviceId: DPTableState;
  energyPro: EnergyPro;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
  nodes: EnergyDeviceFromApi[];
  deviceById: ObjectById<EnergyDeviceFromApi>;
  highlightFromFirstRow?: boolean;
  localDispatch: React.Dispatch<ActionPayLoad>;
  setNewSensorDevice: (device: EnergyDeviceFromApi) => void;
  showActiveOnly?: boolean;
};

const SensorBusTree = ({
  busNumber,
  title,
  distributionPanel,
  siteId,
  configByDeviceId,
  energyPro,
  setUnconfirmedUpdateAction,
  deviceById,
  nodes,
  highlightFromFirstRow,
  localDispatch,
  setNewSensorDevice,
  showActiveOnly,
}: SensorBusTreeProps) => {
  const reduxDispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [newEquipmentPendingAction, setNewEquipmentPendingAction] = useState<
    ActionPayLoad | undefined
  >(undefined);

  const { energyCalculationMode } = useGetSite(siteId) || {};
  const circuitbreakerEditById = useSelector(
    ({ circuitBreakers }: ApplicationState) => {
      return circuitBreakers.editById;
    }
  );

  useEffect(() => {
    if (!newEquipmentPendingAction) return;
    const { id: energyDeviceId, port } = newEquipmentPendingAction;
    if (!port) return;
    const portData = configByDeviceId[energyDeviceId][port];
    const { breakerId } = portData;
    const equipmentIdFromRedux: number | undefined =
      circuitbreakerEditById[breakerId]?.fields?.equipmentId;
    if (equipmentIdFromRedux === undefined) return;
    if (
      equipmentIdFromRedux !==
      configByDeviceId.breakersById[breakerId]?.equipmentId
    ) {
      localDispatch({
        ...newEquipmentPendingAction,
        value: equipmentIdFromRedux,
      });
      const payload = {
        ...rPick(acceptedPatchBreakerFields, portData),
        id: breakerId,
        equipmentId: equipmentIdFromRedux,
      };
      reduxDispatch(
        patchCircuitBreaker(distributionPanel.id, breakerId, payload)
      );
      setNewEquipmentPendingAction(undefined);
    }
  }, [
    configByDeviceId,
    circuitbreakerEditById,
    newEquipmentPendingAction,
    distributionPanel,
    localDispatch,
  ]);

  const { breakers } = distributionPanel;
  const energySensorsReadingById = useEnergyProStreamDataBySensorId(
    energyPro.id
  );

  if (nodes.length === 0) return null;

  const { breakersById } = configByDeviceId;

  const headerStyle = classNames(styles.tableHeader, styles.bottomSeparator);
  const headerStyleWithPadding = classNames(
    styles.tableHeader,
    styles.dropdownHeaderPadding,
    styles.bottomSeparator
  );

  return (
    <div className={styles.busDeviceRoot}>
      <div
        className={styles.busDeviceHeader}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <IoIosArrowUp color="var(--accent-base)" size={'1.5em'} />
        ) : (
          <IoIosArrowDown color="var(--accent-base)" size={'1.5em'} />
        )}
        <span>{title}</span>
      </div>

      <div
        className={classNames(
          styles.busDeviceContainer,
          !expanded && styles.hidden
        )}
      >
        <div className={classNames(styles.sensorBusTable)}>
          <div>&nbsp;</div>
          <div className={headerStyleWithPadding}>Index</div>
          <div className={headerStyle}>Equipment</div>
          <div className={headerStyle}>Site Total</div>
          <div className={headerStyleWithPadding}>Breakers Name</div>
          <div className={headerStyleWithPadding}>CT Type</div>
          <div className={headerStyleWithPadding}>Phase</div>
          <div className={headerStyleWithPadding}>CT Polarity</div>
          <div className={headerStyle}>
            <div className={styles.sensorReading}>Current (A)</div>
          </div>
          <div className={headerStyle}>
            <div className={styles.sensorReading}>Voltage (V)</div>
          </div>
          <div className={headerStyle}>
            <div className={styles.sensorReading}>Power Factor</div>
          </div>
          <div className={headerStyle}>
            <div className={styles.sensorReading}>Active Power ({KW_UNIT})</div>
          </div>
        </div>
        {nodes.map((node, deviceIndex, devicesList) => {
          const device = deviceById[node.id];
          const portsData = mapArrayToObject(
            Object.values(configByDeviceId[node.id] || {}),
            'port'
          );
          const portRows = new Array(EnergyDevicePorts[device._entity]).fill(0);
          return (
            <>
              <div
                className={styles.sensorBusTable}
                key={`busDevice${node.id}`}
              >
                {deviceIndex != 0 && (
                  <>
                    <div></div>
                    <div className={styles.busDeviceSeparator}></div>
                  </>
                )}
                <div
                  className={classNames(styles.busDeviceTitle)}
                  style={{ gridRow: 'span ' + portRows.length }}
                >
                  <div className={styles.deviceSeparator}>&nbsp;</div>
                  <div>{title}</div>
                </div>
                {portRows.map((_, row) => {
                  const data = portsData[row + 1];
                  if (data === undefined) {
                    return (
                      <React.Fragment key={`bus${node.id}DevicePort${row + 1}`}>
                        <div
                          className={row % 2 === 0 ? styles.white : styles.gray}
                        >
                          <div
                            className={classNames(
                              styles.centered,
                              styles.tableCellPadding
                            )}
                          >
                            Port {row + 1}
                            <IoIosAddCircleOutline
                              size={18}
                              color="var(--accent-base)"
                              onClick={() => {
                                reduxDispatch(
                                  showNewEnergyDeviceSensorModal(device.id)
                                );
                                setNewSensorDevice(device);
                                reduxDispatch(
                                  updateEnergyDeviceSensorField(
                                    'new',
                                    'energyDevicePort',
                                    row + 1,
                                    device.id
                                  )
                                );
                                reduxDispatch(
                                  updateEnergyDeviceSensorField(
                                    'new',
                                    'breakerId',
                                    null,
                                    device.id
                                  )
                                );
                              }}
                              cursor={'pointer'}
                            />
                          </div>
                        </div>
                        {new Array(10).fill(0).map((_, index) => (
                          <div
                            key={index}
                            className={classNames(
                              row % 2 === 0 ? styles.white : styles.gray,
                              index > 0 && index < 5 && styles.tableCellPadding
                            )}
                          >
                            {index >= 6 ? (
                              <div className={styles.sensorReading}>
                                {global.NOT_AVAILABLE}
                              </div>
                            ) : (
                              global.NOT_AVAILABLE
                            )}
                          </div>
                        ))}
                      </React.Fragment>
                    );
                  }

                  if (data === undefined) return null;
                  const {
                    port,
                    energyDeviceId,
                    energySensorId,
                    ct,
                    reversePolarity,
                    phase,
                    breakerId,
                    isMainBreaker,
                    disabled,
                  } = data || {};

                  const breaker: CircuitBreakerFromApiResponse | undefined =
                    breakersById[breakerId];

                  if (!device) return null;
                  if (showActiveOnly && disabled && !breaker?.equipmentId)
                    return null;

                  const siteTotalChecked =
                    energyCalculationMode &&
                    isSiteTotalChecked(
                      distributionPanel.mdp,
                      isMainBreaker,
                      energyCalculationMode,
                      breaker.siteTotal
                    );
                  const isCheckboxDisabled =
                    breaker === undefined ||
                    energyCalculationMode !==
                      SiteEnergyCalculationMode.FLAGGED_BREAKERS;

                  return (
                    <React.Fragment key={`bus${node.id}DevicePort${port}`}>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div
                          className={classNames(
                            styles.centered,
                            styles.tableCellPadding
                          )}
                        >
                          Port {port}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        {newEquipmentPendingAction?.port === port &&
                          newEquipmentPendingAction?.id === energyDeviceId && (
                            <NewEquipmentModal
                              lockSiteId={siteId}
                              breakerIdToUpdate={breakerId || 'new'}
                            />
                          )}
                        <SelectEquipment
                          onCreateEquipmentModalOpen={() => {
                            setNewEquipmentPendingAction({
                              type:
                                UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID,
                              deviceTitle: device.title,
                              id: energyDeviceId,
                              port,
                              entity: UpdateEntity.BREAKER,
                            });
                          }}
                          noBottomLine
                          disabled={!breakerId || isMainBreaker}
                          onSelect={updated =>
                            setUnconfirmedUpdateAction({
                              type:
                                UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID,
                              deviceTitle: device.title,
                              id: energyDeviceId,
                              port,
                              value: updated,
                              entity: UpdateEntity.BREAKER,
                            })
                          }
                          value={breaker?.equipmentId}
                          siteId={siteId}
                        />
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          <Checkbox
                            checked={siteTotalChecked}
                            disabled={isCheckboxDisabled}
                            className={styles.delete}
                            size={18}
                            onChange={() =>
                              setUnconfirmedUpdateAction({
                                type: UpdateEnergyDeviceAction.SITE_TOTAL,
                                deviceTitle: device.title,
                                id: energyDeviceId,
                                breakerId: breaker.id,
                                port,
                                value: !breaker.siteTotal,
                                entity: UpdateEntity.BREAKER,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <BreakerDropdown
                          disabled
                          selectedBreaker={data.breaker}
                          selectedBreakerPole={data.breakerPole}
                          energyDeviceId={data.energyDeviceId}
                          port={data.port}
                          deviceTitle={device.title}
                          breakers={breakers}
                          setUnconfirmedUpdateAction={
                            setUnconfirmedUpdateAction
                          }
                        />
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          <SelectEnergyDeviceCt
                            noBottomLine
                            deviceType={device._entity}
                            value={ct}
                            onSelect={updated =>
                              setUnconfirmedUpdateAction({
                                type: UpdateEnergyDeviceAction.CT,
                                deviceTitle: device.title,
                                id: energyDeviceId,
                                port,
                                value: updated,
                                entity: UpdateEntity.ENERGY_DEVICE,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          <SelectPhase
                            noBottomLine
                            value={phase}
                            onSelect={updated =>
                              setUnconfirmedUpdateAction({
                                type: UpdateEnergyDeviceAction.PHASE,
                                deviceTitle: device.title,
                                id: energyDeviceId,
                                port,
                                value: updated,
                                entity: UpdateEntity.ENERGY_DEVICE,
                              })
                            }
                            panelType={distributionPanel.type}
                            error={false}
                          />
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div>
                          <SelectCtPolarity
                            noBottomLine
                            value={reversePolarity}
                            onSelect={updated =>
                              setUnconfirmedUpdateAction({
                                type: UpdateEnergyDeviceAction.REVERSE_POLARITY,
                                deviceTitle: device.title,
                                id: energyDeviceId,
                                port,
                                value: updated,
                                entity: UpdateEntity.ENERGY_DEVICE,
                              })
                            }
                            error={false}
                          />
                        </div>
                      </div>

                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div className={styles.sensorReading}>
                          {getCurrent(energySensorId, energySensorsReadingById)}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div className={styles.sensorReading}>
                          {getVoltage(energySensorId, energySensorsReadingById)}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div className={styles.sensorReading}>
                          {getPowerFactor(
                            energySensorId,
                            energySensorsReadingById
                          )}
                        </div>
                      </div>
                      <div
                        className={row % 2 === 0 ? styles.white : styles.gray}
                      >
                        <div className={styles.sensorReading}>
                          {getActivePower(
                            energySensorId,
                            energySensorsReadingById
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SensorBusTree;
