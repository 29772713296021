import {
  ComponentTypes,
  EnergyTariff,
  LaborRate,
  MaintenanceVisitRate,
  ProductValue,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';

import React from 'react';
import { useCurrentUser } from '../../hooks/useAppDetails';
import useGetTimezones from '../../hooks/useGetTimezones';
import { useSiteTimezone } from '../../hooks/useSites';

interface Props {
  component: ComponentTypes;
  siteId?: number;
}

const SopUnit: React.FC<Props> = ({ siteId, component }) => {
  const currentUser = useCurrentUser();
  const siteTimezone = useSiteTimezone(siteId);
  const timezones = useGetTimezones();

  const { type } = component;
  let value;

  if (!currentUser) return null;
  switch (type) {
    case SopTypes.PRODUCT_VALUE:
      let productValue = component as ProductValue;
      value = `${productValue.currencyCode}`;
      break;
    case SopTypes.MAINTENANCE_VISIT_RATE:
      let maintenanceVisitRate = component as MaintenanceVisitRate;
      value = `${maintenanceVisitRate.currencyCode}`;
      break;
    case SopTypes.LABOR_RATE:
      let laborRate = component as LaborRate;
      value = `${laborRate.currencyCode}/hr`;
      break;
    case SopTypes.ENERGY_TARIFF:
      value = `${(component as EnergyTariff).currencyCode}/kWh`;
      break;
    case SopTypes.DOOR_OPENED_MAX_DURATION:
      value = `mins`;
      break;
    case SopTypes.HUMIDITY_RANGE:
      value = `%`;
      break;
    case SopTypes.NORMAL_POWER_CONSUMPTION:
      value = `kW`;
      break;
    case SopTypes.TEMPERATURE_RANGE:
      value = getUserPreferenceTemperatureUnit(currentUser);
      break;
    case SopTypes.SITE_OPERATING_HOURS:
    case SopTypes.EQUIPMENT_OPERATING_HOURS: {
      const targetTz =
        timezones &&
        timezones.find(tz => siteTimezone && tz.title === siteTimezone);
      value = (targetTz && 'UTC ' + targetTz.offset) || global.NOT_AVAILABLE;
      break;
    }
    default: {
      return <>{global.NOT_AVAILABLE}</>;
    }
  }

  return <span>{value}</span>;
};

export default SopUnit;
