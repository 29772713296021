import {
  Site,
  SensorTarget,
  SensorType,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect, useState } from 'react';
import { fetchSites } from '../../actions/sitesFetch';

function SiteSelector(props: {
  selectedOptions: number[];
  onChange: (site: Site) => void;
  onSelectAll: (sites: Site[]) => void;
  sensorTypes: SensorType[];
  sensorTargetTypes: SensorTarget[];
  fetchAll?: boolean;
}) {
  const {
    selectedOptions,
    onChange,
    onSelectAll,
    sensorTypes,
    sensorTargetTypes,
    fetchAll,
  } = props;

  const [sites, setSites] = useState<Site[]>([]);

  const siteAccumulator = (acc: object, next: Site) => {
    acc[next.id.toString()] = next;
    return acc;
  };

  const siteCompare = (a: Site, b: Site) =>
    a.title.localeCompare(b.title, ['kn']);

  useEffect(() => {
    if (fetchAll && !sites.length) {
      fetchSites()
        .then(sites => {
          const s = sites.reduce(siteAccumulator, {});
          setSites((Object.values(s) as Site[]).sort(siteCompare));
        })
        .catch(err => console.log(err));
    } else {
      if (!sites.length) {
        Promise.all(
          sensorTargetTypes.map(sensorTarget =>
            fetchSites({ sensorTypes, sensorTarget })
          )
        )
          .then(sites => {
            const s = ([] as Site[])
              .concat(...sites)
              .reduce(siteAccumulator, {});
            setSites((Object.values(s) as Site[]).sort(siteCompare));
          })
          .catch(err => console.log(err));
      }
    }
  }, [sensorTargetTypes, sensorTypes, fetchAll, sites.length]);

  let filteredOptions = sites;

  const [value, setValue] = useState('');

  if (value !== '') {
    filteredOptions = filteredOptions.filter(s =>
      s.title.toLowerCase().includes(value.toLowerCase())
    );
  }

  if (selectedOptions.length > 0) {
    filteredOptions = filteredOptions.filter(
      s => !selectedOptions.includes(s.id)
    );
  }

  return (
    <React.Fragment>
      <Select variant="add" title="Add site" onClick={() => setValue('')}>
        <SelectSearch
          onChange={ev => setValue(ev.currentTarget.value)}
          value={value}
        />

        {filteredOptions.map((site: Site) => (
          <SelectItem
            key={site.id}
            onSelect={() => onChange(site)}
            noRadioButton
          >
            {site.title}
          </SelectItem>
        ))}
      </Select>
      <Button size="small" onClick={() => onSelectAll(sites)}>
        Add all sites
      </Button>
    </React.Fragment>
  );
}

export default SiteSelector;
