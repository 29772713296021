import {
  EditableReportPeriod,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Checkbox,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { Trash as TrashIcon } from '@energybox/react-ui-library/dist/icons';

import React, { useState } from 'react';
import { IoIosAddCircle } from 'react-icons/io';
import { newReportFieldPeriod } from '../../../reducers/reports';
import ModalFormContent from '../../ModalFormContent';
import ReportContentSection from '../ReportContentSection';
import styles from './SetupTemperatureChecks.module.css';
import TimeTableTimeInput from '../../EditTimeTableForm/TimeTableTimeInput';

type Props = {
  title: string;
  description: string;
  periods: EditableReportPeriod[];
  useAverageOfReadings: boolean;
  updateReportField: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
};

const SetupTemperatureChecks: React.FC<Props> = ({
  title,
  description,
  periods,
  useAverageOfReadings,
  updateReportField,
  formErrors,
  formErrorsVisible,
}) => {
  const [tooManyPeriods, setTooManyPeriods] = useState(false);

  const renderAddTimeRangeButton = () => {
    return (
      <Button
        className={styles.button}
        type="button"
        variant="outlined"
        onClick={onAddTimeRangeButtonClick}
      >
        <IoIosAddCircle size="16" />
        Add New Range
      </Button>
    );
  };

  const onAddTimeRangeButtonClick = () => {
    const updatedPeriods = [...periods];

    if (updatedPeriods.length === 4) {
      setTooManyPeriods(true);
    } else {
      updatedPeriods.push({ ...newReportFieldPeriod });
      updateReportField('periods', updatedPeriods);
    }
  };

  const onPeriodInputChange = (
    fieldName: string,
    value: string,
    idx: number
  ) => {
    const updatedPeriods = [...periods];
    const periodToUpdate = updatedPeriods[idx];

    periodToUpdate[fieldName] = value;
    updatedPeriods[idx] = periodToUpdate;

    updateReportField('periods', updatedPeriods);
  };

  const onRemovePeriod = (idx: number) => {
    const updatedPeriods = [...periods];
    updatedPeriods.splice(idx, 1);

    updateReportField('periods', updatedPeriods);
  };

  return (
    <div className={styles.root}>
      <ReportContentSection title="General Information">
        <ModalFormContent>
          <div>
            <Label required>Report name</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="title"
              autoComplete="off"
              value={title}
              onChange={e => updateReportField('title', e.currentTarget.value)}
              error={formErrorsVisible && !!formErrors.title}
              customErrorText={formErrors.title && formErrors.title[0]}
            />
          </div>

          <div>
            <Label>Description</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="description"
              autoComplete="off"
              value={description}
              onChange={e =>
                updateReportField('description', e.currentTarget.value)
              }
            />
          </div>

          <div>
            <Label required>Average readings for each time range?</Label>
          </div>
          <div className={styles.checkBoxContainer}>
            <Checkbox
              checked={useAverageOfReadings}
              onChange={() => {
                updateReportField(
                  'useAverageOfReadings',
                  !useAverageOfReadings
                );
              }}
            />
          </div>
        </ModalFormContent>
      </ReportContentSection>

      <ReportContentSection
        title="Add Time Range For Each Temperature Check"
        action={renderAddTimeRangeButton()}
      >
        {periods.map((p: EditableReportPeriod, idx: number) => (
          <div
            key={`period-${idx + 1}`}
            className={styles.reportPeriodContainer}
          >
            <div className={styles.paddingRight}>Check {idx + 1}.</div>
            <div>
              <TimeTableTimeInput
                id={`from`}
                name={'from'}
                value={p.from}
                type={'CLOCK'}
                onChange={(fieldName, value) =>
                  onPeriodInputChange(fieldName, value, idx)
                }
                error={
                  formErrorsVisible &&
                  !!formErrors.periods &&
                  !!formErrors.periods[0][idx]
                }
                customErrorText={'Enter valid time ranges'}
              />
            </div>
            <div className={styles.colonSeparator}> : </div>
            <div>
              <TimeTableTimeInput
                id={`to`}
                name={'to'}
                value={p.to}
                type={'CLOCK'}
                onChange={(fieldName, value) =>
                  onPeriodInputChange(fieldName, value, idx)
                }
                error={
                  formErrorsVisible &&
                  !!formErrors.periods &&
                  !!formErrors.periods[0][idx]
                }
                customErrorText={'Enter valid time ranges'}
              />
            </div>
            <div
              className={styles.trashIcon}
              onClick={() => onRemovePeriod(idx)}
            >
              <TrashIcon size={16} />
            </div>
          </div>
        ))}

        {tooManyPeriods && (
          <div className={styles.tooManyPeriodsMessage}>
            This report only allows up to four temperature checks. If you
            require more, please contact Energybox.
          </div>
        )}
      </ReportContentSection>
    </div>
  );
};

export default SetupTemperatureChecks;
