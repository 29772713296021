import {
  Button,
  FormText,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import React, { useRef } from 'react';
import ModalFormContent from '../../../components/ModalFormContent';
import styles from './UserApiTokenCreatedModal.module.css';

interface Props {
  onClose: () => void;
  title: string;
  apiKey: string;
}

const UserApiTokenCreatedModal = ({ onClose, title, apiKey }: Props) => {
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    if (textAreaRef !== null && textAreaRef.current !== null) {
      // @ts-ignore
      textAreaRef.current.select();
      document.execCommand('copy');
    }
  }

  const actions = (
    <>
      <Button onClick={onClose}>Ok</Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalTitle>API Key Created</ModalTitle>
      <ModalContent>
        <ModalFormContent>
          <div>
            <Label>Name</Label>
          </div>
          <Label>{title}</Label>

          <div>
            <Label>Key</Label>
          </div>
          <div>
            <textarea className={styles.textarea} ref={textAreaRef}>
              {apiKey}
            </textarea>
            <Button variant={'text'} onClick={copyToClipboard}>
              Copy key to clipboard
            </Button>
          </div>
        </ModalFormContent>
        <div></div>
        <FormText>
          This is the only time we will show this API key. Lost API keys cannot
          be restored and must be re-created.
        </FormText>
      </ModalContent>
    </Modal>
  );
};

export default UserApiTokenCreatedModal;
