import { ApplicationState } from '../../reducers';
import { Actions } from '../../actions/equipment';

export const equipmentByIdSelector = ({ equipment }: ApplicationState) =>
  equipment.equipmentById;

export const equipmentIdsBySiteIdSelector = ({ equipment }: ApplicationState) =>
  equipment.equipmentIdsBySiteId;

export const equipmentLoadingBySiteIdSelector = ({
  equipment,
}: ApplicationState) =>
  equipment.loadingStatusByAction[Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING];
