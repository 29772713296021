import { Tooltip } from '@energybox/react-ui-library/dist/components';
import { calculateLightSensorThresholdWithHysteresis } from '../../utils/controls';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import styles from './HysteresisInfoTooltip.module.css';

type Props = {
  hysteresis: number;
  threshold: number;
  isOrgLevel?: boolean;
};

const HysteresisInfoTooltip: React.FC<Props> = ({
  threshold,
  hysteresis,
  isOrgLevel,
}) => {
  return (
    <Tooltip
      underline={isOrgLevel ? true : false}
      arrowDirection={isOrgLevel ? 'topLeft' : 'right'}
      tooltipTextClassName={isOrgLevel ? styles.text : ''}
      extraClassNames={isOrgLevel ? [styles.tooltip] : [styles.margin]}
      disableAutoAdjust
      content={
        <div className={styles.tooltipRoot}>
          <div className={styles.tooltipTitle}>Hysterisis</div>
          <div className={styles.paddingTop}>
            <div className={styles.tooltipLineEntry}>
              <span>Default</span>
              <span>10%</span>
            </div>
            <div className={styles.tooltipLineEntry}>
              <span>Minimum</span>
              <span>10%</span>
            </div>
            <div className={styles.tooltipLineEntry}>
              <span>Maximum</span>
              <span>100%</span>
            </div>
            {isOrgLevel ? (
              <></>
            ) : (
              <div className={styles.tooltipCommentLine}>
                Will switch off at{' '}
                {calculateLightSensorThresholdWithHysteresis(
                  threshold,
                  hysteresis
                )}{' '}
                lux
              </div>
            )}
          </div>
        </div>
      }
    >
      {isOrgLevel ? (
        <div className={styles.title}> Hysteresis </div>
      ) : (
        <IoIosInformationCircleOutline
          size={20}
          className={styles.marginLeft}
        />
      )}
    </Tooltip>
  );
};

export default HysteresisInfoTooltip;
