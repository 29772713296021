import { getEbTstatUuidRange } from '../config';

export function isValidUUID(uuid: string): boolean {
  const pattern = /^([0-9A-Z]{2}:){5}[0-9A-Z]{2}$/;
  return pattern.test(uuid);
}

function parseUUIDRange(uuidRangeString: string): [string, string] | null {
  const parts = uuidRangeString.split('-');
  if (parts.length !== 2) {
    return null; // Invalid format
  }

  const startUUID = parts[0].trim();
  const endUUID = parts[1].trim();

  if (!isValidUUID(startUUID) || !isValidUUID(endUUID)) {
    return null; // Invalid UUID format
  }

  return [startUUID, endUUID];
}

function uuidToNumber(uuid: string): number {
  const pattern = /^([0-9A-Z]{2}:){5}[0-9A-Z]{2}$/;
  if (!pattern.test(uuid)) {
    throw new Error('Invalid UUID format provided - ' + uuid);
  }

  const parts = uuid.split(':');
  let number = 0;

  for (let i = 0; i < parts.length; i++) {
    const hexValue = parseInt(parts[i], 16);
    number += hexValue * Math.pow(256, parts.length - 1 - i);
  }

  return number;
}

function isInRange(uuid: string, startUUID: string, endUUID: string): boolean {
  const start = uuidToNumber(startUUID);
  const end = uuidToNumber(endUUID);
  const number = uuidToNumber(uuid);
  return number >= start && number <= end;
}

export const isEBThermostat = (uuid: string): boolean => {
  if (!uuid) {
    return false;
  }
  if (!isValidUUID(uuid)) {
    return false;
  }
  //get range from env file
  const uuidRangeString = getEbTstatUuidRange();
  const parsedRange = parseUUIDRange(uuidRangeString);

  if (parsedRange) {
    const [startUUID, endUUID] = parsedRange;
    return isInRange(uuid, startUUID, endUUID);
  }
  return false;
};
