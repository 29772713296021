import { InputField } from '@energybox/react-ui-library/dist/components';
import { EquipmentControl } from '@energybox/react-ui-library/dist/types';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

type Props = {
  activeControl: EquipmentControl;
  onChange: onChange;
  equipmentId: number;
};
const EditModeSchedulerOffset: React.FC<Props> = ({
  activeControl,
  onChange,
  equipmentId,
}) => {
  const schedulerId = activeControl?.id;
  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });
  const fields = schedulerControls?.fields;
  const formErrors = schedulerControls?.formErrors;
  const formErrorsVisible = schedulerControls?.formErrorsVisible;
  const timetableId = fields?.timetableId;
  const beginDelta = fields?.beginDelta!;
  const endDelta = fields?.endDelta!;

  return (
    <div>
      <div>
        <InputField
          type="number"
          value={parseInt(beginDelta)}
          onChange={({ currentTarget }) => {
            if (schedulerId) {
              onChange(String(schedulerId), 'beginDelta', currentTarget.value);
            } else {
              onChange(`new${equipmentId}`, 'beginDelta', currentTarget.value);
            }
          }}
          error={formErrorsVisible && !!formErrors?.beginDelta}
          customErrorText={'Invalid'}
          disabled={!isDefined(timetableId)}
        />
      </div>
      <div>
        <InputField
          value={parseInt(endDelta)}
          type="number"
          onChange={({ currentTarget }) => {
            if (schedulerId) {
              onChange(String(schedulerId), 'endDelta', currentTarget.value);
            } else {
              onChange(`new${equipmentId}`, 'endDelta', currentTarget.value);
            }
          }}
          error={formErrorsVisible && !!formErrors?.endDelta}
          customErrorText={'Invalid'}
          disabled={!isDefined(timetableId)}
        />
      </div>
    </div>
  );
};

export default EditModeSchedulerOffset;
