import React from 'react';
import {
  Card,
  CardContent,
  CardList,
  RunConfigTestWarning,
} from '@energybox/react-ui-library/dist/components';
import { Link } from 'react-router-dom';
import UpdateNetworkGroupsButton from '../../components/HvacSopTable/UpdateNetworkGroupsButton';
import { SiteHvacData } from '../../reducers/organizations';
import { Routes } from '../../routes';
import { CardListRowData } from '@energybox/react-ui-library/dist/components/CardList';
import styles from '../../components/HvacSopTable/HvacSopTable.module.css';
import { hasDaResults } from '../../util';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';

interface Props {
  site: SiteHvacData;
  siteId: number;
  sopData: CardListRowData[];
  noSopData: CardListRowData[];
  orgId: number | undefined;
  featureHvacSopId: number;
  networkGroupIds: number[];
}
const SiteCardContainer: React.FC<Props> = ({
  site,
  siteId,
  sopData,
  noSopData,
  orgId,
  featureHvacSopId,
  networkGroupIds,
}) => {
  //To display the test-inprogress-warning.
  const currentSite = useSelector(
    ({ sites }: ApplicationState) => sites.sitesById[siteId]
  );
  const daResultsExist: boolean = hasDaResults(
    currentSite?.installerTestResults
  );
  const networkGroupsArray = useSelector(
    ({ networkGroups }: ApplicationState) => {
      return networkGroups?.networkGroupsByOrgId[orgId ?? ''];
    }
  );
  const filteredNetworkGroupsArray = networkGroupsArray?.filter(
    networkGroup => {
      return networkGroupIds.includes(networkGroup.id);
    }
  );

  return (
    <>
      <Card>
        <div>
          {daResultsExist && (
            <RunConfigTestWarning
              configLink={`${Routes.RUN_CONFIGURATION_TEST}/${siteId}`}
            />
          )}
        </div>
        <CardContent>
          <div className={styles.alignCardHeader}>
            <div>
              <Link
                className={styles.cardTitle}
                to={`${Routes.SITES}/${siteId}${Routes.SOPS}${Routes.HVAC}`}
                target="_blank"
              >
                {site.title}
              </Link>
              <div className={styles.cardSubHead}>{site.address}</div>
            </div>
            <div className={styles.lastConfigSection}>
              <UpdateNetworkGroupsButton
                orgId={orgId!}
                siteId={siteId}
                daResultsExist={daResultsExist}
                networkGroups={filteredNetworkGroupsArray}
              />
            </div>
          </div>
          <CardList
            numberOfGridColumns={19}
            header={tableHeader}
            data={[...sopData, ...noSopData]}
            featureCardId={featureHvacSopId}
          />
        </CardContent>
      </Card>
    </>
  );
};
const tableHeader = [
  {
    width: '2',
    content: 'Attached To (Eq.Type) ',
  },
  {
    width: '2',
    content: 'Equipment',
  },
  {
    width: '2',
    content: 'Sop Level/Title',
  },
  {
    width: '2',
    content: 'Local Adjustment',
  },
  {
    width: '1',
    content: 'EDM Enabled',
    featureFlag: true,
  },
  {
    width: '2',
    content: 'Timetable',
  },
  {
    width: '2',
    content: 'Days/Time',
  },
  {
    width: '2',
    content: 'Thermostat Mode',
  },
  {
    width: '2',
    content: 'Temperature',
  },
  {
    width: '1',
    content: 'Fan',
  },
  {
    width: '1',
    content: 'Actions',
  },
];
export default SiteCardContainer;
