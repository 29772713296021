import {
  SiteGroup,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SiteGroupActions,
  destroy,
  displayFormErrors,
  getGroup,
  patch,
  reset,
  updateField,
} from '../../actions/site_groups';
import EditGroupForm from '../../components/EditGroupForm';
import ShowDetailPageHeader from '../../components/ShowDetailPageHeader';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import SiteGroupEditSites from '../SiteGroup/SiteGroupEditSites';
import { accessDeniedError } from '../../utils/ApiError/accessDeniedError';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  onChange: (field: string, value: string) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  group?: SiteGroup;
  isChanged: boolean;
  title: string;
  description: string;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  displayFormErrors: () => void;
  apiError: ApiError;
  siteGroupApiError: ApiError;
}

interface State {
  showDeletePrompt: boolean;
}

class ShowSitesGroupPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeletePrompt: false,
    };
  }

  onSave = () => {
    const { formErrors, displayFormErrors, patch } = this.props;
    if (hasKeys(formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  componentDidMount() {
    this.props.load();
  }

  handleOpenDeletePrompt() {
    this.setState({ showDeletePrompt: true });
  }

  handleCloseDeletePrompt() {
    this.setState({ showDeletePrompt: false });
  }

  deletePrompt() {
    const { group, onDelete, apiError } = this.props;

    const actions = (
      <>
        <Button
          variant="text"
          onClick={this.handleCloseDeletePrompt.bind(this)}
        >
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal
        onClose={this.handleCloseDeletePrompt.bind(this)}
        actions={actions}
      >
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete{' '}
          {group ? <b>{group.title}</b> : 'this group'}?
        </p>
        {renderAPIerror(apiError, SiteGroupActions.DELETE_ERROR)}
      </Modal>
    );
  }

  render() {
    const {
      group,
      title,
      description,
      isChanged,
      onChange,
      formErrors,
      formErrorsVisible,
      apiError,
      siteGroupApiError,
    } = this.props;
    const { showDeletePrompt } = this.state;

    return !Object.keys(siteGroupApiError).length ? (
      <>
        {group && (
          <ShowDetailPageHeader
            name={group.title}
            description={group.description}
            resourceName="Group"
            onDelete={this.handleOpenDeletePrompt.bind(this)}
          />
        )}

        <div style={{ padding: '3rem' }}>
          <Card>
            <CardContent>
              <EditGroupForm
                onChange={onChange}
                title={title}
                description={description}
                formErrors={formErrors}
                formErrorsVisible={formErrorsVisible}
              />
              {renderAPIerror(apiError, SiteGroupActions.PATCH_ERROR)}
            </CardContent>

            {isChanged && (
              <CardActions>
                <Button
                  variant="text"
                  onClick={this.props.onCancel}
                  children="Cancel"
                />
                <Button onClick={this.onSave}>Save Changes</Button>
              </CardActions>
            )}
          </Card>
        </div>
        <div style={{ padding: '0 3rem 3rem 3rem' }}>
          {group && (
            <SiteGroupEditSites
              groupId={group.id}
              siteIds={group.sites ? group.sites.map(s => s.id) : []}
            />
          )}
        </div>

        {showDeletePrompt && this.deletePrompt()}
      </>
    ) : (
      accessDeniedError(siteGroupApiError)
    );
  }
}

const mapStateToProps = (
  { siteGroups }: ApplicationState,
  { id }: OwnProps
) => {
  const siteGroup = siteGroups.siteGroupsById[id];
  const editSiteGroup = siteGroups.editById[id] || {};
  const siteGroupApiError = siteGroups.siteGroupApiError;
  const {
    isChanged,
    apiError,
    fields,
    formErrors,
    formErrorsVisible,
  } = editSiteGroup;

  return {
    group: siteGroup,
    isChanged,
    title: fields && fields.title,
    description: fields && fields.description,
    formErrors,
    formErrorsVisible,
    apiError,
    siteGroupApiError: siteGroupApiError,
  };
};

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => dispatch(getGroup(id)),
  onChange: (f: string, v: string) => dispatch(updateField(id, f, v)),
  patch: () => dispatch(patch(id)),
  onCancel: () => dispatch(reset(id)),
  onDelete: () => dispatch(destroy(id)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowSitesGroupPage);
