import styles from './HvacSopTimetableTooltipContent.module.css';
import convertTemp from '@energybox/react-ui-library/dist/utils/temperature';
import {
  useIs24HrTimeFormat,
  useUserSettingTemperatureUnit,
} from '../../../hooks/useAppDetails';

type props = {
  timetableTitle: string;
  timeRange: string;
  thermostatMode: string;
  minTemp: number | undefined;
  maxTemp: number | undefined;
};

const HvacSopTimetableTooltipContent: React.FC<props> = ({
  timetableTitle,
  timeRange,
  thermostatMode,
  minTemp,
  maxTemp,
}) => {
  const userTemperatureUnit = useUserSettingTemperatureUnit();
  let setPointString = '';
  const autoMode = thermostatMode === 'AUTO';
  const heatingMode = thermostatMode === 'HEATING';
  const coolingMode = thermostatMode === 'COOLING';
  if (userTemperatureUnit == 'FAHRENHEIT') {
    if (minTemp && maxTemp && autoMode) {
      setPointString =
        convertTemp(minTemp, 'c', 0) +
        '°F - ' +
        convertTemp(maxTemp, 'c', 0) +
        '°F';
    } else if (minTemp && heatingMode) {
      setPointString = convertTemp(minTemp, 'c', 0) + '°F';
    } else if (maxTemp && coolingMode) {
      setPointString = convertTemp(maxTemp, 'c', 0) + '°F';
    } else {
      setPointString = ' - ';
    }
  } else {
    if (minTemp && maxTemp && autoMode) {
      setPointString = minTemp + '°C - ' + maxTemp + '°C';
    } else if (minTemp && heatingMode) {
      setPointString = minTemp + '°C';
    } else if (maxTemp && coolingMode) {
      setPointString = maxTemp + '°C';
    } else {
      setPointString = ' - ';
    }
  }
  return (
    <div>
      <div className={styles.tooltipTitle}>{timetableTitle}</div>
      <div className={styles.tooltipTime}>{timeRange}</div>
      <div className={styles.thermostatInfo}>
        <div className={styles.thermostatInfoPadding}>
          Thermostat Mode: {thermostatMode}
        </div>
        <div>Set Point : {setPointString}</div>
      </div>
    </div>
  );
};

export default HvacSopTimetableTooltipContent;
