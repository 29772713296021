import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { IoIosMenu } from 'react-icons/io';
import {
  FeaturesByMonth,
  Organization,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  global,
  isCustomer,
  render,
} from '@energybox/react-ui-library/dist/utils';
import {
  Organization as OrganizationIcon,
  User as UserIcon,
} from '@energybox/react-ui-library/dist/icons';
import CompanyLogo from '@energybox/react-ui-library/dist/icons/CompanyLogo';
import {
  AsyncImgLoader,
  HelpMenu,
  NewFeatureModal,
  NewFeaturePopup,
  SearchBox,
  TopBar,
  TopBarMenu,
  HighlightedNumber,
  DownloadIcon,
  NewPasswordPopup,
} from '@energybox/react-ui-library/dist/components';
import { menuWithMobileStyles } from '@energybox/react-ui-library/dist/components/TopBar';
import TopBarMenuItem from '@energybox/react-ui-library/dist/components/TopBarMenuItem';
import {
  dismissNewPasswordPopup,
  getCurrentUser,
  logout,
  switchOrganization,
  toggleMobileMenu,
} from '../actions/app';
import { getOrganizations } from '../actions/organizations';
import history from '../history';
import { ApplicationState } from '../reducers';
import { sortedOrganizationsWithOrgTypeOrdinal } from '../reducers/organizations';
import styles from './TopBarContainer.module.css';
import {
  dismissNewFeature,
  getAttachmentUrl,
  getNewFeature,
  getNewFeatureVisited,
  viewNewFeature,
} from '../actions/whatsNew';
import {
  ImgDom,
  Renderer,
} from '@energybox/react-ui-library/dist/types/ParsedHTML';
import { isAtlassianImg } from '@energybox/react-ui-library/dist/utils/parsedHTML';
import { FeatureStatus } from '@energybox/react-ui-library/dist/types/Feature';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import mixpanel from 'mixpanel-browser';
import { getUser } from '../actions/users';
import {
  determineInstallerRoleInPlatform,
  determineUserIsEBGlobalAdmin,
} from '../utils/user';
import { CurrentUserPlus } from '../reducers/app';
import { InstallerAccess } from '../types/user';
import { Routes } from '../routes';
import mixpanelEvents from '../mixpanelEvents';
import { TopBarMenuItemProps } from '@energybox/react-ui-library/dist/components/TopBarMenuItem/TopBarMenuItem';

import { useDownloadStatus } from '../hooks/reportWorker/useDownloadStatus';

interface Props {
  getCurrentUser: typeof getCurrentUser;
  getOrganizations: typeof getOrganizations;
  switchOrganization: typeof switchOrganization;
  logout: typeof logout;
  user?: CurrentUserPlus;
  currentOrganizationId?: number;
  organizationsById: { [key: string]: Organization };
  isMobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
  getNewFeature: typeof getNewFeature;
  features: FeaturesByMonth;
  getNewFeatureVisited: typeof getNewFeatureVisited;
  viewStatus: FeatureStatus;
  viewNewFeature: typeof viewNewFeature;
  dismissNewFeature: typeof dismissNewFeature;
  notifyStatus: boolean;
  showHideIcon: boolean;
  saveFileId: Array<string>;
  getUser: typeof getUser;
  pathname: string;
  passwordUpdateEnforced?: boolean;
  showNewPasswordPopup: boolean;
  dismissNewPasswordPopup: typeof dismissNewPasswordPopup;
  showFindMySensorMenuItem: boolean;
}

const TopBarContainer: React.FC<Props> = props => {
  const [query, setQuery] = useState('');
  const [showNewFeaturePopup, setShowNewFeaturePopup] = useState(false);
  const [showNewPasswordPopup, setShowNewPasswordPopup] = useState(false);
  const [isNewFeatureModalVisible, setIsNewFeatureModalVisible] = useState(
    false
  );

  useDownloadStatus();

  useEffect(() => {
    props.getCurrentUser();
    props.getOrganizations();
    props.getNewFeature();
  }, []);

  useEffect(() => {
    if (props.user) {
      const { id, email, position } = props.user;
      const currentOrganization =
        props.currentOrganizationId &&
        props.organizationsById[props.currentOrganizationId];

      if (email) {
        mixpanel.identify(email);
        mixpanel.people.set({
          $email: email,
          USER_ID: id,
          ORG_ID: currentOrganization ? currentOrganization.id : undefined,
          ORG_TITLE: currentOrganization
            ? currentOrganization.title
            : undefined,
          POSITION: position,
        });
      }
      mixpanel.track(mixpanelEvents.USER_VISIT);
    }
  }, [props.user]);

  useEffect(() => {
    if (props.pathname) {
      try {
        mixpanel.track(mixpanelEvents.PAGE_VISIT, {
          page: props.pathname,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.pathname]);

  useEffect(() => {
    if (props.viewStatus === FeatureStatus.HAS_UPDATE) {
      const featureTimeout = setTimeout(() => {
        setShowNewFeaturePopup(true);
      }, 2000);
      return () => clearTimeout(featureTimeout);
    } else {
      setShowNewFeaturePopup(false);
    }
  }, [props.viewStatus]);

  useEffect(() => {
    if (props.showNewPasswordPopup) {
      const passwordTimeout = setTimeout(() => {
        setShowNewPasswordPopup(true);
      }, 2000);
      return () => clearTimeout(passwordTimeout);
    } else {
      setShowNewPasswordPopup(false);
    }
  }, [props.showNewPasswordPopup]);

  const handleSearchChange = (value: string) => {
    setQuery(value);
  };

  const handleShowNewFeatureModal = () => {
    if (props.user) {
      props.viewNewFeature(props.user.id);
      setIsNewFeatureModalVisible(true);
    }
  };

  const hideNewFeatureModal = () => {
    setIsNewFeatureModalVisible(false);
  };

  const img: Renderer<ImgDom> = dom => {
    if (isAtlassianImg(dom)) {
      return (
        <AsyncImgLoader
          src={async () => getAttachmentUrl(dom)}
          width="100%"
          originalSize={{
            width: parseInt(dom.attributes['data-width']),
            height: parseInt(dom.attributes['data-height']),
          }}
        />
      );
    }
    return <img {...dom.attributes} />;
  };

  const {
    user,
    organizationsById,
    currentOrganizationId,
    isMobileMenuOpen,
    toggleMobileMenu,
    features,
    notifyStatus,
    showHideIcon,
    saveFileId,
    passwordUpdateEnforced,
    dismissNewPasswordPopup,
    showFindMySensorMenuItem,
  } = props;

  const organizationsList =
    organizationsById &&
    sortedOrganizationsWithOrgTypeOrdinal(organizationsById);

  const filteredOrganizationsList = organizationsList.filter(
    (organization: Organization) =>
      organization.title.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
      String(organization.id).indexOf(query) > -1
  );

  const currentOrganization = currentOrganizationId
    ? organizationsById[currentOrganizationId]
    : undefined;

  const installerAccess = determineInstallerRoleInPlatform(
    user,
    currentOrganization
  );

  const isEBInternal =
    currentOrganization?.organizationType === OrganizationType.EB_INTERNAL;

  const getUserMenuItems = useCallback(
    (user, installerAccess, passwordUpdateEnforced) => {
      const Logout = (
        <TopBarMenuItem
          className={menuWithMobileStyles.link}
          onSelect={props.logout}
          key="logout"
        >
          Logout
        </TopBarMenuItem>
      );
      if (passwordUpdateEnforced) return [Logout];

      return [
        <TopBarMenuItem
          className={menuWithMobileStyles.mobileStaticMenuItem}
          key="username"
        >
          {user ? `${user.firstName} ${user.lastName}` : global.NOT_AVAILABLE}
        </TopBarMenuItem>,
        ...(installerAccess !== InstallerAccess.INSTALLER
          ? [
              <TopBarMenuItem
                key="downloadPage"
                className={menuWithMobileStyles.link}
                onSelect={() => history.push('/my-downloads')}
              >
                My Downloads
              </TopBarMenuItem>,
            ]
          : []),
        <TopBarMenuItem
          key="pwChangeLink"
          className={menuWithMobileStyles.link}
          onSelect={() => history.push('/settings/password-change')}
        >
          Change Password
        </TopBarMenuItem>,
        Logout,
      ];
    },
    [props.logout]
  );

  const getOrganizationMenuItems = useCallback(
    (currentOrganization, filteredOrganizationsList) => {
      return [
        <TopBarMenuItem
          className={menuWithMobileStyles.mobileStaticMenuItem}
          key="orgTitle"
        >
          {currentOrganization
            ? currentOrganization.title
            : global.NOT_AVAILABLE}
        </TopBarMenuItem>,
        <SearchBox
          key="orgSearch"
          color="ambient"
          placeholder="Search Organizations"
          onChange={handleSearchChange}
          query={query}
          width="100%"
          widthActive="100%"
          error={filteredOrganizationsList.length === 0}
          noClickOutsideDetection
        />,
        filteredOrganizationsList.map((organization: Organization) => (
          <TopBarMenuItem
            key={organization.id}
            onSelect={() => props.switchOrganization(organization.id)}
            className={
              isCustomer(organization.organizationType)
                ? styles.customerOrgMenuItem
                : styles.nonCustomerOrgMenuItem
            }
          >
            {`${organization.title} [${organization.id}]`}
          </TopBarMenuItem>
        )),
      ];
    },
    [handleSearchChange, query, props.switchOrganization]
  );

  const userMenuItem = (
    <div className={menuWithMobileStyles.menuContainer}>
      {user ? (
        <TopBarMenu
          title={`${user.firstName} ${user.lastName}`}
          icon={
            <UserIcon variant="tiny" color="var(--accent-base)" size={16} />
          }
        >
          {getUserMenuItems(user, installerAccess, passwordUpdateEnforced)}
        </TopBarMenu>
      ) : null}
    </div>
  );

  const userMenuItemInMobile = (
    <div className={menuWithMobileStyles.mobileMenuContainer}>
      <TopBarMenu
        icon={<UserIcon variant="tiny" color="var(--accent-base)" size={16} />}
        noExpandIcon
        alignItemsRight
      >
        {getUserMenuItems(user, installerAccess, passwordUpdateEnforced)}
      </TopBarMenu>
    </div>
  );

  const menuItems: TopBarMenuItemProps[] = [
    {
      key: 'whatsnew',
      children: "What's New...",
      onSelect: handleShowNewFeatureModal,
      isVisible: isEBInternal && !!features,
    },
    {
      children: 'Find My Sensor',
      onSelect: () => window.open('/find-my-sensor', '_blank'),
      isVisible: showFindMySensorMenuItem,
    },
    {
      key: 'install-manual',
      children: 'Installation Manual',
      onSelect: () => window.open('/installation-manual', '_blank'),
      isVisible: isEBInternal || installerAccess === InstallerAccess.INSTALLER,
    },
    {
      key: 'license',
      children: 'Open Source Notices',
      onSelect: () => history.push('/license'),
    },
  ];

  return (
    <TopBar
      showPopup={showNewFeaturePopup || showNewPasswordPopup}
      popup={
        <>
          {showNewPasswordPopup && (
            <NewPasswordPopup
              onDismiss={dismissNewPasswordPopup}
              enforceDate={
                process.env.REACT_APP_ENFORCE_PASSWORD_CHANGE_DATE ||
                '2023-07-12'
              }
              changePasswordRoute={Routes.PASSWORD_CHANGE}
            />
          )}
          {showNewFeaturePopup && (
            <NewFeaturePopup
              onAction={handleShowNewFeatureModal}
              onDismiss={props.dismissNewFeature}
            />
          )}
        </>
      }
    >
      <div>
        <button
          className={classNames(
            menuWithMobileStyles.mobileMenuButton,
            isMobileMenuOpen ? menuWithMobileStyles.glowingButton : undefined
          )}
          onClick={toggleMobileMenu}
        >
          <IoIosMenu
            className={menuWithMobileStyles.mobileMenuIcon}
            size="24"
          />
        </button>
        <CompanyLogo
          className={menuWithMobileStyles.companyLogoWithoutText}
          withText={false}
          width="18"
          height="18"
        />
      </div>
      {passwordUpdateEnforced ? (
        <div>
          {userMenuItem}
          {userMenuItemInMobile}
        </div>
      ) : (
        <div>
          <HelpMenu menuItems={menuItems} />

          {features && (
            <NewFeatureModal
              features={features}
              isModalShowing={isNewFeatureModalVisible}
              onClose={hideNewFeatureModal}
              render={render({ img })}
            />
          )}

          {organizationsList.length > 0 ? (
            <div
              className={classNames(
                menuWithMobileStyles.menuContainer,
                styles.organizationMenuContainer
              )}
            >
              <TopBarMenu
                title={
                  currentOrganization
                    ? currentOrganization.title
                    : global.NOT_AVAILABLE
                }
                icon={<OrganizationIcon color="var(--accent-base)" size={14} />}
              >
                {getOrganizationMenuItems(
                  currentOrganization,
                  filteredOrganizationsList
                )}
              </TopBarMenu>
            </div>
          ) : null}

          {organizationsList.length > 0 ? (
            <div className={menuWithMobileStyles.mobileMenuContainer}>
              <TopBarMenu
                icon={<OrganizationIcon color="var(--accent-base)" size={16} />}
                noExpandIcon
                alignItemsRight
              >
                {getOrganizationMenuItems(
                  currentOrganization,
                  filteredOrganizationsList
                )}
              </TopBarMenu>
            </div>
          ) : null}

          {userMenuItem}

          {showHideIcon && installerAccess !== InstallerAccess.INSTALLER && (
            <DownloadIcon
              onClick={() => {
                history.push('/my-downloads');
              }}
              animated={notifyStatus}
            />
          )}

          {saveFileId.length !== 0 &&
            installerAccess !== InstallerAccess.INSTALLER && (
              <HighlightedNumber
                value={saveFileId.length}
                className={styles.fileCounter}
                onClick={() => {
                  history.push('/my-downloads');
                }}
              />
            )}

          {userMenuItemInMobile}
        </div>
      )}
    </TopBar>
  );
};

const mapStateToProps = ({
  app,
  organizations,
  whatsNew,
  reportworker,
  router,
}: ApplicationState) => ({
  user: app.currentUser,
  organizationsById: organizations.organizationsById,
  currentOrganizationId: app.currentOrganizationId,
  isMobileMenuOpen: app.isMobileMenuOpen,
  features: whatsNew.newFeatures,
  viewStatus: whatsNew.featureStatus,
  notifyStatus: reportworker.notifyStatus,
  showHideIcon: reportworker.toggleIcon,
  saveFileId: reportworker.saveFileIds,
  pathname: router.location.pathname,
  passwordUpdateEnforced: app.passwordUpdateEnforced,
  showNewPasswordPopup:
    app.passwordUpdateEnforced === false && !app.newPasswordPopupDismissed,
  showFindMySensorMenuItem: determineUserIsEBGlobalAdmin(app.currentUser),
});

const mapDispatchToProps = {
  getCurrentUser,
  getOrganizations,
  switchOrganization,
  logout,
  toggleMobileMenu,
  getNewFeature,
  getNewFeatureVisited,
  viewNewFeature,
  dismissNewFeature,
  getUser,
  dismissNewPasswordPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBarContainer);
