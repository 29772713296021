import { Sentinel } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modal';
import { deleteSentinel } from '../../actions/sentinels';
import ShowDetailPageHeader from '../../components/ShowDetailPageHeader';
import SentinelWizard from '../../containers/SentinelWizard';
import history from '../../history';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { StepType } from '../../types/sentinelWizard';

type Props = {
  sentinel?: Sentinel;
  deleteSentinel: typeof deleteSentinel;
  openModal: typeof openModal;
};

type OwnProps = {
  id: string;
  step?: StepType;
};

function deleteSentinelModal(
  openModalFn: typeof openModal,
  actionFn: typeof deleteSentinel,
  targetId: string,
  targetTitle: string
) {
  openModalFn({
    title: 'Delete configuration',
    description: `Do you really want to delete the configuration ${targetTitle}?`,
    actionFn: () => {
      actionFn(targetId);
      history.push(Routes.SENTINELS);
    },
  });
}

function ShowSentinelPage(props: Props & OwnProps) {
  const { sentinel, id, step, openModal, deleteSentinel } = props;

  return (
    <>
      <ShowDetailPageHeader
        name={sentinel ? sentinel.title : ''}
        description={sentinel ? sentinel.description : ''}
        resourceName="Sentinel"
        // pathBack={Routes.SENTINELS}
        hideDelete={id === 'new'}
        onDelete={() =>
          sentinel &&
          deleteSentinelModal(
            openModal,
            deleteSentinel,
            sentinel.id,
            sentinel.title
          )
        }
      />

      <div style={{ padding: '3rem' }}>
        <SentinelWizard id={id} step={step} />
      </div>
    </>
  );
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
  sentinel: state.sentinels.byId[ownProps.id],
});

const mapDispatchToProps = {
  deleteSentinel,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowSentinelPage);
