import {
  Calendar,
  InputField,
  MultiSelect,
} from '@energybox/react-ui-library/dist/components';
import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import { Trash } from '@energybox/react-ui-library/dist/icons';
import { TimeTableSpecialStore } from '@energybox/react-ui-library/dist/types';
import dateFormat from 'date-fns/format';
import React, { useEffect, useRef, useState } from 'react';
import { generateDateLabel } from '../../../utils/dates';
import ToggleItem from '../../ToggleItem';
import AddRowButton from '../AddRowButton';
import TimeTableRow from '../TimeTableRow';
import styles from './TimeTableSpecialRow.module.css';

interface Props {
  id: string | number;
  special: TimeTableSpecialStore;
  isInDropdown?: boolean;
  shouldCollapse?: boolean;
  shouldAutoCollapse?: boolean;
  addTimeTableRow: () => void;
  removeTimeTableRow: (rowIndex) => void;
  onRemove: () => void;
  onChange: (path, field, value, validationType) => void;
  formErrorsVisible: boolean;
}

const TimeTableSpecialRow = ({
  special,
  addTimeTableRow,
  removeTimeTableRow,
  onRemove,
  onChange,
  id,
  formErrorsVisible,
  shouldCollapse,
  shouldAutoCollapse,
  isInDropdown = false,
}: Props) => {
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const [fromDate, setFromDate] = useState(
    dateFormat(new Date(), 'yyyy-MM-dd')
  );
  const [toDate, setToDate] = useState<string | null>('');

  const onRemoveRow = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onRemove();
  };

  const onUpdateDateRange = (from: string, to: string | null = null) => {
    setFromDate(from);
    setToDate(to);
  };

  const resetDate = () => {
    setFromDate(dateFormat(new Date(), 'yyyy-MM-dd'));
    setToDate(dateFormat(new Date(), 'yyyy-MM-dd'));
  };

  const onAddDateRange = index => {
    const newDateRanges = [
      ...special.dateRanges,
      {
        begin: fromDate,
        end: toDate,
      },
    ];
    onChange([id], 'dateRanges', newDateRanges, 'specialTimeTableRow');
    resetDate();
  };

  const onRemoveDateRange = (e, dateRangeIndex) => {
    e.preventDefault();

    const newDateRanges = special.dateRanges.filter(
      (dateRange, index) => index !== dateRangeIndex
    );

    onChange([id], 'dateRanges', newDateRanges, 'specialTimeTableRow');
  };

  const prev = usePrevious({ special }) || { special: { rows: [] } };
  const isAddingRow = prev.special.rows.length < special.rows.length;

  return (
    <ToggleItem
      startOpen
      shouldCollapse={shouldCollapse}
      header={
        <>
          <Cell width="11">{special.title || 'Special'}</Cell>
          <Cell cellAlign="right">
            <button
              type="button"
              onClick={onRemoveRow}
              className={`${styles.invisibleButton} ${styles.trashButton}`}
            >
              <Trash size={16} />
            </button>
          </Cell>
        </>
      }
    >
      <Cell width="12">
        <Cell className={styles.specialInfoContainer}>
          <Cell className={styles.specialTitleRow}>
            <Cell>Special</Cell>
            <Cell cellAlign="right">
              <button
                type="button"
                onClick={onRemoveRow}
                className={`${styles.invisibleButton} ${styles.trashButton}`}
              >
                <Trash size={16} />
              </button>
            </Cell>
          </Cell>
          <Cell>
            <InputField
              type="text"
              name={special.title}
              value={special.title}
              autoComplete={special.title}
              onChange={e =>
                onChange(
                  [id],
                  'title',
                  e.currentTarget.value,
                  'specialTimeTableRow'
                )
              }
              placeholder="Name for special row"
              error={formErrorsVisible && !!special.formErrors.title}
              customErrorText={
                !!special.formErrors.title && special.formErrors.title[0]
              }
            />
          </Cell>
          <Cell className={styles.marginTop}>
            <MultiSelect
              error={formErrorsVisible && !!special.formErrors.dateRanges}
              customErrorText={
                !!special.formErrors.dateRanges &&
                special.formErrors.dateRanges[0]
              }
              onDeleteItem={(e, index) => onRemoveDateRange(e, index)}
              items={special.dateRanges.map((dateRange, index) => ({
                value: `${generateDateLabel(dateRange.begin)}${
                  dateRange.end ? ' - ' + generateDateLabel(dateRange.end) : ''
                }`,
                id: index,
              }))}
              selectionComponent={
                <Calendar
                  onChange={({ from, to }) => onUpdateDateRange(from, to)}
                  from={fromDate}
                  to={toDate || undefined}
                />
              }
              selectionComponentClassName={
                isInDropdown ? styles.calendarBackdrop : ''
              }
              onSelection={onAddDateRange}
              selectionComponentType={isInDropdown ? 'dropdown' : 'modal'}
            />
          </Cell>
        </Cell>
        {special.rows.map((row, index) => (
          <TimeTableRow
            id={index}
            weekdayPickerId={`${id}${index}`}
            key={index}
            removable={special.rows.length > 1}
            row={row}
            shouldCollapse={
              shouldAutoCollapse &&
              isAddingRow &&
              index < special.rows.length - 1
            }
            onChange={(rowIndex, rowValue) =>
              onChange([id, 'rows'], rowIndex, rowValue, 'timeTableRow')
            }
            onRemove={removeTimeTableRow}
            formErrorsVisible={formErrorsVisible}
          />
        ))}
        <Cell width="12">
          <AddRowButton onClick={addTimeTableRow}>Add row</AddRowButton>
        </Cell>
      </Cell>
    </ToggleItem>
  );
};

export default TimeTableSpecialRow;
