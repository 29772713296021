import {
  Site,
  Sop,
  SopCostTypes,
  SopPolicyTypes,
  TimeTablesById,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getEquipmentBySiteId } from '../../actions/equipment';
import {
  Actions,
  changeActiveSopTab,
  getSop,
  getSopByOrganizationalUnitId,
  showNewOrEditCostSopModal,
  showNewOrEditPolicySopModal,
  showNewOrEditHvacSopModal,
} from '../../actions/sops';
import {
  getTimeTables,
  showNewModal as showNewTimeTableSopModal,
} from '../../actions/time_tables';
import { ApplicationState } from '../../reducers';
import { SopTabTitles } from '../../types/sop';
import { Routes } from '../../routes';
import { getObjectWithSelectedKeys } from '../../util';
import { getTimetablesRoute } from '../../utils/timeTables';
import SopCardContainer from '../Sop/SopCardContainer';
import SopDetailTabs from '../Sop/SopDetailTabs';
import ShowSitePageTimeTables from './ShowSitePageTimeTables';
import ShowSitePageHvacSops from './ShowSitePageHvacSops';
import { showNewModal } from '../../actions/taskSop';
import ShowSitePageTaskSops from './ShowSitePageTaskSops';

interface OwnProps {
  id: string;
  orgId: number;
}

interface Props extends OwnProps {
  load: () => void;
  sitesSops: Sop[];
  orgLevelSops: Sop[];
  siteTimeTablesById: TimeTablesById;
  orgTimeTablesById: TimeTablesById;
  siteEquipmentTypeIds: number[];
  site: Site;
  getSop: typeof getSop;
  showNewOrEditCostModal: typeof showNewOrEditCostSopModal;
  showNewOrEditPolicyModal: typeof showNewOrEditPolicySopModal;
  showNewOrEditHvacSopModal: (id: number | string) => void;
  showNewOrEditTaskSopModal: () => void;
  showNewTimeTableModal: typeof showNewTimeTableSopModal;
  getSopByOrganizationalUnitId: typeof getSopByOrganizationalUnitId;
  getSopsIsLoading?: boolean;
  activeTab?: string;
  onSopTabChanged: (sopType: string) => {};
}

interface State {
  sopId: string | number;
  sopType: string;
}

class ShowSitePageSops extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      sopId: 'new',
      sopType: 'policy',
    };
  }

  componentDidMount() {
    this.props.load();
  }

  render() {
    const {
      sitesSops,
      orgLevelSops,
      getSopsIsLoading,
      siteTimeTablesById,
      orgTimeTablesById,
      siteEquipmentTypeIds,
      site,
    } = this.props;

    let sops = [...sitesSops, ...orgLevelSops];
    const timeTablesById = {
      ...siteTimeTablesById,
      ...orgTimeTablesById,
    };

    return (
      <>
        {!getSopsIsLoading && (
          <div>
            {site && (
              <SopDetailTabs
                siteId={site.id}
                onSopTypeCategoryChange={this.handleGetSopTypeFromSopDetailTabs}
              />
            )}

            <Switch>
              <Route exact path={`${Routes.SITES}/${site.id}${Routes.SOPS}`}>
                <Redirect
                  to={`${Routes.SITES}/${site.id}${Routes.SOPS}${Routes.POLICIES}`}
                />
              </Route>
              <Route
                exact
                path={`${Routes.SITES}/${site.id}${Routes.SOPS}${Routes.POLICIES}`}
                render={({ match }) =>
                  match &&
                  site &&
                  this.renderSopContainer(
                    sops,
                    site,
                    timeTablesById,
                    siteEquipmentTypeIds,
                    match,
                    SopTabTitles.POLICY_TAB_TITLE
                  )
                }
              />
              <Route
                path={`${Routes.SITES}/${site.id}${Routes.SOPS}${Routes.COSTS}`}
                render={({ match }) =>
                  match &&
                  site &&
                  this.renderSopContainer(
                    sops,
                    site,
                    timeTablesById,
                    siteEquipmentTypeIds,
                    match,
                    SopTabTitles.COST_TAB_TITLE
                  )
                }
              />
              <Route
                path={getTimetablesRoute({ orgUnitId: site.id })}
                render={({ match }) => match && this.renderTimeTable(site)}
              />
              <Route
                path={`${Routes.SITES}/${site.id}${Routes.SOPS}${Routes.HVAC}`}
                render={({ match }) =>
                  match && site && this.renderHvacSopContainer(site)
                }
              />
              <Route
                path={`${Routes.SITES}/${site.id}${Routes.SOPS}${Routes.TASKS}`}
                render={({ match }) =>
                  match && site && this.renderTaskSopContainer(site)
                }
              />
            </Switch>
          </div>
        )}
      </>
    );
  }

  renderSopContainer = (
    sops,
    site,
    timeTablesById,
    siteEquipmentTypeIds,
    match,
    activeTabTitle
  ) => {
    let filteredSops;

    if (SopTabTitles.POLICY_TAB_TITLE === activeTabTitle) {
      filteredSops = sops.filter(sop =>
        sop && sop.components[0] !== undefined
          ? Object.values(SopPolicyTypes).includes(sop.components[0].type)
          : ''
      );
      const { onSopTabChanged } = this.props;

      onSopTabChanged('policy');
    } else if (SopTabTitles.COST_TAB_TITLE === activeTabTitle) {
      filteredSops = sops.filter(sop =>
        sop && sop.components[0] !== undefined
          ? Object.values(SopCostTypes).includes(sop.components[0].type)
          : ''
      );
      const { onSopTabChanged } = this.props;

      onSopTabChanged('cost');
    }

    return (
      <SopCardContainer
        sops={filteredSops}
        timeTablesById={timeTablesById}
        siteEquipmentTypeIds={siteEquipmentTypeIds}
        onEditSop={this.handleGetSopIdFromSopCardContainer}
        sopId={this.state.sopId}
        sopType={sops.activeSopTab}
        site={site}
        orgId={this.props.orgId}
        activeTabTitle={activeTabTitle}
        {...match.params}
      />
    );
  };

  renderTimeTable = site => {
    this.props.onSopTabChanged('timetable');
    return <ShowSitePageTimeTables id={site.id} site={site} />;
  };

  renderHvacSopContainer = site => {
    this.props.onSopTabChanged('hvac');
    return <ShowSitePageHvacSops siteId={site.id} site={site} />;
  };

  renderTaskSopContainer = site => {
    this.props.onSopTabChanged('task');
    return <ShowSitePageTaskSops siteId={site.id} site={site} />;
  };

  handleGetSopTypeFromSopDetailTabs = val => {
    const {
      showNewOrEditPolicyModal,
      showNewOrEditCostModal,
      showNewTimeTableModal,
      showNewOrEditHvacSopModal,
      showNewOrEditTaskSopModal,
    } = this.props;

    this.setState({
      sopId: 'new',
      sopType: String(val),
    });

    if (val === 'policy') {
      showNewOrEditPolicyModal();
    } else if (val == 'cost') {
      showNewOrEditCostModal();
    } else if (val === 'timetable') {
      showNewTimeTableModal();
    } else if (val === 'hvac') {
      showNewOrEditHvacSopModal('new');
    } else if (val === 'task') {
      showNewOrEditTaskSopModal();
    }
  };

  handleGetSopIdFromSopCardContainer = val => {
    const {
      showNewOrEditPolicyModal,
      showNewOrEditCostModal,
      activeTab,
    } = this.props;

    this.setState({
      sopId: String(val),
    });

    if (activeTab === 'policy') {
      showNewOrEditPolicyModal();
    } else if (activeTab == 'cost') {
      showNewOrEditCostModal();
    }
  };
}

const mapStateToProps = (
  { app, sops, timeTables, equipment }: ApplicationState,
  { site, orgId }: Props
) => ({
  sitesSops: (sops.organizationUnitIdToSopIds[site.id] || [])
    .map(id => sops.sopsById[String(id)])
    .filter(s => !!s),
  orgLevelSops: (sops.organizationUnitIdToSopIds[orgId] || [])
    .map(id => sops.sopsById[String(id)])
    .filter(s => !!s)
    .filter(s => s.organizationUnitId === orgId),
  siteTimeTablesById: getObjectWithSelectedKeys(
    timeTables.timeTablesById,
    timeTables.timeTableIdsByParentId[site.id]
  ),
  orgTimeTablesById: getObjectWithSelectedKeys(
    timeTables.timeTablesById,
    timeTables.timeTableIdsByParentId[orgId]
  ),
  siteEquipmentTypeIds: [
    ...new Set(
      (equipment.equipmentIdsBySiteId[site.id] || [])
        .map(id => equipment.equipmentById[id]?.typeId)
        .filter(id => id)
    ),
  ],
  site: site,
  activeTab: sops.activeSopTab,
  getSopsIsLoading: sops.isLoading[Actions.GET_SOP_LOADING],
});

const mapDispatchToProps = (dispatch: any, { id, site, orgId }: Props) => ({
  load: () => {
    dispatch(getSopByOrganizationalUnitId(site.id));
    dispatch(getSopByOrganizationalUnitId(orgId));
    dispatch(getTimeTables(site.id));
    dispatch(getTimeTables(orgId));
    dispatch(getEquipmentBySiteId(site.id));
  },
  getSop: id => dispatch(getSop(id)),
  site: site,
  showNewOrEditCostModal: () => {
    dispatch(showNewOrEditCostSopModal());
  },
  showNewOrEditPolicyModal: () => {
    dispatch(showNewOrEditPolicySopModal());
  },
  showNewOrEditHvacSopModal: () => {
    dispatch(showNewOrEditHvacSopModal('new', site.id));
  },
  showNewOrEditTaskSopModal: () => {
    dispatch(showNewModal());
  },
  showNewTimeTableModal: () => {
    dispatch(showNewTimeTableSopModal());
  },
  onSopTabChanged: sopType => {
    dispatch(changeActiveSopTab(sopType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowSitePageSops);
