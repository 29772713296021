import {
  DoorValues,
  OperationalSample,
  Locale,
  DeviceState,
  SensorType,
  CurrentUser,
} from '@energybox/react-ui-library/dist/types';
import {
  formatEpochDate,
  createTemperatureString,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import isAfter from 'date-fns/isAfter';

import React from 'react';
import { connect } from 'react-redux';
import { getOperationalSamples } from '../../actions/operationalSamples';
import { ApplicationState } from '../../reducers';
import styles from './ValueChip.module.css';
import { DateTime } from 'luxon';

interface OwnProps {
  sensorId: string;
  updatedAt?: string;
  secondaryText?: string;
  types: SensorType[];
}

interface Props extends OwnProps {
  locale: Locale;
  getOperationalSamples: (updatedAt: string) => void;
  operationalSamples: OperationalSample[];
  deviceStatus?: DeviceState;
  user?: CurrentUser;
}

const renderSampleValue = (
  operationalSamples: OperationalSample[],
  type: SensorType[],
  locale: Locale,
  user: CurrentUser
): { date: string; value: string } => {
  const { measurementSystem, fullDateTimeFormat } = locale;
  let returnObject = {
    date: '',
    value: global.NOT_AVAILABLE as string,
  };

  if (operationalSamples.length === 0) return returnObject;

  // Let this cascade to the next if case for sensors with both temp and humidity.
  if (
    type.indexOf(SensorType.TEMPERATURE) > -1 ||
    operationalSamples.some(sample => sample.type === SensorType.TEMPERATURE)
  ) {
    const sensorReading = operationalSamples.find(
      ({ type }) => type === SensorType.TEMPERATURE
    );
    if (!sensorReading) return returnObject;
    returnObject.date = formatEpochDate(
      sensorReading.timestamp,
      fullDateTimeFormat
    );
    returnObject.value = createTemperatureString(
      Number(sensorReading.value),
      user
    );
  }

  if (type.indexOf(SensorType.HUMIDITY) > -1) {
    const sensorReading = operationalSamples.find(
      ({ type }) => type === SensorType.HUMIDITY
    );

    if (
      returnObject.value !== (global.NOT_AVAILABLE as string) &&
      sensorReading &&
      isDefined(sensorReading.value)
    ) {
      const parsedSensorValue = parseFloat(
        sensorReading.value.toString()
      ).toFixed(1);
      returnObject.value = `${returnObject.value} @ ${parsedSensorValue}%`;
      returnObject.date = formatEpochDate(
        sensorReading.timestamp,
        fullDateTimeFormat
      );
      return returnObject;
    }

    if (sensorReading) {
      returnObject.value = `${
        isDefined(sensorReading.value)
          ? sensorReading.value
          : global.NOT_AVAILABLE
      }%`;
      returnObject.date = formatEpochDate(
        sensorReading.timestamp,
        fullDateTimeFormat
      );
    }
  }

  if (type.indexOf(SensorType.BINARY) > -1) {
    const sensorReading = operationalSamples.find(
      ({ type }) => type === SensorType.BINARY
    );
    if (!sensorReading) return returnObject;
    returnObject.value = isDefined(sensorReading.value)
      ? DoorValues[String(sensorReading.value)]
      : global.NOT_AVAILABLE;
    returnObject.date = formatEpochDate(
      sensorReading.timestamp,
      fullDateTimeFormat
    );
  }
  // we return formatted String in the case temp does not cascade to humidity
  return returnObject;
};

class ValueChip extends React.Component<Props> {
  componentDidMount() {
    const { getOperationalSamples, updatedAt } = this.props;
    updatedAt && getOperationalSamples(updatedAt);
  }

  componentDidUpdate(prevProps) {
    const { getOperationalSamples, deviceStatus, updatedAt } = this.props;

    if (
      deviceStatus &&
      deviceStatus.onlineState &&
      (prevProps.deviceStatus || {}).timestamp !== deviceStatus.timestamp &&
      isAfter(
        new Date(deviceStatus.timestamp),
        new Date(updatedAt || (prevProps.deviceStatus || {}).timestamp)
      )
    ) {
      getOperationalSamples(deviceStatus.timestamp);
    }
  }

  render() {
    const {
      types,
      secondaryText,
      locale,
      operationalSamples,
      sensorId,
      user,
    } = this.props;

    if (user === undefined) return null;
    const valueObject = renderSampleValue(
      operationalSamples,
      types,
      locale,
      user
    );

    return (
      <span key={sensorId} className={styles.sensorIconAndTextGrouping}>
        <span className={styles.sensorTypeText}>
          <span title={valueObject.date ? valueObject.date : ''}>
            {valueObject.value}
          </span>
          {secondaryText && (
            <div className={styles.secondaryText}> {secondaryText}</div>
          )}
        </span>
      </span>
    );
  }
}

const mapStateToProps = (
  { app, operationalSamples, deviceStatusById }: ApplicationState,
  { sensorId }: OwnProps
) => ({
  locale: app.locale,
  user: app.currentUser,
  operationalSamples:
    operationalSamples.operationalSamplesBySensorId[sensorId] || [],
  deviceStatus: deviceStatusById[sensorId],
});

const ISOStringAddHours = (timestamp: string, hours: number) => {
  return DateTime.fromISO(timestamp)
    .plus({ hours: hours })
    .toString();
};

const mapDispatchToProps = (dispatch, { sensorId }: OwnProps) => ({
  getOperationalSamples: (updatedAt: string) =>
    dispatch(
      getOperationalSamples({
        sensorId: sensorId as string,
        from: ISOStringAddHours(updatedAt, -1),
        to: ISOStringAddHours(updatedAt, 1),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValueChip);
