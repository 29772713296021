import {
  Button,
  Modal,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  create,
  displayFormErrors,
  hideNewActuatorModal,
  updateField,
} from '../../actions/control_boards';
import EditActuatorForm from '../../components/EditActuatorForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/control_boards';
import { CreateNewText } from '../../types/global';
import { ApiError } from '../../utils/apiErrorFeedback';

interface OwnProps {
  equipmentId: number;
  preselectedControlBoardId?: number;
  siteId?: number | string;
  orgId?: number;
}

interface Props extends OwnProps {
  isVisible: boolean;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onCreate: () => void;
  formErrors: GenericErrors;
  fields: EditableFields;
  controlBoards: { [id: string]: ControlBoard };
  isLoading: boolean;
  apiError: ApiError;
  displayFormErrors: typeof displayFormErrors;
  formErrorsVisible: boolean;
}

class NewActuatorModal extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const { equipmentId, onChange } = this.props;

    if (prevProps.isVisible !== this.props.isVisible && this.props.isVisible) {
      onChange('equipmentId', equipmentId);
    }
  }

  onActuatorCreate = () => {
    const { formErrors, displayFormErrors, onCreate } = this.props;

    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate();
    }
  };

  onActuatorModalCancel = () => {
    this.props.onClose();
  };

  render() {
    if (!this.props.isVisible) return null;

    const {
      onClose,
      onChange,
      isLoading,
      formErrors,
      formErrorsVisible,
      fields,
      apiError,
      controlBoards,
      preselectedControlBoardId,
      siteId,
    } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={this.onActuatorModalCancel}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={this.onActuatorCreate}>
          Add
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
        <ModalTitle>{CreateNewText.ACTUATOR}</ModalTitle>
        <EditActuatorForm
          isNewActuator
          formErrorsVisible={formErrorsVisible}
          formErrors={formErrors}
          fields={fields}
          controlBoards={controlBoards}
          onChange={onChange}
          apiError={apiError}
          preselectedControlBoardId={preselectedControlBoardId}
          siteIdWithoutResourceId={siteId}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ controlBoards }: ApplicationState) => ({
  isVisible: controlBoards.showNewActuatorModal,
  controlBoards: controlBoards.controlBoardsById,
  ...controlBoards.editById['new'],
});

const mapDispatchToProps = (dispatch, { equipmentId, orgId }: OwnProps) => ({
  onClose: () => dispatch(hideNewActuatorModal()),
  onChange: (field: string, value: string) =>
    dispatch(updateField('new', field, value)),
  onCreate: () => dispatch(create(equipmentId, orgId)),
  displayFormErrors: () => dispatch(displayFormErrors('new')),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewActuatorModal);
