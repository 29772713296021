import {
  FilterDropdown,
  FilterDropdownTextItem,
  PageSelectControl,
  SearchBox,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { SiteEdmData } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import theme from '../../../theme';
import React, { useRef, useState } from 'react';
import {
  PageContentHeader,
  FixedContentWrapper,
} from '../../../components/Page';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { Placeholder } from '../../../types/global';
import { useUrlState } from '../../../hooks/utils';
import FiltersContainer from '../../../components/Filters/FiltersContainer/FiltersContainer';
import EquipmentMenuFilter from '../../../components/Filters/EquipmentMenuFilter';
import SiteFilter from '../../../components/SiteFilter';
import UploadBillModal from './UploadBillModal';
import styles from './EdmTable.module.css';
import EdmSiteCardData from './EdmSiteCardData';
import useSiteFilter from '../../../hooks/useSiteFilter';
import { getCustomPageRowsNumbers } from '@energybox/react-ui-library/dist/utils/pagination';
import { DEFAULT_PAGINATION_ROWS_COUNT } from '../../../constants/pagination';
import SiteGroupFilter from '../../../components/SiteGroupFilter';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import useDynamicFilter from '../../../hooks/useDynamicFilter';
import usePaginationFilter from '../../../hooks/usePaginationFilter';

type Props = {
  sitesData: [string, SiteEdmData][];
  setIsUploadExists: React.Dispatch<React.SetStateAction<boolean>>;
};

const EdmTable = ({ sitesData, setIsUploadExists }: Props) => {
  const [uploadSiteId, setUploadSiteId] = useState<number | undefined>(
    undefined
  );
  const isMobile = ViewportTypes.MOBILE;

  const [query, setQuery] = useUrlState<string>('query', '');
  const { selectedSiteFilters } = useSiteFilter();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();
  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    sitesData?.length
  );

  const setCurrentPage = (currentPage, currentRowLimit) => {
    if (currentPage === 1) {
      setPagination(currentPage, DEFAULT_PAGINATION_ROWS_COUNT);
    } else {
      setPagination(currentPage, currentRowLimit);
    }
  };

  const quickSearchfilteredData =
    query && query?.length >= 3
      ? sitesData?.filter(([id, siteData]) =>
          hasSubstr(
            `${siteData.site_info.site_title}${siteData.site_info.site_address}`,
            query
          )
        )
      : sitesData;

  const filterBySites = quickSearchfilteredData.filter(([id, siteData]) => {
    if (selectedSiteFilters.length === 0) return true;
    return selectedSiteFilters.includes(+id);
  });

  const siteInfoReportData = filterBySites.filter(([id, siteData]) => {
    if (selectedSiteFilters.length === 0) return true;
    return selectedSiteFilters.includes(+id);
  });

  const handleSearchChange = (query: string) => {
    setQuery(query);
  };

  const { selectedFilters: equipmentTypeFilters } = useDynamicFilter<number>(
    'eqType',
    value => parseInt(value)
  );

  const startIndex = (currentPage - 1) * rowLimit;
  const endIndex = startIndex + rowLimit;

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToContainer = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const pageRows = getCustomPageRowsNumbers(siteInfoReportData.length);
  const filterDropdownTextItems = pageRows.map(number => (
    <FilterDropdownTextItem
      key={`pageRows${number}`}
      title={number}
      onClick={() => {
        setPagination(1, number);
      }}
      className={styles.rowLimitDropdown}
      closeOnClick
    />
  ));
  const tooltipDescription = `This page allows you to view the requirements for Energybox’s demand 
  management solution. Errors must be resolved to enable DemandManager at your site, warnings are suggestions
  on how to increase DM savings.`;

  const moreInfo = (
    <div className={styles.moreInfoText}>
      <div className={styles.subHead}>What is DemandManager Report?</div>
      <div>
        Energybox’s DemandManager Report solution reduces peak demand charges by
        optimizing HVAC setpoints. On peak days we reduce demand by precooling
        the space prior to the peak (1-2 hours) then increasing the setpoints
        during the peak (max 2 hours).
      </div>
      <div className={styles.subHead}>
        {' '}
        What is the DemandManager Health Bar?
      </div>
      <div>
        There are multiple conditions that must be met to achieve DemandManager
        savings. This page is a tool to ensure basic DemandManager requirements
        are met. Errors mean DemandManager is not currently active at the site,
        and mean an DemandManager health score of zero. Warnings are possible
        improvements to increase the effectiveness of DemandManager. Not all
        warnings need to be followed, but resolving them where possible will
        increase savings.
      </div>
    </div>
  );

  return (
    <div>
      <FixedContentWrapper>
        <div className={styles.mainHeader}>
          <Tooltip
            underline={false}
            arrowDirection="bottomRight"
            simpleContent={{
              title: 'DemandManager Report',
              description: tooltipDescription,
              moreInfo: moreInfo,
            }}
            isClickable
          >
            <span className={styles.mainHeaderText}>
              DemandManager Overview
            </span>
          </Tooltip>
          <PageContentHeader>
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={siteInfoReportData?.length === 0}
            />
          </PageContentHeader>
        </div>
        <div className={styles.filterSection} ref={containerRef}>
          <FiltersContainer>
            <SiteFilter />
            <SiteGroupFilter />
            <EquipmentMenuFilter label="Equipment Type" isOrglevelHvac={true} />
          </FiltersContainer>
          <div className={styles.pageSelectWrapper}>
            <div className={styles.alignPageSelectionEl}>
              <>
                Show Rows
                <FilterDropdown
                  title={rowLimit}
                  variant="select"
                  size="tiny"
                  className={styles.rowLimitDropdown}
                >
                  {filterDropdownTextItems}
                </FilterDropdown>
              </>
              {siteInfoReportData.length > 0 && (
                <PageSelectControl
                  hidePageNumbers={true}
                  pageCount={Math.ceil(siteInfoReportData.length / rowLimit)}
                  currentPage={currentPage}
                  setCurrentPage={currentPage => {
                    setCurrentPage(currentPage, rowLimit);
                  }}
                ></PageSelectControl>
              )}
            </div>
          </div>
        </div>
      </FixedContentWrapper>
      <TableHeaders />
      {!siteGroupWithoutSites &&
        siteInfoReportData?.length > 0 &&
        siteInfoReportData.slice(startIndex, endIndex).map(([id, siteData]) => {
          return (
            <EdmSiteCardData
              key={`EdmTableRow${id}`}
              siteEdmData={siteData || []}
              siteId={Number(id)}
              setUploadSiteId={setUploadSiteId}
              equipmentTypeFilters={equipmentTypeFilters}
            />
          );
        })}
      <div className={styles.pagination}>
        {!siteGroupWithoutSites && siteInfoReportData?.length > 0 && (
          <PageSelectControl
            pageCount={Math.ceil(siteInfoReportData?.length / rowLimit)}
            currentPage={currentPage}
            setCurrentPage={currentPage => {
              setCurrentPage(currentPage, rowLimit);
              scrollToContainer();
            }}
          ></PageSelectControl>
        )}
      </div>
      {uploadSiteId && (
        <UploadBillModal
          siteId={uploadSiteId}
          setUploadSiteId={setUploadSiteId}
          siteInfoReportData={siteInfoReportData}
          setIsUploadExists={setIsUploadExists}
        />
      )}
    </div>
  );
};

const TableHeaders = () => {
  return (
    <React.Fragment>
      <div className={styles.headerSection}>
        <div className={styles.tableHeader}>Site Name</div>
        <div className={styles.tableHeader}>Historical Data</div>
        <div className={styles.tableHeader}>Last Uploaded Bill</div>
        <div className={styles.tableHeader}>SOP Title</div>
        <div className={styles.equipmentColumns}>
          <div className={styles.tableHeader}>Equipment</div>
          <div className={styles.tableHeader}>Control Mode</div>
        </div>
        <div className={styles.tableHeader}>DemandManager Health</div>
      </div>
    </React.Fragment>
  );
};

export default EdmTable;
