import {
  TemperatureUnit
} from '@energybox/react-ui-library/dist/types';
import { InputField } from '@energybox/react-ui-library/dist/components';
import React, { useState } from 'react';
import convertTemp from '../../utils/temperature';
import { atMostOneDecimalRegex, isNumber } from '../../validators';
import styles from './TemperatureField.module.css';
import { useTemperatureUnit } from '../../hooks/utils';
import { parseValueForDisplay } from '@energybox/react-ui-library/dist/utils';

type Props = {
  onChange: (value: any) => void;
  temperature: number;
  error: boolean;
  customErrorText?: string;
};

const TemperatureField = ({
  onChange,
  temperature,
  error,
  customErrorText,
}: Props) => {
  const temperatureUnit = useTemperatureUnit();
  const isImperial = temperatureUnit === TemperatureUnit.F;

  const [primedTemperature, setPrimedTemperature] = useState(
    parseValueForDisplay(temperatureUnit, temperature)
  );

  return (
    <>
      <div className={styles.inputWithIcon}>
        <div>
          {/* this input allows any inputs,
            and then performs validation onBlur.
            Not ideal, but due to constant conversion between F/C
            and too many edge cases to cover
            */}
          <InputField
            className={styles.inputField}
            id="temperature"
            name="temperature"
            value={primedTemperature}
            onChange={e => {
              const { value } = e.currentTarget;
              if (value.match(atMostOneDecimalRegex) === null) {
                return;
              }
              setPrimedTemperature(value);
            }}
            onBlur={() => {
              if (primedTemperature === '-') {
                onChange(primedTemperature);
              } else if (primedTemperature === '' || isNaN(primedTemperature)) {
                setPrimedTemperature(0);
                let v = 0;
                v = isImperial ? Number(convertTemp(v, 'f', 2)) : v;
                v = isNumber(v) ? Number(v.toFixed(2)) : v;
                onChange(v);
              } else {
                let v = parseFloat(primedTemperature);
                v = isImperial ? Number(convertTemp(v, 'f', 2)) : v;
                v = isNumber(v) ? Number(v.toFixed(2)) : v;
                onChange(v);
              }
            }}
            error={error}
            customErrorText={customErrorText}
          />
        </div>
        {temperatureUnit}
      </div>
    </>
  );
};

export default TemperatureField;
