import * as R from 'ramda';
import { Actions } from '../actions/operationalSamples';
import { OperationalSample } from '@energybox/react-ui-library/dist/types';
import {
  mapValues,
  normalizeTSOperationalDataBySensorId,
  OperationalSampleFromApiResponse,
} from '@energybox/react-ui-library/dist/utils';

export interface OperationalSamples {
  operationalSamplesBySensorId: OperationalSamplesBySensorId;
  isLoading: boolean;
}

export type OperationalSamplesBySensorId = {
  [id: string]: OperationalSample[];
};

export const initialState = {
  operationalSamplesBySensorId: {},
  isLoading: false,
};

// Storing samples most recent first -> least recent last.
const operationalSamples = (
  state: OperationalSamples = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.GET_OPERATIONAL_SAMPLES_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.GET_OPERATIONAL_SAMPLES_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['operationalSamplesBySensorId', action.id],
          mapValues(
            normalizeTSOperationalDataBySensorId(action.data, action.id),
            OperationalSampleFromApiResponse
          )
        ),
        R.assoc('isLoading', false)
      )(state);

    case Actions.GET_OPERATIONAL_SAMPLES_ERROR:
      return R.assoc('isLoading', false, state);

    default:
      return state;
  }
};

export default operationalSamples;
