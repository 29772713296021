import { WizardCard } from '@energybox/react-ui-library/dist/components';
import { TemperatureSensor as IconTemperatureSensor } from '@energybox/react-ui-library/dist/icons';
import {
  ReportType,
  ReportTypeLabel,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import styles from './SelectReportType.module.css';

type Props = {
  onReportTypeClick: (reportType: ReportType) => void;
};

type ReportTypeInfo = {
  key: ReportType;
  title: string;
  icon: React.ReactNode;
  description: string;
};

const reportTypesInfo: ReportTypeInfo[] = [
  {
    key: ReportType.DAILY_TEMPERATURE_REPORT,
    title: ReportTypeLabel[ReportType.DAILY_TEMPERATURE_REPORT],
    icon: <IconTemperatureSensor size={80} />,
    description: 'Automate your daily temperature checks',
  },
];

const SelectReportType = ({ onReportTypeClick }: Props) => {
  return (
    <div className={styles.root}>
      {reportTypesInfo.map((reportType: ReportTypeInfo) => (
        <WizardCard
          key={reportType.key}
          title={reportType.title}
          description={reportType.description}
          onClick={() => onReportTypeClick(reportType.key)}
          icon={reportType.icon}
          className={styles.wizardCard}
        />
      ))}
    </div>
  );
};

export default SelectReportType;
