import {
  SensorType,
  Equipment,
  EquipmentApiFilter,
} from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/apiUtils';
import {
  setEquipmentFilter,
  setGetEnergyEquipmentBySiteIdUrl,
  createQueryString,
} from './equipment';

const apiBase = '/api/v1/equipment';

export const fetchEquipment = (
  filter?: EquipmentApiFilter
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: setEquipmentFilter(filter),
  });

export const fetchEnergyEquipmentBySiteId = (siteId): Promise<Equipment[]> =>
  fetchApi({
    endpoint: setGetEnergyEquipmentBySiteIdUrl(siteId),
  });

export const fetchEquipmentBySiteIdAndSensorType = (
  siteId: number | string,
  sensorTypes: SensorType[]
) => {
  const params = {
    sensorTypes,
  };

  return fetchApi({
    endpoint: `${apiBase}/by-sensors/site/${siteId}?${createQueryString(
      params
    )}`,
  });
};
