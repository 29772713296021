import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { useSubscribeToThermostat } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import {
  subscribeToDeviceReadings,
  unsubscribeFromDeviceReadings,
} from '../../../actions/streamApi';
import { DeviceStatusById } from '../../../reducers/deviceStatus';
import {
  createTemperatureString,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { global } from '@energybox/react-ui-library/dist/utils';
import {
  CurrentUser,
  Thermostat,
} from '@energybox/react-ui-library/dist/types';

type Props = {
  thermostat: Thermostat;
  user: CurrentUser | undefined;
};

const ThermostatCurrentTemperature: React.FC<Props> = ({
  thermostat,
  user,
}) => {
  const dispatch = useDispatch();

  const thermostatsById = useSelector(
    ({ subscribedThermostats }: ApplicationState) => {
      if (thermostat) {
        return subscribedThermostats.byId[thermostat.id];
      }
      return undefined;
    }
  );

  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);

  const subscribedThermostat = useSubscribeToThermostat(
    thermostat,
    () => thermostatsById,
    dispatch,
    subscribeToDeviceReadings,
    unsubscribeFromDeviceReadings
  );

  const isDeviceOnline =
    thermostat &&
    deviceStatusById &&
    deviceStatusById[thermostat.id]?.onlineState === true;
  const temperature = subscribedThermostat?.temperature;

  return (
    <>
      {isDefined(temperature) && isDeviceOnline && isDefined(user)
        ? createTemperatureString(temperature, user)
        : global.NOT_AVAILABLE}
    </>
  );
};

export default ThermostatCurrentTemperature;
