import React from 'react';
import styles from './Switch.module.css';
import { classNames } from '@energybox/react-ui-library/dist/utils';

type Props = {
  checked: boolean;
  onChange: any;
  id: string;
  disabled?: boolean;
};

const Switch: React.FC<Props> = ({ checked, onChange, id, disabled }) => (
  <label
    className={classNames(
      styles.switchWrapper,
      checked ? styles.switchOn : styles.switchOff,
      checked ? styles.active : null,
      disabled ? styles.disabled : null
    )}
    htmlFor={id}
  >
    <input
      className={classNames(styles.switchCheckbox)}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      id={id}
      disabled={disabled}
    />
  </label>
);

export default Switch;
