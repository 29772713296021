import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  TimetableOverrideType,
  TimetableOverrideTypeLabel,
} from '@energybox/react-ui-library/dist/types/TimeTable';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleTimePicker } from '../../actions/time_tables';

interface OwnProps {
  onSelect: (overrideType: string) => void;
  value?: string;
  error?: boolean;
  disabled?: boolean;
}

interface Props extends OwnProps {}

const SelectTimeTableOverrideType: React.FunctionComponent<Props> = ({
  error,
  disabled,
  onSelect,
  value,
}) => {
  const dispatch = useDispatch();
  const selectedItem = value => {
    switch (value) {
      case TimetableOverrideType.ALWAYS_ON:
        return TimetableOverrideTypeLabel.ALWAYS_ON;
      // case TimetableOverrideType.ALWAYS_OFF:
      //   return TimetableOverrideTypeLabel.ALWAYS_OFF;
      case TimetableOverrideType.AUTO:
        return TimetableOverrideTypeLabel.AUTO;
      default:
        return 'Select Override';
    }
  };
  const selectTypeItem = type => {
    onSelect(type);
    dispatch(toggleTimePicker(false));
  };

  return (
    <Select
      variant={'select'}
      onClick={() => dispatch(toggleTimePicker(true))}
      title={selectedItem(value)}
      error={error}
      disabled={disabled}
      fullWidth={false}
    >
      <SelectItem
        key="1"
        isSelected={value === TimetableOverrideType.ALWAYS_ON}
        onSelect={() => selectTypeItem(TimetableOverrideType.ALWAYS_ON)}
      >
        {TimetableOverrideTypeLabel.ALWAYS_ON}
      </SelectItem>
      {/*<SelectItem*/}
      {/*  isSelected={value === TimetableOverrideType.ALWAYS_OFF}*/}
      {/*  key="4"*/}
      {/*  onSelect={() => selectTypeItem(TimetableOverrideType.ALWAYS_OFF)}*/}
      {/*>*/}
      {/*  {TimetableOverrideTypeLabel.ALWAYS_OFF}*/}
      {/*</SelectItem>*/}
      <SelectItem
        key="5"
        isSelected={value === TimetableOverrideType.AUTO}
        onSelect={() => selectTypeItem(TimetableOverrideType.AUTO)}
      >
        {TimetableOverrideTypeLabel.AUTO}
      </SelectItem>
    </Select>
  );
};

export default SelectTimeTableOverrideType;
