import { WorkingModeLabel } from '@energybox/react-ui-library/dist/types';

// NEED TO CHANGE THE ANY TYPING IF POSSIBLE.
// WANTED TO EXTEND THIS TO MULTIPLE COMPONNETS AND AS THE DATA IS NOT COMMON IN EVERY COMPONNET,
// ANY TYPE IS USED FOR DATA.

export const filteredData = (
  data: any,
  query?: string,
  selectedSiteFilters?: number[],
  equipmentTypeFilters?: number[],
  controlModeFilters?: WorkingModeLabel[][],
  selectedSiteGroups?: string[],
  selectedSitesBySiteGroups?: number[],
  filterByQuickSearch?: (data: any) => any,
  filterBySites?: (data: any) => any,
  filterByEquipmentType?: (data: any) => any,
  filterByWorkingMode?: (data: any) => any,
  filterBySiteGroups?: (data: any, selectedSitesBySiteGroups) => any
) => {
  let filteredData = data;
  if (query && query.length >= 3 && filterByQuickSearch) {
    filteredData = filterByQuickSearch(filteredData);
  }

  if (selectedSiteFilters && selectedSiteFilters.length > 0 && filterBySites) {
    filteredData = filterBySites(filteredData);
  }

  if (
    equipmentTypeFilters &&
    equipmentTypeFilters.length > 0 &&
    filterByEquipmentType
  ) {
    filteredData = filterByEquipmentType(filteredData);
  }

  if (
    controlModeFilters &&
    controlModeFilters.length > 0 &&
    filterByWorkingMode
  ) {
    filteredData = filterByWorkingMode(filteredData);
  }

  if (selectedSiteGroups && selectedSiteGroups?.length && filterBySiteGroups) {
    filteredData = filterBySiteGroups(filteredData, selectedSitesBySiteGroups);
  }

  return filteredData;
};
