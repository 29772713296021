import {
  Button,
  Card,
  CardActions,
  CardContent,
} from '@energybox/react-ui-library/dist/components';
import { MutedTarget, Sentinel } from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSentinelsByResourceId,
  muteSentinel,
  resumeSentinel,
} from '../../actions/sentinels';
import { useSiteByResourceId } from '../../hooks/useSites';
import { ApplicationState } from '../../reducers';

import SentinelsTable, {
  SentinelTableColumns,
} from '../Sentinels/SentinelsTable';
import { DEFAULT_PAGINATION_ROWS_COUNT } from '../../constants/pagination';

const DEFAULT_SENTINELS_LIST: Sentinel[] = [];

interface OwnProps {
  id: string | number;
}

const sentinelTableColumns = [
  SentinelTableColumns.CONFIG,
  SentinelTableColumns.CONDITION,
  SentinelTableColumns.RECIPIENTS,
  SentinelTableColumns.MUTE_CURENT_TARRGET,
  // SentinelTableColumns.CHANNEL_TYPE,
];

function ShowEquipmentPageSentinels({ id: equipmentId }: OwnProps) {
  const dispatch = useDispatch();

  const site = useSiteByResourceId(equipmentId);
  const [shouldRefetchSentinels, setShouldRefetchSentinels] = useState(true);
  const sentinels = useSelector(
    ({ sentinels }: ApplicationState) =>
      sentinels.byResourceId[equipmentId] || DEFAULT_SENTINELS_LIST
  );
  const getSentinelsIsLoading = useSelector(
    ({ sentinels }: ApplicationState) =>
      sentinels.isLoadingByResourceId[equipmentId]
  );
  const pendingMuteTargetsUpdate = useSelector(
    ({ sentinels }: ApplicationState) => sentinels.pendingMuteTargetsUpdates
  );

  const initialMuteMappingBySentinelId: {
    [sentinelId: string]: {
      [targetId: string]: MutedTarget;
    };
  } = useMemo(() => {
    let mapping: {
      [sentinelId: string]: {
        [targetId: string]: MutedTarget;
      };
    } = {};

    sentinels.forEach((sentinel: Sentinel) => {
      mapping[`${sentinel.id}`] = mapArrayToObject(
        sentinel.mutedTargets || [],
        'targetId'
      );
    });
    return mapping;
  }, [sentinels]);

  const [mutedMappingBySentinelId, setMutedMappingBySentinelId] = useState(
    initialMuteMappingBySentinelId
  );
  const [unsavedSentinelIdsLookUp, setUnsavedSentinelIdsLookUp] = useState<{
    [sentinelId: string]: boolean;
  }>({});

  useEffect(() => {
    setMutedMappingBySentinelId(initialMuteMappingBySentinelId);
  }, [initialMuteMappingBySentinelId, setMutedMappingBySentinelId]);

  const muteTarget = useCallback(
    (sentinelId: number, mutedTarget: MutedTarget) => {
      setMutedMappingBySentinelId({
        ...mutedMappingBySentinelId,
        [sentinelId]: {
          [mutedTarget.targetId]: mutedTarget,
        },
      });
      if (!unsavedSentinelIdsLookUp[sentinelId]) {
        setUnsavedSentinelIdsLookUp({
          ...unsavedSentinelIdsLookUp,
          [sentinelId]: true,
        });
      }
    },
    [
      mutedMappingBySentinelId,
      setMutedMappingBySentinelId,
      unsavedSentinelIdsLookUp,
      setUnsavedSentinelIdsLookUp,
    ]
  );

  const unmuteTarget = useCallback(
    (sentinelId: number, mutedTarget: MutedTarget) => {
      const updatedMapping = { ...mutedMappingBySentinelId };
      const {
        [mutedTarget.targetId]: unmutedTarget,
        ...updatedMuteTargets
      } = updatedMapping[sentinelId];
      updatedMapping[sentinelId] = updatedMuteTargets;
      setMutedMappingBySentinelId(updatedMapping);

      if (!unsavedSentinelIdsLookUp[sentinelId]) {
        setUnsavedSentinelIdsLookUp({
          ...unsavedSentinelIdsLookUp,
          [sentinelId]: true,
        });
      }
    },
    [
      mutedMappingBySentinelId,
      setMutedMappingBySentinelId,
      unsavedSentinelIdsLookUp,
      setUnsavedSentinelIdsLookUp,
    ]
  );

  useEffect(() => {
    if (pendingMuteTargetsUpdate === 0) {
      setShouldRefetchSentinels(true);
    }
  }, [pendingMuteTargetsUpdate, setShouldRefetchSentinels]);

  useEffect(() => {
    if (!shouldRefetchSentinels) return;
    dispatch(
      getSentinelsByResourceId(equipmentId, {
        equipmentIds: [equipmentId],
      })
    );
    setShouldRefetchSentinels(false);
  }, [
    equipmentId,
    dispatch,
    shouldRefetchSentinels,
    setShouldRefetchSentinels,
  ]);

  const dataIsLoading = getSentinelsIsLoading || pendingMuteTargetsUpdate > 0;

  const unsavedSentinelIds = Object.keys(unsavedSentinelIdsLookUp);
  const hasUnsavedChanges = !!unsavedSentinelIds.length;

  const actions = (
    <>
      <Button
        variant="text"
        onClick={() => {
          setMutedMappingBySentinelId(initialMuteMappingBySentinelId);
          setUnsavedSentinelIdsLookUp({});
        }}
      >
        Cancel
      </Button>

      <Button
        onClick={() => {
          unsavedSentinelIds.forEach(sentinelId => {
            const updatedMutedTargetsByTargetId =
              mutedMappingBySentinelId[sentinelId];
            const updatedMutedMapping = Object.values(
              updatedMutedTargetsByTargetId
            );
            if (updatedMutedMapping.length === 0) {
              const currentlyMutedTargetByTargetId =
                initialMuteMappingBySentinelId[sentinelId];
              const unmuteTargetIds = Object.values(
                currentlyMutedTargetByTargetId
              ).map(target => target.targetId);
              dispatch(resumeSentinel(sentinelId, unmuteTargetIds));
            } else {
              dispatch(
                muteSentinel(sentinelId, {
                  mutedTargets: updatedMutedMapping,
                })
              );
            }
            setUnsavedSentinelIdsLookUp({});
          });
        }}
      >
        Save
      </Button>
    </>
  );
  return (
    <Card>
      <CardContent>
        <div style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }}>
          <SentinelsTable
            dataIsLoading={dataIsLoading}
            sentinels={sentinels}
            columns={sentinelTableColumns}
            renderLimit={DEFAULT_PAGINATION_ROWS_COUNT}
            mutedMappingBySentinelId={mutedMappingBySentinelId}
            selectedTargetId={equipmentId}
            muteTarget={muteTarget}
            unmuteTarget={unmuteTarget}
            ianaTimeZoneCode={site?.timeZone}
          />
        </div>
      </CardContent>
      {hasUnsavedChanges && <CardActions>{actions}</CardActions>}
    </Card>
  );
}

export default ShowEquipmentPageSentinels;
