import { WorkingModeLabel } from '@energybox/react-ui-library/dist/types';
import { useControlStatus } from '../../../hooks/streamApi/useControlStatus';
import { global } from '@energybox/react-ui-library/dist/utils';
import styles from './EditModeOffset.module.css';

type Props = {
  controlBoardId?: number;
};

const ControlBoardLiveMode: React.FC<Props> = ({ controlBoardId }) => {
  const controlStatus = useControlStatus(controlBoardId);
  const workingMode = controlStatus?.workingMode!;

  return workingMode ? (
    <div>{WorkingModeLabel[workingMode]}</div>
  ) : (
    <div className={styles.noData}>{global.NOT_AVAILABLE}</div>
  );
};

export default ControlBoardLiveMode;
