import { classNames } from '@energybox/react-ui-library/dist/utils';
import { GatewayLowBattery as GatewayLowBatteryIcon } from '@energybox/react-ui-library/dist/icons';

import React from 'react';
import styles from './ConditionDoor.module.css';
import StepConditionItem, {
  StepConditionDescription,
  StepConditionTitle,
} from './StepConditionItem';

function ConditionBatteryLevelCheck() {
  return (
    <React.Fragment>
      <StepConditionItem
        label={
          <span className={classNames(styles.icon)}>
            <GatewayLowBatteryIcon size={80} />
          </span>
        }
        condition={
          <div>
            <StepConditionTitle>Low Battery</StepConditionTitle>
            <StepConditionDescription>
              The sensor may stop sending signals any moment
            </StepConditionDescription>
          </div>
        }
        conditionAlignedCenter
      />
    </React.Fragment>
  );
}

export default ConditionBatteryLevelCheck;
