import {
  Actuator,
  ControlBoard,
  ObjectById,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { getMapFromArrayOneToOne } from '@energybox/react-ui-library/dist/utils/util';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActuatorsBySiteId,
  getControlBoardById,
} from '../actions/control_boards';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../actions/streamApi';
import { ApplicationState } from '../reducers';
import { controlBoardSelector } from '../selectors/controlBoards/controlBoards';

export const useControlBoardById = (
  controlBoardId: number | string | undefined
): ControlBoard | undefined => {
  const controlBoard = useSelector(controlBoardSelector(controlBoardId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isDefined(controlBoard) && isDefined(controlBoardId)) {
      dispatch(getControlBoardById(controlBoardId));
    }
  }, [controlBoard, controlBoardId]);
  return controlBoard;
};

export const useSelectControlBoardByEquipmentId = (
  equipmentId: string | number
): ControlBoard | undefined => {
  const actuators = useSelector<ApplicationState, Actuator[] | undefined>(
    state => state.controlBoards.actuatorsByEquipmentId[equipmentId]
  );
  let controlBoardForEquipment: ControlBoard | undefined;
  if (isDefined(actuators)) {
    if (actuators.length > 0) {
      // We can safely grab the first actuator because https://energybox.atlassian.net/browse/CTRL-1002?focusedCommentId=16241
      controlBoardForEquipment = actuators[0].controlBoard;
    }
  }

  return controlBoardForEquipment;
};

export const useActuatorsBySiteId = (
  siteId: number | string
): Actuator[] | undefined => {
  const dispatch = useDispatch();

  const actuators: Actuator[] | undefined = useSelector(
    ({ controlBoards }: ApplicationState) => {
      return controlBoards.actuatorsBySiteId[siteId];
    }
  );

  useEffect(() => {
    dispatch(getActuatorsBySiteId(siteId));
  }, [siteId]);
  return actuators;
};

export const useSiteActuatorsByEquipmentId = (siteId: number | string) => {
  const actuators = useActuatorsBySiteId(siteId);
  const actuatorsByEquipmentId: ObjectById<Actuator> = useMemo(() => {
    return getMapFromArrayOneToOne(actuators || [], 'equipmentId');
  }, [actuators]);
  return actuatorsByEquipmentId;
};

export const useControlBoardLiveData = (
  controlBoard: ControlBoard | undefined
) => {
  const dispatch = useDispatch();
  const liveData = useSelector(
    ({ subscribedControlBoardOutputStates }: ApplicationState) => {
      return subscribedControlBoardOutputStates;
    }
  );
  useEffect(() => {
    if (!controlBoard) return;
    dispatch(
      subscribeToDeviceStatus(
        controlBoard.vendor,
        controlBoard.uuid,
        controlBoard.id
      )
    );
  }, [controlBoard]);
  return liveData;
};

export const useActuatorsLiveData = (actuators: Actuator[] | undefined) => {
  const dispatch = useDispatch();
  const controlBoardsLiveOutputStates = useSelector(
    ({ subscribedControlBoardOutputStates }: ApplicationState) => {
      return subscribedControlBoardOutputStates;
    }
  );
  const controlBoardsLiveDataById = useSelector(
    ({ subscribedControlBoards }: ApplicationState) => {
      return subscribedControlBoards.byId;
    }
  );
  useEffect(() => {
    if (actuators === undefined) return;
    const alreadySubcribed: ObjectById<ControlBoard> = {};
    actuators.forEach(({ controlBoard }) => {
      const isSubscribed = alreadySubcribed[controlBoard.id] !== undefined;
      if (!isSubscribed) {
        alreadySubcribed[controlBoard.id] = controlBoard;
        dispatch(
          subscribeToDeviceStatus(
            controlBoard.vendor,
            controlBoard.uuid,
            controlBoard.id
          )
        );
      }
    });
    const subscribedControlBoards = Object.values(alreadySubcribed);
    return () => {
      subscribedControlBoards.forEach(controlBoard => {
        dispatch(
          unsubscribeFromDeviceStatus(
            controlBoard.vendor,
            controlBoard.uuid,
            controlBoard.id
          )
        );
      });
    };
  }, [actuators]);
  return {
    controlBoardsLiveDataById,
    controlBoardsLiveOutputStates,
  };
};
