import { Actions } from '../actions/records';
import * as R from 'ramda';

export interface Records {
  isLoading: boolean;
  recordsByResourceId: RecordsByResourceId;
}

export type RecordsByResourceId = {
  [id: number]: Record[];
};

type Record = {
  id: number;
  createdAt: string;
  userId: number;
  username: string;
};

export const initialState = {
  isLoading: false,
  recordsByResourceId: {},
};

const records = (state: Records = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_RECORDS_LOADING: {
      return R.pipe(R.assoc('isLoading', true))(state);
    }

    case Actions.GET_RECORDS_ERROR: {
      return R.pipe(R.assoc('isLoading', false))(state);
    }

    case Actions.GET_RECORDS_SUCCESS: {
      return R.pipe(
        R.assoc('isLoading', false),
        R.assocPath(['recordsByResourceId', action.resourceId], action.data)
      )(state);
    }

    case Actions.RESET_RECORDS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default records;
