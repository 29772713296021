import React from 'react';
import { IoIosAddCircle } from 'react-icons/io';

import styles from './AddRowButton.module.css';

interface Props {
  children: React.ReactNode;
  onClick: (e: React.FormEvent<HTMLButtonElement>) => void;
}

function handleClick(e, onClick) {
  e.preventDefault();

  onClick(e);
}

const AddRowButton = ({ children, onClick }: Props) => {
  return (
    <button
      type="button"
      className={styles.invisibleButton}
      onClick={e => handleClick(e, onClick)}
    >
      <div className={styles.accessButton}>
        <IoIosAddCircle size="20" />
        <span>{children}</span>
      </div>
    </button>
  );
};

export default AddRowButton;
