import { IoMdCheckmark } from 'react-icons/io';
import {
  ControlBoard,
  EnergyPro,
  Gateway,
  Locale,
  Firmware,
  FirmwareGatewayModel,
  FirmwareDeviceIdVendor,
  ReleaseTypeHumanReadable,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  Table,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  IconCheckboxContained,
  IconCheckboxOutlined,
} from '@energybox/react-ui-library/dist/icons';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { connect } from 'react-redux';
import { getFirmwares } from '../../actions/app';
import { updateFirmware } from '../../actions/gateways';
import { ApplicationState } from '../../reducers';
import styles from './UpdateBalenaFirmwareModal.module.css';
import { BalenaStatus, FirmwareRelease } from '../../reducers/balena';
import { fetchRelease, pushReleaseToDevice } from '../../actions/balena';

type OwnProps = {
  runningReleaseId?: number;
  device: Gateway | FirmwareDeviceIdVendor;
  firmwareGatewayModel: FirmwareGatewayModel;
  onClose: () => void;
  isVisible: boolean;
  serialNumber: string;
};

interface Props extends OwnProps {
  isLoading?: boolean;
  firmwareReleases: FirmwareRelease[];
  updateFirmware: (device: Gateway, filePath: string, version: string) => void;
  getFirmwares: typeof fetchRelease;
  updateFirmwareBalena: (
    release: string,
    releaseId: number,
    deviceId: string
  ) => void;
  userTimeFormat: string;
}

interface State {
  firmwareIdToUpdate: string;
}

class UpdateBalenaFirmwareModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firmwareIdToUpdate: '',
    };
  }

  onUpdateFirmwareModalClose = () => {
    this.props.onClose();
  };

  componentDidMount = () => {
    this.props.getFirmwares();
  };

  // renderDescription = (description: string) => {
  //   const limit = 36;
  //   if (description.length > limit) {
  //     return `${description.substring(0, limit)}...`;
  //   }
  //   return description;
  // }

  renderConfirmationButtons = (
    release: string,
    releaseId: number,
    serialNumber: string
  ) => {
    const { device, onClose, updateFirmwareBalena } = this.props;

    return (
      <div className={styles.confirmationContainer}>
        <span>Are you sure?</span>
        <span
          className={styles.confirmIcon}
          onClick={() => {
            this.setState({ firmwareIdToUpdate: '' });
            updateFirmwareBalena(release, releaseId, serialNumber);
            onClose();
          }}
        >
          <IconCheckboxOutlined size={16} color="var(--accent-base)" />
        </span>
        <span
          className={styles.cancelIcon}
          onClick={() => this.setState({ firmwareIdToUpdate: '' })}
        >
          <span className={styles.cancelIconText}>x</span>
        </span>
      </div>
    );
  };

  render() {
    const {
      serialNumber,
      firmwareReleases,
      onClose,
      isVisible,
      runningReleaseId,
      userTimeFormat,
    } = this.props;
    const { firmwareIdToUpdate } = this.state;
    if (!isVisible) return null;

    const highlightIndex = firmwareReleases.findIndex(
      ({ id }) => id === runningReleaseId
    );

    const timeFormat = 'dd/MM/yyyy '.concat(userTimeFormat);

    const columns: Columns<FirmwareRelease>[] = [
      {
        header: '',
        cellContent: ({ id }) =>
          id === runningReleaseId ? (
            <div className={styles.checkIconContainer}>
              <IconCheckboxContained className={styles.checkIcon} size={16} />
            </div>
          ) : (
            ''
          ),
        cellStyle: { display: 'flex', justifyContent: 'center' },
      },
      {
        header: 'Type',
        cellContent: () => 'Released',
      },
      {
        header: 'Build Version',
        cellContent: ({ raw_version }) => raw_version,
      },
      {
        header: 'Description',
        cellContent: ({ note }) => (
          <div className={styles.description}>{note}</div>
        ),
        width: '50',
      },
      {
        header: 'Date',
        cellContent: ({ created_at }) => (
          <span className={styles.date}>
            {format(parseISO(created_at), timeFormat)}
          </span>
        ),
      },
      {
        header: 'Action',
        align: 'center',
        cellContent: ({ id, raw_version }) =>
          firmwareIdToUpdate === String(id) ? (
            this.renderConfirmationButtons(raw_version, id, serialNumber)
          ) : (
            <Button
              onClick={() => this.setState({ firmwareIdToUpdate: String(id) })}
              variant="text"
              className={styles.updateFirmwareButton}
              disabled={id === runningReleaseId}
            >
              <span>Update Firmware</span>
              <span className={styles.updateFirmwareIcon}>
                <MdExitToApp size={16} />
              </span>
            </Button>
          ),
      },
    ];

    const actions = (
      <>
        <Button variant="text" onClick={this.onUpdateFirmwareModalClose}>
          Close
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose>
        <ModalTitle>Firmware Update</ModalTitle>
        <ModalContent>
          <div className={styles.tableContainer}>
            <Table
              data={firmwareReleases}
              columns={columns}
              highlightRowIndexes={[highlightIndex]}
              highlightAlternateRows
            />
          </div>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app, balena }: ApplicationState) => ({
  userTimeFormat: app.locale.timeFormat,
  firmwareReleases: balena.firmwareReleases,
});

const mapDispatchToProps = (
  dispatch: any,
  { firmwareGatewayModel }: OwnProps
) => ({
  getFirmwares: () => dispatch(fetchRelease()),
  updateFirmware: (
    device: Gateway | EnergyPro | ControlBoard | FirmwareDeviceIdVendor,
    filePath: string,
    version: string
  ) => {
    const { id, vendor } = device;
    dispatch(updateFirmware({ vendor, id: id.toString(), version, filePath }));
  },
  updateFirmwareBalena: (
    release: string,
    releaseId: number,
    deviceId: string
  ) => {
    dispatch(pushReleaseToDevice(release, releaseId, deviceId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateBalenaFirmwareModal);
