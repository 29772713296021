import React from 'react';

import GatewaysTable from '../Gateways/GatewaysTable';
import NewDeviceModal from '../Devices/NewDeviceModal';

interface OwnProps {
  id: string;
}

function ShowSitePageGateways(props: OwnProps) {
  return (
    <>
      <NewDeviceModal lockSiteId={parseInt(props.id)} />
      <GatewaysTable siteId={parseInt(props.id)} />
    </>
  );
}

export default ShowSitePageGateways;
