import {
  ControlBoard,
  Gateway,
  GatewayVendorLabel,
  PowerSupplyMaskHumanReadable,
} from '@energybox/react-ui-library/dist/types';
import {
  Card,
  CardContent,
  CardTitle,
  InfoChip,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { useAppLocale } from '../../hooks/useAppDetails';
import styles from './GatewayCommonCardStyle.module.css';
import { formatIanaTimeZone } from '@energybox/react-ui-library/dist/utils';

interface Props {
  gateway: Gateway | ControlBoard;
  ianaTimeZoneCode?: string;
}

const HardWareCard = ({ gateway, ianaTimeZoneCode }: Props) => {
  const locale = useAppLocale();
  return (
    <Card>
      <CardContent>
        <div className={styles.generalCardGrid}>
          <CardTitle className={styles.cardTitleExtraPadding}>
            Hardware
          </CardTitle>
          <div>
            <InfoChip title="UUID" fields={[gateway && gateway.uuid]} />
            <InfoChip
              title="Created At"
              fields={[
                gateway &&
                  ianaTimeZoneCode &&
                  formatIanaTimeZone(
                    new Date(gateway.createdAt),
                    locale.dateTimeFormat,
                    ianaTimeZoneCode,
                    true
                  ),
              ]}
            />
          </div>
          <div>
            <InfoChip
              title="Hardware Version"
              fields={[
                gateway &&
                  gateway.gatewayInfo &&
                  gateway.gatewayInfo.hardwareVersion,
              ]}
            />
            <InfoChip
              title="Firmware Version"
              fields={[
                gateway &&
                  gateway.gatewayInfo &&
                  gateway.gatewayInfo.firmwareVersion,
              ]}
            />
          </div>
          <div>
            <InfoChip
              title="Vendor"
              fields={[gateway && GatewayVendorLabel[gateway.vendor]]}
            />
            <InfoChip
              title="Model"
              fields={[
                gateway &&
                  gateway.gatewayInfo &&
                  gateway.gatewayInfo.productType,
              ]}
            />
            <InfoChip
              title="Power Source"
              fields={[
                gateway &&
                  gateway.gatewayStatus &&
                  PowerSupplyMaskHumanReadable[
                    gateway.gatewayStatus.powerSupplyMask
                  ],
              ]}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default HardWareCard;
