const apiBase = '/api/v1/sop';

export enum Actions {
  TOGGLE_NEW_MODAL = '@taskSop/TOGGLE_NEW_MODAL',
  TOGGLE_DELETE_MODAL = '@taskSop/TOGGLE_DELETE_MODAL',
  TOGGLE_EDIT_MODAL = '@taskSop/TOGGLE_EDIT_MODAL',

  CREATE_TASK_SOP_SUCCESS = '@taskSop/CREATE_TASK_SOP_SUCCESS',
  CREATE_TASK_SOP_LOADING = '@taskSop/CREATE_TASK_SOP_LOADING',
  CREATE_TASK_SOP_ERROR = '@taskSop/CREATE_TASK_SOP_ERROR',

  GET_ALL_TASK_SOP_SUCCESS = '@taskSop/GET_ALL_TASK_SOP_SUCCESS',
  GET_ALL_TASK_SOP_LOADING = '@taskSop/GET_ALL_TASK_SOP_LOADING',
  GET_ALL_TASK_SOP_ERROR = '@taskSop/GET_ALL_TASK_SOP_ERROR',

  UPDATE_TASK_SOP_SUCCESS = '@taskSop/UPDATE_TASK_SOP_SUCCESS',
  UPDATE_TASK_SOP_LOADING = '@taskSop/UPDATE_TASK_SOP_LOADING',
  UPDATE_TASK_SOP_ERROR = '@taskSop/UPDATE_TASK_SOP_ERROR',
}

export const showNewModal = () => ({
  type: Actions.TOGGLE_NEW_MODAL,
  value: true,
});

export const hideNewModal = () => ({
  type: Actions.TOGGLE_NEW_MODAL,
  value: false,
});

export const createTaskSop = payload => ({
  type: 'API_POST',
  path: `${apiBase}`,
  payload,
  success: { type: Actions.CREATE_TASK_SOP_SUCCESS },
  error: { type: Actions.CREATE_TASK_SOP_ERROR },
  loading: { type: Actions.CREATE_TASK_SOP_LOADING },
});

export const updateTaskSop = (id, payload) => ({
  type: 'API_PUT',
  path: `${apiBase}/${id}`,
  payload,
  loading: { type: Actions.UPDATE_TASK_SOP_LOADING },
  success: { type: Actions.UPDATE_TASK_SOP_SUCCESS },
  error: { type: Actions.UPDATE_TASK_SOP_ERROR, id },
});

export const getAllTaskSop = () => ({
  type: 'API_GET',
  path: `${apiBase}`,
  success: Actions.GET_ALL_TASK_SOP_SUCCESS,
  error: Actions.GET_ALL_TASK_SOP_ERROR,
  loading: Actions.GET_ALL_TASK_SOP_LOADING,
});
