import {
  Button,
  Checkbox,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  BaseEntity,
  EntityByOwnId,
  EntityListByResourceId,
  IdListMapping,
  MutedTarget,
  ResourceType,
  Sentinel,
  SentinelTarget,
  SentinelTargetType,
  sentinelTypeToSensorType,
  Site,
  SiteWithSentinelTargetsData,
} from '@energybox/react-ui-library/dist/types';
import {
  capitalizeFirstLetterOnly,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnergyProsBySiteId } from '../../../actions/energy_pros';
import { getEquipmentBySiteId } from '../../../actions/equipment';
import { getGatewaysBySiteId } from '../../../actions/gateways';
import { getNetworkGroupBySiteId } from '../../../actions/network_groups';
import { getSensorsBySiteId } from '../../../actions/sensors';
import { muteSentinel, resumeSentinel } from '../../../actions/sentinels';
import { getSitesBySentinel } from '../../../actions/sites';
import { getSpacesBySiteId } from '../../../actions/spaces';
import ResourcePath from '../../../containers/ResourcePath';
import { useSearchFilter } from '../../../hooks/utils';
import { ApplicationState } from '../../../reducers';
import {
  energyProIdsBySiteIdSelector,
  energyProsByIdSelector,
  energyProsLoadingBySiteIdSelector,
} from '../../../selectors/energy_pros/energy_pros';
import {
  equipmentByIdSelector,
  equipmentIdsBySiteIdSelector,
  equipmentLoadingBySiteIdSelector,
} from '../../../selectors/equipment/equipment';
import {
  gatewayIdsBySiteIdSelector,
  gatewaysByIdSelector,
  gatewaysLoadingBySiteIdSelector,
} from '../../../selectors/gateways/gateways';
import {
  networkGroupIdsBySiteIdSelector,
  networkGroupsByIdSelector,
  networkGroupsLoadingBySiteIdSelector,
} from '../../../selectors/networkGroups/networkGroups';
import {
  sensorIdsBySiteIdSelector,
  sensorsByIdSelector,
  sensorsLoadingBySiteIdSelector,
} from '../../../selectors/sensors/sensors';
import { siteIdsBySentinelIdSelector } from '../../../selectors/sites/sites';
import {
  spaceIdsBySiteIdSelector,
  spacesByIdSelector,
  spacesLoadingBySiteIdSelector,
} from '../../../selectors/spaces/spaces';
import { SentinelTypeIcon } from '../../../utils/sentinel';
import {
  EquipmentChip,
  SensorChip,
  SpaceChip,
} from '../../SentinelWizard/steps/StepRollout/TargetChips';
import DurationPicker from './DurationPicker/DurationPicker';
import styles from './MuteModal.module.css';

type Props = {
  sentinel: Sentinel;
  onClose: () => void;
  targetTypes: SentinelTargetType[];
  currentSiteId?: number | string;
  currentTargetId?: number | string;
};

const MuteModal = ({
  sentinel,
  onClose,
  targetTypes,
  currentSiteId,
  currentTargetId,
}: Props) => {
  const dispatch = useDispatch();
  const [selectedTargetType, setSelectedTargetType] = useState(targetTypes[0]);

  const {
    data: sites,
  }: {
    data: Site[];
  } = useSelector(siteIdsBySentinelIdSelector)[sentinel.id] || { data: [] };

  const sentinelAPIMutedMapping: {
    [targetId: string]: MutedTarget;
  } = mapArrayToObject(sentinel.mutedTargets || [], 'targetId');

  const targetsById: {
    [targetId: string]: SentinelTarget;
  } = mapArrayToObject(sentinel.targets || [], 'targetId');

  const [mutedMapping, setMutedMapping] = useState(sentinelAPIMutedMapping);

  useSentinelTargetSitesFetch(sentinel);
  useSentinelTargetEntitiesFetch(sentinel, sites, selectedTargetType);

  const sentinelTargetsAreLoading = useTargetEntitiesLoadingStatus(
    sites,
    selectedTargetType
  );

  const targetedEntitiesBySiteId = useTargetEntities(
    sentinel,
    sites,
    selectedTargetType
  );

  const muteTarget = useCallback(
    (sentinelId: number | string, mutedTarget: MutedTarget) =>
      setMutedMapping({
        ...mutedMapping,
        [mutedTarget.targetId]: mutedTarget,
      }),
    [mutedMapping]
  );

  const unmuteTarget = useCallback(
    (sentinelId: number | string, mutedTarget: MutedTarget) => {
      const updatedMapping = {
        ...mutedMapping,
      };
      delete updatedMapping[mutedTarget.targetId];
      setMutedMapping(updatedMapping);
    },
    [mutedMapping]
  );

  const processedSites = useMemo(() => {
    return (
      (sites &&
        sites?.length > 0 &&
        sites
          ?.reduce((processedSites, site) => {
            // if a site is selected, only display selected site in mute modal.
            if (currentSiteId && site.id !== currentSiteId)
              return processedSites;
            const targetedEntities = targetedEntitiesBySiteId[site.id];
            if (!targetedEntities || targetedEntities.length === 0)
              return processedSites;

            let targets = targetedEntitiesBySiteId[site.id];
            // if a sentinel target (equipment, space, gateways, etc...) is selected
            // only display selected target in the expanded entry.
            if (currentTargetId) {
              targets = targets.filter(
                target => +target.id === +currentTargetId
              );
            }
            if (targets.length === 0) return processedSites;
            processedSites.push({
              ...site,
              sentinelTargetedEntities: targets,
            });
            return processedSites;
          }, [] as SiteWithSentinelTargetsData[])
          .sort((a, b) => a.title.localeCompare(b.title))) ||
      []
    );
  }, [sites, targetedEntitiesBySiteId, currentSiteId, currentTargetId]);

  const searchPathsArray = useMemo(() => [['title']], []);

  const { query, setQuery, filteredList: filteredSitesList } = useSearchFilter<
    SiteWithSentinelTargetsData
  >(processedSites, searchPathsArray);

  const sitesTargetsData: CardListRowData[] | undefined =
    (!sentinelTargetsAreLoading &&
      sites?.length &&
      filteredSitesList.map(site => {
        const row: CardListRowData = {
          key: `${site.id}MutedTargets`,
          content: (
            <>
              <Cell width="3">{site.title}</Cell>
              <Cell width="3" />
              <Cell width="3" />
              <Cell width="3" />
            </>
          ),
          extraContent: site.sentinelTargetedEntities.map(entity => {
            return (
              <React.Fragment key={`muteTarget${entity.id}`}>
                <Cell width="1" />
                <Cell width="2" />
                <Cell width="3">
                  {getSentinelTargetResourceChip(entity, selectedTargetType)}
                </Cell>
                <Cell width="3">
                  <ResourcePath
                    suppressLink
                    resourceId={entity.id}
                    resourceType={ResourceType.SPACE}
                  />
                </Cell>
                <Cell width="3">
                  {site.timeZone && (
                    <DurationPicker
                      disabled={sentinel.muted}
                      sentinelId={sentinel.id}
                      ianaTimeZoneCode={site.timeZone}
                      target={
                        targetsById[
                          extractTargetIdFromEntity(selectedTargetType, entity)
                        ]
                      }
                      mutedTarget={
                        mutedMapping[
                          extractTargetIdFromEntity(selectedTargetType, entity)
                        ]
                      }
                      muteTarget={muteTarget}
                      unmuteTarget={unmuteTarget}
                    />
                  )}
                </Cell>
              </React.Fragment>
            );
          }),
          startExpanded: true,
        };
        return row;
      })) ||
    undefined;

  const actions = (
    <>
      <Button variant="text" onClick={() => onClose()}>
        Cancel
      </Button>

      <Button
        onClick={() => {
          onClose();
          const updatedMutedMapping = Object.values(mutedMapping);
          if (updatedMutedMapping.length === 0) {
            const unmuteTargetIds = Object.values(sentinelAPIMutedMapping).map(
              target => target.targetId
            );
            dispatch(resumeSentinel(sentinel.id, unmuteTargetIds));
          } else {
            dispatch(
              muteSentinel(sentinel.id, {
                mutedTargets: Object.values(mutedMapping),
              })
            );
          }
        }}
      >
        Save
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <div className={styles.root}>
        <ModalTitle className={styles.modalHeader}>
          Mute Notifications
        </ModalTitle>
        <ModalContent className={styles.modalBody}>
          <div className={styles.modalBodyHeader}>
            <div className={styles.actionTargetType}>
              {targetTypes.map(t => (
                <Checkbox
                  key={t}
                  id={t}
                  name={t}
                  checked={selectedTargetType === t}
                  onChange={ev => setSelectedTargetType(ev.currentTarget.name)}
                  label={
                    t === SentinelTargetType.EDGEAPP
                      ? 'network groups'
                      : t.toLowerCase()
                  }
                />
              ))}
            </div>
            <SearchBox
              placeholder="Sites Search"
              onChange={setQuery}
              query={query}
              width="12rem"
              widthActive="12.5rem"
              error={filteredSitesList.length === 0}
            />
          </div>
          <div className={styles.contentHeader}>
            <div className={styles.sentinelTitleContainer}>
              <SentinelTypeIcon type={sentinel.sentinelType} size={16} />
              <div className={styles.sentinelTitle}>{sentinel.title}</div>
            </div>
          </div>
          {(sitesTargetsData && sitesTargetsData.length) ||
          sentinelTargetsAreLoading ? (
            sites?.length > 0 ? (
              <CardList
                header={[
                  {
                    width: '3',
                    content: 'Site',
                  },
                  {
                    width: '3',
                    content: resolveEntityLabel(selectedTargetType),
                  },
                  {
                    width: '3',
                    content: 'Location',
                  },
                  {
                    width: '3',
                    content: 'Mute Notifications',
                  },
                ]}
                data={sentinelTargetsAreLoading ? [] : sitesTargetsData}
              />
            ) : (
              <div className={styles.loader}>
                <Loader />
              </div>
            )
          ) : (
            <div className={styles.errorMessage}>
              There is no {resolveEntityLabel(selectedTargetType)} target for
              this sentinel.
            </div>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

const useSentinelTargetSitesFetch = (sentinel: Sentinel) => {
  const dispatch = useDispatch();

  const sites: Site[] | undefined = useSelector(siteIdsBySentinelIdSelector)[
    sentinel.id
  ];

  useEffect(() => {
    dispatch(getSitesBySentinel(sentinel));
  }, [sentinel, dispatch]);

  return sites;
};

const useSentinelTargetEntitiesFetch = (
  sentinel: Sentinel,
  sites: Site[] | undefined,
  targetType: SentinelTargetType
) => {
  const equipmentIdsBySiteId = useSelector(equipmentIdsBySiteIdSelector);
  const spaceIdsBySiteId = useSelector(spaceIdsBySiteIdSelector);
  const sensorIdsBySiteId = useSelector(
    ({ sensors }: ApplicationState) => sensors.sensorIdsBySiteId
  );
  const gatewayIdsBySiteId = useSelector(
    ({ gateways }: ApplicationState) => gateways.gatewayIdsBySiteId
  );
  const energyProIdsBySiteId = useSelector(
    ({ energyPros }: ApplicationState) => energyPros.energyProIdsBySiteId
  );
  const networkGroupIdsBySiteId = useSelector(
    ({ networkGroups }: ApplicationState) =>
      networkGroups.networkGroupIdsBySiteId
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const sensorTypes = sentinelTypeToSensorType(sentinel.sentinelType);
    switch (targetType) {
      case SentinelTargetType.EQUIPMENT:
        if (!sites) break;
        sites?.length &&
          sites.forEach(site => {
            if (equipmentIdsBySiteId[site.id] === undefined) {
              dispatch(getEquipmentBySiteId(site.id, sensorTypes));
            }
          });
        break;
      case SentinelTargetType.SPACE:
        if (!sites) break;
        sites?.length &&
          sites.forEach(site => {
            if (spaceIdsBySiteId[site.id] === undefined) {
              dispatch(getSpacesBySiteId(site.id, { sensorTypes }));
            }
          });
        break;
      case SentinelTargetType.SENSOR:
        if (!sites) break;
        sites?.length &&
          sites.forEach(site => {
            if (sensorIdsBySiteId[site.id] === undefined) {
              dispatch(getSensorsBySiteId(site.id, { sensorTypes }));
            }
          });
        break;
      case SentinelTargetType.GATEWAY:
        if (!sites) break;
        sites?.length &&
          sites.forEach(site => {
            if (gatewayIdsBySiteId[site.id] === undefined) {
              dispatch(getGatewaysBySiteId(site.id));
            }
          });
        break;
      case SentinelTargetType.ENERGYPRO:
        if (!sites) break;
        sites?.length &&
          sites.forEach(site => {
            if (energyProIdsBySiteId[site.id] === undefined) {
              dispatch(getEnergyProsBySiteId(site.id));
            }
          });
        break;
      case SentinelTargetType.EDGEAPP:
        if (!sites) break;
        sites?.length &&
          sites.forEach(site => {
            if (networkGroupIdsBySiteId[site.id] === undefined) {
              dispatch(getNetworkGroupBySiteId(site.id));
            }
          });
        break;
      default:
        break;
    }
  }, [
    sentinel,
    sites,
    targetType,
    equipmentIdsBySiteId,
    spaceIdsBySiteId,
    sensorIdsBySiteId,
    gatewayIdsBySiteId,
    energyProIdsBySiteId,
    networkGroupIdsBySiteId,
    dispatch,
  ]);
};

const useTargetEntities = (
  sentinel: Sentinel,
  sites: Site[] | undefined,
  selectedTargetType: SentinelTargetType
) => {
  const [targetEntitiesBySiteId, setTargetEnTitiesBySiteId] = useState<
    EntityListByResourceId
  >({});
  const equipmentIdsBySiteId = useSelector(equipmentIdsBySiteIdSelector);
  const equipmentById = useSelector(equipmentByIdSelector);

  const spaceIdsBySiteId = useSelector(spaceIdsBySiteIdSelector);
  const spacesById = useSelector(spacesByIdSelector);

  const sensorIdsBySiteId = useSelector(sensorIdsBySiteIdSelector);
  const sensorsById = useSelector(sensorsByIdSelector);

  const gatewayIdsBySiteId = useSelector(gatewayIdsBySiteIdSelector);
  const gatewaysById = useSelector(gatewaysByIdSelector);

  const energyProIdsBySiteId = useSelector(energyProIdsBySiteIdSelector);
  const energyProsById = useSelector(energyProsByIdSelector);

  const networkGroupIdsBySiteId = useSelector(networkGroupIdsBySiteIdSelector);
  const networkGroupsById = useSelector(networkGroupsByIdSelector);

  useEffect(() => {
    if (!sites || sites.length === 0) return;
    switch (selectedTargetType) {
      case SentinelTargetType.EQUIPMENT:
        const equipmentBySiteId = mapSentinelTargetEntitiesToSiteIds(
          sentinel,
          sites,
          selectedTargetType,
          equipmentById,
          equipmentIdsBySiteId
        );
        setTargetEnTitiesBySiteId(equipmentBySiteId);
        break;
      case SentinelTargetType.SPACE:
        const spacesBySiteId = mapSentinelTargetEntitiesToSiteIds(
          sentinel,
          sites,
          selectedTargetType,
          spacesById,
          spaceIdsBySiteId
        );
        setTargetEnTitiesBySiteId(spacesBySiteId);
        break;
      case SentinelTargetType.SENSOR:
        const sensorsBySiteId = mapSentinelTargetEntitiesToSiteIds(
          sentinel,
          sites,
          selectedTargetType,
          sensorsById,
          sensorIdsBySiteId
        );
        setTargetEnTitiesBySiteId(sensorsBySiteId);
        break;
      case SentinelTargetType.GATEWAY:
        const gatewaysBySiteId = mapSentinelTargetEntitiesToSiteIds(
          sentinel,
          sites,
          selectedTargetType,
          gatewaysById,
          gatewayIdsBySiteId
        );
        setTargetEnTitiesBySiteId(gatewaysBySiteId);
        break;
      case SentinelTargetType.ENERGYPRO:
        const energyProsBySiteId = mapSentinelTargetEntitiesToSiteIds(
          sentinel,
          sites,
          selectedTargetType,
          energyProsById,
          energyProIdsBySiteId
        );
        setTargetEnTitiesBySiteId(energyProsBySiteId);
        break;
      case SentinelTargetType.EDGEAPP:
        const networkGroupsBySiteId = mapSentinelTargetEntitiesToSiteIds(
          sentinel,
          sites,
          selectedTargetType,
          networkGroupsById,
          networkGroupIdsBySiteId,
          ['edge', 'id']
        );
        setTargetEnTitiesBySiteId(networkGroupsBySiteId);
        break;
    }
  }, [
    sites,
    selectedTargetType,
    sentinel,
    equipmentById,
    equipmentIdsBySiteId,
    spacesById,
    spaceIdsBySiteId,
    sensorsById,
    sensorIdsBySiteId,
    gatewaysById,
    gatewayIdsBySiteId,
    energyProsById,
    energyProIdsBySiteId,
    networkGroupsById,
    networkGroupIdsBySiteId,
  ]);

  return targetEntitiesBySiteId;
};

const useTargetEntitiesLoadingStatus = (
  sites: Site[] | undefined,
  selectedTargetType: SentinelTargetType
) => {
  const equipmentLoadingBySiteId = useSelector(
    equipmentLoadingBySiteIdSelector
  );
  const spacesLoadingBySiteId = useSelector(spacesLoadingBySiteIdSelector);
  const sensorsLoadingBySiteId = useSelector(sensorsLoadingBySiteIdSelector);
  const gatewaysLoadingBySiteId = useSelector(gatewaysLoadingBySiteIdSelector);
  const energyProsLoadingBySiteId = useSelector(
    energyProsLoadingBySiteIdSelector
  );
  const networkGroupsLoadingBySiteId = useSelector(
    networkGroupsLoadingBySiteIdSelector
  );

  if (!sites) return true;

  switch (selectedTargetType) {
    case SentinelTargetType.EQUIPMENT:
      if (equipmentLoadingBySiteId === undefined) return true;
      return sites.some(site => equipmentLoadingBySiteId[site.id]);
    case SentinelTargetType.SPACE:
      if (spacesLoadingBySiteId === undefined) return true;
      return sites.some(site => spacesLoadingBySiteId[site.id]);
    case SentinelTargetType.SENSOR:
      if (sensorsLoadingBySiteId === undefined) return true;
      return sites.some(site => sensorsLoadingBySiteId[site.id]);
    case SentinelTargetType.GATEWAY:
      if (gatewaysLoadingBySiteId === undefined) return true;
      return sites.some(site => gatewaysLoadingBySiteId[site.id]);
    case SentinelTargetType.ENERGYPRO:
      if (energyProsLoadingBySiteId === undefined) return true;
      return sites.some(site => energyProsLoadingBySiteId[site.id]);
    case SentinelTargetType.EDGEAPP:
      if (networkGroupsLoadingBySiteId === undefined) return true;
      return sites.some(site => networkGroupsLoadingBySiteId[site.id]);
    default:
      return false;
  }
};

export const mapSentinelTargetEntitiesToSiteIds = (
  sentinel: Sentinel,
  sites: Site[],
  selectedTargetType: SentinelTargetType,
  entityMapping: EntityByOwnId,
  idListMapping: IdListMapping,
  customEntityIdPath?: string[]
) => {
  const targetedEntitiesBySiteId: EntityListByResourceId = {};
  sites?.length &&
    sites.forEach(site => {
      const objectIdsBySiteId = idListMapping[site.id];
      if (objectIdsBySiteId === undefined) return;
      const targetedEntitiesIds = objectIdsBySiteId.filter(entityId =>
        sentinel.targets.some(({ targetId, targetType }) => {
          if (customEntityIdPath) {
            const childEntityId = pathOr(
              undefined,
              customEntityIdPath,
              entityMapping[entityId]
            );
            return (
              targetId === childEntityId && targetType === selectedTargetType
            );
          }
          return targetId === entityId && targetType === selectedTargetType;
        })
      );
      targetedEntitiesBySiteId[site.id] = targetedEntitiesIds.reduce(
        (result, entityId) => {
          const entity = entityMapping[entityId];
          if (entity) result.push(entity);
          return result;
        },
        [] as BaseEntity[]
      );
    });
  return targetedEntitiesBySiteId;
};

const getSentinelTargetResourceChip = (
  entity: BaseEntity,
  selectedTargetType: SentinelTargetType
) => {
  switch (selectedTargetType) {
    case SentinelTargetType.EQUIPMENT:
      return <EquipmentChip title={entity.title} />;
    case SentinelTargetType.SPACE:
      return <SpaceChip id="generic" title={entity.title} />;
    case SentinelTargetType.SENSOR:
    case SentinelTargetType.GATEWAY:
    case SentinelTargetType.ENERGYPRO:
    case SentinelTargetType.EDGEAPP:
      return <SensorChip title={entity.title} />;
  }
};

const extractTargetIdFromEntity = (
  targetType: SentinelTargetType,
  entity: BaseEntity
) => {
  switch (targetType) {
    case SentinelTargetType.EDGEAPP:
      const edgeId = pathOr(undefined, ['edge', 'id'], entity);
      if (edgeId === undefined) return entity.id;
      return edgeId;
    default:
      return entity.id;
  }
};

const resolveEntityLabel = (selectedTargetType: SentinelTargetType) => {
  switch (selectedTargetType) {
    case SentinelTargetType.EDGEAPP:
      return 'Network Group';
    default:
      return capitalizeFirstLetterOnly(selectedTargetType);
  }
};

export default MuteModal;
