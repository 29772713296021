import { Card } from '@energybox/react-ui-library/dist/components';
import { SiteEdmData } from '@energybox/react-ui-library/dist/types';
import { classNames, KW_UNIT } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { getErrorOrWarningIconForField } from '../../Site/SiteInspectionReport/InspectionTile';
import EdmHealthBar from './EdmHealthBar';
import styles from './EdmTable.module.css';
import RenderSopRow from './RenderSopRow';
import UploadBillData from './UploadBillData';

type Props = {
  siteEdmData: SiteEdmData;
  siteId: number;
  setUploadSiteId: (siteId: number | undefined) => void;
  equipmentTypeFilters: number[];
};
const EdmSiteCardData = ({
  siteEdmData,
  siteId,
  setUploadSiteId,
  equipmentTypeFilters,
}: Props) => {
  const sopIds = Object.keys(siteEdmData.by_sop);

  return (
    <Card className={styles.cardRow}>
      <React.Fragment>
        <div className={styles.tableData}>
          <div className={classNames(styles.tableCell, styles.displayBlock)}>
            <div className={styles.siteName}>
              {siteEdmData.site_info.site_title}
            </div>
            <div className={styles.siteAddress}>
              {siteEdmData.site_info.site_address}
            </div>
          </div>
          <div
            className={classNames(styles.displayBlock, styles.dataPaddingLeft)}
          >
            <div className={styles.historicalData}>
              <div>
                {Math.round(
                  Number(siteEdmData.historical_data.max_power.field) * 100
                ) / 100}{' '}
                {KW_UNIT}
              </div>
              {(siteEdmData.historical_data.overall_nday_available.error ||
                siteEdmData.historical_data.max_power.error) && (
                <div className={styles.dataPaddingLeft}>
                  {getErrorOrWarningIconForField({
                    error: siteEdmData.historical_data.overall_nday_available
                      .error
                      ? siteEdmData.historical_data.overall_nday_available.error
                      : siteEdmData.historical_data.max_power.error,
                  })}
                </div>
              )}
            </div>
            {siteEdmData.historical_data.overall_nday_available.field! < 31 &&
              siteEdmData.historical_data.overall_nday_available.field! >=
                0 && (
                <div className={styles.historicalWarning}>
                  <div className={styles.errorText}>
                    {siteEdmData.historical_data.overall_nday_available.field}{' '}
                    days of data is available
                  </div>
                  <div>Min 30 days data required.</div>
                </div>
              )}
          </div>
          <UploadBillData
            siteEdmData={siteEdmData}
            siteId={siteId}
            setUploadSiteId={setUploadSiteId}
          />
          <div>
            {Object.values(siteEdmData.by_sop).map(
              ({ sop_title, by_equipment, edm_enable }, index) => {
                const equipmentIds = Object.keys(by_equipment);
                const equipmentsInSop = Object.entries(by_equipment);
                const filteredEqTypes: string[] = [];
                equipmentTypeFilters.forEach(t => {
                  equipmentsInSop.forEach(([id, type]) => {
                    if (type.equipment_type_id === t) {
                      filteredEqTypes.push(id);
                    }
                  });
                });
                const filteredOrUniqueTypes = equipmentTypeFilters.length
                  ? filteredEqTypes
                  : equipmentIds;
                return (
                  <RenderSopRow
                    sop_title={sop_title}
                    by_equipment={by_equipment}
                    edm_enable={edm_enable}
                    index={index}
                    filteredOrUniqueTypes={filteredOrUniqueTypes}
                    sopIds={sopIds}
                    siteId={siteId}
                  />
                );
              }
            )}
          </div>
          <EdmHealthBar
            siteEdmData={siteEdmData}
            equipmentTypeFilters={equipmentTypeFilters}
          />
        </div>
      </React.Fragment>
    </Card>
  );
};
export default EdmSiteCardData;
