import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SiteActions,
  create,
  displayFormErrors,
  hideNewSiteModal,
  updateField,
} from '../../actions/sites';
import EditSiteForm from '../../components/EditSiteForm';
import { ApplicationState } from '../../reducers';
import { createFullAddress, EditableFields } from '../../reducers/sites';
import { CreateNewText } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface Props {
  isVisible: boolean;
  onCreate: () => void;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  fields: EditableFields;
  isLoading: boolean;
  apiError: ApiError;
  formErrors: GenericErrors;
  displayFormErrors: typeof displayFormErrors;
  formErrorsVisible: boolean;
}

function NewSiteModal(props: Props) {
  if (!props.isVisible) return null;
  const {
    onClose,
    onChange,
    onCreate,
    isLoading,
    displayFormErrors,
    formErrorsVisible,
    formErrors,
    fields,
    apiError,
  } = props;

  const onSiteCreate = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onChange('address', createFullAddress(fields));
      onCreate();
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onSiteCreate}>
        Create
      </Button>
    </>
  );

  return (
    <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
      <div style={{ width: '65rem' }}>
        <ModalTitle>{CreateNewText.SITE}</ModalTitle>
        <ModalContent>
          <EditSiteForm
            onChange={onChange}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
            {...fields}
            apiError={apiError}
          />
          {renderAPIerror(apiError, SiteActions.CREATE_SITE_ERROR)}
        </ModalContent>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ sites }: ApplicationState) => {
  return {
    isVisible: sites.showNewSiteModal,
    ...sites.editById['new'],
  };
};

const mapDispatchToProps = {
  onClose: hideNewSiteModal,
  onChange: (field: string, value: string) => updateField('new', field, value),
  onCreate: create,
  displayFormErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSiteModal);
