import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../actions/app';
import { determineInstallerRoleInPlatform } from '../utils/user';
import { useCurrentUser } from './useAppDetails';
import { useGetCurrentOrganization } from './useCurrentOrganization';

export const useInstallerAccess = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const currentOrg = useGetCurrentOrganization();
  const currentUser = useCurrentUser();

  return {
    installerAccess: determineInstallerRoleInPlatform(currentUser, currentOrg),
    baseOrgId: currentUser?.organizationId,
    currentOrganizationType: currentOrg?.organizationType,
  };
};
