import {
  Button,
  Modal,
  ModalContent,
} from '@energybox/react-ui-library/dist/components';
import {
  HvacControl,
  Scheduler,
  TemperatureControl,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearUpdateControlModeModalErrorMessage,
  hideUpdateControlModeModal,
} from '../../../actions/controls';
import {
  Actions as HvacControlActions,
  patchHvacControlWorkingMode,
} from '../../../actions/hvacControls';
import {
  Actions as SchedulerActions,
  patchSchedulerControlWorkingMode,
} from '../../../actions/schedulers';
import {
  Actions as TemperatureControlActions,
  patchTemperatureControlWorkingMode,
} from '../../../actions/temperature_controls';
import { useGetGatewaysByNetworkGroupById } from '../../../hooks/useGetGateways';
import useGetNetworkGroupById, {
  useIsNetworkGroupOnline,
} from '../../../hooks/useGetNetworkGroup';
import { useGetValidationError } from '../../../hooks/useGetValidationError';
import { ApiError, renderAPIerror } from '../../../utils/apiErrorFeedback';
import { determineOfflineGateways } from '../../../utils/gateway';
import styles from './UpdateControlModeModal.module.css';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../../../mixpanelEvents';

interface Props {
  equipmentId: string;
  isLocalOverrideActive: boolean | undefined;
  primedControlMode: WorkingMode;
  scheduler: Scheduler | undefined;
  temperatureControls: TemperatureControl | undefined;
  hvacControls: HvacControl | undefined;
  schedulerApiError: ApiError;
  temperatureApiError: ApiError;
  hvacApiError: ApiError;
  networkGroupId: number | undefined;
  controlBoardId: number | undefined;
}

const UpdateControlModeModal: React.FC<Props> = ({
  primedControlMode,
  equipmentId,
  isLocalOverrideActive,
  scheduler,
  temperatureControls,
  hvacControls,
  schedulerApiError,
  temperatureApiError,
  hvacApiError,
  networkGroupId,
  controlBoardId,
}) => {
  const dispatch = useDispatch();
  const isHvacControl = isDefined(hvacControls);

  const {
    isLoading: isNetworkGroupLoading,
    isOnline: isNetworkGroupOnline,
    edgeUuid,
  } = useIsNetworkGroupOnline(networkGroupId);

  const isNotValidNetworkGroup = !isDefined(networkGroupId);
  const isValidNetworkGroupOffline =
    isDefined(networkGroupId) && isNetworkGroupOnline === false;

  const gateways = useGetGatewaysByNetworkGroupById(networkGroupId);

  const offlineThermostats = [
    ...determineOfflineGateways(gateways, 'VenstarThermostat'),
    ...determineOfflineGateways(gateways, 'EnergyboxThermostat'),
  ];

  const offlineControlBoards = determineOfflineGateways(
    gateways,
    'ControlBoard'
  );

  const [ymlValidationError, setYmlValidationError] = useState([]);

  const { networkGroup } = useGetNetworkGroupById(networkGroupId);
  const serialNumber = networkGroup?.edge?.serialNumber!;

  const validationError = useGetValidationError(serialNumber)!;

  useEffect(() => {
    if (validationError?.length) {
      setYmlValidationError(validationError);
    }
  }, [validationError]);

  const onCancel = () => {
    dispatch(clearUpdateControlModeModalErrorMessage());
    dispatch(hideUpdateControlModeModal());
  };

  const actions = (
    <>
      <Button variant="text" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        disabled={isNotValidNetworkGroup || isValidNetworkGroupOffline}
        onClick={() => {
          if (hvacControls?.id) {
            dispatch(
              patchHvacControlWorkingMode(
                equipmentId,
                hvacControls.id,
                primedControlMode,
                edgeUuid,
                networkGroup?.edge?.id
              )
            );
            // Mixpanel tracking
            mixpanel.track(mixpanelEvents.CONTROL_MODE_CHANGE, {
              controlType: 'HVAC',
              equipmentId,
              hvacControlsId: hvacControls.id,
              from: hvacControls.workingMode,
              to: primedControlMode,
              source: 'connectEquipmentPage'
            });
          } else if (temperatureControls?.id) {
            dispatch(
              patchTemperatureControlWorkingMode(
                equipmentId,
                temperatureControls.id,
                primedControlMode,
                edgeUuid
              )
            );
            // Mixpanel tracking
            mixpanel.track(mixpanelEvents.CONTROL_MODE_CHANGE, {
              controlType: 'TEMPERATURE',
              equipmentId,
              temperatureControlsId: temperatureControls.id,
              from: temperatureControls.workingMode,
              to: primedControlMode,
              source: 'connectEquipmentPage'
            });
          } else if (scheduler?.id) {
            dispatch(
              patchSchedulerControlWorkingMode(
                equipmentId,
                scheduler.id,
                primedControlMode,
                edgeUuid
              )
            );
            // Mixpanel tracking
            mixpanel.track(mixpanelEvents.CONTROL_MODE_CHANGE, {
              controlType: 'SCHEDULER',
              equipmentId,
              schedulerId: scheduler.id,
              from: scheduler.workingMode,
              to: primedControlMode,
              source: 'connectEquipmentPage'
            });
          }
        }}
      >
        Update
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalContent className={styles.content}>
        <div>Are you sure you want to update this control mode?</div>

        {isLocalOverrideActive === true && !isHvacControl && (
          <div className={styles.pinkText}>
            Note: LOCAL OVERRIDE is still in effect
          </div>
        )}

        {!isNetworkGroupLoading && isNotValidNetworkGroup && (
          <div className={styles.pinkText}>
            Configuration Error: Please check Equipment Relay Mapping and
            corresponding Device/Network Group
          </div>
        )}

        {!isNetworkGroupLoading && isValidNetworkGroupOffline && (
          <div className={styles.pinkText}>Note: Network Group is offline</div>
        )}

        {isDefined(hvacControls) &&
          offlineThermostats
            .filter(t => t.id === hvacControls.thermostatId)
            .map(t => (
              <div key={t.id} className={styles.pinkText}>
                Note: Thermostat is offline:{' '}
                <span className={styles.bold}>{t.title}</span>
              </div>
            ))}

        {offlineControlBoards
          .filter(cb => cb.id === controlBoardId)
          .map(cb => (
            <div key={cb.id} className={styles.pinkText}>
              Note: SiteController is offline:{' '}
              <span className={styles.bold}>{cb.title}</span>
            </div>
          ))}

        {Object.values(schedulerApiError).length > 0 &&
          renderAPIerror(
            schedulerApiError,
            SchedulerActions.PATCH_SCHEDULER_CONTROL_MODE_ERROR
          )}
        {Object.values(temperatureApiError).length > 0 &&
          renderAPIerror(
            temperatureApiError,
            TemperatureControlActions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_ERROR
          )}
        {Object.values(hvacApiError).length > 0 &&
          renderAPIerror(
            hvacApiError,
            HvacControlActions.PATCH_HVAC_CONTROL_WORKING_MODE_ERROR
          )}

        {ymlValidationError?.length > 0 ? (
          <div className={styles.pinkText}>
            {ymlValidationError?.length
              ? `Configuration Warning: ${ymlValidationError}`
              : ''}
          </div>
        ) : (
          ''
        )}
      </ModalContent>
    </Modal>
  );
};

export default UpdateControlModeModal;
