import Tooltip from '@energybox/react-ui-library/dist/components/Tooltip';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import styles from './EditControlsHvacForm.module.css';

type Props = {
  hasError?: boolean;
  arrowDirection?:
    | 'left'
    | 'top'
    | 'topRight'
    | 'bottom'
    | 'bottomRight'
    | 'right'
    | 'closeBottom';
};

const EditControlsHvacFormAdjustabilityTooltip: React.FC<Props> = ({
  hasError,
  arrowDirection,
}) => {
  return (
    <Tooltip
      underline={false}
      disableAutoAdjust={isDefined(arrowDirection) ? true : false}
      arrowDirection={arrowDirection || 'left'}
      extraClassNames={[styles.tooltip]}
      simpleContent={{
        title: 'Local Adjustment',
        description:
          'Enable Local Adjustment to allow limited setting changes on site.',
      }}
    >
      <IoIosInformationCircleOutline
        color={hasError ? 'var(--pink-base)' : 'black'}
        size={20}
      />
    </Tooltip>
  );
};

export default EditControlsHvacFormAdjustabilityTooltip;
