import { useEffect, useRef, useState } from 'react';
import {
  isAllReady,
  isReady,
  isSent,
  isUploaded,
  isUploading,
  UploadFile,
  UploadFilePending,
  UploadFileReady,
  UploadFileSent,
  UploadFileUploaded,
  UploadStatus,
  UploadTracker,
} from '../../types/upload';

export const useUpload = <T extends object>(params: {
  uploadFromProp: UploadFile<T>;
  fileIdx: number;
  uploadDidChange;
  downloadFile;
}): {
  retry: () => void;
  upload: UploadFile<T>;
  uploadDidFinish: (uploaded: UploadFileUploaded<T>) => void;
  status: UploadStatus;
  uploadResult?: null | string;
  viewFile: (fileId: string) => Promise<void>;
} => {
  const { uploadFromProp, fileIdx, uploadDidChange, downloadFile } = params;
  const { pending, ready, uploading, failed, done } = UploadStatus;
  // pending: either startDate of endDate is not set
  // ready: both startDate and endDate are set, ready to start upload
  // uploading: Uploader component is rendered to start upload logic
  //            All actions are locked when ANY files are uploading
  // failed: upload failed in the process
  // success: upload succeeded and url of the uploaded file is set to "View File"

  const [upload, setUpload] = useState<UploadFile<T>>(uploadFromProp);

  const { file } = upload;
  const uploadResult = isSent(upload) ? upload.fileId : undefined;
  // Essentially unwrapping fileId from upload
  // and given an alias "uploadResult"

  const { name, size, type } = file;

  useEffect(() => {
    if (uploadFromProp) setUpload(uploadFromProp);
  }, [uploadFromProp]);

  const status: UploadStatus = (() => {
    switch (uploadResult) {
      case undefined: // undefined value indicates an upload is not yet initiated
        return isReady(upload) ? ready : pending;
      case null: // null value indicates upload in progress
        return uploading;
      case '': // empty string indicates upload failure
        return failed;
      default:
        // a trivial string value implies upload success
        return done;
    }
  })();

  const dim = [pending, failed].includes(status);

  const uploadDidFinish = (uploaded: UploadFileUploaded<T>) => {
    setUpload(uploaded);
    uploadDidChange(fileIdx, uploaded);
  };

  const [downloading, startDownload] = useState(false);
  const viewFile = async (fileId: string) => {
    if (!downloading) {
      startDownload(true);
      await downloadFile(fileId);
      startDownload(false);
    }
  };

  const retry = () => {
    const newUpload = {
      ...upload,
      status: UploadStatus.uploading,
      fileId: null,
    };
    setUpload(newUpload);
    uploadDidChange(fileIdx, newUpload);
  };

  return {
    retry,
    upload,
    uploadDidFinish,
    status,
    uploadResult,
    viewFile,
  };
};

export interface UseUploads<T extends object> {
  uploads: UploadFile<T>[];
  isSomeUploading: boolean;
  addUploads: (files: File[]) => void;
  removeUpload: (idx: number) => void;
  changeUpload: (idx: number, newUpload: UploadFile<T>) => void;
  batchUpload: () => void;
  clearUpload: () => void;
  isUnSavable: boolean;
}

export const useUploads = <T extends object>(
  initialMetadata?: Partial<T>
): UseUploads<T> => {
  const [uploads, setUploads] = useState<UploadFile<T>[]>([]);

  // Define utility functions and values on upload status monitor
  const uploadTracker = useRef<UploadTracker>({
    results: {},
    count: 0,
  });
  const isActualUploading = () => {
    const { results, count } = uploadTracker.current;
    return !!count && count !== Object.keys(results).length;
  };
  const [isSomeUploading, setIsSomeUploading] = useState<boolean>(
    isActualUploading()
  );
  // end

  const addUploads = (files: File[]) => {
    if (!files) return;
    const uploadFiles: UploadFile<T>[] = Object.keys(files).map(key => {
      return {
        file: files[key],
        ...(initialMetadata ?? {}),
      } as UploadFilePending<T>;
    });
    setUploads([...uploadFiles, ...uploads]);
  };

  const removeUpload = idx => setUploads(uploads.filter((_, i) => i !== idx));

  const changeUpload = (idx, newUpload: UploadFile<T>) => {
    let { results, count } = uploadTracker.current;

    if (!isSent(newUpload)) {
      // Changing startDate or endDate only, no upload result needs to be handled
      setUploads(uploads.map((file, i) => (i === idx ? newUpload : file)));
    } else if (isUploaded(newUpload)) {
      // Once upload finished
      results[idx] = newUpload.fileId;

      // Checking if all uploads finished
      if (!isActualUploading()) {
        // Batch update the uploads from uploadResults
        setUploads(
          uploads.map((u, i) =>
            i in results
              ? {
                  ...u,
                  fileId: results[i],
                }
              : u
          )
        );

        // reset upload status monitor
        uploadTracker.current = {
          results: {},
          count: 0,
        };
        setIsSomeUploading(false);
      }
    } else if (isUploading(newUpload)) {
      // Once retry upload is triggered
      if (idx in results) {
        // the upload is still ongoing
        delete results[idx]; // so just de-register the previous result
      } else {
        count++; // consider it a whole new upload
        setIsSomeUploading(true);
      }
    }
  };

  const start = (upload: UploadFileReady<T>): UploadFileSent<T> => {
    if (isUploaded(upload) || isUploading(upload)) return upload;
    else {
      uploadTracker.current.count++;
      return {
        ...upload,
        fileId: null,
      };
    }
  };

  const isUnSavable =
    !isAllReady(uploads) || // only all files are ready
    isSomeUploading || // Cannot start new upload when uploads are in progress
    uploads.filter(isReady).length === 0; // For UI: Disable button when there are no uploadable files at all

  const batchUpload = () => {
    if (isUnSavable) return;
    if (isAllReady(uploads)) {
      setUploads(uploads.map(start));
      setIsSomeUploading(true);
    }
  };

  const clearUpload = () => setUploads([]);

  return {
    uploads,
    isSomeUploading,
    addUploads,
    removeUpload,
    changeUpload,
    batchUpload,
    clearUpload,
    isUnSavable,
  };
};
