import React from 'react';

import NetworkGroupTable from '../NetworkGroups/NetworkGroupTable';
import NewNetworkGroupModal from '../NetworkGroups/NewNetworkGroupModal';
import NewDeviceModal from '../Devices/NewDeviceModal';

interface OwnProps {
  id: string;
}

class ShowSitePageNetworkGroups extends React.Component<OwnProps> {
  render() {
    return (
      <>
        <NewNetworkGroupModal lockSiteId={parseInt(this.props.id)} />
        <NewDeviceModal lockSiteId={parseInt(this.props.id)} />
        <NetworkGroupTable siteId={parseInt(this.props.id)} />
      </>
    );
  }
}

export default ShowSitePageNetworkGroups;
