import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import { Region } from '@energybox/react-ui-library/dist/types/Region';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSites } from '../../actions/sites';
import useSiteFilter from '../../hooks/useSiteFilter';
import { ApplicationState } from '../../reducers';

type Props = {
  onChange: (selectedRegions: Region[]) => void;
  selection: Region[];
};

const RegionFilter: React.FC<Props> = ({ onChange, selection }) => {
  return (
    <GenericFilter
      items={Object.values(Region)}
      setFilter={onChange}
      selectedItems={selection}
      title="EB Region"
    />
  );
};

export default RegionFilter;
