import { Actions as AppActions } from '../actions/app';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as SiteActions } from '../actions/sites';
import { Actions as UserActions } from '../actions/users';
import { Actions as SiteGroupActions } from '../actions/site_groups';
import { Actions as UserGroupActions } from '../actions/user_groups';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as DistributionPanelActions } from '../actions/distribution_panel';
import { Actions as EnergyProActions } from '../actions/energy_pros';
import { Actions as NetworkGroupActions } from '../actions/network_groups';
import { Actions as ThermostatActions } from '../actions/thermostats';

import { Routes } from '../routes';
import history from '../history';

const redirectMiddleware = store => next => action => {
  switch (action.type) {
    case AppActions.REDIRECT: {
      return history.push(action.path, {});
    }
    // TODO: This only works with the current delete schema, as the logic gets more complex this will have to change
    // Delete API calls can respond with "false" action.data. Therefore, must first check if action.data is true and entity was actually deleted from backend
    case SiteActions.DELETE_SITE_SUCCESS: {
      if (!!action.data) {
        history.push(Routes.SITES);
      }
      return next(action);
    }
    case EquipmentActions.DELETE_EQUIPMENT_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case UserActions.DELETE_USER_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case UserGroupActions.DELETE_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case SiteGroupActions.DELETE_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case GatewayActions.DELETE_GATEWAY_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case SensorActions.DELETE_SENSOR_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case NetworkGroupActions.DELETE_NETWORK_GROUP_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case NetworkGroupActions.PATCH_NETWORK_GROUP_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }
    case UserActions.USER_CHANGE_PASSWORD_SUCCESS: {
      history.push(`/`);
      return;
    }

    case DistributionPanelActions.CREATE_DISTRIBUTION_PANEL_SUCCESS: {
      history.push(`${Routes.DISTRIBUTION_PANELS}/${action.data.id}`);
      return next(action);
    }

    case EnergyProActions.DELETE_ENERGY_PRO_SUCCESS: {
      if (!!action.data && action.redirectAfter) {
        history.goBack();
      }
      return next(action);
    }

    case ThermostatActions.DELETE_THERMOSTAT_DEVICE_SUCCESS: {
      if (!!action.data) {
        history.goBack();
      }
      return next(action);
    }

    default:
      return next(action);
  }
};

export default redirectMiddleware;
