import { Site } from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  convertToSiteTz,
  formatApiDate,
  isDefined,
  renderAstroEventTime,
} from '@energybox/react-ui-library/dist/utils';
import { useAppLocale } from '../../hooks/useAppDetails';
import useSiteAstroClock from '../../hooks/useSiteAstroClock';
import {
  LiveClock,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { TimeAndLocation } from '@energybox/react-ui-library/dist/icons';
import { useIsMobileViewPort } from '../../hooks/useViewPort';
import styles from './SiteDateAndTimeInfo.module.css';

interface Props {
  className?: string;
  site?: Site;
}

const SiteDateAndTimeInfo = ({ className, site }: Props) => {
  const siteId = site?.id!;
  const timeZone = site?.timeZone;
  const siteDate = convertToSiteTz(new Date(), timeZone);
  const locale = useAppLocale();

  const astroClock = useSiteAstroClock(siteId, formatApiDate(siteDate));
  const formatString = `LLL dd, yyyy ${locale.timeFormat}`;

  const mobileViewPortBreakPoint = useIsMobileViewPort();
  const isClickableState = mobileViewPortBreakPoint ? true : false;

  return (
    <>
      <Tooltip
        isClickable={isClickableState}
        autoCloseDuration={5}
        arrowDirection="top"
        disableAutoAdjust
        content={
          <div className={styles.tooltipRoot}>
            <span className={styles.tooltipTitle}>Astronomical Clock</span>
            {isDefined(astroClock) ? (
              <>
                <div className={styles.row}>
                  <span>Dawn</span>
                  <span>{renderAstroEventTime(astroClock, 0, 'dawn')}</span>
                </div>
                <div className={styles.row}>
                  <span>Sunrise</span>
                  <span>{renderAstroEventTime(astroClock, 0, 'sunrise')}</span>
                </div>
                <div className={styles.row}>
                  <span>Sunset</span>
                  <span>{renderAstroEventTime(astroClock, 0, 'sunset')}</span>
                </div>
                <div className={styles.row}>
                  <span>Dusk</span>
                  <span>{renderAstroEventTime(astroClock, 0, 'dusk')}</span>
                </div>
              </>
            ) : (
              <span>Astronomical Clock data can't be retrieved.</span>
            )}
          </div>
        }
      >
        <div className={classNames(className, styles.contentRow)}>
          <div className={styles.timeAndLocationIcon}>
            <TimeAndLocation size="16" />
          </div>
          <LiveClock
            showTimezoneAbbreviation
            dateTimeFormat={formatString}
            timezone={timeZone}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default SiteDateAndTimeInfo;
