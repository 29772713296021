import {
  Button,
  FormText,
  NativeSelectField,
} from '@energybox/react-ui-library/dist/components';
import {
  initialSentinelTimeRange,
  ScheduleTimeRange,
  SentinelSchedule,
  SentinelScheduleType,
  SentinelScheduleWeekDay,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { MdAddCircle as PlusIcon, MdDelete as TrashIcon } from 'react-icons/md';
import FormGroup from '../../../../components/ui/FormGroup';
import TimeTableTimeInput from '../../../EditTimeTableForm/TimeTableTimeInput';
import ScheduleWeekDayButton from './ScheduleWeekDayButton';
import styles from './StepScheduleItem.module.css';

type Props = {
  schedule: SentinelSchedule;
  handleUpdateSchedule: (schedule: SentinelSchedule) => void;
  handleRemoveSchedule: () => void;
};

function StepScheduleItem(props: Props) {
  const { schedule, handleUpdateSchedule, handleRemoveSchedule } = props;
  const { scheduleType, weekDays } = schedule;

  return (
    <div className={styles.root}>
      <div>
        <FormGroup>
          <NativeSelectField
            value={scheduleType}
            onChange={(ev: any) =>
              handleUpdateSchedule({
                ...schedule,
                scheduleType: ev.target.value,
              })
            }
          >
            <option value={SentinelScheduleType.DAILY}>Daily</option>
            <option value={SentinelScheduleType.WEEKLY}>Weekly</option>
          </NativeSelectField>
        </FormGroup>
      </div>
      <div>
        {scheduleType === SentinelScheduleType.WEEKLY && (
          <FormGroup>
            {Object.keys(SentinelScheduleWeekDay).map((day: any) => (
              <ScheduleWeekDayButton
                key={`weeklyButton${day}`}
                onClick={() =>
                  handleUpdateSchedule({
                    ...schedule,
                    weekDays: weekDays.includes(day)
                      ? weekDays.filter(d => d !== day)
                      : [...weekDays, day],
                  })
                }
                isActive={weekDays.includes(day)}
                day={day}
              />
            ))}
          </FormGroup>
        )}

        <div className={styles.timeRanges}>
          {schedule.timeRanges.map((range: ScheduleTimeRange, index) => (
            <FormGroup key={`SentinelScheduleItemTimeRange${index.toString()}`}>
              <div className={styles.timeSelect}>
                {schedule.timeRanges.length > 1 && (
                  <div
                    onClick={() =>
                      handleUpdateSchedule({
                        ...schedule,
                        timeRanges: schedule.timeRanges.filter(
                          (t, i) => index !== i
                        ),
                      })
                    }
                  >
                    <TrashIcon size={14} className={styles.deleteIcon} />
                  </div>
                )}
                <div>
                  <TimeTableTimeInput
                    id={`begin${index}`}
                    name={'begin'}
                    value={range.begin}
                    type={'CLOCK'}
                    onChange={(fieldName, value) =>
                      handleUpdateSchedule({
                        ...schedule,
                        timeRanges: schedule.timeRanges.map((r, i) =>
                          i === index
                            ? {
                                begin: value,
                                end: range.end,
                              }
                            : r
                        ),
                      })
                    }
                  />
                </div>
                <div className={styles.timeSelectSeparator}>
                  <FormText>-</FormText>
                </div>
                <div>
                  <TimeTableTimeInput
                    id={`end${index}`}
                    name={'end'}
                    value={range.end}
                    type={'CLOCK'}
                    onChange={(fieldName, value) =>
                      handleUpdateSchedule({
                        ...schedule,
                        timeRanges: schedule.timeRanges.map((r, i) =>
                          i === index
                            ? {
                                begin: range.begin,
                                end: value,
                              }
                            : r
                        ),
                      })
                    }
                  />
                </div>
              </div>
            </FormGroup>
          ))}
        </div>
        <FormGroup>
          {schedule.timeRanges.length < 5 && (
            <Button
              variant="outlined"
              onClick={() =>
                handleUpdateSchedule({
                  ...schedule,
                  timeRanges: [
                    ...schedule.timeRanges,
                    initialSentinelTimeRange,
                  ],
                })
              }
            >
              <PlusIcon size={12} className={styles.addIcon} />
              Add new time range
            </Button>
          )}
        </FormGroup>
      </div>
      <div>
        <Button
          className={styles.deleteButton}
          variant="outlined"
          onClick={handleRemoveSchedule}
        >
          <TrashIcon size={14} className={styles.deleteIcon} />
          <span className={styles.buttonText}>Delete time schedule</span>
        </Button>
      </div>
    </div>
  );
}

export default StepScheduleItem;
