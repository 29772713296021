import {
  GenericErrors,
  IdListMapping,
  LoadingById,
  ResourceType,
  Space,
  SpaceFromApiResponse,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  mapArrayToObject,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/spaces';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface EditableFields {
  title: string;
  description: string;
  parentId: number;
  area: null | number;
}

export const editableFields = (space: object) =>
  R.pick(['parentId', 'title', 'description', 'area'], space);

export type EditSpace = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const newSpaceFields = {
  title: '',
  description: '',
  // We put an invalid id here to make sure it doesn't accidentally hook to something real
  parentId: -1,
  area: null,
};

const newSpace = {
  isLoading: false,
  isChanged: false,
  fields: newSpaceFields,
  formErrors: formValidationErrors('space', newSpaceFields),
  formErrorsVisible: false,
  apiError: {},
};

export type SpacesById = {
  [id: string]: Space;
};

export type SpaceIdsByParentId = {
  [id: string]: number[];
};

export type Counts = {
  spaces: number;
  equipment: number;
  gateways: number;
  sensors: number;
};

export type CountsById = {
  [id: string]: Counts;
};

export type ActionsLoadingStatus = {
  [Actions.GET_SPACES_LOADING]?: boolean;
  [Actions.GET_SPACES_BY_SITE_ID_LOADING]?: LoadingById;
};

export interface Spaces {
  query: string;
  spaceIdsByParentId: SpaceIdsByParentId;
  spaceIdsBySiteId: IdListMapping;
  spacesById: SpacesById;
  editById: EditById;
  showNewSpaceModal: boolean;
  showDeleteModal: boolean;
  editingSpace: string;
  primedForDelete: number;
  countsById: CountsById;
  apiError: ApiError;
  loadingStatusByAction: ActionsLoadingStatus;
  createdId: number;
}

export type EditById = {
  [id: string]: EditSpace;
};

export const spacesFromApiResponse = (data: any) =>
  ({
    id: data.id,
    title: data.title,
    parentId: data.parentId,
    parent: data.parent,
    description: data.description || '',
    createdAt: data.createdAt,
    updatedAt: data.updatedAt || undefined,
    resourceType: ResourceType[(data._entity as string).toUpperCase()],
    area: data.area,
  } as Space);

export const initialState = {
  query: '',
  spacesById: {},
  spaceIdsByParentId: {},
  spaceIdsBySiteId: {},
  showNewSpaceModal: false,
  showDeleteModal: false,
  editById: {},
  primedForDelete: -1,
  editingSpace: '-1',
  countsById: {},
  apiError: {},
  loadingStatusByAction: {},
  createdId: -1,
};

// have to modify slightly for recursive spaces ( but this basically doesn't exist in the real world )
export const spaceList = (state: Spaces, parentId: number) => {
  const { spacesById, query, spaceIdsByParentId } = state;
  let spaces = values(spacesById);

  if (parentId && parentId >= 0) {
    spaces = spaces.filter(
      space => (spaceIdsByParentId[parentId] || []).indexOf(space.id) >= 0
    );
  }

  if (query && query.length >= 3) {
    spaces = spaces.filter(space => hasSubstr(space.title, query));
  }

  return spaces;
};

export const sortedSpaces = ({ spacesById }: Spaces) =>
  values(spacesById).sort((a, b) => a.title.localeCompare(b.title));

const spaces = (state: Spaces = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SPACES_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_SPACES_LOADING],
        true,
        state
      );

    case Actions.GET_SPACES_BY_SITE_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_SPACES_BY_SITE_ID_LOADING,
          [action.siteId],
        ],
        true,
        state
      );

    case Actions.GET_SPACES_SUCCESS:
      return R.pipe(
        R.assoc(
          'spaceIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('spaceIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ parentId }) => parentId,
              action.data
            )
          )
        ),
        R.assoc(
          'spacesById',
          R.mergeRight(
            R.view(R.lensProp('spacesById'), state),
            mapArrayToObject(mapValues(action.data, spacesFromApiResponse))
          )
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_SPACES_LOADING],
          false
        )
      )(state);
    case Actions.GET_SPACES_BY_SITE_ID_SUCCESS:
      const apiRes: SpaceFromApiResponse[] = action.data;
      const spaceIds = apiRes.map(({ id }) => id);
      return R.pipe(
        R.assocPath(['spaceIdsBySiteId', [action.siteId]], spaceIds),
        R.assoc(
          'spaceIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('spaceIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ parentId }) => parentId,
              action.data
            )
          )
        ),
        R.assoc(
          'spacesById',
          R.mergeRight(
            R.view(R.lensProp('spacesById'), state),
            mapArrayToObject(mapValues(action.data, spacesFromApiResponse))
          )
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_SPACES_BY_SITE_ID_LOADING,
            [action.siteId],
          ],
          false
        )
      )(state);
    case Actions.GET_SPACE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);
    case Actions.GET_SPACE_SUCCESS: {
      let space = spacesFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['spacesById', action.data.id], space),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(space),
        })
      )(state);
    }

    case Actions.UPDATED_QUERY:
      return R.assoc('query', action.query, state);

    case Actions.TOGGLE_NEW_SPACE_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newSpace),
        R.assoc('showNewSpaceModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.TOGGLE_DELETE_MODAL:
      return R.pipe(
        R.assoc('showDeleteModal', action.value),
        R.assoc('apiError', {})
      )(state);

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('space', updatedField)
        )
      )(state);

    case Actions.CREATE_SPACE_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_SPACE_SUCCESS: {
      const pathToByParentIdStore = [
        'spaceIdsByParentId',
        action.data.parentId,
      ];
      const existingList = R.path(pathToByParentIdStore, state);
      return R.pipe(
        R.assocPath(
          ['spacesById', action.data.id],
          spacesFromApiResponse(action.data)
        ),
        R.assocPath(
          pathToByParentIdStore,
          existingList ? [...existingList, action.data.id] : [action.data.id]
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewSpaceModal', false),
        R.assoc('createdId', action.data.id)
      )(state);
    }

    case Actions.CREATE_SPACE_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_SPACE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_SPACE_SUCCESS: {
      const space = spacesFromApiResponse(action.data);
      const oldParent = state.spacesById[action.id].parentId;
      const newParent = space.parentId;
      const pathToByOldParentIdStore = ['spaceIdsByParentId', oldParent];
      const pathToNewByParentIdStore = ['spaceIdsByParentId', newParent];

      const oldParentList = R.path(pathToByOldParentIdStore, state).filter(
        id => id !== action.id
      );

      const newParentList = [
        ...new Set(
          (R.path(pathToNewByParentIdStore, state) || []).concat([
            parseInt(action.id),
          ])
        ),
      ];

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {}),
        R.assocPath(['spacesById', action.id], space),
        R.assocPath(pathToByOldParentIdStore, oldParentList),
        R.assocPath(pathToNewByParentIdStore, newParentList)
      )(state);
    }

    case Actions.PATCH_SPACE_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_SPACE:
      const fields = editableFields(R.path(['spacesById', action.id], state));

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('space', fields)
        ),
        R.assoc('createdId', -1)
      )(state);

    case Actions.TOGGLE_EDIT_MODAL:
      return R.assoc('editingSpace', action.id, state);

    case Actions.PRIME_FOR_DELETE:
      return R.assoc('primedForDelete', action.id, state);

    case Actions.UNSTAGE_DELETE:
      return R.assoc('primedForDelete', -1, state);

    case Actions.DELETE_SPACE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_SPACE_SUCCESS: {
      if (!!action.data) {
        const spaceToDelete = state['spacesById'][action.id];
        const parentId = spaceToDelete.parentId;
        const filteredArray = R.filter(
          id => id !== parseInt(action.id),
          state['spaceIdsByParentId'][parentId]
        );
        return R.pipe(
          R.assocPath(['spaceIdsByParentId', parentId], filteredArray),
          R.dissocPath(['spacesById', action.id]),
          R.dissocPath(['editById', action.id]),
          R.assoc('showDeleteModal', false),
          R.assoc('primedForDelete', -1)
        )(state);
      } else {
        return state;
      }
    }

    case Actions.DELETE_SPACE_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.GET_COUNTS_SUCCESS:
      return R.assocPath(['countsById', action.id], action.data, state);

    default:
      return state;
  }
};

export default spaces;
