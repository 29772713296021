import { InputField, Label } from '@energybox/react-ui-library/dist/components';
import { countDecimalsMinTwo } from '@energybox/react-ui-library/dist/utils';
import {
  CurrentUser,
  GenericErrors,
  MeasurementSystem,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import ParentSelectForSpaces from '../../containers/ParentSelectForSpaces';
import useConvertedArea from '../../hooks/useConvertedArea';
import { EditableFields } from '../../reducers/spaces';
import ModalFormContent from '../ModalFormContent';
import { convertSquareFeetToMeters } from '@energybox/react-ui-library/dist/utils/util';
import {
  AreaUnitApiValue,
  AreaUnitLabels,
} from '@energybox/react-ui-library/dist/types/Global';
import { useCurrentUser } from '../../hooks/useAppDetails';

interface Props {
  fields: EditableFields;
  onChange: (field: string, value: any) => void;
  ownerId?: string;
  parentId?: number;
  currentSiteId: string;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  isNew?: boolean;
}

const EditSpaceForm = ({
  ownerId,
  fields,
  onChange,
  currentSiteId,
  formErrors = {},
  formErrorsVisible,
  isNew,
}: Props) => {
  const currentUser = useCurrentUser();
  const { title, description, parentId, area: unconvertedArea = null } = fields;
  const { userAreaUnit, convertedArea, setConvertedArea } = useConvertedArea(
    unconvertedArea,
    false
  );

  if (!userAreaUnit || !currentUser) return null;
  return (
    <div>
      <ModalFormContent>
        <div>
          <Label required htmlFor="title">
            Space Name:
          </Label>
        </div>
        <div>
          <InputField
            id="title"
            type="text"
            name="title"
            value={title}
            onChange={({ currentTarget }) =>
              onChange('title', currentTarget.value)
            }
            error={formErrorsVisible && !!formErrors.title}
            customErrorText={formErrors.title && formErrors.title[0]}
          />
        </div>
        <div>
          <Label htmlFor="description">Description:</Label>
        </div>
        <div>
          <InputField
            id="description"
            type="text"
            name="description"
            placeholder="optional"
            value={description || ''}
            onChange={({ currentTarget }) =>
              onChange('description', currentTarget.value)
            }
          />
        </div>
        <div>
          <Label htmlFor="area">
            Total Area ({AreaUnitLabels[userAreaUnit]})
          </Label>
        </div>
        <div>
          <InputField
            id="area"
            type="text"
            name="area"
            placeholder="optional"
            value={convertedArea || ''}
            onChange={onChangeArea(currentUser, setConvertedArea, onChange)}
          />
        </div>
      </ModalFormContent>
      <ModalFormContent>
        <div>
          <Label required htmlFor="parentLocation">
            Parent Location:
          </Label>
        </div>
        <div>
          <ParentSelectForSpaces
            disabled={!isNew}
            ownerId={ownerId}
            onSelect={parentId => onChange('parentId', parentId)}
            value={parentId?.toString() || currentSiteId?.toString()}
            currentSiteId={currentSiteId}
            error={formErrorsVisible && !!formErrors.parentId}
            customErrorText={formErrors.parentId && formErrors.parentId[0]}
          />
        </div>
      </ModalFormContent>
      <ModalFormContent>
        <Label>* Mandatory fields</Label>
      </ModalFormContent>
    </div>
  );
};

const onChangeArea = (
  currentUser: CurrentUser,
  setConvertedArea: React.Dispatch<React.SetStateAction<string | null>>,
  onChangeFormFields: (field: string, value: any) => void
) => ({ currentTarget }) => {
  const { area, measurementSystem } = currentUser;
  let shouldConvert = false;
  if (area === undefined) {
    switch (measurementSystem) {
      case MeasurementSystem.METRIC:
        shouldConvert = false;
        break;
      case MeasurementSystem.IMPERIAL:
        shouldConvert = true;
        break;
    }
  } else {
    switch (area) {
      case AreaUnitApiValue.M2:
        shouldConvert = false;
        break;
      case AreaUnitApiValue.FT2:
        shouldConvert = true;
        break;
    }
  }
  if (!shouldConvert) {
    setConvertedArea(`${currentTarget.value}`);
    onChangeFormFields('area', currentTarget.value);
  } else {
    const parsedValue = Number.parseFloat(currentTarget.value);
    const areaHelper = isNaN(parsedValue)
      ? currentTarget.value
      : `${parsedValue}`;
    if (currentTarget.value.endsWith('.')) {
      setConvertedArea(areaHelper + '.');
    } else {
      setConvertedArea(areaHelper);
    }
    const convertedToMeters = isNaN(parsedValue)
      ? currentTarget.value
      : convertSquareFeetToMeters(
          parsedValue,
          countDecimalsMinTwo(parsedValue)
        );
    onChangeFormFields('area', convertedToMeters);
  }
};

export default EditSpaceForm;
