import { ApplicationState } from '../../reducers';
import { Actions } from '../../actions/network_groups';

export const networkGroupsByIdSelector = ({
  networkGroups,
}: ApplicationState) => networkGroups.networkGroupsById;

export const networkGroupIdsBySiteIdSelector = ({
  networkGroups,
}: ApplicationState) => networkGroups.networkGroupIdsBySiteId;

export const networkGroupsLoadingBySiteIdSelector = ({
  networkGroups,
}: ApplicationState) =>
  networkGroups?.loadingStatusByAction[
    Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING
  ];
