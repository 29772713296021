import { DateTime } from 'luxon';
import pipe from 'ramda/src/pipe';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import { Actions } from '../actions/reportworker';
import { ReportStatus, StatusDetail } from '../types/reportworker';
import { Region } from '@energybox/react-ui-library/dist/types';

const fileIdsString = localStorage.getItem('saveFileIds');
const fileIdsData =
  typeof fileIdsString === 'string' ? JSON.parse(fileIdsString) : [];

const reportWorkString = localStorage.getItem('reportWorkerData');
const reportWorkData =
  typeof reportWorkString === 'string' ? JSON.parse(reportWorkString) : [];

const initialState = {
  reportWorker: reportWorkData,
  notifyStatus: false,
  fileCounter: 0,
  toggleIcon: false,
  saveFileIds: fileIdsData,
  latestCount: 0,
  initiateFileDownload: false,
  activateLink: false,
  saveEquipData: {},
  pageStatus: {},
  sirSubmissions: {},
  lastSirSubmissions: {},
  sirRecipients: {
    Americas: [],
    EMEA: [],
    APAC: [],
  },
};

export enum InspectionSubmission {
  submitting = 'submitting',
  submitted = 'submitted',
  error = 'error',
}

export type PageStatus = {
  [p: string]: StatusDetail;
};

export type SIRSubmissionBySiteId = {
  [siteId: number]: InspectionSubmission;
};

export type LastSIRSubmissionBySiteId = {
  [siteId: string]: string;
};

export type SIRRecipients = {
  [region in Region]: string[];
};

export type ReportWorker = {
  reportWorker: any;
  notifyStatus: boolean;
  fileCounter: number;
  toggleIcon: boolean;
  saveFileIds: any;
  latestCount: number;
  initiateFileDownload: boolean;
  activateLink: boolean;
  saveEquipData: any;
  pageStatus: PageStatus;
  sirSubmissions: SIRSubmissionBySiteId;
  lastSirSubmissions: LastSIRSubmissionBySiteId;
  sirRecipients: SIRRecipients;
};

export default (state: ReportWorker = initialState, action: any) => {
  const reportWorkerString = localStorage.getItem('reportWorkerData');
  const reportWorkerData =
    typeof reportWorkerString === 'string'
      ? JSON.parse(reportWorkerString)
      : [];

  const saveFileIdsString = localStorage.getItem('saveFileIds');
  const saveFileIdsData =
    typeof saveFileIdsString === 'string' ? JSON.parse(saveFileIdsString) : [];

  switch (action.type) {
    case Actions.REPORT_WORKER_LOADING:
      return {
        ...state,
        reportWorker: action.data,
        pageStatus: action.page
          ? {
              ...state.pageStatus,
              [action.page]: { status: ReportStatus.TRIGGER_LOADING, msg: '' },
            }
          : state.pageStatus,
      };

    case Actions.REPORT_WORKER_SUCCESS:
      localStorage.setItem(
        'reportWorkerData',
        JSON.stringify([...reportWorkerData, action.data])
      );

      return {
        ...state,
        reportWorker: [...reportWorkerData, action.data],
        notifyStatus: true,
        toggleIcon: true,
        pageStatus: action.page
          ? {
              ...state.pageStatus,
              [action.page]: { status: ReportStatus.TRIGGER_SUCCESS, msg: '' },
            }
          : state.pageStatus,
      };

    case Actions.REPORT_WORKER_ERROR:
      let data =
        { ...action.data, ...{ failedAt: DateTime.now().toMillis() } } || {};
      if (Object.keys(data).length === 0) {
        data = {
          jobId: undefined,
          fileName: undefined,
          errorStatusResponse: {
            errorCode: 503,
            message: 'Please try again later',
          },
          failedAt: DateTime.now().toMillis(),
        };
      }
      localStorage.setItem(
        'reportWorkerData',
        JSON.stringify([...reportWorkerData, data])
      );
      localStorage.setItem(
        'saveFileIds',
        JSON.stringify(state.saveFileIds.filter(val => val !== data.jobId))
      );
      return {
        ...state,
        reportWorker: [...reportWorkerData, data],
        saveFileIds: state.saveFileIds.filter(val => val !== data.jobId),
        pageStatus: action.page
          ? {
              ...state.pageStatus,
              [action.page]: {
                status: ReportStatus.TRIGGER_SUCCESS,
                msg: data?.errorStatusResponse?.message || '',
              },
            }
          : state.pageStatus,
      };

    case Actions.NOTIFIY_DOWNLOAD_STATUS:
      return {
        ...state,
        notifyStatus: action.payload,
      };

    case Actions.SET_DOWNLOAD_FILE_COUNTER:
      return {
        ...state,
        fileCounter: action.payload,
      };

    case Actions.TOGGLE_HIDE_SHOW_ICON:
      return {
        ...state,
        toggleIcon: action.payload,
      };

    case Actions.UPDATE_REPORT_WORKER_JOBS:
      localStorage.setItem('reportWorkerData', JSON.stringify(action.payload));
      return {
        ...state,
        reportWorker: action.payload,
      };

    case Actions.REMOVE_OUTDATED_REPORT_WORKER_JOBS:
      const now = DateTime.now().toMillis();
      const filteredReportWorkerData =
        reportWorkerData instanceof Array
          ? reportWorkerData.filter(data => {
              if (data.failedAt && data.failedAt > 0) {
                return now - data.failedAt < 86400000;
              }
              return true;
            })
          : [];
      localStorage.setItem(
        'reportWorkerData',
        JSON.stringify(filteredReportWorkerData)
      );
      return {
        ...state,
        reportWorker: filteredReportWorkerData,
      };

    case Actions.SAVE_FILE_IDS:
      const newSaveFileIds = [...state.saveFileIds, action.payload].filter(
        (e, i) => {
          return [...state.saveFileIds, action.payload].indexOf(e) === i;
        }
      );
      localStorage.setItem('saveFileIds', JSON.stringify(newSaveFileIds));
      return {
        ...state,
        saveFileIds: newSaveFileIds,
      };

    case Actions.REMOVE_FILE_IDS:
      localStorage.setItem(
        'saveFileIds',
        JSON.stringify(state.saveFileIds.filter(val => val !== action.payload))
      );
      return {
        ...state,
        saveFileIds: state.saveFileIds.filter(val => val !== action.payload),
      };

    case Actions.INCREMENT_FILE_COUNT:
      return {
        ...state,
        latestCount: state.latestCount + action.payload,
      };

    case Actions.DECREMENT_FILE_COUNT:
      return {
        ...state,
        latestCount:
          state.latestCount > 0
            ? state.latestCount - action.payload
            : state.latestCount,
      };

    case Actions.LATEST_FILE_COUNT:
      return {
        ...state,
        latestCount: saveFileIdsData.length,
      };

    case Actions.INITIATE_FILE_DOWNLOAD:
      return {
        ...state,
        initiateFileDownload: action.payload,
      };

    case Actions.DEINITIATE_FILE_DOWNLOAD:
      return {
        ...state,
        initiateFileDownload: action.payload,
      };

    case Actions.GET_INITIATE_FILE_DOWNLOAD:
      return {
        ...state,
        initiateFileDownload: state.initiateFileDownload,
      };

    case Actions.SAVE_EQUIP_DATA_FOR_DOWNLOAD:
      return {
        ...state,
        saveEquipData: action.payload,
      };
    case Actions.CLEAR_EQUIP_DATA_FOR_DOWNLOAD:
      return {
        ...state,
        saveEquipData: {},
      };
    case Actions.GET_EQUIP_DATA_FOR_DOWNLOAD:
      return {
        ...state,
        saveEquipData: state.saveEquipData,
      };

    case Actions.GET_LAST_SIR_SUBMISSION_SUCCESS:
      if (action.data) {
        return assocPath(
          ['lastSirSubmissions', action.siteId],
          action.data.lastSubmitted
        )(state);
      } else {
        return dissocPath(['lastSirSubmissions', action.siteId])(state);
      }

    case Actions.SUBMIT_SITE_INSPECTION_REPORT_SUCCESS:
      const { siteId } = action;
      if (state.sirSubmissions[siteId] === InspectionSubmission.submitting)
        return pipe(
          assocPath(['sirSubmissions', siteId], InspectionSubmission.submitted),
          assocPath(['lastSirSubmissions', siteId], action.reportTimestamp)
          // A flawed assumption is placed here that the PDF generation and
          // email delivery always succeed, so last submission is refreshed
          // here immediately after the reportworker job is created
          // successfully
          // This is a give-in due to architectual design oversight and
          // limited implementation time
          // May need revision (together with BE) in the future
        )(state);
      else return state;

    case Actions.SUBMIT_SITE_INSPECTION_REPORT_SENDING:
      return assocPath(
        ['sirSubmissions', action.siteId],
        InspectionSubmission.submitting
      )(state);

    case Actions.SUBMIT_SITE_INSPECTION_REPORT_RESET:
      return dissocPath(['sirSubmissions', action.siteId])(state);

    case Actions.SUBMIT_SITE_INSPECTION_REPORT_ERROR:
      return assocPath(
        ['sirSubmissions', action.siteId],
        InspectionSubmission.error
      )(state);

    case Actions.GET_REGION_RECIPIENTS_SUCCESS:
      return assocPath(['sirRecipients', action.region], action.data)(state);

    case Actions.GET_REGION_RECIPIENTS_LOADING:
      return assocPath(
        ['sirRecipients', action.region],
        ['fetching from backend...']
      )(state);

    case Actions.GET_REGION_RECIPIENTS_ERROR:
      return assocPath(['sirRecipients', action.region], ['unknown'])(state);

    default:
      return state;
  }
};
