import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import { Actions as AppActions } from '../actions/app';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as OrganizationActions } from '../actions/organizations';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as ControlBoards } from '../actions/control_boards';
import { Actions as SentinelActions } from '../actions/sentinels';
import { Actions as SiteGroupActions } from '../actions/site_groups';
import { Actions as SiteActions } from '../actions/sites';
import { Actions as SpaceActions } from '../actions/spaces';
import { Actions as UserGroupActions } from '../actions/user_groups';
import { Actions as UserActions } from '../actions/users';
import { Actions as DistributionPanelActions } from '../actions/distribution_panel';
import { Actions as CircuitBreakerActions } from '../actions/circuit_breakers';
import { Actions as MainBreakerActions } from '../actions/main_breakers';
import { Actions as EnergyProActions } from '../actions/energy_pros';
import { Actions as EnergyDeviceActions } from '../actions/energy_devices';
import { Actions as NetworkGroupActions } from '../actions/network_groups';
import { Actions as TimeTableActions } from '../actions/time_tables';
import { Actions as SopActions } from '../actions/sops';
import { Actions as TaskSopActions } from '../actions/taskSop';
import { Actions as SchedulerActions } from '../actions/schedulers';
import { Actions as TemperatureControlActions } from '../actions/temperature_controls';
import { Actions as ReportActions } from '../actions/reports';
import { Actions as EdgeDeviceActions } from '../actions/edge_devices';
import { Actions as ThermostatActions } from '../actions/thermostats';
import { Actions as HvacControlsActions } from '../actions/hvacControls';
import { Actions as CertificationActions } from '../actions/certifications';
import FileActions from '../actions/FileActions';
import BillActions from '../actions/UtilityBillActions';
import {
  ApiError as ImportedApiError,
  ErrorPayload,
} from '@energybox/react-ui-library/dist/types';

export type ApiError = ImportedApiError; // preserve original imports

type Action = {
  type: string;
  data: ErrorPayload;
};

export type AllActions =
  | AppActions
  | EquipmentActions
  | GatewayActions
  | OrganizationActions
  | SensorActions
  | ControlBoards
  | SentinelActions
  | SiteGroupActions
  | SiteActions
  | SpaceActions
  | UserGroupActions
  | UserActions
  | DistributionPanelActions
  | CircuitBreakerActions
  | MainBreakerActions
  | EnergyProActions
  | EnergyDeviceActions
  | SopActions
  | TaskSopActions
  | NetworkGroupActions
  | TimeTableActions
  | SchedulerActions
  | TemperatureControlActions
  | ReportActions
  | EdgeDeviceActions
  | ThermostatActions
  | HvacControlsActions
  | CertificationActions
  | BillActions
  | FileActions;

export const storeAPIerror = (action: Action) => ({
  type: action.type,
  status: action.data.status,
  //TODO: will likely need to refactor below: backend is currently sending two forms of error messages: DATA.ERRORS (which is an array of object or sometimes returns null) and DATA.MESSAGE (string).
  //For DATA.ERRORS, even when there are multiple api errors (i.e. creating a user with an email that's already taken AND a password less than 6 characters) only one error is sent back...
  message:
    action.type === CertificationActions.CREATE_CERTIFICATION_ERROR &&
    action.data.exception === 'MaxUploadSizeExceededException'
      ? action.data.error
      : Array.isArray(action.data.errors) && action.data.errors.length > 0
      ? action.data.errors[0].message
      : action.data.message,
  payload: action.data,
});

export const renderAPIerror = (
  apiError: {} | { type: string; status: number; message: string },
  ...errorTypes: AllActions[]
) => {
  if (
    hasKeys(apiError) &&
    (apiError['force'] || errorTypes.includes(apiError['type']))
  ) {
    return (
      <div
        style={{
          color: 'var(--pink-base)',
          textAlign: 'center',
          fontSize: '0.75rem',
        }}
      >
        {apiError['message']}
      </div>
    );
  }
};
