import React from 'react';
import styles from './StepConditionItem.module.css';
import { classNames } from '@energybox/react-ui-library/dist/utils';

type Props = {
  label?: React.ReactNode;
  condition: React.ReactNode;
  description?: React.ReactNode;
  labelAlignedTop?: boolean;
  conditionAlignedCenter?: boolean;
};

export const StepConditionTitle = ({ children }: any) => (
  <h4 className={styles.conditionTitle}>{children}</h4>
);

export const StepConditionDescription = ({ children }: any) => (
  <p className={styles.conditionDescription}>{children}</p>
);

const StepConditionItem = ({
  condition,
  label,
  description,
  labelAlignedTop = false,
  conditionAlignedCenter = false,
}: Props) => (
  <div className={styles.root}>
    {label && (
      <div
        className={classNames(
          styles.label,
          labelAlignedTop ? styles.labelAlignedTop : null
        )}
      >
        {label}
      </div>
    )}
    <div
      className={classNames(
        styles.condition,
        conditionAlignedCenter ? styles.conditionAlignedCenter : null
      )}
    >
      {description ? (
        <div>
          {condition}
          {description && (
            <StepConditionDescription>{description}</StepConditionDescription>
          )}
        </div>
      ) : (
        condition
      )}
    </div>
  </div>
);

export default StepConditionItem;
