import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEdmReportData, getEdmReportKey } from '../../../actions/analytics';
import { useCurrentOrganizationId } from '../../../hooks/useCurrentOrganization';
import { ApplicationState } from '../../../reducers';
import LoaderLayer from '../../Site/SiteInspectionReport/LoaderLayer';
import EdmTable from './EdmTable';

const EdmView = () => {
  const dispatch = useDispatch();
  const [isUploadExist, setIsUploadExists] = useState(false);
  const [startLoadingTime, setStartLoadingTime] = useState(0);
  const orgId = useCurrentOrganizationId();
  useEffect(() => {
    dispatch(getEdmReportKey(orgId));
  }, [orgId]);

  useEffect(() => {
    if (isUploadExist) {
      dispatch(getEdmReportKey(orgId));
    }
  }, [isUploadExist]);
  const reportDate: string = useSelector<ApplicationState, string>(
    ({ analytics }) => analytics.report_key[orgId!]
  );
  const edmStatus = useSelector(({ analytics }: ApplicationState) => {
    return analytics.edmReportData?.data;
  });
  useEffect(() => {
    let progressUpdateInterval;
    if (edmStatus?.loading && typeof edmStatus?.loading === 'string')
      setStartLoadingTime(new Date().getTime());
    if (
      (edmStatus === undefined ||
        edmStatus === null ||
        isDefined(edmStatus?.loading)) &&
      reportDate
    ) {
      progressUpdateInterval = window.setInterval(
        () => dispatch(getEdmReportData(orgId, reportDate)),
        1000
      );
    }
    if (isUploadExist) {
      setIsUploadExists(false);
      progressUpdateInterval = window.setInterval(
        () => dispatch(getEdmReportData(orgId, reportDate)),
        1000
      );
    }

    return () => window.clearInterval(progressUpdateInterval);
  }, [edmStatus, reportDate]);
  const edmReportVersion = useSelector(({ analytics }: ApplicationState) => {
    return analytics.edmReportData?.report_version;
  });
  const subProgress = edmStatus?.loading ? edmStatus?.loading * 100 : 0;
  const loadingProgress =
    !edmStatus?.loading && edmReportVersion && edmReportVersion > 0
      ? 100
      : subProgress;
  const secondsLoaded =
    (new Date().getTime() - startLoadingTime) / 100000000000; //Added to calculate correct remaining secs as BE not rounding off the time
  const remainingSeconds = edmStatus?.loading
    ? Math.round(secondsLoaded / edmStatus.loading - secondsLoaded)
    : 0;
  const edmReportData =
    edmReportVersion && edmReportVersion > 0 && !edmStatus?.loading
      ? edmStatus?.by_site
      : undefined;
  const sortedEntries =
    edmReportData &&
    Object.entries(
      edmReportData
    ).sort(([, { site_info: a }], [, { site_info: b }]) =>
      a.site_title.localeCompare(b.site_title)
    );

  return (
    <div
      style={{
        position: 'relative',
        flexDirection: 'row',
        display: 'flex',
        height: 'calc(100vh + 3rem)',
      }}
    >
      <LoaderLayer
        isVisible={edmStatus?.loading || !edmStatus?.by_site ? true : false}
        progress={loadingProgress}
        remainingSeconds={remainingSeconds}
        isEdm={true}
      />
      <div style={{ width: '100%' }}>
        {sortedEntries && (
          <EdmTable
            sitesData={sortedEntries}
            setIsUploadExists={setIsUploadExists}
          />
        )}
      </div>
    </div>
  );
};

export default EdmView;
