import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import { useDispatch, useSelector } from 'react-redux';
import { FixedContentWrapper } from '../../components/Page';
import { ApplicationState } from '../../reducers';
import { Sop } from '@energybox/react-ui-library/dist/types/Sop';
import CardList, {
  CardListGroupHeader,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  getOrgSopAppliedCountsByEquipmentType,
  destroy as deleteSop,
  getSopByOrganizationalUnitId,
} from '../../actions/sops';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import { OrgSopAppliedCountsByEquipmentType } from '../../reducers/sop';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { useUrlState } from '../../hooks/utils';
import styles from './ShowOrgLevelTaskSopsPage.module.css';
import NewOrEditTaskSopModal from './NewOrEditTaskSopModal';
import { hideNewModal, showNewModal } from '../../actions/taskSop';
import { useCurrentUser } from '../../hooks/useAppDetails';
import {
  getCommonPageHeader,
  getDisplayRowData,
  getFormData,
  getHeader,
} from './ShowTaskSopHelper';
import { Role } from '@energybox/react-ui-library/dist/types';

type Props = {};

const ShowOrgLevelTaskSopsPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();
  const user = useCurrentUser();
  const [query, setQuery] = useUrlState<string>('query', '');
  const [idToEdit, setIdToEdit] = useState<null | number>(null);
  const [idToDelete, setIdToDelete] = useState<null | number>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(
    false
  );

  const isModalVisible = useSelector<ApplicationState, boolean>(
    ({ taskSop }) => {
      return taskSop.showNewModal;
    }
  );

  const taskSops = useSelector<ApplicationState, Sop[]>(({ sops }) => {
    return Object.values(sops.sopsById).filter(item => {
      return (
        item.components[0]?.type === 'TASK' && item.organizationUnitId === orgId
      );
    });
  });

  const equipmentTypeCounts = useSelector<
    ApplicationState,
    OrgSopAppliedCountsByEquipmentType
  >(({ sops }) => {
    return sops.orgSopAppliedCountsByEquipmentType;
  });

  const filteredTaskSop =
    query && query.length >= 3
      ? taskSops.filter(sop =>
          hasSubstr(`${sop.title}${sop.description}`, query)
        )
      : taskSops;

  const getEquipmentTypeIdFromSOP = sops => {
    return sops.reduce(
      (equipTypeId, sop) => equipTypeId.concat(sop.equipmentTypeIds),
      []
    );
  };

  useEffect(() => {
    if (orgId) {
      const equipmentIds = getEquipmentTypeIdFromSOP(taskSops);
      dispatch(getOrgSopAppliedCountsByEquipmentType(orgId, equipmentIds));
    }
  }, [orgId]);

  const ENERGYBOX_ORG_ID = Number(process.env.REACT_APP_ENERGYBOX_ORG);

  const getUserAccess = (user, organizationUnitId) => {
    return user.accessResources.reduce((hasAccess, resourceAccess) => {
      return (
        ((resourceAccess.resourceId === organizationUnitId ||
          resourceAccess.resourceId === ENERGYBOX_ORG_ID) &&
          resourceAccess.role !== Role.VIEWER &&
          resourceAccess.role !== Role.MANAGER &&
          resourceAccess.role !== Role.INSTALLER) ||
        hasAccess
      );
    }, false);
  };

  const hasAccess = getUserAccess(user, orgId);

  const isMobile = ViewportTypes.MOBILE;

  const handleSearchChange = (query: string) => {
    setQuery(query);
  };

  const handleEditSop = (sopId: number) => {
    setIdToEdit(sopId);
    dispatch(showNewModal());
  };

  const handleDeleteSop = (sopId: number) => {
    setIdToDelete(sopId);
    setIsDeleteModalVisible(true);
  };

  const handleCloseSopDeletePrompt = () => {
    setIsDeleteModalVisible(false);
  };

  const displayItems = getDisplayRowData('org', filteredTaskSop, styles, {
    orgLevel: {
      equipmentTypeCounts,
      hasAccess,
      handleEditSop,
      handleDeleteSop,
    },
  });

  const headerSopText = filteredTaskSop.length > 1 ? 'SOPs' : 'SOP';

  const getGroupHeaders = () => {
    return [
      {
        width: '9',
        content: '',
      },
      {
        width: '2',
        content: 'Equipment',
      },
    ] as CardListGroupHeader[];
  };

  const actions = (
    <>
      <Button variant="text" onClick={handleCloseSopDeletePrompt}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          dispatch(deleteSop(idToDelete || ''));
          if (orgId) {
            setTimeout(() => dispatch(getSopByOrganizationalUnitId(orgId)), 50);
          }
          handleCloseSopDeletePrompt();
        }}
      >
        Delete
      </Button>
    </>
  );

  return (
    <div id="TaskSOPContainer">
      {isModalVisible && (
        <NewOrEditTaskSopModal
          isVisible={isModalVisible}
          formData={getFormData('org', idToEdit, taskSops, orgId)}
          organizationUnitId={orgId || ''}
          onClose={() => {
            setTimeout(() => {
              if (orgId) dispatch(getSopByOrganizationalUnitId(orgId));
            }, 50);
            dispatch(hideNewModal());
            setIdToEdit(null);
          }}
          onChange={() => {}}
        />
      )}
      {isDeleteModalVisible && (
        <Modal onClose={handleCloseSopDeletePrompt} actions={actions}>
          <p style={{ textAlign: 'center' }}>
            Are you sure you want to delete this SOP?
          </p>
        </Modal>
      )}
      <FixedContentWrapper>
        {getCommonPageHeader(
          filteredTaskSop,
          headerSopText,
          query,
          handleSearchChange,
          isMobile
        )}
        <CardList
          headerClassName={styles.headerGrid}
          groupHeaders={getGroupHeaders()}
          header={getHeader('org')}
          data={displayItems}
        />
      </FixedContentWrapper>
    </div>
  );
};

export default ShowOrgLevelTaskSopsPage;
