import React from 'react';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import { determineTimeStringByTimezone } from '@energybox/react-ui-library/dist/utils';
import { useSiteTimezone } from '../../../../hooks/useSites';

import styles from './TimestampTooltip.module.css';

type Props = {
  children: React.ReactNode;
  timestamp: string | number;
  siteId: string | number;
};

const TimestampTooltip: React.FC<Props> = ({ children, timestamp, siteId }) => {
  const timezone = useSiteTimezone(siteId);
  if (
    !timestamp ||
    (typeof timestamp !== 'number' && typeof timestamp !== 'string')
  ) {
    return <span>{children}</span>;
  }
  const time = determineTimeStringByTimezone(
    new Date(Number(timestamp) * 1000),
    'dd/MM/yyyy h:mm a',
    timezone || '',
    true
  );
  return (
    <Tooltip
      content={time}
      tooltipTextClassName={styles.tooltipText}
      arrowDirection="top"
      disableAutoAdjust
      underline={false}
    >
      {children}
    </Tooltip>
  );
};

export default TimestampTooltip;
