import { TextCircle } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import styles from './UserGroupTitle.module.css';

interface Props {
  id: number;
  title: string;
  description: string;
}

const UserGroupTitle = ({ id, title, description }: Props) => (
  <div className={styles.root}>
    <TextCircle>{`#${title ? title[0] : ''}`}</TextCircle>
    <div>
      <Link to={`${Routes.USER_GROUPS}/${id}`}>{title}</Link>
      <div>{description || '-'}</div>
    </div>
  </div>
);

export default UserGroupTitle;
