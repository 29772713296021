import {
  Button,
  FilterDropdown,
  FilterDropdownTextItem,
  Loader,
  SearchInput,
} from '@energybox/react-ui-library/dist/components';
import {
  GenericFunction,
  TimeTable,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  hasSubstr,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosAddCircle } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import {
  getTimeTables,
  hideNewModal,
  showNewModal,
  resetLastCreatedIdOnSelectTimetable,
} from '../../actions/time_tables';
import NewTimeTableItem from '../../containers/TimeTables/NewTimeTableItem';
import { ApplicationState } from '../../reducers';
import styles from './SelectTimeTable.module.css';

const NO_TIMETABLE_TEXT = 'No Timetable';

interface Props {
  siteId: number;
  value: string | null;
  onSelect: GenericFunction;
  includeSearchBox?: boolean;
  includeNullSelectOption?: boolean;
  canCreateTimeTable?: boolean;
  disabled?: boolean;
  error?: boolean;
  customErrorText?: string;
  isOrgLevel?: boolean;
}

const SelectTimeTable = ({
  siteId,
  value,
  onSelect,
  disabled,
  error,
  customErrorText,
  includeNullSelectOption,
  includeSearchBox = true,
  canCreateTimeTable = true,
  isOrgLevel,
}: Props) => {
  //** Redux **//
  const orgId = useCurrentOrganizationId();
  const [selectedTab, setSelectedTab] = useState('site');

  const siteTimeTables: TimeTable[] = useSelector(
    (state: ApplicationState) =>
      (state.timeTables.timeTableIdsByParentId[siteId] &&
        state.timeTables.timeTableIdsByParentId[siteId].map(
          timetableId => state.timeTables.timeTablesById[timetableId]
        )) ||
      []
  );

  const orgTimeTables: TimeTable[] = useSelector(
    ({ timeTables }: ApplicationState) => {
      if (!isDefined(orgId)) return [];
      const orgTimeTableIds = timeTables.timeTableIdsByParentId[orgId] || [];

      return orgTimeTableIds.map(
        timetableId => timeTables.timeTablesById[timetableId]
      );
    }
  );

  const allTimeTables = siteTimeTables.concat(orgTimeTables);

  const currentTimeTables =
    selectedTab === 'site' ? siteTimeTables : orgTimeTables;

  const showNewModalTimeTables = useSelector(
    (state: ApplicationState) => state.timeTables.showNewModal
  );

  const isNewTimetableFormVisible: boolean =
    canCreateTimeTable && showNewModalTimeTables;

  const isTimePickerOpen: boolean = useSelector(
    (state: ApplicationState) => state.timeTables.isTimePickerOpen
  );

  const lastCreatedIdOnSelectTimetable: number = useSelector(
    (state: ApplicationState) => state.timeTables.lastCreatedIdOnSelectTimetable
  );

  const containerRef = useRef<HTMLDivElement>(null);

  //** Actions **//
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDefined(orgId)) {
      dispatch(getTimeTables(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    // auto select newly created timetable
    if (lastCreatedIdOnSelectTimetable !== -1 && isOrgLevel !== true) {
      onSelect(lastCreatedIdOnSelectTimetable);

      // close the dropdown by simulating a mousedown event outside it
      const mouseEvent = document.createEvent('MouseEvents');
      mouseEvent.initEvent('mousedown', true, true);
      containerRef.current?.dispatchEvent(mouseEvent);

      dispatch(resetLastCreatedIdOnSelectTimetable());
    }
  }, [lastCreatedIdOnSelectTimetable]);

  useEffect(() => {
    return () => {
      canCreateTimeTable && dispatch(hideNewModal());
    };
  }, []);

  //** State **//
  const [query, setQuery] = useState('');

  //** Functions **//
  const searchChange = (e: any) => {
    setQuery(e.currentTarget.value);
  };

  const resetState = () => {
    if (!isOrgLevel) {
      setQuery('');
      canCreateTimeTable && dispatch(hideNewModal());
    }
  };

  const filterTimeTablesByQuery = useCallback(
    (timeTables: TimeTable[], query: string) => {
      return timeTables.filter((timeTable: TimeTable) => {
        if (query.length === 0) return true;
        return hasSubstr(timeTable.title, query);
      });
    },
    []
  );

  if (!siteTimeTables && !orgTimeTables) {
    return <Loader size={12} />;
  } else {
    const selectedTimeTable = allTimeTables.find(
      (timeTable: TimeTable) => timeTable.id === Number(value)
    );
    const filteredTimeTables = filterTimeTablesByQuery(
      currentTimeTables,
      query
    );
    const isNoTimetableOptionSelected = value === null || value === 'null';

    const renderSelectedTimetableTitle = () => {
      if (isNoTimetableOptionSelected) return NO_TIMETABLE_TEXT;
      return selectedTimeTable?.title;
    };

    const renderTimeTables = () => {
      return (
        <div>
          {!isNewTimetableFormVisible && (
            <div className={styles.itemsContainer}>
              {includeNullSelectOption && (
                <FilterDropdownTextItem
                  title={NO_TIMETABLE_TEXT}
                  className={styles.dropdownItem}
                  key={'None'}
                  onClick={() => onSelect(null)}
                  closeOnClick
                />
              )}
              {filteredTimeTables.map((timeTable: TimeTable) => (
                <FilterDropdownTextItem
                  title={timeTable.title}
                  className={styles.dropdownItem}
                  key={timeTable.id}
                  onClick={() => onSelect(timeTable.id)}
                  closeOnClick
                />
              ))}
            </div>
          )}
          {canCreateTimeTable && (
            <FilterDropdownTextItem
              title={
                <>
                  <IoIosAddCircle size="20" />
                  <span className={styles.createTimetableLabel}>
                    Add Timetable
                  </span>
                </>
              }
              className={classNames(
                styles.dropdownItem,
                styles.createTimetableButton,
                isNewTimetableFormVisible ? styles.createTimetableTitle : null
              )}
              key="create"
              onClick={() => {
                dispatch(showNewModal());
              }}
            />
          )}
        </div>
      );
    };

    return (
      <div className={styles.dropdownContainer} ref={containerRef}>
        <FilterDropdown
          variant="select"
          title={renderSelectedTimetableTitle() || '- Select -'}
          disabled={disabled}
          isDatePickerModalOpen={isTimePickerOpen}
          buttonClassName={classNames(
            styles.normalDropdownButton,
            !isNoTimetableOptionSelected && !selectedTimeTable?.title
              ? styles.placeholderDropdownButton
              : '',
            disabled ? styles.disabledDropdownButton : '',
            error
              ? styles.errorDropdownButton
              : selectedTimeTable
              ? styles.selectedDropdownButton
              : ''
          )}
          dropdownClassName={classNames(
            (isNewTimetableFormVisible && styles.selectListWithTimetableForm) ||
              styles.dropdownListWrapper,
            isOrgLevel ? styles.dropdownListWrapper1 : ''
          )}
          fnToExecuteOnClickOutside={resetState}
        >
          {includeSearchBox && !isNewTimetableFormVisible && (
            <SearchInput
              active={true}
              color="shade"
              onChange={searchChange}
              placeholder="Quick Search"
              value={query}
              error={filteredTimeTables.length === 0}
            />
          )}
          <div className={styles.tabs}>
            <Button
              className={classNames(
                styles.button,
                selectedTab === 'site' ? styles.activeTab : styles.inActiveTab
              )}
              onClick={() => setSelectedTab('site')}
            >
              Site Timetables
            </Button>
            <Button
              className={classNames(
                styles.button,
                selectedTab === 'org' ? styles.activeTab : styles.inActiveTab
              )}
              onClick={() => setSelectedTab('org')}
            >
              Org Timetables
            </Button>
          </div>

          {renderTimeTables()}

          {isNewTimetableFormVisible && (
            <NewTimeTableItem
              organizationUnitId={
                selectedTab === 'site' ? String(siteId) : String(orgId)
              }
              className={styles.createTimetableForm}
            />
          )}
        </FilterDropdown>
        {!!error && !!customErrorText && (
          <div className={styles.customErrorText}>{customErrorText}</div>
        )}
      </div>
    );
  }
};

export default SelectTimeTable;
