import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';
import { fetchApi } from '../utils/apiUtils';

const newApiBase = '/api/v1/thermostat';

export const fetchThermostat = (
  id: number | string,
  model: ThermostatModelType | string
) => {
  return fetchApi({
    method: 'GET',
    endpoint: `${newApiBase}/${id}?type=${model}`,
  });
};
