import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGatewaysByNetworkGroupId } from '../actions/gateways';
import { ApplicationState } from '../reducers';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { Gateway, ControlBoard } from '@energybox/react-ui-library/dist/types';

export const useGetGatewaysByNetworkGroupById = (
  networkGroupId: number | string | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(networkGroupId)) {
      dispatch(getGatewaysByNetworkGroupId(networkGroupId));
    }
  }, [dispatch, networkGroupId]);

  const gateways = useSelector<
    ApplicationState,
    (Gateway | ControlBoard)[] | undefined
  >(({ gateways }) => {
    if (isDefined(networkGroupId)) {
      return gateways?.gatewaysByNetworkGroupId[networkGroupId];
    }
    return undefined;
  });

  return gateways;
};
