import React from 'react';

type Props = {
  size: number;
  disabled?: boolean;
  className?: string;
};

export default ({ size, disabled, ...rest }: Props) => (
  <svg
    width={size * 0.8}
    height={size * 1.7}
    viewBox="0 0 8 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_656_1729)">
      <path
        d="M3.91295 5.08423C4.67738 5.51229 5.20718 6.3684 5.20718 7.33808V14.0123C5.20718 15.4188 4.1173 16.5632 2.7928 16.5632C1.46073 16.5632 0.378418 15.4188 0.378418 14.0123V7.33808C0.378418 6.38587 0.885514 5.54723 1.61967 5.11044"
        stroke={disabled ? '#D3D3D3' : '#00A1AF'}
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M4.08708 11.9157C3.32265 11.4876 2.79285 10.6315 2.79285 9.66185V2.98764C2.79285 1.58117 3.88272 0.436768 5.20723 0.436768C6.5393 0.436768 7.62161 1.58117 7.62161 2.98764V9.66185C7.62161 10.6141 7.11451 11.4527 6.38036 11.8895"
        stroke={disabled ? '#D3D3D3' : '#00A1AF'}
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_656_1729">
        <rect width="8" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
