import { ApplicationState } from '../reducers';
import {
  SensorType,
  SpaceApiFilter,
} from '@energybox/react-ui-library/dist/types';

export enum Actions {
  GET_SPACES_SUCCESS = '@spaces/GET_SPACES_SUCCESS',
  GET_SPACES_ERROR = '@spaces/GET_SPACES_ERROR',
  GET_SPACES_LOADING = '@spaces/GET_SPACES_LOADING',

  GET_SPACES_BY_SITE_ID_SUCCESS = '@spaces/GET_SPACES_BY_SITE_ID_SUCCESS',
  GET_SPACES_BY_SITE_ID_ERROR = '@spaces/GET_SPACES_BY_SITE_ID_ERROR',
  GET_SPACES_BY_SITE_ID_LOADING = '@spaces/GET_SPACES_BY_SITE_ID_LOADING',

  GET_SPACE_SUCCESS = '@spaces/GET_SPACE_SUCCESS',
  GET_SPACE_ERROR = '@spaces/GET_SPACE_ERROR',
  GET_SPACE_LOADING = '@spaces/GET_SPACE_LOADING',

  PATCH_SPACE_LOADING = '@spaces/PATCH_SPACE_LOADING',
  PATCH_SPACE_ERROR = '@spaces/PATCH_SPACE_ERROR',
  PATCH_SPACE_SUCCESS = '@spaces/PATCH_SPACE_SUCCESS',

  DELETE_SPACE_SUCCESS = '@spaces/DELETE_SPACE_SUCCESS',
  DELETE_SPACE_ERROR = '@spaces/DELETE_SPACE_ERROR',
  DELETE_SPACE_LOADING = '@spaces/DELETE_SPACE_LOADING',

  GET_COUNTS_LOADING = '@spaces/GET_COUNTS_LOADING',
  GET_COUNTS_ERROR = '@spaces/GET_COUNTS_ERROR',
  GET_COUNTS_SUCCESS = '@spaces/GET_COUNTS_SUCCESS',

  UPDATED_QUERY = '@spaces/UPDATED_QUERY',

  TOGGLE_NEW_SPACE_MODAL = '@spaces/TOGGLE_NEW_SPACE_MODAL',

  TOGGLE_DELETE_MODAL = '@spaces/TOGGLE_DELETE_MODAL',

  TOGGLE_EDIT_MODAL = '@spaces/TOGGLE_EDIT_MODAL',

  UPDATE_FIELD = '@spaces/UPDATE_FIELD',
  RESET_EDIT_SPACE = '@spaces/RESET_EDIT_SPACE',

  CREATE_SPACE_LOADING = '@spaces/CREATE_SPACE_LOADING',
  CREATE_SPACE_SUCCESS = '@spaces/CREATE_SPACE_SUCCESS',
  CREATE_SPACE_ERROR = '@spaces/CREATE_SPACE_ERROR',

  PRIME_FOR_DELETE = '@spaces/PRIME_FOR_DELETE',
  UNSTAGE_DELETE = '@spaces/UNSTAGE_DELETE',

  DISPLAY_FORM_ERRORS = '@spaces/DISPLAY_FORM_ERRORS',
}

export type GetSpacesParams = {
  ids?: string[] | number[];
  limit?: number;
  sensorTypes?: SensorType[];
  siteIds?: string[] | number[];
  skip?: number;
  withPath?: boolean;
  siteId?: number;
};

// Make this cleaner with mapping tomorrow
const createQueryString = (params: GetSpacesParams = {}) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getSpace = (id: string) => ({
  type: 'API_GET',
  path: `/api/v1/spaces/${id}`,
  success: Actions.GET_SPACE_SUCCESS,
  error: Actions.GET_SPACE_ERROR,
  loading: { type: Actions.GET_SPACE_LOADING, id },
});

export const getSpaces = (params: GetSpacesParams) => ({
  type: 'API_GET',
  path: `/api/v1/spaces?${createQueryString(params)}`,
  success: Actions.GET_SPACES_SUCCESS,
  error: Actions.GET_SPACES_ERROR,
  loading: Actions.GET_SPACES_LOADING,
});

export const getSpacesBySiteId = (
  siteId: number | string,
  params: GetSpacesParams = {}
) => ({
  type: 'API_GET',
  path: `/api/v1/spaces?${createQueryString({
    ...params,
    siteIds: [siteId],
  } as GetSpacesParams)}`,
  success: { type: Actions.GET_SPACES_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_SPACES_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_SPACES_BY_SITE_ID_LOADING, siteId },
});

export const patch = (id: number) => (dispatch, getState) => {
  dispatch({
    type: 'API_PATCH',
    path: `/api/v1/spaces/${id}`,
    payload: (getState() as ApplicationState).spaces.editById[id].fields,
    loading: { type: Actions.PATCH_SPACE_LOADING, id },
    success: { type: Actions.PATCH_SPACE_SUCCESS, id },
    error: { type: Actions.PATCH_SPACE_ERROR, id },
  });
};

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `/api/v1/spaces/${id}`,
  loading: { type: Actions.DELETE_SPACE_LOADING, id },
  success: { type: Actions.DELETE_SPACE_SUCCESS, id },
  error: { type: Actions.DELETE_SPACE_ERROR, id },
});

export const getCounts = (id: string | number) => ({
  type: 'API_GET',
  path: `/api/v1/spaces/${id}/stats`,
  success: { type: Actions.GET_COUNTS_SUCCESS, id },
  error: Actions.GET_COUNTS_ERROR,
  loading: Actions.GET_COUNTS_LOADING,
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_SPACE,
  id,
});

export const updateQuery = (query: string) => ({
  type: Actions.UPDATED_QUERY,
  query,
});

export const showNewModal = (parentId: string) => (dispatch, getState) => {
  dispatch({
    type: Actions.TOGGLE_NEW_SPACE_MODAL,
    value: true,
  });
  dispatch({
    type: Actions.UPDATE_FIELD,
    id: 'new',
    field: 'parentId',
    value: parentId,
  });
};

export const showEditModal = (spaceId: string) => ({
  type: Actions.TOGGLE_EDIT_MODAL,
  id: spaceId,
});

export const hideEditModal = () => ({
  type: Actions.TOGGLE_EDIT_MODAL,
  id: '-1',
});

export const hideNewModal = () => ({
  type: Actions.TOGGLE_NEW_SPACE_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const showDeleteModal = () => ({
  type: Actions.TOGGLE_DELETE_MODAL,
  value: true,
});

export const hideDeleteModal = () => ({
  type: Actions.TOGGLE_DELETE_MODAL,
  value: false,
});

export const primeForDelete = (id: string) => ({
  type: Actions.PRIME_FOR_DELETE,
  id,
});

export const unstageDelete = () => ({
  type: Actions.UNSTAGE_DELETE,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const create = () => (dispatch, getState) => {
  dispatch({
    type: 'API_POST',
    path: '/api/v1/spaces',
    payload: (getState() as ApplicationState).spaces.editById['new'].fields,
    loading: Actions.CREATE_SPACE_LOADING,
    success: Actions.CREATE_SPACE_SUCCESS,
    error: Actions.CREATE_SPACE_ERROR,
  });
};

export const collectSpacesRecursive = (id, idsByParentId, byId) => {
  return [byId[id]].concat(
    ...(idsByParentId[id] || []).map(id =>
      collectSpacesRecursive(id, idsByParentId, byId)
    )
  );
};

export const setSpacesApiFilter = (filter?: SpaceApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return `/api/v1/spaces?${queryParams.toString()}`;
};
