export const getUniqueSiteIdsFromSelectedSiteGroups = (
  siteGroupsById,
  selectedSiteGroups
) => {
  if (siteGroupsById === undefined || selectedSiteGroups === undefined) {
    return null;
  }

  const siteIds = new Set();
  const selectedSiteGroupsSet = new Set(selectedSiteGroups);

  const siteGroupsValues = Object.values(siteGroupsById);
  const selectedSiteGroupsById: number[] = siteGroupsValues
    .filter((data: any) => selectedSiteGroupsSet.has(data?.title))
    ?.map((data: any) => data?.id);

  selectedSiteGroupsById.forEach(groupId => {
    const siteGroup = siteGroupsById[groupId];
    if (siteGroup && siteGroup.sites && siteGroup.sites.length > 0) {
      siteGroup.sites.forEach(site => {
        siteIds.add(Number(site.id));
      });
    }
  });

  return [...siteIds];
};
