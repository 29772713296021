import React from 'react';
import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';

import ReminderWizard from '../ReminderWizard';

function RemindersCreatePage() {
  return (
    <IndexManagementPageLayout
      tabs={[]}
      content={<ReminderWizard id="new" />}
    />
  );
}

export default RemindersCreatePage;
