import { ApplicationState } from '../reducers';
import {
  SensorApiFilter,
  SensorType,
  OffsetConfig,
  SensorVendors,
} from '@energybox/react-ui-library/dist/types';
import { getSiteByResourceId } from './sites';
import { enqueueSensorAction, SensorAction } from './streamApi';
import { EditableFields } from '../reducers/sensors';
import { getGatewaysByNetworkGroupId } from './gateways';

const apiBase = '/api/v1/sensors';

export enum Actions {
  GET_SENSORS_SUCCESS = '@sensors/GET_SENSORS_SUCCESS',
  GET_SENSORS_ERROR = '@sensors/GET_SENSORS_ERROR',
  GET_SENSORS_LOADING = '@sensors/GET_SENSORS_LOADING',

  GET_SENSORS_BY_SITE_ID_SUCCESS = '@sensors/GET_SENSORS_BY_SITE_ID_SUCCESS',
  GET_SENSORS_BY_SITE_ID_ERROR = '@sensors/GET_SENSORS_BY_SITE_ID_ERROR',
  GET_SENSORS_BY_SITE_ID_LOADING = '@sensors/GET_SENSORS_BY_SITE_ID_LOADING',

  GET_SENSOR_SUCCESS = '@sensors/GET_SENSOR_SUCCESS',
  GET_SENSOR_ERROR = '@sensors/GET_SENSOR_ERROR',
  GET_SENSOR_LOADING = '@sensors/GET_SENSOR_LOADING',

  GET_SENSOR_ID_FROM_UUID_SUCCESS = '@sensors/GET_SENSOR_ID_FROM_UUID_SUCCESS',
  GET_SENSOR_ID_FROM_UUID_ERROR = '@sensors/GET_SENSOR_ID_FROM_UUID_ERROR',
  GET_SENSOR_ID_FROM_UUID_LOADING = '@sensors/GET_SENSOR_ID_FROM_UUID_LOADING',

  GET_SENSORS_BY_RESOURCE_ID_SUCCESS = '@sensors/GET_SENSORS_BY_RESOURCE_ID_SUCCESS',
  GET_SENSORS_BY_RESOURCE_ID_ERROR = '@sensors/GET_SENSORS_BY_RESOURCE_ID_ERROR',
  GET_SENSORS_BY_RESOURCE_ID_LOADING = '@sensors/GET_SENSORS_BY_RESOURCE_ID_LOADING',

  GET_PRELOADED_SENSORS_BY_SITE_SUCCESS = '@sensors/GET_PRELOADED_SENSORS_BY_SITE_SUCCESS',
  GET_PRELOADED_SENSORS_BY_SITE_ERROR = '@sensors/GET_PRELOADED_SENSORS_BY_SITE_ERROR',
  GET_PRELOADED_SENSORS_BY_SITE_LOADING = '@sensors/GET_PRELOADED_SENSORS_BY_SITE_LOADING',

  PATCH_SENSOR_LOADING = '@sensors/PATCH_SENSOR_LOADING',
  PATCH_SENSOR_ERROR = '@sensors/PATCH_SENSOR_ERROR',
  PATCH_SENSOR_SUCCESS = '@sensors/PATCH_SENSOR_SUCCESS',

  UPDATE_SENSOR_OFFSET_LOADING = '@sensors/UPDATE_SENSOR_OFFSET_LOADING',
  UPDATE_SENSOR_OFFSET_ERROR = '@sensors/UPDATE_SENSOR_OFFSET_ERROR',
  UPDATE_SENSOR_OFFSET_SUCCESS = '@sensors/UPDATE_SENSOR_OFFSET_SUCCESS',

  DELETE_SENSOR_SUCCESS = '@sensors/DELETE_SENSOR_SUCCESS',
  DELETE_SENSOR_ERROR = '@sensors/DELETE_SENSOR_ERROR',
  DELETE_SENSOR_LOADING = '@sensors/DELETE_SENSOR_LOADING',

  UPDATED_QUERY = '@sensors/UPDATED_QUERY',

  TOGGLE_NEW_SENSOR_MODAL = '@sensors/TOGGLE_NEW_SENSOR_MODAL',

  UPDATE_FIELD = '@sensors/UPDATE_FIELD',
  RESET_EDIT_SENSOR = '@sensors/RESET_EDIT_SENSOR',

  CREATE_SENSOR_LOADING = '@sensors/CREATE_SENSOR_LOADING',
  CREATE_SENSOR_SUCCESS = '@sensors/CREATE_SENSOR_SUCCESS',
  CREATE_SENSOR_ERROR = '@sensors/CREATE_SENSOR_ERROR',

  DISPLAY_FORM_ERRORS = '@sensors/DISPLAY_FORM_ERRORS',
}

export type GetSensorsParams = {
  ids?: string[] | number[];
  equipmentIds?: string[];
  siteIds?: string[];
  spaceIds?: string[];
  limit?: number;
  sensorTypes?: SensorType[];
  vendors?: string[];
  skip?: number;
  withPath?: boolean;
};

const createQueryString = (params: GetSensorsParams = {}) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const setSensorApiFilter = (filter?: SensorApiFilter) => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }
  return `/api/v1/sensors?${queryParams.toString()}`;
};

export const getSensor = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_SENSOR_SUCCESS,
  error: Actions.GET_SENSOR_ERROR,
  loading: Actions.GET_SENSOR_LOADING,
});

export const getSensorIdFromUuid = (
  uuid: string,
  vendor: string = 'energybox'
) => ({
  type: 'API_GET',
  path: `${apiBase}/lookup/${vendor}/${uuid}`,
  success: { type: Actions.GET_SENSOR_ID_FROM_UUID_SUCCESS, id: uuid },
  error: Actions.GET_SENSOR_ID_FROM_UUID_ERROR,
  loading: Actions.GET_SENSOR_ID_FROM_UUID_LOADING,
});

export const getSensors = (params?: GetSensorsParams) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString(params)}`,
  success: Actions.GET_SENSORS_SUCCESS,
  error: Actions.GET_SENSORS_ERROR,
  loading: Actions.GET_SENSORS_LOADING,
});

export const getSensorsBySiteId = (
  siteId: number | string,
  params: GetSensorsParams = {}
) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString({
    ...params,
    siteIds: [siteId],
  } as GetSensorsParams)}`,
  success: { type: Actions.GET_SENSORS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_SENSORS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_SENSORS_BY_SITE_ID_LOADING, siteId },
});

export const getPreloadedSensorsBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/preloaded/${siteId}`,
  success: Actions.GET_PRELOADED_SENSORS_BY_SITE_SUCCESS,
  error: Actions.GET_PRELOADED_SENSORS_BY_SITE_ERROR,
  loading: Actions.GET_PRELOADED_SENSORS_BY_SITE_LOADING,
});

export const filterSensorsBySiteIds = (
  siteIds: (number | string)[],
  params: GetSensorsParams = {}
) => {
  return {
    type: 'API_GET',
    path: `${apiBase}?${
      siteIds.length
        ? createQueryString({
            ...params,
            siteIds,
          } as GetSensorsParams)
        : ''
    }`,
    success: { type: Actions.GET_SENSORS_BY_SITE_ID_SUCCESS },
    error: { type: Actions.GET_SENSORS_BY_SITE_ID_ERROR },
    loading: { type: Actions.GET_SENSORS_BY_SITE_ID_LOADING },
  };
};

export const patch = (id: string) => (dispatch, getState) => {
  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: (getState() as ApplicationState).sensors.editById[id].fields,
    loading: { type: Actions.PATCH_SENSOR_LOADING, id },
    success: [
      { type: Actions.PATCH_SENSOR_SUCCESS, id },
      getSiteByResourceId(id),
    ],
    error: { type: Actions.PATCH_SENSOR_ERROR, id },
  });
};

export const patchDummySensor = (id: string) => (dispatch, getState) => {
  const state = getState() as ApplicationState;
  const fieldsToUpdate = state.sensors.editById['new'].fields;

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: fieldsToUpdate,
    loading: { type: Actions.PATCH_SENSOR_LOADING, id },
    success: [
      { type: Actions.PATCH_SENSOR_SUCCESS, id },
      getSiteByResourceId(id),
      () => dispatch(hideNewSensorModal()),
    ],
    error: { type: Actions.PATCH_SENSOR_ERROR, id },
  });
};

// hack to automatically update monnit sensors to be temperature sensors
export const putMonnitSensorTypeTemperature = (id: number) => ({
  type: 'API_PUT',
  path: `${apiBase}/${id}/types`,
  payload: ['TEMPERATURE'],
  loading: { type: 'UPDATE_MONNIT_SENSOR_LOADING', id },
  success: { type: 'UPDATE_MONNIT_SENSOR_SUCCESS', id },
  error: { type: 'UPDATE_MONNIT_SENSOR_ERROR', id },
});

export const updateOffset = (id: string, offsetConfig: OffsetConfig) => ({
  type: 'API_PUT',
  path: `${apiBase}/${id}/offset`,
  payload: offsetConfig,
  loading: { type: Actions.UPDATE_SENSOR_OFFSET_LOADING, id },
  success: { type: Actions.UPDATE_SENSOR_OFFSET_SUCCESS, id },
  error: { type: Actions.UPDATE_SENSOR_OFFSET_ERROR, id },
});

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_SENSOR_LOADING, id },
  success: { type: Actions.DELETE_SENSOR_SUCCESS, id },
  error: { type: Actions.DELETE_SENSOR_ERROR, id },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_SENSOR,
  id,
});

export const updateQuery = (query: string) => ({
  type: Actions.UPDATED_QUERY,
  query,
});

type NewSensorModalPreselect = {
  uuid?: string;
  resourceId?: number;
};

export const showNewSensorModal = (
  preselect: NewSensorModalPreselect = {}
) => ({
  type: Actions.TOGGLE_NEW_SENSOR_MODAL,
  value: true,
  preselect,
});

export const hideNewSensorModal = () => ({
  type: Actions.TOGGLE_NEW_SENSOR_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const create = (resourceId?: number) => (dispatch, getState) => {
  const sensorProperties = (getState() as ApplicationState).sensors.editById[
    'new'
  ].fields;
  const isMonnit = sensorProperties.vendor === SensorVendors.monnit;
  dispatch({
    type: 'API_POST',
    path: apiBase,
    payload: isMonnit
      ? ({
          ...sensorProperties,
          types: [SensorType.TEMPERATURE],
        } as EditableFields)
      : sensorProperties,
    loading: Actions.CREATE_SENSOR_LOADING,
    success: [
      { type: Actions.CREATE_SENSOR_SUCCESS, resourceId },
      resourceId ? getByResourceId(resourceId) : '',
      resourceId ? getSensor(String(resourceId)) : '',
    ],
    error: Actions.CREATE_SENSOR_ERROR,
  });
};

export const getByResourceId = (id: number, sensorType?: SensorType[]) => ({
  type: 'API_GET',
  path: `${apiBase}/resource/${id}?recursive=true${
    sensorType ? `&types=${sensorType}` : ''
  }`,
  success: { type: Actions.GET_SENSORS_BY_RESOURCE_ID_SUCCESS, id },
  error: Actions.GET_SENSORS_BY_RESOURCE_ID_ERROR,
  loading: Actions.GET_SENSORS_BY_RESOURCE_ID_LOADING,
});

/***** SENSOR CONTROL ******/

export const changeSensorPollingInterval = (
  id: string,
  pairedIDeviceUuid: string,
  intervalMs: number
) => async (dispatch: any) => {
  dispatch(
    enqueueSensorAction(SensorAction.SET_POLLING_INTERVAL, id, {
      interval: intervalMs,
      pairedIDeviceUuid,
    })
  );
};

export const changeSensorReportingInterval = (
  id: string,
  pairedIDeviceUuid: string,
  intervalMs: number
) => async (dispatch: any) => {
  dispatch(
    enqueueSensorAction(SensorAction.SET_REPORT_INTERVAL, id, {
      interval: intervalMs,
      pairedIDeviceUuid,
    })
  );
};

export const emitConfig = (id: string, pairedIDeviceUuid: string) => async (
  dispatch: any
) => {
  dispatch(
    enqueueSensorAction(SensorAction.EMIT_SENSOR_CONFIG, id, {
      pairedIDeviceUuid,
    })
  );
};

export const emitStatus = (id: string, pairedIDeviceUuid: string) => async (
  dispatch: any
) => {
  dispatch(
    enqueueSensorAction(SensorAction.EMIT_SENSOR_STATUS, id, {
      pairedIDeviceUuid,
    })
  );
};
