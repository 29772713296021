import {
  Columns,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import SpaceCardContainer from '../../containers/Space/SpaceCardContainer';
import { CreateNewText } from '../../types/global';
import styles from './SpaceCard.module.css';

interface Props {
  id: string;
  title: string;
  equipmentCount: number;
  sensorCount: number;
  gatewayCount: number;
  showNewModal: (id: string) => void;
  showEditModal: (id: string) => void;
  showDeleteModal: () => void;
  primeForDelete: (id: string) => void;
  getSpace: (id: string) => void;
  nestedSpaces: string[];
  toggleExpand: (id) => void;
  expanded: boolean;
  paddingLeft: number;
}

export default ({
  id,
  title,
  equipmentCount,
  sensorCount,
  gatewayCount,
  showDeleteModal,
  showNewModal,
  showEditModal,
  primeForDelete,
  getSpace,
  nestedSpaces,
  toggleExpand,
  expanded = true,
  paddingLeft,
}: Props) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <Columns template=".25fr .25fr .47fr .03fr">
        <div style={{ paddingLeft: `${paddingLeft}rem` }}>
          <Columns template="2rem auto" gap=".5rem">
            {nestedSpaces.length > 0 ? (
              <button className={styles.arrow} onClick={toggleExpand}>
                {expanded ? (
                  <IoIosArrowDown size="16" />
                ) : (
                  <IoIosArrowForward size="16" />
                )}
              </button>
            ) : (
              <div />
            )}

            <div className={styles.spaceTitle}>
              <h3
                onClick={() => {
                  getSpace(id.toString());
                  showEditModal(id.toString());
                }}
              >
                {title}
              </h3>
            </div>
          </Columns>
        </div>

        <div className={styles.autoMargin}>{`${nestedSpaces.length}`}</div>
        <Columns>
          <div className={styles.autoMargin}>{`${equipmentCount}`}</div>
          <div className={styles.autoMargin}>{`${sensorCount}`}</div>
          <div className={styles.autoMargin}>{`${gatewayCount}`}</div>
        </Columns>
        <div className={styles.menuDropdownContainer}>
          <MenuDropdown>
            <MenuDropdownItem
              onSelect={() => {
                getSpace(id.toString());
                showEditModal(id.toString());
              }}
            >
              Space Details
            </MenuDropdownItem>
            <MenuDropdownItem onSelect={() => showNewModal(id)}>
              {CreateNewText.SPACE}
            </MenuDropdownItem>
            <MenuDropdownItem
              isRed
              onSelect={() => {
                primeForDelete(id);
                showDeleteModal();
              }}
            >
              Delete Space
            </MenuDropdownItem>
          </MenuDropdown>
        </div>
      </Columns>

      {expanded &&
        nestedSpaces.map(spaceId => (
          <SpaceCardContainer
            key={spaceId}
            id={spaceId}
            paddingLeft={paddingLeft + 1}
          />
        ))}
    </div>
  </div>
);
