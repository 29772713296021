import { Box, Divider, List, ListItem } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { isAllReady, UploadFileUploading } from '../../../../types/upload';
import styles from './UploadInstallDoc.module.css';
import Button, {
  FileUploadButton,
} from '@energybox/react-ui-library/dist/components/Button';
import { FileType as UploadFileType } from '../../../../types/files';
import { useUploads, UseUploads } from '../../../../hooks/fileUpload/useUpload';
import UploadInstallDocCell from './UploadInstallDocCell';
import { uploadFile } from '../../../../actions/files';
import FileDragAndDrop from '../../../../components/FileUploader/FileDragAndDrop/FileDragAndDrop';
import { supportInstallationDocFileType } from '@energybox/react-ui-library/dist/types/Files';
import { useFiles } from '../../../../hooks/files/useFile';
import { Loader } from '@energybox/react-ui-library/dist/components';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';

interface Props extends Partial<UseUploads<{}>> {
  siteId?: number;
  id?: string;
}

const UploadInstallDoc: React.FC<Props> = ({ siteId, id }) => {
  const accept = supportInstallationDocFileType.join(',');

  const {
    uploads,
    addUploads,
    removeUpload,
    changeUpload,
    isSomeUploading,
    batchUpload,
    clearUpload,
    isUnSavable,
  } = useUploads<{}>();

  const isMobile = useViewportType() === ViewportTypes.MOBILE;

  const uploadSingleFile = async (upload: UploadFileUploading<{}>) => {
    return await uploadFile({
      fileType: UploadFileType.SITE_INSTALLATION_REPORT,
      upload,
      siteId,
    });
  };

  const { getFiles } = useFiles({
    fileType: UploadFileType.SITE_INSTALLATION_REPORT,
    siteId,
  });

  useEffect(() => {
    if (!isSomeUploading) {
      getFiles();
    }
  }, [isSomeUploading]);

  return (
    <Box
      className={styles.filesUploadContent}
      id={id}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <FileDragAndDrop
        addUploads={addUploads}
        isUploading={isSomeUploading}
        accept={accept}
        style={{
          height: '6.8rem',
        }}
        className={styles.dragAndDropComp}
        children={
          <span>
            Drag files to upload or click on
            <span className={styles.browseFiles}>
              <FileUploadButton
                multiple
                handleFiles={addUploads}
                nowrap
                accept={accept}
                variant="text"
              >
                <span className={styles.browseFilesText}>Browse Files</span>
              </FileUploadButton>
            </span>
          </span>
        }
      />
      <div className={styles.filesUploadContent}>
        <List disablePadding>
          {uploads.map((upload, idx) => (
            <Fragment key={idx}>
              <ListItem className={styles.listItem}>
                <UploadInstallDocCell
                  remove={removeUpload}
                  uploadDidChange={changeUpload}
                  upload={upload}
                  uploadFile={uploadSingleFile}
                  fileIdx={idx}
                  isUndeletable={isSomeUploading}
                />
              </ListItem>
              {idx !== uploads.length - 1 && <Divider />}
            </Fragment>
          ))}
        </List>
      </div>
      {uploads.length > 0 && (
        <Box className={styles.flexRight}>
          <Button variant="text" onClick={clearUpload} children="Cancel" />
          <Button disabled={isUnSavable} onClick={batchUpload}>
            {isSomeUploading ? (
              <Loader size={16} variant="secondary" />
            ) : (
              'Upload'
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UploadInstallDoc;
