import { WizardCard } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import FeatureFlag from '../../../containers/FeatureFlag';
import styles from './StepReminderType.module.css';
import {
  ReminderConfiguration,
  ReminderType,
} from '@energybox/react-ui-library/dist/types/Reminder';
import { ReminderTypeIcon } from '../../../utils/reminder';

type StepSensorTypeCardProps = {
  onChange: (reminderType: ReminderType) => void;
  reminderType: ReminderType;
  reminderConfiguration: ReminderConfiguration;
};

const StepReminderTypeCard = ({
  reminderConfiguration,
  reminderType,
  onChange,
}: StepSensorTypeCardProps) => (
  <>
    <WizardCard
      className={styles.wizardCard}
      onClick={() => onChange(reminderConfiguration.type)}
      icon={<ReminderTypeIcon type={reminderConfiguration.type} size={80} />}
      title={reminderConfiguration.title}
      description={reminderConfiguration.description}
      active={reminderType === reminderConfiguration.type}
    />
  </>
);

type StepTypeProps = {
  onChange: (reminderType: ReminderType) => void;
  reminderType: ReminderType;
  reminderTypesList: ReminderConfiguration[];
};

const StepReminderType = ({
  onChange,
  reminderType,
  reminderTypesList,
}: StepTypeProps) => {
  const renderReminderConfig = (reminderConfig: ReminderConfiguration) => {
    return (
      <StepReminderTypeCard
        key={`StepType${reminderConfig.type}`}
        reminderType={reminderType}
        reminderConfiguration={reminderConfig}
        onChange={onChange}
      />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.operationalContainer}>
        {/* <span className={styles.operationalTitle}>Operations</span> */}
        <div className={styles.operationalTiles}>
          {reminderTypesList.map(renderReminderConfig)}
        </div>
      </div>
    </div>
  );
};

export default StepReminderType;
