import {
  EnergyPro,
  MainBreaker,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  Tabs,
  Tab,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { reset as resetBreaker } from '../../../actions/circuit_breakers';
import {
  Actions,
  displayFormErrors as displayPanelFormErrors,
  patch as patchPanel,
  reset as resetPanel,
  updateField,
} from '../../../actions/distribution_panel';
import {
  displayFormErrors as displayMainBreakerFormErrors,
  patch as patchMainBreaker,
} from '../../../actions/main_breakers';
import { getResourcePathById } from '../../../actions/paths';
import EditDistributionPanelForm from '../../../components/EditDistributionPanelForm';
import EditMainBreakerForm from '../../../components/EditMainBreakerForm';
import { ApplicationState } from '../../../reducers';
import { EditCircuitBreaker } from '../../../reducers/circuit_breakers';
import { EditDistributionPanel } from '../../../reducers/distribution_panels';
import { EditMainBreaker } from '../../../reducers/main_breakers';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import CircuitBreakersTab from '../../CircuitBreakers/CircuitBreakersTab';
import ImagesContainer from '../../ImagesContainer';
import styles from './DistributionPanelConfiguration.module.css';

interface OwnProps {
  id: string;
  expandedId: string | number;
  toggleCardState: (id: string) => void;
  currentSiteId: number;
  selectedBreakerId: number;
  resetSelectedBreakerId: () => void;
  mainBreaker: MainBreaker;
  panelColumns: number;
  energyPro: EnergyPro;
}

interface Props extends OwnProps {
  editDistributionPanel: EditDistributionPanel;
  editCircuitBreakers: EditCircuitBreaker[];
  editMainBreaker: EditMainBreaker;
  onChange: (field: string, value: string | number) => void;
  patchPanel: () => void;
  patchMainBreaker: () => void;
  resetPanel: () => void;
  displayPanelFormErrors: () => void;
  displayMainBreakerFormErrors: () => void;
  resetBreaker: typeof resetBreaker;
}

interface State {
  activeTab: TabNames;
}

enum TabNames {
  GENERAL_SETTINGS = 'General Settings',
  MAIN_BREAKER = 'Main Breaker',
  CIRCUIT_BREAKERS = 'Circuit Breakers',
  IMAGES = 'Images',
}

class DistributionPanelConfiguration extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TabNames.GENERAL_SETTINGS,
    };
  }

  componentDidUpdate() {
    const { selectedBreakerId, mainBreaker } = this.props;
    const { activeTab } = this.state;

    const isCircuitBreakerSelected =
      selectedBreakerId !== -1 &&
      String(selectedBreakerId) !== String(mainBreaker.id);
    const isMainBreakerSelected =
      String(selectedBreakerId) === String(mainBreaker.id);

    if (isCircuitBreakerSelected && activeTab !== TabNames.CIRCUIT_BREAKERS) {
      this.setState({ activeTab: TabNames.CIRCUIT_BREAKERS });
    }

    if (isMainBreakerSelected && activeTab !== TabNames.MAIN_BREAKER) {
      this.setState({ activeTab: TabNames.MAIN_BREAKER });
    }
  }

  onUpdateDistributionPanel = () => {
    const {
      patchPanel,
      editDistributionPanel,
      displayPanelFormErrors,
    } = this.props;
    if (editDistributionPanel && hasKeys(editDistributionPanel.formErrors)) {
      displayPanelFormErrors();
    } else {
      patchPanel();
    }
  };

  onUpdateMainBreaker = () => {
    const {
      patchMainBreaker,
      editMainBreaker,
      displayMainBreakerFormErrors,
    } = this.props;
    if (editMainBreaker && hasKeys(editMainBreaker.formErrors)) {
      displayMainBreakerFormErrors();
    } else {
      patchMainBreaker();
    }
  };

  onCardClose = () => {
    const {
      editCircuitBreakers,
      toggleCardState,
      resetPanel,
      resetBreaker,
      resetSelectedBreakerId,
    } = this.props;
    toggleCardState('');
    resetPanel();
    resetSelectedBreakerId();
    editCircuitBreakers.forEach((breaker: EditCircuitBreaker) => {
      if (breaker.isChanged) {
        resetBreaker(breaker.fields.id);
      }
    });
  };

  renderCardActions = () => {
    const { editDistributionPanel, editMainBreaker } = this.props;
    const { activeTab } = this.state;

    return (
      <CardActions className={styles.cardActionsContainer}>
        <Button variant="text" onClick={this.onCardClose} children="Close" />

        {activeTab === TabNames.GENERAL_SETTINGS && (
          <Button
            disabled={!editDistributionPanel.isChanged}
            onClick={this.onUpdateDistributionPanel}
          >
            {editDistributionPanel.isLoading ? (
              <Loader size={16} variant="secondary" />
            ) : (
              'Save'
            )}
          </Button>
        )}

        {activeTab === TabNames.MAIN_BREAKER && (
          <Button
            disabled={editMainBreaker && !editMainBreaker.isChanged}
            onClick={this.onUpdateMainBreaker}
          >
            {editMainBreaker && !editMainBreaker.isLoading ? (
              'Save'
            ) : (
              <Loader size={16} variant="secondary" />
            )}
          </Button>
        )}
      </CardActions>
    );
  };

  render() {
    const {
      expandedId,
      editDistributionPanel,
      toggleCardState,
      onChange,
      currentSiteId,
      id,
      selectedBreakerId,
      resetSelectedBreakerId,
      mainBreaker,
      panelColumns,
      energyPro,
    } = this.props;
    const { activeTab } = this.state;
    const cardIsOpen = expandedId === id;
    return (
      <>
        {!cardIsOpen && !expandedId && (
          <div className={styles.isolateCard}>
            <Card
              className={styles.listContainer}
              onClick={() => toggleCardState(id)}
            >
              <CardTitle>
                <span className={styles.cardListTitle}>Distribution Panel</span>
              </CardTitle>
            </Card>
          </div>
        )}

        {cardIsOpen && (
          <Card className={styles.expandedContainer}>
            <div className={styles.expandedContainerMargin}>
              <CardTitle>
                <span className={styles.expandedCardTitle}>
                  Distribution Panel
                </span>
              </CardTitle>

              <div className={styles.tabsContainer}>
                <Tabs>
                  <Tab
                    active={activeTab === TabNames.GENERAL_SETTINGS}
                    onClick={() => {
                      this.setState({ activeTab: TabNames.GENERAL_SETTINGS });
                      resetSelectedBreakerId();
                    }}
                  >
                    General Settings
                  </Tab>

                  <Tab
                    active={activeTab === TabNames.MAIN_BREAKER}
                    onClick={() => {
                      this.setState({ activeTab: TabNames.MAIN_BREAKER });
                      resetSelectedBreakerId();
                    }}
                  >
                    Main Breaker
                  </Tab>

                  <Tab
                    active={activeTab === TabNames.CIRCUIT_BREAKERS}
                    onClick={() => {
                      this.setState({ activeTab: TabNames.CIRCUIT_BREAKERS });
                      resetSelectedBreakerId();
                    }}
                  >
                    Circuit Breakers
                  </Tab>

                  <Tab
                    active={activeTab === TabNames.IMAGES}
                    onClick={() => {
                      this.setState({ activeTab: TabNames.IMAGES });
                      resetSelectedBreakerId();
                    }}
                  >
                    Images
                  </Tab>
                </Tabs>
              </div>

              <div className={styles.tabContent}>
                {activeTab === TabNames.GENERAL_SETTINGS && (
                  <CardContent>
                    <EditDistributionPanelForm
                      patchingPanel
                      fields={editDistributionPanel.fields}
                      onChange={onChange}
                      siteId={currentSiteId}
                      formErrorsVisible={
                        editDistributionPanel.formErrorsVisible
                      }
                      formErrors={editDistributionPanel.formErrors}
                      apiError={editDistributionPanel.apiError}
                      isChanged={editDistributionPanel.isChanged}
                    />
                  </CardContent>
                )}

                {activeTab === TabNames.MAIN_BREAKER && (
                  <CardContent>
                    <EditMainBreakerForm
                      mainBreaker={mainBreaker}
                      energyPro={energyPro}
                      // onChange={onChange}
                    />
                  </CardContent>
                )}

                {activeTab === TabNames.CIRCUIT_BREAKERS && (
                  <div className={styles.breakerTabContainer}>
                    <CircuitBreakersTab
                      panelId={parseInt(id)}
                      currentSiteId={currentSiteId}
                      selectedBreakerId={selectedBreakerId}
                      resetSelectedBreakerId={resetSelectedBreakerId}
                      panelColumns={panelColumns}
                      energyPro={energyPro}
                    />
                  </div>
                )}

                {activeTab === TabNames.IMAGES && (
                  <div className={styles.imagesTabContainer}>
                    <ImagesContainer
                      id={id}
                      type={ResourceType.DISTRIBUTIONPANEL}
                    />
                    {renderAPIerror(
                      editDistributionPanel.apiError,
                      Actions.DELETE_PICTURE_ERROR,
                      Actions.UPLOAD_PICTURE_ERROR
                    )}
                  </div>
                )}
              </div>
            </div>

            {this.renderCardActions()}
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (
  { distributionPanels, circuitBreakers, mainBreakers }: ApplicationState,
  { id, mainBreaker }: OwnProps
) => {
  return {
    editDistributionPanel: distributionPanels.editById[id],
    editMainBreaker: mainBreakers.editById[mainBreaker.id],
    editCircuitBreakers: Object.keys(circuitBreakers.editById)
      .filter(key => key !== 'new')
      .map(key => circuitBreakers.editById[key]),
  };
};

const mapDispatchToProps = (dispatch: any, { id, mainBreaker }: OwnProps) => ({
  patchPanel: () => {
    dispatch(patchPanel(id));
    dispatch(getResourcePathById(parseInt(id)));
  },
  patchMainBreaker: () => dispatch(patchMainBreaker(mainBreaker.id, id)),
  resetPanel: () => dispatch(resetPanel(id)),
  onChange: (field: string, value: string | number) =>
    dispatch(updateField(id, field, value)),
  displayPanelFormErrors: () => dispatch(displayPanelFormErrors(id)),
  displayMainBreakerFormErrors: () =>
    dispatch(displayMainBreakerFormErrors(mainBreaker.id)),
  resetBreaker: (breakerId: number) => dispatch(resetBreaker(breakerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionPanelConfiguration);
