import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './FileDragAndDrop.module.css';

interface Props {
  addUploads: (files: File[]) => void;
  accept?: string | string[];
  isUploading: boolean;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const FileDragAndDrop: React.FC<Props> = ({
  addUploads,
  accept,
  isUploading,
  className,
  style,
  children,
}) => {
  const { getRootProps, isDragActive } = useDropzone({
    onDrop: addUploads,
    accept,
  });
  const appendValidChildren = () => {
    switch (true) {
      case isUploading:
        return 'Uploading...';
      case isDragActive:
        return 'Drop files here';
      case children != null:
        return children;
      default:
        return 'Drag files to Upload';
    }
  };
  return (
    <div
      className={classNames(styles.dragFile, className)}
      {...(!isUploading ? getRootProps() : {})}
      style={style}
    >
      {appendValidChildren()}
    </div>
  );
};

export default FileDragAndDrop;
