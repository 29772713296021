import { TimeTableTimeSlot } from '@energybox/react-ui-library/dist/types';

type HasStringTitle = {
  title: string;
};

export const sortByTitleWithEmptyStringToLast = (
  { title: aTitle }: HasStringTitle,
  { title: bTitle }: HasStringTitle
) => {
  if (aTitle && bTitle) return aTitle.localeCompare(bTitle);
  else if (aTitle) return -1;
  else return 1;
};

// only simple time comparison is implemented
// null value will be sorted to last
export const sortTimeTableTimeSlot = (
  { time: aTime }: TimeTableTimeSlot,
  { time: bTime }: TimeTableTimeSlot
) => {
  if (aTime && bTime) return aTime.localeCompare(bTime);
  else if (aTime) return -1;
  else return 1;
};
