import React from 'react';

import { EnergySensor } from '@energybox/react-ui-library/dist/types';
import {
  KW_UNIT,
  KW_DECIMAL_POINT,
} from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { SubscribedEnergyProSensorsReadingBySensorId } from '../../reducers/subscribedEnergyPros';
import LiveReadingTable, {
  RowCellFormatter,
} from '../LiveReadingTable/LiveReadingTable';

const PowerReadingsHeaders = ['Power Readings', ''];

const PowerReadings = ({
  energySensors,
}: {
  energySensors?: EnergySensor[];
}) => {
  const readingsByEnergySensorId = useSelector<
    ApplicationState,
    SubscribedEnergyProSensorsReadingBySensorId
  >(({ subscribedEnergyPros }) => {
    return subscribedEnergyPros.subscribedEnergyProSensorsReadingBySensorId;
  }, equals);

  if (!energySensors) return null;

  if (energySensors.length === 1) {
    const sensorReading = readingsByEnergySensorId[energySensors[0].id];
    const rowData = [
      [
        { label: 'P' },
        {
          value: sensorReading?.powerActive,
          unit: KW_UNIT,
          decimal: KW_DECIMAL_POINT,
        },
      ],
      [
        { label: 'VAr' },
        { value: sensorReading?.powerActive, unit: 'kVAr', decimal: 2 },
      ],
      [
        { label: 'VA' },
        { value: sensorReading?.powerApparent, unit: 'kVA', decimal: 2 },
      ],
      [{ label: 'PF' }, { value: sensorReading?.powerFactor, decimal: 2 }],
    ];
    return <LiveReadingTable headers={PowerReadingsHeaders} rows={rowData} />;
  }

  const activePowerSum = energySensors.reduce((sum, energySensor) => {
    const energyReading = readingsByEnergySensorId[energySensor.id];
    if (!energyReading) return sum;
    const { powerActive } = readingsByEnergySensorId[energySensor.id];
    return sum + powerActive;
  }, 0);

  const reactivePowerSum = energySensors.reduce((sum, energySensor) => {
    const energyReading = readingsByEnergySensorId[energySensor.id];
    if (!energyReading) return sum;
    const { powerReactive } = readingsByEnergySensorId[energySensor.id];
    return sum + powerReactive;
  }, 0);

  const apparentPowerSum = energySensors.reduce((sum, energySensor) => {
    const energyReading = readingsByEnergySensorId[energySensor.id];
    if (!energyReading) return sum;
    const { powerApparent } = readingsByEnergySensorId[energySensor.id];
    return sum + powerApparent;
  }, 0);

  // CAUTION
  // hack per Daniel Tsang's request to address rounding
  // that may cause PF to be greater than 1
  let powerFactorSum = apparentPowerSum
    ? activePowerSum / apparentPowerSum
    : undefined;
  if (powerFactorSum && powerFactorSum > 1) powerFactorSum = 1;

  const rowData: Array<RowCellFormatter[]> = [
    [
      { label: 'P' },
      { value: activePowerSum, unit: KW_UNIT, decimal: KW_DECIMAL_POINT },
    ],
    [{ label: 'VAr' }, { value: reactivePowerSum, unit: 'kVAr', decimal: 2 }],
    [{ label: 'VA' }, { value: apparentPowerSum, unit: 'kVA', decimal: 2 }],
    [{ label: 'PF' }, { value: powerFactorSum, decimal: 2 }],
  ];

  return <LiveReadingTable headers={PowerReadingsHeaders} rows={rowData} />;
};

export default PowerReadings;
