import { ProgressBar } from '@energybox/react-ui-library/dist/components';
import { displayByte } from '@energybox/react-ui-library/dist/utils';
import { Grid } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToUploadProgress,
  unsubscribeFromUploadProgress,
} from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';
import {
  StreamData,
  StreamStatus,
  UploadFileUploaded,
  UploadFileUploading,
  UploadResponse,
  UploadStatus,
} from '../../types/upload';
import styles from './FileUploader.module.css';
import { path } from 'ramda';

interface Props<T extends object> {
  upload: UploadFileUploading<T>;
  uploadDidFinish: (done: UploadFileUploaded<T>) => void;
  siteId?: number;
  uploadFile: (
    upload: UploadFileUploading<T>,
    siteId?: number
  ) => Promise<UploadResponse>;
  streamDataPath?: string[];
}

// Where the actual upload logic takes place

// Upload started the moment this component is mounted
// Upload progress is contained in this component
// to prevent whole UtilityBillCard re-rendered

// Logic:
// 1. Call upload api and get fileId
// 2. subscribe to stream api with fileId to listen upload progress
// 3. useUploadProgress custom hook will update redux when receiving upload progress
// 4. streamData retrieved from redux update component states
// 5. call uploadDidFinish when streamData indicates an upload success (or failure)
// 6. uploadDidFinish will unmount this component (by logic in UploadCell)
// 7. Unsubscribe stream and redux whenever this component is unmounted (normal hook lifecycle)
const FileUploader = <T extends object>({
  upload,
  uploadDidFinish,
  // siteId,
  uploadFile,
  streamDataPath = ['files', 'progressByFileId'],
}: Props<T>): ReactElement => {
  const dispatch = useDispatch();
  const { file } = upload;
  const { size } = file;

  const [fileId, setFileId] = useState<string>();
  const [progress, setProgress] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [status, setStatus] = useState<StreamStatus>();

  const streamData = useSelector<ApplicationState, StreamData>(
    applicationState =>
      path([...streamDataPath, fileId || ''], applicationState)
  );

  const uploadDidFail = () =>
    uploadDidFinish({
      ...upload,
      fileId: '',
    });

  useEffect(() => {
    uploadFile(upload).then(
      res => {
        const { fileId } = res;
        setFileId(fileId);
      },
      err => uploadDidFail()
    );
  }, []);

  useEffect(() => {
    if (fileId) dispatch(subscribeToUploadProgress(fileId));
    return () => {
      if (fileId) dispatch(unsubscribeFromUploadProgress(fileId));
    };
  }, [fileId]);

  useEffect(() => {
    if (streamData) {
      const { percentage, transferredBytes, uploadStatus } = streamData;
      setProgress(transferredBytes);
      setPercentage(Math.round(percentage));
      setStatus(uploadStatus);
    }
  }, [streamData]);

  useEffect(() => {
    if (fileId) {
      if (status === StreamStatus.FAILED) uploadDidFail();
      else if (percentage === 100 && status === StreamStatus.SUCCESS) {
        setTimeout(
          () =>
            uploadDidFinish({
              ...upload,
              fileId,
              status: UploadStatus.done,
            }),
          1500
        ); // wait for 1.5 second for better UX
      }
    }
  }, [status]);

  return (
    <Grid container className={styles.root}>
      <Grid item xs={12}>
        <ProgressBar perceivedProgress={progress} totalProgress={size} />
      </Grid>
      <Grid item xs={6}>
        {displayByte(progress)} of {displayByte(size)}
      </Grid>
      <Grid item xs={6} className={styles.uploadStatus}>
        <span className={styles.uploading}>Uploading.....</span>
        <span className={styles.uploading}>{percentage}%</span>
      </Grid>
    </Grid>
  );
};

export default FileUploader;
