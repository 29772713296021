import {
  SubscribedThermostat,
  Thermostat,
} from '@energybox/react-ui-library/dist/types';
import {
  determineLiveStatusFieldTextColor,
  getRemoteSensorsCount,
} from '../../../Controls/HvacControlsContent';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

type Props = {
  subscribedThermostat: SubscribedThermostat;
  thermostat: Thermostat | null;
  isDeviceOnline: boolean;
};
const SensorCount: React.FC<Props> = ({
  subscribedThermostat,
  thermostat,
  isDeviceOnline,
}) => {
  const liveDetectedSensors = getRemoteSensorsCount(
    subscribedThermostat?.additionalTemperatureReadings
  );
  const configRemoteSensors = thermostat?.wirelessTemperatureSensorsCount;

  const totalSensorCount = isDefined(liveDetectedSensors)
    ? liveDetectedSensors
    : global.NOT_AVAILABLE;
  const configuredSensorCount = isDefined(configRemoteSensors)
    ? configRemoteSensors
    : global.NOT_AVAILABLE;
  return (
    <>
      {isDefined(thermostat) && thermostat !== null && isDeviceOnline ? (
        <div
          className={determineLiveStatusFieldTextColor(
            configuredSensorCount,
            totalSensorCount,
            true
          )}
        >
          {totalSensorCount} / {configuredSensorCount}
        </div>
      ) : (
        global.NOT_AVAILABLE
      )}
    </>
  );
};
export default SensorCount;
