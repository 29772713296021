import {
  ApplicationMenu,
  ApplicationMenuItem,
} from '@energybox/react-ui-library/dist/components';
import {
  AlertConfig as AlertConfigIcon,
  ControlsIcon,
  Dashboard as DashboardIcon,
  Equipment as EquipmentIcon,
  Home as HomeIcon,
  SensorGateway as SensorGatewayIcon,
  Sites as SitesIcon,
  Sop as SopIcon,
  User as UserIcon,
} from '@energybox/react-ui-library/dist/icons';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';

import React, { ReactElement, useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { switchOrganization, toggleMobileMenu } from '../../actions/app';
import DashboardPage from '../../containers/Dashboard/DashboardPage';
import RunConfigurationTest from '../../containers/DistributionPanels/RunConfigurationTest/RunConfigurationTest';
import InstallerDashboard from '../../containers/Dashboard/InstallerDashboardPage';
import ShowDistributionPanelPage from '../../containers/DistributionPanels/ShowDistributionPanelPage/ShowDistributionPanelPage';
import EquipmentsPage from '../../containers/Equipment/EquipmentsPage';
import ShowEquipmentPage from '../../containers/Equipment/ShowEquipmentPage';
import FeatureFlag from '../../containers/FeatureFlag';
import Footer from '../../containers/Footer';
import ShowGatewayPageContainer from '../../containers/Gateways/ShowGatewayPageContainer';
import InstallerGroupsPage from '../../containers/UserGroup/InstallerGroupsPage';
import ShowFindMySensorPage from '../../containers/InventoryManagement/ShowFindMySensorPage';
import Modals from '../../containers/Modals';
import MyDownloads from '../../containers/MyDownloads';
import ShowNetworkGroupPage from '../../containers/NetworkGroups/ShowNetworkGroupPage';
import ShowOrganizationPage from '../../containers/Organizations/ShowOrganizationPage';
import OrganizationEBSettingsPage from '../../containers/OrganizationsEBSettings/OrganizationEBSettingsPage';
import ShowSensorPage from '../../containers/Sensors/ShowSensorPage/ShowSensorPage';
import SentinelsCreatePage from '../../containers/Sentinels/SentinelsCreatePage';
import SentinelsIndexPage from '../../containers/Sentinels/SentinelsIndexPage';
import ShowSentinelPage from '../../containers/Sentinels/ShowSentinelPage';
import ChangePasswordPage from '../../containers/Settings/ChangePasswordPage';
import ShowSitePageContainer from '../../containers/Site/ShowSitePageContainer';
import ShowSitesGroupPage from '../../containers/Site/ShowSitesGroupPage';
import SitesPage from '../../containers/Site/SitesPage';
import SiteGroupsPage from '../../containers/SiteGroup/SiteGroupsPage';
import ShowOrganizationSopsPage from '../../containers/Sop/ShowOrganizationSopsPage';
import TopBarContainer from '../../containers/TopBarContainer';
import ShowUserPage from '../../containers/User/ShowUserPage';
import UsersPage from '../../containers/User/UsersPage';
import ShowUsersGroupPage from '../../containers/UserGroup/ShowUsersGroupPage';
import UserGroupsPage from '../../containers/UserGroup/UserGroupsPage';
import { useInstallerAccess } from '../../hooks/useInstallerAccess';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { InstallerAccess } from '../../types/user';
import styles from './PrimaryLayout.module.css';
import DevicesContainer from '../../containers/DevicesContainer';
import RemindersCreatePage from '../../containers/Sentinels/RemindersCreatePage';
import ShowReminderPage from '../../containers/Sentinels/ShowReminderPage';
import { EB_INSTALLER_WHITELIST } from '../../featureFlagSettings';
import OrgControlsManagement from '../../containers/OrgControlsManagement/OrgControlsManagement';
import { pageNotFoundError } from '../../utils/ApiError/pageNotFoundError';
import { isInSitesPage } from '../../util';
import InstallationManual from '../../containers/InstallationManual/InstallationManual';
import License from '../../containers/License';
import { isCustomer } from '@energybox/react-ui-library/dist/utils';
import { handlePlatformSwitch } from '../../utils/platformRedirection';

interface Props {
  accessToken?: string;
  currentOrganizationId?: number;
  orgTitle: string | undefined;
}

type MenuItems = {
  title: string;
  icon: ReactElement;
  to: string;
  activeOn: RegExp;
  isFeatureFlagged?: boolean;
};

const ReusableMenuItems = {
  Overview: {
    title: 'Overview',
    icon: <DashboardIcon size={30} />,
    to: '/',
    activeOn: new RegExp(/^\/$/),
  },
  Sites: {
    title: 'Sites',
    icon: <SitesIcon size={30} />,
    to: `${Routes.SITES}`,
    activeOn: new RegExp(`^(${Routes.SITES}|${Routes.SITE_GROUPS})`, 'g'),
  },
};

const getMenuItems = (
  access: InstallerAccess | undefined,
  passwordUpdateEnforced?: boolean,
  currentOrgType?: OrganizationType
): MenuItems[] => {
  if (passwordUpdateEnforced) return [];
  switch (access) {
    case InstallerAccess.DEFAULT:
      return [
        // The ordering matters. Sites regex supersedes the other ones.
        ReusableMenuItems.Overview,
        ReusableMenuItems.Sites,
        {
          title: 'Equipment',
          icon: <EquipmentIcon size={30} />,
          to: `${Routes.EQUIPMENT}`,
          activeOn: new RegExp(
            `^(${Routes.EQUIPMENT}|${Routes.DISTRIBUTION_PANELS})`,
            'g'
          ),
        },
        {
          title: 'Devices',
          icon: <SensorGatewayIcon size={30} />,
          to: `${Routes.DEVICES}${Routes.GATEWAYS}`,
          activeOn: new RegExp(
            `^(${Routes.DEVICES}|${Routes.SENSORS}|${Routes.GATEWAYS}|${Routes.NETWORK_GROUPS})`,
            'g'
          ),
        },
        {
          title: 'Users',
          icon: <UserIcon size={30} />,
          to: `${Routes.USERS}`,
          activeOn: new RegExp(`^(${Routes.USERS}|${Routes.USER_GROUPS})`, 'g'),
        },
        {
          title: 'SOP',
          icon: <SopIcon size={30} />,
          to: `${Routes.SOPS}${Routes.POLICIES}`,
          activeOn: new RegExp(`^(${Routes.SOPS})`, 'g'),
        },
        {
          title: 'Controls',
          icon: <ControlsIcon size={30} />,
          to: `${Routes.CONTROLS}${Routes.HVAC_CONTROLS}`,
          activeOn: new RegExp(`^(${Routes.CONTROLS})`, 'g'),
        },
        {
          title: 'Notifications',
          icon: <AlertConfigIcon size={30} fill="white" />,
          to: `${Routes.SENTINELS}`,
          activeOn: new RegExp(`^(${Routes.SENTINELS})`, 'g'),
        },
      ];
    case InstallerAccess.ADMIN:
      return [
        {
          title: 'Users',
          icon: <UserIcon size={30} />,
          to: `${Routes.INSTALLERS}`,
          activeOn: /^(\/install|\/user)/g,
        },
      ];
    case InstallerAccess.INSTALLER:
      if (currentOrgType === OrganizationType.INSTALLER_PARTNER)
        return [ReusableMenuItems.Overview];
      else return [ReusableMenuItems.Sites];
    case InstallerAccess.DENIED:
    case undefined:
      return [];
  }
};

const EnforcedChangePasswordPage = () => <ChangePasswordPage enforced />;

const getLandingRouteProps = (
  access: InstallerAccess | undefined,
  passwordUpdateEnforced?: boolean,
  currentOrganizationType?: OrganizationType
): RouteProps => {
  if (passwordUpdateEnforced) return { component: EnforcedChangePasswordPage };
  switch (access) {
    case InstallerAccess.DEFAULT:
      return { component: DashboardPage };
    case InstallerAccess.ADMIN:
      return { render: () => <Redirect to={Routes.USERS} /> };
    case InstallerAccess.INSTALLER:
      if (currentOrganizationType === OrganizationType.INSTALLER_PARTNER)
        return { component: InstallerDashboard };
      return { render: () => <Redirect to={Routes.SITES} /> };
    case InstallerAccess.DENIED:
      return { render: () => 'Access Denied' };
    case undefined:
      return { render: () => 'Loading...' };
  }
};

const genKeyFromPath = (path: string) =>
  path
    .replaceAll('/:', '-')
    .replaceAll('/', '-')
    .slice(1);

const SITE_ROUTES = [
  <Route
    key={genKeyFromPath(`${Routes.SITES}/:id`)}
    path={`${Routes.SITES}/:id`}
    render={({ match }) => match && <ShowSitePageContainer {...match.params} />}
  />,
  <Route
    key={genKeyFromPath(Routes.SITES)}
    path={Routes.SITES}
    component={SitesPage}
  />,
];

const DETAILS_PAGE_ROUTES = [
  <Route
    key={genKeyFromPath(`${Routes.EQUIPMENT}/:id`)}
    path={`${Routes.EQUIPMENT}/:id`}
    render={({ match }) => match && <ShowEquipmentPage {...match.params} />}
  />,
  <Route
    key={genKeyFromPath(`${Routes.DEVICES}${Routes.GATEWAYS}/:id`)}
    path={`${Routes.DEVICES}${Routes.GATEWAYS}/:id`}
    render={({ match }) =>
      match && <ShowGatewayPageContainer {...match.params} />
    }
  />,

  <Route
    key={genKeyFromPath(`${Routes.DEVICES}${Routes.SENSORS}/:id`)}
    path={`${Routes.DEVICES}${Routes.SENSORS}/:id`}
    render={({ match }) => match && <ShowSensorPage {...match.params} />}
  />,

  <Route
    key={genKeyFromPath(`${Routes.DEVICES}${Routes.NETWORK_GROUPS}/:id`)}
    path={`${Routes.DEVICES}${Routes.NETWORK_GROUPS}/:id`}
    render={({ match }) => match && <ShowNetworkGroupPage {...match.params} />}
  />,

  <Route
    key={genKeyFromPath(`${Routes.DISTRIBUTION_PANELS}/:id`)}
    path={`${Routes.DISTRIBUTION_PANELS}/:id`}
    render={({ match }) =>
      match && <ShowDistributionPanelPage {...match.params} />
    }
  />,
];

const FIND_MY_SENSOR_ROUTE = (
  <Route
    key={genKeyFromPath(Routes.FIND_MY_SENSOR)}
    path={Routes.FIND_MY_SENSOR}
    component={ShowFindMySensorPage}
  />
);

const OPEN_SOURCE_LICENSE_ROUTE = (
  <Route
    key={genKeyFromPath(Routes.OPEN_SOURCE_LICENSE)}
    path={Routes.OPEN_SOURCE_LICENSE}
    render={({ match }) => match && <License />}
  />
);

const RUN_CONFIGURATION_TEST_ROUTE = (
  <Route
    key={genKeyFromPath(Routes.RUN_CONFIGURATION_TEST)}
    path={Routes.RUN_CONFIGURATION_TEST}
    component={RunConfigurationTest}
  />
);

const getAvailableRoutes = (
  access: InstallerAccess | undefined,
  passwordUpdateEnforced?: boolean,
  currentOrganizationId?: number,
  currentOrganizationType?: OrganizationType
): React.ReactNode => {
  if (passwordUpdateEnforced)
    return <Route path="/*" component={EnforcedChangePasswordPage} />;
  switch (access) {
    case undefined:
      return <Route path="/*" render={() => 'Loading...'} />;
    case InstallerAccess.DENIED:
      return <Route path="/*" render={() => <Redirect to={homeRoute} />} />;
    case InstallerAccess.INSTALLER:
      let homeRoute = Routes.BASE,
        additionalRoutes: ReactElement[] = [];
      if (currentOrganizationType && isCustomer(currentOrganizationType)) {
        homeRoute = Routes.SITES;
        additionalRoutes = [
          ...SITE_ROUTES,
          ...DETAILS_PAGE_ROUTES,
          FIND_MY_SENSOR_ROUTE,
          RUN_CONFIGURATION_TEST_ROUTE,
        ];
      }
      return [
        ...additionalRoutes,
        OPEN_SOURCE_LICENSE_ROUTE,
        <Route
          key={genKeyFromPath(Routes.INSTALLATION_MANUAL)}
          path={Routes.INSTALLATION_MANUAL}
          component={InstallationManual}
        />,
        <Route
          key={genKeyFromPath(`home`)}
          path="/*"
          render={() => <Redirect to={homeRoute} />}
        />,
      ];
    case InstallerAccess.ADMIN:
      return [
        <Route
          key={genKeyFromPath(`${InstallerAccess.ADMIN}/${Routes.USERS}/:id`)}
          path={`${Routes.USERS}/:id`}
          render={({ match }) =>
            match && <ShowUserPage {...match.params} showInstallers />
          }
        />,
        <Route
          key={genKeyFromPath(`${InstallerAccess.ADMIN}/${Routes.USERS}`)}
          path={Routes.USERS}
          render={() => <Redirect to={Routes.INSTALLERS} />}
        />,
        <Route
          key={genKeyFromPath(`${InstallerAccess.ADMIN}/${Routes.INSTALLERS}`)}
          path={Routes.INSTALLERS}
          render={() => <UsersPage showInstallers />}
        />,

        <Route
          key={genKeyFromPath(
            `${InstallerAccess.ADMIN}/${Routes.USER_GROUPS}/:id`
          )}
          path={`${Routes.USER_GROUPS}/:id`}
          render={({ match }) =>
            match && <ShowUsersGroupPage {...match.params} isInstallerGroup />
          }
        />,
        <Route
          key={genKeyFromPath(`${InstallerAccess.ADMIN}/${Routes.USER_GROUPS}`)}
          path={Routes.USER_GROUPS}
          render={() => <Redirect to={Routes.INSTALLER_GROUPS} />}
        />,
        <Route
          key={genKeyFromPath(
            `${InstallerAccess.ADMIN}/${Routes.INSTALLER_GROUPS}`
          )}
          path={Routes.INSTALLER_GROUPS}
          component={InstallerGroupsPage}
        />,

        OPEN_SOURCE_LICENSE_ROUTE,

        FIND_MY_SENSOR_ROUTE,

        <Route
          key={genKeyFromPath(Routes.INSTALLATION_MANUAL)}
          path={Routes.INSTALLATION_MANUAL}
          component={InstallationManual}
        />,

        <Route
          key={genKeyFromPath(`${InstallerAccess.ADMIN}/${Routes.USERS}`)}
          path="/*"
          render={() => <Redirect to={Routes.USERS} />}
        />,
      ];
    default:
      return [
        <Route
          key={genKeyFromPath(`${Routes.SOPS}`)}
          path={`${Routes.SOPS}`}
          render={({ match }) =>
            match &&
            currentOrganizationId && (
              <ShowOrganizationSopsPage
                orgId={String(currentOrganizationId)}
                {...match.params}
              />
            )
          }
        />,
        <Route
          key={genKeyFromPath(Routes.CONTROLS)}
          path={Routes.CONTROLS}
          render={({ match }) =>
            match &&
            currentOrganizationId && <OrgControlsManagement {...match.params} />
          }
        />,

        <Route
          key={genKeyFromPath(`${Routes.ORGANIZATIONS}/:orgId`)}
          path={`${Routes.ORGANIZATIONS}/:orgId`}
          render={({ match }) =>
            match && (
              <FeatureFlag>
                <ShowOrganizationPage {...match.params} />
              </FeatureFlag>
            )
          }
        />,

        <Route
          key={genKeyFromPath(`${Routes.EB_SETTINGS}/:orgId`)}
          path={`${Routes.EB_SETTINGS}/:orgId`}
          render={({ match }) =>
            match && (
              <FeatureFlag>
                <OrganizationEBSettingsPage {...match.params} />
              </FeatureFlag>
            )
          }
        />,

        ...SITE_ROUTES,

        ...DETAILS_PAGE_ROUTES,

        FIND_MY_SENSOR_ROUTE,

        <Route
          key={genKeyFromPath(Routes.EQUIPMENT)}
          path={Routes.EQUIPMENT}
          component={EquipmentsPage}
        />,

        <Route
          key={genKeyFromPath(`${Routes.USERS}/:id`)}
          path={`${Routes.USERS}/:id`}
          render={({ match }) => match && <ShowUserPage {...match.params} />}
        />,
        <Route
          key={genKeyFromPath(Routes.INSTALLERS)}
          path={Routes.INSTALLERS}
          render={() => <Redirect to={Routes.USERS} />}
        />,
        <Route
          key={genKeyFromPath(Routes.USERS)}
          path={Routes.USERS}
          component={UsersPage}
        />,

        <Route
          key={genKeyFromPath(`${Routes.USER_GROUPS}/:id`)}
          path={`${Routes.USER_GROUPS}/:id`}
          render={({ match }) =>
            match && <ShowUsersGroupPage {...match.params} />
          }
        />,
        <Route
          key={genKeyFromPath(Routes.USER_GROUPS)}
          path={Routes.USER_GROUPS}
          component={UserGroupsPage}
        />,

        <Route
          key={genKeyFromPath(`${Routes.SITE_GROUPS}/:id`)}
          path={`${Routes.SITE_GROUPS}/:id`}
          render={({ match }) =>
            match && <ShowSitesGroupPage {...match.params} />
          }
        />,

        <Route
          key={genKeyFromPath(Routes.SITE_GROUPS)}
          path={Routes.SITE_GROUPS}
          component={SiteGroupsPage}
        />,

        <Route
          key={genKeyFromPath(`${Routes.DEVICES}`)}
          path={`${Routes.DEVICES}`}
          component={DevicesContainer}
        />,

        <Route
          key={genKeyFromPath(`${Routes.SENTINELS}/create`)}
          path={`${Routes.SENTINELS}/create`}
          component={SentinelsCreatePage}
        />,

        <Route
          key={genKeyFromPath(`${Routes.SENTINELS}/:id/:step`)}
          path={`${Routes.SENTINELS}/:id/:step`}
          render={({ match }) =>
            match && <ShowSentinelPage {...match.params} />
          }
        />,

        <Route
          key={genKeyFromPath(`${Routes.SENTINELS}/:id`)}
          path={`${Routes.SENTINELS}/:id`}
          render={({ match }) =>
            match && <ShowSentinelPage {...match.params} />
          }
        />,

        <Route
          key={genKeyFromPath(Routes.SENTINELS)}
          path={Routes.SENTINELS}
          component={SentinelsIndexPage}
        />,

        <Route
          key={genKeyFromPath(Routes.MY_DOWNLOADS_PAGE)}
          path={Routes.MY_DOWNLOADS_PAGE}
          component={MyDownloads}
        />,

        <Route
          key={genKeyFromPath(`${Routes.REMINDER}/create`)}
          path={`${Routes.REMINDER}/create`}
          component={RemindersCreatePage}
        />,

        <Route
          key={genKeyFromPath(`${Routes.REMINDER}/:id/:step`)}
          path={`${Routes.REMINDER}/:id/:step`}
          render={({ match }) =>
            match && <ShowReminderPage {...match.params} />
          }
        />,

        <Route
          key={genKeyFromPath(`${Routes.REMINDER}/:id`)}
          path={`${Routes.REMINDER}/:id`}
          render={({ match }) =>
            match && <ShowReminderPage {...match.params} />
          }
        />,

        <Route
          key={genKeyFromPath(Routes.REMINDER)}
          path={Routes.REMINDER}
          component={SentinelsIndexPage}
        />,

        <Route
          key={genKeyFromPath(`${Routes.RUN_CONFIGURATION_TEST}/:id`)}
          path={`${Routes.RUN_CONFIGURATION_TEST}/:id`}
          render={({ match }) =>
            match && (
              <FeatureFlag orgWhiteList={EB_INSTALLER_WHITELIST}>
                <RunConfigurationTest {...match.params} />
              </FeatureFlag>
            )
          }
        />,

        <Route
          key={genKeyFromPath(Routes.INSTALLATION_MANUAL)}
          path={Routes.INSTALLATION_MANUAL}
          component={InstallationManual}
        />,

        <Route
          key={genKeyFromPath(Routes.OPEN_SOURCE_LICENSE)}
          path={Routes.OPEN_SOURCE_LICENSE}
          render={({ match }) => match && <License />}
        />,

        <Route component={pageNotFoundError} />,
      ];
  }
};

const PrimaryLayout = ({
  accessToken,
  currentOrganizationId,
  orgTitle,
}: Props) => {
  const isMobileMenuOpen = useSelector<ApplicationState, boolean>(
    ({ app }) => app.isMobileMenuOpen
  );

  const passwordUpdateEnforced = useSelector<
    ApplicationState,
    boolean | undefined
  >(({ app }) => app.passwordUpdateEnforced);

  const title = document.title;
  const hasPattern = isInSitesPage();

  const {
    installerAccess,
    baseOrgId,
    currentOrganizationType,
  } = useInstallerAccess();

  const dispatch = useDispatch();
  const toggleMobileVerMenu = () => dispatch(toggleMobileMenu());

  const SwitchPlatformLink = `${process.env.REACT_APP_PLATFORM_SWITCH_URL}`;

  const InsightsSwitchLink = useCallback(
    () => (
      <a
        href={SwitchPlatformLink}
        className={styles.InsightsSwitchLink}
        onClick={e => {
          handlePlatformSwitch(e, accessToken, SwitchPlatformLink);
        }}
      >
        <ApplicationMenuItem icon={<HomeIcon size={30} />} title="Home" />
      </a>
    ),
    [SwitchPlatformLink]
  );

  dispatch({ type: 'API_TRACE_START', tracingScope: location.pathname });

  const navigationBarSeperators = (title: string) => {
    let text = '';
    if (title === 'Sites') {
      text = 'SETUP';
    } else if (title === 'SOP') {
      text = 'BUSINESS RULES';
    }

    return (
      text && (
        <div className={styles.horizontalLine}>
          <span className={styles.dividerText}> {text} </span>
        </div>
      )
    );
  };

  const renderMenuItem = (
    title: string,
    to: string,
    activeOn: RegExp,
    icon: React.ReactElement
  ) => {
    return (
      <>
        {navigationBarSeperators(title)}
        <Route
          key={title}
          children={({ location }) => (
            <Link to={to} onClick={toggleMobileVerMenu}>
              <ApplicationMenuItem
                isActive={!!(location && location.pathname.match(activeOn))}
                icon={icon}
                title={title || ''}
              />
            </Link>
          )}
        />
      </>
    );
  };

  useEffect(() => {
    if (installerAccess === InstallerAccess.DENIED && baseOrgId) {
      dispatch(switchOrganization(baseOrgId));
    }
  }, [installerAccess, baseOrgId]);

  useEffect(() => {
    if (!hasPattern && title !== orgTitle) {
      document.title = orgTitle || '';
    }
  }, [hasPattern]);

  return (
    <div className={styles.root}>
      <div className={styles.ApplicationMenu}>
        <ApplicationMenu
          logoLink={SwitchPlatformLink}
          isMobileMenuOpen={isMobileMenuOpen}
          onMobileMenuBackdropClick={toggleMobileVerMenu}
        >
          <div className={styles.ApplicationMenuWrapper}>
            <div className={styles.appMenuIconRoutes}>
              {getMenuItems(
                installerAccess,
                passwordUpdateEnforced,
                currentOrganizationType
              ).map(({ to, icon, title, activeOn, isFeatureFlagged }) => {
                return isFeatureFlagged ? (
                  <FeatureFlag
                    key={title}
                    orgWhiteList={EB_INSTALLER_WHITELIST}
                  >
                    {renderMenuItem(title, to, activeOn, icon)}
                  </FeatureFlag>
                ) : (
                  renderMenuItem(title, to, activeOn, icon)
                );
              })}
            </div>
            {installerAccess === InstallerAccess.DEFAULT && (
              <div className={styles.homeSwitcher}>
                <InsightsSwitchLink />
              </div>
            )}
          </div>
        </ApplicationMenu>
      </div>
      <div className={styles.contentPanel}>
        <TopBarContainer />
        <div>
          <div className={styles.content}>
            <Switch>
              <Route
                exact
                path={Routes.BASE}
                {...getLandingRouteProps(
                  installerAccess,
                  passwordUpdateEnforced,
                  currentOrganizationType
                )}
              />

              <Route
                path={Routes.PASSWORD_CHANGE}
                component={ChangePasswordPage}
              />

              {getAvailableRoutes(
                installerAccess,
                passwordUpdateEnforced,
                currentOrganizationId,
                currentOrganizationType
              )}
            </Switch>
          </div>
        </div>
        <Footer />
        <Modals />
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, organizations }: ApplicationState) => ({
  accessToken: app.accessToken,
  currentOrganizationId: app.currentOrganizationId,
  orgTitle: organizations.currentOrganization?.title,
});

export default connect(mapStateToProps)(PrimaryLayout);
