import {
  OpacityIndex,
  SortDirection,
  SensorTypeFilter as SensorTypeFilterEnum,
  Sensor,
  SensorVendors,
  ResourcePath as RPath,
  ResourceType,
  sensorTypeToSensorTypeFilterMapping,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
  hasSubstr,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import {
  ExtraShortSkeletonCell,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
  MediaElement,
  SearchBox,
  SensorTypeFilter,
  Table,
} from '@energybox/react-ui-library/dist/components';
import pathOr from 'ramda/src/pathOr';

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Actions,
  getByResourceId,
  getSensors,
  filterSensorsBySiteIds,
  putMonnitSensorTypeTemperature,
} from '../../actions/sensors';
import ValueChip from '../../components/ValueChip';
import { ApplicationState } from '../../reducers';
import { DeviceStatusById } from '../../reducers/deviceStatus';
import { IsPathLoadingById, PathsById } from '../../reducers/paths';
import { Routes } from '../../routes';
import { PropertyToLabel } from '../../types/global';
import { checkCommonPlural } from '../../util';
import { getDeviceStatusSortingFn } from '../../utils/devices';
import {
  BatteryStatus,
  SensorTypeIconWithHoverText,
  SignalStatus,
} from '../../utils/sensor';
import { useGetPairedHubsByResourceId } from '../../hooks/useGetPairedHubs';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import DeviceOnlineState from '../DeviceStatus/DeviceOnlineState';
import DeviceLocation from '../ResourceLocation';
import SiteFilter from '../../components/SiteFilter';
import { HubsByPairedSensorId } from '../../reducers/gateways';
import history from '../../history';
import { SentinelsByResourceId } from '../../reducers/sentinels';
import { TableWrapper } from '../../components/ui/Table';

import theme from '../../theme';

import { Placeholder } from '../../types/global';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import FiltersContainer from '../../components/Filters/FiltersContainer/FiltersContainer';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';
import SiteGroupFilter from '../../components/SiteGroupFilter';
import useDynamicFilter from '../../hooks/useDynamicFilter';
import useSiteGroupsFilter from '../../hooks/useSiteGroupsFilter';
import useSiteFilter from '../../hooks/useSiteFilter';
import usePaginationFilter from '../../hooks/usePaginationFilter';
import { getResourcePathsByIds } from '../../actions/paths';
import ResourcePaths from '../ResourcePath/ResourcePaths';

interface OwnProps {
  resourceId?: number;
  hasSearch?: boolean;
  secondaryAction?: any | React.Component;
  renderLimit?: number;
  listView?: boolean;
  hideSensorTypeFilter?: boolean;
  siteId?: number;
  viewportType: ViewportTypes;
}

interface Props extends OwnProps {
  getSensors: typeof getSensors;
  putMonnitSensorTypeTemperature: typeof putMonnitSensorTypeTemperature;
  getByResourceId: typeof getByResourceId;
  filterSensorsBySiteIds: typeof filterSensorsBySiteIds;
  getResourcePathsByIds: typeof getResourcePathsByIds;
  setSensorType: (sensorType: SensorTypeFilterEnum[]) => void;
  setPagination?: (
    page: number | undefined,
    rowLimit: number | undefined
  ) => void;
  currentPage: number | undefined;
  rowLimit: number | undefined;
  sensors: Sensor[];
  getSensorsIsLoading: boolean | undefined;
  resourcePathsById: PathsById;
  sensorIdsFilteredBySites: number[];
  deviceStatusById: DeviceStatusById;
  isOrgLevel: boolean;
  hubsByPairedSensorId: HubsByPairedSensorId;
  sentinelsByResourceId: SentinelsByResourceId;
  selectedSensors: SensorTypeFilterEnum[];
  selectedSiteFilters: string[];
  siteGroupWithoutSites: boolean;
}

interface State {
  query: string;
  sensorLoadingGuard: boolean;
  hasUpdatedMonnit: boolean;
}

const withDynamicFilter = WrappedComponent => {
  return props => {
    const orgId = useCurrentOrganizationId();
    const isOrgLevel = !isDefined(props.resourceId);
    const parentResourceId = isOrgLevel ? orgId : props.siteId;
    const hubsByPairedSensorId = useGetPairedHubsByResourceId(parentResourceId);

    const dataLength = useMemo(() => props.sensors?.length, [
      props?.sensors?.length,
    ]);

    const { currentPage, rowLimit, setPagination } = usePaginationFilter(
      dataLength
    );

    const {
      selectedFilters: selectedSensors,
      setFilter: setSensorType,
    } = useDynamicFilter<SensorTypeFilterEnum[]>('sensorType');
    const { siteGroupWithoutSites } = useSiteGroupsFilter();
    const { selectedSiteFilters } = useSiteFilter();

    return (
      <WrappedComponent
        setPagination={setPagination}
        rowLimit={rowLimit}
        currentPage={currentPage}
        siteGroupWithoutSites={siteGroupWithoutSites}
        selectedSiteFilters={selectedSiteFilters}
        setSensorType={setSensorType}
        selectedSensors={selectedSensors}
        isOrgLevel={isOrgLevel}
        hubsByPairedSensorId={hubsByPairedSensorId}
        {...props}
      />
    );
  };
};

class SensorsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: '',
      sensorLoadingGuard: true,
      hasUpdatedMonnit: false,
    };
  }

  componentDidMount() {
    const {
      getSensors,
      getByResourceId,
      resourceId,
      selectedSiteFilters,
      sensors,
      getResourcePathsByIds,
    } = this.props;

    setTimeout(() => {
      this.setState({ sensorLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams<string>(history, 'query', '');

    this.setState({ query: savedQuery });

    if (resourceId) {
      getByResourceId(resourceId);
    } else {
      if (!selectedSiteFilters?.length) {
        getSensors();
      }
    }

    if (sensors.length > 0) {
      const sensorIds = sensors.map(sensor => sensor.id);
      getResourcePathsByIds(sensorIds);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      filterSensorsBySiteIds,
      sensors,
      putMonnitSensorTypeTemperature,
      selectedSiteFilters,
      sensorIdsFilteredBySites,
      getResourcePathsByIds,
    } = this.props;

    if (
      prevProps.selectedSiteFilters.join('') !== selectedSiteFilters.join('') ||
      (selectedSiteFilters?.length && !sensorIdsFilteredBySites?.length)
    ) {
      filterSensorsBySiteIds(selectedSiteFilters);
    }

    if (!this.state.hasUpdatedMonnit && sensors?.length) {
      const unprocessedMonnitSensors = sensors.filter(sensor => {
        return sensor.vendor === 'monnit' && !sensor.types.length;
      });

      if (unprocessedMonnitSensors?.length) {
        unprocessedMonnitSensors.forEach(sensor => {
          putMonnitSensorTypeTemperature(sensor.id);
        });
      }
      this.setState({
        hasUpdatedMonnit: true,
      });

      if (prevProps.sensors !== sensors) {
        const sensorIds = sensors.map(sensor => sensor.id);
        getResourcePathsByIds(sensorIds);
      }
    }
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  render() {
    const {
      sensors,
      hasSearch,
      getSensorsIsLoading,
      resourcePathsById,
      renderLimit,
      listView = true,
      deviceStatusById,
      hideSensorTypeFilter,
      sensorIdsFilteredBySites,
      isOrgLevel,
      hubsByPairedSensorId,
      viewportType,
      siteGroupWithoutSites,
      selectedSensors,
      setSensorType,
      selectedSiteFilters,
      currentPage,
      setPagination,
      rowLimit,
    } = this.props;
    const isMobile = viewportType === ViewportTypes.MOBILE;
    const { query, sensorLoadingGuard } = this.state;
    const isLoading = getSensorsIsLoading || sensorLoadingGuard;
    const hasSiteFilter = isOrgLevel;

    const queryFilteredSensors =
      query && query.length >= 3
        ? sensors.filter((sensor: Sensor) =>
            hasSubstr(
              `${sensor.title}${sensor.uuid}${sensor.resource &&
                sensor.resource.title}`,
              query
            )
          )
        : sensors;
    const filteredSensors = queryFilteredSensors
      .filter(item =>
        hasSiteFilter && selectedSiteFilters.length > 0
          ? sensorIdsFilteredBySites.includes(item.id)
          : true
      )
      .filter(item =>
        selectedSensors.length > 0
          ? item.types.filter(value =>
              selectedSensors.includes(
                sensorTypeToSensorTypeFilterMapping[value]
              )
            ).length > 0
          : true
      );

    queryFilteredSensors.forEach(item => {
      item['equipmentTitle'] = item.resource?.title;
    });

    const columns = [
      {
        width: '5%',
        header: 'Type',
        cellContent: (sensor: Sensor) => {
          const sensorTypes = sensor.types || [];

          return (
            <MediaElement
              key={sensor.id}
              icon={sensorTypes.map(sensorType => (
                <SensorTypeIconWithHoverText
                  key={sensorType}
                  sensorType={sensorType}
                />
              ))}
              title={''}
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ExtraShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          const getTypes = (s: Sensor) => {
            const types = pathOr(undefined, ['types'], s);
            return types.sort().join();
          };
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getTypes
          );
        },
      },
      {
        width: '17.5%',
        header: 'Sensor Name',
        cellContent: (sensor: Sensor) => {
          return (
            <MediaElement
              key={sensor.id}
              title={
                <Link to={`${Routes.DEVICES}${Routes.SENSORS}/${sensor.id}`}>
                  {sensor.title}
                </Link>
              }
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '10%',
        header: 'Status / Last Check-In',
        cellContent: (sensor: Sensor) => (
          <DeviceOnlineState
            devices={[
              {
                id: sensor.id,
                uuid: sensor.uuid,
                vendor: sensor.vendor,
              },
            ]}
          />
        ),
        comparator: getDeviceStatusSortingFn(deviceStatusById),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '5%',
        header: 'Last Reading',
        cellContent: (sensor: Sensor) => (
          <ValueChip
            sensorId={String(sensor.id)}
            types={sensor.types || []}
            updatedAt={sensor.sensorStatus && sensor.sensorStatus.receivedAt}
          />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ExtraShortSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '10%',
        header: 'Uuid / Vendor',
        cellContent: (sensor: Sensor) => (
          <div>
            <div>
              <strong>{sensor.uuid}</strong>
            </div>
            {SensorVendors[sensor.vendor]}
          </div>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'uuid',
          ]);
        },
      },
      {
        width: '10%',
        header: 'Device Name / Uuid',
        cellContent: (sensor: Sensor) => {
          const pairedHub = hubsByPairedSensorId?.[sensor.id];

          return (
            <div>
              <div>
                {isDefined(pairedHub) ? (
                  <Link
                    to={`${Routes.DEVICES}${Routes.GATEWAYS}/${pairedHub.id}`}
                  >
                    {pairedHub.title}
                  </Link>
                ) : (
                  global.NOT_AVAILABLE
                )}
              </div>
              <div>{pairedHub?.uuid || global.NOT_AVAILABLE}</div>
            </div>
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          const aPairedHub = hubsByPairedSensorId?.[a.id];
          const bPairedHub = hubsByPairedSensorId?.[b.id];
          const aSortValue = aPairedHub?.title || global.NOT_AVAILABLE;
          const bSortValue = bPairedHub?.title || global.NOT_AVAILABLE;
          return genericTableSort(
            aSortValue,
            bSortValue,
            sortDirection,
            SORT_IGNORED_VALUES,
            []
          );
        },
      },
      {
        width: '17.5%',
        header: `${PropertyToLabel.siteId} / ${PropertyToLabel.spaceId}`,
        cellContent: (sensor: Sensor) => (
          <ResourcePaths
            resourceId={sensor.id}
            resourceType={ResourceType.SPACE}
          />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          const getLocation = (s: Sensor) => {
            return (resourcePathsById[s.id] || [])
              .map((p: RPath) => p.title)
              .join();
          };

          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getLocation
          );
        },
      },
      {
        width: '10%',
        header: 'Equipment',
        cellContent: (sensor: Sensor) => (
          <DeviceLocation
            resourceId={sensor.id}
            resourceType={ResourceType.EQUIPMENT}
            showSentinelsInfo
          />
        ),

        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            `equipmentTitle`,
          ]);
        },
      },
      {
        width: '5%',
        header: 'Firmware Version',
        cellContent: (sensor: Sensor) =>
          sensor.firmwareVersion || global.NOT_AVAILABLE,
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ExtraShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'firmwareVersion',
          ]);
        },
      },
      {
        header: 'Signal',
        width: '5%',
        cellContent: (sensor: Sensor) => (
          <SignalStatus
            vendor={sensor.vendor}
            sensorStatus={sensor.sensorStatus}
          />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ExtraShortSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        header: 'Power',
        width: '5%',
        cellContent: (sensor: Sensor) => (
          <BatteryStatus
            vendor={sensor.vendor}
            sensorStatus={sensor.sensorStatus}
          />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ExtraShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Sensor, b: Sensor, sortDirection: SortDirection) => {
          // TODO THIS ONLY WORKS WITH OUR SENSORS. NOT MONNIT SENSORS
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'sensorStatus',
            'batteryVoltage',
          ]);
        },
      },
    ];

    return (
      <DynamicContentWrapper>
        <PageContentHeader header="All Sensors">
          {hasSearch && (
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredSensors?.length === 0}
            />
          )}
        </PageContentHeader>
        <FiltersContainer>
          {!hideSensorTypeFilter && (
            <SensorTypeFilter
              setFilter={setSensorType}
              selectedSensorTypes={selectedSensors}
              sensorTypes={Object.keys(SensorTypeFilterEnum).map(
                key => SensorTypeFilterEnum[key]
              )}
            />
          )}
          {hasSiteFilter && <SiteFilter />}
          {hasSiteFilter && <SiteGroupFilter />}
        </FiltersContainer>

        <TableWrapper
          header={checkCommonPlural('Sensor', filteredSensors?.length)}
          pageNavHidden={filteredSensors?.length == 0 ? true : false}
        >
          <Table
            listView={listView}
            columns={columns}
            data={!siteGroupWithoutSites ? filteredSensors : []}
            renderLimit={renderLimit}
            uniqueKeyField="id"
            dataIsLoading={isLoading}
            rowLimitFromPaginationHook={rowLimit}
            currentPageFromPaginationHook={currentPage}
            setPagination={setPagination}
          />
        </TableWrapper>
      </DynamicContentWrapper>
    );
  }
}

const mapStateToProps = (
  { sensors, resourcePaths, deviceStatusById, sentinels }: ApplicationState,
  { resourceId }: OwnProps
) => ({
  sentinelsByResourceId: sentinels.byResourceId,
  deviceStatusById: deviceStatusById,
  sensors: (resourceId
    ? (sensors.sensorIdsByResourceId[resourceId] || []).map(
        id => sensors.sensorsById[id]
      )
    : Object.values(sensors.sensorsById)
  ).filter(item => item),
  getSensorsIsLoading:
    sensors.loadingStatusByAction[Actions.GET_SENSORS_LOADING] ||
    Object.values(
      sensors.loadingStatusByAction[Actions.GET_SENSORS_BY_SITE_ID_LOADING] ||
        {}
    ).some(loading => loading),
  resourcePathsById: resourcePaths.byIds,
  sensorIdsFilteredBySites: sensors.sensorIdsFilteredBySites,
});

const mapDispatchToProps = {
  getSensors,
  getByResourceId,
  filterSensorsBySiteIds,
  putMonnitSensorTypeTemperature,
  getResourcePathsByIds,
};

export default withViewportType(
  withDynamicFilter(connect(mapStateToProps, mapDispatchToProps)(SensorsTable))
);
