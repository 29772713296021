import { SortDirection } from '@energybox/react-ui-library/dist/types';
import {
  values,
  hasSubstr,
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Card,
  CardContent,
  Columns,
  Select,
  SelectItem,
  SelectSearch,
  Table,
  TextCircle,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { connect } from 'react-redux';
import { addUserToGroup, removeUserFromGroup } from '../../actions/users';
import { getGroups } from '../../actions/user_groups';
import AccessResourceRecord from '../../components/AccessResourceRecord';
import UserGroupTitle from '../../components/UserGroupTitle';
import { ApplicationState } from '../../reducers';
import { UserGroup } from '../../reducers/user_groups';

interface OwnProps {
  userId: number;
  groupIds: number[];
}

interface Props extends OwnProps {
  getAllGroups: typeof getGroups;
  addToGroup: (groupId) => void;
  removeFromGroup: (groupId) => void;
  allGroups: UserGroup[];
}

interface State {
  selectSearch: string;
}

class EditUserGroups extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectSearch: '',
    };
  }

  componentDidMount() {
    this.props.getAllGroups();
  }

  selectSearchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      selectSearch: e.currentTarget.value,
    });
  }

  resetSelectSearch() {
    this.setState({
      selectSearch: '',
    });
  }

  selectOptions() {
    const { allGroups, groupIds } = this.props;
    const { selectSearch } = this.state;

    return allGroups.filter(({ id, title }: UserGroup) => {
      return !groupIds.includes(id) && hasSubstr(title, selectSearch);
    });
  }

  displayedGroups() {
    const { allGroups, groupIds } = this.props;
    return allGroups.filter(({ id }: UserGroup) => groupIds.includes(id));
  }

  render() {
    const { addToGroup, removeFromGroup } = this.props;
    const { selectSearch } = this.state;
    const userGroups = this.displayedGroups();

    const tableColumns = [
      {
        header: 'User Group',
        cellContent: (userGroup: UserGroup) => (
          <UserGroupTitle {...userGroup} />
        ),
        comparator: (
          a: UserGroup,
          b: UserGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Site Access',
        cellContent: ({ accessResources }: UserGroup) => (
          <>
            {accessResources.map(resource => (
              <AccessResourceRecord key={resource.id} {...resource} />
            ))}
          </>
        ),
      },
      {
        header: '',
        width: '180px',
        cellStyle: { textAlign: 'right' },
        cellContent: ({ id }: UserGroup) => (
          <Button variant="text" onClick={() => removeFromGroup(id)}>
            <Columns template="auto 20px" gap="5px">
              <span>Remove from group</span>
              <MdExitToApp size={16} />
            </Columns>
          </Button>
        ),
      },
    ];

    return (
      <Card>
        <CardContent>
          <Columns template="0.7fr 0.3fr">
            <h4 className="m0">Groups ({userGroups.length})</h4>

            <Select
              variant="add"
              title="Add to group"
              onClick={this.resetSelectSearch.bind(this)}
            >
              <SelectSearch
                onChange={this.selectSearchChange.bind(this)}
                value={selectSearch}
              />

              {this.selectOptions().map(({ id, title }: UserGroup) => (
                <SelectItem
                  key={id}
                  onSelect={() => addToGroup(id)}
                  noRadioButton
                >
                  <Columns template="35px auto">
                    <TextCircle size={24}>{`#${title[0]}`}</TextCircle>
                    <span style={{ alignSelf: 'center' }}>{title}</span>
                  </Columns>
                </SelectItem>
              ))}
            </Select>
          </Columns>

          <div style={{ marginTop: '20px' }}>
            <Table columns={tableColumns} data={userGroups} />
          </div>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = ({ userGroups }: ApplicationState) => ({
  allGroups: values(userGroups.userGroupsById),
});

const mapDispatchToProps = (dispatch: any, { userId }: OwnProps) => ({
  getAllGroups: () => dispatch(getGroups()),
  addToGroup: groupId => dispatch(addUserToGroup(userId, groupId)),
  removeFromGroup: groupId => dispatch(removeUserFromGroup(userId, groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserGroups);
