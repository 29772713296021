import {
  SensorType,
  SentinelTarget,
  SentinelTargetType,
  Site,
  Space,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { fetchSpaces } from '../../../../actions/spacesFetch';
import RolloutSelector from './RolloutSelector';
import styles from './StepRollout.module.css';

type Props = {
  site: Site;
  sensorTypes: SensorType[];
  isCollapsed: boolean;
  selected: number[];
  handleTargetsSelect: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (
    deselectedTargetIds: number[],
    deselectedEntityType?: SentinelTargetType
  ) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  updateEquipmentList: (list: any) => void;
  upDatedSentielId: (sentinelId: any) => void;
};

type State = {
  spaces: Space[];
  isLoading: boolean;
};

class StepRolloutSpacesHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      spaces: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site, sensorTypes } = this.props;

      const spaces = await fetchSpaces({
        siteIds: [site.id],
        sensorTypes,
      });

      handleSetResourceRelation(
        site.id,
        spaces.map((e: Space) => e.id)
      );

      const selectedBySite = spaces
        ? spaces.reduce(
            (sum, item) =>
              this.props.selected.includes(item.id) ? sum + 1 : sum,
            0
          )
        : 0;

      const sentinelTargetTypeID = SentinelTargetType.SPACE;

      this.props.updateEquipmentList(spaces);
      this.props.upDatedSentielId(sentinelTargetTypeID);

      this.setState({
        spaces,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { spaces = [], isLoading } = this.state;
    const {
      selected,
      isCollapsed,
      handleTargetDelete,
      handleTargetsSelect,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedBySite = spaces
      ? spaces.reduce(
          (sum, item) => (selected.includes(item.id) ? sum + 1 : sum),
          0
        )
      : 0;

    const total = spaces.length;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Spaces</th>
              <th style={{ width: '50%' }}>{isCollapsed && 'Location'}</th>
              <th
                style={{ width: '20%' }}
                className={classNames(styles.actionHeader, styles.action)}
              >
                <RolloutSelector
                  isCollapsed={isCollapsed}
                  selected={selectedBySite}
                  totalSelected={total}
                  onDelete={() =>
                    handleTargetDelete(
                      spaces.map(item => item.id),
                      SentinelTargetType.SPACE
                    )
                  }
                  onSelect={() =>
                    handleTargetsSelect(
                      spaces.map(item => ({
                        targetId: item.id,
                        targetType: SentinelTargetType.SPACE,
                      }))
                    )
                  }
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

export default StepRolloutSpacesHeader;
