import React from 'react';
import styles from './PageContentHeader.module.css';
import { SkeletonDisplayText } from '@energybox/react-ui-library/dist/components';
import { Height, Length } from '@energybox/react-ui-library/dist/types';
import { IconContext } from 'react-icons';

interface Props {
  header?: React.ReactNode;
  isLoading?: boolean;
  children?: React.ReactNode;
  tooltip?: React.ReactNode;
}

const PageContentHeader: React.FC<Props> = ({
  header,
  isLoading,
  children,
  tooltip,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.leftContainer}>
        {isLoading && (
          <SkeletonDisplayText
            height={Height.SMALL}
            possibleLengths={[Length.SHORT]}
          />
        )}
        {!isLoading && (
          <div className={styles.headerHeading}>
            {header || ''}
            {tooltip && (
              <IconContext.Provider value={{ className: styles.toolTipIcon }}>
                <div className={styles.toolTipContainer}>{tooltip}</div>
              </IconContext.Provider>
            )}
          </div>
        )}
      </div>
      <div className={styles.rightContainer}>{children}</div>
    </div>
  );
};

export default PageContentHeader;
