import React from 'react';

interface Props {
  onToggle?: (on: boolean) => void;
  children: (props: ChildrenProps) => React.ReactNode;
  locked?: boolean;
  shouldCollapse?: boolean;
  startOpen?: boolean;
}

interface State {
  on: boolean;
}

interface ChildrenProps {
  on: boolean;
  toggle: () => void;
}

class Toggle extends React.Component<Props, State> {
  state = {
    on: false,
  };

  componentDidMount() {
    if (!!this.props.startOpen) {
      this.setState({
        on: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { locked, shouldCollapse } = this.props;

    if (prevProps.locked !== locked && !!locked) {
      this.setState({
        on: true,
      });
    }

    // both prevState.on and this.state.on being true means that componentDidUpdate was not triggered by toggle method
    if (!locked && shouldCollapse && prevState.on && this.state.on) {
      this.setState({
        on: false,
      });
    }
  }

  toggle = (e?: React.FormEvent) => {
    const { onToggle, locked } = this.props;

    if (e) {
      e.preventDefault();
    }

    if (!locked) {
      this.setState(
        currentState => {
          return {
            on: !currentState.on,
          };
        },
        () => {
          onToggle && onToggle(this.state.on);
        }
      );
    }
  };

  render() {
    return this.props.children({ on: this.state.on, toggle: this.toggle });
  }
}

export default Toggle;
