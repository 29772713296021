import {
  Button,
  Card,
  CardActions,
  CardContent,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import {
  ResourceType,
  SopComponent,
  TimeTablesById,
  CurrentUser,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  displayFormErrors,
  getSite,
  patch,
  reset,
  updateField,
} from '../../actions/sites';
import {
  destroy as deleteSop,
  getSopComponentsByResourceId,
  reset as resetSop,
} from '../../actions/sops';
import { getTimeTables } from '../../actions/time_tables';
import EditSiteForm from '../../components/EditSiteForm';
import SopTable from '../../components/SopTable/SopTable';
import { ApplicationState } from '../../reducers';
import { createFullAddress, EditSite } from '../../reducers/sites';
import { getObjectWithSelectedKeys } from '../../util';
import FeatureFlag from '../FeatureFlag';
import ImagesContainer from '../ImagesContainer';
import styles from './ShowSitePageGeneral.module.css';
import SiteStatusAndServiceInfo from './SitePageGeneral/SiteStatusAndServiceInfo';

interface OwnProps {
  id: string;
  orgId: number;
  showReports?: boolean;
  viewAsInstaller?: boolean;
}

interface State {
  showSopDeleteModal: boolean;
  sopStagedForDelete: number;
  sopId: string | number;
}

interface Props extends OwnProps {
  load: () => void;
  onChange: (field: string, value: string) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  editSite?: EditSite;
  displayFormErrors: () => void;
  getSopComponentsByResourceId: typeof getSopComponentsByResourceId;
  siteSopComponents: SopComponent[];
  siteTimeTablesById: TimeTablesById;
  getTimeTables: (id: string | number) => void;
  currentUser: CurrentUser | undefined;
  site: Site;
}

class ShowSitePageGeneral extends React.Component<Props, State> {
  private bottomOfPageRef: React.RefObject<HTMLDivElement>;
  private installationRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.bottomOfPageRef = React.createRef();
    this.installationRef = React.createRef();
  }

  componentDidMount() {
    const { load, getTimeTables, orgId } = this.props;
    load();
    getTimeTables(orgId);
    if (this.props.showReports && this.installationRef.current) {
      this.installationRef.current.scrollIntoView();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.editSite === undefined &&
      prevProps.editSite !== this.props.editSite
    ) {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      if (
        params.has('location') &&
        params.get('location') === 'sop' &&
        this.bottomOfPageRef.current
      ) {
        this.bottomOfPageRef.current.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  }

  updateAddressOnSave = () => {
    const { onChange, editSite } = this.props;
    if (editSite) {
      onChange('address', createFullAddress(editSite.fields));
    }
  };

  onSave = () => {
    const { patch, editSite, displayFormErrors } = this.props;

    if (editSite && hasKeys(editSite.formErrors)) {
      displayFormErrors();
    } else {
      this.updateAddressOnSave();
      patch();
    }
  };

  render() {
    const {
      id,
      orgId,
      editSite,
      onChange,
      onCancel,
      siteSopComponents,
      siteTimeTablesById,
      currentUser,
      site,
      viewAsInstaller,
    } = this.props;
    return (
      <div className={styles.root}>
        {editSite && (
          <>
            <div className={styles.paddingBetweenCards}>
              <Card>
                <CardContent>
                  <EditSiteForm
                    siteId={id}
                    onChange={onChange}
                    formErrors={editSite.formErrors}
                    formErrorsVisible={editSite.formErrorsVisible}
                    {...editSite.fields}
                    apiError={editSite.apiError}
                    viewAsInstaller={viewAsInstaller}
                  />
                </CardContent>

                {editSite.isChanged && (
                  <CardActions>
                    <Button
                      variant="text"
                      onClick={onCancel}
                      children="Cancel"
                    />

                    <Button disabled={editSite.isLoading} onClick={this.onSave}>
                      {editSite.isLoading ? (
                        <Loader size={16} variant="secondary" />
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </CardActions>
                )}
              </Card>
            </div>
            <FeatureFlag>
              <div
                className={styles.paddingBetweenCards}
                ref={this.installationRef}
              >
                <SiteStatusAndServiceInfo siteId={id} />
              </div>
            </FeatureFlag>
            <div className={styles.paddingBetweenCards}>
              <Card>
                <CardContent>
                  <SopTable
                    orgId={orgId}
                    siteId={parseInt(id)}
                    sopComponents={[...siteSopComponents]}
                    timeTablesById={siteTimeTablesById}
                    isEquipmentSops={false}
                  />
                </CardContent>
              </Card>
            </div>

            <div className={styles.paddingBetweenCards}>
              <Card>
                <CardContent>
                  <div className={styles.cardTitle}>Company logo & images</div>
                  <ImagesContainer id={id} type={ResourceType.SITE} />
                </CardContent>
              </Card>
            </div>
            <div className={styles.bottomOfPage} ref={this.bottomOfPageRef} />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  { app, sites, sops, timeTables }: ApplicationState,
  { id }: OwnProps
) => ({
  editSite: sites.editById[parseInt(id)],
  siteSopComponents: sops.sopComponentsByResourceId[id] || [],
  siteTimeTablesById: getObjectWithSelectedKeys(
    timeTables.timeTablesById,
    timeTables.timeTableIdsByParentId[id]
  ),
  site: sites.sitesById[id],
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => {
    dispatch(getSite(id));
    dispatch(getSopComponentsByResourceId(id));
    dispatch(getTimeTables(id));
  },
  deleteSop: id => {
    dispatch(deleteSop(String(id)));
  },
  onChange: (f: string, v: string) => dispatch(updateField(id, f, v)),
  patch: () => dispatch(patch(id)),
  onCancel: () => dispatch(reset(id)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
  resetSop: id => dispatch(resetSop(id)),
  getTimeTables: (id: number | string) => dispatch(getTimeTables(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSitePageGeneral);
