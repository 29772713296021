import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import { hideDeleteControlModal } from '../../actions/controls';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import {
  Actions as SchedulerActions,
  deleteScheduler,
} from '../../actions/schedulers';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import styles from './DeleteControlsModuleModel.module.css';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';

type Props = {
  schedulerId: number;
  equipmentId: number;
  orgId?: number;
};

const DeleteControlsModuleModel: React.FC<Props> = ({
  schedulerId,
  equipmentId,
  orgId,
}) => {
  const dispatch = useDispatch();
  const schedulerApiError = useSelector(({ schedulers }: ApplicationState) => {
    return schedulers.apiError;
  });

  const actions = (
    <>
      <Button variant="text" onClick={() => dispatch(hideDeleteControlModal())}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          dispatch(deleteScheduler(schedulerId, equipmentId, orgId));
        }}
      >
        Delete
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <div className={styles.modal}>
        <div className={styles.warningIcon}>
          <WarningIcon size={25} />
        </div>
        <div>
          <p className={styles.mainText}>
            {`Are you sure you want to delete this Controls Module?`}
          </p>
          <p className={styles.subText}>
            <i>
              {' '}
              {`Please note: This Equipment will be released from controls and revert to its Normal state.`}
            </i>
          </p>
        </div>
      </div>
      {Object.values(schedulerApiError).length > 0 &&
        renderAPIerror(
          schedulerApiError,
          SchedulerActions.DELETE_SCHEDULER_ERROR
        )}
    </Modal>
  );
};

export default DeleteControlsModuleModel;
