export enum Actions {
  SITE = '@@filters/SITE',
  EQUIPMENT_TYPE = '@@filters/EQUIPMENT_TYPE',
  RESET = '@@filters/RESET',
}

export const setSiteFilter = (siteIds: number[]) => ({
  type: Actions.SITE,
  payload: siteIds,
});

export const setEquipmentTypeFilter = (equipmentTypeIds: number[]) => ({
  type: Actions.EQUIPMENT_TYPE,
  payload: equipmentTypeIds,
});

export const resetFilters = () => ({
  type: Actions.RESET,
});
