import {
  MeasurementSystem,
  ThermostatWorkingMode,
  TimeTable,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

export const MAX_LIGHT_SENSOR_THRESHOLD_INCLUDING_HYSTERESIS = 9900;

export const renderLocalOverrideText = (
  localOverride: boolean | undefined | null
) => {
  if (localOverride === true) {
    return (
      <span
        style={{
          color: 'var(--orange-baseMinus10)',
        }}
      >
        Active
      </span>
    );
  }
  if (localOverride === false) {
    return <span>Inactive</span>;
  }
  return <span>{global.NOT_AVAILABLE}</span>;
};

export const calculateLightSensorThresholdWithHysteresis = (
  threshold: number,
  hysteresis: number
) => {
  //hysteresis is in number percent i.e. 10 (which means 10%)
  return Number((threshold * (1 + hysteresis / 100)).toFixed());
};

export const overrideTimerOptionsMap = {
  ONE_HOUR: 3600,
  TWO_HOURS: 7200,
  THREE_HOURS: 10800,
  FOUR_HOURS: 14400,
};

export const convertSecToHrsText = (timeInSec: number | null) => {
  if (!isDefined(timeInSec) || timeInSec === 0) {
    return global.NOT_AVAILABLE;
  }
  const numHours = timeInSec / (60 * 60);
  return `${numHours} hour${numHours > 1 ? 's' : ''}`;
};
