import * as R from 'ramda';
import { Actions as EdgeDeviceActions } from '../actions/edge_devices';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';

export type EdgeDeviceState = {
  isEdgeDeviceLoadingByNetworkGroupId: IsEdgeDeviceLoadingByNetworkGroupId;
  edgeDeviceResponseTextByNetworkGroupId: EdgeDeviceResponseTextByNetworkGroupId;
  isConfigAcceptedByNetworkGroupId: IsConfigAcceptedByNetworkGroupId;
  edgeConfigFileBySerialNumber: EdgeConfigFileBySerialNumber;
  edgeConfigUpdateStatusByEdgeId: EdgeConfigUpdateStatusByEdgeId;
};

export type IsEdgeDeviceLoadingByNetworkGroupId = {
  [networkGroupId: string]: boolean;
};

export type EdgeDeviceResponseTextByNetworkGroupId = {
  [networkGroupId: string]: [];
};

export type IsConfigAcceptedByNetworkGroupId = {
  [networkGroupId: string]: boolean;
};

export type EdgeConfigFileBySerialNumber = {
  [serialNumber: string]: EdgeConfigFileStatus;
};

export type EdgeConfigUpdateStatusByEdgeId = {
  [edgeId: number]: EdgeConfigUpdate;
};

export type EdgeConfigFileStatus = {
  isLoading: boolean;
  apiError: ApiError;
  data: EdgeConfigFile;
};

export type EdgeConfigFile = {
  configYaml: string;
  id: number;
  serialNumber: string;
  uuid: string;
  lastRunningConfigHash: string;
  lastSentConfigHash: string;
  currentConfigHash: string;
  yamlValidationErrors: [];
};

export type EdgeConfigUpdate = {
  edgeId: number;
  isHashEqual: boolean;
  lastRunningHashTimeStamp: string;
  lastSentHashTimeStamp: string;
  reasonForFailure: string;
};

const initialState: EdgeDeviceState = {
  isEdgeDeviceLoadingByNetworkGroupId: {},
  edgeDeviceResponseTextByNetworkGroupId: {},
  isConfigAcceptedByNetworkGroupId: {},
  edgeConfigFileBySerialNumber: {},
  edgeConfigUpdateStatusByEdgeId: {},
};

const edgeDevices = (state = initialState, action: any) => {
  switch (action.type) {
    case EdgeDeviceActions.POST_NEW_EDGE_LOADING: {
      return R.assocPath(
        ['isEdgeDeviceLoadingByNetworkGroupId', action.networkGroupId],
        true,
        state
      );
    }

    case EdgeDeviceActions.POST_NEW_EDGE_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['isEdgeDeviceLoadingByNetworkGroupId', action.networkGroupId],
          false
        ),
        R.assocPath(
          ['isConfigAcceptedByNetworkGroupId', action.networkGroupId],
          action.data.result
        ),
        R.assocPath(
          ['edgeDeviceResponseTextByNetworkGroupId', action.networkGroupId],
          action.data.validationErrors
        )
      )(state);
    }

    case EdgeDeviceActions.POST_NEW_EDGE_ERROR: {
      return R.pipe(
        R.assocPath(
          ['isEdgeDeviceLoadingByNetworkGroupId', action.networkGroupId],
          false
        ),
        R.assocPath(
          ['edgeDeviceResponseTextByNetworkGroupId', action.networkGroupId],
          action.data.message
        ),
        R.assocPath(
          ['isConfigAcceptedByNetworkGroupId', action.networkGroupId],
          false
        )
      )(state);
    }

    case EdgeDeviceActions.RESET_EDGE_DEVICE_STATE:
      return R.pipe(
        R.assoc('edgeDeviceResponseTextByNetworkGroupId', {}),
        R.assoc('isConfigAcceptedByNetworkGroupId', {})
      )(state);

    case EdgeDeviceActions.GET_EDGE_CONFIG_FILE_SUCCESS:
      return R.pipe(
        R.assocPath(['edgeConfigFileBySerialNumber', action.serialNumber], {
          isLoading: false,
          data: action.data,
          apiError: {},
        })
      )(state);

    case EdgeDeviceActions.GET_EDGE_CONFIG_FILE_LOADING:
      return R.pipe(
        R.assocPath(
          ['edgeConfigFileBySerialNumber', action.serialNumber, 'isLoading'],
          true
        )
      )(state);

    case EdgeDeviceActions.GET_EDGE_CONFIG_FILE_ERROR:
      return R.pipe(
        R.assocPath(['edgeConfigFileBySerialNumber', action.serialNumber], {
          isLoading: false,
          data: {},
          apiError: storeAPIerror(action),
        })
      )(state);

    case EdgeDeviceActions.GET_EDGE_UPDATE_LOADING:
      return R.pipe(
        R.assocPath(
          ['edgeConfigUpdateStatusByEdgeId', action.edgeId, 'isLoading'],
          true
        )
      )(state);

    case EdgeDeviceActions.GET_EDGE_UPDATE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['edgeConfigUpdateStatusByEdgeId', action.edgeId],
          action.data
        )
      )(state);

    case EdgeDeviceActions.GET_EDGE_UPDATE_ERROR:
      return R.pipe(
        R.assocPath(['edgeConfigUpdateStatusByEdgeId', action.edgeId], {
          isLoading: false,
          data: {},
          apiError: storeAPIerror(action),
        })
      )(state);

    default:
      return state;
  }
};

export default edgeDevices;
