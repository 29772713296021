enum Actions {
  GET_BILLS_BY_SITE_ID_LOADING = '@files/GET_BILLS_BY_SITE_ID_LOADING',
  GET_BILLS_BY_SITE_ID_SUCCESS = '@files/GET_BILLS_BY_SITE_ID_SUCCESS',
  GET_BILLS_BY_SITE_ID_ERROR = '@files/GET_BILLS_BY_SITE_ID_ERROR',

  DELETE_BILLS_LOADING = '@files/DELETE_BILLS_LOADING',
  DELETE_BILLS_SUCCESS = '@files/DELETE_BILLS_SUCCESS',
  DELETE_BILLS_ERROR = '@files/DELETE_BILLS_ERROR',

  EDIT_BILL_SUCCESS = '@files/EDIT_BILLS_SUCCESS',
  EDIT_BILL_ERROR = '@files/EDIT_BILL_ERROR',
  EDIT_BILL_LOADING = '@files/EDIT_BILL_LOADING',
  EDIT_BILL_UPDATE = '@files/EDIT_BILL_UPDATE',
  EDIT_BILL_CANCEL = '@files/EDIT_BILL_CANCEL',
}

export default Actions;
