import {
  Button,
  Card,
  CardContent,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import {
  ChevronRight,
  DefaultSite as DefaultSiteIcon,
} from '@energybox/react-ui-library/dist/icons';
import { Organization } from '@energybox/react-ui-library/dist/types';
import { classNames, global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showNewDistributionPanelModal } from '../../../actions/distribution_panel';
import { showNewEquipmentModal } from '../../../actions/equipment';
import { showNewGatewayModal } from '../../../actions/gateways';
import {
  getCurrentOrganization,
  getOrganizationResources,
} from '../../../actions/organizations';
import { showNewSensorModal } from '../../../actions/sensors';
import { showNewSiteModal } from '../../../actions/sites';
import { showNewUserModal } from '../../../actions/users';
import ImageSquare from '../../../components/ImageSquare';
import IndexManagementPageLayout from '../../../components/IndexManagementPageLayout';
import history from '../../../history';
import { ApplicationState } from '../../../reducers';
import { OrganizationResources } from '../../../reducers/organizations';
import { Routes } from '../../../routes';
import DashboardDistributionPanelIcon from '../../../static/icons/DashboardIcons/dashboardDistributionPanelIcon';
import DashboardEquipmentIcon from '../../../static/icons/DashboardIcons/dashboardEquipmentIcon';
import DashboardGatewayIcon from '../../../static/icons/DashboardIcons/dashboardGatewayIcon';
import DashboardSiteIcon from '../../../static/icons/DashboardIcons/dashboardSiteIcon';
import DashboardUserIcon from '../../../static/icons/DashboardIcons/dashboardUserIcon';
import { CreateNewText } from '../../../types/global';
import NewDeviceModal from '../../Devices/NewDeviceModal';
import NewDistributionPanelModal from '../../DistributionPanels/NewDistributionPanelModal';
import NewEquipmentModal from '../../Equipment/NewEquipmentModal';
import FeatureFlag from '../../FeatureFlag';
import NewSensorModal from '../../Sensors/NewSensorModal';
import NewSiteModal from '../../Site/NewSiteModal';
import NewUserModal from '../../User/NewUserModal';
import styles from './Dashboard.module.css';
import NewNetworkGroupModal from '../../NetworkGroups/NewNetworkGroupModal';
import { showNewNetworkGroupModal } from '../../../actions/network_groups';

interface CardInfo {
  title: string;
  description: string;
  icon: React.ReactNode;
  navLinks: {
    key: string;
    description: string;
    path?: string;
    action?: any;
  }[];
  removeIconPadding?: boolean;
  featureFlag?: boolean;
}

enum CardTitles {
  SITE = 'Sites',
  EQUIPMENT = 'Equipment',
  DEVICES = 'Devices',
  USER = 'Users',
  DISTRIBUTIONPANEL = 'Distribution Panels',
}

interface Props {
  getOrganizationResources: typeof getOrganizationResources;
  getCurrentOrganization: typeof getCurrentOrganization;
  showNewSiteModal: typeof showNewSiteModal;
  showNewEquipmentModal: typeof showNewEquipmentModal;
  showNewDistributionPanelModal: typeof showNewDistributionPanelModal;
  showNewGatewayModal: typeof showNewGatewayModal;
  showNewSensorModal: typeof showNewSensorModal;
  showNewUserModal: typeof showNewUserModal;
  showNewNetworkGroupModal: typeof showNewNetworkGroupModal;
  organizationResources: OrganizationResources | undefined;
  currentOrganization: Organization | undefined;
}

class DashboardPage extends React.Component<Props> {
  componentDidMount() {
    const { getOrganizationResources, getCurrentOrganization } = this.props;

    getCurrentOrganization();
    getOrganizationResources();
  }

  renderOrgCard = () => {
    const { currentOrganization } = this.props;
    const imagePath = currentOrganization?.images?.[0];
    const orgTitle = currentOrganization?.title;
    const orgAddress = currentOrganization?.address;
    const orgId = currentOrganization?.id;

    return (
      <Card className={styles.orgCardContainer}>
        <div className={styles.orgCardContent}>
          <div className={styles.orgCardLeftAlign}>
            {imagePath ? (
              <ImageSquare
                size={'115'}
                baseUrl={process.env.REACT_APP_CDN_BASE_URL || ''}
                imagePath={imagePath}
              />
            ) : (
              <div className={styles.defaultSiteIcon}>
                <DefaultSiteIcon size={130} color="var(--accent-base)" />
              </div>
            )}

            <div>
              <div className={styles.orgTitle}>
                {orgTitle || global.NOT_AVAILABLE}
              </div>
              <div className={styles.orgAddress}>
                {orgAddress || global.NOT_AVAILABLE}
              </div>
            </div>
          </div>

          <FeatureFlag>
            <div className={styles.orgCardRightAlign}>
              {orgId && (
                <MenuDropdown>
                  <MenuDropdownItem
                    onSelect={() =>
                      history.push(`${Routes.ORGANIZATIONS}/${orgId}`)
                    }
                  >
                    Edit
                  </MenuDropdownItem>
                </MenuDropdown>
              )}
            </div>
          </FeatureFlag>
        </div>
      </Card>
    );
  };

  renderResourceCard = (card: CardInfo) => {
    return (
      <Card className={styles.cardStyle} key={card.title}>
        <CardContent>
          <h4 className={styles.cardTitle}>{card.title}</h4>
          <div className={styles.cardDescription}>{card.description}</div>
          <div
            className={
              card.removeIconPadding
                ? classNames(styles.cardIcon, styles.removeIconPadding)
                : styles.cardIcon
            }
          >
            {card.icon}
          </div>
          <div>
            {card.navLinks.map(link => (
              <div className={styles.cardLinks} key={link.key}>
                {link.path ? (
                  <Link to={link.path}>{link.description}</Link>
                ) : (
                  <span className={styles.linkText} onClick={link.action}>
                    {link.description}
                  </span>
                )}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  };

  render() {
    const { organizationResources } = this.props;
    const cardData = [
      {
        title: CardTitles.SITE,
        description: organizationResources
          ? `${organizationResources.sites} sites`
          : 'loading...',
        icon: <DashboardSiteIcon size={160} />,
        navLinks: [
          {
            key: 'linkToSite',
            description: `Manage ${CardTitles.SITE}`,
            path: Routes.SITES,
          },
          {
            key: 'createNewSite',
            description: CreateNewText.SITE,
            action: this.props.showNewSiteModal,
          },
        ],
        featureFlag: false,
      },
      {
        title: CardTitles.EQUIPMENT,
        description: organizationResources
          ? `${organizationResources.equipment} equipment`
          : 'loading...',
        icon: <DashboardEquipmentIcon size={160} />,
        navLinks: [
          {
            key: 'linkToEquipment',
            description: `Manage ${CardTitles.EQUIPMENT}`,
            path: Routes.EQUIPMENT,
          },
          {
            key: 'createNewEquipment',
            description: CreateNewText.EQUIPMENT,
            action: this.props.showNewEquipmentModal,
          },
        ],
        featureFlag: false,
      },
      {
        title: `${CardTitles.DEVICES}`,
        description: organizationResources
          ? `${organizationResources.gateways +
              organizationResources.sensors} devices`
          : 'loading...',
        icon: <DashboardGatewayIcon size={160} />,
        navLinks: [
          {
            key: 'linkToGateways',
            description: `Manage Devices`,
            path: `devices${Routes.GATEWAYS}`,
          },
          {
            key: 'createNewGateway',
            description: CreateNewText.GATEWAY,
            action: this.props.showNewGatewayModal,
          },
          {
            key: 'createNewSensor',
            description: CreateNewText.SENSOR,
            action: this.props.showNewSensorModal,
          },
          {
            key: 'createNewNetworkGroup',
            description: CreateNewText.NETWORK_GROUP,
            action: this.props.showNewNetworkGroupModal,
          },
        ],
        featureFlag: false,
      },
      {
        title: `${CardTitles.USER}`,
        description: organizationResources
          ? `${organizationResources.users} users`
          : 'loading...',
        icon: <DashboardUserIcon size={160} />,
        navLinks: [
          {
            key: 'linkToUsers',
            description: `Manage Users`,
            path: Routes.USERS,
          },
          {
            key: 'createNewGroups',
            description: `Manage User Groups`,
            path: Routes.USER_GROUPS,
          },
          {
            key: 'createNewUser',
            description: CreateNewText.USER,
            action: this.props.showNewUserModal,
          },
        ],
        featureFlag: false,
      },
      {
        title: `${CardTitles.DISTRIBUTIONPANEL}`,
        //TODO: ask Aleks to create distribution panel count inside getOrganizationResources
        description: '',
        icon: <DashboardDistributionPanelIcon size={160} />,
        navLinks: [
          {
            key: 'createNewDistributionPanel',
            description: CreateNewText.DISTRIBUTION_PANEL,
            action: this.props.showNewDistributionPanelModal,
          },
        ],
        featureFlag: false,
      },
    ];

    return (
      <IndexManagementPageLayout
        tabs={[]}
        content={
          <div className={styles.root}>
            {this.renderOrgCard()}

            {cardData.map((card: CardInfo) => {
              const cardComponent = this.renderResourceCard(card);
              return card.featureFlag ? (
                <FeatureFlag>{cardComponent}</FeatureFlag>
              ) : (
                cardComponent
              );
            })}
            <NewSiteModal />
            <NewEquipmentModal />
            <NewDeviceModal />
            <NewSensorModal />
            <NewUserModal />
            <NewDistributionPanelModal />
            <NewNetworkGroupModal />
          </div>
        }
        actions={
          <FeatureFlag>
            <Button
              variant="text"
              onClick={() =>
                history.push(
                  `${Routes.EB_SETTINGS}/${this.props.currentOrganization?.id}`
                )
              }
            >
              Energybox Settings
              <ChevronRight size={12} />
            </Button>
          </FeatureFlag>
        }
      />
    );
  }
}

const mapStateToProps = ({ organizations }: ApplicationState) => ({
  organizationResources: organizations.organizationResources,
  currentOrganization: organizations.currentOrganization,
});

const mapDispatchToProps = {
  getOrganizationResources,
  getCurrentOrganization,
  showNewDistributionPanelModal,
  showNewSiteModal,
  showNewEquipmentModal,
  showNewGatewayModal,
  showNewSensorModal,
  showNewUserModal,
  showNewNetworkGroupModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
