import { EnergyboxService } from '../config';

export enum Actions {
  START_SITE_INSPECTION_REPORT_SUCCESS = '@inspection/START_SITE_INSPECTION_REPORT_SUCCESS',
  START_SITE_INSPECTION_REPORT_LOADING = '@inspection/START_SITE_INSPECTION_REPORT_LOADING',
  START_SITE_INSPECTION_REPORT_ERROR = '@inspection/START_SITE_INSPECTION_REPORT_ERROR',

  GET_SITE_INSPECTION_REPORT_SUCCESS = '@inspection/GET_SITE_INSPECTION_REPORT_SUCCESS',
  GET_SITE_INSPECTION_REPORT_LOADING = '@inspection/GET_SITE_INSPECTION_REPORT_LOADING',
  GET_SITE_INSPECTION_REPORT_ERROR = '@inspection/GET_SITE_INSPECTION_REPORT_ERROR',

  START_CONTROL_TEST_BUTTON_REPORT_SUCCESS = '@inspection/START_CONTROL_TEST_BUTTON_REPORT_SUCCESS',
  START_CONTROL_TEST_BUTTON_REPORT_LOADING = '@inspection/START_CONTROL_TEST_BUTTON_REPORT_LOADING',
  START_CONTROL_TEST_BUTTON_REPORT_ERROR = '@inspection/START_CONTROL_TEST_BUTTON_REPORT_ERROR',

  GET_CONTROL_TEST_BUTTON_REPORT_SUCCESS = '@inspection/GET_CONTROL_TEST_BUTTON_REPORT_SUCCESS',
  GET_CONTROL_TEST_BUTTON_REPORT_LOADING = '@inspection/GET_CONTROL_TEST_BUTTON_REPORT_LOADING',
  GET_CONTROL_TEST_BUTTON_REPORT_ERROR = '@inspection/GET_CONTROL_TEST_BUTTON_REPORT_ERROR',
}

export const startSiteInspectionReport = (siteId: number | string) => ({
  type: 'API_GET',
  path: `/real_time_report/inspection_report/${siteId}`,
  service: EnergyboxService.analytics,
  loading: { type: Actions.START_SITE_INSPECTION_REPORT_LOADING, siteId },
  success: { type: Actions.START_SITE_INSPECTION_REPORT_SUCCESS, siteId },
  error: { type: Actions.START_SITE_INSPECTION_REPORT_ERROR, siteId },
});

export const getSiteInspectionReport = (
  siteId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  path: `/report/inspection_report/${siteId}/${timestamp}`,
  service: EnergyboxService.analytics,
  loading: { type: Actions.GET_SITE_INSPECTION_REPORT_LOADING, siteId },
  success: { type: Actions.GET_SITE_INSPECTION_REPORT_SUCCESS, siteId },
  error: { type: Actions.GET_SITE_INSPECTION_REPORT_ERROR, siteId },
});

export const startControlTestButtonReport = (
  siteId: number | string,
  equipmentId: number | null,
  timestamp1: string,
  timestamp2: string
) => ({
  type: 'API_GET',
  path: `/real_time_report/control_test_button_report/${siteId}
    ?${
      equipmentId ? 'equipmentId=' + equipmentId : ''
    }&timestamp1=${timestamp1}&timestamp2=${timestamp2}`,
  service: EnergyboxService.analytics,
  loading: { type: Actions.START_CONTROL_TEST_BUTTON_REPORT_LOADING, siteId },
  success: { type: Actions.START_CONTROL_TEST_BUTTON_REPORT_SUCCESS, siteId },
  error: { type: Actions.START_CONTROL_TEST_BUTTON_REPORT_ERROR, siteId },
});

export const getControlTestButtonReport = (
  siteId: number | string,
  report_key: string
) => ({
  type: 'API_GET',
  path: `/report/control_test_button_report/${siteId}/${report_key}`,
  service: EnergyboxService.analytics,
  loading: { type: Actions.GET_CONTROL_TEST_BUTTON_REPORT_LOADING, siteId },
  success: { type: Actions.GET_CONTROL_TEST_BUTTON_REPORT_SUCCESS, siteId },
  error: { type: Actions.GET_CONTROL_TEST_BUTTON_REPORT_ERROR, siteId },
});
