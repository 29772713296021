import { Loader, Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './RecordInfoTooltip.module.css';
import {
  classNames,
  determineTimeStringByTimezone,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { useAppLocale } from '../../hooks/useAppDetails';
import { LastEditedByIcon } from '@energybox/react-ui-library/dist/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { RecordsByResourceId } from '../../reducers/records';
import { getRecords } from '../../actions/records';
import useGetUsersInOrganization from '../../hooks/useGetUsers';
import useRecordByResourceIds from '../../hooks/records/useRecordByResourceId';

declare type Props = {
  resourceId: number;
  ianaTimeZoneCode?: string;
};

export const RecordInfoTooltip: React.FC<Props> = ({
  resourceId,
  ianaTimeZoneCode,
}) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [timestampString, setTimestampString] = useState('');

  const recordsByResourceId = useRecordByResourceIds();
  const usersInOrg = useGetUsersInOrganization();
  const locale = useAppLocale();

  const fetchRecords = () => {
    if (!recordsByResourceId[resourceId]) {
      dispatch(getRecords(resourceId));
    }
  };

  useEffect(() => {
    const latestRecord = recordsByResourceId[resourceId]?.[0];
    if (latestRecord) {
      if (latestRecord.username) {
        setUsername(latestRecord.username);
      } else if (usersInOrg[latestRecord.userId] != null) {
        // Fallback in case username can't be found in logging service
        setUsername(
          `${usersInOrg[latestRecord.userId]['firstName']} ${
            usersInOrg[latestRecord.userId]['lastName']
          }`
        );
      } else {
        setUsername('-');
      }
      setTimestampString(
        determineTimeStringByTimezone(
          new Date(latestRecord.createdAt),
          locale.fullDateTimeFormat,
          ianaTimeZoneCode,
          true
        )
      );
    } else {
      setUsername('-');
      setTimestampString('');
    }
  }, [recordsByResourceId]);

  return (
    <div onClick={fetchRecords}>
      <Tooltip
        isClickable={true}
        underline={false}
        arrowDirection={'right'}
        extraClassNames={[styles.tooltip, styles.tooltipContainer]}
        content={
          <div className={styles.tooltipText}>
            <div
              className={classNames(
                styles.tooltipLineEntry,
                styles.secondaryText
              )}
            >
              Last Edited By
            </div>
            {recordsByResourceId[resourceId] != null ? (
              <>
                <div className={styles.nameText}>{username}</div>
                <div
                  className={classNames(
                    styles.secondaryText,
                    styles.timestampText
                  )}
                >
                  {timestampString}
                </div>
              </>
            ) : (
              <Loader className={styles.loader} size={14} />
            )}
          </div>
        }
      >
        <LastEditedByIcon width="32" height="32" />
      </Tooltip>
    </div>
  );
};

export default RecordInfoTooltip;
