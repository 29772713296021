import {
  SearchBox,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  Organization as OrganizationIcon,
  Site as SiteIcon,
  SitesGroup as SitesGroupIcon,
} from '@energybox/react-ui-library/dist/icons';
import {
  AccessResource,
  Organization,
  ResourceType,
  Site,
  permissionsList,
  SiteGroup,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import {
  IoIosAddCircle,
  IoIosAddCircleOutline,
  IoIosCloseCircle,
} from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import styles from './ResourceAccess.module.css';

interface Props {
  headerTitle: string;
  description?: string;
  searchOpen: boolean;
  query: string;
  orgs?: Organization[];
  sites?: Site[];
  siteGroups?: SiteGroup[];
  scopes: AccessResource[];
  onResourceAccessDelete: (resourceId: string) => void;
  onQueryChange: (queryString: string) => void;
  toggleSearch: () => void;
  onNewResourceSelect: (resourceId: string[]) => void;
  onExistingResourceSelect: (resourceId: string[]) => void;
  onResourceAccessChange: (
    resourceId: string,
    updatedPermission: string
  ) => void;
}

const renderIcon = (resourceType: string) => {
  switch (resourceType) {
    case ResourceType.ORGANIZATION:
      return <OrganizationIcon size={25} />;
    case ResourceType.SITE:
      return <SiteIcon size={25} />;
    case ResourceType.SITEGROUP:
      return <SitesGroupIcon size={25} />;
    default:
      return;
  }
};
const renderAddResourceRow = (
  resource: Site | SiteGroup | Organization,
  onNewResourceSelect: Props['onNewResourceSelect']
) => (
  <div className={styles.row} key={resource.id}>
    <div className={styles.resourceTypeIconContainer}>
      {renderIcon(resource.resourceType)}
    </div>
    <div className={classNames(styles.resourceTextContainer)}>
      <div>{resource.title}</div>
      {resource.resourceType === ResourceType.SITE && (
        <div>{resource.address}</div>
      )}
      {resource.resourceType === ResourceType.SITEGROUP && (
        <div>{resource.description}</div>
      )}
    </div>
    <div className={classNames(styles.addButtonContainer)}>
      <button
        onClick={() => onNewResourceSelect([resource.id.toString()])}
        className={styles.invisibleButton}
      >
        <IoIosAddCircleOutline size={'1.250rem'} />
      </button>
    </div>
  </div>
);

// Using placeholder Icon for delete button
export const renderAccessRow = (
  resource: AccessResource,
  { onResourceAccessChange, onResourceAccessDelete },
  darkBackground = false
) => (
  <div
    className={styles.row}
    key={resource.id}
    style={{
      display: 'grid',
      gridTemplateColumns: '1fr 4fr 4fr 1fr',
      ...(darkBackground
        ? { backgroundColor: 'var(--ambient-basePlus90)', border: 'none' }
        : {}),
    }}
  >
    <div className={styles.resourceTypeContainer}>
      {renderIcon(resource.resourceType)}
    </div>
    <div>{resource.title}</div>
    <div className={classNames(styles.accessDropdownContainer)}>
      <Select title={resource.role}>
        {permissionsList
          .filter(({ value }) => value !== 'INSTALLER')
          // Assuming this component will never be used for installer
          // If needed to use, need revision
          .map(({ value, title }) => (
            <SelectItem
              key={value}
              isSelected={title === resource.role}
              onSelect={() =>
                onResourceAccessChange(resource.resourceId, value)
              }
            >
              {title}
            </SelectItem>
          ))}
      </Select>
    </div>
    <div>
      <div className={styles.deleteButtonContainer}>
        <button
          onClick={onResourceAccessDelete.bind(null, resource.resourceId)}
          className={styles.invisibleButton}
        >
          <MdDelete size={'1.250rem'} />
        </button>
      </div>
    </div>
  </div>
);

export const filterAttachedScopes = (
  usedScopeIds: number[],
  resourceList: any[]
) => {
  return resourceList.filter(({ id }) => usedScopeIds.indexOf(id) === -1);
};

const ResourceAccess = ({
  headerTitle,
  description,
  searchOpen = false,
  onQueryChange = () => {},
  onNewResourceSelect = () => {},
  toggleSearch = () => {},
  onResourceAccessDelete = () => {},
  onResourceAccessChange = () => {},
  query = '',
  orgs = [],
  sites = [],
  siteGroups = [],
  scopes = [],
}: Props) => {
  const scopesResourceIds = scopes.map(({ resourceId }) => resourceId);
  const filteredOrgs = filterAttachedScopes(scopesResourceIds, orgs);
  const filteredSites = filterAttachedScopes(scopesResourceIds, sites);
  const filteredSiteGroups = filterAttachedScopes(
    scopesResourceIds,
    siteGroups
  );

  const handleSearchChange = (value: string) => {
    onQueryChange(value);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {searchOpen && (
          <button className={styles.invisibleButton} onClick={toggleSearch}>
            <IoIosCloseCircle size="20" />
          </button>
        )}
        <span>{headerTitle}</span>
      </div>
      <p>{description || ''}</p>

      {!searchOpen && (
        <button className={styles.accessButton} onClick={toggleSearch}>
          <IoIosAddCircle size="20" />
          <span>Add Access</span>
        </button>
      )}
      {!searchOpen && <div className={styles.divider} />}
      {!searchOpen &&
        (scopes.length > 0 ? (
          scopes.map(resource =>
            renderAccessRow(resource, {
              onResourceAccessDelete,
              onResourceAccessChange,
            })
          )
        ) : (
          <div className={styles.noResultsContainer}>
            <h3>Sorry, no scopes found</h3>
            <p> Why don't you add some scopes?</p>
          </div>
        ))}
      {searchOpen && (
        <div style={{ height: '100%', overflowY: 'hidden' }}>
          <div className={styles.actionHeaderContainer}>
            <SearchBox
              placeholder="Resources, Sites, City, Zip..."
              onChange={handleSearchChange}
              query={query}
              width="16rem"
              widthActive="16rem"
              error={
                filteredOrgs.length === 0 &&
                filteredSiteGroups.length === 0 &&
                filteredSites.length === 0
              }
            />
          </div>
          <div className={styles.resultsContainer}>
            <div>
              {filteredOrgs.length > 0 && (
                <div>
                  <h3>Organizations</h3>
                  {filteredOrgs.map(org =>
                    renderAddResourceRow(org, onNewResourceSelect)
                  )}
                </div>
              )}
              {filteredSiteGroups.length > 0 && (
                <div>
                  <h3>Site Groups</h3>
                  {filteredSiteGroups
                    .filter(({ id }) => scopesResourceIds.indexOf(id) === -1)
                    .map(site =>
                      renderAddResourceRow(site, onNewResourceSelect)
                    )}
                </div>
              )}
              {filteredSites.length > 0 && (
                <div>
                  <h3>Sites</h3>
                  {filteredSites
                    .filter(({ id }) => scopesResourceIds.indexOf(id) === -1)
                    .map(site =>
                      renderAddResourceRow(site, onNewResourceSelect)
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourceAccess;
