import {
  Button,
  FilterLabels,
  GenericFilter,
  SelectAll,
} from '@energybox/react-ui-library/dist/components';
import {
  EquipmentGroup,
  EquipmentType,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import {
  useEquipmentTypesByIdWithinSite,
  useEquipmentTypesByOrgOrSiteId,
} from '../../../hooks/useEquipmentTypes';
import { usePrevious } from '@energybox/react-ui-library/dist/hooks';
import EquipmentMenuItem, {
  ListItem as EquipmentMenuListItem,
} from '../EquipmentMenuItem';
import FilterMenuContent from '../FilterMenuContent';
import FilterMenuFooter from '../FilterMenuFooter';
import { sortByTitleWithEmptyStringToLast } from '../../../utils/sorting';
import styles from './EquipmentMenuFilter.module.css';
import { HVAC_CONTROL_EQUIPMENT_TYPES } from '../../../types/hvacControl';
import useDynamicFilter from '../../../hooks/useDynamicFilter';

type Props = {
  className?: string;
  dropDownWrapperClassName?: string;
  label: string;
  isInDropdown?: boolean;
  relevantItemTypeIds?: number[];
  relevantItemGroupId?: number;
  resetFilters?: boolean;
  isOrglevelHvac?: boolean;
  onChange?: (selectedSiteIds: number[]) => void;
  orgId?: number;
  siteId?: number;
};

const EquipmentMenuFilter: React.FC<Props> = ({
  className,
  dropDownWrapperClassName,
  label,
  isInDropdown,
  relevantItemTypeIds,
  relevantItemGroupId,
  resetFilters,
  isOrglevelHvac,
  onChange,
  orgId,
  siteId,
}: Props) => {
  const {
    selectedFilters: activeTypeFilters,
    setFilter: setTypeFilter,
  } = useDynamicFilter<number>('eqType', value => parseInt(value));

  const allEquipmentTypes = useEquipmentTypesByOrgOrSiteId(siteId, orgId);
  const hvacAvailableTypes = allEquipmentTypes.filter(equipment => {
    return HVAC_CONTROL_EQUIPMENT_TYPES.includes(equipment.title);
  });
  const equipmentTypes = !isOrglevelHvac
    ? allEquipmentTypes
    : hvacAvailableTypes;
  const equipmentTypesById = useEquipmentTypesByIdWithinSite(siteId!);
  const equipmentTypeIds = equipmentTypes.map(({ id }) => id);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [groups, setGroups] = useState<Map<string, EquipmentGroup>>(new Map());
  const [types, setTypes] = useState<Map<string, EquipmentType[]>>(new Map());
  const [availableTypes, setAvailableTypes] = useState<EquipmentType[]>(
    equipmentTypes
  );

  // This array is for the temporary state of the menu before user hits apply
  const [currentlySelectedFilters, setCurrentlySelectedFilters] = useState<
    number[]
  >([]);

  const [maxExpectedFilters, setMaxExpectedFilters] = useState<
    number | undefined
  >(undefined);

  const prevAvailableTypes = usePrevious(availableTypes);
  const prevEquipmentTypes = usePrevious(equipmentTypes);
  const prevRelevantItemTypes = usePrevious(relevantItemTypeIds);
  const prevEquipmentTypeIds = usePrevious(equipmentTypeIds);

  /** FUNCTION DEFINITIONS START HERE */
  const close = (forceClose = false) => {
    if (isInDropdown) {
      if (forceClose) {
        // workaround: close the outer dropdown by simulating a mousedown event outside it
        const mouseEvent = document.createEvent('MouseEvents');
        mouseEvent.initEvent('mousedown', true, true);
        document.body.dispatchEvent(mouseEvent);
      }
      return;
    }
    document.removeEventListener('keydown', handleKeyPress);
    setOpen(false);
  };

  const handleMenuFilterButtonClick = () => {
    if (!open) {
      document.addEventListener('keydown', handleKeyPress);
    }
    setOpen(prevOpen => !prevOpen);
  };

  const handleMenuFilterButtonSubmitClick = () => {
    close(true);
    setTypeFilter(currentlySelectedFilters);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      close();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      // https://stackoverflow.com/a/43851475/ cites TS issue tracker
      // casting to Node seems to be the solution
      wrapperRef.current.contains(event.target as Node) === false
    ) {
      close();
    }
  };
  /** FUNCTION DEFINITIONS END HERE */

  /** REACT EFFECTS START HERE */
  const initializeFiltersCB = useCallback(() => {
    initializeFilters(
      relevantItemTypeIds,
      equipmentTypes,
      setAvailableTypes,
      () => groupEquipmentTypeByGroup(availableTypes, setGroups, setTypes)
    );
  }, [
    relevantItemTypeIds,
    equipmentTypes,
    setAvailableTypes,
    availableTypes,
    setGroups,
    setTypes,
  ]);

  useEffect(() => {
    setCurrentlySelectedFilters(activeTypeFilters);
  }, [activeTypeFilters]);

  useEffect(() => {
    initializeFiltersCB();
    document.addEventListener('mousedown', handleClickOutside);
    isInDropdown &&
      activeTypeFilters.length > 0 &&
      resetIntermediateState(
        activeTypeFilters,
        setCurrentlySelectedFilters,
        availableTypes
      );
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      !isInDropdown && resetFilters && setTypeFilter([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (availableTypes.length !== equipmentTypes.length && !orgId) {
      resetAllFilters(
        currentlySelectedFilters,
        setAvailableTypes,
        equipmentTypes
      );
    }
  }, [availableTypes, equipmentTypes]);

  // Process available item types
  useEffect(() => {
    if (
      !equals(prevEquipmentTypes, equipmentTypes) ||
      ((prevRelevantItemTypes === undefined ||
        prevRelevantItemTypes.length === 0) &&
        relevantItemTypeIds !== undefined &&
        relevantItemTypeIds.length > 0)
    ) {
      initializeFiltersCB();
    }
  }, [
    equipmentTypes,
    relevantItemTypeIds,
    prevEquipmentTypes,
    prevRelevantItemTypes,
    initializeFiltersCB,
  ]);

  // Set maxExpectedFilters
  useEffect(() => {
    if (
      !equals(prevRelevantItemTypes, relevantItemTypeIds) ||
      !equals(prevEquipmentTypeIds, equipmentTypeIds)
    ) {
      setMaxExpectedFilters(
        relevantItemTypeIds
          ? relevantItemTypeIds.length
          : equipmentTypeIds.length
      );
    }
  }, [
    prevRelevantItemTypes,
    relevantItemTypeIds,
    prevEquipmentTypeIds,
    equipmentTypeIds,
  ]);

  // Process items into groups based on the available types
  useEffect(() => {
    if (
      filtersHaveUpdated(
        prevAvailableTypes?.map(({ id }) => id),
        availableTypes.map(({ id }) => id)
      )
    ) {
      groupEquipmentTypeByGroup(availableTypes, setGroups, setTypes);
    }
  }, [prevAvailableTypes, availableTypes]);
  /** REACT EFFECTS END HERE */

  /** DEPENDENT VARIABLES START HERE */
  const selectedAll = currentlySelectedFilters.length === maxExpectedFilters;
  const areAnySelected = currentlySelectedFilters.length !== 0;
  const isActive = currentlySelectedFilters.length > 0 && !selectedAll;
  /** DEPENDENT VARIABLES END HERE */

  const toggleSelectAll = () => {
    let newFilters: number[] = [];
    if (!areAnySelected) {
      newFilters = relevantItemTypeIds || equipmentTypeIds;
    }

    setCurrentlySelectedFilters(newFilters);
  };

  const equipmentTypesByGroup =
    relevantItemGroupId !== undefined
      ? types
          .get(relevantItemGroupId.toString())
          ?.sort(sortByTitleWithEmptyStringToLast)
      : undefined;

  const equipmentTypeToTitleMap = new Map(
    allEquipmentTypes.map(type => [type.id, type.title])
  );

  const selectedEquipmentTypes = [
    ...new Set(
      allEquipmentTypes
        .filter(equipmentType => {
          return activeTypeFilters.includes(equipmentType.id);
        })
        .map(equipmentType => equipmentType.id)
    ),
  ];

  const selectedEquipmentGroups = [
    ...new Set(
      activeTypeFilters
        .map(
          equipmentTypeId =>
            availableTypes.find(type => +equipmentTypeId === +type.id)
              ?.groupId || ''
        )
        .filter(id => id)
    ),
  ];

  return equipmentTypesByGroup !== undefined ? (
    <GenericFilter
      className={styles.genericFilter}
      title="Equipment Type"
      setFilter={setTypeFilter}
      items={equipmentTypesByGroup.map(type => type.id)}
      selectedItems={activeTypeFilters}
      transformItemName={typeId => {
        const type = equipmentTypesById[typeId];
        return type !== undefined ? type.title : '';
      }}
    />
  ) : (
    <div className={classNames(className, styles.wrapper)} ref={wrapperRef}>
      {!isInDropdown && (
        <Button
          className={classNames(
            styles.button,
            activeTypeFilters.length > 0
              ? styles.dropdownButtonWithSelectedItems
              : '',
            isActive ? styles.buttonActive : '',
            open ? styles.buttonOpen : ''
          )}
          roundedCorners
          variant="outlined"
          onClick={handleMenuFilterButtonClick}
        >
          {/*
           * Use equipment group IDs as selected items of FilterLabels, NOT individual equipment type IDs.
           * There are 2 scanarios that an equipment group name appears in the filter label list:
           * 1) an equipment group is selected.
           * 2) at least one equipment type in an equipment group is selected.
           *
           * When user clicks delete button of a filter label, all equipment types of that group is deselected.
           */}
          <FilterLabels
            customTextMaxLength={isOrglevelHvac ? 50 : 0}
            maxItemCount={isOrglevelHvac ? 3 : 2}
            selectedItems={
              isOrglevelHvac ? selectedEquipmentTypes : selectedEquipmentGroups
            }
            title="Equipment Type"
            transformItemName={groupId =>
              isOrglevelHvac
                ? equipmentTypeToTitleMap.get(+groupId) || ''
                : groups.get(groupId.toString())?.title || ''
            }
            removeFilter={groupId =>
              isOrglevelHvac
                ? handleTypeItemsClick(
                    +groupId,
                    currentlySelectedFilters,
                    setCurrentlySelectedFilters,
                    setTypeFilter
                  )
                : handleGroupItemsClick(
                    types.get(groupId.toString())?.map(type => type.id) || [],
                    currentlySelectedFilters,
                    setCurrentlySelectedFilters,
                    setTypeFilter
                  )
            }
          />
          <MdExpandMore
            size={16}
            className={open ? styles.openArrowIcon : styles.arrowIcon}
          />
        </Button>
      )}

      {(open || isInDropdown) && (
        <div
          className={classNames(
            isInDropdown
              ? styles.isInCustomDropdownWrapper
              : isOrglevelHvac
              ? styles.dropDownWrapperOrgHvac
              : styles.dropDownWrapper,
            dropDownWrapperClassName
          )}
        >
          <FilterMenuContent
            className={isInDropdown ? styles.selectAllInCustomDropdown : ''}
          >
            <SelectAll
              areAnySelected={areAnySelected}
              toggleSelectAll={toggleSelectAll}
            />
          </FilterMenuContent>
          {relevantItemGroupId === undefined && (
            <FilterMenuContent
              className={isInDropdown ? styles.listInCustomDropdown : ''}
            >
              <ul className={styles.listWrapper}>
                {Array.from(groups.values())
                  .sort(sortByTitleWithEmptyStringToLast)
                  .map(group => {
                    const equipmentTypesByGroup = types
                      .get(group.id.toString())
                      ?.sort(sortByTitleWithEmptyStringToLast);

                    if (
                      (relevantItemGroupId !== undefined &&
                        group.id !== relevantItemGroupId) ||
                      !equipmentTypesByGroup
                    ) {
                      return null;
                    }

                    const equipmentTypeIds = equipmentTypesByGroup.map(
                      equipmentType => equipmentType.id
                    );

                    const activeItems = equipmentTypeIds.reduce(
                      (state, id) =>
                        currentlySelectedFilters.includes(id)
                          ? state + 1
                          : state,
                      0
                    );

                    return (
                      <>
                        {!isOrglevelHvac ? (
                          <EquipmentMenuItem
                            alias={group.alias}
                            key={`EquipmentMenuItem${group.id}`}
                            isCompact={isInDropdown}
                            title={group.title}
                            onClick={() =>
                              handleGroupItemsClick(
                                equipmentTypeIds,
                                currentlySelectedFilters,
                                setCurrentlySelectedFilters
                              )
                            }
                            activeItems={activeItems}
                            maxItems={equipmentTypeIds.length}
                          >
                            {equipmentTypesByGroup.map(equipmentType => (
                              <EquipmentMenuListItem
                                key={`EquipmentMenuListItem${equipmentType.id}`}
                                isCompact={isInDropdown}
                                onClick={() =>
                                  handleTypeItemsClick(
                                    equipmentType.id,
                                    currentlySelectedFilters,
                                    setCurrentlySelectedFilters
                                  )
                                }
                                label={equipmentType.title}
                                checked={currentlySelectedFilters.includes(
                                  equipmentType.id
                                )}
                              />
                            ))}
                          </EquipmentMenuItem>
                        ) : (
                          <>
                            {equipmentTypesByGroup.map(equipmentType => (
                              <EquipmentMenuListItem
                                isHvacSop={true}
                                key={`EquipmentMenuListItem${equipmentType.id}`}
                                isCompact={isInDropdown}
                                onClick={() =>
                                  handleTypeItemsClick(
                                    equipmentType.id,
                                    currentlySelectedFilters,
                                    setCurrentlySelectedFilters
                                  )
                                }
                                label={equipmentType.title}
                                checked={currentlySelectedFilters.includes(
                                  equipmentType.id
                                )}
                                variant={
                                  isOrglevelHvac ? 'contained' : 'outlined'
                                }
                              />
                            ))}
                          </>
                        )}
                      </>
                    );
                  })}
              </ul>
            </FilterMenuContent>
          )}
          <FilterMenuFooter
            className={isInDropdown ? styles.footerInCustomDropdown : ''}
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                resetIntermediateState(
                  activeTypeFilters,
                  setCurrentlySelectedFilters,
                  availableTypes
                );
                close(true);
              }}
            >
              Cancel
            </Button>

            <Button size="small" onClick={handleMenuFilterButtonSubmitClick}>
              Apply
            </Button>
          </FilterMenuFooter>
        </div>
      )}
    </div>
  );
};

const initializeFilters = (
  relevantItemTypeIds: number[] | undefined,
  equipmentTypes: EquipmentType[],
  setAvailableTypes: React.Dispatch<React.SetStateAction<EquipmentType[]>>,
  groupEquipmentTypeByGroup: () => void
) => {
  if (relevantItemTypeIds !== undefined && relevantItemTypeIds.length > 0) {
    const filtered = equipmentTypes.filter(({ id }) => {
      return relevantItemTypeIds.includes(id);
    });
    setAvailableTypes(filtered);
  } else {
    groupEquipmentTypeByGroup();
  }
};

const groupEquipmentTypeByGroup = (
  availableTypes: EquipmentType[],
  setGroups: React.Dispatch<React.SetStateAction<Map<string, EquipmentGroup>>>,
  setTypes: React.Dispatch<React.SetStateAction<Map<string, EquipmentType[]>>>
) => {
  const groups: Map<string, EquipmentGroup> = new Map();
  const types: Map<string, EquipmentType[]> = new Map();

  for (const equipmentType of availableTypes) {
    const groupId = equipmentType.groupId.toString();
    const existingItems = types.get(groupId) || [];
    types.set(groupId, existingItems.concat(equipmentType));
    if (equipmentType.group) {
      groups.set(groupId, equipmentType.group);
    }
  }

  setGroups(groups);
  setTypes(types);
};

const handleGroupItemsClick = (
  equipmentTypeIds: number[],
  currentlySelectedFilters: number[],
  setCurrentlySelectedFilters: React.Dispatch<React.SetStateAction<number[]>>,
  setTypeFilter = types => {}
) => {
  const newActiveFilter = currentlySelectedFilters.some(i =>
    equipmentTypeIds.some(filterItem => {
      return i === filterItem;
    })
  )
    ? currentlySelectedFilters.filter(
        item => equipmentTypeIds.includes(item) === false
      )
    : [...currentlySelectedFilters, ...equipmentTypeIds];
  setCurrentlySelectedFilters(newActiveFilter);

  setTypeFilter?.(newActiveFilter);
};

const handleTypeItemsClick = (
  typeId: number,
  currentlySelectedFilters: number[],
  setCurrentlySelectedFilters: React.Dispatch<React.SetStateAction<number[]>>,
  setTypeFilter = types => {}
) => {
  const newActiveFilter = currentlySelectedFilters.includes(typeId)
    ? currentlySelectedFilters.filter(f => f !== typeId)
    : [...currentlySelectedFilters, typeId];
  setCurrentlySelectedFilters(newActiveFilter);
  setTypeFilter?.(newActiveFilter);
};

const filtersHaveUpdated = (prev: number[] | undefined, next: number[]) => {
  if (prev === undefined) return true;
  return (
    JSON.stringify(prev.sort((a, b) => a - b)) !==
    JSON.stringify(next.sort((a, b) => a - b))
  );
};

const resetIntermediateState = (
  activeFilters: number[],
  setCurrentlySelectedFilters: React.Dispatch<React.SetStateAction<number[]>>,
  availableTypes: EquipmentType[]
) => {
  if (activeFilters.length === 0) {
    setCurrentlySelectedFilters(availableTypes.map(({ id }) => id));
  } else {
    setCurrentlySelectedFilters(activeFilters);
  }
};

const resetAllFilters = (
  currentlySelectedFilters: number[],
  setAvailableTypes: React.Dispatch<React.SetStateAction<EquipmentType[]>>,
  equipmentTypes: EquipmentType[]
) => {
  if (!currentlySelectedFilters.length) {
    setAvailableTypes(equipmentTypes);
  }
};

export default EquipmentMenuFilter;
