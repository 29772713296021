import React from 'react';
import { Routes } from '../../routes';
import IndexManagementPageLayout from '../IndexManagementPageLayout';
import TabLink from '../TabLink';

interface Props {
  actions?: React.ReactNode;
  showSiteGroups?: boolean;
}

const SitesManagementLayout: React.FunctionComponent<Props> = ({
  actions,
  showSiteGroups = true,
  children,
}) => (
  <IndexManagementPageLayout
    actions={actions}
    tabs={
      <>
        <TabLink to={Routes.SITES}>Sites</TabLink>
        {showSiteGroups && (
          <TabLink to={Routes.SITE_GROUPS}>Site Groups</TabLink>
        )}
      </>
    }
    content={children}
  />
);

export default SitesManagementLayout;
