import {
  Loader,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import styles from './LastNetworkGroupConfigUpdate.module.css';
import { useGetEdgeConfigUpdateByEdgeId } from '../../hooks/useGetEdgeConfigUpdate';

type Props = {
  className?: string;
  networkGroup: NetworkGroup;
};

const LastNetworkGroupConfigUpdate: React.FC<Props> = ({
  className,
  networkGroup,
}) => {
  const edgeId = networkGroup?.edge?.id!;

  const {
    isHashEqual,
    lastSentHashTimeStamp,
    shouldEdgeConfigStillBeUpdating,
  } = useGetEdgeConfigUpdateByEdgeId(edgeId);

  return !shouldEdgeConfigStillBeUpdating ? (
    <div className={className}>
      <div className={isHashEqual ? '' : styles.redText}>
        <div className={styles.bold}>
          {isHashEqual ? 'Update Successful' : 'Update Failed'}
        </div>
        {isHashEqual ? null : <div>Please try again later</div>}
      </div>
      <div className={styles.timeStamp}>
        {lastSentHashTimeStamp ? (
          <TimeDistance timestamp={lastSentHashTimeStamp} />
        ) : (
          global.NOT_AVAILABLE
        )}
      </div>
    </div>
  ) : (
    <>
      {' '}
      <Loader size={16} />
    </>
  );
};

export default LastNetworkGroupConfigUpdate;
