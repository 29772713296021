import {
  GenericErrors,
  ResourceType,
  SopPolicyTypes,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  InputField,
  Label,
  PhoneCodeDropdown,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect, useMemo, useRef } from 'react';
import SelectCountry from '../../containers/Selects/SelectCountry';
import TimeZoneSelect from '../../containers/TimeZoneSelect';
import useConvertedArea from '../../hooks/useConvertedArea';
import { PropertyToLabel } from '../../types/global';
import { countryNamesFromISO } from '../../utils/countryNames';
import {
  getTimezoneFromCity,
  resolveAddressToCoordinates,
} from '../../utils/geo';
import styles from './EditSiteForm.module.css';
import { convertSquareFeetToMeters } from '@energybox/react-ui-library/dist/utils/util';
import {
  AreaUnitApiValue,
  AreaUnitLabels,
} from '@energybox/react-ui-library/dist/types/Global';
import { EditableFields } from '../../reducers/sites';
import SopInfo from '../../containers/Sop/SopInfo';
import { renderAPIerror, ApiError } from '../../utils/apiErrorFeedback';

import { Actions as SiteActions } from '../../actions/sites';

interface Props extends EditableFields {
  siteId?: string | number;
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
  viewAsInstaller?: boolean;
}

const EditSiteForm = ({
  siteId,
  onChange,
  title,
  email,
  country,
  street,
  street2,
  state,
  city,
  postalCode,
  phone,
  timeZone,
  latitude,
  longitude,
  formErrorsVisible,
  formErrors = {},
  areaTotal,
  apiError,
  viewAsInstaller,
}: Props) => {
  // separate phone country code from phone number
  const [parsedPhoneCode, parsedPhoneNumber] = useMemo(() => {
    let phoneCode: number | undefined = undefined;
    const hasPhoneCode = (phone || '').charAt(0) === '+';
    const phoneNumberStarts = hasPhoneCode ? phone.indexOf('-') + 1 : -1;
    if (hasPhoneCode) {
      phoneCode = +phone.slice(0, phoneNumberStarts - 1);
    }
    const phoneNumber =
      phoneNumberStarts === -1 ? phone : phone.slice(phoneNumberStarts);
    return [phoneCode, phoneNumber];
  }, [phone]);
  const { userAreaUnit, convertedArea, setConvertedArea } = useConvertedArea(
    areaTotal,
    true
  );

  useSetTimeZoneFromAddress(city, country, onChange);

  if (!userAreaUnit) return null;
  return (
    <>
      <form className={styles.form}>
        <div className={styles.formColumn}>
          <span className={styles.columnHeader}>General Information</span>
          <div className={styles.inputGroup}>
            <Label htmlFor="siteName" required>
              Site Name
            </Label>
            <div>
              <InputField
                id="siteName"
                type="text"
                name="title"
                value={title}
                placeholder={'Site_Name_###'}
                onChange={e =>
                  onChange(e.currentTarget.name, e.currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.title}
                customErrorText={formErrors.title && formErrors.title[0]}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="phone">{PropertyToLabel.phoneNumber}</Label>
            <div className={styles.phoneInputWrapper}>
              <PhoneCodeDropdown
                selectedPhoneCode={parsedPhoneCode}
                onSelect={value => {
                  onChange('phone', `+${value}-${parsedPhoneNumber || ''}`);
                }}
              />
              <InputField
                className={styles.phoneNumberInput}
                id="phone"
                type="text"
                name="phone"
                placeholder="phone number"
                value={parsedPhoneNumber}
                error={formErrorsVisible && !!formErrors.phone}
                customErrorText={formErrors.phone && formErrors.phone[0]}
                onChange={e => {
                  if (parsedPhoneCode) {
                    onChange(
                      'phone',
                      `+${parsedPhoneCode}-${e.currentTarget.value}`
                    );
                  } else {
                    onChange('phone', e.currentTarget.value);
                  }
                }}
              />
              {renderAPIerror(apiError, SiteActions.PATCH_SITE_ERROR)}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="email">Site Email Address</Label>
            <div>
              <InputField
                id="email"
                type="text"
                name="email"
                placeholder="email address"
                value={email}
                onChange={e => onChange('email', e.currentTarget.value || null)}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="areaTotal">
              Total Area ({AreaUnitLabels[userAreaUnit]})
            </Label>
            <div>
              <InputField
                id="areaTotal"
                type="text"
                name="areaTotal"
                value={convertedArea !== null ? convertedArea : ''}
                onChange={onChangeArea(
                  userAreaUnit,
                  setConvertedArea,
                  onChange
                )}
                error={formErrorsVisible && !!formErrors.areaTotal}
                customErrorText={
                  formErrors.areaTotal && formErrors.areaTotal[0]
                }
              />
            </div>
          </div>

          {siteId && !viewAsInstaller && (
            <div className={styles.inputGroup}>
              <Label htmlFor="occupiedHours">Occupied Hours</Label>
              <div id="occupiedHours">
                <SopInfo
                  showSopTitle
                  resourceId={siteId}
                  resourceType={ResourceType.SITE}
                  sopTypeCategory={'policy'}
                  isEquipment={false}
                  setUpSopPolicyType={SopPolicyTypes.SITE_OPERATING_HOURS}
                />
              </div>
            </div>
          )}
        </div>

        <div className={styles.formColumn}>
          <span className={styles.columnHeader}>Address</span>
          <div className={styles.inputGroup}>
            <Label htmlFor="street" required>
              {PropertyToLabel.street}
            </Label>
            <div>
              <InputField
                id="street"
                type="text"
                name="street"
                placeholder="Street and House #"
                value={street}
                onChange={e => onChange('street', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.street}
                customErrorText={formErrors.street && formErrors.street[0]}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="street2">{PropertyToLabel.street2}</Label>
            <div>
              <InputField
                id="street2"
                type="text"
                name="street2"
                placeholder="optional"
                value={street2}
                onChange={e => onChange('street2', e.currentTarget.value)}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="city" required>
              {PropertyToLabel.city}
            </Label>
            <div>
              <InputField
                id="city"
                type="text"
                name="city"
                placeholder="City or Town"
                value={city}
                onChange={e => onChange('city', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.city}
                customErrorText={formErrors.city && formErrors.city[0]}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="postalCode" required>
              {PropertyToLabel.postalCode}
            </Label>
            <div>
              <InputField
                id="postalCode"
                type="text"
                name="postalCode"
                placeholder={PropertyToLabel.postalCode}
                value={postalCode}
                onChange={e => onChange('postalCode', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.postalCode}
                customErrorText={
                  formErrors.postalCode && formErrors.postalCode[0]
                }
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="state">{PropertyToLabel.state}</Label>
            <div>
              <InputField
                id="state"
                type="text"
                name="state"
                placeholder="state"
                value={state}
                onChange={e => onChange('state', e.currentTarget.value)}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="country" required>
              {PropertyToLabel.country}
            </Label>
            <div>
              <SelectCountry
                onSelect={e => onChange('country', e)}
                value={country}
                error={formErrorsVisible && !!formErrors.country}
                customErrorText={formErrors.country && formErrors.country[0]}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <Label htmlFor="timeZone" required>
              Time Zone
            </Label>
            <div>
              <TimeZoneSelect
                onSelect={selectedTimeZone => {
                  onChange('timeZone', selectedTimeZone);
                }}
                value={timeZone}
                error={formErrorsVisible && !!formErrors.timeZone}
                customErrorText={formErrors.timeZone && formErrors.timeZone[0]}
              />
            </div>
          </div>
          <div className={styles.paddingTop}>
            <div className={styles.inputGroup}>
              <Label htmlFor="latitude" required>
                {PropertyToLabel.latitude}
              </Label>
              <div>
                <InputField
                  id="latitude"
                  type="text"
                  name="latitude"
                  placeholder="00.000000"
                  value={latitude || ''}
                  onChange={e =>
                    onChange(e.currentTarget.name, e.currentTarget.value)
                  }
                  error={formErrorsVisible && !!formErrors.latitude}
                  customErrorText={
                    formErrors.latitude && formErrors.latitude[0]
                  }
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <Label htmlFor="longitude" required>
                {PropertyToLabel.longitude}
              </Label>
              <div>
                <InputField
                  id="longitude"
                  type="text"
                  name="longitude"
                  placeholder="00.000000"
                  value={longitude || ''}
                  onChange={e =>
                    onChange(e.currentTarget.name, e.currentTarget.value)
                  }
                  error={formErrorsVisible && !!formErrors.longitude}
                  customErrorText={
                    formErrors.longitude && formErrors.longitude[0]
                  }
                />
              </div>
            </div>
            <div>
              <Button
                type="button"
                variant="outlined"
                size="small"
                disabled={!country || !street || !city}
                onClick={() =>
                  resolveAddressToCoordinates(
                    (latitude, longitude) => {
                      onChange('latitude', latitude);
                      onChange('longitude', longitude);
                    },
                    country,
                    street,
                    state,
                    city,
                    postalCode
                  )
                }
              >
                Lookup coordinates
              </Button>
            </div>
          </div>
        </div>
      </form>

      <div>
        <Label>* Mandatory fields</Label>
      </div>
    </>
  );
};

const useSetTimeZoneFromAddress = (
  city: string,
  country: string,
  onChange: (field: string, value: any) => void
) => {
  const userKeyUpTimeout = useRef(setTimeout(() => {}, 0));
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!city || !country || !city.length || !country.length) {
      return;
    }
    userKeyUpTimeout.current = setTimeout(() => {
      const countryName = countryNamesFromISO[country];
      getTimezoneFromCity({ city, countryName }, onChange);
    }, 500);

    const cleanUp = () => clearTimeout(userKeyUpTimeout.current);
    return cleanUp;
  }, [city, country, onChange]);
};

const onChangeArea = (
  userAreaUnit: AreaUnitApiValue | null,
  setConvertedArea: React.Dispatch<React.SetStateAction<string | null>>,
  onChangeFormFields: (field: string, value: any) => void
) => ({ currentTarget }) => {
  if (!userAreaUnit) return;
  if (userAreaUnit === AreaUnitApiValue.M2) {
    setConvertedArea(`${currentTarget.value}`);
    onChangeFormFields('areaTotal', currentTarget.value);
  } else {
    const parsedValue = Number.parseFloat(currentTarget.value);
    const areaHelper = isNaN(parsedValue)
      ? currentTarget.value
      : `${parsedValue}`;
    if (currentTarget.value.endsWith('.')) {
      setConvertedArea(areaHelper + '.');
    } else {
      setConvertedArea(areaHelper);
    }
    const convertedToMeters = isNaN(parsedValue)
      ? currentTarget.value
      : convertSquareFeetToMeters(parsedValue, 2);
    onChangeFormFields('areaTotal', convertedToMeters);
  }
};

export default EditSiteForm;
