import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../reducers';
import { RecordsByResourceId } from '../../reducers/records';

export const useRecordByResourceIds = () => {
  const recordsByResourceId = useSelector<
    ApplicationState,
    RecordsByResourceId
  >(({ records }) => {
    return isDefined(records.recordsByResourceId)
      ? records.recordsByResourceId
      : {};
  });

  return recordsByResourceId;
};

export default useRecordByResourceIds;
