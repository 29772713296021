import {
  EnergyApiFilter,
  EnergyPowerApiResponse,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnergyByEquipmentId } from '../actions/energy';
import { getTSEnergyByEquipmentId } from '../actions/energyTS';
import { ApplicationState } from '../reducers';

const useGetEnergyByEquipmentId = (
  equipmentId: number | string | undefined,
  queryParams: EnergyApiFilter,
  isNewTsdb: boolean,
  secondaryId?: string
) => {
  const dispatch = useDispatch();
  const pathId = isDefined(secondaryId)
    ? equipmentId + `_${secondaryId}`
    : equipmentId;

  useEffect(() => {
    if (equipmentId) {
      isNewTsdb
        ? dispatch(
            getTSEnergyByEquipmentId(equipmentId, queryParams, secondaryId)
          )
        : dispatch(
            getEnergyByEquipmentId(equipmentId, queryParams, secondaryId)
          );
    }
  }, [isNewTsdb, equipmentId, queryParams]);

  const data = useSelector<
    ApplicationState,
    EnergyPowerApiResponse | undefined
  >(({ energy }) => {
    return pathOr(undefined, ['energyByEquipmentId', pathId], energy);
  });

  const isLoading = useSelector<ApplicationState, boolean>(({ energy }) => {
    return pathOr(false, ['isLoadingByResourceId', pathId], energy);
  });

  return { data, isLoading };
};

export default useGetEnergyByEquipmentId;
