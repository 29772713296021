import { Checkbox } from '@energybox/react-ui-library/dist/components';
import {
  Site,
  Sop,
  EquipmentType,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import styles from './StepRollout.module.css';
import { ReminderType } from '@energybox/react-ui-library/dist/types/Reminder';
import {
  convertWeekdays,
  sortWeekdays,
} from '../../../../utils/getWeekdayRange';

type Props = {
  orgId: number;
  site: Site;
  sops: Sop[];
  reminderType?: ReminderType;
  handleTargetSelect: (siteId: number | string, sopId: number | string) => void;
  selected: number[];
  selectedEquipmentType: EquipmentType[];
};

type State = {
  sops: Sop[];
  isLoading: boolean;
  isFirstTime: number;
  selected: number[];
};

class StepRolloutEquipment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sops: [],
      isLoading: true,
      isFirstTime: 0,
      selected: [],
    };
  }

  componentDidMount(): void {
    const { sops, selected } = this.props;
    this.setState({
      sops: sops,
      isLoading: sops.length === 0,
      selected: selected,
    });
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      selected: props.selected,
      sops: props.sops,
    };
  }

  handleTargetSelectClick = (ev: any, item: Sop) => {
    const { handleTargetSelect, site } = this.props;
    ev.preventDefault();
    this.setState({
      isFirstTime: 1,
    });
    handleTargetSelect(site.id, item.id);
  };

  findOverrideSop = (sop, taskSops, orgId) => {
    return taskSops.find(item => {
      return (
        item.organizationUnitId !== orgId &&
        // sop.equipmentTypeIds === item.equipmentTypeIds
        sop.equipmentTypeIds.reduce((match, equipTypeId) => {
          return item.equipmentTypeIds.includes(equipTypeId) || match;
        }, false)
      );
    });
  };

  render() {
    const { sops = [], isLoading, selected } = this.state;
    const { orgId, site, selectedEquipmentType } = this.props;
    const sopsBySite = sops.filter(item => {
      return (
        item.organizationUnitId === site.id || item.organizationUnitId === orgId
      );
    });
    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }
    return (
      <div>
        <table className={styles.table}>
          <tbody>
            {sops.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div>The site does not have the required equipment type.</div>
                </td>
              </tr>
            ) : (
              sops.map((item: Sop) => {
                const weekdays =
                  item.components[0].type === 'TASK'
                    ? item.components[0].taskSettings.weekdays
                    : [];
                const weekdayString = convertWeekdays(weekdays);
                const hasOverride = this.findOverrideSop(
                  item,
                  sopsBySite,
                  orgId
                );
                const isOrgLevelSop = item.organizationUnitId === orgId;

                if (isOrgLevelSop && hasOverride) {
                  return <></>;
                }

                if (
                  selectedEquipmentType &&
                  selectedEquipmentType.length !== 0
                ) {
                  const selectedEquipmentTypeIds = selectedEquipmentType.map(
                    i => i.id
                  );
                  if (
                    item.equipmentTypes.filter(i =>
                      selectedEquipmentTypeIds.includes(i.id)
                    ).length === 0
                  ) {
                    return <></>;
                  } else {
                    return (
                      <tr
                        key={`renderSopRows${item.id}`}
                        onClick={ev => this.handleTargetSelectClick(ev, item)}
                        className={styles.filterRow}
                      >
                        <td
                          key={`sopColumn${item.id}`}
                          style={{ width: '20%' }}
                        >
                          {item.title}
                          <br />
                          {item.description}
                        </td>
                        <td
                          key={`sopLevelColumn${item.id}`}
                          style={{ width: '20%' }}
                        >
                          {item.organizationUnitId === orgId
                            ? 'Organization'
                            : 'Site'}
                        </td>
                        <td
                          key={`equipmentTypeColumn${item.id}`}
                          style={{ width: '20%' }}
                        >
                          {item.equipmentTypes
                            // .filter(i =>
                            //   selectedEquipmentTypeIds.includes(i.id)
                            // )
                            .map(equipmentType => {
                              return (
                                <div key={`equipmentType${equipmentType.id}`}>
                                  {equipmentType.title}
                                </div>
                              );
                            })}
                        </td>
                        <td
                          key={`daysColumn${item.id}`}
                          style={{ width: '20%' }}
                        >
                          {item.components[0].type === 'TASK' &&
                          typeof weekdayString === 'string'
                            ? weekdayString
                            : sortWeekdays(weekdays).join(', ')}
                        </td>
                        <td
                          key={`checkColumn${item.id}`}
                          style={{ width: '20%' }}
                        >
                          {item.components[0].type === 'TASK' &&
                            `Check 1: ${item.components[0].taskSettings.time}`}
                        </td>
                        <td
                          key={`checkboxColumn${item.id}`}
                          className={styles.action}
                          style={{ width: '20%' }}
                        >
                          <Checkbox
                            key={`checkbox_${site.id}_${item.id}`}
                            checked={selected?.includes(item.id) || false}
                            onChange={ev =>
                              this.handleTargetSelectClick(ev, item)
                            }
                            id={`checkbox_${site.id}_${item.id}`}
                            size={16}
                          />
                        </td>
                      </tr>
                    );
                  }
                } else {
                  return (
                    <tr
                      key={`renderSopRows${item.id}`}
                      onClick={ev => this.handleTargetSelectClick(ev, item)}
                      className={styles.filterRow}
                    >
                      <td key={`sopColumn${item.id}`} style={{ width: '20%' }}>
                        {item.title}
                        <br />
                        {item.description}
                      </td>
                      <td
                        key={`sopLevelColumn${item.id}`}
                        style={{ width: '20%' }}
                      >
                        {item.organizationUnitId === orgId
                          ? 'Organization'
                          : 'Site'}
                      </td>
                      <td
                        key={`equipmentTypeColumn${item.id}`}
                        style={{ width: '20%' }}
                      >
                        {item.equipmentTypes.map(equipmentType => {
                          return (
                            <div key={`equipmentType${equipmentType.id}`}>
                              {equipmentType.title}
                            </div>
                          );
                        })}
                      </td>
                      <td key={`daysColumn${item.id}`} style={{ width: '20%' }}>
                        {item.components[0].type === 'TASK' &&
                        typeof weekdayString === 'string'
                          ? weekdayString
                          : sortWeekdays(weekdays).join(', ')}
                      </td>
                      <td
                        key={`checkColumn${item.id}`}
                        style={{ width: '20%' }}
                      >
                        {item.components[0]?.type === 'TASK' &&
                          `Check 1: ${item.components[0].taskSettings.time}`}
                      </td>
                      <td
                        key={`checkboxColumn${item.id}`}
                        className={styles.action}
                        style={{ width: '20%' }}
                      >
                        <Checkbox
                          key={`checkbox_${site.id}_${item.id}`}
                          checked={selected?.includes(item.id) || false}
                          onChange={ev =>
                            this.handleTargetSelectClick(ev, item)
                          }
                          id={`checkbox_${site.id}_${item.id}`}
                          size={16}
                        />
                      </td>
                    </tr>
                  );
                }
              })
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StepRolloutEquipment;
