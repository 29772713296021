import { UtilityBillMetadata } from '@energybox/react-ui-library/dist/types/UtilityBill';
import { EnergyboxService } from '../config';
import { BillEdit } from '../reducers/utilityBills';
import {
  BillMetadata,
  UploadFileUploading,
  UploadResponse,
} from '../types/upload';
import { fetchApi } from '../utils/apiUtils';

export const apiBase = '/api/v2/files';

import Actions from './UtilityBillActions';

export const getUtilityBillsBySiteId = (siteId: number | string) => ({
  service: EnergyboxService.files,
  type: 'API_GET',
  path: `${apiBase}?resourceId=${siteId}&fileType=UTILITY_BILL`,
  success: { type: Actions.GET_BILLS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_BILLS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_BILLS_BY_SITE_ID_LOADING, siteId },
});

export const uploadSingleSiteUtilityBill = async (
  upload: UploadFileUploading<BillMetadata>,
  siteId?: number
): Promise<UploadResponse> => {
  const { file, startDate, endDate } = upload;
  const { name } = file;

  const payload = new FormData();
  const metadata = JSON.stringify({
    provider: 'testprovider',
    status: 'PENDING',
    billType: 'test',
    startDate,
    endDate,
  });

  payload.append('file', file);
  payload.append('fileName', name);
  payload.append('resourceId', siteId?.toString() ?? '');
  payload.append('fileType', 'UTILITY_BILL');
  payload.append('trackProgress', 'true');
  payload.append('metadata', metadata);

  return fetchApi({
    service: EnergyboxService.files,
    endpoint: `${apiBase}/upload`,
    method: 'POST',
    payload,
    sendingFormData: true,
  });
};

// Logic:
// Call /generate-presigned-url endpoint to retrieve a presigned url
// ttl (in s) of presigned is 15 minutes by default
//
// Sample Response:
// {"url":"https://someDownloadUrl","expiredAt":"2022-05-10T07:41:07.767758Z"}
export const downloadUtilityBill = async (fileId: string) => {
  try {
    const { url } = await fetchApi({
      service: EnergyboxService.files,
      endpoint: `${apiBase}/generate-presigned-url`,
      method: 'POST',
      payload: { fileId, ttl: 900 },
    });

    const downloader = document.createElement('a');
    downloader.href = url;
    document.body.appendChild(downloader);
    downloader.click();
    document.body.removeChild(downloader);
  } catch (e) {
    return;
  }
};

export const editFile = (edit: BillEdit) => ({
  type: Actions.EDIT_BILL_UPDATE,
  edit,
});

export const cancelEdit = (edit: BillEdit) => ({
  type: Actions.EDIT_BILL_CANCEL,
  edit,
});

export const sendEdit = (edit: BillEdit) => {
  const {
    file,
    newBilling: { startDate, endDate },
  } = edit;
  let { metadata: originalMeta, fileId } = file;

  let metadata: UtilityBillMetadata;

  if (originalMeta) {
    originalMeta.startDate = startDate;
    originalMeta.endDate = endDate;
    metadata = originalMeta;
  } else {
    metadata = {
      startDate,
      endDate,
    };
  }

  return {
    service: EnergyboxService.files,
    path: `${apiBase}/${fileId}`,
    type: 'API_PUT',
    payload: { metadataString: JSON.stringify(metadata) },
    success: { type: Actions.EDIT_BILL_SUCCESS, fileId },
    error: { type: Actions.EDIT_BILL_ERROR, fileId },
    loading: { type: Actions.EDIT_BILL_LOADING, fileId },
  };
};

export const deleteFileBySiteIdAndFileId = (
  siteId: string,
  fileId: string
) => ({
  service: EnergyboxService.files,
  path: `${apiBase}/${fileId}`,
  type: 'API_DELETE',
  success: { type: Actions.DELETE_BILLS_SUCCESS, siteId, fileId },
  error: Actions.DELETE_BILLS_ERROR,
  loading: Actions.DELETE_BILLS_LOADING,
});
