import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { EquipmentGroup } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentGroups } from '../../actions/app';
import { ApplicationState } from '../../reducers';

interface Props {
  onSelect: (equipmentGroupId: number) => void;
  value?: number;
  error: boolean;
  customErrorText?: string;
  equipmentGroups?: EquipmentGroup[];
}

const EquipmentGroupSelect: React.FC<Props> = props => {
  const { value, onSelect, error, customErrorText } = props;

  const [search, setSearch] = useState('');

  const equipmentGroups = useSelector(({ app }: ApplicationState) =>
    app.equipmentGroups?.sort(({ title: aTitle }, { title: bTitle }) =>
      aTitle.localeCompare(bTitle)
    )
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!equipmentGroups) dispatch(getEquipmentGroups());
  }, [dispatch]);

  const searchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const resetSearch = () => {
    setSearch('');
  };

  // Render starts here
  if (!equipmentGroups) {
    return <Loader size={12} />;
  } else {
    const listedEquipmentGroups = (equipmentGroups || []).filter(
      (eq: EquipmentGroup) => {
        if (search.length <= 2) return true;
        return hasSubstr(eq.title, search);
      }
    );

    const selectedEquipmentGroup = equipmentGroups.find(eq => eq.id === value);

    return (
      <Select
        variant={'select'}
        onClick={resetSearch}
        title={selectedEquipmentGroup?.title}
        value={selectedEquipmentGroup?.id}
        error={error}
        customErrorText={customErrorText}
      >
        <SelectSearch
          onChange={searchChange}
          value={search}
          error={listedEquipmentGroups.length === 0}
        />
        {listedEquipmentGroups.map((eq: EquipmentGroup) => (
          <SelectItem
            key={eq.id}
            isSelected={eq.id === selectedEquipmentGroup?.id}
            onSelect={() => onSelect(eq.id)}
          >
            {eq.title}
          </SelectItem>
        ))}
      </Select>
    );
  }
};

export default EquipmentGroupSelect;
