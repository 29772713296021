import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as CircuitBreakerActions,
  destroy,
  hideDeleteCircuitBreakerModal,
  unstageDelete,
} from '../../actions/circuit_breakers';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface OwnProps {
  panelId: number;
}

interface Props extends OwnProps {
  isVisible: boolean;
  primedForDelete: number;
  onDelete: (panelId: number, breakerId: number) => void;
  onClose: () => void;
  apiError: ApiError;
}

class DeleteCircuitBreakerModal extends React.Component<Props> {
  render() {
    if (!this.props.isVisible) return null;

    const {
      onDelete,
      onClose,
      apiError,
      primedForDelete,
      panelId,
    } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>

        <Button onClick={() => onDelete(panelId, primedForDelete)}>
          Delete
        </Button>
      </>
    );
    return (
      <Modal actions={actions}>
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ textAlign: 'justify', textAlignLast: 'center' }}>
            <div style={{ padding: '1rem 0' }}>
              Are you sure you want to delete this circuit breaker? This will
              remove all Energy Bar/Spider/Flex sensors attached to this
              breaker.
            </div>
            {renderAPIerror(
              apiError,
              CircuitBreakerActions.DELETE_CIRCUIT_BREAKER_ERROR
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ circuitBreakers }: ApplicationState) => {
  return {
    isVisible: circuitBreakers.showDeleteCircuitBreakerModal,
    primedForDelete: circuitBreakers.primedForDelete,
    apiError:
      circuitBreakers.primedForDelete !== -1 &&
      circuitBreakers.editById[circuitBreakers.primedForDelete].apiError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onDelete: (panelId: number, breakerId: number) => {
    dispatch(destroy(panelId, breakerId));
  },
  onClose: () => {
    dispatch(unstageDelete());
    dispatch(hideDeleteCircuitBreakerModal());
  },
  unstageDelete: () => dispatch(unstageDelete()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCircuitBreakerModal);
