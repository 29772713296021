import { isDefined } from '@energybox/react-ui-library/dist/utils';
import DeviceOnlineState, {
  DisplayType,
} from '../../DeviceStatus/DeviceOnlineState';
import { useControlBoardById } from '../../../hooks/useControlBoard';
import { global } from '@energybox/react-ui-library/dist/utils';
import styles from './EditModeOffset.module.css';

type Props = {
  lightSensorControlBoardId: number;
  controlBoardId: number;
};

const ContorlBoardLiveStatus: React.FC<Props> = ({
  lightSensorControlBoardId,
  controlBoardId,
}) => {
  const controlBoardForLightSensor = useControlBoardById(
    lightSensorControlBoardId
  );
  const controlBoardForBaseSchedular = useControlBoardById(controlBoardId);
  const controlBoardToSubscribeTo =
    controlBoardForLightSensor || controlBoardForBaseSchedular;
  const renderOnlineStatus = () => {
    return (
      isDefined(controlBoardToSubscribeTo) && (
        <DeviceOnlineState
          displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
          devices={[
            {
              id: controlBoardToSubscribeTo.id,
              uuid: controlBoardToSubscribeTo.uuid,
              vendor: controlBoardToSubscribeTo.vendor,
            },
          ]}
        />
      )
    );
  };

  return (
    <>
      {!isDefined(controlBoardId) ? (
        <div className={styles.pinkText}> No Relay </div>
      ) : (
        renderOnlineStatus()
      )}
    </>
  );
};

export default ContorlBoardLiveStatus;
