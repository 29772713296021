import {
  Actions as SentinelActions,
  getSentinelById,
} from '../actions/sentinels';
import { Routes } from '../routes';
import history from '../history';

const sentinelMiddleware = store => next => action => {
  switch (action.type) {
    case SentinelActions.UPDATE_SENTINEL_SUCCESS:
    case SentinelActions.CREATE_SENTINEL_SUCCESS:
      store.dispatch(getSentinelById(action.data.id));
      history.push(Routes.SENTINELS);
      return next(action);
    default:
      return next(action);
  }
};

export default sentinelMiddleware;
