import React from 'react';
import { Route } from 'react-router-dom';
import { Tab } from '@energybox/react-ui-library/dist/components';

export default ({ to, ...props }) => (
  <Route
    path={to}
    children={({ match, history }) => (
      <Tab
        active={!!match}
        onClick={() => {
          if (props?.search) {
            return history.push(`${to}${props?.search}`);
          } else {
            return history.push(to);
          }
        }}
        {...props}
      />
    )}
  />
);
