import { EnergyPro } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnergyProsBySiteId } from '../actions/energy_pros';
import { ApplicationState } from '../reducers';
import { EnergyProsById } from '../reducers/energy_pros';

export const useGetEnergyProsBySiteId = (
  siteId: string | number | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(siteId)) {
      dispatch(getEnergyProsBySiteId(siteId));
    }
  }, [dispatch, siteId]);

  const energyProIdsBySiteId = useSelector<ApplicationState, number[]>(
    ({ energyPros }) => {
      if (isDefined(siteId)) {
        return energyPros.energyProIdsBySiteId[siteId] || [];
      }
      return [];
    }
  );

  const energyProsById = useSelector<ApplicationState, EnergyProsById>(
    ({ energyPros }) => {
      return energyPros.energyProsById;
    }
  );

  const siteEnergyPros: EnergyPro[] = useMemo(() => {
    return energyProIdsBySiteId
      .map(energyProId => energyProsById[energyProId])
      .filter(energyPro => isDefined(energyPro));
  }, [energyProIdsBySiteId, energyProsById]);

  return siteEnergyPros;
};
