import { parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  FileIcon,
  LongSkeletonCell,
  LongExtraLongSkeletonCell,
  MediumSkeletonCell,
  ShortSkeletonCell,
  Table,
  MenuDropdown,
  MenuDropdownItem,
  SingleDatePicker,
} from '@energybox/react-ui-library/dist/components';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  OpacityIndex,
  SortDirection,
  UtilityBill,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  determineTimeStringByTimezone,
  displayByte,
  genericDateFormat,
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';

import styles from './UtilityBillTable.module.css';
import { downloadUtilityBill } from '../../actions/utilityBills';
import Actions from '../../actions/UtilityBillActions';
import { useSiteTimezone } from '../../hooks/useSites';
import { useUtilityBills } from '../../hooks/useUtilityBills';
import { ApplicationState } from '../../reducers';
import { useEffect, useState } from 'react';
import { Delete, Download, Save } from '@energybox/react-ui-library/dist/icons';
import DeleteModal from '../Modals/DeleteModal';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import { BillEdit } from '../../reducers/utilityBills';

type Props = {
  title: string;
  siteId: number;
  editable?: boolean;
  isUploadFromEdmPage?: boolean;
};

const UtilityBillTable = ({
  title,
  siteId,
  editable,
  isUploadFromEdmPage,
}: Props) => {
  const ianaTimeZoneCode = useSiteTimezone(siteId);
  const locale = useSelector(({ app }: ApplicationState) => app.locale);

  const {
    utilityBills,
    editByFileId,
    isLoading,
    isDeleting,
    apiError,
    deleteFileById,
    editFile,
    cancelEdit,
    sendEdit,
  } = useUtilityBills(siteId);

  const [downloading, startDownload] = useState(false);
  const [fileToDelete, selectFileToDelete] = useState<UtilityBill>();

  const viewFile = async (fileId: string) => {
    if (!downloading) {
      startDownload(true);
      await downloadUtilityBill(fileId);
      startDownload(false);
    }
  };

  const onEdit = (fileToEdit: UtilityBill, edit?: BillEdit) =>
    editFile(
      edit || {
        file: fileToEdit,
        newBilling: fileToEdit.billing,
      }
    );

  useEffect(() => {
    if (!isDeleting && !apiError) {
      // A good deletion
      selectFileToDelete(undefined);
    }
  }, [isDeleting, apiError]);

  const onDelete = async () => {
    if (fileToDelete && !isDeleting) {
      deleteFileById(fileToDelete.fileId);
    }
  };

  const deleteError = apiError
    ? renderAPIerror(apiError, Actions.DELETE_BILLS_ERROR)
    : undefined;

  const renderBillingPeriod = (file: UtilityBill): JSX.Element => {
    const edit = editByFileId[file.fileId];
    if (edit) {
      if (edit.isLoading) {
        return <LongSkeletonCell />;
      }
      const { startDate, endDate } = edit.newBilling;
      return (
        <div className={styles.datePickers}>
          <SingleDatePicker
            locale={locale}
            value={startDate}
            setDate={newStartDate => {
              onEdit(file, {
                file,
                newBilling: {
                  startDate: newStartDate,
                  endDate,
                },
              });
            }}
            disableAfter={endDate}
          />
          <span className={styles.separator}>-</span>
          <SingleDatePicker
            locale={locale}
            value={endDate}
            setDate={newEndDate => {
              onEdit(file, {
                file,
                newBilling: {
                  startDate,
                  endDate: newEndDate,
                },
              });
            }}
            disableBefore={startDate}
          />
        </div>
      );
    }
    return (
      <div className={styles.cellContainer}>
        {genericDateFormat(parseISO(file.billing.startDate), locale.dateFormat)}{' '}
        - {genericDateFormat(parseISO(file.billing.endDate), locale.dateFormat)}
      </div>
    );
  };

  const renderDownload = (file: UtilityBill): JSX.Element => {
    return (
      <div className={styles.buttonContainer}>
        <div
          className={styles.actionItem}
          onClick={() => viewFile(file.fileId)}
        >
          <Download size={18} color={'var(--accent-baseMinus10)'} />
        </div>
      </div>
    );
  };
  const renderActionItems = (file: UtilityBill): JSX.Element => {
    const edit = editByFileId[file.fileId];
    if (edit) {
      if (edit.isLoading) {
        return <></>;
      }
      return (
        <div className={styles.buttonContainer}>
          <div className={styles.actionItem} onClick={() => sendEdit(edit)}>
            <Save size={20} color={'var(--accent-baseMinus10)'} />
          </div>
          <div
            className={classNames(styles.actionItem, styles.leftSpace)}
            onClick={() => cancelEdit(edit)}
          >
            <Delete size={16} color={'var(--accent-baseMinus10)'} />
          </div>
        </div>
      );
    }
    return (
      <>
        {editable && (
          <div className={styles.leftSpace}>
            <MenuDropdown>
              <MenuDropdownItem
                onSelect={() => onEdit(file)}
                disabled={downloading}
              >
                Edit
              </MenuDropdownItem>
              <MenuDropdownItem
                isRed
                onSelect={() => selectFileToDelete(file)}
                disabled={downloading}
              >
                Delete
              </MenuDropdownItem>
            </MenuDropdown>
          </div>
        )}
      </>
    );
  };

  const columns: Columns<UtilityBill>[] = [
    {
      header: 'Document',
      width: isUploadFromEdmPage ? '35%' : '40%',
      cellContent: ({ fileName, fileType }) => (
        <div className={styles.cellContainer}>
          <div className={styles.fileIcon}>
            <FileIcon fileType={fileType} size={26} />
          </div>
          <div className={styles.leftSpace}>{fileName}</div>
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: UtilityBill,
        b: UtilityBill,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'filename',
        ]);
      },
    },
    {
      header: 'Billing Period',
      width: isUploadFromEdmPage ? '27%' : '30%',
      cellContent: renderBillingPeriod,
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: UtilityBill,
        b: UtilityBill,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(
          a.billing.startDate,
          b.billing.startDate,
          sortDirection,
          SORT_IGNORED_VALUES,
          parseISO
        );
      },
    },

    {
      header: 'Size',
      width: isUploadFromEdmPage ? '17%' : '7%',
      cellContent: ({ fileSize }) => {
        return (
          <div className={styles.cellContainer}>
            <div>{displayByte(fileSize)}</div>
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: UtilityBill,
        b: UtilityBill,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'size',
        ]);
      },
    },

    {
      header: 'Timestamp',
      width: isUploadFromEdmPage ? '25%' : '15%',
      defaultSortDirection: SortDirection.DESC,
      isDefaultSort: true,
      cellContent: ({ modifiedAt, createdAt }) => (
        <div className={styles.cellContainer}>
          {determineTimeStringByTimezone(
            new Date(modifiedAt || createdAt),
            locale.dateTimeFormat,
            ianaTimeZoneCode,
            true
          )}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (
        a: UtilityBill,
        b: UtilityBill,
        sortDirection: SortDirection
      ) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'modifiedAt',
        ]);
      },
    },

    {
      header: 'Download',
      width: '5%',
      cellContent: renderDownload,
      skeletonCellContent: () => <div />,
    },
    {
      header: 'Actions',
      width: '7%',
      cellContent: renderActionItems,
      skeletonCellContent: () => <div />,
    },
  ];

  const columnsWitoutActions = columns.filter(column => {
    return column.header !== 'Actions' && column.header !== 'Download';
  });

  return (
    <>
      <div className={styles.tableGrid}>
        {title && <div className={styles.cardTitle}>{title}</div>}
      </div>
      {!isUploadFromEdmPage ? (
        <Table
          listView
          columns={columns}
          data={utilityBills}
          dataIsLoading={isLoading}
          skeletonRowCount={3}
        />
      ) : (
        <Table
          columns={columnsWitoutActions}
          data={utilityBills.slice(0, 3)}
          dataIsLoading={isLoading}
          hideRowLimitControl={true}
          usePageSelectControl={false}
          listView={false}
        />
      )}
      {fileToDelete && (
        <DeleteModal
          onClose={() => selectFileToDelete(undefined)}
          onDelete={onDelete}
          apiError={deleteError}
          title={fileToDelete.fileName}
          isDeleting={isDeleting}
        />
      )}
    </>
  );
};

export default UtilityBillTable;
