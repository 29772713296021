import fetch from 'node-fetch';

export enum Actions {
  GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS = '@@weather/GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS',
  GET_WEATHER_BY_MULTI_SITES_IDS_LOADING = '@@weather/GET_WEATHER_BY_MULTI_SITES_IDS_LOADING',
  GET_WEATHER_BY_MULTI_SITES_IDS_ERROR = '@@weather/GET_WEATHER_BY_MULTI_SITES_IDS_ERROR',
}

export const getWeatherByMultiSitesIds = (siteIds: number[]) => {
  return async dispatch => {
    try {
      dispatch({ type: Actions.GET_WEATHER_BY_MULTI_SITES_IDS_LOADING });

      // using own fetch to avoid CORS and other store initialization errors
      const response = await fetch(
        process.env.REACT_APP_SERVICE_WEATHER_ROOT +
          '/weather/multisite/latest',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(siteIds),
        }
      );

      if (!response.ok) {
        throw new Error('Response failed');
      }
      const result = await response.json();

      dispatch({
        type: Actions.GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS,
        payload: result,
      });
    } catch (error) {
      dispatch({ type: Actions.GET_WEATHER_BY_MULTI_SITES_IDS_ERROR, error });
    }
  };
};
