import {
  Gateway,
  ResourceType,
  Site,
  SentinelTarget,
  SentinelTargetType,
} from '@energybox/react-ui-library/dist/types';
import { Checkbox } from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { fetchGateways } from '../../../../actions/gatewaysFetch';
import ResourcePath from '../../../../containers/ResourcePath';
import styles from './StepRollout.module.css';
import { SensorChip } from './TargetChips';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { filterControlBoardsByHardwareVersion } from './../StepRollout/Util';

type Props = {
  site: Site;
  handleTargetSelect: (sentinelTarget: SentinelTarget) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  filterByEntityType?: 'ControlBoard';
  filterByDeviceTypes: string[];
};

type State = {
  gateways: Gateway[];
  isLoading: boolean;
};

class StepRolloutGateways extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gateways: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    let dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
    if (dublicateSentinelkey) {
      const { handleTargetSelect } = this.props;
      if (this.props.selected.length > 0) {
        handleTargetSelect({
          targetId: this.props.selected[0],
          targetType: SentinelTargetType.GATEWAY,
        });
      }
    }
    this.onLoad();
  }

  async componentDidUpdate(prevProps) {
    // Compare the previous props with the current props
    if (prevProps.filterByDeviceTypes !== this.props.filterByDeviceTypes) {
      // Logic to execute when actionTrigger changes
      const gateways = await fetchGateways({
        siteIds: [this.props.site.id],
      });
      let filteredGateways = gateways;
      if (isDefined(this.props.filterByEntityType)) {
        filteredGateways = gateways.filter(
          gateway => gateway._entity === this.props.filterByEntityType
        );
      }

      if (this.props.filterByEntityType === 'ControlBoard') {
        filteredGateways =
          filterControlBoardsByHardwareVersion(
            this.props.filterByDeviceTypes,
            filteredGateways
          ) || filteredGateways;
      }

      this.setState({ gateways: filteredGateways });
    }
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation, filterByEntityType } = this.props;

    try {
      const { site } = this.props;

      const gateways = await fetchGateways({
        siteIds: [site.id],
      });

      let filteredGateways = gateways;

      if (isDefined(filterByEntityType)) {
        filteredGateways = gateways.filter(
          gateway => gateway._entity === filterByEntityType
        );

        if (filterByEntityType === 'ControlBoard') {
          filteredGateways =
            filterControlBoardsByHardwareVersion(
              this.props.filterByDeviceTypes,
              filteredGateways
            ) || filteredGateways;
        }
      }

      filteredGateways = filteredGateways.sort((a, b) =>
        a.title.localeCompare(b.title)
      );

      handleSetResourceRelation(
        site.id,
        filteredGateways.map((g: Gateway) => g.id)
      );

      this.setState({
        gateways: filteredGateways,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleTargetSelectClick = (ev: any, item: any) => {
    const { handleTargetSelect } = this.props;
    ev.preventDefault();
    handleTargetSelect({
      targetId: item.id,
      targetType: SentinelTargetType.GATEWAY,
    });
  };

  render() {
    const { gateways = [], isLoading } = this.state;
    const { site, selected, filterByEntityType } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    return (
      <div>
        <table className={styles.table}>
          <tbody>
            {gateways.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div>This site has no gateways.</div>
                </td>
              </tr>
            ) : (
              gateways.map((item: Gateway) => (
                <tr
                  key={`rolloutSensorRow${item.id}`}
                  onClick={ev => this.handleTargetSelectClick(ev, item)}
                  className={styles.filterRow}
                >
                  <td style={{ width: '25%' }}>
                    <SensorChip title={item.title} />
                  </td>
                  <td style={{ width: '25%' }}>
                    <ResourcePath
                      suppressLink
                      resourceId={item.id}
                      resourceType={ResourceType.SPACE}
                    />
                  </td>
                  <td className={styles.action} style={{ width: '15%' }}>
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={ev => this.handleTargetSelectClick(ev, item)}
                      id={`checkbox_${site.id}_${item.id}`}
                      size={16}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StepRolloutGateways;
