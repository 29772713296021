import {
  FormText,
  Label,
  NativeSelectField,
} from '@energybox/react-ui-library/dist/components';
import {
  CurrentUser,
  IntervalUnit,
  SentinelInterval,
  SentinelParameters,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { checkCommonPlural } from '../../../../util';
import TextField from '../../../ui/TextField/TextField';
import StepTitle from '../StepTitle';
import ConditionBatteryLevelCheck from './ConditionBatteryLevelCheck';
import ConditionSiteControllerOverrideCheck from './ConditionSiteControllerOverrideCheck';
import ConditionThermostatOverrideCheck from './ConditionThermostatOverrideCheck';
import ConditionBinaryCounter from './ConditionBinaryCounter';
import ConditionConnectivity from './ConditionConnectivity';
import ConditionCustomerComfort from './ConditionCustomerComfort';
import ConditionDoor from './ConditionDoor';
import ConditionTemperature from './ConditionTemperature';
import ConfigureThresholdLines from './ConfigureThresholds/ConfigureThresholdLines';
import styles from './StepCondition.module.css';
import StepConditionItem from './StepConditionItem';
import StepConditionItemInput from './StepConditionItemInput';

type DelayConditionProps = {
  onDelayChange: (intervalUnit: IntervalUnit, value: string) => void;
  onUnitChange: (unit: IntervalUnit) => void;
  delay: SentinelInterval;
};

const DelayCondition: React.FunctionComponent<DelayConditionProps> = ({
  delay,
  onDelayChange,
  onUnitChange,
}) => (
  <>
    <StepConditionItem
      label={<Label htmlFor="delayType">Delay Type:</Label>}
      condition={
        <NativeSelectField
          onChange={ev => onUnitChange(ev.currentTarget.value as IntervalUnit)}
          value={delay.unit}
          name="delayType"
          id="delayType"
        >
          <option value={IntervalUnit.MILLISECOND}>Time</option>
          <option value={IntervalUnit.DEVICEINTERVAL}>Device interval</option>
        </NativeSelectField>
      }
    />

    {delay.unit === IntervalUnit.MILLISECOND ? (
      <StepConditionTimeInput delay={delay} onDelayChange={onDelayChange} />
    ) : (
      <StepConditionItem
        label={<FormText>Notify After:</FormText>}
        condition={
          <StepConditionItemInput
            value={
              <TextField
                type="number"
                value={delay.value.toString()}
                onChange={(ev: any) =>
                  onDelayChange(delay.unit, ev.target.value)
                }
              />
            }
            unit={
              <FormText>{`interval${delay.value === 1 ? '' : 's'}`}</FormText>
            }
          />
        }
        description={`${checkCommonPlural(
          'device interval',
          delay.value
        )} of delay time`}
      />
    )}
  </>
);

type Props = {
  currentUser: CurrentUser;
  onChange: (arg0: string, arg1: string) => void;
  onDelayChange: (intervalUnit: IntervalUnit, value: string) => void;
  onUnitChange: (unit: IntervalUnit) => void;
  sentinelType: SentinelType;
  parameters?: SentinelParameters;
  delay: SentinelInterval;
  customDelaySentinels: SentinelType[];
};

const getSentinelSpecificCondition = (
  sentinelType,
  parameters,
  onChange,
  currentUser: CurrentUser
) => {
  switch (sentinelType) {
    case SentinelType.TEMPERATURE_AND_DOOR:
    case SentinelType.TEMPERATURE:
      return (
        <ConditionTemperature
          parameters={parameters}
          onValueChange={onChange}
          onComparisonTypeChange={onChange}
          currentUser={currentUser}
        />
      );
    case SentinelType.ACTIVE_POWER_THRESHOLD:
      return (
        <ConfigureThresholdLines
          thresholdEntity="Energy"
          unit="kW"
          rangeMin={0}
          rangeMax={500}
          parameters={parameters}
          onValueChange={onChange}
          onComparisonTypeChange={onChange}
        />
      );
    case SentinelType.BINARY_COUNTER:
      return (
        <ConditionBinaryCounter parameters={parameters} onChange={onChange} />
      );
    case SentinelType.DOOR_SIREN_CHECK:
      return <ConditionDoor parameters={parameters} onChange={onChange} />;

    case SentinelType.BINARY:
      return <ConditionDoor parameters={parameters} onChange={onChange} />;

    case SentinelType.CONNECTIVITY:
      return <ConditionConnectivity />;

    case SentinelType.BATTERY_LEVEL_CHECK:
      return <ConditionBatteryLevelCheck />;

    case SentinelType.CUSTOMER_COMFORT:
      return (
        <ConditionCustomerComfort parameters={parameters} onChange={onChange} />
      );

    case SentinelType.HUMIDITY:
      return (
        <ConfigureThresholdLines
          thresholdEntity="Humidity"
          unit="%"
          rangeMin={0}
          rangeMax={100}
          parameters={parameters}
          onValueChange={onChange}
          onComparisonTypeChange={onChange}
        />
      );

    case SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK:
      return <ConditionSiteControllerOverrideCheck />;

    case SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK:
      return <ConditionThermostatOverrideCheck />;

    default:
      return null;
  }
};

const getTimeValue = (value, type) => {
  if (value <= 0 || isNaN(value)) {
    return 0;
  }
  const hour = Math.floor(value / 1000 / 60 / 60);
  if (type === 'hour') {
    return hour;
  }
  const minute = (value - hour * 60 * 60 * 1000) / 1000 / 60;
  return minute;
};

const StepCondition = ({
  currentUser,
  onChange,
  onDelayChange,
  onUnitChange,
  parameters,
  sentinelType,
  delay,
  customDelaySentinels,
}: Props) => {
  return (
    <div>
      <StepTitle>
        Please define the condition under which you want to be notified.
      </StepTitle>
      <div className={styles.conditionalsWrapper}>
        {getSentinelSpecificCondition(
          sentinelType,
          parameters,
          onChange,
          currentUser
        )}

        {customDelaySentinels.includes(sentinelType) && (
          <DelayCondition
            delay={delay}
            onUnitChange={onUnitChange}
            onDelayChange={onDelayChange}
          />
        )}
      </div>
    </div>
  );
};

const StepConditionTimeInput = ({ delay, onDelayChange }) => {
  const hour = getTimeValue(delay.value, 'hour');
  const minute = getTimeValue(delay.value, 'minute');
  return (
    <StepConditionItem
      label={<Label>Notify After:</Label>}
      condition={
        <StepConditionItemInput
          inputClassName={styles.notifyAfterItem}
          value={
            <>
              <TextField
                type="number"
                containerClassName={styles.inlineInput}
                value={hour.toString()}
                onChange={(ev: any) =>
                  onDelayChange(
                    delay.unit,
                    Number(ev.target.value * 60) + minute
                  )
                }
              />
              <div className={styles.hhmmMark}>
                <FormText>:</FormText>
              </div>
              <TextField
                type="number"
                containerClassName={styles.inlineInput}
                value={minute.toString()}
                onChange={(ev: any) =>
                  onDelayChange(delay.unit, hour * 60 + Number(ev.target.value))
                }
              />
            </>
          }
          unit={null}
        />
      }
      description={`${checkCommonPlural('hour', hour)} ${checkCommonPlural(
        'minute',
        minute
      )} of delay time`}
    />
  );
};

export default StepCondition;
