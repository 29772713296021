import { Actions as AppActions } from '../actions/app';
import { Actions as SiteActions } from '../actions/sites';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as SensorActions } from '../actions/sensors';

const initialState = {
  equipment: false,
  sites: false,
  app: false,
  notifications: false,
  spaces: false,
  sensors: false,
};

export type Loading = {
  equipment: boolean;
  sites: boolean;
  app: boolean;
  notifications: boolean;
  spaces: boolean;
  sensors: boolean;
};

export default (state: Loading = initialState, action: any) => {
  switch (action.type) {
    case AppActions.LOGIN_LOADING:
      return {
        ...state,
        app: true,
      };

    case AppActions.LOGIN_SUCCESS:
    case AppActions.LOGIN_ERROR:
      return {
        ...state,
        app: false,
      };

    case EquipmentActions.GET_EQUIPMENT_LOADING: {
      return {
        ...state,
        equipment: true,
      };
    }
    case EquipmentActions.GET_EQUIPMENT_SUCCESS:
    case EquipmentActions.GET_EQUIPMENT_ERROR:
      return {
        ...state,
        equipment: false,
      };

    case SiteActions.GET_SITES_LOADING: {
      return {
        ...state,
        sites: true,
      };
    }

    case SiteActions.GET_SITES_SUCCESS:
    case SiteActions.GET_SITES_ERROR:
      return {
        ...state,
        sites: false,
      };

    case SensorActions.GET_SENSORS_LOADING: {
      return {
        ...state,
        sensors: true,
      };
    }

    case SensorActions.GET_SENSORS_SUCCESS:
    case SensorActions.GET_SENSORS_ERROR:
      return {
        ...state,
        sensors: false,
      };

    default: {
      return state;
    }
  }
};
