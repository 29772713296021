export enum Actions {
  PATH_LOADING = '@paths/PATH_LOADING',
  PATH_SUCCESS = '@paths/PATH_SUCCESS',
  PATH_ERROR = '@paths/PATH_ERROR',

  PATHS_LOADING = '@paths/PATHS_LOADING',
  PATHS_SUCCESS = '@paths/PATHS_SUCCESS',
  PATHS_ERROR = '@paths/PATHS_ERROR',

  CLEAR_RESOURCE_PATH = '@paths/CLEAR_RESOURCE_PATHS',
  CLEAR_RESOURCE_PATHS = '@paths/CLEAR_RESOURCE_PATHS',
}

export const getResourcePathById = (id: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/resources/${id}/path-tiny`,
  loading: { type: Actions.PATH_LOADING, id },
  success: { type: Actions.PATH_SUCCESS, id },
  error: { type: Actions.PATH_ERROR, id },
});

export const getResourcePathsByIds = (ids: (number | string)[]) => ({
  type: 'API_POST',
  path: '/api/v1/resources/paths-tiny',
  payload: ids,
  loading: { type: Actions.PATHS_LOADING, ids },
  success: { type: Actions.PATHS_SUCCESS, ids },
  error: { type: Actions.PATHS_ERROR, ids },
});

export const validateResourcePath = (resourcePathsById, resource: any) => {
  const id =
    typeof resource === 'number' || typeof resource === 'string'
      ? resource
      : resource.id;
  const path = resourcePathsById[id];
  if (path && path.length) {
    return path;
  } else {
    return false;
  }
};

export const validateResourcePaths = (resourcePathsById, resources: any[]) => {
  const validPaths = {};
  resources.forEach(resource => {
    const id =
      typeof resource === 'number' || typeof resource === 'string'
        ? resource
        : resource.id;
    const path = resourcePathsById[id];
    if (path && path.length) {
      validPaths[id] = path;
    }
  });
  return Object.keys(validPaths).length === resources.length
    ? validPaths
    : false;
};

export const clearResourcePath = (id: number) => ({
  type: Actions.CLEAR_RESOURCE_PATH,
  id,
});

export const clearResourcePaths = (ids: (number | string)[]) => ({
  type: Actions.CLEAR_RESOURCE_PATHS,
  ids,
});
