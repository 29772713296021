import {
  MenuDropdown,
  MenuDropdownItem,
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  Modal,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import {
  CircuitBreaker,
  EnergyDevice,
  EnergyPro,
} from '@energybox/react-ui-library/dist/types';
import { classNames, hasKeys } from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { MdDelete } from 'react-icons/md';
import { connect } from 'react-redux';
import {
  displayFormErrors,
  getEnergyDevice,
  hideDeleteEnergyDeviceModal,
  patch,
  reset,
  showDeleteEnergyDeviceModal,
  showNewEnergyDeviceSensorModal,
  showRemoveAllEnergyDeviceSensorsModal,
  updateField,
} from '../../../actions/energy_devices';
import { removeEnergyDeviceFromBus } from '../../../actions/energy_pros';
import EditEnergyDeviceForm from '../../../components/EditEnergyDeviceForm';
import { ApplicationState } from '../../../reducers';
import { EditEnergyDevice } from '../../../reducers/energy_devices';
import { ApiError } from '../../../utils/apiErrorFeedback';
import { SelectedEnergyDevice } from '../../DistributionPanels/ShowDistributionPanelPage/ShowDistributionPanelPage';
import NewEnergyDeviceSensorModal from '../../EnergyDevices/NewEnergyDeviceSensorModal';
import EnergyDeviceSensorsList from '../EnergyDeviceSensorsList/EnergyDeviceSensorsList';
import RemoveEnergyDeviceSensorModal from '../RemoveEnergyDeviceSensorModal';
import styles from './EnergyDeviceConfiguration.module.css';

interface OwnProps {
  id: string;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  energyPro: EnergyPro;
  expandedId: string;
  toggleCardState: (id: string) => void;
  predeterminedBus: number;
  panelId: string;
  selectedEnergyDevice: SelectedEnergyDevice;
  resetSelectedEnergyDevice: () => void;
  handleEnergyProChange: (eProId: number) => void;
}

interface Props extends OwnProps {
  getEnergyDevice: () => void;
  editEnergyDevice: EditEnergyDevice;
  energyDevice: EnergyDevice;
  onDelete: () => void;
  showDeleteEnergyDeviceModal: () => void;
  hideDeleteEnergyDeviceModal: () => void;
  showingDeleteEnergyDeviceModal: boolean;
  showingNewEnergyDeviceSensorModal: boolean;
  showNewEnergyDeviceSensorModal: () => void;
  onChange: (field: string, value: string | number) => void;
  patch: () => void;
  displayFormErrors: () => void;
  reset: () => void;
  showRemoveAllEnergyDeviceSensorsModal: () => void;
  showingRemoveAllEnergyDeviceSensorsModal: boolean;
  apiError: ApiError;
  circuitBreakers: CircuitBreaker[];
}

interface State {
  activeTab: string;
}

const tabs = [
  {
    name: 'SETTINGS',
    label: 'Settings',
  },
  {
    name: 'PORTS',
    label: 'Ports',
  },
];

class EnergyDeviceConfiguration extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'SETTINGS',
    };
  }

  componentDidMount = () => {
    this.openPortsTabIfSelectedEnergyDevice();
  };

  componentDidUpdate = prevProps => {
    const { circuitBreakers } = this.props;

    this.openPortsTabIfSelectedEnergyDevice();
    if (!equals(prevProps.circuitBreakers, circuitBreakers)) {
      this.props.getEnergyDevice();
    }
  };

  openPortsTabIfSelectedEnergyDevice = () => {
    if (
      this.props.selectedEnergyDevice.id !== -1 &&
      this.state.activeTab !== 'PORTS'
    ) {
      this.setState({
        activeTab: 'PORTS',
      });
    }
  };

  onEnergyDeviceSave = () => {
    const { patch, editEnergyDevice, displayFormErrors } = this.props;
    if (editEnergyDevice && hasKeys(editEnergyDevice.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  onCloseClick = () => {
    const { toggleCardState, reset, resetSelectedEnergyDevice } = this.props;
    toggleCardState('');
    resetSelectedEnergyDevice();
    reset();
  };

  onUnexpandedCardClick = () => {
    const {
      id,
      toggleCardState,
      energyPro,
      handleEnergyProChange,
    } = this.props;

    toggleCardState(id);
    handleEnergyProChange(energyPro.id);
  };

  renderCardActions = () => {
    const { editEnergyDevice } = this.props;
    return (
      <CardActions className={styles.cardActionsContainer}>
        <Button variant="text" onClick={this.onCloseClick} children="Close" />
        <Button
          disabled={!editEnergyDevice.isChanged}
          onClick={this.onEnergyDeviceSave}
        >
          {editEnergyDevice.isLoading ? (
            <Loader size={16} variant="secondary" />
          ) : (
            'Save'
          )}
        </Button>
      </CardActions>
    );
  };

  deletePrompt = () => {
    const { energyDevice, onDelete, hideDeleteEnergyDeviceModal } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={hideDeleteEnergyDeviceModal}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal onClose={hideDeleteEnergyDeviceModal} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete{' '}
          {energyDevice ? <b>{energyDevice.title}</b> : 'this energy device'}?
        </p>
      </Modal>
    );
  };

  render() {
    const {
      id,
      expandedId,
      editEnergyDevice,
      onChange,
      predeterminedBus,
      energyDevice,
      isFirstItem,
      isLastItem,
      showingNewEnergyDeviceSensorModal,
      showNewEnergyDeviceSensorModal,
      showingDeleteEnergyDeviceModal,
      showDeleteEnergyDeviceModal,
      panelId,
      showRemoveAllEnergyDeviceSensorsModal,
      showingRemoveAllEnergyDeviceSensorsModal,
      apiError,
      circuitBreakers,
      resetSelectedEnergyDevice,
    } = this.props;
    const { activeTab } = this.state;
    const cardIsOpen = id === expandedId;

    if (!energyDevice) return null;

    return (
      <>
        {!cardIsOpen && !expandedId && (
          <div>
            <Card
              className={styles.cardListItem}
              onClick={this.onUnexpandedCardClick}
            >
              {isFirstItem ? (
                <div className={styles.busTab}>BUS {predeterminedBus}</div>
              ) : (
                ''
              )}
              <CardTitle
                className={classNames(
                  styles.listContainer,
                  isFirstItem ? styles.noPaddingTop : ''
                )}
              >
                <span className={styles.cardListTitle}>
                  {`${energyDevice.resourceType}: ${energyDevice.title}`}
                </span>
                {isLastItem ? (
                  <div>
                    <Button
                      className={styles.noPaddingTop}
                      variant="text"
                      onClick={e => {
                        showDeleteEnergyDeviceModal();
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <MdDelete color={'var(--pink-base)'} size="20" />
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </CardTitle>
            </Card>
          </div>
        )}
        {editEnergyDevice && cardIsOpen && (
          <Card className={styles.expandedContainer}>
            <div className={styles.expandedContainerMargin}>
              <CardTitle>
                <div>
                  <span className={styles.expandedCardTitle}>
                    {energyDevice.title}
                  </span>
                  <div className={styles.floatRight}>
                    <MenuDropdown>
                      <MenuDropdownItem
                        onSelect={showNewEnergyDeviceSensorModal}
                      >
                        Set up new {energyDevice.resourceType} sensor
                      </MenuDropdownItem>
                      <MenuDropdownItem
                        isRed
                        onSelect={showRemoveAllEnergyDeviceSensorsModal}
                      >
                        Remove all sensors
                      </MenuDropdownItem>
                    </MenuDropdown>
                  </div>
                </div>
                <div className={styles.tabsContainer}>
                  <Tabs>
                    {tabs.map(({ label, name }) => {
                      return (
                        <Tab
                          key={name}
                          active={activeTab === name}
                          onClick={() => {
                            if (activeTab === 'PORTS') {
                              resetSelectedEnergyDevice();
                            }
                            this.setState({
                              activeTab: name,
                            });
                          }}
                        >
                          {label}
                        </Tab>
                      );
                    })}
                  </Tabs>
                </div>
              </CardTitle>
              <CardContent>
                <div className={styles.tabContent}>
                  {activeTab === 'SETTINGS' && (
                    <>
                      <EditEnergyDeviceForm
                        fields={editEnergyDevice.fields}
                        onChange={onChange}
                        formErrorsVisible={editEnergyDevice.formErrorsVisible}
                        formErrors={editEnergyDevice.formErrors}
                        apiError={editEnergyDevice.apiError}
                        predeterminedBus={predeterminedBus}
                      />
                    </>
                  )}

                  {activeTab === 'PORTS' && (
                    <>
                      <EnergyDeviceSensorsList
                        deviceType={energyDevice.resourceType}
                        sensors={energyDevice.sensors}
                        panelId={panelId}
                        energyDeviceId={energyDevice.id}
                        circuitBreakers={circuitBreakers}
                      />
                    </>
                  )}
                </div>
              </CardContent>
            </div>
            {this.renderCardActions()}
          </Card>
        )}
        {showingDeleteEnergyDeviceModal && this.deletePrompt()}
        {showingNewEnergyDeviceSensorModal &&
          expandedId === String(energyDevice.id) && (
            <NewEnergyDeviceSensorModal
              panelId={panelId}
              energyDeviceId={String(energyDevice.id)}
              sensors={energyDevice.sensors}
            />
          )}
        {showingRemoveAllEnergyDeviceSensorsModal &&
          expandedId === String(energyDevice.id) && (
            <RemoveEnergyDeviceSensorModal
              energyDeviceId={parseInt(id)}
              apiError={apiError}
              energyDeviceTitle={energyDevice.title}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (
  { energyDevices, distributionPanels, circuitBreakers }: ApplicationState,
  { id, panelId }: OwnProps
) => {
  return {
    showingDeleteEnergyDeviceModal: (energyDevices.editById[id] || {})
      .showDeleteEnergyDeviceModal,
    showingNewEnergyDeviceSensorModal:
      energyDevices.showNewEnergyDeviceSensorModal,
    editEnergyDevice: energyDevices.editById[id],
    energyDevice: energyDevices.energyDevicesById[id],
    showingRemoveAllEnergyDeviceSensorsModal:
      energyDevices.showRemoveAllEnergyDeviceSensorsModal,
    apiError: energyDevices.apiError,
    circuitBreakers: pathOr(
      [],
      [panelId, 'breakers'],
      distributionPanels.distributionPanelsById
    ).map(breaker => circuitBreakers.circuitBreakersById[breaker.breaker.id]),
  };
};

const mapDispatchToProps = (
  dispatch: any,
  { id, energyPro, predeterminedBus }: OwnProps
) => ({
  getEnergyDevice: () => dispatch(getEnergyDevice(id)),
  patch: () => dispatch(patch(id)),
  reset: () => dispatch(reset(id)),
  showDeleteEnergyDeviceModal: () => dispatch(showDeleteEnergyDeviceModal(id)),
  hideDeleteEnergyDeviceModal: () => dispatch(hideDeleteEnergyDeviceModal(id)),
  onDelete: () => {
    dispatch(
      removeEnergyDeviceFromBus(String(energyPro.id), id, predeterminedBus)
    );
  },
  onChange: (field: string, value: string | number) =>
    dispatch(updateField(id, field, value)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
  showNewEnergyDeviceSensorModal: () =>
    dispatch(showNewEnergyDeviceSensorModal(id)),
  showRemoveAllEnergyDeviceSensorsModal: () =>
    dispatch(showRemoveAllEnergyDeviceSensorsModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyDeviceConfiguration);
// `
