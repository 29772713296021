import {
  TimetableControlTypes,
  TimeTableEvent,
  TimeTablePreview,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeTablePreviewsByEquipmentId } from '../actions/time_tables';
import { ApplicationState } from '../reducers';

export const useTimetablePreviewsByEquipmentId = (
  equipmentId: number | string | undefined | null,
  timezone: string | undefined,
  fromDate: number,
  toDate: number,
  controlTypeFilter?: TimetableControlTypes
) => {
  const dispatch = useDispatch();
  const timetablePreviews: TimeTablePreview[] | undefined = useSelector(
    ({ timeTables }: ApplicationState) => {
      if (!isDefined(equipmentId)) return undefined;
      let timetablePreviews: TimeTablePreview[] | undefined =
        timeTables.timetablePreviewsByEquipmentId[equipmentId];
      if (controlTypeFilter) {
        timetablePreviews = timetablePreviews?.filter(
          ({ controlType }) => controlType === controlTypeFilter
        );
      }
      if (timetablePreviews === undefined) return undefined;
      return timetablePreviews;
    }
  );

  useEffect(() => {
    if (!isDefined(equipmentId)) return;
    if (timezone)
      dispatch(
        getTimeTablePreviewsByEquipmentId(
          equipmentId,
          timezone,
          new Date(fromDate),
          new Date(toDate)
        )
      );
  }, [equipmentId, timezone, fromDate, toDate]);

  return timetablePreviews;
};

export const useTimetableEventsByEquipmentId = (
  equipmentId: number | string | undefined | null,
  timezone: string | undefined,
  fromDate: number,
  toDate: number,
  controlTypeFilter?: TimetableControlTypes
) => {
  useTimetablePreviewsByEquipmentId(
    equipmentId,
    timezone,
    fromDate,
    toDate,
    controlTypeFilter
  );
  const timetableEvents: TimeTableEvent[][] | undefined = useSelector(
    ({ timeTables }: ApplicationState) => {
      if (!isDefined(equipmentId)) return undefined;
      let timetablePreviews: TimeTablePreview[] | undefined =
        timeTables.timetablePreviewsByEquipmentId[equipmentId];
      if (controlTypeFilter) {
        timetablePreviews = timetablePreviews?.filter(
          ({ controlType }) => controlType === controlTypeFilter
        );
      }
      if (timetablePreviews === undefined) return undefined;
      const events: TimeTableEvent[][] = (timetablePreviews.map(
        ({ events }) => events
      ) as unknown[]).filter(event => isDefined(event)) as TimeTableEvent[][];

      return events;
    }
  );

  return timetableEvents;
};
