import { FormText, Radio } from '@energybox/react-ui-library/dist/components';
import {
  ComparisonType,
  defaultActivePowerParams,
  ThresholdConfigParameters,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import RangeField from '../../../../ui/RangeField';
import TextField from '../../../../ui/TextField/TextField';
import StepConditionItem from '../StepConditionItem';
import StepConditionItemInput from '../StepConditionItemInput';
import styles from './ConfigureThresholdLines.module.css';

type Props = {
  onValueChange: (name: string, value: number | string | undefined) => void;
  onComparisonTypeChange: (
    comparisonType: string,
    comparisonTypeValue: string,
    comparisonSentinelType?: string
  ) => void;
  parameters?: ThresholdConfigParameters;
  thresholdEntity: string;
  unit: string;
  rangeMin: number;
  rangeMax: number;
};

function parseInput(fn: (v: number) => number, value: string): number {
  if (value === '' || value === '-') {
    return 0;
  }

  if (isNaN(parseFloat(value))) {
    return 0;
  }

  return fn(parseFloat(value));
}

const ConfigureThresholdLines = ({
  onValueChange,
  onComparisonTypeChange,
  parameters = defaultActivePowerParams,
  rangeMin,
  rangeMax,
  thresholdEntity,
  unit,
}: Props) => {
  const {
    threshold,
    rangeLowerBound,
    rangeUpperBound,
    comparisonType,
  } = parameters;

  function enforceBounds(value: number): number {
    if (value > rangeMax) {
      return rangeMax;
    }

    if (value < rangeMin) {
      return rangeMin;
    }

    return value;
  }

  const handleOnValueChange = (name: string) => (
    ev: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = ev.currentTarget;

    if (parseFloat(value) === 0) {
      onValueChange(name, value);
      return;
    }

    return onValueChange(name, enforceBounds(parseInput(f => f, value)));
  };
  return (
    <>
      <StepConditionItem
        condition={
          <RangeField
            min={rangeMin}
            max={rangeMax}
            step={1}
            value={threshold}
            rangeLowerBound={rangeLowerBound}
            rangeUpperBound={rangeUpperBound}
            condition={comparisonType}
            valueFormatterFn={v => String(v) + ` ${unit}`}
            onChange={(name, value) =>
              onValueChange(name, enforceBounds(value))
            }
          />
        }
      />

      <StepConditionItem
        condition={
          <div className={styles.checkboxWrapper}>
            <Radio
              id="condition_less"
              checked={parameters.comparisonType === ComparisonType.lt}
              onChange={() =>
                onComparisonTypeChange('comparisonType', ComparisonType.lt)
              }
              label="Less than"
            />
            <Radio
              id="condition_equals"
              checked={parameters.comparisonType === ComparisonType.eq}
              onChange={() =>
                onComparisonTypeChange('comparisonType', ComparisonType.eq)
              }
              label="Exact"
            />
            <Radio
              id="condition_greater"
              checked={parameters.comparisonType === ComparisonType.gt}
              onChange={() =>
                onComparisonTypeChange('comparisonType', ComparisonType.gt)
              }
              label="Greater than"
            />
            <Radio
              id="condition_range_inner"
              checked={parameters.comparisonType === ComparisonType.inner}
              onChange={() =>
                onComparisonTypeChange('comparisonType', ComparisonType.inner)
              }
              label="Range"
            />
            <Radio
              id="condition_range_outer"
              checked={parameters.comparisonType === ComparisonType.outer}
              onChange={() =>
                onComparisonTypeChange('comparisonType', ComparisonType.outer)
              }
              label="Inverse Range"
            />
          </div>
        }
      />

      {parameters.comparisonType === ComparisonType.inner ||
      parameters.comparisonType === ComparisonType.outer ? (
        <>
          <StepConditionItem
            label={<FormText>{thresholdEntity} lower bound:</FormText>}
            condition={
              <StepConditionItemInput
                value={
                  <TextField
                    type="number"
                    step={0.01}
                    value={String(parameters.rangeLowerBound)}
                    onChange={handleOnValueChange('rangeLowerBound')}
                  />
                }
                unit={<FormText>{unit}</FormText>}
              />
            }
          />
          <StepConditionItem
            label={<FormText>{thresholdEntity} upper bound:</FormText>}
            condition={
              <StepConditionItemInput
                value={
                  <TextField
                    type="number"
                    step={0.01}
                    value={String(parameters.rangeUpperBound)}
                    onChange={handleOnValueChange('rangeUpperBound')}
                  />
                }
                unit={<FormText>{unit}</FormText>}
              />
            }
          />
        </>
      ) : (
        <StepConditionItem
          label={<FormText>{thresholdEntity}:</FormText>}
          condition={
            <StepConditionItemInput
              value={
                <TextField
                  type="number"
                  step={0.01}
                  value={String(parameters.threshold)}
                  onChange={handleOnValueChange('threshold')}
                />
              }
              unit={<FormText>{unit}</FormText>}
            />
          }
        />
      )}
    </>
  );
};

export default ConfigureThresholdLines;
