import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { destroy, unstageDelete } from '../../actions/time_tables';
import { hideDeleteModal } from '../../actions/time_tables';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import { Actions as TimeTableActions } from '../../actions/time_tables';
import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import style from './TimeTableModal.module.css';
import { TimeTableCounts } from '@energybox/react-ui-library/dist/types';
import { getTimeTableCount } from './TimeTablesTable';

interface OwnProps {
  organizationUnitId: string;
  isOrgLevel: boolean | undefined;
}
interface Props extends OwnProps {
  isVisible: boolean;
  primedForDelete: number;
  onDelete: (id: number) => void;
  onClose: () => void;
  unstageDelete: () => void;
  apiError: ApiError;
  timeTableTitle?: string;
  timeTableCounts: TimeTableCounts[];
}

class DeleteTimeTableModal extends React.Component<Props> {
  render() {
    if (!this.props.isVisible) return null;

    const {
      onClose,
      apiError,
      timeTableTitle,
      onDelete,
      primedForDelete,
      timeTableCounts,
      isOrgLevel,
    } = this.props;

    const {
      sopReferenceCount,
      siteReferenceCount,
      camCount,
    } = getTimeTableCount(timeTableCounts, primedForDelete);

    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onDelete(this.props.primedForDelete)}>
          {'Delete'}
        </Button>
      </>
    );

    const extraDescription = (
      <>
        {' '}
        across{' '}
        <b>
          {siteReferenceCount} {siteReferenceCount > 1 ? 'Sites' : 'Site'}
        </b>
        .
      </>
    );

    const description = (
      <>
        This timetable is referenced in{' '}
        <b>
          {camCount} {camCount > 1 ? 'Equipment Controls' : 'Equipment Control'}{' '}
        </b>
        and <b> {sopReferenceCount} SOP </b>
        configurations{isOrgLevel ? extraDescription : '.'}
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions}>
        <p className={style.deleteModal}>
          Are you sure you want to delete{' '}
          <span className={style.boldText}>{timeTableTitle}</span>?
          <br />
          <span>{description}</span>
        </p>
        {apiError
          ? renderAPIerror(apiError, TimeTableActions.DELETE_TIME_TABLE_ERROR)
          : ''}
      </Modal>
    );
  }
}

const mapStateToProps = (
  { timeTables }: ApplicationState,
  { organizationUnitId }: OwnProps
) => {
  return {
    timeTableTitle:
      timeTables.timeTablesById[timeTables.primedForDelete] &&
      timeTables.timeTablesById[timeTables.primedForDelete].title,
    isVisible: timeTables.showDeleteModal,
    primedForDelete: timeTables.primedForDelete,
    apiError:
      timeTables.editById[timeTables.primedForDelete] &&
      timeTables.editById[timeTables.primedForDelete].apiError,
    timeTableCounts:
      timeTables.timeTableCountsByOrgUnitId[organizationUnitId] || [],
  };
};

const mapDispatchtoProps = (dispatch: any) => ({
  onDelete: id => {
    dispatch(destroy(id));
  },
  onClose: () => {
    dispatch(unstageDelete());
    dispatch(hideDeleteModal());
  },
  unstageDelete: () => {
    dispatch(unstageDelete());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(DeleteTimeTableModal);
