import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import styles from './ConfirmDeleteDialog.module.css';
import { deleteFiles, toggleDeleteStatus } from '../../actions/files';
import { removeFileIDs, decrementFileCount } from '../../actions/reportworker';
import { useDispatch } from 'react-redux';

type Props = {
  isCreateDashboardDialogOpen: boolean;
  dialogReportData: any;
  dialogSetJobIdForDel: any;
  dialogSaveFileId: any;
  onClose: () => void;
  onSubmit: () => void;
};

const ConfirmDeleteDialog = ({
  isCreateDashboardDialogOpen,
  dialogReportData,
  dialogSetJobIdForDel,
  dialogSaveFileId,
  onClose,
  onSubmit,
}: Props) => {
  const dispatch = useDispatch();
  const closeWithResetName = () => {
    onClose();
  };

  const onHandleSubmit = () => {
    dispatch(deleteFiles(dialogReportData));
    dispatch(toggleDeleteStatus(false));
    dispatch(removeFileIDs(dialogSetJobIdForDel));
    if (dialogSaveFileId.includes(dialogSetJobIdForDel)) {
      dispatch(decrementFileCount());
    }
    onSubmit();
    onClose();
  };

  return isCreateDashboardDialogOpen ? (
    <Modal
      onClose={closeWithResetName}
      actions={[
        <Button key="cancel" variant="text" onClick={closeWithResetName}>
          Cancel
        </Button>,
        <Button key="submit" onClick={onHandleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <ModalTitle>Confirm Delete</ModalTitle>
      <ModalContent className={styles.modalContent}>
        <span>Are you sure want to delete this report ?</span>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
};

export default ConfirmDeleteDialog;
