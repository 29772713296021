import {
  GenericErrors,
  ResourceType,
  Site,
  SiteGroup,
  SiteGroupSite,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  mapArrayToObject,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions as SitesActions } from '../actions/sites';
import { Actions } from '../actions/site_groups';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface SiteGroupSites {
  isLoading: boolean;
  items: SiteGroupSite[];
}

export type SiteGroupsSitesByGroupId = {
  [id: number]: SiteGroupSites;
};

export type SiteGroupsById = {
  [id: number]: SiteGroup;
};

type EditableFields = {
  title: string;
  description: string;
};

export type EditGroup = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

export type EditGroupById = {
  [id: string]: EditGroup;
};

export interface SiteGroups {
  siteGroupsById: SiteGroupsById;
  siteGroupsSitesByGroupId: SiteGroupsSitesByGroupId;
  showNewGroupModal: boolean;
  editById: EditGroupById;
  query: string;
  loadingStatusByAction: ActionsLoadingStatus;
  siteGroupApiError: ApiError;
}

export type ActionsLoadingStatus = {
  [Actions.GET_SITE_GROUPS_LOADING]?: boolean;
};

const siteGroupSiteFromApiResponse = (data: any): SiteGroupSite => ({
  id: data.id,
  title: data.title,
  description: data.description,
});

const siteGroupFromApiResponse = (data: any): SiteGroup => ({
  id: data.id,
  title: data.title,
  description: data.description,
  sites: (data.sites || []).map(siteGroupSiteFromApiResponse),
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
});

const newSiteGroupFields = {
  title: '',
  description: '',
};

const newGroupInitialState = {
  isChanged: false,
  isLoading: false,
  fields: newSiteGroupFields,
  formErrorsVisible: false,
  formErrors: formValidationErrors('siteGroup', newSiteGroupFields),
  apiError: {},
};

const initialState = {
  siteGroupsById: {},
  siteGroupsSitesByGroupId: {},
  showNewGroupModal: false,
  editById: {},
  query: '',
  loadingStatusByAction: {},
  siteGroupApiError: {},
};

export const siteGroupsList = (state: SiteGroups) => {
  const { siteGroupsById, query } = state;
  let siteGroups = values(siteGroupsById);

  if (query && query.length >= 3) {
    siteGroups = siteGroups.filter(
      site => hasSubstr(site.title, query) || hasSubstr(site.description, query)
    );
  }

  return siteGroups;
};

export const siteGroups = (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SITE_GROUPS_SUCCESS:
      return R.pipe(
        R.assoc(
          'siteGroupsById',
          mapArrayToObject(mapValues(action.data, siteGroupFromApiResponse))
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_SITE_GROUPS_LOADING],
          false
        )
      )(state);

    case Actions.GET_SITE_GROUPS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_SITE_GROUPS_LOADING],
        true,
        state
      );

    case Actions.GET_SUCCESS:
      let siteGroup = siteGroupSiteFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(
          ['siteGroupsById', action.data.id],
          siteGroupFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: siteGroup,
          apiError: {},
        }),
        R.assoc('siteGroupApiError', {})
      )(state);

    case Actions.GET_ERROR:
      return R.assoc('siteGroupApiError', action.data, state);

    case Actions.TOGGLE_NEW_SITEGROUP_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newGroupInitialState),
        R.assoc('showNewGroupModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedFields = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(['editById', action.id, 'fields'], updatedFields),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('siteGroup', updatedFields)
        )
      )(state);

    case Actions.RESET_FIELDS:
      var fields = R.path(['siteGroupsById', action.id], state);
      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('siteGroup', fields)
        )
      )(state);

    case Actions.CREATE_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_SUCCESS:
      return R.pipe(
        R.assoc('showNewGroupModal', false),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assocPath(
          ['siteGroupsById', action.data.id],
          siteGroupFromApiResponse(action.data)
        )
      )(state);

    case Actions.CREATE_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_SUCCESS:
      var patchedSiteGroup = siteGroupFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['siteGroupsById', action.data.id], patchedSiteGroup),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DELETE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_SUCCESS:
      return R.pipe(
        R.dissocPath(['siteGroupsById', action.id]),
        R.dissocPath(['editById', action.id])
      )(state);

    case Actions.DELETE_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case SitesActions.ADD_SITE_TO_GROUP_SUCCESS:
      return R.over(
        R.lensPath(['siteGroupsById', action.groupId, 'sites']),
        R.append(siteGroupSiteFromApiResponse(action.data)),
        state
      );

    case Actions.UPDATED_QUERY:
      return R.assoc('query', action.query, state);

    case SitesActions.REMOVE_SITE_FROM_GROUP_SUCCESS:
      return R.over(
        R.lensPath(['siteGroupsById', action.groupId, 'sites']),
        R.filter(({ id }: Site) => id !== action.siteId),
        state
      );

    default:
      return state;
  }
};

export default siteGroups;
