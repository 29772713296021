import {
  Gateway,
  NetworkGroup,
  Sensor,
  SensorType,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as NetworkGroupActions } from '../actions/network_groups';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as StreamActions } from '../actions/streamApi';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

export const IGNORE_DEFAULT_TIMESTAMP = '1970-01-01T00:00:00Z';

export interface DeviceState {
  id?: number;
  onlineState?: boolean;
  onlineStateUpdatedAt?: string;
  timestamp: string;
  unixTimestamp: number;
  types?: SensorType[];
  signalStrength?: number;
  signalStrengthLevel?: number;
  batteryVoltage?: number;
  batteryLevel?: number;
  vendor?: string;
  interval?: number;
  firmwareVersion?: string;
}

export type DeviceStatusById = {
  [id: string]: DeviceState;
};

function onlineStatusByDeviceType(event) {
  switch (event.deviceType) {
    // todo: sometimes sensor also returns onlineState and onlineStateUpdatedAt,
    // todo: but it looks outdated
    case 'sensor':
      if (
        event.hasOwnProperty('onlineState') &&
        event.hasOwnProperty('onlineStateUpdatedAt')
      ) {
        return {
          ...(event.status ? event.status : {}),
          id: event.id,
          vendor: event.vendor,
          uuid: event.uuid,
          onlineState: event.onlineState,
          onlineStateUpdatedAt: event.onlineStateUpdatedAt,
          timestamp: event.timestamp,
          unixTimestamp: new Date(event.timestamp).getTime(),
          types: event.info && event.info.types,
          firmwareVersion: event.info && event.info.firmwareVersion,
        };
      }

      if (!event.status) {
        return;
      }

      if (event.vendor === 'monnit') {
        return {
          vendor: 'monnit',
          signalStrengthLevel: event.status.signalStrengthLevel,
          batteryLevel: event.status.batteryLevel,
          onlineState: event.status.state === 'ACTIVE',
          timestamp: event.status.processedAt,
          unixTimestamp: new Date(event.status.processedAt).getTime(),
        };
      }
      // Todo something is weird about this case
      return {
        id: event.id,
        vendor: 'energybox',
        types: event.info && event.info.types,
        firmwareVersion: event.info && event.info.firmwareVersion,
        batteryLevel: event.status.batteryLevel,
        batteryVoltage: event.status.batteryVoltage,
        signalStrength: event.status.signalStrength,
        signalStrengthLevel: event.status.signalStrengthLevel,
        onlineState: event.status.state === 'ACTIVE',
        timestamp: event.status.receivedAt,
        interval: event.status.interval,
        unixTimestamp: new Date(event.status.receivedAt).getTime(),
      };
    case 'edge': {
      if (
        event.hasOwnProperty('onlineState') &&
        event.hasOwnProperty('onlineStateUpdatedAt')
      ) {
        return {
          ...(event.status ? event.status : {}),
          ...(event.info ? event.status : {}),
          id: event.id,
          uuid: event.uuid,
          onlineState: event.onlineState,
          onlineStateUpdatedAt: event.onlineStateUpdatedAt,
          timestamp: event.timestamp,
          unixTimestamp: new Date(event.timestamp).getTime(),
        };
      }
      return;
    }
    // https://energybox.atlassian.net/browse/CTRL-1006?focusedCommentId=16325
    // For control board the first message type seems to be 'control' rather
    // than 'gateway', and it can take 2-3 minutes for a `gateway` message with
    // the online state to come through. So handle `control` message the same
    // way, if it has the online info, which the first message seems to
    // basically combine both
    case 'control':
    case 'gateway':
      if (
        event.hasOwnProperty('onlineState') &&
        event.hasOwnProperty('onlineStateUpdatedAt')
      ) {
        return {
          ...(event.status ? event.status : {}),
          id: event.id,
          uuid: event.uuid,
          onlineState: event.onlineState,
          onlineStateUpdatedAt: event.onlineStateUpdatedAt,
          timestamp: event.timestamp,
          unixTimestamp: new Date(event.timestamp).getTime(),
        };
      }

      return;

    default:
      return;
  }
}

const pickMostRecentDeviceState = (a: DeviceState, b: DeviceState) => {
  if (!a) return b;
  if (!b) return a;
  return a.unixTimestamp >= b.unixTimestamp ? a : b;
};

const deviceStatusById = (state = {} as DeviceStatusById, action: any) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      const status = onlineStatusByDeviceType(action.data);

      if (status && action.data.id) {
        const dateFromState = state[action.data.id.toString()];

        const mergedById = R.mergeDeepRight(
          R.pathOr({}, [action.data.id.toString()], state),
          status
        );
        const mergedByUuid = R.mergeDeepRight(
          R.pathOr({}, [action.data.uuid], state),
          status
        );
        if (dateFromState && !isNaN(dateFromState.unixTimestamp)) {
          if (dateFromState.unixTimestamp < status.unixTimestamp) {
            return {
              ...state,
              // Map by both id and uuid, this is for use by whitelist/nearby which may not include id
              [action.data.id.toString()]: mergedById,
              [action.data.uuid]: mergedByUuid,
            };
          }
        } else {
          return {
            ...state,
            // Map by both id and uuid, this is for use by whitelist/nearby which may not include id
            [action.data.id.toString()]: mergedById,
            [action.data.uuid]: mergedByUuid,
          };
        }
      } else if (status) {
        // Edge Case : Sensor that does not include id but sensor status and info merged into the same state
        // resulting in missing information at times. This block can be removed once sensor info
        // channel is separated from device status in stream_api.
        // Buggy case : Thermostat is also sensor and we do not recieve id for this sometimes
        if (
          action.data.deviceType == 'sensor' &&
          !isDefined(action.data.info)
        ) {
          const previousState = state[action.data.uuid];
          if (isDefined(previousState) && isDefined(previousState.types)) {
            return {
              ...state,
              [action.data.uuid]: previousState,
            };
          }
        }
        const dateFromState = state[action.data.uuid];
        if (dateFromState) {
          if (dateFromState.unixTimestamp < status.unixTimestamp) {
            return {
              ...state,
              [action.data.uuid]: status,
            };
          }
        } else {
          return {
            ...state,
            [action.data.uuid]: status,
          };
        }
      }

      return state;
    }

    // WE SWITCHED FROM UPDATED AT TO RECEIVED AT TO BETTER TRACK THE ONLINE OFFLINE STATE OF SENSORS
    // UPDATED AT IS AN AUTOMATIC VALUE FROM THE MODEL EXTENSION IN IAM, PROCESSED AT AND RECEIVED AT CAN BE CONTROLLED
    // BY EXTERNAL UPDATES. Marcus only updates these values when the state changes.

    case SensorActions.GET_SENSORS_BY_RESOURCE_ID_SUCCESS:
    case SensorActions.GET_SENSORS_SUCCESS: {
      return R.mergeWith(
        pickMostRecentDeviceState,
        {
          ...action.data
            .filter((s: Sensor) => s.sensorStatus !== null)
            .filter(s => s.sensorStatus.receivedAt !== null)
            .reduce((obj, next) => {
              const newEntry = {
                timestamp: next.sensorStatus.receivedAt,
                unixTimestamp: new Date(next.sensorStatus.receivedAt).getTime(),
                onlineState: next.sensorStatus.state === 'ACTIVE',
              };

              obj[next.id.toString()] = newEntry;

              return obj;
            }, {}),
        },
        state
      );
    }

    case SensorActions.GET_SENSOR_SUCCESS: {
      if (!action.data || !action.data.sensorStatus) return state;
      return R.mergeWith(
        pickMostRecentDeviceState,
        {
          [action.data.id.toString()]: {
            ...action.data,
            timestamp: action.data.sensorStatus.receivedAt,
            unixTimestamp: new Date(
              action.data.sensorStatus.receivedAt
            ).getTime(),
            onlineState: action.data.sensorStatus.state === 'ACTIVE',
          },
        },
        state
      );
    }

    case GatewayActions.GET_GATEWAYS_BY_RESOURCE_ID_SUCCESS:
    case GatewayActions.GET_GATEWAYS_SUCCESS: {
      return R.mergeWith(
        pickMostRecentDeviceState,
        {
          ...action.data
            .filter((g: Gateway) => g.gatewayOnlineStatus !== null)
            .reduce((obj, next) => {
              obj[next.id.toString()] = {
                timestamp: next.gatewayOnlineStatus.timestamp,
                unixTimestamp: new Date(
                  next.gatewayOnlineStatus.timestamp
                ).getTime(),
                onlineState: next.gatewayOnlineStatus.onlineState,
              };

              return obj;
            }, {}),
        },
        state
      );
    }

    case NetworkGroupActions.GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS: {
      return R.mergeWith(
        pickMostRecentDeviceState,
        {
          ...action.data
            .filter((g: NetworkGroup) => g.edge !== null)
            .map(g => g.edge)
            .filter(g => g.onlineStatus !== null)
            .reduce((obj, next) => {
              obj[next.id.toString()] = {
                timestamp: next.onlineStatus.timestamp,
                unixTimestamp: new Date(next.onlineStatus.timestamp).getTime(),
                onlineState: next.onlineStatus.onlineState,
              };
              return obj;
            }, {}),
        },
        state
      );
    }

    case GatewayActions.GET_GATEWAY_SUCCESS: {
      if (!action.data || !action.data.gatewayOnlineStatus) return state;
      return R.mergeWith(
        pickMostRecentDeviceState,
        {
          [action.data.id.toString()]: {
            timestamp: action.data.gatewayOnlineStatus.timestamp,
            unixTimestamp: new Date(
              action.data.gatewayOnlineStatus.timestamp
            ).getTime(),
            onlineState: action.data.gatewayOnlineStatus.onlineState,
          },
        },
        state
      );
    }

    default:
      return state;
  }
};

export default deviceStatusById;
