import {
  EditableReportPeriod,
  mapHvacControlFieldToLabel,
  mapThermostatFieldToLabel,
  MeasurementSystem,
  OutsideOfTimeTable,
  SopTypes,
  TemperatureUnit,
  ThermostatWorkingMode,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { PropertyToLabel } from '../types/global';
import { EditHvacSOPControl } from '../types/hvacControl';
import { EditThermostat } from '../types/Thermostat';
import * as v from '../validators';
import {
  calculateLightSensorThresholdWithHysteresis,
  MAX_LIGHT_SENSOR_THRESHOLD_INCLUDING_HYSTERESIS,
} from './controls';
import { sopComponentTypeAppliesToEquipment } from './sops';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { TimetableOverrideType } from '@energybox/react-ui-library/dist/types/TimeTable';
import { HvacSop } from '@energybox/react-ui-library/dist/types/Sop';
import { getEbTstatUuidRange } from '../config';
import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';
import { isEBThermostat } from './ebThermostat';

// Something is messed up here with the way webpack is grabbing this file, we should fix, import like the one below do not work
// import { MINIMUM_HYSTERESIS_VALUE, MAXIMUM_HYSTERESIS_VALUE } from '../reducers/temperature_controls';

const formFields = {
  organization: ['country', 'street', 'city', 'postalCode'],
  site: [
    'title',
    'timeZone',
    'country',
    'street',
    'city',
    'postalCode',
    'latitude',
    'longitude',
    'areaTotal',
    'energyCalculationMode',
    'phone',
  ],
  sop: [
    'organizationUnitId',
    'title',
    'components',
    'timetableId',
    'equipmentTypeIds',
  ],
  space: ['title', 'parentId'],
  equipment: ['title', 'groupId', 'typeId', 'spaceId'],
  networkGroup: [
    'title',
    'siteId',
    'edge.serialNumber',
    'edge.edgeDevice',
    'spaceId',
    'northUuid',
    'southUuid',
  ],
  timeTable: ['title'],
  timeTableRow: ['weekdays', 'beginTime', 'endTime', 'overrideType'],
  specialTimeTableRow: ['title', 'dateRanges'],
  scheduler: [
    'beginDelta',
    'endDelta',
    'timetableId',
    'lightSensorSettings.controlBoardId',
    'lightSensorSettings.actionInterval',
    'lightSensorSettings.threshold',
    'lightSensorSettings.hysteresis',
    'lightSensorSettings.beginDelta',
    'lightSensorSettings.endDelta',
    'lightSensorSettings.timetableId',
  ],
  gateway: ['title', 'uuid', 'spaceId', 'model', 'networkGroupId'],
  controlBoard: [
    'title',
    'uuid',
    'spaceId',
    'model',
    'networkGroupId',
    'lightSensorPort',
  ],
  sensor: ['title', 'uuid', 'vendor', 'resourceId'],
  actuator: ['title', 'equipmentId', 'port', 'controlBoardId'],
  newUser: ['firstName', 'lastName', 'email'],
  newInstaller: ['firstName', 'lastName', 'email'],
  editUser: ['firstName', 'lastName', 'email', 'phone'],
  apiKey: ['title'],
  userGroup: ['title'],
  installerGroup: ['title', 'region'],
  siteGroup: ['title'],
  distributionPanel: ['title', 'spaceId', 'breakerSlots', 'type'],
  circuitBreaker: ['title', 'breakerColumn', 'breakerSlot', 'rating', 'type'],
  mainBreaker: ['title'],
  energyPro: ['title', 'uuid', 'networkGroupId', 'sampleFrequency'],
  newEnergyDevice: ['title', 'energyProId', 'bus'],
  editEnergyDevice: ['title'],
  energyDeviceSensor: ['breakerPole', 'energyDevicePort', 'phase'],
  temperatureControl: [
    'equipmentId',
    'sensorId',
    'setPoint',
    'hysteresis',
    'beginDelta',
    'endDelta',
  ],
  report: ['title', 'resourceIds', 'periods'],
  certification: [
    'title',
    'deviceUuid',
    'deviceVendor',
    'certificationBody',
    'certificationCompany',
    'validFrom',
    'validTo',
    'certificationId',
    'certificate',
  ],
};

// 'edge.edgeDevice', 'edge.uuid'
// Allow for nested fields via dot notation
export function formValidationErrors(formType: string, fields: any) {
  const errors = {};
  const ignoreFields: string[] = [];

  const addError = (field: string, message: string | string[]) => {
    if (!ignoreFields.includes(field)) {
      errors[field] = errors[field] || [];
      errors[field].push(message);
    }
  };
  formFields[formType].forEach((fieldOrPathString: string) => {
    //check if field is empty
    const pathToValue = R.split('.', fieldOrPathString);
    const value = R.path(pathToValue, fields);

    //for hide sr no validation if superHub selected
    if (formType === 'networkGroup') {
      if (
        fieldOrPathString === 'edge.serialNumber' &&
        !value &&
        fields?.edge?.edgeDevice === 'EB_SUPER_HUB'
      ) {
        ignoreFields.push('edge.serialNumber');
      } else {
        ignoreFields.push('northUuid', 'southUuid');
      }

      if (fields?.edge?.edgeDevice !== 'EB_SUPER_HUB') {
        ignoreFields.push('spaceId', 'northUuid', 'southUuid');
      }
    }

    if (
      value !== 0 &&
      !v.isPresent(value) &&
      fieldOrPathString !== 'latitude' &&
      fieldOrPathString !== 'longitude' &&
      fieldOrPathString !== 'areaTotal' &&
      fieldOrPathString !== 'edmHysteresisPercent' &&
      fieldOrPathString !== 'validTo' &&
      fieldOrPathString !== 'timetableId' &&
      fieldOrPathString !== 'beginTime' &&
      fieldOrPathString !== 'endTime' &&
      fieldOrPathString !== 'lightSensorSettings.timetableId' &&
      fieldOrPathString !== 'phone' &&
      fieldOrPathString !== 'spaceId' &&
      fieldOrPathString !== 'networkGroupId' &&
      !ignoreFields.includes(fieldOrPathString)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} cannot be empty`
      );
    }

    if (fieldOrPathString === 'phone') {
      if (value) {
        let splitValue = value.split('-');
        let onlyNumbersRegex = /^[0-9]+$/;
        for (let i = 0; i < splitValue.length; i++) {
          if (onlyNumbersRegex.test(splitValue)) {
            if (!splitValue[0].includes('+')) {
              addError(fieldOrPathString, `Please Select Country Code`);
            } else if (
              !splitValue[0].includes('+') &&
              splitValue[1].length < 8
            ) {
              addError(fieldOrPathString, `Please fill Valid Phone Number`);
            }
          }
        }
      }
    }

    if (
      (fieldOrPathString === 'beginDelta' ||
        fieldOrPathString === 'endDelta' ||
        fieldOrPathString === 'lightSensorSettings.beginDelta' ||
        fieldOrPathString === 'lightSensorSettings.endDelta') &&
      (isNaN(value) || value < -120 || value > 120)
    ) {
      addError(fieldOrPathString, `must be between -120 and 120`);
    }

    if (
      fieldOrPathString === 'sampleFrequency' &&
      (isNaN(value) || value < 2)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must be 2 or above`
      );
    }

    if (
      fieldOrPathString === 'edmHysteresisPercent' &&
      v.isPresent(value) &&
      (isNaN(value) || value < 0 || value > 100)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must be between 0 and 100`
      );
    }
    if (fieldOrPathString === 'lightSensorSettings.threshold') {
      const hysteresis = fields['lightSensorSettings']?.['hysteresis'];
      const thresholdWithHysteresis = calculateLightSensorThresholdWithHysteresis(
        Number(value) || 0,
        Number(hysteresis) || 0
      );

      if (
        v.isPresent(value) &&
        (isNaN(value) ||
          thresholdWithHysteresis < 0 ||
          thresholdWithHysteresis >
            MAX_LIGHT_SENSOR_THRESHOLD_INCLUDING_HYSTERESIS)
      ) {
        addError(
          fieldOrPathString,
          `${PropertyToLabel[fieldOrPathString]} with hysteresis must be between 0lux and ${MAX_LIGHT_SENSOR_THRESHOLD_INCLUDING_HYSTERESIS}lux`
        );
      }
    }
    if (
      fieldOrPathString === 'lightSensorSettings.hysteresis' &&
      v.isPresent(value) &&
      (isNaN(value) || value > 100 || value < 10)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must be between 10 and 100`
      );
    }
    if (
      fieldOrPathString === 'lightSensorSettings.actionInterval' &&
      v.isPresent(value) &&
      (isNaN(value) || value < 1 || value > 15)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must be between 1 and 15`
      );
    }
    if (
      fieldOrPathString === 'areaTotal' &&
      v.isPresent(value) &&
      !v.isPositiveNumberOrZero(Number(value))
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must be greater than 0`
      );
    }

    //check if latitude is valid
    if (fieldOrPathString === 'latitude' && !v.isLatitudeValid(value)) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} has an invalid format`
      );
    }
    //check if longitude is valid
    if (fieldOrPathString === 'longitude' && !v.isLongitudeValid(value)) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} has an invalid format`
      );
    }
    //check if Select components are valid
    if (
      (fieldOrPathString === 'typeId' ||
        fieldOrPathString === 'groupId' ||
        fieldOrPathString === 'spaceId' ||
        fieldOrPathString === 'resourceId' ||
        fieldOrPathString === 'equipmentId' ||
        fieldOrPathString === 'port' ||
        fieldOrPathString === 'controlBoardId' ||
        fieldOrPathString === 'energyProId' ||
        fieldOrPathString === 'bus' ||
        fieldOrPathString === 'breakerId' ||
        fieldOrPathString === 'siteId' ||
        fieldOrPathString === 'networkGroupId' ||
        fieldOrPathString === 'energyDevicePort' ||
        fieldOrPathString === 'phase') &&
      (value === -1 || value === undefined)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must be selected`
      );
    }
    //check if email is valid
    if (fieldOrPathString === 'email' && !v.isEmail(value)) {
      addError(
        'email',
        `${PropertyToLabel[fieldOrPathString]} has an invalid format`
      );
    }

    if (fieldOrPathString === 'password' && !v.isValidPassword(value)) {
      addError(
        'password',
        `${PropertyToLabel[fieldOrPathString]} should consist of 6 or more characters with no spaces`
      );
    }

    //check if non custom times are valid
    if (
      (fieldOrPathString === 'beginTime' &&
        fields['beginType'] !== 'CLOCK' &&
        fields['overrideType'] === TimetableOverrideType.AUTO) ||
      (fieldOrPathString === 'endTime' &&
        fields['endType'] !== 'CLOCK' &&
        fields['overrideType'] === TimetableOverrideType.AUTO)
    ) {
      if (!v.isNumber(parseInt(value))) {
        addError(
          fieldOrPathString,
          `${PropertyToLabel[fieldOrPathString]} cannot be empty`
        );
      }
    }

    //check if times are valid
    if (
      (fieldOrPathString === 'beginTime' &&
        fields['beginType'] === 'CLOCK' &&
        fields['overrideType'] === TimetableOverrideType.AUTO) ||
      (fieldOrPathString === 'endTime' &&
        fields['endType'] === 'CLOCK' &&
        fields['overrideType'] === TimetableOverrideType.AUTO)
    ) {
      if (!v.isValidTime(value)) {
        addError(
          fieldOrPathString,
          `${PropertyToLabel[fieldOrPathString]} cannot be empty or has an invalid format`
        );
      }
    }

    //check if array is not empty
    if (
      (fieldOrPathString === 'weekdays' ||
        fieldOrPathString === 'dateRanges') &&
      !value.length
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must have at least 1 value`
      );
    }

    if (
      fieldOrPathString === 'components' &&
      (value[0].type === SopTypes.HUMIDITY_RANGE ||
        value[0].type === SopTypes.TEMPERATURE_RANGE)
    ) {
      if (value[0].min === '-' || value[0].max === '-') {
        addError(fieldOrPathString, `Must be a valid number`);
      }
      if (value[0].min >= value[0].max) {
        addError(fieldOrPathString, `Max value must be greater than min value`);
      }
    }

    if (fieldOrPathString === 'components' && value[0].type === SopTypes.HVAC) {
      const sopComponents = value[0] as HvacSop;
      const {
        thermostatDisplayUnits,
        hvacSchedules,
      } = sopComponents.hvacSettings;
      if (
        thermostatDisplayUnits !== MeasurementSystem.IMPERIAL &&
        thermostatDisplayUnits !== MeasurementSystem.METRIC
      ) {
        addError(
          `${fieldOrPathString}.thermostatDisplayUnits`,
          `Must select Thermostat Display Type`
        );
      }
      hvacSchedules.forEach((hvacSchedule, index, hvacSchedulesArray) => {
        const {
          timetable,
          maxTemp,
          minTemp,
          thermostatWorkingMode,
        } = hvacSchedule;
        const isThermostatModeAuto =
          thermostatWorkingMode === ThermostatWorkingMode.AUTO;
        const isThermostatModeHeating =
          thermostatWorkingMode === ThermostatWorkingMode.HEATING;
        const isThermostatModeCooling =
          thermostatWorkingMode === ThermostatWorkingMode.COOLING;

        if (
          isDefined(timetable) &&
          isDefined(timetable.rows) &&
          isDefined(timetable.rows[0])
        ) {
          const { weekdays, begin, end } = timetable.rows[0];
          if (!isDefined(weekdays) || weekdays.length === 0) {
            addError(
              `${fieldOrPathString}.${index}.weekdays`,
              `Must select days of the week.`
            );
          }

          if (!begin.time) {
            addError(
              `${fieldOrPathString}.${index}.beginTime`,
              `Invalid start time`
            );
          }

          if (!end.time) {
            addError(
              `${fieldOrPathString}.${index}.endTime`,
              `Invalid end time`
            );
          }

          if (begin.time && end.time) {
            if (!v.isValidBeginAndEndTime(begin.time, end.time)) {
              addError(
                `${fieldOrPathString}.${index}.beginTime`,
                `Begin time must be before end time`
              );
              addError(
                `${fieldOrPathString}.${index}.endTime`,
                `Begin time must be before end time`
              );
            }
          }

          if (
            isDefined(weekdays) &&
            weekdays.length > 0 &&
            begin.time &&
            end.time
          ) {
            const otherOccupiedSchedules = hvacSchedulesArray.filter(
              (h, i) => i !== index && h.hvacScheduleType === 'OCCUPIED'
            );

            if (
              !v.isValidHvacSopSchedules(hvacSchedule, otherOccupiedSchedules)
            ) {
              addError(
                `${fieldOrPathString}.${index}.beginTime`,
                `Time overlaps with other Occupied schedules`
              );
              addError(
                `${fieldOrPathString}.${index}.endTime`,
                `Time overlaps with other Occupied schedules`
              );
            }
          }
        }

        if (
          (isThermostatModeAuto || isThermostatModeCooling) &&
          (!isDefined(maxTemp) ||
            Number.isNaN(maxTemp) ||
            maxTemp > 35 ||
            maxTemp < 4)
        ) {
          addError(`${fieldOrPathString}.${index}.maxTemp`, `Invalid value`);
        }
        if (
          (isThermostatModeAuto || isThermostatModeHeating) &&
          (!isDefined(minTemp) ||
            Number.isNaN(minTemp) ||
            minTemp < 4 ||
            minTemp > 35)
        ) {
          addError(`${fieldOrPathString}.${index}.minTemp`, `Invalid value`);
        }
        if (
          isThermostatModeAuto &&
          isDefined(maxTemp) &&
          isDefined(minTemp) &&
          minTemp > maxTemp
        ) {
          addError(
            `${fieldOrPathString}.${index}.maxTemp`,
            `Heat To must be lower than Cool To`
          );
          addError(
            `${fieldOrPathString}.${index}.minTemp`,
            `Heat To must be lower than Cool To`
          );
        }
        if (
          isThermostatModeAuto &&
          isDefined(maxTemp) &&
          isDefined(minTemp) &&
          Math.abs(minTemp - maxTemp) < 1
        ) {
          addError(
            `${fieldOrPathString}.${index}.maxTemp`,
            `Difference between Heat To and Cool To must be ≥ 1°C`
          );
          addError(
            `${fieldOrPathString}.${index}.minTemp`,
            `Difference between Heat To and Cool To must be ≥ 1°C`
          );
        }
      });
    }

    if (fieldOrPathString === 'components') {
      const mandatoryFields = [
        'powerConsumption',
        'duration',
        'value',
        'tariff',
      ];
      mandatoryFields.forEach(field => {
        if (value[0][field] === '') {
          addError(
            fieldOrPathString,
            `${PropertyToLabel[field]} cannot be empty`
          );
        }
      });
    }

    if (
      fieldOrPathString === 'timetableId' &&
      fields.components?.[0]?.timetableId !== undefined &&
      value === undefined
    ) {
      addError(fieldOrPathString, 'Please select a timetable');
    }

    if (
      fieldOrPathString === 'equipmentTypeIds' &&
      fields.components?.[0]?.type &&
      sopComponentTypeAppliesToEquipment(fields.components[0].type) &&
      value?.length === 0
    ) {
      addError(fieldOrPathString, 'Please select at least one equipment type');
    }

    if (
      fieldOrPathString === 'hysteresis' &&
      !isNaN(value) &&
      (value < 1 || value > 10)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} must be between 1°C (1.8°F) and 10°C (18°F).`
      );
    }

    if (
      fieldOrPathString === 'sensorId' &&
      (value === undefined || value < 0)
    ) {
      addError(fieldOrPathString, 'Please select a sensor.');
    }

    if (
      (fieldOrPathString === 'validFrom' || fieldOrPathString === 'validTo') &&
      !!fields[fieldOrPathString]
    ) {
      if (!v.isValidISODateString(value)) {
        addError(
          fieldOrPathString,
          'Please make sure date is valid YYYY-MM-DD format.'
        );
      }
    }

    if (
      fieldOrPathString === 'validFrom' &&
      !!fields[fieldOrPathString] &&
      !!fields['validTo']
    ) {
      const toDateValue = fields['validTo'];
      if (
        v.isValidISODateString(toDateValue) &&
        !v.isFromDateBeforeToDate(value, toDateValue)
      ) {
        addError(fieldOrPathString, 'Valid From must be before Valid To');
      }
    }

    if (
      fieldOrPathString === 'validTo' &&
      !!fields[fieldOrPathString] &&
      !!fields['validFrom']
    ) {
      const fromDateValue = fields['validFrom'];
      if (
        v.isValidISODateString(fromDateValue) &&
        !v.isFromDateBeforeToDate(fromDateValue, value)
      ) {
        addError(fieldOrPathString, 'Valid From must be before Valid To');
      }
    }

    if (fieldOrPathString === 'periods') {
      const errorsByPeriodIndex: string[] = value.map(
        (p: EditableReportPeriod) => {
          if (!v.isValidTimeRange(p.from, p.to)) {
            return 'error';
          }
          return undefined;
        }
      );

      if (errorsByPeriodIndex.includes('error')) {
        addError(fieldOrPathString, errorsByPeriodIndex);
      }
    }

    if (fieldOrPathString === 'setPoint') {
      const numberValue = Number(value);
      if (!v.isNumber(numberValue)) {
        addError(fieldOrPathString, 'Must be a valid number');
      }
    }

    if (fieldOrPathString === 'ct') {
      if (!value) {
        addError(fieldOrPathString, 'Please select a CT Type');
      }
    }

    if (
      (fieldOrPathString === 'northUuid' ||
        fieldOrPathString === 'southUuid') &&
      value !== 0 &&
      !v.isPresent(value)
    ) {
      addError(
        fieldOrPathString,
        `${PropertyToLabel[fieldOrPathString]} cannot be empty`
      );
    }

    // EPro2 UUID validation
    if (formType === 'energyPro') {
      if (
        fieldOrPathString === 'uuid' &&
        fields.energyProModel === 'ENERGY_PRO2'
      ) {
        if (!String(value).startsWith('02')) {
          addError(
            fieldOrPathString,
            `${PropertyToLabel[fieldOrPathString]} must start with '02'`
          );
        }
        if (String(value).length !== 17) {
          addError(
            fieldOrPathString,
            `${PropertyToLabel[fieldOrPathString]} must be exactly 12 digits long`
          );
        }
      }
    }
  });

  return errors;
}

export enum FormErrorMessage {
  IS_EMPTY = 'cannot be empty',
  NOT_SELECTED = 'must be selected',
  IS_NEGATIVE_INTEGER = 'must be an integer 0 or greater',
  IS_INVALID_BIAS = 'must be between -120 and 120',
  INCORRECT_UUID_FORMAT = 'Incorrect uuid format. Pattern should resemble 00:A0:C9:14:C8:29',
  INVALId_UUID_FOR_THERMOSTAT = 'Incorrect uuid for thermostat type',
  DEFAULT = 'Invalid',
}

export const areThereFormErrors = (formErrors: Object) => {
  const isThereAnyFormError = Object.values(formErrors).some(err => {
    return v.isPresent(err);
  });

  return isThereAnyFormError;
};

export const validateThermostatForm = (venstarFields?: EditThermostat) => {
  let errors = {};
  if (!venstarFields) return errors;
  Object.keys(venstarFields).forEach(field => {
    switch (field) {
      case 'title':
        if (!v.isPresent(venstarFields[field])) {
          errors[
            field
          ] = `${mapThermostatFieldToLabel[field]} ${FormErrorMessage.IS_EMPTY}`;
        }
        break;
      case 'uuid':
        if (!v.isPresent(venstarFields[field])) {
          errors[
            field
          ] = `${mapThermostatFieldToLabel[field]} ${FormErrorMessage.IS_EMPTY}`;
          break;
        }
        const pattern = /^([0-9A-F]{2}:){5}[0-9A-F]{2}$/;
        if (!pattern.test(venstarFields[field])) {
          errors[field] = `${FormErrorMessage.INCORRECT_UUID_FORMAT}`;
          break;
        }
        if (
          !isUUIDValidForThermostatType(
            venstarFields?.model,
            venstarFields[field]
          )
        ) {
          errors[field] = `${FormErrorMessage.INVALId_UUID_FOR_THERMOSTAT}`;
        }
        break;
      case 'siteId':
      case 'spaceId':
      case 'networkGroupId':
        if (!v.isDropdownSelected(venstarFields[field] as number)) {
          errors[
            field
          ] = `${mapThermostatFieldToLabel[field]} ${FormErrorMessage.NOT_SELECTED}`;
        }
        break;

      case 'wirelessTemperatureSensorsCount':
        if (!v.isPositiveIntegerOrZero(venstarFields[field])) {
          errors[
            field
          ] = `${mapThermostatFieldToLabel[field]} ${FormErrorMessage.IS_NEGATIVE_INTEGER}`;
        }
        break;

      default:
        break;
    }
  });
  return errors;
};

export const validateHvacControlForm = (
  hvacControlFields: EditHvacSOPControl | undefined,
  temperatureUnit: TemperatureUnit
) => {
  let errors = {};
  if (!hvacControlFields) return errors;
  const {
    thermostatId,
    outsideOfTimeTable,
    maxTemp,
    minTemp,
    timetableId,
    outsideMaxTemp,
    outsideMinTemp,
    outsideFanMode,
    thermostatWorkingMode,
    enableLocalAdjustment,
    setPointLimitDelta,
  } = hvacControlFields;
  const isOutsideOfTimeTableActive =
    outsideOfTimeTable === OutsideOfTimeTable.ALTERNATIVE_SET_POINTS;
  const isTimeTableSelected = v.isDropdownSelected(timetableId);

  const isMaxTempValid = (maxTemp: number | undefined) => {
    switch (thermostatWorkingMode) {
      case ThermostatWorkingMode.AUTO:
      case ThermostatWorkingMode.COOLING:
        return !R.isNil(maxTemp) && Number(maxTemp) <= 35;

      default:
        return true;
    }
  };

  const isMinTempValid = (minTemp: number | undefined) => {
    switch (thermostatWorkingMode) {
      case ThermostatWorkingMode.AUTO:
      case ThermostatWorkingMode.HEATING:
        if (temperatureUnit === TemperatureUnit.F) {
          //Fahrenheit min allowed value is 39F, which converts to 3.8889
          return !R.isNil(minTemp) && Number(minTemp) >= 3.8889;
        }

        return !R.isNil(minTemp) && Number(minTemp) >= 4;

      default:
        return true;
    }
  };
  const isTemperatureRangeDifferenceValid = (
    maxTemp: number | undefined,
    minTemp: number | undefined
  ) => {
    switch (thermostatWorkingMode) {
      case ThermostatWorkingMode.AUTO:
        return (
          !R.isNil(maxTemp) &&
          !R.isNil(minTemp) &&
          Number(maxTemp) - Number(minTemp) >= 1
        );

      default:
        return true;
    }
  };
  const isBiasValid = (value: string | undefined) => {
    return v.isPresent(value) && Number(value) >= -120 && Number(value) <= 120;
  };

  Object.keys(hvacControlFields).forEach(field => {
    switch (field) {
      case 'thermostatId':
        if (!v.isDropdownSelected(thermostatId)) {
          errors[
            field
          ] = `${mapHvacControlFieldToLabel[field]} ${FormErrorMessage.NOT_SELECTED}`;
        }
        break;

      case 'maxTemp':
        if (
          !isMaxTempValid(maxTemp) ||
          !isTemperatureRangeDifferenceValid(maxTemp, minTemp)
        ) {
          errors[field] = FormErrorMessage.DEFAULT;
        }
        break;

      case 'minTemp':
        if (
          !isMinTempValid(minTemp) ||
          !isTemperatureRangeDifferenceValid(maxTemp, minTemp)
        ) {
          errors[field] = FormErrorMessage.DEFAULT;
        }
        break;

      case 'setPointLimitDelta':
        if (
          enableLocalAdjustment &&
          (!isDefined(setPointLimitDelta) ||
            setPointLimitDelta < 1 ||
            setPointLimitDelta > 2)
        ) {
          errors[field] = `${PropertyToLabel[field]} must be selected`;
        }
        break;

      case 'beginDelta':
      case 'endDelta':
        if (
          isTimeTableSelected &&
          !isBiasValid(String(hvacControlFields[field]))
        ) {
          errors[field] = FormErrorMessage.IS_INVALID_BIAS;
        }
        break;

      case 'outsideMaxTemp':
        if (
          isTimeTableSelected &&
          isOutsideOfTimeTableActive &&
          (!isMaxTempValid(outsideMaxTemp) ||
            !isTemperatureRangeDifferenceValid(outsideMaxTemp, outsideMinTemp))
        ) {
          errors[field] = FormErrorMessage.DEFAULT;
        }
        break;

      case 'outsideMinTemp':
        if (
          isTimeTableSelected &&
          isOutsideOfTimeTableActive &&
          (!isMinTempValid(outsideMinTemp) ||
            !isTemperatureRangeDifferenceValid(outsideMaxTemp, outsideMinTemp))
        ) {
          errors[field] = FormErrorMessage.DEFAULT;
        }
        break;

      // TODO: uncomment this once we remove FeatureFlag from Outside Fan Mode
      // case 'outsideFanMode':
      //   if (
      //     isTimeTableSelected &&
      //     isOutsideOfTimeTableActive &&
      //     !isDefined(outsideFanMode)
      //   ) {
      //     errors[field] = FormErrorMessage.NOT_SELECTED;
      //   }
      //   break;

      default:
        break;
    }
  });
  return errors;
};

//Validate if uuid complies with thermostat type
const isUUIDValidForThermostatType = (
  model: string,
  uuid: string | undefined
) => {
  if (uuid && model == ThermostatModelType.ENERGYBOX_THERMOSTAT) {
    return isEBThermostat(uuid);
  } else if (uuid && model == ThermostatModelType.VENSTAR_THERMOSTAT) {
    return !isEBThermostat(uuid);
  }
  return true;
};
