import * as R from 'ramda';
import { Actions } from '../actions/paths';
import {
  Edge,
  Equipment,
  NetworkGroup,
  Organization,
  ResourcePath,
  ResourceType,
  Sensor,
  Site,
  Space,
} from '@energybox/react-ui-library/dist/types';

const initialState = {
  byId: {},
  isLoadingById: {},
  byIds: {},
};

function mapTargetTypes(
  resource: Sensor | Equipment | Space | Organization | Edge | NetworkGroup
) {
  switch (resource._entity) {
    case ResourceType.EQUIPMENT:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity,
        typeId: (resource as Equipment).typeId,
      };

    case ResourceType.ORGANIZATION:
    case null:
      return null;

    case ResourceType.EDGE:
      return {
        title: (resource as Edge).uuid,
        id: resource.id,
        type: resource._entity,
      };

    case ResourceType.SITE:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity,
        timeZone: (resource as Site).timeZone,
      };

    default:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity,
      };
  }
}

export type ResourcePaths = {
  byId: PathsById;
  byIds: PathsById;
  isLoadingById: IsPathLoadingById;
};

export type IsPathLoadingById = {
  [id: string]: boolean;
};

export type PathsById = {
  [id: string]: ResourcePath[];
};

const resourcePaths = (state: ResourcePaths = initialState, action: any) => {
  switch (action.type) {
    case Actions.PATH_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['byId', action.id],
          (action.data || []).map(r => mapTargetTypes(r)).filter(p => p)
        ),
        R.assocPath(['isLoadingById', action.id], false)
      )(state);

    case Actions.PATH_LOADING:
      return R.assocPath(['isLoadingById', action.id], true, state);

    case Actions.PATH_ERROR:
      return R.assocPath(['isLoadingById', action.id], false, state);

    case Actions.CLEAR_RESOURCE_PATH:
      return R.dissocPath(['byId', action.id], state);

    case Actions.PATHS_SUCCESS:
      const newPathsById = action.ids.reduce((acc, id) => {
        acc[id] = (action.data[id] || [])
          .map(r => mapTargetTypes(r))
          .filter(p => p);
        return acc;
      }, {});
      return R.pipe(
        R.mergeDeepRight(R.__, { byIds: newPathsById }),
        R.assocPath(['isLoadingById', action.ids], false)
      )(state);

    case Actions.PATHS_LOADING:
      return R.assocPath(['isLoadingById', action.ids], true, state);

    case Actions.PATHS_ERROR:
      return R.assocPath(['isLoadingById', action.ids], false, state);

    case Actions.CLEAR_RESOURCE_PATHS:
      return R.pipe(
        R.evolve({
          byIds: R.omit(action.ids),
          isLoadingById: R.omit(action.ids),
        })
      )(state);

    default:
      return state;
  }
};

export default resourcePaths;
