import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
  SelectItemContainer,
} from '@energybox/react-ui-library/dist/components';
import { EquipmentType } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import useEquipmentTypes from '../../hooks/useEquipmentTypes';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import checkWhichEnvironment from '../../utils/environment';
import { getEquipmentGroups, getEquipmentTypes } from '../../actions/app';
import { ApplicationState } from '../../reducers';

interface Props {
  listClassName?: string;
  onSelect: (equipmentTypeId: number) => void;
  value?: number;
  groupId?: number;
  useControlledOnly?: boolean;
  useHvacOnly?: boolean;
  error: boolean;
  customErrorText?: string;
  fullWidth?: boolean;
  isOrgLevelEdit?: boolean;
}

const SelectEquipmentType: React.FC<Props> = props => {
  const {
    listClassName,
    value,
    onSelect,
    error,
    customErrorText,
    groupId,
    fullWidth,
    isOrgLevelEdit,
    useControlledOnly = false,
    useHvacOnly = false,
  } = props;

  const [search, setSearch] = useState('');

  const equipmentGroups = useSelector(({ app }: ApplicationState) =>
    app.equipmentGroups?.sort(({ title: aTitle }, { title: bTitle }) =>
      aTitle.localeCompare(bTitle)
    )
  );
  const equipmentTypes = useEquipmentTypes(groupId);
  const filteredEquipmentTypes = useControlledOnly
    ? equipmentTypes?.filter(({ controllable }) => controllable === true)
    : equipmentTypes;
  const HVAC_EQUIPMENT_GROUP_ID = equipmentGroups
    ? equipmentGroups.find(group => group.alias === 'equipmentHvac')?.id
    : null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!equipmentTypes) dispatch(getEquipmentTypes());
    if (!equipmentGroups) dispatch(getEquipmentGroups());
  }, []);

  const searchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const resetSearch = () => {
    setSearch('');
  };

  const isEquipmentGroupSelected = () => {
    if (props.groupId !== -1) {
      return true;
    }
    return false;
  };

  // Render starts here
  if (!filteredEquipmentTypes) {
    return <Loader size={12} />;
  } else {
    const listedEquipmentTypes = (filteredEquipmentTypes || []).filter(
      (eq: EquipmentType) => {
        if (search.length <= 2) return true;
        return hasSubstr(eq.title, search);
      }
    );

    const selectedEquipmentType = filteredEquipmentTypes.find(
      eq => eq.id === value
    );

    const renderSelectItems = () => {
      const isHvacEquipmentGroupSelected =
        useHvacOnly || groupId === HVAC_EQUIPMENT_GROUP_ID;

      if (isHvacEquipmentGroupSelected && !useControlledOnly) {
        const controlsHvacEquipmentTypes = listedEquipmentTypes.filter(eqType =>
          eqType.controllable!.valueOf()
        );
        const otherEquipmentTypes = listedEquipmentTypes.filter(
          eqType => !eqType.controllable!.valueOf()
        );

        return (
          <div>
            <SelectItemContainer title="HVAC Controlled">
              {controlsHvacEquipmentTypes.map((eqType: EquipmentType) => (
                <SelectItem
                  key={eqType.id}
                  isSelected={eqType.id === selectedEquipmentType?.id}
                  onSelect={() => onSelect(eqType.id)}
                >
                  {eqType.title}
                </SelectItem>
              ))}
            </SelectItemContainer>

            <SelectItemContainer hideBottomBorder title="HVAC Others">
              {otherEquipmentTypes.map((eqType: EquipmentType) => (
                <SelectItem
                  key={eqType.id}
                  isSelected={eqType.id === selectedEquipmentType?.id}
                  onSelect={() => onSelect(eqType.id)}
                >
                  {eqType.title}
                </SelectItem>
              ))}
            </SelectItemContainer>
          </div>
        );
      }

      return (
        <div>
          {listedEquipmentTypes.map((eqType: EquipmentType) => (
            <SelectItem
              key={eqType.id}
              isSelected={eqType.id === selectedEquipmentType?.id}
              onSelect={() => onSelect(eqType.id)}
            >
              {eqType.title}
            </SelectItem>
          ))}
        </div>
      );
    };

    return (
      <Select
        listClassName={listClassName}
        disabled={isOrgLevelEdit ? true : !isEquipmentGroupSelected()}
        variant={'select'}
        onClick={resetSearch}
        title={selectedEquipmentType?.title}
        value={selectedEquipmentType?.id}
        error={error}
        customErrorText={customErrorText}
        fullWidth={fullWidth}
      >
        <SelectSearch
          onChange={searchChange}
          value={search}
          error={listedEquipmentTypes.length === 0}
        />

        {renderSelectItems()}
      </Select>
    );
  }
};

export default SelectEquipmentType;
