import { DefaultSite as PhotoIcon } from '@energybox/react-ui-library/dist/icons';
import React, { SyntheticEvent } from 'react';
import styles from './UploadImageButton.module.css';

type Props = {
  handleSubmit: (e: SyntheticEvent) => void;
  customText?: string;
};

class UploadImageButton extends React.Component<Props> {
  render() {
    const { handleSubmit, customText } = this.props;
    return (
      <label htmlFor="in" className={styles.root}>
        <input
          id="in"
          name="in"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleSubmit}
          className={styles.input}
        />

        <div>
          <PhotoIcon size={70} color={'var(--accent-base)'} />
        </div>

        <div className={styles.text}>{customText || 'Add image'}</div>
      </label>
    );
  }
}

export default UploadImageButton;
