import {
  TimeTable,
  TimeTableRow,
  TimeTableTimeSlot,
} from '@energybox/react-ui-library/dist/types';
import { HvacSopTimeSlot } from '@energybox/react-ui-library/dist/types/Sop';
import { convert12hrTo24HrFormat } from '@energybox/react-ui-library/dist/utils';
import { isWorldTime, isUsTime } from './utils/time';
import { Interval, DateTime } from 'luxon';
import { MappedWeekdayToNumber } from './types/date';

export const isPresent = (v: string | undefined | null) => !!v && v !== '';

export const isDropdownSelected = (v: number | null | undefined) =>
  !!v && v !== -1;

export const hasWhiteSpace = v => /\s/.test(v);

export const isNumber = (value: number) => {
  return typeof value === 'number' && !Number.isNaN(value);
};

export const isPositiveIntegerOrZero = (value: number) => {
  return isNumber(value) && Number.isInteger(value) && value >= 0;
};

export const isPositiveNumberOrZero = (value: number) => {
  return Number.isFinite(value) && value >= 0;
};

export const isValidPassword = (p: string) =>
  isPresent(p) && !hasWhiteSpace(p) && p.length >= 6;

export const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test((email || '').toLowerCase());
};

export const isLatitudeValid = (lat: number) => {
  const reg = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/;
  return reg.test(lat.toString());
};

export const isLongitudeValid = (long: number) => {
  const reg = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/;
  return reg.test(long.toString());
};

export const isValidTime = (time: string) => {
  const reg = new RegExp('^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$');

  return reg.test(time);
};

export const isValidTimeRange = (from: string, to: string) => {
  //checks if times fit either 12hr or 24hr format,
  //and also if fromTime is before toTime
  if (isWorldTime.test(from) && isWorldTime.test(to)) {
    const fromTime = from.replace(':', '');
    const toTime = to.replace(':', '');

    return Number(toTime) > Number(fromTime);
  }

  if (isUsTime.test(from) && isUsTime.test(to)) {
    //format must be 09:00 AM
    const convertedFromTime = convert12hrTo24HrFormat(from);
    const convertedToTime = convert12hrTo24HrFormat(to);

    const fromTime = convertedFromTime.replace(':', '');
    const toTime = convertedToTime.replace(':', '');

    return Number(toTime) > Number(fromTime);
  }

  return false;
};

export const atMostOneDecimalRegex = /^-?(\d)*(\.)?([0-9]{1})?$/;

export const isValidISODateString = (dateString: string) => {
  //checks if date strings are in correct YYYY-MM-DD format
  const reg = new RegExp(
    /^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/g
  );
  return reg.test(dateString);
};

export const isFromDateBeforeToDate = (fromDate: string, toDate: string) => {
  const fromDateUnix = Date.parse(fromDate);
  const toDateUnix = Date.parse(toDate);

  return fromDateUnix < toDateUnix;
};

/**
 * @param beginTime expected format "HH:mm"
 * @param endTime expected format "HH:mm"
 * 
 returns true if endTime is after beginTime
 */
export const isValidBeginAndEndTime = (beginTime: string, endTime: string) => {
  const MOCK_WEEKDAY = 'MON';
  const beginDateTime = DateTime.fromObject({
    weekday: MappedWeekdayToNumber[MOCK_WEEKDAY],
    hour: +beginTime.slice(0, 2),
    minute: +beginTime.slice(3, 5),
  });
  const endDateTime = DateTime.fromObject({
    weekday: MappedWeekdayToNumber[MOCK_WEEKDAY],
    hour: +endTime.slice(0, 2),
    minute: +endTime.slice(3, 5),
  });

  return beginDateTime < endDateTime;
};

/**
 returns true if the hvacScheduleToValidate does not overlap any other hvac schedules
 */
export const isValidHvacSopSchedules = (
  hvacScheduleToValidate: HvacSopTimeSlot,
  otherOccupiedSchedules: HvacSopTimeSlot[]
) => {
  const intervalsToValidate: Interval[] = [];
  const otherIntervals: Interval[] = [];

  const createInterval = (
    weekday: string,
    begin: TimeTableTimeSlot,
    end: TimeTableTimeSlot
  ) => {
    const beginDateTime = DateTime.fromObject({
      weekday: MappedWeekdayToNumber[weekday],
      hour: +(begin.time as string).slice(0, 2),
      minute: +(begin.time as string).slice(3, 5),
    });
    const endDateTime = DateTime.fromObject({
      weekday: MappedWeekdayToNumber[weekday],
      hour: +(end.time as string).slice(0, 2),
      minute: +(end.time as string).slice(3, 5),
    });

    return Interval.fromDateTimes(beginDateTime, endDateTime);
  };

  const { timetable: timetableToValidate } = hvacScheduleToValidate;
  //assumes weekdays, begin, end are validated already from higher scope

  const { weekdays, begin, end } = timetableToValidate.rows[0];

  weekdays.forEach(w => {
    if (hvacScheduleToValidate.hvacScheduleType === 'OCCUPIED') {
      const interval = createInterval(w, begin, end);
      intervalsToValidate.push(interval);
    }
  });

  const otherTimetableRows = otherOccupiedSchedules.map(
    s => s.timetable.rows[0]
  );
  otherTimetableRows.forEach(({ weekdays, begin, end }) => {
    weekdays.forEach(weekday => {
      const interval = createInterval(weekday, begin, end);
      otherIntervals.push(interval);
    });
  });

  const isThereNoOverlap = intervalsToValidate.every(intervalToValidate => {
    return otherIntervals.every(otherInterval => {
      return !otherInterval.overlaps(intervalToValidate);
    });
  });

  if (isThereNoOverlap) {
    return true;
  } else {
    return false;
  }
};
