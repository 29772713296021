import {
  createOperationalSamplesQueryString,
  convertQueryDatesToTsApiFormat,
} from '@energybox/react-ui-library/dist/utils';
import { GetOperationalSamplesParams } from '@energybox/react-ui-library/dist/types';

import * as R from 'ramda';
import { EnergyboxService } from '../config';

const apiBase = '/operational/sensor/';

export enum Actions {
  GET_OPERATIONAL_SAMPLES_SUCCESS = '@operational_samples/GET_OPERATIONAL_SAMPLES_SUCCESS',
  GET_OPERATIONAL_SAMPLES_ERROR = '@operational_samples/GET_OPERATIONAL_SAMPLES_ERROR',
  GET_OPERATIONAL_SAMPLES_LOADING = '@operational_samples/GET_OPERATIONAL_SAMPLES_LOADING',
}

export const getOperationalSamples = (params: GetOperationalSamplesParams) => ({
  service: EnergyboxService.timeSeries,
  type: 'API_GET',
  path: `${apiBase}${params.sensorId}?${createOperationalSamplesQueryString(
    convertQueryDatesToTsApiFormat(R.omit(['sensorId'], params))
  )}`,
  success: {
    type: Actions.GET_OPERATIONAL_SAMPLES_SUCCESS,
    id: params.sensorId,
  },
  error: Actions.GET_OPERATIONAL_SAMPLES_ERROR,
  loading: Actions.GET_OPERATIONAL_SAMPLES_LOADING,
});
