import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './DelayInfoTooltip.module.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';

type Props = {
  hasError?: boolean;
  isOrgLevel?: boolean;
};

const DelayInfoTooltip: React.FC<Props> = ({ hasError, isOrgLevel }) => {
  return (
    <Tooltip
      underline={isOrgLevel ? true : false}
      arrowDirection={isOrgLevel ? 'topLeft' : 'right'}
      tooltipTextClassName={isOrgLevel ? styles.text : ''}
      extraClassNames={isOrgLevel ? [styles.tooltip] : [styles.margin]}
      content={
        <div className={styles.tooltipText}>
          <div className={styles.bold}>Delay</div>
          <div className={styles.paddingTop}>
            <div>
              The circuit will only be switched on/off after the entered number
              of consecutive readings below/above the lux threshold are
              registered
            </div>
          </div>
        </div>
      }
    >
      {isOrgLevel ? (
        <div className={styles.title}> Delay </div>
      ) : (
        <IoIosInformationCircleOutline
          color={hasError ? 'var(--pink-base)' : 'black'}
          size={20}
        />
      )}
    </Tooltip>
  );
};

export default DelayInfoTooltip;
