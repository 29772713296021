import { ApplicationState } from '../reducers';
import {
  AccessResource,
  Role,
  User,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../actions/users';
import { getUserGroups } from '../actions/user_groups';
import flatten from 'ramda/src/flatten';

export type UserPermission = {
  isGlobal: boolean;
  role: Role | null;
};

const useGetUserPermission = (): UserPermission => {
  const dispatch = useDispatch();
  const userId = useSelector(
    ({ app }: ApplicationState) => app.currentUser?.id
  );

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [dispatch, userId]);

  const user: User | undefined = useSelector(({ users }: ApplicationState) => {
    if (userId) {
      return users.usersById[userId];
    }
    return undefined;
  });

  const userGroupIds = user?.groupIds;
  useEffect(() => {
    if (userGroupIds) {
      dispatch(getUserGroups(userGroupIds));
    }
  }, [dispatch, userGroupIds]);

  const userGroups = useSelector(({ userGroups }: ApplicationState) => {
    return Object.values(userGroups.userGroupsById);
  });

  //we have a hack where users have a "global" permissions
  //can be "Viewer"/"Manager"/"Admin" (like normal permissions), can switch between orgs
  //only EB users should have global permissions, i believe
  const globalPermissions = user?.role;

  //this shows sites/orgs that a user has direct access to
  const userAccessResources: AccessResource[] | undefined =
    user?.accessResources;

  //this shows sites/orgs that a user has access to via association with userGroup
  const userGroupAccessResources: AccessResource[] = flatten(
    userGroups.map((ug) => ug.accessResources)
  );
  const highestRole = determineHighestRole(
    userAccessResources,
    userGroupAccessResources
  );

  if (isDefined(globalPermissions)) {
    return {
      isGlobal: true,
      role: globalPermissions,
    };
  }

  return {
    isGlobal: false,
    role: highestRole,
  };
};

export default useGetUserPermission;

export const determineHighestRole = (
  userAccessResources: AccessResource[] = [],
  userGroupAccessResources: AccessResource[]
) => {
  const allAccessResources = [
    ...userAccessResources,
    ...userGroupAccessResources,
  ];

  if (allAccessResources.some((r) => r.role === Role.ADMIN)) {
    return Role.ADMIN;
  }

  if (allAccessResources.some((r) => r.role === Role.MANAGER)) {
    return Role.MANAGER;
  }

  if (allAccessResources.some((r) => r.role === Role.VIEWER)) {
    return Role.VIEWER;
  }

  return null;
};