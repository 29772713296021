import { ActuatorState } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as StreamActions } from '../actions/streamApi';

export type SubscribedControlBoardOutputStates = {
  [id: number]: NormalizedActuatorStates;
};

export type NormalizedActuatorStates = {
  timestamp: number;
  state: ActuatorState[];
  uuid?: string;
};

const normalizeActuatorStates = (data: any) => {
  return {
    timestamp: data.timestamp,
    state: data.state,
  };
};

const pickMostRecentReading = (
  a: NormalizedActuatorStates,
  b: NormalizedActuatorStates
) => {
  if (!a || !a.timestamp) return b;
  if (!b || !b.timestamp) return a;
  return a.timestamp >= b.timestamp ? a : b;
};

const subscribedControlBoardOutputStates = (
  state = {} as SubscribedControlBoardOutputStates,
  action: any
) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      if (action.data.deviceType === 'control' && action.data.outputState) {
        const id = action.data.id;
        const actuatorStates = normalizeActuatorStates(action.data.outputState);
        return R.assocPath(
          [id],
          pickMostRecentReading(R.pathOr({}, [id], state), actuatorStates),
          state
        );
      }
      return state;
    }

    case GatewayActions.GET_GATEWAY_SUCCESS: {
      if (
        action.data &&
        action.data.type === 'CONTROL' &&
        action.data.actuatorsStates
      ) {
        const id = action.data.id;
        const actuatorStates = normalizeActuatorStates(
          action.data.actuatorsStates
        );
        return R.assocPath(
          [id],
          pickMostRecentReading(R.pathOr({}, [id], state), actuatorStates),
          state
        );
      }

      return state;
    }

    default:
      return state;
  }
};

export default subscribedControlBoardOutputStates;
