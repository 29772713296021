import {
  EquipmentTemperatureRecommendation,
  SpaceTemperatureRecommendation,
} from '@energybox/react-ui-library/dist/types';

export enum ReportType {
  TEMPERATURE_CHECK = 'temperature_check',
}

export interface ReDoRequestBody {
  report_type: string;
  report_key: string;
  start_date: string;
  end_date: string;
}

export enum TempCheckInterval {
  DAILY,
  MONTHLY,
}

export type EquipmentSOP = {
  equipmentId?: string;
  equipmentName: string;
  sop?: {
    components: [
      {
        hvacSettings: {
          hvacSchedules: [
            {
              id: number;
              maxTemp: number;
              minTemp: number;
            }
          ];
        };
        type: string;
      }
    ];
    id: number;
    title: string;
  };
};

export type SetPointRecommendation = {
  comfortTemp: number;
  equipment: EquipmentSOP[];
  spaceName: string;
  status: string;
};

export type TemperatureRecommendationBySiteId = {
  [siteId: number]: {
    isLoading: boolean;
    data: {
      [equipmentId: number]: EquipmentTemperatureRecommendation;
    };
    predict_day_start: string;
    predict_day_end: string;
  };
};

export type TemperatureRecommendationBySiteIdResponse = {
  data: {
    by_space: {
      [spaceId: number]: SpaceTemperatureRecommendation;
    };
    by_hvac_equipment: {
      [equipmentId: number]: EquipmentTemperatureRecommendation;
    };
    predict_day_start: string;
    predict_day_end: string;
  };
};
