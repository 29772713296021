import {
  DeviceType,
  Gateway,
  SensorsWhitelist,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { MdAutorenew } from 'react-icons/md';
import { connect } from 'react-redux';
import {
  addToWhitelist,
  refreshPairedSensors,
  refreshScannedSensors,
  refreshWhitelist,
  removeFromWhitelist,
} from '../../actions/gateways';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import SensorsNearbyTable from '../../components/SensorsNearbyTable';
import { ApplicationState } from '../../reducers';
import { PairedSensor } from '../../reducers/pairedSensors';
import { ScannedSensor } from '../../reducers/scannedSensors';
import { Status } from '../../types/status';
import FirmwareFlag from '../FirmwareFlag';
import history from '../../history';
import { Routes } from '../../routes';
import PairedSensorsActionsTable from './PairedSensorsActionsTable';
import WhitelistTable from './WhitelistTable';

interface OwnProps {
  gateway: Gateway;
  ianaTimeZoneCode?: string;
}
interface Props extends OwnProps {
  load: () => void;

  sensorWhitelistStatus: Status;
  sensorsPairedListStatus: Status;
  scannedSensorsListStatus: Status;

  sensorsWhitelist?: SensorsWhitelist;
  sensorsPairedList?: PairedSensor[];
  scannedSensorsList?: ScannedSensor[];

  subscribeToDeviceStatus: (vendor: string, uuid: string) => void;
  unsubscribeFromDeviceStatus: (vendor: string, uuid: string) => void;

  addToWhitelist: (uuid: string) => void;

  removeFromWhitelist: (uuid: string) => void;
  refreshWhitelist: () => void;
  refreshPairedList: () => void;
  refreshNearbyList: () => void;
}

export enum GatewaySensorTableTabs {
  WHITE_LIST = 'White List',
  SENSORS_NEARBY = 'Sensors Nearby',
  DEVICES_NEARBY = 'Devices Nearby',
  SENSOR_CONTROL = 'Paired Sensor Actions',
  UNPAIRED_LIST = 'Unpaired List',
  CONNECTED = 'Connected',
  UNCONNECTED = 'Unconnected',
}

interface State {
  activeTab: GatewaySensorTableTabs;
  sensorUuidWhitelist: string;
}

class GatewaySensors extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: GatewaySensorTableTabs.WHITE_LIST,
      sensorUuidWhitelist: '',
    };
  }

  gatewaySensorTableRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const { uuid, vendor } = this.props.gateway;
    this.props.subscribeToDeviceStatus(vendor, uuid);
    this.props.load();
    if (history.location.hash === Routes.PAIRED_SENSOR_ACTIONS) {
      this.setState({
        activeTab: GatewaySensorTableTabs.SENSOR_CONTROL,
      });
      this.gatewaySensorTableRef.current?.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }

  componentWillUnmount() {
    const { uuid, vendor } = this.props.gateway;
    this.props.unsubscribeFromDeviceStatus(vendor, uuid);
  }

  refreshAll = () => {
    this.props.refreshPairedList();
    this.props.refreshWhitelist();
    this.props.refreshNearbyList();
  };

  render() {
    const {
      gateway,
      sensorsWhitelist,
      sensorsPairedList = [],
      scannedSensorsList = [],
      addToWhitelist,
      removeFromWhitelist,
      sensorWhitelistStatus,
      sensorsPairedListStatus,
      scannedSensorsListStatus,
      ianaTimeZoneCode,
    } = this.props;

    const { activeTab } = this.state;
    const isLoadingLists =
      [
        sensorWhitelistStatus,
        sensorsPairedListStatus,
        scannedSensorsListStatus,
      ].indexOf(Status.LOADING) !== -1;
    const isSuperHub = gateway.model === DeviceType.EB_SUPER_HUB;
    // Filters out thermostats by uuid starting with 03:00
    const filteredWhitelist = sensorsWhitelist?.uuids.filter(
      uuid => !uuid.startsWith('03:00')
    );
    const filteredPairedList = sensorsPairedList.filter(
      sensor => !sensor.uuid.startsWith('03:00')
    );
    const filteredScannedList = scannedSensorsList.filter(
      sensor => !sensor.uuid.startsWith('03:00')
    );

    return (
      <Card>
        <div
          style={{ position: 'relative', width: '100%', minHeight: '100px' }}
        >
          {isLoadingLists && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                display: 'flex',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
              <div style={{ margin: '0 auto' }}>
                {/* <Loader size={25}/> */}
              </div>
            </div>
          )}
          <CardContent>
            <div
              style={{ display: 'grid', gridTemplateColumns: 'auto 100px' }}
              ref={this.gatewaySensorTableRef}
            >
              <Tabs>
                <Tab
                  active={activeTab === GatewaySensorTableTabs.WHITE_LIST}
                  onClick={() =>
                    this.setState({
                      activeTab: GatewaySensorTableTabs.WHITE_LIST,
                    })
                  }
                >
                  {GatewaySensorTableTabs.WHITE_LIST}
                </Tab>
                <Tab
                  active={activeTab === GatewaySensorTableTabs.SENSORS_NEARBY}
                  onClick={() =>
                    this.setState({
                      activeTab: GatewaySensorTableTabs.SENSORS_NEARBY,
                    })
                  }
                >
                  {GatewaySensorTableTabs.SENSORS_NEARBY}
                </Tab>
                {!isSuperHub && (
                  <FirmwareFlag
                    firmwareVersion={
                      gateway.gatewayInfo
                        ? gateway.gatewayInfo.firmwareVersion
                        : 'n/a'
                    }
                    operator={'>='}
                    supportedFirmwareVersion={'1.1.482'}
                  >
                    <Tab
                      active={
                        activeTab === GatewaySensorTableTabs.SENSOR_CONTROL
                      }
                      onClick={() =>
                        this.setState({
                          activeTab: GatewaySensorTableTabs.SENSOR_CONTROL,
                        })
                      }
                    >
                      {GatewaySensorTableTabs.SENSOR_CONTROL}
                    </Tab>
                  </FirmwareFlag>
                )}
              </Tabs>
              <Button
                disabled={isLoadingLists}
                onClick={this.refreshAll}
                variant="text"
              >
                {' '}
                Refresh <MdAutorenew size={16} />
              </Button>
            </div>
            <div>
              {activeTab === GatewaySensorTableTabs.WHITE_LIST && (
                <WhitelistTable
                  ianaTimeZoneCode={ianaTimeZoneCode}
                  isLoading={isLoadingLists}
                  list={filteredWhitelist ? filteredWhitelist : []}
                  sensorsPairedList={sensorsPairedList ? sensorsPairedList : []}
                  onRemoveSensor={removeFromWhitelist}
                  onSensorAdd={addToWhitelist}
                  openNewModal={() => {}}
                />
              )}
              {activeTab === GatewaySensorTableTabs.SENSORS_NEARBY && (
                <SensorsNearbyTable
                  isLoading={isLoadingLists}
                  onSensorAdd={addToWhitelist}
                  list={filteredScannedList}
                  sensorsWhitelist={filteredWhitelist ? filteredWhitelist : []}
                />
              )}
              {activeTab === GatewaySensorTableTabs.SENSOR_CONTROL && (
                <PairedSensorsActionsTable
                  gatewayUuid={gateway.uuid}
                  sensorsPairedList={
                    filteredPairedList ? filteredPairedList : []
                  }
                />
              )}
            </div>
          </CardContent>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (
  { sensorWhitelist, pairedSensors, scannedSensors }: ApplicationState,
  { gateway }: OwnProps
) => ({
  sensorWhitelistStatus: sensorWhitelist.status,
  sensorsPairedListStatus: pairedSensors.status,
  scannedSensorsListStatus: scannedSensors.status,

  sensorsWhitelist:
    sensorWhitelist.sensorWhitelistByGatewayId[gateway.id.toString()],
  sensorsPairedList:
    pairedSensors.pairedSensorsByGatewayId[gateway.id.toString()],
  scannedSensorsList:
    scannedSensors.scannedSensorsByGatewayId[gateway.id.toString()],
});

const mapDispatchToProps = (dispatch: any, { gateway }: OwnProps) => ({
  load: () => {
    dispatch(refreshWhitelist(gateway.id.toString()));
    dispatch(refreshPairedSensors(gateway.id.toString()));
    dispatch(refreshScannedSensors(gateway.id.toString()));
  },
  addToWhitelist: uuid => {
    dispatch(addToWhitelist(gateway.id.toString(), uuid));
  },
  removeFromWhitelist: uuid => {
    dispatch(removeFromWhitelist(gateway.id.toString(), uuid));
  },
  refreshWhitelist: () => {
    dispatch(refreshWhitelist(gateway.id.toString()));
  },
  refreshPairedList: () => {
    dispatch(refreshPairedSensors(gateway.id.toString()));
  },
  refreshNearbyList: () => {
    dispatch(refreshScannedSensors(gateway.id.toString()));
  },
  subscribeToDeviceStatus: (vendor, uuid) => {
    dispatch(
      subscribeToDeviceStatus(vendor, uuid, parseInt(gateway.id.toString()))
    );
  },
  unsubscribeFromDeviceStatus: (vendor, uuid) => {
    dispatch(
      unsubscribeFromDeviceStatus(vendor, uuid, parseInt(gateway.id.toString()))
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GatewaySensors);
