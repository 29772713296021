import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { BreakerRatings } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getBreakerRatings } from '../../actions/app';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  onSelect: (breakerRating: number) => void;
  value?: number;
  error: boolean;
  disabled?: boolean;
}

interface Props extends OwnProps {
  getBreakerRatings: typeof getBreakerRatings;
  breakerRatings?: BreakerRatings['ratings'];
}

interface State {
  search: string;
}

class SelectBreakerRating extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { breakerRatings, getBreakerRatings } = this.props;
    if (!breakerRatings) getBreakerRatings();
  }

  render() {
    const { breakerRatings, value, onSelect, error, disabled } = this.props;

    if (!breakerRatings) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;

      const listedBreakerRatings = (breakerRatings || []).filter(
        (eq: number) => {
          return hasSubstr(eq.toString(), search);
        }
      );

      const selectedBreakerRating = breakerRatings.find(eq => eq === value);

      return (
        <Select
          disabled={disabled}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedBreakerRating}
          value={selectedBreakerRating}
          error={error}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
          />
          {listedBreakerRatings.map((eq: number) => (
            <SelectItem
              key={eq}
              isSelected={eq === selectedBreakerRating}
              onSelect={() => onSelect(eq)}
            >
              {eq}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  breakerRatings: app.breakerRatings && app.breakerRatings.ratings,
});

const mapDispatchToProps = {
  getBreakerRatings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectBreakerRating);
