import { Tooltip } from '@energybox/react-ui-library/dist/components';
import { Sop } from '@energybox/react-ui-library/dist/types/Sop';
import { Link } from 'react-router-dom';
import { useGetNetworkGroupsBySopId } from '../../hooks/useGetNetworkGroup';
import { Routes } from '../../routes';
import styles from './LastNetworkGroupConfigUpdate.module.css';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import LastNetworkGroupConfigUpdate from './LastNetworkGroupConfigUpdate';
import EdgeConfigStatus from './EdgeConfigStatus';
import useForceRerender from '../../hooks/useForceRerender';
import { useEffect } from 'react';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

declare type Props = {
  siteId: number;
  hvacSops?: Sop[];
  isOrglevelUpdate?: boolean;
  networkGroups?: NetworkGroup[];
  getUpdate?: boolean;
};

export const LastConfigUpdateTooltip: React.FC<Props> = ({
  siteId,
  hvacSops,
  isOrglevelUpdate,
  networkGroups,
  getUpdate,
}) => {
  const orgId = useCurrentOrganizationId();
  const networkGroupArrayFromSop = useGetNetworkGroupArray(hvacSops!, orgId);
  const networkGroupArray = networkGroups?.length
    ? networkGroups
    : networkGroupArrayFromSop;

  const title = 'Last Config Update Status';
  const forceRerender = useForceRerender();
  const renderData = isDefined(getUpdate) ? getUpdate : true;

  //every minute, force rerender so that we can update config button message depending on local storage
  useEffect(() => {
    setInterval(() => {
      forceRerender();
    }, 60000);
  }, [forceRerender]);

  return (
    <Tooltip
      arrowDirection="topRight"
      disableAutoAdjust
      underline={false}
      isClickable
      content={
        <div className={styles.tooltipLineEntry}>
          <div>
            <div className={styles.lastConfigUpdateContainer}>
              {networkGroupArray?.map(networkGroup => (
                <div
                  key={`clickableTooltip${networkGroup?.id}`}
                  className={styles.lastConfigUpdate}
                >
                  <div className={styles.Title}>
                    {networkGroup.title}
                    {renderData && (
                      <EdgeConfigStatus networkGroup={networkGroup} />
                    )}
                    {isOrglevelUpdate &&
                      networkGroup.edge?.onlineStatus !== null &&
                      !networkGroup.edge?.onlineStatus?.onlineState && (
                        <span className={styles.redText}>
                          Network Group is offline.
                        </span>
                      )}
                  </div>
                  <div>
                    <div className={styles.subTitle}>Last Config Update:</div>
                    {renderData && (
                      <LastNetworkGroupConfigUpdate
                        networkGroup={networkGroup}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {!networkGroupArray?.length && (
            <div className={styles.noNetworkGroupText}>
              No Network group configured
            </div>
          )}
          <div className={styles.linkText}>
            Visit &nbsp;
            <Link
              to={`${Routes.SITES}/${siteId}${Routes.NETWORK_GROUPS}`}
              target="_blank"
              className={styles.link}
            >
              Network Groups
            </Link>
            &nbsp; to update the edge device
          </div>
        </div>
      }
    >
      {title}
    </Tooltip>
  );
};

// Merging all network groups accociated with all sops existing in this site.

export const useGetNetworkGroupArray = (hvacSops: Sop[], orgId?: number) => {
  const Sops = hvacSops?.map(sop => sop.id);

  const networkGroup1 = useGetNetworkGroupsBySopId(Sops?.[0], orgId);
  const networkGroup2 = useGetNetworkGroupsBySopId(Sops?.[1], orgId);
  const networkGroup3 = useGetNetworkGroupsBySopId(Sops?.[2], orgId);

  const array = [
    ...(networkGroup1 || []),
    ...(networkGroup2 || []),
    ...(networkGroup3 || []),
  ];

  const uniqueIds: number[] = [];

  const networkGroupArray = array.filter(networkGroup => {
    const isDuplicate = uniqueIds.includes(networkGroup.id);

    if (!isDuplicate) {
      uniqueIds.push(networkGroup.id);
      return true;
    }
    return false;
  });

  return networkGroupArray;
};

export default LastConfigUpdateTooltip;
