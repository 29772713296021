import { EnergyPro } from '@energybox/react-ui-library/dist/types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';
import { EnergyProsById } from '../../reducers/energy_pros';
import styles from './DistributionPanelOnlineState.module.css';

export const IGNORE_DEFAULT_TIMESTAMPS = [
  '1970-01-01T00:00:00Z',
  '1970-01-01T00:00:00.000Z',
  '1970-01-01T00:00:00',
];

enum PanelOnlineStatus {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
  PARTIALLY_OFFLINE = 'Partially Offline',
  NO_STATUS = '-',
}

type Props = {
  energyProIds: string[];
};

const DistributionPanelOnlineState: React.FC<Props> = ({ energyProIds }) => {
  //** Local Custom Hooks **/
  const energyProsById = useEnergyProsById();
  const energyProsList = useEnergyProsList(energyProIds, energyProsById);
  const deviceStatusById = useDeviceStatusById();
  const streamConnected = useStreamConnected();

  // ********* useDispatch ********* //
  const dispatch = useDispatch();
  const subscribeToEnergyPro = useCallback(
    (ePro: EnergyPro) => {
      dispatch(subscribeToDeviceStatus(ePro.vendor, ePro.uuid, ePro.id));
    },
    [dispatch]
  );

  const unsubscribeFromEnergyPro = useCallback(
    (ePro: EnergyPro) => {
      dispatch(unsubscribeFromDeviceStatus(ePro.vendor, ePro.uuid, ePro.id));
    },
    [dispatch]
  );

  // ********* useEffect ********* //
  useEffect(() => {
    if (streamConnected) {
      energyProsList.forEach(ePro => {
        subscribeToEnergyPro(ePro);
      });

      return () => {
        energyProsList.forEach(ePro => {
          unsubscribeFromEnergyPro(ePro);
        });
      };
    }
  }, [
    energyProsList,
    subscribeToEnergyPro,
    unsubscribeFromEnergyPro,
    streamConnected,
  ]);

  // ****************************** //
  const onlineState = (() => {
    const isOnline = (isOnline: boolean | undefined) => isOnline;
    const isOffline = (isOnline: boolean | undefined) => !isOnline;
    const isNotPresent = (isOnline: boolean | undefined) =>
      isOnline === undefined;

    const deviceStatuses = energyProIds.map(eProId => {
      return deviceStatusById[eProId] || {};
    });
    const onlineStates = deviceStatuses.map(device => {
      if (
        device.timestamp === undefined ||
        IGNORE_DEFAULT_TIMESTAMPS.indexOf(device.timestamp) > -1
      )
        return undefined;
      else return !!device.onlineState;
    });
    if (onlineStates.every(isNotPresent)) {
      return 'NO_STATUS';
    } else if (onlineStates.every(isOnline)) {
      return 'ONLINE';
    } else if (onlineStates.every(isOffline)) {
      return 'OFFLINE';
    } else {
      return 'PARTIALLY_OFFLINE';
    }
  })();

  return (
    <div className={`${styles.root} ${styles[onlineState]}`}>
      {PanelOnlineStatus[onlineState]}
    </div>
  );
};

const useEnergyProsById = () => {
  return useSelector(({ energyPros }: ApplicationState) => {
    return energyPros.energyProsById;
  }, shallowEqual);
};

const useEnergyProsList = (
  energyProIds: string[],
  energyProsById: EnergyProsById
) => {
  return useMemo(() => {
    return energyProIds.map(eProId => energyProsById[eProId]);
  }, [energyProIds, energyProsById]);
};

export const useDeviceStatusById = () => {
  return useSelector(({ deviceStatusById }: ApplicationState) => {
    return deviceStatusById;
  });
};

const useStreamConnected = () => {
  return useSelector(({ app }: ApplicationState) => {
    return app.streamConnected;
  });
};

export default DistributionPanelOnlineState;
