import {
  EnergyPowerApiResponse,
  EnergyDashboard,
  ZippedTSEnergySeriesData,
} from '@energybox/react-ui-library/dist/types';
import {
  normalizeTSEnergyByEquipmentId,
  normalizeTSEnergyDashboardBySiteId,
  zipTSApiData,
} from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions as EnergyActions } from '../actions/energy';
import { Actions as TSEnergyActions } from '../actions/energyTS';

export type Energy = {
  energyByEquipmentId: EnergyByEquipmentIdResponse;
  isLoadingByResourceId: IsLoadingByResourceId;
  energyDashboardBySiteId: EnergyDashboardBySiteId;
  energyBySensorId: EnergyBySensorIdResponse;
};

export type EnergyDashboardBySiteId = {
  [siteId: string]: EnergyDashboardStatus;
};

export type EnergyDashboardStatus = {
  data?: EnergyDashboard;
  isLoading: boolean;
};

export type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

export type EnergyByEquipmentIdResponse = {
  [equipmentId: string]: EnergyPowerApiResponse;
};

export type EnergyBySensorIdResponse = {
  [equipmentId: string]: ZippedTSEnergySeriesData[];
};

const initialState: Energy = {
  energyByEquipmentId: {},
  energyDashboardBySiteId: {},
  isLoadingByResourceId: {},
  energyBySensorId: {},
};

export default (state: Energy = initialState, action: any) => {
  switch (action.type) {
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(['energyByEquipmentId', action.id], action.data),
        assocPath(['isLoadingByResourceId', action.id], false)
      )(state);

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyByEquipmentId', action.id],
          normalizeTSEnergyByEquipmentId(action.data)
        ),
        assocPath(['isLoadingByResourceId', action.id], false)
      )(state);

    case TSEnergyActions.TS_GET_ENERGY_BY_SENSOR_ID_LOADING:
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_LOADING:
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_LOADING:
      return pipe(assocPath(['isLoadingByResourceId', action.id], true))(state);

    case TSEnergyActions.TS_GET_ENERGY_BY_SENSOR_ID_ERROR:
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_ERROR:
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_ERROR:
      return assocPath(['isLoadingByResourceId', action.id], false, state);

    case TSEnergyActions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING:
    case EnergyActions.GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING:
      return assocPath(
        ['energyDashboardBySiteId', action.siteId, 'isLoading'],
        true,
        state
      );

    case EnergyActions.GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'data'],
          action.data
        ),
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);

    case TSEnergyActions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'data'],
          normalizeTSEnergyDashboardBySiteId(action.data)
        ),
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);

    case TSEnergyActions.TS_GET_ENERGY_BY_SENSOR_ID_SUCCESS:
      return pipe(
        assocPath(['energyBySensorId', action.id], zipTSApiData(action.data)),
        assocPath(['isLoadingByResourceId', action.id], false)
      )(state);

    default:
      return state;
  }
};
