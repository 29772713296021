import { Thermostat } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceReadings,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';
import { DeviceStatusById } from '../../reducers/deviceStatus';

const useSubscribeToStreamEntities = (thermostat: Thermostat) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let unsubscribe: (() => void) | null = null;

    if (isDefined(thermostat)) {
      dispatch(
        subscribeToDeviceReadings(
          thermostat?.vendor,
          thermostat?.uuid,
          thermostat?.id
        )
      );
      dispatch(
        subscribeToDeviceStatus(
          thermostat?.vendor,
          thermostat?.uuid,
          thermostat?.id
        )
      );
    }
    unsubscribe = () => {
      dispatch(
        unsubscribeFromDeviceReadings(
          thermostat?.vendor,
          thermostat?.uuid,
          thermostat?.id
        )
      );
      dispatch(
        unsubscribeFromDeviceStatus(
          thermostat?.vendor,
          thermostat?.uuid,
          thermostat?.id
        )
      );
    };

    return () => {
      if (isDefined(unsubscribe)) {
        unsubscribe();
      }
    };
  }, []);

  const subscribedThermostat = useSelector(
    ({ subscribedThermostats }: ApplicationState) => {
      if (thermostat) {
        return subscribedThermostats.byId[thermostat.id];
      }
      return undefined;
    }
  );

  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);

  return { subscribedThermostat, deviceStatusById };
};
export default useSubscribeToStreamEntities;
