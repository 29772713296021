import * as R from 'ramda';

import { Actions as StreamActions, GatewayAction } from '../actions/streamApi';

import { Status } from '../types/status';

export type ScannedSensor = {
  id: string;
  uuid: string;
  vendor: string;
};

export type ScannedSensorsByGatewayId = {
  [id: string]: ScannedSensor[];
};

export type ScannedSensors = {
  scannedSensorsByGatewayId: ScannedSensorsByGatewayId;
  status: Status;
};

const initialState = {
  scannedSensorsByGatewayId: {},
  status: Status.INIT,
};

const scannedSensorsByGatewayId = (state = initialState, action: any) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      if (action.data && action.data.deviceType === 'gateway') {
        if (action.data.scannedSensors) {
          return R.pipe(
            R.assocPath(
              ['scannedSensorsByGatewayId', action.data.id.toString()],
              action.data.scannedSensors
            ),
            R.assoc('status', Status.SUCCESS)
          )(state);
        } else {
          // this is a temporary hack so that the white card list
          // doesn't get stuck in loading mode
          // context: there are situations where streamAPI doesn't
          // return any scanned sensors data even when we request it
          return R.assoc('status', Status.SUCCESS, state);
        }
      }

      return state;
    }

    case StreamActions.SEND_MESSAGE: {
      if (action.data.action === GatewayAction.EMIT_SCANNED_SENSOR_LIST) {
        return {
          ...state,
          status: Status.LOADING,
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default scannedSensorsByGatewayId;
