import {
  Gateway,
  SentinelTarget,
  SentinelTargetType,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { classNames, isDefined } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { fetchGateways } from '../../../../actions/gatewaysFetch';
import RolloutSelector from './RolloutSelector';
import styles from './StepRollout.module.css';
import { filterControlBoardsByHardwareVersion } from './../StepRollout/Util';

type Props = {
  site: Site;
  handleTargetsSelect: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (sentinelTargetIds: number[]) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  isCollapsed: boolean;
  filterByEntityType?: 'ControlBoard';
  filterByDeviceTypes: string[];
  updateEquipmentList: (list: any) => void;
  upDatedSentielId: (sentinelId: any) => void;
};

type State = {
  gateways: Gateway[];
  isLoading: boolean;
};

class StepRolloutGatewaysHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gateways: [],
      isLoading: true,
    };
  }

  async componentDidUpdate(prevProps) {
    // Compare the previous props with the current props
    if (prevProps.filterByDeviceTypes !== this.props.filterByDeviceTypes) {
      // Logic to execute when actionTrigger changes
      const gateways = await fetchGateways({
        siteIds: [this.props.site.id],
      });
      let filteredGateways = gateways;
      if (isDefined(this.props.filterByEntityType)) {
        filteredGateways = gateways.filter(
          gateway => gateway._entity === this.props.filterByEntityType
        );
        if (this.props.filterByEntityType === 'ControlBoard') {
          filteredGateways =
            filterControlBoardsByHardwareVersion(
              this.props.filterByDeviceTypes,
              filteredGateways
            ) || filteredGateways;
        }
      }
      this.setState({ gateways: filteredGateways });
    }
  }

  componentDidMount(): void {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const {
      handleSetResourceRelation,
      filterByEntityType,
      filterByDeviceTypes,
    } = this.props;

    try {
      const { site } = this.props;

      const gateways = await fetchGateways({
        siteIds: [site.id],
      });

      let filteredGateways = gateways;

      if (
        isDefined(filterByEntityType) &&
        isDefined(this.props.filterByDeviceTypes)
      ) {
        filteredGateways = gateways.filter(
          gateway => gateway._entity === filterByEntityType
        );
        if (filterByEntityType === 'ControlBoard') {
          filteredGateways =
            filterControlBoardsByHardwareVersion(
              this.props.filterByDeviceTypes,
              filteredGateways
            ) || filteredGateways;
        }
      }

      handleSetResourceRelation(
        site.id,
        filteredGateways.map((g: Gateway) => g.id)
      );

      const selectedBySite = gateways
        ? gateways.reduce(
            (sum, item) =>
              this.props.selected.includes(item.id) ? sum + 1 : sum,
            0
          )
        : 0;

      const sentinelTargetTypeID = SentinelTargetType.GATEWAY;

      this.props.updateEquipmentList(gateways);
      this.props.upDatedSentielId(sentinelTargetTypeID);

      this.setState({
        gateways: filteredGateways,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { gateways = [], isLoading } = this.state;
    const {
      selected,
      isCollapsed,
      handleTargetsSelect,
      handleTargetDelete,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedBySite = gateways
      ? gateways.reduce(
          (sum, item) => (selected.includes(item.id) ? sum + 1 : sum),
          0
        )
      : 0;
    const total = gateways.length;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Gateways</th>
              <th style={{ width: '25%' }}>{isCollapsed && 'Location'}</th>
              <th
                style={{ width: '15%' }}
                className={classNames(styles.actionHeader, styles.action)}
              >
                <RolloutSelector
                  isCollapsed={isCollapsed}
                  selected={selectedBySite}
                  totalSelected={total}
                  onDelete={() =>
                    handleTargetDelete(gateways.map(item => item.id))
                  }
                  onSelect={() =>
                    handleTargetsSelect(
                      gateways.map(item => ({
                        targetId: item.id,
                        targetType: SentinelTargetType.GATEWAY,
                      }))
                    )
                  }
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

export default StepRolloutGatewaysHeader;
