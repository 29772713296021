import {
  Button,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
  SearchBox,
  SkeletonDisplayText,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListGroupHeader,
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  CurrentUser,
  DoorOpenMaxDuration,
  EnergyTariff,
  Height,
  HumidityRange,
  LaborRate,
  Length,
  MaintenanceVisitRate,
  NormalPowerConsumption,
  ProductValue,
  Role,
  Sop,
  SopCostTypes,
  SopPolicyTypes,
  SopTypeCategory,
  SopTypes,
  SopTypeToLabel,
  SortDirection,
  TemperatureRange,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  global,
  hasSubstr,
  SORT_IGNORED_VALUES,
  createTemperatureString,
} from '@energybox/react-ui-library/dist/utils';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import React from 'react';
import { connect } from 'react-redux';
import { getOrganizations } from '../../../actions/organizations';
import {
  destroy as deleteSop,
  getOrgSopAppliedCounts,
  getSopByOrganizationalUnitId,
  reset as resetSop,
  showNewOrEditCostSopModal,
  showNewOrEditPolicySopModal,
} from '../../../actions/sops';
import { showNewModal as showNewTimeTableModal } from '../../../actions/time_tables';
import { showNewModal as showNewOrEditTaskSopModal } from '../../../actions/taskSop';
import IndexManagementPageLayout from '../../../components/IndexManagementPageLayout';
import TabLink from '../../../components/TabLink';
import history from '../../../history';
import { ApplicationState } from '../../../reducers';
import { OrgSopAppliedCountsBySopId } from '../../../reducers/sop';
import { Routes } from '../../../routes';
import { NewButtonText, Placeholder } from '../../../types/global';
import { OrgSopAppliedCountType } from '../../../types/sop';
import { checkCommonPlural } from '../../../util';
import { formatDecimalValue } from '../../../utils/numbers';
import NewTimeTableModal from '../../TimeTables/NewTimeTableModal';
import TimeTablesTable from '../../TimeTables/TimeTablesTable';
import NewOrEditSopModal from '../NewOrEditSopModal';
import SopUnit from '../SopUnit';
import theme from '../../../theme';
import {
  PageContentHeader,
  FixedContentWrapper,
} from '../../../components/Page';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import ShowOrgLevelHVACSopsPage from '../ShowOrgLevelHVACSopsPage';
import styles from './ShowOrganizationSopsPage.module.css';
import ShowOrgLevelTaskSopsPage from '../ShowOrgLevelTaskSopsPage';

interface OwnProps {
  orgId: string;
}

interface State {
  query: string;
  showSopDeleteModal: boolean;
  sopStagedForDelete: number;
  sopId: string | number;
}

interface Props extends OwnProps {
  user?: CurrentUser;
  isLoading: boolean;
  isOrgSopCountsLoading: boolean;
  sops: Sop[];
  load: () => void;
  showNewOrEditPolicyModal: () => void;
  showNewOrEditCostModal: () => void;
  showNewTimeTableModal: () => void;
  showNewOrEditTaskModal: () => void;
  deleteSop: (id: string | number) => void;
  resetSop: (id: string | number) => void;
  orgSopAppliedCountsBySopId: OrgSopAppliedCountsBySopId;
  organizationName: string;
  viewportType: ViewportTypes;
}

class ShowOrganizationSopsPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      showSopDeleteModal: false,
      sopStagedForDelete: -1,
      sopId: 'new',
    };
  }

  componentDidMount() {
    this.props.load();
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value });
  };

  handleDeleteSop = (id: number) => {
    this.setState({
      sopStagedForDelete: id,
      showSopDeleteModal: true,
    });
  };

  handleEditSop = (id: number) => {
    this.setState({
      sopId: String(id),
    });
    this.showSopModal();
  };

  handleNewSop = () => {
    this.setState({
      sopId: 'new',
    });
    this.showSopModal();
  };

  showSopModal = () => {
    const {
      showNewOrEditPolicyModal,
      showNewOrEditCostModal,
      showNewOrEditTaskModal,
    } = this.props;
    const sopTypeCategory = this.getSopTypeCategory();
    if (sopTypeCategory === 'cost') {
      showNewOrEditCostModal();
    } else if (sopTypeCategory === 'task') {
      showNewOrEditTaskModal();
    } else {
      showNewOrEditPolicyModal();
    }
  };

  handleResetEditSop = () => {
    const { resetSop } = this.props;
    const { sopId } = this.state;
    resetSop(sopId);
  };

  handleCloseSopDeletePrompt = () => {
    this.setState({
      sopStagedForDelete: -1,
      showSopDeleteModal: false,
    });
  };

  getSopTypeCategory = () => {
    const pathname = history?.location?.pathname || '';
    switch (`/${pathname.split('/')[2]}`) {
      case Routes.HVAC:
        return 'hvac' as SopTypeCategory;

      case Routes.COSTS:
        return 'cost' as SopTypeCategory;

      case Routes.POLICIES:
        return 'policy' as SopTypeCategory;

      case Routes.TASKS:
        return 'task' as SopTypeCategory;

      default:
        break;
    }
  };

  sopDeleteModal = () => {
    const { deleteSop } = this.props;
    const { sopStagedForDelete } = this.state;
    const actions = (
      <>
        <Button variant="text" onClick={this.handleCloseSopDeletePrompt}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteSop(sopStagedForDelete);
            this.handleCloseSopDeletePrompt();
          }}
        >
          Delete
        </Button>
      </>
    );

    return (
      <Modal onClose={this.handleCloseSopDeletePrompt} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete this SOP?
        </p>
      </Modal>
    );
  };

  renderNameAndDescription = (sop: Sop) => {
    return (
      <>
        <div className={styles.title}>{sop.title}</div>
        {sop.description && (
          <div className={styles.description}>{sop.description}</div>
        )}
      </>
    );
  };

  renderComponentsType = (sop: Sop) => {
    const { user } = this.props;

    const component = sop.components[0];
    if (!component) return global.NOT_AVAILABLE;
    const componentType = component.type;

    const renderComponentDetail = () => {
      switch (componentType) {
        case SopTypes.TEMPERATURE_RANGE: {
          const tempRangeComponent = component as TemperatureRange;
          return (
            <div>
              Min:{' '}
              {user && createTemperatureString(tempRangeComponent.min, user)} /
              Max:{' '}
              {user && createTemperatureString(tempRangeComponent.max, user)}
            </div>
          );
        }

        case SopTypes.HUMIDITY_RANGE: {
          const humidityComponent = component as HumidityRange;
          return (
            <div>
              <span>Min: {humidityComponent.min}</span>
              <SopUnit component={humidityComponent} />
              <span> / Max: {humidityComponent.max}</span>
              <SopUnit component={humidityComponent} />
            </div>
          );
        }

        case SopTypes.ENERGY_TARIFF: {
          const energyComponent = component as EnergyTariff;
          return (
            <>
              <div>
                <span>
                  Tariff: {formatDecimalValue(energyComponent.tariff)}
                </span>
                <SopUnit component={energyComponent} />
              </div>
              <div>Currency: {energyComponent.currencyCode}</div>
            </>
          );
        }

        case SopTypes.LABOR_RATE: {
          const laborComponent = component as LaborRate;

          return (
            <>
              <div>Value: {formatDecimalValue(laborComponent.value)}</div>
              <div>Currency: {laborComponent.currencyCode}</div>
            </>
          );
        }

        case SopTypes.MAINTENANCE_VISIT_RATE: {
          const maintenanceVisitRate = component as MaintenanceVisitRate;
          return (
            <>
              <div>Value: {formatDecimalValue(maintenanceVisitRate.value)}</div>
              <div>Currency: {maintenanceVisitRate.currencyCode}</div>
            </>
          );
        }

        case SopTypes.DOOR_OPENED_MAX_DURATION: {
          const doorComponent = component as DoorOpenMaxDuration;
          return (
            <>
              <div>
                <span>Duration: {doorComponent.duration} </span>
                <SopUnit component={doorComponent} />
              </div>
            </>
          );
        }

        case SopTypes.NORMAL_POWER_CONSUMPTION: {
          const powerComponent = component as NormalPowerConsumption;
          return (
            <div>
              <span>{`Power Consumption: ${formatDecimalValue(
                powerComponent.powerConsumption,
                1
              )}`}</span>
              <SopUnit component={powerComponent} />
            </div>
          );
        }

        case SopTypes.PRODUCT_VALUE: {
          const productValueComponent = component as ProductValue;

          return (
            <>
              <div>
                Value: {formatDecimalValue(productValueComponent.value)}
              </div>
              <div>Currency: {productValueComponent.currencyCode}</div>
            </>
          );
        }

        default: {
          return <div>{global.NOT_AVAILABLE}</div>;
        }
      }
    };

    return (
      <>
        <div className={styles.componentType}>
          {SopTypeToLabel[component.type] || global.NOT_AVAILABLE}
        </div>
        <div>{renderComponentDetail()}</div>
      </>
    );
  };

  renderSopAttachmentInfo = (sop: Sop) => {
    const {
      isOrgSopCountsLoading,
      orgSopAppliedCountsBySopId,
      organizationName,
    } = this.props;
    const appliedCounts = orgSopAppliedCountsBySopId[sop.id];
    const isWithEquipmentTypes = sop.equipmentTypes.length > 0;
    const isSiteCountType =
      appliedCounts?.countType === OrgSopAppliedCountType.SITE;

    if (isSiteCountType || (!appliedCounts && !isWithEquipmentTypes)) {
      return (
        <>
          <Cell width="2">
            {(isSiteCountType && organizationName) || global.NOT_AVAILABLE}
          </Cell>
          <Cell width="1" cellAlign="center" />
          <Cell width="1" cellAlign="center" />
          <Cell width="1" cellAlign="center">
            {isOrgSopCountsLoading ? (
              <SkeletonDisplayText
                height={Height.EXTRA_SMALL}
                possibleLengths={[Length.EXTRA_SHORT]}
              />
            ) : (
              appliedCounts?.siteCount?.generic?.length || global.NOT_AVAILABLE
            )}
          </Cell>
          <Cell width="1" cellAlign="center">
            {isOrgSopCountsLoading ? (
              <SkeletonDisplayText
                height={Height.EXTRA_SMALL}
                possibleLengths={[Length.EXTRA_SHORT]}
              />
            ) : (
              appliedCounts?.siteCount?.localised?.length ||
              global.NOT_AVAILABLE
            )}
          </Cell>
        </>
      );
    }

    return (
      <>
        {sop.equipmentTypes.map(eqType => {
          const { equipmentAppliedTo, sitesAppliedTo } =
            appliedCounts?.equipmentCount || {};
          return (
            <>
              <Cell key={`${eqType.id}-title`} width="2">
                {eqType.title}
              </Cell>
              <Cell key={`${eqType.id}-eq-org`} width="1" cellAlign="center">
                {isOrgSopCountsLoading ? (
                  <SkeletonDisplayText
                    height={Height.EXTRA_SMALL}
                    possibleLengths={[Length.EXTRA_SHORT]}
                  />
                ) : (
                  equipmentAppliedTo?.generic.filter(
                    item => item.equipmentTypeId === eqType.id
                  ).length
                )}
              </Cell>
              <Cell key={`${eqType.id}-eq-site`} width="1" cellAlign="center">
                {isOrgSopCountsLoading ? (
                  <SkeletonDisplayText
                    height={Height.EXTRA_SMALL}
                    possibleLengths={[Length.EXTRA_SHORT]}
                  />
                ) : (
                  equipmentAppliedTo?.localised.filter(
                    item => item.equipmentTypeId === eqType.id
                  ).length
                )}
              </Cell>
              <Cell
                key={`${eqType.id}-site-org`}
                width="1"
                cellAlign="center"
              />
              <Cell
                key={`${eqType.id}-site-site`}
                width="1"
                cellAlign="center"
              />
            </>
          );
        })}
      </>
    );
  };

  renderSopAttachmentInfoHeader = () => {
    return (
      <div className={styles.sopAttachmentInfoHeader}>
        <Cell width="2">Attached To</Cell>
        {this.renderCountColumnHeader()}
        {this.renderCountColumnHeader()}
      </div>
    );
  };

  renderCountColumnHeader = () => (
    <Cell width="2" className={styles.sopCountHeader}>
      <div className={styles.countSubtitlesWrapper}>
        <div className={styles.countSubtitles}>Org SOP</div>
        <div className={styles.countSubtitles}>Site SOP</div>
      </div>
    </Cell>
  );

  render() {
    const { showSopDeleteModal, sopId, query } = this.state;

    const {
      sops,
      orgId,
      user,
      isLoading,
      showNewTimeTableModal,
      viewportType,
    } = this.props;
    const isMobile = viewportType === ViewportTypes.MOBILE;

    const sopsFilteredByQuery =
      query && query.length >= 3
        ? sops.filter(sop => hasSubstr(sop.title, query))
        : sops;
    const sopTypeCategory = this.getSopTypeCategory();
    const filteredSops = sopsFilteredByQuery.filter(sop => {
      const sopEntityTypes =
        sopTypeCategory === 'cost' ? SopCostTypes : SopPolicyTypes;
      return sop && sop.components[0] !== undefined
        ? Object.values(sopEntityTypes).includes(sop.components[0].type)
        : '';
    });
    const pathname = history?.location?.pathname || '';
    const isTimetableTabActive = pathname.match(Routes.TIME_TABLES);
    const isHVACActive = pathname.match(Routes.HVAC);
    const isTaskActive = pathname.match(Routes.TASKS);
    const ENERGYBOX_ORG_ID = Number(process.env.REACT_APP_ENERGYBOX_ORG);
    const hasAccess = user?.accessResources?.reduce(
      (hasAccess, accessResource) => {
        return (
          hasAccess ||
          ((accessResource.resourceId === +orgId ||
            accessResource.resourceId === ENERGYBOX_ORG_ID) &&
            accessResource.role !== Role.VIEWER &&
            accessResource.role !== Role.MANAGER &&
            accessResource.role !== Role.INSTALLER)
        );
      },
      false
    );

    const groupHeaders: CardListGroupHeader[] = [
      {
        width: '8',
        content: '',
      },
      {
        width: '2',
        content: 'Equipment',
        className: styles.groupHeader,
      },
      {
        width: '2',
        content: 'Site',
        className: styles.groupHeader,
      },
      {
        width: '1',
        content: '',
      },
    ];

    const cardListHeader: CardListHeader[] = [
      {
        width: '4',
        content: 'Name/Description',
        comparator: (a: Sop, b: Sop, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '2',
        content: sopTypeCategory === 'cost' ? 'Cost Type' : 'Policy Type',
        comparator: (a: Sop, b: Sop, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'components',
            0,
            'type',
          ]);
        },
      },
      {
        width: '6',
        content: this.renderSopAttachmentInfoHeader(),
        cellClassName: styles.sopAttachmentInfoHeaderCell,
      },
      {
        width: '1',
        align: 'right',
        content: '',
      },
    ];

    const data: CardListRowData[] = filteredSops.map(sop => ({
      key: sop.id.toString(),
      dataObject: sop,
      content: (
        <>
          <Cell width="4">{this.renderNameAndDescription(sop)}</Cell>
          <Cell width="2">{this.renderComponentsType(sop)}</Cell>
          <Cell width="6" className={styles.sopAttachmentInfo}>
            {this.renderSopAttachmentInfo(sop)}
          </Cell>
          <Cell
            width="1"
            cellAlign="right"
            className={styles.dropdownContainer}
          >
            {hasAccess ? (
              <MenuDropdown>
                <MenuDropdownItem onSelect={() => this.handleEditSop(sop.id)}>
                  Edit
                </MenuDropdownItem>
                <MenuDropdownItem
                  isRed
                  onSelect={() => this.handleDeleteSop(sop.id)}
                >
                  Delete
                </MenuDropdownItem>
              </MenuDropdown>
            ) : (
              <></>
            )}
          </Cell>
        </>
      ),
    }));

    const createPageAction = () => {
      let onClickAction;
      let actionText;
      if (hasAccess) {
        if (isTimetableTabActive) {
          onClickAction = showNewTimeTableModal;
          actionText = NewButtonText.TIME_TABLE;
        } else if (!isHVACActive && !isTaskActive) {
          onClickAction = this.handleNewSop;
          actionText = NewButtonText.SOP_POLICY;
          if (sopTypeCategory === 'cost') {
            actionText = NewButtonText.SOP_COST;
          }
        } else if (isTaskActive && !isHVACActive) {
          onClickAction = this.handleNewSop;
          actionText = NewButtonText.SOP_TASK;
        } else {
          return null;
        }
        return <Button onClick={onClickAction}>{actionText}</Button>;
      } else {
        return null;
      }
    };

    return (
      <>
        <IndexManagementPageLayout
          id="SOPContainer"
          actions={createPageAction()}
          tabs={
            <>
              <TabLink to={`${Routes.SOPS}${Routes.POLICIES}`}>
                Policies
              </TabLink>
              <TabLink to={`${Routes.SOPS}${Routes.COSTS}`}>Cost</TabLink>
              <TabLink to={`${Routes.SOPS}${Routes.TIME_TABLES}`}>
                Timetables
              </TabLink>
              <TabLink to={`${Routes.SOPS}${Routes.HVAC}`}>HVAC</TabLink>
              <TabLink to={`${Routes.SOPS}${Routes.TASKS}`}>Task</TabLink>
            </>
          }
        >
          {isTimetableTabActive && (
            <>
              <NewTimeTableModal organizationUnitId={orgId} />
              <TimeTablesTable organizationUnitId={orgId} isOrgLevel={true} />
            </>
          )}
          {!isTimetableTabActive && !isHVACActive && !isTaskActive && (
            <FixedContentWrapper>
              <PageContentHeader
                header={checkCommonPlural('Sop', filteredSops.length)}
                isLoading={isLoading}
              >
                <SearchBox
                  placeholder={Placeholder.seachBox}
                  onChange={this.handleSearchChange}
                  query={query}
                  width={
                    isMobile
                      ? theme.size.table.searchBox.mobile
                      : theme.size.table.searchBox.web
                  }
                  widthActive={
                    isMobile
                      ? theme.size.table.searchBox.mobile
                      : theme.size.table.searchBox.web
                  }
                  error={filteredSops.length === 0}
                />
              </PageContentHeader>
              <CardList
                groupHeaders={groupHeaders}
                header={cardListHeader}
                data={data}
                noLeftIconColumn
              />
            </FixedContentWrapper>
          )}
          <>
            {!isTimetableTabActive && isHVACActive && (
              <ShowOrgLevelHVACSopsPage scrollIntoViewProp={'SOPContainer'} />
            )}

            {!!isTaskActive && <ShowOrgLevelTaskSopsPage />}
          </>
        </IndexManagementPageLayout>

        <NewOrEditSopModal
          resetSop={this.handleResetEditSop}
          sopId={String(sopId)}
          setOrgUnitId={Number(orgId)}
          sopTypeCategory={sopTypeCategory}
        />
        {showSopDeleteModal ? this.sopDeleteModal() : ''}
      </>
    );
  }
}

const mapStateToProps = (
  { app, sops, equipment, organizations }: ApplicationState,
  { orgId }: OwnProps
) => ({
  isLoading: sops.isLoading,
  user: app.currentUser,
  sops: (sops.organizationUnitIdToSopIds[orgId] || [])
    .map(id => sops.sopsById[String(id)])
    .filter(s => !!s)
    .filter(s => String(s.organizationUnitId) === orgId),
  orgSopAppliedCountsBySopId: sops.orgSopAppliedCountsBySopId,
  isOrgSopCountsLoading: sops.isOrgSopCountsLoading,
  organizationName:
    organizations.organizationsById[orgId]?.title || 'Organization',
});

const mapDispatchToProps = (dispatch: any, { orgId }: OwnProps) => ({
  load: () => {
    dispatch(getSopByOrganizationalUnitId(orgId));
    dispatch(getOrgSopAppliedCounts(orgId));
    dispatch(getOrganizations());
  },
  showNewOrEditPolicyModal: () => dispatch(showNewOrEditPolicySopModal()),
  showNewOrEditCostModal: () => dispatch(showNewOrEditCostSopModal()),
  showNewTimeTableModal: () => dispatch(showNewTimeTableModal()),
  showNewOrEditTaskModal: () => dispatch(showNewOrEditTaskSopModal()),
  deleteSop: (id: string | number) => dispatch(deleteSop(id)),
  resetSop: (id: string | number) => dispatch(resetSop(id)),
});

export default withViewportType(
  connect(mapStateToProps, mapDispatchToProps)(ShowOrganizationSopsPage)
);
