import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { switchOrganization } from '../actions/app';

interface Props {
  orgId: number;
}

const CrossOrgAdminAccess = ({ orgId }: Props) => {
  const dispatch = useDispatch();
  const currentUrl = window.location.href; // Save the current URL

  useEffect(() => {
    // Dispatch the switchOrganization action with the orgId
    dispatch(switchOrganization(orgId));

    return () => {
      // Restore the previous URL when component unmounts
      window.history.pushState(null, '', currentUrl);
    };
  }, [dispatch, orgId, currentUrl]); // Include dispatch, orgId, and currentUrl in the dependency array

  return <></>;
};

export default CrossOrgAdminAccess;
