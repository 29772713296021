import {
  Button,
  Card,
  CardContent,
  Table,
} from '@energybox/react-ui-library/dist/components';
import { Trash } from '@energybox/react-ui-library/dist/icons';
import {
  ApiKey,
  NewKeyResponse,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions,
  addApiTokenToUser,
  getUserApiTokens,
  hideApiKeyCreatedModal,
  hideApiKeyDeleteModal,
  hideNewApiKeyModal,
  removeApiTokenFromUser,
  showApiKeyDeleteModal,
  showNewApiKeyModal,
  updateApiKeyField,
} from '../../../actions/users';
import DeleteModal from '../../../components/Modals/DeleteModal';
import { ApplicationState } from '../../../reducers';
import { EditApiKey, EditApiKeyById } from '../../../reducers/users';
import { CreateNewText } from '../../../types/global';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import UserApiNewTokenModal from './UserApiNewTokenModal';
import UserApiTokenCreatedModal from './UserApiTokenCreatedModal';
import styles from './UserApiToken.module.css';

interface OwnProps {
  userId: number;
}

interface Props extends OwnProps {
  showingDeleteApiKeyModal: boolean;
  newApiKey?: EditApiKey;
  editApiKeyById: EditApiKeyById;
  onDelete: (number) => void;
  apiKeys: ApiKey[];
  newKeyResponse?: NewKeyResponse;
  showingNewApiKeyModal: boolean;
  showNewApiKeyModal: () => void;
  showApiKeyCreatedModal: boolean;
  hideNewApiKeyModal: () => void;
  hideApiKeyCreatedModal: () => void;
  addApiTokenToUser: () => void;
  getApiKeys: () => void;
  updateField: typeof updateApiKeyField;
  showApiKeyDeleteModal: typeof showApiKeyDeleteModal;
  hideApiKeyDeleteModal: typeof hideApiKeyDeleteModal;
}

interface State {
  primeForDeleteId: number;
}

class UserApiToken extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      primeForDeleteId: -1,
    };
  }

  componentDidMount() {
    this.props.getApiKeys();
  }

  handleCloseCreatedApiTokenModal = () => {};

  handleCloseDeletePrompt = () => {
    this.setState({ primeForDeleteId: -1 });
    this.props.hideApiKeyDeleteModal();
  };

  onTrashClick = (id: number) => {
    this.setState({ primeForDeleteId: id });
    this.props.showApiKeyDeleteModal();
  };

  onDeleteConfirm = () => {
    this.props.onDelete(this.state.primeForDeleteId);
  };
  render() {
    const { primeForDeleteId } = this.state;
    const {
      apiKeys,
      newApiKey,
      newKeyResponse,
      showApiKeyCreatedModal,
      updateField,
      showingNewApiKeyModal,
      showNewApiKeyModal,
      hideNewApiKeyModal,
      hideApiKeyCreatedModal,
      showingDeleteApiKeyModal,
      addApiTokenToUser,
      editApiKeyById,
    } = this.props;

    const editApiKey = editApiKeyById[primeForDeleteId.toString()];

    const tableColumns = [
      {
        header: 'Name',
        cellContent: ({ title }) => <span>{title}</span>,
        comparator: (a: ApiKey, b: ApiKey, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Description',
        cellContent: ({ description }) => <span>{description}</span>,
        comparator: (a: ApiKey, b: ApiKey, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'description',
          ]);
        },
      },
      // {
      //   header: 'Read-only',
      //   comparator: 'readOnly',
      //   cellContent: ({ id, readOnly }) => (
      //     <Checkbox
      //       id={id}
      //       name="read-only"
      //       disabled={true}
      //       onChange={() => {}}
      //       checked={readOnly}
      //     />
      //   ),
      // },
      {
        header: '',
        cellContent: tokenObject => (
          <Trash
            color={'var(--pink-base)'}
            onClick={this.onTrashClick.bind(this, tokenObject.id)}
          />
        ),
      },
    ];

    return (
      <Card>
        <CardContent>
          <div className={styles.header}>
            <h4 className="m0">API Keys</h4>
            <Button onClick={showNewApiKeyModal}>
              {' '}
              {CreateNewText.API_KEY}
            </Button>
          </div>

          <div style={{ marginTop: '20px' }}>
            <Table columns={tableColumns} data={apiKeys} />
          </div>
        </CardContent>
        {showingNewApiKeyModal && newApiKey && (
          <UserApiNewTokenModal
            onChange={(field, value) => updateField('new', field, value)}
            onClose={hideNewApiKeyModal}
            onSubmit={addApiTokenToUser}
            formErrors={newApiKey.formErrors}
            formErrorsVisible={newApiKey.formErrorsVisible}
            apiError={newApiKey.apiError}
            {...newApiKey.fields}
          />
        )}
        {newKeyResponse && showApiKeyCreatedModal && (
          <UserApiTokenCreatedModal
            apiKey={newKeyResponse.apikey}
            title={newKeyResponse.title}
            onClose={hideApiKeyCreatedModal}
          />
        )}
        {showingDeleteApiKeyModal && primeForDeleteId > -1 && (
          <DeleteModal
            onDelete={this.onDeleteConfirm}
            onClose={this.handleCloseDeletePrompt}
            apiError={
              editApiKey &&
              renderAPIerror(
                editApiKey.apiError,
                Actions.REMOVE_API_KEY_FROM_USER_ERROR
              )
            }
            title={
              editApiKey && editApiKey.fields ? editApiKey.fields.title : ''
            }
          />
        )}
      </Card>
    );
  }
}

const mapStateToProps = (
  { users }: ApplicationState,
  { userId }: OwnProps
) => ({
  apiKeys: (users.apiKeyIdsByUserId[userId.toString()] || [])
    .map(id => users.apiKeysById[id.toString()])
    .filter(e => e !== undefined && e !== null),
  editApiKeyById: users.editApiKeyById,
  newApiKey: users.editApiKeyById['new'],
  newKeyResponse: users.temporaryNewKeyStore,
  showApiKeyCreatedModal: users.showApiKeyCreatedModal,
  showingNewApiKeyModal: users.showNewApiKeyModal,
  showingDeleteApiKeyModal: users.showDeleteApiKeyModal,
});

const mapDispatchToProps = (dispatch: any, { userId }: OwnProps) => ({
  onDelete: (id: number) => dispatch(removeApiTokenFromUser(userId, id)),
  getApiKeys: () => dispatch(getUserApiTokens(userId)),
  addApiTokenToUser: () => dispatch(addApiTokenToUser(userId)),
  showNewApiKeyModal: () => dispatch(showNewApiKeyModal()),
  showApiKeyDeleteModal: () => dispatch(showApiKeyDeleteModal()),
  hideApiKeyDeleteModal: () => dispatch(hideApiKeyDeleteModal()),
  hideNewApiKeyModal: () => dispatch(hideNewApiKeyModal()),
  hideApiKeyCreatedModal: () => dispatch(hideApiKeyCreatedModal()),
  updateField: (id, field, value) =>
    dispatch(updateApiKeyField(id, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserApiToken);
