import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import { App } from '../reducers/app';
export const featureFlagOrganizationId = parseInt(
  process.env.REACT_APP_ENERGYBOX_ORG || '275',
  10
);

export const canAccessFeatures = (
  app: App,
  orgWhiteList?: number[],
  organizationType?: OrganizationType
) => {
  const orgType = app.currentUser && app.currentUser.organizationType;
  const orgId =
    (app.currentUser && app.currentUser.organizationId) ??
    app.currentOrganizationId;

  const isWhiteListedOrgInProps =
    (orgId && (orgWhiteList || []).includes(orgId)) ||
    (organizationType && orgType === organizationType);

  return (
    orgId === featureFlagOrganizationId ||
    isWhiteListedOrgInProps ||
    process.env.NODE_ENV === 'development' ||
    false
  );
};
