import React from 'react';
import { FormText } from '@energybox/react-ui-library/dist/components';

type Props = {
  label: string;
  value?: number;
  isTime?: boolean;
};

export default function({ label, value, isTime = false }: Props) {
  if (!value) {
    return null;
  }

  let displayText = '';
  if (isTime) {
    displayText = `${label} ${value / 60 / 1000} minutes`;
  } else {
    displayText = `${label} ${value}`;
  }

  return <FormText>{displayText}</FormText>;
}
