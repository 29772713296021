import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import {
  IconCheckboxContained,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import { EnergyPro } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import { useCallback, useState } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { patch, updateField } from '../../../actions/circuit_breakers';
import SelectEquipment from '../../Selects/SelectEquipment';
import styles from './EquipmentConfigurationTestResults.module.css';

type Props = {
  index: number;
  result: any;
  energyPro: EnergyPro;
  siteId: number;
};
const ConfigurationTestCircuitResults: React.FC<Props> = ({
  index,
  result,
  energyPro,
  siteId,
}) => {
  const dispatch = useDispatch();
  const [mapEquipment, setMapEquipment] = useState(false);
  const [selectEquipment, setSelectEquipment] = useState(0);
  const onChange = id => {
    setMapEquipment(true);
    setSelectEquipment(id);
  };
  const onBreakerEdit = useCallback(
    (breakerId: string, field: string, value: string) => {
      dispatch(updateField(breakerId, field, value));
    },
    [dispatch]
  );

  const onPatchBreaker = useCallback(
    (breakerId: number) => {
      dispatch(patch(energyPro?.distributionPanelId, breakerId));
    },
    [dispatch]
  );

  const onOpenUpdateBreakerModal = (equipmentId: number, breakerId: number) => {
    onBreakerEdit(String(breakerId), 'equipmentId', String(equipmentId));
    onPatchBreaker(breakerId);
  };
  const testResult = result => {
    if (result === 'pass') {
      return (
        <span className={styles.resultsIcon}>
          <IconCheckboxContained size={20} />
        </span>
      );
    } else {
      return (
        <>
          <span className={styles.paddingRight}>
            <WarningIcon width="18" height="18" />
          </span>
          <span> {result} </span>
        </>
      );
    }
  };
  const warningBorder = result.test_result !== 'pass' ? true : false;
  const current = Math.round(result.current * 100) / 100;
  return (
    <>
      {index !== 0 && <Cell width="3" />}
      <Cell
        width="10"
        className={classNames(
          styles.testResults,
          styles.resultBorder,
          warningBorder ? styles.warningBorder : styles.withoutWarning
        )}
      >
        <Cell width="1">
          <div>{result.port_title}</div>
        </Cell>
        <Cell width="1">
          <div>{current}</div>
        </Cell>
        <Cell width="2">
          <div>{result.breaker_title}</div>
        </Cell>
        <Cell width="3">
          <SelectEquipment
            onSelect={equipmentId => {
              onOpenUpdateBreakerModal(equipmentId, result.breaker_id);
              onChange(equipmentId);
            }}
            value={mapEquipment ? selectEquipment : result.equipment_id}
            siteId={siteId}
            noBottomLine
          />
        </Cell>
        <Cell width="2">
          {mapEquipment ? (
            <div className={styles.results}>
              <span className={styles.paddingRight}>
                <IoIosInformationCircleOutline size={20} />
              </span>
              <span> Changes Made. Run Test </span>
            </div>
          ) : (
            <div className={styles.results}>
              {testResult(result.test_result)}
            </div>
          )}
        </Cell>
      </Cell>
    </>
  );
};

export default ConfigurationTestCircuitResults;
