import { Reminder, Actions } from '../actions/reminder';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import * as R from 'ramda';

const initialState = {
  isInitialized: false,
  reminders: [],
  isLoading: false,
};

export type ReminderState = {
  isInitialized: boolean;
  reminders: Reminder[];
  isLoading: boolean;
};

const reminder = (state: ReminderState = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_REMINDER_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.GET_REMINDER_ERROR:
      return R.assoc('isLoading', false, state);

    case Actions.GET_REMINDER_SUCCESS:
    case Actions.CREATE_REMINDER_SUCCESS:
      return R.pipe(
        R.assoc('reminders', R.uniq([...state.reminders, action.data])),
        R.assoc('isLoading', false)
      )(state);

    case Actions.GET_REMINDERS_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.GET_REMINDERS_ERROR:
      return R.assoc('isLoading', false, state);

    case Actions.GET_REMINDERS_SUCCESS:
      return R.pipe(
        R.assoc('reminders', R.uniq([...state.reminders, ...action.data])),
        R.assoc('isLoading', false),
        R.assoc('isInitialized', true)
      )(state);

    case Actions.CREATE_REMINDER_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.CREATE_REMINDER_ERROR:
      return R.assoc('isLoading', false, state);

    case Actions.UPDATE_REMINDER_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.UPDATE_REMINDER_ERROR:
      return R.assoc('isLoading', false, state);

    case Actions.UPDATE_REMINDER_SUCCESS:
      const newReminders = [
        ...state.reminders.filter(i => i.id !== action.id),
        { ...action.data, id: action.id },
      ];
      return R.pipe(
        R.assoc('reminders', R.uniqBy(R.path(['id']), newReminders)),
        R.assoc('isLoading', false)
      )(state);

    case Actions.DELETE_REMINDER_LOADING:
      return R.assoc('isLoading', true, state);

    case Actions.DELETE_REMINDER_ERROR:
      return R.assoc('isLoading', false, state);

    case Actions.DELETE_REMINDER_SUCCESS:
      return R.pipe(
        R.assoc(
          'reminders',
          state.reminders.filter(i => i.id !== action.id)
        ),
        R.assoc('isLoading', false)
      )(state);

    default:
      return state;
  }
};

export default reminder;
