import { Equipment } from '@energybox/react-ui-library/dist/types';
import SelectTimeTable from '../../Selects/SelectTimeTable';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { useEffect, useState } from 'react';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

type Props = {
  equipment: Equipment;
  siteId: string;
  onChange: onChange;
};

const EditModeSchedulerTimetable: React.FC<Props> = ({
  equipment,
  siteId,
  onChange,
}) => {
  const [tempId, setTempId] = useState('');
  const schedulerId = equipment.activeControl?.id;
  const activeControl = equipment.activeControl;
  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipment.id}`];
  });
  const fields = schedulerControls?.fields;
  const selectedScheduleTimeTableId = fields?.timetableId;
  const selectedLightTimeTableId = fields?.lightSensorSettings?.timetableId;
  const formErrorsVisible = schedulerControls?.formErrorsVisible;
  const formErrors = schedulerControls?.formErrors;

  const isNoScheduleTimetableOptionSelected =
    selectedScheduleTimeTableId === 'null' ||
    selectedScheduleTimeTableId === null;

  const hasAtLeastOneTimetableBeenSelected =
    isDefined(selectedScheduleTimeTableId) ||
    (isDefined(selectedLightTimeTableId) && selectedLightTimeTableId !== -1);

  const hasInvalidScheduleTimetable =
    (!isDefined(selectedScheduleTimeTableId) &&
      !isNoScheduleTimetableOptionSelected) ||
    !hasAtLeastOneTimetableBeenSelected;

  const beginDelta = activeControl?.beginDelta;
  const endDelta = activeControl?.endDelta;
  const lightBeginDelta = beginDelta
    ? Number(activeControl?.beginDelta?.slice(0, -1))
    : 0;
  const lightEndDelta = endDelta
    ? Number(activeControl?.endDelta?.slice(0, -1))
    : 0;

  useEffect(() => {
    if (schedulerId) {
      onChange(
        String(schedulerId),
        'timetableId',
        equipment.activeControl?.timetableId
      );
      onChange(String(schedulerId), 'beginDelta', lightBeginDelta);
      onChange(String(schedulerId), 'endDelta', lightEndDelta);
    }
  }, []);

  useEffect(() => {
    if (tempId) {
      onChange(`new${equipment.id}`, 'beginDelta', lightBeginDelta);
      onChange(`new${equipment.id}`, 'endDelta', lightEndDelta);
    }
  }, [tempId]);

  return (
    <SelectTimeTable
      isOrgLevel={true}
      includeNullSelectOption
      siteId={+siteId}
      value={String(fields?.timetableId)}
      onSelect={(value: string | number) => {
        if (schedulerId) {
          onChange(String(schedulerId), 'timetableId', value);
        } else {
          setTempId(`new${equipment.id}`);
          onChange(`new${equipment.id}`, 'timetableId', value);
        }
      }}
      error={
        formErrorsVisible &&
        (!!formErrors?.timetableId || hasInvalidScheduleTimetable)
      }
      customErrorText={
        hasInvalidScheduleTimetable
          ? 'Invalid Timetable'
          : formErrors?.timetableId && formErrors.timetableId.join(', ')
      }
    />
  );
};

export default EditModeSchedulerTimetable;
