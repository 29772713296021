import { GatewayOffline as GatewayOfflineIcon } from '@energybox/react-ui-library/dist/icons';
import React from 'react';
import styles from './ConditionDoor.module.css';
import StepConditionItem, { StepConditionTitle } from './StepConditionItem';

const ConditionConnectivity: React.FunctionComponent = () => (
  <React.Fragment>
    <StepConditionItem
      label={
        <span className={styles.icon}>
          <GatewayOfflineIcon size={80} />
        </span>
      }
      condition={
        <div>
          <StepConditionTitle>Inactive Device</StepConditionTitle>
          <p>The device may have stopped sending signals</p>
        </div>
      }
      conditionAlignedCenter
    />
  </React.Fragment>
);

export default ConditionConnectivity;
