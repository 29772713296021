import {
  OffsetConfig,
  SensorOffset,
  SensorType,
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  InputField,
  Label,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import {
  parseHysteresisValueForDisplay,
  atMostOneDecimalRegex,
} from '@energybox/react-ui-library/dist/utils';
import convertTemp from '../../../utils/temperature';

import React, { useState } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import ModalFormContent from '../../../components/ModalFormContent';
import styles from './ShowSensorPage.module.css';
import { useCurrentUser } from '../../../hooks/useAppDetails';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';

type Props = {
  onSave: (OffsetConfig) => void;
  initialOffset?: SensorOffset;
  sensorTypes: SensorType[];
};

const UpdateSensorOffsetCard = ({
  onSave,
  initialOffset,
  sensorTypes,
}: Props) => {
  const currentOffset = {
    temperatureOffset:
      initialOffset && initialOffset.temperatureOffset !== null
        ? initialOffset.temperatureOffset
        : undefined,
    humidityOffset:
      initialOffset && initialOffset.humidityOffset !== null
        ? initialOffset.humidityOffset
        : undefined,
  };
  const [offset, setOffset] = useState(currentOffset);
  const currentUser = useCurrentUser();
  if (!currentUser) return null;

  const TEMPERATURE_SYSTEM = getUserPreferenceTemperatureUnit(currentUser);
  const isFahrenheit = TEMPERATURE_SYSTEM === TemperatureUnit.F;

  const OFFSET_MIN_MAX_TEXT = `+/- ${
    isFahrenheit ? 18 : 10
  } ${TEMPERATURE_SYSTEM}`;

  const HUMIDITY_OFFSET_MIN_MAX_TEXT = `+/- 30%`;

  const onClickCancel = () => {
    setOffset(currentOffset);
  };

  const onOffsetChange = (offsetToUpdate: OffsetConfig) => {
    setOffset({
      ...offset,
      ...offsetToUpdate,
    });
  };

  const onTemperatureChange = e => {
    const { value } = e.currentTarget;
    let v;
    if (isNaN(parseFloat(value))) {
      v = value;
    } else {
      v = parseFloat(value);
    }

    if (value.match(atMostOneDecimalRegex) === null) {
      return;
    }
    // Need to compare from 0 in the case of f, otherwise conversion is not correct.
    v = isFahrenheit
      ? Number(convertTemp(v, 'f', 3)) - Number(convertTemp(0, 'f', 3))
      : v;

    if (v === '')
      v = isFahrenheit
        ? Number(convertTemp(v, 'f', 3)) - Number(convertTemp(0, 'f', 3))
        : 0;

    // Restrict to +/- 10 C per harsh
    if (v > 10) v = 10;
    if (v < -10) v = -10;

    onOffsetChange({
      temperatureOffset: Number(v.toFixed(2)),
    });
  };

  const onHumidityChange = e => {
    const { value } = e.currentTarget;
    let v;
    if (isNaN(parseFloat(value))) {
      v = value;
    } else {
      v = parseFloat(value);
    }

    if (v > 30) v = 30;
    if (v < -30) v = -30;

    onOffsetChange({
      humidityOffset: Number(v),
    });
  };

  const isOffsetUpdated =
    currentOffset.temperatureOffset !== offset.temperatureOffset ||
    currentOffset.humidityOffset !== offset.humidityOffset;

  return (
    <Card>
      <CardContent>
        <div className={styles.mainCardGrid}>
          <CardTitle className={styles.mainCardTitle}>Sensor offset</CardTitle>

          <ModalFormContent>
            {sensorTypes.includes(SensorType.TEMPERATURE) && (
              <>
                <div>
                  <Label>Temperature offset</Label>
                </div>
                <div className={styles.offsetInputField}>
                  <InputField
                    className={styles.inputLength}
                    id="temperatureOffset"
                    type="number"
                    name="temperature offset"
                    value={parseHysteresisValueForDisplay(
                      currentUser,
                      offset.temperatureOffset
                    )}
                    onChange={e => onTemperatureChange(e)}
                  />
                  <div>{TEMPERATURE_SYSTEM}</div>
                  <div className={styles.infoPadding}>
                    <Tooltip
                      underline={false}
                      arrowDirection="right"
                      extraClassNames={[styles.marginLeft]}
                      content={
                        <div className={styles.tooltipText}>
                          Accepted Range: {OFFSET_MIN_MAX_TEXT}
                        </div>
                      }
                    >
                      <IoIosInformationCircleOutline size={20} />
                    </Tooltip>
                  </div>
                </div>
              </>
            )}

            {sensorTypes.includes(SensorType.HUMIDITY) && (
              <>
                <div>
                  <Label>Humidity offset</Label>
                </div>
                <div className={styles.offsetInputField}>
                  <InputField
                    id="humidityOffset"
                    type="number"
                    name="humdity offset"
                    value={offset.humidityOffset}
                    min={-30}
                    max={30}
                    onChange={e => onHumidityChange(e)}
                  />
                  <div>%</div>
                  <div className={styles.infoPadding}>
                    <Tooltip
                      underline={false}
                      arrowDirection="right"
                      extraClassNames={[styles.marginLeft]}
                      content={
                        <div className={styles.tooltipText}>
                          Accepted Range: {HUMIDITY_OFFSET_MIN_MAX_TEXT}
                        </div>
                      }
                    >
                      <IoIosInformationCircleOutline size={20} />
                    </Tooltip>
                  </div>
                </div>
              </>
            )}
          </ModalFormContent>
        </div>
      </CardContent>

      {isOffsetUpdated && (
        <CardActions>
          <Button variant="text" onClick={onClickCancel} children="Cancel" />
          <Button onClick={() => onSave(offset)}>Save changes</Button>
        </CardActions>
      )}
    </Card>
  );
};

export default UpdateSensorOffsetCard;
