import {
  Button,
  MultiSelect,
  MultiSelectItem,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import {
  AccessResource,
  Organization,
  ResourceType,
  Role,
  Site,
  SiteGroup,
  User,
} from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations } from '../../actions/organizations';
import { ApplicationState } from '../../reducers';
import styles from './MultiSelects.module.css';
import { IoIosAddCircle } from 'react-icons/io';
import { siteList } from '../../reducers/sites';
import { siteGroupsList } from '../../reducers/site_groups';
import { getSites } from '../../actions/sites';
import { getSiteGroups } from '../../actions/site_groups';

interface Props {
  onSelect: (scope: AccessResource) => void;
  onDeselect: (resourceId: number) => void;
  selectedResourceIds: number[];
  onlySites?: boolean;
}

const MultiSelectSiteAccessButton: React.FC<Props> = ({
  onSelect,
  onDeselect,
  selectedResourceIds,
  onlySites,
}) => {
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const { currentOrg, sites, siteGroups } = useSelector<
    ApplicationState,
    {
      currentOrg?: Organization;
      sites: Site[];
      siteGroups: SiteGroup[];
    }
  >(({ organizations, sites, siteGroups }) => ({
    currentOrg: organizations.currentOrganization,
    sites: siteList(sites),
    siteGroups: siteGroupsList(siteGroups),
  }));

  const toAccessResource = (resourceId: number, role: Role): AccessResource => {
    let resourceType: ResourceType = ResourceType.ORGANIZATION;
    let resource: Organization | Site | SiteGroup | undefined;

    if (currentOrg?.id === resourceId) {
      resource = currentOrg;
    } else {
      resource =
        sites.find(({ id }) => {
          if (id === resourceId) {
            resourceType = ResourceType.SITE;
            return true;
          }
          return false;
        }) ||
        siteGroups.find(({ id }) => {
          if (id === resourceId) {
            resourceType = ResourceType.SITEGROUP;
            return true;
          }
          return false;
        });
    }
    return {
      id: 0,
      role,
      resourceType,
      resourceId,
      title: resource?.title || 'not found',
    };
  };

  const filteredOrganization: Organization[] =
    currentOrg && hasSubstr(currentOrg.title, query) ? [currentOrg] : [];
  const filteredSites: Site[] = sites.filter(({ title }) =>
    hasSubstr(title, query)
  );
  const filteredSiteGroups: SiteGroup[] = siteGroups.filter(({ title }) =>
    hasSubstr(title, query)
  );

  const hasOrganization = filteredOrganization.length > 0;
  const hasSites = filteredSites.length > 0;
  const hasSiteGroups = filteredSiteGroups.length > 0;

  useEffect(() => {
    dispatch(getOrganizations());
    dispatch(getSites());
    dispatch(getSiteGroups());
  }, []);

  const onItemClick = (id: number) => {
    selectedResourceIds.indexOf(id) === -1
      ? onSelect(toAccessResource(id, Role.VIEWER))
      : onDeselect(id);
    setQuery('');
  };

  return (
    <MultiSelect
      doNotIncludeActions
      selectionComponentType="portalDropdown"
      limitDropdownWidth
      selectButton={
        <Button
          variant="outlined"
          onClick={() => {}}
          className={styles.accessButton}
        >
          <IoIosAddCircle size="20" />
          {onlySites ? 'Add Sites' : 'Access to Sites'}
        </Button>
      }
      selectionComponent={
        <div className={styles.dropdown}>
          <div className={styles.searchBox}>
            <SearchBox
              error={filteredOrganization.length === 0}
              withDropdown={false}
              query={query}
              width={'100%'}
              widthActive={'100%'}
              placeholder={'Quick Search'}
              onChange={setQuery}
              noClickOutsideDetection
            />
          </div>
          <div className={styles.dropdownList}>
            {hasOrganization && !onlySites && (
              <>
                <h3 className={styles.sectionHeader}>Organizations</h3>
                {filteredOrganization.map(({ title, id }) => (
                  <MultiSelectItem
                    isSelected={selectedResourceIds.indexOf(id) > -1}
                    onSelect={() => onItemClick(id)}
                  >
                    {title}
                  </MultiSelectItem>
                ))}
              </>
            )}
            {hasSiteGroups && !onlySites && (
              <>
                <h3 className={styles.sectionHeader}>Site Groups</h3>
                {filteredSiteGroups.map(({ title, id }) => (
                  <MultiSelectItem
                    isSelected={selectedResourceIds.indexOf(id) > -1}
                    onSelect={() => onItemClick(id)}
                  >
                    {title}
                  </MultiSelectItem>
                ))}
              </>
            )}
            {hasSites && (
              <>
                {!onlySites && <h3 className={styles.sectionHeader}>Sites</h3>}
                {filteredSites.map(({ title, id }) => (
                  <MultiSelectItem
                    isSelected={selectedResourceIds.indexOf(id) > -1}
                    onSelect={() => onItemClick(id)}
                  >
                    {title}
                  </MultiSelectItem>
                ))}
              </>
            )}
          </div>
        </div>
      }
      hideSelection
      className={styles.accessButton}
    />
  );
};

export default MultiSelectSiteAccessButton;
