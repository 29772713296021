import {
  CertificateBodies,
  Certification,
  CertificationTypes,
  NewCertificationDto,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import lensProp from 'ramda/src/lensProp';
import mergeRight from 'ramda/src/mergeRight';
import path from 'ramda/src/path';
import pipe from 'ramda/src/pipe';
import view from 'ramda/src/view';
import { Actions } from '../actions/certifications';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

// Certifications are not editable.

export type Certifications = {
  idPrimedForDelete?: number | string;
  showNewCertificateModal: boolean;
  showDeleteCertificateModal: boolean;
  certificationsById: CertificationsById;
  newCertificate: NewCertificate;
  certificationIdsByResourceId: CertificationIdsByResourceId;
  certificateDownloadUrlById: CertificateDownloadUrlById;
  editCertificateById: EditCertificateById;
};

export type NewCertificate = {
  isLoading: boolean;
  isChanged: boolean;
  fields: NewCertificationDto;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const newCertificateFields = {
  title: '',
  description: '',
  deviceUuid: '',
  deviceVendor: 'energybox',
  certificationCompany: 'Cal Labs',
  certificationBody: CertificateBodies.NIST,
  certificationType: CertificationTypes.TEMPERATURE_PROBE_CALIBRATION,
  // Local Date String YYYY-MM-DD
  /// ISO-8601
  validFrom: '',
  validTo: '',
  certificationId: '',
  certificate: undefined,
};

const newCertificate = {
  isLoading: false,
  isChanged: false,
  fields: newCertificateFields,
  formErrors: formValidationErrors('certification', newCertificateFields),
  formErrorsVisible: false,
  apiError: {},
};

const initialState = {
  showNewCertificateModal: false,
  showDeleteCertificateModal: false,
  certificationsById: {},
  certificationIdsByResourceId: {},
  certificateDownloadUrlById: {},
  newCertificate: newCertificate,
  editCertificateById: {},
};

export type CertificationsById = {
  [certificateId: string]: Certification;
};

export type CertificationIdsByResourceId = {
  [certificateId: string]: {
    isLoading: boolean;
    certificationIds: (number | string)[];
  };
};
export type CertificateDownloadUrlById = {
  [certificateId: string]: {
    isLoading: boolean;
    url?: string;
  };
};
// Todo extend later
export type EditCertificateById = {
  [certificateId: string]: {
    apiError: ApiError;
  };
};

const certificationsFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  deviceUuid: data.deviceUuid,
  deviceVendor: data.deviceVendor,
  certificationId: data.certificationId,
  certificationBody: data.certificationBody,
  certificationCompany: data.certificationCompany,
  createdBy: data.createdBy,
  createdAt: data.createdAt,
  // Local Date String YYYY-MM-DD
  // ISO-8601
  validFrom: data.validFrom,
  validTo: data.validTo,
  certificationType: data.certificationType,
});

const certifications = (state: Certifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.TOGGLE_NEW_CERTIFICATION_MODAL:
      return pipe(
        assocPath(['newCertificate'], {
          ...newCertificate,
          fields: {
            ...newCertificate.fields,
            deviceUuid: action.deviceUuid || '',
            deviceVendor: action.deviceVendor || 'energybox',
            certificationType: action.certificationType,
          },
        }),
        assoc('showNewCertificateModal', action.value)
      )(state);

    case Actions.GET_CERTIFICATIONS_OF_RESOURCE_SUCCESS: {
      return pipe(
        assoc(
          'certificationsById',
          mergeRight(
            view(lensProp('certificationsById'), state),
            mapArrayToObject(
              mapValues(action.data, certificationsFromApiResponse)
            )
          )
        ),
        assocPath(
          [
            'certificationIdsByResourceId',
            action.resourceId,
            'certificationIds',
          ],
          action.data.map(({ id }) => id)
        ),
        assocPath(
          ['certificationIdsByResourceId', action.resourceId, 'isLoading'],
          false
        )
      )(state);
    }

    case Actions.GET_CERTIFICATIONS_OF_RESOURCE_LOADING: {
      return pipe(
        assocPath(
          ['certificationIdsByResourceId', action.resourceId, 'isLoading'],
          true
        )
      )(state);
    }

    case Actions.GET_CERTIFICATIONS_OF_RESOURCE_ERROR: {
      return pipe(
        assocPath(
          ['certificationIdsByResourceId', action.resourceId, 'isLoading'],
          false
        )
      )(state);
    }

    case Actions.GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_LOADING: {
      return assocPath(
        ['certificateDownloadUrlById', action.id],
        {
          isLoading: true,
        },
        state
      );
    }

    case Actions.GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_ERROR: {
      return assocPath(
        ['certificateDownloadUrlById', action.id],
        {
          isLoading: false,
        },
        state
      );
    }

    case Actions.GET_CERTIFICATION_AUTHENTICATED_DOWNLOAD_URL_SUCCESS: {
      return assocPath(
        ['certificateDownloadUrlById', action.id],
        {
          url: action.data,
          isLoading: false,
        },
        state
      );
    }

    case Actions.TOGGLE_DELETE_CERTIFICATION_MODAL:
      return pipe(
        assoc('showDeleteCertificateModal', action.value),
        assoc('idPrimedForDelete', action.id || undefined)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return assocPath(
        ['newCertificate', 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = assoc(
        action.field,
        action.value,
        path(['newCertificate', 'fields'], state)
      );
      return pipe(
        assocPath(['newCertificate', 'fields'], updatedField),
        assocPath(['newCertificate', 'isChanged'], true),
        assocPath(
          ['newCertificate', 'formErrors'],
          formValidationErrors('certification', updatedField)
        )
      )(state);

    case Actions.CREATE_CERTIFICATION_SUCCESS:
      return pipe(
        assocPath(['certificationsById', action.data.id], action.data),
        assoc('showNewCertificateModal', false)
      )(state);

    case Actions.CREATE_CERTIFICATION_LOADING:
      return assocPath(['newCertificate', 'isLoading'], true, state);

    case Actions.CREATE_CERTIFICATION_ERROR:
      return pipe(
        assocPath(['newCertificate', 'apiError'], storeAPIerror(action)),
        assocPath(['newCertificate', 'isLoading'], false)
      )(state);

    case Actions.DELETE_CERTIFICATION_LOADING:
      return pipe(
        assocPath(
          ['editCertificateById', action.certificateId, 'isLoading'],
          true
        )
      )(state);

    case Actions.DELETE_CERTIFICATION_SUCCESS: {
      return pipe(
        dissocPath(['certificationsById', action.certificateId]),
        assoc('showDeleteCertificateModal', false)
      )(state);
    }

    case Actions.DELETE_CERTIFICATION_ERROR:
      return pipe(
        assocPath(
          ['editCertificateById', action.certificateId, 'apiError'],
          storeAPIerror(action)
        ),
        assocPath(
          ['editCertificateById', action.certificateId, 'isLoading'],
          false
        )
      )(state);

    default:
      return state;
  }
};

export default certifications;
