import {
  ChartConfig,
  OperationalSample,
  Locale,
  FilterTimePeriod,
  SensorType,
  Sentinel,
  ThresholdLine,
  ChartData,
  CurrentUser,
} from '@energybox/react-ui-library/dist/types';
import {
  DateFilter,
  SensorLineChart,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import {
  determineDateRangeAfterZoomIn,
  determineXTicksAfterZoomIn,
  generateXTicks,
  convertSensorTypesToSentinelTypes,
  getChartConfig,
  processSamplesData,
  processThresholdLines,
  getDateLast15MinuteStep,
  genericDateFormat,
} from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';

import React from 'react';
import { connect } from 'react-redux';
import { getOperationalSamples } from '../../actions/operationalSamples';
import { getSentinels } from '../../actions/sentinels';
import styles from './SensorLineChartContainer.module.css';
import { startOfDayTz } from '@energybox/react-ui-library/dist/utils/date';

interface OwnProps {
  ianaTimeZoneCode: string;
  sensorId: number;
  sensorType: SensorType[];
  locale: Locale;
  resourceId?: number;
}

interface Props extends OwnProps {
  currentUser?: CurrentUser;
  getSentinels: () => void;
  getOperationalSamples: (fromDate: number, toDate: number) => void;
  sentinels: Sentinel[];
  operationalSamples: OperationalSample[];
  isLoading: boolean;
}

type State = {
  dateFilterFromDate: number;
  dateFilterToDate: number;
  fromDate: number;
  toDate: number;
  ticks: number[];
  title: string;
  refAreaStart: string;
  refAreaEnd: string;
  data: ChartData[];
  chartConfig?: { [key: string]: ChartConfig };
  thresholdLines: ThresholdLine[];
  isMonthly?: boolean;
  isCustomRange?: boolean;
};

class SensorLineChartContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { ianaTimeZoneCode } = props;

    const startOfToday = startOfDayTz(new Date(), ianaTimeZoneCode).valueOf();

    const currentTimeRoundedTo15Min = getDateLast15MinuteStep().valueOf();

    this.state = {
      title: 'Today',
      dateFilterFromDate: startOfToday,
      dateFilterToDate: currentTimeRoundedTo15Min,
      fromDate: startOfToday,
      toDate: currentTimeRoundedTo15Min,
      ticks: generateXTicks(startOfToday, currentTimeRoundedTo15Min),
      refAreaStart: '',
      refAreaEnd: '',
      data: [],
      thresholdLines: [],
      chartConfig: props.currentUser
        ? getChartConfig(props.sensorType, props.currentUser)
        : undefined,
    };
  }

  componentDidMount() {
    const { getSentinels, getOperationalSamples } = this.props;
    const { fromDate, toDate } = this.state;

    getOperationalSamples(fromDate, toDate);
    getSentinels();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      currentUser,
      resourceId,
      sentinels,
      getOperationalSamples,
      operationalSamples,
      sensorType,
      ianaTimeZoneCode,
    } = this.props;
    if (!currentUser) return;
    const { fromDate, toDate } = this.state;

    if (fromDate !== prevState.fromDate || toDate !== prevState.toDate) {
      this.setState({ data: [] });
      getOperationalSamples(fromDate, toDate);
    }

    if (currentUser && sentinels !== prevProps.sentinels) {
      this.setState({
        thresholdLines: processThresholdLines(
          sentinels,
          currentUser,
          convertSensorTypesToSentinelTypes(sensorType),
          resourceId
        ),
      });
    }

    if (sensorType !== prevProps.sensorType) {
      this.setState({
        chartConfig: getChartConfig(sensorType, currentUser),
      });
    }

    if (operationalSamples !== prevProps.operationalSamples) {
      this.setState({
        data: processSamplesData(operationalSamples, currentUser),
      });
    }

    if (
      sensorType !== prevProps.sensorType ||
      currentUser !== prevProps.currentUser
    ) {
      this.setState({
        chartConfig: getChartConfig(sensorType, currentUser),
      });
    }
  }

  setFilter = (filter: FilterTimePeriod) => {
    const { ianaTimeZoneCode } = this.props;
    const { fromDate, toDate, title, isMonthly, isCustomRange } = filter;

    if (!fromDate || !toDate) {
      return;
    }
    const fromDateNumber = DateTime.fromJSDate(fromDate)
      .setZone(ianaTimeZoneCode)
      .toMillis();
    const toDateNumber = DateTime.fromJSDate(toDate)
      .setZone(ianaTimeZoneCode)
      .toMillis();

    const buttonTitle =
      title ||
      `${genericDateFormat(
        fromDate,
        this.props.locale.dateFormat,
        ianaTimeZoneCode
      )} - ${genericDateFormat(
        toDate,
        this.props.locale.dateFormat,
        ianaTimeZoneCode
      )}`;

    this.setState({
      dateFilterFromDate: fromDateNumber,
      dateFilterToDate: toDateNumber,
      fromDate: fromDateNumber,
      toDate: toDateNumber,
      ticks: generateXTicks(fromDateNumber, toDateNumber),
      title: buttonTitle,
      isMonthly,
      isCustomRange,
    });
  };

  updateRefAreaStart = (newRefAreaLeft: string) => {
    this.setState({ refAreaStart: newRefAreaLeft });
  };

  updateRefAreaEnd = (newRefAreaRight: string) => {
    this.setState({ refAreaEnd: newRefAreaRight });
  };

  zoomIn = () => {
    const { refAreaStart, refAreaEnd } = this.state;
    const refAreaStartNumber = parseInt(refAreaStart);
    const refAreaEndNumber = parseInt(refAreaEnd);

    if (refAreaStart === refAreaEnd || refAreaEnd === '') {
      this.setState(() => ({
        refAreaStart: '',
        refAreaEnd: '',
      }));
      return;
    }

    const {
      fromDate: newFromDate,
      toDate: newToDate,
    } = determineDateRangeAfterZoomIn(refAreaStartNumber, refAreaEndNumber);

    const newTicks = determineXTicksAfterZoomIn(
      refAreaStartNumber,
      refAreaEndNumber
    );

    this.setState({
      fromDate: newFromDate,
      toDate: newToDate,
      ticks: newTicks,
      refAreaStart: '',
      refAreaEnd: '',
    });
  };

  zoomOut = () => {
    const { dateFilterFromDate, dateFilterToDate } = this.state;

    this.setState({
      fromDate: dateFilterFromDate,
      toDate: dateFilterToDate,
      ticks: generateXTicks(dateFilterFromDate, dateFilterToDate),
    });
  };

  render() {
    const {
      currentUser,
      sensorType,
      locale,
      isLoading,
      ianaTimeZoneCode,
    } = this.props;
    const {
      dateFilterToDate,
      dateFilterFromDate,
      fromDate,
      toDate,
      ticks,
      title,
      refAreaStart,
      refAreaEnd,
      data,
      chartConfig,
      thresholdLines,
      isMonthly,
      isCustomRange,
    } = this.state;
    const dateFilterFromDateJS = DateTime.fromMillis(
      dateFilterFromDate
    ).toJSDate();
    const dateFilterToDateJS = DateTime.fromMillis(dateFilterToDate).toJSDate();

    const isZoomedIn =
      dateFilterFromDate !== fromDate || dateFilterToDate !== toDate;

    if (!currentUser) return null;
    return (
      <div className={styles.container}>
        <header className={styles.header}>
          <div>
            <div className={styles.title}>Sensor Activity</div>
          </div>

          <div className={styles.headerRightAlign}>
            <DateFilter
              ianaTimeZoneCode={ianaTimeZoneCode}
              alignItemsRight
              setFilter={this.setFilter}
              value={{
                title,
                toDate: dateFilterToDateJS,
                fromDate: dateFilterFromDateJS,
                isMonthly,
                isCustomRange,
              }}
              dateFormat="datetime"
              customPickerVariant="datetime"
              locale={locale}
            />

            <div className={styles.zoomResetContainer}>
              <ResetButton
                customText="Reset Zoom"
                displayInfoTooltip
                onClick={this.zoomOut}
                disabled={!isZoomedIn}
              />
            </div>
          </div>
        </header>

        <SensorLineChart
          ianaTimeZoneCode={ianaTimeZoneCode}
          sensorType={sensorType}
          locale={locale}
          fromDate={fromDate}
          toDate={toDate}
          ticks={ticks}
          refAreaStart={refAreaStart}
          refAreaEnd={refAreaEnd}
          zoomIn={this.zoomIn}
          updateRefAreaStart={this.updateRefAreaStart}
          updateRefAreaEnd={this.updateRefAreaEnd}
          isLoading={isLoading}
          chartConfig={chartConfig}
          thresholdLines={thresholdLines}
          data={data}
          hideNotificationsCheckbox
          hideCommentsCheckbox
          hideControlDetailsCheckbox
        />
      </div>
    );
  }
}

const mapStateToProps = (
  { app, sentinels, operationalSamples },
  { sensorId }: OwnProps
) => ({
  currentUser: app.currentUser,
  sentinels: Object.values<any>(sentinels.byId),
  operationalSamples: operationalSamples.operationalSamplesBySensorId[sensorId],
  isLoading: operationalSamples.isLoading,
});

const mapDispatchToProps = (dispatch, { sensorId }: OwnProps) => ({
  getOperationalSamples: (fromDate: number, toDate: number) =>
    dispatch(
      getOperationalSamples({
        sensorId: String(sensorId),
        from: DateTime.fromMillis(fromDate)
          .toUTC()
          .toISO(),
        to: DateTime.fromMillis(toDate)
          .toUTC()
          .toISO(),
      })
    ),
  getSentinels: () => dispatch(getSentinels({ sensorIds: [sensorId] })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SensorLineChartContainer);
