import { HvacSop } from '@energybox/react-ui-library/dist/types/Sop';
import {
  FanModeLabel,
  ThermostatWorkingModeLabel,
  ThermostatWorkingMode,
  CurrentUser,
} from '@energybox/react-ui-library/dist/types';
import {
  createTemperatureString,
  isDefined,
  global,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { useCurrentUser, useIs12HrTimeFormat } from '../../hooks/useAppDetails';
import {
  ReducedHvacSopTimeSlots,
  reduceHvacSopTimetableRows,
} from '../../utils/hvacSop';
import { formatTimeSlotToString } from '../../utils/time';
import styles from './HvacSopSchedulesTable.module.css';

type Props = {
  hvacSopComponent: HvacSop | undefined;
  isControlInForcedOnMode: boolean;
};

const HvacSopSchedulesTable: React.FC<Props> = ({
  hvacSopComponent,
  isControlInForcedOnMode,
}) => {
  const currentUser = useCurrentUser();
  const is12HrFormat = useIs12HrTimeFormat();

  if (!isDefined(hvacSopComponent) || !isDefined(currentUser)) {
    return null;
  }

  const renderDataRows = (hvacSopComponent: HvacSop) => {
    return hvacSopComponent.hvacSettings.hvacSchedules.map((s, i) => {
      const reducedTimeSlots = reduceHvacSopTimetableRows(s.timetable.rows);
      const isUnoccupied = s.hvacScheduleType === 'UNOCCUPIED';
      const isForcedOnSchedule =
        isControlInForcedOnMode &&
        s.hvacScheduleType === 'OCCUPIED' &&
        s.default === true;

      return (
        <tr
          className={`${
            isForcedOnSchedule ? styles.forcedOnScheduleRow : styles.tableRow
          }`}
          key={`${i + 1}-${s.id}`}
        >
          <td className={styles.cell} valign="top">
            {s.hvacScheduleType === 'OCCUPIED'
              ? `Occupied ${i + 1}`
              : 'Unoccupied'}
          </td>
          <td className={styles.cell} valign="top">
            {renderTimeSlots(reducedTimeSlots, is12HrFormat, isUnoccupied)}
          </td>
          <td className={styles.cell} valign="top">
            {ThermostatWorkingModeLabel[s.thermostatWorkingMode] ||
              global.NOT_AVAILABLE}
          </td>
          <td className={styles.cell} valign="top">
            {renderSetPointInputs(
              s.thermostatWorkingMode,
              s.minTemp,
              s.maxTemp,
              currentUser
            )}
          </td>
          <td className={styles.cell} valign="top">
            {s.thermostatWorkingMode !== 'OFF'
              ? FanModeLabel[s.fanMode]
              : global.NOT_AVAILABLE}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className={styles.tableTitle}>Schedules & Setpoints</div>

      <table className={styles.root}>
        <tr className={styles.tableRow}>
          <th className={styles.headerCell} scope="col" align="left">
            Timetable
          </th>
          <th className={styles.headerCell} scope="col" align="left">
            Days/Time
          </th>
          <th className={styles.headerCell} scope="col" align="left">
            <div>Thermostat</div>
            <div>Mode</div>
          </th>
          <th className={styles.headerCell} scope="col" align="left">
            Temperature
          </th>
          <th className={styles.headerCell} scope="col" align="left">
            Fan
          </th>
        </tr>

        {renderDataRows(hvacSopComponent)}
      </table>

      {isControlInForcedOnMode && (
        <div className={styles.forcedOnWarning}>
          <div className={styles.bold}>Warning</div>
          <div>
            Our system refers to Occupied 1 settings during "Forced On" control
            mode. To revert back to schedule, click on "Auto"
          </div>
        </div>
      )}
    </>
  );
};

const renderTimeSlots = (
  reducedTimeSlots: ReducedHvacSopTimeSlots,
  is12HrFormat: boolean,
  isUnoccupied: boolean
) => {
  return Object.entries(reducedTimeSlots).map(
    ([weekdayKey, timesArray], index) => (
      <div
        key={`timeSlot-${index}`}
        className={`${styles.timeSlotContainer} ${
          isUnoccupied ? styles.unOccupiedSchedule : ''
        }`}
      >
        <div>{summarizeDays(weekdayKey)}</div>
        <div>
          {timesArray.map(({ begin, end }, index) => {
            return (
              <div key={`${weekdayKey}-${begin}-${end}-${index}`}>
                {`${formatTimeSlotToString(
                  begin,
                  is12HrFormat
                )} - ${formatTimeSlotToString(end, is12HrFormat)}`}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

const summarizeDays = (weekdayKey: string) => {
  const Weekdays = 'MON, TUE, WED, THU, FRI';
  const Weekends = 'SAT, SUN';
  const Everyday = 'MON, TUE, WED, THU, FRI, SAT, SUN';

  switch (weekdayKey) {
    case Everyday: {
      return 'Everyday';
    }
    case Weekdays: {
      return 'Weekdays';
    }
    case Weekends: {
      return 'Weekends';
    }
    default:
      return weekdayKey;
  }
};

const renderSetPointInputs = (
  thermostatWorkingMode: ThermostatWorkingMode,
  minTemp: number | undefined,
  maxTemp: number | undefined,
  currentUser: CurrentUser
) => {
  const isAuto = thermostatWorkingMode === ThermostatWorkingMode.AUTO;
  const isCooling = thermostatWorkingMode === ThermostatWorkingMode.COOLING;
  const isHeating = thermostatWorkingMode === ThermostatWorkingMode.HEATING;

  return (
    <>
      <div>
        {isDefined(minTemp) && (isAuto || isHeating)
          ? `Heat to: ${createTemperatureString(minTemp, currentUser, 0)}`
          : global.NOT_AVAILABLE}
        {isDefined(minTemp) && isDefined(maxTemp) && isAuto && ' / '}
      </div>
      <div>
        {isDefined(maxTemp) && (isAuto || isCooling)
          ? `Cool to: ${createTemperatureString(maxTemp, currentUser, 0)}`
          : ''}
      </div>
    </>
  );
};

export default HvacSopSchedulesTable;
