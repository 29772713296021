import { Checkbox } from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  ComparisonType,
  CurrentUser,
  ResourceType,
  SentinelParameters,
  SentinelTargetType,
  Site,
  Space,
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  getTempDifferenceInCelsius,
  getTempDifferenceInFahrenheit,
  toFahrenheit,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';

import React, { useState } from 'react';
import { openModal } from '../../../../actions/modal';
import ResourcePath from '../../../../containers/ResourcePath';
import { useCurrentUser } from '../../../../hooks/useAppDetails';
import TextField from '../../../ui/TextField';
import { TargetedEntitiesMappingBySiteId } from '../../SentinelWizard';
import { EquipmentChip, SpaceChip } from '../StepRollout/TargetChips';
import styles from './StepAdditions.module.css';

type Props = {
  targetTypes: SentinelTargetType[];
  openModal: typeof openModal;
  targetedSites: Site[];
  targetedEntitiesBySiteId: TargetedEntitiesMappingBySiteId;
  sentinelParameters?: SentinelParameters;
  setSentinelParameters: (params: SentinelParameters) => void;
};

const StepAdditions = ({
  sentinelParameters = {},
  setSentinelParameters,
  targetedEntitiesBySiteId,
  targetedSites = [],
  targetTypes,
}: Props) => {
  const currentUser = useCurrentUser();
  const [selectedTargetType, setSelectedTargetType] = useState(targetTypes[0]);

  const targetsTolerance = sentinelParameters.targetsTolerance || {};

  const data: CardListRowData[] = targetedSites.reduce((result, site) => {
    const siteEntities = targetedEntitiesBySiteId[site.id];
    if (siteEntities === undefined) return result;
    const equipmentList = siteEntities.equipment;
    const spacesList = siteEntities.spaces;

    const noEquipmentTargetSelected =
      equipmentList.length === 0 &&
      selectedTargetType === SentinelTargetType.EQUIPMENT;
    const noSpaceTargetSelected =
      spacesList.length === 0 &&
      selectedTargetType === SentinelTargetType.SPACE;
    if (noEquipmentTargetSelected || noSpaceTargetSelected) return result;

    result.push({
      key: `site${site.title}${site.id}Header`,
      startExpanded: true,
      content: (
        <>
          <Cell width="4">{site.title}</Cell>
          <Cell width="8">
            <table className={styles.table}>
              <tbody>
                <tr>
                  <td style={{ width: '30%', minWidth: '4rem' }}>Equipment</td>
                  <td style={{ width: '25%', minWidth: '4rem' }}>Location</td>
                  <td style={{ width: '20%', minWidth: '5rem' }}>
                    Sentinel Threshold
                  </td>
                  <td style={{ width: '25%', minWidth: '5rem' }}>
                    Temp. Tolerance
                  </td>
                </tr>
              </tbody>
            </table>
          </Cell>
        </>
      ),
      extraContent: [
        <>
          <Cell width="4">{site.address}</Cell>
          <Cell width="8">
            <table className={styles.table}>
              <tbody>
                {selectedTargetType === SentinelTargetType.EQUIPMENT &&
                  equipmentList.map(equipment => (
                    <tr key={`equipmentRows${equipment.id}`}>
                      <td style={{ width: '30%', minWidth: '4rem' }}>
                        <EquipmentChip
                          id={equipment.type ? equipment.type.alias : ''}
                          title={equipment.title}
                        />
                      </td>
                      <td style={{ width: '25%', minWidth: '4rem' }}>
                        <ResourcePath
                          suppressLink
                          resourceId={equipment.id}
                          resourceType={ResourceType.SPACE}
                        />
                      </td>
                      <td style={{ width: '20%', minWidth: '5rem' }}>
                        {currentUser &&
                          getSentinelThresholdString(
                            sentinelParameters,
                            currentUser,
                            equipment.id
                          )}
                      </td>
                      <td style={{ width: '25%', minWidth: '5rem' }}>
                        <TemperatureTolerance
                          sentinelParameters={sentinelParameters}
                          tolerance={targetsTolerance[equipment.id] || 0}
                          setTolerance={tolerance => {
                            const updatedToleranceMapping = {
                              ...targetsTolerance,
                              [equipment.id]: tolerance,
                            };
                            if (tolerance === 0) {
                              delete updatedToleranceMapping[equipment.id];
                            }
                            setSentinelParameters({
                              ...sentinelParameters,
                              targetsTolerance: updatedToleranceMapping,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                {selectedTargetType === SentinelTargetType.SPACE &&
                  spacesList.map((space: Space) => (
                    <tr key={`spaceRows${space.id}`}>
                      <td style={{ width: '30%', minWidth: '4rem' }}>
                        <SpaceChip id="generic" title={space.title} />
                      </td>
                      <td style={{ width: '25%', minWidth: '4rem' }}>
                        <ResourcePath
                          suppressLink
                          resourceId={space.id}
                          resourceType={ResourceType.SPACE}
                        />
                      </td>
                      <td style={{ width: '20%', minWidth: '5rem' }}>
                        {currentUser &&
                          getSentinelThresholdString(
                            sentinelParameters,
                            currentUser,
                            space.id
                          )}
                      </td>
                      <td style={{ width: '25%', minWidth: '5rem' }}>
                        <TemperatureTolerance
                          sentinelParameters={sentinelParameters}
                          tolerance={targetsTolerance[space.id] || 0}
                          setTolerance={tolerance => {
                            const updatedToleranceMapping = {
                              ...targetsTolerance,
                              [space.id]: tolerance,
                            };
                            if (tolerance === 0) {
                              delete updatedToleranceMapping[space.id];
                            }
                            setSentinelParameters({
                              ...sentinelParameters,
                              targetsTolerance: updatedToleranceMapping,
                            });
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Cell>
        </>,
      ],
    });
    return result;
  }, [] as CardListRowData[]);

  return (
    <div>
      <div className={styles.actionTargetType}>
        {targetTypes.map(t => (
          <Checkbox
            key={t}
            id={t}
            name={t}
            checked={selectedTargetType === t}
            onChange={() => setSelectedTargetType(t)}
            label={t.charAt(0) + t.slice(1).toLowerCase()}
          />
        ))}
      </div>
      <CardList
        header={[
          {
            width: '4',
            content: 'Site',
          },
          {
            width: '8',
            content: 'Selection',
          },
        ]}
        data={data}
      />
    </div>
  );
};

const TemperatureTolerance = ({
  sentinelParameters,
  tolerance,
  setTolerance,
}: {
  sentinelParameters: SentinelParameters;
  tolerance: number;
  setTolerance: (value: number) => void;
}) => {
  const currentUser = useCurrentUser();
  const {
    comparisonType,
    rangeUpperBound,
    rangeLowerBound,
  } = sentinelParameters;

  if (!comparisonType) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  let toleranceInCelsius = Math.round((tolerance + Number.EPSILON) * 10) / 10;
  let toleranceInFahrenheit =
    Math.round(
      (getTempDifferenceInFahrenheit(tolerance) + Number.EPSILON) * 10
    ) / 10;

  let maxToleranceInCelsius = Number.MIN_SAFE_INTEGER,
    maxToleranceInFahrenheit = Number.MIN_SAFE_INTEGER;

  if (comparisonType === ComparisonType.inner) {
    if (rangeUpperBound === undefined || rangeLowerBound === undefined) {
      return <>{global.NOT_AVAILABLE}</>;
    }

    const rangeInCelsius = (rangeUpperBound - rangeLowerBound) / 2;

    maxToleranceInCelsius =
      Math.round((rangeInCelsius + Number.EPSILON) * 10) / 10;
    maxToleranceInFahrenheit =
      Math.round(
        (getTempDifferenceInFahrenheit(rangeInCelsius) + Number.EPSILON) * 10
      ) / 10;
    if (maxToleranceInCelsius < toleranceInCelsius) {
      toleranceInCelsius = maxToleranceInCelsius;
      toleranceInFahrenheit = maxToleranceInFahrenheit;
    }
  }

  if (!currentUser) return null;
  const temperatureUnit = getUserPreferenceTemperatureUnit(currentUser);
  return (
    <div className={styles.toleranceSetting}>
      <TextField
        type="number"
        value={
          temperatureUnit === TemperatureUnit.C
            ? toleranceInCelsius
            : toleranceInFahrenheit
        }
        step={0.1}
        min={0}
        onChange={(ev: any) => {
          let newTolerance = +ev.target.value;
          if (newTolerance < 0) return;

          if (temperatureUnit === TemperatureUnit.C) {
            if (
              comparisonType === ComparisonType.inner &&
              newTolerance > maxToleranceInCelsius
            ) {
              newTolerance = maxToleranceInCelsius;
            }
            setTolerance(newTolerance);
          } else {
            if (
              comparisonType === ComparisonType.inner &&
              newTolerance > maxToleranceInFahrenheit
            ) {
              newTolerance = maxToleranceInFahrenheit;
            }
            setTolerance(getTempDifferenceInCelsius(newTolerance));
          }
        }}
      />
    </div>
  );
};

const getSentinelThresholdString = (
  sentinelParameters: SentinelParameters,
  currentUser: CurrentUser,
  targetId: number
) => {
  const {
    comparisonType,
    threshold,
    rangeLowerBound,
    rangeUpperBound,
  } = sentinelParameters;
  const temperatureUnit = getUserPreferenceTemperatureUnit(currentUser);
  const targetsTolerance = sentinelParameters.targetsTolerance || {};
  let tolerance = targetsTolerance[targetId] || 0;
  if (temperatureUnit === TemperatureUnit.F) {
    tolerance = +getTempDifferenceInFahrenheit(tolerance).toFixed(1);
  }
  let comparisonTypeString = '';
  let displayThreshold = threshold,
    displayLower = rangeLowerBound,
    displayUpper = rangeUpperBound;

  if (temperatureUnit === TemperatureUnit.F) {
    if (isDefined(displayThreshold)) {
      displayThreshold = toFahrenheit(displayThreshold);
    }

    if (isDefined(displayLower) && isDefined(displayUpper)) {
      displayLower = toFahrenheit(displayLower);
      displayUpper = toFahrenheit(displayUpper);
    }
  }

  switch (comparisonType) {
    case ComparisonType.gt: {
      comparisonTypeString = '>';
      if (displayThreshold !== undefined) {
        displayThreshold += tolerance;
        return `${comparisonTypeString} ${displayThreshold.toFixed(
          1
        )}${temperatureUnit}`;
      }
      break;
    }
    case ComparisonType.lt: {
      comparisonTypeString = '<';
      if (displayThreshold !== undefined) {
        displayThreshold -= tolerance;
        return `${comparisonTypeString} ${displayThreshold.toFixed(
          1
        )}${temperatureUnit}`;
      }
      break;
    }
    case ComparisonType.outer: {
      if (displayLower !== undefined && displayUpper !== undefined) {
        displayLower -= tolerance;
        displayUpper += tolerance;
        return `${displayLower.toFixed(
          1
        )}${temperatureUnit} - ${displayUpper.toFixed(1)}${temperatureUnit}`;
      }
      break;
    }
    case ComparisonType.inner: {
      if (displayLower && displayUpper) {
        displayLower += tolerance;
        displayUpper -= tolerance;
        return `${displayLower.toFixed(
          1
        )}${temperatureUnit} > x < ${displayUpper.toFixed(
          1
        )}${temperatureUnit}`;
      }
      break;
    }
    case ComparisonType.eq: {
      if (displayThreshold !== undefined) {
        return `${displayThreshold.toFixed(1)}`;
      }
      break;
    }
    default:
      break;
  }
  return global.NOT_AVAILABLE;
};

export default StepAdditions;
