export enum EnergyboxService {
  reminder = 'reminder',
  sentinels = 'sentinels',
  iam = 'iam',
  tsd = 'tsd',
  timeSeries = 'timeSeries',
  analytics = 'analytics',
  files = 'files',
  logging = 'logging',
  help = 'help',
  reportworker = 'reportworker',
  transmission = 'transmission',
  weather = 'weather',
}

export const getServiceUrl = (service?: EnergyboxService) => {
  switch (service) {
    case EnergyboxService.reminder:
      return process.env.REACT_APP_SERVICE_REMINDER_BASE_URL;
    case EnergyboxService.sentinels:
      return process.env.REACT_APP_SERVICE_SENTINEL_BASE_URL;
    case EnergyboxService.timeSeries:
      return process.env.REACT_APP_SERVICE_TIMESERIES_ROOT;
    case EnergyboxService.analytics:
      return process.env.REACT_APP_SERVICE_ANALYTICS_ROOT;
    case EnergyboxService.files:
      return process.env.REACT_APP_SERVICE_FILE_ROOT;
    case EnergyboxService.logging:
      return process.env.REACT_APP_SERVICE_LOGGING_ROOT;
    case EnergyboxService.help:
      return process.env.REACT_APP_SERVICE_HELP_ROOT;
    case EnergyboxService.reportworker:
      return process.env.REACT_APP_SERVICE_REPORTWORKER_ROOT;
    case EnergyboxService.transmission:
      return process.env.REACT_APP_SERVICE_TRANSMISSIONS_ROOT;
    case EnergyboxService.weather:
      return process.env.REACT_APP_SERVICE_WEATHER_ROOT;
    case EnergyboxService.iam:
    default:
      return process.env.REACT_APP_API_BASE_URL;
  }
};

export const getEbTstatUuidRange = (): string => {
  if (process.env.REACT_APP_EB_THERMOSTAT_UUID_RANGE) {
    return process.env.REACT_APP_EB_THERMOSTAT_UUID_RANGE;
  }
  return '';
};

export const tracingEnabledServices = (): Set<string> => {
  if (process.env.REACT_APP_EB_TRACING_ENABLED_SERVICES) {
    return new Set<string>(
      process.env.REACT_APP_EB_TRACING_ENABLED_SERVICES.split(',')
    );
  } else {
    return new Set<string>();
  }
};
