import { ResourcePath } from '@energybox/react-ui-library/dist/types';
import pathOr from 'ramda/src/pathOr';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResourcePathById } from '../actions/paths';
import { ApplicationState } from '../reducers';

const useGetResourcePathById = (id: string | number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getResourcePathById(+id));
    }
  }, [id]);

  const resourcePath = useSelector<
    ApplicationState,
    ResourcePath[] | undefined
  >(({ resourcePaths }) => {
    return pathOr(undefined, [id], resourcePaths.byId);
  });

  return resourcePath;
};

export default useGetResourcePathById;
