import { useSubscribeToControlBoard } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import { GatewayStates } from '@energybox/react-ui-library/dist/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { useControlBoardById } from '../../hooks/useControlBoard';
import { ApplicationState } from '../../reducers';
import styles from './ControlBoardLocalOverrideLabel.module.css';

interface ControlBoardLocalOverrideLabelProps {
  controlBoardId: number;
}

const ControlBoardLocalOverrideLabel = ({
  controlBoardId,
}: ControlBoardLocalOverrideLabelProps) => {
  const dispatch = useDispatch();
  const controlBoard = useControlBoardById(controlBoardId);

  const subscribedControlBoard = useSubscribeToControlBoard(
    controlBoard,
    () =>
      useSelector(({ subscribedControlBoards }: ApplicationState) => {
        if (controlBoardId) {
          return subscribedControlBoards.byId[controlBoardId];
        }
        return undefined;
      }),
    dispatch,
    subscribeToDeviceStatus,
    unsubscribeFromDeviceStatus
  );

  const isLocalOverrideActive = subscribedControlBoard?.status?.gatewayStates?.includes(
    GatewayStates.CONTROL_OVERRIDE
  );

  return isLocalOverrideActive ? (
    <div className={styles.localOverrideLabel}>Local Override</div>
  ) : null;
};

export default ControlBoardLocalOverrideLabel;
