import {
  Button,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { DeviceType } from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { connect } from 'react-redux';
import {
  Actions as ControlBoardActions,
  createControlBoard,
  displayControlBoardFormErrors,
  updateControlBoardFields,
  clearFormErrors as clearSiteControllerForm,
} from '../../actions/control_boards';
import {
  Actions as GatewayActions,
  create,
  displayFormErrors as displayGatewayFormErrors,
  hideNewGatewayModal,
  updateField,
  clearFormErrors as clearHubForm,
} from '../../actions/gateways';
import {
  Actions as ThermostatActions,
  create as createThermostat,
  clearFormErrors as clearThermostatForm,
} from '../../actions/thermostats';
import EditControlBoardForm from '../../components/EditControlBoardForm';
import EditHubForm from '../../components/EditHubForm';
import SelectDeviceForm from '../../components/SelectDeviceForm/SelectDeviceForm';
import { ApplicationState } from '../../reducers';
import { EditControlBoard } from '../../reducers/control_boards';
import { EditGateway } from '../../reducers/gateways';
import { CreateNewText } from '../../types/global';
import { EditThermostat, ThermostatFormErrors } from '../../types/Thermostat';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import {
  areThereFormErrors,
  validateThermostatForm,
} from '../../utils/formValidation';
import styles from './NewDeviceModal.module.css';
import EditThermostatForm from '../../components/EditThermostatForm';
import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';
import { isEBThermostat } from '../../utils/ebThermostat';

type OwnProps = {
  lockSiteId?: number;
};

interface Props extends OwnProps {
  isVisible: boolean;
  onClose: () => void;

  hub: EditGateway;
  onHubChange: (field: string, value: any) => void;
  onHubCreate: (number?) => void;

  controlBoard: EditControlBoard;
  onControlBoardChange: (field: string, value: any) => void;
  onControlBoardCreate: (number?) => void;

  createThermostat: (payload: EditThermostat) => void;
  clearThermostatForm: () => void;
  clearSiteControllerForm: () => void;
  clearHubForm: () => void;
  displayControlBoardFormErrors: (id: string) => void;
  displayGatewayFormErrors: (id: string) => void;
  thermostatApiError: ApiError;
  isThermostatLoading: boolean;
  isNewSuperHub: boolean;
  hubSiteId: string;
}

interface State {
  currentStep: number;
  model?: DeviceType;
  thermostatFields: EditThermostat;
  thermostatFormErrors: ThermostatFormErrors;
  thermostatFormErrorsVisible: boolean;
}

const initialThermostatFields = (lockSiteId?: number): EditThermostat => ({
  title: '',
  description: '',
  uuid: '',
  siteId: lockSiteId || -1,
  spaceId: -1,
  networkGroupId: -1,
  wirelessTemperatureSensorsCount: 0,
  model: DeviceType.THERMOSTAT,
});

class NewDeviceModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialThermostat = initialThermostatFields(props.lockSiteId);
    this.state = {
      model: undefined,
      currentStep: 0,
      thermostatFormErrorsVisible: false,
      thermostatFormErrors: validateThermostatForm(initialThermostat),
      thermostatFields: initialThermostat,
    };
  }

  componentDidMount(): void {
    const { isNewSuperHub } = this.props;
    if (isNewSuperHub) this.onDeviceSelection(DeviceType.ENERGYBOX_HUB);
  }

  componentDidUpdate(prevProps: any): void {
    const { isNewSuperHub } = this.props;
    if (isNewSuperHub !== prevProps.isNewSuperHub) {
      if (isNewSuperHub === true)
        this.onDeviceSelection(DeviceType.ENERGYBOX_HUB);
      else this.setState({ currentStep: 0 });
    }
  }

  resetThermostatForm = () => {
    const initialThermostat = initialThermostatFields(this.props.lockSiteId);
    this.setState({
      currentStep: 0,
      thermostatFormErrorsVisible: false,
      thermostatFormErrors: validateThermostatForm(initialThermostat),
      thermostatFields: initialThermostat,
    });
  };

  onDeviceModalClose = () => {
    this.resetThermostatForm();
    this.props.onClose();
  };

  onDeviceSelection = (device: DeviceType) => {
    // TODO: Model selection
    // const { onChange } = this.props;
    // onChange('model', device);
    this.setState({ currentStep: 1, model: device });
  };

  onBackButtonClick = () => {
    this.props.clearHubForm();
    this.props.clearSiteControllerForm();
    this.props.clearThermostatForm();
    this.resetThermostatForm();
    this.setState({ currentStep: this.state.currentStep - 1 });
  };

  onDeviceCreate = () => {
    const {
      lockSiteId,
      hub,
      controlBoard,
      onControlBoardCreate,
      onHubCreate,
      displayControlBoardFormErrors,
      displayGatewayFormErrors,
    } = this.props;
    switch (this.state.model) {
      case DeviceType.THERMOSTAT: {
        this.onThermostatCreate();
        return;
      }
      case DeviceType.ENERGYBOX_HUB: {
        if (hasKeys(hub.formErrors)) {
          displayGatewayFormErrors('new');
        } else {
          onHubCreate(lockSiteId);
        }
        return;
      }
      case DeviceType.ENERGYBOX_CB: {
        if (hasKeys(controlBoard.formErrors)) {
          displayControlBoardFormErrors('new');
        } else {
          onControlBoardCreate(lockSiteId);
        }
        return;
      }
      default:
        return;
    }
  };

  // Probably move this to the reducer
  onThermostatCreate = () => {
    const { createThermostat } = this.props;
    const { thermostatFields, thermostatFormErrors } = this.state;
    const areThereErrors = areThereFormErrors(thermostatFormErrors);
    if (areThereErrors) {
      this.setState({
        thermostatFormErrorsVisible: true,
      });
    } else {
      //validate uuid to create venstar or EB thermostat
      if (isEBThermostat(thermostatFields.uuid)) {
        const updatedFields = {
          ...this.state.thermostatFields,
          model: ThermostatModelType.ENERGYBOX_THERMOSTAT,
        };
        createThermostat(updatedFields);
      } else {
        const updatedFields = {
          ...this.state.thermostatFields,
          model: ThermostatModelType.VENSTAR_THERMOSTAT,
        };
        createThermostat(updatedFields);
      }
    }
  };

  onThermostatFieldChange = (field: string, value: string | number) => {
    const { thermostatFields } = this.state;
    const updatedFields = {
      ...thermostatFields,
      [field]: value,
    };

    this.setState({
      thermostatFields: updatedFields,
      thermostatFormErrors: validateThermostatForm(updatedFields),
    });
  };

  onThermostatSelectSite = (value: number) => {
    const updatedFields = {
      ...this.state.thermostatFields,
      siteId: value,
      spaceId: -1,
      networkGroupId: -1,
    };

    this.setState({
      thermostatFields: updatedFields,
      thermostatFormErrors: validateThermostatForm(updatedFields),
    });
  };

  renderDeviceForm = () => {
    const {
      currentStep,
      thermostatFields,
      thermostatFormErrorsVisible,
      thermostatFormErrors,
    } = this.state;

    const {
      hub,
      controlBoard,
      lockSiteId,
      onHubChange,
      onControlBoardChange,
      isNewSuperHub,
    } = this.props;

    if (currentStep === 0) {
      return (
        <>
          <ModalTitle>Devices</ModalTitle>
          <ModalContent>
            <SelectDeviceForm onChange={this.onDeviceSelection} />
          </ModalContent>
        </>
      );
    }

    switch (this.state.model) {
      case DeviceType.ENERGYBOX_HUB:
        return (
          <>
            <ModalTitle>Install Hub</ModalTitle>
            <ModalContent>
              <EditHubForm
                {...hub.fields}
                title={hub.fields.title}
                isNew={isNewSuperHub ? 'superHub' : true}
                lockSiteId={lockSiteId || Number(this.props.hubSiteId)}
                onChange={onHubChange}
                formErrors={hub.formErrors}
                formErrorsVisible={hub.formErrorsVisible}
              />
            </ModalContent>
          </>
        );

      case DeviceType.ENERGYBOX_CB:
        return (
          <>
            <ModalTitle>{CreateNewText.CONTROL_BOARD}</ModalTitle>
            <ModalContent>
              <EditControlBoardForm
                {...controlBoard.fields}
                isNew
                lockSiteId={lockSiteId}
                onChange={onControlBoardChange}
                formErrors={controlBoard.formErrors}
                formErrorsVisible={controlBoard.formErrorsVisible}
              />
            </ModalContent>
          </>
        );

      case DeviceType.THERMOSTAT:
        return (
          <>
            <ModalTitle>{CreateNewText.THERMOSTAT}</ModalTitle>
            <ModalContent>
              <EditThermostatForm
                isNew
                lockSiteId={lockSiteId}
                onChange={this.onThermostatFieldChange}
                onSelectSite={this.onThermostatSelectSite}
                fields={thermostatFields}
                isEbThermostat={isEBThermostat(thermostatFields.uuid)}
                formErrorsVisible={thermostatFormErrorsVisible}
                formErrors={thermostatFormErrors}
              />
            </ModalContent>
          </>
        );

      default:
        return null;
    }
  };

  render() {
    if (!this.props.isVisible) return null;

    const {
      hub,
      controlBoard,
      onClose,
      thermostatApiError,
      isThermostatLoading,
      isNewSuperHub,
    } = this.props;

    const { currentStep } = this.state;
    const isLoading =
      hub.isLoading || controlBoard.isLoading || isThermostatLoading;

    const actions = () => {
      if (currentStep === 1) {
        return (
          <div className={styles.actionWrapper}>
            <div className={styles.backButtonContainer}>
              {!isNewSuperHub && (
                <Button onClick={this.onBackButtonClick} variant="text">
                  <span className={styles.backButtonText}>
                    <IoIosArrowBack size={16} /> &nbsp; Back
                  </span>
                </Button>
              )}
            </div>
            <div>
              <Button variant="text" onClick={this.onDeviceModalClose}>
                Cancel
              </Button>
              <Button disabled={isLoading} onClick={this.onDeviceCreate}>
                {isLoading ? <Loader size={12} /> : 'Add'}
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <Button variant="text" onClick={this.onDeviceModalClose}>
            Cancel
          </Button>
        );
      }
    };

    return (
      <Modal onClose={onClose} actions={actions()} disableEscapeClose={true}>
        {this.renderDeviceForm()}
        <ModalContent>
          {renderAPIerror(hub.apiError, GatewayActions.CREATE_GATEWAY_ERROR)}
          {renderAPIerror(
            thermostatApiError,
            ThermostatActions.CREATE_THERMOSTAT_DEVICE_ERROR
          )}
          {renderAPIerror(
            controlBoard.apiError,
            ControlBoardActions.CREATE_CONTROL_BOARD_ERROR
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  thermostats,
  gateways,
  controlBoards,
}: ApplicationState) => ({
  isThermostatLoading: thermostats.createThermostatIsLoading,
  isVisible: gateways.showNewGatewayModal,
  thermostatApiError: thermostats.apiError,
  hub: gateways.editById['new'],
  controlBoard: controlBoards.editControlBoardById['new'],
  isNewSuperHub: gateways.isNewSuperHub,
  hubSiteId: gateways.siteId,
});

const mapDispatchToProps = {
  onClose: () => hideNewGatewayModal(),
  onControlBoardChange: (field: string, value: string) =>
    updateControlBoardFields('new', field, value),
  onControlBoardCreate: lockSiteId => createControlBoard(lockSiteId),
  onHubCreate: lockSiteId => create(lockSiteId),
  onHubChange: (field: string, value: string) =>
    updateField('new', field, value),
  displayControlBoardFormErrors,
  displayGatewayFormErrors,
  createThermostat,
  clearThermostatForm,
  clearHubForm,
  clearSiteControllerForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDeviceModal);
