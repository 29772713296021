import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getEnergyCalculationModes } from '../../actions/app';
import { ApplicationState } from '../../reducers';
import { EnergyCalculationMode } from '../../reducers/app';

interface OwnProps {
  onSelect: (energyCalculationModeId: string) => void;
  value?: string;
  error: boolean;
  disabled?: boolean;
  customErrorText?: string;
}

interface Props extends OwnProps {
  getEnergyCalculationModes: typeof getEnergyCalculationModes;
  energyCalculationModes?: EnergyCalculationMode[];
}

interface State {
  search: string;
}

class SelectEnergyCalculationModes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { energyCalculationModes, getEnergyCalculationModes } = this.props;
    if (!energyCalculationModes) getEnergyCalculationModes();
  }

  render() {
    const {
      energyCalculationModes,
      value,
      onSelect,
      error,
      disabled,
      customErrorText,
    } = this.props;

    if (!energyCalculationModes) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;

      const listedenergyCalculationModes = (
        energyCalculationModes || []
      ).filter((eq: EnergyCalculationMode) => {
        if (search.length <= 2) return true;
        return hasSubstr(eq.title, search);
      });

      const selectedEnergyCalculationModes = energyCalculationModes.find(
        eq => eq.id === value
      );

      return (
        <Select
          disabled={disabled}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedEnergyCalculationModes?.title}
          value={selectedEnergyCalculationModes?.id}
          error={error}
          customErrorText={customErrorText}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
          />
          {listedenergyCalculationModes.map((eq: EnergyCalculationMode) => (
            <SelectItem
              key={eq.id}
              isSelected={eq.id === selectedEnergyCalculationModes?.id}
              onSelect={() => onSelect(eq.id)}
            >
              {eq.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  energyCalculationModes: app.energyCalculationModes,
});

const mapDispatchToProps = {
  getEnergyCalculationModes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectEnergyCalculationModes);
