import {
  GenericErrors,
  EditableReportPeriod,
  Report,
  ReportType,
} from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import lensProp from 'ramda/src/lensProp';
import mergeRight from 'ramda/src/mergeRight';
import path from 'ramda/src/path';
import pick from 'ramda/src/pick';
import pipe from 'ramda/src/pipe';
import view from 'ramda/src/view';

import { Actions } from '../actions/reports';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

const initialState = {
  showNewReportModal: false,
  showDeleteReportModal: false,
  reportsById: {},
  editReportById: {},
  reportsBySiteId: {},
  isReportsBySiteIdLoading: false,
};

export type Reports = {
  showNewReportModal: boolean;
  showDeleteReportModal: boolean;
  reportsById: ReportsById;
  editReportById: EditReportById;
  reportsBySiteId: ReportsBySiteId;
  isReportsBySiteIdLoading: boolean;
};

export type ReportsBySiteId = {
  [siteId: string]: ReportsById;
};

export type EditReportById = {
  [id: string]: EditReport;
};

export type EditReport = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

export interface EditableFields {
  title: string;
  description: string;
  organizationUnitId: number;
  resourceIds: number[];
  periods: EditableReportPeriod[];
  reportType: ReportType;
  useAverageOfReadings: boolean;
}

export const newReportFieldPeriod = { from: '', to: '' };

const newReportFields = {
  title: '',
  description: '',
  organizationUnitId: -1,
  resourceIds: [],
  periods: [{ ...newReportFieldPeriod }],
  reportType: undefined,
  useAverageOfReadings: false,
};

const newReport = {
  isLoading: false,
  isChanged: false,
  fields: newReportFields,
  formErrors: formValidationErrors('report', newReportFields),
  formErrorsVisible: false,
  apiError: {},
};

export type ReportsById = {
  [reportId: string]: Report;
};

const editableFields = (equipment: object) =>
  pick(
    [
      'title',
      'description',
      'resourceIds',
      'periods',
      'reportType',
      'organizationUnitId',
      'useAverageOfReadings',
    ],
    equipment
  );

const reports = (state: Reports = initialState, action: any) => {
  switch (action.type) {
    case Actions.TOGGLE_NEW_REPORT_MODAL:
      return pipe(
        assocPath(['editReportById', 'new'], newReport),
        assoc('showNewReportModal', action.value)
      )(state);

    case Actions.TOGGLE_DELETE_REPORT_MODAL:
      return assoc('showDeleteReportModal', action.value, state);

    case Actions.DISPLAY_FORM_ERRORS:
      return assocPath(
        ['editReportById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = assoc(
        action.field,
        action.value,
        path(['editReportById', action.id, 'fields'], state)
      );
      return pipe(
        assocPath(['editReportById', action.id, 'fields'], updatedField),
        assocPath(['editReportById', action.id, 'isChanged'], true),
        assocPath(
          ['editReportById', action.id, 'formErrors'],
          formValidationErrors('report', updatedField)
        )
      )(state);

    case Actions.GET_REPORTS_BY_SITE_ID_LOADING:
      return assoc('isReportsBySiteIdLoading', true, state);

    case Actions.GET_REPORTS_BY_SITE_ID_ERROR:
      return assoc('isReportsBySiteIdLoading', false, state);

    case Actions.GET_REPORTS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['reportsBySiteId', action.siteId],
          mapArrayToObject(action.data)
        ),
        assoc(
          'reportsById',
          mergeRight(
            view(lensProp('reportsById'), state),
            mapArrayToObject(action.data)
          )
        ),
        assoc('isReportsBySiteIdLoading', false)
      )(state);

    case Actions.GET_REPORT_SUCCESS:
      const editFields = editableFields(action.data);
      return pipe(
        assocPath(['reportsById', action.reportId], action.data),
        assocPath(['editReportById', action.reportId], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editFields,
          apiError: {},
          formErrors: formValidationErrors('report', editFields),
        })
      )(state);

    case Actions.CREATE_REPORT_SUCCESS:
      return pipe(
        assocPath(
          ['reportsBySiteId', action.data.organizationUnitId, action.data.id],
          action.data
        ),
        assocPath(['reportsById', action.data.id], action.data),
        assocPath(['editReportById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(action.data),
          formErrors: formValidationErrors(
            'report',
            editableFields(action.data)
          ),
          apiError: {},
        }),
        assoc('showNewReportModal', false)
      )(state);

    case Actions.CREATE_REPORT_LOADING:
      return assocPath(['editReportById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_REPORT_ERROR:
      return pipe(
        assocPath(['editReportById', 'new', 'apiError'], storeAPIerror(action)),
        assocPath(['editReportById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_REPORT_LOADING:
      return assocPath(['editReportById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_REPORT_SUCCESS:
      return pipe(
        assocPath(['reportsById', action.id], action.data),
        assocPath(['editReportById', action.id, 'isChanged'], false),
        assocPath(['editReportById', action.id, 'isLoading'], false),
        assocPath(['editReportById', action.id, 'apiError'], {}),
        assocPath(
          ['reportsBySiteId', action.data.organizationUnitId, action.data.id],
          action.data
        ),
        assoc('showNewReportModal', false)
      )(state);

    case Actions.PATCH_REPORT_ERROR:
      return pipe(
        assocPath(
          ['editReportById', action.id, 'apiError'],
          storeAPIerror(action)
        ),
        assocPath(['editReportById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DELETE_REPORT_LOADING:
      return pipe(assocPath(['editReportById', action.id, 'isLoading'], true))(
        state
      );

    case Actions.DELETE_REPORT_SUCCESS:
      return pipe(
        dissocPath(['reportsById', action.id]),
        dissocPath(['editReportById', action.id]),
        dissocPath(['editReportById', action.id]),
        dissocPath(['reportsBySiteId', action.siteId, action.reportId]),
        assoc('showDeleteReportModal', false)
      )(state);

    case Actions.DELETE_REPORT_ERROR:
      return pipe(
        assocPath(
          ['editReportById', action.id, 'apiError'],
          storeAPIerror(action)
        ),
        assocPath(['editReportById', action.id, 'isLoading'], false)
      )(state);

    default:
      return state;
  }
};

export default reports;
