import {
  Button,
  FormText,
  InputField,
  Label,
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as UserActions,
  addScopeToUser,
  addUserToGroup,
  create,
  displayFormErrors,
  getUser,
  hideNewUserModal,
  updateField,
} from '../../actions/users';
import ModalFormContent from '../../components/ModalFormContent';
import { ApplicationState } from '../../reducers';
import { EditUser } from '../../reducers/users';
import { CreateNewText, PropertyToLabel } from '../../types/global';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import UserPositionSelect from './UserPositionSelect';

import styles from './ShowUserPage.module.css';
import { getGroups } from '../../actions/user_groups';
import InstallerGroupSelect from './InstallerGroupSelect';
import MultiSelectOrgAccess from '../Selects/MultiSelectOrgAccess';
import { Role } from '@energybox/react-ui-library/dist/types';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';

export enum Steps {
  information,
  access,
  success,
}

const NewInstallerModal: React.FC = () => {
  const { information, access, success } = Steps;

  const dispatch = useDispatch();

  const [step, setStep] = useState(information);
  const [installerGroupId, setInstallerGroupId] = useState<number>();
  const [orgAccess, setOrgAccess] = useState<number[]>([]);
  const [userId, setUserId] = useState<string>('installer');

  const showNewUserModal = useSelector<ApplicationState, boolean>(
    ({ users }) => users.showNewUserModal
  );

  const {
    isLoading,
    formErrorsVisible,
    fields,
    formErrors,
    apiError,
  } = useSelector<ApplicationState, EditUser>(
    ({ users }) => users.editById[userId] || {}
  );

  const currentOrgId = useCurrentOrganizationId();

  const onChange = (field: string, value: string) =>
    dispatch(updateField(userId, field, value));

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  const resetLocalState = () => {
    setUserId('installer');
    setStep(information);
    setInstallerGroupId(undefined);
    setOrgAccess([]);
  };

  const onAction = () => {
    if (step === information) {
      setStep(access);
    } else if (step === access) {
      if (hasKeys(formErrors)) {
        dispatch(displayFormErrors(userId));
      } else {
        onChange('position', 'Installer');
        dispatch(create({ installer: true, callback: onUserCreated }));
      }
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (hasKeys(formErrors) && formErrorsVisible) {
      setStep(information);
    }
  }, [formErrors, formErrorsVisible]);

  const onUserCreated = ({ id }: { id?: number }) => {
    if (id) {
      const createdUserId = '' + id;
      dispatch(getUser(createdUserId));
      // required to update editById and thus retrieve the email from selector
      setUserId(createdUserId);
      Promise.all<void>([
        new Promise(resolve => {
          // add the basic viewer scope to parent org
          // IMPORTANT: This scope must be added to ensure installer can have a
          // correct UI when they login; this is the only FE place that can add
          // this scope. If it fails, it has to be called separately from
          // postman
          if (currentOrgId)
            dispatch(
              addScopeToUser(
                createdUserId,
                {
                  resourceId: ['' + currentOrgId],
                  role: Role.VIEWER.toUpperCase(),
                },
                // seeems current redux structure is unable to handle
                // consecutive calls to same api, instead make the second scope
                // api call in a callback
                () => {
                  // grant org access if any
                  if (orgAccess.length > 0)
                    dispatch(
                      addScopeToUser(
                        createdUserId,
                        {
                          resourceId: orgAccess.map(id => '' + id),
                          role: Role.INSTALLER.toUpperCase(),
                        },
                        () => resolve()
                      )
                    );
                  else resolve();
                }
              )
            );
          else resolve();
        }),
        new Promise(resolve => {
          // add use to installer group if specified
          if (installerGroupId)
            dispatch(addUserToGroup(id, installerGroupId, () => resolve()));
          else resolve();
        }),
      ]).then(() => setStep(success));
    } else {
      setStep(information);
    }
  };

  if (!showNewUserModal) return null;

  const { firstName, lastName, email } = fields || {};

  const onClose = () => {
    dispatch(hideNewUserModal());
    resetLocalState();
  };

  const actions = (
    <>
      {step !== success && (
        <Button variant="text" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
      )}
      <Button disabled={isLoading} onClick={onAction}>
        {step === information ? (
          'Next'
        ) : step === success ? (
          'OK'
        ) : isLoading ? (
          <Loader size={16} variant="secondary" />
        ) : (
          'Save'
        )}
      </Button>
    </>
  );

  return (
    <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
      {step !== success && (
        <>
          <ModalTitle>{CreateNewText.INSTALLER}</ModalTitle>
          <ModalContent className={styles.installerModalContent}>
            <Tabs className={styles.installerModalTabs}>
              <Tab
                active={step === information}
                onClick={() => setStep(information)}
              >
                User Information
              </Tab>
              <Tab active={step === access} onClick={() => setStep(access)}>
                User Access
              </Tab>
            </Tabs>
            {step === information && (
              <>
                <ModalFormContent>
                  <b>
                    <Label required>{PropertyToLabel.firstName}</Label>
                  </b>
                  <div>
                    <InputField
                      type="text"
                      name="firstName"
                      // autoComplete="new-password" is to remove any auto complete behaviour
                      // this work on FireFox, Safari and Chrome
                      // see more in https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
                      autoComplete="new-password"
                      value={firstName}
                      onChange={e =>
                        onChange(e.currentTarget.name, e.currentTarget.value)
                      }
                      error={formErrorsVisible && !!formErrors.firstName}
                      customErrorText={
                        formErrors?.firstName && formErrors?.firstName[0]
                      }
                      disabled={isLoading}
                    />
                  </div>

                  <b>
                    <Label required>{PropertyToLabel.lastName}</Label>
                  </b>
                  <div>
                    <InputField
                      type="text"
                      name="lastName"
                      value={lastName}
                      autoComplete="new-password"
                      onChange={e =>
                        onChange(e.currentTarget.name, e.currentTarget.value)
                      }
                      error={formErrorsVisible && !!formErrors.lastName}
                      customErrorText={
                        formErrors?.lastName && formErrors?.lastName[0]
                      }
                      disabled={isLoading}
                    />
                  </div>

                  <b>
                    <Label required>{PropertyToLabel.email}</Label>
                  </b>
                  <div>
                    <InputField
                      type="email"
                      name="email"
                      value={email}
                      autoComplete="new-password"
                      onChange={e =>
                        onChange(e.currentTarget.name, e.currentTarget.value)
                      }
                      error={formErrorsVisible && !!formErrors.email}
                      customErrorText={
                        formErrors?.email && formErrors?.email[0]
                      }
                      disabled={isLoading}
                    />
                  </div>

                  <b>
                    <Label required>{PropertyToLabel.position}</Label>
                  </b>
                  <div>
                    <UserPositionSelect
                      disabled={true}
                      onSelect={value => onChange('position', value)}
                      value="Installer"
                    />
                  </div>

                  <b>
                    <Label>Installer Group</Label>
                  </b>
                  <div>
                    <InstallerGroupSelect
                      value={installerGroupId}
                      onSelect={id => setInstallerGroupId(id)}
                      disabled={isLoading}
                    />
                  </div>
                </ModalFormContent>
                <ModalFormContent>
                  <FormText>* Mandatory fields</FormText>
                </ModalFormContent>
                {renderAPIerror(apiError, UserActions.CREATE_USER_ERROR)}
              </>
            )}
            {step === access && (
              <>
                <div className={styles.userAccessPrompt}>
                  Select Org for Installer Access Rights
                </div>
                <ModalFormContent className={styles.userAccess}>
                  <b>
                    <Label>User Rights</Label>
                  </b>
                  <div className={styles.predefined}>Installer</div>
                  <b>
                    <Label>Select Organizations</Label>
                  </b>
                  <div>
                    <MultiSelectOrgAccess
                      onSelect={id => setOrgAccess([...orgAccess, id])}
                      onToggleSelectAll={ids => setOrgAccess(ids)}
                      onDeselect={removeId =>
                        setOrgAccess(orgAccess.filter(id => id !== removeId))
                      }
                      selectedOrgIds={orgAccess}
                      disabled={isLoading}
                    />
                  </div>
                </ModalFormContent>
              </>
            )}
          </ModalContent>
        </>
      )}
      {step === success && (
        <>
          <ModalTitle>New User Created</ModalTitle>
          <ModalContent>
            An email has been sent to the email address (
            <a href={`mailto:${email}`}>{email}</a>) for user to confirm their
            account and create password.
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

export default NewInstallerModal;
