const theme = {
  size: {
    table: {
      searchBox: {
        web: '15rem',
        mobile: '100%',
      },
    },
    icon: {
      medium: '1rem',
    },
  },
};

export default theme;
