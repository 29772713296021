import { ApplicationState } from '../reducers';

export enum Actions {
  GET_ORGANIZATIONS_SUCCESS = '@organizations/GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_ERROR = '@organizations/GET_ORGANIZATIONS_ERROR',
  GET_ORGANIZATIONS_LOADING = '@organizations/GET_ORGANIZATIONS_LOADING',

  GET_ORGANIZATION_RESOURCES_SUCCESS = '@organizations/GET_ORGANIZATION_RESOURCES_SUCCESS',
  GET_ORGANIZATION_RESOURCES_ERROR = '@organizations/GET_ORGANIZATION_RESOURCES_ERROR',
  GET_ORGANIZATION_RESOURCES_LOADING = '@organizations/GET_ORGANIZATION_RESOURCES_LOADING',

  GET_CURRENT_ORGANIZATION_SUCCESS = '@organizations/GET_CURRENT_ORGANIZATION_SUCCESS',
  GET_CURRENT_ORGANIZATION_ERROR = '@organizations/GET_CURRENT_ORGANIZATION_ERROR',
  GET_CURRENT_ORGANIZATION_LOADING = '@organizations/GET_CURRENT_ORGANIZATION_LOADING',

  PATCH_ORGANIZATION_SUCCESS = '@organizations/PATCH_ORGANIZATION_SUCCESS',
  PATCH_ORGANIZATION_ERROR = '@organizations/PATCH_ORGANIZATION_ERROR',
  PATCH_ORGANIZATION_LOADING = '@organizations/PATCH_ORGANIZATION_LOADING',

  UPDATE_FIELD = '@organizations/UPDATE_FIELD',
  RESET_EDIT_ORGANIZATION = '@organizations/RESET_EDIT_ORGANIZATION',
  DISPLAY_FORM_ERRORS = '@organizations/DISPLAY_FORM_ERRORS',

  UPLOAD_PICTURE_SUCCESS = '@organizations/UPLOAD_PICTURE_SUCCESS',
  UPLOAD_PICTURE_ERROR = '@organizations/UPLOAD_PICTURE_ERROR',
  UPLOAD_PICTURE_LOADING = '@organizations/UPLOAD_PICTURE_LOADING',

  DELETE_PICTURE_SUCCESS = '@organizations/DELETE_PICTURE_SUCCESS',
  DELETE_PICTURE_ERROR = '@organizations/DELETE_PICTURE_ERROR',
  DELETE_PICTURE_LOADING = '@organizations/DELETE_PICTURE_LOADING',
}

const apiBase = '/api/v1/organizations';

export const getOrganizations = () => ({
  type: 'API_GET',
  path: apiBase,
  success: Actions.GET_ORGANIZATIONS_SUCCESS,
  error: Actions.GET_ORGANIZATIONS_ERROR,
  loading: Actions.GET_ORGANIZATIONS_LOADING,
});

export const getCurrentOrganization = () => ({
  type: 'API_GET',
  path: `${apiBase}/current`,
  success: Actions.GET_CURRENT_ORGANIZATION_SUCCESS,
  error: Actions.GET_CURRENT_ORGANIZATION_ERROR,
  loading: Actions.GET_CURRENT_ORGANIZATION_LOADING,
});

export const patch = (id: string) => (dispatch, getState) => {
  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: (getState() as ApplicationState).organizations.editById[id].fields,
    success: [
      { type: Actions.PATCH_ORGANIZATION_SUCCESS, id },
      getCurrentOrganization(),
    ],
    loading: { type: Actions.PATCH_ORGANIZATION_LOADING, id },
    error: { type: Actions.PATCH_ORGANIZATION_ERROR, id },
  });
};

export const updateField = (
  id: string,
  field: string,
  value: any,
  isEB: boolean = false
) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
  isEB,
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_ORGANIZATION,
  id,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const uploadPicture = (id: string, formData) => ({
  type: 'API_POST',
  path: `${apiBase}/${id}/image`,
  payload: formData,
  ignoreContentType: true,
  loading: { type: Actions.UPLOAD_PICTURE_LOADING, id },
  success: [
    { type: Actions.UPLOAD_PICTURE_SUCCESS, id },
    getCurrentOrganization(),
  ],
  error: { type: Actions.UPLOAD_PICTURE_ERROR, id },
});

export const deletePicture = (id: string, path: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}/image?path=${path}`,
  loading: { type: Actions.DELETE_PICTURE_LOADING, id, path },
  success: [
    { type: Actions.DELETE_PICTURE_SUCCESS, id, path },
    getCurrentOrganization(),
  ],
  error: { type: Actions.DELETE_PICTURE_ERROR, id, path },
});

export const getOrganizationResources = () => ({
  type: 'API_GET',
  path: '/api/v1/organizations/current/stats',
  success: Actions.GET_ORGANIZATION_RESOURCES_SUCCESS,
  error: Actions.GET_ORGANIZATION_RESOURCES_ERROR,
  loading: Actions.GET_ORGANIZATION_RESOURCES_LOADING,
});
