import { Actions as AppActions } from '../actions/app';
import history from '../history';
import { LocalStorageKey } from '../utils/localStorage';

const getToken = (): string | null => {
  return window.localStorage.getItem(LocalStorageKey.TOKEN);
};

const setToken = (authToken: string) => {
  window.localStorage.setItem(LocalStorageKey.TOKEN, authToken);
};

const removeToken = () => {
  window.localStorage.removeItem(LocalStorageKey.TOKEN);
};

const updateToken = (store: any, accessToken: string) => {
  setToken(accessToken);

  store.dispatch({
    type: AppActions.ACCESS_TOKEN_UPDATED,
    accessToken: accessToken,
  });
};

const authMiddleware = store => next => action => {
  switch (action.type) {
    case AppActions.INIT:
      const accessToken = getToken();

      if (accessToken) {
        store.dispatch({
          type: AppActions.ACCESS_TOKEN_UPDATED,
          accessToken,
        });
      }
      break;

    case AppActions.INIT_WITH_TOKEN:
    case AppActions.LOGIN_SUCCESS:
    case AppActions.RESET_PASSWORD_SUCCESS:
      updateToken(store, action.data.accessToken);
      history.push('/', {});
      window.location.reload();
      break;

    case AppActions.CHANGE_ORGANIZATION_SUCCESS:
      updateToken(store, action.data.accessToken);
      history.push('/');
      window.location.reload();
      break;

    case AppActions.LOGOUT:
      removeToken();
      break;

    default:
      break;
  }

  next(action);
};

export default authMiddleware;
