import {
  TemperatureUnit,
  SensorType,
  TemperatureControl,
  TemperatureControlLabel,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import {
  FormText,
  Label,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';

import React, { useState } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import SelectSensor from '../../containers/Selects/SelectSensor';
import {
  DEFAULT_HYSTERESIS_VALUE,
  MAXIMUM_HYSTERESIS_VALUE,
  MINIMUM_HYSTERESIS_VALUE,
} from '../../reducers/temperature_controls';
import convertTemp, { convertHysteresis } from '../../utils/temperature';
import { isNumber } from '../../validators';
import ModalFormContent from '../ModalFormContent';
import TemperatureInputField from '../TemperatureInputField';
import styles from './EditControlsTemperatureFormBasicContent.module.css';
import { useTemperatureUnit } from '../../hooks/utils';

type Props = {
  equipmentId: number;
  spaceId?: number;
  siteId: number;
  onChange: (field: string, value: any) => void;
  fields: TemperatureControl;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
};

const calculateSwitchOffPoint = (setPoint, hysteresis, type, isImperial) => {
  const h = isImperial
    ? Number(convertTemp(hysteresis, 'c', 2)) - Number(convertTemp(0, 'c', 2))
    : hysteresis;
  const sp = isImperial ? convertTemp(setPoint, 'c', 2) : setPoint;

  return (sp + (type === 'HEATING' ? h : h * -1)).toFixed(1);
};

const EditControlsTemperatureFormBasicContent = ({
  equipmentId,
  spaceId,
  siteId,
  onChange,
  fields,
  formErrors,
  formErrorsVisible,
}: Props) => {
  const { sensorId, setPoint, hysteresis, type } = fields;
  const temperatureUnit = useTemperatureUnit();
  const isImperial = temperatureUnit === TemperatureUnit.F;

  const MAXIMUM_HYSTERESIS_VALUE_CONVERTED = isImperial
    ? convertHysteresis(MAXIMUM_HYSTERESIS_VALUE, 'c', 1)
    : MAXIMUM_HYSTERESIS_VALUE;
  const MINIMUM_HYSTERESIS_VALUE_CONVERTED = isImperial
    ? convertHysteresis(MINIMUM_HYSTERESIS_VALUE, 'c', 1)
    : MINIMUM_HYSTERESIS_VALUE;
  const DEFAULT_HYSTERESIS_VALUE_CONVERTED = isImperial
    ? convertHysteresis(DEFAULT_HYSTERESIS_VALUE, 'c', 1)
    : DEFAULT_HYSTERESIS_VALUE;

  const [helpExpansion, setHelpExpansion] = useState(false);

  return (
    <>
      <ModalFormContent>
        <div>
          <Label required htmlFor="sensorId">
            {TemperatureControlLabel.TEMP_SENSOR}
          </Label>
        </div>
        <div>
          <SelectSensor
            siteId={siteId}
            sensorType={SensorType.TEMPERATURE}
            value={sensorId.toString()}
            onSelect={sensorId => onChange('sensorId', sensorId)}
            error={formErrorsVisible && !!formErrors.sensorId}
            customErrorText={
              formErrors.sensorId !== undefined &&
              formErrors.sensorId.length > 0
                ? formErrors.sensorId[0]
                : ''
            }
          />
        </div>

        <div>
          <Label required htmlFor="setPoint">
            {TemperatureControlLabel.SET_POINT}
          </Label>
        </div>
        <TemperatureInputField
          className={styles.inputField}
          id="setPoint"
          name="setPoint"
          value={setPoint}
          onChange={v => onChange('setPoint', v)}
          error={formErrorsVisible && !!formErrors.setPoint}
          customErrorText={formErrors.setPoint && formErrors.setPoint[0]}
        />

        <div>
          <Label required htmlFor="hysteresis">
            {TemperatureControlLabel.HYSTERESIS}
          </Label>
        </div>
        <div className={styles.inputWithIcon}>
          <div className={styles.column}>
            <div className={styles.row}>
              <TemperatureInputField
                className={styles.inputField}
                id="hysteresis"
                name="hysteresis"
                value={hysteresis}
                onChange={v => onChange('hysteresis', v)}
                error={formErrorsVisible && !!formErrors.hysteresis}
                customErrorText={'Invalid'}
              />

              <Tooltip
                underline={false}
                arrowDirection="right"
                extraClassNames={[styles.marginLeft]}
                content={
                  <div>
                    <div className={styles.tooltipText}>
                      <div>
                        The hysteresis prevents relays from oscillating when
                        close to the set-point temperature.
                      </div>
                      <div>
                        In control theory this is also referred to as a double
                        set-point control.
                      </div>
                    </div>

                    <div
                      className={`${styles.tooltipText} ${styles.paddingTop}`}
                    >
                      <div className={styles.tooltipLineEntry}>
                        <span>Default</span>
                        <span>
                          {`${DEFAULT_HYSTERESIS_VALUE_CONVERTED} ${temperatureUnit} `}
                        </span>
                      </div>
                      <div className={styles.tooltipLineEntry}>
                        <span>Minimum</span>
                        <span>
                          {`${MINIMUM_HYSTERESIS_VALUE_CONVERTED} ${temperatureUnit} `}
                        </span>
                      </div>
                      <div className={styles.tooltipLineEntry}>
                        <span>Maximum</span>
                        <span>
                          {`${Number(
                            MAXIMUM_HYSTERESIS_VALUE_CONVERTED
                          ).toFixed(1)} ${temperatureUnit} `}
                        </span>
                      </div>
                    </div>
                  </div>
                }
              >
                <IoIosInformationCircleOutline
                  color={
                    formErrorsVisible && !!formErrors.hysteresis
                      ? 'var(--pink-base)'
                      : 'black'
                  }
                  size={20}
                />
              </Tooltip>
            </div>

            {isNumber(hysteresis) && isNumber(setPoint) && (
              <span
                className={styles.greyText}
              >{`Will switch off at ${calculateSwitchOffPoint(
                setPoint,
                hysteresis,
                type,
                isImperial
              )}${temperatureUnit}.`}</span>
            )}
          </div>
        </div>

        <FormText>
          <Label>* Mandatory fields</Label>
        </FormText>
      </ModalFormContent>
    </>
  );
};

export default EditControlsTemperatureFormBasicContent;
