import { ApplicationState } from '../../reducers';
import { Actions } from '../../actions/sensors';

export const sensorsByIdSelector = ({ sensors }: ApplicationState) =>
  sensors.sensorsById;

export const sensorIdsBySiteIdSelector = ({ sensors }: ApplicationState) =>
  sensors.sensorIdsBySiteId;

export const sensorsLoadingBySiteIdSelector = ({ sensors }: ApplicationState) =>
  sensors.loadingStatusByAction[Actions.GET_SENSORS_BY_SITE_ID_LOADING];
