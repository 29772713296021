import { omit } from 'ramda';

import { ApplicationState } from '../reducers';
import { Actuator } from '@energybox/react-ui-library/dist/types';
import { getByResourceId, getGatewaysByNetworkGroupId } from './gateways';
import { getNonHvacEquipments } from './aggregate_views';

const apiBase = '/api/v1/control-boards';

export enum Actions {
  CREATE_CONTROL_BOARD_SUCCESS = '@control_boards/CREATE_CONTROL_BOARD_SUCCESS',
  CREATE_CONTROL_BOARD_LOADING = '@control_boards/CREATE_CONTROL_BOARD_LOADING',
  CREATE_CONTROL_BOARD_ERROR = '@control_boards/CREATE_CONTROL_BOARD_ERROR',

  PATCH_CONTROL_BOARD_SUCCESS = '@control_boards/PATCH_CONTROL_BOARD_SUCCESS',
  PATCH_CONTROL_BOARD_LOADING = '@control_boards/PATCH_CONTROL_BOARD_LOADING',
  PATCH_CONTROL_BOARD_ERROR = '@control_boards/PATCH_CONTROL_BOARD_ERROR',

  GET_CONTROL_BOARDS_SUCCESS = '@control_boards/GET_CONTROL_BOARDS_SUCCESS',
  GET_CONTROL_BOARDS_ERROR = '@control_boards/GET_CONTROL_BOARDS_ERROR',
  GET_CONTROL_BOARDS_LOADING = '@control_boards/GET_CONTROL_BOARDS_LOADING',

  GET_CONTROL_BOARDS_BY_SITEID_SUCCESS = '@control_boards/GET_CONTROL_BOARDS_BY_SITEID_SUCCESS',
  GET_CONTROL_BOARDS_BY_SITEID_ERROR = '@control_boards/GET_CONTROL_BOARDS_BY_SITEID_ERROR',
  GET_CONTROL_BOARDS_BY_SITEID_LOADING = '@control_boards/GET_CONTROL_BOARDS_BY_SITEID_LOADING',

  GET_CONTROL_BOARD_SUCCESS = '@control_boards/GET_CONTROL_BOARD_SUCCESS',
  GET_CONTROL_BOARD_ERROR = '@control_boards/GET_CONTROL_BOARD_ERROR',
  GET_CONTROL_BOARD_LOADING = '@control_boards/GET_CONTROL_BOARD_LOADING',

  DELETE_CONTROL_BOARD_SUCCESS = '@control_boards/DELETE_CONTROL_BOARD_SUCCESS',
  DELETE_CONTROL_BOARD_LOADING = '@control_boards/DELETE_CONTROL_BOARD_LOADING',
  DELETE_CONTROL_BOARD_ERROR = '@control_boards/DELETE_CONTROL_BOARD_ERROR',

  GET_ACTUATORS_BY_EQUIPMENT_SUCCESS = '@control_boards/GET_ACTUATORS_BY_EQUIPMENT_SUCCESS',
  GET_ACTUATORS_BY_EQUIPMENT_ERROR = '@control_boards/GET_ACTUATORS_BY_EQUIPMENT_ERROR',
  GET_ACTUATORS_BY_EQUIPMENT_LOADING = '@control_boards/GET_ACTUATORS_BY_EQUIPMENT_LOADING',

  GET_ACTUATORS_BY_SITE_ID_SUCCESS = '@control_boards/GET_ACTUATORS_BY_SITE_ID_SUCCESS',
  GET_ACTUATORS_BY_SITE_ID_ERROR = '@control_boards/GET_ACTUATORS_BY_SITE_ID_ERROR',
  GET_ACTUATORS_BY_SITE_ID_LOADING = '@control_boards/GET_ACTUATORS_BY_SITE_ID_LOADING',

  CREATE_ACTUATOR_SUCCESS = '@control_boards/CREATE_ACTUATOR_SUCCESS',
  CREATE_ACTUATOR_LOADING = '@control_boards/CREATE_ACTUATOR_LOADING',
  CREATE_ACTUATOR_ERROR = '@control_boards/CREATE_ACTUATOR_ERROR',

  PATCH_ACTUATOR_SUCCESS = '@control_boards/PATCH_ACTUATOR_SUCCESS',
  PATCH_ACTUATOR_LOADING = '@control_boards/PATCH_ACTUATOR_LOADING',
  PATCH_ACTUATOR_ERROR = '@control_boards/PATCH_ACTUATOR_ERROR',

  DELETE_ACTUATOR_SUCCESS = '@control_boards/DELETE_ACTUATOR_SUCCESS',
  DELETE_ACTUATOR_LOADING = '@control_boards/DELETE_ACTUATOR_LOADING',
  DELETE_ACTUATOR_ERROR = '@control_boards/DELETE_ACTUATOR_ERROR',

  TOGGLE_NEW_ACTUATOR_MODAL = '@control_boards/TOGGLE_NEW_ACTUATOR_MODAL',
  TOGGLE_DELETE_ACTUATOR_MODAL = '@control_boards/TOGGLE_DELETE_ACTUATOR_MODAL',
  SHOW_EDIT_ACTUATOR_MODAL = '@control_boards/SHOW_EDIT_ACTUATOR_MODAL',
  HIDE_EDIT_ACTUATOR_MODAL = '@control_boards/HIDE_EDIT_ACTUATOR_MODAL',
  TOGGLE_NEW_CONTROLBOARD_MODAL = '@control_boards/TOGGLE_NEW_CONTROLBOARD_MODAL',

  UPDATE_FIELD = '@control_boards/UPDATE_FIELD',
  UPDATE_CONTROL_BOARD_FIELD = '@control_boards/UPDATE_CONTROL_BOARD_FIELD',

  DISPLAY_FORM_ERRORS = '@control_boards/DISPLAY_FORM_ERRORS',
  DISPLAY_CONTROL_BOARD_FORM_ERRORS = '@control_boards/DISPLAY_CONTROL_BOARD_FORM_ERRORS',

  POST_START_INSTALLER_TEST_LOADING = '@control_boards/POST_START_INSTALLER_TEST_LOADING',
  POST_START_INSTALLER_TEST_SUCCESS = '@control_boards/POST_START_INSTALLER_TEST_SUCCESS',
  POST_START_INSTALLER_TEST_ERROR = '@control_boards/POST_START_INSTALLER_TEST_ERROR',

  POST_END_INSTALLER_TEST_LOADING = '@control_boards/POST_END_INSTALLER_TEST_LOADING',
  POST_END_INSTALLER_TEST_SUCCESS = '@control_boards/POST_END_INSTALLER_TEST_SUCCESS',
  POST_END_INSTALLER_TEST_ERROR = '@control_boards/POST_END_INSTALLER_TEST_ERROR',

  POST_SWITCH_RELAYS_TEST_LOADING = '@control_boards/POST_SWITCH_RELAYS_TEST_LOADING',
  POST_SWITCH_RELAYS_TEST_SUCCESS = '@control_boards/POST_SWITCH_RELAYS_TEST_SUCCESS',
  POST_SWITCH_RELAYS_TEST_ERROR = '@control_boards/POST_SWITCH_RELAYS_TEST_ERROR',

  POST_UNMAPPED_RELAYS_TEST_LOADING = '@control_boards/POST_UNMAPPED_RELAYS_TEST_LOADING',
  POST_UNMAPPED_RELAYS_TEST_SUCCESS = '@control_boards/POST_UNMAPPED_RELAYS_TEST_SUCCESS',
  POST_UNMAPPED_RELAYS_TEST_ERROR = '@control_boards/POST_UNMAPPED_RELAYS_TEST_ERROR',

  POST_TEST_RESULTS_LOADING = '@control_boards/POST_TEST_RESULTS_LOADING',
  POST_TEST_RESULTS_SUCCESS = '@control_boards/POST_TEST_RESULTS_SUCCESS',
  POST_TEST_RESULTS_ERROR = '@control_boards/POST_TEST_RESULTS_ERROR',

  GET_TEST_RESULTS_SUCCESS = '@control_boards/GET_TEST_RESULTS_SUCCESS',
  GET_TEST_RESULTS_LOADING = '@control_boards/GET_TEST_RESULTS_LOADING',
  GET_TEST_RESULTS_ERROR = '@control_boards/GET_TEST_RESULTS_ERROR',

  CLEAR_FORM_ERRORS = '@control_boards/CLEAR_FORM_ERRORS',
}

export type GetControlBoardParams = {
  edgeIds?: number[];
  ids?: number[];
  limit?: number;
  models?: 'ENERGYBOX_HUB' | 'GHUB' | 'ENERGYBOX_CB';
  organizationIds?: number[];
  siblings?: boolean;
  siteIds?: number[];
  skip?: number[];
  spaceIds?: number[];
  textSearch?: string;
  vendors?: 'energybox' | 'monnit';
};

export const showNewActuatorModal = (equipmentId: number) => ({
  type: Actions.TOGGLE_NEW_ACTUATOR_MODAL,
  value: true,
  equipmentId,
});

export const hideNewActuatorModal = () => ({
  type: Actions.TOGGLE_NEW_ACTUATOR_MODAL,
  value: false,
});

export const showControlboardModal = () => ({
  type: Actions.TOGGLE_NEW_CONTROLBOARD_MODAL,
  value: true,
});

export const hideControlboardModal = () => ({
  type: Actions.TOGGLE_NEW_CONTROLBOARD_MODAL,
  value: false,
});

export const showEditActuatorModal = (
  actuatorId: number,
  equipmentId: number
) => ({
  type: Actions.SHOW_EDIT_ACTUATOR_MODAL,
  value: true,
  actuatorId,
  equipmentId,
});

export const hideEditActuatorModal = () => ({
  type: Actions.HIDE_EDIT_ACTUATOR_MODAL,
  value: false,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const updateControlBoardFields = (
  id: string,
  field: string,
  value: any
) => ({
  type: Actions.UPDATE_CONTROL_BOARD_FIELD,
  id,
  field,
  value,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const displayControlBoardFormErrors = (id: string) => ({
  type: Actions.DISPLAY_CONTROL_BOARD_FORM_ERRORS,
  value: true,
  id,
});

export const patch = (actuator: Actuator) => (dispatch, getState) => {
  const {
    port,
    title,
    description,
    portType,
  } = (getState() as ApplicationState).controlBoards.editById[
    actuator.id
  ].fields;

  const payload = { description, port, title, portType };

  dispatch({
    type: 'API_PUT',
    path: `${apiBase}/${actuator.controlBoardId}/actuator/${actuator.id}`,
    payload,
    loading: {
      type: Actions.PATCH_ACTUATOR_LOADING,
      actuatorId: actuator.id,
      equipmentId: actuator.equipmentId,
    },
    success: [
      {
        type: Actions.PATCH_ACTUATOR_SUCCESS,
        actuatorId: actuator.id,
        equipmentId: actuator.equipmentId,
      },
      getActuatorsByEquipment(actuator.equipmentId),
    ],
    error: {
      type: Actions.PATCH_ACTUATOR_ERROR,
      actuatorId: actuator.id,
      equipmentId: actuator.equipmentId,
    },
  });
};

export const createControlBoard = (
  lockSiteId?: number,
  networkGroupId?: number
) => (dispatch, getState) => {
  return dispatch({
    type: 'API_POST',
    path: apiBase,
    payload: (getState() as ApplicationState).controlBoards
      .editControlBoardById['new'].fields,
    loading: Actions.CREATE_CONTROL_BOARD_LOADING,
    success: [
      Actions.CREATE_CONTROL_BOARD_SUCCESS,
      lockSiteId ? getByResourceId(lockSiteId) : '',
      networkGroupId ? getGatewaysByNetworkGroupId(networkGroupId!) : '',
    ],
    error: Actions.CREATE_CONTROL_BOARD_ERROR,
  });
};

export const patchControlBoard = (id: string | number) => (
  dispatch,
  getState
) => {
  return dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: (getState() as ApplicationState).controlBoards
      .editControlBoardById[id].fields,
    loading: { type: Actions.PATCH_CONTROL_BOARD_LOADING, id },
    success: { type: Actions.PATCH_CONTROL_BOARD_SUCCESS, id },
    error: { type: Actions.PATCH_CONTROL_BOARD_ERROR, id },
  });
};

export const deleteControlBoard = (id: string | number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  success: Actions.DELETE_CONTROL_BOARD_SUCCESS,
  error: Actions.DELETE_CONTROL_BOARD_ERROR,
  loading: Actions.DELETE_CONTROL_BOARD_LOADING,
});

export const create = (equipmentId: number, orgId?: number) => (
  dispatch,
  getState
) => {
  const {
    controlBoardId,
    port,
    title,
    description,
    portType,
  } = (getState() as ApplicationState).controlBoards.editById['new'].fields;

  const payload = {
    description,
    equipmentId,
    port,
    title,
    portType,
  };

  dispatch({
    type: 'API_POST',
    path: `${apiBase}/${controlBoardId}/actuator`,
    payload,
    loading: Actions.CREATE_ACTUATOR_LOADING,
    success: [
      Actions.CREATE_ACTUATOR_SUCCESS,
      getActuatorsByEquipment(equipmentId),
      getNonHvacEquipments(orgId),
    ],
    id: 'new',
    error: Actions.CREATE_ACTUATOR_ERROR,
  });
};

export const destroy = (actuator: Actuator) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${actuator.controlBoardId}/actuator/${actuator.port}`,
  loading: {
    type: Actions.DELETE_ACTUATOR_LOADING,
    actuatorId: actuator.id,
  },
  success: [
    {
      type: Actions.DELETE_ACTUATOR_SUCCESS,
      actuatorId: actuator.id,
    },
    getActuatorsByEquipment(actuator.equipmentId),
  ],
  error: {
    type: Actions.DELETE_ACTUATOR_ERROR,
    actuatorId: actuator.id,
  },
});

export const showDeleteActuatorModal = () => ({
  type: Actions.TOGGLE_DELETE_ACTUATOR_MODAL,
  value: true,
});

export const hideDeleteActuatorModal = () => ({
  type: Actions.TOGGLE_DELETE_ACTUATOR_MODAL,
  value: false,
});

export const clearFormErrors = () => {
  return {
    type: Actions.CLEAR_FORM_ERRORS,
  };
};

const createQueryString = (params: GetControlBoardParams = {}) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getControlBoards = (params?: GetControlBoardParams) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString(params)}`,
  success: Actions.GET_CONTROL_BOARDS_SUCCESS,
  error: Actions.GET_CONTROL_BOARDS_ERROR,
  loading: Actions.GET_CONTROL_BOARDS_LOADING,
});

export const getControlBoardsBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/site/${siteId}`,
  success: { type: Actions.GET_CONTROL_BOARDS_BY_SITEID_SUCCESS, siteId },
  error: Actions.GET_CONTROL_BOARDS_BY_SITEID_ERROR,
  loading: Actions.GET_CONTROL_BOARDS_BY_SITEID_LOADING,
});

export const getControlBoardById = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: { type: Actions.GET_CONTROL_BOARD_SUCCESS, id },
  error: Actions.GET_CONTROL_BOARD_ERROR,
  loading: Actions.GET_CONTROL_BOARD_LOADING,
});

export const getActuatorsByEquipment = (equipmentId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/actuators?equipmentId=${equipmentId}`,
  success: {
    type: Actions.GET_ACTUATORS_BY_EQUIPMENT_SUCCESS,
    equipmentId,
  },
  error: Actions.GET_ACTUATORS_BY_EQUIPMENT_ERROR,
  loading: Actions.GET_ACTUATORS_BY_EQUIPMENT_LOADING,
});

export const getActuatorsBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/actuators/site/${siteId}`,
  success: {
    type: Actions.GET_ACTUATORS_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: Actions.GET_ACTUATORS_BY_SITE_ID_ERROR,
  loading: Actions.GET_ACTUATORS_BY_EQUIPMENT_LOADING,
});

export const startInstallerTest = (siteId: string | number) => ({
  type: 'API_POST',
  path: `${apiBase}/installer-test/site/${siteId}/start`,
  success: Actions.POST_START_INSTALLER_TEST_SUCCESS,
  error: Actions.POST_START_INSTALLER_TEST_ERROR,
  loading: Actions.POST_START_INSTALLER_TEST_LOADING,
});

export const endInstallerTest = (siteId: string | number) => ({
  type: 'API_POST',
  path: `${apiBase}/installer-test/site/${siteId}/end`,
  success: Actions.POST_END_INSTALLER_TEST_SUCCESS,
  error: Actions.POST_END_INSTALLER_TEST_ERROR,
  loading: Actions.POST_END_INSTALLER_TEST_LOADING,
});

export const relaySwitchInstallerTest = (
  equipmentId: string | number,
  payload: string
) => ({
  type: 'API_POST',
  payload: payload,
  path: `${apiBase}/installer-test/equipment/${equipmentId}`,
  success: Actions.POST_SWITCH_RELAYS_TEST_SUCCESS,
  error: Actions.POST_SWITCH_RELAYS_TEST_ERROR,
  loading: Actions.POST_SWITCH_RELAYS_TEST_LOADING,
});

export const unmappedRelayInstallerTest = (
  controlBoardId: string | number,
  payload: string
) => ({
  type: 'API_POST',
  payload: payload,
  path: `${apiBase}/installer-test/unmapped-relays/${controlBoardId}`,
  success: Actions.POST_UNMAPPED_RELAYS_TEST_SUCCESS,
  error: Actions.POST_UNMAPPED_RELAYS_TEST_ERROR,
  loading: Actions.POST_UNMAPPED_RELAYS_TEST_LOADING,
});

export const postTestResults = (
  equipmentId: string | number,
  payload: JSON
) => ({
  type: 'API_POST',
  payload: payload,
  path: `${apiBase}/installer-test/equipment/${equipmentId}/test-results`,
  success: Actions.POST_TEST_RESULTS_SUCCESS,
  error: Actions.POST_TEST_RESULTS_ERROR,
  loading: Actions.POST_TEST_RESULTS_LOADING,
});

export const getTestResults = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/installer-test/site/${siteId}/test-results`,
  success: Actions.GET_TEST_RESULTS_SUCCESS,
  error: Actions.GET_TEST_RESULTS_ERROR,
  loading: Actions.GET_TEST_RESULTS_LOADING,
});
