import { LoginPage } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import { equals } from 'ramda';

import {
  Actions as AppActions,
  login,
  updateLoginField,
} from '../../actions/app';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import LoginBackground from '../../static/patterns/login_background.jpg';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import { isEmail } from '../../validators';
import {
  authenticationErrorFromApiResponse,
  hasApiError,
} from '@energybox/react-ui-library/dist/utils';
import { LocalStorageKey } from '../../utils/localStorage';
import {
  LockState,
  isAccountLocked,
} from '@energybox/react-ui-library/dist/components/LoginPage/LoginPage';

interface Props {
  email: string;
  password: string;
  isPasswordReset: boolean;
  apiError: ApiError;
  login: () => void;
  onChange: (field: string, value: string) => void;
}

interface State {
  email: string;
  password: string;
  lockState: LockState;
  lockedFor?: number;
  lockWatch?: NodeJS.Timeout;
}

class LoginPageContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      lockState: LockState.normal,
    };
  }

  // remember the credentials between failed logins
  // as 401 status returned from failed logins triggers LOGOUT action and
  // reset the login form
  onChange = (field: string, value: string) => {
    let newState = { ...this.state };
    newState[field] = value;
    this.setState(newState);
    this.props.onChange(field, value);
  };
  repopulateCredentials = () => {
    const { email, password } = this.state;

    if (!this.props.email && !!email) {
      this.props.onChange('email', email);
    }
    if (!this.props.password && !!password) {
      this.props.onChange('password', password);
    }
  };

  validateForm = () => {
    const { email, password } = this.props;
    return email !== '' && password !== '' && isEmail(email);
  };

  handleLogin = (e: any) => {
    e.preventDefault();
    this.props.login();
  };

  lock = (lockedOrUnlocktime: true | Date, remainingTime?: number) => {
    // sometimes two updates are so close that two intervals are created
    if (this.state.lockWatch) clearInterval(this.state.lockWatch);

    if (lockedOrUnlocktime === true) {
      this.setState({ lockState: LockState.suspended });
      return;
    }
    this.setState({
      lockState: LockState.timeLocked,
      lockedFor: remainingTime,
    });

    const lockWatch = setInterval(() => {
      const lockedFor = this.getRemainTime(lockedOrUnlocktime);
      if (lockedFor)
        this.setState({ lockState: LockState.timeLocked, lockedFor });
      else this.unlock();
    }, 1000);
    this.setState({ lockWatch });
  };
  getRemainTime = (unlockTime: Date) => {
    const diff = unlockTime.getTime() - new Date().getTime();
    return Math.max(Math.round(diff / 1000), 0);
  };
  unlock = () => {
    let newState = { lockState: LockState.normal, lockedFor: undefined };
    if (this.state.lockWatch) {
      newState['lockWatch'] = undefined;
      clearInterval(this.state.lockWatch);
    }
    this.setState(newState);
  };
  retrieveLock = () => {
    const lockDetails = localStorage.getItem(LocalStorageKey.ACCOUNT_LOCKED);
    if (lockDetails) {
      const { email, ...authenticationError } = JSON.parse(lockDetails);
      const lockedOrUnlocktime = isAccountLocked(authenticationError);

      const remainingTime =
        typeof lockedOrUnlocktime !== 'boolean'
          ? this.getRemainTime(lockedOrUnlocktime)
          : undefined;
      // prevent extra lock action if the lock just lapsed ie lockedFor = 0

      if (lockedOrUnlocktime === false || remainingTime === 0) {
        localStorage.removeItem(LocalStorageKey.ACCOUNT_LOCKED);
      } else {
        this.lock(lockedOrUnlocktime, remainingTime);

        // repopulate the email if page refreshes when locked
        if (!this.props.email) {
          this.setState({ email });
          this.props.onChange('email', email);
        }
      }
    }
  };
  clearLock = () => {
    localStorage.removeItem(LocalStorageKey.ACCOUNT_LOCKED);
    window.location.reload();
  };

  componentDidUpdate(prevProps: Props, _: State) {
    this.repopulateCredentials();

    const { apiError } = this.props;
    const { apiError: prevApiError } = prevProps;

    if (!equals(apiError, prevApiError) && hasApiError(apiError)) {
      const authenticationError = authenticationErrorFromApiResponse(
        apiError.payload
      );

      if (authenticationError && isAccountLocked(authenticationError)) {
        localStorage.setItem(
          LocalStorageKey.ACCOUNT_LOCKED,
          JSON.stringify({
            ...authenticationError,
            email: this.state.email,
          })
        );
      }
    }

    if (this.state.lockState === LockState.normal) this.retrieveLock();
  }

  componentDidMount(): void {
    this.setState({
      email: this.props.email,
      password: this.props.password,
    });
    this.retrieveLock();
  }

  componentWillUnmount(): void {
    this.unlock();
  }

  render() {
    const { email, password, isPasswordReset, apiError } = this.props;
    const { lockState, lockedFor } = this.state;

    return (
      <LoginPage
        email={email}
        password={password}
        isPasswordReset={isPasswordReset}
        apiError={renderAPIerror(apiError, AppActions.LOGIN_ERROR)}
        validateForm={this.validateForm}
        handleLogin={this.handleLogin}
        handleChange={this.onChange}
        forgotPasswordRoute={Routes.FORGOT_PASSWORD}
        backgroundImage={LoginBackground}
        lockState={lockState}
        lockedFor={lockedFor}
        clearLock={this.clearLock}
      />
    );
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  email: app.loginForm.email,
  password: app.loginForm.password,
  isPasswordReset: app.isPasswordReset,
  apiError: app.apiError,
});

const mapDispatchToProps = {
  onChange: updateLoginField,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);
