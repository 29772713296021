import {
  User,
  SortDirection,
  ResourceType,
  Role,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
  hasSubstr,
  values,
} from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Columns,
  Select,
  SelectItem,
  SelectSearch,
  Table,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  addUserToGroup,
  getUsers,
  removeUserFromGroup,
} from '../../actions/users';
import AccessResourceRecord from '../../components/AccessResourceRecord';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { fullName } from '../../util';
import styles from './UserGroupUsersTable.module.css';

interface OwnProps {
  groupId: number;
  userIds: number[];
  isInstallerGroup?: boolean;
}

interface Props extends OwnProps {
  getAllUsers: typeof getUsers;
  allUsers: User[];
  addUser: (userId: number) => void;
  removeUser: (userId: number) => void;
}

interface State {
  selectSearch: string;
}

const positionComparator = (a: User, b: User, sortDirection: SortDirection) => {
  return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
    'position',
  ]);
};

class UserGroupUsersTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectSearch: '',
    };
  }

  componentDidMount() {
    this.props.getAllUsers();
  }

  selectSearchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      selectSearch: e.currentTarget.value,
    });
  }

  resetSelectSearch() {
    this.setState({
      selectSearch: '',
    });
  }

  selectOptions() {
    const { allUsers, userIds } = this.props;
    const { selectSearch } = this.state;

    return allUsers.filter((user: User) => {
      return (
        !userIds.includes(user.id) && hasSubstr(fullName(user), selectSearch)
      );
    });
  }

  displayedUsers() {
    const { allUsers, userIds } = this.props;
    return allUsers.filter(({ id }: User) => userIds.includes(id));
  }

  render() {
    const { addUser, removeUser, isInstallerGroup } = this.props;
    const { selectSearch } = this.state;
    const users = this.displayedUsers();

    const tableColumns = [
      {
        header: 'User',
        cellContent: (user: User) => (
          <Link to={`${Routes.USERS}/${user.id}`}>{fullName(user)}</Link>
        ),
        comparator: (a: User, b: User, sortDirection: SortDirection) => {
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            fullName
          );
        },
      },
      ...(isInstallerGroup
        ? [
            {
              header: 'Position',
              cellContent: ({ position }: User) => <>{position || '-'}</>,
              comparator: positionComparator,
            },
            {
              header: 'Access to Organizations',
              cellContent: ({ accessResources }: User) => (
                <>
                  {(accessResources || [])
                    .filter(
                      resource =>
                        resource.resourceType === ResourceType.ORGANIZATION &&
                        resource.role === Role.INSTALLER
                    )
                    .map(resource => (
                      <AccessResourceRecord key={resource.id} {...resource} />
                    ))}
                </>
              ),
            },
          ]
        : [
            {
              header: 'Position / Work Place',
              cellContent: ({ position, organizationUnitTitle }: User) => (
                <>
                  <b>{position || '-'}</b>
                  <div>{organizationUnitTitle || '-'}</div>
                </>
              ),
              comparator: positionComparator,
            },
            {
              header: 'Site Access',
              cellContent: ({ accessResources }: User) => (
                <>
                  {(accessResources || []).map(resource => (
                    <AccessResourceRecord key={resource.id} {...resource} />
                  ))}
                </>
              ),
            },
          ]),
      {
        header: 'Email',
        cellContent: ({ email }: User) => (
          <a href={`mailto:${email}`}>{email}</a>
        ),
        comparator: (a: User, b: User, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'email',
          ]);
        },
      },
      {
        header: '',
        width: '15%',
        cellContent: ({ id }: User) => (
          <Button
            className={styles.removeUserButton}
            variant="text"
            onClick={() => removeUser(id)}
          >
            <span className={styles.paddingRight}>Remove user</span>
            <FaRegTimesCircle size={16} />
          </Button>
        ),
      },
    ];

    return (
      <>
        <Columns template="0.7fr 0.3fr">
          <h4 className="m0">
            {isInstallerGroup ? 'Installers' : 'Users'} ({users.length})
          </h4>

          <Select
            variant="add"
            title="Add user"
            onClick={this.resetSelectSearch.bind(this)}
          >
            <SelectSearch
              onChange={this.selectSearchChange.bind(this)}
              value={selectSearch}
            />

            {this.selectOptions().map((user: User) => (
              <SelectItem
                key={user.id}
                onSelect={() => addUser(user.id)}
                noRadioButton
              >
                {fullName(user)}
              </SelectItem>
            ))}
          </Select>
        </Columns>

        <Table columns={tableColumns} data={users} />
      </>
    );
  }
}

const mapStateToProps = ({ users, userGroups }: ApplicationState) => ({
  allUsers: values(users.usersById).sort((a: User, b: User) =>
    fullName(a).localeCompare(fullName(b))
  ),
});

const mapDispatchToProps = (dispatch: any, { groupId }: OwnProps) => ({
  getAllUsers: () => dispatch(getUsers()),
  removeUser: (userId: number) =>
    dispatch(removeUserFromGroup(userId, groupId)),
  addUser: (userId: number) => dispatch(addUserToGroup(userId, groupId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserGroupUsersTable);
