import {
  Label,
  MenuDropdown,
  MenuDropdownItem,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import { useSubscribeToThermostat } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import {
  ControlBoard,
  ControlsType,
  FanModeLabel,
  HvacControl,
  HvacControlLabel,
  MeasurementSystemToTemperatureLabel,
  ThermostatWorkingMode,
  ThermostatWorkingModeLabel,
  VenstarAdditionalTemperatureReadingsMetadata,
  Thermostat,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import {
  capitalizeFirstLetterOnly,
  classNames,
  createTemperatureString,
  global,
  isDefined,
  renderLocalAdjustmentDegree,
} from '@energybox/react-ui-library/dist/utils';
import {
  renderLocalSetpointDeltaSign,
  renderOverrideTime,
  showThermostatDisplay,
  getThermostatDisplayLabel,
} from '@energybox/react-ui-library/dist/utils/thermostat';

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  subscribeToDeviceReadings,
  unsubscribeFromDeviceReadings,
} from '../../actions/streamApi';
import { getThermostatById } from '../../actions/thermostats';
import { useSetIsLocalOverrideActive } from '../../hooks/controls/util';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { useSelectControlBoardByEquipmentId } from '../../hooks/useControlBoard';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { convertSecToHrsText } from '../../utils/controls';
import { getTimetablesRoute } from '../../utils/timeTables';
import DeviceOnlineState, {
  DisplayType,
} from '../DeviceStatus/DeviceOnlineState';
import { useTemperatureUnit } from '../../hooks/utils';
import styles from './GenericControlsCard/GenericControlsCard.module.css';
import { ThermostatIcon } from '@energybox/react-ui-library/dist/icons';

type Props = {
  siteId: number;
  showEditControlModal: () => void;
  showDeleteControlModal: () => void;
  hvacControl: HvacControl;
  setIsLocalOverrideActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const HvacControlsContent: React.FC<Props> = ({
  siteId,
  showEditControlModal,
  showDeleteControlModal,
  hvacControl,
  setIsLocalOverrideActive,
}) => {
  const {
    equipmentId,
    thermostatId,
    minTemp,
    maxTemp,
    thermostatWorkingMode,
    fanMode,
    thermostatDisplayUnits,
    outsideMinTemp,
    outsideMaxTemp,
    outsideFanMode,
    timetable,
    beginDelta,
    endDelta,
    enableLocalAdjustment,
    setPointLimitDelta,
    overrideTimer,
    outsideOfTimeTable,
    workingMode,
  } = hvacControl;

  const isControlInCalibrationMode = workingMode === WorkingMode.CALIBRATION;

  //** Redux **//
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const temperatureUnit = useTemperatureUnit();

  const thermostat: Thermostat | undefined = useSelector(
    ({ thermostats }: ApplicationState) => {
      return thermostats.thermostatsById[thermostatId];
    }
  );

  const thermostatsById = useSelector(
    ({ subscribedThermostats }: ApplicationState) => {
      if (thermostat) {
        return subscribedThermostats.byId[thermostat.id];
      }
      return undefined;
    }
  );

  const subscribedThermostat = useSubscribeToThermostat(
    thermostat,
    () => thermostatsById,
    dispatch,
    subscribeToDeviceReadings,
    unsubscribeFromDeviceReadings
  );

  const isDesiredSettingsValid = isDefined(
    subscribedThermostat?.desiredSettings
  );

  const isLocalOverrideActive =
    subscribedThermostat?.localAdjustmentOverrideEnergybox;

  useSetIsLocalOverrideActive(isLocalOverrideActive, setIsLocalOverrideActive);

  const controlBoardForEquipment:
    | ControlBoard
    | undefined = useSelectControlBoardByEquipmentId(equipmentId);

  //** Actions **//
  const getThermostat = useCallback(() => {
    dispatch(getThermostatById(thermostatId));
  }, [dispatch, thermostatId]);

  //** useEffect **//
  useEffect(() => {
    getThermostat();
  }, [getThermostat]);

  const formatDeltaProperty = (delta: string | number) => {
    const deltaNum = Number(delta);
    return deltaNum >= 0 ? `+${deltaNum}m` : `${deltaNum}m`;
  };

  const configRemoteSensorsCount = thermostat?.wirelessTemperatureSensorsCount;
  const liveDetectedSensorsCount = getRemoteSensorsCount(
    subscribedThermostat?.additionalTemperatureReadings
  );
  const isLocalAdjustmentActive = (subscribedThermostat?.overrideTime || 0) > 0;

  if (!user) return null;
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>Controls: {ControlsType.HVAC}</div>
        <div>
          <MenuDropdown>
            <MenuDropdownItem onSelect={showEditControlModal}>
              Edit Controls
            </MenuDropdownItem>
            <MenuDropdownItem isRed onSelect={showDeleteControlModal}>
              Delete Controls
            </MenuDropdownItem>
          </MenuDropdown>
        </div>
      </div>

      <hr className={styles.divider} />

      <div className={styles.contentContainer}>
        <div className={styles.icon}>
          <ThermostatIcon size={80} />
        </div>

        <div className={styles.columnContainer}>
          <div
            className={classNames(
              styles.column,
              isControlInCalibrationMode ? styles.greyText : ''
            )}
          >
            <Label>
              <span className={styles.boldText}>Settings Overview</span>
            </Label>
            <div />

            <Label>{HvacControlLabel.THERMOSTAT}</Label>
            <div className={styles.value}>
              {thermostat ? (
                <Link
                  to={`${Routes.DEVICES}${Routes.GATEWAYS}/${thermostat.id}`}
                >
                  {thermostat.title}
                </Link>
              ) : (
                global.NOT_AVAILABLE
              )}
            </div>

            <Label>{HvacControlLabel.REMOTE_SENSORS}</Label>
            <div className={styles.value}>
              {isDefined(thermostat)
                ? configRemoteSensorsCount
                : global.NOT_AVAILABLE}
            </div>

            <Label>{HvacControlLabel.THERMOSTAT_MODE}</Label>
            <div className={styles.value}>
              {ThermostatWorkingModeLabel[thermostatWorkingMode]}
            </div>

            {(thermostatWorkingMode === ThermostatWorkingMode.HEATING ||
              thermostatWorkingMode === ThermostatWorkingMode.AUTO) && (
              <>
                <Label>{HvacControlLabel.MIN_TEMP}</Label>
                <div className={styles.value}>
                  {isDefined(minTemp)
                    ? createTemperatureString(minTemp, user, 0)
                    : global.NOT_AVAILABLE}
                </div>
              </>
            )}

            {(thermostatWorkingMode === ThermostatWorkingMode.COOLING ||
              thermostatWorkingMode === ThermostatWorkingMode.AUTO) && (
              <>
                <Label>{HvacControlLabel.MAX_TEMP}</Label>
                <div className={styles.value}>
                  {isDefined(maxTemp)
                    ? createTemperatureString(maxTemp, user, 0)
                    : global.NOT_AVAILABLE}
                </div>
              </>
            )}

            <Label>{HvacControlLabel.FAN_MODE}</Label>
            <div className={styles.value}>{FanModeLabel[fanMode]}</div>

            <Label>{HvacControlLabel.THERMOSTAT_UNIT}</Label>
            <div className={styles.value}>
              {MeasurementSystemToTemperatureLabel[thermostatDisplayUnits]}
            </div>

            <Label>{HvacControlLabel.LOCAL_ADJUSTMENT}</Label>
            <div className={styles.value}>
              {enableLocalAdjustment === true ? 'Enabled' : 'Disabled'}
            </div>

            <Label>{HvacControlLabel.SETPOINT_LIMITS}</Label>
            <div className={styles.value}>
              {Number.isSafeInteger(setPointLimitDelta)
                ? `${renderLocalSetpointDeltaSign(
                    thermostatWorkingMode
                  )}${setPointLimitDelta}${temperatureUnit}`
                : global.NOT_AVAILABLE}
            </div>

            <Label>{HvacControlLabel.REVERT_ADJUSTMENT}</Label>
            <div className={styles.value}>
              {Number.isSafeInteger(setPointLimitDelta)
                ? convertSecToHrsText(overrideTimer)
                : global.NOT_AVAILABLE}
            </div>

            <Label>
              <span className={styles.boldText}>Schedule Overview</span>
            </Label>
            <div />

            <Label>{HvacControlLabel.TIME_TABLE}</Label>
            <div className={styles.value}>
              {timetable ? (
                <Link
                  to={`${getTimetablesRoute({
                    orgUnitId: timetable.organizationUnitId,
                    timetableId: timetable.id,
                    isOrgLevelTimetable:
                      timetable.organizationUnitId !== siteId,
                  })}`}
                >
                  {timetable.title}
                </Link>
              ) : (
                global.NOT_AVAILABLE
              )}
            </div>

            <Label>Offset</Label>
            <div className={styles.doubleColumn}>
              <div className={classNames(styles.value, styles.marginRight)}>
                Start{` `}
                {isDefined(beginDelta)
                  ? formatDeltaProperty(beginDelta)
                  : global.NOT_AVAILABLE}
              </div>
              <div className={classNames(styles.value, styles.marginRight)}>
                {':'}
              </div>
              <div className={classNames(styles.value)}>
                End{` `}
                {isDefined(endDelta)
                  ? formatDeltaProperty(endDelta)
                  : global.NOT_AVAILABLE}
              </div>
            </div>

            {(thermostatWorkingMode === ThermostatWorkingMode.HEATING ||
              thermostatWorkingMode === ThermostatWorkingMode.AUTO) && (
              <>
                <Label>{HvacControlLabel.OUTSIDE_MIN_TEMP}</Label>
                <div className={styles.value}>
                  {outsideMinTemp
                    ? createTemperatureString(outsideMinTemp, user, 0)
                    : global.NOT_AVAILABLE}
                </div>
              </>
            )}

            {(thermostatWorkingMode === ThermostatWorkingMode.COOLING ||
              thermostatWorkingMode === ThermostatWorkingMode.AUTO) && (
              <>
                <Label>{HvacControlLabel.OUTSIDE_MAX_TEMP}</Label>
                <div className={styles.value}>
                  {outsideMaxTemp
                    ? createTemperatureString(outsideMaxTemp, user, 0)
                    : global.NOT_AVAILABLE}
                </div>
              </>
            )}

            <Label>Outside {HvacControlLabel.FAN_MODE}</Label>
            <div className={styles.value}>
              {isDefined(outsideFanMode)
                ? FanModeLabel[outsideFanMode]
                : global.NOT_AVAILABLE}
            </div>
          </div>
        </div>

        <div className={styles.columnContainer}>
          <div className={styles.column}>
            <Label>
              <span className={styles.boldText}>Live Status</span>
            </Label>
            <div />

            <Label>{HvacControlLabel.ONLINE_STATUS}</Label>
            <div className={styles.value}>
              {renderOnlineStatus(thermostat, controlBoardForEquipment)}
            </div>

            <div className={styles.dividerLine} />
            <span className={styles.streamTimestampText}>
              Updated{' '}
              {<TimeDistance timestamp={subscribedThermostat?.timestamp} />}
            </span>

            <Label>{HvacControlLabel.THERMOSTAT_TEMPERATURE}</Label>
            <div className={styles.value}>
              {isDefined(subscribedThermostat) &&
              isDefined(subscribedThermostat?.temperature)
                ? createTemperatureString(
                    subscribedThermostat.temperature,
                    user
                  )
                : global.NOT_AVAILABLE}
            </div>

            <Label>{HvacControlLabel.NUMBER_OF_SENSORS}</Label>
            <div
              className={classNames(
                determineLiveStatusFieldTextColor(
                  configRemoteSensorsCount,
                  liveDetectedSensorsCount
                ),
                styles.value
              )}
            >
              {`${
                isDefined(liveDetectedSensorsCount)
                  ? liveDetectedSensorsCount
                  : global.NOT_AVAILABLE
              } detected / ${
                isDefined(configRemoteSensorsCount)
                  ? configRemoteSensorsCount
                  : global.NOT_AVAILABLE
              } configured`}
            </div>

            <div className={styles.dividerLine} />
            <div className={styles.dividerLine} />

            {/* Currently not a real data point, but might add this feature in the future      */}
            {/* <Label>
              <div>Outdoor Temperature</div>
              <div>(optional)</div>
            </Label>
            <div className={styles.value}>
              {venstarThermostatStatus.outdoorTemperature !== null
                ? createTemperaturString(
                    venstarThermostatStatus.outdoorTemperature,
                    measurementSystem
                  )
                : global.NOT_AVAILABLE}
            </div> */}

            {isDefined(subscribedThermostat) &&
              (subscribedThermostat?.mode === ThermostatWorkingMode.HEATING ||
                subscribedThermostat?.mode === ThermostatWorkingMode.AUTO) && (
                <>
                  <Label>{HvacControlLabel.MIN_TEMP}</Label>
                  <div>
                    <div
                      className={classNames(
                        determineLiveStatusFieldTextColor(
                          subscribedThermostat?.desiredSettings?.heatSetPoint,
                          subscribedThermostat?.heatSetPoint,
                          isDesiredSettingsValid
                        ),
                        styles.value
                      )}
                    >
                      {isDefined(subscribedThermostat.heatSetPoint)
                        ? createTemperatureString(
                            subscribedThermostat.heatSetPoint,
                            user,
                            0
                          )
                        : global.NOT_AVAILABLE}
                    </div>

                    {/* only display local adjustment temp difference
                    when revert timer is active */}
                    {isLocalAdjustmentActive && (
                      <div className={styles.sopDeviation}>
                        {renderLocalAdjustmentDegree(
                          subscribedThermostat,
                          user
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

            {isDefined(subscribedThermostat) &&
              (subscribedThermostat?.mode === ThermostatWorkingMode.COOLING ||
                subscribedThermostat?.mode === ThermostatWorkingMode.AUTO) && (
                <>
                  <Label>{HvacControlLabel.MAX_TEMP}</Label>
                  <div>
                    <div
                      className={classNames(
                        determineLiveStatusFieldTextColor(
                          subscribedThermostat?.desiredSettings?.coolSetPoint,
                          subscribedThermostat?.coolSetPoint,
                          isDesiredSettingsValid
                        ),
                        styles.value
                      )}
                    >
                      {isDefined(subscribedThermostat.coolSetPoint)
                        ? createTemperatureString(
                            subscribedThermostat.coolSetPoint,
                            user,
                            0
                          )
                        : global.NOT_AVAILABLE}
                    </div>

                    {/* only display local adjustment temp difference
                    when revert timer is active */}
                    {isLocalAdjustmentActive && (
                      <div className={styles.sopDeviation}>
                        {renderLocalAdjustmentDegree(
                          subscribedThermostat,
                          user
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

            <Label>{HvacControlLabel.REVERT_ADJUSTMENT} in</Label>
            <div className={styles.value}>
              {isDefined(subscribedThermostat) &&
              isDefined(subscribedThermostat.overrideTime)
                ? renderOverrideTime(
                    subscribedThermostat.overrideTime,
                    hvacControl.overrideTimer,
                    subscribedThermostat?.vendor !== 'energybox'
                  )
                : global.NOT_AVAILABLE}
            </div>

            <Label>{HvacControlLabel.THERMOSTAT_MODE}</Label>
            <div
              className={classNames(
                determineLiveStatusFieldTextColor(
                  subscribedThermostat?.desiredSettings?.mode,
                  subscribedThermostat?.mode,
                  isDesiredSettingsValid
                ),
                styles.value
              )}
            >
              {isDefined(subscribedThermostat) &&
              isDefined(subscribedThermostat.mode)
                ? ThermostatWorkingModeLabel[subscribedThermostat.mode]
                : global.NOT_AVAILABLE}
            </div>

            <Label>{HvacControlLabel.FAN_MODE}</Label>
            <div
              className={classNames(
                determineLiveStatusFieldTextColor(
                  subscribedThermostat?.desiredSettings?.fanMode,
                  subscribedThermostat?.fanMode,
                  isDesiredSettingsValid
                ),
                styles.value
              )}
            >
              {isDefined(subscribedThermostat) &&
              isDefined(subscribedThermostat.fanMode)
                ? FanModeLabel[subscribedThermostat.fanMode]
                : global.NOT_AVAILABLE}
            </div>

            <Label>{HvacControlLabel.THERMOSTAT_UNIT}</Label>
            <div
              className={classNames(
                determineLiveStatusFieldTextColor(
                  subscribedThermostat?.desiredSettings?.tempUnits,
                  subscribedThermostat?.tempUnits,
                  isDesiredSettingsValid
                ),
                styles.value
              )}
            >
              {isDefined(subscribedThermostat)
                ? capitalizeFirstLetterOnly(
                    subscribedThermostat.tempUnits || ''
                  )
                : global.NOT_AVAILABLE}
            </div>

            <Label>{HvacControlLabel.THERMOSTAT_DISPLAY}</Label>
            <div
              className={classNames(
                determineScreenLockedTextColor(
                  enableLocalAdjustment,
                  isDefined(subscribedThermostat)
                    ? showThermostatDisplay(subscribedThermostat)
                    : false
                ),
                styles.value
              )}
            >
              {isDefined(subscribedThermostat) &&
                getThermostatDisplayLabel(subscribedThermostat)}
            </div>

            <div className={styles.dividerLine} />
            <div className={styles.dividerLine} />

            <Label>Heating Stage 1</Label>
            <div className={styles.value}>
              {isDefined(subscribedThermostat)
                ? displayActiveOrIdle(subscribedThermostat.heatingStage1Running)
                : global.NOT_AVAILABLE}
            </div>

            <Label>Heating Stage 2</Label>
            <div className={styles.value}>
              {isDefined(subscribedThermostat)
                ? displayActiveOrIdle(subscribedThermostat.heatingStage2Running)
                : global.NOT_AVAILABLE}
            </div>

            <Label>Cooling Stage 1</Label>
            <div className={styles.value}>
              {isDefined(subscribedThermostat)
                ? displayActiveOrIdle(subscribedThermostat.coolingStage1Running)
                : global.NOT_AVAILABLE}
            </div>

            <Label>Cooling Stage 2</Label>
            <div className={styles.value}>
              {isDefined(subscribedThermostat)
                ? displayActiveOrIdle(subscribedThermostat.coolingStage2Running)
                : global.NOT_AVAILABLE}
            </div>

            <Label>Fan Output</Label>
            <div className={styles.value}>
              {isDefined(subscribedThermostat)
                ? displayActiveOrIdle(subscribedThermostat.fanRunning)
                : global.NOT_AVAILABLE}
            </div>

            <div className={styles.dividerLine} />
            <div className={styles.dividerLine} />

            <Label>Local Override</Label>
            <div className={styles.value}>
              {renderLocalOverride(isLocalOverrideActive)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const displayActiveOrIdle = (value: boolean) => {
  if (value) {
    return 'Active';
  }
  return 'Idle';
};

export const renderLocalOverride = (
  isLocalOverrideActive: boolean | null | undefined
) => {
  if (isLocalOverrideActive === true) {
    return <span className={styles.redText}>Active</span>;
  } else if (isLocalOverrideActive === false) {
    return <span>Inactive</span>;
  } else {
    return <span>{global.NOT_AVAILABLE}</span>;
  }
};

export const renderOnlineStatus = (
  thermostat: Thermostat | undefined,
  controlBoardForEquipment?: ControlBoard | undefined
) => {
  if (!isDefined(thermostat)) {
    return global.NOT_AVAILABLE;
  }

  let devicesToSubscribeTo: EntityInfoToSub[] = [
    {
      id: thermostat.id,
      uuid: thermostat.uuid,
      vendor: thermostat.vendor,
    },
  ];

  if (isDefined(controlBoardForEquipment)) {
    devicesToSubscribeTo.push({
      id: controlBoardForEquipment.id,
      uuid: controlBoardForEquipment.uuid,
      vendor: controlBoardForEquipment.vendor,
    });
  }

  return (
    <DeviceOnlineState
      displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
      devices={devicesToSubscribeTo}
    />
  );
};

export const getRemoteSensorsCount = (
  additionalTemperatureReadingsList:
    | VenstarAdditionalTemperatureReadingsMetadata[]
    | null
    | undefined
) => {
  if (!isDefined(additionalTemperatureReadingsList)) return 0;
  return additionalTemperatureReadingsList.reduce((count, { type }) => {
    if (type === 'Remote') return count + 1;
    return count;
  }, 0);
};

export const determineScreenLockedTextColor = (
  limitedAdjustabilityEnabled: boolean | undefined | null,
  screenLocked: boolean | undefined | null
) => {
  if (!isDefined(limitedAdjustabilityEnabled) || !isDefined(screenLocked)) {
    return '';
  }

  const isInvalid =
    (limitedAdjustabilityEnabled === true && screenLocked === true) ||
    (limitedAdjustabilityEnabled === false && screenLocked === false);

  if (isInvalid) {
    return styles.redText;
  }

  return '';
};

export const determineLiveStatusFieldTextColor = (
  configField: number | string | undefined | null,
  liveField: number | string | undefined | null,
  isConfigFieldValid: boolean = true
) => {
  if (isConfigFieldValid && configField !== liveField) {
    return styles.redText;
  }

  return '';
};

export default HvacControlsContent;
