import { Notification } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/notifications';

export interface Notifications {
  notificationsById: NotificationsById;
  loadingStatusByAction: ActionsLoadingStatus;
}

export type NotificationsById = {
  [id: string]: Notification;
};

export type ActionsLoadingStatus = {
  [Actions.GET_NOTIFICATIONS_LOADING]?: boolean;
};

const initialState = {
  notificationsById: {},
  loadingStatusByAction: {},
};

function reverseHandlingLogs(notification: Notification): Notification {
  const { handlingLogs, ...rest } = notification;
  return {
    ...rest,
    handlingLogs: [...handlingLogs].reverse(),
  };
}

export function sortByDateDescending(a: Notification, b: Notification): number {
  return (
    new Date(b.handlingLogs[0].at).valueOf() -
    new Date(a.handlingLogs[0].at).valueOf()
  );
}

//TODO: migrated from EB!, not utilized yet
// function sortByDateByUnseen(a: Notification, b: Notification): number {
//   if (a.dismissedBy) {
//     if (b.dismissedBy) {
//       return sortByDateDescending(a, b);
//     }
//     return 1;
//   } else if (b.dismissedBy) {
//     return -1;
//   }
//   return sortByDateDescending(a, b);
// }

const notificationFromApiResponse = (data: Notification) => ({
  createdAt: data.createdAt,
  dismissedAt: data.dismissedAt,
  dismissedComment: data.dismissedComment,
  handlingLogs: data.handlingLogs,
  id: data.id,
  sensorParams: data.sensorParams,
  sentinelId: data.sentinelId,
  sentinelTitle: data.sentinelTitle,
  sentinelType: data.sentinelType,
});

const notifications = (state: Notifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsById: {
          ...state.notificationsById,
          ...action.data
            .map((n: Notification) => notificationFromApiResponse(n))
            .map((n: Notification) => reverseHandlingLogs(n))
            .reduce((acc, n: Notification) => {
              acc[n.id] = n;
              return acc;
            }, {}),
        },
        loadingStatusByAction: {
          ...state.loadingStatusByAction,
          [Actions.GET_NOTIFICATIONS_LOADING]: false,
        },
      };
    case Actions.GET_NOTIFICATIONS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_NOTIFICATIONS_LOADING],
        true,
        state
      );

    //TODO: migrated from EB1, not being utilized yet
    // case Actions.DISMISS_SUCCESS: {
    //   const notificationIndex = state.findIndex(
    //     e => e.id === action.payload.id
    //   );

    //   return [
    //     ...state.slice(0, notificationIndex),
    //     reverseHandlingLogs(action.payload),
    //     ...state.slice(notificationIndex + 1),
    //   ];
    // }

    // case Actions.NOTIFICATIONS_CLEAR: {
    //   return initialState;
    // }

    // case Actions.ORDER_UNSEEN: {
    //   return [...state].sort(sortByDateByUnseen);
    // }

    // case Actions.ORDER_DATE: {
    //   return [...state].sort(sortByDateDescending);
    // }

    default: {
      return state;
    }
  }
};

export default notifications;
