import React, { useState } from 'react';
import convertTemp, {
  parseHysteresisValueForDisplay,
} from '../../utils/temperature';
import { isNumber, atMostOneDecimalRegex } from '../../validators';
import { TemperatureUnit } from '@energybox/react-ui-library/dist/types';

import { InputField } from '@energybox/react-ui-library/dist/components';

import styles from './TemperatureInputField.module.css';
import { useTemperatureUnit } from '../../hooks/utils';
import { parseValueForDisplay } from '@energybox/react-ui-library/dist/utils';

type Props = {
  id?: string;
  className?: string;
  error?: boolean;
  customErrorText?: string;
  disabled?: boolean;

  name: string;
  value: number | undefined;
  onChange: (newValue: number) => void;
  integersOnly?: boolean;
};

const TemperatureInputField: React.FC<Props> = ({
  className,
  value,
  onChange,
  id,
  name,
  error,
  customErrorText,
  disabled,
  integersOnly,
}) => {
  const temperatureUnit = useTemperatureUnit();
  const isImperial = temperatureUnit === TemperatureUnit.F;

  const isHysteresis = name === 'hysteresis';

  const [primedValue, setPrimedValue] = useState(
    isHysteresis
      ? parseHysteresisValueForDisplay(temperatureUnit, value)
      : parseValueForDisplay(temperatureUnit, value)
  );

  const onTemperatureInputFieldChange = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;

    if (value === '0-') {
      setPrimedValue('-');
      return;
    }

    if (value.match(atMostOneDecimalRegex) === null) {
      return;
    }

    if (!value) {
      setPrimedValue('0');
      return;
    }

    setPrimedValue(value);
  };

  const onTemperatureInputFieldBlur = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    if (primedValue === '-') {
      onChange(primedValue);
    }

    let v = parseFloat(primedValue);

    if (isHysteresis) {
      v = isImperial
        ? Number(convertTemp(v, 'f', 3)) - Number(convertTemp(0, 'f', 3))
        : v;
      v = isNumber(v) ? Number(v.toFixed(3)) : v;
    } else {
      v = isImperial ? Number(convertTemp(v, 'f', 3)) : v;
      v = isNumber(v) ? Number(v.toFixed(3)) : v;
    }

    onChange(v);
  };

  const displayValue = (primedValue: string) => {
    const isLastCharacterAPeriod = primedValue[primedValue.length - 1] === '.';

    if (primedValue === '' || primedValue === '-' || isLastCharacterAPeriod) {
      return primedValue;
    }

    const valueToDisplay = integersOnly
      ? Math.round(Number(primedValue))
      : +Number(primedValue).toFixed(1);

    return valueToDisplay;
  };

  return (
    <div className={styles.root}>
      <div>
        {/* this input allows any inputs,
            and then performs validation onBlur.
            Not ideal, but going this route
            due to constant conversion between F/C
            and too many numeric input edge cases to cover

            also, DON'T USE type="number"
            it won't allow initial input of "-"
            which we need
        */}
        <InputField
          // type="number"
          placeholder={'0'}
          className={className}
          id={id}
          name={name}
          value={displayValue(primedValue)}
          onChange={onTemperatureInputFieldChange}
          onBlur={onTemperatureInputFieldBlur}
          error={error}
          customErrorText={customErrorText}
          disabled={disabled}
        />
      </div>
      {temperatureUnit}
    </div>
  );
};

export default TemperatureInputField;
