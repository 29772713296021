import {
  TimeTableRow,
  TimeTableRowStore,
  TimeTableSpecialRow,
  TimeTableSpecialStore,
} from '@energybox/react-ui-library/dist/types';
import { TimetableOverrideType } from '@energybox/react-ui-library/dist/types/TimeTable';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { Routes } from '../routes';

const createDeltaTime = (time: string) => {
  if (time.substring(time.length - 1, time.length) !== 'm') {
    return parseInt(time) >= 0 ? `+${time}m` : `${time}m`;
  }
  return time;
};

export const check24hrOptionSettings = (fields: any) => {
  if (!fields) {
    return null;
  }
  // Check for contradictory 24hr-option settings in timetable rows
  let alwaysOnWeekdays = new Set();
  let alwaysOffWeekdays = new Set();
  let conflictingDays = new Set();
  fields.rows.forEach(row => {
    if (row.overrideType === TimetableOverrideType.ALWAYS_ON) {
      row.weekdays.forEach(weekday => alwaysOnWeekdays.add(weekday));
    }
    if (row.overrideType === TimetableOverrideType.ALWAYS_OFF) {
      row.weekdays.forEach(weekday => alwaysOffWeekdays.add(weekday));
    }
  });
  alwaysOnWeekdays.forEach(day => {
    if (alwaysOffWeekdays.has(day)) {
      conflictingDays.add(day);
    }
  });
  if (conflictingDays.size > 0) {
    let dayList = '';
    conflictingDays.forEach(day => {
      dayList = dayList + ' ' + day;
    });
    return dayList;
  }
  return null;
};

export const denormalizeTimeTableRow = (
  row: TimeTableRowStore
): TimeTableRow => {
  const data = {
    begin: {
      delta: row.beginType === 'CLOCK' ? '' : createDeltaTime(row.beginTime),
      time:
        row.overrideType === TimetableOverrideType.AUTO
          ? row.beginType === 'CLOCK'
            ? row.beginTime
            : null
          : '00:00',
      type: row.beginType,
    },
    end: {
      delta: row.endType === 'CLOCK' ? '' : createDeltaTime(row.endTime),
      time:
        row.overrideType === TimetableOverrideType.AUTO
          ? row.endType === 'CLOCK'
            ? row.endTime
            : null
          : row.overrideType === TimetableOverrideType.ALWAYS_OFF
          ? '00:00'
          : '23:59:59',
      type: row.endType,
    },
    weekdays: row.weekdays,
    overrideType: row.overrideType,
  };
  return data;
};

export const denormalizeTimeTableRows = (
  rows: TimeTableRowStore[]
): TimeTableRow[] => {
  return rows.map(row => {
    return denormalizeTimeTableRow(row);
  });
};

export const denormalizeTimeTableSpecialRow = (
  row: TimeTableSpecialStore
): TimeTableSpecialRow => {
  const data = {
    title: row.title,
    rows: denormalizeTimeTableRows(row.rows),
    dateRanges: row.dateRanges,
  };

  return data;
};

export const denormalizeTimeTableSpecialRows = (
  rows: TimeTableSpecialStore[]
): TimeTableSpecialRow[] => {
  return rows.map(row => {
    return denormalizeTimeTableSpecialRow(row);
  });
};

export const getTimetablesRoute = ({
  orgUnitId,
  timetableId,
  isOrgLevelTimetable = false,
}: {
  orgUnitId?: string | number;
  timetableId?: string | number | null;
  isOrgLevelTimetable?: boolean;
}) => {
  if (orgUnitId === undefined) {
    return '';
  }
  const timetableIdFragment = isDefined(timetableId) ? `#${timetableId}` : '';
  if (isOrgLevelTimetable) {
    return `${Routes.SOPS}${Routes.TIME_TABLES}${timetableIdFragment}`;
  }
  return `${Routes.SITES}/${orgUnitId}${Routes.SOPS}${Routes.TIME_TABLES}${timetableIdFragment}`;
};
