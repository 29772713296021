import { Actions as AppActions } from '../actions/app';
import { EnergyboxService, getServiceUrl } from '../config';
import { genHeaders } from '../utils/apiUtils';
import { Tracer } from '../telemetry/tracer';

/**** DO NOT MODIFY WITHOUT DISCUSSING WITH THE TEAM *********

  - Every backend interaction on our platform is currently done through redux
  using this middleware.
  - This is NOT the place for custom logic.
  - This is also legacy code built upon flawed architecture.

  - Ideally, we should eventually deprecate this for
  a more flexible, less bloat-y module
  However, there is no major gain for such a major refactor at this time.

  - We should slowly phase out the use of redux for API calls
  once a suitable module is created.

*************************************************************/

const ALLOWED_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'UPDATE', 'DELETE'];

const matcher = new RegExp(`^API_(${ALLOWED_METHODS.join('|')})$`);

const tracer = new Tracer();

const dispatchAction = (store: any, action: any, data: any = {}) => {
  if (typeof action === 'string') {
    store.dispatch({ type: action, ...data });
  } else if (Array.isArray(action)) {
    action.forEach(a => {
      dispatchAction(store, a, data);
    });
  } else {
    store.dispatch({ ...action, ...data });
  }
};

const requestCache = new Set<string>();

const apiMiddleware = store => next => action => {
  if (action.type == 'API_TRACE_START') {
    const { tracingScope } = action;
    tracer.startTrace(tracingScope);
  }

  const matchedGroup = action.type.match(matcher);
  if (matchedGroup) {
    const { path, payload, service, callback, tracingName } = action;

    const successAction = action.success || '@@API/UNHANDLED_SUCCESS_RESPONSE';
    const errorAction = action.error || '@@API/UNHANDLED_ERROR_RESPONSE';

    const fixedService = service || EnergyboxService.iam;
    const serviceUrl = getServiceUrl(service);
    const urlKey = serviceUrl + path;
    const cacheKey = `${matchedGroup[1]}-${urlKey}`;

    if (action.loading || (action.type && action.type.loading)) {
      if (requestCache.has(cacheKey)) {
        return;
      }

      requestCache.add(cacheKey);
      dispatchAction(store, action.loading);
    }
    const span = tracer.startSpan(tracingName ?? fixedService + ':' + path);
    const headers = {
      ...genHeaders(store, action, serviceUrl),
      ...tracer.genTraceHeader(span, fixedService),
    };

    return fetch(urlKey, {
      method: matchedGroup[1],
      headers,
      body:
        headers['Content-Type'] === 'application/json'
          ? JSON.stringify(payload)
          : payload,
    })
      .then(response => {
        tracer.endSpan(
          span,
          response.status < 300
            ? { code: Tracer.SpanStatusCode.OK }
            : {
                code: Tracer.SpanStatusCode.ERROR,
                message: response.statusText,
              }
        );

        if (response.status === 401) {
          store.dispatch({ type: AppActions.LOGOUT });
        }

        const time = response.headers.get('servertime');
        if (time)
          store.dispatch({ type: AppActions.SET_LAST_SERVER_TIME, time });

        const responseContentType = response.headers.get('content-type');
        if (responseContentType && response.status !== 204) {
          if (
            responseContentType.startsWith('application/json') ||
            responseContentType.startsWith('application/vnd.api+json')
          ) {
            return response.json();
          }
        }

        return response.text();
      })
      .then(response => {
        dispatchAction(store, response.error ? errorAction : successAction, {
          data: response,
        });
        requestCache.delete(cacheKey);

        if (callback) callback(response);
      })
      .catch(error => {
        tracer.endSpan(span, {
          code: Tracer.SpanStatusCode.ERROR,
          message: JSON.stringify(error),
        });
        dispatchAction(store, errorAction, { data: error });
        requestCache.delete(cacheKey);
      });
  } else {
    next(action);
  }
};

export default apiMiddleware;
