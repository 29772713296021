import React from 'react';
import {
  EnergyDeviceFromApi,
  EnergyPro,
} from '@energybox/react-ui-library/dist/types';
import EnergyDeviceConfiguration from '../EnergyDevices/EnergyDeviceConfiguration';
import { SelectedEnergyDevice } from '../DistributionPanels/ShowDistributionPanelPage/ShowDistributionPanelPage';

interface Props {
  energyPro: EnergyPro;
  energyDevice: EnergyDeviceFromApi | null;
  toggleCardState: (id: string) => void;
  expandedId: string;
  predeterminedBus: number;
  isFirstItem?: boolean;
  panelId: string;
  selectedEnergyDevice: SelectedEnergyDevice;
  resetSelectedEnergyDevice: () => void;
  handleEnergyProChange: (eProId: number) => void;
}

class RecursiveEnergyDevicesList extends React.Component<Props> {
  render() {
    const {
      energyDevice,
      toggleCardState,
      expandedId,
      predeterminedBus,
      isFirstItem,
      energyPro,
      panelId,
      selectedEnergyDevice,
      resetSelectedEnergyDevice,
      handleEnergyProChange,
    } = this.props;

    if (!!energyDevice) {
      return (
        <>
          <EnergyDeviceConfiguration
            energyPro={energyPro}
            isFirstItem={isFirstItem}
            isLastItem={!energyDevice.busDevice}
            id={String(energyDevice.id)}
            expandedId={expandedId}
            toggleCardState={toggleCardState}
            predeterminedBus={predeterminedBus}
            panelId={panelId}
            selectedEnergyDevice={selectedEnergyDevice}
            resetSelectedEnergyDevice={resetSelectedEnergyDevice}
            handleEnergyProChange={handleEnergyProChange}
          />
          {energyDevice.busDevice && (
            <RecursiveEnergyDevicesList
              energyPro={energyPro}
              energyDevice={energyDevice.busDevice}
              expandedId={expandedId}
              toggleCardState={toggleCardState}
              predeterminedBus={predeterminedBus}
              panelId={panelId}
              selectedEnergyDevice={selectedEnergyDevice}
              resetSelectedEnergyDevice={resetSelectedEnergyDevice}
              handleEnergyProChange={handleEnergyProChange}
            />
          )}
        </>
      );
    }

    return null;
  }
}

export default RecursiveEnergyDevicesList;
