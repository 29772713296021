import {
  InputField,
  Label,
  ModalContent,
  PortSelector,
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';
import {
  ActuatorPortType,
  ActuatorPortTypeText,
  ControlBoard,
  DeviceTypeDisplayText,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { Actions as ActuatorActions } from '../../actions/control_boards';
import SelectControlBoard from '../../containers/Selects/SelectControlBoard';
import { EditableFields } from '../../reducers/control_boards';
import { PropertyToLabel } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import ModalFormContent from '../ModalFormContent';
import { SiteController } from '@energybox/react-ui-library/dist/types/Device';

enum SiteControllerPorts {
  Port_6 = 6,
  Port_10 = 10,
  Default = 16,
}

type Props = {
  isNewActuator?: boolean;
  apiError: ApiError;
  formErrorsVisible: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  controlBoards: { [id: string]: ControlBoard };
  onChange: (field: string, value: any) => void;
  preselectedControlBoardId?: number;
  siteIdWithoutResourceId?: number | string;
};

const EditActuatorForm = ({
  isNewActuator,
  apiError,
  formErrorsVisible,
  formErrors,
  fields,
  onChange,
  controlBoards,
  preselectedControlBoardId,
  siteIdWithoutResourceId,
}: Props) => {
  const { title, description, controlBoardId, port, portType } = fields;
  const { Port_10, Port_6, Default } = SiteControllerPorts;

  const selectedControlBoard = controlBoards[controlBoardId] || null;
  const model = selectedControlBoard?.model;

  const getUsedPorts = () => {
    if (selectedControlBoard) {
      return selectedControlBoard.actuators.map(actuator => {
        return actuator.port;
      });
    }

    return [];
  };

  const isSiteControllerEUSelected = model === SiteController.ENERGYBOX_CB_EU_6;
  const getNumberOfPorts = () => {
    if (
      isSiteControllerEUSelected ||
      model === SiteController.ENERGYBOX_CB_US_6
    ) {
      return Port_6;
    } else if (model === SiteController.ENERGYBOX_CB_US_10) {
      return Port_10;
    } else {
      return Default;
    }
  };

  const onControlBoardSelect = controlBoardId => {
    onChange('port', -1);
    onChange('controlBoardId', controlBoardId);
  };

  return (
    <ModalContent>
      <ModalFormContent>
        <div>
          <Label required>{DeviceTypeDisplayText.ENERGYBOX_CB}</Label>
        </div>
        <div>
          <SelectControlBoard
            isNewSelection={isNewActuator}
            disabled={!isNewActuator}
            onSelect={value => onControlBoardSelect(value)}
            value={controlBoardId}
            error={formErrorsVisible && !!formErrors.controlBoardId}
            customErrorText={
              formErrors.controlBoardId && formErrors.controlBoardId[0]
            }
            equipmentId={fields.equipmentId}
            preselectedControlBoardId={preselectedControlBoardId}
            siteIdWithoutResourceId={Number(siteIdWithoutResourceId)}
          />
        </div>

        <div>
          <Label required>Port</Label>
        </div>
        <div>
          <PortSelector
            selectedPort={port}
            disabledPorts={getUsedPorts()}
            disabled={controlBoardId === -1}
            onChange={e => onChange('port', parseInt(e.currentTarget.value))}
            error={formErrorsVisible && !!formErrors.port}
            customErrorText={formErrors.port && formErrors.port[0]}
            numberOfPorts={getNumberOfPorts()}
          />
        </div>

        <div>
          <Label required>Port Type</Label>
        </div>
        <div>
          <RadioGroup>
            <RadioButton
              label={ActuatorPortTypeText.NORMALLY_CLOSED}
              value={ActuatorPortType.NORMALLY_CLOSED}
              checked={portType === ActuatorPortType.NORMALLY_CLOSED}
              onChange={() =>
                onChange('portType', ActuatorPortType.NORMALLY_CLOSED)
              }
            />
            {!isSiteControllerEUSelected && (
              <RadioButton
                label={ActuatorPortTypeText.NORMALLY_OPEN}
                value={ActuatorPortType.NORMALLY_OPEN}
                checked={portType === ActuatorPortType.NORMALLY_OPEN}
                onChange={() =>
                  onChange('portType', ActuatorPortType.NORMALLY_OPEN)
                }
              />
            )}
          </RadioGroup>
        </div>

        <div>
          <Label required>Relay Title</Label>
        </div>
        <div>
          <InputField
            type="text"
            name="title"
            value={title}
            autoComplete="title"
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
            customErrorText={formErrors.title && formErrors.title[0]}
          />
        </div>

        <div>
          <Label>{PropertyToLabel.description}</Label>
        </div>
        <div>
          <InputField
            type="text"
            name="description"
            value={description}
            autoComplete="description"
            onChange={e => onChange('description', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.description}
            customErrorText={
              formErrors.description && formErrors.description[0]
            }
          />
        </div>
      </ModalFormContent>
      {renderAPIerror(apiError, ActuatorActions.CREATE_ACTUATOR_ERROR)}
    </ModalContent>
  );
};

export default EditActuatorForm;
