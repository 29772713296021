import {
  EditableReportPeriod,
  Equipment,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { EditableFields } from '../../../reducers/reports';
import ReportContentSection from '../ReportContentSection';
import styles from './ReportSummary.module.css';

type Props = {
  fields: EditableFields;
  equipmentList: Equipment[];
};

const ReportSummary: React.FC<Props> = ({ fields, equipmentList }) => {
  const {
    title,
    description,
    resourceIds,
    periods,
    useAverageOfReadings,
  } = fields;

  const renderEquipmentTitle = (resourceId: number) => {
    const equipment = equipmentList.find(eq => eq.id === resourceId);

    return equipment ? equipment.title : global.NOT_AVAILABLE;
  };

  return (
    <ReportContentSection title="Summary of Report Settings">
      <ReportContentSection isSubSection title="General Information">
        <div className={styles.subSection}>
          <div className={styles.subSectionField}>
            <strong>Report name:</strong>
          </div>
          <div className={styles.subSectionField}>{title}</div>

          <div className={styles.subSectionField}>
            <strong>Description:</strong>
          </div>
          <div className={styles.subSectionField}>{description}</div>

          <div className={styles.subSectionField}>
            <strong>Average readings for each time range:</strong>
          </div>
          <div className={styles.subSectionField}>
            {String(useAverageOfReadings)}
          </div>
        </div>
      </ReportContentSection>

      <ReportContentSection isSubSection title="Selected Time Range">
        <div className={styles.subSection}>
          {periods.map((p: EditableReportPeriod, idx: number) => (
            <>
              <div className={styles.subSectionField}>
                <strong>Check {idx + 1}.</strong>
              </div>
              <div className={styles.subSectionField}>
                {p.from} - {p.to}
              </div>
            </>
          ))}
        </div>
      </ReportContentSection>

      <ReportContentSection isSubSection title="Selected Equipment">
        {resourceIds.map(resourceId => (
          <div className={styles.subSectionField}>
            {renderEquipmentTitle(resourceId)}
          </div>
        ))}
        <div></div>
      </ReportContentSection>
    </ReportContentSection>
  );
};

export default ReportSummary;
