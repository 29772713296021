import React from 'react';
import { Site } from '@energybox/react-ui-library/dist/types';

import TimeTablesTable from '../TimeTables/TimeTablesTable';
import NewTimeTableModal from '../TimeTables/NewTimeTableModal';

interface OwnProps {
  id: string;
  site: Site;
}

class ShowSitePageTimeTables extends React.Component<OwnProps> {
  render() {
    const { id, site } = this.props;

    return (
      <>
        <NewTimeTableModal organizationUnitId={id} />
        <TimeTablesTable
          organizationUnitId={this.props.id}
          isOrgLevel={false}
          site={site}
        />
      </>
    );
  }
}

export default ShowSitePageTimeTables;
