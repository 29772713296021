import { LightSensorPort } from '@energybox/react-ui-library/dist/types';
import { useControlBoardById } from '../../../hooks/useControlBoard';
import { parseLightSensorPort } from '../../Controls/SchedulerContent';
import { useLightLiveReadings } from '../../../hooks/streamApi/deviceReadings';
import { global } from '@energybox/react-ui-library/dist/utils';
import styles from './EditModeOffset.module.css';

type Props = {
  controlBoardId: number;
};

const LsPortValue: React.FC<Props> = ({ controlBoardId }) => {
  const controlBoard = useControlBoardById(controlBoardId);
  const lightSensorPort = parseLightSensorPort(controlBoard);

  const lightLiveReading = useLightLiveReadings(controlBoardId);

  const luxRange =
    lightSensorPort === LightSensorPort.PORT_1
      ? lightLiveReading?.lux1Range
      : lightLiveReading?.lux2Range;

  const isLuxValueFaulty =
    luxRange === 1000 || luxRange === 10000 ? true : false;

  const displayLuxValueOrNot =
    lightSensorPort !== LightSensorPort.NONE && isLuxValueFaulty;

  const formattedLuxRange = luxRange?.toLocaleString('en-US');

  return (
    <div>
      {lightSensorPort}
      <span className={styles.luxValue}>
        {displayLuxValueOrNot ? (
          <div>({formattedLuxRange}lux Sensor)</div>
        ) : (
          <div> {global.NOT_AVAILABLE}</div>
        )}
      </span>
    </div>
  );
};
export default LsPortValue;
