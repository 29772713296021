import React, { Suspense, lazy } from 'react';
import { Modal as ModalType, ModalTypes } from '../../types/modal';

type Props = {
  modal: ModalType;
  onClose: () => void;
};

const ConfirmationModal: React.FunctionComponent<any> = lazy(() =>
  import('./ConfirmationModal')
);

function Modals(props: Props) {
  const { modal } = props;

  function actionFn() {
    if (modal.actionFn) {
      modal.actionFn();
    }
    props.onClose();
  }

  return (
    <Suspense fallback={<div />}>
      {modal && modal.type === ModalTypes.CONFIRMATION && (
        <ConfirmationModal
          title={modal.title}
          description={modal.description}
          onSuccess={actionFn}
          onCancel={props.onClose}
        />
      )}
    </Suspense>
  );
}

export default Modals;
