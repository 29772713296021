import React from 'react';
import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';

import SentinelWizard from '../../containers/SentinelWizard';

function SentinelsCreatePage() {
  return (
    <IndexManagementPageLayout
      tabs={[]}
      content={<SentinelWizard id="new" />}
    />
  );
}

export default SentinelsCreatePage;
