import React from 'react';

import SensorsTable from '../Sensors/SensorsTable';
import NewSensorModal from '../Sensors/NewSensorModal';

interface OwnProps {
  id: string;
}

function ShowSitePageSensors(props: OwnProps) {
  return (
    <>
      <NewSensorModal lockSiteId={parseInt(props.id)} />
      <SensorsTable
        hasSearch
        resourceId={parseInt(props.id)}
        siteId={parseInt(props.id)}
      />
    </>
  );
}

export default ShowSitePageSensors;
