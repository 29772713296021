import { Button, Loader } from '@energybox/react-ui-library/dist/components';
import {
  HvacControl,
  Scheduler,
  TemperatureControl,
  WorkingMode,
  WorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { App } from '../../reducers/app';
import { showUpdateControlModeModal } from '../../actions/controls';
import useForceRerender from '../../hooks/useForceRerender';
import useGetNetworkGroupById from '../../hooks/useGetNetworkGroup';
import { canAccessFeatures } from '../../utils/featureFlag';
import UpdateControlModeModal from '../../containers/Controls/UpdateControlModeModal';
import styles from './UpdateControlModeOptions.module.css';
import { ApiError } from '../../utils/apiErrorFeedback';
import { useGetEdgeConfigUpdateByEdgeId } from '../../hooks/useGetEdgeConfigUpdate';

type Props = {
  scheduler?: Scheduler;
  temperatureControl?: TemperatureControl;
  hvacControl?: HvacControl;
  onClick?: () => void;
  equipmentId: string | number;
  activeControlWorkingMode: WorkingMode | undefined;
  networkGroupIdViaActuator: number | undefined;
  controlBoardIdViaActuator: number | undefined;
  isLocalOverrideActive: boolean | null | undefined;
  customDisabled?: boolean;
};

const UpdateControlModeOptions: React.FC<Props> = ({
  scheduler,
  temperatureControl,
  hvacControl,
  onClick,
  equipmentId,
  activeControlWorkingMode,
  networkGroupIdViaActuator,
  controlBoardIdViaActuator,
  isLocalOverrideActive,
  customDisabled,
}) => {
  const dispatch = useDispatch();
  const forceRerender = useForceRerender();

  useEffect(() => {
    setInterval(() => {
      forceRerender();
    }, 60000);
  }, [forceRerender]);

  const [primedControlMode, setPrimedControlMode] = useState<WorkingMode>(
    WorkingMode.NORMAL
  );

  const app = useSelector<ApplicationState, App>(({ app }) => {
    return app;
  });

  const isUpdateControlModeModalShowing = useSelector<
    ApplicationState,
    boolean
  >(({ controls }) => {
    return controls.showUpdateControlModeModal;
  });

  const schedulerApiError = useSelector<ApplicationState, ApiError>(
    ({ schedulers }) => {
      return pathOr({}, ['apiError'], schedulers);
    }
  );

  const temperatureApiError = useSelector<ApplicationState, ApiError>(
    ({ temperatureControls }) => {
      return pathOr({}, ['apiError'], temperatureControls);
    }
  );

  const hvacApiError = useSelector<ApplicationState, ApiError>(
    ({ hvacControls }) => {
      return pathOr({}, ['apiError'], hvacControls);
    }
  );

  const networkGroupId = isDefined(hvacControl)
    ? hvacControl.thermostat?.networkGroupId
    : networkGroupIdViaActuator;

  const { networkGroup } = useGetNetworkGroupById(networkGroupId);

  const {
    isEdgeConfigUpdating,
    shouldEdgeConfigStillBeUpdating,
  } = useGetEdgeConfigUpdateByEdgeId(networkGroup?.edge?.id!);

  const noValidControl =
    !isDefined(scheduler) &&
    !isDefined(temperatureControl) &&
    !isDefined(hvacControl);

  const disabled =
    customDisabled || noValidControl || !isDefined(activeControlWorkingMode);

  const onUpdateControlModeButtonClick = (workingMode: WorkingMode) => {
    setPrimedControlMode(workingMode);
    dispatch(showUpdateControlModeModal());
  };

  return (
    <>
      <div className={styles.workingModeButtonsContainer}>
        {Object.values(WorkingMode)
          //TODO: filter out calibrationMode for scheduler + temperature controls in prod
          //not available from edge app/BE currently
          .filter(mode => {
            const canUserAccess = canAccessFeatures(app);
            if (!canUserAccess && (scheduler || temperatureControl)) {
              return mode !== WorkingMode.CALIBRATION;
            }
            return true;
          })
          .map(mode => {
            const isActiveControlWorkingMode =
              activeControlWorkingMode === mode;

            if (
              isActiveControlWorkingMode &&
              isEdgeConfigUpdating &&
              shouldEdgeConfigStillBeUpdating
            ) {
              return (
                <div
                  key={`updateSent${mode}`}
                  className={styles.updateSentButton}
                >
                  <Button disabled variant={'solid'}>
                    Update sent
                  </Button>
                  <div className={styles.loader}>
                    <Loader size={16} />
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={`clickable${mode}`}
                  className={styles.workingModeButton}
                >
                  <Button
                    fullWidth
                    disabled={disabled}
                    variant={
                      activeControlWorkingMode === mode ? 'solid' : 'text'
                    }
                    onClick={() => {
                      onUpdateControlModeButtonClick(mode);
                      if (isDefined(onClick)) {
                        onClick();
                      }
                    }}
                  >
                    {WorkingModeLabel[mode]}
                  </Button>
                </div>
              );
            }
          })}
      </div>

      {isUpdateControlModeModalShowing && (
        <UpdateControlModeModal
          scheduler={scheduler}
          temperatureControls={temperatureControl}
          hvacControls={hvacControl}
          equipmentId={String(equipmentId)}
          isLocalOverrideActive={isLocalOverrideActive || undefined}
          primedControlMode={primedControlMode}
          schedulerApiError={schedulerApiError}
          temperatureApiError={temperatureApiError}
          hvacApiError={hvacApiError}
          networkGroupId={networkGroupId}
          controlBoardId={controlBoardIdViaActuator}
        />
      )}
    </>
  );
};

export default UpdateControlModeOptions;
