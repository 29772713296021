import { ResourceSentinelsQuickInfo } from '@energybox/react-ui-library/dist/components';
import {
  ResourcePath as ResourcePathType,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSentinelsByEquipmentMapping } from '../../actions/sentinels';
import { ApplicationState } from '../../reducers';
import { SentinelsByResourceId } from '../../reducers/sentinels';
import { Routes } from '../../routes';

import styles from './ResourceLocation.module.css';

type Props = {
  resourceId: number;
  resourceType: ResourceType;
  showSentinelsInfo?: boolean;
};

const ResourceLocation: React.FunctionComponent<Props> = ({
  resourceId,
  resourceType,
  showSentinelsInfo,
}) => {
  const dispatch = useDispatch();

  const {
    byIds: resourcePathsByIds,
    isLoadingById: resourcePathLoadingById,
  } = useSelector(({ resourcePaths }: ApplicationState) => resourcePaths);
  const path: ResourcePathType[] | undefined = resourcePathsByIds[resourceId];
  const isLoading: boolean = resourcePathLoadingById[resourceId];

  const sentinelsByResourceId: SentinelsByResourceId = useSelector(
    ({ sentinels }: ApplicationState) => {
      return sentinels.byResourceId;
    }
  );

  React.useEffect(() => {
    if (showSentinelsInfo) {
      dispatch(getSentinelsByEquipmentMapping());
    }
  }, [showSentinelsInfo, dispatch]);

  if (isLoading) {
    return <>Loading...</>;
  }
  if (!isLoading && path && path.length) {
    const nodes = path.filter(p => p.type === resourceType);

    if (nodes.length) {
      let sentinelInfo = <></>;
      // only shows sentinel info if the processed path only contains
      // one node.
      if (showSentinelsInfo && nodes.length === 1) {
        sentinelInfo = (
          <ResourceSentinelsQuickInfo
            resourceId={nodes[0].id}
            sentinels={sentinelsByResourceId[nodes[0].id] || []}
            arrowDirection="bottom"
          />
        );
      }
      return (
        <div className={styles.root}>
          <span>{nodes.map(n => n.title).join(' > ')}</span>
          {resourceType === ResourceType.EQUIPMENT ? (
            <Link to={`${Routes.EQUIPMENT}/${nodes[0].id}`}>
              {sentinelInfo}
            </Link>
          ) : (
            sentinelInfo
          )}
        </div>
      );
    }

    return <>{global.NOT_AVAILABLE}</>;
  }

  return <>{global.NOT_AVAILABLE}</>;
};

export default ResourceLocation;
