import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as EnergyDeviceActions,
  hideRemoveAllEnergyDeviceSensorsModal,
  hideRemoveEnergyDeviceSensorModal,
  removeAllSensors,
  removeSensorFromEnergyDevice,
} from '../../actions/energy_devices';
import {
  Actions as EnergyProActions,
  removeEnergyProSensorFromPanelMainBreaker,
} from '../../actions/energy_pros';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface OwnProps {
  primedPortForRemoveSensor?: number;
  resetPrimedPortForRemoveSensor?: () => void;
  energyDeviceTitle?: string;
  energyDeviceId: number;
  apiError: ApiError;
  isEnergyPro?: boolean;
}

interface Props extends OwnProps {
  removeSensorFromEnergyDevice: (port: number) => void;
  removeAllSensors: () => void;
  hideRemoveEnergyDeviceSensorModal: () => void;
  hideRemoveAllEnergyDeviceSensorsModal: typeof hideRemoveAllEnergyDeviceSensorsModal;
  removeEnergyProSensorFromPanelMainBreaker: (port: number) => void;
}

class DeleteEnergyDeviceSensorModal extends React.Component<Props> {
  onCancelClick = () => {
    const {
      primedPortForRemoveSensor,
      resetPrimedPortForRemoveSensor,
      hideRemoveEnergyDeviceSensorModal,
      hideRemoveAllEnergyDeviceSensorsModal,
    } = this.props;

    if (!!primedPortForRemoveSensor) {
      resetPrimedPortForRemoveSensor && resetPrimedPortForRemoveSensor();
      hideRemoveEnergyDeviceSensorModal();
    } else {
      hideRemoveAllEnergyDeviceSensorsModal();
    }
  };

  onDeleteClick = () => {
    const {
      isEnergyPro,
      primedPortForRemoveSensor,
      removeAllSensors,
      removeSensorFromEnergyDevice,
      resetPrimedPortForRemoveSensor,
      removeEnergyProSensorFromPanelMainBreaker,
    } = this.props;

    if (!!primedPortForRemoveSensor) {
      resetPrimedPortForRemoveSensor && resetPrimedPortForRemoveSensor();
      if (isEnergyPro) {
        removeEnergyProSensorFromPanelMainBreaker(primedPortForRemoveSensor);
      } else {
        removeSensorFromEnergyDevice(primedPortForRemoveSensor);
      }
    } else {
      removeAllSensors();
    }
  };

  render() {
    const {
      apiError,
      primedPortForRemoveSensor,
      energyDeviceTitle,
    } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={this.onCancelClick}>
          Cancel
        </Button>

        <Button onClick={this.onDeleteClick}>Delete</Button>
      </>
    );

    return (
      <Modal actions={actions}>
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ textAlign: 'justify', textAlignLast: 'center' }}>
            <div style={{ padding: '1rem 0' }}>
              {!!primedPortForRemoveSensor ? (
                <p>
                  Are you sure you want to remove this sensor from{' '}
                  <b>Port {primedPortForRemoveSensor}</b>?
                </p>
              ) : (
                <p>
                  Are you sure you want to remove all sensors from{' '}
                  {energyDeviceTitle ? (
                    <b>{energyDeviceTitle}</b>
                  ) : (
                    'this energy device'
                  )}
                  ?
                </p>
              )}
            </div>
            {renderAPIerror(
              apiError,
              EnergyDeviceActions.REMOVE_SENSOR_FROM_ENERGY_DEVICE_ERROR,
              EnergyDeviceActions.REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_ERROR,
              EnergyProActions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_ERROR
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: any, { energyDeviceId }: OwnProps) => ({
  removeSensorFromEnergyDevice: (port: number) =>
    dispatch(removeSensorFromEnergyDevice(String(energyDeviceId), port)),
  removeAllSensors: () => dispatch(removeAllSensors(String(energyDeviceId))),
  hideRemoveEnergyDeviceSensorModal: () =>
    dispatch(hideRemoveEnergyDeviceSensorModal(String(energyDeviceId))),
  hideRemoveAllEnergyDeviceSensorsModal: () =>
    dispatch(hideRemoveAllEnergyDeviceSensorsModal()),
  removeEnergyProSensorFromPanelMainBreaker: (port: number) =>
    dispatch(removeEnergyProSensorFromPanelMainBreaker(energyDeviceId, port)),
});

export default connect(null, mapDispatchToProps)(DeleteEnergyDeviceSensorModal);
