import {
  Button,
  Card,
  CardContent,
  CardTitle,
  IconEquipmentFactory,
  MediaElement,
} from '@energybox/react-ui-library/dist/components';
import { ChevronDown, ChevronUp } from '@energybox/react-ui-library/dist/icons';
import RunConfig from '@energybox/react-ui-library/dist/icons/RunConfig';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useActuatorsBySiteId } from '../../../hooks/useControlBoard';
import { ApplicationState } from '../../../reducers';
import { Routes } from '../../../routes';
import { CreateNewText, RunTestButtonText } from '../../../types/global';
import ActuatorsTable from '../../Actuators/ActuatorsTable';
import RunConfigurationTestResults from './RunConfigurationTestResults';

import styles from './RunConfigurationTest.module.css';
import { useEquipmentTypesById } from '../../../hooks/useEquipmentTypes';

type Props = {
  equipmentId: number;
  siteId: string;
  showNewActuator: (id) => void;
  setActuatorIdToEdit: Dispatch<SetStateAction<number>>;
  equipmentIdsArray: number[];
};

export const EquipmentsConfiguration: React.FC<Props> = ({
  equipmentId,
  siteId,
  showNewActuator,
  setActuatorIdToEdit,
  equipmentIdsArray,
}) => {
  const [showTestResults, setShowTestResults] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [testResultsExist, setTestResultsExist] = useState(false);
  const [isRunTest, setIsRunTest] = useState(false);

  const startSequence = useSelector(
    ({ runConfiguration }: ApplicationState) => {
      return runConfiguration.disableStartSequence;
    }
  );
  const runTest = useSelector(({ runConfiguration }: ApplicationState) => {
    return runConfiguration.disableRunTestButton;
  });

  useEffect(() => {
    runTest;
    startSequence;
  }, [runTest, startSequence]);

  useEffect(() => {
    const hasEquipmnet = equipmentIdsArray.find(id => {
      return id === equipmentId;
    });
    hasEquipmnet ? setTestResultsExist(true) : setTestResultsExist(false);
  }, [equipmentId, equipmentIdsArray]);

  const actuators = useActuatorsBySiteId(siteId);
  const equipments = actuators?.map(equipment => {
    return equipment.equipment;
  });

  const equipmentTitleAndTypeId = id => {
    const filteredEquipment = equipments?.find(
      equipment => equipment.id === id
    );
    return filteredEquipment
      ? { title: filteredEquipment.title, typeId: filteredEquipment.typeId }
      : {};
  };

  const equipmentTypesById = useEquipmentTypesById();

  const actuatorsPerEquipment = actuators?.filter(actuator => {
    if (equipmentId === actuator.equipmentId) {
      return actuator;
    }
  });

  const controlBoardIds = actuators?.map(id => {
    return id.controlBoardId;
  });
  const filteredControlBoardIds = [...new Set(controlBoardIds)];
  const controlBoardTitle = id => {
    const controlBoard = actuators?.filter(actuator => {
      return id === actuator.equipmentId;
    });
    return controlBoard?.[0].controlBoard.title!;
  };

  const runConfigurationTest = () => {
    setShowDetails(true);
    setShowTestResults(true);
    setIsRunTest(true);
  };

  const showDetialsTab = () => {
    setShowTestResults(prevState => !prevState);
    setIsRunTest(false);
  };

  return (
    <>
      <Card key={`Equipment${equipmentId}`}>
        <CardContent className={styles.cardContent}>
          <CardTitle>
            <div className={styles.runTest}>
              <MediaElement
                icon={
                  equipmentTypesById &&
                  !!equipmentTitleAndTypeId(equipmentId).typeId && (
                    <IconEquipmentFactory
                      id={
                        (
                          equipmentTypesById[
                            equipmentTitleAndTypeId(equipmentId).typeId!
                          ] || {}
                        ).alias
                      }
                      size={35}
                    />
                  )
                }
                title={
                  <Link
                    to={`${Routes.EQUIPMENT}/${equipmentId}`}
                    target="_blank"
                  >
                    {equipmentTitleAndTypeId(equipmentId).title}{' '}
                  </Link>
                }
              />
              <Button
                onClick={() => runConfigurationTest()}
                disabled={startSequence === runTest ? true : false}
                className={startSequence !== runTest ? '' : styles.disableText}
              >
                <RunConfig width="23" height="20" color="white" />
                <span className={styles.buttonText}>
                  {RunTestButtonText.RUN_TEST}
                </span>
              </Button>
            </div>
          </CardTitle>
          <div className={styles.addRelay}>
            <div>
              {filteredControlBoardIds.length > 1 && (
                <div className={styles.controlBoardTitle}>
                  # {controlBoardTitle(equipmentId)}{' '}
                </div>
              )}
              <Button
                variant="text"
                className={classNames(
                  styles.relayButton,
                  styles.addRelayButton
                )}
                onClick={() => showNewActuator(equipmentId)}
              >
                {CreateNewText.ACTUATOR}
              </Button>
            </div>
            <div className={styles.actuatorTable}>
              <ActuatorsTable
                equipmentId={equipmentId}
                setActuatorIdToEdit={setActuatorIdToEdit}
                runTest={true}
              />
            </div>
          </div>
          <div>
            {(showDetails || testResultsExist) && (
              <Button variant="text" onClick={showDetialsTab}>
                {showTestResults ? (
                  <span className={styles.icon}>
                    Hide Details <ChevronUp size={15} />
                  </span>
                ) : (
                  <span className={styles.icon}>
                    Show Details <ChevronDown size={15} />
                  </span>
                )}
              </Button>
            )}
          </div>
        </CardContent>
        <div>
          {showTestResults ? (
            <RunConfigurationTestResults
              resourceId={equipmentId}
              siteId={siteId}
              actuatorsPerEquipment={actuatorsPerEquipment!}
              isRunTest={isRunTest}
              setIsRunTest={setIsRunTest}
            />
          ) : null}
        </div>
      </Card>
    </>
  );
};

export default EquipmentsConfiguration;
