import { ApplicationState } from '../reducers';
import { GroupTypes } from '../reducers/user_groups';

export enum Actions {
  GET_LIST_LOADING = '@userGroups/GET_LIST_LOADING',
  GET_LIST_SUCCESS = '@userGroups/GET_LIST_SUCCESS',
  GET_LIST_ERROR = '@userGroups/GET_LIST_ERROR',

  GET_LOADING = '@userGroups/GET_LOADING',
  GET_SUCCESS = '@userGroups/GET_SUCCESS',
  GET_ERROR = '@userGroups/GET_ERROR',

  CREATE_LOADING = '@userGroups/CREATE_LOADING',
  CREATE_SUCCESS = '@userGroups/CREATE_SUCCESS',
  CREATE_ERROR = '@userGroups/CREATE_ERROR',

  DELETE_LOADING = '@userGroups/DELETE_LOADING',
  DELETE_SUCCESS = '@userGroups/DELETE_SUCCESS',
  DELETE_ERROR = '@userGroups/DELETE_ERROR',

  PATCH_LOADING = '@userGroups/PATCH_LOADING',
  PATCH_ERROR = '@userGroups/PATCH_ERROR',
  PATCH_SUCCESS = '@userGroups/PATCH_SUCCESS',

  TOGGLE_NEW_USERGROUP_MODAL = '@userGroups/TOGGLE_NEW_USERGROUP_MODAL',

  UPDATE_FIELD = '@userGroups/UPDATE_FIELD',
  RESET_FIELDS = '@userGroups/RESET_FIELDS',

  UPDATE_SCOPE_LOADING = '@userGroups/UPDATE_SCOPE_LOADING',
  UPDATE_SCOPE_SUCCESS = '@userGroups/UPDATE_SCOPE_SUCCESS',
  UPDATE_SCOPE_ERROR = '@userGroups/UPDATE_SCOPE_ERROR',

  ADD_SCOPE_LOADING = '@userGroups/ADD_SCOPE_LOADING',
  ADD_SCOPE_SUCCESS = '@userGroups/ADD_SCOPE_SUCCESS',
  ADD_SCOPE_ERROR = '@userGroups/ADD_SCOPE_ERROR',

  DELETE_SCOPE_LOADING = '@userGroups/DELETE_SCOPE_LOADING',
  DELETE_SCOPE_SUCCESS = '@userGroups/DELETE_SCOPE_SUCCESS',
  DELETE_SCOPE_ERROR = '@userGroups/DELETE_SCOPE_ERROR',

  DISPLAY_FORM_ERRORS = '@userGroups/DISPLAY_FORM_ERRORS',
}

export const getGroups = () => ({
  type: 'API_GET',
  path: '/api/v1/groups',
  success: Actions.GET_LIST_SUCCESS,
  error: Actions.GET_LIST_ERROR,
  loading: Actions.GET_LIST_LOADING,
});

export const getGroup = (id: string) => ({
  type: 'API_GET',
  path: `/api/v1/groups/${id}`,
  success: Actions.GET_SUCCESS,
  error: Actions.GET_ERROR,
  loading: Actions.GET_LOADING,
});

export const getUserGroups = (userGroupIds?: (string | number)[]) => ({
  type: 'API_GET',
  path: `$/api/v1/groups/?${userGroupIds ? `ids=${userGroupIds.join()}` : ''}`,
  success: Actions.GET_SUCCESS,
  error: Actions.GET_ERROR,
  loading: Actions.GET_LOADING,
});

export const showNewModal = (newType?: GroupTypes) => ({
  type: Actions.TOGGLE_NEW_USERGROUP_MODAL,
  value: true,
  validateFromAs: newType,
});

export const hideNewModal = () => ({
  type: Actions.TOGGLE_NEW_USERGROUP_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (
  id: string,
  field: string,
  value: string | number[],
  validateFromAs?: GroupTypes
) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
  validateFromAs,
});

export const create = (
  opts: {
    callback?: (response: { id?: number }) => void;
  } = {}
) => (dispatch, getState: () => ApplicationState) => {
  dispatch({
    type: 'API_POST',
    path: '/api/v1/groups',
    payload: getState().userGroups.editById['new']['fields'],
    loading: Actions.CREATE_LOADING,
    success: Actions.CREATE_SUCCESS,
    error: Actions.CREATE_ERROR,
    callback: opts.callback,
  });
};

export const patch = (id: string) => (dispatch, getState) => {
  dispatch({
    type: 'API_PATCH',
    path: `/api/v1/groups/${id}`,
    payload: getState().userGroups.editById[id]['fields'],
    loading: { type: Actions.PATCH_LOADING, id },
    success: { type: Actions.PATCH_SUCCESS, id },
    error: { type: Actions.PATCH_ERROR, id },
  });
};

export const reset = (id: string) => ({
  type: Actions.RESET_FIELDS,
  id,
});

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `/api/v1/groups/${id}`,
  loading: Actions.DELETE_LOADING,
  success: { type: Actions.DELETE_SUCCESS, id },
  error: Actions.DELETE_ERROR,
});

export const updateScope = (
  groupId: string,
  scope: { resourceId: string; role: string }
) => ({
  type: 'API_PUT',
  path: `/api/v1/groups/${groupId}/scope`,
  payload: scope,
  loading: { type: Actions.UPDATE_SCOPE_LOADING, id: groupId },
  success: { type: Actions.UPDATE_SCOPE_SUCCESS, id: groupId },
  error: { type: Actions.UPDATE_SCOPE_ERROR, id: groupId },
});

export const addScope = (
  groupId: string,
  scope: { resourceId: string; role: string }
) => ({
  type: 'API_POST',
  path: `/api/v1/groups/${groupId}/scope`,
  payload: scope,
  loading: { type: Actions.ADD_SCOPE_LOADING, groupId },
  success: { type: Actions.ADD_SCOPE_SUCCESS, groupId },
  error: { type: Actions.ADD_SCOPE_ERROR, groupId, scope },
});

export const deleteScope = (groupId: string, scopeId: string) => ({
  type: 'API_DELETE',
  path: `/api/v1/groups/${groupId}/scope`,
  payload: { resourceId: scopeId },
  loading: { type: Actions.DELETE_SCOPE_LOADING, groupId },
  success: { type: Actions.DELETE_SCOPE_SUCCESS, groupId },
  error: { type: Actions.DELETE_SCOPE_ERROR, groupId },
});
