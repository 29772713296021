import {
  Button,
  Card,
  CardActions,
  CardContent,
  Label,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import { usePrevious } from '@energybox/react-ui-library/dist/hooks';
import {
  ControlsType,
  Equipment,
  FanMode,
  HvacControl,
  MeasurementSystem,
  ThermostatWorkingMode,
  Thermostat,
  WorkingMode,
  HvacControlLabel,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  hideDeleteControlModal,
  showDeleteControlModal,
} from '../../../actions/controls';
import {
  Actions as HvacControlActions,
  createHvacControl,
  deleteHvacControl,
  patchHvacControl,
} from '../../../actions/hvacControls';
import ModalFormContent from '../../../components/ModalFormContent';
import { ApplicationState } from '../../../reducers';
import { Routes } from '../../../routes';
import { ApiError, renderAPIerror } from '../../../utils/apiErrorFeedback';
import SelectThermostat from '../../Selects/SelectThermostat';
import { editableFields } from '../../EditHvacControlsModal/EditHvacControlsModal';
import styles from './HvacSopControlsCards.module.css';
import useGetNetworkGroupById from '../../../hooks/useGetNetworkGroup';
import { useGetValidationError } from '../../../hooks/useGetValidationError';
import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';
import { getThermostatById } from '../../../actions/thermostats';

type Props = {
  siteId: number;
  equipment: Equipment;
  hvacControl: HvacControl | undefined;
  isNew: boolean;
};

const ThermostatMappingCard: React.FC<Props> = ({
  siteId,
  equipment,
  hvacControl,
  isNew,
}) => {
  const dispatch = useDispatch();
  const [formErrorsVisible, setFormErrorsVisible] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [thermostatId, setThermostatId] = useState(
    isNew ? -1 : (hvacControl?.thermostatId as number)
  );
  const isThermostatIdValid = thermostatId !== -1;

  const prevHvacControl = usePrevious(hvacControl);
  const networkGroupId = hvacControl?.thermostat?.networkGroupId!;

  const [oldNetworkGroupId, setOldNetworkGroupId] = useState(networkGroupId);
  const { networkGroup } = useGetNetworkGroupById(networkGroupId);
  const serialNumber = networkGroup?.edge?.serialNumber!;

  const validationError = useGetValidationError(serialNumber);

  const hvacApiError = useSelector<ApplicationState, ApiError>(
    ({ hvacControls }) => {
      return pathOr({}, ['apiError'], hvacControls);
    }
  );

  const showingDeleteControlModal = useSelector<ApplicationState, boolean>(
    ({ controls }) => {
      return controls.showDeleteControlModal;
    }
  );

  useEffect(() => {
    const didHvacControlLoad =
      !isDefined(prevHvacControl) && isDefined(hvacControl);
    const didHvacControlGetDeleted =
      isDefined(prevHvacControl) && !isDefined(hvacControl);

    const isPatchControlMapping =
      isDefined(prevHvacControl) && isDefined(hvacControl);

    if (didHvacControlLoad && thermostatId === -1) {
      setThermostatId((hvacControl as HvacControl)?.thermostatId);
    } else if (didHvacControlGetDeleted) {
      setThermostatId(-1);
    }

    if (
      isPatchControlMapping &&
      prevHvacControl?.thermostat?.id !== hvacControl?.thermostat?.id
    ) {
      setOldNetworkGroupId(prevHvacControl?.thermostat?.networkGroupId!);
    }
  }, [prevHvacControl, hvacControl, thermostatId]);

  const selectedThermostat = useSelector<
    ApplicationState,
    Thermostat | undefined
  >(({ thermostats }) => {
    if (!thermostats.thermostatsBySiteId[siteId]) return;
    return thermostats.thermostatsBySiteId[siteId].find(
      thermostat => thermostat.id == thermostatId
    );
  });

  const getThermostat = useCallback(() => {
    dispatch(getThermostatById(thermostatId));
  }, [dispatch, thermostatId]);

  //** useEffect **//
  useEffect(() => {
    getThermostat();
  }, [getThermostat]);

  const isVenstarThermostat = (): boolean => {
    return (
      selectedThermostat?.thermostatType ==
      ThermostatModelType.VENSTAR_THERMOSTAT
    );
  };

  const handleCancelThermostatMapping = () => {
    if (isNew) {
      setThermostatId(-1);
    } else {
      setThermostatId(hvacControl?.thermostatId as number);
      setIsInEditMode(false);
    }
  };

  const handleSaveThermostatMapping = () => {
    if (!isThermostatIdValid) {
      setFormErrorsVisible(true);
    } else {
      if (isNew) {
        const hvacControlMapping = createHvacControlMapping(
          equipment.id,
          thermostatId
        );

        dispatch(
          createHvacControl(hvacControlMapping, { produceEdgeConfig: true })
        );
      }
      if (isDefined(hvacControl)) {
        const fields = editableFields(hvacControl);
        const newNetworkGroupId = selectedThermostat?.networkGroupId;
        dispatch(
          patchHvacControl(
            hvacControl.id,
            { ...fields, thermostatId },
            { produceEdgeConfig: true, oldNetworkGroupId, newNetworkGroupId }
          )
        );
        setIsInEditMode(false);
      }
    }
  };

  const handleDeleteThermostatMapping = (
    hvacControl: HvacControl | undefined
  ) => {
    if (isDefined(hvacControl)) {
      const hvacControlId = hvacControl.id;
      const equipmentId = hvacControl.equipmentId;
      const networkGroupId = hvacControl.thermostat?.networkGroupId;

      dispatch(
        deleteHvacControl(hvacControlId, equipmentId, {
          produceEdgeConfig: true,
          networkGroupId,
        })
      );
    }
  };

  const renderDeletePrompt = () => {
    const actions = (
      <>
        <Button
          variant="text"
          onClick={() => dispatch(hideDeleteControlModal())}
        >
          Cancel
        </Button>
        <Button onClick={() => handleDeleteThermostatMapping(hvacControl)}>
          Delete
        </Button>
      </>
    );

    return (
      <Modal actions={actions}>
        <div className={styles.deletePrompt}>
          <div>Are you sure you want to delete this thermostat mapping?</div>
          <div>
            {Object.values(hvacApiError).length > 0 &&
              renderAPIerror(
                hvacApiError,
                HvacControlActions.DELETE_HVAC_CONTROL_ERROR
              )}
            <div className={styles.pinkText}>
              {validationError?.length
                ? `Configuration Warning: ${validationError}`
                : ''}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <CardContent hasExtraPadding={true}>
          <div className={styles.cardHeader}>
            <div className={styles.boldText}>Thermostat Mapping</div>

            <MenuDropdown disabled={isNew}>
              <MenuDropdownItem onSelect={() => setIsInEditMode(true)}>
                Edit
              </MenuDropdownItem>
              <MenuDropdownItem
                isRed
                onSelect={() =>
                  dispatch(showDeleteControlModal(ControlsType.HVAC))
                }
              >
                Delete
              </MenuDropdownItem>
            </MenuDropdown>
          </div>

          <div className={styles.cardContent}>
            <ModalFormContent className={styles.modalFormContent}>
              <div>
                <Label>{HvacControlLabel.THERMOSTAT}</Label>
              </div>
              <div className={styles.valueContainer}>
                {isNew || isInEditMode ? (
                  <div>
                    <SelectThermostat
                      initialThermostatId={
                        isDefined(hvacControl)
                          ? hvacControl.thermostatId
                          : undefined
                      }
                      siteId={siteId}
                      value={String(thermostatId)}
                      onSelect={(value: number) => setThermostatId(value)}
                      error={formErrorsVisible && !isThermostatIdValid}
                      customErrorText={'Please select thermostat'}
                    />
                  </div>
                ) : (
                  <div>
                    {isDefined(hvacControl) ? (
                      <Link
                        to={`${Routes.DEVICES}${Routes.GATEWAYS}/${hvacControl.thermostatId}`}
                      >
                        {hvacControl.thermostat?.title || global.NOT_AVAILABLE}
                      </Link>
                    ) : (
                      global.NOT_AVAILABLE
                    )}
                  </div>
                )}
              </div>

              {isVenstarThermostat() && (
                <>
                  <div>
                    <Label>Remote Sensors</Label>
                  </div>
                  <div className={styles.valueContainer}>
                    {isDefined(
                      selectedThermostat?.wirelessTemperatureSensorsCount
                    )
                      ? selectedThermostat?.wirelessTemperatureSensorsCount
                      : global.NOT_AVAILABLE}
                  </div>
                </>
              )}
            </ModalFormContent>
          </div>
        </CardContent>

        {(isNew || isInEditMode) && (
          <CardActions>
            <Button variant="text" onClick={handleCancelThermostatMapping}>
              Cancel
            </Button>
            <Button onClick={handleSaveThermostatMapping}>Update</Button>
          </CardActions>
        )}
      </Card>

      {showingDeleteControlModal && renderDeletePrompt()}
    </>
  );
};

const createHvacControlMapping = (
  equipmentId: number,
  thermostatId: number
) => ({
  equipmentId,
  thermostatId,
});

export default ThermostatMappingCard;
