import {
  FormText,
  Label,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  FanMode,
  FanModeLabel,
  HvacControlLabel,
  MeasurementSystem,
  MeasurementSystemToTemperatureLabel,
  ThermostatWorkingMode,
  ThermostatWorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { ThermoStatLocalSetPointDelta } from '@energybox/react-ui-library/dist/types/HvacControl';
import {
  renderLocalAdjustmentTitle,
  renderLocalSetpointDeltaSign,
} from '@energybox/react-ui-library/dist/utils/thermostat';
import { pluralize } from '@energybox/react-ui-library/dist/utils/util';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';

import React from 'react';
import SelectThermostat from '../../containers/Selects/SelectThermostat';
import {
  EditHvacSOPControl,
  HvacControlFormErrors,
} from '../../types/hvacControl';
import {
  convertSecToHrsText,
  overrideTimerOptionsMap,
} from '../../utils/controls';
import ModalFormContent from '../ModalFormContent';
import TemperatureInputField from '../TemperatureInputField';
import styles from './EditControlsHvacForm.module.css';
import EditControlsHvacFormAdjustabilityTooltip from './EditControlsHvacFormAdjustabilityTooltip';
import EditControlsHvacFormAdvancedSettingsTooltip from './EditControlsHvacFormAdvancedSettingsTooltip';
import EditControlsHvacSetPointTooltip from './EditControlsHvacSetPointTooltip';
import { useTemperatureUnit } from '../../hooks/utils';

type Props = {
  fields: EditHvacSOPControl;
  siteId: number;
  onChange: (field: string, value: string | number | boolean | null) => void;
  formErrors: HvacControlFormErrors;
  formErrorsVisible: boolean;
};

const EditControlsHvacFormBasicContent: React.FC<Props> = ({
  fields,
  siteId,
  onChange,
  formErrors,
  formErrorsVisible,
}) => {
  const {
    thermostatId,
    minTemp,
    maxTemp,
    thermostatWorkingMode,
    thermostatDisplayUnits,
    fanMode,
    enableLocalAdjustment,
    setPointLimitDelta,
    overrideTimer,
  } = fields;
  const temperatureUnit = useTemperatureUnit();

  const renderSetPointRangeInputFields = () => {
    const renderLabelAndInput = (
      label: HvacControlLabel.MAX_TEMP | HvacControlLabel.MIN_TEMP,
      value: number
    ) => {
      const fieldName =
        label === HvacControlLabel.MAX_TEMP ? 'maxTemp' : 'minTemp';

      return (
        <div className={styles.inputField}>
          <span className={styles.marginRight}>{label}</span>
          <TemperatureInputField
            integersOnly
            className={styles.input}
            id={fieldName}
            name={fieldName}
            // pass unconverted value - TemperatureInputField will convert based on user pref
            value={value}
            onChange={v => onChange(fieldName, v)}
            error={formErrorsVisible && !!formErrors[fieldName]}
            customErrorText={formErrors[fieldName]}
          />
        </div>
      );
    };

    switch (thermostatWorkingMode) {
      case ThermostatWorkingMode.HEATING:
        return (
          <div key={ThermostatWorkingMode.HEATING}>
            {renderLabelAndInput(HvacControlLabel.MIN_TEMP, minTemp)}
          </div>
        );

      case ThermostatWorkingMode.COOLING:
        return (
          <div key={ThermostatWorkingMode.COOLING}>
            {renderLabelAndInput(HvacControlLabel.MAX_TEMP, maxTemp)}
          </div>
        );

      case ThermostatWorkingMode.AUTO:
        return (
          <div
            key={ThermostatWorkingMode.AUTO}
            className={styles.autoSetPointInputRow}
          >
            {renderLabelAndInput(HvacControlLabel.MIN_TEMP, minTemp)}
            <div className={styles.inputSeparator}> : </div>
            {renderLabelAndInput(HvacControlLabel.MAX_TEMP, maxTemp)}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <ModalFormContent className={styles.modalFormContent}>
        <div>
          <Label required>{HvacControlLabel.THERMOSTAT}</Label>
        </div>
        <div>
          <SelectThermostat
            siteId={siteId}
            value={String(thermostatId)}
            onSelect={(value: number) => onChange('thermostatId', value)}
            error={formErrorsVisible && !!formErrors.thermostatId}
            customErrorText={formErrors.thermostatId}
          />
        </div>

        <div>
          <Label required>{HvacControlLabel.THERMOSTAT_MODE}</Label>
        </div>
        <div>
          <Select title={ThermostatWorkingModeLabel[thermostatWorkingMode]}>
            {Object.values(ThermostatWorkingMode)
              .filter(mode => mode !== ThermostatWorkingMode.OFF)
              .map(mode => (
                <SelectItem
                  key={mode}
                  isSelected={mode === thermostatWorkingMode}
                  onSelect={() => onChange('thermostatWorkingMode', mode)}
                >
                  {ThermostatWorkingModeLabel[mode]}
                </SelectItem>
              ))}
          </Select>
        </div>

        <div>
          <Label required>{HvacControlLabel.SET_POINT_RANGE}</Label>
        </div>
        <div className={styles.inputFieldContainer}>
          {renderSetPointRangeInputFields()}
          <div>
            <EditControlsHvacSetPointTooltip
              temperatureUnit={temperatureUnit}
              hasError={
                formErrorsVisible &&
                (!!formErrors['maxTemp'] || !!formErrors['minTemp'])
              }
            />
          </div>
        </div>

        <div>
          <Label required>{HvacControlLabel.FAN_MODE}</Label>
        </div>
        <div>
          <Select title={FanModeLabel[fanMode]}>
            {Object.values(FanMode).map(mode => (
              <SelectItem
                key={mode}
                isSelected={mode === fanMode}
                onSelect={() => onChange('fanMode', mode)}
              >
                {FanModeLabel[mode]}
              </SelectItem>
            ))}
          </Select>
        </div>

        <div>
          <Label required>{HvacControlLabel.THERMOSTAT_UNIT}</Label>
        </div>
        <div>
          <Select
            title={MeasurementSystemToTemperatureLabel[thermostatDisplayUnits]}
          >
            {Object.values(MeasurementSystem).map(ms => (
              <SelectItem
                key={ms}
                isSelected={ms === thermostatDisplayUnits}
                onSelect={() => onChange('thermostatDisplayUnits', ms)}
              >
                {MeasurementSystemToTemperatureLabel[ms]}
              </SelectItem>
            ))}
          </Select>
        </div>
      </ModalFormContent>

      <div className={styles.dividerLine}></div>

      <div className={styles.advancedSettingsRow}>
        <Label>
          <span className={styles.bold}>
            {HvacControlLabel.ADVANCED_SETTINGS}
          </span>
        </Label>
        <EditControlsHvacFormAdjustabilityTooltip />
      </div>
      <ModalFormContent>
        <div>
          <Label>{HvacControlLabel.LOCAL_ADJUSTMENT}</Label>
        </div>
        <div>
          <FormControlLabel
            label={
              <FormLabel
                className={styles.localAdjustmentSwitch}
                color={enableLocalAdjustment ? 'primary' : 'secondary'}
                classes={{ colorSecondary: styles.localAdjustmentGrey }}
              >
                {enableLocalAdjustment === true ? 'On' : 'Off'}
              </FormLabel>
            }
            control={
              <Switch
                checked={enableLocalAdjustment}
                color={enableLocalAdjustment ? 'primary' : 'default'}
                onChange={() => {
                  const willBeEnabled = !enableLocalAdjustment;
                  onChange('enableLocalAdjustment', willBeEnabled);
                  if (willBeEnabled === false) {
                    onChange('setPointLimitDelta', null);
                    onChange('overrideTimer', null);
                  } else {
                    onChange('setPointLimitDelta', 2);
                    onChange('overrideTimer', overrideTimerOptionsMap.ONE_HOUR);
                  }
                }}
                name="localAdjustment"
              />
            }
          />
        </div>

        <div>
          <Label>{HvacControlLabel.SETPOINT_LIMITS}</Label>
        </div>
        <div>
          <Select
            title={renderLocalAdjustmentTitle(
              thermostatWorkingMode,
              setPointLimitDelta
            )}
            disabled={
              enableLocalAdjustment === undefined ||
              enableLocalAdjustment === false
            }
            error={
              formErrorsVisible &&
              enableLocalAdjustment === true &&
              !!formErrors.setPointLimitDelta
            }
            customErrorText={formErrors.setPointLimitDelta}
          >
            {Object.values(ThermoStatLocalSetPointDelta)
              .filter(deltaOption => Number.isSafeInteger(deltaOption))
              .map(deltaOption => {
                return (
                  <SelectItem
                    key={deltaOption}
                    isSelected={deltaOption === setPointLimitDelta}
                    onSelect={() => onChange('setPointLimitDelta', deltaOption)}
                  >
                    <>
                      {renderLocalSetpointDeltaSign(thermostatWorkingMode)}
                      {deltaOption}
                      {` ${pluralize('degree', deltaOption as number)}`}
                    </>
                  </SelectItem>
                );
              })}
          </Select>
        </div>

        <div>
          <Label>{HvacControlLabel.REVERT_ADJUSTMENT}</Label>
        </div>
        <div className={styles.inlineDescriptionHeader}>
          <Select
            title={convertSecToHrsText(overrideTimer)}
            disabled={
              enableLocalAdjustment === undefined ||
              enableLocalAdjustment === false
            }
          >
            {Object.values(overrideTimerOptionsMap).map(overrideTimerInSec => {
              return (
                <SelectItem
                  key={overrideTimerInSec}
                  isSelected={overrideTimerInSec === overrideTimer}
                  onSelect={() => onChange('overrideTimer', overrideTimerInSec)}
                >
                  {convertSecToHrsText(overrideTimerInSec)}
                </SelectItem>
              );
            })}
          </Select>
          <EditControlsHvacFormAdvancedSettingsTooltip />
        </div>

        <FormText>
          <Label>* Mandatory fields</Label>
        </FormText>
      </ModalFormContent>
    </>
  );
};

export default EditControlsHvacFormBasicContent;
