import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './SchedulerTimetableInfoTooltip.module.css';

type TooltipType = 'Schedule' | 'Light Sensor';

type Props = {
  hasError?: boolean;
  type: TooltipType;
  isOrgLevel?: boolean;
};

const SchedulerTimetableInfoTooltip: React.FC<Props> = ({
  hasError,
  type,
  isOrgLevel,
}) => {
  return (
    <Tooltip
      underline={isOrgLevel ? true : false}
      arrowDirection={isOrgLevel ? 'topLeft' : 'right'}
      extraClassNames={isOrgLevel ? [styles.tooltip] : [styles.margin]}
      tooltipTextClassName={isOrgLevel ? styles.text : ''}
      content={
        <div className={styles.tooltipText}>
          <div className={styles.bold}>Timetable Field</div>

          <div className={styles.paddingTop}>
            <div>
              Select a Scheduled Timetable and/or a Light Sensor Timetable.
            </div>
          </div>

          {renderVariableText(type)}
        </div>
      }
    >
      {isOrgLevel ? (
        <div className={styles.title}> TimeTable </div>
      ) : (
        <IoIosInformationCircleOutline
          color={hasError ? 'var(--pink-base)' : 'black'}
          size={20}
        />
      )}
    </Tooltip>
  );
};

const renderVariableText = (type: TooltipType) => {
  if (type === 'Schedule') {
    return (
      <>
        <div className={styles.paddingTop}>
          <div>
            Scheduled Timetables take priority over Light Sensor settings.
          </div>
        </div>

        <div className={styles.paddingTop}>
          <div>
            If “No Timetable” is selected in Schedule tab, Controls will follow
            LightSensor settings.
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.paddingTop}>
        <div>
          Light Sensor settings & Timetables apply outside of Scheduled
          Timetables only.
        </div>
      </div>
    );
  }
};

export default SchedulerTimetableInfoTooltip;
