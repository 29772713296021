import React from 'react';

type Props = {
  size: number;
};

const DashboardDistributionPanelIcon = ({ size }: Props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 160 160"
      enableBackground="new 0 0 160 160"
    >
      <g fill="#66d2d9">
        <path
          d="M111.4 61H99v-1c0-1.66-1.34-3-3-3v-5h-2v5h-2v-5h-2v5h-2v-5h-2v5c-1.66 0-3 1.34-3 3v1H70.6c-4.2 0-7.6 3.4-7.6 7.6v51.8c0 4.2 3.4 7.6 7.6 7.6h40.8c4.2 0 7.6-3.4 7.6-7.6V68.6c0-4.2-3.4-7.6-7.6-7.6z"
          opacity=".1"
        />
        <path d="M130.5 89c-.28 0-.5.22-.5.5v5c0 .28.22.5.5.5s.5-.22.5-.5v-5c0-.28-.22-.5-.5-.5z" />
        <circle cx="130.5" cy="87.5" r=".5" />
        <path d="M133.5 93c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM130.5 97c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5s.5-.22.5-.5v-1c0-.28-.22-.5-.5-.5zM68 70h-1v-1h-3v5h3v-1h1v1h13v-5H68v1zm-3 3v-3h1v3h-1zm2-1v-1h1v1h-1zm13-2v3H69v-3h11z" />
        <path d="M71 71h2v1h-2zM75 71h2v1h-2zM99 70h-1v-1H85v5h13v-1h1v1h3v-5h-3v1zm-13 3v-3h11v3H86zm12-1v-1h1v1h-1zm3-2v3h-1v-3h1z" />
        <path d="M93 71h2v1h-2zM89 71h2v1h-2zM68 78h-1v-1h-3v5h3v-1h1v1h13v-5H68v1zm-3 3v-3h1v3h-1zm2-1v-1h1v1h-1zm13-2v3H69v-3h11z" />
        <path d="M71 79h2v1h-2zM75 79h2v1h-2zM99 78h-1v-1H85v5h13v-1h1v1h3v-5h-3v1zm-13 3v-3h11v3H86zm12-1v-1h1v1h-1zm3-2v3h-1v-3h1z" />
        <path d="M93 79h2v1h-2zM89 79h2v1h-2zM68 86h-1v-1h-3v5h3v-1h1v1h13v-5H68v1zm-3 3v-3h1v3h-1zm2-1v-1h1v1h-1zm13-2v3H69v-3h11z" />
        <path d="M71 87h2v1h-2zM75 87h2v1h-2zM99 86h-1v-1H85v5h13v-1h1v1h3v-5h-3v1zm-13 3v-3h11v3H86zm12-1v-1h1v1h-1zm3-2v3h-1v-3h1z" />
        <path d="M93 87h2v1h-2zM89 87h2v1h-2zM68 94h-1v-1h-3v5h3v-1h1v1h13v-5H68v1zm-3 3v-3h1v3h-1zm2-1v-1h1v1h-1zm13-2v3H69v-3h11z" />
        <path d="M71 95h2v1h-2zM75 95h2v1h-2zM99 94h-1v-1H85v5h13v-1h1v1h3v-5h-3v1zm-13 3v-3h11v3H86zm12-1v-1h1v1h-1zm3-2v3h-1v-3h1z" />
        <path d="M93 95h2v1h-2zM89 95h2v1h-2zM68 102h-1v-1h-3v5h3v-1h1v1h13v-5H68v1zm-3 3v-3h1v3h-1zm2-1v-1h1v1h-1zm13-2v3H69v-3h11z" />
        <path d="M71 103h2v1h-2zM75 103h2v1h-2zM99 102h-1v-1H85v5h13v-1h1v1h3v-5h-3v1zm-13 3v-3h11v3H86zm12-1v-1h1v1h-1zm3-2v3h-1v-3h1z" />
        <path d="M93 103h2v1h-2zM89 103h2v1h-2zM126 66.5c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5s.5-.22.5-.5v-1c0-.28-.22-.5-.5-.5zM126 71.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM126 78.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM126 113.5h-3c-.28 0-.5.22-.5.5s.22.5.5.5h3c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM119 113.5h-3c-.28 0-.5.22-.5.5s.22.5.5.5h3c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM126 99.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM126 106.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM126 92.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM126 85.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM126 54.5c.28 0 .5-.22.5-.5v-3c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3c0 .28.22.5.5.5zM126 47.5c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1c0 .28.22.5.5.5zM38 87c0-.28-.22-.5-.5-.5h-4c-.28 0-.5.22-.5.5s.22.5.5.5h4c.28 0 .5-.22.5-.5zM28.5 86.5H28V86c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.5h-.5c-.28 0-.5.22-.5.5s.22.5.5.5h.5v.5c0 .28.22.5.5.5s.5-.22.5-.5v-.5h.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM33 112h-1.5v-1c0-.28-.22-.5-.5-.5s-.5.22-.5.5v2H33c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM31 100.02c.28 0 .5-.22.5-.5v-3.13c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.13c0 .28.22.5.5.5zM31 107.33c.28 0 .5-.22.5-.5v-3.13c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.13c0 .27.22.5.5.5zM31 78.11c.28 0 .5-.22.5-.5v-3.13c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.13c0 .28.22.5.5.5zM31 70.8c.28 0 .5-.22.5-.5v-3.13c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.13c0 .28.22.5.5.5zM31 85.41c.28 0 .5-.22.5-.5v-3.13c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.13c0 .28.22.5.5.5zM31 92.72c.28 0 .5-.22.5-.5v-3.13c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3.13c0 .27.22.5.5.5zM31 63.5c.28 0 .5-.22.5-.5v-1h1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-2v2c0 .28.22.5.5.5zM36.86 62h3.27c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3.27c-.28 0-.5.22-.5.5s.23.5.5.5zM46 61h-1.5c-.28 0-.5.22-.5.5s.22.5.5.5H46c.28 0 .5-.22.5-.5s-.22-.5-.5-.5z" />
        <circle cx="42" cy="39.5" r="1" />
        <path d="M46.84 32.15a.45.45 0 0 0-.67 0l-2.02 2.12c-.19.2-.19.51 0 .71.09.1.22.15.34.15.12 0 .24-.05.34-.15l1.21-1.27V40c0 .28.21.5.48.5s.48-.22.48-.5v-6.29l1.21 1.27c.19.2.49.2.67 0s.19-.51 0-.71l-2.04-2.12zM46.5 48.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM46.5 41.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM46.5 55.5c-.28 0-.5.22-.5.5v3c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zM40.67 112.33c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-.17v-.17c0-.64-.52-1.16-1.16-1.16h-2.68c-.64 0-1.16.52-1.16 1.16v2.68c0 .64.52 1.16 1.16 1.16h2.68c.64 0 1.16-.52 1.16-1.16v-.17h.17c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H39.5v1.17c0 .09-.07.16-.16.16h-2.68c-.09 0-.16-.07-.16-.16v-2.68c0-.09.07-.16.16-.16h2.68c.09 0 .16.07.16.16v1.17h1.17zM46 112h-3c-.28 0-.5.22-.5.5s.22.5.5.5h3c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM51 112h-1c-.28 0-.5.22-.5.5s.22.5.5.5h1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM128.25 58.12l.4-.56-1.45-1.05-.4.56c-.34-.11-.7-.14-1.06-.11l-.28-.62-1.63.73.28.62c-.13.12-.25.25-.35.4-.1.14-.19.3-.27.47l-.68-.07-.18 1.78.68.07c.08.35.23.67.44.97l-.4.56 1.45 1.05.4-.56c.34.11.7.15 1.06.11l.28.62 1.63-.73-.28-.62c.13-.12.25-.26.36-.4.1-.14.19-.3.26-.46l.68.07.18-1.78-.68-.07a2.64 2.64 0 0 0-.44-.98zm-.56 2.07c-.06.21-.15.4-.26.55-.11.16-.26.3-.44.42l-.25.17-.46.12c-.35.06-.71 0-1.04-.16l-.28-.14-.22-.22c-.26-.26-.42-.59-.48-.94l-.03-.47.08-.29c.06-.21.15-.4.26-.55.11-.16.26-.3.44-.42l.25-.17.46-.12c.35-.06.72 0 1.05.16l.28.14.22.22c.25.26.42.59.48.94l.03.48-.09.28z" />
        <path d="M126.8 59.58a.837.837 0 0 0-.17-.37 1.19 1.19 0 0 0-.2-.19c-.05-.03-.1-.05-.15-.07a.803.803 0 0 0-.41-.04h-.01a.78.78 0 0 0-.39.18c-.05.04-.1.09-.14.15-.04.06-.07.12-.1.18-.05.13-.07.28-.05.43v.01c.02.14.08.26.17.37.05.06.1.11.16.15.06.04.13.07.19.1.13.05.27.06.4.04h.01a.78.78 0 0 0 .39-.18c.05-.04.1-.09.14-.15.04-.06.07-.12.1-.18.07-.14.09-.28.06-.43z" />
      </g>
      <g fill="#00a1af">
        <path d="M85.1 54h-3.87l-2.26 6.61h3.87l-1.29 5.01 5.48-7.22h-4.19z" />
        <path d="M105.4 49H91v-1c0-1.66-1.34-3-3-3v-5h-2v5h-2v-5h-2v5h-2v-5h-2v5c-1.66 0-3 1.34-3 3v1H60.6c-4.2 0-7.6 3.4-7.6 7.6v58.8c0 4.2 3.4 7.6 7.6 7.6h44.8c4.2 0 7.6-3.4 7.6-7.6V56.6c0-4.2-3.4-7.6-7.6-7.6zM77 48c0-.55.45-1 1-1h10c.55 0 1 .45 1 1v1H77v-1zm3 73v-3h2v3h-2zm-7.11-4c-.49 0-.89-.4-.89-.89v-4.22c0-.49.4-.89.89-.89h20.22c.49 0 .89.4.89.89v4.22c0 .49-.4.89-.89.89H72.89zM84 121v-3h2v3h-2zm27-5.6c0 3.09-2.51 5.6-5.6 5.6H88v-3h5.11c1.04 0 1.89-.85 1.89-1.89v-4.22c0-1.04-.85-1.89-1.89-1.89H72.89c-1.04 0-1.89.85-1.89 1.89v4.22c0 1.04.85 1.89 1.89 1.89H78v3H60.6c-3.09 0-5.6-2.51-5.6-5.6V56.6c0-3.09 2.51-5.6 5.6-5.6h44.8c3.09 0 5.6 2.51 5.6 5.6v58.8z" />
        <path d="M76 113h2v2h-2zM82 113h2v2h-2zM88 113h2v2h-2z" />
      </g>
    </svg>
  );
};

export default DashboardDistributionPanelIcon;
