import {
  HvacControl,
  OutsideOfTimeTable,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import isNil from 'ramda/src/isNil';
import pipe from 'ramda/src/pipe';

import { Actions as ControlsActions } from '../actions/controls';
import { Actions } from '../actions/hvacControls';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import * as R from 'ramda';

export const initialState = {
  hvacControlsBySiteId: {},
  hvacControlsByEquipmentId: {},
  apiError: {},
  showEditHvacControlModal: false,
  editById: {},
};

export type HvacControls = {
  hvacControlsBySiteId: HvacControlsBySiteId;
  hvacControlsByEquipmentId: HvacControlsByEquipmentId;
  apiError: ApiError;
  showEditHvacControlModal: boolean;
  editById: EditById;
};

export type EditById = {
  [id: string]: EditHvacEquipment;
};

export type EditHvacEquipment = {
  thermostatId: number;
  workingMode: WorkingMode;
};

export type HvacControlsByEquipmentId = {
  //only one hvacControl possible per equipment
  [equipmentId: string]: HvacControl;
};

export type HvacControlsBySiteId = {
  [siteId: string]: HvacControl[];
};

const normalizeHvacControl = (hvacControl?: HvacControl) => {
  if (!hvacControl) return undefined;
  return {
    _entity: hvacControl._entity,
    beginDelta: hvacControl.beginDelta
      ? parseInt(hvacControl.beginDelta)
      : null,
    endDelta: hvacControl.endDelta ? parseInt(hvacControl.endDelta) : null,
    equipment: hvacControl.equipment,
    equipmentId: hvacControl.equipmentId,
    fanMode: hvacControl.fanMode,
    id: hvacControl.id,
    maxTemp: hvacControl.maxTemp,
    minTemp: hvacControl.minTemp,
    outsideMaxTemp: hvacControl.outsideMaxTemp,
    outsideMinTemp: hvacControl.outsideMinTemp,
    outsideFanMode: hvacControl.outsideFanMode,
    thermostat: hvacControl.thermostat,
    thermostatDisplayUnits: hvacControl.thermostatDisplayUnits,
    thermostatId: hvacControl.thermostatId,
    thermostatWorkingMode: hvacControl.thermostatWorkingMode,
    timetable: hvacControl.timetable,
    timetableId: hvacControl.timetableId,
    type: hvacControl.type,
    createdAt: hvacControl.createdAt,
    updatedAt: hvacControl.updatedAt,
    workingMode: hvacControl.workingMode,
    outsideOfTimeTable: determineOutsideOfTimeTable(hvacControl),
    enableLocalAdjustment: hvacControl.enableLocalAdjustment,
    setPointLimitDelta: hvacControl.setPointLimitDelta,
    overrideTimer: hvacControl.overrideTimer,
  };
};

const determineOutsideOfTimeTable = (hvacControl: HvacControl) => {
  if (
    !isNil(hvacControl.outsideMaxTemp) ||
    !isNil(hvacControl.outsideMinTemp)
  ) {
    return OutsideOfTimeTable.ALTERNATIVE_SET_POINTS;
  }
  return OutsideOfTimeTable.OFF;
};

const hvacControls = (state: HvacControls = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['hvacControlsBySiteId', action.siteId],
          action.data.map((vc: HvacControl) => normalizeHvacControl(vc))
        )
      )(state);

    case Actions.GET_HVAC_CONTROL_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(
          ['hvacControlsByEquipmentId', action.equipmentId],
          normalizeHvacControl(action.data)
        )
      )(state);

    case Actions.PATCH_HVAC_CONTROL_SUCCESS:
      return pipe(
        assocPath(
          ['hvacControlsByEquipmentId', action.data.equipmentId],
          normalizeHvacControl(action.data)
        )
      )(state);

    case Actions.PATCH_HVAC_CONTROL_BATCH_SUCCESS:
      return pipe(
        assocPath(['hvacControlsBatch', action.data.equipmentId], action.data)
      )(state);

    case Actions.ADD_HVAC_CONTROL_BATCH_SUCCESS:
      return pipe(assocPath('newHvacControlsBatch', action.data))(state);

    case Actions.UPDATE_FIELD: {
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id], state)
      );
      return R.pipe(R.assocPath(['editById', action.id], updatedField))(state);
    }

    case Actions.CREATE_HVAC_CONTROL_SUCCESS:
      return pipe(
        assocPath(
          ['hvacControlsByEquipmentId', action.data.equipmentId],
          normalizeHvacControl(action.data)
        )
      )(state);

    case Actions.DELETE_HVAC_CONTROL_SUCCESS:
      return pipe(
        dissocPath(['hvacControlsByEquipmentId', action.equipmentId])
      )(state);

    case Actions.PATCH_HVAC_CONTROL_WORKING_MODE_ERROR:
    case Actions.PATCH_HVAC_CONTROL_ERROR:
    case Actions.CREATE_HVAC_CONTROL_ERROR:
    case Actions.DELETE_HVAC_CONTROL_ERROR:
      return pipe(assoc('apiError', storeAPIerror(action)))(state);

    case ControlsActions.CLEAR_UPDATE_CONTROL_MODE_MODAL_ERROR_MESSAGE:
      return assoc('apiError', {}, state);

    case Actions.SHOW_EDIT_HVAC_CONTROL_MODAL:
      return pipe(assoc('showEditHvacControlModal', true))(state);

    case Actions.HIDE_EDIT_HVAC_CONTROL_MODAL:
      return pipe(assoc('showEditHvacControlModal', false))(state);

    default:
      return state;
  }
};

export default hvacControls;
