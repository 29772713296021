import { omit } from 'ramda';

export type UploadResultByFileIdx = {
  [idx: number]: string;
};

export type UploadTracker = {
  results: UploadResultByFileIdx;
  count: number;
};

export const initialBillMetadata: Partial<BillMetadata> = {
  startDate: undefined,
  endDate: undefined,
};

export type BillMetadata = {
  startDate: string;
  endDate: string;
};

export type UploadFilePending<T extends object> = {
  file: File;
} & Partial<T>;

export type UploadFileReady<T extends object> = {
  file: File;
} & T;

export type UploadFileUploading<T extends object> = UploadFileReady<T> & {
  fileId: null;
};

export type UploadFileFailed<T extends object> = UploadFileReady<T> & {
  fileId: '';
};

export type UploadFileDone<T extends object> = UploadFileReady<T> & {
  fileId: string; // file id used by file service api
};

export type UploadFileUploaded<T extends object> =
  | UploadFileFailed<T>
  | UploadFileDone<T>;

export type UploadFileSent<T extends object> =
  | UploadFileUploading<T>
  | UploadFileUploaded<T>;

export type UploadFile<T extends object> =
  | UploadFilePending<T>
  | UploadFileReady<T>
  | UploadFileSent<T>;

function isBill(obj: object): obj is BillMetadata {
  return (obj as BillMetadata).startDate !== undefined;
}
export const isPending = <T extends object>(
  obj: UploadFile<T>
): obj is UploadFilePending<T> =>
  Object.values(omit(['fileId'], obj)).some(value => !value);

export const isReady = <T extends object>(
  obj: UploadFile<T>
): obj is UploadFileReady<T> => !isPending(obj) && !('fileId' in obj);

export const isUploading = <T extends object>(
  obj: UploadFile<T>
): obj is UploadFileUploading<T> => 'fileId' in obj && obj.fileId === null;

export const isUploaded = <T extends object>(
  obj: UploadFile<T>
): obj is UploadFileUploaded<T> =>
  'fileId' in obj && typeof obj.fileId === 'string';

export const isSent = <T extends object>(
  obj: UploadFile<T>
): obj is UploadFileSent<T> => 'fileId' in obj;

export const isAllReady = <T extends object>(
  uploads: UploadFile<T>[]
): uploads is UploadFileReady<T>[] => !uploads.some(isPending);

export enum UploadStatus {
  pending = 'pending',
  ready = 'ready',
  uploading = 'uploading',
  failed = 'failed',
  done = 'done',
}

export enum StreamStatus {
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export type StreamData = {
  percentage: number;
  transferredBytes: number;
  uploadStatus: StreamStatus;
};

// Sample response
// {
//   "fileSize": 765365,
//   "fileId": "2d879d1d-1adb-44b8-8185-15fa45d83b00",
//   "fileName": "1",
//   "fileType": "UTILITY_BILL",
//   "createdAt": "2022-03-25T04:01:39.485243",
//   "modifiedAt": "2022-03-25T04:01:39.485243"
// }
export interface UploadResponse {
  fileSize: number;
  fileId: string;
  fileName: string;
  fileType: 'UTILITY_BILL';
  createdAt: Date;
  modifiedAt: Date;
}
