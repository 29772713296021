import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './SopOverviewTooltip.module.css';

type Props = {
  tooltipEntry: string;
};

const SopOverviewTooltip: React.FC<Props> = ({ tooltipEntry }) => {
  return (
    <Tooltip
      underline={false}
      arrowDirection="bottomRight"
      extraClassNames={[styles.tooltip]}
      content={
        <div className={styles.tooltipText}>
          <div className={styles.tooltipLineEntry}>{tooltipEntry}</div>
        </div>
      }
    >
      <IoIosInformationCircleOutline size={20} />
    </Tooltip>
  );
};

export default SopOverviewTooltip;
