import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../reducers';
import { useIs12HrTimeFormat } from '../../hooks/useAppDetails';
import {
  TimeTable,
  TimeTableRow,
} from '@energybox/react-ui-library/dist/types';

import { getWeekDayRange } from '../../utils/getWeekdayRange';
import { formatTimeSlotToString } from '../../utils/time';
import { getTimetablesRoute } from '../../utils/timeTables';

import styles from './OperatingHoursSopValue.module.css';

type Props = {
  timeTable: TimeTable;
};

const displayTimeTableRow = (
  row: TimeTableRow,
  index: number,
  is12HrTimeFormat: boolean
) => (
  <div key={index} className={styles.timeTableRow}>
    {`${getWeekDayRange(row.weekdays)} (${formatTimeSlotToString(
      row.begin,
      is12HrTimeFormat
    )} - ${formatTimeSlotToString(row.end, is12HrTimeFormat)})`}
  </div>
);

const OperatingHoursSopValue: React.FC<Props> = ({ timeTable }) => {
  const is12HrTimeFormat = useIs12HrTimeFormat();
  const orgId = useSelector(
    ({ app }: ApplicationState) => app.currentOrganizationId
  );

  return (
    <div>
      <div className={styles.opHoursSopValue}>
        {timeTable.rows.map((row, index) =>
          displayTimeTableRow(row, index, is12HrTimeFormat)
        )}
      </div>
      <Link
        className={styles.timetableLink}
        to={getTimetablesRoute({
          orgUnitId: timeTable.organizationUnitId,
          timetableId: timeTable.id,
          isOrgLevelTimetable: timeTable.organizationUnitId === orgId,
        })}
      >
        {timeTable.title}
      </Link>
    </div>
  );
};

export default OperatingHoursSopValue;
