import {
  Button,
  Card,
  CardContent,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { Organization } from '@energybox/react-ui-library/dist/types';
import { Organization as OrganizationIcon } from '@energybox/react-ui-library/dist/icons';
import { useDispatch, useSelector } from 'react-redux';
import { switchOrganization } from '../../../actions/app';
import IndexManagementPageLayout from '../../../components/IndexManagementPageLayout';
import ModalFormContent from '../../../components/ModalFormContent';
import { ApplicationState } from '../../../reducers';
import { sortedOrganizationsWithOrgTypeOrdinal } from '../../../reducers/organizations';
import styles from './InstallerDashboard.module.css';
import { useCurrentOrganizationId } from '../../../hooks/useCurrentOrganization';
import { useCallback, useEffect } from 'react';
import { useCurrentUser } from '../../../hooks/useAppDetails';
import { isCustomer } from '@energybox/react-ui-library/dist/utils';

const InstallerDashboard: React.FC = () => {
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const currentOrganizationId = useCurrentOrganizationId();

  const organizations = useSelector<ApplicationState, Organization[]>(
    ({ organizations }) =>
      sortedOrganizationsWithOrgTypeOrdinal(organizations.organizationsById)
  );

  const switchOrg = useCallback(
    (id: number) => dispatch(switchOrganization(id)),
    []
  );

  return (
    <IndexManagementPageLayout
      content={
        <div className={styles.root}>
          <div className={styles.prompt}>
            Please Select the Organization you want to work in from the
            Organization Selector.
          </div>
          <Card className={styles.cardContainer}>
            <CardContent>
              <ModalFormContent className={styles.fields}>
                <div className={styles.accessToOrg}>
                  <Label htmlFor="title">Access to Organizations:</Label>
                </div>
                <div className={styles.organizationList}>
                  {organizations.map(({ title, id, organizationType }) => (
                    <Button
                      variant="text"
                      onClick={() => switchOrg(id)}
                      disabled={currentOrganizationId === id}
                      className={styles.switchOrgButton}
                    >
                      <OrganizationIcon
                        color="#45555f"
                        size={14}
                        className={styles.orgIcon}
                      />
                      {title}
                      {isCustomer(organizationType) && ` (${id})`}
                    </Button>
                  ))}
                </div>
              </ModalFormContent>
            </CardContent>
          </Card>
        </div>
      }
    />
  );
};

export default InstallerDashboard;
