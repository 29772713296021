import React from 'react';
import { EnergyPro } from '@energybox/react-ui-library/dist/types';
import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';

type Props = {
  className?: string;
  energyPros: (
    | EnergyPro
    | {
        id: number;
        title: string;
      }
  )[];
  activeEnergyPro: EnergyPro | undefined;
  onChange: (value: number) => void;
};

const SelectActiveEnergyPro: React.FC<Props> = ({
  className,
  energyPros,
  activeEnergyPro,
  onChange,
}) => {
  if (!energyPros || energyPros.length === 0) return null;
  return (
    <div data-testid={'active-energyPro-selector'} className={className}>
      <Select title={activeEnergyPro?.title} variant={'select'} noBottomLine>
        {energyPros &&
          energyPros.map(ePro => (
            <SelectItem
              key={`${ePro.id}-ePro-select`}
              isSelected={ePro.id === activeEnergyPro?.id}
              onSelect={() => onChange(ePro.id)}
            >
              {ePro.title}
            </SelectItem>
          ))}
      </Select>
    </div>
  );
};

export default SelectActiveEnergyPro;
