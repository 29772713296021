import { Site } from '@energybox/react-ui-library/dist/types';
import {
  MultiSelect,
  MultiSelectItem,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect, useState } from 'react';
import { equals } from 'ramda';
import styles from '../../containers/Selects/MultiSelects.module.css';

interface Props {
  onSelect: (site: Site) => void;
  onToggleSelectAll?: (sites: Site[]) => void;
  onRemoveSite: (siteId: Number) => void;
  error: boolean;
  customErrorText?: string;
  showSelectAll?: boolean;
  selectedSiteIds: number[];
  errorSiteIds?: number[];
  sites: Site[];
}

function SiteSelector(props: Props) {
  const {
    onSelect,
    onRemoveSite,
    error,
    customErrorText,
    showSelectAll = true,
    selectedSiteIds,
    errorSiteIds,
    sites,
  } = props;

  const [filteredSites, setFilteredSites] = useState<Site[]>([]);
  const [value, setValue] = useState('');

  const siteCompare = (a: Site, b: Site) =>
    a.title.localeCompare(b.title, ['kn']);

  useEffect(() => {
    let filteredOptions = sites;
    if (value !== '') {
      filteredOptions = filteredOptions.filter(s =>
        s.title.toLowerCase().includes(value.toLowerCase())
      );
    }
    if (selectedSiteIds.length > 0) {
      filteredOptions = filteredOptions
        .filter(s => !selectedSiteIds.includes(s.id))
        .sort(siteCompare);
    }
    if (!equals(filteredSites, filteredOptions)) {
      setFilteredSites(filteredOptions);
    }
  }, [sites, value, selectedSiteIds]);

  const toggleSelectAll = () => {
    const { onToggleSelectAll, selectedSiteIds } = props;
    onToggleSelectAll?.(selectedSiteIds.length > 0 ? [] : filteredSites || []);
  };

  return (
    <MultiSelect
      doNotIncludeActions
      selectionComponentType="portalDropdown"
      onDeleteItem={(e, itemId) => onRemoveSite(Number(itemId))}
      items={selectedSiteIds.map(siteId => {
        return {
          value: `${
            (Object.values(sites).find(({ id }) => id === siteId) || {}).title
          }`,
          className:
            error && errorSiteIds && errorSiteIds.indexOf(siteId) > -1
              ? styles.errorChip
              : '',
          id: siteId,
        };
      })}
      selectionComponent={
        <div className={styles.dropdown}>
          <div className={styles.searchBox}>
            <SearchBox
              error={filteredSites.length === 0}
              withDropdown={false}
              query={value}
              width={'100%'}
              widthActive={'100%'}
              placeholder={'Quick Search'}
              onChange={setValue}
              noClickOutsideDetection
            />
          </div>
          <div className={styles.dropdownList}>
            {showSelectAll && (
              <div className={styles.selectAll}>
                <span
                  tabIndex={0}
                  onKeyPress={toggleSelectAll}
                  role="button"
                  onClick={toggleSelectAll}
                >
                  {selectedSiteIds.length > 0 ? 'Deselect All' : 'Select All'}
                </span>
              </div>
            )}
            {filteredSites.map(site => (
              <MultiSelectItem
                key={`siteSelectItem${site.id}`}
                isSelected={selectedSiteIds.indexOf(site.id) > -1}
                onSelect={() => onSelect(site)}
              >
                <div style={{ padding: '10px', lineHeight: '1rem' }}>
                  <b>{site.title}</b>
                  <br />
                  <span>{site.address}</span>
                </div>
              </MultiSelectItem>
            ))}
          </div>
        </div>
      }
      onSelection={onSelect}
      error={error}
      customErrorText={customErrorText}
    />
  );
}

export default SiteSelector;
