import {
  Loader,
  Modal,
  ModalContent,
  ModalTitle,
  ProgressBar,
} from '@energybox/react-ui-library/dist/components';
import {
  Actuator,
  ActuatorCircuitStatus,
  Equipment,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import { SubscribedControlStatusById } from '@energybox/react-ui-library/dist/types/SubscribedControlStatus';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  endInstallerTest,
  getTestResults,
} from '../../../actions/control_boards';
import {
  enableStartSequence,
  hideStartSequenceModal,
} from '../../../actions/run_configuration';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../../actions/streamApi';
import { useControlBoardLocalOverride } from '../../../hooks/streamApi/useControlStatus';
import { ApplicationState } from '../../../reducers';
import { renderCircuitStatus } from '../../Gateways/GatewayDetailPages/ShowControlBoardPage/ShowControlBoardPage';
import styles from './StartSequence.module.css';

type Props = {
  siteId: string | number;
  equipments: Equipment[];
  actuators: Actuator[];
};
export const StartSequenceModal: React.FC<Props> = ({
  siteId,
  equipments,
  actuators,
}) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [callTestResults, setCallTestResults] = useState(false);
  const [loader, setLoader] = useState(false);

  const filteredEquipments = equipments.filter(equipment => {
    if (equipment.activeControl?._entity === 'Scheduler') {
      return equipment;
    }
  });

  const checkActuators = actuators?.filter(actuator => {
    const status = getActuatorCircuitStatus(actuator);
    if (status?.props.children === ActuatorCircuitStatus.OFF) {
      return actuator;
    }
  });

  const equipmentControlStatus = filteredEquipments.filter(equipment => {
    const workingMode = getControlStatus(equipment);
    if (workingMode !== WorkingMode.CALIBRATION && workingMode !== undefined) {
      return equipment;
    }
  });

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!equipmentControlStatus.length && !checkActuators.length) {
      setTimeout(() => {
        setCallTestResults(true);
        dispatch(hideStartSequenceModal());
        dispatch(enableStartSequence());
      }, 40000);
    }
  }, [equipmentControlStatus, checkActuators]);

  useEffect(() => {
    if (callTestResults) {
      dispatch(getTestResults(siteId));
    }
  }, [callTestResults]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress === 100) {
          clearInterval(timer);
          setError(
            'Something went wrong. Please Cancel and restart the Sequence'
          );
          return prevProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(prevProgress + diff, 100);
      });
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const closeStartSequenceModal = () => {
    dispatch(endInstallerTest(siteId));
    setLoader(true);
    const timeout = setTimeout(() => {
      dispatch(hideStartSequenceModal());
      reload();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  };

  const actions = (
    <>
      <Button variant="text" onClick={() => closeStartSequenceModal()}>
        {loader ? <Loader size={15} /> : 'Cancel'}
      </Button>
    </>
  );
  return (
    <Modal actions={actions} disableEscapeClose={true}>
      <ModalTitle>Preparing Equipment</ModalTitle>
      <ModalContent>
        <div className={styles.loadingBar}>
          <ProgressBar perceivedProgress={progress} />
          <span>{Math.round(progress)}% done</span>
        </div>
        <p>
          The system is changing all CAMs to Manual mode and setting all relays
          to CircuitON{' '}
        </p>
        <p>
          This process may take few minutes. Please do not close this window.
        </p>
        <div className={styles.pinkText}>{error}</div>
      </ModalContent>
    </Modal>
  );
};

export default StartSequenceModal;

export const getControlStatus = (equipment: Equipment) => {
  const dispatch = useDispatch();
  const activeControlId = equipment.activeControl?.id;
  useEffect(() => {
    let unsubscribe: (() => void) | null = null;
    if (isDefined(activeControlId)) {
      dispatch(
        subscribeToDeviceStatus(
          'energybox',
          String(activeControlId),
          activeControlId
        )
      );
      unsubscribe = () =>
        dispatch(
          unsubscribeFromDeviceStatus(
            'energybox',
            String(activeControlId),
            activeControlId
          )
        );
    }
    return () => {
      if (isDefined(unsubscribe)) unsubscribe();
    };
  }, [activeControlId, subscribeToDeviceStatus, unsubscribeFromDeviceReadings]);

  const controlStatus = useSelector<
    ApplicationState,
    SubscribedControlStatusById
  >(state => state.subscribedControlStatus);
  const { byCamId } = controlStatus;

  if (isDefined(byCamId) && isDefined(activeControlId)) {
    return byCamId[activeControlId]?.workingMode;
  }
  return undefined;
};

export const getActuatorCircuitStatus = (actuator: Actuator) => {
  const subscribedControlBoardOutputStates = useSelector(
    ({ subscribedControlBoardOutputStates }: ApplicationState) =>
      subscribedControlBoardOutputStates
  );
  const subscribedActuatorStates =
    subscribedControlBoardOutputStates[actuator.controlBoardId]?.state;

  const isLocalOverrideActive = useControlBoardLocalOverride(
    actuator.controlBoardId
  );
  const circuitStatus = renderCircuitStatus(
    actuator.port,
    actuator.portType,
    subscribedActuatorStates,
    isLocalOverrideActive
  );
  return circuitStatus;
};
