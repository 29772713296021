import { classNames, isDefined } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import styles from './ControlCardNotes.module.css';

type Props = {
  siteId?: number;
  className?: string;
  isControlInCalibrationMode?: boolean;
  isThermostatNotMapped?: boolean;
  thereIsNoHvacSop?: boolean;
};

const ControlCardNotes: React.FC<Props> = ({
  siteId,
  className,
  isControlInCalibrationMode,
  isThermostatNotMapped,
  thereIsNoHvacSop,
}) => {
  const noNotesToDisplay =
    !isControlInCalibrationMode && !isThermostatNotMapped && !thereIsNoHvacSop;

  if (noNotesToDisplay) return null;
  return (
    <div className={`${styles.root} ${className}`}>
      <div className={classNames(styles.boldText, styles.paddingRight)}>
        Note:
      </div>

      <div>
        {isControlInCalibrationMode && (
          <div>No configuration will be sent to the device in manual mode</div>
        )}

        {isThermostatNotMapped && <div>Please complete Thermostat Mapping</div>}

        {thereIsNoHvacSop && isDefined(siteId) && (
          <div>
            <div>There is no SOP attached to this Equipment Type.</div>
            <div>
              Please go to{' '}
              <Link
                to={`${Routes.SITES}/${siteId}${Routes.SOPS}${Routes.HVAC}`}
              >
                HVAC SOP
              </Link>{' '}
              to add a standard operating procedure
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ControlCardNotes;
