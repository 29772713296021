const apiBase = '/api/v1/edge';

export enum Actions {
  POST_NEW_EDGE_LOADING = '@edge_devices/POST_NEW_EDGE_CONFIG_LOADING',
  POST_NEW_EDGE_SUCCESS = '@edge_devices/POST_NEW_EDGE_CONFIG_SUCCES',
  POST_NEW_EDGE_ERROR = '@edge_devices/POST_NEW_EDGE_CONFIG_ERROR',

  GET_EDGE_CONFIG_FILE_LOADING = '@edge_devices/GET_EDGE_CONFIG_FILE_LOADING',
  GET_EDGE_CONFIG_FILE_SUCCESS = '@edge_devices/GET_EDGE_CONFIG_FILE_SUCCES',
  GET_EDGE_CONFIG_FILE_ERROR = '@edge_devices/GET_EDGE_CONFIG_FILE_ERROR',

  GET_EDGE_UPDATE_LOADING = '@edge_devices/GET_EDGE_UPDATE_LOADING',
  GET_EDGE_UPDATE_SUCCESS = '@edge_devices/GET_EDGE_UPDATE_SUCCES',
  GET_EDGE_UPDATE_ERROR = '@edge_devices/GET_EDGE_UPDATE_ERROR',

  RESET_EDGE_DEVICE_STATE = '@edge_devices/RESET_EDGE_DEVICE_STATE',
}

export const produceEdgeConfig = (
  networkGroupId: string | number,
  edgeUuid?: string | undefined,
  edgeId?: number
) => ({
  type: 'API_POST',
  path: `${apiBase}/produce-config/by-network-group-id/${networkGroupId}`,
  success: edgeId
    ? [
        { type: Actions.POST_NEW_EDGE_SUCCESS, networkGroupId, edgeUuid },
        edgeConfigStatusUpdate(edgeId),
      ]
    : { type: Actions.POST_NEW_EDGE_SUCCESS, networkGroupId, edgeUuid },
  error: { type: Actions.POST_NEW_EDGE_ERROR, networkGroupId, edgeUuid },
  loading: { type: Actions.POST_NEW_EDGE_LOADING, networkGroupId, edgeUuid },
});

export const getEdgeConfigFileBySerialNumber = (serialNumber: string) => ({
  type: 'API_GET',
  path: `${apiBase}/config/by-serial-number/${serialNumber}/preview`,
  success: { type: Actions.GET_EDGE_CONFIG_FILE_SUCCESS, serialNumber },
  error: { type: Actions.GET_EDGE_CONFIG_FILE_ERROR, serialNumber },
  loading: { type: Actions.GET_EDGE_CONFIG_FILE_LOADING, serialNumber },
});

export const edgeConfigStatusUpdate = (edgeId: number | undefined) => ({
  type: 'API_GET',
  path: `${apiBase}/fetch-hash-edge/by-id/${edgeId}`,
  success: { type: Actions.GET_EDGE_UPDATE_SUCCESS, edgeId },
  error: { type: Actions.GET_EDGE_UPDATE_ERROR, edgeId },
  loading: { type: Actions.GET_EDGE_UPDATE_LOADING, edgeId },
});

export const resetEdgeDeviceState = () => ({
  type: Actions.RESET_EDGE_DEVICE_STATE,
});
