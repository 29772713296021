import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addScopeToUser } from '../../actions/users';
import ModalFormContent from '../../components/ModalFormContent';
import { ApplicationState } from '../../reducers';

import styles from '../User/ShowUserPage.module.css';
import MultiSelectOrgAccess from '../Selects/MultiSelectOrgAccess';
import { Role, User } from '@energybox/react-ui-library/dist/types';

type Props = {
  user: User;
  onClose: () => void;
};

const AddOrgAccessModal: React.FC<Props> = ({ user, onClose }) => {
  const dispatch = useDispatch();

  const [orgAccess, setOrgAccess] = useState<number[]>([]);

  const isAdding = useSelector<ApplicationState, boolean>(
    ({ users }) => users.editById[user.id].isLoading
  );

  const save = () => {
    if (orgAccess.length > 0) {
      dispatch(
        addScopeToUser('' + user.id, {
          resourceId: orgAccess.map(id => '' + id),
          role: Role.INSTALLER.toUpperCase(),
        })
      );
      onClose();
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button disabled={orgAccess.length === 0 || isAdding} onClick={save}>
        Save
      </Button>
    </>
  );

  return (
    <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
      <ModalTitle>Add Access to Organizations</ModalTitle>
      <ModalContent>
        <div>Select Org for Installer Access Rights</div>
        <ModalFormContent className={styles.userAccess}>
          <div>
            <Label>User Rights</Label>
          </div>
          <div className={styles.predefined}>Installer</div>
          <div>
            <Label>Select Organizations</Label>
          </div>
          <div>
            <MultiSelectOrgAccess
              onSelect={id => setOrgAccess([...orgAccess, id])}
              onToggleSelectAll={ids => setOrgAccess(ids)}
              onDeselect={removeId =>
                setOrgAccess(orgAccess.filter(id => id !== removeId))
              }
              selectedOrgIds={orgAccess}
              checkAgianstUser={user}
            />
          </div>
        </ModalFormContent>
      </ModalContent>
    </Modal>
  );
};

export default AddOrgAccessModal;
