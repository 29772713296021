import {
  Button,
  LongExtraLongSkeletonCell,
  MediumLongSkeletonCell,
  SearchBox,
  SkeletonDisplayText,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  Height,
  Length,
  OpacityIndex,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  hasSubstr,
  SORT_IGNORED_VALUES,
  values,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { Actions, getGroups, showNewModal } from '../../actions/user_groups';
import AccessResourceRecord from '../../components/AccessResourceRecord';
import BubblesList from '../../components/BubblesList';
import UserGroupTitle from '../../components/UserGroupTitle';
import UsersManagementLayout from '../../components/UsersManagementLayout';
import { ApplicationState } from '../../reducers';
import { UserGroup } from '../../reducers/user_groups';
import { CreateNewText } from '../../types/global';
import { checkCommonPlural, fullName, initials } from '../../util';
import NewGroupModal from '../Modals/NewGroupModal';
import history from '../../history';
import { Placeholder } from '../../types/global';

import styles from './UserGroupUsersTable.module.css';
import theme from '../../theme';
import { TableWrapper } from '../../components/ui/Table';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';

interface Props {
  getGroups: typeof getGroups;
  showNewModal: typeof showNewModal;
  userGroups: UserGroup[];
  getGroupsIsLoading: boolean | undefined;
  viewportType: ViewportTypes;
}

interface State {
  query: string;
  groupsLoadingGuard: boolean;
  pageNumber: number;
}

class UserGroupsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: '',
      groupsLoadingGuard: true,
      pageNumber: 1,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ groupsLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams<string>(history, 'query', '');
    const savedPage = getUrlStateParams<number>(history, 'page', 1);

    this.setState({ query: savedQuery });
    this.setState({ pageNumber: savedPage });

    this.props.getGroups();
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  pagination = (pageCount: number) => {
    this.setState({ pageNumber: pageCount }, () => {
      const { pageNumber: pageFilter } = this.state;
      updateUrlState(history, 'page', pageFilter);
    });
  };

  render() {
    const { userGroups, getGroupsIsLoading, viewportType } = this.props;
    const { query, groupsLoadingGuard } = this.state;

    const isMobile = viewportType === ViewportTypes.MOBILE;
    const isLoading = groupsLoadingGuard || getGroupsIsLoading;

    const filteredGroups =
      query && query.length >= 3
        ? userGroups.filter(group => hasSubstr(group.title, query))
        : userGroups;

    const actions = (
      <>
        <Button onClick={this.props.showNewModal}>
          {CreateNewText.USER_GROUP}
        </Button>
      </>
    );

    const tableColumns: Columns<UserGroup>[] = [
      {
        header: 'User Group',
        width: '40%',
        cellContent: (userGroup: UserGroup) => (
          <UserGroupTitle {...userGroup} />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: UserGroup,
          b: UserGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Users',
        width: '25%',
        defaultSortDirection: SortDirection.DESC,
        cellContent: ({ id, users }: UserGroup) => (
          <div>
            <BubblesList
              limit={6}
              resource="user"
              moreButtonLinkTo={`/user-groups/${id}`}
              items={(users || []).map(user => ({
                title: fullName(user),
                displayTitle: initials(user),
                url: `/users/${user.id}`,
              }))}
            />
          </div>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: UserGroup,
          b: UserGroup,
          sortDirection: SortDirection
        ) => {
          const numberOfUsers = (u: UserGroup) => u.users.length;
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            numberOfUsers
          );
        },
      },
      {
        header: 'Site Access',
        width: '35%',
        cellContent: ({ accessResources }: UserGroup) => (
          <>
            {(accessResources || []).map(resource => (
              <AccessResourceRecord key={resource.id} {...resource} />
            ))}
          </>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
      },
    ];

    return (
      <UsersManagementLayout actions={actions}>
        <DynamicContentWrapper>
          <PageContentHeader>
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredGroups.length === 0}
            />
          </PageContentHeader>
          <TableWrapper
            header={checkCommonPlural('Group', filteredGroups.length)}
            isLoading={isLoading}
          >
            <Table
              listView
              columns={tableColumns}
              data={filteredGroups || []}
              dataIsLoading={isLoading}
              pagination={this.pagination}
              pageNumber={filteredGroups.length > 5 ? this.state.pageNumber : 1}
            />
          </TableWrapper>
        </DynamicContentWrapper>
        <NewGroupModal type="user" />
      </UsersManagementLayout>
    );
  }
}

const mapStateToProps = ({ userGroups }: ApplicationState) => ({
  userGroups: values(userGroups.userGroupsById),
  getGroupsIsLoading:
    userGroups.loadingStatusByAction[Actions.GET_LIST_LOADING],
});

const mapDispatchToProps = {
  getGroups,
  showNewModal: () => showNewModal(),
};

export default withViewportType(
  connect(mapStateToProps, mapDispatchToProps)(UserGroupsPage)
);
