import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import EditSpaceForm from '../../components/EditSpaceForm';
import { Button } from '@energybox/react-ui-library/dist/components';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import styles from './NewSpaceItem.module.css';
import { EditableFields } from '../../reducers/spaces';
import { ModalTitle } from '@energybox/react-ui-library/dist/components';
import { CreateNewText } from '../../types/global';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import {
  create,
  displayFormErrors,
  updateField,
  Actions as SpaceActions,
  reset,
} from '../../actions/spaces';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';

interface OwnProps {}

interface Props extends OwnProps {
  className?: string;
  siteId: number;
  fields: EditableFields;
  isLoading: boolean;
  isChanged: boolean;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
  onChange: (field: string, value: any) => void;
  onClose: () => void;
  createSpace: () => void;
  displayFormErrors: () => void;
}

class NewSpaceItem extends React.Component<Props> {
  onBlurItem = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  onCreate = () => {
    this.props.createSpace();
    this.props.onClose();
  };

  render() {
    const {
      className,
      siteId,
      fields,
      formErrors,
      formErrorsVisible,
      apiError,
      onChange,
      onClose,
    } = this.props;
    return (
      <div
        className={classNames(styles.wrapper, className)}
        onBlur={this.onBlurItem}
      >
        <ModalTitle>{CreateNewText.SPACE}</ModalTitle>
        <div className={styles.container}>
          <EditSpaceForm
            isNew
            fields={fields}
            onChange={onChange}
            currentSiteId={String(siteId)}
            formErrorsVisible={formErrorsVisible}
            formErrors={formErrors}
          />
        </div>
        <div className={styles.actions}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={false} onClick={this.onCreate}>
            Create
          </Button>
        </div>
        {renderAPIerror(apiError, SpaceActions.CREATE_SPACE_ERROR)}
      </div>
    );
  }
}

const mapStateToProps = ({ spaces }: ApplicationState) => ({
  ...spaces.editById.new,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    createSpace: () => dispatch(create()),
    onChange: (field, value) => dispatch(updateField('new', field, value)),
    displayFormErrors: () => dispatch(displayFormErrors('new')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSpaceItem);
