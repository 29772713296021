import {
  Button,
  FormText,
  InputField,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors, Region } from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import * as siteGroupsActions from '../../actions/site_groups';
import * as userGroupsActions from '../../actions/user_groups';
import ModalFormContent from '../../components/ModalFormContent';
import { ApplicationState } from '../../reducers';
import { CreateNewText } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import SelectRegion from '../Selects/SelectRegion';
import MultiSelectAddUser from '../Selects/MultiSelectAddUser';
import { addUserToGroup } from '../../actions/users';
import MultiSelectSiteAccessButton from '../Selects/MultiSelectSiteAccessButton';
import SelectedSitesTable from './SelectedSitesTable';

const mapTypeToStateStore = {
  site: 'siteGroups',
  user: 'userGroups',
  installer: 'userGroups',
};

const mapTypeToActions = {
  site: siteGroupsActions,
  user: userGroupsActions,
  installer: userGroupsActions,
};

interface OwnProps {
  type: 'user' | 'site' | 'installer';
}

interface Props extends OwnProps {
  isVisible: boolean;
  onCreate: (callback?: (response: { id?: number }) => void) => void;
  onClose: () => void;
  onChange: (field: string, value: string | number[]) => void;
  addUserToGroup: typeof addUserToGroup;
  title: string;
  description: string;
  region: Region | null;
  displayFormErrors: () => void;
  formErrorsVisible: boolean;
  formErrors: GenericErrors;
  apiError: ApiError;
}

interface State {
  adding: boolean;
  resourceIdsToBeAdded: number[];
}

class NewGroupModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      adding: false,
      resourceIdsToBeAdded: [],
    };
  }

  onGroupCreate = () => {
    const {
      formErrors,
      displayFormErrors,
      onCreate,
      addUserToGroup,
    } = this.props;
    this.setState({ adding: true });
    const { resourceIdsToBeAdded } = this.state;
    if (hasKeys(formErrors)) {
      displayFormErrors();
      this.setState({ adding: false });
    } else {
      onCreate(response => {
        const { id: createdGroupId } = response;
        if (createdGroupId && resourceIdsToBeAdded.length > 0) {
          Promise.all(
            resourceIdsToBeAdded.map(
              resourceId =>
                new Promise<void>(resolve => {
                  addUserToGroup(resourceId, createdGroupId, () => resolve());
                })
            )
          ).finally(() =>
            this.setState({ adding: false, resourceIdsToBeAdded: [] })
          );
        }
      });
    }
    this.setState({ adding: false, resourceIdsToBeAdded: [] });
  };

  onClose = () => {
    this.setState({ resourceIdsToBeAdded: [] });
    this.props.onClose();
  };

  addResource(ids: number[]) {
    this.setState({ resourceIdsToBeAdded: ids });
  }

  render() {
    if (!this.props.isVisible) return null;
    const {
      onChange,
      title,
      description,
      region,
      type,
      formErrorsVisible,
      formErrors,
      apiError,
    } = this.props;

    const { adding, resourceIdsToBeAdded } = this.state;

    const actions = (
      <>
        <Button variant="text" onClick={this.onClose}>
          Cancel
        </Button>
        <Button onClick={this.onGroupCreate} disabled={adding}>
          Add
        </Button>
      </>
    );
    return (
      <Modal onClose={() => {}} actions={actions} disableEscapeClose>
        <ModalTitle>
          {type === 'site'
            ? CreateNewText.SITE_GROUP
            : type === 'user'
            ? CreateNewText.USER_GROUP
            : CreateNewText.INSTALLER_GROUP}
        </ModalTitle>
        <ModalContent>
          <ModalFormContent>
            <b>
              <Label required>Group Name</Label>
            </b>
            <div>
              <InputField
                id="title"
                value={title}
                onChange={e => onChange('title', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.title}
                customErrorText={formErrors.title && formErrors.title[0]}
              />
            </div>
            <b>
              <Label>Description</Label>
            </b>
            <div>
              <InputField
                id="description"
                value={description}
                placeholder="Brief description recommended"
                onChange={e => onChange('description', e.currentTarget.value)}
              />
            </div>
            {type === 'installer' && (
              <>
                <b>
                  <Label required>EB Region</Label>
                </b>
                <div>
                  <SelectRegion
                    onSelect={value => onChange('region', value)}
                    value={region}
                    error={formErrorsVisible && !!formErrors.region}
                    customErrorText={formErrors?.region?.[0]}
                  />
                </div>
              </>
            )}
            {type === 'installer' && (
              <>
                <b>
                  <Label>Users</Label>
                </b>
                <div>
                  <MultiSelectAddUser
                    onSelect={id =>
                      this.addResource([...resourceIdsToBeAdded, id])
                    }
                    onToggleSelectAll={ids => this.addResource(ids)}
                    onDeselect={removeId =>
                      this.addResource(
                        resourceIdsToBeAdded.filter(id => id !== removeId)
                      )
                    }
                    selectedUserIds={resourceIdsToBeAdded}
                  />
                </div>
              </>
            )}
            {type === 'site' && (
              <>
                <b>
                  <Label>Sites</Label>
                </b>
                <div>
                  <MultiSelectSiteAccessButton
                    selectedResourceIds={resourceIdsToBeAdded}
                    onSelect={id => {
                      let changedResourceIdList = [
                        ...resourceIdsToBeAdded,
                        id.resourceId,
                      ];
                      this.addResource(changedResourceIdList);
                      onChange('siteIds', changedResourceIdList);
                    }}
                    onDeselect={removeId => {
                      let changedResourceIdList = resourceIdsToBeAdded.filter(
                        id => id !== removeId
                      );
                      this.addResource(changedResourceIdList);
                      onChange('siteIds', changedResourceIdList);
                    }}
                    onlySites={true}
                  />
                </div>
              </>
            )}
          </ModalFormContent>
          {type === 'site' && (
            <SelectedSitesTable selectedSiteIds={resourceIdsToBeAdded} />
          )}
          <FormText>* Mandatory fields</FormText>

          {renderAPIerror(
            apiError,
            mapTypeToActions[type].Actions.CREATE_ERROR
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState, { type }: OwnProps) => {
  const store = state[mapTypeToStateStore[type]];
  const group = store.editById['new'];

  return {
    isVisible: store.showNewGroupModal,
    title: group && group.title,
    description: group && group.description,
    region: group && group.fields.region,
    formErrorsVisible: group && group.formErrorsVisible,
    formErrors: group && group.formErrors,
    apiError: group && group.apiError,
  };
};

const mapDispatchToProps = (dispatch, { type }: OwnProps) => {
  const actions = mapTypeToActions[type];
  return {
    onClose: () => dispatch(actions.hideNewModal()),
    onChange: (field: string, value: string | number[]) =>
      dispatch(
        actions.updateField(
          'new',
          field,
          value,
          type === 'installer' ? 'installerGroup' : undefined
        )
      ),
    onCreate: (callback?: (response: { id?: number }) => void) =>
      dispatch(actions.create({ callback })),
    addUserToGroup: (
      u: number,
      g: number,
      c?: (response: { id: number }) => void
    ) => dispatch(addUserToGroup(u, g, c)),
    displayFormErrors: () => dispatch(actions.displayFormErrors('new')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupModal);
