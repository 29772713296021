import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { ResourceType } from '@energybox/react-ui-library/dist/types';
import { global, hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as OrganizationActions,
  displayFormErrors,
  patch,
  reset,
  updateField,
} from '../../actions/organizations';
import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';
import ModalFormContent from '../../components/ModalFormContent';
import { useGetCurrentOrganization } from '../../hooks/useCurrentOrganization';
import { ApplicationState } from '../../reducers';
import { EditOrganization } from '../../reducers/organizations';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import ImagesContainer from '../ImagesContainer';
import SelectCountry from '../Selects/SelectCountry';
import styles from './ShowOrganizationPage.module.css';

type Props = {
  orgId: string;
};

const ShowOrganizationPage: React.FC<Props> = ({ orgId }) => {
  const dispatch = useDispatch();
  const currentOrganization = useGetCurrentOrganization();

  const onChange = (field: string, value: any) => {
    dispatch(updateField(orgId, field, value));
  };

  const onCancel = () => {
    dispatch(reset(orgId));
  };

  const onOrganizationSave = () => {
    if (hasKeys(editOrganization?.formErrors || {})) {
      dispatch(displayFormErrors(orgId));
    } else {
      dispatch(patch(orgId));
    }
  };

  const editOrganization = useSelector<
    ApplicationState,
    EditOrganization | undefined
  >(({ organizations }) => {
    return organizations.editById[orgId];
  });

  if (!currentOrganization || !editOrganization) return null;
  const { fields, formErrors, formErrorsVisible, apiError } = editOrganization;
  const { street, street2, city, state, postalCode, country } = fields;
  return (
    <IndexManagementPageLayout>
      <Card className={styles.cardContainer}>
        <CardContent className={styles.cardGrid}>
          <CardTitle className={styles.cardTitle}>
            <div>General Information</div>
          </CardTitle>

          <div>
            <ModalFormContent>
              <div>
                <Label required htmlFor='title'>
                  Organization Name
                </Label>
              </div>
              <div className={styles.orgTitleText}>
                {currentOrganization?.title || global.NOT_AVAILABLE}
              </div>

              <div>
                <Label required htmlFor='country'>
                  Country
                </Label>
              </div>
              <div>
                <SelectCountry
                  onSelect={value => onChange('country', value)}
                  value={country}
                  error={formErrorsVisible && !!formErrors.country}
                  customErrorText={formErrors?.country?.[0]}
                />
              </div>

              <div>
                <Label required htmlFor='street'>
                  Street
                </Label>
              </div>
              <div>
                <InputField
                  id='street'
                  type='text'
                  name='street'
                  value={street || ''}
                  onChange={e => onChange('street', e.currentTarget.value)}
                  error={formErrorsVisible && !!formErrors.street}
                  customErrorText={formErrors?.street?.[0]}
                />
              </div>

              <div>
                <Label htmlFor='street2'>Street 2</Label>
              </div>
              <div>
                <InputField
                  id='street2'
                  type='text'
                  name='street2'
                  value={street2 || ''}
                  onChange={e => onChange('street2', e.currentTarget.value)}
                  error={formErrorsVisible && !!formErrors.street2}
                />
              </div>

              <div>
                <Label required htmlFor='city'>
                  City
                </Label>
              </div>
              <div>
                <InputField
                  id='city'
                  type='text'
                  name='city'
                  value={city || ''}
                  onChange={e => onChange('city', e.currentTarget.value)}
                  error={formErrorsVisible && !!formErrors.city}
                  customErrorText={formErrors?.city?.[0]}
                />
              </div>

              <div>
                <Label required htmlFor='postalCode'>
                  Postal Code
                </Label>
              </div>
              <div>
                <InputField
                  id='postalCode'
                  type='text'
                  name='postalCode'
                  value={postalCode || ''}
                  onChange={e => onChange('postalCode', e.currentTarget.value)}
                  error={formErrorsVisible && !!formErrors.postalCode}
                  customErrorText={formErrors?.postalCode?.[0]}
                />
              </div>

              <div>
                <Label htmlFor='state'>State</Label>
              </div>
              <div>
                <InputField
                  id='state'
                  type='text'
                  name='state'
                  value={state || ''}
                  onChange={e => onChange('state', e.currentTarget.value)}
                  error={formErrorsVisible && !!formErrors.state}
                />
              </div>
            </ModalFormContent>

            <ModalFormContent>
              <Label>* Mandatory fields</Label>
            </ModalFormContent>

            {renderAPIerror(
              apiError,
              OrganizationActions.PATCH_ORGANIZATION_ERROR
            )}
          </div>
        </CardContent>

        {editOrganization.isChanged && (
          <CardActions>
            <Button variant='text' onClick={onCancel} children='Cancel' />
            <Button
              disabled={editOrganization.isLoading}
              onClick={onOrganizationSave}
            >
              {editOrganization.isLoading ? (
                <Loader size={16} variant='secondary' />
              ) : (
                'Save Changes'
              )}
            </Button>
          </CardActions>
        )}
      </Card>

      <Card className={styles.cardContainer}>
        <CardContent>
          <CardTitle className={styles.cardTitle}>
            <div>Company Logo</div>
          </CardTitle>

          <div className={styles.imagesContainer}>
            <ImagesContainer id={orgId} type={ResourceType.ORGANIZATION} />
          </div>
        </CardContent>
      </Card>
    </IndexManagementPageLayout>
  );
};

export default ShowOrganizationPage;
