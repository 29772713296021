import { Actions } from '../../actions/sites';
import { ApplicationState } from '../../reducers';

export const siteIdsBySentinelIdSelector = ({ sites }: ApplicationState) =>
  sites.sitesBySentinelId;

export const sitesByIdSelector = ({ sites }: ApplicationState) =>
  sites.sitesById;

export const getSitesLoadingStatusSelector = ({ sites }: ApplicationState) => {
  return sites.loadingStatusByAction[Actions.GET_SITES_LOADING];
};
