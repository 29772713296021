import React from 'react';
import { DateTime } from 'luxon';
import { Locale } from '@energybox/react-ui-library/dist/types';
import {
  convertIsoToJSDate,
  genericDateFormat,
} from '@energybox/react-ui-library/dist/utils';

import { useEdmSchedules } from '../../hooks/useEdmConfig';
import HvacEdmSchedulesTooltip from './HvacEdmSchedulesTooltip';
import { formatTimeSlotToString } from '../../utils/time';
import styles from './HvacSopSchedulesTable.module.css';
import { EdmSchedule } from '../../reducers/edm';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

type Props = {
  equipmentId: number;
  locale: Locale;
  is12HrFormat: boolean;
};

const HvacEdmSchedulesTable: React.FC<Props> = ({
  equipmentId,
  locale,
  is12HrFormat,
}) => {
  const { scheduleByEquipmentId, isLoading, apiError } = useEdmSchedules();

  const edmSchedule = scheduleByEquipmentId['' + equipmentId];

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={styles.tableTitle}>
        <div>EDM Schedules</div>
        <span>
          <HvacEdmSchedulesTooltip arrowDirection="right" />
        </span>
      </div>

      {renderTable(edmSchedule, locale, apiError, is12HrFormat)}
    </>
  );
};

const renderTable = (
  edmSchedule: EdmSchedule,
  locale: Locale,
  apiError: ApiError,
  is12HrFormat: boolean
) => {
  if (!edmSchedule)
    return (
      <span className={styles.info}>
        {renderAPIerror(apiError) || 'No upcoming EDM overrides'}
      </span>
    );

  const scheduledDateObj = convertIsoToJSDate(edmSchedule.scheduledDate);
  const scheduledDate = genericDateFormat(scheduledDateObj, locale.dateFormat);

  return (
    <table className={styles.root}>
      <tr className={styles.tableRow}>
        <th className={styles.headerCell} scope="col" align="left">
          Timetable
        </th>
        <th className={styles.headerCell} scope="col" align="left">
          Days/Time
        </th>
      </tr>

      <tr className={styles.tableRow}>
        <td className={styles.cell} valign="top">
          EDM Override Start
        </td>
        <td className={styles.cell} valign="top">
          <div>{scheduledDate}</div>
          <div>{formatTimeSlotToString(edmSchedule.begin, is12HrFormat)}</div>
        </td>
      </tr>
      <tr className={styles.tableRow}>
        <td className={styles.cell} valign="top">
          EDM Override End
        </td>
        <td className={styles.cell} valign="top">
          <div>{scheduledDate}</div>
          <div>{formatTimeSlotToString(edmSchedule.end, is12HrFormat)}</div>
        </td>
      </tr>
    </table>
  );
};

export default HvacEdmSchedulesTable;
