// TODO: export this file to react-ui-lib after prod release

const orgNameToId = {
  CKA: 50780,
  BIO_REFERENCE: 81521,
  DUALE: 53268,
  HOLIDAY: 1257,
  MTG: 36052,
  MY_PLACE: 48779,
  LAB_CORP: 38782,
  CAL_LABS: 134009,
  QFRM: 214332,
  YELLOW_CAB: 234840,
  NEW_FRONTIER_TECH: 51439,
  INSTALLER_PARTNER: 909772,
};

const {
  CKA,
  BIO_REFERENCE,
  DUALE,
  HOLIDAY,
  MTG,
  LAB_CORP,
  MY_PLACE,
  CAL_LABS,
  QFRM,
  YELLOW_CAB,
  NEW_FRONTIER_TECH,
  INSTALLER_PARTNER,
} = orgNameToId;

export const CALIBRATION_CERTIFICATION_WHITELIST = [BIO_REFERENCE];
export const GATEWAY_SIREN_WHITELIST = [MTG];
export const USER_API_TOKEN_WHITELIST = [MY_PLACE];
export const EB_INSTALLER_WHITELIST = [INSTALLER_PARTNER];
