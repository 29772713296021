import { Actions } from '../actions/balena';
import { Actions as NetworkGroupActions } from '../actions/network_groups';
import { assocPath, pipe } from 'ramda';

export type RunningRelease = {
  id: number;
  raw_version: string;
  is_final: boolean;
};

export type BalenaFinalStatus =
  | 'Online'
  | 'Offline'
  | 'Frozen'
  | 'Inactive'
  | 'Online (VPN Only)'
  | 'Online (Heartbeat Only)'
  | 'Configuring'
  | 'Updating'
  | 'Post Provisioning';

export type BalenaStatus = {
  apiHeartbeatState: 'offline' | 'online';
  lastConnectivityEvent: string; // stringified timestamp
  runningRelease: RunningRelease[];
  online: boolean;
  finalStatus: BalenaFinalStatus;
  uuid: string;
};

export type BalenaDeviceStatus = {
  uuid: string;
  finalStatus: BalenaFinalStatus;
  overallStatus: string;
  overallProgress?: string;
  runningRelease: RunningRelease[];
  targetRelease: RunningRelease[];
  lastConnectivityEvent: string;
  apiHeartbeatState: string;
  osVersion: string;
  deviceTags: {
    tag_key: string;
    value: string;
  }[];
  active: boolean;
  webAccessible: boolean;
  online: boolean;
};

export type BalenaStatusById = {
  [uuid: string]: BalenaStatus;
};

export type BalenaStatusBySerialNumber = {
  [serialNumber: string]: BalenaDeviceStatus;
};

export type Balena = {
  statusByUuid: BalenaStatusById;
  statusBySerialNumber: BalenaStatusBySerialNumber;
  firmwareReleases: FirmwareRelease[];
  patchReleaseToDevice: {
    release: string;
    releaseId: number;
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  lookupMACAddressLoading: boolean;
  lookupMACAddressError: boolean;
};

export type FirmwareRelease = {
  id: number;
  is_running_on__device: number;
  status: string;
  commit: string;
  source: string;
  created_at: string;
  start_timestamp: string;
  update_timestamp: string;
  end_timestamp: string;
  raw_version: string;
  is_final: boolean;
  note: string;
};

export const initialState: Balena = {
  statusByUuid: {},
  statusBySerialNumber: {},
  firmwareReleases: [],
  patchReleaseToDevice: {
    release: '',
    releaseId: 0,
    loading: false,
    error: false,
    success: false,
  },
  lookupMACAddressLoading: false,
  lookupMACAddressError: false,
};

const balena = (state: Balena = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_DEVICE_STATUS_SUCCESS:
      return pipe(
        ...action.data.map((datum: BalenaStatus) =>
          assocPath(['statusByUuid', datum.uuid], datum)
        )
      )(state);
    case Actions.GET_DEVICE_STATUS_LOADING:
    case Actions.GET_DEVICE_STATUS_ERROR:
      return state;
    case Actions.GET_DEVICE_STATUS_BY_SERIAL_NUMBER_SUCCESS:
      return assocPath(
        ['statusBySerialNumber', action.serialNumber],
        action.data,
        state
      );
    case Actions.GET_DEVICE_STATUS_BY_SERIAL_NUMBER_LOADING:
    case Actions.GET_DEVICE_STATUS_BY_SERIAL_NUMBER_ERROR:
      return state;
    case Actions.GET_FIRMWARE_RELEASE_SUCCESS:
      return assocPath(['firmwareReleases'], action.data, state);
    case Actions.GET_FIRMWARE_RELEASE_LOADING:
    case Actions.GET_FIRMWARE_RELEASE_ERROR:
      return state;
    case Actions.PATCH_RELEASE_TO_DEVICE_SUCCESS:
      return pipe(
        assocPath(['patchReleaseToDevice', 'loading'], false),
        assocPath(['patchReleaseToDevice', 'error'], false),
        assocPath(['patchReleaseToDevice', 'success'], true),
        assocPath(['patchReleaseToDevice', 'release'], action.release),
        assocPath(['patchReleaseToDevice', 'releaseId'], action.releaseId)
      )(state);
    case Actions.PATCH_RELEASE_TO_DEVICE_LOADING:
      return assocPath(['patchReleaseToDevice', 'loading'], true, state);
    case Actions.PATCH_RELEASE_TO_DEVICE_ERROR:
      return assocPath(['patchReleaseToDevice', 'error'], true, state);
    case Actions.GET_DEVICE_INFO_BY_UUID_SUCCESS:
      if (action.data.macSouthUUID !== '') {
        return pipe(
          assocPath(
            ['statusByUuid', action.data.uuid, 'macNorthUUID'],
            action.data.macNorthUUID
          ),
          assocPath(
            ['statusByUuid', action.data.uuid, 'macSouthUUID'],
            action.data.macSouthUUID
          ),
          assocPath(
            ['statusByUuid', action.data.uuid, 'uuid'],
            action.data.uuid
          ),
          assocPath(['lookupMACAddressError'], false),
          assocPath(['lookupMACAddressLoading'], false)
        )(state);
      } else {
        return pipe(
          assocPath(['lookupMACAddressError'], true),
          assocPath(['lookupMACAddressLoading'], false)
        )(state);
      }
    case Actions.GET_DEVICE_INFO_BY_UUID_LOADING:
      return assocPath(['lookupMACAddressLoading'], true, state);
    case Actions.GET_DEVICE_INFO_BY_UUID_ERROR:
      return pipe(
        assocPath(['lookupMACAddressError'], true),
        assocPath(['lookupMACAddressLoading'], false, state)
      )(state);
    case NetworkGroupActions.RESET_EDIT_NETWORK_GROUP:
    case NetworkGroupActions.CLEAR_FORM_ERRORS:
      return assocPath(['lookupMACAddressError'], false, state);
    default:
      return { ...state };
  }
};

export default balena;
