import React, { useEffect, useRef, useState } from 'react';
import { ProgressBar } from '@energybox/react-ui-library/dist/components';
import styles from './SignalStrength.module.css';

// normalizeSignal from -100 ~ -20
const normaliseSignal = (value: number) => {
  const min = -100;
  const max = -20;
  if (-value <= min) return 0;
  if (-value >= max) return 100;
  return ((-value - min) * 100) / (max - min);
};

const getSignalColor = (value: number) => {
  if (value > 80) return '#FF514B';
  if (value > 70) return '#FC8017';
  return '#70DA7F';
};

const SignalStrength: React.FC<{ value: number; showValue?: boolean }> = ({
  value,
  showValue = true,
}) => {
  const barRef = useRef<HTMLDivElement>(null);
  const percentage = normaliseSignal(value);

  useEffect(() => {
    const customBackgroundColor = getSignalColor(value);

    if (barRef.current) {
      barRef.current.style.setProperty(
        '--customBackgroundColor',
        customBackgroundColor
      );
    }
  }, [value, percentage]);

  return (
    <>
      {showValue ? `-${value}dBm` : ''}
      <div ref={barRef}>
        <ProgressBar
          perceivedProgress={percentage}
          className={styles.customProgressBar}
          isSignalStrength={true}
          signalStyles={styles.customHighlightBar}
        />
      </div>
    </>
  );
};

export default SignalStrength;
