import { Card, CardContent } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import styles from './ChartCard.module.css';
import {
  global,
  isDefined,
  KWH_UNIT,
} from '@energybox/react-ui-library/dist/utils';

interface Props {
  id?: string;
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  energyTotal?: number;
}

const ChartCardContainer: React.FC<Props> = props => {
  const { title, subTitle, energyTotal, children } = props;

  return (
    <Card className={styles.root}>
      <CardContent className={styles.content}>
        <div className={styles.header}>
          <div>
            <div className={styles.title}>{title || global.NOT_AVAILABLE}</div>
            <div className={styles.subTitle}>
              {subTitle || global.NOT_AVAILABLE}{' '}
            </div>
          </div>

          <div className={styles.chartCardRightAlign}>
            Total Power:{' '}
            {isDefined(energyTotal)
              ? `${Number(energyTotal.toFixed(1))} ${KWH_UNIT}`
              : global.NOT_AVAILABLE}
          </div>
        </div>
        {children}
      </CardContent>
    </Card>
  );
};

export default ChartCardContainer;
