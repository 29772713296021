import {
  Equipment,
  EquipmentType,
  GenericErrors,
  Organization,
  Region,
  ResourceType,
  Sop,
} from '@energybox/react-ui-library/dist/types';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import {
  isDefined,
  mapArrayToObject,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/organizations';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface EditableFields {
  street: string | null;
  street2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
  region: Region | null;
}

const editableFields = (organization: Organization) =>
  R.pick(
    ['street', 'street2', 'city', 'state', 'postalCode', 'country'],
    organization
  );

export const sortedOrganizations = (
  organizationsById: OrganizationById
): Organization[] =>
  values(organizationsById)
    .filter(org => isDefined(org.title))
    .sort((a: Organization, b: Organization) => a.title.localeCompare(b.title));

export const sortedOrganizationsWithOrgTypeOrdinal = (
  organizationsById: OrganizationById
): Organization[] =>
  sortedOrganizations(organizationsById).sort(
    (a: Organization, b: Organization) =>
      Object.values(OrganizationType).indexOf(a.organizationType) -
      Object.values(OrganizationType).indexOf(b.organizationType)
  );

export const sortedCustomerOrganizations = (
  organizationsById: OrganizationById
): Organization[] =>
  sortedOrganizations(organizationsById).filter(
    org => org.organizationType === OrganizationType.CUSTOMER
  );

export type OrganizationById = {
  [id: string]: Organization;
};

export type OrganizationResources = {
  organizationId: number;
  sites: number;
  spaces: number;
  equipment: number;
  gateways: number;
  sensors: number;
  users: number;
  groups: number;
};

export type SiteHvacData = {
  siteId: number;
  address: string;
  title: string;
  timezone: string;
  equipment: EquipmentOrgLevel[];
  sop: Sop[];
  networkGroupIds: number[];
};

export type EquipmentOrgLevel = {
  equipment: Equipment;
  equipmentType: EquipmentType;
};

export interface Organizations {
  organizationsById: OrganizationById;
  currentOrganization?: Organization;
  organizationResources?: OrganizationResources;
  editById: EditById;
}

export type EditById = {
  [orgId: string]: EditOrganization;
};

export type EditOrganization = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const createOrgAddress = (data: any) => {
  return `${data.street ? data.street + ', ' : ''}${
    data.street2 ? data.street2 + ', ' : ''
  }${data.city ? data.city + ', ' : ''}${data.state ? `${data.state} ` : ''}${
    data.postalCode ? data.postalCode + ', ' : ''
  }${data.country ? `${data.country} ` : ''}`;
};

const organizationFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  images: data.images || [],
  street: data.street,
  street2: data.street2,
  city: data.city,
  state: data.string,
  postalCode: data.postalCode,
  country: data.country,
  createdBy: data.createdBy,
  editedBy: data.editedBy,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  address: createOrgAddress(data),
  region: data.region,
  organizationType: data.organizationType || OrganizationType.CUSTOMER, // if null means customer org
});

const organizationResourcesFromApiResponse = (data: any) => ({
  organizationId: data.organizationId,
  sites: data.sites,
  spaces: data.spaces,
  equipment: data.equipment,
  gateways: data.gateways,
  sensors: data.sensors,
  users: data.users,
  groups: data.groups,
});

const initialState: Organizations = {
  organizationsById: {},
  editById: {},
};

export const organizations = (
  state: Organizations = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.GET_ORGANIZATIONS_SUCCESS:
      return R.assoc(
        'organizationsById',
        mapArrayToObject(mapValues(action.data, organizationFromApiResponse)),
        state
      );

    case Actions.GET_CURRENT_ORGANIZATION_SUCCESS:
      const organization = organizationFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['organizationsById', organization.id], organization),
        R.assoc('currentOrganization', organization),
        R.assocPath(['editById', organization.id], {
          isLoading: false,
          isChanged: false,
          formErrorsVisible: false,
          fields: editableFields(organization),
          apiError: {},
        })
      )(state);

    case Actions.GET_ORGANIZATION_RESOURCES_SUCCESS:
      const organizationResource = organizationResourcesFromApiResponse(
        action.data
      );
      return R.assoc('organizationResources', organizationResource, state);

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      const unvalidated = R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true)
      )(state);
      if (action.isEB) return unvalidated;
      else
        return R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('organization', updatedField)
        )(unvalidated);

    case Actions.RESET_EDIT_ORGANIZATION: {
      var fields = editableFields(
        R.path(['organizationsById', String(action.id)], state)
      );

      return R.pipe(
        R.assocPath(['editById', String(action.id), 'isChanged'], false),
        R.assocPath(['editById', String(action.id), 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', String(action.id), 'formErrors'],
          formValidationErrors('organization', fields)
        )
      )(state);
    }

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.PATCH_ORGANIZATION_SUCCESS:
      const patchedOrg = organizationFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['organizationsById', action.id], patchedOrg),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_ORGANIZATION_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.PATCH_ORGANIZATION_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.UPLOAD_PICTURE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['organizationsById', action.id, 'images'],
          state['organizationsById'][action.id].images.concat(action.data.path)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DELETE_PICTURE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['organizationsById', action.id, 'images'],
          state['organizationsById'][action.id].images.filter(path => {
            return path !== action.path;
          })
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    default:
      return state;
  }
};

export default organizations;
