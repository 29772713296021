import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  InputField,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import React, { useEffect } from 'react';
import styles from './DPSettingsCard.module.css';
import { DistributionPanel } from '@energybox/react-ui-library/dist/types';
import { global, hasKeys } from '@energybox/react-ui-library/dist/utils';
import { useGetSites } from '../../../hooks/useSites';
import { useDistributionPanelEditById } from '../../../hooks/distributionPanel/distributionPanel';
import { useDispatch } from 'react-redux';
import {
  deletePicture,
  displayFormErrors,
  patch,
  primeForDelete as primeDPForDelete,
  showDeleteDistributionPanelModal,
  updateField,
  uploadPicture,
} from '../../../actions/distribution_panel';
import { primeForDelete as primeEPForDelete } from '../../../actions/energy_pros';
import SelectSpace from '../../Selects/SelectSpace';
import { DefaultSite, Trash } from '@energybox/react-ui-library/dist/icons';
import { MdMoreHoriz } from 'react-icons/md';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import { Actions as DistributionPanelActions } from '../../../actions/distribution_panel';
import { handleImageUpload } from '../../../utils/imageUpload';

interface DPSettingsCardProps {
  distributionPanel: DistributionPanel;
  activeEnergyProId?: number;
  className?: string;
  isEPro2?: boolean;
}

const DPSettingsCard: React.FC<DPSettingsCardProps> = props => {
  const dispatch = useDispatch();
  const { distributionPanel, activeEnergyProId, className, isEPro2 } = props;

  const [editMode, setEditMode] = React.useState(false);
  const [imagesExpanded, setImagesExpanded] = React.useState(false);
  const [imagesToDelete, setImagesToDelete] = React.useState('');
  const [showImageDeleteModal, setShowImageDeleteModal] = React.useState(false);

  const [formError, setFormError] = React.useState<string | null>(null);
  const { sitesById } = useGetSites();
  const {
    fields,
    formErrors,
    formErrorsVisible,
    apiError,
  } = useDistributionPanelEditById(distributionPanel.id, editMode);

  useEffect(() => {
    if (editMode) {
      setImagesExpanded(true);
    }
  }, [editMode]);

  const onChange = (field: string, value: any) => {
    dispatch(updateField(String(distributionPanel.id), field, value));
  };

  const breakerSlotsError = 'Breaker slots must be between 0 and 35.';
  const onChangeBreakerSlots = (value: string) => {
    if (parseInt(value) < 0) {
      onChange('breakerSlots', 0);
      setFormError(breakerSlotsError);
    } else if (parseInt(value) > 35) {
      onChange('breakerSlots', 35);
      setFormError(breakerSlotsError);
    } else {
      onChange('breakerSlots', parseInt(value));
      setFormError(null);
    }
  };

  const onUpdateDistributionPanel = () => {
    const id = String(distributionPanel.id);
    if (fields && hasKeys(formErrors)) {
      dispatch(displayFormErrors(id));
    } else {
      dispatch(patch(id));
      setEditMode(false);
    }
  };

  const onDeletePanel = () => {
    dispatch(primeDPForDelete(distributionPanel.id));
    activeEnergyProId !== undefined &&
      dispatch(primeEPForDelete(String(activeEnergyProId)));
    dispatch(showDeleteDistributionPanelModal());
  };

  const getPanelTypeText = (dp: DistributionPanel) =>
    dp.type
      ? dp.type[0] +
        dp.type
          .split('_')
          .join(' ')
          .toLowerCase()
          .slice(1)
      : global.NOT_AVAILABLE;

  const displayColumns = [
    {
      width: '10%',
      header: 'Panel Name',
      cellContent: (dp: DistributionPanel) => dp.title,
    },
    {
      width: '10%',
      header: 'Description',
      cellContent: (dp: DistributionPanel) =>
        dp.description || global.NOT_AVAILABLE,
    },
    {
      width: '10%',
      header: 'Site',
      cellContent: (dp: DistributionPanel) => {
        const site = sitesById[dp.space.parentId];
        return site ? site.title : global.NOT_AVAILABLE;
      },
    },
    {
      width: '15%',
      header: 'Location',
      cellContent: (dp: DistributionPanel) => dp.space.title,
    },
    {
      width: '5%',
      header: 'Main Panel',
      cellContent: (dp: DistributionPanel) => (
        <Checkbox
          checked={dp.mdp}
          onChange={() => {}}
          className={styles.disabledCheckbox}
        />
      ),
    },
    {
      width: '20%',
      header: 'Panel Type',
      cellContent: (dp: DistributionPanel) => getPanelTypeText(dp),
    },
    {
      width: '10%',
      header: 'Slots per Column',
      cellContent: (dp: DistributionPanel) => dp.breakerSlots,
    },
    {
      width: '20%',
      header: 'Images',
      cellContent: (dp: DistributionPanel) => (
        <div className={styles.imagesContainer}>{renderImages(dp.images)}</div>
      ),
    },
  ];

  const editModeColumns = [
    {
      width: '10%',
      header: 'Panel Name',
      cellContent: () => (
        <InputField
          id="title"
          type="text"
          name="title"
          value={fields.title}
          onChange={e => onChange('title', e.currentTarget.value)}
          width={'80%'}
          error={formErrorsVisible && !!formErrors.title}
        />
      ),
    },
    {
      width: '10%',
      header: 'Description',
      cellContent: () => (
        <InputField
          id="description"
          type="text"
          name="description"
          value={fields.description}
          onChange={e => onChange('description', e.currentTarget.value)}
        />
      ),
    },
    {
      width: '10%',
      header: 'Site',
      cellContent: (dp: DistributionPanel) => {
        const site = sitesById[dp.space.parentId];
        return (
          <span className={styles.disabled}>
            {site ? site.title : global.NOT_AVAILABLE}
          </span>
        );
      },
    },
    {
      width: '15%',
      header: 'Location',
      cellContent: (dp: DistributionPanel) => (
        <SelectSpace
          onSelect={spaceId => onChange('spaceId', spaceId)}
          value={fields.spaceId}
          siteId={dp.space.parentId}
          error={formErrorsVisible && !!formErrors.spaceId}
          customErrorText={'Please select a location.'}
        />
      ),
    },
    {
      width: '5%',
      header: 'Main Panel',
      cellContent: () => (
        <Checkbox
          checked={fields.mdp}
          onChange={() => onChange('mdp', !fields.mdp)}
        />
      ),
    },
    {
      width: '20%',
      header: 'Panel Type',
      cellContent: (dp: DistributionPanel) => (
        <span className={styles.disabled}>{getPanelTypeText(dp)}</span>
      ),
    },
    {
      width: '10%',
      header: 'Slots per Column',
      cellContent: (dp: DistributionPanel) =>
        isEPro2 ? (
          <span className={styles.disabled}>{dp.breakerSlots}</span>
        ) : (
          <InputField
            id="breakerSlots"
            type="number"
            name="breakerSlots"
            value={fields.breakerSlots}
            onChange={e => onChangeBreakerSlots(e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.breakerSlots}
          />
        ),
    },
    {
      width: '20%',
      header: 'Images',
      cellContent: (dp: DistributionPanel) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <input
            id="in"
            name="in"
            accept="image/png, image/jpeg"
            type="file"
            onChange={e =>
              handleImageUpload(
                e,
                String(distributionPanel.id),
                (id: string, formData: FormData) =>
                  dispatch(uploadPicture(id, formData)),
                (errorMessage: string) => setFormError(errorMessage)
              )
            }
            className={styles.input}
          />
          <Button
            variant="outlined"
            onClick={() => document.getElementById('in')?.click()}
          >
            <DefaultSite size={20} />
            <span style={{ marginLeft: 5 }}>Add Image</span>
          </Button>
          <div className={styles.imagesContainer}>
            {renderImages(dp.images, true)}
          </div>
        </div>
      ),
    },
  ];

  const renderImages = (images: string[], isEdit?: boolean) => {
    if (!images || images.length === 0) {
      return global.NOT_AVAILABLE;
    }
    const imagesToDisplay = imagesExpanded ? images : images.slice(0, 2);
    const imageElements = imagesToDisplay.map((image, index) => (
      <div key={index} className={styles.imageContainer}>
        {isEdit && (
          <Trash
            color="var(--pink-base)"
            size={16}
            onClick={() => {
              setImagesToDelete(image);
              setShowImageDeleteModal(true);
            }}
            style={{ cursor: 'pointer' }}
          />
        )}
        <img
          className={styles.image}
          src={process.env.REACT_APP_CDN_BASE_URL + '/' + image}
          alt="Distribution Panel"
          onClick={() =>
            window.open(
              process.env.REACT_APP_CDN_BASE_URL + '/' + image,
              '_blank'
            )
          }
        />
      </div>
    ));
    if (!imagesExpanded) {
      return (
        <>
          {imageElements}
          {images.length > 2 && (
            <span
              className={styles.moreImages}
              onClick={() => setImagesExpanded(true)}
            >
              +{images.length - 2} more
            </span>
          )}
        </>
      );
    }
    return (
      <>
        {imageElements}
        {images.length > 2 && (
          <span
            className={styles.moreImages}
            onClick={() => setImagesExpanded(false)}
          >
            Show less
          </span>
        )}
      </>
    );
  };

  const renderDeleteImageModal = () => {
    const actions = (
      <>
        <Button variant="text" onClick={() => setShowImageDeleteModal(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            dispatch(
              deletePicture(String(distributionPanel.id), imagesToDelete)
            );
            setShowImageDeleteModal(false);
            setImagesToDelete('');
          }}
        >
          Delete
        </Button>
      </>
    );

    return (
      <Modal onClose={() => setShowImageDeleteModal(false)} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete {imagesToDelete}?
        </p>
      </Modal>
    );
  };

  const columns = editMode ? editModeColumns : displayColumns;

  return (
    <Card className={className}>
      <CardContent className={styles.content}>
        <header className={styles.header}>
          <span>Distribution Panel Settings</span>
          <MenuDropdown icon={<MdMoreHoriz size={32} strokeWidth={0.1} />}>
            <MenuDropdownItem onSelect={() => setEditMode(!editMode)}>
              {editMode ? 'Cancel Edit' : 'Edit'}
            </MenuDropdownItem>
            <MenuDropdownItem onSelect={onDeletePanel} isRed>
              Delete Panel
            </MenuDropdownItem>
          </MenuDropdown>
        </header>

        <div className={styles.scrollContainer}>
          <div className={styles.tableWidthContainer}>
            <div className={styles.headerRow}>
              {columns.map((column, index) => (
                <div
                  key={index}
                  style={{ width: column.width }}
                  className={styles.headerCell}
                >
                  {column.header}
                </div>
              ))}
            </div>

            <div className={styles.bodyRow}>
              {columns.map((column, index) => (
                <div
                  key={index}
                  style={{ width: column.width }}
                  className={
                    column.header === 'Images' ? styles.imageCell : styles.cell
                  }
                >
                  {column.cellContent(distributionPanel)}
                </div>
              ))}
            </div>
          </div>
        </div>

        {formError && <div className={styles.error}>{formError}</div>}
        {renderAPIerror(
          apiError,
          DistributionPanelActions.UPLOAD_PICTURE_ERROR,
          DistributionPanelActions.DELETE_PICTURE_ERROR,
          DistributionPanelActions.DELETE_DISTRIBUTION_PANEL_ERROR,
          DistributionPanelActions.PATCH_DISTRIBUTION_PANEL_ERROR
        )}
      </CardContent>

      {editMode && (
        <CardActions>
          <Button variant="text" onClick={() => setEditMode(false)}>
            Cancel
          </Button>
          <Button onClick={onUpdateDistributionPanel}>Save</Button>
        </CardActions>
      )}

      {showImageDeleteModal && renderDeleteImageModal()}
    </Card>
  );
};

export default DPSettingsCard;
