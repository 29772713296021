import {
  Button,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as ReportActions,
  destroy,
  getReportsBySiteId,
  hideDeleteReportModal,
  showDeleteReportModal,
  showNewReportModal,
} from '../../actions/reports';
import { ApplicationState } from '../../reducers';
import { checkCommonPlural } from '../../util';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import ReportsModal from '../Reports/ReportsModal';

type Props = {
  siteId: string;
};

const ShowSitePageReports: React.FC<Props> = ({ siteId }) => {
  const dispatch = useDispatch();
  const getReports = useCallback(() => dispatch(getReportsBySiteId(siteId)), [
    dispatch,
    siteId,
  ]);
  const openNewReportModal = useCallback(() => dispatch(showNewReportModal()), [
    dispatch,
  ]);
  const openDeleteReportModal = useCallback(
    () => dispatch(showDeleteReportModal()),
    [dispatch]
  );
  const closeDeleteReportModal = useCallback(
    () => dispatch(hideDeleteReportModal()),
    [dispatch]
  );
  const deleteReport = useCallback(
    (reportId: string | number) => dispatch(destroy(reportId, siteId)),
    [dispatch, siteId]
  );

  useEffect(() => {
    getReports();
  }, [getReports]);

  const [predeterminedStepNumber, setPredeterminedStepNumber] = useState<
    number
  >();
  const [selectedReportId, setSelectedReportId] = useState<number>();
  const reports = useSelector(({ reports }: ApplicationState) => {
    return reports.reportsBySiteId[siteId] || {};
  });
  const reportsArray = Object.values(reports);
  const isLoading = useSelector(({ reports }: ApplicationState) => {
    return reports.isReportsBySiteIdLoading;
  });
  const isNewReportModalVisible = useSelector(
    ({ reports }: ApplicationState) => {
      return reports.showNewReportModal;
    }
  );
  const isDeleteReportModalVisible = useSelector(
    ({ reports }: ApplicationState) => {
      return reports.showDeleteReportModal;
    }
  );
  const editReport = useSelector(({ reports }: ApplicationState) => {
    return selectedReportId && reports.editReportById[selectedReportId];
  });

  const renderContent = () => {
    if (isLoading) {
      return null;
    } else if (reportsArray.length > 0) {
      return (
        <div>
          <div style={{ fontWeight: 'bold', paddingBottom: '1rem' }}>
            {checkCommonPlural('Report', reportsArray.length)}
          </div>
          <CardList data={data} />
        </div>
      );
    } else {
      return <div>No reports set up</div>;
    }
  };

  const closeDeletePrompt = () => {
    closeDeleteReportModal();
  };

  const openDeletePrompt = (reportId: number) => {
    setSelectedReportId(reportId);
    openDeleteReportModal();
  };

  const DeleteModal: React.FC = () => {
    useEffect(() => {
      return () => {
        setSelectedReportId(undefined);
      };
    }, []);

    const selectedReport = selectedReportId
      ? reports[selectedReportId]
      : undefined;
    const actions = (
      <>
        <Button variant="text" onClick={closeDeletePrompt}>
          Cancel
        </Button>
        <Button
          disabled={!selectedReport}
          onClick={() => {
            if (selectedReport) {
              deleteReport(selectedReport.id);
            }
          }}
        >
          Delete
        </Button>
      </>
    );

    return (
      <Modal onClose={closeDeletePrompt} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete{' '}
          {selectedReport ? <b>{selectedReport.title}</b> : 'this report'}?
        </p>
        {editReport &&
          renderAPIerror(
            editReport.apiError,
            ReportActions.DELETE_REPORT_ERROR
          )}
      </Modal>
    );
  };

  const onSelectReportDetails = (reportId: number) => {
    setSelectedReportId(reportId);
    setPredeterminedStepNumber(3);
    openNewReportModal();
  };

  const onSelectEditReport = (reportId: number) => {
    setSelectedReportId(reportId);
    setPredeterminedStepNumber(1);
    openNewReportModal();
  };

  const resetEditReportModal = () => {
    setSelectedReportId(undefined);
    setPredeterminedStepNumber(undefined);
  };

  const data: CardListRowData[] = reportsArray.map(r => ({
    key: r.id.toString(),
    content: (
      <>
        <Cell width="11">
          <span>
            <strong>{r.title}</strong>
          </span>
        </Cell>
        <Cell width="1" cellAlign="right">
          <MenuDropdown>
            <MenuDropdownItem onSelect={() => onSelectReportDetails(r.id)}>
              Report Details
            </MenuDropdownItem>

            <MenuDropdownItem onSelect={() => onSelectEditReport(r.id)}>
              Edit Report
            </MenuDropdownItem>

            <MenuDropdownItem isRed onSelect={() => openDeletePrompt(r.id)}>
              Delete Report
            </MenuDropdownItem>
          </MenuDropdown>
        </Cell>
      </>
    ),
  }));

  return (
    <>
      {renderContent()}
      {isDeleteReportModalVisible && <DeleteModal />}
      {isNewReportModalVisible && (
        <ReportsModal
          siteId={siteId}
          predeterminedStepNumber={predeterminedStepNumber}
          selectedReportId={selectedReportId}
          resetEditReportModal={resetEditReportModal}
        />
      )}
    </>
  );
};

export default ShowSitePageReports;
