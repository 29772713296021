import {
  Button,
  Card,
  CardContent,
  Loader,
  Modal,
  RunConfigTestWarning,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlsType,
  ControlTypesToDisplayTextsMapping,
  HvacControl,
  Scheduler,
  TemperatureControl,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  capitalizeFirstLetterOnly,
  classNames,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  hideDeleteControlModal,
  showDeleteControlModal,
  showEditControlModal,
} from '../../../actions/controls';
import {
  Actions as HvacControlActions,
  deleteHvacControl,
  hideEditHvacControlModal,
  showEditHvacControlModal,
} from '../../../actions/hvacControls';
import {
  Actions as SchedulerActions,
  createNewSchedulerInStoreAndOpenControlModal,
  deleteScheduler,
} from '../../../actions/schedulers';
import ControlCardNotes from '../../../components/ControlCardNotes';
import {
  Actions as TemperatureControlActions,
  deleteTemperatureControls,
} from '../../../actions/temperature_controls';
import LastNetworkGroupConfigUpdate from '../../../components/LastNetworkGroupConfigUpdate';
import history from '../../../history';
import useForceRerender from '../../../hooks/useForceRerender';
import useGetNetworkGroupById from '../../../hooks/useGetNetworkGroup';
import { ApplicationState } from '../../../reducers';
import { App } from '../../../reducers/app';
import { Routes } from '../../../routes';
import { ApiError, renderAPIerror } from '../../../utils/apiErrorFeedback';
import EditHvacControlsModal from '../../EditHvacControlsModal';
import HvacControlsContent from '../HvacControlsContent';
import SchedulerContent from '../SchedulerContent';
import TemperatureControlsContent from '../TemperatureControlsContent';
import UpdateControlModeOptions from '../../../components/UpdateControlModeOptions';
import styles from './GenericControlsCard.module.css';
import { useGetSite } from '../../../hooks/useSites';
import { hasDaResults } from '../../../util';
import RecordInfoTooltip from '../../../components/RecordBubble/RecordInfoTooltip';

interface OwnProps {
  scheduler?: Scheduler;
  siteId: number;
  equipmentId: string;
  loading: boolean;
  equipmentTitle: string;
  networkGroupIdViaActuator: number | undefined;
  controlBoardIdViaActuator: number | undefined;
  isEquipmentHvacControlledType: boolean;
}

interface Props extends OwnProps {
  schedulerApiError: ApiError;
  temperatureApiError: ApiError;
  hvacApiError: ApiError;
  temperatureControls?: TemperatureControl;
  showingDeleteControlModal: boolean;
  showDeleteControlModal: (controlType: ControlsType) => void;
  hideDeleteControlModal: () => void;
  createNewSchedulerInStoreAndOpenControlModal: () => void;
  showEditControlModal: (controlType: ControlsType) => void;
  onDeleteScheduler: (schedulerId: number, equipmentId: number) => void;
  onDeleteTemperatureControls: (temperatureControlId: number) => void;
  onDeleteHvacControl: (hvacControlId: number) => void;
  hvacControls?: HvacControl;
  showEditHvacControlModal: () => void;
  hideEditHvacControlModal: () => void;
  showingEditHvacControlModal: boolean;
  app: App;
}

export const GenericControlsCard = ({
  equipmentTitle,
  createNewSchedulerInStoreAndOpenControlModal,
  showEditControlModal,
  showingDeleteControlModal,
  showDeleteControlModal,
  hideDeleteControlModal,
  scheduler,
  temperatureControls,
  onDeleteTemperatureControls,
  onDeleteScheduler,
  onDeleteHvacControl,
  siteId,
  equipmentId,
  loading,
  schedulerApiError,
  temperatureApiError,
  hvacControls,
  hvacApiError,
  showEditHvacControlModal,
  hideEditHvacControlModal,
  showingEditHvacControlModal,
  app,
  networkGroupIdViaActuator,
  controlBoardIdViaActuator,
  isEquipmentHvacControlledType,
}: Props) => {
  //** Hooks **//
  const forceRerender = useForceRerender();

  useEffect(() => {
    setInterval(() => {
      forceRerender();
    }, 60000);
  }, [forceRerender]);

  const [isLocalOverrideActive, setIsLocalOverrideActive] = useState(false);
  const site = useGetSite(siteId);
  const daResultsExist: boolean = hasDaResults(site?.installerTestResults);
  const activeControlWorkingMode = useMemo(() => {
    let controlMode = WorkingMode.NORMAL;

    if (hvacControls?.workingMode !== undefined) {
      controlMode = hvacControls.workingMode;
    } else if (temperatureControls?.workingMode !== undefined) {
      controlMode = temperatureControls.workingMode;
    } else if (scheduler?.workingMode !== undefined) {
      controlMode = scheduler.workingMode;
    }

    return controlMode;
  }, [hvacControls, temperatureControls, scheduler]);

  const networkGroupId = isDefined(hvacControls)
    ? hvacControls.thermostat?.networkGroupId
    : networkGroupIdViaActuator;

  const { networkGroup } = useGetNetworkGroupById(networkGroupId);

  const isControlInCalibrationMode =
    activeControlWorkingMode === WorkingMode.CALIBRATION;

  const [isInit, setIsInit] = useState(true);
  const hvacControlsCardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (
      isInit &&
      hvacControls &&
      history.location.hash.indexOf(Routes.HVAC_EDITOR) > -1
    ) {
      showEditHvacControlModal();
      setIsInit(false);
    } else if (
      isInit &&
      hvacControls &&
      history.location.hash.indexOf(Routes.HVAC_CONTROLS_CARD) > -1
    ) {
      hvacControlsCardRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
      setIsInit(false);
    }
  }, [hvacControls]);
  //******************//

  if (loading) {
    return (
      <Card>
        <CardContent hasExtraPadding={true}>
          <Loader size={16} />
        </CardContent>
      </Card>
    );
  }
  if (!scheduler && !temperatureControls && !hvacControls) {
    return (
      <Card>
        <CardContent hasExtraPadding={true}>
          <div className={styles.cardHeader}>
            <div style={{ fontWeight: 'bold' }}>Controls</div>
            <Button onClick={createNewSchedulerInStoreAndOpenControlModal}>
              Add Controls
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  const deletePrompt = (
    scheduler?: Scheduler,
    temperatureControl?: TemperatureControl,
    hvacControl?: HvacControl
  ) => {
    const controlsType = (() => {
      if (hvacControl) {
        return ControlsType.HVAC;
      } else {
        return temperatureControl !== undefined
          ? temperatureControl.type
          : ControlsType.SCHEDULER;
      }
    })();

    const actions = (
      <>
        <Button variant="text" onClick={hideDeleteControlModal}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (hvacControls && hvacControls.id) {
              onDeleteHvacControl(hvacControls.id);
            } else if (temperatureControl && temperatureControl.id) {
              onDeleteTemperatureControls(temperatureControl.id);
            } else if (scheduler) {
              onDeleteScheduler(scheduler.id, scheduler.equipmentId);
            }
          }}
        >
          Delete
        </Button>
      </>
    );

    return (
      <Modal actions={actions}>
        <p style={{ textAlign: 'center' }}>
          {`Are you sure you want to delete this ${ControlTypesToDisplayTextsMapping[controlsType]} control?`}
        </p>
        {Object.values(schedulerApiError).length > 0 &&
          renderAPIerror(
            schedulerApiError,
            SchedulerActions.DELETE_SCHEDULER_ERROR
          )}
        {Object.values(temperatureApiError).length > 0 &&
          renderAPIerror(
            temperatureApiError,
            TemperatureControlActions.DELETE_TEMPERATURE_CONTROLS_ERROR
          )}
        {Object.values(hvacApiError).length > 0 &&
          renderAPIerror(
            hvacApiError,
            HvacControlActions.DELETE_HVAC_CONTROL_ERROR
          )}
      </Modal>
    );
  };

  return (
    <Card>
      <div>
        {daResultsExist && (
          <RunConfigTestWarning
            configLink={`${Routes.RUN_CONFIGURATION_TEST}/${siteId}`}
          />
        )}
      </div>
      <CardContent
        className={classNames(
          styles.cardContent,
          daResultsExist ? styles.RunConfigTestWarning : ''
        )}
        hasExtraPadding={!daResultsExist ? true : false}
      >
        {!isEquipmentHvacControlledType && hvacControls && (
          <div ref={hvacControlsCardRef}>
            <HvacControlsContent
              siteId={siteId}
              showEditControlModal={showEditHvacControlModal}
              showDeleteControlModal={() =>
                showDeleteControlModal(ControlsType.HVAC)
              }
              hvacControl={hvacControls}
              setIsLocalOverrideActive={setIsLocalOverrideActive}
            />
          </div>
        )}

        {temperatureControls && (
          <TemperatureControlsContent
            siteId={siteId}
            temperatureControl={temperatureControls}
            setIsLocalOverrideActive={setIsLocalOverrideActive}
            showEditControlModal={() =>
              showEditControlModal(
                capitalizeFirstLetterOnly(temperatureControls.type) ===
                  ControlsType.HEATING
                  ? ControlsType.HEATING
                  : ControlsType.COOLING
              )
            }
            showDeleteControlModal={() =>
              showDeleteControlModal(
                capitalizeFirstLetterOnly(temperatureControls.type) ===
                  ControlsType.HEATING
                  ? ControlsType.HEATING
                  : ControlsType.COOLING
              )
            }
          />
        )}

        {scheduler && !temperatureControls && (
          <SchedulerContent
            siteId={siteId}
            scheduler={scheduler}
            setIsLocalOverrideActive={setIsLocalOverrideActive}
            showEditControlModal={() =>
              showEditControlModal(ControlsType.SCHEDULER)
            }
            showDeleteControlModal={() =>
              showDeleteControlModal(ControlsType.SCHEDULER)
            }
          />
        )}

        <div className={styles.bottomOfCard}>
          <div className={styles.recordInfoTooltip}>
            <RecordInfoTooltip
              resourceId={scheduler?.id!}
              ianaTimeZoneCode={site?.timeZone}
            />
          </div>
          {isControlInCalibrationMode ? (
            <ControlCardNotes
              className={styles.controlCardNotes}
              isControlInCalibrationMode={isControlInCalibrationMode}
            />
          ) : (
            <div />
          )}

          <div>
            <UpdateControlModeOptions
              scheduler={scheduler}
              temperatureControl={temperatureControls}
              hvacControl={hvacControls}
              equipmentId={equipmentId}
              activeControlWorkingMode={activeControlWorkingMode}
              networkGroupIdViaActuator={networkGroupIdViaActuator}
              controlBoardIdViaActuator={controlBoardIdViaActuator}
              isLocalOverrideActive={isLocalOverrideActive}
              customDisabled={daResultsExist ? true : false}
            />

            {networkGroup && (
              <div className={styles.lastConfigUpdateContainer}>
                <div>Last Config Update: </div>
                <LastNetworkGroupConfigUpdate
                  className={styles.lastConfigUpdate}
                  networkGroup={networkGroup}
                />
              </div>
            )}
          </div>
        </div>
      </CardContent>
      {showingDeleteControlModal &&
        deletePrompt(scheduler, temperatureControls, hvacControls)}
      {showingEditHvacControlModal && hvacControls && (
        <EditHvacControlsModal
          siteId={siteId}
          hvacControls={hvacControls}
          onClose={hideEditHvacControlModal}
          equipmentTitle={equipmentTitle}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (
  {
    schedulers,
    temperatureControls,
    controls,
    hvacControls,
    app,
  }: ApplicationState,
  { equipmentId }: OwnProps
) => ({
  schedulerApiError: pathOr({}, ['apiError'], schedulers),
  temperatureApiError: pathOr({}, ['apiError'], temperatureControls),
  hvacApiError: pathOr({}, ['apiError'], hvacControls),
  showingDeleteControlModal: controls.showDeleteControlModal,
  temperatureControls:
    temperatureControls.temperatureControlsByEquipmentId[equipmentId],
  hvacControls: hvacControls.hvacControlsByEquipmentId[equipmentId],
  showingEditHvacControlModal: hvacControls.showEditHvacControlModal,
  app,
});

const mapDispatchToProps = (dispatch: any, { equipmentId }: OwnProps) => ({
  createNewSchedulerInStoreAndOpenControlModal: () =>
    dispatch(createNewSchedulerInStoreAndOpenControlModal()),
  showDeleteControlModal: (controlType: ControlsType) =>
    dispatch(showDeleteControlModal(controlType)),
  hideDeleteControlModal: () => dispatch(hideDeleteControlModal()),
  onDeleteTemperatureControls: (temperatureControlId: number) =>
    dispatch(deleteTemperatureControls(temperatureControlId)),
  onDeleteScheduler: (schedulerId: number, equipmentId: number) =>
    dispatch(deleteScheduler(schedulerId, equipmentId)),
  showEditControlModal: (controlType: ControlsType) =>
    dispatch(showEditControlModal(controlType)),
  onDeleteHvacControl: (hvacControlId: number) =>
    dispatch(deleteHvacControl(hvacControlId, equipmentId)),
  showEditHvacControlModal: () => dispatch(showEditHvacControlModal()),
  hideEditHvacControlModal: () => dispatch(hideEditHvacControlModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericControlsCard);
