export enum UserPlatformAccess {
  GLOBAL_ADMIN = 'GLOBAL_ADMIN',
  GLOBAL_VIEWER = 'GLOBAL_VIEWER',
  ORG_ADMIN = 'ORG_ADMIN',
  SITE_ADMIN = 'SITE_ADMIN',
  ORG_MANAGER = 'ORG_MANAGER',
  SITE_MANAGER = 'SITE_MANAGER',
  INSTALLER = 'INSTALLER',
  DEFAULT = 'DEFAULT',
}

export enum InstallerAccess {
  DEFAULT = 'DEFAULT',
  INSTALLER = 'INSTALLER',
  ADMIN = 'ADMIN',
  DENIED = 'DENIED',
}
