import {
  ChevronDown,
  ChevronRight,
} from '@energybox/react-ui-library/dist/icons';
import React from 'react';
import Toggle from '../Toggle';
import styles from './ToggleItem.module.css';

interface Props {
  header: React.ReactNode;
  alwaysShowHeader?: boolean;
  isFirstItem?: boolean;
  locked?: boolean;
  startOpen?: boolean;
  shouldCollapse?: boolean;
}

class ToggleItem extends React.Component<Props, {}> {
  render() {
    const {
      header,
      alwaysShowHeader = false,
      isFirstItem = false,
      locked = false,
      startOpen = false,
      shouldCollapse = false,
    } = this.props;

    return (
      <Toggle
        locked={locked}
        startOpen={startOpen}
        shouldCollapse={shouldCollapse}
      >
        {({ on: expanded, toggle }) => {
          return (
            <div
              className={`${styles.container} ${
                isFirstItem ? styles.containerFirst : ''
              }`}
            >
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  title="expand"
                  className={styles.button}
                  onClick={toggle}
                  aria-expanded={expanded}
                >
                  {expanded ? (
                    <ChevronDown size={16} />
                  ) : (
                    <ChevronRight size={16} />
                  )}
                </button>
              </div>
              <div className={styles.content}>
                {(alwaysShowHeader === true || expanded === false) && (
                  <div className={styles.header}>{header}</div>
                )}
                {expanded === true && (
                  <div className={styles.row}>
                    <div className={styles.toggleContent}>
                      {this.props.children}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

export default ToggleItem;
