import { Sensor, SensorType } from '@energybox/react-ui-library/dist/types';
import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { connect } from 'react-redux';
import { getByResourceId as getSensorsByResourceId } from '../../actions/sensors';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  siteId: number;
  sensorType?: SensorType;
  value: string;
  onSelect: any;
  disabled?: boolean;
  error?: boolean;
  customErrorText?: string;
}

interface Props extends OwnProps {
  getSensorsByResourceId: (
    resourceId: number,
    sensorType?: SensorType[]
  ) => void;
  sensors: Sensor[];
}

interface State {
  search: string;
}

class SelectSensor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { getSensorsByResourceId, siteId, sensorType } = this.props;
    getSensorsByResourceId(siteId, sensorType ? [sensorType] : undefined);
  }

  filterSensorsByQuery = (sensors: Sensor[]) => {
    const { search } = this.state;

    return sensors.filter((sensor: Sensor) => {
      if (search.length <= 2) return true;
      return hasSubstr(sensor.title, search);
    });
  };

  render() {
    const { sensors, value, onSelect, error, customErrorText } = this.props;

    if (!sensors) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;
      const selectedSensor = sensors.find(
        (sensor: Sensor) => sensor.id === Number(value)
      );
      const filteredSensors = this.filterSensorsByQuery(sensors);

      return (
        <Select
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedSensor?.title}
          value={selectedSensor?.id}
          error={error}
          customErrorText={customErrorText}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
            error={filteredSensors.length === 0}
          />
          {filteredSensors.map((sensor: Sensor) => (
            <SelectItem
              key={sensor.id}
              isSelected={sensor.id === selectedSensor?.id}
              onSelect={() => onSelect(sensor.id)}
            >
              {sensor.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = (
  { sensors }: ApplicationState,
  { sensorType, siteId }: OwnProps
) => ({
  sensors: pathOr([], [siteId], sensors.sensorIdsByResourceId)
    .map(id => sensors.sensorsById[id])
    .filter((s: Sensor) => {
      return sensorType
        ? sensorType && s.types && s.types.includes(sensorType)
        : true;
    }),
});

const mapDispatchToProps = {
  getSensorsByResourceId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSensor);
