import {
  CurrentUser,
  ResourceType,
  SopComponent,
  SopCostTypes,
  SopPolicyTypes,
  SopTypeCategory,
  SopTypes,
  SopTypeToShortLabel,
  TimeTable,
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  global,
  createTemperatureString,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteByResourceId } from '../../actions/sites';
import {
  getSopComponentsByResourceId,
  reset,
  showNewOrEditPolicySopModal,
} from '../../actions/sops';
import { getTimeTables } from '../../actions/time_tables';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { useGetSite } from '../../hooks/useSites';
import { ApplicationState } from '../../reducers';
import { formatDecimalValue } from '../../utils/numbers';
import { sopComponentTypeAppliesToEquipment } from '../../utils/sops';
import NewOrEditSopModal from './NewOrEditSopModal';
import OperatingHoursSopValue from './OperatingHoursSopValue';
import styles from './SopInfo.module.css';
import SopUnit from './SopUnit';

type Props = {
  showSopTitle?: boolean;
  resourceId: string | number;
  resourceType?: ResourceType;
  sopTypeCategory: SopTypeCategory;
  isEquipment: boolean | undefined;
  setUpSopPolicyType?: SopPolicyTypes;
  sopData?: SopComponent[];
};

const SopInfo: React.FunctionComponent<Props> = ({
  showSopTitle,
  resourceId,
  resourceType,
  sopTypeCategory,
  isEquipment,
  setUpSopPolicyType,
  sopData,
}) => {
  const user = useCurrentUser();
  const sopComponent = useSelector(({ sops }: ApplicationState) => {
    return sops.sopComponentsByResourceId[resourceId];
  });

  const sopComponentsNeedUpdate = useSelector(({ sops }: ApplicationState) => {
    const needsUpdate = sops.entityIdNeedsUpdate[resourceId];
    if (needsUpdate === undefined) return true;
    return sops.entityIdNeedsUpdate[resourceId];
  });

  const sopComponents = sopData ? sopData : sopComponent;

  const operatingHoursTimeTables = useSelector(
    ({ timeTables }: ApplicationState) =>
      sopComponents
        ? sopComponents
            .filter(item =>
              isEquipment
                ? item.component.type === SopTypes.EQUIPMENT_OPERATING_HOURS
                : item.component.type === SopTypes.SITE_OPERATING_HOURS
            )
            .map(
              item =>
                (item.timetableId &&
                  timeTables.timeTablesById[item.timetableId]) ||
                ({} as TimeTable)
            )
            .filter(item => !!item.rows)
        : []
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      (sopData && sopData?.length > 0 && sopComponentsNeedUpdate) ||
      (sopComponent && sopComponentsNeedUpdate)
    ) {
      dispatch(getSopComponentsByResourceId(resourceId));
    }
  }, [sopData, sopComponentsNeedUpdate, dispatch, sopComponent]);

  const equipmentSiteId = useSelector(({ sites }: ApplicationState) => {
    return sites.resourceIdToSiteId[resourceId];
  });

  React.useEffect(() => {
    const operatingHoursSops =
      (sopComponents &&
        sopComponents.filter(item =>
          isEquipment
            ? item.component.type === SopTypes.EQUIPMENT_OPERATING_HOURS
            : item.component.type === SopTypes.SITE_OPERATING_HOURS
        )) ||
      [];

    if (operatingHoursSops.length > 0) {
      const id = isEquipment ? equipmentSiteId : resourceId;
      id && dispatch(getTimeTables(id));
      isEquipment &&
        !equipmentSiteId &&
        dispatch(getSiteByResourceId(resourceId));
    }
  }, []);

  if (!user) return null;
  return renderSopComponentList(
    resourceId,
    sopTypeCategory,
    sopComponents,
    operatingHoursTimeTables,
    isEquipment,
    user,
    resourceType,
    showSopTitle,
    setUpSopPolicyType
  );
};

function renderSopComponentList(
  resourceId: string | number,
  sopTypeCategory: SopTypeCategory,
  sopComponents: SopComponent[],
  operatingHoursTimeTables: TimeTable[],
  isEquipment: boolean | undefined,
  user: CurrentUser,
  resourceType?: ResourceType,
  showSopTitle?: boolean,
  setUpSopPolicyType?: SopPolicyTypes
) {
  if (!isDefined(sopComponents)) {
    return <>{global.NOT_AVAILABLE}</>;
  }
  let needPolicySopSetup = false;
  if (setUpSopPolicyType) {
    needPolicySopSetup = !sopComponents.some(({ component }) => {
      return component.type === SopTypes[setUpSopPolicyType];
    });
  }

  if (
    setUpSopPolicyType &&
    needPolicySopSetup &&
    resourceType === ResourceType.SITE
  ) {
    return (
      <SetUpSitePolicySopClickable
        siteId={resourceId}
        type={setUpSopPolicyType}
      />
    );
  }

  let sopComponentsList: JSX.Element[] = sopComponents
    .sort((a, b) => {
      // sort by component type names in alphabetical order
      const aType: string = a.component.type;
      const bType: string = b.component.type;
      if (aType < bType) {
        return -1;
      } else if (aType > bType) {
        return 1;
      }
      return 0;
    })
    .map(sopComponent => {
      switch (sopTypeCategory) {
        case 'cost':
          let isCostComponent = Object.values(SopCostTypes).includes(
            SopCostTypes[sopComponent.component.type]
          );

          if (
            isCostComponent &&
            isEquipment &&
            sopComponentTypeAppliesToEquipment(sopComponent.component.type)
          ) {
            return renderSopComponentResourceHint(
              sopComponent,
              operatingHoursTimeTables,
              user,
              showSopTitle
            );
          } else if (
            isCostComponent &&
            !isEquipment &&
            !sopComponentTypeAppliesToEquipment(sopComponent.component.type)
          ) {
            return renderSopComponentResourceHint(
              sopComponent,
              operatingHoursTimeTables,
              user,
              showSopTitle
            );
          }

          break;

        case 'policy':
          if (
            Object.values(SopPolicyTypes).includes(
              SopPolicyTypes[sopComponent.component.type]
            )
          ) {
            return renderSopComponentResourceHint(
              sopComponent,
              operatingHoursTimeTables,
              user,
              showSopTitle
            );
          }

          break;

        default:
          break;
      }

      return null;
    })
    .filter(isDefined);

  return (
    <div>
      {sopComponentsList?.length ? sopComponentsList : global.NOT_AVAILABLE}
    </div>
  );
}

function renderSopComponentResourceHint(
  sopComponent: SopComponent,
  operatingHoursTimeTables: TimeTable[],
  user: CurrentUser,
  showSopTitle?: boolean
) {
  let label: JSX.Element | null = null;
  let sopUnitComponent: any;

  switch (sopComponent.component.type) {
    case SopTypes.DOOR_OPENED_MAX_DURATION:
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          <span>{`Duration: ${sopUnitComponent.duration} `}</span>
          <SopUnit component={sopUnitComponent} />
        </>
      );
      break;

    case SopTypes.ENERGY_TARIFF:
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          <span>{formatDecimalValue(sopUnitComponent.tariff)}</span>
          <SopUnit component={sopUnitComponent} />
        </>
      );
      break;

    case SopTypes.HUMIDITY_RANGE:
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          <span>{`Min: ${sopUnitComponent.min}`}</span>
          <SopUnit component={sopUnitComponent} />
          <span>{` / Max: ${sopUnitComponent.max}`}</span>
          <SopUnit component={sopUnitComponent} />
        </>
      );
      break;

    case SopTypes.LABOR_RATE:
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          <span>{formatDecimalValue(sopUnitComponent.value)}</span>
          <SopUnit component={sopUnitComponent} />
        </>
      );
      break;

    case SopTypes.MAINTENANCE_VISIT_RATE:
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          <span>{formatDecimalValue(sopUnitComponent.value)}</span>
          <SopUnit component={sopUnitComponent} />
        </>
      );
      break;

    case SopTypes.NORMAL_POWER_CONSUMPTION:
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          <span>
            {formatDecimalValue(sopUnitComponent.powerConsumption, 1)}
          </span>
          <SopUnit component={sopUnitComponent} />
        </>
      );
      break;

    case SopTypes.SITE_OPERATING_HOURS:
    case SopTypes.EQUIPMENT_OPERATING_HOURS: {
      // if (sopComponent.component.type === SopTypes.SITE_OPERATING_HOURS) {
      //   sopUnitComponent = sopComponent.component;
      // } else {
      //   sopUnitComponent = sopComponent.component;
      // }
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          {operatingHoursTimeTables.length > 0 ? (
            <div className={styles.opHoursValue}>
              {operatingHoursTimeTables.map(timeTable => (
                <OperatingHoursSopValue timeTable={timeTable} />
              ))}
            </div>
          ) : (
            <div>{global.NOT_AVAILABLE}</div>
          )}
        </>
      );
      break;
    }

    case SopTypes.PRODUCT_VALUE:
      sopUnitComponent = sopComponent.component;
      label = (
        <>
          <span>{formatDecimalValue(sopUnitComponent.value)}</span>
          <SopUnit component={sopUnitComponent} />
        </>
      );
      break;

    case SopTypes.TEMPERATURE_RANGE: {
      sopUnitComponent = sopComponent.component;
      const min = createTemperatureString(sopUnitComponent.min, user, 1);
      const max = createTemperatureString(sopUnitComponent.max, user, 1);
      label = <span>{`Min: ${min} / Max: ${max}`}</span>;
      break;
    }

    default:
      label = <>{global.NOT_AVAILABLE}</>;
      break;
  }

  return (
    <div className={styles.label} key={sopComponent.sopId}>
      {showSopTitle ? (
        <span className={styles.sopTitle}>{sopComponent.title}</span>
      ) : (
        <span>{SopTypeToShortLabel[sopComponent.component.type]}: </span>
      )}
      {label}
    </div>
  );
}

const SetUpSitePolicySopClickable = ({
  siteId,
  type,
}: {
  siteId: number | string;
  type: SopPolicyTypes;
}) => {
  const site = useGetSite(siteId);
  const dispatch = useDispatch();

  if (type === SopPolicyTypes.SITE_OPERATING_HOURS) {
    return (
      <>
        <a
          className={styles.createNewSopText}
          onClick={() => {
            dispatch(showNewOrEditPolicySopModal(type));
          }}
        >
          Add Site Occupied Hours SOP
        </a>
        <NewOrEditSopModal
          resetSop={() => dispatch(reset('new'))}
          sopId={'new'}
          site={site}
          setOrgUnitId={Number(site?.id)}
          sopTypeCategory="policy"
          disableTypeSelect
        />
      </>
    );
  } else {
    return null;
  }
};

export default SopInfo;
