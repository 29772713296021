import { SopTypes } from '@energybox/react-ui-library/dist/types';

export const sopComponentTypeAppliesToEquipment = (sopType: SopTypes) => {
  return ![
    SopTypes.LABOR_RATE,
    SopTypes.MAINTENANCE_VISIT_RATE,
    SopTypes.ENERGY_TARIFF,
    SopTypes.SITE_OPERATING_HOURS,
  ].includes(sopType);
};
