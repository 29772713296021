import {
  DateFilter,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import { EnergyLineChartType } from '@energybox/react-ui-library/dist/components/EnergyLineChart';
import {
  AggregationFilter,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { TIME_IN_MILLISECONDS } from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';
import { useState } from 'react';
import EnergyChartTypeToggle from '../../../components/EnergyChartTypeToggle';
import ChartResolutionFilter from '../../../components/Filters/ChartResolutionFilter';
import { useAppLocale } from '../../../hooks/useAppDetails';
import useChartDetails from '../../../hooks/useChartDetails';
import styles from './EnergyChartHOC.module.css';

interface FilterHOCProps {
  site: Site;
  header?: React.ReactNode;
}

export const withFilters = <T extends FilterHOCProps>(Component) => (
  props: Omit<T, 'chartDetails' | 'chartType'>
) => {
  const { site, header } = props;
  const locale = useAppLocale();

  const [chartType, setChartType] = useState<EnergyLineChartType>(
    EnergyLineChartType.POWER
  );

  const chartDetails = useChartDetails(locale, {
    includeAggregationFilter: true,
    timezone: site.timeZone,
    useCurrentTime: true,
    useZoomUtils: true,
  });

  const {
    aggregationFilter,
    zoomRange,
    setAggregationFilter,
    setDateFilter,
    dateFilterTitle,
    isZoomedIn,
    isMonthly,
    isCustomRange,
    zoomOut,
  } = chartDetails;
  const fromDateMs = zoomRange.from;
  const toDateMs = zoomRange.to;
  const dateFilterFromDateJS = DateTime.fromMillis(fromDateMs).toJSDate();
  const dateFilterToDateJS = DateTime.fromMillis(toDateMs).toJSDate();
  const timeDelta = toDateMs - fromDateMs;
  const disableOneMinuteResolution = timeDelta > TIME_IN_MILLISECONDS.ONE_DAY;

  return (
    <div>
      <div className={styles.headerRow}>
        <div className={styles.header}>{header}</div>

        <div className={styles.filters}>
          <EnergyChartTypeToggle
            chartType={chartType}
            setChartType={setChartType}
          />

          {aggregationFilter && (
            <ChartResolutionFilter
              className={styles.chartResolutionFilterContainer}
              disableOneMinuteResolution={disableOneMinuteResolution}
              title={aggregationFilter.title}
              onChange={(aggFilter: AggregationFilter) =>
                setAggregationFilter(aggFilter)
              }
            />
          )}

          <DateFilter
            value={{
              title: dateFilterTitle,
              toDate: dateFilterToDateJS,
              fromDate: dateFilterFromDateJS,
              isZoomingIn: isZoomedIn,
              isMonthly,
              isCustomRange,
            }}
            alignItemsRight
            dateFormat="datetime"
            customPickerVariant="datetime"
            setFilter={setDateFilter}
            ianaTimeZoneCode={site.timeZone}
            displayDatesAsTitle
            locale={locale}
          />

          <div className={styles.zoomResetContainer}>
            <ResetButton
              customText="Reset Zoom"
              displayInfoTooltip
              onClick={zoomOut}
              disabled={!isZoomedIn}
            />
          </div>
        </div>
      </div>

      <Component {...props} chartDetails={chartDetails} chartType={chartType} />
    </div>
  );
};
