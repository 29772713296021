import { GenericErrors } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/main_breakers';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface EditableFields {
  title: string;
  description: string;
  siteTotal: boolean;
  rating: number;
}

export const editableFieldKeys = [
  'title',
  'description',
  'siteTotal',
  'rating',
];

const editableFields = (mainBreaker: object) =>
  R.pick(editableFieldKeys, mainBreaker);

export type EditMainBreaker = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

export interface MainBreakers {
  editById: EditById;
}

export const initialState = {
  editById: {},
};

export type EditById = {
  [id: string]: EditMainBreaker;
};

const mainBreakers = (state: MainBreakers = initialState, action: any) => {
  switch (action.type) {
    case Actions.SET_EDIT_MAIN_BREAKER:
      return R.pipe(
        R.assocPath(['editById', action.mainBreaker.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(action.mainBreaker),
          apiError: {},
        })
      )(state);

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('mainBreaker', updatedField)
        )
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.PATCH_MAIN_BREAKER_LOADING:
      return R.pipe(
        R.assocPath(['editById', action.mainBreakerId, 'isLoading'], true)
      )(state);

    case Actions.PATCH_MAIN_BREAKER_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', action.mainBreakerId, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', action.mainBreakerId, 'isLoading'], false)
      )(state);

    case Actions.PATCH_MAIN_BREAKER_SUCCESS:
      return R.pipe(
        R.assocPath(['editById', action.mainBreakerId, 'apiError'], {}),
        R.assocPath(['editById', action.mainBreakerId, 'isLoading'], false)
      )(state);

    default:
      return state;
  }
};

export default mainBreakers;
