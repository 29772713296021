import React, { useState, useEffect } from 'react';

export const useViewportWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // Return the width so it can be used in components
  return width;

  /*
Reason for choosing  useEffect vs useLayoutEffect

Performance: useLayoutEffect, if misused, can lead to performance issues because it blocks visual updates. 
If we don't have a critical need to measure the viewport before the first render (like avoiding flicker, layout jank, etc.), useEffect would be a better choice.

Code Consistency and Readability: useEffect is more commonly used and understood by most React developers. 
By using useEffect, we keep the codebase more standard and easier to understand and maintain by other developers in the team.

Server Side Rendering (SSR) Compatibility: useLayoutEffect does not run on the server, but useEffect does. 

The goal is to provide a good user experience, but also maintain high performance and readability for our codebase. 
If we later found that there was a specific need to perform the measurement synchronously (e.g., to prevent noticeable flicker on load), 
we could then reconsider using useLayoutEffect. But it would not be the first choice without a compelling reason.

*/
};

export const useIsMobileViewPort = (breakPoint = 900) => {
  const width = useViewportWidth();

  return width < breakPoint;
};
