import {
  ComponentTypes,
  FanMode,
  GenericErrors,
  MeasurementSystem,
  ResourceType,
  Sop,
  SopComponent,
  SopTypes,
  ThermostatWorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  HvacSop,
  HvacSopTimeSlot,
} from '@energybox/react-ui-library/dist/types/Sop';
import { TimetableOverrideType } from '@energybox/react-ui-library/dist/types/TimeTable';
import { mapValues } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/sops';
import {
  OrgSopAppliedCounts,
  OrgSopAppliedEquipmentCounts,
} from '../types/sop';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';
import { SiteHvacData } from './organizations';
import { getMapFromArrayOneToOne } from '@energybox/react-ui-library/dist/utils/util';

export interface EditableFields {
  id?: number;
  title: string;
  description: string;
  components: ComponentTypes[];
  resourceIds: number[];
  equipmentTypeIds: number[];
  timetableId?: number | null;
  organizationUnitId: number;
}

const editableFields = (sop: object) =>
  R.pick(
    [
      'title',
      'description',
      'components',
      'resourceIds',
      'equipmentTypeIds',
      'timetableId',
      'organizationUnitId',
    ],
    sop
  );

export type EditSop = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrorsVisible: boolean;
  formErrors: GenericErrors;
  apiError: ApiError;
};

const defaultCurrencyCode = 'USD';

const defaultProductValueSop = {
  type: SopTypes.PRODUCT_VALUE,
  value: '',
  currencyCode: defaultCurrencyCode,
};

const defaultMaintenanceVisitRateSop = {
  type: SopTypes.MAINTENANCE_VISIT_RATE,
  value: '',
  currencyCode: defaultCurrencyCode,
};

const defaultLaborRateSop = {
  type: SopTypes.LABOR_RATE,
  value: '',
  currencyCode: defaultCurrencyCode,
};

const defaultEnergyTariffSop = {
  type: SopTypes.ENERGY_TARIFF,
  tariff: '',
  currencyCode: defaultCurrencyCode,
};

const defaultDoorOpenedMaxDurationFields = {
  type: SopTypes.DOOR_OPENED_MAX_DURATION,
  duration: '',
};

const defaultHumidityRangeSop = {
  type: SopTypes.HUMIDITY_RANGE,
  min: 0,
  max: 0,
};

const defaultNormalPowerConsumptionSop = {
  type: SopTypes.NORMAL_POWER_CONSUMPTION,
  powerConsumption: '',
};

const defaultSiteOperatingHoursSop = {
  type: SopTypes.SITE_OPERATING_HOURS,
  timetableId: 0,
};

const defaultEquipmentOperatingHoursSop = {
  type: SopTypes.EQUIPMENT_OPERATING_HOURS,
  timetableId: 0,
};

const defaultTemperatureRangeSop = {
  type: SopTypes.TEMPERATURE_RANGE,
  min: 0,
  max: 0,
};

const defaultTaskSop = {
  type: SopTypes.TASK,
  taskSettings: {
    taskType: 'DAILY_CHECK',
    time: '00:00',
    weekdays: [],
  },
};

const sopTypeToDefaultFields = {
  [SopTypes.LABOR_RATE]: defaultLaborRateSop,
  [SopTypes.ENERGY_TARIFF]: defaultEnergyTariffSop,
  [SopTypes.DOOR_OPENED_MAX_DURATION]: defaultDoorOpenedMaxDurationFields,
  [SopTypes.HUMIDITY_RANGE]: defaultHumidityRangeSop,
  [SopTypes.NORMAL_POWER_CONSUMPTION]: defaultNormalPowerConsumptionSop,
  [SopTypes.SITE_OPERATING_HOURS]: defaultSiteOperatingHoursSop,
  [SopTypes.EQUIPMENT_OPERATING_HOURS]: defaultEquipmentOperatingHoursSop,
  [SopTypes.TEMPERATURE_RANGE]: defaultTemperatureRangeSop,
  [SopTypes.MAINTENANCE_VISIT_RATE]: defaultMaintenanceVisitRateSop,
  [SopTypes.PRODUCT_VALUE]: defaultProductValueSop,
  [SopTypes.TASK]: defaultTaskSop,
};

export const newCostSopFields = {
  title: '',
  components: [{ ...defaultEnergyTariffSop }],
  organizationUnitId: -1,
  description: '',
  equipmentTypeIds: [],
  resourceIds: [],
};

export const newPolicySopFields = {
  title: '',
  components: [{ ...defaultDoorOpenedMaxDurationFields }],
  organizationUnitId: -1,
  description: '',
  equipmentTypeIds: [],
  resourceIds: [],
};

export const newSopFields = {
  title: '',
  components: [{ ...defaultDoorOpenedMaxDurationFields }],
  organizationUnitId: -1,
  description: '',
  equipmentTypeIds: [],
  resourceIds: [],
};

export const newHvacSopFields = (
  organizationUnitId: number,
  typeId?: number
) => {
  return {
    title: '',
    organizationUnitId,
    description: '',
    policyType: 'HVAC SOP',
    attachedTo: 'Equipment Types',
    equipmentTypeIds: typeId ? [typeId] : [],
    resourceIds: [],
    components: [
      {
        type: SopTypes.HVAC,
        hvacSettings: {
          enableLocalAdjustment: false,
          overrideTimer: null,
          setPointLimitDelta: null,
          thermostatDisplayUnits: MeasurementSystem.IMPERIAL,
          hvacSchedules: [
            // All schedules need an unoccupied and at least 1 occupied time slot
            // Unoccupied should always be the last time slot
            {
              ...newOccupiedHvacSopTimeSlot(),
            },
            {
              thermostatWorkingMode: ThermostatWorkingMode.AUTO,
              hvacScheduleType: 'UNOCCUPIED',
              fanMode: FanMode.AUTO,
              minTemp: undefined,
              maxTemp: undefined,
            },
          ],
        },
      } as HvacSop,
    ],
  };
};

export const newOccupiedHvacSopTimeSlot = (): HvacSopTimeSlot => ({
  thermostatWorkingMode: ThermostatWorkingMode.AUTO,
  hvacScheduleType: 'OCCUPIED',
  fanMode: FanMode.AUTO,
  minTemp: undefined,
  maxTemp: undefined,
  timetable: {
    title: '',
    specials: [],
    rows: [
      {
        begin: {
          delta: '',
          time: '',
          type: 'CLOCK',
        },
        end: {
          delta: '',
          time: '',
          type: 'CLOCK',
        },
        weekdays: [],
        overrideType: TimetableOverrideType.AUTO,
      },
    ],
  },
});

const sanitizeCustomHvacSopComponents = (components: HvacSop[]) => {
  const sanitizedComponents = components.map(c => ({
    ...c,
    id: undefined,
    hvacSettings: {
      ...c.hvacSettings,
      id: undefined,
      hvacSchedules: [...c.hvacSettings.hvacSchedules].map(s => ({
        ...s,
        id: undefined,
        timetable:
          s.hvacScheduleType === 'UNOCCUPIED'
            ? undefined
            : {
                title: '',
                special: [],
                rows: [...s.timetable.rows],
              },
      })),
    },
  }));

  return sanitizedComponents;
};

const newSop = {
  isLoading: false,
  isChanged: false,
  isCustomizing: false,
  fields: newSopFields,
  formErrors: formValidationErrors('sop', newSopFields),
  formErrorsVisible: false,
  apiError: {},
};

const newCostSop = {
  isLoading: false,
  isChanged: false,
  isCustomizing: false,
  fields: newCostSopFields,
  formErrors: formValidationErrors('sop', newCostSopFields),
  formErrorsVisible: false,
  apiError: {},
};

const newPolicySop = {
  isLoading: false,
  isChanged: false,
  isCustomizing: false,
  fields: newPolicySopFields,
  formErrors: formValidationErrors('sop', newPolicySopFields),
  formErrorsVisible: false,
  apiError: {},
};

const newHvacSop = (organizationUnitId: number, typeId?: number) => ({
  isLoading: false,
  isChanged: false,
  isCustomizing: false,
  fields: newHvacSopFields(organizationUnitId, typeId),
  formErrors: formValidationErrors('sop', newHvacSopFields(organizationUnitId)),
  formErrorsVisible: false,
  apiError: {},
});

export type OrgHvacData = {
  [siteId: number]: SiteHvacData;
};
const organizationHvacDataFromApiResponse = (data: any) => ({
  siteId: data.siteId,
  address: data.address,
  title: data.title,
  equipment: data.equipment || [],
  sop: data.sop || [],
  networkGroupIds: data.networkGroupIds || [],
});

export type SopsById = {
  [id: string]: Sop;
};

export type SopComponentsByResourceId = {
  [id: string]: SopComponent[];
};

export type OrgSopAppliedCountsBySopId = {
  [sopId: number]: OrgSopAppliedCounts;
};

export type OrgSopAppliedCountsByEquipmentType = {
  [equipmentTypeId: number]: OrgSopAppliedEquipmentCounts[];
};

export interface Sops {
  activeSopTab: string;
  isLoading: boolean;
  isOrgSopCountsLoading: boolean;
  isOrgSopCountsByEquipmentTypeLoading: boolean;
  sopsById: SopsById;
  editById: EditById;
  showNewOrEditPolicyModal: boolean;
  showNewOrEditCostModal: boolean;
  showNewOrEditHvacSopModal: boolean;
  idBeingEdited: number | 'new';
  organizationUnitIdToSopIds: OrganizationUnitIdToSopIds;
  sopComponentsByResourceId: SopComponentsByResourceId;
  orgSopAppliedCountsBySopId: OrgSopAppliedCountsBySopId;
  orgSopAppliedCountsByEquipmentType: OrgSopAppliedCountsByEquipmentType;
  // Check for stale SOP data by resource/entity ID
  // when fetched by entities/resourceIds
  // only available for CREATE_SOP_SUCCESS actions as of now
  // TODO: extend this to other actions as needed
  entityIdNeedsUpdate: {
    [id: string]: boolean;
  };
  orgHvacData: OrgHvacData;
  showRecommendation: boolean;
  isOrgHvacLoading: boolean;
  isSopDataLoading: boolean;
  sopComponentData: SopComponentsByResourceId;
}

export type EditById = {
  [id: string]: EditSop;
};

export type OrganizationUnitIdToSopIds = {
  [id: string]: number[];
};

const sopsFromApiResponse = (data: any) => {
  return {
    id: data.id,
    title: data.title,
    components: processHvacSop(data.components),
    description: data.description,
    equipmentTypeIds: data.equipmentTypeIds || [],
    equipmentTypes: data.equipmentTypes || [],
    organizationUnit: data.organization,
    organizationUnitId: data.organizationUnitId,
    resourceIds: data.resourceIds || [],
    resources: data.resources || [],
    timetable: data.timetable || null,
    timetableId: data.timetableId || null,
    resourceType: ResourceType[(data._entity as string).toUpperCase()],
  };
};

/**
 * hvac sop components need to always be sorted so that
 * unoccupied schedule is the last element of array
 */
export const processHvacSop = (
  components: ComponentTypes[] | ComponentTypes
) => {
  const sortHvacSchedule = c => {
    const isHvacSop = c._entity === 'HvacSopComponent';
    if (isHvacSop) {
      return {
        ...c,
        hvacSettings: c.hvacSettings
          ? {
              ...c.hvacSettings,
              hvacSchedules: c.hvacSettings?.hvacSchedules
                ? c.hvacSettings.hvacSchedules.sort(
                    (hvacScheduleA, hvacScheduleB) => {
                      if (
                        hvacScheduleA.hvacScheduleType ===
                        hvacScheduleB.hvacScheduleType
                      ) {
                        //sorting so that UNOCCUPIED schedule is always sorted last
                        //for multiple OCCUPIED schedules, the hvacSchedule.default === true needs to always be the first one
                        return hvacScheduleA.default === true ? -1 : 1;
                      }

                      return hvacScheduleA.hvacScheduleType.localeCompare(
                        hvacScheduleB.hvacScheduleType
                      );
                    }
                  )
                : undefined,
            }
          : undefined,
      };
    }
    return { ...c };
  };

  if (Array.isArray(components)) {
    return components.map(sortHvacSchedule);
  }

  return sortHvacSchedule(components);
};

export const initialState = {
  isLoading: false,
  isOrgSopCountsLoading: false,
  isOrgSopCountsByEquipmentTypeLoading: false,
  sopsById: {},
  showNewOrEditPolicyModal: false,
  showNewOrEditCostModal: false,
  showNewOrEditHvacSopModal: false,
  editById: {},
  idBeingEdited: 'new' as 'new',
  organizationUnitIdToSopIds: {},
  activeSopTab: 'policy',
  sopComponentsByResourceId: {},
  orgSopAppliedCountsBySopId: {},
  orgSopAppliedCountsByEquipmentType: {},
  entityIdNeedsUpdate: {},
  orgHvacData: {},
  showRecommendation: true,
  isOrgHvacLoading: false,
  isSopDataLoading: false,
  sopComponentData: {},
};

const sops = (state: Sops = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SOPS_LOADING:
      return R.pipe(R.assoc('isLoading', true))(state);

    case Actions.GET_SOPS_SUCCESS:
      return R.pipe(
        R.assoc(
          'sopsById',
          R.mergeRight(
            R.view(R.lensProp('sopsById'), state),
            getMapFromArrayOneToOne(mapValues(action.data, sopsFromApiResponse))
          )
        ),
        R.assoc('isLoading', false)
      )(state);

    case Actions.CHANGE_ACTIVE_SOP_TAB: {
      return R.pipe(R.assoc('activeSopTab', action.activeSopTab))(state);
    }

    case Actions.GET_ORGANIZATION_HVAC_LOADING: {
      return R.assoc('isOrgHvacLoading', true, state);
    }
    case Actions.GET_ORGANIZATION_HVAC_SUCCESS: {
      return R.pipe(
        R.assoc('orgHvacData', action.data),
        R.assoc('isOrgHvacLoading', false)
      )(state);
    }

    case Actions.CHANGE_SOP_TYPE: {
      let newType = sopTypeToDefaultFields[action.value];

      const fieldToUpdate = R.view(
        R.lensPath(['editById', action.id, 'fields']),
        state
      );
      const updatedField = {
        ...fieldToUpdate,
        components: [{ ...newType }],
      };

      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('sop', updatedField)
        )
      )(state);
    }

    case Actions.GET_SOP_SUCCESS:
      let sop = sopsFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['sopsById', action.data.id], sop),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          formErrors: {},
          fields: editableFields(sop),
          apiError: {},
        })
      )(state);

    case Actions.GET_SOPS_BY_ORG_UNIT_ID_LOADING: {
      return R.pipe(R.assoc('isLoading', true))(state);
    }

    case Actions.GET_SOPS_BY_ORG_UNIT_ID_ERROR: {
      return R.pipe(R.assoc('isLoading', false))(state);
    }

    case Actions.GET_SOPS_BY_ORG_UNIT_ID_SUCCESS: {
      return R.pipe(
        R.assoc('isLoading', false),
        R.assoc(
          'sopsById',
          R.mergeRight(
            R.view(R.lensProp('sopsById'), state),
            getMapFromArrayOneToOne(mapValues(action.data, sopsFromApiResponse))
          )
        ),
        R.assocPath(
          ['organizationUnitIdToSopIds', action.id],
          action.data.map(({ id }) => id)
        )
      )(state);
    }

    case Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING: {
      return R.pipe(R.assoc('isLoading', true))(state);
    }

    case Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR: {
      return R.pipe(R.assoc('isLoading', false))(state);
    }

    case Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS: {
      if (!!action.data) {
        return R.pipe(
          R.assocPath(
            ['sopComponentsByResourceId', action.id],
            action.data.map(rawData => ({
              ...rawData,
              component: processHvacSop(rawData.component),
            }))
          ),
          R.assocPath(['entityIdNeedsUpdate', action.id], false)
        )(state);
      }
      return state;
    }

    case Actions.GET_SOP_COMPONENTS_SUCCESS: {
      if (!!action.data) {
        const newState = { ...state }; // Create a new state object

        for (const key in action.data) {
          if (Array.isArray(action.data[key]) && action.data[key].length > 0) {
            newState.sopComponentData = {
              ...newState.sopComponentData,
              [key]: action.data[key].map(rawData => ({
                ...rawData,
                component: processHvacSop(rawData.component),
              })),
            };
          } else {
            // If the array is empty, keep the existing value in the newState object
            newState.sopComponentData = {
              ...newState.sopComponentData,
              [key]: state.sopComponentData[key] || [],
            };
          }
          newState.entityIdNeedsUpdate = {
            ...newState.entityIdNeedsUpdate,
            [key]: false, // Assigning a boolean value to a key within an object
          };
        }
        return newState;
      }
      return state;
    }

    case Actions.GET_ORG_SOP_APPLIED_COUNTS_LOADING: {
      return R.pipe(R.assoc('isOrgSopCountsLoading', true))(state);
    }

    case Actions.GET_ORG_SOP_APPLIED_COUNTS_ERROR: {
      return R.pipe(R.assoc('isOrgSopCountsLoading', false))(state);
    }

    case Actions.GET_ORG_SOP_APPLIED_COUNTS_SUCCESS:
      return R.pipe(
        R.assoc('isOrgSopCountsLoading', false),
        R.assoc(
          'orgSopAppliedCountsBySopId',
          R.mergeRight(
            R.view(R.lensProp('orgSopAppliedCountsBySopId'), state),
            getMapFromArrayOneToOne(action.data, 'sopId')
          )
        )
      )(state);

    case Actions.GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_LOADING: {
      return R.pipe(R.assoc('isOrgSopCountsByEquipmentTypeLoading', true))(
        state
      );
    }

    case Actions.GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_ERROR: {
      return R.pipe(R.assoc('isOrgSopCountsByEquipmentTypeLoading', false))(
        state
      );
    }

    case Actions.GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_SUCCESS:
      return R.pipe(
        R.assoc('isOrgSopCountsByEquipmentTypeLoading', false),
        R.assoc(
          'orgSopAppliedCountsByEquipmentType',
          R.mergeRight(
            R.view(R.lensProp('orgSopAppliedCountsByEquipmentType'), state),
            action.data
          )
        )
      )(state);

    case Actions.TOGGLE_NEW_OR_EDIT_COST_SOP_MODAL: {
      const { selectedSopType } = action;
      const editObject = { ...newCostSop };
      if (selectedSopType) {
        editObject.fields = {
          ...editObject.fields,
          components: [
            {
              ...sopTypeToDefaultFields[selectedSopType],
            },
          ],
        };
      }
      return R.pipe(
        R.assocPath(['editById', 'new'], editObject),
        R.assoc('showNewOrEditCostModal', action.value)
      )(state);
    }

    case Actions.TOGGLE_NEW_OR_EDIT_POLICY_SOP_MODAL: {
      const { selectedSopType } = action;
      const editObject = { ...newPolicySop };
      if (selectedSopType) {
        editObject.fields = {
          ...editObject.fields,
          components: [
            {
              ...sopTypeToDefaultFields[selectedSopType],
            },
          ],
        };
      }
      return R.pipe(
        R.assocPath(['editById', 'new'], editObject),
        R.assoc('showNewOrEditPolicyModal', action.value)
      )(state);
    }

    case Actions.TOGGLE_NEW_OR_EDIT_HVAC_SOP_MODAL:
      const { id, siteId, value, equipmentTypeId } = action;
      const isOpening = value === true;

      let sopToEdit: EditSop | undefined;
      const newSopObj = newHvacSop(siteId, equipmentTypeId);

      if (isOpening) {
        if (id !== 'new') {
          sopToEdit = { ...newSopObj, fields: state.sopsById[id] };
          if (sopToEdit.fields === undefined) {
            const sopObject = Object.values(state.orgHvacData).find(
              item => item.siteId === siteId
            );
            const siteSop = sopObject?.sop.find(item => {
              return id === item.id;
            });
            sopToEdit = {
              ...newSopObj,
              fields: siteSop ? editableFields(siteSop) : undefined,
            };
          }
        } else {
          sopToEdit = { ...newSopObj };
        }
      }

      return R.pipe(
        isOpening
          ? R.assocPath(['editById', id], sopToEdit)
          : R.dissocPath(['editById', id]),
        R.assoc('showNewOrEditHvacSopModal', action.value),
        R.assoc('idBeingEdited', isOpening ? id : 'new')
      )(state);

    case Actions.CUSTOMIZE_ORG_SOP: {
      const {
        title,
        description,
        components,
        equipmentTypeIds,
        timetableId,
        organizationUnitId,
      } = action.value;

      let modalToOpen = '';
      let newCustomSop: any = { ...newSop };
      let newCustomSopFields: any = { ...newSopFields };
      let sanitizedComponents = [...components];

      switch (action.sopType) {
        case 'cost':
          modalToOpen = 'showNewOrEditCostModal';
          newCustomSop = newCostSop;
          newCustomSopFields = newCostSopFields;
          break;
        case 'policy':
          modalToOpen = 'showNewOrEditPolicyModal';
          newCustomSop = newPolicySop;
          newCustomSopFields = newPolicySopFields;
          break;
        case 'hvac':
          modalToOpen = 'showNewOrEditHvacSopModal';
          newCustomSop = newHvacSop(organizationUnitId);
          newCustomSopFields = newHvacSopFields(organizationUnitId);
          sanitizedComponents = sanitizeCustomHvacSopComponents(components);
          break;
        default:
          break;
      }

      newCustomSopFields = {
        ...newCustomSopFields,
        title: title ? title + ' (Site)' : newCustomSopFields.title,
        description: description || newCustomSopFields.description,
        components: sanitizedComponents || newCustomSopFields.components,
        equipmentTypeIds:
          equipmentTypeIds || newCustomSopFields.equipmentTypeIds,
        timetableId,
      };

      return R.pipe(
        R.assocPath(['editById', 'new'], {
          ...newCustomSop,
          isChanged: true,
          isCustomizing: true,
          formErrors: formValidationErrors('sop', newCustomSopFields),
          fields: {
            ...newCustomSopFields,
          },
        }),
        R.assoc(modalToOpen, true)
      )(state);
    }

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD: {
      const path = action.field
        .split('.')
        .map(stringOrNumber =>
          !isNaN(stringOrNumber) ? Number(stringOrNumber) : stringOrNumber
        );

      let updatedField = R.assocPath(
        path,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('sop', updatedField)
        )
      )(state);
    }

    case Actions.CREATE_SOP_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_SOP_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sopsById', action.data.id],
          sopsFromApiResponse(action.data)
        ),
        R.assocPath(
          ['organizationUnitIdToSopIds', action.data.organizationUnitId],
          R.uniq(
            (
              state.organizationUnitIdToSopIds[
                action.data.organizationUnitId
              ] || []
            ).concat(action.data.id)
          )
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assocPath(['entityIdNeedsUpdate', action.updateId], true)
      )(state);
    case Actions.CREATE_COST_SOP_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sopsById', action.data.id],
          sopsFromApiResponse(action.data)
        ),
        R.assocPath(
          ['organizationUnitIdToSopIds', action.data.organizationUnitId],
          R.uniq(
            (
              state.organizationUnitIdToSopIds[
                action.data.organizationUnitId
              ] || []
            ).concat(action.data.id)
          )
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewOrEditCostModal', false),
        R.assocPath(['entityIdNeedsUpdate', action.updateId], true)
      )(state);

    case Actions.CREATE_POLICY_SOP_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sopsById', action.data.id],
          sopsFromApiResponse(action.data)
        ),
        R.assocPath(
          ['organizationUnitIdToSopIds', action.data.organizationUnitId],
          R.uniq(
            (
              state.organizationUnitIdToSopIds[
                action.data.organizationUnitId
              ] || []
            ).concat(action.data.id)
          )
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewOrEditPolicyModal', false),
        R.assocPath(['entityIdNeedsUpdate', action.updateId], true)
      )(state);

    case Actions.UPDATE_SOP_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sopsById', action.data.id],
          sopsFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.UPDATE_COST_SOP_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sopsById', action.data.id],
          sopsFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assoc('showNewOrEditCostModal', false)
      )(state);

    case Actions.UPDATE_POLICY_SOP_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sopsById', action.data.id],
          sopsFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assoc('showNewOrEditPolicyModal', false)
      )(state);

    case Actions.CREATE_SOP_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('isLoading', false)
      )(state);

    case Actions.UPDATE_SOP_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assoc('isLoading', false)
      )(state);

    case Actions.RESET_EDIT_SOP:
      const fields = editableFields(R.path(['sopsById', action.id], state));

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('sop', fields)
        )
      )(state);

    case Actions.DELETE_SOP_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_SOP_SUCCESS:
      if (action.data) {
        return R.pipe(
          R.dissocPath(['sopsById', action.id]),
          R.dissocPath(['editById', action.id])
        )(state);
      }
      return state;

    case Actions.DELETE_SOP_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.CREATE_HVAC_SOP_SUCCESS:
    case Actions.UPDATE_HVAC_SOP_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['sopsById', action.data.id],
          sopsFromApiResponse(action.data)
        ),
        R.assoc('showNewOrEditHvacSopModal', false),
        R.assoc('isLoading', false)
      )(state);

    case Actions.UPDATE_SOP_LOADING:
    case Actions.CREATE_SOP_LOADING: {
      return R.pipe(R.assoc('isLoading', true))(state);
    }

    case Actions.TOGGLE_SOP_RECOMMENDATION:
      return R.pipe(R.assoc('showRecommendation', action.value))(state);

    default:
      return state;
  }
};

export default sops;
