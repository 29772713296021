import {
  CircuitBreaker,
  EnergyDevicePorts,
  GenericErrors,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  InputField,
  Label,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions as EnergyDeviceActions } from '../../actions/energy_devices';
import { Actions as EnergyProActions } from '../../actions/energy_pros';
import { SelectedEnergyDevice } from '../../containers/DistributionPanels/ShowDistributionPanelPage/ShowDistributionPanelPage';
import SelectCtPolarity from '../../containers/Selects/SelectCtPolarity';
import SelectEnergyProCt from '../../containers/Selects/SelectEnergyProCt';
import SelectEnergySpiderCt from '../../containers/Selects/SelectEnergySpiderCt';
import SelectEnergySpiderFlexCt from '../../containers/Selects/SelectEnergySpiderFlexCt';
import SelectPhase from '../../containers/Selects/SelectPhase';
import { EditableSensorFields } from '../../reducers/energy_devices';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import ModalFormContent from '../ModalFormContent';
import styles from './EditEnergyDeviceSensorForm.module.css';
import { useDistributionPanelType } from '../../hooks/distributionPanel/distributionPanel';

import { global } from '@energybox/react-ui-library/dist/utils';
import { showNewCircuitBreakerModal } from '../../actions/circuit_breakers';
import { ApplicationState } from '../../reducers';
import { resetNewlyCreatedBreakerId } from '../../actions/distribution_panel';

const PANEL_MAIN_BREAKER_TEXT = 'Panel Main Breaker';

interface Props {
  fields: EditableSensorFields;
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
  deviceType: string;
  circuitBreakers?: CircuitBreaker[];
  isNew?: boolean;
  occupiedPorts: number[];
  predeterminedData?: SelectedEnergyDevice;
  isMainBreaker?: boolean;
  panelId?: string | number;
}

const EditEnergyDeviceSensorForm = ({
  fields,
  onChange,
  formErrors,
  formErrorsVisible,
  apiError,
  deviceType,
  circuitBreakers = [],
  occupiedPorts,
  isNew,
  predeterminedData,
  isMainBreaker,
  panelId,
}: Props) => {
  const {
    breakerId,
    breakerPole,
    ct,
    energyDevicePort,
    reversePolarity,
    phase,
  } = fields;

  const dispatch = useDispatch();
  const newlySelectedBreakerId = useSelector(
    ({ distributionPanels }: ApplicationState) => {
      return distributionPanels.newlyInstalledBreakerId;
    }
  );

  useEffect(() => {
    if (!newlySelectedBreakerId) return;
    onChange('breakerId', newlySelectedBreakerId);
    dispatch(resetNewlyCreatedBreakerId());
  }, [newlySelectedBreakerId]);

  React.useEffect(() => {
    if (predeterminedData) {
      Object.keys(predeterminedData)
        .filter(key => key !== 'id')
        .forEach((key: string) => onChange(key, predeterminedData[key]));
    }
  }, [predeterminedData, onChange]);

  const panelType = useDistributionPanelType(panelId);

  const breakerPoles = {
    SINGLE_POLE: [1],
    DOUBLE_POLE: [1, 2],
    THREE_POLE: [1, 2, 3],
  };
  const mainBreakerPoles = [1, 2, 3];

  const circuitBreakersWithMainBreaker = [
    {
      id: -1,
      title: PANEL_MAIN_BREAKER_TEXT,
      description: PANEL_MAIN_BREAKER_TEXT,
      type: 'THREE_POLE',
    },
    ...circuitBreakers,
  ];

  const selectedCircuitBreaker = circuitBreakersWithMainBreaker.find(
    breaker => breaker.id === breakerId
  );

  const renderEnergyDevicePortSelect = (occupiedPorts: number[]) => {
    let arr: number[] = [];
    for (let i = 1; i <= EnergyDevicePorts[deviceType]; i++) {
      if (occupiedPorts.includes(i)) {
        continue;
      }

      arr.push(i);
    }

    return (
      <Select
        variant={'select'}
        title={energyDevicePort !== -1 ? energyDevicePort : undefined}
        error={formErrorsVisible && !!formErrors.energyDevicePort}
      >
        {arr.map(num => (
          <SelectItem
            key={num}
            isSelected={num === energyDevicePort}
            onSelect={() => onChange('energyDevicePort', num)}
          >
            {num}
          </SelectItem>
        ))}
      </Select>
    );
  };

  const renderCiruitBreakerTitle = () => {
    if (isMainBreaker) {
      return PANEL_MAIN_BREAKER_TEXT;
    } else {
      return selectedCircuitBreaker?.title;
    }
  };
  const circuitBreakerTitle = renderCiruitBreakerTitle();

  const renderCiruitBreakerDescription = () => {
    if (isMainBreaker) {
      return PANEL_MAIN_BREAKER_TEXT;
    } else {
      return selectedCircuitBreaker?.description;
    }
  };

  const circuitBreakerDescription = renderCiruitBreakerDescription();

  const determineBreakerPoles = (selectedCircuitBreaker?: any) => {
    if (!isMainBreaker && selectedCircuitBreaker) {
      return breakerPoles[selectedCircuitBreaker.type];
    } else if (isMainBreaker || selectedCircuitBreaker?.id === -1) {
      return mainBreakerPoles;
    }
    return [];
  };

  return (
    <div>
      <ModalFormContent className={styles.formRoot}>
        {isNew && (
          <>
            <div>
              <Label required htmlFor="energyDevicePort">
                Port in
              </Label>
            </div>
            <div>{renderEnergyDevicePortSelect(occupiedPorts)}</div>
          </>
        )}

        <div>
          <Label required htmlFor="breakerId">
            Breaker Name
          </Label>
        </div>
        <div>
          <Select
            disabled={!isNew || isMainBreaker}
            variant="select"
            value={circuitBreakerTitle}
            title={circuitBreakerTitle}
            error={formErrorsVisible && !!formErrors.breakerId}
          >
            <SelectItem
              isSelected={false}
              onSelect={() => dispatch(showNewCircuitBreakerModal())}
            >
              Add New Circuit Breaker
            </SelectItem>
            {circuitBreakersWithMainBreaker.map((breaker: any) => (
              <SelectItem
                key={breaker.id}
                isSelected={breaker.id === selectedCircuitBreaker?.id}
                onSelect={() => onChange('breakerId', breaker.id)}
              >
                {breaker.title}
              </SelectItem>
            ))}
          </Select>
        </div>

        <div>
          <Label required htmlFor="breakerId">
            Breaker Description
          </Label>
        </div>
        <div>
          <InputField type="text" value={circuitBreakerDescription} disabled />
        </div>

        <div>
          <Label required htmlFor="breakerPole">
            Breaker Pole
          </Label>
        </div>
        <div>
          <Select
            disabled={!isNew || (!isMainBreaker && !selectedCircuitBreaker)}
            variant={'select'}
            title={breakerPole !== -1 ? breakerPole : undefined}
            error={formErrorsVisible && !!formErrors.breakerPole}
          >
            {determineBreakerPoles(selectedCircuitBreaker).map(
              (num: number) => (
                <SelectItem
                  key={num}
                  isSelected={num === breakerPole}
                  onSelect={() => onChange('breakerPole', num)}
                >
                  {num}
                </SelectItem>
              )
            )}
          </Select>
        </div>

        <div>
          <Label required htmlFor="reversePolarity">
            CT Polarity
          </Label>
        </div>
        <div>
          <SelectCtPolarity
            value={reversePolarity}
            onSelect={v => onChange('reversePolarity', v)}
          />
        </div>

        <div>
          <Label required htmlFor="phase">
            Phase(s)
          </Label>
        </div>
        <div>
          <SelectPhase
            isEnergyPro={
              deviceType === ResourceType.ENERGYPRO ||
              deviceType === 'Energy Pro'
            }
            value={phase !== -1 ? phase : undefined}
            onSelect={value => onChange('phase', value)}
            error={formErrorsVisible && !!formErrors.phase}
            customErrorText={'Please select a phase'}
            panelType={panelType}
          />
        </div>

        {(deviceType === ResourceType.ENERGYPRO ||
          deviceType === ResourceType.ENERGYSPIDER ||
          deviceType === ResourceType.ENERGYSPIDERFLEX) && (
          <>
            <div>
              <Label required htmlFor="ct">
                CT Type
              </Label>
            </div>
            <div>
              {deviceType === ResourceType.ENERGYSPIDER && (
                <SelectEnergySpiderCt
                  error={formErrorsVisible && !!formErrors.ct}
                  value={ct}
                  onSelect={selectedCT => onChange('ct', selectedCT)}
                />
              )}
              {deviceType === ResourceType.ENERGYSPIDERFLEX && (
                <SelectEnergySpiderFlexCt
                  error={formErrorsVisible && !!formErrors.ct}
                  value={ct}
                  onSelect={selectedCT => onChange('ct', selectedCT)}
                />
              )}
              {deviceType === ResourceType.ENERGYPRO && (
                <SelectEnergyProCt
                  error={formErrorsVisible && !!formErrors.ct}
                  value={ct}
                  onSelect={selectedCT => onChange('ct', selectedCT)}
                  customErrorText="Please select a CT Type"
                />
              )}
            </div>
          </>
        )}
      </ModalFormContent>

      {renderAPIerror(
        apiError,
        EnergyDeviceActions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_ERROR,
        EnergyDeviceActions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR,
        EnergyProActions.ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR,
        EnergyDeviceActions.EDIT_ENERGY_DEVICE_SENSOR_PORT_ERROR
      )}
    </div>
  );
};

type SelectCtProps = {
  noBottomLine?: boolean;
  value: string;
  deviceType: string;
  onSelect: (selectedCt) => void;
};

export const SelectEnergyDeviceCt = ({
  value,
  deviceType,
  onSelect,
  noBottomLine,
}: SelectCtProps) => {
  switch (deviceType) {
    case 'EnergySpider':
    case ResourceType.ENERGYSPIDER:
      return (
        <SelectEnergySpiderCt
          noBottomLine={noBottomLine}
          error={false}
          value={value}
          onSelect={onSelect}
        />
      );

    case 'EnergySpiderFlex':
    case ResourceType.ENERGYSPIDERFLEX:
      return (
        <SelectEnergySpiderFlexCt
          noBottomLine={noBottomLine}
          error={false}
          value={value}
          onSelect={onSelect}
        />
      );

    case 'EnergyPro I':
    case ResourceType.ENERGYPRO:
      return (
        <SelectEnergyProCt
          noBottomLine={noBottomLine}
          error={false}
          value={value}
          onSelect={onSelect}
        />
      );

    default:
      return <>{global.NOT_AVAILABLE}</>;
  }
};

export default EditEnergyDeviceSensorForm;
