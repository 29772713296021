import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import {
  getThermostatDetailById,
  getThermostatDisplayName,
} from '../../actions/thermostats';
import globalVariables from '@energybox/react-ui-library/dist/utils/global';

type Props = {
  thermostatId: string | number;
};

// getThermostatDisplayName is the current new resource
// for getting the thermostat display name.
// if we cant get the tsat display name from that resource
// then we use the deprecated resource.
const ThermostatDisplayNameLabel: React.FC<Props> = ({ thermostatId }) => {
  const dispatch = useDispatch();

  const thermostatDisplayName = useSelector(
    ({ thermostats }: ApplicationState) =>
      thermostats.thermostatDisplayNames[thermostatId]
  );

  const thermostatDetail = useSelector(
    ({ thermostats }: ApplicationState) =>
      thermostats.thermostatDetailById[thermostatId]
  );

  useEffect(() => {
    if (!thermostatDisplayName) {
      dispatch(getThermostatDisplayName(thermostatId));
    }
  }, [dispatch, thermostatDisplayName, thermostatId]);

  useEffect(() => {
    if (!thermostatDisplayName && !thermostatDetail) {
      dispatch(getThermostatDetailById(thermostatId));
    }
  }, [dispatch, thermostatDisplayName, thermostatDetail, thermostatId]);

  const displayName =
    thermostatDisplayName ||
    thermostatDetail?.configuration?.equipmentName ||
    globalVariables.NOT_AVAILABLE;

  return <div>{displayName}</div>;
};

export default ThermostatDisplayNameLabel;
