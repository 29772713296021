import {
  FormText,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import {
  DeviceType,
  FirmwareGatewayModel,
  GenericErrors,
  LightSensorPort,
} from '@energybox/react-ui-library/dist/types';
import { classNames, global } from '@energybox/react-ui-library/dist/utils';
import styles from './EditControlBoardForm.module.css';

import React, { useEffect } from 'react';
import SelectLightSensorPort from '../../containers/Selects/SelectLightSensorPort';
import SelectNetworkGroup from '../../containers/Selects/SelectNetworkGroup';
import SelectSite from '../../containers/Selects/SelectSite';
import SelectSpace from '../../containers/Selects/SelectSpace';
import { useLightLiveReadings } from '../../hooks/streamApi/deviceReadings';
import { PropertyToLabel } from '../../types/global';
import ModalFormContent from '../ModalFormContent';
import SelectSiteController from '../../containers/Selects/SelectSiteController';
import { SiteController } from '@energybox/react-ui-library/dist/types/Device';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getFirmwares } from '../../actions/app';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import { convertMinutesToHours, getSiteControllerModel } from '../../util';
import {
  subscribeToControlBoardIloState,
  unsubscribeFromControlBoardIloState,
} from '../../actions/streamApi';
import { getBalenaStatusBySiteId } from '../../actions/balena';

interface Props {
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  title: string;
  description: string;
  uuid: string;
  spaceId: number;
  networkGroupId: number;
  siteId?: number;
  isChanged?: boolean;
  formErrorsVisible: boolean;
  lockSiteId?: number;
  lightSensorPort: LightSensorPort;
  isNew?: boolean;
  firmwareVersion?: string;
  controlBoardId?: number;
  testResultsExist?: boolean;
  model?: DeviceType | SiteController;
  updateIsLatestFirmware?: (isLatestFirmware: boolean) => void;
  hardwareVersion?: string;
  isSiteControllerCard?: boolean;
}

const EditControlBoardForm = ({
  onChange,
  isNew,
  firmwareVersion,
  title,
  controlBoardId,
  description,
  uuid,
  lightSensorPort,
  lockSiteId,
  siteId = -1,
  networkGroupId,
  spaceId,
  isChanged,
  formErrors,
  formErrorsVisible,
  testResultsExist,
  model,
  hardwareVersion,
  updateIsLatestFirmware,
}: Props) => {
  const [siteIdInState, setSiteId] = React.useState(lockSiteId || siteId);
  const [formattedUuid, setFormattedUuid] = React.useState<string>(uuid);
  const SiteControllerModel = getSiteControllerModel(hardwareVersion!);

  const dispatch = useDispatch();
  const firmwares = useSelector(
    (state: ApplicationState) => state.app.firmwares
  );

  const controlBoardIloState = useSelector(
    ({ controlBoards }: ApplicationState) => {
      return controlBoards.cbIloState;
    }
  );

  const isLatestFirmware = React.useMemo(() => {
    if (!firmwareVersion || !firmwares) {
      return true;
    }

    const releaseFirmwares = firmwares.filter(
      firmware => firmware.type === 'RELEASE'
    );

    const latestFirmware = releaseFirmwares.reduce(
      (prev, current) => (prev.version > current.version ? prev : current),
      { version: '' }
    );

    return latestFirmware.version === firmwareVersion;
  }, [firmwareVersion, firmwares]);

  React.useEffect(() => {
    dispatch(
      getFirmwares({ gatewayModels: [FirmwareGatewayModel.ENERGYBOX_CB] })
    );
    dispatch(getBalenaStatusBySiteId(siteId));
  }, []);

  React.useEffect(() => {
    // Case where form is rest to initial state
    if (isChanged !== undefined && !isChanged && siteId) {
      setSiteId(siteId);
    }
  }, [siteId, isChanged]);

  React.useEffect(() => {
    setFormattedUuid(uuid);
  }, [uuid]);

  React.useEffect(() => {
    if (updateIsLatestFirmware) {
      updateIsLatestFirmware(isLatestFirmware);
    }
  }, [isLatestFirmware]);

  useEffect(() => {
    if (SiteControllerModel === 'SiteController-ILO') {
      dispatch(subscribeToControlBoardIloState(uuid));
    }

    return () => {
      dispatch(unsubscribeFromControlBoardIloState(uuid));
    };
  }, [uuid, dispatch]);

  const lightLiveReading = useLightLiveReadings(controlBoardId);

  const luxRange =
    lightSensorPort === LightSensorPort.PORT_1
      ? lightLiveReading?.lux1Range
      : lightLiveReading?.lux2Range;

  const formattedLuxRange = luxRange?.toLocaleString('en-US');

  const isLuxValueFaulty =
    luxRange === 1000 || luxRange === 10000 ? true : false;

  const displayLuxValueOrNot =
    lightSensorPort !== LightSensorPort.NONE && isLuxValueFaulty;

  const revertTime = controlBoardIloState.intelligentOverrideRevertTime;

  const onChangeSite = value => {
    setSiteId(value);
    onChange('spaceId', -1);
    onChange('networkGroupId', -1);
  };

  const onChangeUuid = value => {
    const formattedUuid =
      value
        .replace(/[^0-9A-Fa-f]/g, '')
        ?.slice(0, 12)
        ?.toUpperCase()
        ?.match(/.{1,2}/g)
        ?.join(':') || '';
    setFormattedUuid(formattedUuid);
    onChange('uuid', formattedUuid);
  };

  return (
    <>
      <div className={isNew ? '' : styles.cardContent}>
        <div className={styles.columnData}>
          <div>
            <Label required>Device Name</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="title"
              autoComplete="title"
              value={title}
              onChange={e => onChange('title', e.currentTarget.value)}
              error={formErrorsVisible && !!formErrors.title}
              customErrorText={formErrors.title && formErrors.title[0]}
            />
          </div>

          <div>
            <Label>{PropertyToLabel.description}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="description"
              value={description}
              autoComplete="description"
              onChange={e => onChange('description', e.currentTarget.value)}
            />
          </div>
          <div>
            <Label required>{PropertyToLabel.uuid}</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="uuid"
              value={formattedUuid}
              autoComplete="uuid"
              onChange={e => onChangeUuid(e.currentTarget.value)}
              error={formErrorsVisible && !!formErrors.uuid}
              customErrorText={formErrors.uuid && formErrors.uuid[0]}
            />
          </div>

          {!isNew && (
            <>
              <div>
                <Label> {PropertyToLabel.SiteControllerModel} </Label>
              </div>

              <div>
                <Label>
                  {hardwareVersion ? SiteControllerModel : global.NOT_AVAILABLE}
                </Label>
              </div>

              <div>
                <Label htmlFor="type">Firmware</Label>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  fontSize: '0.75rem',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    padding: '0.5rem 0rem',
                  }}
                >
                  v{firmwareVersion || global.NOT_AVAILABLE}
                </div>
                {!isLatestFirmware && (
                  <div className={styles.updateFirmwareWarning}>
                    <WarningIcon size={16} />
                    <span>
                      <b>Update Firmware</b> Not the latest release version
                    </span>
                  </div>
                )}
              </div>

              <div>
                <Label required>{PropertyToLabel.hardwareVersion}</Label>
              </div>

              <div>
                <Label>
                  {hardwareVersion ? hardwareVersion : global.NOT_AVAILABLE}
                </Label>
              </div>
            </>
          )}
        </div>
        <div className={classNames(styles.columnData, styles.modelContent)}>
          <div>
            <Label required>{PropertyToLabel.siteId}</Label>
          </div>
          <div>
            <SelectSite
              disabled={!!lockSiteId}
              onSelect={onChangeSite}
              value={siteIdInState}
              testResultsExist={testResultsExist}
            />
          </div>

          <div>
            <Label required>{PropertyToLabel.spaceId}</Label>
          </div>
          <div>
            <SelectSpace
              onSelect={value => onChange('spaceId', value)}
              value={spaceId}
              siteId={siteIdInState}
              error={formErrorsVisible && !!formErrors.spaceId}
              customErrorText={formErrors.spaceId && formErrors.spaceId[0]}
              testResultsExist={testResultsExist}
            />
          </div>

          <div>
            <Label required>{PropertyToLabel.networkGroupId}</Label>
          </div>
          <div>
            <SelectNetworkGroup
              siteId={siteIdInState}
              onSelect={value => {
                onChange('networkGroupId', value);
              }}
              value={networkGroupId}
              error={formErrorsVisible && !!formErrors.networkGroupId}
              customErrorText={
                formErrors.networkGroupId && formErrors.networkGroupId[0]
              }
              testResultsExist={testResultsExist}
            />
          </div>
          <div>
            <Label>{PropertyToLabel.relayCount}</Label>
          </div>
          <div>
            <SelectSiteController
              onSelect={value => {
                onChange('model', value);
              }}
              siteId={siteIdInState}
              value={model}
              testResultsExist={testResultsExist}
              isNew={isNew!}
            />
          </div>
          {model !== SiteController.ENERGYBOX_CB_EU_6 && (
            <>
              <div>
                <Label required>{PropertyToLabel.lightSensorPort}</Label>
              </div>
              <div className={styles.luxValueContainer}>
                <SelectLightSensorPort
                  onSelect={value => {
                    onChange('lightSensorPort', value);
                  }}
                  value={lightSensorPort}
                  error={formErrorsVisible && !!formErrors.lightSensorPort}
                  customErrorText={
                    formErrors.lightSensorPort && formErrors.lightSensorPort[0]
                  }
                  testResultsExist={testResultsExist}
                />
                <div className={styles.luxValue}>
                  {displayLuxValueOrNot ? (
                    <div> ( {formattedLuxRange}lux Sensor ) </div>
                  ) : (
                    <div> ( {global.NOT_AVAILABLE} ) </div>
                  )}
                </div>
              </div>
            </>
          )}
          {!isNew && SiteControllerModel === 'SiteController-ILO' && (
            <>
              <div>
                <Label>{PropertyToLabel.revertTime}</Label>
              </div>

              <div>
                <Label>
                  {revertTime
                    ? convertMinutesToHours(revertTime)
                    : global.NOT_AVAILABLE}
                </Label>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalFormContent>
        <FormText>* Mandatory fields</FormText>
      </ModalFormContent>
    </>
  );
};

export default EditControlBoardForm;
