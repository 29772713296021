import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import {
  ApiError,
  ControlBoard,
  DeviceType,
  FirmwareGatewayModel,
  Gateway,
  GatewayVendorLabel,
  NetworkGroup,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as NetworkGroupActions,
  destroy,
  displayFormErrors,
  getNetworkGroup,
  patch,
  reset,
  updateField,
} from '../../../actions/network_groups';
import EditNetworkGroupForm from '../../../components/EditNetworkGroupForm';
import ShowDetailPageHeader from '../../../components/ShowDetailPageHeader';
import { ApplicationState } from '../../../reducers';
import { EditNetworkGroup } from '../../../reducers/network_groups';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import ResourceFullPath from '../../ResourceFullPath/ResourceFullPath';
import styles from './ShowNetworkGroupPage.module.css';
import {
  getGatewaysByNetworkGroupId,
  newGateway,
  create as createHub,
  patch as patchHub,
  updateField as updateHub,
} from '../../../actions/gateways';
import EditHubForm from '../../../components/EditHubForm';
import UpdateBalenaFirmwareModal from '../../../components/UpdateBalenaFirmwareModal';
import { BalenaStatusBySerialNumber } from '../../../reducers/balena';
import {
  fetchRemoteAccessToken,
  getBalenaStatusBySerialNumber,
} from '../../../actions/balena';
import {
  IconCheckboxOutlined,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import { fetchApi } from '../../../utils/apiUtils';
import { clearSuperHubBuffer, rebootSuperHub } from '../../../actions/superHub';
import { SuperHubStatusBySN } from '../../../reducers/superHub';
import { UserPlatformAccess } from '../../../types/user';
import { accessDeniedError } from '../../../utils/ApiError/accessDeniedError';
import RemoteAccessButton from '../../../components/RemoteAccessButton/RemoteAccessButton';
import { EditById } from '../../../reducers/gateways';
import SuperHubSensors from '../SuperHubSensors';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  patch: () => void;
  onChange: (field: string, value: number) => void;
  onDelete: () => void;
  onCancel: () => void;
  createHub: (siteId: number) => void;
  updateHub: (id: string, field: string, value: any) => void;
  patchHub: (id: string) => void;
  newGateway: (id: string) => void;
  networkGroup?: NetworkGroup;
  editNetworkGroup?: EditNetworkGroup;
  gateways?: (Gateway | ControlBoard)[];
  loadGateways: () => void;
  balenaStatusBySerialNumber?: BalenaStatusBySerialNumber;
  firmwareReleases?: any;
  loadBalenaStatusBySerialNumber: (serialNumber: string) => void;
  displayFormErrors: () => void;
  superHubStatusBySN?: SuperHubStatusBySN;
  updateFirmware?: {
    release: string;
    releaseId: number;
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  clearSuperHubBuffer?: (serialNumber: string) => void;
  rebootSuperHub?: (serialNumber: string) => void;
  networkGroupApiError: ApiError;
  editGateway: EditById;
  lookupMACAddressError: boolean;
}

interface State {
  showDeletePrompt: boolean;
  showConfirmPrompt?:
    | {
        message: string;
        onConfirm: () => void;
        onCancel: () => void;
      }
    | undefined;
  showSuccessToastMessage: string | undefined;
  showFailureToastMessage: string | undefined;
  showUpdateFirmwareModal: boolean;
}

class ShowNetworkGroupPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeletePrompt: false,
      showSuccessToastMessage: undefined,
      showFailureToastMessage: undefined,
      showUpdateFirmwareModal: false,
    };
  }

  interval: any;

  componentDidMount() {
    this.props.load();
    const { serialNumber } = this.props.networkGroup?.edge || {};
    if (serialNumber) {
      this.props.loadBalenaStatusBySerialNumber(serialNumber);
      this.interval = setInterval(() => {
        this.props.loadBalenaStatusBySerialNumber(serialNumber);
      }, 5000);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      networkGroup,
      loadBalenaStatusBySerialNumber,
      superHubStatusBySN,
    } = this.props;
    if (
      networkGroup &&
      prevProps.networkGroup?.id !== networkGroup.id &&
      networkGroup.edge?.serialNumber
    ) {
      loadBalenaStatusBySerialNumber(networkGroup.edge.serialNumber);
      this.interval = setInterval(() => {
        if (networkGroup.edge?.serialNumber) {
          loadBalenaStatusBySerialNumber(networkGroup.edge.serialNumber);
        }
      }, 5000);
    }

    const prevBalenaStatus =
      prevProps.balenaStatusBySerialNumber?.[
        networkGroup?.edge?.serialNumber || ''
      ];
    const balenaStatus = this.props.balenaStatusBySerialNumber?.[
      networkGroup?.edge?.serialNumber || ''
    ];

    // check if balena status has changed
    const previousRunningRelease =
      prevBalenaStatus?.runningRelease?.[0]?.raw_version;
    const previousTargetRelease =
      prevBalenaStatus?.targetRelease?.[0]?.raw_version;

    const runningRelease = balenaStatus?.runningRelease?.[0]?.raw_version;
    const targetRelease = balenaStatus?.targetRelease?.[0]?.raw_version;

    if (runningRelease && previousRunningRelease !== runningRelease) {
      if (
        (this.props.updateFirmware?.success &&
          runningRelease === this.props.updateFirmware.release) ||
        (runningRelease === targetRelease &&
          previousTargetRelease === targetRelease)
      ) {
        this.setState({
          showSuccessToastMessage: `Successfully updated build version to ${
            runningRelease.split('-')[0]
          }`,
        });
        setTimeout(() => {
          this.setState({ showSuccessToastMessage: undefined });
        }, 7000);
      }
    }

    // show error message if firmware update failed
    if (
      prevProps.updateFirmware !== this.props.updateFirmware &&
      this.props.updateFirmware?.error
    ) {
      this.setState({ showSuccessToastMessage: undefined });
      this.setState({
        showFailureToastMessage: 'Version Update did not complete',
      });
      setTimeout(() => {
        this.setState({ showFailureToastMessage: undefined });
      }, 7000);
    }

    // reload balena status after firmware update
    if (
      prevProps.updateFirmware !== this.props.updateFirmware &&
      this.props.updateFirmware?.success &&
      networkGroup?.edge?.serialNumber
    ) {
      loadBalenaStatusBySerialNumber(networkGroup.edge?.serialNumber);
    }

    // check if superhub status has changed
    const prevSHClearBuffer =
      (prevProps.superHubStatusBySN &&
        prevProps.networkGroup?.edge?.serialNumber &&
        prevProps.superHubStatusBySN[prevProps.networkGroup.edge.serialNumber]
          ?.clearBuffer) ||
      undefined;
    const shClearBuffer =
      (superHubStatusBySN &&
        networkGroup?.edge?.serialNumber &&
        superHubStatusBySN[networkGroup.edge.serialNumber]?.clearBuffer) ||
      undefined;

    if (prevSHClearBuffer !== shClearBuffer) {
      if (shClearBuffer?.success) {
        this.setState({
          showSuccessToastMessage: 'Successfully cleared buffer',
        });
        setTimeout(() => {
          this.setState({ showSuccessToastMessage: undefined });
        }, 7000);
      }

      if (shClearBuffer?.error) {
        this.setState({
          showFailureToastMessage: 'Clear Buffer did not complete',
        });
        setTimeout(() => {
          this.setState({ showFailureToastMessage: undefined });
        }, 7000);
      }
    }

    const prevSHReboot =
      (prevProps.superHubStatusBySN &&
        prevProps.networkGroup?.edge?.serialNumber &&
        prevProps.superHubStatusBySN[prevProps.networkGroup.edge.serialNumber]
          ?.reboot) ||
      undefined;
    const shReboot =
      (superHubStatusBySN &&
        networkGroup?.edge?.serialNumber &&
        superHubStatusBySN[networkGroup.edge.serialNumber]?.reboot) ||
      undefined;

    if (prevSHReboot !== shReboot) {
      if (shReboot?.success) {
        this.setState({
          showSuccessToastMessage: 'Successfully rebooted SuperHub',
        });
        setTimeout(() => {
          this.setState({ showSuccessToastMessage: undefined });
        }, 7000);
      }

      if (shReboot?.error) {
        this.setState({ showFailureToastMessage: 'Reboot did not complete' });
        setTimeout(() => {
          this.setState({ showFailureToastMessage: undefined });
        }, 7000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  deletePrompt() {
    const { networkGroup, onDelete, editNetworkGroup } = this.props;

    const actions = (
      <>
        <Button
          variant="text"
          onClick={this.handleCloseDeletePrompt.bind(this)}
        >
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal
        onClose={this.handleCloseDeletePrompt.bind(this)}
        actions={actions}
      >
        <div className={styles.confirmModalContent}>
          <WarningIcon size={18} />
          <p className={styles.textAlignCenter}>
            Are you sure you want to delete{' '}
            {networkGroup ? <b>{networkGroup.title}</b> : 'this network group'}?
          </p>
        </div>
        {editNetworkGroup &&
          renderAPIerror(
            editNetworkGroup.apiError,
            NetworkGroupActions.DELETE_NETWORK_GROUP_ERROR
          )}
      </Modal>
    );
  }

  handleOpenDeletePrompt = () => {
    this.setState({ showDeletePrompt: true });
  };

  handleCloseDeletePrompt = () => {
    this.setState({ showDeletePrompt: false });
  };

  confirmPrompt = () => {
    const { showConfirmPrompt } = this.state;
    if (!showConfirmPrompt) return null;

    const actions = (
      <>
        <Button variant="text" onClick={showConfirmPrompt.onCancel}>
          Cancel
        </Button>
        <Button onClick={showConfirmPrompt.onConfirm}>Confirm</Button>
      </>
    );

    return (
      <Modal onClose={showConfirmPrompt.onCancel} actions={actions}>
        <div className={styles.confirmModalContent}>
          <WarningIcon size={18} />
          {showConfirmPrompt.message}
        </div>
      </Modal>
    );
  };

  handleClearBuffer = () => {
    this.setState({
      showConfirmPrompt: {
        message: 'Are you sure you want to clear the buffer?',
        onConfirm: () => {
          const { networkGroup, clearSuperHubBuffer } = this.props;
          if (networkGroup && networkGroup.edge?.serialNumber) {
            clearSuperHubBuffer?.(networkGroup.edge.serialNumber);
            this.setState({ showConfirmPrompt: undefined });
          }
        },
        onCancel: () => {
          this.setState({ showConfirmPrompt: undefined });
        },
      },
    });
  };

  handleReboot = () => {
    this.setState({
      showConfirmPrompt: {
        message: 'Are you sure you want to reboot the SuperHub?',
        onConfirm: () => {
          const { networkGroup, rebootSuperHub } = this.props;
          if (networkGroup && networkGroup.edge?.serialNumber) {
            rebootSuperHub?.(networkGroup.edge.serialNumber);
            this.setState({ showConfirmPrompt: undefined });
          }
        },
        onCancel: () => {
          this.setState({ showConfirmPrompt: undefined });
        },
      },
    });
  };

  handleOpenUpdateFirmwareModal = () => {
    this.setState({ showUpdateFirmwareModal: true });
  };

  handleCloseUpdateFirmwareModal = () => {
    this.setState({ showUpdateFirmwareModal: false });
  };

  onSave = () => {
    const {
      id,
      createHub,
      patchHub,
      updateHub,
      patch,
      editNetworkGroup,
      displayFormErrors,
      gateways,
      editGateway,
    } = this.props;
    if (editNetworkGroup && hasKeys(editNetworkGroup.formErrors)) {
      displayFormErrors();
    } else {
      if (
        editNetworkGroup?.fields?.edge?.edgeDevice === DeviceType.EB_SUPER_HUB
      ) {
        const gateway = gateways?.find(
          g =>
            g.model === DeviceType.EB_SUPER_HUB ||
            g.model === DeviceType.ENERGYBOX_HUB
        ) as Gateway;

        if (!!editGateway?.new && gateway === undefined) {
          const updateNewHub = (key: string, value: any) =>
            updateHub('new', key, value);
          updateNewHub('title', `${editNetworkGroup.fields.title}-Hub`);
          updateNewHub('description', '');
          updateNewHub('uuid', editGateway.new.fields.uuid);
          updateNewHub('model', DeviceType.EB_SUPER_HUB);
          updateNewHub('spaceId', editNetworkGroup?.fields.spaceId);
          updateNewHub('networkGroupId', Number(id));
          createHub(editNetworkGroup.fields.siteId);
        } else {
          const updateExistingHub = (key: string, value: any) =>
            updateHub(String(gateway.id), key, value);
          updateExistingHub('title', `${editNetworkGroup.fields.title}-Hub`);
          updateExistingHub('description', '');
          updateExistingHub('uuid', editGateway[gateway.id]?.fields.uuid);
          updateExistingHub('model', DeviceType.EB_SUPER_HUB);
          updateExistingHub('spaceId', editNetworkGroup?.fields.spaceId);
          updateExistingHub('networkGroupId', Number(id));
          patchHub(String(gateway.id));
        }
      }
      patch();
    }
  };

  isLatestBuild = (runningRelease: string, latestRelease: string) => {
    if (runningRelease && latestRelease) {
      const runningReleaseVersion = runningRelease.toString().split('-')[0];
      const latestReleaseVersion = latestRelease.toString().split('-')[0];
      return runningReleaseVersion === latestReleaseVersion;
    }
    return true;
  };

  getRemoteAccessUrl = (serialNumber: string) =>
    `https://${serialNumber}.${process.env.REACT_APP_SUPERHUB_REMOTE_DOMAIN}`;

  startRemoteAccessInNewTab = async (serialNumber: string) => {
    const { accessToken } = await fetchApi(
      fetchRemoteAccessToken(serialNumber)
    );

    const remoteAccessUrl =
      this.getRemoteAccessUrl(serialNumber) + `?token=${accessToken}`;
    const a = document.createElement('a');
    a.href = remoteAccessUrl;

    a.setAttribute('target', '_blank');
    a.click();
    a.remove();
  };

  userAccess = [UserPlatformAccess.GLOBAL_ADMIN, UserPlatformAccess.INSTALLER];

  listOptions = (
    runningRelease: string | undefined,
    latestRelease: string | undefined
  ) => [
    {
      title: 'Clear Buffer',
      onSelect: this.handleClearBuffer,
    },
    {
      title: 'Reboot',
      onSelect: this.handleReboot,
      access: this.userAccess,
    },
    {
      title: 'Update Firmware',
      onSelect: this.handleOpenUpdateFirmwareModal,
      type: [GatewayVendorLabel.energybox.toString()],
      icon: runningRelease &&
        latestRelease &&
        !this.isLatestBuild(runningRelease, latestRelease) && (
          <WarningIcon size={16} />
        ),
      access: this.userAccess,
    },
    {
      title: 'Delete',
      onSelect: this.handleOpenDeletePrompt.bind(this),
      isDeleteItem: true,
    },
  ];

  render() {
    const {
      networkGroup,
      editNetworkGroup,
      onChange,
      onCancel,
      gateways,
      balenaStatusBySerialNumber,
      firmwareReleases,
      superHubStatusBySN,
      networkGroupApiError,
      lookupMACAddressError,
    } = this.props;

    const {
      showDeletePrompt,
      showConfirmPrompt,
      showUpdateFirmwareModal,
      showSuccessToastMessage,
      showFailureToastMessage,
    } = this.state;

    if (!editNetworkGroup) {
      if (Object.keys(networkGroupApiError).length) {
        return accessDeniedError(networkGroupApiError);
      } else {
        return <div>Loading...</div>;
      }
    }

    const superHub =
      networkGroup?.edge?.edgeDevice === 'EB_SUPER_HUB'
        ? (gateways?.find(
            g =>
              g.model === DeviceType.EB_SUPER_HUB ||
              g.model === DeviceType.ENERGYBOX_HUB
          ) as Gateway)
        : undefined;

    const balenaStatus =
      balenaStatusBySerialNumber?.[networkGroup?.edge?.serialNumber || ''];

    const runningRelease = balenaStatus?.runningRelease?.[0]?.raw_version;
    const runningReleaseId = balenaStatus?.runningRelease?.[0]?.id;

    return (
      <>
        {superHub && balenaStatus && networkGroup?.edge?.serialNumber && (
          <UpdateBalenaFirmwareModal
            device={superHub}
            runningReleaseId={runningReleaseId}
            onClose={this.handleCloseUpdateFirmwareModal}
            isVisible={showUpdateFirmwareModal}
            firmwareGatewayModel={FirmwareGatewayModel.ENERGYBOX_HUB}
            serialNumber={networkGroup.edge.serialNumber}
          />
        )}

        {networkGroup && (
          <ShowDetailPageHeader
            name={networkGroup.title}
            description={<ResourceFullPath resourceId={networkGroup.id} />}
            resourceName="Network Group"
            onDelete={this.handleOpenDeletePrompt.bind(this)}
            listOptions={
              networkGroup.edge?.edgeDevice === 'EB_SUPER_HUB'
                ? this.listOptions(
                    runningRelease,
                    firmwareReleases[0]?.raw_version
                  )
                : undefined
            }
            siteId={networkGroup.siteId}
            isOnline={balenaStatus?.online}
          />
        )}

        {showSuccessToastMessage && (
          <div className={styles.successToastMessage}>
            <div className={styles.successToastMessageCard}>
              <IconCheckboxOutlined
                className={styles.successToastMessageIcon}
                size={16}
              />
              {showSuccessToastMessage}
            </div>
          </div>
        )}

        {showFailureToastMessage && (
          <div className={styles.failureToastMessage}>
            <div className={styles.failureToastMessageCard}>
              <WarningIcon
                className={styles.failureToastMessageIcon}
                size={16}
              />
              <span className={styles.toastWarning}>Warning</span>
              {showFailureToastMessage}
            </div>
          </div>
        )}

        <div
          className={classNames(
            styles.paddingBetweenCards,
            styles.mainCardTopPadding
          )}
        >
          {editNetworkGroup && networkGroup && (
            <>
              <Card>
                <CardContent>
                  <div className={styles.mainCardGrid}>
                    <CardTitle className={styles.mainCardTitle}>
                      <span>General Information</span>
                      <span>
                        {networkGroup.edge?.edgeDevice ===
                          DeviceType.EB_SUPER_HUB && (
                          <RemoteAccessButton
                            serialNumber={networkGroup.edge?.serialNumber || ''}
                            balenaStatus={balenaStatus}
                          />
                        )}
                      </span>
                    </CardTitle>
                    <div className={styles.subCard}>
                      <EditNetworkGroupForm
                        {...editNetworkGroup.fields}
                        isChanged={editNetworkGroup.isChanged}
                        formErrors={editNetworkGroup.formErrors}
                        formErrorsVisible={editNetworkGroup.formErrorsVisible}
                        onChange={onChange}
                        // When editing SuperHub, site should not be changed
                        lockSiteId={
                          editNetworkGroup.fields.edge.edgeDevice ===
                          'EB_SUPER_HUB'
                            ? networkGroup.siteId ||
                              editNetworkGroup.fields.siteId
                            : undefined
                        }
                        originalNetworkGroup={networkGroup}
                        superHub={superHub}
                        siteId={
                          networkGroup?.siteId ||
                          editNetworkGroup?.fields?.siteId
                        }
                        spaceId={superHub?.spaceId}
                        runningRelease={runningRelease}
                        balenaStatus={balenaStatus}
                        updateHub={updateHub}
                      />
                      {renderAPIerror(
                        editNetworkGroup.apiError,
                        NetworkGroupActions.PATCH_NETWORK_GROUP_ERROR
                      )}
                    </div>
                  </div>
                </CardContent>

                {editNetworkGroup.isChanged && (
                  <CardActions>
                    <Button
                      variant="text"
                      onClick={onCancel}
                      children="Cancel"
                    />

                    <Button
                      disabled={
                        editNetworkGroup.isLoading || lookupMACAddressError
                      }
                      onClick={this.onSave}
                    >
                      {editNetworkGroup.isLoading ? (
                        <Loader size={16} variant="secondary" />
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </CardActions>
                )}
              </Card>

              {superHub && (
                <>
                  <div className={styles.mainCardTopPadding}>
                    <Card>
                      <CardContent>
                        <div className={styles.mainCardGrid}>
                          <CardTitle className={styles.mainCardTitle}>
                            <span>Hub App (Radio Network)</span>
                            <span>
                              <RemoteAccessButton
                                serialNumber={
                                  networkGroup.edge?.serialNumber || ''
                                }
                                balenaStatus={balenaStatus}
                                path={'/radioNetwork'}
                              />
                            </span>
                          </CardTitle>
                          <div className={styles.subCard}>
                            <EditHubForm
                              {...superHub}
                              lockSiteId={networkGroup.siteId}
                              onChange={() => {}}
                              formErrors={{ error: ['error'] }}
                              formErrorsVisible={false}
                              model={superHub.model as DeviceType}
                              isNew={'superHub'}
                              readOnly={true}
                              isNetworkGroupPage={true}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>

                  <SuperHubSensors
                    gateways={gateways}
                    uuid={networkGroup.edge?.uuid!}
                    networkGroup={networkGroup}
                  />
                </>
              )}
            </>
          )}
        </div>

        {showDeletePrompt && this.deletePrompt()}
        {showConfirmPrompt && this.confirmPrompt()}
      </>
    );
  }
}

const mapStateToProps = (
  { networkGroups, gateways, balena, superhub }: ApplicationState,
  { id }: OwnProps
) => ({
  networkGroup: networkGroups.networkGroupsById[parseInt(id)],
  editNetworkGroup: networkGroups.editById[parseInt(id)],
  gateways: gateways.gatewaysByNetworkGroupId[id],
  balenaStatusBySerialNumber: balena.statusBySerialNumber,
  firmwareReleases: balena.firmwareReleases,
  superHubStatusBySN: superhub.statusBySN,
  updateFirmware: balena.patchReleaseToDevice,
  networkGroupApiError: networkGroups.networkGroupApiError,
  editGateway: gateways.editById,
  lookupMACAddressError: balena.lookupMACAddressError,
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => {
    dispatch(getNetworkGroup(id));
    dispatch(getGatewaysByNetworkGroupId(id));
  },
  onChange: (f: string, v: string | number) => {
    dispatch(updateField(id, f, v));
  },
  patch: () => {
    dispatch(patch(id));
  },
  onDelete: () => {
    dispatch(destroy(id));
  },
  onCancel: () => dispatch(reset(id)),
  newGateway: siteId => dispatch(newGateway(siteId)),
  createHub: siteId => dispatch(createHub(siteId)),
  updateHub: (id, field, value) => dispatch(updateHub(id, field, value)),
  patchHub: id => dispatch(patchHub(id)),
  loadGateways: () => dispatch(getGatewaysByNetworkGroupId(id)),
  loadBalenaStatusBySerialNumber: (serialNumber: string) =>
    dispatch(getBalenaStatusBySerialNumber(serialNumber)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
  clearSuperHubBuffer: (serialNumber: string) =>
    dispatch(clearSuperHubBuffer(serialNumber)),
  rebootSuperHub: (serialNumber: string) =>
    dispatch(rebootSuperHub(serialNumber)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowNetworkGroupPage);
