import {
  Button,
  ExtraShortShortSkeletonCell,
  LongExtraLongSkeletonCell,
  MediaElement,
  SearchBox,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  SortDirection,
  OpacityIndex,
  Sop,
  Equipment,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
  hasSubstr,
} from '@energybox/react-ui-library/dist/utils';
import { TempCheck } from '@energybox/react-ui-library/dist/icons';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { openModal } from '../../actions/modal';
import { Routes } from '../../routes';
import { OpenModal } from '../../types/modal';
import { checkCommonPlural } from '../../util';
import styles from './SentinelsTable.module.css';
import { useCurrentUser } from '../../hooks/useAppDetails';

import theme from '../../theme';
import { TableWrapper } from '../../components/ui/Table';

import { Placeholder } from '../../types/global';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { useUrlState } from '../../hooks/utils';
import { deleteReminderModal } from '../../utils/reminder';
import { Reminder, deleteReminder } from '../../actions/reminder';
import { uniq, uniqBy, path } from 'ramda';
import { ApplicationState } from '../../reducers';
import { getEquipments } from '../../actions/equipment';
import { getSops } from '../../actions/sops';
import { StepType } from '../../types/reminderWizard';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';

interface Props {
  dataIsLoading: boolean;
  reminders: Reminder[];
  columns: ReminderTableColumns[];
  hasSearch?: boolean;
  secondaryAction?: any | React.Component;
  redirect?: string;
  renderLimit?: number;
  currentSiteId?: number | string;
  currentTargetId?: number | string;
  ianaTimeZoneCode?: string;
  selectedTargetId?: number | string;
  viewportType: ViewportTypes.MOBILE;
}

export const RemindersTable = (props: Props) => {
  const {
    dataIsLoading,
    reminders,
    columns,
    secondaryAction,
    hasSearch,
    renderLimit,
    ianaTimeZoneCode,
    selectedTargetId,
    viewportType,
  } = props;
  const history = useHistory();
  const isMobile = viewportType === ViewportTypes.MOBILE;
  const currentUser = useCurrentUser();
  const orgId = useCurrentOrganizationId();

  const dispatch = useDispatch();

  const [equipments, setEquipments] = useState<Equipment[]>([]);

  const [savedQuery, updateSavedQuery] = useUrlState<string>('query', '');
  const [savedPage, updateSavedPage] = useUrlState<number>('page', 1);

  const equipmentsFormAppState = useSelector(
    ({ equipment }: ApplicationState) => equipment.equipmentPayloadArray
  );
  const taskSops = useSelector(({ sops }: ApplicationState) =>
    Object.values(sops.sopsById)
  );

  useEffect(() => {
    const siteIds =
      (reminders?.length &&
        uniq(
          reminders
            ?.map(reminder => reminder?.components?.map(i => i.siteId))
            .flat()
        )) ??
      [];
    if (siteIds.length > 0) {
      dispatch(getEquipments({ siteIds }));
      let getSopsParams = {
        types: ['TASK'],
      };
      getSopsParams['organizationUnitIds'] = siteIds;
      if (orgId !== undefined) {
        getSopsParams['organizationUnitIds'].push(orgId);
      }
      dispatch(getSops(getSopsParams));
    }
  }, [reminders]);

  useEffect(() => {
    setEquipments(equipmentsFormAppState);
  }, [equipmentsFormAppState]);

  const handleSearchChange = (query: string) => {
    updateSavedQuery(query);
  };

  const pagination = (pageCount: number) => {
    updateSavedPage(pageCount);
  };

  const filteredReminders =
    savedQuery && savedQuery.length >= 3
      ? reminders.filter((reminder: Reminder) =>
          hasSubstr(`${reminder.title}`, savedQuery)
        )
      : reminders;

  const isLoading = dataIsLoading;

  const renderedColumns: Columns<Reminder>[] = useMemo(() => {
    const columnsMapping = getColumnsMapping({
      history,
      currentUser,
      dispatch,
      ianaTimeZoneCode,
      equipments,
      taskSops,
    });
    return columns.map(column => columnsMapping[column]);
  }, [
    currentUser,
    dispatch,
    ianaTimeZoneCode,
    selectedTargetId,
    equipments,
    taskSops,
  ]);

  return (
    <>
      <DynamicContentWrapper>
        <PageContentHeader header="All Reminders">
          {secondaryAction ? secondaryAction : ''}
          {hasSearch && (
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={handleSearchChange}
              query={savedQuery}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredReminders.length === 0}
            />
          )}
        </PageContentHeader>
        <TableWrapper
          header={checkCommonPlural('Reminder', filteredReminders.length)}
        >
          <Table
            listView
            columns={renderedColumns}
            data={filteredReminders}
            dataIsLoading={isLoading}
            renderLimit={renderLimit}
            pagination={pagination}
            pageNumber={filteredReminders.length > 5 ? savedPage : 1}
          />
        </TableWrapper>
      </DynamicContentWrapper>
    </>
  );
};

export enum ReminderTableColumns {
  CONFIG = 'config',
  RECIPIENTS = 'recipients',
  SITES = 'sites',
  EQUIPMENTS = 'equipments',
  RESUME_CONFIG = 'resumeConfig',
  ACTIONS = 'action',
}

const countRecipients = (reminder: Reminder) => {
  const { components } = reminder;
  const uniqRecipients =
    (
      components?.length &&
      uniqBy(path(['id']), components?.map(i => i.recipients).flat())
    )?.length ?? 0;

  return uniqRecipients;
};

const countSites = (reminder: Reminder) => {
  return uniq(reminder?.components?.map(i => i.siteId) || []).length;
};

const countEquipments = (
  reminder: Reminder,
  sops: Sop[],
  equipments: Equipment[]
) => {
  const sopIds = reminder?.components?.map(i => i.sopId);
  const siteIds = reminder?.components?.map(i => i.siteId);
  const filteredSops = sops?.filter(i => sopIds?.includes(i.id));
  const equipmentTypeIds = filteredSops?.map(i => i?.equipmentTypeIds).flat();
  const filteredEquipments = equipments?.filter(i => {
    return (
      equipmentTypeIds.includes(i.typeId) &&
      i.space &&
      siteIds.includes(i.space?.parentId)
    );
  });
  return filteredEquipments.length;
};

const getColumnsMapping = ({
  history,
  dispatch,
  currentUser,
  ianaTimeZoneCode,
  equipments,
  taskSops,
}): {
  [column: string]: Columns<Reminder>;
} => {
  return {
    [ReminderTableColumns.CONFIG]: {
      width: '20%',
      header: 'Configuration',
      cellContent: (reminder: Reminder) => {
        return (
          <Link to={`${Routes.REMINDER}/${reminder.id}`}>
            <MediaElement
              title={reminder.title}
              // description={reminder.description}
              icon={<TempCheck size={24} />}
            />
          </Link>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Reminder, b: Reminder, sortDirection: SortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
        ]);
      },
    },
    [ReminderTableColumns.RECIPIENTS]: {
      width: '10%',
      header: 'Recipients',
      defaultSortDirection: SortDirection.DESC,
      cellContent: (reminder: Reminder) => <>{countRecipients(reminder)}</>,
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Reminder, b: Reminder, sortDirection: SortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          countRecipients
        );
      },
    },
    [ReminderTableColumns.SITES]: {
      width: '10%',
      header: 'Sites',
      defaultSortDirection: SortDirection.DESC,
      cellContent: (reminder: Reminder) => <>{countSites(reminder)}</>,
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Reminder, b: Reminder, sortDirection: SortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          countSites
        );
      },
    },
    [ReminderTableColumns.EQUIPMENTS]: {
      width: '10%',
      header: 'Equipments',
      defaultSortDirection: SortDirection.DESC,
      cellContent: (reminder: Reminder) => (
        <>{countEquipments(reminder, taskSops, equipments)}</>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Reminder, b: Reminder, sortDirection: SortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          reminder => countEquipments(reminder, taskSops, equipments)
        );
      },
    },
    [ReminderTableColumns.RESUME_CONFIG]: {
      width: '10%',
      header: '',
      align: 'right',
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      cellContent: (reminder: Reminder) => (
        <>
          {reminder.completed === true ? (
            ''
          ) : (
            <Button
              variant="outlined"
              onClick={() =>
                history.push(
                  `${Routes.REMINDER}/${reminder.id}/${StepType.rollout}`
                )
              }
            >
              Finish configuration
            </Button>
          )}
        </>
      ),
    },
    [ReminderTableColumns.ACTIONS]: {
      width: '10%',
      header: '',
      align: 'right',
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      cellContent: (reminder: Reminder) => {
        return (
          <div className={styles.dropdownMenu}>
            <div className={styles.dropdownMenuButton}>
              <MenuDropdown>
                <MenuDropdownItem
                  onSelect={() => {
                    history.push({
                      pathname: `${Routes.REMINDER}/${reminder?.id}/rollout`,
                    });
                  }}
                >
                  Edit
                </MenuDropdownItem>
                <MenuDropdownItem
                  isRed
                  onSelect={() =>
                    deleteReminderModal(
                      (modalConfig: OpenModal) =>
                        dispatch(openModal(modalConfig)),
                      () => dispatch(deleteReminder(reminder)),
                      reminder,
                      reminder.title
                    )
                  }
                >
                  Delete
                </MenuDropdownItem>
              </MenuDropdown>
            </div>
          </div>
        );
      },
    },
  };
};

export default withViewportType(RemindersTable);
