import {
  FormText,
  NativeSelectField,
  Radio,
} from '@energybox/react-ui-library/dist/components';
import {
  ComparisonType,
  CurrentUser,
  defaultTemperatureParams,
  MeasurementSystem,
  TemperatureUnit,
  ThresholdConfigParameters,
} from '@energybox/react-ui-library/dist/types';
import {
  parseInput,
  parseValueForDisplay,
  toCelsius,
  toFahrenheit,
  atMostOneDecimalRegex,
} from '@energybox/react-ui-library/dist/utils';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';

import React from 'react';
import RangeField from '../../../ui/RangeField';
import TextField from '../../../ui/TextField/TextField';
import styles from './ConditionTemperature.module.css';
import StepConditionItem from './StepConditionItem';
import StepConditionItemInput from './StepConditionItemInput';

type Props = {
  onValueChange: (name: string, value: number | string | undefined) => void;
  onComparisonTypeChange: (
    comparisonType: string,
    comparisonTypeValue: string,
    comparisonSentinelType?: string
  ) => void;
  parameters?: ThresholdConfigParameters;
  currentUser: CurrentUser;
};

type State = {
  measurementSystem: MeasurementSystem;
  isInvalidRange: boolean;
  errorMessage: string;
};

const RANGE_MIN = -250;
const RANGE_MAX = 250;

function round(n: number): number {
  return parseFloat(n.toFixed(1));
}

function enforceBounds(value: number): number {
  if (value > RANGE_MAX) {
    return RANGE_MAX;
  }

  if (value < RANGE_MIN) {
    return RANGE_MIN;
  }

  return value;
}

function getValueFormatter(type: MeasurementSystem): (value: number) => string {
  switch (type) {
    case MeasurementSystem.METRIC:
      return v => `${round(v)} °C`;
    case MeasurementSystem.IMPERIAL:
      return v => `${round(v)} °F`;
    default:
      return v => v.toString();
  }
}

class ConditionTemperature extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { currentUser } = this.props;
    const defaultTemperatureUnit = getUserPreferenceTemperatureUnit(
      currentUser
    );
    this.state = {
      measurementSystem:
        defaultTemperatureUnit === TemperatureUnit.C
          ? MeasurementSystem.METRIC
          : MeasurementSystem.IMPERIAL,
      isInvalidRange: false,
      errorMessage: '',
    };
  }

  onUnitChange = (ev: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({ measurementSystem: ev.target.value as MeasurementSystem });

  handleOnValueChange = (name: string) => (
    ev: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = ev.currentTarget;
    const { isInvalidRange } = this.state;

    if (value.match(atMostOneDecimalRegex) === null) {
      return;
    }

    const boundValue = enforceBounds(
      (() => {
        if (this.state.measurementSystem === MeasurementSystem.METRIC) {
          if (isNaN(parseFloat(value))) {
            return value;
          } else {
            return parseFloat(value);
          }
        } else {
          return parseInput(toCelsius, value, 4);
        }
      })()
    );

    this.props.onValueChange(name, boundValue);
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { parameters } = this.props;
    const { isInvalidRange } = this.state;

    if (
      parameters?.comparisonType === ComparisonType.inner ||
      parameters?.comparisonType === ComparisonType.outer
    ) {
      if (
        parameters?.rangeUpperBound &&
        parameters?.rangeLowerBound &&
        parameters?.rangeUpperBound <= parameters?.rangeLowerBound
      ) {
        if (!isInvalidRange) {
          this.setState({
            isInvalidRange: true,
            errorMessage:
              'The Temperature Upper bound must be higher than the Temperature Lower Bound.',
          });
        }
      } else {
        if (isInvalidRange) {
          this.setState({
            isInvalidRange: false,
            errorMessage: '',
          });
        }
      }
    }
  }

  render() {
    const { measurementSystem, isInvalidRange, errorMessage } = this.state;
    const {
      onValueChange,
      onComparisonTypeChange,
      parameters = defaultTemperatureParams,
    } = this.props;

    const temperatureUnit =
      measurementSystem === MeasurementSystem.IMPERIAL
        ? TemperatureUnit.F
        : TemperatureUnit.C;

    return (
      <>
        <StepConditionItem
          condition={
            <RangeField
              min={
                measurementSystem === MeasurementSystem.METRIC
                  ? RANGE_MIN
                  : toFahrenheit(RANGE_MIN)
              }
              max={
                measurementSystem === MeasurementSystem.METRIC
                  ? RANGE_MAX
                  : toFahrenheit(RANGE_MAX)
              }
              step={1}
              value={
                measurementSystem === MeasurementSystem.METRIC
                  ? parameters.threshold
                  : parseInput(toFahrenheit, parameters.threshold, 1)
              }
              rangeLowerBound={
                measurementSystem === MeasurementSystem.METRIC
                  ? parameters.rangeLowerBound
                  : parseInput(toFahrenheit, parameters.rangeLowerBound, 1)
              }
              rangeUpperBound={
                measurementSystem === MeasurementSystem.METRIC
                  ? parameters.rangeUpperBound
                  : parseInput(toFahrenheit, parameters.rangeUpperBound, 1)
              }
              condition={parameters.comparisonType}
              valueFormatterFn={getValueFormatter(measurementSystem)}
              onChange={(name, value) =>
                onValueChange(
                  name,
                  enforceBounds(
                    measurementSystem === MeasurementSystem.METRIC
                      ? value
                      : toCelsius(value)
                  )
                )
              }
            />
          }
        />

        <div className={styles.error}>
          <span className={styles.errorContainer}>
            {isInvalidRange ? errorMessage : ''}
          </span>
        </div>

        <StepConditionItem
          condition={
            <div className={styles.checkboxWrapper}>
              <Radio
                id="condition_less_then"
                checked={parameters.comparisonType === ComparisonType.lt}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.lt)
                }
                label="Less than"
              />
              <Radio
                id="condition_equals"
                checked={parameters.comparisonType === ComparisonType.eq}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.eq)
                }
                label="Exact"
              />
              <Radio
                id="condition_greater"
                checked={parameters.comparisonType === ComparisonType.gt}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.gt)
                }
                label="Greater than"
              />
              <Radio
                id="condition_range_inner"
                checked={parameters.comparisonType === ComparisonType.inner}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.inner)
                }
                label="Range"
              />
              <Radio
                id="condition_range_outer"
                checked={parameters.comparisonType === ComparisonType.outer}
                onChange={() =>
                  onComparisonTypeChange('comparisonType', ComparisonType.outer)
                }
                label="Inverse Range"
              />
            </div>
          }
        />

        <StepConditionItem
          label={<FormText>Scale:</FormText>}
          condition={
            <NativeSelectField
              onChange={this.onUnitChange}
              value={this.state.measurementSystem}
            >
              <option value={MeasurementSystem.IMPERIAL}>° F</option>
              <option value={MeasurementSystem.METRIC}>° C</option>
            </NativeSelectField>
          }
        />

        {parameters.comparisonType === ComparisonType.inner ||
        parameters.comparisonType === ComparisonType.outer ? (
          <>
            <StepConditionItem
              label={<FormText>Temperature lower bound:</FormText>}
              condition={
                <StepConditionItemInput
                  value={
                    <TextField
                      type="number"
                      step={0.1}
                      value={(threshold => {
                        return parseValueForDisplay(temperatureUnit, threshold);
                      })(parameters.rangeLowerBound)}
                      onChange={this.handleOnValueChange('rangeLowerBound')}
                    />
                  }
                  unit={<FormText>{temperatureUnit}</FormText>}
                />
              }
            />
            <StepConditionItem
              label={<FormText>Temperature upper bound:</FormText>}
              condition={
                <StepConditionItemInput
                  value={
                    <TextField
                      type="number"
                      step={0.1}
                      value={(threshold => {
                        return parseValueForDisplay(temperatureUnit, threshold);
                      })(parameters.rangeUpperBound)}
                      onChange={this.handleOnValueChange('rangeUpperBound')}
                    />
                  }
                  unit={<FormText>{temperatureUnit}</FormText>}
                />
              }
            />
          </>
        ) : (
          <StepConditionItem
            label={<FormText>Temperature:</FormText>}
            condition={
              <StepConditionItemInput
                value={
                  <TextField
                    type="number"
                    step={0.1}
                    value={(threshold => {
                      return parseValueForDisplay(temperatureUnit, threshold);
                    })(parameters.threshold)}
                    onChange={this.handleOnValueChange('threshold')}
                  />
                }
                unit={<FormText>{temperatureUnit}</FormText>}
              />
            }
          />
        )}
      </>
    );
  }
}

export default ConditionTemperature;
