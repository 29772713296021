import React from 'react';

type Props = {
  size: number;
};

const DashboardSiteIcon = ({ size }: Props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 160 160"
      enableBackground="new 0 0 160 160"
    >
      <g opacity=".1" fill="#00A1AF">
        <path d="M140.01 84.5h-38.5c-.83 0-1.5.67-1.5 1.5v1c0 .83.67 1.5 1.5 1.5h22.5v2h2v25h-2v-11c0-1.1-.9-2-2-2h-4.91c-1.1 0-2 .9-2 2v9h-.6a.49.49 0 0 1-.49-.49v-5.51c0-.13.05-.25.14-.35l.71-.71c.2-.2.2-.51 0-.71s-.51-.2-.71 0l-.71.71c-.28.28-.44.66-.44 1.05V113c0 .82.67 1.49 1.49 1.49h.6v1h-3.59c-.28 0-.5.22-.5.5s.22.5.5.5h28c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-.59v-1h.6c.82 0 1.49-.67 1.49-1.49v-5.51c0-.4-.15-.77-.44-1.05l-.71-.71c-.2-.2-.51-.2-.71 0s-.2.51 0 .71l.71.71a.5.5 0 0 1 .14.35v5.51c0 .27-.22.49-.49.49h-.6v-9c0-1.1-.9-2-2-2h-4.91c-1.1 0-2 .9-2 2v11h-2v-25h2v-2h10c.83 0 1.5-.67 1.5-1.5v-1c.02-.83-.65-1.5-1.48-1.5z" />
        <path d="M110.01 87.5h-1.5v-14h3v-2h-21v-15h2v-3h3.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5h-24c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5h3.5v3h2v15h-7v2h3v2.69a5.983 5.983 0 0 0-5-2.69h-20c-3.31 0-6 2.69-6 6v15.41l-17.49 9.71c-.48.27-.66.88-.39 1.36.18.33.52.51.88.51.16 0 .33-.04.48-.13l1.51-.84v14.97h65v-26h.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-.5v-5H110c.28 0 .5-.22.5-.5a.478.478 0 0 0-.49-.49zm-71.5 28h-5v-8h5v8zm24 3h-8v-14h8v14zm25-4h-9v-4h9v4zm0-6h-9v-4h9v4zm0-6h-9v-4h9v4zm-9-6v-2h9v2h-9z" />
      </g>
      <path
        d="M51.51 46.5c0-2.76-2.24-5-5-5-2.43 0-4.46 1.74-4.9 4.04-1.73.2-3.1 1.67-3.1 3.46 0 1.92 1.57 3.5 3.5 3.5h9.5c1.66 0 3-1.34 3-3s-1.34-3-3-3z"
        opacity=".1"
        fill="#00A1AF"
        enableBackground="new"
      />
      <g fill="#66D2D9">
        <path d="M43.51 77.5h4v6h-4zM50.51 77.5h4v6h-4zM57.51 77.5h4v6h-4zM43.51 86.5h4v6h-4zM50.51 86.5h4v6h-4zM57.51 86.5h4v6h-4zM27.51 109.5h5v-8h-5v8zm1-7h3v6h-3v-6zM72.51 90.5h9v-4h-9v4zm1-3h7v2h-7v-2zM72.51 96.5h9v-4h-9v4zm1-3h7v2h-7v-2zM72.51 102.5h9v-4h-9v4zm1-3h7v2h-7v-2zM72.51 108.5h9v-4h-9v4zm1-3h7v2h-7v-2zM103.01 71.5h-9v10h15v-10h-6zm-5 9h-3v-8h3v8zm5 0h-4v-8h4v8zm5-8v8h-4v-8h4zM86.01 61.5h5v1h-5zM86.01 64.5h5v1h-5zM86.01 67.5h5v1h-5zM86.01 70.5h5v1h-5z" />
        <circle cx="88.51" cy="55" r="1.5" />
        <path d="M114.01 101.5h5v2h-5zM129.01 101.5h5v2h-5zM41.01 46.5h-2.5V44c0-.28-.22-.5-.5-.5s-.5.22-.5.5v2.5h-2.5c-.28 0-.5.22-.5.5s.22.5.5.5h2.5V50c0 .28.22.5.5.5s.5-.22.5-.5v-2.5h2.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM27.51 64c0 .28.22.5.5.5s.5-.22.5-.5v-.5h.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-.5V62c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.5h-.5c-.28 0-.5.22-.5.5s.22.5.5.5h.5v.5z" />
      </g>
      <g fill="#00A1AF">
        <path d="M97.51 86.5h-2.5v-5h19.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-1.5v-14h3v-2h-21v-15h2v-4h-17v4h2v15h-7v2h3v14h-9.5v-7c0-3.31-2.69-6-6-6h-20c-3.31 0-6 2.69-6 6v15.41l-17.49 9.71c-.48.27-.66.88-.39 1.36.18.33.52.51.88.51.16 0 .33-.04.48-.13l1.51-.84v12.97c-.55 0-1 .45-1 1s.45 1 1 1h65v-27h11c.28 0 .5-.22.5-.5s-.21-.49-.49-.49zm14.5-6h-17v-14h17v14zm-31-32v-2h15v2h-15zm-2 18h3v14h-3v-14zm-55.5 46V98.42l13-7.22v21.3h-13zm33 0h-8v-13h8v13zm10 0h-9v-14h-10v14h-9v-39c0-2.21 1.79-4 4-4h20c2.21 0 4 1.79 4 4v39zm18 0h-16v-30h16v30zm2-26v-6h-2.5v-31h9v37h-6.5z" />
        <path d="M137.37 103.64a.485.485 0 0 0-.7 0c-.2.2-.2.51 0 .71l.71.71a.5.5 0 0 1 .14.35v5.51c0 .27-.22.49-.49.49h-1.01c-.03 0-.06.01-.09.02v-9.93c0-1.1-.9-2-2-2h-4.91c-1.1 0-2 .9-2 2v11h-2v-25h2v-2h10.5c.55 0 1-.45 1-1s-.45-1-1-1h-39.5c-.55 0-1 .45-1 1s.45 1 1 1h23v2h2v25h-2v-11c0-1.1-.9-2-2-2h-4.91c-1.1 0-2 .9-2 2v9h-.6a.49.49 0 0 1-.49-.49v-5.51c0-.13.05-.25.14-.35l.71-.71c.2-.2.2-.51 0-.71a.485.485 0 0 0-.7 0l-.71.71c-.28.28-.44.66-.44 1.05V110c0 .82.67 1.49 1.49 1.49h.6v1h-3.59c-.28 0-.5.22-.5.5s.22.5.5.5h28c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-.59v-.11c.03.01.06.02.09.02h1.01c.82 0 1.49-.67 1.49-1.49v-5.51c0-.4-.15-.77-.44-1.05l-.71-.71zM122.01 86.5v-1h4v1h-4zm-8.9 15c0-.55.45-1 1-1h4.91c.55 0 1 .45 1 1l.02 11h-6.93v-11zm14.9 11v-11c0-.55.45-1 1-1h4.91c.55 0 1 .45 1 1l.02 11h-6.93zM29.01 53.5h1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5s.23.5.5.5zM25.01 56.5h3c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3c-.28 0-.5.22-.5.5s.23.5.5.5zM31.01 56.5h5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-5c-.28 0-.5.22-.5.5s.23.5.5.5zM100.51 110c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5s.22.5.5.5h1c.28 0 .5-.23.5-.5zM96.01 115.5h-1c-.28 0-.5.22-.5.5s.22.5.5.5h1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM103.01 112.5h-3c-.28 0-.5.22-.5.5s.22.5.5.5h3c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM96.01 112.5h-5c-.28 0-.5.22-.5.5s.22.5.5.5h5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5z" />
      </g>
      <path
        fill="#66D2D9"
        d="M76.51 46.5h24c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5h-24c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5zm0-5h24c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-24c-.83 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5z"
      />
    </svg>
  );
};

export default DashboardSiteIcon;
