import {
  Button,
  Card,
  CardActions,
  CardContent,
  Label,
  Loader,
  SingleDatePicker,
} from '@energybox/react-ui-library/dist/components';
import {
  Site,
  SiteInstallationStatus,
  SolutionInstallationStatus,
} from '@energybox/react-ui-library/dist/types/Site';
import { classNames, isDefined } from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';
import { pick } from 'ramda';

import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { patch } from '../../../actions/sites';
import formStyles from '../../../components/EditSiteForm/EditSiteForm.module.css';
import { useAppLocale } from '../../../hooks/useAppDetails';
import { useGetSite } from '../../../hooks/useSites';
import { ApplicationState } from '../../../reducers';
import SelectSiteInstallationStatus from '../../Selects/SelectSiteInstallationStatus';
import styles from './SiteStatusAndServiceInfo.module.css';

interface Props {
  siteId: string | number;
}

export type SiteStatusFields = {
  siteHeroDate: string | null;
  siteHeroStatus: SolutionInstallationStatus | null;
  siteInstallationStatus: SiteInstallationStatus;
  siteInstallationDate: string | null;
  subscriptionStartDate: string | null;
  thermostatControl: SolutionInstallationStatus | null;
  thermostatControlDate: string | null;
  autoScheduler: SolutionInstallationStatus | null;
  autoSchedulerDate: string | null;
  energyTracker: SolutionInstallationStatus | null;
  energyTrackerDate: string | null;
};

export type LocalStore = {
  hasUnsavedChanges: boolean;
  fields: SiteStatusFields;
};

const initialState: LocalStore = {
  hasUnsavedChanges: false,
  fields: {
    siteHeroDate: null,
    siteHeroStatus: null,
    siteInstallationStatus: SiteInstallationStatus.NOT_STARTED,
    siteInstallationDate: null,
    subscriptionStartDate: null,
    thermostatControl: null,
    thermostatControlDate: null,
    autoScheduler: null,
    autoSchedulerDate: null,
    energyTracker: null,
    energyTrackerDate: null,
  },
};

enum OtherActions {
  FROM_API = 'FROM_API',
}

export enum UpdateFieldActions {
  SITE_HERO_DATE = 'siteHeroDate',
  SITE_HERO_STATUS = 'siteHeroStatus',
  SITE_INSTALLATION_DOC = 'siteInstallationDoc',
  ENERGY_TRACKER = 'energyTracker',
  ENERGY_TRACKER_DATE = 'energyTrackerDate',
  SITE_INSTALLATION_STATUS = 'siteInstallationStatus',
  SITE_INSTALLATION_DATE = 'siteInstallationDate',
  SUBSCRIPTION_START_DATE = 'subscriptionStartDate',
  THERMOSTAT_CONTROL = 'thermostatControl',
  THERMOSTAT_CONTROL_DATE = 'thermostatControlDate',
  AUTO_SCHEDULER = 'autoScheduler',
  AUTO_SCHEDULER_DATE = 'autoSchedulerDate',
}

type ActionPayLoad = {
  type: OtherActions | UpdateFieldActions;
  value?;
  payload?: SiteStatusFields;
};

export const reducer = (state, action: ActionPayLoad) => {
  switch (action.type) {
    case OtherActions.FROM_API:
      if (action.payload === undefined) return state;
      return {
        fields: { ...action.payload },
        hasUnsavedChanges: false,
      };
    case UpdateFieldActions.SITE_HERO_DATE:
    case UpdateFieldActions.SITE_HERO_STATUS:
    case UpdateFieldActions.ENERGY_TRACKER:
    case UpdateFieldActions.ENERGY_TRACKER_DATE:
    case UpdateFieldActions.SITE_INSTALLATION_STATUS:
    case UpdateFieldActions.SITE_INSTALLATION_DATE:
    case UpdateFieldActions.SUBSCRIPTION_START_DATE:
    case UpdateFieldActions.THERMOSTAT_CONTROL:
    case UpdateFieldActions.THERMOSTAT_CONTROL_DATE:
    case UpdateFieldActions.AUTO_SCHEDULER:
    case UpdateFieldActions.AUTO_SCHEDULER_DATE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.type]: action.value,
        },
        hasUnsavedChanges: true,
      };
    default:
      throw new Error();
  }
};

const SiteStatusAndServiceInfo: React.FC<Props> = ({ siteId }) => {
  const dispatch = useDispatch();
  const [localStore, localDispatch] = useReducer(reducer, initialState);
  const [formErrorsVisible, setFormErrorsVisible] = useState(false);

  const locale = useAppLocale();
  const site: Site | undefined = useGetSite(siteId);

  const patchingInProgress = useSelector(({ sites }: ApplicationState) => {
    return sites.editById[siteId].isLoading;
  });

  useEffect(() => {
    if (!site) return;
    const payload: SiteStatusFields = pick(
      Object.values(UpdateFieldActions),
      site
    );
    localDispatch({ type: OtherActions.FROM_API, payload });
  }, [site]);

  const {
    hasUnsavedChanges,
    fields: {
      subscriptionStartDate,
      siteInstallationStatus,
      siteInstallationDate,
      siteHeroStatus,
      siteHeroDate,
      energyTracker,
      energyTrackerDate,
      autoScheduler,
      autoSchedulerDate,
      thermostatControl,
      thermostatControlDate,
    },
  } = localStore as LocalStore;
  const isInvalidSiteInstallationDate =
    siteInstallationStatus === SiteInstallationStatus.COMPLETED &&
    !isDefined(siteInstallationDate);
  const isInvalidSiteHeroDate =
    siteHeroStatus === SolutionInstallationStatus.COMPLETED &&
    !isDefined(siteHeroDate);
  const isInvalidEnergyTrackerDate =
    energyTracker === SolutionInstallationStatus.COMPLETED &&
    !isDefined(energyTrackerDate);
  const isInvalidAutoSchedulerDate =
    autoScheduler === SolutionInstallationStatus.COMPLETED &&
    !isDefined(autoSchedulerDate);
  const isInvalidThermostatControlDate =
    thermostatControl === SolutionInstallationStatus.COMPLETED &&
    !isDefined(thermostatControlDate);
  const isThereError =
    isInvalidSiteInstallationDate ||
    isInvalidSiteHeroDate ||
    isInvalidEnergyTrackerDate ||
    isInvalidAutoSchedulerDate ||
    isInvalidThermostatControlDate;

  const onClickSave = () => {
    if (!hasUnsavedChanges) return;
    if (isThereError) {
      setFormErrorsVisible(true);
    } else {
      dispatch(patch(siteId, localStore.fields));
    }
  };

  if (!site) return null;
  return (
    <Card className={styles.paddingBetweenCards}>
      <CardContent>
        <span className={formStyles.columnHeader}>Deployment Status</span>
        <div className={styles.inputGroup}>
          <div className={styles.inputLabel}>
            <Label htmlFor={UpdateFieldActions.SUBSCRIPTION_START_DATE}>
              Subscription Start Date
            </Label>
          </div>
          <div id={UpdateFieldActions.SUBSCRIPTION_START_DATE}>
            <SingleDatePicker
              value={subscriptionStartDate || undefined}
              setDate={(date: string) => {
                localDispatch({
                  type: UpdateFieldActions.SUBSCRIPTION_START_DATE,
                  value: DateTime.fromFormat(date, 'yyyy-MM-dd', {
                    zone: site.timeZone,
                  }).toISO(),
                });
              }}
              locale={locale}
              ianaTimeZoneCode={site.timeZone}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <InstallationStatusInput
            required
            installationType="SITE"
            label="Site Status"
            description="Handover to the customer"
            currentStatus={siteInstallationStatus}
            dateCompleted={siteInstallationDate}
            dispatch={localDispatch}
            statusAction={UpdateFieldActions.SITE_INSTALLATION_STATUS}
            dateAction={UpdateFieldActions.SITE_INSTALLATION_DATE}
            ianaTimeZoneCode={site.timeZone}
            formErrorsVisible={formErrorsVisible}
            error={isInvalidSiteInstallationDate}
          />
        </div>
      </CardContent>
      <div className={styles.dividerLine} />
      <CardContent>
        <div className={styles.marginVertical} />
        <span className={formStyles.columnHeader}>Service</span>
        <div className={styles.inputGroup}>
          <InstallationStatusInput
            installationType="SOLUTION"
            label="SiteHero"
            currentStatus={siteHeroStatus}
            dateCompleted={siteHeroDate}
            dispatch={localDispatch}
            statusAction={UpdateFieldActions.SITE_HERO_STATUS}
            dateAction={UpdateFieldActions.SITE_HERO_DATE}
            ianaTimeZoneCode={site.timeZone}
            formErrorsVisible={formErrorsVisible}
            error={isInvalidSiteHeroDate}
          />
        </div>
        <div className={styles.inputGroup}>
          <InstallationStatusInput
            installationType="SOLUTION"
            label="EnergyTracker"
            currentStatus={energyTracker}
            dateCompleted={energyTrackerDate}
            dispatch={localDispatch}
            statusAction={UpdateFieldActions.ENERGY_TRACKER}
            dateAction={UpdateFieldActions.ENERGY_TRACKER_DATE}
            ianaTimeZoneCode={site.timeZone}
            formErrorsVisible={formErrorsVisible}
            error={isInvalidEnergyTrackerDate}
          />
        </div>
        <div className={styles.inputGroup}>
          <InstallationStatusInput
            installationType="SOLUTION"
            label="AutoScheduler"
            currentStatus={autoScheduler}
            dateCompleted={autoSchedulerDate}
            dispatch={localDispatch}
            statusAction={UpdateFieldActions.AUTO_SCHEDULER}
            dateAction={UpdateFieldActions.AUTO_SCHEDULER_DATE}
            ianaTimeZoneCode={site.timeZone}
            formErrorsVisible={formErrorsVisible}
            error={isInvalidAutoSchedulerDate}
          />
        </div>
        <div className={styles.inputGroup}>
          <InstallationStatusInput
            installationType="SOLUTION"
            label="Thermostat Control"
            currentStatus={thermostatControl}
            dateCompleted={thermostatControlDate}
            dispatch={localDispatch}
            statusAction={UpdateFieldActions.THERMOSTAT_CONTROL}
            dateAction={UpdateFieldActions.THERMOSTAT_CONTROL_DATE}
            ianaTimeZoneCode={site.timeZone}
            formErrorsVisible={formErrorsVisible}
            error={isInvalidThermostatControlDate}
          />
        </div>
      </CardContent>
      {hasUnsavedChanges && (
        <CardActions>
          <Button
            variant="text"
            onClick={() => {
              const payload: SiteStatusFields = pick(
                Object.values(UpdateFieldActions),
                site
              );
              localDispatch({ type: OtherActions.FROM_API, payload });
            }}
            children="Cancel"
          />
          <Button disabled={patchingInProgress} onClick={onClickSave}>
            {patchingInProgress ? (
              <Loader size={16} variant="secondary" />
            ) : (
              'Save changes'
            )}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const InstallationStatusInput = ({
  installationType,
  label,
  description,
  currentStatus,
  dateCompleted,
  dispatch,
  statusAction,
  dateAction,
  ianaTimeZoneCode,
  required,
  formErrorsVisible,
  error,
}: {
  installationType: 'SITE' | 'SOLUTION';
  required?: boolean;
  label: string;
  description?: string;
  currentStatus: SiteInstallationStatus | SolutionInstallationStatus | null;
  dateCompleted: string | null;
  dispatch;
  statusAction: UpdateFieldActions;
  dateAction: UpdateFieldActions;
  ianaTimeZoneCode: string;
  formErrorsVisible: boolean;
  error: boolean;
}) => {
  const locale = useAppLocale();
  const isInstalled = currentStatus === 'COMPLETED';

  useEffect(() => {
    if (isInstalled) return;
    // resets completed date if installation status is not COMPLETE
    if (!isInstalled && dateCompleted) {
      dispatch({
        type: dateAction,
        value: null,
      });
    }
  }, [isInstalled, dateCompleted, dateAction]);

  const displayDate = dateCompleted
    ? DateTime.fromISO(dateCompleted).toFormat('yyyy-MM-dd')
    : undefined;

  return (
    <>
      <div className={styles.inputLabel}>
        <Label
          htmlFor={statusAction}
          required={required}
          style={{ paddingBottom: description ? '0' : '' }}
        >
          {label}
        </Label>
        {description && (
          <span className={styles.labelDescription}>{description}</span>
        )}
      </div>
      <div id={statusAction} className={styles.statusAndDateInput}>
        <div>
          <SelectSiteInstallationStatus
            installationType={installationType}
            onSelect={updatedStatus => {
              dispatch({
                type: statusAction,
                value: updatedStatus,
              });
            }}
            value={currentStatus}
            error={formErrorsVisible && error}
            customErrorText={'Select date if completed'}
          />
        </div>

        <SingleDatePicker
          className={styles.datePickerMarginLeft}
          value={displayDate}
          setDate={(date: string) => {
            dispatch({
              type: dateAction,
              value: DateTime.fromFormat(date, 'yyyy-MM-dd', {
                zone: ianaTimeZoneCode,
              }).toISO(),
            });
          }}
          locale={locale}
          ianaTimeZoneCode={ianaTimeZoneCode}
          disabled={!isInstalled}
        />
      </div>
    </>
  );
};

export default SiteStatusAndServiceInfo;
