import { Checkbox } from '@energybox/react-ui-library/dist/components';
import {
  Equipment,
  SentinelTarget,
  SentinelTargetEntities,
  SentinelTargetType,
  SentinelType,
  ResourceType,
  Site,
  SensorType,
  HvacControl,
  EquipmentType,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import {
  fetchEnergyEquipmentBySiteId,
  fetchEquipment,
} from '../../../../actions/equipmentFetch';
import ResourcePath from '../../../../containers/ResourcePath';
import styles from './StepRollout.module.css';
import { EquipmentChip } from './TargetChips';

type Props = {
  site: Site;
  sensorTypes: SensorType[];
  sentinelType?: SentinelType;
  handleTargetSelect: (
    sentinelTarget: SentinelTarget,
    siteId?: number | string,
    targetedEntity?: SentinelTargetEntities
  ) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  equipmentTypeFilter?: EquipmentType[];
};

type State = {
  equipment: Equipment[];
  isLoading: boolean;
  isFirstTime: number;
};

class StepRolloutEquipment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      equipment: [],
      isLoading: true,
      isFirstTime: 0,
    };
  }

  componentDidMount(): void {
    let dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
    if (this.props.sentinelType === SentinelType.ACTIVE_POWER_THRESHOLD) {
      this.loadEnergyEquipment();
    } else {
      this.onLoad();
    }
    if (dublicateSentinelkey) {
      if (this.props.selected.length > 0) {
        this.props.handleTargetSelect({
          targetId: this.props.selected[0],
          targetType: SentinelTargetType.EQUIPMENT,
        });
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    let dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
    if (dublicateSentinelkey) {
      if (this.props.selected.length > 0 && this.state.isFirstTime === 0) {
        this.setState({ isFirstTime: 1 });
        this.props.handleTargetSelect({
          targetId: this.props.selected[0],
          targetType: SentinelTargetType.EQUIPMENT,
        });
      }
    }

    const { equipmentTypeFilter } = this.props;
    if (
      equipmentTypeFilter &&
      prevProps.equipmentTypeFilter &&
      equipmentTypeFilter.map((et: EquipmentType) => et.id).join() !==
        prevProps.equipmentTypeFilter.map((et: EquipmentType) => et.id).join()
    ) {
      this.onLoad();
    }
  }

  loadEnergyEquipment = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site } = this.props;
      const equipment = await fetchEnergyEquipmentBySiteId(site.id);
      handleSetResourceRelation(
        site.id,
        equipment.map((e: Equipment) => e.id)
      );
      this.setState({
        equipment,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site, sensorTypes } = this.props;

      const equipment = await fetchEquipment({
        siteIds: [site.id],
        sensorTypes: sensorTypes.length === 0 ? undefined : sensorTypes,
      });

      handleSetResourceRelation(
        site.id,
        equipment.map((e: Equipment) => e.id)
      );

      this.setState({
        equipment,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  handleTargetSelectClick = (ev: any, item: Equipment) => {
    const { site } = this.props;
    ev.preventDefault();
    this.setState({
      isFirstTime: 1,
    });
    this.props.handleTargetSelect(
      {
        targetId: item.id,
        targetType: SentinelTargetType.EQUIPMENT,
      },
      site.id,
      item
    );
  };

  render() {
    const { equipment = [], isLoading } = this.state;
    const { site, selected, sentinelType } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const filteredEquipmentList = filterRolloutEquipmentBySentinelType(
      filterRolloutEquipmentByEquipmentType(
        equipment,
        this.props.equipmentTypeFilter || []
      ),
      sentinelType
    ).sort((a, b) => a.title.localeCompare(b.title));

    return (
      <div>
        <table className={styles.table}>
          <tbody>
            {filteredEquipmentList.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div>The site does not have the required equipment type.</div>
                </td>
              </tr>
            ) : (
              filteredEquipmentList.map((item: Equipment) => (
                <tr
                  key={`renderEquipmentRows${item.id}`}
                  onClick={ev => this.handleTargetSelectClick(ev, item)}
                  className={styles.filterRow}
                >
                  <td style={{ width: '27%' }}>
                    <EquipmentChip
                      id={item.type ? item.type.alias : ''}
                      title={item.title}
                    />
                  </td>
                  <td style={{ width: '42%' }}>
                    <ResourcePath
                      suppressLink
                      resourceId={item.id}
                      resourceType={ResourceType.SPACE}
                    />
                  </td>
                  <td className={styles.action} style={{ width: '21%' }}>
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={ev => this.handleTargetSelectClick(ev, item)}
                      id={`checkbox_${site.id}_${item.id}`}
                      size={16}
                    />
                  </td>
                  <td style={{ width: '10%' }} />
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export const filterRolloutEquipmentBySentinelType = (
  equipmentList: Equipment[],
  sentinelType: SentinelType | undefined
) => {
  if (sentinelType === SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK) {
    return equipmentList.filter(e => {
      const equipmentControl = e.activeControl as HvacControl | undefined;
      return (
        isDefined(equipmentControl?.type === 'HVAC') &&
        isDefined(equipmentControl?.thermostatId)
      );
    });
  }
  return equipmentList;
};

export const filterRolloutEquipmentByEquipmentType = (
  equipmentList: Equipment[],
  equipmentTypeFilter: EquipmentType[]
) => {
  if (equipmentTypeFilter.length === 0) {
    return equipmentList;
  }
  return equipmentList.filter((e: Equipment) => {
    return equipmentTypeFilter.some(
      (et: EquipmentType) => et.id === e.type?.id
    );
  });
};

export default StepRolloutEquipment;
