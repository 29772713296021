import styles from './HvacSopControlsCards.module.css';
import { Label } from '@energybox/react-ui-library/dist/components';
import {
  HvacControlLabel,
  SubscribedThermostat,
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  renderTimetablesRange,
} from '@energybox/react-ui-library/dist/utils';
import { TimetableScheduleType } from '@energybox/react-ui-library/dist/types/Controls';

type Props = {
  subscribedThermostat: SubscribedThermostat | undefined;
  is12HrFormat: boolean;
};

const EdmOverride: React.FC<Props> = ({
  subscribedThermostat,
  is12HrFormat,
}) => {
  const isEdmOverrideActive =
    isDefined(subscribedThermostat) &&
    TimetableScheduleType[subscribedThermostat.timetableActiveState!] ===
      TimetableScheduleType.EDM_OVERRIDE_ACTIVE;
  return (
    <>
      <Label>{HvacControlLabel.EDM_OVERRIDE}</Label>
      <div>
        <div className={styles.value}>
          {isEdmOverrideActive ? 'Active' : 'Inactive'}
        </div>
        {isEdmOverrideActive && (
          <div className={styles.edmSchedule} data-testid="time-range">
            {renderTimetablesRange(
              subscribedThermostat?.timetableActiveStateStartTime,
              subscribedThermostat?.timetableActiveStateEndTime,
              is12HrFormat
            )}
          </div>
        )}
      </div>
      <div className={styles.dividerLine} />
      <div className={styles.dividerLine} />
    </>
  );
};

export default EdmOverride;
