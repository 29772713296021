import {
  ApiError,
  EnergyPro,
  EnergySource,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import { EditableFields } from '../../../reducers/energy_pros';
import styles from './EditEnergyProModal.module.css';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
} from '@energybox/react-ui-library/dist/components';
import EditEnergyProForm from '../../../components/EditEnergyProForm';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';

import {
  displayFormErrors,
  Actions as EnergyProActions,
  hideEditEnergyProModal,
  patch,
  reset,
  updateField,
} from '../../../actions/energy_pros';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import { useEffect, useState } from 'react';
import { usePrevious } from '@energybox/react-ui-library/dist/hooks';

interface OwnProps {
  id: number;
  energyPro: EnergyPro;
  siteId: string | number;
  distributionPanelEnergySource: EnergySource;
}

interface Props extends OwnProps {
  onChange: (field: string, value: string | number) => void;
  onClose: () => void;
  patch: (id: string) => void;
  reset: (id: string) => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  formErrorsVisible: boolean;
  displayFormErrors: () => void;
  apiError: ApiError;
}

const EditEnergyProModal = ({
  id,
  energyPro,
  siteId,
  distributionPanelEnergySource,
  onChange,
  onClose,
  patch,
  reset,
  fields,
  isLoading,
  formErrors,
  isVisible,
  formErrorsVisible,
  displayFormErrors,
  apiError,
}: Props) => {
  const prevIsLoading = usePrevious(isLoading);
  const [isChanged, setIsChanged] = useState(false);

  const onEnergyProSave = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors();
    } else {
      patch(String(id));
    }
  };

  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      window.location.reload();
    }
  }, [isLoading]);

  const onCancel = () => {
    onClose();
    reset(String(id));
    setIsChanged(false);
  };

  const actions = (
    <>
      <Button variant="text" onClick={onCancel}>
        Cancel
      </Button>

      <Button disabled={!isChanged || isLoading} onClick={onEnergyProSave}>
        Save
      </Button>
    </>
  );

  if (!isVisible) return null;
  return (
    <div className={styles.modal}>
      <div className={styles.arrow} />
      <Card>
        <CardContent>
          <CardTitle className={styles.title}>{energyPro.title}</CardTitle>
          <EditEnergyProForm
            siteId={siteId}
            fields={fields}
            onChange={(field: string, value: any) => {
              onChange(field, value);
              if (!isChanged) setIsChanged(true);
            }}
            formErrorsVisible={formErrorsVisible}
            formErrors={formErrors}
            distributionPanelEnergySource={distributionPanelEnergySource}
            firmwareVersion={energyPro.firmwareVersion}
          />
          {renderAPIerror(apiError, EnergyProActions.PATCH_ENERGY_PRO_ERROR)}
        </CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = (
  { energyPros }: ApplicationState,
  { id }: OwnProps
) => {
  return {
    isVisible: energyPros.showEditEnergyProModal,
    ...energyPros.editById[String(id)],
  };
};

const mapDispatchToProps = (dispatch: any, { id: id }: OwnProps) => ({
  patch: (id: string) => dispatch(patch(id)),
  reset: (id: string) => dispatch(reset(id)),
  onChange: (field: string, value: string | number) =>
    dispatch(updateField(String(id), field, value)),
  onClose: () => dispatch(hideEditEnergyProModal()),
  displayFormErrors: () => dispatch(displayFormErrors(String(id))),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEnergyProModal);
