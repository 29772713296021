import {
  Button,
  LongExtraLongSkeletonCell,
  MediumLongSkeletonCell,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  OpacityIndex,
  Region,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  hasSubstr,
  SORT_IGNORED_VALUES,
  values,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { Actions, getGroups, showNewModal } from '../../actions/user_groups';
import AccessResourceRecord from '../../components/AccessResourceRecord';
import BubblesList from '../../components/BubblesList';
import UsersManagementLayout from '../../components/UsersManagementLayout';
import { ApplicationState } from '../../reducers';
import { UserGroup } from '../../reducers/user_groups';
import { CreateNewText, Placeholder } from '../../types/global';
import { checkCommonPlural, fullName, initials } from '../../util';
import NewGroupModal from '../Modals/NewGroupModal';
import history from '../../history';

import theme from '../../theme';
import { TableWrapper } from '../../components/ui/Table';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';
import { aggregateAccessForUserGroup } from '../../utils/user';
import { UsersById } from '../../reducers/users';
import { getUsers } from '../../actions/users';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import FiltersContainer from '../../components/Filters/FiltersContainer/FiltersContainer';
import RegionFilter from './RegionFilter';

interface Props {
  getGroups: typeof getGroups;
  showNewModal: typeof showNewModal;
  getUsers: typeof getUsers;
  userGroups: UserGroup[];
  getGroupsIsLoading: boolean | undefined;
  usersById: UsersById;
  viewportType: ViewportTypes;
}

interface State {
  query: string;
  groupsLoadingGuard: boolean;
  regionFilter: Region[];
  pageNumber: number;
}

class InstallerGroupsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: '',
      groupsLoadingGuard: true,
      pageNumber: 1,
      regionFilter: [],
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.usersById).length === 0) this.props.getUsers();

    setTimeout(() => {
      this.setState({ groupsLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams<string>(history, 'query', '');
    const savedPage = getUrlStateParams<number>(history, 'page', 1);

    this.setState({ query: savedQuery });
    this.setState({ pageNumber: savedPage });

    this.props.getGroups();
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  pagination = (pageCount: number) => {
    this.setState({ pageNumber: pageCount }, () => {
      const { pageNumber: pageFilter } = this.state;
      updateUrlState(history, 'page', pageFilter);
    });
  };

  setRegionFilter = (value: Region[]) => this.setState({ regionFilter: value });

  render() {
    const {
      userGroups,
      getGroupsIsLoading,
      usersById,
      viewportType,
    } = this.props;
    const { query, groupsLoadingGuard, regionFilter } = this.state;

    const isMobile = viewportType === ViewportTypes.MOBILE;
    const isLoading = groupsLoadingGuard || getGroupsIsLoading;

    const filteredGroups = userGroups
      .filter(group => query.length < 3 || hasSubstr(group.title, query))
      .filter(
        ({ region }) =>
          regionFilter.length === 0 || regionFilter.includes(region)
      );

    const actions = (
      <>
        <Button onClick={this.props.showNewModal}>
          {CreateNewText.INSTALLER_GROUP}
        </Button>
      </>
    );

    const tableColumns: Columns<UserGroup>[] = [
      {
        header: 'Installer Group',
        width: '40%',
        cellContent: ({ id, title }: UserGroup) => (
          <Link to={`${Routes.USER_GROUPS}/${id}`}>{title}</Link>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: UserGroup,
          b: UserGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Users',
        width: '25%',
        defaultSortDirection: SortDirection.DESC,
        cellContent: ({ id, users }: UserGroup) => (
          <div>
            <BubblesList
              limit={6}
              resource="user"
              moreButtonLinkTo={`/user-groups/${id}`}
              items={(users || []).map(user => ({
                title: fullName(user),
                displayTitle: initials(user),
                url: `/users/${user.id}`,
              }))}
            />
          </div>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: UserGroup,
          b: UserGroup,
          sortDirection: SortDirection
        ) => {
          const numberOfUsers = (u: UserGroup) => u.users.length;
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            numberOfUsers
          );
        },
      },
      {
        header: 'Access to Organizations',
        width: '25%',
        cellContent: (userGroup: UserGroup) => (
          <>
            {aggregateAccessForUserGroup(userGroup, usersById).map(resource => (
              <AccessResourceRecord key={resource.id} {...resource} />
            ))}
          </>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        header: 'EB Region',
        width: '10%',
        cellContent: ({ region }: UserGroup) => region,
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
      },
    ];

    return (
      <UsersManagementLayout actions={actions} showInstallers>
        <DynamicContentWrapper>
          <PageContentHeader
            header={
              <FiltersContainer>
                <RegionFilter
                  onChange={this.setRegionFilter}
                  selection={regionFilter}
                />
              </FiltersContainer>
            }
          >
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredGroups.length === 0}
            />
          </PageContentHeader>
          <TableWrapper
            header={checkCommonPlural('Installer Group', filteredGroups.length)}
            isLoading={isLoading}
          >
            <Table
              listView
              columns={tableColumns}
              data={filteredGroups || []}
              dataIsLoading={isLoading}
              pagination={this.pagination}
              pageNumber={filteredGroups.length > 5 ? this.state.pageNumber : 1}
            />
          </TableWrapper>
        </DynamicContentWrapper>
        <NewGroupModal type="installer" />
      </UsersManagementLayout>
    );
  }
}

const mapStateToProps = ({ userGroups, users }: ApplicationState) => ({
  userGroups: values(userGroups.userGroupsById),
  getGroupsIsLoading:
    userGroups.loadingStatusByAction[Actions.GET_LIST_LOADING],
  usersById: users.usersById,
});

const mapDispatchToProps = {
  getGroups,
  showNewModal: () => showNewModal('installerGroup'),
  getUsers,
};

export default withViewportType(
  connect(mapStateToProps, mapDispatchToProps)(InstallerGroupsPage)
);
