import {
  Label,
  MenuDropdown,
  MenuDropdownItem,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import { useSubscribeToControlBoard } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import {
  HvacHeater as IconHeating,
  RefrigerationWalkInFreezer as IconCooling,
} from '@energybox/react-ui-library/dist/icons';
import {
  ControlBoard,
  ControlsType,
  ControlTypesToDisplayTextsMapping,
  GatewayStates,
  SensorOnlineState,
  SensorType,
  TemperatureControl,
  TemperatureControlLabel,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  capitalizeFirstLetterOnly,
  classNames,
  createTemperatureString,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  subscribeToDeviceReadings as subscribeToDeviceReadingsAction,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings as unsubscribeFromDeviceReadingsAction,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { useSetIsLocalOverrideActive } from '../../hooks/controls/util';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { useSelectControlBoardByEquipmentId } from '../../hooks/useControlBoard';
import { ApplicationState } from '../../reducers';
import { App } from '../../reducers/app';
import { SensorReading, Sensors } from '../../reducers/sensors';
import { renderLocalOverrideText } from '../../utils/controls';
import { getSensorRoute } from '../../utils/sensor';
import { getTimetablesRoute } from '../../utils/timeTables';
import DeviceOnlineState, {
  DisplayType,
} from '../DeviceStatus/DeviceOnlineState';
import styles from './GenericControlsCard/GenericControlsCard.module.css';

type Props = {
  siteId: number;
  temperatureControl: TemperatureControl;
  showEditControlModal: () => void;
  showDeleteControlModal: () => void;
  setIsLocalOverrideActive: React.Dispatch<React.SetStateAction<boolean>>;
};

type ControlFieldProps = {
  temperatureControl: TemperatureControl;
  siteId?: number;
};

interface LocalOverrideProps extends ControlFieldProps {
  setIsLocalOverrideActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const TemperatureControlsContent = ({
  siteId,
  temperatureControl,
  showEditControlModal,
  showDeleteControlModal,
  setIsLocalOverrideActive,
}: Props) => {
  const isControlInCalibrationMode =
    temperatureControl.workingMode === WorkingMode.CALIBRATION;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>{`Controls: ${
          ControlTypesToDisplayTextsMapping[
            capitalizeFirstLetterOnly(temperatureControl.type)
          ]
        }`}</div>
        <div>
          <MenuDropdown>
            <MenuDropdownItem onSelect={showEditControlModal}>
              Edit Controls
            </MenuDropdownItem>
            <MenuDropdownItem isRed onSelect={showDeleteControlModal}>
              Delete Controls
            </MenuDropdownItem>
          </MenuDropdown>
        </div>
      </div>

      <hr className={styles.divider} />

      <div className={styles.contentContainer}>
        <div className={styles.icon}>
          {capitalizeFirstLetterOnly(temperatureControl.type) ===
            ControlsType.HEATING && <IconHeating size={80} />}
          {capitalizeFirstLetterOnly(temperatureControl.type) ===
            ControlsType.COOLING && <IconCooling size={80} />}
        </div>

        <div className={styles.columnContainer}>
          <div
            className={classNames(
              styles.column,
              isControlInCalibrationMode ? styles.greyText : ''
            )}
          >
            <Label>
              <span className={styles.boldText}>Settings Overview</span>
            </Label>
            <div />

            <TemperatureSensorLink temperatureControl={temperatureControl} />
            <SetPoint temperatureControl={temperatureControl} />
            <OutsideSetPoint temperatureControl={temperatureControl} />
            <TimetableLink
              temperatureControl={temperatureControl}
              siteId={siteId}
            />
            <Offset temperatureControl={temperatureControl} />
          </div>
        </div>

        <div className={styles.columnContainer}>
          <div className={styles.column}>
            <Label>
              <span className={styles.boldText}>Live Status</span>
            </Label>
            <div />

            <LiveTemperatureReading temperatureControl={temperatureControl} />
            <LocalOverride
              temperatureControl={temperatureControl}
              setIsLocalOverrideActive={setIsLocalOverrideActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SetPoint = ({ temperatureControl }: ControlFieldProps) => {
  const user = useCurrentUser();
  return (
    <>
      <Label>{TemperatureControlLabel.SET_POINT}</Label>
      <div className={styles.value}>
        {user && createTemperatureString(temperatureControl.setPoint, user)}
      </div>
    </>
  );
};

const OutsideSetPoint = ({ temperatureControl }: ControlFieldProps) => {
  const { outsideSetPoint } = temperatureControl;
  const user = useCurrentUser();

  return (
    <>
      <Label>{TemperatureControlLabel.OUTSIDE_SET_POINT}</Label>
      <div className={styles.value}>
        {outsideSetPoint !== null && outsideSetPoint !== undefined && user
          ? createTemperatureString(outsideSetPoint, user)
          : global.NOT_AVAILABLE}
      </div>
    </>
  );
};

const TemperatureSensorLink = ({ temperatureControl }: ControlFieldProps) => {
  return (
    <>
      <Label>{TemperatureControlLabel.TEMP_SENSOR}</Label>
      <div className={styles.value}>
        {temperatureControl.sensor ? (
          <Link to={getSensorRoute(temperatureControl.sensor.id)}>
            {temperatureControl.sensor.title}
          </Link>
        ) : (
          global.NOT_AVAILABLE
        )}
      </div>
    </>
  );
};

const LiveTemperatureReading = ({ temperatureControl }: ControlFieldProps) => {
  const user = useCurrentUser();

  const dispatch = useDispatch();

  const controlBoardForEquipment:
    | ControlBoard
    | undefined = useSelectControlBoardByEquipmentId(
    temperatureControl.equipmentId
  );

  let sensorReadingText: string = '';
  const isSensorOnline: boolean =
    !!temperatureControl.sensor &&
    temperatureControl.sensor.sensorStatus !== null &&
    temperatureControl.sensor.sensorStatus !== undefined &&
    temperatureControl.sensor.sensorStatus.state === SensorOnlineState.ACTIVE;

  const subscribeToDeviceReadings = useCallback(
    (vendor: string, uuid: string, id: string | number) =>
      dispatch(subscribeToDeviceReadingsAction(vendor, uuid, id)),
    [dispatch]
  );
  const unsubscribeFromDeviceReadings = useCallback(
    (vendor: string, uuid: string, id: string | number) =>
      dispatch(unsubscribeFromDeviceReadingsAction(vendor, uuid, id)),
    [dispatch]
  );

  const streamConnected = useSelector(
    (state: { app: App }) => state.app.streamConnected
  );
  const sensorTempReading: SensorReading | undefined = useSelector(
    (state: { sensors: Sensors }) => {
      if (
        isSensorOnline &&
        temperatureControl.sensor !== undefined &&
        temperatureControl.sensor !== null
      ) {
        const { id } = temperatureControl.sensor;
        return pathOr(
          undefined,
          [
            'sensorReadingsById',
            id,
            SensorType.TEMPERATURE,
            // SensorType.TEMPERATURE.toLocaleLowerCase(),
          ],
          state.sensors
        );
      } else {
        return undefined;
      }
    }
  );

  let tempValue: undefined | number, tempTimestamp: undefined | string;

  if (isDefined(sensorTempReading)) {
    tempValue = sensorTempReading.temperature;
    tempTimestamp = sensorTempReading.timestamp;
  }

  if (tempValue !== undefined && user) {
    sensorReadingText = createTemperatureString(tempValue, user);
  }

  useEffect(() => {
    let unsubscribe: (() => void) | null = null;
    if (isSensorOnline && temperatureControl.sensor && streamConnected) {
      const { vendor, uuid, id } = temperatureControl.sensor;
      subscribeToDeviceReadings(vendor, uuid, id);
      unsubscribe = () => unsubscribeFromDeviceReadings(vendor, uuid, id);
    }
    return () => {
      if (unsubscribe !== null) unsubscribe();
    };
  }, [
    isSensorOnline,
    temperatureControl,
    streamConnected,
    subscribeToDeviceReadings,
    unsubscribeFromDeviceReadings,
  ]);

  return (
    <>
      <>
        <Label>{TemperatureControlLabel.ONLINE_STATUS}</Label>
        <div className={styles.value}>
          {isDefined(controlBoardForEquipment) ? (
            <DeviceOnlineState
              displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
              devices={[
                {
                  id: controlBoardForEquipment.id,
                  uuid: controlBoardForEquipment.uuid,
                  vendor: controlBoardForEquipment.vendor,
                },
              ]}
              additionalOnlineState={isSensorOnline}
            />
          ) : (
            global.NOT_AVAILABLE
          )}
        </div>
      </>

      <div className={styles.dividerLine} />
      <span className={styles.streamTimestampText}>
        Updated {<TimeDistance timestamp={tempTimestamp} />}
      </span>

      <Label>{TemperatureControlLabel.LIVE_TEMPERATURE}</Label>
      <div className={styles.value}>
        {isSensorOnline && sensorTempReading !== undefined
          ? sensorReadingText
          : global.NOT_AVAILABLE}
      </div>
    </>
  );
};

const TimetableLink = ({ temperatureControl, siteId }: ControlFieldProps) => {
  if (siteId === undefined) return null;
  return (
    <>
      <Label>{TemperatureControlLabel.TIMETABLE}</Label>
      <div className={styles.value}>
        {temperatureControl && temperatureControl.timetable ? (
          <Link
            to={getTimetablesRoute({
              orgUnitId: temperatureControl.timetable.organizationUnitId,
              timetableId: temperatureControl.timetable.id,
              isOrgLevelTimetable:
                temperatureControl.timetable.organizationUnitId !== siteId,
            })}
            data-testid="timetableLink"
          >
            {temperatureControl.timetable.title}
          </Link>
        ) : (
          global.NOT_AVAILABLE
        )}
      </div>
    </>
  );
};

const Offset = ({ temperatureControl }: ControlFieldProps) => {
  return (
    <>
      <Label>{TemperatureControlLabel.BIAS_START}</Label>
      <div className={styles.value}>
        {(temperatureControl && temperatureControl.beginDelta) ||
          global.NOT_AVAILABLE}
      </div>

      <Label>{TemperatureControlLabel.BIAS_END}</Label>
      <div className={styles.value}>
        {(temperatureControl && temperatureControl.endDelta) ||
          global.NOT_AVAILABLE}
      </div>
    </>
  );
};

const LocalOverride = ({
  temperatureControl,
  setIsLocalOverrideActive,
}: LocalOverrideProps) => {
  const equipmentId = temperatureControl.equipmentId;

  //this works b/c we fetch actuatorsByEquipmentId in a parent component in this page
  const controlBoard = useSelectControlBoardByEquipmentId(equipmentId);

  const dispatch = useDispatch();
  const subscribedControlBoardById = useSelector(
    ({ subscribedControlBoards }: ApplicationState) => {
      if (controlBoard) {
        return subscribedControlBoards.byId[controlBoard.id];
      }
      return undefined;
    }
  );
  const subscribedControlBoard = useSubscribeToControlBoard(
    controlBoard,
    () => subscribedControlBoardById,
    dispatch,
    subscribeToDeviceStatus,
    unsubscribeFromDeviceStatus
  );

  const isLocalOverrideActive = useMemo(() => {
    return subscribedControlBoard?.status?.gatewayStates?.includes(
      GatewayStates.CONTROL_OVERRIDE
    );
  }, [subscribedControlBoard]);

  useSetIsLocalOverrideActive(isLocalOverrideActive, setIsLocalOverrideActive);

  return (
    <>
      <Label>{TemperatureControlLabel.LOCAL_OVERRIDE}</Label>
      <div className={styles.value}>
        {renderLocalOverrideText(isLocalOverrideActive)}
      </div>
    </>
  );
};

export default TemperatureControlsContent;
