import {
  ModalContent,
  ModalTitle,
  Button,
  FormText,
  InputField,
  Label,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { Actions } from '../../../actions/users';
import ModalFormContent from '../../../components/ModalFormContent';
import { CreateNewText } from '../../../types/global';
import { ApiError, renderAPIerror } from '../../../utils/apiErrorFeedback';

interface Props {
  apiError: ApiError;
  onChange: (field: string, value: any) => void;
  onSubmit: () => void;
  onClose: () => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  title: string;
  description: string;
}

const UserApiNewTokenModal = ({
  onChange,
  onSubmit,
  onClose,
  title,
  apiError,
  description,
  formErrors,
  formErrorsVisible,
}: Props) => {
  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={onSubmit}>Add</Button>
    </>
  );

  return (
    <Modal actions={actions} disableEscapeClose={true}>
      <ModalTitle>{CreateNewText.API_KEY}</ModalTitle>
      <ModalContent>
        <ModalFormContent>
          <div>
            <Label required>Name</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="title"
              autoComplete="title"
              value={title}
              onChange={e => onChange('title', e.currentTarget.value)}
              error={formErrorsVisible && !!formErrors.title}
              customErrorText={formErrors.title && formErrors.title[0]}
            />
          </div>

          <div>
            <Label>Description</Label>
          </div>
          <div>
            <InputField
              type="text"
              name="description"
              value={description}
              autoComplete="description"
              onChange={e => onChange('description', e.currentTarget.value)}
            />
          </div>
        </ModalFormContent>

        {renderAPIerror(apiError, Actions.ADD_API_KEY_TO_USER_ERROR)}

        <ModalFormContent>
          <FormText>* Mandatory fields</FormText>
        </ModalFormContent>
      </ModalContent>
    </Modal>
  );
};

export default UserApiNewTokenModal;
