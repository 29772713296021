import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  OrgSopTypes,
  SopCostTypes,
  SopPolicyTypes,
  SopTypes,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { SopTypeMapping } from '../../types/sop';

interface Props {
  onSelect: (sopType: SopTypes) => void;
  value?: string;
  isOrgLevel?: boolean;
  disabled?: boolean;
  error: boolean;
  customErrorText?: string;
  sopTypeCategory?: string;
}

type Sop = {
  name: string;
  value: SopTypes;
};

interface State {
  types: Sop[];
}

class SelectSopComponentType extends React.PureComponent<Props, State> {
  getSelectableTypes() {
    const { sopTypeCategory, isOrgLevel } = this.props;
    let filterByType:
      | typeof SopTypes
      | typeof SopCostTypes
      | typeof SopPolicyTypes;

    if (sopTypeCategory == 'cost') {
      filterByType = SopCostTypes;
    } else if (sopTypeCategory == 'policy') {
      filterByType = SopPolicyTypes;
    } else {
      filterByType = SopTypes;
    }

    return (Object.keys(SopTypeMapping) as SopTypes[])
      .filter(key => Object.values(filterByType).includes(key))
      .filter(key =>
        isOrgLevel
          ? Object.values(OrgSopTypes).includes((key as unknown) as OrgSopTypes)
          : true
      )
      .map(key => {
        return {
          name: SopTypeMapping[key].title,
          value: key,
        };
      });
  }

  render() {
    const { onSelect, value, error, customErrorText, disabled } = this.props;

    const types = this.getSelectableTypes();

    return (
      <Select
        disabled={disabled}
        title={value && SopTypeMapping[value].title}
        variant={'select'}
        error={error}
        customErrorText={customErrorText}
      >
        {types.map((type: Sop) => (
          <SelectItem
            key={type.name}
            isSelected={type.value === value}
            onSelect={() => {
              onSelect(type.value);
            }}
          >
            {type.name}
          </SelectItem>
        ))}
      </Select>
    );
  }
}

export default SelectSopComponentType;
