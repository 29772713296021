import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlsType,
  HvacControl,
} from '@energybox/react-ui-library/dist/types';
import equals from 'ramda/src/equals';
import pick from 'ramda/src/pick';

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as HvacControlsActions,
  patchHvacControl,
} from '../../actions/hvacControls';
import EditControlsHvacForm from '../../components/EditControlsHvacForm';
import { useTemperatureUnit } from '../../hooks/utils';
import { ApplicationState } from '../../reducers';
import { EditHvacSOPControl } from '../../types/hvacControl';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import {
  areThereFormErrors,
  validateHvacControlForm,
} from '../../utils/formValidation';
import styles from './EditHvacControlsModal.module.css';

type Props = {
  siteId: number;
  hvacControls: HvacControl;
  onClose: () => void;
  equipmentTitle: string;
};

const EditHvacControlsModal: React.FC<Props> = ({
  siteId,
  hvacControls,
  onClose,
  equipmentTitle,
}) => {
  //** useState **//
  const [numberOfTimesClickedOnSave, setNumberOfTimesClickedOnSave] = useState(
    0
  );

  const initialHvacControlFields = editableFields(hvacControls);
  const [editHvacControlFields, setEditHvacControlFields] = useState<
    EditHvacSOPControl
  >(initialHvacControlFields);
  const [hvacFormErrorsVisible, setHvacFormErrorsVisible] = useState(false);

  //** useSelector **//
  const hvacApiError = useSelector(({ hvacControls }: ApplicationState) => {
    return hvacControls.apiError;
  });

  //** Actions **//
  const dispatch = useDispatch();
  const patch = useCallback(
    (fields: EditHvacSOPControl) => {
      dispatch(patchHvacControl(hvacControls.id, fields));
    },
    [dispatch, hvacControls.id]
  );

  //**************//
  const temperatureUnit = useTemperatureUnit();

  const ThermostatFormErrors = validateHvacControlForm(
    editHvacControlFields,
    temperatureUnit
  );
  const isChanged = !equals(initialHvacControlFields, editHvacControlFields);

  const onHvacControlFieldChange = (
    field: string,
    value: string | number | boolean | null
  ) => {
    setEditHvacControlFields(prevEditHvacControlFields => ({
      ...prevEditHvacControlFields,
      [field]: value,
    }));
  };

  const onSave = () => {
    if (areThereFormErrors(ThermostatFormErrors)) {
      setHvacFormErrorsVisible(true);
    } else {
      patch(editHvacControlFields);
    }

    setNumberOfTimesClickedOnSave((prevNum: number) => {
      return prevNum + 1;
    });
  };

  const actions = (
    <div>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={onSave} disabled={!isChanged}>
        Save
      </Button>
    </div>
  );

  return (
    <Modal onClose={onClose} actions={actions} disableEscapeClose>
      <div className={styles.modal}>
        <ModalTitle>
          <div>{`Edit ${ControlsType.HVAC}`}</div>
          <div className={styles.equipmentTitle}>{equipmentTitle}</div>
        </ModalTitle>

        <ModalContent>
          <EditControlsHvacForm
            siteId={siteId}
            fields={editHvacControlFields}
            formErrors={ThermostatFormErrors}
            formErrorsVisible={hvacFormErrorsVisible}
            onChange={onHvacControlFieldChange}
            numberOfTimesClickedOnSave={numberOfTimesClickedOnSave}
          />
        </ModalContent>

        {renderAPIerror(
          hvacApiError,
          HvacControlsActions.PATCH_HVAC_CONTROL_ERROR
        )}
      </div>
    </Modal>
  );
};

export default EditHvacControlsModal;

export const editableFields = (hvacControls: HvacControl) => {
  return pick(
    [
      'outsideOfTimeTable',
      'equipmentId',
      'workingMode',
      'thermostatId',
      'maxTemp',
      'minTemp',
      'thermostatWorkingMode',
      'fanMode',
      'thermostatDisplayUnits',
      'timetableId',
      'beginDelta',
      'endDelta',
      'outsideMinTemp',
      'outsideMaxTemp',
      'outsideFanMode',
      'enableLocalAdjustment',
      'setPointLimitDelta',
      'overrideTimer',
    ],
    hvacControls
  );
};
