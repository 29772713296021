import {
  FilterDropdown,
  FilterDropdownTextItem,
  PageSelectControl,
} from '@energybox/react-ui-library/dist/components';
import styles from './TopPagination.module.css';
import { getCustomPageRowsNumbers } from '@energybox/react-ui-library/dist/utils/pagination';
import React from 'react';

type Props = {
  data: any;
  setPagination: (newPage: any, newRowLimit: any) => void;
  rowLimit: number;
  currentPage: number;
  numberOfSites: number;
};
const TopPagination: React.FC<Props> = ({
  data,
  currentPage,
  rowLimit,
  setPagination,
  numberOfSites,
}) => {
  const pageRows = getCustomPageRowsNumbers(data.length);

  const filterDropdownTextItems = pageRows.map(number => (
    <FilterDropdownTextItem
      key={`pageRows${number}`}
      title={number}
      onClick={() => setPagination(1, number)}
      className={styles.rowLimitDropdown}
      closeOnClick
    />
  ));

  return (
    <div className={styles.pageSelectWrapper}>
      <div className={styles.cardHeader}>{numberOfSites} Sites</div>
      <div className={styles.alignPageSelectionEl}>
        <>
          Show Rows
          <div>
            <FilterDropdown
              title={rowLimit}
              variant="select"
              size="tiny"
              className={styles.rowLimitDropdown}
            >
              {filterDropdownTextItems}
            </FilterDropdown>
          </div>
        </>
        {data.length > 0 && (
          <PageSelectControl
            hidePageNumbers={true}
            pageCount={Math.ceil(data.length / rowLimit)}
            currentPage={currentPage}
            setCurrentPage={currentPage => {
              setPagination(currentPage, rowLimit);
            }}
          ></PageSelectControl>
        )}
      </div>
    </div>
  );
};

export default TopPagination;
