import {
  Card,
  CardContent,
  CardTitle,
  ProgressBar,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import {
  Actuator,
  ActuatorCircuitStatus,
} from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postTestResults,
  relaySwitchInstallerTest,
  unmappedRelayInstallerTest,
} from '../../../actions/control_boards';
import {
  getControlTestButtonReport,
  startControlTestButtonReport,
} from '../../../actions/inspection';
import {
  disableRunTestButton,
  enableRunTestButton,
} from '../../../actions/run_configuration';
import { ApplicationState } from '../../../reducers';
import { RunTestData } from '../../../reducers/inspection';
import { ConfigurationResultData } from './ConfigurationResultData';
import styles from './EquipmentConfigurationTestResults.module.css';
import { getActuatorCircuitStatus } from './StartSequenceModal';

type Props = {
  resourceId: number;
  siteId: string;
  actuatorsPerEquipment: Actuator[];
  isUnmappedRelays?: boolean;
  isRunTest: boolean;
  setIsRunTest: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RunConfigurationTestResults: React.FC<Props> = ({
  resourceId,
  siteId,
  actuatorsPerEquipment,
  isUnmappedRelays = false,
  isRunTest,
  setIsRunTest,
}) => {
  const dispatch = useDispatch();
  const [onState, setOnState] = useState(true);
  const [timestamp1, setTimestamp1] = useState('');
  const [timestamp2, setTimestamp2] = useState('');
  const [progress, setProgress] = useState(0);
  const [prevReportKey, setPrevReportKey] = useState(0);
  const [daResultsExist, setDaResultsExist] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');
  const [currentResultsShouldSend, setCurrentResultsShouldSend] = useState(
    false
  );

  const checkActuators = actuatorsPerEquipment?.filter(actuator => {
    const status = getActuatorCircuitStatus(actuator);
    if (status?.props.children === ActuatorCircuitStatus.OFF) {
      return actuator;
    }
  });

  useEffect(() => {
    if (isRunTest) {
      setProgress(0);
      setShowLoader(true);
      setDaResultsExist(false);
    } else if (!panelData?.length) {
      setError(
        'Sorry! We could not display the results. Please run the test again'
      );
    }
  }, [isRunTest]);

  useEffect(() => {
    let timeout;
    const dataExistCheck =
      finalData && +finalData[0] === resourceId && finalData[1].length < 3;
    if (dataExistCheck || isRunTest) {
      setPrevReportKey(reportKey);
      dispatch(enableRunTestButton());
      if (onState) {
        if (!isUnmappedRelays)
          dispatch(relaySwitchInstallerTest(resourceId, 'TURN_OFF'));
        else dispatch(unmappedRelayInstallerTest(resourceId, 'TURN_OFF'));
        const date = new Date();
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        setTimestamp1(String(unixTimestamp));
        timeout = setTimeout(() => {
          setOnState(false);
        }, 50000);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [resourceId, isRunTest]);

  useEffect(() => {
    let timeout;
    if (!onState) {
      if (checkActuators?.length === actuatorsPerEquipment.length) {
        if (!isUnmappedRelays)
          dispatch(relaySwitchInstallerTest(resourceId, 'TURN_ON'));
        else dispatch(unmappedRelayInstallerTest(resourceId, 'TURN_ON'));
        const date = new Date().getTime();
        const unixTimestamp = Math.floor(date / 1000);
        setTimestamp2(String(unixTimestamp));
      }
      timeout = setTimeout(() => {
        setOnState(true);
      }, 60000);
    }

    if (onState && timestamp1 && timestamp2) {
      dispatch(
        startControlTestButtonReport(
          siteId,
          isUnmappedRelays ? null : resourceId,
          timestamp1,
          timestamp2
        )
      );
      setCurrentResultsShouldSend(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [onState]);

  const testResults = useSelector(({ controlBoards }: ApplicationState) => {
    return controlBoards.testResults.results;
  });
  const results = testResults && Object.entries(testResults);
  const finalData = results?.find(([id, object]) => {
    if (+id === resourceId) {
      return object;
    }
  });
  const finalDataObject = finalData?.[1]!;
  const parsedData = finalDataObject && JSON.parse(finalDataObject);
  const reportKey = useSelector(({ inspection }: ApplicationState) => {
    return inspection.loadingTestButtonReport[siteId];
  });
  const daResults = useSelector(({ inspection }: ApplicationState) => {
    return inspection.testButtonReport[siteId];
  });
  const reportData: RunTestData = parsedData?.data;

  useEffect(() => {
    let interval;
    if (
      reportKey &&
      daResults?.report_version !== 1 &&
      timestamp1 &&
      timestamp2 &&
      !error
    ) {
      interval = setInterval(() => {
        dispatch(getControlTestButtonReport(siteId, reportKey));
        setDaResultsExist(true);
      }, 10000);
    }

    if (
      prevReportKey &&
      reportKey !== prevReportKey &&
      onState &&
      timestamp1 &&
      timestamp2
    ) {
      setPrevReportKey(reportKey);
      interval = setInterval(() => {
        dispatch(getControlTestButtonReport(siteId, reportKey));
        setDaResultsExist(true);
      }, 10000);
    }

    if (
      reportKey &&
      daResults?.report_version === 1 &&
      onState &&
      daResultsExist &&
      currentResultsShouldSend
    ) {
      setDaResultsExist(false);
      dispatch(disableRunTestButton());
      dispatch(postTestResults(resourceId, daResults));
      setShowLoader(false);
      setCurrentResultsShouldSend(false);
    }

    return () => {
      clearInterval(interval);
    };
  }, [reportKey, daResults]);

  useEffect(() => {
    let timer;
    if (isRunTest) {
      timer = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress === 100) {
            if (panelData?.length) {
              setError('');
            } else {
              setError(
                'Sorry! We could not display the results. Please run the test again'
              );
            }
            dispatch(disableRunTestButton());
            setIsRunTest(false);
            setShowLoader(false);
            clearInterval(timer);
            return prevProgress;
          }
          const diff = Math.random() * 10;
          return Math.min(prevProgress + diff, 100);
        });
      }, 7000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isRunTest]);

  const energyProId =
    reportData?.by_energy_pro && Object.keys(reportData?.by_energy_pro);
  const panelData =
    reportData?.by_energy_pro && Object.values(reportData?.by_energy_pro);

  const cardTitle = isUnmappedRelays
    ? 'Unmapped Relays Configuration Test Results'
    : 'Equipment Configuration Test Results';

  return (
    <>
      <Card className={styles.table}>
        <CardTitle className={styles.cardTitle}>
          {cardTitle}
          <span className={styles.timestamp}>
            <TimeDistance
              timestamp={
                parsedData?.report_date && `${parsedData?.report_date}Z`
              }
            />
          </span>
        </CardTitle>
        <CardContent className={styles.card}>
          {panelData &&
            !showLoader &&
            panelData?.map(({ energy_pro_title, by_bus_device }) => {
              const equipmentConfigurationData =
                by_bus_device && Object.values(by_bus_device);
              return (
                <>
                  <ConfigurationResultData
                    energyProId={+energyProId[0]}
                    energyProTitle={energy_pro_title}
                    busDevice={equipmentConfigurationData}
                    siteId={Number(siteId)}
                    isUnmappedRelays={isUnmappedRelays}
                  />
                </>
              );
            })}
          {showLoader || (!panelData?.length && progress < 100 && !error) ? (
            <>
              <div className={styles.loadingBar}>
                <ProgressBar perceivedProgress={progress} />
                <span>{Math.round(progress)}% done</span>
              </div>
            </>
          ) : (
            <>
              {!panelData?.length && error && (
                <div className={styles.errorMessage}>
                  <span>
                    <WarningIcon width="18" height="18" />
                  </span>
                  <span> {error}</span>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default RunConfigurationTestResults;
