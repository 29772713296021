import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import useDynamicFilter from '../../hooks/useDynamicFilter';

export enum EdmLabel {
  EDM_ENABLED = 'EDM Enabled',
  EDM_DISABLED = 'EDM Disabled',
}

const EdmFilter = () => {
  const { selectedFilters, setFilter } = useDynamicFilter('DemandManager');

  return (
    <GenericFilter
      title="DemandManager"
      setFilter={setFilter}
      items={Object.values(EdmLabel)}
      selectedItems={selectedFilters}
    />
  );
};

export default EdmFilter;
