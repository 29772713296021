import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  DeviceType,
  DeviceTypeDisplayText,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';

export type NewHubMethod = 'byNetworkGroup' | 'manual';

interface Props {
  onSelect: (model: DeviceType) => void;
  value?: DeviceType;
  disabled?: boolean;
  error?: boolean;
  customErrorText?: string;
  isNew?: NewHubMethod;
}

const hubModels: {
  id: DeviceType;
  methods: NewHubMethod[];
}[] = [
  {
    id: DeviceType.ENERGYBOX_HUB,
    methods: ['manual', 'byNetworkGroup'],
  },
  {
    id: DeviceType.GHUB,
    methods: ['manual'],
  },
  {
    id: DeviceType.EB_SUPER_HUB,
    methods: ['byNetworkGroup'],
  },
];

class SelectHubModel extends React.Component<Props> {
  render() {
    const { value, onSelect, disabled, customErrorText, isNew } = this.props;

    return (
      <Select
        variant={'select'}
        title={value ? DeviceTypeDisplayText[value] : undefined}
        disabled={isNew !== 'manual' || disabled}
        customErrorText={customErrorText}
      >
        {hubModels
          .filter(({ methods }) => (isNew ? methods.includes(isNew) : true))
          .map(({ id }) => (
            <SelectItem
              key={id}
              isSelected={id === value}
              onSelect={() => onSelect(id)}
            >
              {DeviceTypeDisplayText[id]}
            </SelectItem>
          ))}
      </Select>
    );
  }
}

export default SelectHubModel;
