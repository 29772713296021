import { HvacControl } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHvacControlByEquipmentId } from '../actions/hvacControls';
import { ApplicationState } from '../reducers';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

export const useGetHvacControlByEquipmentId = (
  equipmentId: string | number
) => {
  const dispatch = useDispatch();

  const hvacControl = useSelector<ApplicationState, HvacControl | undefined>(
    ({ hvacControls }) => {
      return hvacControls.hvacControlsByEquipmentId[equipmentId];
    }
  );

  useEffect(() => {
    if (!isDefined(hvacControl)) {
      dispatch(getHvacControlByEquipmentId(equipmentId));
    }
  }, [dispatch, equipmentId, hvacControl]);

  return hvacControl;
};
