import { SubscribedControlBoards } from '@energybox/react-ui-library/dist/types/SubscribedControlBoard';
import { SubscribedControlStatusById } from '@energybox/react-ui-library/dist/types/SubscribedControlStatus';
import { SubscribedThermostats } from '@energybox/react-ui-library/dist/types/SubscribedThermostat';
import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { Actions as AppActions } from '../actions/app';
import history from '../history';
import { Modal } from '../types/modal';
import app, { App } from './app';
import certifications, { Certifications } from './certifications';
import circuitBreakers, { CircuitBreakers } from './circuit_breakers';
import controls, { Controls } from './controls';
import controlBoards, { ControlBoards } from './control_boards';
import deviceStatusById, { DeviceStatusById } from './deviceStatus';
import distributionPanels, { DistributionPanels } from './distribution_panels';
import edgeDevices, { EdgeDeviceState } from './edge_devices';
import energy, { Energy } from './energy';
import energyDevices, { EnergyDevices } from './energy_devices';
import energyPros, { EnergyPros } from './energy_pros';
import equipments, { Equipments } from './equipment';
import filters, { Filters } from './filters';
import gateways, { Gateways } from './gateways';
import hvacControls, { HvacControls } from './hvacControls';
import inspection, { Inspection } from './inspection';
import mainBreakers, { MainBreakers } from './main_breakers';
import modal from './modal';
import networkGroups, { NetworkGroups } from './network_groups';
import notifications, { Notifications } from './notifications';
import operationalSamples, { OperationalSamples } from './operationalSamples';
import { Organizations, organizations } from './organizations';
import pairedSensors, { PairedSensors } from './pairedSensors';
import resourcePaths, { ResourcePaths } from './paths';
import reports, { Reports } from './reports';
import records, { Records } from './records';
import scannedSensors, { ScannedSensors } from './scannedSensors';
import schedulers, { Schedulers } from './schedulers';
import sensors, { Sensors } from './sensors';
import sensorWhitelist, { SensorWhitelist } from './sensorWhitelist';
import sentinels, { Sentinels } from './sentinels';
import sites, { Sites } from './sites';
import { siteGroups, SiteGroups } from './site_groups';
import sops, { Sops } from './sop';
import spaces, { Spaces } from './spaces';
import subscribedControlBoardOutputStates, {
  SubscribedControlBoardOutputStates,
} from './subscribedControlBoardOutputStates';
import subscribedControlBoards from './subscribedControlBoards';
import subscribedControlStatus from './subscribedControlStatus';
import subscribedEnergyPros, {
  SubscribedEnergyPros,
} from './subscribedEnergyPros';
import subscribedThermostats from './subscribedThermostats';
import subscriptions, { Subscriptions } from './subscriptions';
import temperatureControls, {
  TemperatureControls,
} from './temperature_controls';
import timeTables, { TimeTables } from './time_tables';
import { users, Users } from './users';
import { userGroups, UserGroups } from './user_groups';
import utilityBills, { UtilityBills } from './utilityBills';
import thermostats, { Thermostats } from './thermostats';
import subscribedNetworkGroups, {
  SubscribedNetworkGroups,
} from './subscribedNetworkGroups';
import edm, { EdmSchedules } from './edm';
import analytics, { Analytics } from './analytics';
import files, { Files } from './files';
import store from '../store';
import whatsNew, { WhatsNew } from './whatsNew';
import reportworker, { ReportWorker } from './reportworker';
import subscribedReportWorker, {
  SubscribedReportWorker,
} from './subscribeReport';
import loading, { Loading } from './loading';
import downloads, { Downloads } from './download';
import runConfiguration, { RunConfiguration } from './run_configuration';
import iotod, { Iotod } from './iotod';
import taskSop, { TaskSOP } from './taskSop';
import reminder, { ReminderState } from './reminder';
import weather, { Weather } from './weather';
import aggregate_views, { AggregateViews } from './aggregate_views';
import balena, { Balena } from './balena';
import superhub, { SuperHubState } from './superHub';
import subscribedDeviceList, { DeviceList } from './subscribedDeviceList';

export type Dispatch = typeof store.dispatch;

export interface ApplicationState {
  router: RouterState;
  app: App;
  loading: Loading;
  subscribedControlBoardOutputStates: SubscribedControlBoardOutputStates;
  sops: Sops;
  organizations: Organizations;
  deviceStatusById: DeviceStatusById;
  subscribedControlStatus: SubscribedControlStatusById;
  pairedSensors: PairedSensors;
  sensorWhitelist: SensorWhitelist;
  scannedSensors: ScannedSensors;
  users: Users;
  sites: Sites;
  spaces: Spaces;
  networkGroups: NetworkGroups;
  equipment: Equipments;
  resourcePaths: ResourcePaths;
  sentinels: Sentinels;
  sensors: Sensors;
  gateways: Gateways;
  controlBoards: ControlBoards;
  timeTables: TimeTables;
  userGroups: UserGroups;
  siteGroups: SiteGroups;
  distributionPanels: DistributionPanels;
  circuitBreakers: CircuitBreakers;
  mainBreakers: MainBreakers;
  energyPros: EnergyPros;
  energyDevices: EnergyDevices;
  operationalSamples: OperationalSamples;
  notifications: Notifications;
  modal: Modal;
  controls: Controls;
  schedulers: Schedulers;
  temperatureControls: TemperatureControls;
  edgeDevices: EdgeDeviceState;
  subscriptions: Subscriptions;
  reports: Reports;
  thermostats: Thermostats;
  hvacControls: HvacControls;
  subscribedEnergyPros: SubscribedEnergyPros;
  certifications: Certifications;
  energy: Energy;
  subscribedThermostats: SubscribedThermostats;
  inspection: Inspection;
  filters: Filters;
  subscribedControlBoards: SubscribedControlBoards;
  subscribedNetworkGroups: SubscribedNetworkGroups;
  utilityBills: UtilityBills;
  records: Records;
  edmConfigs: EdmSchedules;
  analytics: Analytics;
  files: Files;
  whatsNew: WhatsNew;
  subscribedReportWorker: SubscribedReportWorker;
  reportworker: ReportWorker;
  downloads: Downloads;
  runConfiguration: RunConfiguration;
  iotod: Iotod;
  taskSop: TaskSOP;
  reminder: ReminderState;
  weather: Weather;
  aggregate_views: AggregateViews;
  balena: Balena;
  superhub: SuperHubState;
  subscribedDeviceList: DeviceList;
}

const appReducer = combineReducers<ApplicationState>({
  router: connectRouter(history),
  app,
  loading,
  subscribedControlBoardOutputStates,
  deviceStatusById,
  subscribedControlStatus,
  organizations,
  sites,
  spaces,
  subscriptions,
  sops,
  networkGroups,
  equipment: equipments,
  resourcePaths,
  sensors,
  sentinels,
  pairedSensors,
  sensorWhitelist,
  scannedSensors,
  gateways,
  controlBoards,
  timeTables,
  users,
  userGroups,
  siteGroups,
  distributionPanels,
  circuitBreakers,
  mainBreakers,
  energyPros,
  energyDevices,
  operationalSamples,
  notifications,
  modal,
  controls,
  schedulers,
  temperatureControls,
  edgeDevices,
  reports,
  thermostats,
  hvacControls,
  subscribedEnergyPros,
  certifications,
  energy,
  subscribedThermostats,
  inspection,
  filters,
  subscribedControlBoards,
  subscribedNetworkGroups,
  utilityBills,
  records,
  edmConfigs: edm,
  analytics,
  files,
  whatsNew,
  subscribedReportWorker,
  reportworker,
  downloads,
  runConfiguration,
  iotod,
  taskSop,
  reminder,
  weather,
  aggregate_views,
  balena,
  superhub,
  subscribedDeviceList,
});

const rootReducer = (state, action) => {
  if (action.type === AppActions.LOGOUT) {
    state = undefined;
    return appReducer(state, { type: AppActions.INIT });
  }
  return appReducer(state, action);
};

export default rootReducer;
