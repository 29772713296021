import {
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';
import { EnergyLineChartType } from '@energybox/react-ui-library/dist/components/EnergyLineChart';

import React from 'react';
import styles from './EnergyChartTypeToggle.module.css';

type Props = {
  chartType: EnergyLineChartType;
  setChartType: (value: EnergyLineChartType) => void;
};

const EnergyChartTypeToggle: React.FC<Props> = ({
  chartType,
  setChartType,
}) => {
  return (
    <RadioGroup>
      <RadioButton
        label={EnergyLineChartType.POWER}
        value={EnergyLineChartType.POWER}
        checked={chartType === EnergyLineChartType.POWER}
        onChange={() => setChartType(EnergyLineChartType.POWER)}
      />
      <RadioButton
        label={EnergyLineChartType.CURRENT}
        value={EnergyLineChartType.CURRENT}
        checked={chartType === EnergyLineChartType.CURRENT}
        onChange={() => setChartType(EnergyLineChartType.CURRENT)}
      />
    </RadioGroup>
  );
};

export default EnergyChartTypeToggle;
