import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import {
  unsubscribeFromWorkerStatus,
  subscribeToWorkerStatus,
} from '../actions/streamApi';
import { ReportStatusById } from '../reducers/subscribeReport';

const useSubscribeToReportStatus = () => {
  const dispatch = useDispatch();
  const [jobIds, setJobIds] = useState([]);

  const reportWorker = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.reportWorker
  );

  useEffect(() => {
    if (reportWorker && reportWorker.length) {
      setJobIds(reportWorker.map(data => data.jobId));
    }
  }, [reportWorker]);

  useEffect(() => {
    if (isDefined(jobIds)) {
      if (Array.isArray(jobIds) && jobIds.length !== 0) {
        jobIds.forEach(jobId => dispatch(subscribeToWorkerStatus(jobId)));
      } else if (typeof jobIds === 'string') {
        dispatch(subscribeToWorkerStatus(jobIds));
      }
    }
  }, [dispatch, jobIds]);

  const subscribedReportStatusById = useSelector<
    ApplicationState,
    ReportStatusById | undefined
  >(({ subscribedReportWorker }) => {
    return subscribedReportWorker.reportStatusById;
  });

  return subscribedReportStatusById;
};

export default useSubscribeToReportStatus;
