import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { EnergySpiderFlexCt } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getEnergySpiderFlexCts } from '../../actions/app';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  onSelect: (energySpiderFlexCtId: string) => void;
  value?: string;
  error: boolean;
  disabled?: boolean;
  noBottomLine?: boolean;
}

interface Props extends OwnProps {
  getEnergySpiderFlexCts: typeof getEnergySpiderFlexCts;
  energySpiderFlexCts?: EnergySpiderFlexCt[];
}

interface State {
  search: string;
}

class SelectEnergySpiderFlexCt extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { energySpiderFlexCts, getEnergySpiderFlexCts } = this.props;
    if (!energySpiderFlexCts) getEnergySpiderFlexCts();
  }

  render() {
    const {
      energySpiderFlexCts,
      value,
      onSelect,
      error,
      disabled,
      noBottomLine = false,
    } = this.props;

    if (!energySpiderFlexCts) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;

      const listedEnergySpiderFlexCts = (energySpiderFlexCts || []).filter(
        (eq: EnergySpiderFlexCt) => {
          if (search.length <= 2) return true;
          return hasSubstr(eq.title, search);
        }
      );

      const selectedEnergySpiderFlexCt = energySpiderFlexCts.find(
        eq => eq.id === value
      );

      return (
        <Select
          disabled={disabled}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedEnergySpiderFlexCt?.title}
          value={selectedEnergySpiderFlexCt?.id}
          error={error}
          customErrorText={'Invalid CT type'}
          noBottomLine={noBottomLine}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
          />
          {listedEnergySpiderFlexCts.map((eq: EnergySpiderFlexCt) => (
            <SelectItem
              key={eq.id}
              isSelected={eq.id === selectedEnergySpiderFlexCt?.id}
              onSelect={() => onSelect(eq.id)}
            >
              {eq.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  energySpiderFlexCts: app.energySpiderFlexCts,
});

const mapDispatchToProps = {
  getEnergySpiderFlexCts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectEnergySpiderFlexCt);
