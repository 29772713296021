import { Scheduler } from '@energybox/react-ui-library/dist/types';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import styles from './LightingControls.module.css';
import { InputField } from '@energybox/react-ui-library/dist/components';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { DEFAULT_HYSTERESIS } from '../../../constants/scheduler';

type Props = {
  activeControl: Scheduler;
  onChange: onChange;
  equipmentId: number;
};

const EditModeHysteresis: React.FC<Props> = ({
  activeControl,
  onChange,
  equipmentId,
}) => {
  const schedulerId = activeControl?.id;
  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });
  const fields = schedulerControls?.fields;
  const controlBoardId = fields?.lightSensorSettings?.controlBoardId;
  const hysteresis = fields?.lightSensorSettings?.hysteresis;
  const formErrorsVisible = schedulerControls?.formErrorsVisible;
  const formErrors = schedulerControls?.formErrors;
  const isControlBoardSelected =
    isDefined(controlBoardId) && controlBoardId !== -1;

  return (
    <InputField
      className={styles.biasInput}
      type="number"
      min={10}
      max={100}
      value={hysteresis ?? DEFAULT_HYSTERESIS}
      disabled={!isControlBoardSelected}
      onChange={({ currentTarget }) =>
        onChange(
          String(schedulerId),
          ['lightSensorSettings', 'hysteresis'],
          currentTarget.value
        )
      }
      error={
        formErrorsVisible &&
        isDefined(controlBoardId) &&
        !!formErrors?.['lightSensorSettings.hysteresis']
      }
      customErrorText={
        formErrors?.['lightSensorSettings.hysteresis'] &&
        formErrors['lightSensorSettings.hysteresis'].join(', ')
      }
    />
  );
};

export default EditModeHysteresis;
