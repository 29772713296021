export enum StepType {
  'NONE' = 'NONE',
  'type' = 'type',
  'condition' = 'condition',
  'schedule' = 'schedule',
  'handling' = 'handling',
  'rollout' = 'rollout',
  'additions' = 'additions',
}

export type Step = {
  key: StepType;
  title: string;
  isValid: boolean;
};

export type Steps = {
  [key: string]: Step;
};

export type Condition = 'lt' | 'eq' | 'gt';

export const sentinelSteps: Steps = {
  [StepType.type]: {
    key: StepType.type,
    title: 'Type',
    isValid: false,
  },
  [StepType.condition]: {
    key: StepType.condition,
    title: 'Condition',
    isValid: false,
  },
  [StepType.schedule]: {
    key: StepType.schedule,
    title: 'Schedule',
    isValid: false,
  },
  [StepType.handling]: {
    key: StepType.handling,
    title: 'Handling',
    isValid: false,
  },
  [StepType.rollout]: {
    key: StepType.rollout,
    title: 'Rollout',
    isValid: false,
  },
  [StepType.additions]: {
    key: StepType.additions,
    title: 'Additions',
    isValid: false,
  },
};
