import * as R from 'ramda';
import { Actions } from '../actions/iotod';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';

export type Iotod = {
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  macAddressIsLoading: boolean;
  macAddress: string;
};

export const initialState = {
  formErrorsVisible: false,
  formErrors: {},
  macAddressIsLoading: false,
  macAddress: '',
};

export const iotod = (state: Iotod = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_IOTOD_MAC_ADDRESS_ERROR:
      return R.pipe(
        R.assocPath(['macAddressIsLoading'], false),
        R.assocPath(
          ['formErrors', 'edge.serialNumber'],
          ['Serial Number entered could not be found']
        ),
        R.assocPath(['formErrorsVisible'], true)
      )(state);

    case Actions.GET_IOTOD_MAC_ADDRESS_LOADING:
      return R.pipe(
        R.assocPath(['macAddressIsLoading'], true),
        R.assocPath(['formErrorsVisible'], false)
      )(state);

    case Actions.GET_IOTOD_MAC_ADDRESS_SUCCESS:
      return R.pipe(
        R.assocPath(['macAddressIsLoading'], false),
        R.assocPath(['macAddress'], action?.data?.uuid)
      )(state);

    case Actions.SET_INVALID_SERIAL_NUMBER_ERROR:
      return R.pipe(
        R.assocPath(['macAddressIsLoading'], false),
        R.assocPath(['formErrors', 'edge.serialNumber'], [action.value]),
        R.assocPath(['formErrorsVisible'], true)
      )(state);

    case Actions.RENDER_NEW_IOTOD_FIELDS:
      return R.pipe(
        R.assocPath(['macAddress'], ''),
        R.assocPath(['formErrors'], {}),
        R.assocPath(['formErrorsVisible'], false)
      )(state);

    default:
      return state;
  }
};

export default iotod;
