import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { Region } from '@energybox/react-ui-library/dist/types/Region';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions as OrganizationActions,
  displayFormErrors,
  patch,
  reset,
  updateField,
} from '../../actions/organizations';
import { getRegionRecipients } from '../../actions/reportworker';
import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';
import ModalFormContent from '../../components/ModalFormContent';
import { useGetCurrentOrganization } from '../../hooks/useCurrentOrganization';
import { ApplicationState } from '../../reducers';
import { EditOrganization } from '../../reducers/organizations';
import { SIRRecipients } from '../../reducers/reportworker';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import SelectRegion from '../Selects/SelectRegion';
import styles from './OrganizationEBSettingsPage.module.css';

type Props = {
  orgId: string;
};

const OrganizationEBSettingsPage: React.FC<Props> = ({ orgId }) => {
  const dispatch = useDispatch();
  const currentOrganization = useGetCurrentOrganization();

  const onChange = (field: string, value: any) => {
    dispatch(updateField(orgId, field, value, true));
  };

  const onCancel = () => {
    dispatch(reset(orgId));
  };

  const onOrganizationSave = () => {
    if (hasKeys(editOrganization?.formErrors || {})) {
      dispatch(displayFormErrors(orgId));
    } else {
      dispatch(patch(orgId));
    }
  };

  useEffect(() => {
    for (let region in Region) {
      dispatch(getRegionRecipients(region as Region));
    }
  }, []);

  const editOrganization = useSelector<
    ApplicationState,
    EditOrganization | undefined
  >(({ organizations }) => organizations.editById[orgId]);

  const recipients = useSelector<ApplicationState, SIRRecipients>(
    ({ reportworker }) => reportworker.sirRecipients
  );

  if (!currentOrganization || !editOrganization) return null;
  const { formErrors, formErrorsVisible, apiError } = editOrganization;

  const region = editOrganization.fields.region || currentOrganization.region;
  return (
    <IndexManagementPageLayout backTitle="Energybox Settings">
      <Card className={styles.cardContainer}>
        <CardContent>
          <CardTitle className={styles.cardTitle}>
            <div>Region Settings</div>
          </CardTitle>

          <div className={styles.columns}>
            <div>
              <ModalFormContent className={styles.fields}>
                <div>
                  <Label htmlFor="title">Region</Label>
                </div>
                <div>
                  <SelectRegion
                    onSelect={value => onChange('region', value)}
                    value={region}
                    error={formErrorsVisible && !!formErrors.region}
                    customErrorText={formErrors?.region?.[0]}
                  />
                </div>
              </ModalFormContent>

              {renderAPIerror(
                apiError,
                OrganizationActions.PATCH_ORGANIZATION_ERROR
              )}
            </div>
            {region && (
              <ModalFormContent className={styles.fields}>
                <div>
                  <Label>Email for SIR:</Label>
                </div>
                <div className={styles.predefined}>
                  {recipients[region].map(recipient => (
                    <Label>{recipient}</Label>
                  ))}
                </div>
              </ModalFormContent>
            )}
          </div>
        </CardContent>

        {editOrganization.isChanged && (
          <CardActions>
            <Button variant="text" onClick={onCancel} children="Cancel" />
            <Button
              disabled={editOrganization.isLoading}
              onClick={onOrganizationSave}
            >
              {editOrganization.isLoading ? (
                <Loader size={16} variant="secondary" />
              ) : (
                'Save Changes'
              )}
            </Button>
          </CardActions>
        )}
      </Card>
    </IndexManagementPageLayout>
  );
};

export default OrganizationEBSettingsPage;
