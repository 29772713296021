import { Equipment, HvacControl } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect, useState } from 'react';
import SelectThermostat from '../../../Selects/SelectThermostat';
import { onChange } from '../../LightingEquipmentContorls/LightingEquipmentData';
import styles from '../HvacControls.module.css';

type MapProps = {
  equipment: Equipment;
  siteId: number;
  isEditModeActive: boolean;
  onChange: onChange;
};
const AddAndMapThermostat: React.FC<MapProps> = ({
  equipment,
  siteId,
  isEditModeActive,
  onChange,
}) => {
  const hvacControl: HvacControl = equipment?.activeControl as HvacControl;
  const thermostat = hvacControl?.thermostat;
  const isThereHvacControl = isDefined(hvacControl) || hvacControl === null;
  const isNew = !isThereHvacControl;
  const [thermostatId, setThermostatId] = useState(isNew ? -1 : thermostat?.id);
  const [formErrorsVisible, setFormErrorsVisible] = useState(false); // may not needed as we are using multip update
  const isThermostatIdValid = thermostatId !== -1;

  const setThermostatValue = value => {
    setThermostatId(value);
    onChange(String(equipment.id), 'thermostatId', value);
  };

  const thermostatTitle = () => {
    return isDefined(thermostat) && thermostat !== null ? (
      thermostat?.title
    ) : (
      <span className={styles.pinkText}>No Thermostat</span>
    );
  };

  useEffect(() => {
    if (hvacControl) {
      setThermostatId(thermostat?.id);
    } else {
      setThermostatId(-1);
    }
  }, [siteId, equipment]);

  return (
    <>
      {isEditModeActive ? (
        <SelectThermostat
          initialThermostatId={
            isDefined(hvacControl) ? thermostat?.id : undefined
          }
          siteId={siteId}
          value={String(thermostatId)}
          onSelect={(value: number) => setThermostatValue(value)}
          error={formErrorsVisible && !isThermostatIdValid}
          customErrorText={'Please select thermostat'}
          IsEditFromControls
        />
      ) : (
        thermostatTitle()
      )}
    </>
  );
};
export default AddAndMapThermostat;
