import { ControlOverridesDisplay } from '@energybox/react-ui-library/dist/components';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import { useControlStatus } from '../../hooks/streamApi/useControlStatus';
import { useGetEquipmentById } from '../../hooks/useEquipment';

interface Props {
  equipmentId: number;
  isLocalOverrideActive: boolean | undefined;
}

const ActuatorControlMode = ({ equipmentId, isLocalOverrideActive }: Props) => {
  const equipment = useGetEquipmentById(equipmentId);
  const controlStatus = useControlStatus(equipment?.activeControl?.id);
  return isDefined(controlStatus) ? (
    <ControlOverridesDisplay
      controlWorkingMode={controlStatus.workingMode}
      // Just using disabled field to handle isLocalOverrideActive
      isLocalOverrideActive={undefined}
      disabled={isLocalOverrideActive === true}
      useGreenText={false}
    />
  ) : (
    <>{global.NOT_AVAILABLE}</>
  );
};

export default ActuatorControlMode;
