import {
  SiteGroup,
  SortDirection,
  OpacityIndex,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
  hasSubstr,
  values,
} from '@energybox/react-ui-library/dist/utils';
import {
  LongExtraLongSkeletonCell,
  MediumLongSkeletonCell,
  ShortSkeletonCell,
  Button,
  SearchBox,
  Table,
} from '@energybox/react-ui-library/dist/components';

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Actions,
  getSiteGroups,
  showNewModal,
} from '../../actions/site_groups';
import SiteGroupDescription from '../../components/SiteGroupDescription';
import SiteGroupTitle from '../../components/SiteGroupTitle';
import SitesManagementLayout from '../../components/SitesManagementLayout';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { CreateNewText, Placeholder } from '../../types/global';
import { checkCommonPlural } from '../../util';
import NewGroupModal from '../Modals/NewGroupModal';
import { pluralize } from '@energybox/react-ui-library/dist/utils/util';
import history from '../../history';
import { TableWrapper } from '../../components/ui/Table';

import theme from '../../theme';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';
import usePaginationFilter from '../../hooks/usePaginationFilter';

interface Props {
  getSiteGroups: typeof getSiteGroups;
  showNewModal: typeof showNewModal;
  setPagination?: (
    page: number | undefined,
    rowLimit: number | undefined
  ) => void;
  currentPage: number | undefined;
  rowLimit: number | undefined;
  siteGroups: SiteGroup[];
  getGroupsIsLoading: boolean | undefined;
  viewportType: ViewportTypes;
}

interface State {
  query: string;
  groupsLoadingGuard: boolean;
}

const withDynamicFilter = WrappedComponent => {
  return props => {
    const dataLength = useMemo(() => props.siteGroups?.length, [
      props?.siteGroups?.length,
    ]);

    const { currentPage, rowLimit, setPagination } = usePaginationFilter(
      dataLength
    );

    return (
      <WrappedComponent
        setPagination={setPagination}
        rowLimit={rowLimit}
        currentPage={currentPage}
        {...props}
      />
    );
  };
};

class SiteGroupsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: '',
      groupsLoadingGuard: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ groupsLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams(history, 'query', '');

    this.setState({ query: savedQuery as string });

    this.props.getSiteGroups();
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  render() {
    const {
      siteGroups = [],
      getGroupsIsLoading,
      viewportType,
      currentPage,
      setPagination,
      rowLimit,
    } = this.props;
    const isMobile = viewportType === ViewportTypes.MOBILE;
    const { query, groupsLoadingGuard } = this.state;
    const isLoading = groupsLoadingGuard || getGroupsIsLoading;

    const filteredGroups =
      query && query.length >= 3
        ? siteGroups.filter(group => hasSubstr(group.title, query))
        : siteGroups;

    const actions = (
      <>
        <Button onClick={this.props.showNewModal}>
          {CreateNewText.SITE_GROUP}
        </Button>
      </>
    );

    const tableColumns = [
      {
        header: 'Site Group',
        width: '30%',
        cellContent: (siteGroup: SiteGroup) => (
          <SiteGroupTitle {...siteGroup} />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: SiteGroup,
          b: SiteGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Description',
        width: '30%',
        cellContent: (siteGroup: SiteGroup) => (
          <SiteGroupDescription {...siteGroup} />
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: SiteGroup,
          b: SiteGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'description',
          ]);
        },
      },
      {
        header: 'Sites',
        cellContent: ({ id, sites }: SiteGroup) => {
          const numberOfSites = sites ? sites.length : 0;
          return (
            <Link
              to={`${Routes.SITE_GROUPS}/${id}`}
              children={`${numberOfSites} ${pluralize('site', numberOfSites)}`}
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: SiteGroup,
          b: SiteGroup,
          sortDirection: SortDirection
        ) => {
          const numberOfSites = (s: SiteGroup) => s.sites.length;
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            numberOfSites
          );
        },
      },
    ];

    return (
      <SitesManagementLayout actions={actions}>
        <DynamicContentWrapper>
          <PageContentHeader>
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredGroups.length === 0}
            />
          </PageContentHeader>
          <TableWrapper
            isLoading={isLoading}
            header={checkCommonPlural('Site Group', filteredGroups.length)}
          >
            <Table
              listView
              columns={tableColumns}
              data={!getGroupsIsLoading ? filteredGroups : []}
              dataIsLoading={isLoading}
              rowLimitFromPaginationHook={rowLimit}
              currentPageFromPaginationHook={currentPage}
              setPagination={setPagination}
            />
          </TableWrapper>
        </DynamicContentWrapper>
        <NewGroupModal type="site" />
      </SitesManagementLayout>
    );
  }
}

const mapStateToProps = ({ siteGroups }: ApplicationState) => ({
  siteGroups: values(siteGroups.siteGroupsById),
  getGroupsIsLoading:
    siteGroups.loadingStatusByAction[Actions.GET_SITE_GROUPS_LOADING],
});

const mapDispatchToProps = {
  getSiteGroups,
  showNewModal,
};

export default withViewportType(
  withDynamicFilter(
    connect(mapStateToProps, mapDispatchToProps)(SiteGroupsPage)
  )
);
