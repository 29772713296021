import React from 'react';
import { ProgressBar } from '@energybox/react-ui-library/dist/components';
import { SiteEdmData } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import styles from './UploadBillData.module.css';

type Props = {
  siteEdmData: SiteEdmData;
  equipmentTypeFilters: number[];
};

const EdmHealthBar: React.FC<Props> = ({
  siteEdmData,
  equipmentTypeFilters,
}) => {
  let errors = false;
  let isOnlyEdmError = false;
  let totaPercentagelLengthPerSite = 1;
  let totalNoOfWarnings = 0;
  const hasAnyErrorInSite =
    siteEdmData.historical_data.max_power.error ||
    siteEdmData.historical_data.overall_nday_available.error ||
    siteEdmData.uploaded_bills.last_bill_name.error ||
    siteEdmData.uploaded_bills.last_bill_name.error ||
    siteEdmData.site_edm_enable.error;
  if (hasAnyErrorInSite) {
    errors = true;
  }
  const siteHasOnlyEdmError =
    !(
      siteEdmData.historical_data.max_power.error ||
      siteEdmData.historical_data.overall_nday_available.error ||
      siteEdmData.uploaded_bills.last_bill_name.error ||
      siteEdmData.uploaded_bills.last_bill_name.error
    ) && siteEdmData.site_edm_enable.error;
  if (siteHasOnlyEdmError) {
    isOnlyEdmError = true;
  }
  const noBillsin3MonthsWar = siteEdmData.uploaded_bills.last_bill_name.warning;

  if (noBillsin3MonthsWar) {
    totalNoOfWarnings = totalNoOfWarnings + 1;
  }
  totaPercentagelLengthPerSite =
    totaPercentagelLengthPerSite + Object.keys(siteEdmData.by_sop).length;
  Object.values(siteEdmData.by_sop).forEach(
    ({ by_equipment, edm_enable }, index) => {
      if (edm_enable.warning) {
        totalNoOfWarnings = totalNoOfWarnings + 1;
      }
      totaPercentagelLengthPerSite =
        totaPercentagelLengthPerSite + Object.keys(by_equipment).length;
      const equipmentIds = Object.keys(by_equipment);
      const equipmentsInSop = Object.entries(by_equipment);
      const filteredEqTypes: string[] = [];
      equipmentTypeFilters.forEach(t => {
        equipmentsInSop.forEach(([id, type]) => {
          if (type.equipment_type_id === t) {
            filteredEqTypes.push(id);
          }
        });
      });
      const filteredOrUniqueTypes = equipmentTypeFilters.length
        ? filteredEqTypes
        : equipmentIds;
      filteredOrUniqueTypes.forEach(equipmentId => {
        const equipmentInfo = by_equipment[equipmentId];
        if (equipmentInfo.control_mode.warning) {
          totalNoOfWarnings = totalNoOfWarnings + 1;
        }
      });
    }
  );

  const calculatePercentage = (
    errors: boolean,
    total: number,
    warnings: number
  ): number => {
    if (errors) {
      return 0;
    } else if (warnings) {
      const percent = 100 / total;
      const warningPercent = Math.round(100 - percent * warnings);
      return warningPercent;
    } else {
      return 100;
    }
  };

  const percentage = calculatePercentage(
    errors,
    totaPercentagelLengthPerSite,
    totalNoOfWarnings
  );
  const renderErrorBlock = () => {
    return (
      <>
        {isOnlyEdmError && (
          <span
            className={classNames(styles.warningText, styles.edmHealthWarning)}
          >
            DemandManager is disabled on all HVAC SOPs at this site.
          </span>
        )}

        {errors && !isOnlyEdmError && (
          <span
            className={classNames(styles.errorText, styles.edmHealthWarning)}
          >
            DemandManager is not functioning at this site, please resolve
            errors.
          </span>
        )}
      </>
    );
  };
  return (
    <div className={styles.edmHealth}>
      <div className={styles.edmHealthBar}>
        <span>
          <ProgressBar
            perceivedProgress={percentage === 0 ? 5 : percentage}
            className={styles.progressBar}
            isEDMProgress={true}
          />
        </span>
        <span>{percentage}%</span>
      </div>
      {renderErrorBlock()}
    </div>
  );
};

export default EdmHealthBar;
