import { Modal } from '../types/modal';
import { Actions as ModalActions } from '../actions/modal';

const initialState: Modal = {
  isOpen: false,
};

export default (state: Modal = initialState, action: any) => {
  switch (action.type) {
    case ModalActions.MODAL_OPEN: {
      return {
        isOpen: true,
        ...action.payload,
      };
    }

    case ModalActions.MODAL_CLOSE: {
      return initialState;
    }

    default:
      return state;
  }
};
