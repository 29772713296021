import React from 'react';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import styles from './ReportContentSection.module.css';

type Props = {
  title: string;
  action?: React.ReactNode;
  isSubSection?: boolean;
};

const ReportContentSection: React.FC<Props> = ({
  title,
  action,
  isSubSection,
  children,
}) => {
  return (
    <div
      className={styles.root}
      style={{ width: isSubSection ? '90%' : '100%' }}
    >
      <div
        className={classNames(
          isSubSection ? styles.subHeaderContainer : styles.headerContainer
        )}
      >
        <div
          className={classNames(
            isSubSection ? styles.subHeaderTitle : styles.title
          )}
        >
          {title}
        </div>
        {action && <div>{action}</div>}
      </div>

      {children}
    </div>
  );
};

export default ReportContentSection;
