import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from './reducers';
import apiMiddleware from './middlewares/apiMiddleware';
import authMiddleware from './middlewares/authMiddleware';
import redirectMiddleware from './middlewares/redirectMiddleware';
import streamApiMiddleware from './middlewares/streamApiMiddleware';
import deleteMiddleware from './middlewares/deleteMiddleware';
import energyMiddleware from './middlewares/energyMiddleware';
import controlsMiddleware from './middlewares/controlsMiddleware';
import sentinelMiddleware from './middlewares/sentinelMiddleware';
import gaMiddleware from './middlewares/gaMiddleware';
import updateClearMiddleware from './middlewares/updateClearMiddleware';
import timetableMiddleware from './middlewares/timetableMiddleware';
import localStorageMiddleware from './middlewares/localStorageMiddleware';
import history from './history';

// TODO: Add index signature
interface Window {
  [key: string]: any;
}

export interface Action {
  type: string;
  payload: any;
}

const middleware = [thunk];
let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  const w: Window = window;
  composeEnhancers =
    typeof w !== 'undefined' && w['__REDUX_DEVTOOLS_EXTENSION__']
      ? w['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
      : compose;

  middleware.push(logger);
}

export const configureStore = (preloadedState: any = {}) =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        ...middleware,
        routerMiddleware(history),
        apiMiddleware,
        authMiddleware,
        streamApiMiddleware,
        redirectMiddleware,
        gaMiddleware,
        updateClearMiddleware,
        deleteMiddleware,
        energyMiddleware,
        controlsMiddleware,
        sentinelMiddleware,
        timetableMiddleware,
        localStorageMiddleware
      )
    )
  );

const store = configureStore();

export default store;
