import { Button } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import { showNewEquipmentModal } from '../../actions/equipment';
import EquipmentsManagementLayout from '../../components/EquipmentsManagementLayout';
import { CreateNewText } from '../../types/global';
import EquipmentTable from './EquipmentTable';
import NewEquipmentModal from './NewEquipmentModal';

interface Props {
  showNewEquipmentModal: typeof showNewEquipmentModal;
}

class EquipmentsPage extends React.Component<Props> {
  render() {
    const { showNewEquipmentModal } = this.props;

    const actions = (
      <>
        <Button onClick={showNewEquipmentModal}>
          {CreateNewText.EQUIPMENT}
        </Button>
      </>
    );

    return (
      <EquipmentsManagementLayout actions={actions}>
        <EquipmentTable />
        <NewEquipmentModal />
      </EquipmentsManagementLayout>
    );
  }
}

const mapDispatchToProps = {
  showNewEquipmentModal,
};

export default connect(null, mapDispatchToProps)(EquipmentsPage);
