import React from 'react';
import styles from './FilterMenuFooter.module.css';
import { classNames } from '@energybox/react-ui-library/dist/utils';

type Props = {
  className?: string;
  children?: any;
};

const FilterMenuFooter = ({ className, children }: Props) => (
  <div className={classNames(styles.root, className)}>{children}</div>
);

export default FilterMenuFooter;
