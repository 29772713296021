import { classNames } from '@energybox/react-ui-library/dist/utils';
import { ThermostatOverride as ThermostatOverrideIcon } from '@energybox/react-ui-library/dist/icons';

import React from 'react';
import styles from './ConditionDoor.module.css';
import StepConditionItem, {
  StepConditionDescription,
  StepConditionTitle,
} from './StepConditionItem';

function ConditionThermostatOverrideCheck() {
  return (
    <StepConditionItem
      label={
        <span className={classNames(styles.icon)}>
          <ThermostatOverrideIcon size={80} />
        </span>
      }
      condition={
        <div>
          <StepConditionTitle>Device in Local Override</StepConditionTitle>
          <StepConditionDescription>
            Configured settings are not being followed
          </StepConditionDescription>
        </div>
      }
      conditionAlignedCenter
    />
  );
}

export default ConditionThermostatOverrideCheck;
