import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  LightSensorPort,
  LightSensorPortText,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';

type Props = {
  value?: LightSensorPort;
  onSelect: (value: LightSensorPort) => void;
  error?: boolean;
  customErrorText?: string;
  testResultsExist?: boolean;
};

const SelectLightSensorPort: React.FC<Props> = ({
  value,
  onSelect,
  error,
  customErrorText,
  testResultsExist,
}) => {
  return (
    <Select
      title={
        value
          ? LightSensorPortText[value]
          : LightSensorPortText[LightSensorPort.NONE]
      }
      error={error}
      customErrorText={customErrorText}
      disabled={testResultsExist}
    >
      {Object.keys(LightSensorPort).map(v => (
        <SelectItem
          key={v}
          isSelected={v === value}
          onSelect={() => onSelect(v as LightSensorPort)}
        >
          {LightSensorPortText[v]}
        </SelectItem>
      ))}
    </Select>
  );
};

export default SelectLightSensorPort;
