import {
  DistributionPanel,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { connect } from 'react-redux';
import {
  displayFormErrors,
  hideNewCircuitBreakerModal,
  updateField,
} from '../../actions/circuit_breakers';
import { installCircuitBreaker } from '../../actions/distribution_panel';
import EditCircuitBreakerForm from '../../components/EditCircuitBreakerForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/circuit_breakers';
import { CreateNewText } from '../../types/global';
import { ApiError } from '../../utils/apiErrorFeedback';

interface OwnProps {
  panelId: number;
  currentSiteId: number;
  breakerSlotRows: number;
  panelColumns: number;
  distributionPanel: DistributionPanel;
}

interface Props extends OwnProps {
  onChange: (field: string, value: string | number) => void;
  onCreate: (panelId: number) => void;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  formErrorsVisible: boolean;
  displayFormErrors: (id: string) => void;
  apiError: ApiError;
}

function NewCircuitBreakerModal(props: Props) {
  if (!props.isVisible) return null;

  const {
    fields,
    onChange,
    onClose,
    onCreate,
    isLoading,
    formErrors,
    currentSiteId,
    formErrorsVisible,
    displayFormErrors,
    apiError,
    breakerSlotRows,
    panelColumns,
    distributionPanel,
  } = props;

  const onCircuitBreakerCreate = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate(props.panelId);
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onCircuitBreakerCreate}>
        Create
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalTitle>{CreateNewText.CIRCUIT_BREAKER}</ModalTitle>
      <ModalContent>
        <EditCircuitBreakerForm
          distributionPanel={distributionPanel}
          fields={fields}
          onChange={onChange}
          currentSiteId={currentSiteId}
          formErrorsVisible={formErrorsVisible}
          formErrors={formErrors}
          breakerSlotRows={breakerSlotRows}
          apiError={apiError}
          panelColumns={panelColumns}
        />
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({ circuitBreakers }: ApplicationState) => {
  return {
    isVisible: circuitBreakers.showNewCircuitBreakerModal,
    ...circuitBreakers.editById['new'],
  };
};

const mapDispatchToProps = (dispatch, { panelId }: OwnProps) => ({
  onClose: () => dispatch(hideNewCircuitBreakerModal()),
  onChange: (field: string, value: string | number) =>
    dispatch(updateField('new', field, value)),
  onCreate: () => dispatch(installCircuitBreaker(panelId)),
  displayFormErrors: (breakerId: string) =>
    dispatch(displayFormErrors(breakerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCircuitBreakerModal);
