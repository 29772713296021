import {
  Equipment,
  Sensor,
  SensorType,
  SentinelTarget,
  SentinelTargetType,
  Site,
  Space,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import { Checkbox } from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { fetchSensors } from '../../../../actions/sensorsFetch';
import ResourcePath from '../../../../containers/ResourcePath';
import { EquipmentChip, SensorChip, SpaceChip } from './TargetChips';
import styles from './StepRollout.module.css';

type Props = {
  site: Site;
  sensorTypes: SensorType[];
  handleTargetSelect: (sentinelTarget: SentinelTarget) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
};

type State = {
  sensors: Sensor[];
  isLoading: boolean;
};

class StepRolloutSensors extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sensors: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    let dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
    if (dublicateSentinelkey) {
      const { handleTargetSelect } = this.props;
      if (this.props.selected.length > 0) {
        handleTargetSelect({
          targetId: this.props.selected[0],
          targetType: SentinelTargetType.SENSOR,
        });
      }
    }
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site, sensorTypes } = this.props;

      const sensors = await fetchSensors({
        siteIds: [site.id],
        sensorTypes,
      });

      handleSetResourceRelation(
        site.id,
        sensors.map((e: Sensor) => e.id)
      );

      const sortedSensors = sensors.sort((a, b) =>
        a.title.localeCompare(b.title)
      );

      this.setState({
        sensors: sortedSensors,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  resourceChipSelector = (resource: Equipment | Space | Site) => {
    switch (resource.resourceType) {
      case ResourceType.EQUIPMENT:
        return (
          <EquipmentChip
            id={resource.type ? resource.type.alias : ''}
            title={resource.title}
          />
        );

      case ResourceType.SPACE:
        return <SpaceChip id="generic" title={resource.title} />;

      default:
        return <span>{resource.title}</span>;
    }
  };

  handleTargetSelectClick = (ev: any, item: any) => {
    const { handleTargetSelect } = this.props;
    ev.preventDefault();
    handleTargetSelect({
      targetId: item.id,
      targetType: SentinelTargetType.SENSOR,
    });
  };

  render() {
    const { sensors = [], isLoading } = this.state;
    const { site, selected } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    return (
      <div>
        <table className={styles.table}>
          <tbody>
            {sensors.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <div>This site has no sensors.</div>
                </td>
              </tr>
            ) : (
              sensors.map((item: Sensor) => (
                <tr
                  key={`rolloutSensorRow${item.id}`}
                  onClick={ev => this.handleTargetSelectClick(ev, item)}
                  className={styles.filterRow}
                >
                  <td style={{ width: '35%' }}>
                    <SensorChip
                      id={SensorType[item.types[0]]}
                      title={item.title}
                    />
                  </td>
                  <td style={{ width: '25%' }}>
                    {item.resource && this.resourceChipSelector(item.resource)}
                  </td>
                  <td style={{ width: '25%' }}>
                    <ResourcePath
                      suppressLink
                      resourceId={item.id}
                      resourceType={ResourceType.SPACE}
                    />
                  </td>
                  <td className={styles.action} style={{ width: '15%' }}>
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={ev => this.handleTargetSelectClick(ev, item)}
                      id={`checkbox_${site.id}_${item.id}`}
                      size={16}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default StepRolloutSensors;
