import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../actions/users';

import { ApplicationState } from '../reducers';

export const useGetUsersInOrganization = () => {
  const dispatch = useDispatch();
  const usersInOrganization = useSelector(({ users }: ApplicationState) => {
    return users.usersById;
  });

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return usersInOrganization;
};

export default useGetUsersInOrganization;
