import * as R from 'ramda';
import {
  Actions as StreamActions,
  CommandType,
  GatewayAction,
  SensorAction,
} from '../actions/streamApi';
import { Status } from '../types/status';

export type PairedSensor = {
  id: string;
  uuid: string;
  vendor: string;
};

export type PairedSensorsByGatewayId = {
  [id: string]: PairedSensor[];
};

export type IsReportIntervalUpdatingBySensorId = {
  [sensorId: string]: boolean;
};

export type PairedSensors = {
  pairedSensorsByGatewayId: PairedSensorsByGatewayId;
  isReportIntervalUpdatingBySensorId: IsReportIntervalUpdatingBySensorId;
  status: Status;
};

const initialState = {
  pairedSensorsByGatewayId: {},
  isReportIntervalUpdatingBySensorId: {},
  status: Status.INIT,
};

const pairedSensors = (state: PairedSensors = initialState, action: any) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      if (action.data && action.data.deviceType === 'gateway') {
        if (action.data.pairedSensors) {
          return R.pipe(
            R.assocPath(
              ['pairedSensorsByGatewayId', action.data.id?.toString()],
              action.data.pairedSensors
            ),
            R.assoc('status', Status.SUCCESS)
          )(state);
        }
      }

      if (action?.data?.deviceType === 'sensor') {
        return R.assocPath(
          ['isReportIntervalUpdatingBySensorId', action.data.id],
          false,
          state
        );
      }

      return state;
    }

    case StreamActions.SEND_MESSAGE: {
      if (action.data.action === GatewayAction.EMIT_PAIRED_SENSOR_LIST) {
        return {
          ...state,
          status: Status.LOADING,
        };
      }

      if (
        action.data.type === CommandType.ENQUEUE_SENSOR_ACTION &&
        action.data.action === SensorAction.SET_REPORT_INTERVAL
      ) {
        return R.assocPath(
          ['isReportIntervalUpdatingBySensorId', action.data.id],
          true,
          state
        );
      }

      return state;
    }

    default:
      return state;
  }
};

export default pairedSensors;
