import { Equipment } from '@energybox/react-ui-library/dist/types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEquipment,
  getEquipmentBySiteId,
  showNewEquipmentModal,
} from '../actions/equipment';
import { ApplicationState } from '../reducers';
import {
  isDefined,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import { EquipmentById } from '../reducers/equipment';

export const useGetEquipmentById = (equipmentId: string | number) => {
  const dispatch = useDispatch();

  const equipment = useSelector<ApplicationState, Equipment | undefined>(
    ({ equipment }) => {
      return equipment.equipmentById[equipmentId];
    }
  );

  useEffect(() => {
    if (!isDefined(equipment)) {
      dispatch(getEquipment(String(equipmentId)));
    }
  }, [dispatch, equipmentId, equipment]);

  return equipment;
};

export const useGetEquipmentByEquipmentTypeId = (
  siteId: string | number | undefined,
  equipmentTypeId: string | number | undefined
) => {
  const equipmentByIdForSite = useGetEquipmentBySiteId(siteId);

  const equipmentForTypeId = Object.values(equipmentByIdForSite).filter(
    equipment => equipment.typeId === equipmentTypeId
  );

  return equipmentForTypeId;
};

export const useGetEquipmentBySiteId = (
  siteId: string | number | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(siteId)) {
      dispatch(getEquipmentBySiteId(siteId));
    }
  }, [dispatch, siteId]);

  const equipmentIdsBySiteId = useSelector<ApplicationState, number[]>(
    ({ equipment }) => {
      if (isDefined(siteId)) {
        return equipment.equipmentIdsBySiteId[siteId] || [];
      }
      return [];
    }
  );

  const equipmentById = useSelector<ApplicationState, EquipmentById>(
    ({ equipment }) => {
      return equipment.equipmentById;
    }
  );

  const siteEquipmentById: EquipmentById = useMemo(() => {
    return mapArrayToObject(
      equipmentIdsBySiteId
        .map(equipmentId => equipmentById[equipmentId])
        .filter(equipment => isDefined(equipment))
    );
  }, [equipmentIdsBySiteId, equipmentById]);

  return siteEquipmentById;
};

export const useNewEquipmentModal = () => {
  const dispatch = useDispatch();

  const openNewEquipmentModal = useCallback(() => {
    dispatch(showNewEquipmentModal());
  }, []);

  const isVisible = useSelector(({ equipment }: ApplicationState) => {
    return equipment.showNewEquipmentModal;
  });

  return {
    isVisible,
    openNewEquipmentModal,
  };
};
