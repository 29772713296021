import { ModalTypes, OpenModal } from '../types/modal';

export enum Actions {
  MODAL_OPEN = '@@dialog/MODAL_OPEN',
  MODAL_CLOSE = '@@dialog/MODAL_CLOSE',
}

export const openModal = (payload: OpenModal) => ({
  type: Actions.MODAL_OPEN,
  payload: {
    ...payload,
    type: payload.type || ModalTypes.CONFIRMATION,
  },
});

export const closeModal = () => ({
  type: Actions.MODAL_CLOSE,
});
