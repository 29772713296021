import { GatewayStates } from '@energybox/react-ui-library/dist/types';
import { useSubscribeToControlBoard } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import {
  SubscribedControlStatus,
  SubscribedControlStatusById,
} from '@energybox/react-ui-library/dist/types/SubscribedControlStatus';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { ApplicationState } from '../../reducers';
import { useControlBoardById } from '../useControlBoard';

export const useControlStatus = (
  controlId: string | number | undefined
): SubscribedControlStatus | undefined => {
  const dispatch = useDispatch();
  useEffect(() => {
    let unsubscribe: (() => void) | null = null;
    if (isDefined(controlId)) {
      dispatch(
        subscribeToDeviceStatus('energybox', String(controlId), controlId)
      );
      unsubscribe = () =>
        dispatch(
          unsubscribeFromDeviceStatus('energybox', String(controlId), controlId)
        );
    }
    return () => {
      if (isDefined(unsubscribe)) unsubscribe();
    };
  }, [controlId, subscribeToDeviceStatus, unsubscribeFromDeviceReadings]);

  const controlStatus = useSelector<
    ApplicationState,
    SubscribedControlStatusById
  >(state => state.subscribedControlStatus);
  const { byCamId } = controlStatus;

  if (isDefined(byCamId) && isDefined(controlId)) {
    return byCamId[controlId];
  }
  return undefined;
};

export const useControlBoardLocalOverride = (controlBoardId: number) => {
  const dispatch = useDispatch();
  const controlBoard = useControlBoardById(controlBoardId);
  const subscribedControlBoardsById = useSelector(
    ({ subscribedControlBoards }: ApplicationState) =>
      subscribedControlBoards.byId?.[controlBoardId]
  );

  const contorlBoardData = subscribedControlBoardsById ?? undefined;

  const subscribedControlBoard = useSubscribeToControlBoard(
    controlBoard,
    () => contorlBoardData,
    dispatch,
    subscribeToDeviceStatus,
    unsubscribeFromDeviceStatus
  );

  const isLocalOverrideActive = subscribedControlBoard?.status?.gatewayStates?.includes(
    GatewayStates.CONTROL_OVERRIDE
  );
  return isLocalOverrideActive;
};
