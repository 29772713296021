import {
  ControlsType,
  GenericErrors,
  TemperatureControl,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import {
  Actions as ControlActions,
  Actions as ControlsActions,
} from '../actions/controls';
import { Actions } from '../actions/temperature_controls';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export const DEFAULT_HYSTERESIS_VALUE = 1;
export const MINIMUM_HYSTERESIS_VALUE = 1;
export const MAXIMUM_HYSTERESIS_VALUE = 10;

const initialState: TemperatureControls = {
  temperatureControlsByEquipmentId: {},
  editById: {},
  apiError: {},
};
type EditTemperatureControlById = {
  [id: string]: EditTemperatureControl;
};

type TemperatureControlByEquipmentId = {
  [id: string]: TemperatureControl;
};

export type EditTemperatureControl = {
  isLoading: boolean;
  isChanged: boolean;
  fields: TemperatureControl;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

const editableFields = (tempControl: TemperatureControl) =>
  R.pick(
    [
      'equipmentId',
      'sensorId',
      'hysteresis',
      'schedulerId',
      'beginDelta',
      'endDelta',
      'timetableId',
      'setPoint',
      'outsideSetPoint',
      'type',
      'id',
    ],
    tempControl
  );

const newTemperatureControlFields = {
  equipmentId: -1,
  sensorId: -1,
  hysteresis: DEFAULT_HYSTERESIS_VALUE,
  timetableId: undefined,
  beginDelta: 0,
  endDelta: 0,
  setPoint: 0,
  outsideSetPoint: null,
  controlType: ControlsType.COOLING,
  workingMode: WorkingMode.NORMAL,
};

const newTemperatureControl = {
  isLoading: false,
  isChanged: false,
  fields: newTemperatureControlFields,
  formErrors: formValidationErrors(
    'temperatureControl',
    newTemperatureControlFields
  ),
  formErrorsVisible: false,
};

export interface TemperatureControls {
  temperatureControlsByEquipmentId: TemperatureControlByEquipmentId;
  editById: EditTemperatureControlById;
  apiError: ApiError;
}

const temperatureControls = (
  state: TemperatureControls = initialState,
  action: any
) => {
  switch (action.type) {
    case ControlActions.TOGGLE_NEW_CONTROL_MODAL:
      return R.pipe(R.assocPath(['editById', 'new'], newTemperatureControl))(
        state
      );

    case Actions.UPDATE_FIELD:
      const updatedFields = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedFields),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('temperatureControl', updatedFields)
        )
      )(state);

    case Actions.RESET_EDIT_TEMPERATURE_CONTROLS: {
      const fields = editableFields(
        R.path(['temperatureControlsByEquipmentId', action.equipmentId], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('temperatureControl', fields)
        )
      )(state);
    }

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.PATCH_TEMPERATURE_CONTROLS_SUCCESS:
    case Actions.CREATE_TEMPERATURE_CONTROLS_SUCCESS:
      if (!!action.data) {
        return R.pipe(
          R.assocPath(
            ['temperatureControlsByEquipmentId', action.data.equipmentId],
            action.data
          ),
          R.assocPath(['editById', action.data.id], {
            isLoading: false,
            formErrorsVisible: false,
            formErrors: formValidationErrors(
              'temperatureControl',
              editableFields(action.data)
            ),
            fields: editableFields(action.data),
          }),
          R.assoc('apiError', {})
        )(state);
      } else {
        return state;
      }

    case Actions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_SUCCESS:
      return R.pipe(
        R.dissocPath(['editById', action.temperatureControlId, 'apiError'])
      )(state);

    case Actions.CREATE_TEMPERATURE_CONTROLS_ERROR:
    case Actions.PATCH_TEMPERATURE_CONTROLS_ERROR:
    case Actions.PATCH_TEMPERATURE_CONTROLS_CONTROL_MODE_ERROR:
    case Actions.DELETE_TEMPERATURE_CONTROLS_ERROR:
      return R.pipe(R.assoc('apiError', storeAPIerror(action)))(state);

    case ControlsActions.CLEAR_UPDATE_CONTROL_MODE_MODAL_ERROR_MESSAGE:
      return R.assoc('apiError', {}, state);

    case Actions.GET_TEMPERATURE_CONTROLS_BY_EQUIPMENT_ID_SUCCESS:
      if (!!action.data && action.data.length > 0) {
        return R.pipe(
          R.assocPath(
            ['temperatureControlsByEquipmentId', action.data[0].equipmentId],
            action.data[0]
          ),
          R.assocPath(['editById', action.data[0].id], {
            isLoading: false,
            formErrorsVisible: false,
            formErrors: formValidationErrors(
              'temperatureControl',
              editableFields(action.data[0])
            ),
            fields: editableFields(action.data[0]),
            apiError: {},
          })
        )(state);
      } else {
        return R.pipe(
          R.assocPath(
            ['temperatureControlsByEquipmentId', action.equipmentId],
            undefined
          ),
          R.assocPath(['editById', action.equipmentId], {
            isLoading: false,
            formErrorsVisible: false,
            formErrors: formValidationErrors(
              'temperatureControl',
              newTemperatureControlFields
            ),
            fields: newTemperatureControlFields,
            apiError: {},
          })
        )(state);
      }

    case Actions.DELETE_TEMPERATURE_CONTROLS_SUCCESS:
      let equipmentIdToRemove: string = '';
      Object.entries(state.temperatureControlsByEquipmentId).forEach(
        ([equipmentId, controlObj]) => {
          if (controlObj.id === action.temperatureControlId) {
            equipmentIdToRemove = equipmentId;
          }
        }
      );
      if (equipmentIdToRemove !== '') {
        return R.pipe(
          R.dissocPath([
            'temperatureControlsByEquipmentId',
            equipmentIdToRemove,
          ]),
          R.dissocPath(['editById', action.temperatureControlId])
        )(state);
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default temperatureControls;
