import {
  Button,
  Checkbox,
  FormText,
  InputField,
  ListItem,
  MultiSelect,
  MultiSelectItem,
  NativeSelectField,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import {
  initialSentinelEscalation,
  IntervalUnit,
  SentinelApiRecipient,
  SentinelEscalation,
  SentinelPriorityLevel,
  SentinelRecurType,
} from '@energybox/react-ui-library/dist/types';
import { Trash } from '@energybox/react-ui-library/dist/icons';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { MdDelete as TrashIcon } from 'react-icons/md';
import {
  DEFAULT_MAXIMUM_RESENDS,
  DEFAULT_SENTINEL_ESCALATION_SINGLE_RUN_DELAY,
  DEFAULT_SENTINEL_RECUR_INTERVAL,
} from '../../../../constants/sentinels';
import FormTimeSelect from '../../../ui/FormTimeSelect';
import PriorityLevel from '../../../ui/PriorityLevel';
import TextField from '../../../ui/TextField';
import { Recipient } from './StepHandling';
import styles from './StepHandlingItem.module.css';
import StepHandlingItemConditionLabel from './StepHandlingItemConditionLabel';
import { SentinelChannelType } from '@energybox/react-ui-library/dist/types/Sentinel';

export interface ActionError {
  name?: string;
  recipients?: string;
}

function SelectRecipient(props: {
  options: Recipient[];
  selected: number[];
  onChange: (recipient: Recipient) => void;
}) {
  const { options, selected, onChange } = props;

  const [value, setValue] = useState('');

  let filteredOptions = [...options];
  if (value !== '') {
    filteredOptions = filteredOptions.filter((recipient: Recipient) =>
      recipient.title.toLowerCase().includes(value.toLowerCase())
    );
  }

  if (selected.length > 0) {
    filteredOptions = filteredOptions.filter(
      (recipient: Recipient) => !selected.includes(recipient.id)
    );
  }

  return (
    <Select
      variant="add"
      title="Add group or user"
      onClick={() => setValue('')}
    >
      <SelectSearch
        onChange={ev => setValue(ev.currentTarget.value)}
        value={value}
      />

      {filteredOptions.map((recipient: Recipient) => (
        <SelectItem
          key={recipient.id}
          onSelect={() => onChange(recipient)}
          noRadioButton
        >
          {recipient.title}
        </SelectItem>
      ))}
    </Select>
  );
}

type Props = {
  ref: any;
  escalation: SentinelEscalation;
  isDeleteAble: boolean;
  options: Recipient[];
  handleAddEscalation: (sentinelEscalation: SentinelEscalation) => void;
  handleUpdateEscalation: (sentinelEscalation: SentinelEscalation) => void;
  handleDeleteEscalation: (sentinelEscalationIndex: number) => void;
  index: number;
  isLoading: boolean;
  isSetErrorField: (value: boolean) => void;
  isErrorField: boolean;
  isSetEmailErrorField: (value: boolean) => void;
  isEmailErrorField: boolean;
  setAction: (value: any) => void;
  setEmail: (value: any) => void;
  isSetFormSaveError: (value: boolean) => void;
  handleValidation: (value: any) => void;
  // onEmailValidationFunction: (data: any) => void
  isFormSaveError: boolean;
  isFirstTimeCountCopy: (count: any) => void;
  copyCount: any;
  setEditBtnEnable: (status: boolean[]) => any;
  editBtnEnable: boolean[];
};

type State = {
  isEditable: boolean;
  escalation: SentinelEscalation;
  isDublicateKeyExist: boolean;
  showSelectAll: boolean;
  filteredEquipmentTypes: any;
  // channelType: any;
  errors: ActionError;
};

const StepHandlingItem = forwardRef((props: Props, ref) => {
  const {
    escalation: propEscalation,
    copyCount,
    isLoading,
    setAction,
    isSetFormSaveError,
    handleUpdateEscalation,
    isFirstTimeCountCopy,
    setEditBtnEnable,
    handleDeleteEscalation,
  } = props;

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [escalation, setEscalation] = useState<SentinelEscalation>(
    initialSentinelEscalation
  );
  const [isDublicateKeyExist, setIsDublicateKeyExist] = useState<boolean>(
    false
  );
  const [showSelectAll, setShowSelectAll] = useState<boolean>(false);
  const [itemErrors, setItemErrors] = useState<ActionError>({});
  const [channelType, setChannelType] = useState<SentinelChannelType[]>(
    propEscalation?.channelType || []
  );
  const [filteredChannelTypes, setFilteredChannelTypes] = useState([
    { id: SentinelChannelType.EMAIL, name: 'Email' },
    { id: SentinelChannelType.SIMPLYCHECK, name: 'SimplyCheck' },
    { id: SentinelChannelType.KIOSK, name: 'Kiosk' },
  ]);

  useImperativeHandle(ref, () => ({
    validate() {
      let newErrors: ActionError = itemErrors;

      if (escalation.name === '') {
        newErrors = {
          ...newErrors,
          name: 'Please define the action name for this tier',
        };
      }
      if (escalation.recipients.length === 0) {
        newErrors = {
          ...newErrors,
          recipients: 'Please add the users or groups',
        };
      }
      setItemErrors({ ...newErrors });
    },
  }));

  useEffect(() => {
    const { recurrenceLimit, ix } = propEscalation;
    const dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');

    // This guard should be removed soon, but is added to ensure correct behavior
    // if Holiday uses this feature before this latest updated is deployed
    const recurrenceLimitGuard =
      recurrenceLimit === -1 ? null : recurrenceLimit;
    let hasRecurrenceLimit = false;
    if (
      ix !== 0 && //first action doesn't take these settings
      recurrenceLimitGuard !== null
    ) {
      hasRecurrenceLimit = true;
    }
    if (copyCount === 0 && dublicateSentinelkey) {
      propEscalation['recipients'] = [];
    }

    setEscalation({
      ...propEscalation,
      recurrenceLimitChecked: hasRecurrenceLimit,
      recurrenceLimit: recurrenceLimitGuard,
    });
  }, []);

  useEffect(() => {
    setShowSelectAll(channelType.length === 3);
  }, [channelType]);

  useEffect(() => {
    // remove error message
    if (escalation.name && itemErrors.name) {
      setItemErrors({ ...itemErrors, name: undefined });
    }
    if (escalation.recipients.length > 0 && itemErrors.recipients) {
      setItemErrors({ ...itemErrors, recipients: undefined });
    }
  }, [escalation]);

  const handleChange = (name: string) => (ev: any) => {
    const value = ev.target.value;

    setEscalation({
      ...escalation,
      [name]: value,
    });
    setAction({
      escalation: {
        ...escalation,
        [name]: value,
      },
    });
  };

  const handleDelayChange = (name: any) => (value: number) => {
    setEscalation({
      ...escalation,
      [name]: {
        value,
        unit: IntervalUnit.MILLISECOND,
      },
    });
  };

  const handleAddRecipient = (recipient: Recipient) => {
    let stringArr: Array<any> = [];
    const newRecipients = [...escalation.recipients, recipient];
    setEscalation(prev => ({
      ...prev,
      recipients: newRecipients,
    }));
    setIsDublicateKeyExist(true);

    setAction({
      escalation: {
        ...escalation,
        recipients: newRecipients,
      },
    });
  };

  const handleRemoveRecipient = (recipient: Recipient) => {
    const newRecipients = escalation.recipients.filter(
      r => r.identifier !== recipient.identifier
    );

    setEscalation(prevState => ({
      ...prevState,
      recipients: newRecipients,
    }));
    setAction([
      {
        ...escalation,
        recipients: newRecipients,
      },
    ]);
    isSetFormSaveError(false);
  };

  const handleOnSave = () => {
    handleUpdateEscalation({ ...escalation });
    isSetFormSaveError(false);
    setIsEditable(false);
    isFirstTimeCountCopy(1);
    updateEditables(true);
  };

  const handleOnCancel = () => {
    setIsEditable(false);
    setEscalation({
      ...propEscalation,
      recurrenceLimitChecked: propEscalation.recurrenceLimit !== null,
    });
    updateEditables(true);
  };

  const handleSwitchEditableMode = () => {
    setIsEditable(prevState => !prevState);
    updateEditables(false);
  };

  const updateEditables = (editable: boolean) => {
    const newStatus = [...props.editBtnEnable];
    newStatus[escalation.ix] = editable;
    setEditBtnEnable(newStatus);
  };

  const handleToggleDelayChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const { checked } = ev.target;

    setEscalation(prevState => ({
      ...prevState,
      singleRun: checked,
      singleRunDelay: checked
        ? {
            unit: IntervalUnit.MILLISECOND,
            value: DEFAULT_SENTINEL_ESCALATION_SINGLE_RUN_DELAY,
          }
        : { unit: IntervalUnit.MILLISECOND, value: 0 },
    }));
  };

  const handleToggleRecurTypeChange = () =>
    setEscalation(prevState => ({
      ...prevState,
      recurType:
        prevState.recurType !== SentinelRecurType.UNTIL_RESOLVED
          ? SentinelRecurType.UNTIL_RESOLVED
          : SentinelRecurType.NONE,
      recurInterval:
        prevState.recurInterval &&
        typeof prevState.recurInterval.value === 'number'
          ? undefined
          : {
              unit: IntervalUnit.MILLISECOND,
              value: DEFAULT_SENTINEL_RECUR_INTERVAL,
            },
    }));

  const handleToggleRecurrenceLimitChange = event => {
    const recurrenceLimitChecked = event.target.checked || false;
    setEscalation({
      ...escalation,
      recurrenceLimitChecked: recurrenceLimitChecked,
      recurrenceLimit: recurrenceLimitChecked
        ? recurrenceLimit
        : DEFAULT_MAXIMUM_RESENDS,
    });
  };

  const stateIsValid = () =>
    escalation.name !== '' &&
    escalation.recipients.length > 0 &&
    escalation.channelType?.length > 0;

  const escalationItemIsValid = () =>
    propEscalation.name !== '' && propEscalation.recipients.length > 0;

  const onItemClick = id => {
    if (channelType.indexOf(id) === -1) {
      const updatedChannelType = [...channelType, id];
      setEscalation({
        ...escalation,
        channelType: updatedChannelType,
      });
      setChannelType(updatedChannelType);
      setIsDublicateKeyExist(true);
    } else if (channelType.indexOf(id) !== -1) {
      const updatedChannelType = channelType.filter(
        removeId => id !== removeId
      );
      setEscalation({
        ...escalation,
        channelType: updatedChannelType,
      });
      setChannelType(updatedChannelType);
      setIsDublicateKeyExist(true);
    }
  };

  const toggleSelectAll = () => {
    if (!showSelectAll) {
      const selectAll: any[] = [];
      filteredChannelTypes.forEach(item => {
        // if(item.name !== 'Platform (Default)')
        selectAll.push(item.id);
      });
      setEscalation({
        ...escalation,
        channelType: selectAll,
      });
      setChannelType(selectAll);
    } else {
      setEscalation({
        ...escalation,
        channelType: [],
      });
      setChannelType([]);
    }
  };

  const onRemoveChannelType = id => {
    if (channelType.indexOf(id) !== -1) {
      const filteredChannelType = channelType.filter(
        removeId => id !== removeId
      );
      setEscalation({
        ...escalation,
        channelType: filteredChannelType,
      });
      setChannelType(filteredChannelType);
    }
  };

  const { options, isDeleteAble, index } = props;
  const { recurrenceLimit, recurrenceLimitChecked } = escalation;

  if (
    escalation.priorityLevel &&
    escalation.priorityLevel === SentinelPriorityLevel.NORMAL
  ) {
    escalation.priorityLevel = SentinelPriorityLevel.LOW;
  }
  if (
    escalation.priorityLevel &&
    escalation.priorityLevel === SentinelPriorityLevel.HIGHEST
  ) {
    escalation.priorityLevel = SentinelPriorityLevel.CRITICAL;
  }

  const dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');

  const isFirstEscalationLevel = index === 0;
  const isEditableOrInvalid = isEditable || escalationItemIsValid() === false;

  const addTitleToEscalation = (item: SentinelApiRecipient): Recipient => {
    const id = +item.identifier;
    const title = (options.find(o => o.id === id) || { title: '...loading' })
      .title;
    return {
      title,
      channel: item.channel,
      identifier: item.identifier,
      id,
    };
  };
  const recipientsWithTitle = escalation.recipients.map(addTitleToEscalation);
  // components expect undefined not null
  let recurrenceLimitValueForInput: undefined | number = undefined;
  if (recurrenceLimitChecked && recurrenceLimit !== null) {
    recurrenceLimitValueForInput = recurrenceLimit;
  }

  const priorityLevel = escalation.priorityLevel;

  return (
    <div className={`${styles.root} ${styles[priorityLevel.toLowerCase()]}`}>
      <div className={styles.handling}>
        <div>
          {isEditableOrInvalid ? (
            <>
              <TextField
                placeholder="Action"
                value={escalation.name || ''}
                onChange={handleChange('name')}
              />
              <span className={styles.errorContainer}>
                {itemErrors.name ?? ''}
              </span>
            </>
          ) : (
            <FormText>{propEscalation.name || 'Action'}</FormText>
          )}

          {isFirstEscalationLevel && (
            <div>
              <FormText>
                {!itemErrors.name
                  ? 'Once the first notification is triggered.'
                  : ''}
              </FormText>
            </div>
          )}
        </div>
        {/* if duplicate sentinel key does not exist in localstorage it open the email column else not open the column */}

        <div>
          {isEditableOrInvalid ? (
            <>
              <SelectRecipient
                onChange={handleAddRecipient}
                selected={escalation.recipients.map(e => +e.identifier)}
                options={options}
              />
              {recipientsWithTitle.map(r => (
                <ListItem
                  key={`ListItem${r.identifier}`}
                  primaryText={r.title}
                  actions={
                    <span className={styles.trashIcon}>
                      <Trash
                        color={'var(--pink-base)'}
                        onClick={() => handleRemoveRecipient(r)}
                      />
                    </span>
                  }
                />
              ))}
              {/* <span className={styles.errorContainer}>{this.props.isEmailErrorField === true ? this.props.errors['errors']['email'] : ''}</span> */}
              <span className={styles.errorContainer}>
                {itemErrors.recipients ?? ''}
              </span>
            </>
          ) : (
            <FormText>{recipientsWithTitle.length} Recipients</FormText>
          )}
        </div>

        {isEditableOrInvalid ? (
          <NativeSelectField
            onChange={handleChange('priorityLevel')}
            value={priorityLevel}
          >
            <option value="LOW">Low</option>
            <option value="MEDIUM">Medium</option>
            <option value="HIGH">High</option>
            <option value="CRITICAL">Critical</option>
          </NativeSelectField>
        ) : (
          <FormText>
            <PriorityLevel priority={priorityLevel}>
              {priorityLevel.toLowerCase()}
            </PriorityLevel>
          </FormText>
        )}

        {/* <FormText>Email</FormText> */}

        {isEditableOrInvalid ? (
          // <div className={this.state.channelType.map((item) => item === "Platform (Default)" ? styles.disabledBtnClass : '')}>
          <MultiSelect
            doNotIncludeActions
            selectionComponentType="portalDropdown"
            onDeleteItem={(e, itemId) => onRemoveChannelType(String(itemId))}
            items={channelType.map(channelTypeId => ({
              value: `${
                (
                  filteredChannelTypes.find(({ id }) => id === channelTypeId) ||
                  {}
                ).name
              }`,
              id: channelTypeId,
            }))}
            selectionComponent={
              <div className={styles.dropdown}>
                <div className={styles.dropdownList}>
                  <div className={styles.selectAll}>
                    <span
                      tabIndex={0}
                      onKeyPress={toggleSelectAll}
                      role="button"
                      onClick={toggleSelectAll}
                    >
                      {showSelectAll ? 'Deselect All' : 'Select All'}
                    </span>
                  </div>
                  {/* <MultiSelectItem
                    isSelected={true}
                    onSelect={() => false}
                    className={styles.multiSelect}
                  >
                    Platform (Default)
                  </MultiSelectItem> */}
                  {filteredChannelTypes.map(({ name, id }) => (
                    <>
                      <MultiSelectItem
                        isSelected={channelType.indexOf(id) > -1}
                        onSelect={() => onItemClick(id)}
                      >
                        {name}
                      </MultiSelectItem>
                    </>
                  ))}
                </div>
              </div>
            }
          />
        ) : (
          <FormText>
            {props.escalation?.channelType !== null &&
            props.escalation?.channelType?.length !== 0
              ? `${props.escalation?.channelType?.join(', ')}` || []
              : []}
          </FormText>
        )}
        <div>
          {isEditableOrInvalid ? (
            <TextField
              placeholder="Optional message"
              onChange={handleChange('message')}
              value={escalation.message || ''}
            />
          ) : (
            <FormText>{propEscalation.message || ''} </FormText>
          )}
        </div>

        {isFirstEscalationLevel === false && (
          <>
            {/* Per Harsh, we don't want this "Notify if no action taken in" option anymore
                not sure if we will bring this back in the future */}
            {/* <div>
                  {isEditableOrInvalid ? (
                    <Checkbox
                      onChange={this.handleToggleDelayChange}
                      checked={escalation.singleRun}
                      label="Notify if no action taken in"
                    />
                  ) : (
                    <StepHandlingItemConditionLabel
                      label="Notify if no action taken in"
                      value={
                        this.props.escalation.singleRunDelay
                          ? this.props.escalation.singleRunDelay.value
                          : 0
                      }
                      isTime
                    />
                  )}
                </div>
                <div>
                  {isEditableOrInvalid && (
                    <FormTimeSelect
                      disabled={!escalation.singleRun}
                      onChange={this.handleDelayChange('singleRunDelay')}
                      value={
                        escalation.singleRunDelay
                          ? escalation.singleRunDelay.value
                          : 0
                      }
                    />
                  )}
                </div>
                <div />
                <div />
                <div /> */}

            <div>
              {isEditableOrInvalid ? (
                <>
                  <Checkbox
                    onChange={() => {
                      setEscalation(prevState => ({
                        ...prevState,
                        recurrenceLimitChecked: false,
                        recurrenceLimit: DEFAULT_MAXIMUM_RESENDS,
                      }));
                      handleToggleRecurTypeChange();
                    }}
                    checked={
                      escalation.recurType === SentinelRecurType.UNTIL_RESOLVED
                    }
                    label="Notify repeatedly until resolved every"
                  />
                </>
              ) : (
                <StepHandlingItemConditionLabel
                  label="Notify repeatedly until resolved every"
                  value={
                    propEscalation.recurInterval
                      ? propEscalation.recurInterval.value
                      : 0
                  }
                  isTime
                />
              )}
            </div>
            <div>
              {isEditableOrInvalid && (
                <FormTimeSelect
                  disabled={
                    escalation.recurInterval === undefined ||
                    escalation.recurType === SentinelRecurType.NONE
                  }
                  onChange={handleDelayChange('recurInterval')}
                  value={
                    escalation.recurInterval && escalation.recurInterval.value
                  }
                />
              )}
            </div>
            <div />
            <div />
            <div />

            <div>
              {isEditableOrInvalid ? (
                <Checkbox
                  onChange={handleToggleRecurrenceLimitChange}
                  checked={escalation.recurrenceLimitChecked}
                  label="Maximum number of resends"
                  disabled={
                    escalation.recurType !== SentinelRecurType.UNTIL_RESOLVED
                  }
                />
              ) : (
                <StepHandlingItemConditionLabel
                  label="Maximum number of resends"
                  value={recurrenceLimitValueForInput}
                />
              )}
            </div>
            <div>
              {isEditableOrInvalid && (
                <InputField
                  className={styles.inputField}
                  id="recurrenceLimit"
                  type="number"
                  name="recurrenceLimit"
                  value={recurrenceLimitValueForInput}
                  disabled={!escalation.recurrenceLimitChecked}
                  onChange={e => {
                    const { value } = e.currentTarget;
                    setEscalation(prevState => {
                      const parsedValue = Number.parseInt(value);
                      const recurrenceLimit = Number.isNaN(parsedValue)
                        ? null
                        : parsedValue;
                      return {
                        ...prevState,
                        recurrenceLimit,
                      };
                    });
                  }}
                  error={
                    escalation.recurrenceLimitChecked &&
                    (escalation.recurrenceLimit === null ||
                      (escalation.recurrenceLimit !== null &&
                        escalation.recurrenceLimit <= 0))
                  }
                  customErrorText={
                    'Recurrence limit must be an integer greater than 0.'
                  }
                />
              )}
            </div>
            <div />
            <div />
            <div />
          </>
        )}
      </div>

      <div
        className={`${styles.actions} ${
          isEditableOrInvalid
            ? styles.actionsEditable
            : styles.actionsPositionFixed
        }`}
      >
        {isEditableOrInvalid ? (
          <>
            {isDeleteAble ? (
              <>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => handleDeleteEscalation(index)}
                >
                  <TrashIcon className={styles.deleteIcon} />
                  <span className={styles.deleteText}>Delete action</span>
                </Button>
              </>
            ) : (
              <div />
            )}

            <div>
              <Button size="small" variant="text" onClick={handleOnCancel}>
                Cancel
              </Button>{' '}
              <Button
                size="small"
                onClick={handleOnSave}
                disabled={stateIsValid() === false}
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <>
            <div />
            <Button size="small" onClick={handleSwitchEditableMode}>
              Edit
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

export default StepHandlingItem;
