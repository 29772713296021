import { ApplicationState } from '../reducers';
import {
  Sentinel,
  SitesApiFilter,
} from '@energybox/react-ui-library/dist/types';

const apiBase = '/api/v1/sites';
import * as R from 'ramda';
import { DEFAULT_HALT_KEYS } from '../constants/sentinels';

export enum Actions {
  GET_SITES_SUCCESS = '@sites/GET_SITES_SUCCESS',
  GET_SITES_ERROR = '@sites/GET_SITES_ERROR',
  GET_SITES_LOADING = '@sites/GET_SITES_LOADING',

  GET_SITE_SUCCESS = '@sites/GET_SITE_SUCCESS',
  GET_SITE_ERROR = '@sites/GET_SITE_ERROR',
  GET_SITE_LOADING = '@sites/GET_SITE_LOADING',

  GET_SITE_BY_RESOURCE_ID_SUCCESS = '@sites/GET_SITE_BY_RESOURCE_ID_SUCCESS',
  GET_SITE_BY_RESOURCE_ID_ERROR = '@sites/GET_SITE_BY_RESOURCE_ID_ERROR',
  GET_SITE_BY_RESOURCE_ID_LOADING = '@sites/GET_SITE_BY_RESOURCE_ID_LOADING',

  GET_SITES_BY_SENTINEL_TARGETS_SUCCESS = '@sites/GET_SITES_BY_SENTINEL_TARGETS_SUCCESS',
  GET_SITES_BY_SENTINEL_TARGETS_ERROR = '@sites/GET_SITES_BY_SENTINEL_TARGETS_ERROR',
  GET_SITES_BY_SENTINEL_TARGETS_LOADING = '@sites/GET_SITES_BY_SENTINEL_TARGETS_LOADING',

  PATCH_SITE_LOADING = '@sites/PATCH_SITE_LOADING',
  PATCH_SITE_ERROR = '@sites/PATCH_SITE_ERROR',
  PATCH_SITE_SUCCESS = '@sites/PATCH_SITE_SUCCESS',

  DELETE_SITE_SUCCESS = '@sites/DELETE_SITE_SUCCESS',
  DELETE_SITE_ERROR = '@sites/DELETE_SITE_ERROR',
  DELETE_SITE_LOADING = '@sites/DELETE_SITE_LOADING',

  UPLOAD_PICTURE_SUCCESS = '@sites/UPLOAD_PICTURE_SUCCESS',
  UPLOAD_PICTURE_ERROR = '@sites/UPLOAD_PICTURE_ERROR',
  UPLOAD_PICTURE_LOADING = '@sites/UPLOAD_PICTURE_LOADING',

  DELETE_PICTURE_SUCCESS = '@sites/DELETE_PICTURE_SUCCESS',
  DELETE_PICTURE_ERROR = '@sites/DELETE_PICTURE_ERROR',
  DELETE_PICTURE_LOADING = '@sites/DELETE_PICTURE_LOADING',

  UPDATED_QUERY = '@sites/UPDATED_QUERY',

  ADD_SITE_TO_GROUP_SUCCESS = '@sites/ADD_SITE_TO_GROUP_SUCCESS',
  ADD_SITE_TO_GROUP_LOADING = '@sites/ADD_SITE_TO_GROUP_LOADING',
  ADD_SITE_TO_GROUP_ERROR = '@sites/ADD_SITE_TO_GROUP_ERROR',

  REMOVE_SITE_FROM_GROUP_SUCCESS = '@sites/REMOVE_SITE_FROM_GROUP_SUCCESS',
  REMOVE_SITE_FROM_GROUP_LOADING = '@sites/REMOVE_SITE_FROM_GROUP_LOADING',
  REMOVE_SITE_FROM_GROUP_ERROR = '@sites/REMOVE_SITE_FROM_GROUP_ERROR',

  GET_SITE_ONLINE_STATUS_SUCCESS = '@sites/GET_SITE_ONLINE_STATUS_SUCCESS',
  GET_SITE_ONLINE_STATUS_ERROR = '@sites/GET_SITE_ONLINE_STATUS_ERROR',
  GET_SITE_ONLINE_STATUS_LOADING = '@sites/GET_SITE_ONLINE_STATUS_LOADING',

  GET_SITES_ONLINE_STATUS_SUCCESS = '@sites/GET_SITES_ONLINE_STATUS_SUCCESS',
  GET_SITES_ONLINE_STATUS_ERROR = '@sites/GET_SITES_ONLINE_STATUS_ERROR',
  GET_SITES_ONLINE_STATUS_LOADING = '@sites/GET_SITES_ONLINE_STATUS_LOADING',

  GET_SITE_ASTRO_CLOCK_SUCCESS = '@sites/GET_SITE_ASTRO_CLOCK_SUCCESS',
  GET_SITE_ASTRO_CLOCK_ERROR = '@sites/GET_SITE_ASTRO_CLOCK_ERROR',
  GET_SITE_ASTRO_CLOCK_LOADING = '@sites/GET_SITE_ASTRO_CLOCK_LOADING',

  TOGGLE_NEW_SITE_MODAL = '@sites/TOGGLE_NEW_SITE_MODAL',

  UPDATE_FIELD = '@sites/UPDATE_FIELD',
  RESET_EDIT_SITE = '@sites/RESET_EDIT_SITE',

  CREATE_SITE_LOADING = '@sites/CREATE_SITE_LOADING',
  CREATE_SITE_SUCCESS = '@sites/CREATE_SITE_SUCCESS',
  CREATE_SITE_ERROR = '@sites/CREATE_SITE_ERROR',

  DISPLAY_FORM_ERRORS = '@sites/DISPLAY_FORM_ERRORS',
}

export const getSite = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_SITE_SUCCESS,
  error: Actions.GET_SITE_ERROR,
  loading: Actions.GET_SITE_LOADING,
});

export const getSites = () => ({
  type: 'API_GET',
  path: `${apiBase}`,
  success: Actions.GET_SITES_SUCCESS,
  error: Actions.GET_SITES_ERROR,
  loading: Actions.GET_SITES_LOADING,
});

export const getSiteByResourceId = (id: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/resources/${id}/site`,
  success: { type: Actions.GET_SITE_BY_RESOURCE_ID_SUCCESS, id },
  error: Actions.GET_SITE_BY_RESOURCE_ID_ERROR,
  loading: Actions.GET_SITE_BY_RESOURCE_ID_LOADING,
});

export const getSitesBySentinel = (sentinel: Sentinel) => {
  const { id: sentinelId, targets } = sentinel;
  const targetIds = targets.map(target => target.targetId);
  return {
    type: 'API_GET',
    path: `/api/v1/sites/by-targets?targetIds=${targetIds.join(',')}`,
    success: {
      type: Actions.GET_SITES_BY_SENTINEL_TARGETS_SUCCESS,
      sentinelId,
    },
    error: {
      type: Actions.GET_SITES_BY_SENTINEL_TARGETS_ERROR,
      sentinelId,
    },
    loading: {
      type: Actions.GET_SITES_BY_SENTINEL_TARGETS_LOADING,
      sentinelId,
    },
  };
};

export const patch = (id: string | number, fields?) => (dispatch, getState) => {
  if (fields) {
    dispatch({
      type: 'API_PATCH',
      path: `${apiBase}/${id}`,
      payload: fields,
      loading: { type: Actions.PATCH_SITE_LOADING, id },
      success: { type: Actions.PATCH_SITE_SUCCESS, id },
      error: { type: Actions.PATCH_SITE_ERROR, id },
    });
  } else {
    let sendingData: any;
    if (
      (getState() as ApplicationState).sites.editById[id].fields.phone === '' ||
      (getState() as ApplicationState).sites.editById[id].fields.phone === null
    ) {
      sendingData = R.omit(
        [DEFAULT_HALT_KEYS],
        (getState() as ApplicationState).sites.editById[id].fields
      );
    } else if (
      (getState() as ApplicationState).sites.editById[id].fields.phone.length <=
      6
    ) {
      sendingData = R.omit(
        [DEFAULT_HALT_KEYS],
        (getState() as ApplicationState).sites.editById[id].fields
      );
    } else {
      sendingData = (getState() as ApplicationState).sites.editById[id].fields;
    }

    dispatch({
      type: 'API_PATCH',
      path: `${apiBase}/${id}`,
      payload: sendingData,
      loading: { type: Actions.PATCH_SITE_LOADING, id },
      success: { type: Actions.PATCH_SITE_SUCCESS, id },
      error: { type: Actions.PATCH_SITE_ERROR, id },
    });
  }
};

export const destroy = (id: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_SITE_LOADING, id },
  success: { type: Actions.DELETE_SITE_SUCCESS, id },
  error: { type: Actions.DELETE_SITE_ERROR, id },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_SITE,
  id,
});

export const updateQuery = (query: string) => ({
  type: Actions.UPDATED_QUERY,
  query,
});

export const addSiteToGroup = (siteId: number, groupId: number) => ({
  type: 'API_PUT',
  path: `${apiBase}/${siteId}/groups`,
  payload: {
    groupIds: [groupId],
  },
  success: { type: Actions.ADD_SITE_TO_GROUP_SUCCESS, siteId, groupId },
  loading: { type: Actions.ADD_SITE_TO_GROUP_LOADING, siteId, groupId },
  error: { type: Actions.ADD_SITE_TO_GROUP_ERROR, siteId, groupId },
});

export const removeSiteFromGroup = (siteId: number, groupId: number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${siteId}/groups`,
  payload: {
    groupIds: [groupId],
  },
  success: { type: Actions.REMOVE_SITE_FROM_GROUP_SUCCESS, siteId, groupId },
  loading: { type: Actions.REMOVE_SITE_FROM_GROUP_LOADING, siteId, groupId },
  error: { type: Actions.REMOVE_SITE_FROM_GROUP_ERROR, siteId, groupId },
});

export const getSiteOnlineStatus = (id: number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}/online-status`,
  success: { type: Actions.GET_SITE_ONLINE_STATUS_SUCCESS, id },
  loading: { type: Actions.GET_SITE_ONLINE_STATUS_LOADING, id },
  error: { type: Actions.GET_SITE_ONLINE_STATUS_ERROR, id },
});

export const getSitesOnlineStatus = () => ({
  type: 'API_GET',
  path: `${apiBase}/online-status`,
  success: { type: Actions.GET_SITES_ONLINE_STATUS_SUCCESS },
  loading: { type: Actions.GET_SITES_ONLINE_STATUS_LOADING },
  error: { type: Actions.GET_SITES_ONLINE_STATUS_ERROR },
});

interface GetSiteAstroClockParams {
  startDate: string;
  endDate?: string;
}

const createQueryString = (params: GetSiteAstroClockParams) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getSiteAstroClock = (
  siteId: number | string,
  startDate: string,
  endDate?: string
) => ({
  type: 'API_GET',
  path: `${apiBase}/${siteId}/astro?${createQueryString({
    startDate,
    endDate: endDate ? endDate : startDate,
  })}`,
  success: {
    type: Actions.GET_SITE_ASTRO_CLOCK_SUCCESS,
    siteId,
    startDate,
    endDate,
  },
  loading: { type: Actions.GET_SITE_ASTRO_CLOCK_LOADING, siteId },
  error: { type: Actions.GET_SITE_ASTRO_CLOCK_ERROR, siteId },
});

export const showNewSiteModal = () => ({
  type: Actions.TOGGLE_NEW_SITE_MODAL,
  value: true,
});

export const hideNewSiteModal = () => ({
  type: Actions.TOGGLE_NEW_SITE_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const create = () => (dispatch, getState) => {
  dispatch({
    type: 'API_POST',
    path: `${apiBase}`,
    payload: (getState() as ApplicationState).sites.editById['new'].fields,
    loading: Actions.CREATE_SITE_LOADING,
    success: Actions.CREATE_SITE_SUCCESS,
    error: Actions.CREATE_SITE_ERROR,
  });
};

export const uploadPicture = (id: string, formData) => ({
  type: 'API_POST',
  path: `${apiBase}/${id}/image`,
  payload: formData,
  ignoreContentType: true,
  loading: { type: Actions.UPLOAD_PICTURE_LOADING, id },
  success: { type: Actions.UPLOAD_PICTURE_SUCCESS, id },
  error: { type: Actions.UPLOAD_PICTURE_ERROR, id },
});

export const deletePicture = (id: string, path: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}/image?path=${path}`,
  loading: { type: Actions.DELETE_PICTURE_LOADING, id, path },
  success: { type: Actions.DELETE_PICTURE_SUCCESS, id, path },
  error: { type: Actions.DELETE_PICTURE_ERROR, id, path },
});

export const setSitesFilter = (filter?: SitesApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter) {
    if (filter.equipmentIds) {
      queryParams.set('equipmentIds', filter.equipmentIds.join(','));
    }

    if (filter.sensorIds) {
      queryParams.set('sensorIds', filter.sensorIds.join(','));
    }

    if (filter.spaceIds) {
      queryParams.set('spaceIds', filter.spaceIds.join(','));
    }

    if (filter.sensorTypes && filter.sensorTypes.length > 0) {
      queryParams.set('sensorTypes', filter.sensorTypes.join(','));
    }

    if (filter.sensorTarget) {
      queryParams.set('sensorTarget', filter.sensorTarget);
    }

    if (filter.sensorTypesAnd) {
      queryParams.set('sensorTypesAnd', true.toString());
    }
  }

  return `/api/v1/sites?${queryParams.toString()}`;
};
