import {
  Button,
  FileIcon,
  ShortenedSpan,
} from '@energybox/react-ui-library/dist/components';
import { UploadBillIcon } from '@energybox/react-ui-library/dist/icons';
import { SiteEdmData } from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  getFileTypeByName,
} from '@energybox/react-ui-library/dist/utils';
import { getErrorOrWarningIconForField } from '../../Site/SiteInspectionReport/InspectionTile';
import styles from './UploadBillData.module.css';

type Props = {
  siteEdmData: SiteEdmData;
  siteId: number;
  setUploadSiteId: (siteId: number | undefined) => void;
};
const UploadBillData: React.FC<Props> = ({
  siteEdmData,
  siteId,
  setUploadSiteId,
}: Props) => {
  return (
    <div>
      {siteEdmData.uploaded_bills.last_bill_name.field ? (
        <span className={styles.displayFlex}>
          <span className={styles.billNameSection}>
            <span className={styles.filesIcon}>
              <FileIcon
                fileType={getFileTypeByName(
                  siteEdmData.uploaded_bills.last_bill_name.field
                )}
              />
            </span>
            <span className={styles.billName}>
              <ShortenedSpan
                content={siteEdmData.uploaded_bills.last_bill_name.field}
                maxStringLength={19}
              />
              <span className={classNames(styles.subText, styles.timPeriod)}>
                {siteEdmData.uploaded_bills.last_bill_start} -
                {siteEdmData.uploaded_bills.last_bill_end}
              </span>
            </span>
          </span>
          {siteEdmData.uploaded_bills.last_bill_name.error && (
            <span className={styles.uploadedBillwarning}>
              {getErrorOrWarningIconForField({
                error: siteEdmData.uploaded_bills.last_bill_name.error,
              })}
            </span>
          )}
          {siteEdmData.uploaded_bills.last_bill_name.warning && (
            <span className={styles.uploadedBillwarning}>
              {getErrorOrWarningIconForField({
                warning: siteEdmData.uploaded_bills.last_bill_name.warning,
              })}
            </span>
          )}
        </span>
      ) : (
        <div>
          <span className={styles.displayFlex}>
            <div className={styles.errorText}>Utility Bills not uploaded</div>
            {siteEdmData.uploaded_bills.last_bill_name.error && (
              <span className={styles.uploadedBillwarning}>
                {getErrorOrWarningIconForField({
                  error: siteEdmData.uploaded_bills.last_bill_name.error,
                })}
              </span>
            )}
            {siteEdmData.uploaded_bills.last_bill_name.warning && (
              <span className={styles.uploadedBillwarning}>
                {getErrorOrWarningIconForField({
                  warning: siteEdmData.uploaded_bills.last_bill_name.warning,
                })}
              </span>
            )}
          </span>
        </div>
      )}
      <span className={styles.uploadBills}>
        <Button
          variant="outlined"
          onClick={() => {
            setUploadSiteId(siteId);
          }}
          className={styles.customButton}
        >
          <UploadBillIcon height={20} width={20} />
          Upload Bills
        </Button>
      </span>
    </div>
  );
};
export default UploadBillData;
