import { clearResourcePath, getResourcePathById } from '../actions/paths';

const updateClearMiddleware = store => next => action => {
  const reg = /PATCH_.*_SUCCESS/g;
  const isUpdateSuccessAction = action.type.match(reg);
  if (isUpdateSuccessAction) {
    store.dispatch(clearResourcePath(action.id));
    if (action.id !== undefined) store.dispatch(getResourcePathById(action.id));
  }
  return next(action);
};

export default updateClearMiddleware;
