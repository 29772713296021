import React from 'react';
import styles from './FilterMenuContent.module.css';
import { classNames } from '@energybox/react-ui-library/dist/utils';

type Props = {
  className?: string;
  children?: any;
  style?: any;
};

const FilterMenuContent = ({ className, children, style }: Props) => (
  <div
    className={classNames(styles.root, className)}
    style={style ? style : {}}
  >
    {children}
  </div>
);

export default FilterMenuContent;
