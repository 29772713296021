import {
  Card,
  CardContent,
  CardTitle,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import { SiteEnergyCalculationMode } from '@energybox/react-ui-library/dist/types';
import {
  global,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSubscribeToSiteEnergyPros from '../../../hooks/streamApi/useSubscribeToSiteEnergyPros';
import { useGetEnergyProsBySiteId } from '../../../hooks/useGetEnergyProsBySiteId';
import { ApplicationState } from '../../../reducers';
import { EditableFields } from '../../../reducers/sites';
import {
  ProcessedEnergySensor,
  ProcessedEnergySensorsById,
} from '../../../types/energyDevice';
import {
  getEnergyDeviceSensorsOfEnergyPro,
  ProcessedSubscribedEnergySensor,
  processSubscribedEnergyProSensors,
} from '../../../utils/energyPro';
import styles from './SiteTotalBreakersLiveReadingTable.module.css';
import { formatNumber } from '@energybox/react-ui-library/dist/utils/number';

type Props = {
  siteId: string;
};

const SiteTotalBreakersLiveReadingTable: React.FC<Props> = ({ siteId }) => {
  const siteEnergyPros = useGetEnergyProsBySiteId(siteId);
  const siteSubscribedEnergyPros = useSubscribeToSiteEnergyPros(siteEnergyPros);

  const siteEnergyDeviceSensorsById: ProcessedEnergySensorsById = useMemo(() => {
    const siteEnergySensors: ProcessedEnergySensor[] = [];

    siteEnergyPros.forEach(ePro => {
      const energyDeviceSensors = getEnergyDeviceSensorsOfEnergyPro(ePro) || [];
      siteEnergySensors.push(...energyDeviceSensors);
    });

    return mapArrayToObject(siteEnergySensors);
  }, [siteEnergyPros]);

  const energyCalculationMode = useSelector(({ sites }: ApplicationState) => {
    const siteConfigs: EditableFields | undefined =
      sites.editById[siteId]?.fields;
    if (!siteConfigs) return undefined;
    return siteConfigs.energyCalculationMode;
  });

  const processedData = useMemo(() => {
    if (!energyCalculationMode) return [];
    const processedSubscribedEnergySensors: ProcessedSubscribedEnergySensor[] = [];

    siteSubscribedEnergyPros.forEach(subscribedEnergyPro => {
      processedSubscribedEnergySensors.push(
        ...processSubscribedEnergyProSensors(
          subscribedEnergyPro,
          siteEnergyDeviceSensorsById
        )
      );
    });

    const liveEnergyDeviceSensorsContributingToSiteTotal = processedSubscribedEnergySensors.filter(
      ({ sensorId, isSiteTotal, isMainBreaker, isMainPanel }) => {
        // TODO: for stream-api, it will return disconnected sensor readings with a null id for some EPro
        if (sensorId === null) return false;
        switch (energyCalculationMode) {
          case SiteEnergyCalculationMode.MDP_MAINS:
            if (isMainPanel && isMainBreaker) return true;
            break;
          case SiteEnergyCalculationMode.MDP_BREAKERS:
            if (isMainPanel && !isMainBreaker) return true;
            break;
          case SiteEnergyCalculationMode.DP_MAINS:
            if (!isMainPanel && isMainBreaker) return true;
            break;
          case SiteEnergyCalculationMode.DP_BREAKERS:
            if (!isMainPanel && !isMainBreaker) return true;
            break;
          case SiteEnergyCalculationMode.FLAGGED_BREAKERS:
          default:
            if (isSiteTotal) return true;
        }
        return false;
      }
    );

    // Add up active power and current, name the Breaker as 'Total'
    const total = liveEnergyDeviceSensorsContributingToSiteTotal.reduce(
      (acc, { powerActive, current }) => {
        acc.powerActive += powerActive;
        acc.current += current;
        return acc;
      },
      {
        breakerName: 'Total',
        powerActive: 0,
        current: 0,
        isTotal: true,
      }
    );

    return [total, ...liveEnergyDeviceSensorsContributingToSiteTotal];
  }, [
    siteSubscribedEnergyPros,
    siteEnergyDeviceSensorsById,
    energyCalculationMode,
  ]);

  return (
    <Card>
      <CardContent>
        <CardTitle className={styles.cardTitle}>
          Site Total Calculation
        </CardTitle>

        <Table highlightAlternateRows data={processedData} columns={columns} />
      </CardContent>
    </Card>
  );
};

const columns: Columns<ProcessedSubscribedEnergySensor>[] = [
  {
    header: 'Breaker',
    cellContent: (s: ProcessedSubscribedEnergySensor) =>
      s.isTotal ? (
        <span>
          <b>{s.breakerName || global.NOT_AVAILABLE}</b>
        </span>
      ) : (
        <span>{s.breakerName || global.NOT_AVAILABLE}</span>
      ),
  },
  {
    header: 'Equipment',
    cellContent: (s: ProcessedSubscribedEnergySensor) => (
      <span>{s.equipmentTitle || global.NOT_AVAILABLE}</span>
    ),
  },
  {
    header: 'Distribution Panel',
    cellContent: (s: ProcessedSubscribedEnergySensor) => (
      <span>{s.panelTitle || global.NOT_AVAILABLE}</span>
    ),
  },
  {
    header: 'Active Power (kW)',
    cellContent: (s: ProcessedSubscribedEnergySensor) =>
      s.isTotal ? (
        <span>
          <b>{formatNumber(s.powerActive, 2)}</b>
        </span>
      ) : (
        <span>{s.powerActive}</span>
      ),
  },
  {
    header: 'Reactive Power (VAr)',
    cellContent: (s: ProcessedSubscribedEnergySensor) => (
      <span>{s.powerReactive || global.NOT_AVAILABLE}</span>
    ),
  },
  {
    header: 'Current (A)',
    cellContent: (s: ProcessedSubscribedEnergySensor) =>
      s.isTotal ? (
        <span>
          <b>{formatNumber(s.current, 1)}</b>
        </span>
      ) : (
        <span>{s.current}</span>
      ),
  },
  {
    header: 'Power Factor',
    cellContent: (s: ProcessedSubscribedEnergySensor) => (
      <span>{s.powerFactor || global.NOT_AVAILABLE}</span>
    ),
  },
];

export default SiteTotalBreakersLiveReadingTable;
