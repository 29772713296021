import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { Site, Space } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { collectSpacesRecursive } from '../actions/spaces';
import { ApplicationState } from '../reducers';

interface OwnProps {
  onSelect: (spaceId: number) => void;
  value?: string;
  ownerId?: string;
  currentSiteId: string;
  error: boolean;
  customErrorText?: string;
  disabled?: boolean;
}

interface Props extends OwnProps {
  site?: Site;
  spaces: Space[];
}

interface State {
  search: string;
}

class ParentSelectForSpaces extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  selectedName = (spaces: Space[], site: Site, value: string | undefined) => {
    const siteSelected = site.id.toString() === value;
    const selectedSpace = spaces.find(space => space.id.toString() === value);

    if (siteSelected) {
      return site.title;
    } else if (selectedSpace) {
      return selectedSpace.title;
    } else {
      return undefined;
    }
  };

  render() {
    const {
      spaces,
      value,
      onSelect,
      site,
      error,
      customErrorText,
      disabled,
    } = this.props;
    const { search } = this.state;
    const listedResources = spaces.filter((space: Space) => {
      return space && hasSubstr(space.title, search);
    });

    if (!site) {
      return <Loader size={12} />;
    }

    return (
      <Select
        disabled={disabled}
        variant={'select'}
        onClick={this.resetSearch.bind(this)}
        title={this.selectedName(spaces, site, value)}
        value={value}
        error={error}
        customErrorText={customErrorText}
      >
        <SelectSearch
          error={listedResources.length === 0}
          onChange={this.searchChange.bind(this)}
          value={search}
        />
        <SelectItem
          key={site.id}
          isSelected={site.id.toString() === value}
          onSelect={() => onSelect(site.id)}
        >
          {`Site: ${site.title}`}
        </SelectItem>
        {listedResources.map((resource: any) => (
          <SelectItem
            key={resource.id}
            isSelected={resource.id.toString() === value}
            onSelect={() => onSelect(resource.id)}
          >
            {resource.title}
          </SelectItem>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = (
  { spaces, sites }: ApplicationState,
  { currentSiteId, ownerId }: OwnProps
) => ({
  site: sites.sitesById[currentSiteId],
  spaces: collectSpacesRecursive(
    currentSiteId,
    spaces.spaceIdsByParentId,
    spaces.spacesById
  ).filter((item: Space) => item !== undefined && String(item.id) !== ownerId),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentSelectForSpaces);
