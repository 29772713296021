import { AreaUnitApiValue } from '@energybox/react-ui-library/dist/types/Global';
import { countDecimalsMinTwo } from '@energybox/react-ui-library/dist/utils';
import { convertSquareMetersToFeet } from '@energybox/react-ui-library/dist/utils/util';
import { useEffect, useState } from 'react';
import { useUserSettingAreaUnit } from './useAppDetails';

export const useConvertedArea = (
  unconvertedArea: number | '' | null,
  shouldRound: boolean
): {
  userAreaUnit: AreaUnitApiValue | null
  convertedArea: string | null;
  setConvertedArea: React.Dispatch<React.SetStateAction<string | null>>;
} => {
  const userAreaUnit = useUserSettingAreaUnit()
  const [onlyConvertInitialLoadFlag, setOnlyConvertInitialLoadFlag] = useState(
    false
  );
  // Convert the area from M to FT if necessary, on initial load only
  let convertedAreaHelper: number | string | null = null;
  useEffect(() => {
    setOnlyConvertInitialLoadFlag(false);
  }, [userAreaUnit, unconvertedArea, shouldRound])
  if (
    !onlyConvertInitialLoadFlag &&
    unconvertedArea !== null &&
    unconvertedArea !== '' &&
    userAreaUnit === AreaUnitApiValue.FT2
  ) {
    setOnlyConvertInitialLoadFlag(true);
    convertedAreaHelper = convertSquareMetersToFeet(
      unconvertedArea,
      countDecimalsMinTwo(unconvertedArea)
    );
    convertedAreaHelper = shouldRound
      ? Math.round(convertedAreaHelper)
      : convertedAreaHelper;
  } else if (unconvertedArea === '') {
    convertedAreaHelper = null;
  } else {
    convertedAreaHelper = unconvertedArea;
  }
  const [convertedArea, setConvertedArea] = useState<string | null>(
    convertedAreaHelper !== null ? `${convertedAreaHelper}` : null
  );
  return { userAreaUnit , convertedArea, setConvertedArea };
};

export default useConvertedArea;
