import { Scheduler } from '@energybox/react-ui-library/dist/types';
import SelectTimeTable from '../../Selects/SelectTimeTable';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { useEffect } from 'react';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

type Props = {
  activeControl: Scheduler;
  siteId: string;
  onChange: onChange;
  equipmentId: number;
};

const EditModeTimetable: React.FC<Props> = ({
  activeControl,
  siteId,
  onChange,
  equipmentId,
}) => {
  const schedulerId = activeControl?.id;
  const lightSensor = activeControl?.lightSensorSettings;
  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });
  const fields = schedulerControls?.fields;
  const controlBoardId = fields?.lightSensorSettings?.controlBoardId;
  const formErrorsVisible = schedulerControls?.formErrorsVisible;
  const formErrors = schedulerControls?.formErrors;
  const selectedScheduleTimeTableId = fields?.timetableId;
  const selectedLightTimeTableId = fields?.lightSensorSettings?.timetableId;

  const hasAtLeastOneTimetableBeenSelected =
    isDefined(selectedScheduleTimeTableId) ||
    isDefined(selectedLightTimeTableId);

  const hasInvalidLightTimetable =
    !hasAtLeastOneTimetableBeenSelected ||
    (isDefined(controlBoardId) && !isDefined(selectedLightTimeTableId));

  const isControlBoardSelected =
    isDefined(controlBoardId) && controlBoardId !== -1;
  useEffect(() => {
    if (lightSensor?.controlBoardId) {
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'timetableId'],
        lightSensor?.timetableId
      );
    }
  }, []);

  return (
    <div>
      <SelectTimeTable
        isOrgLevel={true}
        siteId={+siteId}
        value={String(fields?.lightSensorSettings?.timetableId)}
        disabled={!isControlBoardSelected}
        onSelect={(value: string | number) => {
          if (schedulerId) {
            onChange(
              String(schedulerId),
              ['lightSensorSettings', 'timetableId'],
              value
            );
          } else {
            onChange(
              `new${equipmentId}`,
              ['lightSensorSettings', 'timetableId'],
              value
            );
          }
        }}
        error={
          formErrorsVisible &&
          isDefined(controlBoardId) &&
          (!!formErrors?.['lightSensorSettings.timetableId'] ||
            hasInvalidLightTimetable)
        }
        customErrorText={
          hasInvalidLightTimetable
            ? 'Invalid Timetable'
            : formErrors?.['lightSensorSettings.timetableId'] &&
              formErrors.formErrors['lightSensorSettings.timetableId'].join(
                ', '
              )
        }
      />
    </div>
  );
};

export default EditModeTimetable;
