import { classNames } from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import history from '../../history';
import styles from './ShowDetailPageHeader.module.css';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { determineUserRoleInPlatform } from '../../utils/user';
import { UserPlatformAccess } from '../../types/user';
import { useGetSite } from '../../hooks/useSites';

interface Props {
  name: string;
  description: string | React.ReactNode;
  resourceName: string;
  isUser?: boolean;
  pathBack?: string;
  hideDelete: boolean;
  onDelete?: () => void;
  listOptions?: {
    access?: UserPlatformAccess[];
    title: string;
    onSelect: () => void;
    isDeleteItem?: boolean;
    icon?: React.ReactNode;
  }[];
  // add siteId when you want to hide the delete button according to the user role
  siteId?: number;
  isOnline?: boolean;
}

const ShowDetailPageHeader = ({
  name,
  description,
  resourceName,
  pathBack,
  hideDelete,
  onDelete,
  listOptions,
  siteId,
  isOnline = true,
}: Props) => {
  const currentUser = useCurrentUser();
  const dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
  const site = useGetSite(siteId);
  const orgId = site?.organizationId;

  const userPlatformAccess = determineUserRoleInPlatform(
    currentUser,
    siteId,
    orgId
  );

  useEffect(() => {
    if (site) {
      document.title = site?.title || '';
    }
  }, [site]);

  let actionsComponent: React.ReactNode;

  if (siteId && userPlatformAccess === UserPlatformAccess.DEFAULT) {
    actionsComponent = null;
  } else {
    actionsComponent = listOptions ? (
      <div className={styles.dropdownMenu}>
        <MenuDropdown
          icon={
            <span className={styles.dropdownTitle}>
              •••&nbsp;&nbsp;&nbsp;Actions
            </span>
          }
        >
          {listOptions
            .filter(
              item =>
                item.access === undefined ||
                item.access.includes(userPlatformAccess)
            )
            .map(item => (
              <MenuDropdownItem
                isRed={item.isDeleteItem}
                onSelect={item.onSelect}
                disabled={!isOnline && !item.isDeleteItem}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  {item.title}
                  {item.icon}
                </div>
              </MenuDropdownItem>
            ))}
        </MenuDropdown>
      </div>
    ) : (
      <div className={styles.delete}>
        <Button variant="text" onClick={onDelete}>
          <span className={styles.deleteButtonText}>
            {' '}
            Delete {resourceName}
          </span>
        </Button>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.root,
        listOptions ? styles.listColumns : styles.deleteColumns
      )}
    >
      <span
        role="link"
        onClick={() => {
          pathBack ? history.push(pathBack) : history.goBack();
        }}
        className={classNames(styles.button, styles.buttonBack)}
      >
        <IoIosArrowBack />
      </span>

      <div>
        <div className={styles.title}>
          <div>
            <b>{!dublicateSentinelkey ? name : ''}</b>
            <div>{!dublicateSentinelkey ? description : ''}</div>
          </div>
        </div>
      </div>

      {!dublicateSentinelkey ? !hideDelete && actionsComponent : ''}
    </div>
  );
};

ShowDetailPageHeader.defaultProps = {
  hideDelete: false,
};

export default ShowDetailPageHeader;
