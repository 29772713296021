import {
  MenuDropdown,
  MenuDropdownItem,
  Select,
} from '@energybox/react-ui-library/dist/components';
import {
  Equipment,
  WorkingMode,
  WorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import styles from './EditControlModeDropdown.module.css';
import { onChange } from '../LightingEquipmentContorls/LightingEquipmentData';
import { useEffect, useState } from 'react';
import { useControlStatus } from '../../../hooks/streamApi/useControlStatus';

interface DropdownControlProps {
  equipment: Equipment;
  onChange: onChange;
  isHvac?: boolean;
}

const EditControlModeDropdown: React.FC<DropdownControlProps> = ({
  equipment,
  onChange,
  isHvac,
}) => {
  const [tempId, setTempId] = useState('');
  const equipmentId = equipment.id;
  const schedulerId = equipment.activeControl?.id;
  const controlStatus = useControlStatus(schedulerId);

  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });

  const hvacControls = useSelector(({ hvacControls }: ApplicationState) => {
    return equipmentId ? hvacControls.editById[equipmentId] : undefined;
  });

  const fields = schedulerControls?.fields;
  const controlMode = fields?.workingMode;
  const hvacControlMode = hvacControls?.workingMode;
  const ControlModeStatus = hvacControlMode ?? controlMode;

  const menuDropdownIcon = () => {
    const selectValue = WorkingModeLabel[ControlModeStatus || ''];
    return (
      <div className={styles.selectContainer}>
        <Select
          title={selectValue}
          value={selectValue}
          size="custom"
          fullWidth={false}
          selectButtonContainerClassName={styles.selectButtonContainer}
        />
      </div>
    );
  };

  const onSelectMode = mode => {
    if (isHvac) {
      onChange(String(equipmentId), 'workingMode', mode);
    } else {
      if (schedulerId) {
        onChange(String(schedulerId), 'workingMode', mode);
      } else {
        setTempId(`new${equipmentId}`);
        onChange(`new${equipmentId}`, 'workingMode', mode);
      }
    }
  };

  useEffect(() => {
    if (schedulerId && !isHvac) {
      onChange(String(schedulerId), 'workingMode', controlStatus?.workingMode);
    }
  }, []);

  return (
    <div className={styles.dropdownButtonContainer}>
      <MenuDropdown icon={menuDropdownIcon}>
        {Object.values(WorkingMode).map(mode => {
          return (
            <MenuDropdownItem
              key={`schedulerControlButton-${equipment?.id}-${mode}`}
              onSelect={() => onSelectMode(mode)}
              isSelected={mode === ControlModeStatus}
            >
              {WorkingModeLabel[mode]}
            </MenuDropdownItem>
          );
        })}
      </MenuDropdown>
    </div>
  );
};

export default EditControlModeDropdown;
