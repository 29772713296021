import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';

interface Props {
  onSelect: (currency: string) => void;
  value?: string;
  error: boolean;
  customErrorText?: string;
}

type Currency = {
  name: string;
  code: string;
};

interface State {
  currencies: Currency[];
}

class SelectCurrency extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currencies: [
        {
          name: 'USD',
          code: 'USD',
        },
        {
          name: 'HKD',
          code: 'HKD',
        },
        {
          name: 'CNY',
          code: 'CNY',
        },
        {
          name: 'EUR',
          code: 'EUR',
        },
        {
          name: 'IDR',
          code: 'IDR',
        },
        {
          name: 'MXN',
          code: 'MXN',
        },
        {
          name: 'MUR',
          code: 'MUR',
        },
        {
          name: 'TWD',
          code: 'TWD',
        },
        {
          name: 'CAD',
          code: 'CAD',
        },
        {
          name: 'SGD',
          code: 'SGD',
        },
        {
          name: 'JPY',
          code: 'JPY',
        },
        {
          name: 'KRW',
          code: 'KRW',
        },
        {
          name: 'VND',
          code: 'VND',
        },
        {
          name: 'MYR',
          code: 'MYR',
        },
      ],
    };
  }

  render() {
    const { onSelect, value, error, customErrorText } = this.props;
    const { currencies } = this.state;

    return (
      <Select
        title={value}
        variant={'select'}
        error={error}
        customErrorText={customErrorText}
      >
        {currencies.map((currency: Currency) => (
          <SelectItem
            key={currency.name}
            isSelected={currency.code === value}
            onSelect={() => {
              onSelect(currency.code);
            }}
          >
            {currency.name}
          </SelectItem>
        ))}
      </Select>
    );
  }
}

export default SelectCurrency;
