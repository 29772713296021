import { tracingEnabledServices } from '../config';
import otel_api, { Span, SpanStatusCode, trace } from '@opentelemetry/api';
import { updateLoggingContext } from './initialize';

class Tracer {
  private tracedServices = tracingEnabledServices();

  private traceParent: Span | undefined = undefined;
  private traceParentCtx;
  private traceCtx;
  private spanCache = new Map<string, Span>();
  private lastSpanEndTime: Date | undefined = undefined;
  private tracer = trace.getTracer('api-middleware');
  private currentTracingScope = undefined;
  private timeout: NodeJS.Timeout | undefined = undefined;
  static SpanStatusCode = SpanStatusCode;

  constructor() {}

  startSpan(spanName: string) {
    if (this.traceParent != undefined) {
      otel_api.trace.setSpan(otel_api.context.active(), this.traceParent);
      const span = this.tracer.startSpan(spanName, undefined, this.traceCtx);
      this.spanCache.set(span.spanContext().spanId, span);
      updateLoggingContext({ span_id: span.spanContext().spanId });
      return span;
    } else {
      console.log('no parent span');
    }

    return undefined;
  }

  endSpan(
    span: Span | undefined,
    p: { code: SpanStatusCode } | { code: SpanStatusCode; message: string }
  ) {
    if (span != undefined) {
      span.setStatus({ ...p });
      span.end();
      this.spanCache.delete(span.spanContext().spanId);
      this.lastSpanEndTime = new Date();
      updateLoggingContext({ span_id: undefined });
    }
  }
  genTraceHeader(span: Span | undefined, service: any) {
    if (this.tracedServices.has(service)) {
      return {
        traceparent:
          '00-' +
          span?.spanContext().traceId +
          '-' +
          span?.spanContext().spanId +
          '-01',
      };
    } else {
      return {};
    }
  }

  checkIfTraceStillActive() {
    if (this.spanCache.size == 0) {
      this.endTrace();
    } else {
      this.scheduleTraceCheck(2000);
    }
  }
  startTrace(tracingScope) {
    if (tracingScope == this.currentTracingScope) {
      console.log('duplicate trace start. ignoring');
      return;
    }
    this.endTrace();
    this.currentTracingScope = tracingScope;

    this.traceParent = this.tracer.startSpan(tracingScope);
    this.traceCtx = otel_api.trace.setSpan(
      otel_api.context.active(),
      this.traceParent
    );
    this.traceParentCtx = this.traceParent.spanContext();

    this.lastSpanEndTime = new Date();
    updateLoggingContext({ trace_id: this.traceParent.spanContext().traceId });

    this.scheduleTraceCheck(10000);
  }

  endTrace() {
    if (this.traceParentCtx) {
      otel_api.context.with(this.traceParentCtx, () => {
        this.traceParent?.end(this.lastSpanEndTime);
      });

      this.traceParent = undefined;
      updateLoggingContext({ trace_id: undefined });
    }
  }

  scheduleTraceCheck(timeout: number) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => this.checkIfTraceStillActive(), timeout);
  }
}

export { Tracer };
