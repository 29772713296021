import React from 'react';

type Props = {
  size?: number;
  className?: string;
};

export default ({ size, className, ...rest }: Props) => (
  <svg
    xmlnsXlink="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 15 15"
    className={className}
    {...rest}
  >
    <path
      d="M9,2.5A6.5,6.5,0,1,1,2.5,9,6.51,6.51,0,0,1,9,2.5m0-1A7.5,7.5,0,1,0,16.5,9,7.5,7.5,0,0,0,9,1.5Z"
      transform="translate(-1.5 -1.5)"
    />
    <path
      d="M9,4.5a.5.5,0,0,0-.5.5V9h0a.5.5,0,0,0,.5.5h2a.5.5,0,0,0,0-1H9.5V5A.5.5,0,0,0,9,4.5Z"
      transform="translate(-1.5 -1.5)"
    />
  </svg>
);
