import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  SiteInstallationStatus,
  SolutionInstallationStatus,
  SiteDeploymentStatusLabels,
  SolutionDeploymentStatusLabels,
} from '@energybox/react-ui-library/dist/types/Site';
import React from 'react';

type Props = {
  installationType: 'SITE' | 'SOLUTION';
  value: number | string | null;
  onSelect: (
    value: SiteInstallationStatus | SolutionInstallationStatus
  ) => void;
  error?: boolean;
  customErrorText?: string;
};

const InstallationMapping = {
  SITE: {
    labels: SiteDeploymentStatusLabels,
    status: SiteInstallationStatus,
  },
  SOLUTION: {
    labels: SolutionDeploymentStatusLabels,
    status: SolutionInstallationStatus,
  },
};

const SelectSiteInstallationStatus: React.FC<Props> = ({
  installationType,
  value,
  onSelect,
  error,
  customErrorText,
}) => {
  const { labels, status } = InstallationMapping[installationType];
  const options = Object.values(status);
  return (
    <Select
      title={value ? labels[value] : ''}
      error={error}
      customErrorText={customErrorText}
    >
      {options.map(v => (
        <SelectItem
          key={v}
          isSelected={v === value}
          onSelect={() => onSelect(v)}
        >
          {labels[v]}
        </SelectItem>
      ))}
    </Select>
  );
};

export default SelectSiteInstallationStatus;
