import { Actions } from '../actions/superHub';
import * as R from 'ramda';

export type SuperHubStatus = {
  reboot: {
    isLoading: boolean;
    status: string;
    success: boolean;
    error: boolean;
  };
  clearBuffer: {
    isLoading: boolean;
    status: string;
    success: boolean;
    error: boolean;
  };
};

export type ThermostatRebootStatus = {
  reboot: {
    isLoading: boolean;
    status: string;
    success: boolean;
    error: boolean;
  };
};

export type SuperHubStatusBySN = {
  [sn: string]: SuperHubStatus;
};

export type ThermostatRebootStatusByID = {
  [id: number]: ThermostatRebootStatus;
};

export interface SuperHubState {
  statusBySN: SuperHubStatusBySN;
  rebootThermostatById: ThermostatRebootStatusByID;
}

export const initialState: SuperHubState = {
  statusBySN: {},
  rebootThermostatById: {},
};

const superhub = (state: SuperHubState = initialState, action: any) => {
  switch (action.type) {
    case Actions.REBOOT_SUPERHUB_LOADING:
      return R.pipe(
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'isLoading'],
          true
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'status'],
          ''
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'success'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'error'],
          false
        )
      )(state);

    case Actions.REBOOT_SUPERHUB_ERROR:
      return R.pipe(
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'isLoading'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'status'],
          action.data
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'success'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'error'],
          true
        )
      )(state);

    case Actions.REBOOT_SUPERHUB_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'isLoading'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'status'],
          action.data
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'success'],
          true
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'reboot', 'error'],
          false
        )
      )(state);

    case Actions.CLEAR_SUPERHUB_BUFFER_LOADING:
      return R.pipe(
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'isLoading'],
          true
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'status'],
          ''
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'success'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'error'],
          false
        )
      )(state);

    case Actions.CLEAR_SUPERHUB_BUFFER_ERROR:
      return R.pipe(
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'isLoading'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'status'],
          action.data
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'success'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'error'],
          true
        )
      )(state);

    case Actions.CLEAR_SUPERHUB_BUFFER_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'isLoading'],
          false
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'status'],
          action.data
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'success'],
          true
        ),
        R.assocPath(
          ['statusBySN', action.serialNumber, 'clearBuffer', 'error'],
          false
        )
      )(state);

    case Actions.REBOOT_THERMOSTAT_LOADING:
      return R.pipe(
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'isLoading'],
          true
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'status'],
          ''
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'success'],
          false
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'error'],
          false
        )
      )(state);

    case Actions.REBOOT_THERMOSTAT_ERROR:
      return R.pipe(
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'isLoading'],
          false
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'status'],
          action.data
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'success'],
          false
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'error'],
          true
        )
      )(state);

    case Actions.REBOOT_THERMOSTAT_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'isLoading'],
          false
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'status'],
          action.data
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'success'],
          true
        ),
        R.assocPath(
          ['rebootThermostatById', action.thermostatId, 'reboot', 'error'],
          false
        )
      )(state);

    default:
      return state;
  }
};

export default superhub;
