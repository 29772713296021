const apiBase = '/api/v1/superHub/admin-portal';

export enum Actions {
  REBOOT_SUPERHUB_SUCCESS = '@@superHub/REBOOT_SUPERHUB_SUCCESS',
  REBOOT_SUPERHUB_LOADING = '@@superHub/REBOOT_SUPERHUB_LOADING',
  REBOOT_SUPERHUB_ERROR = '@@superHub/REBOOT_SUPERHUB_ERROR',

  CLEAR_SUPERHUB_BUFFER_SUCCESS = '@@superHub/CLEAR_SUPERHUB_BUFFER_SUCCESS',
  CLEAR_SUPERHUB_BUFFER_LOADING = '@@superHub/CLEAR_SUPERHUB_BUFFER_LOADING',
  CLEAR_SUPERHUB_BUFFER_ERROR = '@@superHub/CLEAR_SUPERHUB_BUFFER_ERROR',

  REBOOT_THERMOSTAT_SUCCESS = '@@superHub/REBOOT_THERMOSTAT_SUCCESS',
  REBOOT_THERMOSTAT_LOADING = '@@superHub/REBOOT_THERMOSTAT_LOADING',
  REBOOT_THERMOSTAT_ERROR = '@@superHub/REBOOT_THERMOSTAT_ERROR',
}

export type SuperHubParam = {
  serialNumber: string;
};

export const rebootSuperHub = (serialNumber: string) => ({
  type: 'API_POST',
  path: `${apiBase}/reboot`,
  payload: { serialNumber },
  success: { type: Actions.REBOOT_SUPERHUB_SUCCESS, serialNumber },
  error: { type: Actions.REBOOT_SUPERHUB_ERROR, serialNumber },
  loading: { type: Actions.REBOOT_SUPERHUB_LOADING, serialNumber },
});

export const clearSuperHubBuffer = (serialNumber: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/clearBuffering`,
  payload: { serialNumber },
  success: { type: Actions.CLEAR_SUPERHUB_BUFFER_SUCCESS, serialNumber },
  error: { type: Actions.CLEAR_SUPERHUB_BUFFER_ERROR, serialNumber },
  loading: { type: Actions.CLEAR_SUPERHUB_BUFFER_LOADING, serialNumber },
});

export const rebootThermostat = (thermostatId: number) => ({
  type: 'API_POST',
  path: `${apiBase}/rebootThermostatById?thermostatId=${thermostatId}`,
  success: { type: Actions.REBOOT_THERMOSTAT_SUCCESS, thermostatId },
  error: { type: Actions.REBOOT_THERMOSTAT_ERROR, thermostatId },
  loading: { type: Actions.REBOOT_THERMOSTAT_LOADING, thermostatId },
});
