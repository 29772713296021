import React from 'react';
import { fetchDownloadFile } from '../../../../actions/files';
import FileUploadCell from '../../../../components/FileUploader/FileUploadCell';
import { useUpload } from '../../../../hooks/fileUpload/useUpload';
import {
  UploadFile,
  UploadFileUploading,
  UploadResponse,
} from '../../../../types/upload';

interface Props {
  upload: UploadFile<{}>;
  fileIdx: number;
  uploadDidChange: (idx: number, newUpload: UploadFile<{}>) => void;
  remove: (idx: number) => void;
  uploadFile: (upload: UploadFileUploading<{}>) => Promise<UploadResponse>;
  isUndeletable?: boolean;
}

const UploadInstallDocCell: React.FC<Props> = ({
  upload: uploadFromProp,
  fileIdx,
  uploadDidChange,
  remove,
  uploadFile,
  isUndeletable,
}) => {
  const {
    retry,
    upload,
    uploadDidFinish,
    status,
    uploadResult,
    viewFile,
  } = useUpload({
    uploadFromProp,
    fileIdx,
    uploadDidChange,
    downloadFile: fetchDownloadFile,
  });

  return (
    <FileUploadCell
      upload={upload}
      uploadFile={uploadFile}
      status={status}
      uploadDidFinish={uploadDidFinish}
      retry={retry}
      viewFile={viewFile}
      remove={() => remove(fileIdx)}
      isHorizontal
      isUndeletable={isUndeletable}
      uploadResult={uploadResult}
    />
  );
};

export default UploadInstallDocCell;
