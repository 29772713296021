import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPairedHubsByResourceId } from '../actions/gateways';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { ApplicationState } from '../reducers';
import { HubsByPairedSensorId } from '../reducers/gateways';

//for current use case, resourceId is siteId or orgId
//to get all paired hubs underneath that resourceId
//note that however, the return value is an object of hubsByPairedSensorId
export const useGetPairedHubsByResourceId = (
  resourceId: number | string | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(resourceId)) {
      dispatch(getPairedHubsByResourceId(resourceId));
    }
  }, [resourceId]);

  const hubsByPairedSensorId = useSelector<
    ApplicationState,
    HubsByPairedSensorId
  >(({ gateways }) => {
    return gateways.hubsByPairedSensorId;
  });

  return hubsByPairedSensorId;
};
