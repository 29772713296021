import {
  Sensor,
  SensorType,
  SentinelTarget,
  SentinelTargetType,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { fetchSensors } from '../../../../actions/sensorsFetch';
import RolloutSelector from './RolloutSelector';
import styles from './StepRollout.module.css';

type Props = {
  site: Site;
  sensorTypes: SensorType[];
  handleTargetsSelect: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (sentinelTargetIds: number[]) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  isCollapsed: boolean;
  updateEquipmentList: (list: any) => void;
  upDatedSentielId: (sentinelId: any) => void;
};

type State = {
  sensors: Sensor[];
  isLoading: boolean;
};

class StepRolloutSensorsHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sensors: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site, sensorTypes } = this.props;

      const sensors = await fetchSensors({
        siteIds: [site.id],
        sensorTypes,
      });

      handleSetResourceRelation(
        site.id,
        sensors.map((e: Sensor) => e.id)
      );

      const selectedBySite = sensors
        ? sensors.reduce(
            (sum, item) =>
              this.props.selected.includes(item.id) ? sum + 1 : sum,
            0
          )
        : 0;

      const sentinelTargetTypeID = SentinelTargetType.SENSOR;

      this.props.updateEquipmentList(sensors);
      this.props.upDatedSentielId(sentinelTargetTypeID);

      this.setState({
        sensors,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { sensors = [], isLoading } = this.state;
    const {
      selected,
      isCollapsed,
      handleTargetsSelect,
      handleTargetDelete,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedBySite = sensors
      ? sensors.reduce(
          (sum, item) => (selected.includes(item.id) ? sum + 1 : sum),
          0
        )
      : 0;

    const total = sensors.length;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '35%' }}>Sensors</th>
              <th style={{ width: '25%' }}>{isCollapsed && 'Attached to'}</th>
              <th style={{ width: '25%' }}>{isCollapsed && 'Location'}</th>
              <th
                style={{ width: '15%' }}
                className={classNames(styles.actionHeader, styles.action)}
              >
                <RolloutSelector
                  isCollapsed={isCollapsed}
                  selected={selectedBySite}
                  totalSelected={total}
                  onDelete={() =>
                    handleTargetDelete(sensors.map(item => item.id))
                  }
                  onSelect={() =>
                    handleTargetsSelect(
                      sensors.map(item => ({
                        targetId: item.id,
                        targetType: SentinelTargetType.SENSOR,
                      }))
                    )
                  }
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

export default StepRolloutSensorsHeader;
