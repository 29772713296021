import CardList, {
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  IconCheckboxContained,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { RunTestDataResults } from '../../../reducers/inspection';
import { getEnergyPro } from '../../../actions/energy_pros';
import ConfigurationTestCircuitResults from './ConfigurationTestCircuitResults';
import styles from './EquipmentConfigurationTestResults.module.css';

type Props = {
  energyProId: number;
  energyProTitle: string;
  busDevice: RunTestDataResults[];
  siteId: number;
  isUnmappedRelays?: boolean;
};

export const ConfigurationResultData: React.FC<Props> = ({
  energyProId,
  energyProTitle,
  busDevice,
  siteId,
  isUnmappedRelays = false,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEnergyPro(energyProId));
  }, []);

  const energyPro = useSelector(({ energyPros }: ApplicationState) => {
    return energyPros.energyProsById[energyProId];
  });

  const header: CardListHeader[] = [
    {
      width: '2',
      content: 'Device',
    },
    {
      width: '1',
      content: 'Index',
    },
    {
      width: '1',
      content: 'Current(A)',
    },
    {
      width: '2',
      content: 'Breaker',
    },
    {
      width: '3',
      content: 'Mapped Equipment',
    },
    {
      width: '3',
      content: 'Result',
    },
  ];

  const data: CardListRowData[] = busDevice?.map(
    ({ bus_device_title, circuit_results }) => {
      const extractPortNumber = port_title => {
        if (typeof port_title !== 'string' || !port_title.trim()) {
          return null;
        }

        const regex = /\d+/;
        const match = port_title.match(regex);

        if (match) {
          return parseInt(match[0], 10);
        } else {
          return null;
        }
      };

      const sortedCircuitResults = [...circuit_results].sort((a, b) => {
        const portNumberA = extractPortNumber(a?.port_title);
        const portNumberB = extractPortNumber(b?.port_title);

        // If either portNumberA or portNumberB is null, treat it as zero for sorting.
        const numA = portNumberA !== null ? portNumberA : 0;
        const numB = portNumberB !== null ? portNumberB : 0;

        return numA - numB;
      });

      return {
        key: `${bus_device_title}`,
        content: (
          <>
            <Cell width="2">
              <div style={{ fontWeight: 'bold' }}>{energyProTitle}</div>
              <div>{bus_device_title}</div>
            </Cell>
            {circuit_results.length ? (
              sortedCircuitResults.map((result, index) => {
                return (
                  <ConfigurationTestCircuitResults
                    result={result}
                    index={index}
                    energyPro={energyPro}
                    siteId={siteId}
                    key={energyProTitle}
                  />
                );
              })
            ) : (
              <>
                <Cell width="7"></Cell>
                <Cell width="2" className={styles.results}>
                  <span className={styles.paddingRight}>
                    {isUnmappedRelays ? (
                      <span className={styles.resultsIcon}>
                        <IconCheckboxContained size={20} />
                      </span>
                    ) : (
                      <WarningIcon width="18" height="18" />
                    )}
                  </span>
                  <span>{'No circuit responded'}</span>
                </Cell>
              </>
            )}
          </>
        ),
      };
    }
  );
  return (
    <>
      <CardList
        data={data}
        header={header}
        headerClassName={styles.resultsHeaderStyle}
      />
    </>
  );
};

export default ConfigurationResultData;
