import { Site } from '@energybox/react-ui-library/dist/types';
import {
  convertToSiteTz,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSite, getSiteByResourceId, getSites } from '../actions/sites';
import { ApplicationState } from '../reducers';
import {
  getSitesLoadingStatusSelector,
  sitesByIdSelector,
} from '../selectors/sites/sites';

type siteId = number | string | undefined;

export const useGetSite = (siteId: siteId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatch(getSite(String(siteId)));
    }
  }, [siteId]);

  const site = useSelector<ApplicationState, Site | undefined>(({ sites }) => {
    return pathOr(undefined, [siteId], sites.sitesById);
  });
  return site;
};

export const useGetSites = () => {
  const dispatch = useDispatch();
  const sitesById = useSelector(sitesByIdSelector);
  const isLoading = useSelector(getSitesLoadingStatusSelector);
  useEffect(() => {
    if (isLoading === undefined) dispatch(getSites());
  }, [dispatch, isLoading]);
  return { isLoading: isLoading === undefined ? true : isLoading, sitesById };
};

export const useSiteTimezone = (siteId: string | number | undefined) => {
  const site = useSelector<ApplicationState, Site | undefined>(({ sites }) => {
    if (!isDefined(siteId)) return undefined;
    return pathOr(undefined, [siteId], sites.sitesById);
  });

  return site?.timeZone;
};

export const useSiteByResourceId = (
  resourceId: string | number | undefined
) => {
  const dispatch = useDispatch();
  const site: Site | undefined = useSelector(({ sites }: ApplicationState) => {
    if (resourceId) {
      const siteId = sites.resourceIdToSiteId[resourceId];
      return sites.sitesById[siteId];
    }
    return undefined;
  });
  useEffect(() => {
    if (resourceId) {
      dispatch(getSiteByResourceId(resourceId));
    }
  }, [resourceId, dispatch]);

  return site;
};
