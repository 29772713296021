import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { ControlBoard } from '@energybox/react-ui-library/dist/types';
import React, { useMemo } from 'react';
import { checkCommonPlural } from '../../util';
import styles from './SelectLightSensorDelay.module.css';

interface Props {
  onSelect: (gatewayId: number) => void;
  controlBoard: ControlBoard | undefined;
  value: number | undefined;
  disabled?: boolean;
  error?: boolean;
  customErrorText?: string;
  isOrgLevel?: boolean;
}

const SelectLightSensorDelay: React.FC<Props> = ({
  onSelect,
  value,
  disabled,
  error,
  customErrorText,
  controlBoard,
  isOrgLevel,
}) => {
  const lightSensorPublishingInterval =
    controlBoard?.lightSensorPublishingInterval;

  const isLightSensorPublishingIntervalValid =
    isDefined(lightSensorPublishingInterval) &&
    lightSensorPublishingInterval !== 0;

  const numberOfLightSensorReadings =
    isDefined(value) && !!lightSensorPublishingInterval
      ? calculateNumberOfLightSensorReadings(
          Number(value),
          Number(lightSensorPublishingInterval)
        )
      : undefined;

  const delayOptions = useMemo(() => {
    let options: number[] = [];

    if (!isLightSensorPublishingIntervalValid) {
      return options;
    }

    let n = lightSensorPublishingInterval!;
    while (n <= 15) {
      options.push(n);
      n += lightSensorPublishingInterval!;
    }

    return options;
  }, [lightSensorPublishingInterval]);

  if (isDefined(controlBoard) && !isLightSensorPublishingIntervalValid) {
    return <div>Please check light sensor interval</div>;
  }

  return (
    <div className={styles.root}>
      <Select
        variant={'select'}
        title={
          value
            ? checkCommonPlural('minute', value)
            : 'Please Check Light Sensor Interval'
        }
        disabled={disabled || !isDefined(value)}
        error={error}
        customErrorText={customErrorText}
        size={isOrgLevel ? 'small' : undefined}
      >
        {delayOptions.map((num: number) => (
          <SelectItem
            key={num}
            isSelected={num === value}
            onSelect={() => onSelect(num)}
          >
            {checkCommonPlural('minute', num)}
          </SelectItem>
        ))}
      </Select>

      {!error && isDefined(numberOfLightSensorReadings) && !isOrgLevel && (
        <span className={styles.sensorReadingsText}>
          (based on{' '}
          {checkCommonPlural('sensor reading', numberOfLightSensorReadings)})
        </span>
      )}
    </div>
  );
};

export const calculateNumberOfLightSensorReadings = (
  value: number,
  lightSensorPublishingInterval: number
) => {
  //round down, but return 1 if it rounds down to 0;
  return Math.floor(value / lightSensorPublishingInterval) || 1;
};

export default SelectLightSensorDelay;
