import { Mail, WarningIcon } from '@energybox/react-ui-library/dist/icons';
import { User } from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardContent,
  Columns as ColumnsLayout,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';

import React, { useState } from 'react';

import styles from './ShowUserPage.module.css';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import { formatDistance, parseISO } from 'date-fns';
import { fetchApi } from '../../utils/apiUtils';
import { apiBase } from '../../actions/users';
import { UserStatus } from '@energybox/react-ui-library/dist/types/User';
import { classNames } from '@energybox/react-ui-library/dist/utils';

enum SendButtonText {
  send = 'Send Invitation',
  resend = 'Resend Invitation',
  sending = 'Sending',
  success = 'Sent',
  failed = 'Failed',
}

type Props = {
  user: User;
};

const statusThatAllowSendInvite: UserStatus[] = [
  null,
  'PROSPECT',
  'INVITATION_EXPIRED',
  'SUSPENDED',
];

const statusThatShowResend: UserStatus[] = ['INVITATION_EXPIRED', 'SUSPENDED'];

const InvitationStatus: React.FC<Props> = ({ user }) => {
  const {
    lastLogin,
    organizationUnitType,
    userStatus,
    invitationEmailSentAt,
  } = user;
  const userText =
    organizationUnitType === OrganizationType.INSTALLER_PARTNER
      ? 'Installer'
      : 'User';

  const { send, resend, sending, success, failed } = SendButtonText;

  const sendButtonText = statusThatShowResend.includes(userStatus)
    ? resend
    : send;

  const [buttonText, setButtonText] = useState<SendButtonText>(sendButtonText);
  const [showNoScopesPrompt, setShowNoScopesPrompt] = useState(false);

  const dismissNoScopes = () => setShowNoScopesPrompt(false);

  const canSend = [send, resend].includes(buttonText);

  const resentInvitation = async () => {
    if (user.accessResources.length === 0) setShowNoScopesPrompt(true);
    else {
      setButtonText(sending);
      try {
        const result = await fetchApi({
          endpoint: `${apiBase}/invite-by-email/${user.id}`,
          method: 'POST',
        });
        if (result === true) setButtonText(success);
        else setButtonText(failed);
      } catch (e) {
        setButtonText(failed);
      }
    }
  };

  return (
    <Card>
      <CardContent>
        <ColumnsLayout template="0.82fr 0.18fr">
          <h4 className="m0">Account Status</h4>

          <Button
            variant="outlined"
            onClick={resentInvitation}
            className={styles.addOrgAccessButton}
            disabled={
              !canSend || !statusThatAllowSendInvite.includes(userStatus)
            }
          >
            <Mail size="20" />
            {buttonText}
          </Button>
        </ColumnsLayout>

        <div style={{ marginTop: '20px' }}>
          {[null, 'PROSPECT'].includes(userStatus) && (
            <>
              <span
                className={classNames(styles.statusMessage, styles.prospect)}
              >
                {userText} Account is in prospect state.
              </span>{' '}
              Login invitation has not been sent.
            </>
          )}
          {userStatus === 'INVITED' && (
            <>
              <span className={styles.statusMessage}>Invited to Platform.</span>{' '}
              {invitationEmailSentAt && (
                <>
                  {userText} has been invited{' '}
                  {formatDistance(parseISO(invitationEmailSentAt), new Date())}{' '}
                  ago.
                </>
              )}
            </>
          )}
          {userStatus === 'INVITATION_EXPIRED' && (
            <>
              <span className={classNames(styles.statusMessage, styles.alert)}>
                Invitation has expired.
              </span>{' '}
              Re-invite to complete registration.
            </>
          )}
          {(['ACTIVE', 'TIME_LOCKED'] as UserStatus[]).includes(userStatus) && (
            <>
              <span className={styles.statusMessage}>Account active.</span>{' '}
              {userText}{' '}
              {lastLogin
                ? `logged in ${formatDistance(
                    parseISO(lastLogin),
                    new Date()
                  )} ago`
                : 'never logged in'}
              .
            </>
          )}
          {userStatus === 'SUSPENDED' && (
            <>
              <span className={classNames(styles.statusMessage, styles.alert)}>
                Account suspended.
              </span>{' '}
              {userText} failed multiple login attempts.
            </>
          )}
        </div>
      </CardContent>

      {showNoScopesPrompt && (
        <Modal
          onClose={dismissNoScopes}
          actions={<Button onClick={dismissNoScopes}>OK</Button>}
        >
          <ModalTitle className={styles.modalTitle}>
            <WarningIcon
              width="30"
              height="30"
              className={styles.modalTitleIcon}
            />
            Warning
          </ModalTitle>
          <ModalContent className={styles.modalContent}>
            Please add a scope to users before sending platform login
            invitation.
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
};

export default InvitationStatus;
