import CardList, {
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  SentinelTarget,
  SentinelTargetEntities,
  SentinelTargetType,
  SentinelType,
  sentinelTypeToSensorTargetType,
  sentinelTypeToSensorType,
  Site,
  Equipment,
  EquipmentType,
} from '@energybox/react-ui-library/dist/types';
import {
  Checkbox,
  ErrorBoundary,
  GenericFilter,
  Label,
  MultiSelect,
  MultiSelectItem,
} from '@energybox/react-ui-library/dist/components';
import { Trash as TrashIcon } from '@energybox/react-ui-library/dist/icons';

import React from 'react';
import { openModal } from '../../../../actions/modal';
import SiteSelector from '../../SiteSelector';
import StepTitle from '../StepTitle';
import styles from './StepRollout.module.css';
import StepRolloutEnergyPros from './StepRolloutEnergyPro';
import StepRolloutEnergyProsHeader from './StepRolloutEnergyProsHeader';
import StepRolloutEquipment from './StepRolloutEquipment';
import StepRolloutEquipmentHeader from './StepRolloutEquipmentHeader';
import StepRolloutGateways from './StepRolloutGateways';
import StepRolloutGatewaysHeader from './StepRolloutGatewaysHeader';
import StepRolloutNetworkGroups, {
  StepRolloutNetworkGroupsHeader,
} from './StepRolloutNetworkGroups';
import StepRolloutSensors from './StepRolloutSensors';
import StepRolloutSensorsHeader from './StepRolloutSensorsHeader';
import StepRolloutSpaces from './StepRolloutSpaces';
import StepRolloutSpacesHeader from './StepRolloutSpacesHeader';
import { Errors } from '../../SentinelWizard';
import * as R from 'ramda';
import { FilteredDeviceType, SiteControllerDeviceType } from './Util';

type Props = {
  sites: Site[];
  targets: SentinelTarget[];
  sentinelType: SentinelType;
  equipments: Equipment[];
  onTargetChange: (
    sentinelTarget: SentinelTarget,
    siteId?: number | string,
    selectedEntity?: SentinelTargetEntities
  ) => void;
  onTargetsChange: (
    sentinelTargets: SentinelTarget[],
    siteId?: number | string,
    selectedEntities?: SentinelTargetEntities[]
  ) => void;
  onTargetsAdd: (
    sentinelTargets: SentinelTarget[],
    siteId?: number | string,
    selectedEntities?: SentinelTargetEntities[]
  ) => void;
  onTargetsDelete: (
    sentinelTargetIds: number[],
    deselectedEntityType?: SentinelTargetType,
    siteId?: number | string
  ) => void;
  targetTypes: SentinelTargetType[];
  openModal: typeof openModal;
  targetedSites: Site[] | undefined;
  targetedSitesLoading: boolean;
  setTargetedSites: (sitesList: Site[]) => void;
  selectSite: string;
  setSelectSite: (value) => void;
  setCheckBoxType: (value) => void;
  errors: Errors;
  setIsSelectedSiteError: (value) => void;
  isSelectedSiteError: boolean;
  onSelectAllTargets: (
    sentinelTargets: SentinelTarget[],
    siteId?: number | string,
    selectedEntities?: SentinelTargetEntities[]
  ) => void;
  updateEquipmentList: (list: any) => void;
  newListEquipment: any;
  handleRemoveStepHandling: (index: number) => void;
  upDatedSentielId: (sentinelId: any) => void;
  sentinelTargatedType: any;
  setSelectedTargetType: (value: any) => void;
};

type State = {
  sites: Site[];
  sitesExpanded: number[];
  isLoading: boolean;
  siteGatewayRelation: {
    [siteId: number]: number[];
  };
  siteEnergyProRelation: {
    [siteId: number]: number[];
  };
  siteSensorRelations: {
    [siteId: number]: number[];
  };
  siteSpaceRelations: {
    [siteId: number]: number[];
  };
  siteEquipmentRelations: {
    [siteId: number]: number[];
  };
  siteNetworkGroupsRelations: {
    [siteId: number]: number[];
  };
  selectedTargetType: SentinelTargetType;
  isSelectAll: boolean;
  allSelectedSites: Equipment[];
  selectedSiteList: any[];
  selectAllEquipData: any[];
  equipmentTypes: EquipmentType[];
  selectedEquipmentTypes: EquipmentType[];
  deviceType: SiteControllerDeviceType[];
  showSelectAll: boolean;
};

function removeSiteFromSentinelModal(
  openModalFn: typeof openModal,
  actionFn: any,
  targetId: number,
  targetTitle: string
) {
  openModalFn({
    title: 'Remove site',
    description: `Are you sure you would like to remove the site "${targetTitle}" from this configuration?`,
    actionFn: () => {
      actionFn(targetId);
    },
  });
}

class StepRolloutContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sites: props.targetedSites || [],
      sitesExpanded: [],
      isLoading: props.targetedSitesLoading,
      siteGatewayRelation: {},
      siteEnergyProRelation: {},
      siteSensorRelations: {},
      siteSpaceRelations: {},
      siteEquipmentRelations: {},
      siteNetworkGroupsRelations: {},
      selectedTargetType: props.targetTypes[0],
      isSelectAll: false,
      allSelectedSites: [],
      selectedSiteList: [],
      deviceType: [],
      selectAllEquipData: [],
      equipmentTypes: [],
      selectedEquipmentTypes: [],
      showSelectAll: false,
    };
  }

  selectEquipmentData = data => {
    this.setState({
      selectAllEquipData: data,
    });
  };

  filteredDeviceTypes: FilteredDeviceType[] = [
    {
      id: SiteControllerDeviceType.SITE_CONTROLLER,
      name: 'SiteController',
    },
    {
      id: SiteControllerDeviceType.SITE_CONTROLLER_ILO,
      name: 'SiteController-ILO',
    },
  ];

  updateAvailableEquipmentTypes = () => {
    const filteredEquipmentTypesBySelectedSite = R.sortBy(
      R.prop('title'),
      R.uniqBy(
        R.path(['id']),
        R.uniqBy(
          R.path(['id']),
          R.flatten(
            Object.values(
              this.state.siteEquipmentRelations
            ).map((equipmentIds: number[]) =>
              equipmentIds.map((equipmentId: number) =>
                this.props.equipments.find(
                  (equipment: Equipment) => equipment.id === equipmentId
                )
              )
            )
          )
        ).map((equipment: Equipment) => equipment.type)
      )
    );
    this.setState({ equipmentTypes: filteredEquipmentTypesBySelectedSite });
  };

  updateIsSelectAll = () => {
    const relationIds =
      this.state.selectedTargetType === SentinelTargetType.EQUIPMENT
        ? {
            [SentinelTargetType.EQUIPMENT]: this.filteredSiteEquipmentRelations(),
          }
        : this.getRelationIds();
    const selectedEntities = this.getSelectedEntities(relationIds);
    this.setState({
      isSelectAll:
        selectedEntities.length !== 0 &&
        relationIds[this.state.selectedTargetType].length !== 0 &&
        selectedEntities.length >=
          relationIds[this.state.selectedTargetType].length,
    });
  };

  setDeviceType = (deviceType: SiteControllerDeviceType[]) => {
    this.setState({ deviceType: deviceType });
  };

  setSelectedEquipmentTypeWithTitle = (
    selectedEquipmentTypeTitles: string[]
  ) => {
    if (
      this.props.equipments.length === 0 ||
      selectedEquipmentTypeTitles.length === 0
    ) {
      this.setState({ selectedEquipmentTypes: [] });
    }
    const selectedEquipmentTypes = this.state.equipmentTypes.filter(
      (equipmentType: EquipmentType) =>
        selectedEquipmentTypeTitles.includes(equipmentType.title)
    );
    this.setState({ selectedEquipmentTypes });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const {
      sentinelType: prevSentinelType,
      targetedSites: prevTargetedSites,
      targetedSitesLoading: prevTargetedSitesLoading,
    } = prevProps;
    const { sentinelType, targetedSites, targetedSitesLoading } = this.props;
    const dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');

    if (!R.equals(targetedSites, prevTargetedSites) && !dublicateSentinelkey) {
      this.setState({
        sites: targetedSites || [],
      });
    }

    if (!R.equals(targetedSitesLoading, prevTargetedSitesLoading)) {
      this.setState({ isLoading: targetedSitesLoading });
    }

    const hasSiteRelationChanged =
      this.state.selectedTargetType !== prevState.selectedTargetType ||
      !R.equals(
        this.state.siteEquipmentRelations,
        prevState.siteEquipmentRelations
      ) ||
      !R.equals(this.state.siteSpaceRelations, prevState.siteSpaceRelations) ||
      !R.equals(
        this.state.siteEnergyProRelation,
        prevState.siteEnergyProRelation
      ) ||
      !R.equals(
        this.state.siteSensorRelations,
        prevState.siteSensorRelations
      ) ||
      !R.equals(
        this.state.siteGatewayRelation,
        prevState.siteGatewayRelation
      ) ||
      !R.equals(
        this.state.siteNetworkGroupsRelations,
        prevState.siteNetworkGroupsRelations
      );

    if (hasSiteRelationChanged) {
      this.updateAvailableEquipmentTypes();
      this.updateIsSelectAll();
    }

    if (sentinelType !== prevSentinelType) {
      this.updateIsSelectAll();
    }

    if (
      JSON.stringify(
        this.props.targets.map(t => t.targetId).sort((a, b) => a - b)
      ) !==
      JSON.stringify(
        prevProps.targets.map(t => t.targetId).sort((a, b) => a - b)
      )
    ) {
      this.updateIsSelectAll();
    }

    if (this.state.selectedTargetType !== prevState.selectedTargetType) {
      this.updateIsSelectAll();
    }
  };

  handleFilterTargetType: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const relationIds = this.getRelationIds();
    const filterFn = target => target.targetType === ev.currentTarget.name;
    const selectedEntities = this.getSelectedEntities(relationIds, filterFn);
    this.setState({
      selectedTargetType: ev.currentTarget.name as SentinelTargetType,
    });
    this.props.setCheckBoxType(ev.currentTarget.name);
    this.setState({
      isSelectAll:
        selectedEntities.length !== 0 &&
        relationIds[ev.currentTarget.name].length !== 0 &&
        selectedEntities.length === relationIds[ev.currentTarget.name].length,
    });
  };

  handleSelectSite = (site: Site) => {
    this.setState(prevState => ({
      sites: prevState.sites.find(s => s.id === site.id)
        ? prevState.sites
        : [...prevState.sites, site],
    }));
    this.props.setSelectSite(site);
    this.props.setIsSelectedSiteError(false);
  };

  handleSelectAllSites = (sites: Site[]) => this.setState({ sites });

  handleCollapseSite = (siteId: number) =>
    this.setState({
      sitesExpanded: this.state.sitesExpanded.find(id => id === siteId)
        ? this.state.sitesExpanded.filter(id => id !== siteId)
        : [...this.state.sitesExpanded, siteId],
    });

  handleSetSiteEquipmentRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => {
      let newMap = { ...prevState.siteEquipmentRelations };
      newMap[siteId] = resourceIds;
      return {
        siteEquipmentRelations: newMap,
      };
    });

  handleSetSiteSpaceRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => {
      let newMap = { ...prevState.siteSpaceRelations };
      newMap[siteId] = resourceIds;
      return {
        siteSpaceRelations: newMap,
      };
    });

  handleSetSiteSensorRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => {
      let newMap = { ...prevState.siteSensorRelations };
      newMap[siteId] = resourceIds;
      return {
        siteSensorRelations: newMap,
      };
    });

  handleSetSiteGatewayRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => {
      let newMap = { ...prevState.siteGatewayRelation };
      newMap[siteId] = resourceIds;
      return {
        siteGatewayRelation: newMap,
      };
    });

  handleSetSiteEnergyProRelation = (siteId: number, resourceIds: number[]) =>
    this.setState(prevState => {
      let newMap = { ...prevState.siteEnergyProRelation };
      newMap[siteId] = resourceIds;
      return {
        siteEnergyProRelation: newMap,
      };
    });

  handleSetSiteNetworkGroupsRelation = (
    siteId: number,
    resourceIds: number[]
  ) =>
    this.setState(prevState => {
      let newMap = { ...prevState.siteNetworkGroupsRelations };
      newMap[siteId] = resourceIds;
      return {
        siteNetworkGroupsRelations: newMap,
      };
    });

  handleRemoveSite = (siteId: number) => {
    this.setState({ isLoading: true });
    const relationIds = [
      ...(this.state.siteEquipmentRelations[siteId] || []),
      ...(this.state.siteSpaceRelations[siteId] || []),
      ...(this.state.siteSensorRelations[siteId] || []),
      ...(this.state.siteGatewayRelation[siteId] || []),
      ...(this.state.siteEnergyProRelation[siteId] || []),
      ...(this.state.siteNetworkGroupsRelations[siteId] || []),
    ];
    this.props.onTargetsDelete(relationIds);
    this.setState(prevState => ({
      sites: this.state.sites.filter(s => s.id !== siteId),
      isLoading: false,
    }));
    this.handleSetSiteEquipmentRelation(siteId, []);

    if (this.state.sitesExpanded.includes(siteId)) {
      this.handleCollapseSite(siteId);
    }
  };

  filteredSiteEquipmentRelations = () => {
    // flatten siteEquipmentRelations and filter by selected equipment types
    if (this.state.selectedEquipmentTypes.length === 0) {
      return Object.values(this.state.siteEquipmentRelations).flat();
    }
    return Object.values(this.state.siteEquipmentRelations)
      .flat()
      .filter(id => {
        const equipment = this.props.equipments.find(
          equipment => equipment.id === id
        );
        return this.state.selectedEquipmentTypes
          .map(equipmentType => equipmentType.id)
          .includes(equipment?.type?.id || -1);
      });
  };

  componentWillUnmount() {
    // when the user finishes making changes
    // batch update all targeted sites to the Sentinel Wizard
    const { setTargetedSites } = this.props;
    const { sites } = this.state;
    setTargetedSites(sites);
  }

  componentDidMount() {
    const dublicateSentinelkey = localStorage.getItem('duplicateSentinelKey');
    if (dublicateSentinelkey) {
      this.setState({
        sites: [],
      });
    }
    if (this.props.targets) {
      const relationIds = this.getRelationIds();
      const selectedEntities = this.getSelectedEntities(relationIds);
      this.setState({
        isSelectAll:
          selectedEntities.length !== 0 &&
          relationIds[this.state.selectedTargetType].length !== 0 &&
          selectedEntities.length ===
            relationIds[this.state.selectedTargetType].length,
      });
    }

    this.updateAvailableEquipmentTypes();

    if (!this.state.isLoading && this.props.sites.length > 0) {
      const selectAll: SiteControllerDeviceType[] = [];
      this.filteredDeviceTypes.forEach(item => {
        selectAll.push(item.id);
      });
      this.setDeviceType(selectAll);
    }
  }

  getSelectedEntities = (relationIds, filterFn?) => {
    let targets: SentinelTarget[];
    if (filterFn) {
      targets = this.props.targets.filter(filterFn);
    } else {
      targets = this.props.targets;
    }
    return targets
      .filter(
        (target: SentinelTarget) =>
          target.targetType === this.state.selectedTargetType
      )
      .map((target: SentinelTarget) => target?.targetId)
      .reduce((result: number[], targetId) => {
        if (relationIds[this.state.selectedTargetType].includes(targetId)) {
          result.push(targetId);
        }
        return result;
      }, []);
  };

  getRelationIds = () => {
    return {
      [SentinelTargetType.EQUIPMENT]: [
        ...Object.values(this.state.siteEquipmentRelations),
      ].flat(),
      [SentinelTargetType.SPACE]: [
        ...Object.values(this.state.siteSpaceRelations),
      ].flat(),
      [SentinelTargetType.ENERGYPRO]: [
        ...Object.values(this.state.siteEnergyProRelation),
      ].flat(),
      [SentinelTargetType.SENSOR]: [
        ...Object.values(this.state.siteSensorRelations),
      ].flat(),
      [SentinelTargetType.GATEWAY]: [
        ...Object.values(this.state.siteGatewayRelation),
      ].flat(),
      [SentinelTargetType.EDGEAPP]: [
        ...Object.values(this.state.siteNetworkGroupsRelations),
      ].flat(),
    };
  };

  BySentinelTargetType = (i, sentinelTargetedType) => {
    switch (sentinelTargetedType) {
      case SentinelTargetType.GATEWAY:
        return (
          i._entity === 'Hub' ||
          i._entity === 'ControlBoard' ||
          i._entity === 'EnergyPro'
        );

      case SentinelTargetType.SENSOR:
        return i._entity === 'Sensor';

      case SentinelTargetType.ENERGYPRO:
        return i._entity === 'EnergyPro';

      case SentinelTargetType.EDGEAPP:
        return i._entity === 'NetworkGroup';

      case SentinelTargetType.EQUIPMENT:
        return i._entity === 'Equipment';

      case SentinelTargetType.SPACE:
        return i._entity === 'Space';

      default:
        return false;
    }
  };

  toggleSelectAll = () => {
    if (!this.state.showSelectAll) {
      const selectAll: SiteControllerDeviceType[] = [];
      this.filteredDeviceTypes.forEach(item => {
        selectAll.push(item.id);
      });
      this.setDeviceType(selectAll);
    } else {
      this.setDeviceType([]);
    }
  };

  onItemClick = id => {
    if (this.state.deviceType.indexOf(id) === -1) {
      const updatedDeviceType = [...this.state.deviceType, id];
      this.setDeviceType(updatedDeviceType);
    } else if (this.state.deviceType.indexOf(id) !== -1) {
      const updatedDeviceType = this.state.deviceType.filter(
        removeId => id !== removeId
      );
      this.setDeviceType(updatedDeviceType);
    }
  };

  onRemoveDeviceType = id => {
    if (this.state.deviceType.indexOf(id) !== -1) {
      const filteredDeviceType = this.state.deviceType.filter(
        removeId => id !== removeId
      );
      this.setDeviceType(filteredDeviceType);
    }
  };

  selectAllEquipments = () => {
    const relationIds = this.filteredSiteEquipmentRelations();
    this.props.onTargetsAdd(
      relationIds.map(id => ({
        targetId: id,
        targetType: SentinelTargetType.EQUIPMENT,
      }))
    );
  };

  deselectAllEquipments = () => {
    const relationIds = this.filteredSiteEquipmentRelations();
    this.props.onTargetsDelete(relationIds, SentinelTargetType.EQUIPMENT);
  };

  onSelectAll = () => {
    if (this.state.selectedTargetType === SentinelTargetType.EQUIPMENT) {
      const relationIds = this.filteredSiteEquipmentRelations();
      const selectedEntities = this.getSelectedEntities({
        [SentinelTargetType.EQUIPMENT]: relationIds,
      });
      if (
        selectedEntities.length >= relationIds.length &&
        relationIds.length !== 0
      ) {
        this.setState({ isSelectAll: false });
        this.deselectAllEquipments();
      } else {
        this.setState({ isSelectAll: true });
        this.selectAllEquipments();
      }
      return;
    }

    const relationIds = this.getRelationIds();
    const selectedEntities = this.getSelectedEntities(relationIds);

    if (
      selectedEntities.length >=
      relationIds[this.state.selectedTargetType].length
    ) {
      this.setState({ isSelectAll: false });
      this.props.onTargetsDelete(
        relationIds[this.state.selectedTargetType],
        this.state.selectedTargetType
      );
    } else {
      this.setState({ isSelectAll: true });
      const availableTargets: number[] =
        relationIds[this.state.selectedTargetType];
      this.props.onTargetsAdd(
        availableTargets.map(id => ({
          targetId: id,
          targetType: this.state.selectedTargetType,
        }))
      );
    }
  };

  render() {
    const { targets, sentinelType, targetTypes, openModal } = this.props;
    const {
      equipmentTypes,
      isLoading,
      selectedTargetType,
      sites,
      isSelectAll,
      selectedEquipmentTypes,
    } = this.state;

    const renderRemoveButton = (site: Site) => (
      <Cell
        width="1"
        render={() => (
          <div
            onClick={() =>
              removeSiteFromSentinelModal(
                openModal,
                this.handleRemoveSite,
                site.id,
                site.title
              )
            }
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <TrashIcon size={16} color="var(--pink-base)" />
          </div>
        )}
      />
    );

    const data: CardListRowData[] = sites.map((site: Site) => {
      return {
        key: String(site.id),
        content: (
          <>
            <Cell width="4">{site.title}</Cell>
            {selectedTargetType === SentinelTargetType.EQUIPMENT && (
              <Cell
                width="7"
                render={({ isExpanded }) => (
                  <StepRolloutEquipmentHeader
                    sentinelType={sentinelType}
                    sensorTypes={sentinelTypeToSensorType(sentinelType)}
                    isCollapsed={isExpanded}
                    site={site}
                    selected={targets
                      .filter(
                        t => t.targetType === SentinelTargetType.EQUIPMENT
                      )
                      .map(t => t.targetId)}
                    handleTargetDelete={this.props.onTargetsDelete}
                    handleTargetsAdd={this.props.onTargetsAdd}
                    handleSetResourceRelation={
                      this.handleSetSiteEquipmentRelation
                    }
                    updateEquipmentList={this.props.updateEquipmentList}
                    upDatedSentielId={this.props.upDatedSentielId}
                    equipmentTypeFilter={selectedEquipmentTypes}
                  />
                )}
              />
            )}

            {selectedTargetType === SentinelTargetType.SPACE && (
              <Cell
                width="7"
                render={({ isExpanded }) => (
                  <StepRolloutSpacesHeader
                    sensorTypes={sentinelTypeToSensorType(sentinelType)}
                    isCollapsed={isExpanded}
                    site={site}
                    selected={targets
                      .filter(t => t.targetType === SentinelTargetType.SPACE)
                      .map(t => t.targetId)}
                    handleTargetDelete={this.props.onTargetsDelete}
                    handleTargetsSelect={this.props.onTargetsAdd}
                    handleSetResourceRelation={this.handleSetSiteSpaceRelation}
                    updateEquipmentList={this.props.updateEquipmentList}
                    upDatedSentielId={this.props.upDatedSentielId}
                  />
                )}
              />
            )}

            {selectedTargetType === SentinelTargetType.SENSOR && (
              <Cell
                width="7"
                render={({ isExpanded }) => (
                  <StepRolloutSensorsHeader
                    sensorTypes={sentinelTypeToSensorType(sentinelType)}
                    isCollapsed={isExpanded}
                    site={site}
                    selected={targets
                      .filter(t => t.targetType === SentinelTargetType.SENSOR)
                      .map(t => t.targetId)}
                    handleTargetDelete={this.props.onTargetsDelete}
                    handleTargetsSelect={this.props.onTargetsAdd}
                    handleSetResourceRelation={this.handleSetSiteSensorRelation}
                    updateEquipmentList={this.props.updateEquipmentList}
                    upDatedSentielId={this.props.upDatedSentielId}
                  />
                )}
              />
            )}

            {selectedTargetType === SentinelTargetType.GATEWAY && (
              <Cell
                width="7"
                render={({ isExpanded }) => (
                  <StepRolloutGatewaysHeader
                    isCollapsed={isExpanded}
                    site={site}
                    selected={targets
                      .filter(t => t.targetType === SentinelTargetType.GATEWAY)
                      .map(t => t.targetId)}
                    handleTargetDelete={this.props.onTargetsDelete}
                    handleTargetsSelect={this.props.onTargetsAdd}
                    handleSetResourceRelation={
                      this.handleSetSiteGatewayRelation
                    }
                    filterByEntityType={
                      sentinelType ===
                      SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK
                        ? 'ControlBoard'
                        : undefined
                    }
                    filterByDeviceTypes={
                      sentinelType ===
                      SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK
                        ? this.state.deviceType
                        : []
                    }
                    updateEquipmentList={this.props.updateEquipmentList}
                    upDatedSentielId={this.props.upDatedSentielId}
                  />
                )}
              />
            )}
            {selectedTargetType === SentinelTargetType.ENERGYPRO && (
              <Cell
                width="7"
                render={({ isExpanded }) => (
                  <StepRolloutEnergyProsHeader
                    isCollapsed={isExpanded}
                    site={site}
                    selected={targets
                      .filter(
                        t => t.targetType === SentinelTargetType.ENERGYPRO
                      )
                      .map(t => t.targetId)}
                    handleTargetDelete={this.props.onTargetsDelete}
                    handleTargetsSelect={this.props.onTargetsAdd}
                    handleSetResourceRelation={
                      this.handleSetSiteEnergyProRelation
                    }
                    updateEquipmentList={this.props.updateEquipmentList}
                    upDatedSentielId={this.props.upDatedSentielId}
                  />
                )}
              />
            )}
            {selectedTargetType === SentinelTargetType.EDGEAPP && (
              <Cell
                width="7"
                render={({ isExpanded }) => (
                  <StepRolloutNetworkGroupsHeader
                    isCollapsed={isExpanded}
                    site={site}
                    selected={targets
                      .filter(t => t.targetType === SentinelTargetType.EDGEAPP)
                      .map(t => t.targetId)}
                    handleTargetDelete={this.props.onTargetsDelete}
                    handleTargetsSelect={this.props.onTargetsAdd}
                    handleSetResourceRelation={
                      this.handleSetSiteNetworkGroupsRelation
                    }
                    updateEquipmentList={this.props.updateEquipmentList}
                    upDatedSentielId={this.props.upDatedSentielId}
                  />
                )}
              />
            )}
            {renderRemoveButton(site)}
          </>
        ),
        extraContent: [
          <>
            <Cell width="4">
              <p>{site.address}</p>
            </Cell>

            {selectedTargetType === SentinelTargetType.EQUIPMENT && (
              <Cell width="8">
                <ErrorBoundary>
                  <StepRolloutEquipment
                    sensorTypes={sentinelTypeToSensorType(sentinelType)}
                    site={site}
                    sentinelType={sentinelType}
                    selected={targets
                      .filter(
                        t => t.targetType === SentinelTargetType.EQUIPMENT
                      )
                      .map(t => t.targetId)}
                    handleTargetSelect={this.props.onTargetChange}
                    handleSetResourceRelation={
                      this.handleSetSiteEquipmentRelation
                    }
                    equipmentTypeFilter={selectedEquipmentTypes}
                  />
                </ErrorBoundary>
              </Cell>
            )}

            {selectedTargetType === SentinelTargetType.SPACE && (
              <Cell width="8">
                <StepRolloutSpaces
                  sensorTypes={sentinelTypeToSensorType(sentinelType)}
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === SentinelTargetType.SPACE)
                    .map(t => t.targetId)}
                  handleTargetSelect={this.props.onTargetChange}
                  handleSetResourceRelation={this.handleSetSiteSpaceRelation}
                />
              </Cell>
            )}

            {selectedTargetType === SentinelTargetType.SENSOR && (
              <Cell width="8">
                <StepRolloutSensors
                  sensorTypes={sentinelTypeToSensorType(sentinelType)}
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === SentinelTargetType.SENSOR)
                    .map(t => t.targetId)}
                  handleTargetSelect={this.props.onTargetChange}
                  handleSetResourceRelation={this.handleSetSiteSensorRelation}
                />
              </Cell>
            )}

            {selectedTargetType === SentinelTargetType.GATEWAY && (
              <Cell width="8">
                <StepRolloutGateways
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === SentinelTargetType.GATEWAY)
                    .map(t => t.targetId)}
                  handleTargetSelect={this.props.onTargetChange}
                  handleSetResourceRelation={this.handleSetSiteGatewayRelation}
                  filterByEntityType={
                    // Local Override targets Gateway, but really just wants ControlBoard (aka SiteController)
                    sentinelType ===
                    SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK
                      ? 'ControlBoard'
                      : undefined
                  }
                  filterByDeviceTypes={
                    sentinelType ===
                    SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK
                      ? this.state.deviceType
                      : []
                  }
                />
              </Cell>
            )}

            {selectedTargetType === SentinelTargetType.ENERGYPRO && (
              <Cell width="8">
                <StepRolloutEnergyPros
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === SentinelTargetType.ENERGYPRO)
                    .map(t => t.targetId)}
                  handleTargetSelect={this.props.onTargetChange}
                  handleSetResourceRelation={
                    this.handleSetSiteEnergyProRelation
                  }
                />
              </Cell>
            )}

            {selectedTargetType === SentinelTargetType.EDGEAPP && (
              <Cell width="8">
                <StepRolloutNetworkGroups
                  site={site}
                  selected={targets
                    .filter(t => t.targetType === SentinelTargetType.EDGEAPP)
                    .map(t => t.targetId)}
                  handleTargetSelect={this.props.onTargetChange}
                  handleSetResourceRelation={
                    this.handleSetSiteNetworkGroupsRelation
                  }
                />
              </Cell>
            )}
          </>,
        ],
      };
    });

    const header = [
      {
        width: '4',
        content: 'Site',
      },
      {
        width: '6',
        content: 'Selection',
      },
      {
        width: '1',
        content: (
          <button className={styles.selectAllButton} onClick={this.onSelectAll}>
            {isSelectAll ? 'Deselect all' : 'Select all'}
          </button>
        ),
      },
      {
        width: '1',
        content: '',
      },
    ];

    // TODO: fetchAll is a hack to circumvent the filtering by sensor type, we are currently divergent
    // with two different types of sentinels, ones that monitor sensors and ones that monitor energy sensors
    // the two collections in iam are not bridged and helper methods to query properly in a unified fashion do
    // not exist yet.
    return (
      <div>
        <div className={styles.actionContainer}>
          <div className={styles.siteSelectorContainer}>
            <SiteSelector
              fetchAll={
                sentinelType === SentinelType.ACTIVE_POWER_THRESHOLD ||
                sentinelType === SentinelType.CONNECTIVITY ||
                sentinelType === SentinelType.CONTROLS_HEALTH_CHECK ||
                sentinelType ===
                  SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK ||
                sentinelType === SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK
              }
              selectedOptions={sites.map(s => s.id)}
              onChange={this.handleSelectSite}
              onSelectAll={this.handleSelectAllSites}
              sensorTypes={sentinelTypeToSensorType(sentinelType)}
              sensorTargetTypes={sentinelTypeToSensorTargetType(sentinelType)}
            />
          </div>
          <div className={styles.actionTargetTypeContainer}>
            <div className={styles.actionTargetType}>
              {targetTypes.map(t => {
                const checkbox = (
                  <Checkbox
                    key={t}
                    id={t}
                    name={t}
                    checked={selectedTargetType === t}
                    onChange={this.handleFilterTargetType}
                    label={
                      t === SentinelTargetType.EDGEAPP
                        ? 'network groups'
                        : t.toLowerCase()
                    }
                    disabled={this.state.sites.length === 0}
                  />
                );
                if (
                  sentinelType ===
                  SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK
                ) {
                  return (
                    <>
                      <div className={styles.checkboxContainer}>
                        {checkbox}
                        <span className={styles.grayText}>
                          SiteController only
                        </span>
                      </div>
                      <div className={styles.deviceTypeLabel}>
                        <Label>Device Type</Label>
                      </div>

                      <div className={styles.deviceTypeContainer}>
                        <MultiSelect
                          doNotIncludeActions
                          selectionComponentType="portalDropdown"
                          onDeleteItem={(e, itemId) =>
                            this.onRemoveDeviceType(String(itemId))
                          }
                          items={this.state.deviceType.map(deviceTypeId => ({
                            value: `${
                              (
                                this.filteredDeviceTypes.find(
                                  ({ id }) => id === deviceTypeId
                                ) || {}
                              ).name
                            }`,
                            id: deviceTypeId,
                          }))}
                          selectionComponent={
                            <div className={styles.dropdown}>
                              <div className={styles.dropdownList}>
                                <div className={styles.selectAll}>
                                  <span
                                    tabIndex={0}
                                    onKeyPress={this.toggleSelectAll}
                                    role="button"
                                    onClick={this.toggleSelectAll}
                                  >
                                    {this.state.showSelectAll
                                      ? 'Deselect All'
                                      : 'Select All'}
                                  </span>
                                </div>
                                {this.filteredDeviceTypes.map(
                                  ({ name, id }) => (
                                    <>
                                      <MultiSelectItem
                                        isSelected={
                                          this.state.deviceType.indexOf(id) > -1
                                        }
                                        onSelect={() => this.onItemClick(id)}
                                      >
                                        {name}
                                      </MultiSelectItem>
                                    </>
                                  )
                                )}
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </>
                  );
                } else if (
                  sentinelType === SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK
                ) {
                  return (
                    <>
                      <div className={styles.checkboxContainer}>
                        {checkbox}
                        <span className={styles.grayText}>
                          HVAC with Controls only
                        </span>
                      </div>
                    </>
                  );
                } else {
                  return checkbox;
                }
              })}
            </div>
            {selectedTargetType === SentinelTargetType.EQUIPMENT && (
              // Using EquipmentType[] as items directly will cause not matching error
              <GenericFilter
                title="Equipment Type"
                transformItemName={item => item}
                setFilter={items =>
                  this.setSelectedEquipmentTypeWithTitle(items)
                }
                items={equipmentTypes.map((type: EquipmentType) => type.title)}
                selectedItems={selectedEquipmentTypes.map(
                  (type: EquipmentType) => type.title
                )}
                disabled={this.state.sites.length === 0}
              />
            )}
          </div>
        </div>

        {isLoading && (
          <div className={styles.messageContainer}>
            ...loading configuration
          </div>
        )}

        {!isLoading && sites.length === 0 && (
          <div className={styles.messageContainer}>
            <StepTitle>
              Please search for sites to roll out their{' '}
              {targetTypes.map(t => t.toLowerCase()).join(', ')}
            </StepTitle>

            <div>
              <span>No sites selected</span>
            </div>
          </div>
        )}

        {!isLoading && !this.props.isSelectedSiteError && (
          <CardList header={header} data={data} />
        )}

        <div className={styles.errorCenterContainer}>
          <span className={styles.errorContainer}>
            {this.props.isSelectedSiteError === true
              ? this.props.errors.rollOut
              : ''}
          </span>
        </div>
      </div>
    );
  }
}

export default StepRolloutContainer;
