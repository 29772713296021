import { SchedulerDataStatus } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSchedulerByEquipmentId } from '../actions/schedulers';
import { ApplicationState } from '../reducers';

const useGetSchedulerByEquipmentId = (equipmentId: string | number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchedulerByEquipmentId(String(equipmentId)));
  }, [dispatch, equipmentId]);

  const scheduler = useSelector<
    ApplicationState,
    SchedulerDataStatus | undefined
  >(({ schedulers }) => {
    return schedulers.schedulerByEquipmentId[equipmentId];
  });

  return scheduler;
};

export default useGetSchedulerByEquipmentId;
