import {
  Equipment,
  EquipmentType,
  Site,
  Sop,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './StepRollout.module.css';
import { TargetedEntitiesMappingBySiteId } from '../../ReminderWizard';

type Props = {
  site: Site;
  sops: Sop[];
  targets: TargetedEntitiesMappingBySiteId;
  selectedEquipmentType: EquipmentType[];
  isCollapsed: boolean;
};

type State = {
  equipment: Equipment[];
  isLoading: boolean;
};

class StepRolloutEquipmentHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      equipment: [],
      isLoading: false,
    };
  }

  render() {
    const { equipment = [], isLoading } = this.state;
    const {
      isCollapsed,
      sops,
      targets,
      site,
      selectedEquipmentType,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedEquipmentTypeIds = selectedEquipmentType?.map(i => i.id);
    const sopCount =
      selectedEquipmentTypeIds?.length > 0
        ? sops?.filter(i =>
            i?.equipmentTypeIds?.some(a =>
              selectedEquipmentTypeIds?.includes(a)
            )
          )
        : sops;
    const selectedSopCount =
      selectedEquipmentTypeIds?.length > 0
        ? targets[site.id]?.filter(i =>
            i?.sop?.equipmentTypeIds?.some(a =>
              selectedEquipmentTypeIds?.includes(a)
            )
          )
        : targets[site.id];

    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '18%' }}>SOP</th>
              <th style={{ width: '18%' }}>SOP Level</th>
              <th style={{ width: '22%' }}>Equipment Type</th>
              <th style={{ width: '20%' }}>Days</th>
              <th style={{ width: '20%' }}>Check</th>
              <th
                style={{ width: '20%' }}
                className={classNames(styles.actionHeader, styles.action)}
              >
                {!isCollapsed
                  ? `${selectedSopCount?.length}/${sopCount?.length}`
                  : ''}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

export default StepRolloutEquipmentHeader;
