import { ApplicationState } from '../../reducers';
import { Actions } from '../../actions/gateways';

export const gatewaysByIdSelector = ({ gateways }: ApplicationState) =>
  gateways.gatewaysById;

export const gatewayIdsBySiteIdSelector = ({ gateways }: ApplicationState) =>
  gateways.gatewayIdsBySiteId;

export const gatewaysLoadingBySiteIdSelector = ({
  gateways,
}: ApplicationState) =>
  gateways.loadingStatusByAction[Actions.GET_GATEWAYS_BY_SITE_ID_LOADING];
