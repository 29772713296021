import {
  Loader,
  MultiSelect,
  MultiSelectItem,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import { EquipmentType } from '@energybox/react-ui-library/dist/types';

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentTypes } from '../../actions/app';
import { getEquipments } from '../../actions/equipment';
import { ApplicationState } from '../../reducers';
import styles from './MultiSelects.module.css';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';

interface Props {
  onSelect: (equipmentTypeId: number) => void;
  onToggleSelectAll?: (ids: number[]) => void;
  onRemoveEquipmentType: (equipmentTypeId: number) => void;
  equipmentTypeFilter?: (
    equipmentType?: EquipmentType,
    index?: number,
    equipmentTypes?: EquipmentType[]
  ) => boolean;
  error: boolean;
  customErrorText?: string;
  showSelectAll?: boolean;
  selectedEquipmentTypeIds: number[];
  errorEquipmentTypeIds?: number[];
  organizationUnitId?: number;
  disabledEquipmentTypes?: string[];
}

const MultiSelectEquipmentType: React.FC<Props> = props => {
  const {
    onSelect,
    selectedEquipmentTypeIds,
    errorEquipmentTypeIds,
    onRemoveEquipmentType,
    equipmentTypeFilter,
    error,
    customErrorText,
    showSelectAll = true,
    organizationUnitId,
    disabledEquipmentTypes,
  } = props;

  const [query, setQuery] = useState('');

  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();

  const { equipmentTypesById, equipmentById } = useSelector(
    ({ app, equipment }: ApplicationState) => ({
      equipmentTypesById: app.equipmentTypesById,
      equipmentById: equipment.equipmentById,
    })
  );

  const equipmentTypes = useMemo(() => {
    const equipmentTypesInUse = Array.from(
      new Set(
        Object.values(equipmentById)
          .filter(equipment => {
            if (
              organizationUnitId !== undefined &&
              organizationUnitId !== orgId
            ) {
              return equipment.space?.parentId === organizationUnitId;
            } else {
              return true;
            }
          })
          .map(equipment => equipment.typeId)
      )
    );
    if (equipmentTypesById) {
      return equipmentTypesInUse
        .map(typeId => equipmentTypesById[typeId])
        .filter(
          equipmentType =>
            !disabledEquipmentTypes?.includes(equipmentType.title)
        )
        .sort(({ title: aTitle }, { title: bTitle }) =>
          aTitle.localeCompare(bTitle)
        );
    } else {
      return [] as EquipmentType[];
    }
  }, [equipmentById, equipmentTypesById, disabledEquipmentTypes]);

  useEffect(() => {
    if (organizationUnitId !== undefined && organizationUnitId !== orgId) {
      dispatch(
        getEquipments(
          { siteIds: [`${organizationUnitId}`] },
          organizationUnitId
        )
      );
      dispatch(getEquipmentTypes());
    } else {
      dispatch(getEquipments());
      dispatch(getEquipmentTypes());
    }
  }, []);

  const toggleSelectAll = () => {
    const { onToggleSelectAll, selectedEquipmentTypeIds } = props;
    onToggleSelectAll?.(
      selectedEquipmentTypeIds.length > 0
        ? []
        : equipmentTypeFilter
        ? equipmentTypes.filter(equipmentTypeFilter).map(type => type.id)
        : equipmentTypes?.map(type => type.id) || []
    );
  };

  const onItemClick = id => {
    const { selectedEquipmentTypeIds, onSelect, onRemoveEquipmentType } = props;
    selectedEquipmentTypeIds.indexOf(id) === -1
      ? onSelect(id)
      : onRemoveEquipmentType(id);
    setQuery('');
  };

  if (!equipmentTypes) {
    return <Loader size={12} />;
  } else {
    let customFilteredEquipmentTypes;
    if (equipmentTypeFilter !== undefined) {
      customFilteredEquipmentTypes = equipmentTypes.filter(equipmentTypeFilter);
    } else {
      customFilteredEquipmentTypes = equipmentTypes;
    }
    const filteredEquipmentTypes = customFilteredEquipmentTypes.filter(
      ({ alias, title }) => {
        const normalizedQuery = query.toLowerCase();
        if (
          (alias || '').toLowerCase().indexOf(normalizedQuery) > -1 ||
          (title || '').toLowerCase().indexOf(normalizedQuery) > -1
        )
          return true;
        return false;
      }
    );
    return (
      <MultiSelect
        doNotIncludeActions
        selectionComponentType="portalDropdown"
        onDeleteItem={(e, itemId) => onRemoveEquipmentType(Number(itemId))}
        items={selectedEquipmentTypeIds.map(equipmentTypeId => ({
          value: `${
            (
              equipmentTypes.find(({ id }) => id === equipmentTypeId) || {
                title: 'Loading...',
              }
            ).title
          }`,
          className:
            error &&
            errorEquipmentTypeIds &&
            errorEquipmentTypeIds.indexOf(equipmentTypeId) > -1
              ? styles.errorChip
              : '',
          id: equipmentTypeId,
        }))}
        selectionComponent={
          <div className={styles.dropdown}>
            <div className={styles.searchBox}>
              <SearchBox
                error={filteredEquipmentTypes.length === 0}
                withDropdown={false}
                query={query}
                width={'100%'}
                widthActive={'100%'}
                placeholder={'Search Equipment Types'}
                onChange={setQuery}
                noClickOutsideDetection
              />
            </div>
            <div className={styles.dropdownList}>
              {filteredEquipmentTypes.length > 0 && showSelectAll && (
                <div className={styles.selectAll}>
                  <span
                    tabIndex={0}
                    onKeyPress={toggleSelectAll}
                    role="button"
                    onClick={toggleSelectAll}
                  >
                    {selectedEquipmentTypeIds.length > 0
                      ? 'Deselect All'
                      : 'Select All'}
                  </span>
                </div>
              )}
              {filteredEquipmentTypes.length > 0 ? (
                filteredEquipmentTypes.map(({ title, id }) => (
                  <MultiSelectItem
                    key={`multiselect_equipment-type-${id}`}
                    isSelected={selectedEquipmentTypeIds.indexOf(id) > -1}
                    onSelect={() => onItemClick(id)}
                  >
                    {title}
                  </MultiSelectItem>
                ))
              ) : (
                <div className={styles.noItem}>{`No item available`}</div>
              )}
            </div>
          </div>
        }
        onSelection={onSelect}
        error={error}
        customErrorText={customErrorText}
      />
    );
  }
};

export default MultiSelectEquipmentType;
