import {
  InputField,
  Label,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  FanMode,
  FanModeLabel,
  HvacControlLabel,
  OutsideOfTimeTable,
  ThermostatWorkingMode,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import SelectTimeTable from '../../containers/Selects/SelectTimeTable';
import { useTemperatureUnit } from '../../hooks/utils';
import {
  EditHvacSOPControl,
  HvacControlFormErrors,
} from '../../types/hvacControl';
import { isDropdownSelected } from '../../validators';
import BiasInfoTooltip from '../BiasInfoTooltip';
import ModalFormContent from '../ModalFormContent';
import TemperatureInputField from '../TemperatureInputField';
import styles from './EditControlsHvacForm.module.css';
import EditControlsHvacSetPointTooltip from './EditControlsHvacSetPointTooltip';

type Props = {
  fields: EditHvacSOPControl;
  siteId: number;
  onChange: (field: string, value: string | number | null) => void;
  formErrors: HvacControlFormErrors;
  formErrorsVisible: boolean;
};

const EditControlsHvacFormScheduleContent: React.FC<Props> = ({
  fields,
  siteId,
  onChange,
  formErrors,
  formErrorsVisible,
}) => {
  const {
    timetableId,
    beginDelta,
    endDelta,
    outsideMaxTemp,
    outsideMinTemp,
    outsideOfTimeTable,
    thermostatWorkingMode,
    outsideFanMode,
  } = fields;
  const isTimeTableSelected = isDropdownSelected(timetableId);
  const temperatureUnit = useTemperatureUnit();

  const renderSetPointRangeInputFields = () => {
    const renderLabelAndInput = (
      label: HvacControlLabel.MAX_TEMP | HvacControlLabel.MIN_TEMP,
      value: number | undefined
    ) => {
      const fieldName =
        label === HvacControlLabel.MAX_TEMP
          ? 'outsideMaxTemp'
          : 'outsideMinTemp';

      return (
        <div className={styles.inputField}>
          <span className={styles.marginRight}>{label}</span>
          <TemperatureInputField
            integersOnly
            className={styles.input}
            id={fieldName}
            name={fieldName}
            value={value}
            onChange={v => onChange(fieldName, v)}
            error={formErrorsVisible && !!formErrors[fieldName]}
            customErrorText={formErrors[fieldName]}
          />
        </div>
      );
    };

    switch (thermostatWorkingMode) {
      case ThermostatWorkingMode.HEATING:
        return (
          <>{renderLabelAndInput(HvacControlLabel.MIN_TEMP, outsideMinTemp)}</>
        );

      case ThermostatWorkingMode.COOLING:
        return (
          <>{renderLabelAndInput(HvacControlLabel.MAX_TEMP, outsideMaxTemp)}</>
        );

      case ThermostatWorkingMode.AUTO:
        return (
          <>
            {renderLabelAndInput(HvacControlLabel.MIN_TEMP, outsideMinTemp)}
            <div className={styles.inputSeparator}> : </div>
            {renderLabelAndInput(HvacControlLabel.MAX_TEMP, outsideMaxTemp)}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <ModalFormContent className={styles.modalFormContent}>
        <div>
          <Label>{HvacControlLabel.TIME_TABLE}</Label>
        </div>
        <div>
          <SelectTimeTable
            siteId={siteId}
            value={timetableId?.toString() || null}
            includeNullSelectOption
            onSelect={(timetableId: number | null) => {
              onChange('timetableId', timetableId);
              onChange('beginDelta', 0);
              onChange('endDelta', 0);
            }}
          />
        </div>

        <div>
          <Label>
            <span className={isTimeTableSelected ? '' : styles.disabled}>
              {HvacControlLabel.BIAS}
            </span>
          </Label>
        </div>
        <div
          className={`${styles.inputFieldContainer} ${
            isTimeTableSelected ? '' : styles.disabled
          }`}
        >
          <div className={styles.inputField}>
            <span className={styles.marginRight}>Start</span>
            <div>
              <InputField
                className={styles.input}
                type="number"
                value={beginDelta}
                onChange={({ currentTarget }) =>
                  onChange('beginDelta', currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.beginDelta}
                customErrorText="Invalid"
                disabled={!isTimeTableSelected}
              />
            </div>
            <span className={styles.smallFont}>min(s)</span>
          </div>

          <div className={styles.inputSeparator}> : </div>

          <div className={styles.inputField}>
            <span className={styles.marginRight}>End</span>
            <div>
              <InputField
                value={endDelta}
                className={styles.input}
                type="number"
                onChange={({ currentTarget }) =>
                  onChange('endDelta', currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.endDelta}
                customErrorText="Invalid"
                disabled={!isTimeTableSelected}
              />
            </div>
            <span className={styles.smallFont}>min(s)</span>
            <span>
              <BiasInfoTooltip
                hasError={
                  formErrorsVisible &&
                  (!!formErrors.beginDelta || !!formErrors.endDelta)
                }
              />
            </span>
          </div>
        </div>

        <div>
          <Label>
            <span className={isTimeTableSelected ? '' : styles.disabled}>
              {HvacControlLabel.OUTSIDE_OF_TIME_TABLE}
            </span>
          </Label>
        </div>
        <div>
          <Select title={outsideOfTimeTable} disabled={!isTimeTableSelected}>
            <SelectItem
              isSelected={outsideOfTimeTable === OutsideOfTimeTable.OFF}
              onSelect={() => {
                onChange('outsideOfTimeTable', OutsideOfTimeTable.OFF);
              }}
            >
              {OutsideOfTimeTable.OFF}
            </SelectItem>
            <SelectItem
              isSelected={
                outsideOfTimeTable === OutsideOfTimeTable.ALTERNATIVE_SET_POINTS
              }
              onSelect={() => {
                onChange(
                  'outsideOfTimeTable',
                  OutsideOfTimeTable.ALTERNATIVE_SET_POINTS
                );

                onChange('outsideFanMode', FanMode.AUTO);
              }}
            >
              {OutsideOfTimeTable.ALTERNATIVE_SET_POINTS}
            </SelectItem>
          </Select>
        </div>

        {outsideOfTimeTable !== OutsideOfTimeTable.OFF && (
          <>
            <div>
              <Label>
                <span className={isTimeTableSelected ? '' : styles.disabled}>
                  {HvacControlLabel.OUTSIDE_SET_POINT_RANGE}
                </span>
              </Label>
            </div>
            <div
              className={`${styles.inputFieldContainer} ${
                isTimeTableSelected ? '' : styles.disabled
              }`}
            >
              {renderSetPointRangeInputFields()}
              <div>
                <EditControlsHvacSetPointTooltip
                  temperatureUnit={temperatureUnit}
                  hasError={
                    formErrorsVisible &&
                    (!!formErrors['outsideMaxTemp'] ||
                      !!formErrors['outsideMinTemp'])
                  }
                />
              </div>
            </div>
          </>
        )}

        {outsideOfTimeTable !== OutsideOfTimeTable.OFF && (
          <>
            <div>
              <Label>
                <span className={isTimeTableSelected ? '' : styles.disabled}>
                  Outside {HvacControlLabel.FAN_MODE}
                </span>
              </Label>
            </div>
            <div
              className={`${styles.inputFieldContainer} ${
                isTimeTableSelected ? '' : styles.disabled
              }`}
            >
              <Select
                title={
                  isDefined(outsideFanMode) ? FanModeLabel[outsideFanMode] : ''
                }
                error={
                  formErrorsVisible && isDefined(formErrors.outsideFanMode)
                }
                customErrorText={formErrors.outsideFanMode}
              >
                {Object.values(FanMode).map(mode => (
                  <SelectItem
                    key={mode}
                    isSelected={mode === outsideFanMode}
                    onSelect={() => onChange('outsideFanMode', mode)}
                  >
                    {FanModeLabel[mode]}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </>
        )}
      </ModalFormContent>
    </>
  );
};

export default EditControlsHvacFormScheduleContent;
