import { Scheduler } from '@energybox/react-ui-library/dist/types';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { useEffect } from 'react';
import styles from './LightingControls.module.css';
import { InputField } from '@energybox/react-ui-library/dist/components';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { DEFAULT_LUX_THRESHOLD } from '../../../constants/scheduler';

type Props = {
  activeControl: Scheduler;
  onChange: onChange;
  equipmentId: number;
};

const EditModeThershold: React.FC<Props> = ({
  activeControl,
  onChange,
  equipmentId,
}) => {
  const schedulerId = activeControl?.id;
  const lightSensor = activeControl?.lightSensorSettings;
  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });
  const fields = schedulerControls?.fields;
  const threshold = fields?.lightSensorSettings?.threshold;
  const controlBoardId = fields?.lightSensorSettings?.controlBoardId;
  const formErrorsVisible = schedulerControls?.formErrorsVisible;
  const formErrors = schedulerControls?.formErrors;
  const isControlBoardSelected =
    isDefined(controlBoardId) && controlBoardId !== -1;

  useEffect(() => {
    if (schedulerId && (lightSensor?.controlBoardId || controlBoardId)) {
      onChange(
        String(schedulerId),
        ['lightSensorSettings', 'threshold'],
        lightSensor?.threshold ?? DEFAULT_LUX_THRESHOLD
      );
    }
  }, [controlBoardId]);

  return (
    <InputField
      className={styles.biasInput}
      type="number"
      min={0}
      max={9900}
      value={threshold ?? DEFAULT_LUX_THRESHOLD}
      disabled={!isControlBoardSelected}
      onChange={({ currentTarget }) => {
        if (schedulerId) {
          onChange(
            String(schedulerId),
            ['lightSensorSettings', 'threshold'],
            currentTarget.value
          );
        } else {
          onChange(
            `new${equipmentId}`,
            ['lightSensorSettings', 'threshold'],
            currentTarget.value
          );
        }
      }}
      error={
        formErrorsVisible &&
        isDefined(controlBoardId) &&
        !!formErrors?.['lightSensorSettings.threshold']
      }
      customErrorText={
        formErrors?.['lightSensorSettings.threshold'] &&
        formErrors['lightSensorSettings.threshold'].join(', ')
      }
    />
  );
};

export default EditModeThershold;
