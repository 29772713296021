import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { Site } from '@energybox/react-ui-library/dist/types';
import { hasSubstr, values } from '@energybox/react-ui-library/dist/utils';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSites } from '../../actions/sites';
import { ApplicationState } from '../../reducers';
import { sortByTitleWithEmptyStringToLast } from '../../utils/sorting';

interface Props {
  onSelect: (siteId: number) => void;
  value?: number;
  error?: boolean;
  disabled?: boolean;
  customErrorText?: string;
  testResultsExist?: boolean;
}

const SelectSite: React.FC<Props> = props => {
  const {
    value,
    onSelect,
    disabled,
    error,
    customErrorText,
    testResultsExist,
  } = props;

  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  const sites = useSelector(({ sites }: ApplicationState) =>
    values(sites.sitesById).sort(sortByTitleWithEmptyStringToLast)
  );

  useEffect(() => {
    dispatch(getSites());
  }, []);

  const searchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const resetSearch = () => {
    setSearch('');
  };

  const filterSites = (sites: Site[]) => {
    return sites.filter((site: Site) => {
      if (search.length <= 2) return true;
      return hasSubstr(site.title, search);
    });
  };

  // render starts here

  if (!sites) {
    return <Loader size={12} />;
  } else {
    const selectedEquipmentSite = sites.find(site => site.id === value);
    const filteredSites = filterSites(sites);

    return (
      <Select
        variant={'select'}
        onClick={resetSearch}
        title={selectedEquipmentSite?.title}
        value={selectedEquipmentSite?.id}
        disabled={disabled || testResultsExist}
        error={error}
        customErrorText={customErrorText}
      >
        <SelectSearch
          onChange={searchChange}
          value={search}
          error={filteredSites.length === 0}
        />
        {filteredSites.map((site: Site) => (
          <SelectItem
            key={site.id}
            isSelected={site.id === selectedEquipmentSite?.id}
            onSelect={() => onSelect(site.id)}
          >
            {site.title}
          </SelectItem>
        ))}
      </Select>
    );
  }
};

export default SelectSite;
