import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions,
  getNetworkGroup,
  getNetworkGroupsByOrgId,
  getNetworkGroupsBySopId,
} from '../actions/network_groups';
import { ApplicationState } from '../reducers';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

export const useGetNetworkGroupById = (
  networkGroupId: number | string | undefined
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(networkGroupId)) {
      dispatch(getNetworkGroup(networkGroupId));
    }
  }, [dispatch, networkGroupId]);

  const networkGroup = useSelector<ApplicationState, NetworkGroup | undefined>(
    ({ networkGroups }) => {
      if (isDefined(networkGroupId)) {
        return networkGroups?.networkGroupsById[networkGroupId];
      }
      return undefined;
    }
  );

  const isLoading = useSelector<ApplicationState, boolean>(
    ({ networkGroups }) => {
      if (isDefined(networkGroupId)) {
        return (
          networkGroups?.loadingStatusByAction?.[
            Actions.GET_NETWORK_GROUP_LOADING
          ]?.[networkGroupId] ?? false
        );
      }
      return false;
    }
  );

  return { isLoading, networkGroup };
};

export const useIsNetworkGroupOnline = (
  networkGroupId: number | string | undefined
) => {
  const { isLoading, networkGroup } = useGetNetworkGroupById(networkGroupId);

  return {
    isLoading,
    isOnline: networkGroup?.edge?.onlineStatus?.onlineState,
    edgeUuid: networkGroup?.edge?.uuid,
  };
};

export const useGetNetworkGroupsBySopId = (
  sopId: number | string | undefined | null,
  orgId: number | string | undefined | null
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(sopId) && sopId !== 'new' && isDefined(orgId)) {
      dispatch(getNetworkGroupsBySopId(sopId, orgId));
    }
  }, [dispatch, sopId, orgId]);

  const networkGroups = useSelector<
    ApplicationState,
    NetworkGroup[] | undefined
  >(({ networkGroups }) => {
    if (isDefined(sopId) && isDefined(orgId)) {
      return networkGroups.networkGroupsBySopId[sopId];
    }

    return undefined;
  });

  return networkGroups;
};

export const useGetNetworkGroupsByOrgId = (orgId: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefined(orgId)) {
      dispatch(getNetworkGroupsByOrgId(orgId));
    }
  }, [dispatch, orgId]);

  const networkGroups = useSelector(({ networkGroups }: ApplicationState) => {
    if (isDefined(orgId)) {
      return networkGroups.networkGroupsByOrgId[orgId];
    }

    return undefined;
  });

  return networkGroups;
};

export default useGetNetworkGroupById;
