import React from 'react';

const PdfIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M27.9167 6.03333L25.1334 2.56667C24.96 2.34299 24.7376 2.16206 24.4833 2.03782C24.2291 1.91357 23.9497 1.84932 23.6667 1.85H8.66669V10.4667H1.66669V21.4667H8.61669V28.1333H28.3334V7.2C28.33 6.77525 28.1832 6.36411 27.9167 6.03333ZM8.00002 16.75H7.50002V18.65H6.36669V13.3333H8.03335C8.21099 13.3231 8.38906 13.3231 8.56669 13.3333C8.90092 13.3753 9.22062 13.4952 9.50002 13.6833C9.68363 13.846 9.82504 14.0508 9.91212 14.2801C9.99921 14.5094 10.0294 14.7565 10 15C10.0108 15.2384 9.97027 15.4764 9.88113 15.6978C9.79199 15.9192 9.65634 16.1189 9.48335 16.2833C9.21527 16.4868 8.89982 16.6187 8.56669 16.6667C8.38043 16.7101 8.19089 16.738 8.00002 16.75ZM27.4 27.2H9.55002V21.4667H24.1V10.4667H9.55002V2.8H23.6667C23.8104 2.79543 23.9531 2.82474 24.0834 2.88554C24.2137 2.94634 24.3279 3.03693 24.4167 3.15L27.1834 6.66667C27.3179 6.83191 27.3941 7.03699 27.4 7.25V27.2ZM11.0667 18.65V13.3333H12.7334C13.477 13.2886 14.2082 13.5403 14.7667 14.0333C15.0133 14.2789 15.2059 14.5733 15.3322 14.8976C15.4584 15.2219 15.5156 15.569 15.5 15.9167C15.5254 16.2893 15.4716 16.6631 15.3424 17.0135C15.2131 17.3639 15.0113 17.6831 14.75 17.95C14.1452 18.4543 13.3689 18.7051 12.5834 18.65H11.0667ZM19.5334 15.6167V16.5333H17.7334V18.65H16.6667V13.3333H19.6667V14.2667H17.7334V15.65L19.5334 15.6167Z"
      fill="#FF514A"
    />
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M12.8 14.2333H12.2V17.7167H12.6833C13.7833 17.7167 14.35 17.1167 14.35 15.95C14.35 14.7833 13.8167 14.2333 12.8 14.2333Z"
      fill="#FF514A"
    />
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M8.61667 14.3833C8.43667 14.2733 8.22729 14.2209 8.01667 14.2333H7.5V15.9H7.88333C8.14064 15.9139 8.39617 15.85 8.61667 15.7167C8.72598 15.6348 8.81143 15.5251 8.86417 15.3992C8.91691 15.2732 8.93504 15.1354 8.91667 15C8.92688 14.8902 8.91276 14.7795 8.87531 14.6758C8.83786 14.5721 8.778 14.4779 8.7 14.4L8.61667 14.3833Z"
      fill="#FF514A"
    />
  </svg>
);

export default PdfIcon;
