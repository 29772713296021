import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getSiteGroups } from '../../actions/site_groups';
import { sortSiteGroupTitles } from '../../utils/siteGroups/siteGroups';
import useSiteGroupsFilter from '../../hooks/useSiteGroupsFilter';

type Props = {
  dropdownClassName?: string;
  className?: string;
};

const SiteGroupFilter: React.FC<Props> = ({ dropdownClassName, className }) => {
  const dispatch = useDispatch();
  const siteGroupsById = useSelector(
    ({ siteGroups }: ApplicationState) => siteGroups.siteGroupsById ?? {}
  );
  const { setSiteGroupsFilter, selectedSiteGroups } = useSiteGroupsFilter();

  useEffect(() => {
    dispatch(getSiteGroups());
  }, []);

  const siteGroupsTitles = sortSiteGroupTitles(siteGroupsById);

  return (
    <GenericFilter
      className={className}
      dropdownClassName={dropdownClassName}
      title="Site Groups"
      setFilter={setSiteGroupsFilter}
      items={siteGroupsTitles}
      selectedItems={selectedSiteGroups}
      allowLongLabel
    />
  );
};

export default SiteGroupFilter;
