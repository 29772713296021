import { Card, CardContent } from '@energybox/react-ui-library/dist/components';
import { CurrentUser, Site } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import UtilityBillTable from '../../components/UtilityBillTable/UtilityBillTable';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { determineIfUserCanModifySite } from '../../utils/user';
import DocumentTabs from '../Documents/DocumentTabs';
import UtilityBillCard from '../UtilityBillCard';
import UploadInstallDoc from './SitePageGeneral/UploadInstallDoc';
import UploadHistoryTable from './SitePageGeneral/UploadInstallDoc/UploadHistoryTable/UploadHistoryTable';
import styles from './ShowSitePageDocuments.module.css';
import classNames from '@energybox/react-ui-library/dist/utils/classNames';

interface Props {
  site: Site;
  viewAsInstaller: boolean;
  currentUser: CurrentUser | undefined;
}

class ShowSitePageDocuments extends React.Component<Props> {
  render() {
    const { site, currentUser, viewAsInstaller } = this.props;
    return (
      <>
        {site && <DocumentTabs siteId={site.id} />}
        <Switch>
          <Route exact path={`${Routes.SITES}/${site.id}${Routes.DOCUMENTS}`}>
            <Redirect
              to={`${Routes.SITES}/${site.id}${Routes.DOCUMENTS}${Routes.UTILITY_BILLS}`}
            />
          </Route>
          <Route
            exact
            path={`${Routes.SITES}/${site.id}${Routes.DOCUMENTS}${Routes.UTILITY_BILLS}`}
            render={({ match }) =>
              match &&
              site &&
              this.renderUtilityBillContainer(
                site,
                currentUser,
                viewAsInstaller
              )
            }
          />
          <Route
            path={`${Routes.SITES}/${site.id}${Routes.DOCUMENTS}${Routes.INSTALLATION_DOCS}`}
            render={({ match }) =>
              match && site && this.renderInstallationDocContainer(site)
            }
          />
        </Switch>
      </>
    );
  }
  renderUtilityBillContainer = (site, currentUser, viewAsInstaller) => {
    const editable = determineIfUserCanModifySite(currentUser, site);
    return (
      <>
        {editable && !viewAsInstaller && (
          <div style={{ padding: '1rem 0' }}>
            <UtilityBillCard
              title={<div>Utility Bill Uploader</div>}
              siteId={site.id}
            />
          </div>
        )}
        {!viewAsInstaller && (
          <Card>
            <CardContent>
              <UtilityBillTable
                title={'Utility Bill Upload History'}
                siteId={site.id}
                editable={editable}
              />
            </CardContent>
          </Card>
        )}
      </>
    );
  };
  renderInstallationDocContainer = site => {
    return (
      <>
        <Card
          className={classNames(styles.spaceBetweenCards, styles.alignCard)}
        >
          <div className={styles.title}>Upload Installation Document</div>
          <div className={styles.alignDragAndDrop}>
            <UploadInstallDoc siteId={Number(site.id)} />
          </div>
        </Card>
        <Card className={styles.alignCard}>
          <UploadHistoryTable siteId={Number(site.id)} />
        </Card>
      </>
    );
  };
}
const mapStateToProps = (
  { app }: ApplicationState,
  { site, viewAsInstaller }: Props
) => ({
  site: site,
  viewAsInstaller: viewAsInstaller,
  currentUser: app.currentUser,
});

export default connect(mapStateToProps)(ShowSitePageDocuments);
