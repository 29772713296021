import { Thermostat } from '@energybox/react-ui-library/dist/types';
import useSubscribeToStreamEntities from '../../../../hooks/streamApi/useSubscribeToStreamEntities';
import SensorCount from './SensorCount';

type Props = {
  thermostat: Thermostat;
};
const TemperatureSensorCount: React.FC<Props> = ({ thermostat }) => {
  const {
    subscribedThermostat,
    deviceStatusById,
  } = useSubscribeToStreamEntities(thermostat);

  const isDeviceOnline =
    thermostat &&
    deviceStatusById &&
    deviceStatusById[thermostat.id]?.onlineState === true;

  return (
    <SensorCount
      subscribedThermostat={subscribedThermostat!}
      thermostat={thermostat}
      isDeviceOnline={isDeviceOnline}
    />
  );
};

export default TemperatureSensorCount;
