const apiBase = '/api/v1/iotod';

export enum Actions {
  GET_IOTOD_MAC_ADDRESS_SUCCESS = '@iotod/GET_IOTOD_MAC_ADDRESS_SUCCESS',
  GET_IOTOD_MAC_ADDRESS_ERROR = '@iotod/GET_IOTOD_MAC_ADDRESS_ERROR',
  GET_IOTOD_MAC_ADDRESS_LOADING = '@iotod/GET_IOTOD_MAC_ADDRESS_LOADING',
  RENDER_NEW_IOTOD_FIELDS = '@iotod/RENDER_NEW_IOTOD_FIELDS',
  SET_INVALID_SERIAL_NUMBER_ERROR = '@iotod/SET_INVALID_SERIAL_NUMBER_ERROR',
}

export const lookupMacAddress = (serialNumber: string) => {
  return {
    type: 'API_GET',
    path: `${apiBase}/edge-uuid/by-serial-number/${serialNumber}`,
    success: {
      type: Actions.GET_IOTOD_MAC_ADDRESS_SUCCESS,
    },
    error: {
      type: Actions.GET_IOTOD_MAC_ADDRESS_ERROR,
      serialNumber,
    },
    loading: {
      type: Actions.GET_IOTOD_MAC_ADDRESS_LOADING,
      serialNumber,
    },
  };
};

export const setInvalidSerialNumberError = () => {
  return {
    type: Actions.SET_INVALID_SERIAL_NUMBER_ERROR,
    value: 'Invalid Serial Number',
  };
};

export const renderNewIotodFields = () => {
  return {
    type: Actions.RENDER_NEW_IOTOD_FIELDS,
  };
};
