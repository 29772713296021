import {
  Loader,
  MultiSelect,
  MultiSelectItem,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import { Organization, User } from '@energybox/react-ui-library/dist/types';
import { hasSubstr, isCustomer } from '@energybox/react-ui-library/dist/utils';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizations } from '../../actions/organizations';
import { ApplicationState } from '../../reducers';
import { OrganizationById } from '../../reducers/organizations';
import styles from './MultiSelects.module.css';

interface Props {
  onSelect: (equipmentTypeId: number) => void;
  onToggleSelectAll?: (ids: number[]) => void;
  onDeselect: (equipmentTypeId: number) => void;
  showSelectAll?: boolean;
  selectedOrgIds: number[];
  includeNonCustomerOrg?: boolean;
  disabled?: boolean;
  checkAgianstUser?: User;
}

const MultiSelectOrgAccess: React.FC<Props> = ({
  onSelect,
  onToggleSelectAll,
  onDeselect,
  showSelectAll = true,
  selectedOrgIds,
  includeNonCustomerOrg,
  disabled,
  checkAgianstUser,
}) => {
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const { organizationsById, organizations } = useSelector<
    ApplicationState,
    {
      organizationsById: OrganizationById;
      organizations: Organization[];
    }
  >(({ organizations }) => ({
    organizationsById: organizations.organizationsById,
    organizations: Object.values(organizations.organizationsById),
  }));

  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  const availableOrganizations = organizations.filter(
    ({ organizationType, id }) =>
      (includeNonCustomerOrg || isCustomer(organizationType)) &&
      (!checkAgianstUser ||
        !checkAgianstUser.accessResources.find(
          ({ resourceId }) => resourceId === id
        ))
  );

  const filteredOrganizations = availableOrganizations.filter(({ title }) =>
    hasSubstr(title, query)
  );

  const toggleSelectAll = () => {
    onToggleSelectAll?.(
      selectedOrgIds.length > 0
        ? []
        : filteredOrganizations.map(org => org.id) || []
    );
  };

  const onItemClick = id => {
    selectedOrgIds.indexOf(id) === -1 ? onSelect(id) : onDeselect(id);
    setQuery('');
  };

  if (!organizations) {
    return <Loader size={12} />;
  }

  return (
    <div className={disabled ? styles.disabled : ''}>
      <MultiSelect
        doNotIncludeActions
        selectionComponentType="portalDropdown"
        onDeleteItem={(_, itemId) => onDeselect(Number(itemId))}
        items={selectedOrgIds.map(selectedId => ({
          value: organizationsById[selectedId].title,
          className: '',
          id: selectedId,
        }))}
        selectionComponent={
          <div className={styles.dropdown}>
            <div className={styles.searchBox}>
              <SearchBox
                error={filteredOrganizations.length === 0}
                withDropdown={false}
                query={query}
                width={'100%'}
                widthActive={'100%'}
                placeholder={'Search Organization'}
                onChange={setQuery}
                noClickOutsideDetection
              />
            </div>
            <div className={styles.dropdownList}>
              {showSelectAll && (
                <div className={styles.selectAll}>
                  <span
                    tabIndex={0}
                    onKeyPress={toggleSelectAll}
                    role="button"
                    onClick={toggleSelectAll}
                  >
                    {selectedOrgIds.length > 0 ? 'Deselect All' : 'Select All'}
                  </span>
                </div>
              )}
              {filteredOrganizations.map(({ title, id }) => (
                <MultiSelectItem
                  isSelected={selectedOrgIds.indexOf(id) > -1}
                  onSelect={() => onItemClick(id)}
                >
                  {title}
                </MultiSelectItem>
              ))}
            </div>
          </div>
        }
        onSelection={onSelect}
      />
    </div>
  );
};

export default MultiSelectOrgAccess;
