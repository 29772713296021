import {
  Locale,
  NewCertificationDto,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  InputField,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
  SingleDatePicker,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import format from 'date-fns/format';
import { addMonths, subDays } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as CertificationActions,
  create,
  displayFormErrors,
  hideNewCertificationModal,
  updateField,
} from '../../actions/certifications';
import ModalFormContent from '../../components/ModalFormContent';
import { ApplicationState } from '../../reducers';
import { CreateNewText, PropertyToLabel } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import SelectVendor from '../Selects/SelectVendor';
import styles from './NewCertificationModal.module.css';

interface OwnProps {
  resourceId?: string | number;
  lockUuid?: boolean;
  lockVendor?: boolean;
}

interface Props extends OwnProps {
  locale: Locale;
  isVisible: boolean;
  onClose: () => void;
  onChange: (field: string, value: any) => void;
  onCreate: (resourceId?: string | number) => void;
  formErrors: GenericErrors;
  fields: NewCertificationDto;
  isLoading: boolean;
  disabled?: boolean;
  apiError: ApiError;
  displayFormErrors: typeof displayFormErrors;
  formErrorsVisible: boolean;
}

type State = {
  preselectedValidFrom: string;
  preselectedValidTo: string;
};

class NewCertificationModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const today = format(new Date(), 'yyyy-MM-dd');
    this.state = {
      preselectedValidFrom: today,
      preselectedValidTo: format(
        subDays(addMonths(new Date(), 14), 1),
        'yyyy-MM-dd'
      ),
    };
  }

  onCertificateCreate = () => {
    const { formErrors, displayFormErrors, onCreate } = this.props;

    if (hasKeys(formErrors)) {
      displayFormErrors();
    } else {
      onCreate(this.props.resourceId);
    }
  };

  onCertificationModalCancel = () => {
    this.props.onClose();
  };

  onFileChange = e => {
    const { onChange } = this.props;
    if (e.target.files) {
      onChange('certificate', e.currentTarget.files[0]);
    }
  };

  renderCalendarSelect = (field: 'validFrom' | 'validTo') => {
    const { preselectedValidFrom, preselectedValidTo } = this.state;
    const {
      locale,
      fields,
      onChange,
      formErrors,
      formErrorsVisible,
    } = this.props;
    const { validFrom, validTo } = fields;

    const isPropertyValidFrom = field === 'validFrom';
    const preselectedFieldValue = isPropertyValidFrom
      ? preselectedValidFrom
      : preselectedValidTo;
    const fieldValue = isPropertyValidFrom ? validFrom : validTo;

    const onCalendarDateClick = (date: string) => {
      const key = isPropertyValidFrom
        ? 'preselectedValidFrom'
        : 'preselectedValidTo';

      this.setState<never>({
        [key]: date,
      });
    };

    return (
      <SingleDatePicker
        className={styles.datePicker}
        locale={locale}
        value={fieldValue || undefined}
        setDate={date => {
          onChange(field, date);
          if (field === 'validFrom') {
            onChange(
              'validTo',
              format(addMonths(new Date(date), 14), 'yyyy-MM-dd')
            );
          }
          onCalendarDateClick(date);
        }}
        disableBefore={isPropertyValidFrom ? '' : validFrom}
        disableAfter={
          isPropertyValidFrom ? validTo || '9999-12-31' : '9999-12-31'
        }
        error={formErrorsVisible && !!formErrors[field]}
        customErrorText={formErrors[field] && formErrors[field][0]}
      />
    );
  };

  render() {
    if (!this.props.isVisible) return null;

    const {
      locale,
      onClose,
      onChange,
      isLoading,
      formErrors,
      formErrorsVisible,
      fields,
      apiError,
      lockUuid,
      lockVendor,
    } = this.props;

    const { preselectedValidFrom, preselectedValidTo } = this.state;

    const {
      title,
      description,
      deviceUuid,
      deviceVendor,
      validFrom,
      validTo,
      certificationBody,
      certificationCompany,
      certificationId,
    } = fields;

    const actions = (
      <>
        <Button variant="text" onClick={this.onCertificationModalCancel}>
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={this.onCertificateCreate}
        >
          Add
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose={true}>
        <ModalTitle>{CreateNewText.CERTIFICATION}</ModalTitle>
        <ModalContent>
          <ModalFormContent>
            <div>
              <Label required> Certification Name</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="title"
                autoComplete="title"
                value={title}
                onChange={e => onChange('title', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.title}
                customErrorText={formErrors.title && formErrors.title[0]}
              />
            </div>

            <div>
              <Label>{PropertyToLabel.description}</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="description"
                value={description}
                autoComplete="description"
                onChange={e => onChange('description', e.currentTarget.value)}
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.deviceUuid}</Label>
            </div>
            <div>
              <InputField
                disabled={!!lockUuid}
                type="text"
                name="deviceUuid"
                value={deviceUuid}
                autoComplete="deviceUuid"
                onChange={e => onChange('deviceUuid', e.currentTarget.value)}
                error={formErrorsVisible && !!formErrors.deviceUuid}
                customErrorText={
                  formErrors.deviceUuid && formErrors.deviceUuid[0]
                }
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.deviceVendor}</Label>
            </div>

            <div>
              <SelectVendor
                disabled={!!lockVendor}
                onSelect={value => onChange('deviceVendor', value)}
                value={deviceVendor}
                error={formErrorsVisible && !!formErrors.deviceVendor}
                customErrorText={
                  formErrors.deviceVendor && formErrors.deviceVendor[0]
                }
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.certificationBody}</Label>
            </div>
            <div>
              <InputField
                disabled
                type="text"
                name="certificationBody"
                value={certificationBody}
                autoComplete="certificationBody"
                onChange={e =>
                  onChange('certificationBody', e.currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.certificationBody}
                customErrorText={
                  formErrors.certificationBody &&
                  formErrors.certificationBody[0]
                }
              />
            </div>
            <div>
              <Label required>{PropertyToLabel.certificationCompany}</Label>
            </div>
            <div>
              <InputField
                disabled
                type="text"
                name="certificationCompany"
                value={certificationCompany}
                autoComplete="certificationCompany"
                onChange={e =>
                  onChange('certificationCompany', e.currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.certificationCompany}
                customErrorText={
                  formErrors.certificationCompany &&
                  formErrors.certificationCompany[0]
                }
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.certificationId}</Label>
            </div>
            <div>
              <InputField
                type="text"
                name="certificationId"
                value={certificationId}
                autoComplete="certificationId"
                onChange={e =>
                  onChange('certificationId', e.currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.certificationId}
                customErrorText={
                  formErrors.certificationId && formErrors.certificationId[0]
                }
              />
            </div>

            <div>
              <Label required>{PropertyToLabel.validFrom}</Label>
            </div>
            <div>
              {this.renderCalendarSelect('validFrom')}
              {formErrorsVisible &&
                !!formErrors.validFrom &&
                formErrors.validFrom &&
                formErrors.validFrom[0] && (
                  <span className={styles.formErrorText}>
                    {' '}
                    {formErrors.validFrom[0]}{' '}
                  </span>
                )}
            </div>

            <div>
              <Label>{PropertyToLabel.validTo}</Label>
            </div>
            <div>
              {this.renderCalendarSelect('validTo')}
              {formErrorsVisible &&
                !!formErrors.validTo &&
                formErrors.validTo &&
                formErrors.validTo[0] && (
                  <span className={styles.formErrorText}>
                    {' '}
                    {formErrors.validTo[0]}{' '}
                  </span>
                )}
            </div>

            <div>
              <Label required>{PropertyToLabel.certificate}</Label>
            </div>
            <div>
              <input type="file" onChange={this.onFileChange} />
              <span className={styles.inputMessage}>
                {'Maximum size limit - 5 MB'}
              </span>
              {formErrorsVisible &&
                !!formErrors.certificate &&
                formErrors.certificate &&
                formErrors.certificate[0] && (
                  <span className={styles.formErrorText}>
                    {' '}
                    {formErrors.certificate[0]}{' '}
                  </span>
                )}
            </div>
          </ModalFormContent>

          <ModalFormContent>
            <Label>* Mandatory fields</Label>
          </ModalFormContent>
          {renderAPIerror(
            apiError,
            CertificationActions.CREATE_CERTIFICATION_ERROR
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app, certifications }: ApplicationState) => ({
  locale: app.locale,
  isVisible: certifications.showNewCertificateModal,
  ...certifications.newCertificate,
});

const mapDispatchToProps = {
  onClose: () => hideNewCertificationModal(),
  onChange: (field: string, value: string) => updateField(field, value),
  onCreate: resourceId => create(resourceId),
  displayFormErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCertificationModal);
