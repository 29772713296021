import {
  Button,
  Card,
  CardActions,
  CardContent,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import { ApiError, User } from '@energybox/react-ui-library/dist/types';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as UserActions,
  destroy,
  displayFormErrors,
  getUser,
  getUsers,
  patch,
  reset,
  updateField,
} from '../../actions/users';
import EditUserForm from '../../components/EditUserForm';
import DeleteModal from '../../components/Modals/DeleteModal';
import ShowDetailPageHeader from '../../components/ShowDetailPageHeader';
import { USER_API_TOKEN_WHITELIST } from '../../featureFlagSettings';
import { ApplicationState } from '../../reducers';
import { EditUser, UsersById } from '../../reducers/users';
import { fullName } from '../../util';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import FeatureFlag from '../FeatureFlag';
import AccessToOrganizations from './AccessToOrganizations';
import InstallerEditInstallerGroups from './InstallerEditInstallerGroups';
import InvitationStatus from './InvitationStatus';
import styles from './ShowUserPage.module.css';
import UserApiToken from './UserApiToken/UserApiToken';
import UserEditUserGroups from './UserEditUserGroups';
import UserKioskSettings from './UserKioskSettings/UserKioskSettings';
import UserSiteAccess from './UserSiteAccess';
import { accessDeniedError } from '../../utils/ApiError/accessDeniedError';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  onChange: (field: string, value: string) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  usersById: UsersById;
  user?: User;
  editUser?: EditUser;
  displayFormErrors: () => void;
  getUsers: () => void;
  userApiError: ApiError;
}

interface State {
  showDeletePrompt: boolean;
}

class ShowUserPageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeletePrompt: false,
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.usersById).length === 0) this.props.getUsers();
    this.props.load();
  }

  handleOpenDeletePrompt() {
    this.setState({ showDeletePrompt: true });
  }

  handleCloseDeletePrompt() {
    this.setState({ showDeletePrompt: false });
  }

  headerDescription(): string {
    if (!this.props.user) return '';

    const { organizationUnitTitle, position } = this.props.user;

    let result = '';
    result += organizationUnitTitle || '<No site assigned>';
    result += ': ';
    result += position || '<No position>';

    return result;
  }

  onSave = () => {
    const { patch, editUser, displayFormErrors } = this.props;
    if (editUser && hasKeys(editUser.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  render() {
    const {
      usersById,
      user,
      editUser,
      onDelete,
      onChange,
      onCancel,
      userApiError,
    } = this.props;
    const { showDeletePrompt } = this.state;

    const isInstaller =
      user?.organizationUnitType === OrganizationType.INSTALLER_PARTNER;

    const apiError = userApiError && Object.keys(userApiError).length > 0;

    return !apiError ? (
      <div className={styles.root}>
        {user && (
          <ShowDetailPageHeader
            name={fullName(user)}
            description={this.headerDescription()}
            isUser={true}
            resourceName="User"
            onDelete={this.handleOpenDeletePrompt.bind(this)}
          />
        )}
        <div style={{ padding: '3rem' }}>
          {editUser && (
            <>
              <Card>
                <CardContent>
                  <EditUserForm
                    onChange={onChange}
                    formErrors={editUser.formErrors}
                    formErrorsVisible={editUser.formErrorsVisible}
                    {...editUser.fields}
                    editUserProps={editUser}
                    editInstallers={isInstaller}
                  />
                </CardContent>

                {editUser.isChanged && (
                  <CardActions>
                    <Button
                      variant="text"
                      onClick={onCancel}
                      children="Cancel"
                    />

                    <Button disabled={editUser.isLoading} onClick={this.onSave}>
                      {editUser.isLoading ? (
                        <Loader size={16} variant="secondary" />
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </CardActions>
                )}
              </Card>

              {user && (
                <div className={styles.settingsCard}>
                  <InvitationStatus user={user} />
                </div>
              )}

              {user && !isInstaller && (
                <FeatureFlag>
                  <div className={styles.settingsCard}>
                    <UserKioskSettings user={user} />
                  </div>
                </FeatureFlag>
              )}
              {user && !isInstaller && (
                <FeatureFlag orgWhiteList={USER_API_TOKEN_WHITELIST}>
                  <div className={styles.settingsCard}>
                    <UserApiToken userId={user.id} />
                  </div>
                </FeatureFlag>
              )}

              {isInstaller ? (
                <>
                  <div className={styles.settingsCard}>
                    {user && <AccessToOrganizations user={user} />}
                  </div>
                  <div className={styles.settingsCard}>
                    {user && (
                      <InstallerEditInstallerGroups
                        userId={user.id}
                        groupIds={user.groupIds || []}
                        usersById={usersById}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div
                  className={styles.settingsCard}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 1fr',
                    columnGap: '1.25rem',
                  }}
                >
                  {user && (
                    <UserEditUserGroups
                      userId={user.id}
                      groupIds={user.groupIds || []}
                    />
                  )}

                  <Card>
                    <CardContent>
                      {user && <UserSiteAccess id={user.id.toString()} />}
                    </CardContent>
                  </Card>
                </div>
              )}
            </>
          )}
        </div>

        {showDeletePrompt && (
          <DeleteModal
            onDelete={onDelete}
            onClose={this.handleCloseDeletePrompt.bind(this)}
            apiError={
              editUser &&
              renderAPIerror(editUser.apiError, UserActions.DELETE_USER_ERROR)
            }
            title={user ? fullName(user) : 'this user'}
          />
        )}
      </div>
    ) : (
      accessDeniedError(userApiError)
    );
  }
}

const mapStateToProps = ({ users }: ApplicationState, { id }: OwnProps) => ({
  usersById: users.usersById,
  user: users.usersById[parseInt(id)],
  editUser: users.editById[parseInt(id)],
  userApiError: users.userApiError,
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => dispatch(getUser(id)),
  onChange: (f: string, v: string) => dispatch(updateField(id, f, v)),
  patch: () => dispatch(patch(id)),
  onDelete: () => dispatch(destroy(id)),
  onCancel: () => dispatch(reset(id)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
  getUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowUserPageContainer);
