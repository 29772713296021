import {
  initialSentinelSchedule,
  sentinelConfigurations,
  SentinelSchedule,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import AddEntry from '../StepActions/AddEntry';
import StepTitle from '../StepTitle';
import styles from './StepSchedule.module.css';
import StepScheduleItem from './StepScheduleItem';
import { Checkbox } from '@energybox/react-ui-library/dist/components';

type Props = {
  schedules: SentinelSchedule[];
  handleAddSchedule: (schedule: SentinelSchedule) => void;
  handleUpdateSchedule: (index: number) => (schedule: SentinelSchedule) => void;
  handleRemoveSchedule: (index: number) => void;
  handlePersistBeyondSchedule: (value: boolean) => void;
  persistBeyondSchedule: boolean;
  sentinelType: SentinelType;
};

function StepSchedule(props: Props) {
  const {
    schedules,
    handleAddSchedule,
    handleUpdateSchedule,
    handleRemoveSchedule,
    handlePersistBeyondSchedule,
    persistBeyondSchedule,
    sentinelType,
  } = props;

  return (
    <div className={styles.root}>
      <StepTitle>Customise the time period you want to be notified.</StepTitle>

      {schedules.length > 0 && (
        <div className={styles.container}>
          {schedules.map((schedule, index) => (
            <StepScheduleItem
              key={`SentinelScheduleItem${index.toString()}`}
              schedule={schedule}
              handleUpdateSchedule={handleUpdateSchedule(index)}
              handleRemoveSchedule={() => handleRemoveSchedule(index)}
            />
          ))}
        </div>
      )}

      {schedules.length === 0 && (
        <>
          <div className={styles.centeredContainer}>
            This sentinel is valid for a daily period of 24 hours
          </div>
          <AddEntry
            title="Add new time schedule"
            onClick={() => handleAddSchedule(initialSentinelSchedule)}
          />
        </>
      )}

      {sentinelType !== SentinelType.NONE &&
        sentinelConfigurations[sentinelType] &&
        sentinelConfigurations[sentinelType].customSchedule &&
        schedules.length !== 0 &&
        schedules.length < 5 && (
          <AddEntry
            title="Add new time schedule"
            onClick={() => handleAddSchedule(initialSentinelSchedule)}
          />
        )}

      {schedules.length > 0 && (
        <div className={styles.checkboxContainer}>
          <div className={styles.checkbox}>
            <Checkbox
              onChange={() =>
                handlePersistBeyondSchedule(!persistBeyondSchedule)
              }
              checked={persistBeyondSchedule}
            />
          </div>
          <div className={styles.checkboxLabel}>
            Please check the box if the incident should persist beyond the
            scheduled period until it is resolved.
          </div>
        </div>
      )}
    </div>
  );
}

export default StepSchedule;
