import {
  TimeTable,
  TimeTableRowStore,
  TimeTableSpecialStore,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as TimeTableActions,
  addTimeTableRow,
  addTimeTableSpecialRow,
  createTimeTable,
  displayFormErrors,
  hideNewModal,
  removeSpecialRow,
  removeTimeTableRow,
  resetTimeTable,
  updateField,
} from '../../actions/time_tables';
import EditTimeTableForm from '../../components/EditTimeTableForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/time_tables';
import { CreateNewText } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import {
  check24hrOptionSettings,
  denormalizeTimeTableRows,
  denormalizeTimeTableSpecialRows,
} from '../../utils/timeTables';
import styles from './TimeTableModal.module.css';

interface OwnProps {
  organizationUnitId: string;
}

interface Props extends OwnProps {
  isVisible: boolean;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  apiError: ApiError;
  formErrorsVisible: boolean;
  addTimeTableRow: (specialRowIndex?: number | boolean) => void;
  addTimeTableSpecialRow: () => void;
  createTimeTable: (timeTable: TimeTable) => void;
  updateField: (
    path: any[],
    field: string | number,
    value: string | TimeTableRowStore | TimeTableSpecialStore,
    validationType: 'timeTable' | 'timeTableRow' | 'specialTimeTableRow'
  ) => void;
  removeTimeTableRow: (
    rowIndex: number,
    specialRowIndex?: number | boolean
  ) => void;
  removeSpecialRow: (rowIndex: number) => void;
  displayFormErrors: () => void;
}

class NewTimeTableModal extends React.Component<Props> {
  private rowConflictError: ApiError | undefined;
  checkFormErrors = (): boolean => {
    /* TODO
      This needs to be done in a different way. For now this is just a quick fix.
      Lets discuss with the team for a good convention to handle these kind of nested formErrors.
    */
    const { fields } = this.props;
    let hasErrors = false;

    hasErrors = check24hrOptionSettings(fields) !== null;

    if (hasKeys(fields.formErrors)) {
      hasErrors = true;
    }

    if (!hasErrors) {
      fields.rows.forEach(row => {
        if (hasKeys(row.formErrors)) {
          hasErrors = true;
        }
      });
    }

    if (!hasErrors) {
      fields.specials.forEach(special => {
        if (hasKeys(special.formErrors)) {
          hasErrors = true;
        }
        if (!hasErrors) {
          special.rows.forEach(row => {
            if (hasKeys(row.formErrors)) {
              hasErrors = true;
            }
          });
        }
      });
    }

    return hasErrors;
  };

  denormalizeFormData = () => {
    const { fields, organizationUnitId } = this.props;

    let hasErrors = this.checkFormErrors();
    let data: TimeTable = {
      title: fields.title,
      organizationUnitId,
      rows: denormalizeTimeTableRows(fields.rows),
      specials: denormalizeTimeTableSpecialRows(fields.specials),
      propagateConfig: false,
    };

    if (hasErrors) {
      return false;
    }

    return data;
  };

  checkTimeTableConflicts = () => {
    this.rowConflictError = undefined;
    let conflictingDays = check24hrOptionSettings(this.props.fields);
    if (conflictingDays !== null) {
      this.rowConflictError = {
        type: null,
        status: 0,
        message: `Timetable contains conflicting 24hr-Option setting for day(s): ${conflictingDays}`,
        force: true,
      };
    }
  };

  onCreateTimeTable = () => {
    const data = this.denormalizeFormData();

    this.checkTimeTableConflicts();

    if (data) {
      this.props.createTimeTable(data);
    }

    return this.props.displayFormErrors();
  };

  render() {
    this.checkTimeTableConflicts();
    if (!this.props.isVisible) return null;

    const {
      onClose,
      isLoading,
      fields,
      apiError,
      addTimeTableRow,
      addTimeTableSpecialRow,
      updateField,
      removeTimeTableRow,
      removeSpecialRow,
      formErrorsVisible,
    } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={this.onCreateTimeTable}>
          Add
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose>
        <ModalTitle>{CreateNewText.TIME_TABLE}</ModalTitle>
        <ModalContent className={styles.modalContent}>
          <EditTimeTableForm
            formErrorsVisible={formErrorsVisible}
            updateField={updateField}
            addTimeTableRow={addTimeTableRow}
            addTimeTableSpecialRow={addTimeTableSpecialRow}
            removeTimeTableRow={removeTimeTableRow}
            removeSpecialRow={removeSpecialRow}
            {...fields}
          />
          {renderAPIerror(
            this.rowConflictError || apiError,
            TimeTableActions.CREATE_TIME_TABLE_ERROR
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ timeTables }: ApplicationState) => ({
  isVisible: timeTables.showNewModal,
  ...timeTables.editById.new,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    onClose: () => dispatch(hideNewModal(), resetTimeTable()),
    createTimeTable: payload => dispatch(createTimeTable(payload)),
    addTimeTableRow: specialRowIndex =>
      dispatch(addTimeTableRow(specialRowIndex)),
    addTimeTableSpecialRow: () => dispatch(addTimeTableSpecialRow()),
    updateField: (path, field, value, validationType) =>
      dispatch(updateField(path, field, value, validationType)),
    removeTimeTableRow: (rowIndex, specialRowIndex) =>
      dispatch(removeTimeTableRow(rowIndex, specialRowIndex)),
    removeSpecialRow: rowIndex => dispatch(removeSpecialRow(rowIndex)),
    displayFormErrors: () => dispatch(displayFormErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTimeTableModal);
