import React from 'react';

type Props = {
  size: number;
};

const DashboardUserIcon = ({ size }: Props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 160 160"
      enableBackground="new 0 0 160 160"
    >
      <path
        opacity=".1"
        fill="#66D2D9"
        d="M140.67 103.09l-.22-.7c-2-6.3-7.22-10.81-13.63-11.78l-.64-.1-.33.55c-.01.01-.02.03-.03.04-.91 1.5-2.29 2.87-3.92 4.05-2.07-1.92-3.06-5.74-3.44-7.18-.06-.24-.11-.43-.15-.55-.04-.12-.1-.23-.17-.33.07-.04.13-.09.2-.14h9.17l-2.4-18.39v-2.09c0-8.52-6.91-15.44-15.44-15.44-5.98 0-11.15 3.4-13.72 8.38a15.34 15.34 0 0 0-9.72-3.45c-3.94 0-7.52 1.49-10.25 3.92-2.48-5.22-7.78-8.84-13.94-8.84-8.52 0-15.44 6.91-15.44 15.44v2.09l-2.4 18.39h9.17c.07.05.13.1.2.14-.08.1-.14.21-.17.33-.04.13-.09.31-.15.55-.37 1.44-1.37 5.26-3.44 7.18-1.63-1.18-3.01-2.55-3.92-4.05-.01-.01-.02-.03-.03-.04l-.33-.55-.64.1c-6.41.97-11.63 5.48-13.63 11.78l-.22.7.61.4c.38.25 9.24 5.91 27.02 6.38 4.29 1.89 13.54 4.96 28.56 4.96 13.73 0 22.56-3.09 26.67-4.98 17.2-.63 25.76-6.1 26.14-6.35l.63-.42z"
      />
      <path
        fill="#00A1AF"
        d="M98.15 105.98h-11c-.28 0-.5.22-.5.5s.22.5.5.5h11c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM111.48 101.33c-1.93-6.1-6.99-10.47-13.2-11.41l-.66-.1-.34.57c-1.06 1.77-2.77 3.37-4.82 4.65-2.03-2.98-3.06-7.88-3.5-10.1 4.52-2.55 7.57-7.33 7.57-12.82v-7.38c0-8.16-6.72-14.77-15-14.77s-15 6.61-15 14.77v7.38c.06 1.41.43 6.53 4.39 10.44a14.85 14.85 0 0 0 3.17 2.37c-.43 2.18-1.43 6.93-3.38 9.92-1.92-1.25-3.51-2.78-4.53-4.47l-.34-.57-.66.1c-6.21.94-11.26 5.31-13.2 11.41-.17.53.12 1.09.65 1.26.1.03.2.05.3.05.42 0 .82-.27.95-.7 1.66-5.24 5.73-8.91 10.96-9.92 3.21 4.72 10.32 8.21 16.89 8.21s13.67-3.49 16.89-8.21c5.23 1.02 9.3 4.68 10.96 9.92.17.53.73.82 1.26.65.52-.16.81-.72.64-1.25zM68.53 72.09v-7.34c0-7.04 5.83-12.77 13-12.77 6.16 0 11.32 4.23 12.65 9.89-.43.13-.87.24-1.33.34-.3.06-.62.12-.95.18-5.91.95-12.8-.86-16.02-4.21-.19-.2-.51-.2-.71-.01-.2.19-.21.51-.01.71 2.82 2.94 8.24 4.77 13.53 4.77 1.14 0 2.27-.08 3.37-.26.34-.06.68-.12 1-.19.45-.1.88-.21 1.31-.33.09.62.16 1.25.16 1.89v7.38c0 7.04-5.83 12.77-13 12.77-2.46 0-4.86-.92-4.88-.93a13.44 13.44 0 0 1-4.32-2.82c-3.21-3.18-3.73-7.37-3.8-9.07zm3.03 23.3c1.97-3.02 2.96-7.5 3.45-9.96.4.19.72.33.9.4 0 0 2.72 1.07 5.62 1.07 2.34 0 4.54-.54 6.51-1.48.5 2.5 1.52 7.11 3.56 10.14-2.95 1.64-6.48 2.66-9.87 2.66-3.51 0-7.17-1.08-10.17-2.83z"
      />
      <g fill="#66D2D9">
        <path d="M57.08 45.17c-8.22 0-14.89 6.51-14.99 14.59l-2.75 21.07H49.5c-.49 2.38-1.48 6.53-3.25 9.24-1.92-1.25-3.51-2.78-4.53-4.47l-.34-.57-.66.1c-6.21.94-11.26 5.31-13.2 11.41-.17.53.12 1.09.65 1.26.53.17 1.09-.13 1.26-.65 1.66-5.24 5.73-8.91 10.96-9.92 2.53 3.71 7.48 6.66 12.67 7.75.47-.62.98-1.2 1.52-1.75-2.63-.39-5.23-1.32-7.47-2.62 1.92-2.95 2.91-7.28 3.42-9.77.11-.01.21-.03.31-.06.27.12.49.22.63.27 0 0 2.72 1.07 5.62 1.07 2.34 0 4.54-.54 6.51-1.48.35 1.78.98 4.63 2.05 7.21l1.01-.15c-1.16-2.73-1.81-5.88-2.14-7.53.57-.32 1.11-.67 1.63-1.06-.3-.67-.54-1.32-.74-1.96a13.1 13.1 0 0 1-8.32 2.97c-2.46 0-4.86-.92-4.88-.93a13.44 13.44 0 0 1-4.32-2.82c-3.21-3.17-3.73-7.36-3.8-9.06v-7.33l.39-3c0-.02-.01-.03 0-.05 1.39-5.58 6.51-9.73 12.61-9.73 4.96 0 9.27 2.75 11.46 6.78.45-.53.94-1.02 1.46-1.49a15.012 15.012 0 0 0-12.93-7.32zm-10.61 32.6c.4.4.81.74 1.21 1.06h-6.07L42.64 71c.55 2.07 1.64 4.6 3.83 6.77zM136.36 96.52c-1.94-6.1-6.99-10.47-13.2-11.41l-.66-.1-.34.57c-1.06 1.77-2.77 3.37-4.82 4.65-1.83-2.69-2.85-6.94-3.35-9.38h10.2l-2.81-21.53c-.32-7.87-6.89-14.16-14.97-14.16-5.61 0-10.5 3.04-13.07 7.54.51.49.99 1.01 1.44 1.56 2.13-4.2 6.54-7.09 11.63-7.09 6.16 0 11.32 4.23 12.65 9.89-.43.13-.87.24-1.33.34-.3.06-.62.12-.95.18-5.92.95-12.8-.86-16.02-4.21-.19-.2-.51-.2-.71-.01-.2.19-.21.51-.01.71 2.82 2.94 8.24 4.77 13.53 4.77 1.14 0 2.27-.08 3.37-.26.34-.06.68-.12 1-.19.43-.09.84-.2 1.25-.32l.19 1.48c0 .13.02.27.02.4v7.38c0 7.04-5.83 12.77-13 12.77-2.46 0-4.86-.92-4.88-.93-.8-.32-2.27-1.01-3.71-2.26-.2.68-.45 1.33-.74 1.97.66.52 1.31.94 1.88 1.26-.33 1.66-.98 4.81-2.14 7.54l1.02.15c1.07-2.58 1.69-5.43 2.05-7.21.4.19.72.33.9.4 0 0 2.72 1.07 5.62 1.07 2.22 0 4.31-.49 6.21-1.34.11.05.22.08.35.09.51 2.54 1.53 6.97 3.52 9.9a21.355 21.355 0 0 1-7.55 2.5c.54.56 1.06 1.15 1.53 1.78C115.79 94 120.91 91 123.5 87.2c5.23 1.02 9.29 4.68 10.96 9.92.17.53.73.82 1.26.65.51-.16.81-.72.64-1.25zM120.89 71.1l1.01 7.77h-6.15c2.48-1.95 4.31-4.66 5.14-7.77z" />
        <path d="M52.13 51.55c-.19-.2-.51-.2-.71-.01-.2.19-.21.51-.01.71 2.82 2.94 8.24 4.77 13.53 4.77.41 0 .82-.02 1.23-.05.23-.36.66-.71.96-1.07-5.66.6-11.96-1.19-15-4.35zM28.83 65.74c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5s.22.5.5.5h1zM24.83 70.74h-1c-.28 0-.5.22-.5.5s.22.5.5.5h1c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM28.33 68.24c0 .28.22.5.5.5h3c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-3c-.27 0-.5.23-.5.5zM24.83 67.74h-5c-.28 0-.5.22-.5.5s.22.5.5.5h5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM130.73 56.83c.28 0 .5-.22.5-.5v-5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v5c0 .27.22.5.5.5z" />
        <circle cx="130.73" cy="49.33" r=".5" />
        <path d="M133.73 58.83c.28 0 .5-.22.5-.5v-3c0-.28-.22-.5-.5-.5s-.5.22-.5.5v3c0 .27.22.5.5.5zM130.73 60.83c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5s-.5.22-.5.5v1c0 .27.22.5.5.5zM131.25 67.3a.485.485 0 0 0 .7 0l1.27-1.27v6.29c0 .28.22.5.5.5s.5-.22.5-.5v-6.29l1.27 1.27c.2.2.51.2.71 0s.2-.51 0-.71l-2.12-2.12c-.2-.2-.51-.2-.71 0l-2.12 2.12c-.19.2-.19.52 0 .71zM130.73 77.83c.28 0 .5-.22.5-.5v-4c0-.28-.22-.5-.5-.5s-.5.22-.5.5v4c0 .27.22.5.5.5zM133.73 80.83c.28 0 .5-.22.5-.5v-.5h.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-.5v-.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5v.5h-.5c-.28 0-.5.22-.5.5s.22.5.5.5h.5v.5c0 .27.22.5.5.5z" />
      </g>
    </svg>
  );
};

export default DashboardUserIcon;
