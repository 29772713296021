import {
  Button,
  Label,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  Site,
  Sop,
  SopTypeCategory,
  SopTypes,
  SopTypeToLabel,
  SortDirection,
  TimeTablesById,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions,
  customizeOrganizationSop,
  destroy as deleteSop,
  getSop,
  getSops,
  reset as resetSop,
} from '../../actions/sops';
import { ApplicationState } from '../../reducers';
import { checkCommonPlural } from '../../util';
import { sopComponentTypeAppliesToEquipment } from '../../utils/sops';
import NewOrEditSopModal from './NewOrEditSopModal';
import styles from './ShowOrganizationSopsPage/ShowOrganizationSopsPage.module.css';
import SopUnit from './SopUnit';
import SopValue from './SopValue';

interface OwnProps {
  id: string;
}

interface State {
  query: string;
  showSopDeleteModal: boolean;
  sopStagedForDelete: number;
  sopId: string | number;
  sopType: SopTypeCategory;
}

interface Props extends OwnProps {
  load: () => void;
  sops: Sop[];
  sopId: string;
  timeTablesById?: TimeTablesById;
  siteEquipmentTypeIds?: number[];
  sopType: SopTypeCategory;
  site: Site;
  activeTabTitle: string;
  getSop: typeof getSop;
  getSopsIsLoading?: boolean;
  customizeOrganizationSop: (sop: Sop, sopType: SopTypeCategory) => void;
  onEditSop: (id: string | number) => void;
  resetSop: (id: string | number) => void;
  deleteSop: (id: string | number) => void;
  orgId?: number;
}

class SopCardContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      showSopDeleteModal: false,
      sopStagedForDelete: -1,
      sopId: 'new',
      sopType: 'policy',
    };
  }

  componentDidMount() {
    this.props.load();
  }

  componentWillReceiveProps(props) {
    this.setState({
      sopId: props.sopId,
      sopType: props.sopType,
    });
  }

  render() {
    const { site, sops, getSopsIsLoading } = this.props;
    const { showSopDeleteModal, sopId } = this.state;

    return (
      <>
        <div className={styles.listTitleContainer}>
          <h3>{!getSopsIsLoading && checkCommonPlural('SOP', sops.length)}</h3>
        </div>

        <CardList
          header={this.renderSopCardListHeader()}
          data={this.renderSopCardListRow()}
          noLeftIconColumn
        />
        <NewOrEditSopModal
          resetSop={this.handleResetEditSop}
          sopId={String(sopId)}
          site={this.props.site}
          setOrgUnitId={Number(site.id)}
          sopTypeCategory={this.state.sopType}
        />
        {showSopDeleteModal ? this.renderSopDeleteModal() : ''}
      </>
    );
  }

  renderSopCardListHeader = (): CardListHeader[] => {
    const { site, activeTabTitle } = this.props;

    return [
      {
        width: '4',
        content: 'Title/Description',
        comparator: (a: Sop, b: Sop, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '2',
        content: activeTabTitle,
        comparator: (sop1: Sop, sop2: Sop, sortDirection: SortDirection) => {
          const getComponentType = (s: Sop) => {
            return s;
          };

          return genericTableSort(
            sop1.components[0].type,
            sop2.components[0].type,
            sortDirection,
            SORT_IGNORED_VALUES,
            getComponentType
          );
        },
      },
      {
        width: '2',
        content: 'Value',
      },
      {
        width: '1',
        content: 'Unit',
      },
      {
        width: '2',
        content: 'Attached To',
        comparator: (sop1: Sop, sop2: Sop, sortDirection: SortDirection) => {
          const getAttachment = (sop: Sop) => {
            if (sop.equipmentTypes !== undefined) {
              if (sop.equipmentTypes.length > 0) {
                return this.getEquipmentTypesToDisplay(sop)
                  .sort((a, b) => (a.title > b.title ? 1 : -1))
                  .map(eqType => eqType.title)
                  .join();
              } else {
                if (sop.organizationUnitId == site.id) {
                  return site.title;
                }

                return '';
              }
            } else {
              return '';
            }
          };

          return genericTableSort(
            sop1,
            sop2,
            sortDirection,
            SORT_IGNORED_VALUES,
            getAttachment
          );
        },
      },
      {
        width: '1',
        content: 'SOP Level',
        comparator: (a: Sop, b: Sop, sortDirection: SortDirection) => {
          const determineOrgOrSiteLevel = (sop: Sop) => {
            const isOrgLevel = !this.determineIfSopIsSiteLevel(sop);
            return isOrgLevel ? 'Organization' : 'Site';
          };
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            determineOrgOrSiteLevel
          );
        },
      },
      {
        width: '1',
        align: 'right',
        content: '',
      },
    ];
  };

  renderSopCardListRow = (): CardListRowData[] => {
    const { sops, timeTablesById, site } = this.props;

    return sops.map(sop => ({
      key: sop.id.toString(),
      dataObject: sop,
      content: this.renderSopCardListRowContent(
        sop,
        Number(site.id),
        timeTablesById
      ),
      isDisabled: this.isInactiveSop(sop),
    }));
  };

  renderSopCardListRowContent = (
    sop: Sop,
    siteId: number,
    timeTablesById?: TimeTablesById
  ) => {
    return (
      <>
        <Cell width="4">{this.renderNameAndDescription(sop)}</Cell>
        <Cell width="2">{this.renderComponentsType(sop)}</Cell>
        <Cell width="2">{this.renderSopValue(sop, timeTablesById)}</Cell>
        <Cell width="1">{this.renderSopUnit(sop, siteId)}</Cell>
        <Cell width="2">{this.renderAttachedTo(sop)}</Cell>
        <Cell width="1">{this.renderSopLevel(sop)}</Cell>
        <Cell width="1" cellAlign="right" className={styles.dropdownContainer}>
          {this.renderSopMenuDropDown(sop)}
        </Cell>
      </>
    );
  };

  renderNameAndDescription = (sop: Sop) => {
    return (
      <>
        <div className={styles.title}>{sop.title}</div>
        {sop.description && (
          <div className={styles.description}>{sop.description}</div>
        )}
      </>
    );
  };

  renderComponentsType = (sop: Sop) => {
    const component = sop.components[0];
    if (!component) return global.NOT_AVAILABLE;

    return (
      <>
        <div>{SopTypeToLabel[component.type] || global.NOT_AVAILABLE}</div>
      </>
    );
  };

  renderSopValue = (sop: Sop, timeTablesById?: TimeTablesById) => {
    const component = sop.components[0];
    if (!component) return global.NOT_AVAILABLE;
    const timeTable =
      (component.type === SopTypes.SITE_OPERATING_HOURS ||
        component.type === SopTypes.EQUIPMENT_OPERATING_HOURS) &&
      timeTablesById &&
      sop.timetableId
        ? timeTablesById[sop.timetableId]
        : undefined;

    return <SopValue component={sop.components[0]} timeTable={timeTable} />;
  };

  renderSopUnit = (sop: Sop, siteId: number) => {
    const component = sop.components[0];
    if (!component) return global.NOT_AVAILABLE;

    return <SopUnit component={sop.components[0]} siteId={siteId} />;
  };

  renderAttachedTo = (sop: Sop) => {
    const component = sop.components[0];
    if (!component) return global.NOT_AVAILABLE;

    const { type } = component;

    if (!sopComponentTypeAppliesToEquipment(type)) {
      if (this.props.site !== undefined) {
        return <>{this.props.site.title}</>;
      } else {
        return global.NOT_AVAILABLE;
      }
    }

    const equipmentTypesToDisplay = this.getEquipmentTypesToDisplay(sop);
    if (equipmentTypesToDisplay.length > 0) {
      return (
        <>
          {equipmentTypesToDisplay.map(eqType => {
            return <div key={`${eqType.id}-title`}>{eqType.title}</div>;
          })}
        </>
      );
    }

    return global.NOT_AVAILABLE;
  };

  renderSopLevel = (sop: Sop) => {
    let sopLevel;

    if (this.determineIfSopIsSiteLevel(sop)) {
      sopLevel = 'Site';
    } else {
      sopLevel = 'Organization';
    }

    return <label>{sopLevel}</label>;
  };

  renderSopMenuDropDown = (sop: Sop) => {
    if (this.determineIfSopIsSiteLevel(sop)) {
      return (
        <MenuDropdown>
          <MenuDropdownItem
            onSelect={() => {
              this.handleEditSop(sop.id);
            }}
          >
            Edit
          </MenuDropdownItem>
          <MenuDropdownItem
            isRed
            onSelect={() => {
              this.handleDeleteSop(sop.id);
            }}
          >
            Delete
          </MenuDropdownItem>
        </MenuDropdown>
      );
    } else {
      // organization level
      return (
        <MenuDropdown>
          <MenuDropdownItem
            onSelect={() => {
              this.handleCustomizeSop(sop);
            }}
          >
            Customize
          </MenuDropdownItem>
        </MenuDropdown>
      );
    }
  };

  renderSopDeleteModal = () => {
    const { deleteSop } = this.props;
    const { sopStagedForDelete } = this.state;
    const actions = (
      <>
        <Button variant="text" onClick={this.handleCloseSopDeletePrompt}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            deleteSop(sopStagedForDelete);
            this.handleCloseSopDeletePrompt();
          }}
        >
          Delete
        </Button>
      </>
    );

    return (
      <Modal onClose={this.handleCloseSopDeletePrompt} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete this SOP?
        </p>
      </Modal>
    );
  };

  getEquipmentTypesToDisplay = (sop: Sop) => {
    /*
     * 2 cases when an org level SOP is overridden by a site level SOP:
     *   1) partially overridden -> hide inactive equipment types
     *   2) totally overridden -> grey out, but show all equipment types
     */
    return this.isInactiveSop(sop)
      ? sop.equipmentTypes.filter(this.filterEquipmentTypeIdsBySite)
      : this.getActiveEquipmentTypes(sop).filter(
          this.filterEquipmentTypeIdsBySite
        );
  };

  filterEquipmentTypeIdsBySite = eqType =>
    (this.props.siteEquipmentTypeIds || []).indexOf(eqType.id) > -1;

  getActiveEquipmentTypes = (sop: Sop) => {
    const { sops, siteEquipmentTypeIds = [] } = this.props;
    let localisedEquipmentTypeIds: number[] = [];
    if (!this.determineIfSopIsSiteLevel(sop)) {
      /*
       * Organization level SOP can be (totally / partially) overridden by its site level counterpart.
       * When an equipment type is defined in a site level SOP of the same SOP type,
       * that equipment type in the org level counterpart would be considered as inactive.
       */
      sops.forEach(sopItem => {
        if (
          this.determineIfSopIsSiteLevel(sopItem) &&
          sopItem.components[0]?.type === sop.components[0]?.type
        ) {
          localisedEquipmentTypeIds = localisedEquipmentTypeIds.concat(
            sopItem.equipmentTypeIds
          );
        }
      });
    }
    return sop.equipmentTypes.filter(
      eqType => localisedEquipmentTypeIds.indexOf(eqType.id) === -1
    );
  };

  isInactiveSop = (sop: Sop) => {
    if (!this.determineIfSopIsSiteLevel(sop)) {
      // org level SOP
      const isEquipmentSpecific = sopComponentTypeAppliesToEquipment(
        sop.components[0]?.type
      );
      if (isEquipmentSpecific && !this.getActiveEquipmentTypes(sop).length) {
        return true;
      }
      if (
        !isEquipmentSpecific &&
        this.props.sops.find(
          sopItem =>
            this.determineIfSopIsSiteLevel(sopItem) &&
            sopItem.components[0]?.type === sop.components[0]?.type
        )
      ) {
        return true;
      }
    }
    return false;
  };

  handleEditSop = (id: number) => {
    this.props.onEditSop(id);
  };

  handleResetEditSop = () => {
    const { resetSop } = this.props;
    const { sopId } = this.state;
    resetSop(sopId);
  };

  handleCustomizeSop = (sop: Sop) => {
    const { customizeOrganizationSop, sopType, onEditSop } = this.props;
    onEditSop('new');
    customizeOrganizationSop(sop, sopType);
  };

  handleCloseSopDeletePrompt = () => {
    this.setState({
      sopStagedForDelete: -1,
      showSopDeleteModal: false,
    });
  };

  handleDeleteSop = (id: number) => {
    this.setState({
      sopStagedForDelete: id,
      showSopDeleteModal: true,
    });
  };

  determineIfSopIsSiteLevel = (sop: Sop) => {
    return (
      this.props.site !== undefined &&
      this.props.site.id === sop.organizationUnitId
    );
  };
}

const mapStateToProps = ({ sops }: ApplicationState, { site }: Props) => ({
  site: site,
  sopType: sops.activeSopTab,
  getSopsIsLoading: sops.isLoading[Actions.GET_SOP_LOADING],
});

const mapDispatchToProps = (dispatch: any, { site, sops }: Props) => ({
  deleteSop: (id: string | number) => dispatch(deleteSop(id)),
  resetSop: (id: string | number) => dispatch(resetSop(id)),
  customizeOrganizationSop: (sop: Sop, sopType: SopTypeCategory) =>
    dispatch(customizeOrganizationSop(sop, sopType)),
  load: () => dispatch(getSops()),
  getSop: id => dispatch(getSop(id)),
  sops: sops,
  site: site,
});

export default connect(mapStateToProps, mapDispatchToProps)(SopCardContainer);
