import {
  Button,
  Loader,
  Modal,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';

type Props = {
  onDelete: () => void;
  apiError?: any;
  onClose: () => void;
  isDeleting?: boolean;
  title: string;
};

const DeleteModal = ({
  onDelete,
  onClose,
  apiError,
  isDeleting,
  title,
}: Props) => {
  const actions = (
    <>
      <Button variant="text" onClick={onClose} disabled={isDeleting}>
        Cancel
      </Button>
      <Button onClick={onDelete} disabled={isDeleting}>
        {isDeleting ? <Loader size={16} variant="secondary" /> : 'Delete'}
      </Button>
    </>
  );

  return (
    <Modal onClose={isDeleting ? undefined : onClose} actions={actions}>
      <p style={{ textAlign: 'center' }}>
        Are you sure you want to delete{' '}
        <span style={{ fontWeight: 'bold' }}>{title}</span>?
      </p>
      {apiError ? apiError : ''}
    </Modal>
  );
};

export default DeleteModal;
