export type Modal = {
  isOpen: boolean;
  title?: string;
  description?: string;
  actionFn?: () => void;
  targetId?: number;
  type?: string;
};

export type OpenModal = {
  title?: string;
  description?: string;
  actionFn?: () => void;
  type?: string;
};

export enum ModalTypes {
  DEFAULT = 'DEFAULT',
  CONFIRMATION = 'CONFIRMATION',
}
