import { Loader } from '@energybox/react-ui-library/dist/components';
import { NetworkGroup, Sop } from '@energybox/react-ui-library/dist/types';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  edgeConfigStatusUpdate,
  produceEdgeConfig,
} from '../../actions/edge_devices';
import { ApplicationState } from '../../reducers';
import LastConfigUpdateTooltip, {
  useGetNetworkGroupArray,
} from '../LastNetworkGroupConfigUpdate/LastConfigUpdateTooltip';
import styles from './HvacSopTable.module.css';

import { Upload } from '@energybox/react-ui-library/dist/icons';
import { DateTime } from 'luxon';

export const TEN_MINUTES_IN_MILLISECONDS = 600000;

type Props = {
  hvacSops?: Sop[];
  orgId?: number;
  siteId: number;
  daResultsExist?: boolean;
  networkGroups?: NetworkGroup[];
};

const UpdateNetworkGroupsButton: React.FC<Props> = ({
  hvacSops,
  orgId,
  siteId,
  daResultsExist,
  networkGroups,
}) => {
  const dispatch = useDispatch();
  const [getUpdate, setGetUpdate] = useState<boolean>(false);

  let updateConfig = false;
  let canUpdateConfig = false;

  const networkGroupArrayFromSop = useGetNetworkGroupArray(hvacSops!, orgId);
  const networkGroupArray = networkGroups?.length
    ? networkGroups
    : networkGroupArrayFromSop;

  let isUpdateSent: boolean[] = [];
  let areAllNetworkGrupsOffline: boolean[] = [];
  const edgeConfigUpdate = useSelector(({ edgeDevices }: ApplicationState) => {
    return edgeDevices.edgeConfigUpdateStatusByEdgeId;
  });
  networkGroupArray?.forEach(networkGroup => {
    if (networkGroup?.edge?.onlineStatus?.onlineState) {
      areAllNetworkGrupsOffline.push(true);
    } else {
      areAllNetworkGrupsOffline.push(false);
    }
    if (edgeConfigUpdate[networkGroup?.edge?.id!]) {
      const { lastRunningHashTimeStamp, lastSentHashTimeStamp } =
        edgeConfigUpdate[networkGroup?.edge?.id!] || {};

      const isEdgeConfigUpdating =
        DateTime.fromISO(lastSentHashTimeStamp as string).toMillis() >
        DateTime.fromISO(lastRunningHashTimeStamp as string).toMillis();

      isUpdateSent.push(
        isEdgeConfigUpdating
          ? DateTime.now().toMillis() -
              DateTime.fromISO(lastSentHashTimeStamp as string).toMillis() <
              TEN_MINUTES_IN_MILLISECONDS
          : false
      );
    }
  });
  isUpdateSent.forEach(val => {
    if (val) {
      updateConfig = true;
    }
  });
  areAllNetworkGrupsOffline.forEach(val => {
    if (val) {
      canUpdateConfig = true;
    }
  });

  const updateSopNetworkGroups = (networkGroupArray: NetworkGroup[]) => {
    networkGroupArray?.forEach(networkGroup => {
      if (networkGroup.edge?.onlineStatus?.onlineState) {
        dispatch(
          produceEdgeConfig(
            networkGroup.id,
            networkGroup.edge?.uuid,
            networkGroup.edge?.id
          )
        );
      }
    });
  };

  const getEdgeData = () => {
    setGetUpdate(true);
    if (networkGroupArray?.length) {
      networkGroupArray.forEach(networkGroup => {
        if (networkGroup?.edge?.id) {
          dispatch(edgeConfigStatusUpdate(networkGroup.edge.id));
        }
      });
    }
  };

  return (
    <>
      {updateConfig && (
        <span className={styles.loader}>
          <Loader size={14} />
        </span>
      )}
      <div className={styles.lastConfigText} onClick={getEdgeData}>
        <LastConfigUpdateTooltip
          siteId={siteId}
          hvacSops={hvacSops!}
          isOrglevelUpdate={false}
          networkGroups={networkGroups!}
          getUpdate={getUpdate}
        />
      </div>
      <Button
        variant="text"
        className={
          !networkGroupArray.length || updateConfig || daResultsExist
            ? styles.disabled
            : styles.configIcon
        }
        onClick={() => updateSopNetworkGroups(networkGroupArray)}
        disabled={
          !networkGroupArray.length || updateConfig || daResultsExist
            ? true
            : false
        }
      >
        <Upload class={styles.uploadIcon} width="20" height="25" />
      </Button>
    </>
  );
};

export default UpdateNetworkGroupsButton;
