import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentOrganization } from '../actions/organizations';
import { ApplicationState } from '../reducers';
import { Organization } from '@energybox/react-ui-library/dist/types';

export const useGetCurrentOrganization = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentOrganization());
  }, [dispatch]);

  const currentOrganization = useSelector<
    ApplicationState,
    Organization | undefined
  >(({ organizations }) => {
    return organizations?.currentOrganization;
  });

  return currentOrganization;
};

export const useCurrentOrganizationId = () => {
  const orgId = useSelector(
    ({ app }: ApplicationState) => app?.currentOrganizationId
  );

  return orgId;
};
