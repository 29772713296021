import { Card, Columns } from '@energybox/react-ui-library/dist/components';
import { Space } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions,
  getSpace,
  getSpaces,
  primeForDelete,
  showDeleteModal,
  showEditModal,
  showNewModal,
} from '../../actions/spaces';
import { ApplicationState } from '../../reducers';
import { checkCommonPlural } from '../../util';
import DeleteSpaceModal from '../Space/DeleteSpaceModal';
import EditSpaceModal from '../Space/EditSpaceModal';
import NewSpaceModal from '../Space/NewSpaceModal';
import SpaceCardContainer from '../Space/SpaceCardContainer';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  spaces: Space[];
  primeForDelete: (spaceId: number) => void;
  getSpace: typeof getSpace;
  showNewModal: typeof showNewModal;
  showEditModal: typeof showEditModal;
  showDeleteModal: typeof showDeleteModal;
  getSpacesIsLoading?: boolean;
}

class ShowSitePageSpaces extends React.Component<Props> {
  componentDidMount() {
    this.props.load();
  }

  render() {
    const { id, spaces, getSpacesIsLoading } = this.props;

    return (
      <>
        <NewSpaceModal currentSiteId={id} />
        <EditSpaceModal currentSiteId={id} />
        <DeleteSpaceModal />
        {!getSpacesIsLoading && (
          <>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 200px' }}>
              <h3>
                {!getSpacesIsLoading &&
                  checkCommonPlural('Space', spaces.length)}
              </h3>
            </div>
            <div style={{ fontSize: '0.75rem', paddingLeft: '3.5rem' }}>
              <Columns template=".195fr .26fr .17fr .16fr .215fr">
                <span>Space Name</span>
                <span>Sub Spaces</span>
                <span>Equipment</span>
                <span>Sensors</span>
                <span>Gateways</span>
              </Columns>
            </div>

            {spaces.map((space: Space) => (
              <div style={{ marginTop: '1rem' }}>
                <Card key={space.id}>
                  <SpaceCardContainer
                    id={space.id.toString()}
                    paddingLeft={1}
                  />
                </Card>
              </div>
            ))}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ spaces }: ApplicationState, { id }: OwnProps) => ({
  spaces: (spaces.spaceIdsByParentId[id] || []).map(
    id => spaces.spacesById[id]
  ),
  getSpacesIsLoading: spaces.loadingStatusByAction[Actions.GET_SPACES_LOADING],
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => dispatch(getSpaces({ siteIds: [id] })),
  getSpace: id => dispatch(getSpace(id)),
  showNewModal: () => dispatch(showNewModal(id)),
  showEditModal: (spaceId: string) => dispatch(showEditModal(spaceId)),
  primeForDelete: spaceId => dispatch(primeForDelete(spaceId)),
  showDeleteModal: () => dispatch(showDeleteModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowSitePageSpaces);
