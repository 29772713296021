import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React, { Children } from 'react';
import styles from './FiltersContainer.module.css';

interface Props {
  children: React.ReactNode;
  growFirst?: boolean;
  className?: string;
}

const FiltersContainer: React.FC<Props> = ({
  children,
  growFirst,
  className,
}) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;

  const childrenArray = Children.toArray(children);

  return (
    <div
      className={classNames(
        styles.root,
        isMobile ? styles.mobileContainer : null,
        className
      )}
    >
      {Children.map(childrenArray, (child, idx) => {
        return (
          <div
            className={classNames(
              styles.filters,
              growFirst && idx === 0 ? styles.grow : null,
              isMobile && idx == 0 && growFirst
                ? styles.mobileFilterGroup
                : null
            )}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default FiltersContainer;
