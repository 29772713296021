import { setSitesFilter } from './sites';
import { Site, SitesApiFilter } from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/apiUtils';

export const getSitesByTargets = (targetIds: number[] = []): Promise<Site[]> =>
  fetchApi({
    endpoint: `/api/v1/sites/by-targets?targetIds=${targetIds.join(',')}`,
  });

export const fetchSites = (filter?: SitesApiFilter): Promise<Site[]> =>
  fetchApi({ endpoint: setSitesFilter(filter) });
