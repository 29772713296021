import { Tooltip } from '@energybox/react-ui-library/dist/components';
import {
  HvacControlLabel,
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import styles from './EditControlsHvacForm.module.css';

type Props = {
  temperatureUnit: TemperatureUnit;
  hasError?: boolean;
  arrowDirection?:
    | 'right'
    | 'top'
    | 'topRight'
    | 'left'
    | 'bottom'
    | 'bottomRight'
    | 'closeBottom';
};

const EditControlsHvacSetPointTooltip: React.FC<Props> = ({
  temperatureUnit,
  hasError,
  arrowDirection,
}) => {
  const isImperial = temperatureUnit === TemperatureUnit.F;
  const MIN_SETPOINT = isImperial ? 40 : 4;
  const MAX_SETPOINT = isImperial ? 95 : 35;
  const SETPOINT_DELTA = isImperial ? 2 : 1;

  return (
    <Tooltip
      underline={false}
      disableAutoAdjust={isDefined(arrowDirection) ? true : false}
      arrowDirection={arrowDirection || 'right'}
      extraClassNames={[styles.tooltip]}
      content={
        <div className={styles.tooltipText}>
          <div className={styles.tooltipLineEntry}>
            {HvacControlLabel.MIN_TEMP} must be &#8805; {MIN_SETPOINT}{' '}
            {temperatureUnit}
          </div>
          <div className={styles.tooltipLineEntry}>
            {HvacControlLabel.MAX_TEMP} must be &#8804; {MAX_SETPOINT}{' '}
            {temperatureUnit}
          </div>
          <div className={styles.tooltipLineEntry}>
            Difference between {HvacControlLabel.MIN_TEMP} and{' '}
            {HvacControlLabel.MAX_TEMP} must be &#8805; {SETPOINT_DELTA}
            {temperatureUnit}
          </div>
          <div className={styles.tooltipLineEntry}>
            Heat To must be less than Cool To
          </div>
          <div className={styles.tooltipLineEntry}>
            Must be whole numbers (no decimal points)
          </div>
        </div>
      }
    >
      <IoIosInformationCircleOutline
        color={hasError ? 'var(--pink-base)' : 'black'}
        size={20}
      />
    </Tooltip>
  );
};

export default EditControlsHvacSetPointTooltip;
