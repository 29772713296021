import {
  Sentinel,
  SentinelApiFilter,
} from '@energybox/react-ui-library/dist/types';
import pathOr from 'ramda/src/pathOr';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSentinelsByResourceId } from '../actions/sentinels';
import { ApplicationState } from '../reducers';

export const useGetSentinelsByResourceId = (
  resourceId: string | number,
  queryParams: SentinelApiFilter
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSentinelsByResourceId(resourceId, queryParams));
  }, [resourceId, queryParams]);

  const sentinels = useSelector<ApplicationState, Sentinel[] | undefined>(
    ({ sentinels }) => {
      return pathOr(undefined, ['byResourceId', resourceId], sentinels);
    }
  );

  return sentinels;
};
