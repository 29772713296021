import { Tab, Tabs } from '@energybox/react-ui-library/dist/components';
import {
  ControlsType,
  TemperatureControl,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';

import React, { useEffect, useState } from 'react';
import EditControlsTemperatureFormBasicContent from './EditControlsTemperatureFormBasicContent';
import EditControlsTemperatureFormScheduleContent from './EditControlsTemperatureFormScheduleContent';
import styles from './EditControlsTemperatureForm.module.css';

enum TabNames {
  BASICS = 'Basics',
  SCHEDULE = 'Schedule',
}

type Props = {
  siteId: number;
  spaceId?: number;
  equipmentId: number;
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  fields: TemperatureControl;
  temperatureType?: ControlsType.COOLING | ControlsType.HEATING;
  numberOfTimesClickedOnSave: number;
};

const EditControlsTemperatureForm: React.FC<Props> = ({
  siteId,
  spaceId,
  equipmentId,
  onChange,
  formErrors,
  formErrorsVisible,
  fields,
  temperatureType,
  numberOfTimesClickedOnSave,
}) => {
  const [activeTab, setActiveTab] = useState(TabNames.BASICS);

  useEffect(() => {
    if (temperatureType !== undefined) {
      onChange('type', temperatureType.toUpperCase());
    }
  }, [temperatureType, onChange]);

  useEffect(() => {
    const hasErrorInBasicsTab =
      formErrors.hasOwnProperty('sensorId') ||
      formErrors.hasOwnProperty('setPoint') ||
      formErrors.hasOwnProperty('hysteresis');

    const hasErrorInScheduleTab =
      formErrors.hasOwnProperty('beginDelta') ||
      formErrors.hasOwnProperty('endDelta');

    if (formErrorsVisible && hasErrorInBasicsTab) {
      setActiveTab(TabNames.BASICS);
    } else if (
      formErrorsVisible &&
      !hasErrorInBasicsTab &&
      hasErrorInScheduleTab
    ) {
      setActiveTab(TabNames.SCHEDULE);
    }
  }, [formErrorsVisible, numberOfTimesClickedOnSave]);

  return (
    <>
      <Tabs>
        <Tab
          active={activeTab === TabNames.BASICS}
          onClick={() => {
            setActiveTab(TabNames.BASICS);
          }}
        >
          {TabNames.BASICS}
        </Tab>

        <Tab
          active={activeTab === TabNames.SCHEDULE}
          onClick={() => {
            setActiveTab(TabNames.SCHEDULE);
          }}
        >
          {TabNames.SCHEDULE}
        </Tab>
      </Tabs>

      <div className={styles.tabContent}>
        {activeTab === TabNames.BASICS && (
          <EditControlsTemperatureFormBasicContent
            equipmentId={equipmentId}
            spaceId={spaceId}
            siteId={siteId}
            onChange={onChange}
            fields={fields}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
          />
        )}

        {activeTab === TabNames.SCHEDULE && (
          <EditControlsTemperatureFormScheduleContent
            siteId={siteId}
            onChange={onChange}
            fields={fields}
            formErrors={formErrors}
            formErrorsVisible={formErrorsVisible}
          />
        )}
      </div>
    </>
  );
};

export default EditControlsTemperatureForm;
