import { InputField } from '@energybox/react-ui-library/dist/components';
import {
  classNames,
  theme as globalTheme,
} from '@energybox/react-ui-library/dist/utils';
import { createTheme } from '@material-ui/core/styles';
import { KeyboardTimePicker } from '@material-ui/pickers/TimePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ThemeProvider } from '@material-ui/styles';
import { format, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleTimePicker } from '../../../actions/time_tables';
import { useIs12HrTimeFormat } from '../../../hooks/useAppDetails';
import IconClock from '../../ui/icons/IconClock';
import styles from './TimeTableTimeInput.module.css';

const localTheme = createTheme({
  ...globalTheme,
  overrides: {
    MuiInputBase: {
      root: {
        borderBottom: 0,
      },
      input: {
        fontSize: '0.75rem',
        width: '100%',
        maxWidth: '10rem',
        padding: '0.3125rem 0',
      },
    },
    MuiInput: {
      root: {
        padding: '3px 0',
      },
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid var(--accent-base)',
        },
        '&&&&:focus-within:before': {
          borderBottom: '1px solid var(--accent-base)',
        },
        '&::before': {
          borderBottom: '1px solid var(--base25)',
        },
        '&::after': {
          borderBottom: '1px solid var(--base25)',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
          opacity: 0.5,
        },
      },
    },
  },
  zIndex: {
    modal: 10000,
  },
});

interface Props {
  className?: string;
  name: string;
  value: string | number;
  type: string;
  id: string;
  onChange: (fieldName: string, value: string) => void;
  error?: boolean;
  customErrorText?: string;
}

const TimeTableTimeInput = ({
  className,
  id,
  name,
  value,
  type,
  onChange,
  error,
  customErrorText,
}: Props) => {
  const dispatch = useDispatch();
  const is12HrTimeFormat = useIs12HrTimeFormat();
  const [pickerTime, setPickerTime] = useState<MaterialUiPickersDate>(null);

  useEffect(() => {
    if (type === 'CLOCK' && typeof value === 'string') {
      if (value !== '') {
        if (value.length === 5) {
          setPickerTime(parse(value, 'HH:mm', new Date()));
        } else {
          setPickerTime(parse(value, 'HH:mm:ss', new Date()));
        }
      }
    }
  }, [type, value]);

  function handleInputChange(e) {
    onChange(id, e.target.value);
  }

  function handleDateChange(date: MaterialUiPickersDate) {
    if (date === null || date?.toString() === 'Invalid Date') {
      setPickerTime(null);
      onChange(id, '');
      return;
    }
    setPickerTime(date);
    const formattedDate = format(date, 'HH:mm');
    onChange(id, formattedDate);
  }

  const handleTimePickerOpen = () => {
    dispatch(toggleTimePicker(true));
  };

  const handleTimePickerClose = () => {
    dispatch(toggleTimePicker(false));
  };

  if (type === 'CLOCK') {
    return (
      <div className={classNames(className, styles.container)}>
        <div className={styles.customizedTimeInput}>
          <ThemeProvider theme={localTheme}>
            <KeyboardTimePicker
              value={pickerTime}
              onChange={handleDateChange}
              onOpen={handleTimePickerOpen}
              onClose={handleTimePickerClose}
              placeholder={is12HrTimeFormat ? 'HH:mm AM' : 'HH:mm'}
              name={name}
              ampm={is12HrTimeFormat}
              // fullWidth={true}
              InputProps={{
                className: classNames(
                  styles.input,
                  error ? styles.hasError : ''
                ),
              }}
              keyboardIcon={
                <IconClock size={16} className={styles.clockIcon} />
              }
            />
          </ThemeProvider>
          {error && (
            <div className={styles.invalidText}>
              {customErrorText ? customErrorText : `Invalid ${name}`}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames(
          className,
          styles.container,
          styles.timeContainer
        )}
      >
        <div className={styles.timeInput}>
          <InputField
            onChange={handleInputChange}
            name={name}
            type="number"
            value={value}
            error={error}
            customErrorText={customErrorText}
          />
        </div>
        <span>Min</span>
      </div>
      <div></div>
    </>
  );
};

export default TimeTableTimeInput;
