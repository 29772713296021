import {
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import { Region } from '@energybox/react-ui-library/dist/types/Region';

import React from 'react';

interface Props {
  onSelect: (region: Region) => void;
  value?: Region | null;
  error: boolean;
  customErrorText?: string;
}

class RegionSelect extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  render() {
    const { onSelect, value, error, customErrorText } = this.props;

    const { Americas, EMEA, APAC } = Region;

    return (
      <Select
        title={value}
        value={value}
        variant={'select'}
        error={error}
        customErrorText={customErrorText}
      >
        {[Americas, EMEA, APAC].map((region: Region) => (
          <SelectItem
            key={region}
            isSelected={region === value}
            onSelect={() => {
              onSelect(region);
            }}
          >
            {region}
          </SelectItem>
        ))}
      </Select>
    );
  }
}

export default RegionSelect;
