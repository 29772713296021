import {
  CurrentUser,
  Locale,
  MapTimeFormat,
  MeasurementSystem,
  TimeFormat,
  UserRole,
} from '@energybox/react-ui-library/dist/types';
import { AreaUnitApiValue, TemperatureUnitApiValue } from '@energybox/react-ui-library/dist/types/Global';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

export const useMeasurementSystem = () => {
  return useSelector<ApplicationState, MeasurementSystem>(
    ({ app }) => app.locale.measurementSystem
  );
};

export const useUserSettingTemperatureUnit = () => {
  const user = useCurrentUser()

  if (!user) return null;
  const { measurementSystem, temperature } = user;

  if (!temperature) {
    switch (measurementSystem) {
      case MeasurementSystem.METRIC:
        return TemperatureUnitApiValue.CELSIUS;
      case MeasurementSystem.IMPERIAL:
        return TemperatureUnitApiValue.FAHRENHEIT;
    }
  }
  return temperature;
}

export const useUserSettingAreaUnit = () => {
  const user = useCurrentUser()

  if (!user) return null;
  const { measurementSystem, area } = user;

  if (!area) {
    switch (measurementSystem) {
      case MeasurementSystem.METRIC:
        return AreaUnitApiValue.M2;
      case MeasurementSystem.IMPERIAL:
        return AreaUnitApiValue.FT2;
    }
  }
  return area;
}

export const useCurrentUser = () => {
  return useSelector<ApplicationState, CurrentUser | undefined>(({ app }) => {
    return app.currentUser;
  });
}

export const useUserRole = () => {
  return useSelector<ApplicationState, UserRole | undefined>(({ app }) => {
    return (app.currentUser || {}).role;
  });
};

export function useAppLocale() {
  const locale: Locale = useSelector<ApplicationState, Locale>(
    ({ app }) => app.locale
  );
  return locale;
}

export const useIs24HrTimeFormat = () => {
  const timeFormat = useSelector<ApplicationState, string>(({ app }) => {
    return app.locale.timeFormat;
  });

  return timeFormat === MapTimeFormat[TimeFormat.HOUR_24];
};

export const useIs12HrTimeFormat = () => {
  const timeFormat = useSelector<ApplicationState, string>(({ app }) => {
    return app.locale.timeFormat;
  });

  return timeFormat === MapTimeFormat[TimeFormat.HOUR_12];
};
