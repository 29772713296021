import {
  Sentinel,
  SentinelParameters,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/sentinels';
import { replaceNullValues } from '../util';

export type Sentinels = {
  isLoading: boolean;
  byId: {
    [id: string]: Sentinel;
  };
  byResourceId: SentinelsByResourceId;
  isLoadingByResourceId: IsLoadingByResourceId;
  pendingMuteTargetsUpdates: number;
};

export type SentinelsByResourceId = {
  [resourceId: string]: Sentinel[];
};

export type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

const initialState = {
  isLoading: false,
  byId: {},
  byResourceId: {},
  isLoadingByResourceId: {},
  pendingMuteTargetsUpdates: 0,
};

const sentinels = (state = initialState as Sentinels, action: any) => {
  switch (action.type) {
    case Actions.CLEAR_SENTINELS_STORE:
      return {
        ...state,
        byId: {},
      };

    case Actions.GET_SENTINELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        byId: {
          ...action.data.reduce(
            (obj, s: Sentinel) => ({ ...obj, [s.id]: replaceNullValues(s) }),
            {}
          ),
        },
      };

    case Actions.UPDATE_SENTINEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        byId: {
          ...state.byId,
        },
      };

    case Actions.MUTE_SENTINEL_LOADING:
    case Actions.RESUME_SENTINEL_LOADING:
      return {
        ...state,
        pendingMuteTargetsUpdates: state.pendingMuteTargetsUpdates + 1,
        isLoading: true,
      };

    case Actions.GET_SENTINELS_BY_EQUIPMENT_MAP_LOADING:
    case Actions.GET_SENTINELS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case Actions.MUTE_SENTINEL_ERROR:
    case Actions.RESUME_SENTINEL_ERROR:
      return {
        ...state,
        pendingMuteTargetsUpdates: state.pendingMuteTargetsUpdates - 1,
        isLoading: false,
      };

    case Actions.GET_SENTINELS_BY_EQUIPMENT_MAP_ERROR:
    case Actions.GET_SENTINELS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case Actions.MUTE_SENTINEL_SUCCESS: {
      const updatedSentinel = action.data;
      return {
        ...state,
        pendingMuteTargetsUpdates: state.pendingMuteTargetsUpdates - 1,
        isLoading: false,
        byId: {
          ...state.byId,
          [action.id]: {
            ...updatedSentinel,
          },
        },
      };
    }

    case Actions.RESUME_SENTINEL_SUCCESS: {
      const resumeTargets: number[] = action.targetIds || [];
      const currentSentinel = state.byId[action.id];
      if (currentSentinel === undefined)
        return {
          ...state,
          isLoading: false,
          pendingMuteTargetsUpdates: state.pendingMuteTargetsUpdates - 1,
        };
      const { mutedTargets: currentMutedTargets = [] } = currentSentinel;

      const updatedSentinel: Sentinel = {
        ...state.byId[action.id],
        muted: false,
        mutedTargets:
          resumeTargets.length > 0
            ? currentMutedTargets.filter(
                target => !resumeTargets.includes(target.targetId)
              )
            : currentMutedTargets,
      };
      return {
        ...state,
        isLoading: false,
        pendingMuteTargetsUpdates: state.pendingMuteTargetsUpdates - 1,
        byId: {
          ...state.byId,
          [action.id]: updatedSentinel,
        },
      };
    }

    case Actions.GET_SENTINEL_SUCCESS:
      const nullNormalizedData: Sentinel = replaceNullValues(action.data);
      const nullNormalizedParameters: SentinelParameters = replaceNullValues(
        action.data.parameters
      );
      nullNormalizedData.parameters = nullNormalizedParameters;
      return {
        ...state,
        isLoading: false,
        byId: {
          ...state.byId,
          [action.data.id]: nullNormalizedData,
        },
      };

    case Actions.DELETE_SENTINEL_SUCCESS: {
      return R.dissocPath(['byId', action.id], state);
    }

    case Actions.GET_SENTINELS_BY_RESOURCE_ID_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['byResourceId', action.resourceId],
          Object.values(replaceNullValues(action.data))
        ),
        R.assocPath(['isLoadingByResourceId', action.resourceId], false)
      )(state);
    }

    case Actions.GET_SENTINELS_BY_RESOURCE_ID_LOADING: {
      return R.assocPath(
        ['isLoadingByResourceId', action.resourceId],
        true,
        state
      );
    }

    case Actions.GET_SENTINELS_BY_RESOURCE_ID_ERROR: {
      return R.assocPath(
        ['isLoadingByResourceId', action.resourceId],
        false,
        state
      );
    }

    case Actions.GET_SENTINELS_BY_EQUIPMENT_MAP_SUCCESS: {
      return R.pipe(
        R.assocPath(['byResourceId'], replaceNullValues(action.data)),
        R.assocPath(['isLoading'], false)
      )(state);
    }

    default:
      return state;
  }
};

export default sentinels;
