import * as R from 'ramda';
import { ApplicationState } from '../reducers';

const apiBase = '/api/v1/distribution-panels';

export enum Actions {
  GET_DISTRIBUTION_PANELS_BY_SITE_ID_SUCCESS = '@distribution_panels/GET_DISTRIBUTION_PANELS_BY_SITE_ID_SUCCESS',
  GET_DISTRIBUTION_PANELS_BY_SITE_ID_ERROR = '@distribution_panels/GET_DISTRIBUTION_PANELS_BY_SITE_ID_ERROR',
  GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING = '@distribution_panels/GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING',

  GET_DISTRIBUTION_PANEL_SUCCESS = '@distribution_panels/GET_DISTRIBUTION_PANEL_SUCCESS',
  GET_DISTRIBUTION_PANEL_ERROR = '@distribution_panels/GET_DISTRIBUTION_PANEL_ERROR',
  GET_DISTRIBUTION_PANEL_LOADING = '@distribution_panels/GET_DISTRIBUTION_PANEL_LOADING',

  GET_DISTRIBUTION_PANELS_BY_SPACE_ID_SUCCESS = '@distribution_panels/GET_DISTRIBUTION_PANELS_BY_SPACE_ID_SUCCESS',
  GET_DISTRIBUTION_PANELS_BY_SPACE_ID_ERROR = '@distribution_panels/GET_DISTRIBUTION_PANELS_BY_SPACE_ID_ERROR',
  GET_DISTRIBUTION_PANELS_BY_SPACE_ID_LOADING = '@distribution_panels/GET_DISTRIBUTION_PANELS_BY_SPACE_ID_LOADING',

  PATCH_DISTRIBUTION_PANEL_LOADING = '@distribution_panels/PATCH_DISTRIBUTION_PANEL_LOADING',
  PATCH_DISTRIBUTION_PANEL_ERROR = '@distribution_panels/PATCH_DISTRIBUTION_PANEL_ERROR',
  PATCH_DISTRIBUTION_PANEL_SUCCESS = '@distribution_panels/PATCH_DISTRIBUTION_PANEL_SUCCESS',

  DELETE_DISTRIBUTION_PANEL_SUCCESS = '@distribution_panels/DELETE_DISTRIBUTION_PANEL_SUCCESS',
  DELETE_DISTRIBUTION_PANEL_ERROR = '@distribution_panels/DELETE_DISTRIBUTION_PANEL_ERROR',
  DELETE_DISTRIBUTION_PANEL_LOADING = '@distribution_panels/DELETE_DISTRIBUTION_PANEL_LOADING',

  UPLOAD_PICTURE_SUCCESS = '@distribution_panel/UPLOAD_PICTURE_SUCCESS',
  UPLOAD_PICTURE_ERROR = '@distribution_panel/UPLOAD_PICTURE_ERROR',
  UPLOAD_PICTURE_LOADING = '@distribution_panel/UPLOAD_PICTURE_LOADING',

  DELETE_PICTURE_SUCCESS = '@distribution_panel/DELETE_PICTURE_SUCCESS',
  DELETE_PICTURE_ERROR = '@distribution_panel/DELETE_PICTURE_ERROR',
  DELETE_PICTURE_LOADING = '@distribution_panel/DELETE_PICTURE_LOADING',

  TOGGLE_NEW_DISTRIBUTION_PANEL_MODAL = '@distribution_panel/TOGGLE_NEW_DISTRIBUTION_PANEL_MODAL',

  UPDATE_FIELD = '@distribution_panels/UPDATE_FIELD',
  RESET_EDIT_DISTRIBUTION_PANEL = '@distribution_panels/RESET_EDIT_DISTRIBUTION_PANEL',

  CREATE_DISTRIBUTION_PANEL_LOADING = '@distribution_panels/CREATE_DISTRIBUTION_PANEL_LOADING',
  CREATE_DISTRIBUTION_PANEL_SUCCESS = '@distribution_panels/CREATE_DISTRIBUTION_PANEL_SUCCESS',
  CREATE_DISTRIBUTION_PANEL_ERROR = '@distribution_panels/CREATE_DISTRIBUTION_PANEL_ERROR',

  TOGGLE_DELETE_DISTRIBUTION_PANEL_MODAL = '@distribution_panels/TOGGLE_DELETE_DISTRIBUTION_PANEL_MODAL',
  PRIME_FOR_DELETE = '@distribution_panels/PRIME_FOR_DELETE',
  UNSTAGE_DELETE = '@distribution_panels/UNSTAGE_DELETE',

  DISPLAY_FORM_ERRORS = '@distribution_panels/DISPLAY_FORM_ERRORS',

  INSTALL_CIRCUIT_BREAKER_LOADING = '@distribution_panels/INSTALL_CIRCUIT_BREAKER_LOADING',
  INSTALL_CIRCUIT_BREAKER_SUCCESS = '@distribution_panels/INSTALL_CIRCUIT_BREAKER_SUCCESS',
  INSTALL_CIRCUIT_BREAKER_ERROR = '@distribution_panels/INSTALL_CIRCUIT_BREAKER_ERROR',

  MOVE_BREAKER_IN_PANEL_LOADING = '@distribution_panels/MOVE_BREAKER_IN_PANEL_LOADING',
  MOVE_BREAKER_IN_PANEL_SUCCESS = '@distribution_panels/MOVE_BREAKER_IN_PANEL_SUCCESS',
  MOVE_BREAKER_IN_PANEL_ERROR = '@distribution_panels/MOVE_BREAKER_IN_PANEL_ERROR',

  RESET_NEWLY_CREATED_BREAKER_ID = '@distribution_panels/RESET_NEWLY_CREATED_BREAKER_ID',
}

export type GetDistributionPanelParams = {
  ids?: string[];
  limit?: number;
  skip?: number;
  withPath?: boolean;
  siteIds?: (string | number)[];
  spaceIds?: string[];
  equipmentIds?: string[];
  vendors?: string[];
};

const createQueryString = (params: GetDistributionPanelParams = {}) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getDistributionPanel = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_DISTRIBUTION_PANEL_SUCCESS,
  error: Actions.GET_DISTRIBUTION_PANEL_ERROR,
  loading: Actions.GET_DISTRIBUTION_PANEL_LOADING,
});

export const getDistributionPanelsBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString({ siteIds: [siteId] })}`,
  success: { type: Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING, siteId },
});

export const getDistributionPanelsBySpaceId = (id: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/space/${id}`,
  success: { type: Actions.GET_DISTRIBUTION_PANELS_BY_SPACE_ID_SUCCESS, id },
  error: Actions.GET_DISTRIBUTION_PANELS_BY_SPACE_ID_ERROR,
  loading: Actions.GET_DISTRIBUTION_PANELS_BY_SPACE_ID_LOADING,
});

export const create = () => (dispatch, getState) => {
  dispatch({
    type: 'API_POST',
    path: apiBase,
    payload: (getState() as ApplicationState).distributionPanels.editById['new']
      .fields,
    loading: Actions.CREATE_DISTRIBUTION_PANEL_LOADING,
    success: Actions.CREATE_DISTRIBUTION_PANEL_SUCCESS,
    error: Actions.CREATE_DISTRIBUTION_PANEL_ERROR,
  });
};

export const patch = (id: string) => (dispatch, getState) => {
  const acceptedPatchPanelFields = [
    'title',
    'description',
    'spaceId',
    'mdp',
    'breakerSlots',
  ];

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${id}`,
    payload: R.pick(
      acceptedPatchPanelFields,
      getState().distributionPanels.editById[id].fields
    ),
    loading: { type: Actions.PATCH_DISTRIBUTION_PANEL_LOADING, id },
    success: { type: Actions.PATCH_DISTRIBUTION_PANEL_SUCCESS, id },
    error: { type: Actions.PATCH_DISTRIBUTION_PANEL_ERROR, id },
  });
};

export const destroy = (id: number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_DISTRIBUTION_PANEL_LOADING, id },
  success: { type: Actions.DELETE_DISTRIBUTION_PANEL_SUCCESS, id },
  error: { type: Actions.DELETE_DISTRIBUTION_PANEL_ERROR, id },
});

export const uploadPicture = (id: string, formData) => ({
  type: 'API_POST',
  path: `${apiBase}/${id}/image`,
  payload: formData,
  ignoreContentType: true,
  loading: { type: Actions.UPLOAD_PICTURE_LOADING, id },
  success: { type: Actions.UPLOAD_PICTURE_SUCCESS, id },
  error: { type: Actions.UPLOAD_PICTURE_ERROR, id },
});

export const deletePicture = (id: string, path: string) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}/image?path=${path}`,
  loading: { type: Actions.DELETE_PICTURE_LOADING, id, path },
  success: { type: Actions.DELETE_PICTURE_SUCCESS, id, path },
  error: { type: Actions.DELETE_PICTURE_ERROR, id, path },
});

export const reset = (id: string) => ({
  type: Actions.RESET_EDIT_DISTRIBUTION_PANEL,
  id,
});

export const showNewDistributionPanelModal = () => ({
  type: Actions.TOGGLE_NEW_DISTRIBUTION_PANEL_MODAL,
  value: true,
});

export const hideNewDistributionPanelModal = () => ({
  type: Actions.TOGGLE_NEW_DISTRIBUTION_PANEL_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const showDeleteDistributionPanelModal = () => ({
  type: Actions.TOGGLE_DELETE_DISTRIBUTION_PANEL_MODAL,
  value: true,
});

export const hideDeleteDistributionPanelModal = () => ({
  type: Actions.TOGGLE_DELETE_DISTRIBUTION_PANEL_MODAL,
  value: false,
});

export const primeForDelete = (id: number) => ({
  type: Actions.PRIME_FOR_DELETE,
  id,
});

export const unstageDelete = () => ({
  type: Actions.UNSTAGE_DELETE,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const installCircuitBreaker = (panelId: number) => (
  dispatch,
  getState
) => {
  const newBreakerFields = (getState() as ApplicationState).circuitBreakers
    .editById['new'].fields;
  const newBreaker = {
    breaker: {
      title: newBreakerFields.title,
      description: newBreakerFields.description,
      equipmentId:
        newBreakerFields.equipmentId === -1
          ? null
          : newBreakerFields.equipmentId,
      rating: newBreakerFields.rating,
      type: newBreakerFields.type,
      subpanel: newBreakerFields.subpanel,
      siteTotal: newBreakerFields.siteTotal,
    },
    breakerColumn: newBreakerFields.breakerColumn,
    breakerSlot: newBreakerFields.breakerSlot,
    // polePhases: [newBreakerFields.polePhases],
  };

  dispatch({
    type: 'API_POST',
    path: `${apiBase}/${panelId}/breaker`,
    payload: newBreaker,
    loading: Actions.INSTALL_CIRCUIT_BREAKER_LOADING,
    success: Actions.INSTALL_CIRCUIT_BREAKER_SUCCESS,
    error: Actions.INSTALL_CIRCUIT_BREAKER_ERROR,
  });
};

export const moveBreakerInPanel = (panelId: number, breakerId: number) => (
  dispatch,
  getState
) => {
  const editBreakerFields = {
    ...getState().circuitBreakers.editById[breakerId].fields,
  };
  const breakerToMove = {
    breakerId: editBreakerFields['id'],
    breakerSlotPosition: editBreakerFields['breakerSlot'],
    breakerColumn: editBreakerFields['breakerColumn'],
  };

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${panelId}/move-breaker`,
    payload: breakerToMove,
    loading: {
      type: Actions.MOVE_BREAKER_IN_PANEL_LOADING,
      breakerId,
      panelId,
    },
    success: {
      type: Actions.MOVE_BREAKER_IN_PANEL_SUCCESS,
      breakerId,
      panelId,
    },
    error: {
      type: Actions.MOVE_BREAKER_IN_PANEL_ERROR,
      breakerId,
      panelId,
    },
  });
};

export const resetNewlyCreatedBreakerId = () => ({
  type: Actions.RESET_NEWLY_CREATED_BREAKER_ID,
});
