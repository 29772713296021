import { TemperatureUnit } from '@energybox/react-ui-library/dist/types';
import { AreaUnitLabels } from '@energybox/react-ui-library/dist/types/Global';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';
import { getUserPreferenceAreaUnit } from '@energybox/react-ui-library/dist/utils/util';
import { pathOr } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from './useAppDetails';

/** To prevent needless rerender, do one of the followings:
  - memorize the path list array
  - or declare them as the component's global variable . **/
export function useSearchFilter<T extends Object>(
  list: T[],
  searchPathsArray: string[][]
) {
  const [query, setQuery] = useState('');
  return useMemo(() => {
    const shouldFilterApply = query && query.length >= 3;
    if (!shouldFilterApply) {
      return {
        query,
        setQuery,
        filteredList: list,
      };
    }
    const filteredList: T[] = list.filter(item =>
      searchPathsArray.some(key => {
        const dataString = pathOr('', key, item);
        return hasSubstr(dataString, query);
      })
    );
    return {
      query,
      setQuery,
      filteredList,
    };
  }, [query, setQuery, list, searchPathsArray]);
}
export const useTemperatureUnit = () => {
  const currentUser = useCurrentUser();

  if (!currentUser) return TemperatureUnit.F;
  return getUserPreferenceTemperatureUnit(currentUser);
};

export const useAreaUnit = () => {
  const currentUser = useCurrentUser();

  if (!currentUser) return AreaUnitLabels.FT2;
  return getUserPreferenceAreaUnit(currentUser);
};

export const useUrlState = <T extends number | string | number[] | string[]>(
  stateKey: string,
  initialState: T
): [T, (newState: T) => void] => {
  const [urlState, setUrlState] = useState(initialState);
  const history = useHistory();

  useEffect(() => {
    let parsedValue = getUrlStateParams(history, stateKey, initialState);
    if (parsedValue) {
      if (Array.isArray(parsedValue)) {
        if (
          typeof parsedValue[0] === 'string' &&
          !isNaN(parseInt(parsedValue[0]))
        ) {
          //@ts-ignore
          parsedValue = parsedValue.map(Number);
        }
      }
      setUrlState(parsedValue);
    }
  }, []);

  const update = useCallback(
    (newState: T) => {
      setUrlState(newState);
      updateUrlState(history, stateKey, newState);
    },
    [history, stateKey]
  );

  return [urlState as T, update];
};

export const getUrlStateParams = <
  T extends string | number | string[] | number[]
>(
  history,
  stateKey: string,
  initialState: T
): T => {
  const searchParams = new URLSearchParams(history?.location?.search);
  // array processing
  if (Array.isArray(initialState)) {
    if (typeof initialState[0] === 'number') {
      return searchParams.getAll(stateKey).map(Number) as T;
    }
    return searchParams.getAll(stateKey) as T;
  }
  // single number or string value
  const value = searchParams.get(stateKey) as T;

  //@ts-ignore
  if (typeof initialState === 'number') return +value;

  return value || initialState;
};

export const updateUrlState = <T extends string | number | string[] | number[]>(
  history,
  stateKey: string,
  newState: T
) => {
  const pathname = history.location.pathname;
  const updatedSearchParams = new URLSearchParams(history.location.search);
  // add array to url param
  if (Array.isArray(newState)) {
    updatedSearchParams.delete(stateKey);
    newState.forEach(value => {
      updatedSearchParams.append(stateKey, value);
    });
    history.push({ pathname, search: updatedSearchParams.toString() });
    return;
  }
  // add single value to url param.
  if (!newState) {
    updatedSearchParams.delete(stateKey);
  } else updatedSearchParams.set(stateKey, `${newState}`);
  history.push({ pathname, search: updatedSearchParams.toString() });
};
