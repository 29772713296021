import { LocalOverrideActivityDataStatus } from '@energybox/react-ui-library/dist/types';
import equals from 'ramda/src/equals';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalOverrideActivityByControlBoardId } from '../actions/controls';
import { ApplicationState } from '../reducers';

const useGetLocalOverrideActivity = (
  controlBoardId: string | number | undefined,
  fromDate: number,
  toDate: number
): LocalOverrideActivityDataStatus => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (controlBoardId === undefined) return;
    dispatch(
      getLocalOverrideActivityByControlBoardId(
        controlBoardId,
        fromDate.valueOf(),
        toDate.valueOf()
      )
    );
  }, [dispatch, fromDate, toDate, controlBoardId]);

  const timeRange = `${fromDate.valueOf()}-${toDate.valueOf()}`;
  const localOverrideActivityDataStatus = useSelector<
    ApplicationState,
    LocalOverrideActivityDataStatus
  >(({ controls }) => {
    const dataStatus = controlBoardId
      ? controls.localOverrideActivityByControlBoardId[controlBoardId]?.[
          timeRange
        ]
      : undefined;

    if (dataStatus === undefined) {
      return {
        isLoading: false,
      };
    }

    return dataStatus;
  }, equals);

  return localOverrideActivityDataStatus;
};

export default useGetLocalOverrideActivity;
