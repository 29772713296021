import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { useGetNetworkGroupsBySopId } from '../../hooks/useGetNetworkGroup';
import { destroy } from '../../actions/sops';
import styles from './DeleteHvacSopModal.module.css';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';

type Props = {
  setIsDeleteModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
  setIdToDelete: React.Dispatch<React.SetStateAction<number | null>>;
  isDeleteModalShowing: boolean;
  idToDelete: number | null;
  equipmentAffectedCount: number;
  isOrgLevelEdit?: boolean;
};

const DeleteHvacSopModal: React.FC<Props> = ({
  idToDelete,
  isDeleteModalShowing,
  setIdToDelete,
  setIsDeleteModalShowing,
  equipmentAffectedCount,
  isOrgLevelEdit,
}) => {
  const dispatch = useDispatch();

  const orgId = useCurrentOrganizationId();
  const networkGroups = useGetNetworkGroupsBySopId(idToDelete, orgId);
  const networkGroupIds = useMemo(() => {
    if (!isDefined(networkGroups)) {
      return undefined;
    }
    return networkGroups.map(ng => ng.id);
  }, [networkGroups]);

  const actions = [
    <Button
      variant="text"
      onClick={() => {
        setIsDeleteModalShowing(false);
        setIdToDelete(null);
      }}
      className={styles.marginRight}
    >
      Cancel
    </Button>,
    <Button
      onClick={() => {
        if (isDefined(idToDelete)) {
          dispatch(
            destroy(idToDelete, {
              produceEdgeConfigByNetworkGroupIds: networkGroupIds,
              orgId: isOrgLevelEdit ? orgId : undefined,
            })
          );
          setIsDeleteModalShowing(false);
          setIdToDelete(null);
        }
      }}
    >
      Delete
    </Button>,
  ];

  if (!isDeleteModalShowing) return null;
  return (
    <Modal actions={actions}>
      <ModalTitle>
        <WarningIcon className={styles.warningIcon} />
        Are you sure you want to delete this SOP?
      </ModalTitle>
      <ModalContent>
        <div>
          Deleting this SOP will affect{' '}
          <span className={styles.boldText}>
            {equipmentAffectedCount} equipment.
          </span>
        </div>
        <div className={styles.marginTop}>
          With this SOP you are deleting your HVAC control settings.
        </div>
        <div className={styles.marginTop}>
          <i>
            {' '}
            Please note: The Thermostat units onsite will remain with the last
            active settings.
          </i>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DeleteHvacSopModal;
