import {
  ControlOverridesDisplay,
  ShortenedSpan,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { Link } from 'react-router-dom';
import { Routes } from '../../../routes';
import { getHvacSopRoute } from '../../../utils/hvacSop';
import styles from './EdmTable.module.css';
import { global } from '@energybox/react-ui-library/dist/utils';

interface Props {
  sop_title: string;
  edm_enable: {
    field?: boolean;
    warning?: string;
    error?: string;
  };
  filteredOrUniqueTypes: string[];
  sopIds: string[];
  siteId: number;
  by_equipment;
  index;
}
export const RenderSopRow: React.FC<Props> = ({
  sop_title,
  by_equipment,
  edm_enable,
  index,
  filteredOrUniqueTypes,
  sopIds,
  siteId,
}) => {
  return (
    <div key={`sop${index}infoRow`} className={styles.fitContent}>
      <div key={`sop${sopIds[index]}Title`}>
        <Link
          className={styles.cursor}
          to={getHvacSopRoute({
            orgUnitId: siteId,
            hvacSopId: sopIds[index],
          })}
          target="_blank"
        >
          {sop_title}
        </Link>

        <div className={styles.subText}>
          DemandManager:
          {edm_enable.field ? (
            <span>On</span>
          ) : (
            <Tooltip
              content={edm_enable.warning}
              tooltipTextClassName={styles.tooltipText}
              arrowDirection="top"
              disableAutoAdjust
              underline={false}
            >
              <span className={styles.edmStatusOff}>Off</span>
            </Tooltip>
          )}
        </div>
      </div>
      {filteredOrUniqueTypes.length ? (
        <div>
          {filteredOrUniqueTypes.map(equipmentId => {
            const equipmentInfo = by_equipment[equipmentId];
            return (
              <div
                key={`Id${equipmentId}`}
                className={styles.alignEquipmentCells}
              >
                <div
                  className={styles.equipmentTitle}
                  key={`sop${sop_title}equipment${equipmentId}Title`}
                >
                  <Link
                    className={styles.cursor}
                    to={`${Routes.EQUIPMENT}/${equipmentId}`}
                    target="_blank"
                  >
                    <ShortenedSpan
                      content={equipmentInfo?.equipment_title}
                      maxStringLength={20}
                    />
                  </Link>
                </div>
                <div
                  className={styles.equipmentMode}
                  key={`controlMode${equipmentId}`}
                >
                  {equipmentInfo?.control_mode.field &&
                    !equipmentInfo?.control_mode.warning && (
                      <ControlOverridesDisplay
                        controlWorkingMode={equipmentInfo?.control_mode.field}
                        isLocalOverrideActive={undefined}
                        disabled={false}
                        useGreenText={false}
                      />
                    )}
                  {equipmentInfo?.control_mode.warning && (
                    <Tooltip
                      content={equipmentInfo?.control_mode.warning}
                      extraClassNames={[styles.tooltip]}
                      childrenWrapperClassName={styles.tooltip}
                      tooltipTextClassName={styles.tooltipText}
                      arrowDirection="top"
                      disableAutoAdjust
                      underline={false}
                    >
                      {equipmentInfo?.control_mode.field ? (
                        <ControlOverridesDisplay
                          controlWorkingMode={equipmentInfo?.control_mode.field}
                          isLocalOverrideActive={undefined}
                          disabled={false}
                          useGreenText={false}
                        />
                      ) : (
                        <span className={styles.warningWorkingMode}>
                          No Thermostat
                        </span>
                      )}
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.alignEquipmentCells}>
          <div className={styles.equipmentTitle}>{global.NOT_AVAILABLE}</div>
          <div className={styles.equipmentMode}>
            <span className={styles.controlMode}>{global.NOT_AVAILABLE}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default RenderSopRow;
