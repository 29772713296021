import {
  setLastCreatedIdOnSelectTimetable,
  Actions as TimetableActions,
} from '../actions/time_tables';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { act } from 'react-dom/test-utils';

const timetableMiddleware = store => next => action => {
  if (action.type === TimetableActions.CREATE_TIME_TABLE_SUCCESS) {
    if (isDefined(action.createdOnSelectTimetable)) {
      store.dispatch(setLastCreatedIdOnSelectTimetable(action.data.id));
    }
    return next(action);
  } else {
    return next(action);
  }

  // switch (action.type) {
  //   case TimetableActions.CREATE_TIME_TABLE_SUCCESS:
  //     if (isDefined(action.createdOnSelectTimetable)) {
  //       store.dispatch(setLastCreatedIdOnSelectTimetable(action.data.id));
  //     }
  //     return next(action);

  //   default:
  //     return next(action);
  // }
};

export default timetableMiddleware;
