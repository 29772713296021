import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { ChevronLeft } from '@energybox/react-ui-library/dist/icons';
import {
  ControlsType,
  FanMode,
  OutsideOfTimeTable,
  TemperatureControl,
  ThermostatWorkingMode,
  WorkingMode,
  TimeTable,
  ControlTypesToDisplayTextsMapping,
  TemperatureUnit,
  MeasurementSystem,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys, isDefined } from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { hideNewControlModal } from '../../../actions/controls';
import {
  Actions as HvacControlsActions,
  createHvacControl,
} from '../../../actions/hvacControls';
import {
  Actions as SchedulerActions,
  createScheduler,
  displayFormErrors as displaySchedulerFormErrors,
  PostSchedulerParams,
  updateField as updateSchedulerField,
} from '../../../actions/schedulers';
import {
  Actions as TemperatureControlActions,
  createTemperatureControls as createTemperatureControlsAction,
  displayFormErrors as displayTemperatureControlFormErrors,
  updateField as updateTemperatureControlField,
} from '../../../actions/temperature_controls';
import { getTimeTables } from '../../../actions/time_tables';
import EditControlsHvacForm from '../../../components/EditControlsHvacForm';
import EditControlsTemperatureForm from '../../../components/EditControlsTemperatureForm';
import EditSchedulerForm from '../../../components/EditSchedulerForm';
import SelectControlsForm from '../../../components/SelectControlsForm';
import { useTemperatureUnit } from '../../../hooks/utils';
import { ApplicationState } from '../../../reducers';
import { EditableFields, EditScheduler } from '../../../reducers/schedulers';
import { EditTemperatureControl } from '../../../reducers/temperature_controls';
import { CreateNewText } from '../../../types/global';
import { EditHvacSOPControl } from '../../../types/hvacControl';
import { ApiError, renderAPIerror } from '../../../utils/apiErrorFeedback';
import {
  areThereFormErrors,
  validateHvacControlForm,
} from '../../../utils/formValidation';
import styles from './NewControlModal.module.css';

interface OwnProps {
  equipmentId: number;
  equipmentTitle: string;
  spaceId?: number;
}

interface Props extends OwnProps {
  isVisible: boolean;
  siteId?: number;
  orgId?: number;
  timeTables: TimeTable[];
  temperatureControlApiError: ApiError;
  onClose: () => void;
  getTimeTables: (id: string) => void;
  createScheduler: (params: PostSchedulerParams) => void;
  onSchedulerFormChange: (field: string | string[], value: any) => void;
  displaySchedulerFormErrors: () => void;
  displayTemperatureControlFormErrors: () => void;
  onTemperatureControlFormChange: (field: string, value: any) => void;
  editScheduler: EditScheduler;
  editTemperatureControl: EditTemperatureControl;
  createTemperatureControls: (newControlConfig: TemperatureControl) => void;
  createHvacControl: (payload: EditHvacSOPControl) => void;
  timezone?: string;
}

const NewControlModal = ({
  equipmentId,
  equipmentTitle,
  spaceId,
  isVisible,
  siteId,
  orgId,
  timeTables,
  temperatureControlApiError,
  onClose,
  getTimeTables,
  createScheduler,
  displaySchedulerFormErrors,
  onTemperatureControlFormChange,
  displayTemperatureControlFormErrors,
  onSchedulerFormChange,
  editScheduler,
  editTemperatureControl,
  createTemperatureControls,
  createHvacControl,
  timezone,
}: Props) => {
  const temperatureUnit = useTemperatureUnit();
  const {
    fields: schedulerFields,
    formErrors: schedulerFormErrors,
    formErrorsVisible: schedulerFormErrorsVisible,
    apiError: schedulerApiError,
  } = editScheduler;
  const {
    fields: temperatureControlFields,
    formErrors: temperatureControlFormErrors,
    formErrorsVisible: temperatureControlFormErrorsVisible,
  } = editTemperatureControl;

  const initialHvacControlFields = {
    //Basic tab
    thermostatId: -1,
    maxTemp: temperatureUnit === TemperatureUnit.C ? 0 : -17.7778,
    minTemp: temperatureUnit === TemperatureUnit.C ? 0 : -17.7778,
    thermostatWorkingMode: ThermostatWorkingMode.AUTO,
    fanMode: FanMode.AUTO,
    thermostatDisplayUnits: MeasurementSystem.IMPERIAL,
    enableLocalAdjustment: false,
    setPointLimitDelta: 2,
    overrideTimer: 60 * 60,

    //Schedule tab
    timetableId: -1,
    beginDelta: 0,
    endDelta: 0,
    outsideOfTimeTable: OutsideOfTimeTable.OFF,
    outsideMaxTemp: 0,
    outsideMinTemp: 0,
    outsideFanMode: FanMode.AUTO,

    //miscellaneous, static properties
    equipmentId: equipmentId,
    workingMode: WorkingMode.NORMAL,
  };

  //** useState **//
  const [numberOfTimesClickedOnSave, setNumberOfTimesClickedOnSave] = useState(
    0
  );

  const [hvacControlFields, setHvacControlFields] = useState<
    EditHvacSOPControl
  >(initialHvacControlFields);
  const [hvacFormErrorsVisible, setHvacFormErrorsVisible] = useState(false);
  const hvacFormErrors = validateHvacControlForm(
    hvacControlFields,
    temperatureUnit
  );
  const [selectedControlsType, setSelectedControlsType] = useState<
    ControlsType
  >();
  const [stepNo, setStepNo] = useState(1);

  //** useSelector **//
  const hvacApiError = useSelector(({ hvacControls }: ApplicationState) => {
    return hvacControls.apiError;
  });

  //** useEffect **//
  useEffect(() => {
    onTemperatureControlFormChange('equipmentId', equipmentId);
  }, [onTemperatureControlFormChange, equipmentId]);

  useEffect(() => {
    if (isDefined(orgId)) {
      getTimeTables(orgId.toString());
    }
  }, [getTimeTables, orgId]);
  //*******************//

  const nextStep = () => {
    setStepNo(stepNo + 1);
  };

  const previousStep = () => {
    setStepNo(stepNo - 1);
  };

  const onControlsTypeClick = (type: ControlsType) => {
    setSelectedControlsType(type);
    nextStep();
  };

  const onHvacControlFieldChange = (
    field: string,
    value: string | number | boolean | null
  ) => {
    setHvacControlFields(prevHvacControlFields => ({
      ...prevHvacControlFields,
      [field]: value,
    }));
  };

  const renderStepOne = () => {
    return <SelectControlsForm onControlsTypeClick={onControlsTypeClick} />;
  };

  const renderStepTwo = () => {
    switch (selectedControlsType) {
      case ControlsType.COOLING:
      case ControlsType.HEATING: {
        return isDefined(siteId) ? (
          <EditControlsTemperatureForm
            siteId={siteId}
            spaceId={spaceId}
            equipmentId={equipmentId}
            fields={temperatureControlFields}
            onChange={onTemperatureControlFormChange}
            formErrors={temperatureControlFormErrors}
            formErrorsVisible={temperatureControlFormErrorsVisible}
            temperatureType={selectedControlsType}
            numberOfTimesClickedOnSave={numberOfTimesClickedOnSave}
          />
        ) : null;
      }

      case ControlsType.HVAC: {
        return isDefined(siteId) ? (
          <EditControlsHvacForm
            siteId={siteId}
            fields={hvacControlFields}
            formErrors={hvacFormErrors}
            formErrorsVisible={hvacFormErrorsVisible}
            onChange={onHvacControlFieldChange}
            numberOfTimesClickedOnSave={numberOfTimesClickedOnSave}
          />
        ) : null;
      }

      case ControlsType.SCHEDULER: {
        return isDefined(siteId) ? (
          <EditSchedulerForm
            timezone={timezone}
            timeTables={timeTables}
            siteId={siteId}
            equipmentId={equipmentId}
            onChange={onSchedulerFormChange}
            fields={schedulerFields}
            formErrors={schedulerFormErrors}
            formErrorsVisible={schedulerFormErrorsVisible}
            numberOfTimesClickedOnSave={numberOfTimesClickedOnSave}
          />
        ) : null;
      }

      default: {
        return <div>There was an error. Please go back.</div>;
      }
    }
  };

  const renderSteps = () => {
    switch (stepNo) {
      case 1:
        return renderStepOne();
      case 2:
        return renderStepTwo();
    }
  };

  const onSave = () => {
    switch (selectedControlsType) {
      case ControlsType.HEATING:
      case ControlsType.COOLING: {
        if (hasKeys(temperatureControlFormErrors)) {
          displayTemperatureControlFormErrors();
        } else {
          createTemperatureControls({
            ...temperatureControlFields,
            outsideSetPoint: temperatureControlFields.outsideSetPoint,
          });
        }
        break;
      }

      case ControlsType.SCHEDULER: {
        const {
          beginDelta,
          endDelta,
          timetableId,
          lightSensorSettings: {
            beginDelta: lightBeginDelta,
            endDelta: lightEndDelta,
            timetableId: lightTimetableId,
            controlBoardId,
            threshold,
            hysteresis,
            actionInterval,
          },
        } = schedulerFields;

        const selectedScheduleTimeTable = timeTables.find(
          (t: TimeTable) => t.id === timetableId
        );
        const selectedLightTimeTable = timeTables.find(
          (t: TimeTable) => t.id === lightTimetableId
        );

        const hasAtLeastOneTimetableBeenSelected =
          isDefined(selectedScheduleTimeTable) ||
          isDefined(selectedLightTimeTable);

        const isNoScheduleTimetableOptionSelected =
          timetableId === 'null' || timetableId === null;

        const hasInvalidScheduleTimetable =
          (!isDefined(selectedScheduleTimeTable) &&
            !isNoScheduleTimetableOptionSelected) ||
          !hasAtLeastOneTimetableBeenSelected;

        const isControlBoardSelected = controlBoardId !== -1;

        const hasInvalidLightTimetable =
          !hasAtLeastOneTimetableBeenSelected ||
          (isControlBoardSelected && !isDefined(selectedLightTimeTable));

        if (isDefined(controlBoardId) && controlBoardId > 0) {
          if (
            hasKeys(schedulerFormErrors) ||
            hasInvalidScheduleTimetable ||
            hasInvalidLightTimetable
          ) {
            displaySchedulerFormErrors();
          } else {
            createScheduler({
              equipmentId,
              beginDelta: parseInt(beginDelta),
              endDelta: parseInt(endDelta),
              timetableId: timetableId,
              lightSensorSettings: {
                actionInterval,
                controlBoardId,
                threshold,
                hysteresis,
                beginDelta: parseInt(lightBeginDelta),
                endDelta: parseInt(lightEndDelta),
                timetableId:
                  isDefined(lightTimetableId) && lightTimetableId > 0
                    ? lightTimetableId
                    : null,
              },
            });
          }
        } else {
          if (
            Object.keys(schedulerFormErrors).some(
              key => !key.includes('lightSensorSettings')
            ) ||
            hasInvalidScheduleTimetable
          ) {
            displaySchedulerFormErrors();
          } else {
            createScheduler({
              equipmentId,
              beginDelta: parseInt(beginDelta),
              endDelta: parseInt(endDelta),
              timetableId: timetableId,
              lightSensorSettings: null,
            });
          }
        }
        break;
      }

      case ControlsType.HVAC: {
        if (areThereFormErrors(hvacFormErrors)) {
          setHvacFormErrorsVisible(true);
        } else {
          createHvacControl(hvacControlFields);
        }
        break;
      }

      default:
        break;
    }

    setNumberOfTimesClickedOnSave((prevNum: number) => {
      return prevNum + 1;
    });
  };

  const renderModalTitle = () => {
    if (stepNo === 1) {
      return CreateNewText.CONTROL;
    }

    if (selectedControlsType) {
      return (
        <>
          <div>
            Controls: {ControlTypesToDisplayTextsMapping[selectedControlsType]}
          </div>
          <div className={styles.equipmentTitle}>{equipmentTitle}</div>
        </>
      );
    }

    return 'Controls';
  };

  const actions = (
    <>
      {stepNo !== 1 ? (
        <div>
          <Button variant="text" onClick={previousStep}>
            <span className={styles.backButton}>
              <ChevronLeft size={16} />
              <span className={styles.backButtonText}>Back</span>
            </span>
          </Button>
        </div>
      ) : (
        <div />
      )}
      <div>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        {stepNo === 2 && <Button onClick={onSave}>Add</Button>}
      </div>
    </>
  );

  if (!isVisible) return null;
  return (
    <Modal
      onClose={onClose}
      actions={actions}
      actionsClassName={styles.actionsContainer}
      disableEscapeClose
    >
      <div className={styles.modal}>
        <ModalTitle>{renderModalTitle()}</ModalTitle>
        <ModalContent className={styles.modalContent}>
          {renderSteps()}
        </ModalContent>
        {selectedControlsType === ControlsType.SCHEDULER &&
          renderAPIerror(
            schedulerApiError,
            SchedulerActions.CREATE_SCHEDULER_ERROR
          )}
        {(selectedControlsType === ControlsType.COOLING ||
          selectedControlsType === ControlsType.HEATING) &&
          renderAPIerror(
            temperatureControlApiError,
            TemperatureControlActions.CREATE_TEMPERATURE_CONTROLS_ERROR
          )}
        {selectedControlsType === ControlsType.HVAC &&
          renderAPIerror(
            hvacApiError,
            HvacControlsActions.CREATE_HVAC_CONTROL_ERROR
          )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (
  {
    timeTables,
    sites,
    schedulers,
    temperatureControls,
    controls,
    app,
  }: ApplicationState,
  { equipmentId }: OwnProps
) => {
  const siteId: number | undefined = sites.resourceIdToSiteId[equipmentId];
  const orgId: number | undefined = app.currentOrganizationId;

  const siteTimeTableIds: number[] = pathOr(
    [],
    [siteId],
    timeTables.timeTableIdsByParentId
  );
  const orgTimeTableIds: number[] = pathOr(
    [],
    [orgId],
    timeTables.timeTableIdsByParentId
  );

  return {
    isVisible: controls.showNewControlModal,
    siteId,
    orgId,
    timezone: sites.sitesById[siteId]?.timeZone,
    timeTables: [...siteTimeTableIds, ...orgTimeTableIds].map(
      timetableId => timeTables.timeTablesById[timetableId]
    ),
    editScheduler: schedulers.editById['new'],
    editTemperatureControl: temperatureControls.editById['new'],
    temperatureControlApiError: temperatureControls.apiError,
  };
};

const mapDispatchToProps = (dispatch: any, { equipmentId }: OwnProps) => ({
  onClose: () => dispatch(hideNewControlModal()),
  onSchedulerFormChange: (field: string | string[], value: string) =>
    dispatch(updateSchedulerField('new', field, value)),
  getTimeTables: (orgId: string) => dispatch(getTimeTables(orgId)),
  createScheduler: (params: PostSchedulerParams) =>
    dispatch(createScheduler(params)),
  displaySchedulerFormErrors: () => dispatch(displaySchedulerFormErrors('new')),
  onTemperatureControlFormChange: (field: string, value: string) =>
    dispatch(updateTemperatureControlField('new', field, value)),
  displayTemperatureControlFormErrors: () =>
    dispatch(displayTemperatureControlFormErrors('new')),
  createTemperatureControls: (params: TemperatureControl) =>
    dispatch(createTemperatureControlsAction(params)),
  createHvacControl: (payload: EditHvacSOPControl) =>
    dispatch(createHvacControl(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewControlModal);
