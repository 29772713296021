import {
  User,
  UserGroup,
  initialSentinelEscalationWithoutDelay,
  SentinelEscalation,
  SentinelRecipientsChannel,
} from '@energybox/react-ui-library/dist/types';
import { FormText } from '@energybox/react-ui-library/dist/components';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import AddEntry from '../StepActions/AddEntry';
import StepTitle from '../StepTitle';
import styles from './StepHandling.module.css';
import StepHandlingItem from './StepHandlingItem';
import { Errors } from '../../SentinelWizard';

type Props = {
  handleAddStepHandling: (escalation: SentinelEscalation) => void;
  handleRemoveStepHandling: (index: number) => void;
  handleUpdateStepHandling: (
    index: number
  ) => (escalation: SentinelEscalation) => void;
  escalations: SentinelEscalation[];
  users: User[];
  userGroups: UserGroup[];
  errors: Errors;
  setErrors: (errors: Errors) => void;
  isSetErrorField: (value: any) => void;
  isErrorField: boolean;
  isSetEmailErrorField: (value: any) => void;
  isEmailErrorField: boolean;
  setAction: (value: any) => void;
  setEmail: (value: any) => void;
  headerCounter: number;
  reRenderChild: any;
  isFormSaveError: boolean;
  isSetFormSaveError: (value: any) => void;
  handleValidation: (value: any) => void;
  // onEmailValidation: (data: any) => void
  isFirstTimeCountCopy: (count: any) => void;
  copyCount: any;
  setEditBtnEnable: (status: boolean[]) => any;
  editBtnEnable: boolean[];
};

export type Recipient = {
  title: string;
  channel: SentinelRecipientsChannel;
  identifier: string;
  id: number;
};

const StepHandling = forwardRef((props: Props, ref) => {
  const {
    users,
    userGroups,
    isSetErrorField,
    isErrorField,
    isSetEmailErrorField,
    isEmailErrorField,
    setAction,
    setEmail,
    isSetFormSaveError,
    handleValidation,
    isFormSaveError,
    isFirstTimeCountCopy,
    copyCount,
    setEditBtnEnable,
    editBtnEnable,
    setErrors,
  } = props;
  const [data, setData] = useState<Recipient[]>([]);

  const { escalations = [] } = props;

  const itemRef = useRef<{ validate: () => void }>(null);
  useImperativeHandle(ref, () => ({
    handleItemValidation() {
      itemRef.current?.validate();
    },
  }));

  useEffect(() => {
    setData([
      ...users.map((item: User) => ({
        id: item.id,
        title: `${item.email} (User)`,
        channel: SentinelRecipientsChannel.USER_EMAIL,
        identifier: item.id.toString(),
      })),
      ...userGroups.map((item: UserGroup) => ({
        id: item.id,
        title: `${item.title} (UserGroup)`,
        channel: SentinelRecipientsChannel.USER_GROUP_EMAIL,
        identifier: item.id.toString(),
      })),
    ]);
  }, [users, userGroups]);

  const handleAddEscalation = () =>
    props.handleAddStepHandling(initialSentinelEscalationWithoutDelay);

  const lastEscalationIsValid =
    escalations.length &&
    escalations[escalations.length - 1].recipients.length > 0 &&
    escalations[escalations.length - 1].name !== '';
  return (
    <div className={styles.stepWrapper}>
      <StepTitle>
        Please define the actions and the staff handling the triggered
        notifications.
      </StepTitle>

      <div className={styles.formHeading}>
        <div>
          <FormText>Action Name *</FormText>
        </div>
        <div>
          <FormText>Assign *</FormText>
        </div>
        <div>
          <FormText>Severity</FormText>
        </div>
        <div>
          <FormText>Channels</FormText>
        </div>
        <div>
          <FormText>Message</FormText>
        </div>
      </div>

      {escalations.map((escalation: SentinelEscalation, index) => {
        return (
          <div
            key={`StepHandlingElement${index}${escalation.name}}`}
            className={styles.stepHandling}
          >
            <StepHandlingItem
              ref={itemRef}
              handleAddEscalation={handleAddEscalation}
              handleUpdateEscalation={props.handleUpdateStepHandling(index)}
              handleDeleteEscalation={props.handleRemoveStepHandling}
              options={data}
              escalation={escalation}
              isDeleteAble={index !== 0}
              index={index}
              isLoading={false}
              isSetErrorField={isSetErrorField}
              isErrorField={isErrorField}
              isSetEmailErrorField={isSetEmailErrorField}
              isEmailErrorField={isEmailErrorField}
              setAction={setAction}
              setEmail={setEmail}
              isSetFormSaveError={isSetFormSaveError}
              handleValidation={handleValidation}
              // onEmailValidationFunction={onEmailValidation}
              isFormSaveError={isFormSaveError}
              isFirstTimeCountCopy={isFirstTimeCountCopy}
              copyCount={copyCount}
              setEditBtnEnable={setEditBtnEnable}
              editBtnEnable={editBtnEnable}
            />
          </div>
        );
      })}

      {escalations.length < 5 && (
        <>
          <AddEntry
            title="Add new action"
            onClick={handleAddEscalation}
            disabled={lastEscalationIsValid === false}
          />
          <div className={styles.errorCenterContainer}>
            <span className={styles.errorContainer}>
              {props.isFormSaveError === true
                ? props.errors.handling.onSave
                : ''}
            </span>
          </div>
        </>
      )}
    </div>
  );
});

export default StepHandling;
