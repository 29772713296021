import {
  EnergyApiFilter,
  TimeSeriesAggregationLevel,
} from '@energybox/react-ui-library/dist/types';
import {
  formatDateForTimeSeriesApi,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import { EnergyboxService } from '../config';

export enum Actions {
  TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS',
  TS_ENERGY_BY_EQUIPMENT_ID_LOADING = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_ID_LOADING',
  TS_ENERGY_BY_EQUIPMENT_ID_ERROR = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_ID_ERROR',

  TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING = '@@energy/TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING',
  TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR = '@@energy/TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR',
  TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS = '@@energy/TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS',

  TS_GET_ENERGY_BY_SENSOR_ID_SUCCESS = '@@energy/TS_GET_ENERGY_BY_SENSOR_ID_SUCCESS',
  TS_GET_ENERGY_BY_SENSOR_ID_LOADING = '@@energy/TS_GET_ENERGY_BY_SENSOR_ID_LOADING',
  TS_GET_ENERGY_BY_SENSOR_ID_ERROR = '@@energy/TS_GET_ENERGY_BY_SENSOR_ID_ERROR',
}

const baseUrl = '/energy';

export const setEnergyFilter = (filter?: EnergyApiFilter): string => {
  const queryParams = new URLSearchParams();

  //filter by equipmentId
  if (filter && filter.id) {
    queryParams.set('id', filter.id.toString());
  }

  if (filter && filter.from) {
    queryParams.set('from', filter.from.toString());
  }

  if (filter && filter.to) {
    queryParams.set('to', filter.to.toString());
  }

  if (filter && filter.aggregationLevel) {
    queryParams.set('agg', filter.aggregationLevel.toString());
  }

  if (filter && filter.seeSpilloverPoint) {
    queryParams.set('seeSpilloverPoint', filter.seeSpilloverPoint.toString());
  }

  return queryParams.toString();
};

//IMPORTANT: all from/to time queryParams for these functions
//expected to be initially epoch timestamp (in seconds),
//must convert to ISO 8601 format for TS API

const convertQueryParamsToTsApiFormat = (queryParams: EnergyApiFilter) => {
  const updatedQueryParams = { ...queryParams };

  Object.keys(queryParams).forEach(param => {
    const paramValue = queryParams[param];
    if (param === 'to' || param === 'from') {
      const dateFormat = new Date(paramValue);
      updatedQueryParams[param] = formatDateForTimeSeriesApi(dateFormat);
    } else if (param === 'aggregationLevel') {
      updatedQueryParams[param] = TimeSeriesAggregationLevel[paramValue];
    } else {
      updatedQueryParams[param] = queryParams[param];
    }
  });

  return updatedQueryParams;
};

export const getTSEnergyByEquipmentId = (
  equipmentId: number | string,
  queryParams: EnergyApiFilter,
  secondaryId?: string
) => {
  const id = isDefined(secondaryId)
    ? equipmentId + `_${secondaryId}`
    : equipmentId;

  // secondaryId is actually unnecessary for api call, but
  // need it so multiple endpoint calls can be sent to be stored
  // in redux for each id (equipmentId_secondaryId)
  const unnecessarySecondaryIdParam = secondaryId
    ? `&secondaryId=${secondaryId}`
    : '';

  return {
    type: 'API_GET',
    service: EnergyboxService.timeSeries,
    path: `${baseUrl}/equipment/${equipmentId}/series?${setEnergyFilter(
      convertQueryParamsToTsApiFormat(queryParams)
    )}${unnecessarySecondaryIdParam}`,
    // secondaryId is actually unnecessary for api call, but
    // need it to multiple endpoint calls can be sent to be stored
    // in redux for each id (equipmentId_secondaryId)
    loading: {
      type: Actions.TS_ENERGY_BY_EQUIPMENT_ID_LOADING,
      id,
    },
    success: {
      type: Actions.TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS,
      id,
    },
    error: {
      type: Actions.TS_ENERGY_BY_EQUIPMENT_ID_ERROR,
      id,
    },
  };
};

export const getTSEnergyDashboardBySiteId = (
  siteId: string | number,
  queryParams: EnergyApiFilter
) => ({
  type: 'API_GET',
  service: EnergyboxService.timeSeries,
  path: `${baseUrl}/site/${siteId}/dashboard?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(queryParams)
  )}`,
  loading: {
    type: Actions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getTSEnergyBySensorId = (
  sensorId: string | number,
  queryParams: EnergyApiFilter
) => ({
  type: 'API_GET',
  service: EnergyboxService.timeSeries,
  path: `${baseUrl}/sensor/${sensorId}/series?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(queryParams)
  )}`,
  loading: {
    type: Actions.TS_GET_ENERGY_BY_SENSOR_ID_LOADING,
    id: sensorId,
  },
  success: {
    type: Actions.TS_GET_ENERGY_BY_SENSOR_ID_SUCCESS,
    id: sensorId,
  },
  error: {
    type: Actions.TS_GET_ENERGY_BY_SENSOR_ID_ERROR,
    id: sensorId,
  },
});
