import { IoMdCheckmark } from 'react-icons/io';
import {
  ControlBoard,
  EnergyPro,
  Gateway,
  Locale,
  Firmware,
  FirmwareGatewayModel,
  FirmwareDeviceIdVendor,
  ReleaseTypeHumanReadable,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
  Table,
} from '@energybox/react-ui-library/dist/components';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  IconCheckboxContained,
  IconCheckboxOutlined,
} from '@energybox/react-ui-library/dist/icons';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { connect } from 'react-redux';
import { getFirmwares } from '../../actions/app';
import { updateFirmware } from '../../actions/gateways';
import { ApplicationState } from '../../reducers';
import styles from './UpdateFirmwareModal.module.css';

type OwnProps = {
  device: Gateway | EnergyPro | ControlBoard | FirmwareDeviceIdVendor;
  firmwareGatewayModel: FirmwareGatewayModel;
  onClose: () => void;
  isVisible: boolean;
};

interface Props extends OwnProps {
  isLoading?: boolean;
  locale: Locale;
  updateFirmware: (
    device: Gateway | EnergyPro | ControlBoard | FirmwareDeviceIdVendor,
    filePath: string,
    version: string
  ) => void;
  firmwares?: Firmware[];
  getFirmwares: typeof getFirmwares;
}

interface State {
  firmwareIdToUpdate: string;
}

class UpdateFirmwareModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firmwareIdToUpdate: '',
    };
  }

  onUpdateFirmwareModalClose = () => {
    this.props.onClose();
  };

  componentDidMount = () => {
    this.props.getFirmwares();
  };

  renderConfirmationButtons = (version: string, filePath: string) => {
    const { device, onClose, updateFirmware } = this.props;

    return (
      <div className={styles.confirmationContainer}>
        <span>Are you sure?</span>
        <span
          className={styles.confirmIcon}
          onClick={() => {
            this.setState({ firmwareIdToUpdate: '' });
            updateFirmware(device, filePath, version);
            onClose();
          }}
        >
          <IconCheckboxOutlined size={16} color="var(--accent-base)" />
        </span>
        <span
          className={styles.cancelIcon}
          onClick={() => this.setState({ firmwareIdToUpdate: '' })}
        >
          <span className={styles.cancelIconText}>x</span>
        </span>
      </div>
    );
  };

  render() {
    const { device, locale, firmwares, onClose, isVisible } = this.props;
    const { firmwareIdToUpdate } = this.state;
    const data = firmwares || []; // api already sort by version desc

    let runningReleaseVersion: string | undefined;
    runningReleaseVersion = (device as Gateway).gatewayInfo?.firmwareVersion;
    if (!runningReleaseVersion) {
      runningReleaseVersion = (device as EnergyPro).firmwareVersion;
    }

    const highlightIndexes = [
      data.findIndex(firmware => firmware.version === runningReleaseVersion),
    ];

    if (!isVisible) return null;

    const columns: Columns<Firmware>[] = [
      {
        header: '',
        cellContent: ({ version }) =>
          version === runningReleaseVersion ? (
            <IconCheckboxContained className={styles.checkIcon} size={16} />
          ) : (
            ''
          ),
      },
      {
        header: 'Type',
        cellContent: ({ type }) => ReleaseTypeHumanReadable[type],
      },
      {
        header: 'Firmware',
        cellContent: ({ version }) => version,
      },
      {
        header: 'Description',
        cellContent: ({ description }) => description,
      },
      {
        header: 'Date',
        cellContent: ({ createdAt }) => (
          <span className={styles.date}>
            {format(parseISO(createdAt), locale.dateTimeFormat)}
          </span>
        ),
      },
      {
        header: 'Action',
        align: 'center',
        cellContent: ({ id, version, filePath }) =>
          firmwareIdToUpdate === String(id) ? (
            this.renderConfirmationButtons(version, filePath)
          ) : (
            <Button
              onClick={() => this.setState({ firmwareIdToUpdate: String(id) })}
              variant="text"
              className={styles.updateFirmwareButton}
            >
              <span>Update Firmware</span>
              <span className={styles.updateFirmwareIcon}>
                <MdExitToApp size={16} />
              </span>
            </Button>
          ),
      },
    ];

    const actions = (
      <>
        <Button variant="text" onClick={this.onUpdateFirmwareModalClose}>
          Close
        </Button>
      </>
    );

    return (
      <Modal onClose={onClose} actions={actions} disableEscapeClose>
        <ModalTitle>Firmware Update</ModalTitle>
        <ModalContent>
          <div className={styles.grayLineDivider} />
          <div className={styles.tableContainer}>
            <Table
              data={data}
              columns={columns}
              highlightRowIndexes={highlightIndexes}
              highlightAlternateRows
            />
          </div>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  locale: app.locale,
  firmwares: app.firmwares,
});

const mapDispatchToProps = (
  dispatch: any,
  { firmwareGatewayModel }: OwnProps
) => ({
  getFirmwares: () =>
    dispatch(getFirmwares({ gatewayModels: [firmwareGatewayModel] })),
  updateFirmware: (
    device: Gateway | EnergyPro | ControlBoard | FirmwareDeviceIdVendor,
    filePath: string,
    version: string
  ) => {
    const { id, vendor } = device;
    dispatch(updateFirmware({ vendor, id: id.toString(), version, filePath }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateFirmwareModal);
