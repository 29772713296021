import {
  Card,
  Loader,
  MediumLongSkeletonCell,
  MenuDropdown,
  MenuDropdownItem,
  ShortenedSpan,
  Table,
  TableGroupHeader,
} from '@energybox/react-ui-library/dist/components';
import OrgControlsCardHeader from '../OrgControlsCardHeader';
import styles from './LightingEquipmentData.module.css';
import { Link } from 'react-router-dom';
import {
  ControlsType,
  Equipment,
  OpacityIndex,
  SitesById,
} from '@energybox/react-ui-library/dist/types';
import {
  DeleteIcon,
  Scheduler as SchedulerIcon,
} from '@energybox/react-ui-library/dist/icons';
import {
  isDefined,
  global,
  checkCommonPlural,
  hasKeys,
} from '@energybox/react-ui-library/dist/utils';
import { getTimetablesRoute } from '../../../utils/timeTables';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import SiteControllerData from './SiteControllerData';
import LsPortValue from './LsPortValue';
import ContorlBoardLiveStatus from './ContorlBoardLiveStatus';
import LightSensorLiveValue from './LightSensorLiveValue';
import { CbToEquipmentIds } from '../../../reducers/aggregate_views';
import { useEffect, useState } from 'react';
import Button, {
  IconButton,
} from '@energybox/react-ui-library/dist/components/Button';
import {
  PostBatchSchedulerParams,
  PostSchedulerParams,
  displayFormErrors as displaySchedulerFormErrors,
  patchSchedulerBatch,
  patchSchedulerBatchAdd,
  batchReset as resetBatchEditScheduler,
  updateField as updateSchedulerField,
} from '../../../actions/schedulers';
import { ControlBoardsById } from '../../../reducers/control_boards';
import EditModeSchedulerTimetable from './EditModeSchedulerTimetable';
import EditModeSchedulerOffset from './EditModeSchedulerOffset';
import EditModeTimetable from './EditModeTimeTable';
import EditModeOffset from './EditModeOffset';
import EditModeSiteController from './EditModeSiteController';
import EditModeDelay from './EditModeDelay';
import EditModeHysteresis from './EditModeHysteresis';
import EditModeThershold from './EditModeThreshold';
import { MdMoreVert } from 'react-icons/md';
import { CardActions } from '@material-ui/core';
import ControlBoardLiveMode from './ControlBoardLiveMode';
import { EditableFields } from '../../../reducers/schedulers';
import EditControlModeDropdown from '../EditControlModeDropdown/EditControlModeDropdown';
import DeleteControlsModuleModel from '../DeleteControlsModuleModel';
import { showDeleteControlModal } from '../../../actions/controls';
import SchedulerTimetableInfoTooltip from '../../../components/SchedulerTimetableInfoTooltip';
import BiasInfoTooltip from '../../../components/BiasInfoTooltip';
import { useCalculateLast7DayAvgAstronomicalLuxValues } from '../../../hooks/useSiteAstroClock';
import ThresholdInfoTooltip from '../../../components/ThresholdInfoTooltip/ThresholdInfoTooltip';
import DelayInfoTooltip from '../../../components/DelayInfoTooltip/DelayInfoTooltip';
import HysteresisInfoTooltip from '../../../components/HysteresisInfoTooltip/HysteresisInfoTooltip';
import TimetableVisualization from '../TimetableVisualization/TimetableVisualization';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../../../mixpanelEvents';

type props = {
  siteId: string;
  equipments: Equipment[];
  networkGroupIds: number[];
  lightingTypes: Object;
  sitesById: SitesById;
  controlBoardIds: CbToEquipmentIds[];
  orgId: number | undefined;
  advancedView: boolean;
};

export type onChange = (
  id: string,
  field: string | string[],
  value: any
) => void;

const LightingEquipmentData: React.FC<props> = ({
  siteId,
  equipments,
  networkGroupIds,
  lightingTypes,
  sitesById,
  controlBoardIds,
  orgId,
  advancedView,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [schedulerId, setSchedulerId] = useState(0);
  const [equipmentId, setEquipmentId] = useState(0);
  const [prevEquipments, setPrevEquipments] = useState(equipments);
  const [
    openedTimetableVisualizations,
    setOpenedTimetableVisualizations,
  ] = useState(new Array<Array<JSX.Element | undefined>>(equipments.length));

  // Close all timetable visualizations when swapping views
  useEffect(() => {
    setOpenedTimetableVisualizations(
      new Array<Array<JSX.Element>>(equipments.length)
    );
  }, [advancedView]);

  const weatherData = useSelector(({ weather }: ApplicationState) => {
    return weather;
  });
  const controlBoardsById = useSelector<ApplicationState, ControlBoardsById>(
    ({ controlBoards }) => {
      return controlBoards.controlBoardsById;
    }
  );
  const showingDeleteControlModal = useSelector(
    ({ controls }: ApplicationState) => {
      return controls.showDeleteControlModal;
    }
  );
  const equipmentTypeTitle = id => {
    return lightingTypes?.[id]?.title;
  };
  const getRelayControlBoardId = equipmentId => {
    for (const { equipmentIds, controlBoardId } of controlBoardIds) {
      if (equipmentIds.includes(equipmentId)) {
        return controlBoardId;
      }
    }
  };

  const onChange = (id, field, value) => {
    dispatch(updateSchedulerField(id, field, value));
  };

  const combineSchedulars = useSelector(({ schedulers }: ApplicationState) => {
    return schedulers.editById;
  });

  const getSchedulerEquipmentId = id => {
    if (/^[A-Za-z]/.test(id)) {
      return Number(id.slice(3));
    } else {
      const data = equipments.find(
        equipment => equipment.activeControl?.id === Number(id)
      );
      return data?.id;
    }
  };

  const onDelete = id => {
    const equipmentId = getSchedulerEquipmentId(id)!;
    setEquipmentId(equipmentId);
    setSchedulerId(id);
    if (equipmentId > 0 && id > 0)
      dispatch(showDeleteControlModal(ControlsType.SCHEDULER));
  };

  const handleSchedulerButtonClick = (data, index) => {
    const scheduler = data.activeControl;
    let openedVisualizationsCopy = [...openedTimetableVisualizations];
    [...Array(openedTimetableVisualizations.length).keys()].forEach(i => {
      if (i === index) {
        if (
          openedVisualizationsCopy[i] === undefined ||
          openedVisualizationsCopy[i].length === 0
        ) {
          openedVisualizationsCopy[i] = [
            <TimetableVisualization
              scheduler={scheduler}
              numberOfColumns={columns.length}
            />,
          ]
            .concat(
              scheduler.timetable?.specials?.map((_, index: number) => {
                return (
                  <TimetableVisualization
                    scheduler={scheduler}
                    specialIndex={index}
                    numberOfColumns={columns.length}
                  />
                );
              })
            )
            .concat(
              scheduler.lightSensorSettings?.timetable?.specials?.map(
                (_, index: number) => {
                  return (
                    <TimetableVisualization
                      scheduler={scheduler}
                      lightSensorSettingsSpecialIndex={index}
                      numberOfColumns={columns.length}
                    />
                  );
                }
              )
            );
        } else {
          openedVisualizationsCopy[i] = [];
        }
      }
    });
    setOpenedTimetableVisualizations(openedVisualizationsCopy);
  };

  const {
    lightReadingsLast7DaysAvg: astroEventLuxAverages,
  } = useCalculateLast7DayAvgAstronomicalLuxValues(siteId);

  const getSchedulerTimetable = (activeControl, orgUnitId) => {
    return (
      <div>
        {activeControl !== null ? (
          isDefined(activeControl?.timetableId) ? (
            <Link
              to={`${getTimetablesRoute({
                orgUnitId: orgUnitId,
                timetableId: activeControl.timetableId,
                isOrgLevelTimetable: orgUnitId !== siteId,
              })}`}
              target="_blank"
            >
              {activeControl?.timetable?.title}
            </Link>
          ) : (
            <div className={styles.noData}> {global.NOT_AVAILABLE} </div>
          )
        ) : (
          <div className={styles.pinkText}>No Schedule</div>
        )}
      </div>
    );
  };

  const getLightSensorTimetbale = (activeControl, orgUnitId) => {
    const lightSensor = activeControl?.lightSensorSettings;
    return (
      isDefined(activeControl?.id) && (
        <div>
          {isDefined(lightSensor) ? (
            <Link
              to={`${getTimetablesRoute({
                orgUnitId: orgUnitId,
                timetableId: lightSensor.timetableId,
                isOrgLevelTimetable: orgUnitId !== siteId,
              })}`}
              target="_blank"
            >
              {lightSensor?.timetable?.title}
            </Link>
          ) : (
            <div className={styles.noData}> {global.NOT_AVAILABLE}</div>
          )}
        </div>
      )
    );
  };

  const getOffsetValue = activeControl => {
    const lightSensor = activeControl?.lightSensorSettings;
    return (
      isDefined(activeControl?.id) &&
      (isDefined(lightSensor) ? (
        <div className={styles.deltaValue}>
          <span>{lightSensor.beginDelta}</span>
          <span>{lightSensor.endDelta}</span>
        </div>
      ) : (
        <div className={styles.noData}>{global.NOT_AVAILABLE}</div>
      ))
    );
  };

  const sortedEquipmets = equipments.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }

    return 0; // titles are equal
  });

  const columns = [
    {
      header: 'Equipment',
      width: '8%',
      cellContent: equipment => {
        return (
          <div className={styles.eqTitle}>
            <Link to={`/equipment/${equipment?.id}`} target="_blank">
              <ShortenedSpan
                content={equipment.title}
                maxStringLength={25}
                arrowDirection="right"
              />
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Equipment Type',
      width: '8%',
      cellContent: equipment => (
        <div>{equipmentTypeTitle(equipment.typeId)}</div>
      ),
    },
    {
      header: '',
      customHeader: (
        <SchedulerTimetableInfoTooltip type="Schedule" isOrgLevel={true} />
      ),
      width: '6%',
      cellContent: equipment => {
        const activeControl = equipment?.activeControl;
        const orgUnitId =
          equipment?.activeControl?.timetable?.organizationUnitId;
        return editMode ? (
          <EditModeSchedulerTimetable
            equipment={equipment}
            siteId={siteId}
            onChange={onChange}
          />
        ) : (
          getSchedulerTimetable(activeControl, orgUnitId)
        );
      },
    },
    ...(advancedView
      ? [
          {
            header: 'Offset',
            customHeader: <BiasInfoTooltip isOrgLevel={true} />,
            width: '5%',
            cellContent: equipment => {
              const activeControl = equipment?.activeControl;
              return editMode ? (
                <EditModeSchedulerOffset
                  activeControl={activeControl}
                  onChange={onChange}
                  equipmentId={equipment.id}
                />
              ) : (
                isDefined(activeControl?.timetableId) && (
                  <div className={styles.deltaValue}>
                    <span>{activeControl.beginDelta}</span>
                    <span>{activeControl.endDelta}</span>
                  </div>
                )
              );
            },
          },
        ]
      : []),
    {
      header: 'TimeTable',
      customHeader: (
        <SchedulerTimetableInfoTooltip type="Light Sensor" isOrgLevel={true} />
      ),
      width: '5%',
      cellContent: equipment => {
        const activeControl = equipment?.activeControl;
        const orgUnitId =
          equipment?.activeControl?.lightSensorSettings?.timetable
            ?.organizationUnitId;
        return editMode ? (
          <div className={styles.editSiteController}>
            <EditModeTimetable
              activeControl={equipment.activeControl}
              siteId={siteId}
              onChange={onChange}
              equipmentId={equipment.id}
            />
          </div>
        ) : (
          getLightSensorTimetbale(activeControl, orgUnitId)
        );
      },
    },
    ...(advancedView
      ? [
          {
            header: 'Offset',
            customHeader: <BiasInfoTooltip isOrgLevel={true} />,
            width: '5%',
            cellContent: equipment => {
              const activeControl = equipment?.activeControl;
              return editMode ? (
                <EditModeOffset
                  onChange={onChange}
                  activeControl={activeControl}
                  equipmentId={equipment.id}
                />
              ) : (
                getOffsetValue(activeControl)
              );
            },
          },
        ]
      : []),
    {
      header: 'Threshold',
      customHeader: (
        <ThresholdInfoTooltip
          astroEventLuxAverages={astroEventLuxAverages}
          isOrgLevel={true}
        />
      ),
      width: '5%',
      cellContent: equipment => {
        const activeControl = equipment?.activeControl;
        const lightSensor = equipment?.activeControl?.lightSensorSettings;
        return editMode ? (
          <EditModeThershold
            activeControl={activeControl}
            onChange={onChange}
            equipmentId={equipment.id}
          />
        ) : (
          isDefined(activeControl?.id) &&
            (isDefined(lightSensor) ? (
              <div>{lightSensor.threshold} Lux </div>
            ) : (
              <div className={styles.noData}> {global.NOT_AVAILABLE}</div>
            ))
        );
      },
    },
    ...(advancedView
      ? [
          {
            header: 'Hysteresis',
            customHeader: (
              <HysteresisInfoTooltip
                threshold={600}
                hysteresis={10}
                isOrgLevel={true}
              />
            ),
            width: '5%',
            cellContent: equipment => {
              const lightSensor = equipment?.activeControl?.lightSensorSettings;
              const activeControl = equipment?.activeControl;
              return editMode ? (
                <EditModeHysteresis
                  activeControl={activeControl}
                  onChange={onChange}
                  equipmentId={equipment.id}
                />
              ) : (
                isDefined(activeControl?.id) &&
                  (isDefined(lightSensor) ? (
                    <div>{lightSensor.hysteresis}% </div>
                  ) : (
                    <div className={styles.noData}> {global.NOT_AVAILABLE}</div>
                  ))
              );
            },
          },
          {
            header: 'Delay',
            customHeader: <DelayInfoTooltip isOrgLevel={true} />,
            width: '5%',
            cellContent: equipment => {
              const actionInterval =
                equipment.activeControl?.lightSensorSettings?.actionInterval;
              const lightSensor = equipment?.activeControl?.lightSensorSettings;
              const activeControl = equipment?.activeControl;
              return editMode ? (
                <div className={styles.delay}>
                  <EditModeDelay
                    activeControl={activeControl}
                    controlBoardsById={controlBoardsById}
                    onChange={onChange}
                    equipmentId={equipment.id}
                  />
                </div>
              ) : (
                isDefined(activeControl?.id) && (
                  <div>
                    {isDefined(lightSensor) ? (
                      checkCommonPlural('min', actionInterval)
                    ) : (
                      <div className={styles.noData}>
                        {global.NOT_AVAILABLE}
                      </div>
                    )}
                  </div>
                )
              );
            },
          },
        ]
      : []),
    {
      header: 'Site Controller',
      width: '5%',
      cellContent: equipment => {
        const controlBoardId =
          equipment.activeControl?.lightSensorSettings?.controlBoardId;
        const activeControl = equipment?.activeControl;
        return editMode ? (
          <div className={styles.delay}>
            <EditModeSiteController
              equipmentId={equipment.id}
              activeControl={activeControl}
              onChange={onChange}
              controlBoardsById={controlBoardsById}
              siteIdWithoutResourceId={siteId}
            />
          </div>
        ) : (
          isDefined(activeControl?.id) && (
            <SiteControllerData controlBoardId={controlBoardId} />
          )
        );
      },
    },
    ...(advancedView
      ? [
          {
            header: 'L.S.Port',
            width: editMode ? '1%' : '5%',
            cellContent: equipment => {
              const controlBoardId =
                equipment.activeControl?.lightSensorSettings?.controlBoardId;
              const activeControl = equipment?.activeControl;
              return (
                isDefined(activeControl?.id) &&
                (controlBoardId ? (
                  <LsPortValue controlBoardId={controlBoardId} />
                ) : (
                  <div className={styles.noData}> {global.NOT_AVAILABLE}</div>
                ))
              );
            },
          },
        ]
      : []),
    ...(!editMode
      ? [
          {
            header: 'Status',
            width: editMode ? '1%' : '5%',
            cellContent: equipment => {
              const lightSensorControlBoardId =
                equipment.activeControl?.lightSensorSettings?.controlBoardId;
              const relayControlBoardId = getRelayControlBoardId(equipment.id);
              return (
                !editMode && (
                  <ContorlBoardLiveStatus
                    lightSensorControlBoardId={lightSensorControlBoardId}
                    controlBoardId={relayControlBoardId!}
                  />
                )
              );
            },
          },
          {
            header: 'Light Sensor',
            width: editMode ? '1%' : '2%',
            cellContent: equipment => {
              const controlBoardId =
                equipment.activeControl?.lightSensorSettings?.controlBoardId;
              const relayControlBoardId = getRelayControlBoardId(equipment.id);
              return (
                !editMode &&
                isDefined(equipment?.activeControl) && (
                  <LightSensorLiveValue
                    controlBoardId={controlBoardId}
                    relayControlBoardId={relayControlBoardId}
                    equipmentId={equipment.id}
                    siteId={siteId}
                  />
                )
              );
            },
          },
        ]
      : []),
    {
      header: 'Control Mode',
      width: '5%',
      cellContent: equipment => {
        const controlBoardId = equipment?.activeControl?.id;
        return editMode ? (
          <EditControlModeDropdown
            equipment={equipment}
            onChange={onChange}
            isHvac={false}
          />
        ) : (
          isDefined(equipment?.activeControl) && (
            <ControlBoardLiveMode controlBoardId={controlBoardId} />
          )
        );
      },
    },
    {
      header: deleteMode ? '' : 'Schedule',
      width: editMode ? '1%' : '5%',
      cellContent: (equipment, index) => (
        <div className={styles.schedulerIcon}>
          {deleteMode ? (
            <IconButton
              icon={<DeleteIcon size={20} />}
              className={styles.iconContainer}
              onClick={() => onDelete(equipment.activeControl?.id)}
            />
          ) : (
            !editMode &&
            (equipment.activeControl?.id ? (
              <div onClick={() => handleSchedulerButtonClick(equipment, index)}>
                <SchedulerIcon size={30} />
              </div>
            ) : (
              <div className={styles.invalidIcon}>
                <SchedulerIcon size={30} />
              </div>
            ))
          )}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
  ];

  const onCancel = () => {
    dispatch(resetBatchEditScheduler());
    setEditMode(false);
    setDeleteMode(false);
  };

  const onUpdate = () => {
    if (editMode) {
      const newObject = {} as PostBatchSchedulerParams;
      const newAddObject = [] as PostSchedulerParams[];
      let hasErrors = false;
      for (const id in combineSchedulars) {
        const { fields, formErrors } = combineSchedulars[id];
        const equipmentId = getSchedulerEquipmentId(id)!;
        const {
          beginDelta,
          endDelta,
          timetableId,
          workingMode,
          lightSensorSettings,
        } = fields || ({} as EditableFields);

        const {
          beginDelta: lightBeginDelta,
          endDelta: lightEndDelta,
          timetableId: lightTimetableId,
          controlBoardId,
          threshold,
          hysteresis,
          actionInterval,
        } = lightSensorSettings || {};

        const beginDeltaValue = isDefined(beginDelta) ? beginDelta : '0';
        const endDeltaValue = isDefined(endDelta) ? endDelta : '0';
        const lightBeginDeltaValue = isDefined(lightBeginDelta)
          ? lightBeginDelta
          : '0';
        const lightEndDeltaValue = isDefined(lightEndDelta)
          ? lightEndDelta
          : '0';

        const hasAtLeastOneTimetableBeenSelected =
          isDefined(timetableId) || isDefined(lightTimetableId);

        const isNoScheduleTimetableOptionSelected =
          timetableId === 'null' || timetableId === null;

        const hasInvalidScheduleTimetable =
          (!isDefined(timetableId) && !isNoScheduleTimetableOptionSelected) ||
          !hasAtLeastOneTimetableBeenSelected;

        const isControlBoardSelected = controlBoardId !== -1;

        const hasInvalidLightTimetable =
          !hasAtLeastOneTimetableBeenSelected ||
          (isControlBoardSelected && !isDefined(lightTimetableId));
        if (isDefined(controlBoardId) && controlBoardId > 0) {
          if (
            hasKeys(formErrors) ||
            hasInvalidScheduleTimetable ||
            hasInvalidLightTimetable
          ) {
            hasErrors = true;
            dispatch(displaySchedulerFormErrors(id));
          } else {
            const lightSensorSettings = {
              beginDelta: Number(lightBeginDeltaValue),
              endDelta: Number(lightEndDeltaValue),
              timetableId:
                isDefined(lightTimetableId) && lightTimetableId > 0
                  ? lightTimetableId
                  : null,
              controlBoardId: controlBoardId!,
              threshold: Number(threshold),
              hysteresis: Number(hysteresis),
              actionInterval,
            };

            const newScheduler = {
              workingMode,
              beginDelta: Number(beginDeltaValue),
              endDelta: Number(endDeltaValue),
              timetableId: Number(timetableId) || null,
              equipmentId,
              lightSensorSettings,
            };

            if (/^[A-Za-z]/.test(id)) {
              newAddObject.push(newScheduler);
            } else {
              newObject[id] = newScheduler;
            }
          }
        } else {
          if (
            (formErrors &&
              Object.keys(formErrors).some(
                key => !key.includes('lightSensorSettings')
              )) ||
            isNoScheduleTimetableOptionSelected
          ) {
            hasErrors = true;
            dispatch(displaySchedulerFormErrors(id));
          } else {
            const newScheduler = {
              workingMode: workingMode,
              beginDelta: Number(beginDeltaValue),
              endDelta: Number(endDeltaValue),
              timetableId: timetableId,
              equipmentId,
              lightSensorSettings: null,
            };
            if (/^[A-Za-z]/.test(id)) {
              newAddObject.push(newScheduler);
            } else {
              newObject[id] = newScheduler;
            }
          }
        }
      }
      if (!hasErrors) {
        const hasNewObject = Object.keys(newObject).length > 0;
        const hasNewAddObject = newAddObject.length > 0;
        if (hasNewObject) {
          Object.entries(newObject).forEach(([_, scheduler]) => {
            const currentMode = prevEquipments.find(
              equipment => equipment.id === scheduler.equipmentId
            )?.activeControl?.workingMode;
            // Mixpanel Event
            mixpanel.track(mixpanelEvents.CONTROL_MODE_CHANGE, {
              controlType: 'SCHEDULER',
              from: currentMode,
              to: scheduler.workingMode,
              source: 'connectLightingBatch',
              equipmentId: scheduler.equipmentId,
            });
          });
          dispatch(patchSchedulerBatch(newObject, orgId!));
        }
        if (hasNewAddObject)
          dispatch(patchSchedulerBatchAdd(newAddObject, orgId!));
        if (hasNewObject || hasNewAddObject) setLoading(true);
      }
    } else if (deleteMode) {
      setLoading(true);
    }
  };

  useEffect(() => {
    if (equipments !== prevEquipments) {
      setPrevEquipments(equipments);
    }
  }, [equipments, prevEquipments]);

  useEffect(() => {
    if (loading) {
      const timeoutId = setTimeout(() => {
        if (editMode) dispatch(resetBatchEditScheduler());
        setEditMode(false);
        setDeleteMode(false);
        setLoading(false); // Refresh the page
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [loading]);

  const groupHeaders: TableGroupHeader[] = [
    {
      header: '',
      colSpan: 2,
    },
    {
      header: 'Schedule',
      colSpan: advancedView ? 2 : 1,
      className: styles.groupHeader,
    },
    {
      header: 'Light Sensor Settings',
      colSpan: advancedView ? 7 : 3,
      className: styles.groupHeader,
    },
    {
      header: 'Live View',
      colSpan: editMode ? 1 : 3,
    },
    {
      header: '',
      colSpan: 1,
    },
  ];
  return (
    <>
      <div className={styles.cardheader}>
        <div>
          <OrgControlsCardHeader
            site={sitesById[siteId]}
            networkGroupIds={networkGroupIds}
            weatherData={weatherData}
            orgId={orgId}
          />
        </div>
        <div className={styles.menuDropdown}>
          <MenuDropdown icon={<MdMoreVert size="28" />}>
            <MenuDropdownItem
              onSelect={() => {
                setEditMode(true);
                setDeleteMode(false);
              }}
            >
              Add/Edit
            </MenuDropdownItem>
            <MenuDropdownItem
              isRed
              onSelect={() => {
                setDeleteMode(true);
                setEditMode(false);
              }}
            >
              Delete
            </MenuDropdownItem>
          </MenuDropdown>
        </div>
      </div>
      <Card className={styles.customCardStyles}>
        <Table
          columns={columns}
          data={sortedEquipmets}
          dataIsLoading={false}
          highlightAlternateRows={true}
          className={styles.bodyBorder}
          rowDropdownContent={openedTimetableVisualizations}
          groupHeaders={groupHeaders}
        />
        {editMode && (
          <CardActions className={styles.buttons}>
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onUpdate} disabled={false}>
              {loading ? (
                <Loader size={15} className={styles.loader} />
              ) : (
                'Update'
              )}
            </Button>
          </CardActions>
        )}
        {deleteMode && (
          <CardActions className={styles.buttons}>
            <Button variant="solid" onClick={() => setDeleteMode(false)}>
              Done
            </Button>
          </CardActions>
        )}
      </Card>
      {showingDeleteControlModal && schedulerId > 0 && equipmentId > 0 && (
        <DeleteControlsModuleModel
          schedulerId={schedulerId}
          equipmentId={equipmentId}
          orgId={orgId}
        />
      )}
    </>
  );
};

export default LightingEquipmentData;
