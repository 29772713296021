import { Site } from '@energybox/react-ui-library/dist/types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import HvacSopTable from '../../components/HvacSopTable/HvacSopTable';
import NewOrEditHvacSopModal from '../Sop/NewOrEditHvacSopModal';

interface Props {
  siteId: string;
  site: Site;
}

const ShowSitePageHvacSops: React.FC<Props> = ({ siteId, site }) => {
  const [equipmentAffectedCount, setEquipmentAffectedCount] = useState(0);

  const isModalVisible = useSelector<ApplicationState, boolean>(({ sops }) => {
    return sops.showNewOrEditHvacSopModal;
  });

  return (
    <>
      {isModalVisible && (
        <NewOrEditHvacSopModal
          organizationUnitId={siteId}
          equipmentAffectedCount={equipmentAffectedCount}
        />
      )}
      <HvacSopTable
        site={site}
        equipmentAffectedCount={equipmentAffectedCount}
        setEquipmentAffectedCount={setEquipmentAffectedCount}
      />
    </>
  );
};

export default ShowSitePageHvacSops;
