import {
  Button,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import { ArrowRight, RunConfig } from '@energybox/react-ui-library/dist/icons';
import { DistributionPanel } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions,
  getDistributionPanelsBySiteId,
  primeForDelete,
  showDeleteDistributionPanelModal,
} from '../../../actions/distribution_panel';
import {
  getEnergyProsBySiteId,
  primeForDelete as primeEProForDelete,
} from '../../../actions/energy_pros';
import DistributionPanelOnlineState from '../../../components/DistributionPanelOnlineState';
import history from '../../../history';
import { ApplicationState } from '../../../reducers';
import { EnergyProIdsByDistributionPanelId } from '../../../reducers/energy_pros';
import { Routes } from '../../../routes';
import { RunTestButtonText } from '../../../types/global';
import { hasDaResults, checkCommonPlural } from '../../../util';
import FeatureFlag from '../../FeatureFlag';
import styles from './DistributionPanelCardList.module.css';
import { getSite } from '../../../actions/sites';
import { EB_INSTALLER_WHITELIST } from '../../../featureFlagSettings';
import { MdMoreHoriz } from 'react-icons/md';

interface OwnProps {
  siteId: string;
  siteTitle: string;
}

interface Props extends OwnProps {
  load: () => void;
  primeForDelete: typeof primeForDelete;
  primeEProForDelete: typeof primeEProForDelete;
  distributionPanels: DistributionPanel[];
  showDeleteDistributionPanelModal: typeof showDeleteDistributionPanelModal;
  energyProsByPanelId: EnergyProIdsByDistributionPanelId;
  getDistributionPanelsIsLoading?: boolean;
  testResults: Object;
}

class DistributionPanelCardList extends React.Component<Props> {
  componentDidMount() {
    this.props.load();
  }

  header: CardListHeader[] = [
    {
      width: '3',
      content: 'Panel Name',
    },
    {
      width: '2',
      content: 'Site Name',
    },
    {
      width: '2',
      content: 'Installed in',
    },
    {
      width: '2',
      content: 'Energy Pro',
    },
    {
      width: '2',
      content: 'Connectivity',
    },
    {
      width: '1',
      align: 'right',
      content: '',
    },
  ];

  render() {
    const {
      siteTitle,
      distributionPanels,
      showDeleteDistributionPanelModal,
      primeForDelete,
      primeEProForDelete,
      energyProsByPanelId,
      getDistributionPanelsIsLoading,
      siteId,
      testResults,
    } = this.props;

    const daResultsExist: boolean = hasDaResults(testResults);

    const linkToDistributionPanel = id => {
      if (daResultsExist) {
        return '';
      } else {
        return history.push(`${Routes.DISTRIBUTION_PANELS}/${id}`);
      }
    };

    const data: CardListRowData[] = distributionPanels.map(panel => ({
      key: panel.id.toString(),
      content: (
        <>
          <Cell width="3">
            <span
              className={styles.panelTitle}
              onClick={() => linkToDistributionPanel(panel.id)}
            >
              {panel.title}
            </span>
          </Cell>
          <Cell width="2">{siteTitle}</Cell>
          <Cell width="2">{panel.space.title}</Cell>
          <Cell width="2">
            {energyProsByPanelId[panel.id]
              ? energyProsByPanelId[panel.id].length
              : global.NOT_AVAILABLE}
          </Cell>
          <Cell width="2">
            {energyProsByPanelId[panel.id] &&
            energyProsByPanelId[panel.id].length > 0 ? (
              <DistributionPanelOnlineState
                energyProIds={energyProsByPanelId[panel.id]}
              />
            ) : (
              global.NOT_AVAILABLE
            )}
          </Cell>
          <Cell width="1" cellAlign="right">
            <MenuDropdown
              disabled={daResultsExist ? true : false}
              icon={
                <div style={{ marginTop: -5 }}>
                  <MdMoreHoriz size={32} />
                </div>
              }
            >
              <MenuDropdownItem
                onSelect={() =>
                  history.push(`${Routes.DISTRIBUTION_PANELS}/${panel.id}`)
                }
              >
                Panel Details
              </MenuDropdownItem>

              <MenuDropdownItem
                isRed
                onSelect={() => {
                  primeForDelete(panel.id);
                  primeEProForDelete(energyProsByPanelId[panel.id]?.[0] || -1);
                  showDeleteDistributionPanelModal();
                }}
              >
                Delete Panel
              </MenuDropdownItem>
            </MenuDropdown>
          </Cell>
        </>
      ),
    }));

    return (
      <>
        <div className={styles.runConfigurationTestContainer}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 200px',
            }}
          >
            <h3>
              {!getDistributionPanelsIsLoading &&
                checkCommonPlural(
                  'Distribution Panel',
                  distributionPanels.length
                )}
            </h3>
          </div>
          <div>
            <FeatureFlag orgWhiteList={EB_INSTALLER_WHITELIST}>
              <Button
                variant="text"
                className={styles.runConfigurationTest}
                onClick={() =>
                  history.push(`${Routes.RUN_CONFIGURATION_TEST}/${siteId}`)
                }
              >
                <RunConfig width="23" height="20" />
                <span className={styles.buttonText}>
                  {RunTestButtonText.RUN_CONFIGURATION_TEST}
                </span>
                <ArrowRight className={styles.arrow} size={14} />
              </Button>
            </FeatureFlag>
          </div>
        </div>
        <CardList header={this.header} data={data} />
      </>
    );
  }
}

const mapStateToProps = (
  { distributionPanels, energyPros, sites }: ApplicationState,
  { siteId }: OwnProps
) => ({
  distributionPanels: pathOr(
    [],
    [siteId],
    distributionPanels.distributionPanelsBySiteId
  ),
  energyProsByPanelId: energyPros.energyProIdsByDistributionPanelId,
  getDistributionPanelsIsLoading:
    distributionPanels.loadingStatusByAction[
      Actions.GET_DISTRIBUTION_PANELS_BY_SITE_ID_LOADING
    ],
  testResults: sites.sitesById[siteId]?.installerTestResults,
});

const mapDispatchToProps = (dispatch: any, { siteId }: OwnProps) => ({
  load: () => {
    dispatch(getDistributionPanelsBySiteId(siteId));
    dispatch(getEnergyProsBySiteId(siteId));
    dispatch(getSite(siteId));
  },
  showDeleteDistributionPanelModal: () =>
    dispatch(showDeleteDistributionPanelModal()),
  primeForDelete: (panelId: number) => dispatch(primeForDelete(panelId)),
  primeEProForDelete: (eproId: string | number) =>
    dispatch(primeEProForDelete(eproId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionPanelCardList);
