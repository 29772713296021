import {
  Label,
  MenuDropdown,
  MenuDropdownItem,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import { useSubscribeToControlBoard } from '@energybox/react-ui-library/dist/hooks/useStreamApi';
import { Scheduler as SchedulerIcon } from '@energybox/react-ui-library/dist/icons';
import {
  ControlBoard,
  GatewayStates,
  LightSensorPortText,
  Scheduler,
  SchedulerLabel,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import { useSetIsLocalOverrideActive } from '../../hooks/controls/util';
import { useLightLiveReadings } from '../../hooks/streamApi/deviceReadings';
import {
  useControlBoardById,
  useSelectControlBoardByEquipmentId,
} from '../../hooks/useControlBoard';
import { ApplicationState } from '../../reducers';
import { checkCommonPlural } from '../../util';
import { renderLocalOverrideText } from '../../utils/controls';
import { formatDecimalValue } from '../../utils/numbers';
import { getTimetablesRoute } from '../../utils/timeTables';
import DeviceOnlineState, {
  DisplayType,
} from '../DeviceStatus/DeviceOnlineState';
import { calculateNumberOfLightSensorReadings } from '../Selects/SelectLightSensorDelay';
import styles from './GenericControlsCard/GenericControlsCard.module.css';

type Props = {
  siteId: number;
  scheduler: Scheduler;
  showEditControlModal: () => void;
  showDeleteControlModal: () => void;
  setIsLocalOverrideActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const SchedulerContent = ({
  siteId,
  scheduler,
  showEditControlModal,
  showDeleteControlModal,
  setIsLocalOverrideActive,
}: Props) => {
  const equipmentId = scheduler.equipmentId;
  const isControlInCalibrationMode =
    scheduler.workingMode === WorkingMode.CALIBRATION;

  const controlBoardForLightSensor = useControlBoardById(
    scheduler.lightSensorSettings?.controlBoardId
  );

  //this works b/c we fetch actuatorsByEquipmentId in a parent component in this page
  const controlBoardForBaseScheduler = useSelectControlBoardByEquipmentId(
    equipmentId
  );

  const schedulerTimetable = scheduler.timetable;
  const lightSensorTimetable = scheduler.lightSensorSettings?.timetable;
  const lightSensorPort = parseLightSensorPort(controlBoardForLightSensor);

  const hasLightSetting = isDefined(scheduler.lightSensorSettings);
  const lightLiveReading = useLightLiveReadings(
    scheduler.lightSensorSettings?.controlBoardId
  );
  const lightSensorPublishingInterval =
    controlBoardForLightSensor?.lightSensorPublishingInterval;
  const lightSensorActionInterval =
    scheduler.lightSensorSettings?.actionInterval;

  const numberOfLightSensorReadings =
    isDefined(lightSensorActionInterval) && !!lightSensorPublishingInterval
      ? calculateNumberOfLightSensorReadings(
          Number(lightSensorActionInterval),
          Number(lightSensorPublishingInterval)
        )
      : undefined;

  const dispatch = useDispatch();

  const controlBoardToSubscribeTo =
    controlBoardForLightSensor || controlBoardForBaseScheduler;

  const subscribedControlBoardById = useSelector(
    ({ subscribedControlBoards }: ApplicationState) => {
      return subscribedControlBoards.byId[controlBoardToSubscribeTo?.id || -1];
    }
  );

  const subscribedControlBoard = useSubscribeToControlBoard(
    controlBoardToSubscribeTo,
    () => subscribedControlBoardById,
    dispatch,
    subscribeToDeviceStatus,
    unsubscribeFromDeviceStatus
  );

  const isLocalOverrideActive = useMemo(() => {
    return subscribedControlBoard?.status?.gatewayStates?.includes(
      GatewayStates.CONTROL_OVERRIDE
    );
  }, [subscribedControlBoard]);

  useSetIsLocalOverrideActive(isLocalOverrideActive, setIsLocalOverrideActive);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>Controls: Scheduler</div>
        <div>
          <MenuDropdown>
            <MenuDropdownItem onSelect={showEditControlModal}>
              Edit Scheduler
            </MenuDropdownItem>
            <MenuDropdownItem isRed onSelect={showDeleteControlModal}>
              Delete Scheduler
            </MenuDropdownItem>
          </MenuDropdown>
        </div>
      </div>

      <hr className={styles.divider} />

      <div className={styles.contentContainer}>
        <div className={styles.icon}>
          <SchedulerIcon size={80} />
        </div>

        <div className={styles.columnContainer}>
          <div
            className={classNames(
              styles.column,
              isControlInCalibrationMode ? styles.greyText : ''
            )}
          >
            <Label>
              <span className={styles.boldText}>Settings Overview</span>
            </Label>
            <div />

            <Label>{SchedulerLabel.TIMETABLE}</Label>
            <div className={styles.value}>
              {isDefined(schedulerTimetable) ? (
                <Link
                  to={`${getTimetablesRoute({
                    orgUnitId: schedulerTimetable.organizationUnitId,
                    timetableId: schedulerTimetable.id,
                    isOrgLevelTimetable:
                      schedulerTimetable.organizationUnitId !== siteId,
                  })}`}
                >
                  {schedulerTimetable.title}
                </Link>
              ) : (
                global.NOT_AVAILABLE
              )}
            </div>

            <Label>{SchedulerLabel.BIAS_START}</Label>
            <div className={styles.value}>
              {isDefined(scheduler.timetableId)
                ? scheduler.beginDelta
                : global.NOT_AVAILABLE}
            </div>

            <Label>{SchedulerLabel.BIAS_END}</Label>
            <div className={styles.value}>
              {isDefined(scheduler.timetableId)
                ? scheduler.endDelta
                : global.NOT_AVAILABLE}
            </div>

            {isDefined(scheduler.lightSensorSettings) && (
              <>
                <Label>
                  <span className={styles.boldText}>Light Sensor</span>
                </Label>
                <div />

                <Label>{SchedulerLabel.LIGHT_SENSOR_SOURCE}</Label>
                <div className={styles.value}>
                  <Link
                    to={`/devices/gateways/${controlBoardForLightSensor?.id}`}
                  >
                    {controlBoardForLightSensor?.title}
                  </Link>
                </div>

                <Label>{SchedulerLabel.LIGHT_SENSOR_PORT}</Label>
                <div className={styles.value}>{lightSensorPort}</div>

                <Label>{SchedulerLabel.THRESHOLD}</Label>
                <div className={styles.value}>
                  {scheduler.lightSensorSettings?.threshold} lux
                </div>

                <Label>{SchedulerLabel.HYSTERESIS}</Label>
                <div className={styles.value}>
                  {scheduler.lightSensorSettings.hysteresis} %
                </div>

                <Label>{SchedulerLabel.DELAY}</Label>
                <div className={styles.value}>
                  {isDefined(lightSensorActionInterval)
                    ? checkCommonPlural('minute', lightSensorActionInterval)
                    : global.NOT_AVAILABLE}
                  {isDefined(numberOfLightSensorReadings) && (
                    <div
                      className={
                        isControlInCalibrationMode
                          ? styles.grayText
                          : styles.subText
                      }
                    >
                      (based on{' '}
                      {checkCommonPlural(
                        'sensor reading',
                        numberOfLightSensorReadings
                      )}
                      )
                    </div>
                  )}
                </div>

                <Label>{SchedulerLabel.TIMETABLE}</Label>
                <div className={styles.value}>
                  {isDefined(lightSensorTimetable) ? (
                    <Link
                      to={`${getTimetablesRoute({
                        orgUnitId: lightSensorTimetable.organizationUnitId,
                        timetableId: lightSensorTimetable.id,
                        isOrgLevelTimetable:
                          lightSensorTimetable.organizationUnitId !== siteId,
                      })}`}
                    >
                      {lightSensorTimetable.title}
                    </Link>
                  ) : (
                    global.NOT_AVAILABLE
                  )}
                </div>

                <Label>{SchedulerLabel.BIAS_START}</Label>
                <div className={styles.value}>
                  {isDefined(lightSensorTimetable)
                    ? scheduler.lightSensorSettings.beginDelta
                    : global.NOT_AVAILABLE}
                </div>

                <Label>{SchedulerLabel.BIAS_END}</Label>
                <div className={styles.value}>
                  {isDefined(lightSensorTimetable)
                    ? scheduler.lightSensorSettings.endDelta
                    : global.NOT_AVAILABLE}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.columnContainer}>
          <div className={styles.column}>
            <Label>
              <span className={styles.boldText}>Live Status</span>
            </Label>
            <div />

            <Label>{SchedulerLabel.ONLINE_STATUS}</Label>
            <div className={styles.value}>
              {isDefined(controlBoardToSubscribeTo) ? (
                <DeviceOnlineState
                  displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
                  devices={[
                    {
                      id: controlBoardToSubscribeTo.id,
                      uuid: controlBoardToSubscribeTo.uuid,
                      vendor: controlBoardToSubscribeTo.vendor,
                    },
                  ]}
                />
              ) : (
                global.NOT_AVAILABLE
              )}
            </div>

            <div className={styles.dividerLine} />
            <span className={styles.streamTimestampText}>
              Updated {<TimeDistance timestamp={lightLiveReading?.timestamp} />}
            </span>

            {hasLightSetting && (
              <>
                <Label>{SchedulerLabel.LIGHT_SENSOR_READING}</Label>
                <div className={styles.value}>
                  {formatDecimalValue(lightLiveReading?.lux, 0, 'lux')}
                </div>
              </>
            )}

            <Label>{SchedulerLabel.LOCAL_OVERRIDE}</Label>
            <div className={styles.value}>
              {renderLocalOverrideText(isLocalOverrideActive)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const parseLightSensorPort = (
  controlBoardForLightSensor: ControlBoard | undefined
): string => {
  return isDefined(controlBoardForLightSensor) &&
    isDefined(controlBoardForLightSensor.actuators?.[0])
    ? LightSensorPortText[controlBoardForLightSensor.lightSensorPort]
    : global.NOT_AVAILABLE;
};

export default SchedulerContent;
