import React from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import styles from './BiasInfoTooltip.module.css';

type Props = {
  hasError?: boolean;
  isOrgLevel?: boolean;
};

const BiasInfoTooltip: React.FC<Props> = ({ hasError, isOrgLevel }) => {
  return (
    <Tooltip
      underline={isOrgLevel ? true : false}
      arrowDirection={isOrgLevel ? 'topLeft' : 'right'}
      tooltipTextClassName={isOrgLevel ? styles.text : ''}
      extraClassNames={[styles.tooltip]}
      content={
        <div className={styles.tooltipText}>
          <div className={styles.bold}>Offset</div>
          <div className={styles.paddingTop}>
            An offset less than 0 moves events to an earlier point in time.
          </div>
          <div>An offset above 0 moves events to a later point in time.</div>

          <div className={styles.paddingTop}>
            <div>Example: If the lights should light up 5 minutes earlier</div>
            <div>and should stay on for 10 minutes longer,</div>
            <div>
              then Offset Start would be -5 min and Offset End would be 10 min.
            </div>
          </div>

          <div className={styles.paddingTop}>
            The maximum offset is &plusmn;120min.
          </div>
        </div>
      }
    >
      {isOrgLevel ? (
        <div className={styles.title}> Offset </div>
      ) : (
        <IoIosInformationCircleOutline
          color={hasError ? 'var(--pink-base)' : 'black'}
          size={20}
        />
      )}
    </Tooltip>
  );
};

export default BiasInfoTooltip;
