import { Table } from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  DeviceConnectivityText,
  Equipment,
  Gateway,
  MapDeviceConnectivity,
  Sensor,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { GiPlug } from 'react-icons/gi';
import { IoIosWifi } from 'react-icons/io';
import { EquipmentGroupsById } from '../../reducers/app';
import styles from './SpaceDetailResourceList.module.css';

type Resource = Equipment | Sensor | Gateway;

interface Props {
  list: Equipment[] | Sensor[] | (Gateway | ControlBoard)[];
  type: 'gateways' | 'sensors' | 'equipment';
  equipmentGroupsById?: EquipmentGroupsById;
}
// TODO: Map up sensor icons to sensor type;
const SpaceDetailResourceList = ({
  list,
  type,
  equipmentGroupsById,
}: Props) => {
  const getIcon = (resource: Resource) => {
    switch (resource['resourceType']) {
      case ResourceType.EQUIPMENT:
        return <GiPlug size="15" style={{ marginRight: '.75rem' }} />;
      case ResourceType.SENSOR:
        return '';
      case ResourceType.GATEWAY:
        return <IoIosWifi size="15" style={{ marginRight: '.75rem' }} />;
      default:
        return;
    }
  };

  const secondColumn = (resource: any) => {
    switch (type) {
      case 'equipment':
        return equipmentGroupsById && equipmentGroupsById[resource.groupId]
          ? equipmentGroupsById[resource.groupId].title
          : global.NOT_AVAILABLE;
      case 'sensors':
        return resource['uuid'];
      case 'gateways':
        return resource['uuid'];
      default:
        return;
    }
  };

  const thirdColumn = (resource: any) => {
    switch (type) {
      case 'equipment':
        return resource.type ? resource.type.title : global.NOT_AVAILABLE;
      case 'sensors':
        return connectivityValue(resource);
      case 'gateways':
        return connectivityValue(resource);
      default:
        return;
    }
  };

  const sensorConnectivity = ({ sensorStatus }: Sensor) => {
    return sensorStatus
      ? colorState(MapDeviceConnectivity[sensorStatus.state])
      : global.NOT_AVAILABLE;
  };

  const gatewayConnectivity = ({ gatewayOnlineStatus }: Gateway) => {
    return gatewayOnlineStatus
      ? colorState(
          MapDeviceConnectivity[gatewayOnlineStatus.onlineState.toString()]
        )
      : global.NOT_AVAILABLE;
  };

  const colorState = (state: DeviceConnectivityText) => {
    switch (state) {
      case DeviceConnectivityText.ONLINE:
        return (
          <span style={{ color: 'var(--turquoise-baseMinus25)' }}>{state}</span>
        );
      case DeviceConnectivityText.OFFLINE:
        return <span style={{ color: 'var(--pink-base)' }}>{state}</span>;
      default:
        return '';
    }
  };

  const connectivityValue = resource => {
    switch (type) {
      case 'sensors':
        return sensorConnectivity(resource);
      case 'gateways':
        return gatewayConnectivity(resource);
      default:
        return '';
    }
  };
  const headers = () => {
    switch (type) {
      case 'sensors':
        return ['Sensor Name', 'UUID', 'Status'];
      case 'gateways':
        return ['Gateway Name', 'UUID', 'Status'];
      case 'equipment':
        return ['Equipment Name', 'Equipment Group', 'Equipment Type'];
      default:
        return [];
    }
  };
  const headerArr = headers();
  const columns = [
    {
      header: headerArr[0],
      cellContent: (resource: Resource) => (
        <div style={{ color: 'var(--turquoise-baseMinus25)' }}>
          {getIcon(resource)}
          {resource.title}
        </div>
      ),
    },
    {
      header: headerArr[1],
      cellContent: (resource: Resource) => <div>{secondColumn(resource)}</div>,
    },
    {
      header: headerArr[2],
      cellContent: (resource: Resource) => <div>{thirdColumn(resource)}</div>,
    },
  ];
  return (
    <div style={styles}>
      <Table columns={columns} data={list} />
    </div>
  );
};

export default SpaceDetailResourceList;
