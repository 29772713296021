import React from 'react';

interface Props {
  children: React.ReactElement;
  firmwareVersion: string;
  supportedFirmwareVersion: string;
  operator: '>' | '<' | '=' | '<=' | '>=';
}

// Supported Version will support logical operators > < and if these operators are not provided
// static version should be provided and an absolute check will be made.

const FirmwareFlag = ({
  children,
  firmwareVersion,
  supportedFirmwareVersion,
  operator,
}: Props) => {
  const versionRegex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;

  if (
    !versionRegex.test(firmwareVersion) ||
    !versionRegex.test(supportedFirmwareVersion)
  )
    return null;
  let canAccess;

  switch (operator) {
    case '>': {
      canAccess = firmwareVersion > supportedFirmwareVersion;
      break;
    }
    case '<': {
      canAccess = firmwareVersion < supportedFirmwareVersion;
      break;
    }
    case '=': {
      canAccess = firmwareVersion === supportedFirmwareVersion;
      break;
    }
    case '>=': {
      canAccess = firmwareVersion >= supportedFirmwareVersion;
      break;
    }
    case '<=': {
      canAccess = firmwareVersion <= supportedFirmwareVersion;
      break;
    }
    default: {
      canAccess = true;
      break;
    }
  }

  return canAccess ? children : null;
};

export default FirmwareFlag;
