import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { addUserPosition, getUserPositions } from '../../actions/users';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  onSelect: (value: string) => void;
  value: string | null;
  disabled?: boolean;
}

interface Props extends OwnProps {
  getUserPositions: typeof getUserPositions;
  addUserPosition: typeof addUserPosition;
  userPositions?: string[];
}

interface State {
  search: string;
}

class UserPositionSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { search: '' };
  }

  componentDidMount() {
    const { userPositions, getUserPositions } = this.props;
    if (!userPositions) getUserPositions();
  }

  handleSearchSubmit(value) {
    this.props.addUserPosition(value);
    this.props.onSelect(value);
  }

  handleSearchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleResetSearch() {
    this.setState({
      search: '',
    });
  }

  render() {
    const { userPositions, value, onSelect, disabled } = this.props;
    const { search } = this.state;

    const positionsForSelect = (
      userPositions || []
    ).filter((position: string) => hasSubstr(position, search));

    if (!userPositions) {
      return <Loader size={12} />;
    } else {
      return (
        <Select
          disabled={disabled}
          title={value}
          value={value}
          onClick={this.handleResetSearch.bind(this)}
        >
          <SelectSearch
            placeholder="Type to search or create"
            value={search}
            // onSubmit={this.handleSearchSubmit.bind(this)}
            onChange={this.handleSearchChange.bind(this)}
            error={positionsForSelect.length === 0}
          />

          {positionsForSelect.map((position: string) => (
            <SelectItem
              key={position}
              isSelected={position === value}
              onSelect={() => onSelect(position)}
            >
              {position}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ users }: ApplicationState) => ({
  userPositions: users.userPositions,
});

const mapDispatchToProps = {
  getUserPositions,
  addUserPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPositionSelect);
