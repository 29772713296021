import {
  CardList,
  ControlOverridesDisplay,
  MenuDropdown,
  MenuDropdownItem,
  ShortenedSpan,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import {
  CardListGroupHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  Equipment,
  FanModeLabel,
  HvacControl,
  Site,
  Sop,
  SopTypes,
  ThermostatWorkingMode,
  ThermostatWorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import {
  HvacSop,
  HvacSopTimeSlot,
} from '@energybox/react-ui-library/dist/types/Sop';
import {
  classNames,
  createTemperatureString,
  genericDateFormat,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  customizeOrganizationSop,
  getSops,
  showNewOrEditHvacSopModal,
} from '../../actions/sops';
import history from '../../history';
import {
  useAppLocale,
  useCurrentUser,
  useIs12HrTimeFormat,
} from '../../hooks/useAppDetails';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { useGetEquipmentBySiteId } from '../../hooks/useEquipment';
import { useEquipmentTypesById } from '../../hooks/useEquipmentTypes';
import { useSetpointRecommendation } from '../../hooks/useSetpointRecommendation';
import { ApplicationState } from '../../reducers';
import { SopsById } from '../../reducers/sop';
import { hasDaResults } from '../../util';
import DeleteHvacSopModal from '../DeleteHvacSopModal';
import { RecordInfoTooltip } from '../RecordBubble/RecordInfoTooltip';
import styles from './HvacSopTable.module.css';

import {
  Alert,
  ChevronDown,
  ChevronRight,
  Scheduler,
} from '@energybox/react-ui-library/dist/icons';
import { pluralize } from '@energybox/react-ui-library/dist/utils/util';
import { parseISO } from 'date-fns';
import * as R from 'ramda';
import FeatureFlag from '../../containers/FeatureFlag';
import { Routes } from '../../routes';
import { convertSecToHrsText } from '../../utils/controls';
import { reduceHvacSopTimetableRows } from '../../utils/hvacSop';
import { formatTimeSlotToString } from '../../utils/time';
import UpdateNetworkGroupsButton from './UpdateNetworkGroupsButton';
import { handleSchedulerButtonClick } from '../../containers/Sop/SopDataRow';

export const TEN_MINUTES_IN_MILLISECONDS = 600000;

type Props = {
  site: Site;
  setEquipmentAffectedCount: React.Dispatch<React.SetStateAction<number>>;
  equipmentAffectedCount: number;
};

const EB_ORGS = [275, 5786];

const HvacSopTable: React.FC<Props> = ({
  site,
  setEquipmentAffectedCount,
  equipmentAffectedCount,
}) => {
  const urlHvacSopId = Number(history.location.hash.slice(1));
  const [featureHvacSopId, setFeatureHvacSopId] = useState(urlHvacSopId);
  const [idToDelete, setIdToDelete] = useState<null | number>(null);
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState<boolean>(
    false
  );
  const orgId = useCurrentOrganizationId();
  const hvacSops: Sop[] = useHvacSopsBySiteId(site.id);

  const timeZone = site.timeZone;
  const {
    setpointRecommendation,
    predictionStartDate = '',
    predictionEndDate = '',
    isLoading = false,
  } = useSetpointRecommendation(site.id);

  const data: CardListRowData[] = hvacSops
    .sort(
      (itemA, itemB) => itemA.equipmentTypeIds[0] - itemB.equipmentTypeIds[0]
    )
    .map((item, index) => {
      const { hvacSettings } = item.components[0] as HvacSop;
      const {
        hvacSchedules,
        enableEdm,
        enableLocalAdjustment,
        setPointLimitDelta,
        overrideTimer,
      } = hvacSettings;
      const equipments =
        (setpointRecommendation && setpointRecommendation[0]?.equipment) || [];
      const sopRecommendations = R.pluck('sop', equipments);
      const matchedSopRecommendation = sopRecommendations.find(sop =>
        sop && sop?.id ? sop.id === item.id : false
      );
      const recommendation = matchedSopRecommendation
        ? {
            ...matchedSopRecommendation.components[0].hvacSettings
              .hvacSchedules[0],
            predictionStartDate,
            predictionEndDate,
          }
        : undefined;
      const isInactiveSop = determineIfSopIsInactive(item, hvacSops, orgId);

      return {
        key: `${item.id}`,
        isDisabled: isInactiveSop,
        content: (
          <>
            <Cell width="2">
              <div className={styles.cellColumn}>
                <span className={styles.sopTitle}>{item.title}</span>
                <span className={styles.sopDescription}>
                  {item.description}
                </span>
              </div>
            </Cell>

            {hvacSchedules.map((timeSlot, index) => (
              <React.Fragment key={`sopId${item.id}TimetableRow${index}`}>
                <TimeSlotRow
                  siteId={site.id}
                  sop={item}
                  recommendation={recommendation}
                  timeSlot={timeSlot}
                  enableLocalAdjustment={enableLocalAdjustment}
                  setPointLimitDelta={setPointLimitDelta}
                  overrideTimer={overrideTimer}
                  enableEdm={enableEdm}
                  index={index}
                  isInactiveSop={isInactiveSop}
                  setEquipmentAffectedCount={setEquipmentAffectedCount}
                  setFeatureHvacSopId={setFeatureHvacSopId}
                  setIdToDelete={setIdToDelete}
                  setIsDeleteModalShowing={setIsDeleteModalShowing}
                  daResultsExist={hasDaResults(site.installerTestResults)}
                  timeZone={timeZone}
                />
              </React.Fragment>
            ))}
          </>
        ),
      };
    });

  return (
    <>
      <div className={styles.lastConfigSectionSite}>
        <UpdateNetworkGroupsButton
          hvacSops={hvacSops}
          orgId={orgId!}
          siteId={site.id}
          daResultsExist={hasDaResults(site.installerTestResults)}
        />
      </div>

      <CardList
        numberOfGridColumns={19}
        groupHeaders={
          [
            {
              width: '6',
              content: '',
            },
            {
              width: '9',
              content: 'Control Settings',
            },
          ] as CardListGroupHeader[]
        }
        header={tableHeader}
        data={data}
        featureCardId={featureHvacSopId}
      />

      {isDeleteModalShowing && (
        <DeleteHvacSopModal
          isDeleteModalShowing={isDeleteModalShowing}
          idToDelete={idToDelete}
          setIdToDelete={setIdToDelete}
          setIsDeleteModalShowing={setIsDeleteModalShowing}
          equipmentAffectedCount={equipmentAffectedCount}
        />
      )}
    </>
  );
};

const tableHeader = [
  {
    width: '2',
    content: 'Title / Description',
  },
  {
    width: '1',
    content: 'Attached To',
  },
  {
    width: '2',
    content: 'Local Adjustment',
  },
  {
    width: '1',
    content: 'EDM Enabled',
    featureFlag: true,
  },
  {
    width: '2',
    content: 'Timetable',
  },
  {
    width: '2',
    content: 'Days / Time',
  },
  {
    width: '2',
    content: 'Thermostat Mode',
  },
  {
    width: '2',
    content: 'Temperature',
  },
  {
    width: '1',
    content: 'Fan',
  },
  {
    width: '2',
    content: 'Equipment',
  },
  {
    width: '1',
    content: 'SOP Level',
  },
  {
    width: '1',
    content: '',
  },
];

export const useHvacSopsBySiteId = (siteId: number): Sop[] => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();
  const [fetchFlag, setFetchFlag] = useState(true);
  const sopIds = useSelector<ApplicationState, number[] | undefined>(
    ({ sops }) => {
      return sops.organizationUnitIdToSopIds[siteId];
    }
  );
  const sopsById = useSelector<ApplicationState, SopsById>(({ sops }) => {
    return isDefined(sops.sopsById) ? sops.sopsById : {};
  });
  useEffect(() => {
    if (fetchFlag) {
      setFetchFlag(false);
      dispatch(getSops());
    }
  }, [fetchFlag]);

  if (!isDefined(sopIds)) {
    return [];
  }

  const hvacSops = Object.values(sopsById).filter(sop => {
    if (!isDefined(sop.components) || sop.components.length === 0) {
      return false;
    }
    const sopComponent = sop.components[0] as HvacSop;
    if (
      isDefined(sopComponent) &&
      isDefined(sopComponent.type) &&
      isDefined(sopComponent.hvacSettings)
    ) {
      return sop.components[0].type === SopTypes.HVAC;
    }
    return false;
  });

  // For some reason organizationUnitIdToSopIds includes SOP ids that belong to a different site?
  const hvacSopsForSite = hvacSops.filter(
    hvacSop =>
      hvacSop.organizationUnitId === orgId ||
      hvacSop.organizationUnitId === siteId
  );

  return isDefined(hvacSopsForSite) ? hvacSopsForSite : [];
};
export const renderlocalAdjustment = (
  enableLocalAdjustment,
  setPointLimitDelta,
  overrideTimer
) => (
  <div>
    {enableLocalAdjustment ? 'On' : 'Off'}
    {enableLocalAdjustment && (
      <>
        <div
          className={classNames(
            styles.secondaryInfoText,
            styles.paddingBetweenLimits
          )}
        >
          Setpoint:
        </div>
        <div className={styles.secondaryInfoText}>
          {isDefined(setPointLimitDelta) &&
          Number.isSafeInteger(setPointLimitDelta)
            ? `+/-${setPointLimitDelta} ${pluralize(
                'degree',
                setPointLimitDelta
              )}`
            : global.NOT_AVAILABLE}
        </div>
        <div
          className={classNames(
            styles.secondaryInfoText,
            styles.paddingBetweenLimits
          )}
        >
          Revert Adj. :
        </div>
        <div className={styles.secondaryInfoText}>
          {isDefined(overrideTimer) && Number.isSafeInteger(overrideTimer)
            ? convertSecToHrsText(overrideTimer)
            : global.NOT_AVAILABLE}
        </div>
      </>
    )}
  </div>
);
export const renderScheduleType = (
  hvacSopTimeSlot: HvacSopTimeSlot,
  index: number
) => (
  <span
    className={classNames(styles.timeSlotRow, styles.cellBottomBorderLeftEnd)}
    key={createKey(hvacSopTimeSlot, index)}
  >
    {hvacSopTimeSlot.hvacScheduleType === 'OCCUPIED'
      ? `Occupied ${index + 1}`
      : 'Unoccupied'}
  </span>
);

export const renderWorkingMode = (
  hvacSopTimeSlot: HvacSopTimeSlot,
  index: number
) => (
  <span
    className={classNames(styles.timeSlotRow, styles.cellBottomBorder)}
    key={createKey(hvacSopTimeSlot, index)}
  >
    {ThermostatWorkingModeLabel[hvacSopTimeSlot.thermostatWorkingMode]}
  </span>
);

const TimeSlotRow = (props: {
  siteId: number;
  sop: Sop;
  recommendation:
    | {
        minTemp: number | null;
        maxTemp: number | null;
        predictionStartDate: string;
        predictionEndDate: string;
      }
    | undefined;
  timeSlot: HvacSopTimeSlot;
  index: number;
  isInactiveSop: boolean;
  enableEdm: boolean;
  enableLocalAdjustment: boolean;
  setPointLimitDelta: number | null | undefined;
  overrideTimer: number | null | undefined;
  daResultsExist: boolean;
  timeZone: string;
  setEquipmentAffectedCount;
  setFeatureHvacSopId;
  setIdToDelete;
  setIsDeleteModalShowing;
}) => {
  const {
    siteId,
    sop,
    recommendation,
    timeSlot,
    index,
    enableEdm,
    enableLocalAdjustment,
    setPointLimitDelta,
    overrideTimer,
    isInactiveSop,
    setEquipmentAffectedCount,
    setFeatureHvacSopId,
    setIdToDelete,
    setIsDeleteModalShowing,
    daResultsExist,
    timeZone,
  } = props;
  const { equipmentTypeIds } = sop;

  const isUnoccupied = timeSlot.hvacScheduleType === 'UNOCCUPIED';

  const [isExpanded, setIsExpanded] = useState(isUnoccupied ? false : true);
  const hvacSchedules = sop?.components[0]['hvacSettings']['hvacSchedules'];
  const [
    openedTimetableVisualizations,
    setOpenedTimetableVisualizations,
  ] = useState(new Array<Array<JSX.Element | undefined>>(hvacSchedules.length));

  const dispatch = useDispatch();

  const orgId = useCurrentOrganizationId();

  const equipmentBySiteId = useGetEquipmentBySiteId(siteId);
  const equipmentTypesById = useEquipmentTypesById();
  const user = useCurrentUser();
  const locale = useAppLocale();
  const is12HrFormat = useIs12HrTimeFormat();

  const showRecommendation = useSelector<ApplicationState, boolean>(
    ({ sops }) => sops.showRecommendation
  );

  const equipmentType =
    isDefined(equipmentTypesById) &&
    isDefined(equipmentTypeIds[0]) &&
    isDefined(equipmentTypesById[equipmentTypeIds[0]])
      ? equipmentTypesById[equipmentTypeIds[0]]
      : undefined;

  const equipmentsMatchingSite = isDefined(equipmentType)
    ? Object.values(equipmentBySiteId).filter(
        equipment => equipment.typeId === equipmentType.id
      )
    : [];

  const isOrgLevelSop = isDefined(orgId) && orgId === sop.organizationUnitId;

  const expandIcon = isExpanded ? (
    <ChevronDown size={13} color={'#00A1AF'} />
  ) : (
    <ChevronRight size={13} color={'#00A1AF'} />
  );

  const isFirstRow = index === 0;

  return (
    <>
      {index !== 0 && (
        <>
          <div />
          <Cell width="2" />
        </>
      )}

      <Cell className={styles.cardInfoText} width="1">
        {isDefined(equipmentType) && index === 0 && equipmentType.title}
        {!isDefined(equipmentType) && index === 0 && global.NOT_AVAILABLE}
      </Cell>
      <Cell className={styles.cardInfoText} width="2">
        {index !== 0
          ? null
          : renderlocalAdjustment(
              enableLocalAdjustment,
              setPointLimitDelta,
              overrideTimer
            )}
      </Cell>
      <FeatureFlag>
        <Cell className={styles.cardInfoText} width="1">
          {index !== 0 ? null : enableEdm ? 'On' : 'Off'}
        </Cell>
      </FeatureFlag>
      <Cell
        className={classNames(
          styles.timeslotCell,
          isUnoccupied ? '' : styles.occupiedTimeslotCell,
          isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
        )}
        width="2"
      >
        <div
          onClick={() => isUnoccupied && setIsExpanded(!isExpanded)}
          className={classNames(
            styles.scheduleTypeCell,
            isUnoccupied ? styles.clickableCell : ''
          )}
        >
          {isUnoccupied && expandIcon}
          {renderScheduleType(timeSlot, index)}
        </div>
      </Cell>
      <Cell
        className={classNames(
          styles.timeslotCell,
          isUnoccupied ? '' : styles.occupiedTimeslotCell,
          isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
        )}
        width="2"
      >
        {isExpanded && renderScheduleTimeAndDay(timeSlot, is12HrFormat)}
      </Cell>
      <Cell
        className={classNames(
          styles.timeslotCell,
          styles.gridColumnLeftSpace,
          isUnoccupied ? '' : styles.occupiedTimeslotCell,
          isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
        )}
        width="2"
      >
        {renderWorkingMode(timeSlot, index)}
      </Cell>
      <Cell
        className={classNames(
          styles.timeslotCell,
          styles.gridColumnLeftSpace,
          isUnoccupied ? '' : styles.occupiedTimeslotCell,
          isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
        )}
        width="2"
      >
        {renderSetPoint(
          timeSlot,
          index,
          user,
          locale,
          recommendation,
          showRecommendation && !isUnoccupied
        )}
      </Cell>
      <Cell
        className={classNames(
          styles.timeslotCell,
          styles.gridColumnLeftSpace,
          isUnoccupied ? '' : styles.occupiedTimeslotCell,
          isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
        )}
        width="1"
      >
        {renderFanMode(timeSlot, index)}
      </Cell>
      <Cell width="2">
        {isInactiveSop
          ? global.NOT_AVAILABLE
          : index === 0
          ? renderCorrespondingEquipment(equipmentsMatchingSite)
          : ''}
      </Cell>
      <Cell width="1">
        {index !== 0 ? null : isOrgLevelSop ? 'Organization' : 'Site'}
      </Cell>
      <Cell width="1" className={styles.moreDetails}>
        {index === 0 && (
          <MenuDropdown disabled={daResultsExist ? true : false}>
            {isOrgLevelSop ? (
              <MenuDropdownItem
                onSelect={() => {
                  dispatch(
                    customizeOrganizationSop(
                      { ...sop, organizationUnitId: siteId },
                      'hvac'
                    )
                  );
                }}
              >
                Customize
              </MenuDropdownItem>
            ) : (
              <>
                <MenuDropdownItem
                  onSelect={() => {
                    setEquipmentAffectedCount(
                      equipmentsMatchingSite?.length || 0
                    );
                    setFeatureHvacSopId(-1);
                    dispatch(showNewOrEditHvacSopModal(sop.id, siteId));
                  }}
                >
                  Edit
                </MenuDropdownItem>
                <MenuDropdownItem
                  onSelect={() => {
                    setIdToDelete(sop.id);
                    setEquipmentAffectedCount(
                      equipmentsMatchingSite?.length || 0
                    );
                    setFeatureHvacSopId(-1);

                    setIsDeleteModalShowing(true);
                  }}
                >
                  <span className={styles.deleteButtonText}>Delete</span>
                </MenuDropdownItem>
              </>
            )}
          </MenuDropdown>
        )}
      </Cell>
      {index === hvacSchedules.length - 1 && (
        <Cell width="20" className={styles.iconsContainer}>
          <RecordInfoTooltip resourceId={sop.id} ianaTimeZoneCode={timeZone} />
          <div
            onClick={() =>
              handleSchedulerButtonClick(
                openedTimetableVisualizations,
                index,
                hvacSchedules,
                setOpenedTimetableVisualizations
              )
            }
            className={styles.schedulerIcon}
          >
            <Scheduler size={30} />
          </div>
        </Cell>
      )}

      <Cell width="20" className={styles.timetableContainer}>
        {openedTimetableVisualizations[index] &&
          openedTimetableVisualizations[index].map((component, i) => {
            return (
              <div key={i} className={styles.rowDropdownContent}>
                {component}
              </div>
            );
          })}
      </Cell>
    </>
  );
};
// TODO: think I need to redo this function to reflect the typing from endpoint
const createKey = (hvacSopTimeSlot: HvacSopTimeSlot, index: number) => {
  if (!isDefined(hvacSopTimeSlot.timetable?.rows?.[0])) return index;
  return `${hvacSopTimeSlot.timetable.rows[0].begin.time}-${hvacSopTimeSlot.timetable.rows[0].end}-${index}`;
};
export const renderScheduleTimeAndDay = (
  hvacSopTimeSlot: HvacSopTimeSlot,
  is12HrFormat: boolean
) => {
  const { timetable, hvacScheduleType } = hvacSopTimeSlot;
  if (!isDefined(timetable)) return <></>;
  const { rows } = timetable;
  // Ff more than one it is unoccupied and need to handle this
  if (!isDefined(rows) || rows.length === 0) return <></>;

  // Identify which rows correspond to the same days
  const reducedTimeSlots = reduceHvacSopTimetableRows(rows);

  return Object.entries(reducedTimeSlots).map(
    ([weekdayKey, timesArray], index) => {
      return (
        <div
          className={classNames(
            styles.timeSlotRow,
            hvacScheduleType === 'UNOCCUPIED'
              ? styles.unoccupiedPadding
              : styles.cellBottomBorder,
            hvacScheduleType === 'UNOCCUPIED' ? styles.greyText : ''
          )}
          key={createKey(hvacSopTimeSlot, index)}
        >
          <span>{summarizeDays(weekdayKey)}</span>
          {timesArray.map(({ begin, end }, timesArrayIndex) => {
            return (
              <span
                key={`${hvacSopTimeSlot.id}-${hvacScheduleType}-${begin.time}-${end.time}-${timesArrayIndex}`}
              >{`${formatTimeSlotToString(
                begin,
                is12HrFormat
              )} - ${formatTimeSlotToString(end, is12HrFormat)}`}</span>
            );
          })}
        </div>
      );
    }
  );
};

//Summarize Days
const summarizeDays = (weekdayKey: string) => {
  const Weekdays = 'MON, TUE, WED, THU, FRI';
  const Weekends = 'SAT, SUN';
  const Everyday = 'MON, TUE, WED, THU, FRI, SAT, SUN';

  switch (weekdayKey) {
    case Everyday: {
      return 'Everyday';
    }
    case Weekdays: {
      return 'Weekdays';
    }
    case Weekends: {
      return 'Weekends';
    }
    default:
      return weekdayKey;
  }
};

export const renderSetPoint = (
  hvacSopTimeSlot: HvacSopTimeSlot,
  index: number,
  user,
  locale,
  recommendation?: {
    minTemp: number | null;
    maxTemp: number | null;
    predictionStartDate: string;
    predictionEndDate: string;
  },
  showRecommendation?: boolean
) => {
  const { thermostatWorkingMode, minTemp, maxTemp } = hvacSopTimeSlot;
  return (
    <div
      className={classNames(styles.timeSlotRow, styles.cellBottomBorder)}
      key={createKey(hvacSopTimeSlot, index)}
    >
      {renderSetPointInputs(
        thermostatWorkingMode,
        minTemp,
        maxTemp,
        user,
        locale,
        recommendation,
        showRecommendation
      )}
    </div>
  );
};

export const renderSetPointInputs = (
  thermostatWorkingMode: ThermostatWorkingMode,
  minTemp: number | undefined,
  maxTemp: number | undefined,
  user,
  locale,
  recommendation?: {
    minTemp: number | null;
    maxTemp: number | null;
    predictionStartDate: string;
    predictionEndDate: string;
  },
  showRecommendation?: boolean
) => {
  const isAuto = thermostatWorkingMode === ThermostatWorkingMode.AUTO;
  const isCooling = thermostatWorkingMode === ThermostatWorkingMode.COOLING;
  const isHeating = thermostatWorkingMode === ThermostatWorkingMode.HEATING;
  const minTempRecommendation = recommendation?.minTemp;
  const maxTempRecommendation = recommendation?.maxTemp;
  const hasRecommendation =
    isDefined(minTempRecommendation) || isDefined(maxTempRecommendation)
      ? true
      : false;
  return (
    <>
      {' '}
      <span>
        {isDefined(minTemp) && (isAuto || isHeating)
          ? `Heat to: ${createTemperatureString(minTemp, user, 0)}`
          : global.NOT_AVAILABLE}
        {hasRecommendation &&
          showRecommendation &&
          isDefined(minTemp) &&
          (isAuto || isHeating) &&
          minTempRecommendation !== null && (
            <FeatureFlag orgWhiteList={EB_ORGS}>
              <div
                data-testid="sop-recommendation"
                className={styles.sopRecommendation}
              >
                <Tooltip
                  arrowDirection={'bottom'}
                  content={renderSetpointRecommendationTooltip(
                    minTempRecommendation,
                    user,
                    locale,
                    recommendation
                  )}
                >
                  <Alert size={14} />
                </Tooltip>
              </div>
            </FeatureFlag>
          )}
      </span>{' '}
      <span>
        {isDefined(maxTemp) && (isAuto || isCooling)
          ? `Cool to: ${createTemperatureString(maxTemp, user, 0)}`
          : ''}
        {hasRecommendation &&
          showRecommendation &&
          isDefined(maxTemp) &&
          (isAuto || isCooling) &&
          maxTempRecommendation !== null && (
            <FeatureFlag orgWhiteList={EB_ORGS}>
              <div
                data-testid="sop-recommendation"
                className={styles.sopRecommendation}
              >
                <Tooltip
                  arrowDirection={'bottom'}
                  content={renderSetpointRecommendationTooltip(
                    maxTempRecommendation,
                    user,
                    locale,
                    recommendation
                  )}
                >
                  <Alert size={14} />
                </Tooltip>
              </div>
            </FeatureFlag>
          )}
      </span>
    </>
  );
};

export const renderSetpointRecommendationTooltip = (
  temp,
  user,
  locale,
  recommendation
) => {
  const { predictionStartDate, predictionEndDate } = recommendation;
  return (
    <div className={styles.recommendationTooltip}>
      <div className={styles.title}>{'Setpoint Recommendation'}</div>
      <div className={styles.recommendation}>
        {createTemperatureString(temp, user, 0)}
      </div>
      <div className={styles.timeperiodTitle}>{'For Time Period'}</div>
      <div className={styles.timeperiod}>
        {`${genericDateFormat(
          parseISO(predictionStartDate),
          locale.dateFormat
        )} - ${genericDateFormat(
          parseISO(predictionEndDate),
          locale.dateFormat
        )}`}
      </div>
    </div>
  );
};

export const renderFanMode = (
  hvacSopTimeSlot: HvacSopTimeSlot,
  index: number
) => {
  const { thermostatWorkingMode } = hvacSopTimeSlot;
  const isThermostatWorkingModeOff =
    thermostatWorkingMode === ThermostatWorkingMode.OFF;

  return (
    <span
      className={classNames(
        styles.timeSlotRow,
        styles.cellBottomBorderRightEnd
      )}
      key={createKey(hvacSopTimeSlot, index)}
    >
      {isDefined(hvacSopTimeSlot.fanMode) && !isThermostatWorkingModeOff
        ? FanModeLabel[hvacSopTimeSlot.fanMode]
        : global.NOT_AVAILABLE}
    </span>
  );
};

export const renderCorrespondingEquipment = (
  equipmentsMatchingSite: Equipment[]
) => {
  if (equipmentsMatchingSite.length === 0) {
    return <span>{global.NOT_AVAILABLE}</span>;
  }

  return equipmentsMatchingSite.map(equipment => {
    return <EquipmentAndWorkingMode key={equipment.id} equipment={equipment} />;
  });
};

// This component exists so that we can call hooks on each individual equipment to fetch the working mode info
export const EquipmentAndWorkingMode = ({
  equipment,
}: {
  equipment: Equipment;
}) => {
  const workingMode = equipment.activeControl?.workingMode;
  const thermostatId = (equipment.activeControl as HvacControl)?.thermostatId;

  if (!isDefined(equipment.title)) {
    return null;
  }

  const renderSecondaryInfo = () => {
    if (!isDefined(thermostatId)) {
      return (
        <span className={`${styles.secondaryInfoText} ${styles.redText}`}>
          No Thermostat
        </span>
      );
    }

    return (
      <span className={styles.secondaryInfoText}>
        <span className={styles.paddingRight}>Control Mode: </span>

        {isDefined(workingMode) ? (
          <ControlOverridesDisplay
            controlWorkingMode={workingMode}
            // Jan & Ananya don't want to show local override here - so rather than editing the component, just always send false
            isLocalOverrideActive={false}
          />
        ) : (
          global.NOT_AVAILABLE
        )}
      </span>
    );
  };

  return (
    <div className={styles.equipmentDetails}>
      <Link to={`${Routes.EQUIPMENT}/${equipment.id}`}>
        <ShortenedSpan content={equipment.title} maxStringLength={20} />
      </Link>
      {renderSecondaryInfo()}
    </div>
  );
};

export const determineIfSopIsInactive = (
  sop: Sop,
  sopList: Sop[],
  orgId: number | undefined
) => {
  const isOrgLevelSop = isDefined(orgId) && orgId === sop.organizationUnitId;
  if (!isOrgLevelSop) return false;

  const filteredSopList = sopList.filter(s => s.id !== sop.id);
  const equipmentType = sop.equipmentTypeIds[0];

  const siteLevelSopWithSameEquipmentType = filteredSopList.find(s => {
    return (
      s.organizationUnitId !== orgId &&
      s.equipmentTypeIds.includes(equipmentType)
    );
  });

  if (isDefined(siteLevelSopWithSameEquipmentType)) {
    return true;
  }

  return false;
};

export default HvacSopTable;
