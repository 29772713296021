import {
  Gateway,
  GatewayApiFilter,
} from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/apiUtils';
import { setGatewaysApiFilter } from './gateways';

export const fetchGateways = (filter?: GatewayApiFilter): Promise<Gateway[]> =>
  fetchApi({
    endpoint: setGatewaysApiFilter(filter),
  });
