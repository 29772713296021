import React from 'react';
import styles from './ImageSquare.module.css';
import { Trash as TrashIcon } from '@energybox/react-ui-library/dist/icons';

type Props = {
  handleDelete?: (imagePath) => void;
  baseUrl: string;
  imagePath: string;
  size?: string;
};

const ImageSquare = ({
  handleDelete,
  imagePath,
  baseUrl,
  size = '149.33',
}: Props) => {
  return (
    <div style={{ width: size, height: size }} className={styles.root}>
      {handleDelete ? (
        <span className={styles.deleteButton}>
          <TrashIcon
            color="var(--pink-base)"
            size={16}
            onClick={() => handleDelete(imagePath)}
          />
        </span>
      ) : (
        ''
      )}
      <img
        className={styles.image}
        width={size}
        height={size}
        onClick={() => window.open(`${baseUrl}/${imagePath}`, '_blank')}
        src={`${baseUrl}/${imagePath}`}
        alt=""
      />
    </div>
  );
};

export default ImageSquare;
