import {
  Button,
  Card,
  CardActions,
  Loader,
  MediumLongSkeletonCell,
  MenuDropdown,
  MenuDropdownItem,
  ShortenedSpan,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  TableGroupHeader,
} from '@energybox/react-ui-library/dist/components/Table';
import styles from '../HvacControls/HvacControls.module.css';
import { Scheduler as SchedulerIcon } from '@energybox/react-ui-library/dist/icons';
import {
  CurrentUser,
  Equipment,
  EquipmentType,
  EquipmentTypesById,
  OpacityIndex,
  SitesById,
  Sop,
} from '@energybox/react-ui-library/dist/types';
import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isDefined, global } from '@energybox/react-ui-library/dist/utils';
import DeviceOnlineState, {
  DisplayType,
} from '../../DeviceStatus/DeviceOnlineState';
import OrgControlsCardHeader from '../OrgControlsCardHeader';
import AddSopComponent from './ControlsTableComponents/AddSopComponent';
import AddAndMapThermostat from './ControlsTableComponents/AddAndMapThermostat';
import { MdMoreVert } from 'react-icons/md';
import ThermostatCurrentTemperature from './ThermostatCurrentTemperature';
import TemperatureSensorCount from './ControlsTableComponents/TemperatureSensorCount';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import ControlBoardLiveMode from '../LightingEquipmentContorls/ControlBoardLiveMode';
import EditControlModeDropdown from '../EditControlModeDropdown/EditControlModeDropdown';
import {
  addHvacControlBatch,
  deleteHvacControl,
  patchHvacControlBatch,
  updateField as updateHvacField,
} from '../../../actions/hvacControls';
import TimetableVisualization from '../TimetableVisualization/TimetableVisualization';
import { HvacControlBatch } from '../../../types/hvacControl';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../../../mixpanelEvents';
import { getHvacSopRoute } from '../../../utils/hvacSop';

export type ControllableEquipment = {
  equipment: Equipment;
  equipmentType: EquipmentType;
  siteId: number;
  id: number;
  sop?: Sop;
};

enum ColumnHeaders {
  EQUIPMENT = 'Equipment',
  EQUIPMENT_TYPE = 'Equipment Type',
  THERMOSTAT = 'Mapped Thermostat',
  STATUS = 'Status',
  SENSOR_COUNT = 'No. of Sensors',
  CURRENT_TEMPERATURE = 'Current Temp.',
  CONTROL_MODE = 'Control Mode',
  SOP = 'SOP',
  SCHEDULE = 'Schedule',
}

type Props = {
  controllableHvacTypes: EquipmentTypesById;
  equipment: any;
  sop: Sop[];
  siteId: number;
  networkGroupIds: number[];
  user: CurrentUser | undefined;
  orgId: number;
  sitesById: SitesById;
  setEquipmentAffectedCount: Dispatch<SetStateAction<number>>;
};
const HvacControlsTable: React.FC<Props> = ({
  controllableHvacTypes,
  equipment,
  sop,
  siteId,
  sitesById,
  networkGroupIds,
  user,
  orgId,
  setEquipmentAffectedCount,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    openedTimetableVisualizations,
    setOpenedTimetableVisualizations,
  ] = useState(new Array<Array<JSX.Element | undefined>>(equipment.length));
  const [prevEquipment, setPrevEquipment] = useState(equipment);

  const weatherData = useSelector(({ weather }: ApplicationState) => {
    return weather;
  });
  const updatedFields = useSelector(({ hvacControls }: ApplicationState) => {
    return hvacControls.editById;
  });

  const showEditControlMode = () => {
    setEditMode(true);
  };
  const onCancel = () => {
    setEditMode(false);
  };

  const getActiveControlId = equipmentId => {
    const equipmentData = equipment.filter(data => {
      return (
        data.equipment.id === +equipmentId && data.equipment?.activeControl?.id
      );
    });
    const activeControlId = equipmentData[0]?.equipment?.activeControl?.id;
    const currentThermostatId =
      equipmentData[0]?.equipment?.activeControl?.thermostatId;
    const currentWorkingMode =
      equipmentData[0]?.equipment?.activeControl?.workingMode;
    const networkGroupId =
      equipmentData[0]?.equipment?.activeControl?.networkGroupId;

    return {
      activeControlId,
      currentThermostatId,
      currentWorkingMode,
      networkGroupId,
    };
  };

  const onUpdate = () => {
    const bulkUpdatePayload: { [key: number]: HvacControlBatch } = {};
    const bulkAddPayload = [] as HvacControlBatch[];
    Object.entries(updatedFields).forEach(data => {
      const {
        activeControlId,
        currentThermostatId,
        currentWorkingMode,
        networkGroupId,
      } = getActiveControlId(data[0]);

      if (data[1].thermostatId === -1) {
        dispatch(
          deleteHvacControl(
            activeControlId,
            Number(data[0]),
            { produceEdgeConfig: true, networkGroupId: networkGroupId },
            orgId
          )
        );
      } else if (isDefined(activeControlId)) {
        bulkUpdatePayload[activeControlId] = {
          equipmentId: Number(data[0]),
          thermostatId: isDefined(data[1].thermostatId)
            ? data[1].thermostatId
            : currentThermostatId,
          workingMode: data[1].workingMode ?? currentWorkingMode,
        };
      } else {
        bulkAddPayload.push({
          equipmentId: Number(data[0]),
          thermostatId: isDefined(data[1].thermostatId)
            ? data[1].thermostatId
            : currentThermostatId,
          workingMode: data[1].workingMode ?? currentWorkingMode,
        });
      }
    });
    if (bulkAddPayload.length > 0) {
      dispatch(addHvacControlBatch(bulkAddPayload, orgId));
    }
    if (Object.entries(bulkUpdatePayload).length > 0) {
      Object.entries(bulkUpdatePayload).forEach(data => {
        const currentMode = sortedEquipmentData.find(
          equipment => equipment.equipment?.id === data[1].equipmentId
        )?.equipment?.activeControl?.workingMode;
        mixpanel.track(mixpanelEvents.CONTROL_MODE_CHANGE, {
          controlType: 'HVAC',
          equipmentId: data[1].equipmentId,
          thermostatId: data[1].thermostatId,
          from: currentMode,
          to: data[1].workingMode,
          source: 'connectHvacControlsBatch',
        });
      });
      dispatch(patchHvacControlBatch(bulkUpdatePayload, orgId));
    }
    setLoading(true);
  };

  const onChange = (id, field, value) => {
    dispatch(updateHvacField(id, field, value));
  };

  const handleSchedulerButtonClick = (data, index) => {
    const hvacSchedules =
      data.sop?.components[0]['hvacSettings']['hvacSchedules'];
    let openedVisualizationsCopy = [...openedTimetableVisualizations];
    [...Array(openedTimetableVisualizations.length).keys()].forEach(i => {
      if (i === index) {
        if (
          openedVisualizationsCopy[i] === undefined ||
          openedVisualizationsCopy[i].length === 0
        ) {
          openedVisualizationsCopy[i] = [
            <TimetableVisualization
              hvacSchedules={hvacSchedules}
              numberOfColumns={columns.length}
            />,
          ];
        } else {
          openedVisualizationsCopy[i] = [];
        }
      }
    });
    setOpenedTimetableVisualizations(openedVisualizationsCopy);
  };

  const addSopsToEquipmentArray = (equipmentData, sopData, siteId) => {
    const combinedData = equipmentData?.map(equipment => {
      const matchingSop = sopData?.find(
        sop => equipment.equipment?.typeId === sop.equipmentTypeIds[0]
      );

      if (matchingSop) {
        return { ...equipment, id: siteId, sop: matchingSop };
      } else {
        return { ...equipment, id: siteId };
      }
    });

    return combinedData;
  };

  useEffect(() => {
    if (equipment !== prevEquipment) {
      setPrevEquipment(equipment);
    }
  }, [equipment, prevEquipment]);

  const equipmentData = addSopsToEquipmentArray(equipment, sop, siteId);
  const sortedEquipmentData = equipmentData.sort((a, b) => {
    const titleA = a.equipment.title.toUpperCase();
    const titleB = b.equipment.title.toUpperCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }

    return 0; // titles are equal
  });

  const columns = [
    {
      header: ColumnHeaders.EQUIPMENT,
      width: '12%',
      cellContent: data => {
        return (
          <Link to={`/equipment/${data?.equipment?.id}`} target="_blank">
            <ShortenedSpan
              content={data.equipment?.title}
              maxStringLength={30}
              arrowDirection="right"
            />
          </Link>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.EQUIPMENT_TYPE,
      width: '12%',
      cellContent: data => (
        <div>{controllableHvacTypes?.[data.equipment?.typeId]?.title}</div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.THERMOSTAT,
      width: '10%',
      cellContent: data => {
        return (
          <AddAndMapThermostat
            equipment={data.equipment}
            siteId={data.id}
            isEditModeActive={editMode}
            onChange={onChange}
          />
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.STATUS,
      width: '10%',
      cellContent: data => {
        const thermostat = data.equipment?.activeControl?.thermostat;
        return isDefined(thermostat) && thermostat !== null ? (
          <DeviceOnlineState
            displayType={DisplayType.STATUS_ONLY_WITH_TEXT}
            devices={[
              {
                id: thermostat.id,
                uuid: thermostat.uuid,
                vendor: thermostat.vendor,
              },
            ]}
          />
        ) : (
          global.NOT_AVAILABLE
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.SENSOR_COUNT,
      width: '10%',
      cellContent: data => {
        const thermostat = data.equipment?.activeControl?.thermostat;
        return <TemperatureSensorCount thermostat={thermostat} />;
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.CURRENT_TEMPERATURE,
      width: '10%',
      cellContent: data => {
        const thermostat = data.equipment?.activeControl?.thermostat;
        return isDefined(thermostat) ? (
          <ThermostatCurrentTemperature user={user} thermostat={thermostat} />
        ) : (
          global.NOT_AVAILABLE
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.CONTROL_MODE,
      width: '10%',
      cellContent: data => {
        const controlBoardId = data.equipment?.activeControl?.id;
        const equipment = data.equipment;
        return editMode ? (
          <EditControlModeDropdown
            equipment={equipment}
            onChange={onChange}
            isHvac={true}
          />
        ) : (
          <ControlBoardLiveMode controlBoardId={controlBoardId} />
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.SOP,
      width: '15%',
      cellContent: data => {
        const sop = data.sop;
        return sop ? (
          <Link
            to={getHvacSopRoute({
              orgUnitId: siteId,
              hvacSopId: sop?.id,
              isOrgLevelTimetable: true,
            })}
            target="_blank"
          >
            <ShortenedSpan content={sop.title} maxStringLength={25} />
          </Link>
        ) : (
          <AddSopComponent
            equipment={data.equipment}
            siteId={data.id}
            setEquipmentAffectedCount={setEquipmentAffectedCount}
          />
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    {
      header: ColumnHeaders.SCHEDULE,
      width: '5%',
      cellContent: (data, index) =>
        data.sop && (
          <div
            className={styles.schedulerIcon}
            onClick={() => {
              return handleSchedulerButtonClick(data, index);
            }}
          >
            <SchedulerIcon size={30} />
          </div>
        ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
  ];

  const groupHeaders: TableGroupHeader[] = [
    {
      header: '',
      colSpan: 2,
    },
    {
      header: 'Thermostat Configuration',
      colSpan: 3,
      className: styles.groupHeader,
    },
    {
      header: 'Live View',
      colSpan: 2,
    },
    {
      header: '',
      colSpan: 2,
    },
  ];

  useEffect(() => {
    if (loading) {
      const timeoutId = setTimeout(() => {
        setEditMode(false);
        setLoading(false); // Refresh the page
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [loading]);

  return (
    <>
      <div className={styles.cardHeadContainer}>
        <div>
          <OrgControlsCardHeader
            site={sitesById[siteId]}
            networkGroupIds={networkGroupIds}
            weatherData={weatherData}
            orgId={orgId}
          />
        </div>
        <div className={styles.menuDropdown}>
          <MenuDropdown icon={<MdMoreVert size="28" />}>
            <MenuDropdownItem onSelect={showEditControlMode}>
              Add/Edit
            </MenuDropdownItem>
          </MenuDropdown>
        </div>
      </div>
      <Card className={styles.customCardStyles}>
        <Table
          columns={columns}
          groupHeaders={groupHeaders}
          data={sortedEquipmentData}
          dataIsLoading={false}
          highlightAlternateRows={true}
          className={styles.bodyBorder}
          containerClassname={styles.tableContainer}
          rowDropdownContent={openedTimetableVisualizations}
          //removeRowBorders={true}
        />
        {editMode && (
          <CardActions className={styles.buttons}>
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onUpdate} disabled={false}>
              {loading ? (
                <Loader size={15} className={styles.loader} />
              ) : (
                'Update'
              )}
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default HvacControlsTable;
