import { IconLock } from '@energybox/react-ui-library/dist/icons';
import styles from './accessDeniedError.module.css';
import { ApiError } from '@energybox/react-ui-library/dist/types';
import CrossOrgAdminAccess from '../../components/CrossOrgAdminAccess';

export const accessDeniedError = (
  apiError: ApiError | null,
  display403Error?: boolean
) => {
  let hasAccessToResource: boolean | null;
  let orgId: number | undefined;
  if (apiError == null && display403Error) {
    hasAccessToResource = false;
    orgId = undefined;
  } else {
    hasAccessToResource =
      apiError &&
      apiError['additionalErrors']['errorType'] === 'CROSS_ORG_RESOURCE_ACCESS';
    orgId = apiError && apiError['additionalErrors']['requiredOrganizationId'];
  }

  const render403Content = () => {
    return (
      <div className={styles.body}>
        <span className={styles.error}>
          <IconLock size={50} fill={'var(--pink-base)'} />
          403
        </span>
        <span className={styles.mainText}>Access Forbidden/Denied</span>
        <span className={styles.subText}>
          You do not have permissions to view the page or resources
        </span>
      </div>
    );
  };
  return !hasAccessToResource ? (
    render403Content()
  ) : orgId ? (
    <CrossOrgAdminAccess orgId={orgId} />
  ) : (
    render403Content()
  );
};
