import { Tab, Tabs } from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  Equipment,
  Gateway,
  Sensor,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { connect } from 'react-redux';
import { getEquipmentGroups } from '../../actions/app';
import { getEquipments } from '../../actions/equipment';
import { getGateways } from '../../actions/gateways';
import { getSensors } from '../../actions/sensors';
import SpaceDetailResourceList from '../../components/SpaceDetailResourceList';
import { ApplicationState } from '../../reducers';
import { EquipmentGroupsById } from '../../reducers/app';

interface OwnProps {
  id: string;
}

interface State {
  display: string;
}

interface Props extends OwnProps {
  getEquipment: () => void;
  getSensors: () => void;
  getGateways: () => void;
  getEquipmentGroups: () => void;
  sensors: Sensor[];
  gateways: (Gateway | ControlBoard)[];
  equipment: Equipment[];
  equipmentGroupsById?: EquipmentGroupsById;
}

class SpaceResources extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { display: 'Equipment' };
  }

  componentDidMount() {
    this.loadResources();

    if (!this.props.equipmentGroupsById) {
      this.props.getEquipmentGroups();
    }
  }

  handleTabChange = (display: string) => {
    this.setState({ display });
  };

  loadResources() {
    this.props.getEquipment();
    this.props.getSensors();
    this.props.getGateways();
  }

  render() {
    const { sensors, gateways, equipment, equipmentGroupsById } = this.props;
    const { display } = this.state;
    return (
      <div>
        <div>
          <Tabs>
            <Tab
              active={display === 'Equipment'}
              onClick={() => {
                this.handleTabChange('Equipment');
              }}
            >{`${equipment.length} equipment`}</Tab>
            <Tab
              active={display === 'Sensors'}
              onClick={() => {
                this.handleTabChange('Sensors');
              }}
            >{`${sensors.length} sensors`}</Tab>
            <Tab
              active={display === 'Gateways'}
              onClick={() => {
                this.handleTabChange('Gateways');
              }}
            >{`${gateways.length} gateways`}</Tab>
          </Tabs>
        </div>
        <div>
          {display === 'Equipment' && (
            <SpaceDetailResourceList
              type={'equipment'}
              list={equipment}
              equipmentGroupsById={equipmentGroupsById}
            />
          )}
          {display === 'Sensors' && (
            <SpaceDetailResourceList type={'sensors'} list={sensors} />
          )}
          {display === 'Gateways' && (
            <SpaceDetailResourceList type={'gateways'} list={gateways} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  { app, sensors, equipment, gateways }: ApplicationState,
  { id }: OwnProps
) => {
  return {
    sensors:
      (sensors['sensorIdsByParentId'][id] || []).map(
        id => sensors['sensorsById'][id]
      ) || [],
    equipment:
      (equipment['equipmentIdsByParentId'][id] || []).map(
        id => equipment['equipmentById'][id]
      ) || [],
    gateways:
      (gateways['gatewayIdsByParentId'][id] || []).map(
        id => gateways['gatewaysById'][id]
      ) || [],
    equipmentGroupsById: app.equipmentGroupsById,
  };
};

const mapDispatchToProps = (dispatch, { id }: OwnProps) => ({
  getEquipment: () => dispatch(getEquipments({ spaceIds: [id] })),
  getSensors: () => dispatch(getSensors({ spaceIds: [id] })),
  getGateways: () => dispatch(getGateways({ spaceIds: [id] })),
  getEquipmentGroups: () => dispatch(getEquipmentGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpaceResources);
