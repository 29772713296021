import { PositiveNumberInput } from '@energybox/react-ui-library/dist/components';
import {
  MeasurementSystem,
  SopType,
  SopTypes,
  SopTypeToLabel,
} from '@energybox/react-ui-library/dist/types';
import { HvacSopTimeSlot } from '@energybox/react-ui-library/dist/types/Sop';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import TemperatureField from '../components/InputField/TemperatureField';
import SelectCurrency from '../containers/Selects/SelectCurrency';
import SelectTimeTable from '../containers/Selects/SelectTimeTable';
import styles from './sop.module.css';

type OrgSopSiteCounts = {
  generic: OrgSopSiteCountsItem[];
  localised: OrgSopSiteCountsItem[];
};

type OrgSopSiteCountsItem = {
  siteId: number;
};

type OrgSopEquipmentCounts = {
  generic: OrgSopEquipmentCountsItem[];
  localised: OrgSopEquipmentCountsItem[];
};

type OrgSopEquipmentCountsItem = {
  id: number;
  equipmentTypeId: number;
};

type SopDetails = {
  sopId: number;
  sopComponentType: SopType;
};

export enum OrgSopAppliedCountType {
  SITE = 'SITE',
  EQUIPMENT = 'EQUIPMENT',
}

export type OrgSopAppliedCounts = {
  sopId: number;
  countType: OrgSopAppliedCountType;
  equipmentCount?: {
    sitesAppliedTo: OrgSopSiteCounts;
    equipmentAppliedTo: OrgSopEquipmentCounts;
  };
  siteCount?: OrgSopSiteCounts;
};

export type OrgSopAppliedEquipmentCounts = {
  equipmentId: number;
  siteId: number;
  generic: SopDetails[];
  localised: SopDetails[];
};

export enum SopTabTitles {
  POLICY_TAB_TITLE = 'Policy Type',
  COST_TAB_TITLE = 'Cost Type',
}

export type HvacSopFields = {
  organizationUnitId: number | string;
  title: string;
  description: string;
  policyType: string;
  attachedTo: string;
  equipmentTypeId: number[];
  thermostatDisplayUnits: MeasurementSystem;
  enableLocalAdjustment: boolean;
  setPointLimitDelta: number;
  overrideTimer: number;
  hvacTimeSlots: HvacSopTimeSlot[];
};

export const productValueFields = [
  {
    name: 'currencyCode',
    component: (onChange, value, index) => (
      <SelectCurrency
        value={value}
        error={false}
        onSelect={currencyCode =>
          onChange(`components.${index}.currencyCode`, currencyCode)
        }
      />
    ),
  },
  {
    name: 'value',
    component: (onChange, value, index, error, customErrorText) => (
      <div className={styles.numericLongInput}>
        <PositiveNumberInput
          value={value}
          name="value"
          onNumberChange={(value, name) => {
            onChange(`components.${index}.${name}`, value);
          }}
          error={error}
          customErrorText={customErrorText}
        />
      </div>
    ),
  },
];

export const maintenanceVisitRateFields = [
  {
    name: 'currencyCode',
    component: (onChange, value, index) => (
      <SelectCurrency
        value={value}
        error={false}
        onSelect={currencyCode =>
          onChange(`components.${index}.currencyCode`, currencyCode)
        }
      />
    ),
  },
  {
    name: 'value',
    component: (onChange, value, index, error, customErrorText) => (
      <div className={styles.numericLongInput}>
        <PositiveNumberInput
          value={value}
          name="value"
          onNumberChange={(value, name) => {
            onChange(`components.${index}.${name}`, value);
          }}
          error={error}
          customErrorText={customErrorText}
        />
      </div>
    ),
  },
];

export const laborRateFields = [
  {
    name: 'currencyCode',
    component: (onChange, value, index) => (
      <SelectCurrency
        value={value}
        error={false}
        onSelect={currencyCode =>
          onChange(`components.${index}.currencyCode`, currencyCode)
        }
      />
    ),
  },
  {
    name: 'value',
    component: (onChange, value, index, error, customErrorText) => (
      <div className={styles.numericLongInput}>
        <PositiveNumberInput
          value={value}
          name="value"
          onNumberChange={(value, name) => {
            onChange(`components.${index}.${name}`, value);
          }}
          error={error}
          customErrorText={customErrorText}
        />
      </div>
    ),
  },
];

export const energyTariffFields = [
  {
    name: 'currencyCode',
    component: (onChange, value, index) => (
      <SelectCurrency
        value={value}
        error={false}
        onSelect={currencyCode =>
          onChange(`components.${index}.currencyCode`, currencyCode)
        }
      />
    ),
  },
  {
    name: 'tariff',
    component: (onChange, value, index, error, customErrorText) => (
      <div className={styles.numericLongInput}>
        <PositiveNumberInput
          value={value}
          name="tariff"
          onNumberChange={(value, name) => {
            onChange(`components.${index}.${name}`, value);
          }}
          error={error}
          customErrorText={customErrorText}
        />
      </div>
    ),
  },
];

export const humidityRangeFields = [
  {
    name: 'min',
    component: (onChange, value, index, error) => (
      <div className={styles.numericInput}>
        <div>
          <PositiveNumberInput
            value={value}
            name="min"
            autoComplete="min"
            onNumberChange={(value, name) => {
              onChange(`components.${index}.${name}`, Math.min(value, 100));
            }}
            error={error}
          />
        </div>
        <div>%</div>
      </div>
    ),
  },
  {
    name: 'max',
    component: (onChange, value, index, error) => (
      <div className={styles.numericInput}>
        <div>
          <PositiveNumberInput
            value={value}
            name="max"
            autoComplete="max"
            onNumberChange={(value, name) => {
              onChange(`components.${index}.${name}`, Math.min(value, 100));
            }}
            error={error}
          />
        </div>
        <div>%</div>
      </div>
    ),
  },
];

export const temperatureRangeFields = [
  {
    name: 'min',
    component: (onChange, value, index, error, customErrorText) => {
      return (
        <TemperatureField
          onChange={temperature => {
            onChange(`components.${index}.min`, temperature);
          }}
          temperature={value}
          error={error}
          customErrorText={customErrorText}
        />
      );
    },
  },
  {
    name: 'max',
    component: (onChange, value, index, error, customErrorText) => {
      return (
        <TemperatureField
          onChange={temperature => {
            onChange(`components.${index}.max`, temperature);
          }}
          temperature={value}
          error={error}
          customErrorText={customErrorText}
        />
      );
    },
  },
];

export const doorOpenMaxDurationFields = [
  {
    name: 'duration',
    component: (onChange, value, index, error, customErrorText) => (
      <div className={styles.numericLongInput}>
        <PositiveNumberInput
          value={value}
          name="duration"
          onNumberChange={(value, name) => {
            onChange(`components.${index}.${name}`, value);
          }}
          error={error}
          customErrorText={customErrorText}
        />
      </div>
    ),
  },
];

export const normalPowerConsumption = [
  {
    name: 'powerConsumption',
    component: (onChange, value, index, error, customErrorText) => (
      <div
        className={classNames(styles.numericLongInput, styles.withExtraUnit)}
      >
        <div>
          <PositiveNumberInput
            value={value}
            name="powerConsumption"
            autoComplete="powerConsumption"
            onNumberChange={(value, name) => {
              onChange(`components.${index}.${name}`, value);
            }}
            error={error}
            customErrorText={customErrorText}
          />
        </div>
        <div className={styles.numericLongInputUnit}>kW</div>
      </div>
    ),
  },
];

export const siteOperatingHoursFields = [
  {
    name: 'timetableId',
    component: (
      onChange,
      timetableId,
      index,
      error,
      customErrorText,
      siteId
    ) => (
      <SelectTimeTable
        siteId={siteId}
        value={
          timetableId !== undefined && timetableId !== null
            ? timetableId.toString()
            : ''
        }
        includeNullSelectOption={false}
        canCreateTimeTable
        onSelect={(timetableId: string | number | null | undefined) =>
          onChange(`timetableId`, timetableId)
        }
        error={error}
        customErrorText={customErrorText}
      />
    ),
  },
];

export const equipmentOperatingHoursFields = [
  {
    name: 'timetableId',
    component: (
      onChange,
      timetableId,
      index,
      error,
      customErrorText,
      siteId
    ) => (
      <SelectTimeTable
        siteId={siteId}
        value={
          timetableId !== undefined && timetableId !== null
            ? timetableId.toString()
            : ''
        }
        includeNullSelectOption={false}
        canCreateTimeTable
        onSelect={(timetableId: string | number | null | undefined) =>
          onChange(`timetableId`, timetableId)
        }
        error={error}
        customErrorText={customErrorText}
      />
    ),
  },
];

export const SopTypeMapping = {
  [SopTypes.DOOR_OPENED_MAX_DURATION]: {
    title: SopTypeToLabel.DOOR_OPENED_MAX_DURATION,
    fields: doorOpenMaxDurationFields,
    displayFields: ['duration'],
    hint: 'Your maximum allowed door opening duration',
  },
  [SopTypes.ENERGY_TARIFF]: {
    title: SopTypeToLabel.ENERGY_TARIFF,
    fields: energyTariffFields,
    displayFields: ['tariff', 'currencyCode'],
    hint:
      'Your average cost for electrical energy per kWh from your utility bills.',
  },
  [SopTypes.HUMIDITY_RANGE]: {
    title: SopTypeToLabel.HUMIDITY_RANGE,
    fields: humidityRangeFields,
    displayFields: ['min', 'max'],
    hint:
      'Your required humidity range for selected equipment type(s) and spaces',
  },
  [SopTypes.LABOR_RATE]: {
    title: SopTypeToLabel.LABOR_RATE,
    fields: laborRateFields,
    displayFields: ['value', 'currencyCode'],
    hint: 'Your average labor cost per hour for staff working on site.',
  },
  [SopTypes.PRODUCT_VALUE]: {
    title: SopTypeToLabel.PRODUCT_VALUE,
    fields: productValueFields,
    displayFields: ['value', 'currencyCode'],
    hint: 'Your average product value at risk within refrigeration equipment ',
  },
  [SopTypes.MAINTENANCE_VISIT_RATE]: {
    title: SopTypeToLabel.MAINTENANCE_VISIT_RATE,
    fields: maintenanceVisitRateFields,
    displayFields: ['value', 'currencyCode'],
    hint: 'Your average cost for site level repair and maintenance visits.',
  },
  [SopTypes.NORMAL_POWER_CONSUMPTION]: {
    title: SopTypeToLabel.NORMAL_POWER_CONSUMPTION,
    fields: normalPowerConsumption,
    displayFields: ['powerConsumption'],
    hint: 'Your expected power consumption of a selected equipment type',
  },
  [SopTypes.EQUIPMENT_OPERATING_HOURS]: {
    title: SopTypeToLabel.EQUIPMENT_OPERATING_HOURS,
    fields: equipmentOperatingHoursFields,
    displayFields: ['timetableId'],
    hint:
      'Your equipment specific operating hours - link or configure an operating hours timetable.',
  },
  [SopTypes.SITE_OPERATING_HOURS]: {
    title: SopTypeToLabel.SITE_OPERATING_HOURS,
    fields: siteOperatingHoursFields,
    displayFields: ['timetableId'],
    hint:
      'Your site specific operating hours - link or configure an operating hours timetable.',
  },
  [SopTypes.TEMPERATURE_RANGE]: {
    title: SopTypeToLabel.TEMPERATURE_RANGE,
    fields: temperatureRangeFields,
    displayFields: ['min', 'max'],
    hint: 'Your required temperature range for selected equipment type(s)',
  },
};
