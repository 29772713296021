import {
  ComponentTypes,
  DoorOpenMaxDuration,
  EnergyTariff,
  HumidityRange,
  LaborRate,
  MaintenanceVisitRate,
  NormalPowerConsumption,
  ProductValue,
  SopTypes,
  TemperatureRange,
  TimeTable,
} from '@energybox/react-ui-library/dist/types';
import { TemperatureUnit } from '@energybox/react-ui-library/dist/types/Global';
import { global, convertTemp } from '@energybox/react-ui-library/dist/utils';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';

import React from 'react';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { formatDecimalValue } from '../../utils/numbers';
import OperatingHoursSopValue from './OperatingHoursSopValue';

type Props = {
  component: ComponentTypes;
  timeTable?: TimeTable;
};

const SopValue = ({ component, timeTable }: Props) => {
  const currentUser = useCurrentUser();

  if (!currentUser) return null;
  const { type } = component;
  let value;
  switch (type) {
    case SopTypes.PRODUCT_VALUE:
      value = `${formatDecimalValue((component as ProductValue).value)}`;
      break;
    case SopTypes.MAINTENANCE_VISIT_RATE:
      value = `${formatDecimalValue(
        (component as MaintenanceVisitRate).value
      )}`;
      break;
    case SopTypes.ENERGY_TARIFF:
      value = `${formatDecimalValue((component as EnergyTariff).tariff)}`;
      break;
    case SopTypes.LABOR_RATE:
      value = `${formatDecimalValue((component as LaborRate).value)}`;
      break;
    case SopTypes.DOOR_OPENED_MAX_DURATION:
      value = `Duration: ${(component as DoorOpenMaxDuration).duration || 0}`;
      break;
    case SopTypes.HUMIDITY_RANGE:
      value = `Min: ${(component as HumidityRange).min} / Max: ${
        (component as HumidityRange).max
      }`;
      break;
    case SopTypes.NORMAL_POWER_CONSUMPTION:
      value = `${formatDecimalValue(
        (component as NormalPowerConsumption).powerConsumption,
        1
      )}`;
      break;
    case SopTypes.TEMPERATURE_RANGE: {
      const temperatureUnit = getUserPreferenceTemperatureUnit(currentUser);
      let displayF = temperatureUnit === TemperatureUnit.F;
      const min = displayF
        ? convertTemp((component as TemperatureRange).min, 'c', 1)
        : formatDecimalValue((component as TemperatureRange).min, 1);
      const max = displayF
        ? convertTemp((component as TemperatureRange).max, 'c', 1)
        : formatDecimalValue((component as TemperatureRange).max, 1);
      value = `Min: ${min} / Max: ${max}`;
      break;
    }
    case SopTypes.SITE_OPERATING_HOURS:
    case SopTypes.EQUIPMENT_OPERATING_HOURS:
      value =
        (timeTable && <OperatingHoursSopValue timeTable={timeTable} />) ||
        global.NOT_AVAILABLE;
      break;
    default: {
      return <>{global.NOT_AVAILABLE}</>;
    }
  }

  return <span>{value}</span>;
};

export default SopValue;
