import { Tabs, Button } from '@energybox/react-ui-library/dist/components';
import { List, Map } from 'immutable';

import React from 'react';
import TabLink from '../../components/TabLink/TabLink';
import history from '../../history';
import useMedia from '../../hooks/useMedia';
import { Routes } from '../../routes';
import { NewButtonText } from '../../types/global';
import { getTimetablesRoute } from '../../utils/timeTables';
import FeatureFlag from '../FeatureFlag';
import styles from '../SiteDetailTabs/SiteDetailTabs.module.css';
import { useCurrentUser } from '../../hooks/useAppDetails';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { Role } from '@energybox/react-ui-library/dist/types';

interface Props {
  siteId: number;
  children?: React.ReactNode;
  onSopTypeCategoryChange: (id: string | number) => void;
}

interface Tab {
  to: string;
  displayName: string;
  featureFlag?: boolean;
}

enum SizeVariants {
  FULL = 'full',
  COMPACT = 'compact',
}

const getUserAccess = (user, organizationUnitId) => {
  return user.accessResources.reduce((hasAccess, resourceAccess) => {
    return (
      hasAccess ||
      ((resourceAccess.resourceId === +organizationUnitId ||
        resourceAccess.resourceId ===
          Number(process.env.REACT_APP_ENERGYBOX_ORG)) &&
        resourceAccess.role !== Role.VIEWER &&
        resourceAccess.role !== Role.MANAGER &&
        resourceAccess.role !== Role.INSTALLER)
    );
  }, false);
};

const DetermineActionButton = (onSopTypeCategoryChange, siteId) => {
  const pathname =
    (history && history.location && history.location.pathname) || '';
  const user = useCurrentUser();
  const orgId = useCurrentOrganizationId();
  const hasSiteAccess = getUserAccess(user, siteId);
  const hasOrgAccess = getUserAccess(user, orgId);

  let title = '';
  let dataVal = '';

  if (pathname.indexOf(`${Routes.POLICIES}`) > -1) {
    title = NewButtonText.SOP_POLICY;
    dataVal = 'policy';
  } else if (pathname.indexOf(`${Routes.COSTS}`) > -1) {
    title = NewButtonText.SOP_COST;
    dataVal = 'cost';
  } else if (pathname.indexOf(`${Routes.TIME_TABLES}`) > -1) {
    title = NewButtonText.TIME_TABLE;
    dataVal = 'timetable';
  } else if (pathname.indexOf(`${Routes.HVAC}`) > -1) {
    title = NewButtonText.SOP_HVAC;
    dataVal = 'hvac';
  } else if (pathname.indexOf(`${Routes.TASKS}`) > -1) {
    title = NewButtonText.SOP_TASK;
    dataVal = 'task';
  }

  if (title && (hasSiteAccess || hasOrgAccess)) {
    return (
      <div className={styles.actionButtonContainer}>
        <Button
          onClick={() => {
            onSopTypeCategoryChange(dataVal);
          }}
        >
          {title}
        </Button>
      </div>
    );
  } else {
    return '';
  }
};

const getTabs = (
  siteId: number
): [List<Tab>, Map<string, { index: number; displayName: string }>] => {
  const tabs = [
    {
      to: `${Routes.SITES}/${siteId}${Routes.SOPS}${Routes.POLICIES}`,
      displayName: 'Policies',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.SOPS}${Routes.COSTS}`,
      displayName: 'Cost',
      featureFlag: false,
    },
    {
      to: getTimetablesRoute({ orgUnitId: siteId }),
      displayName: 'Timetables',
      featureFlag: false,
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.SOPS}${Routes.HVAC}`,
      displayName: 'HVAC',
    },
    {
      to: `${Routes.SITES}/${siteId}${Routes.SOPS}${Routes.TASKS}`,
      displayName: 'Task',
    },
  ];

  let tabMap = Map<string, { index: number; displayName: string }>();

  tabs.forEach(({ to, displayName }, index) => {
    const splitRoute = to.split('/');
    tabMap = tabMap.set(`/${splitRoute[splitRoute.length - 1]}`, {
      index,
      displayName,
    });
  });
  return [List<Tab>(tabs), tabMap];
};

export const tabMapFn = (tab: Tab) => {
  if (tab.featureFlag)
    return (
      <FeatureFlag key={tab.to}>
        <TabLink to={tab.to}>{tab.displayName}</TabLink>
      </FeatureFlag>
    );
  return (
    <TabLink key={tab.to} to={tab.to}>
      {tab.displayName}
    </TabLink>
  );
};

const getRenderedTabs = (siteId: number, displayType: string) => {
  let tabs: List<Tab> | undefined;

  const [defaultTabs] = getTabs(siteId);

  tabs = defaultTabs;
  return tabs.map(tabMapFn);
};

const SopDetailTabs = ({
  siteId,
  children,
  onSopTypeCategoryChange,
}: Props) => {
  const columnCount = useMedia(
    ['only screen and (min-width : 1440px)'],
    [SizeVariants.FULL],
    SizeVariants.COMPACT
  );

  const button = DetermineActionButton(onSopTypeCategoryChange, siteId);
  const renderedTabs = getRenderedTabs(siteId, columnCount);

  return (
    <div>
      <Tabs className={styles.tabs}>
        {renderedTabs}
        {button}
      </Tabs>

      <div className={styles.content}> {children} </div>
    </div>
  );
};

export default SopDetailTabs;
