import initTracing from './telemetry/initialize';
initTracing();

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import '@energybox/react-ui-library/dist/root.css';
import { isDefined, withTheme } from '@energybox/react-ui-library/dist/utils';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { initApp, initAppWithToken } from './actions/app';
import DateFnsUtils from '@date-io/date-fns';
import store from './store';
import mixpanel from 'mixpanel-browser';

const AppWithTheme = withTheme(App);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AppWithTheme />
      </MuiPickersUtilsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

try {
  const fragment = window.location.hash.substring(1);
  const params = new URLSearchParams(fragment);
  if (params.has('accessToken')) {
    const accessToken = params.get('accessToken');
    store.dispatch(initAppWithToken({ accessToken }));
  } else {
    store.dispatch(initApp());
  }
} catch (err) {
  // access to window is blocked?
  store.dispatch(initApp());
}

const mp_session_config = {
  //thirty minutes in milliseconds
  timeout: 1800000,

  //safe client-side function for generating session_id
  //from: https://stackoverflow.com/a/105074
  get_Session_id: function() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  },

  //set a new session id
  set_Session_id: function() {
    mixpanel.register({
      'session ID': mp_session_config.get_Session_id(),
    });
  },

  //check for a new session id
  check_Session_id: function() {
    // check #1 do they have a session already?
    if (!mixpanel.get_property('last event time')) {
      mp_session_config.set_Session_id();
    }

    if (!mixpanel.get_property('session ID')) {
      mp_session_config.set_Session_id();
    }

    //check #2 did the last session exceed the timeout?
    if (
      Date.now() - mixpanel.get_property('last event time') >
      mp_session_config.timeout
    ) {
      mp_session_config.set_Session_id();
    }
  },
};

const initMixpanel = () => {
  if (isDefined(process.env.REACT_APP_MIXPANEL_TRACKING_ID)) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TRACKING_ID, {
      ignore_dnt: true,
      loaded: function(mixpanel) {
        //mixpanel has loaded

        //check for a session_id ... if any of the checks fail set a new session id
        mp_session_config.check_Session_id();

        //monkey patch to make sure that mixpanel.track() always checks for a session id
        const originalTrack = mixpanel.track;
        mixpanel.track = function() {
          // console.log('session checked');
          mp_session_config.check_Session_id();
          mixpanel.register({ 'last event time': Date.now() });
          originalTrack.apply(mixpanel, arguments);
        };

        // now write your own mixpanel tracking code, listeners, etc…
      },
    });
  }
};

initMixpanel();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
