import {
  FormText,
  Label,
  WeekdayPicker,
} from '@energybox/react-ui-library/dist/components';
import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import { Trash } from '@energybox/react-ui-library/dist/icons';
import { TimeTableRowStore } from '@energybox/react-ui-library/dist/types';
import {
  TimetableOverrideType,
  TimetableOverrideTypeLabel,
} from '@energybox/react-ui-library/dist/types/TimeTable';
import {
  classNames,
  convert24hrTo12HrFormat,
  WEEKDAYS,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import SelectTimeTableOverrideType from '../../../containers/Selects/SelectTimeTableOverrideType';
import { useIs12HrTimeFormat } from '../../../hooks/useAppDetails';
import { getWeekDayRange } from '../../../utils/getWeekdayRange';
import { weekdayToNumberArray } from '../../../utils/weekdayToNumberArray';
import ToggleItem from '../../ToggleItem';
import TimeTableTimeInput from '../TimeTableTimeInput';
import styles from './TimeTableRow.module.css';

interface Props {
  id: number;
  weekdayPickerId: string | number;
  isFirstItem?: boolean;
  shouldCollapse?: boolean;
  removable?: boolean;
  row: TimeTableRowStore;
  onChange: (rowIndex: number, rowValue: TimeTableRowStore) => void;
  onRemove: (rowIndex: number) => void;
  formErrorsVisible: boolean;
}

const TimeTableRow = ({
  id,
  weekdayPickerId,
  isFirstItem = false,
  shouldCollapse = false,
  row,
  onChange,
  onRemove,
  removable,
  formErrorsVisible,
}: Props) => {
  const is12HrTimeFormat = useIs12HrTimeFormat();

  function onWeekdaySelect(weekday: number) {
    const newWeekdays = row.weekdays.includes(WEEKDAYS[weekday])
      ? row.weekdays.filter(d => d !== WEEKDAYS[weekday])
      : [...row.weekdays, WEEKDAYS[weekday]];

    const newRow = {
      ...row,
      weekdays: newWeekdays,
    };

    onChange(id, newRow);
  }

  function onChangeField(fieldName: string, value: string) {
    let newRow;
    const isCustomTimeSelected =
      (fieldName === 'beginType' || fieldName === 'endType') &&
      value === 'CLOCK';

    if (fieldName === 'beginType') {
      newRow = {
        ...row,
        beginTime: isCustomTimeSelected ? '' : '0',
        [fieldName]: value,
      };
    } else if (fieldName === 'endType') {
      newRow = {
        ...row,
        endTime: isCustomTimeSelected ? '' : '0',
        [fieldName]: value,
      };
    } else {
      newRow = {
        ...row,
        [fieldName]: value,
      };
    }

    onChange(id, newRow);
  }

  function handleRemove(e) {
    e.preventDefault();

    onRemove(id);
  }

  function shouldStartOpen() {
    return !row.weekdays.length;
  }

  const stripDeltas = (delta: string) => {
    if (!delta) return 0;
    const lastChar = delta.substring(delta.length - 1, delta.length);
    if (lastChar === 'm') {
      return parseInt(delta.substring(0, delta.length - 1));
    }
    return delta;
  };

  function renderTimeHeader(
    beginType: string,
    endType: string,
    beginTime,
    endTime,
    overrideType
  ) {
    let beginText;
    let endText;
    let timeSubstr;

    const numericalEndDelta = stripDeltas(endTime);
    const numericalBeginDelta = stripDeltas(beginTime);

    if (overrideType !== TimetableOverrideType.AUTO) {
      if (overrideType === TimetableOverrideType.ALWAYS_ON) {
        beginText = TimetableOverrideTypeLabel.ALWAYS_ON;
      }
      if (overrideType === TimetableOverrideType.ALWAYS_OFF) {
        beginText = TimetableOverrideTypeLabel.ALWAYS_OFF;
      }
    } else {
      if (beginType === 'CLOCK' && beginTime) {
        timeSubstr = beginTime.substring(0, 5);
        beginText = is12HrTimeFormat
          ? convert24hrTo12HrFormat(timeSubstr)
          : timeSubstr;
      } else {
        if (numericalBeginDelta && numericalBeginDelta < 0) {
          beginText = `${Math.abs(
            // @ts-ignore
            numericalBeginDelta
          )} min before ${beginType.toLowerCase()}`;
        } else {
          beginText = `${numericalBeginDelta} min after ${beginType?.toLowerCase()}`;
        }
      }
      if (endType === 'CLOCK' && endTime) {
        timeSubstr = endTime.substring(0, 5);
        endText = is12HrTimeFormat
          ? convert24hrTo12HrFormat(timeSubstr)
          : timeSubstr;
      } else {
        if (numericalEndDelta && numericalEndDelta < 0) {
          endText = `${Math.abs(
            // @ts-ignore
            numericalEndDelta
          )} min before ${endType.toLowerCase()}`;
        } else {
          endText = `${numericalEndDelta} min after ${endType?.toLowerCase()}`;
        }
      }
    }

    return endText ? `${beginText} - ${endText}` : `${beginText}`;
  }

  const removeButton = removable ? (
    <button
      type="button"
      onClick={handleRemove}
      className={`${styles.invisibleButton} ${styles.trashButton}`}
    >
      <Trash size={16} />
    </button>
  ) : null;

  return (
    <div className={styles.container}>
      <Cell width="12">
        <ToggleItem
          startOpen={shouldStartOpen()}
          locked={formErrorsVisible && !!Object.keys(row.formErrors).length}
          isFirstItem={isFirstItem}
          shouldCollapse={shouldCollapse}
          header={
            <>
              <Cell width="4">{getWeekDayRange(row.weekdays)}</Cell>
              <Cell />
              <Cell width="6">
                {renderTimeHeader(
                  row.beginType,
                  row.endType,
                  row.beginTime,
                  row.endTime,
                  row.overrideType
                )}
              </Cell>
              <Cell cellAlign="right">{removeButton}</Cell>
            </>
          }
        >
          <div
            className={
              row.overrideType !== TimetableOverrideType.AUTO
                ? styles.rowCompress
                : styles.content
            }
          >
            <Cell cellAlign="right"></Cell>
            <Cell cellAlign="right">{removeButton}</Cell>
            <Cell className={classNames(styles.caption)}>
              <Label htmlFor={`${id}dayPicker`} required>
                Days
              </Label>
            </Cell>
            <Cell>
              <WeekdayPicker
                onChange={onWeekdaySelect}
                id={weekdayPickerId}
                selectedWeekdays={weekdayToNumberArray(row.weekdays)}
                error={formErrorsVisible && !!row.formErrors.weekdays}
              />
            </Cell>

            <>
              <Cell className={classNames(styles.caption, styles.rowTimeLabel)}>
                <Label htmlFor={`${id}overrideType`}>Operation Mode</Label>
              </Cell>
              <Cell className={classNames(styles.timeInput)}>
                <>
                  <SelectTimeTableOverrideType
                    value={row.overrideType}
                    onSelect={value => onChangeField('overrideType', value)}
                  />
                </>
              </Cell>
            </>

            <Cell
              className={classNames(
                row.overrideType !== TimetableOverrideType.AUTO
                  ? styles.remove
                  : styles.addRemoveTransition,
                styles.caption,
                styles.rowTimeLabel,
                styles.horizontalDivider
              )}
            >
              <Label htmlFor={`${id}startTime`} required>
                Start time
              </Label>
              <div
                className={classNames(
                  row.beginType !== 'CLOCK' ? styles.offsetLabel : styles.remove
                )}
              >
                <Label>Offset</Label>
              </div>
            </Cell>
            <Cell
              className={classNames(
                row.overrideType !== TimetableOverrideType.AUTO
                  ? styles.remove
                  : styles.addRemoveTransition,
                styles.timeInput,
                styles.horizontalDivider
              )}
            >
              <>
                <TimeTableTimeInput
                  className={row.overrideType !== 'AUTO' ? styles.hidden : ''}
                  id="beginTime"
                  name={`${id}startTime`}
                  value={stripDeltas(row.beginTime)}
                  type={row.beginType}
                  onChange={onChangeField}
                  error={formErrorsVisible && !!row.formErrors.beginTime}
                  customErrorText={
                    !!row.formErrors.beginTime && row.formErrors.beginTime[0]
                  }
                />
              </>
            </Cell>
            <Cell
              className={classNames(
                row.overrideType !== TimetableOverrideType.AUTO
                  ? styles.remove
                  : styles.addRemoveTransition,
                styles.caption,
                styles.rowTimeLabel
              )}
            >
              <Label htmlFor={`${id}endTime`} required>
                End Time
              </Label>
              <div
                className={classNames(
                  row.endType !== 'CLOCK' ? styles.offsetLabel : styles.remove
                )}
              >
                <Label>Offset</Label>
              </div>
            </Cell>
            <Cell
              className={classNames(
                row.overrideType !== TimetableOverrideType.AUTO
                  ? styles.remove
                  : styles.addRemoveTransition,
                styles.timeInput
              )}
            >
              <>
                {row.overrideType === 'AUTO' ? (
                  <TimeTableTimeInput
                    id="endTime"
                    name={`${id}endTime`}
                    value={stripDeltas(row.endTime)}
                    type={row.endType}
                    onChange={onChangeField}
                    error={formErrorsVisible && !!row.formErrors.endTime}
                    customErrorText={
                      !!row.formErrors.endTime && row.formErrors.endTime[0]
                    }
                  />
                ) : null}
              </>
            </Cell>

            {row.overrideType === TimetableOverrideType.AUTO &&
            (row.beginType !== 'CLOCK' || row.endType !== 'CLOCK') ? (
              <>
                <Cell />
                <Cell>
                  <FormText>
                    <span className={styles.formNotifier}>
                      You can trigger events based on the astronomical clock
                      (e.g., the time that dawn occurs). Negative values
                      correspond to a time before the event, while positive
                      values correspond to a time after.
                    </span>
                  </FormText>
                </Cell>
              </>
            ) : null}
          </div>
        </ToggleItem>
      </Cell>
    </div>
  );
};

export default TimeTableRow;
