import { Button } from '@energybox/react-ui-library/dist/components';
import { tileStyles } from '../InspectionTile';
import history from '../../../../history';

import styles from './SubmitStatusBar.module.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastSIRSubmission } from '../../../../actions/reportworker';
import { ApplicationState } from '../../../../reducers';
import { getRelativeTime } from '@energybox/react-ui-library/dist/utils/inspection';
import { useCurrentUser } from '../../../../hooks/useAppDetails';
import { useGetCurrentOrganization } from '../../../../hooks/useCurrentOrganization';
import { determineShouldHideFromInstaller } from '../../../../utils/user';

type Props = {
  siteId: string;
  currentTime: Date;
};

const SubmitStatusBar: React.FC<Props> = ({ siteId, currentTime }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLastSIRSubmission(siteId));
  }, []);

  const lastSubmission = useSelector<ApplicationState, string | undefined>(
    ({ reportworker }) => reportworker.lastSirSubmissions[siteId]
  );
  const currentUser = useCurrentUser();
  const currentOrganization = useGetCurrentOrganization();
  const hideFromInstaller = determineShouldHideFromInstaller(
    currentUser,
    currentOrganization
  );

  // A large give-in here: It was supposed to be  createdAt from file service
  // given the fileId (returned from the transmission endpoint) ie the PDF
  // generation time.
  // However, due to the status checking is api-based and its update time is
  // unpredictable, it needs careful design to fetch status change without
  // overloading the service.
  // The final decision was to rest on the inspection time, which is readily
  // available on FE and from transmission endpoint. The time will also be
  // refetched occasionally and will not reflect real-time and/or off-screen
  // update
  return (
    <div className={styles.statusBar}>
      <div className={tileStyles.fieldTitle}>
        Last Submitted Report Was Generated at:
      </div>
      <div className={tileStyles.fieldValue}>
        {lastSubmission
          ? getRelativeTime(new Date(lastSubmission + 'Z'), currentTime)
          : 'Never Submitted'}
      </div>
      {/* DM-185 hiding view report link from the installer as the submitted report pdf anyway is not accessable to the installer */}
      {!hideFromInstaller && (
        <>
          <div className={styles.verticalHr} />
          <Button
            variant="text"
            className={styles.viewReportButton}
            onClick={() => {
              history.push(`/sites/${siteId}/documents/installation-docs`);
            }}
          >
            View Report
          </Button>
        </>
      )}
    </div>
  );
};

export default SubmitStatusBar;
