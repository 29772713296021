import {
  InputField,
  Label,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  GenericErrors,
  OutsideOfTimeTable,
  TemperatureControl,
  TemperatureControlLabel,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import SelectTimeTable from '../../containers/Selects/SelectTimeTable';
import BiasInfoTooltip from '../BiasInfoTooltip';
import ModalFormContent from '../ModalFormContent';
import TemperatureInputField from '../TemperatureInputField';
import styles from './EditControlsTemperatureFormScheduleContent.module.css';

type Props = {
  siteId: number;
  onChange: (field: string, value: any) => void;
  fields: TemperatureControl;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
};

const EditControlsTemperatureFormScheduleContent = ({
  siteId,
  onChange,
  fields,
  formErrors,
  formErrorsVisible,
}: Props) => {
  const { timetableId, beginDelta, endDelta, outsideSetPoint } = fields;

  const timeTableNotSelected =
    (!timetableId || timetableId === -1) && timetableId !== 0;

  return (
    <>
      <ModalFormContent>
        <div>
          <Label htmlFor="timetableId">
            {TemperatureControlLabel.TIMETABLE}
          </Label>
        </div>
        <div>
          <SelectTimeTable
            siteId={siteId}
            value={
              timetableId !== undefined && timetableId !== null
                ? timetableId.toString()
                : ''
            }
            includeNullSelectOption
            onSelect={(timetableId: number) =>
              onChange('timetableId', timetableId)
            }
            error={formErrorsVisible && !!formErrors.timetableId}
          />
        </div>

        <div>
          <Label>{TemperatureControlLabel.BIAS}</Label>
        </div>
        <div className={styles.biasFieldContainer}>
          <div className={styles.biasField}>
            <span className={styles.marginRight}>Start</span>
            <div>
              <InputField
                disabled={timeTableNotSelected}
                className={styles.biasInput}
                type="number"
                value={beginDelta}
                onChange={({ currentTarget }) =>
                  onChange('beginDelta', currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.beginDelta}
                customErrorText={'Invalid'}
              />
            </div>
            <span className={styles.smallFont}>min(s)</span>
          </div>

          <div className={styles.biasColon}> : </div>

          <div className={styles.biasField}>
            <span className={styles.marginRight}>End</span>
            <div>
              <InputField
                disabled={timeTableNotSelected}
                value={endDelta}
                className={styles.biasInput}
                type="number"
                onChange={({ currentTarget }) =>
                  onChange('endDelta', currentTarget.value)
                }
                error={formErrorsVisible && !!formErrors.endDelta}
                customErrorText={'Invalid'}
              />
            </div>
            <span className={styles.smallFont}>min(s)</span>

            <span>
              <BiasInfoTooltip
                hasError={
                  formErrorsVisible &&
                  (!!formErrors.beginDelta || !!formErrors.endDelta)
                }
              />
            </span>
          </div>
        </div>

        <div>
          <Label>{TemperatureControlLabel.OUTSIDE_OF_TIMETABLE}</Label>
        </div>
        <div>
          <Select
            disabled={timeTableNotSelected}
            title={
              outsideSetPoint === null
                ? OutsideOfTimeTable.OFF
                : OutsideOfTimeTable.ALTERNATIVE_SET_POINTS
            }
          >
            <SelectItem
              isSelected={outsideSetPoint === null}
              onSelect={() => onChange('outsideSetPoint', null)}
            >
              {OutsideOfTimeTable.OFF}
            </SelectItem>
            <SelectItem
              isSelected={outsideSetPoint === 0}
              onSelect={() => onChange('outsideSetPoint', 0)}
            >
              {OutsideOfTimeTable.ALTERNATIVE_SET_POINTS}
            </SelectItem>
          </Select>
        </div>

        {(outsideSetPoint || outsideSetPoint === 0) && (
          <>
            <div>
              <Label htmlFor="outsideSetPoint">
                {TemperatureControlLabel.OUTSIDE_SET_POINT}
              </Label>
            </div>
            <TemperatureInputField
              className={styles.inputField}
              id="outsideSetPoint"
              name="outsideSetPoint"
              value={outsideSetPoint}
              onChange={v => onChange('outsideSetPoint', v)}
              error={formErrorsVisible && !!formErrors.outsideSetPoint}
              customErrorText={
                formErrors.outsideSetPoint && formErrors.outsideSetPoint[0]
              }
              disabled={timeTableNotSelected}
            />
          </>
        )}
      </ModalFormContent>
    </>
  );
};

export default EditControlsTemperatureFormScheduleContent;
