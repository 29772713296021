import {
  Sop,
  SopCostTypes,
  SopPolicyTypes,
  SopTypeCategory,
  SopTypes,
  ThermostatWorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  HvacSop,
  HvacSopTimeSlot,
} from '@energybox/react-ui-library/dist/types/Sop';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { ApplicationState } from '../reducers';
import { EditableFields } from '../reducers/sop';
import queryString from 'query-string';

const apiBase = '/api/v1/sop';

export enum Actions {
  GET_SOP_SUCCESS = '@sops/GET_SOP_SUCCESS',
  GET_SOP_ERROR = '@sops/GET_SOP_ERROR',
  GET_SOP_LOADING = '@sops/GET_SOP_LOADING',

  GET_SOPS_SUCCESS = '@sops/GET_SOPS_SUCCESS',
  GET_SOPS_ERROR = '@sops/GET_SOPS_ERROR',
  GET_SOPS_LOADING = '@sops/GET_SOPS_LOADING',

  GET_SOPS_BY_ORG_UNIT_ID_SUCCESS = '@sops/GET_SOPS_BY_ORG_UNIT_ID_SUCCESS',
  GET_SOPS_BY_ORG_UNIT_ID_ERROR = '@sops/GET_SOPS_BY_ORG_UNIT_ID_ERROR',
  GET_SOPS_BY_ORG_UNIT_ID_LOADING = '@sops/GET_SOPS_BY_ORG_UNIT_ID_LOADING',

  GET_ORGANIZATION_HVAC_SUCCESS = '@organizations/GET_ORGANIZATION_HVAC_SUCCESS',
  GET_ORGANIZATION_HVAC_ERROR = '@organizations/GET_ORGANIZATION_HVAC_ERROR',
  GET_ORGANIZATION_HVAC_LOADING = '@organizations/GET_ORGANIZATION_HVAC_LOADING',

  GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS = '@sops/GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS',
  GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING = '@sops/GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING',
  GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR = '@sops/GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR',

  GET_SOP_COMPONENTS_SUCCESS = '@sops/GET_SOP_COMPONENTS_SUCCESS',
  GET_SOP_COMPONENTS_LOADING = '@sops/GET_SOP_COMPONENTS_LOADING',
  GET_SOP_COMPONENTS_ERROR = '@sops/GET_SOP_COMPONENTS_ERROR',

  GET_EQUIPMENT_SOP_COMPONENTS_SUCCESS = '@sops/GET_EQUIPMENT_SOP_COMPONENTS_SUCCESS',
  GET_EQUIPMENT_SOP_COMPONENTS_LOADING = '@sops/GET_EQUIPMENT_SOP_COMPONENTS_LOADING',
  GET_EQUIPMENT_SOP_COMPONENTS_ERROR = '@sops/GET_EQUIPMENT_SOP_COMPONENTS_ERROR',

  GET_ORG_SOP_APPLIED_COUNTS_SUCCESS = '@sops/GET_ORG_SOP_APPLIED_COUNTS_SUCCESS',
  GET_ORG_SOP_APPLIED_COUNTS_LOADING = '@sops/GET_ORG_SOP_APPLIED_COUNTS_LOADING',
  GET_ORG_SOP_APPLIED_COUNTS_ERROR = '@sops/GET_ORG_SOP_APPLIED_COUNTS_ERROR',

  GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_SUCCESS = '@sops/GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_SUCCESS',
  GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_LOADING = '@sops/GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_LOADING',
  GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_ERROR = '@sops/GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_ERROR',

  DELETE_SOP_SUCCESS = '@sops/DELETE_SOP_SUCCESS',
  DELETE_SOP_ERROR = '@sops/DELETE_SOP_ERROR',
  DELETE_SOP_LOADING = '@sops/DELETE_SOP_LOADING',

  TOGGLE_NEW_OR_EDIT_POLICY_SOP_MODAL = '@sops/TOGGLE_NEW_OR_EDIT_POLICY_SOP_MODAL',
  TOGGLE_NEW_OR_EDIT_COST_SOP_MODAL = '@sops/TOGGLE_NEW_OR_EDIT_COST_SOP_MODAL',
  TOGGLE_NEW_OR_EDIT_HVAC_SOP_MODAL = '@sops/TOGGLE_NEW_OR_EDIT_HVAC_SOP_MODAL',

  CUSTOMIZE_ORG_SOP = '@sops/CUSTOMIZE_ORG_SOP',

  CHANGE_ACTIVE_SOP_TAB = '@sops/CHANGE_ACTIVE_SOP_TAB',
  CHANGE_SOP_TYPE = '@sops/CHANGE_SOP_TYPE',
  UPDATE_FIELD = '@sops/UPDATE_FIELD',
  RESET_EDIT_SOP = '@sops/RESET_EDIT_SOP',

  CREATE_SOP_LOADING = '@sops/CREATE_SOP_LOADING',
  CREATE_SOP_SUCCESS = '@sops/CREATE_SOP_SUCCESS',
  CREATE_COST_SOP_SUCCESS = '@sops/CREATE_COST_SOP_SUCCESS',
  CREATE_POLICY_SOP_SUCCESS = '@sops/CREATE_POLICY_SOP_SUCCESS',
  CREATE_HVAC_SOP_SUCCESS = '@sops/CREATE_HVAC_SOP_SUCCESS',
  CREATE_SOP_ERROR = '@sops/CREATE_SOP_ERROR',

  UPDATE_SOP_LOADING = '@sops/UPDATE_SOP_LOADING',
  UPDATE_SOP_SUCCESS = '@sops/UPDATE_SOP_SUCCESS',
  UPDATE_COST_SOP_SUCCESS = '@sops/UPDATE_COST_SOP_SUCCESS',
  UPDATE_POLICY_SOP_SUCCESS = '@sops/UPDATE_POLICY_SOP_SUCCESS',
  UPDATE_HVAC_SOP_SUCCESS = '@sops/UPDATE_HVAC_SOP_SUCCESS',
  UPDATE_SOP_ERROR = '@sops/UPDATE_SOP_ERROR',

  DISPLAY_FORM_ERRORS = '@sops/DISPLAY_FORM_ERRORS',
  TOGGLE_SOP_RECOMMENDATION = '@sops/TOGGLE_SOP_RECOMMENDATION',
}

export type GetSOPParams = {
  ids?: string[] | number[];
  limit?: number;
  organizationUnitIds?: string[] | number[];
  equipmentTypeIds?: string[] | number[];
  resourceIds?: string[] | number[];
  siblings?: boolean;
  skip?: number;
  textSearch?: string;
  types?: string[];
};

export const getSop = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_SOP_SUCCESS,
  error: Actions.GET_SOP_ERROR,
  loading: Actions.GET_SOP_LOADING,
});

export const getSops = (params: GetSOPParams = {}) => ({
  type: 'API_GET',
  path: `${apiBase}?${queryString.stringify(params)}`,
  success: Actions.GET_SOPS_SUCCESS,
  error: Actions.GET_SOPS_ERROR,
  loading: Actions.GET_SOPS_LOADING,
});

export const getSopByOrganizationalUnitId = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/organization-unit/${id}`,
  success: { type: Actions.GET_SOPS_BY_ORG_UNIT_ID_SUCCESS, id },
  error: { type: Actions.GET_SOPS_BY_ORG_UNIT_ID_ERROR, id },
  loading: { type: Actions.GET_SOPS_BY_ORG_UNIT_ID_LOADING, id },
});

export const getSopComponentsByResourceId = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/components/resource/${id}`,
  success: { type: Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS, id },
  error: { type: Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR, id },
  loading: { type: Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING, id },
});

export const getSopComponents = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/components/resources/${id}`,
  success: Actions.GET_SOP_COMPONENTS_SUCCESS,
  error: Actions.GET_SOP_COMPONENTS_ERROR,
  loading: Actions.GET_SOP_COMPONENTS_LOADING,
});

export const getOrgSopAppliedCounts = (orgId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/resource/count/${orgId}`,
  success: { type: Actions.GET_ORG_SOP_APPLIED_COUNTS_SUCCESS, orgId },
  error: { type: Actions.GET_ORG_SOP_APPLIED_COUNTS_ERROR, orgId },
  loading: { type: Actions.GET_ORG_SOP_APPLIED_COUNTS_LOADING, orgId },
});

export const getOrgSopAppliedCountsByEquipmentType = (
  orgId: string | number,
  equipmentTypeIds: number[]
) => ({
  type: 'API_GET',
  path: `${apiBase}/resource/count/${orgId}/byEquipmentType?equipmentTypeIds=${equipmentTypeIds.join(
    ','
  )}`,
  success: {
    type: Actions.GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_SUCCESS,
    orgId,
  },
  error: {
    type: Actions.GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_ERROR,
    orgId,
  },
  loading: {
    type: Actions.GET_ORG_SOP_APPLIED_COUNTS_BY_EQUIPMENT_TYPE_LOADING,
    orgId,
  },
});

export const getOrganizationHvacData = (id: Number) => ({
  type: 'API_GET',
  path: `/api/v1/organizations/${id}/views/controls/hvac`,
  success: Actions.GET_ORGANIZATION_HVAC_SUCCESS,
  error: Actions.GET_ORGANIZATION_HVAC_ERROR,
  loading: Actions.GET_ORGANIZATION_HVAC_LOADING,
});

export const destroy = (
  id: string | number,
  options?: { produceEdgeConfigByNetworkGroupIds?: number[]; orgId?: number }
) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${id}`,
  loading: { type: Actions.DELETE_SOP_LOADING, id },
  success: options?.orgId
    ? [
        { type: Actions.DELETE_SOP_SUCCESS, id, options },
        getOrganizationHvacData(options.orgId),
      ]
    : { type: Actions.DELETE_SOP_SUCCESS, id, options },
  error: { type: Actions.DELETE_SOP_ERROR, id },
});

export const reset = (id: string | number) => ({
  type: Actions.RESET_EDIT_SOP,
  id,
});

export const showNewOrEditCostSopModal = (selectedSopType?: SopCostTypes) => ({
  type: Actions.TOGGLE_NEW_OR_EDIT_COST_SOP_MODAL,
  value: true,
  selectedSopType,
});

export const hideNewOrEditCostSopModal = () => ({
  type: Actions.TOGGLE_NEW_OR_EDIT_COST_SOP_MODAL,
  value: false,
});

export const showNewOrEditPolicySopModal = (
  selectedSopType?: SopPolicyTypes
) => ({
  type: Actions.TOGGLE_NEW_OR_EDIT_POLICY_SOP_MODAL,
  value: true,
  selectedSopType,
});

export const hideNewOrEditPolicySopModal = () => ({
  type: Actions.TOGGLE_NEW_OR_EDIT_POLICY_SOP_MODAL,
  value: false,
});

export const showNewOrEditHvacSopModal = (
  id: string | number,
  siteId: string | number,
  equipmentTypeId?: number
) => ({
  type: Actions.TOGGLE_NEW_OR_EDIT_HVAC_SOP_MODAL,
  value: true,
  id,
  siteId,
  equipmentTypeId,
});

export const hideNewOrEditHvacSopModal = (id: string | number) => ({
  type: Actions.TOGGLE_NEW_OR_EDIT_HVAC_SOP_MODAL,
  value: false,
  id,
});

export const customizeOrganizationSop = (
  value: Sop,
  sopType: SopTypeCategory
) => ({
  type: Actions.CUSTOMIZE_ORG_SOP,
  value,
  sopType,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const toggleRecommendation = value => ({
  type: Actions.TOGGLE_SOP_RECOMMENDATION,
  value,
});

export const changeSopType = (id: string, value: SopTypes) => ({
  type: Actions.CHANGE_SOP_TYPE,
  id,
  value,
});

export const changeActiveSopTab = (activeSopTab: string) => (
  dispatch,
  getState
) => {
  const currActiveSopTab = (getState() as ApplicationState).sops.activeSopTab;
  if (currActiveSopTab !== activeSopTab) {
    dispatch({
      type: Actions.CHANGE_ACTIVE_SOP_TAB,
      activeSopTab,
    });
  }
};

export const create = (
  category: string,
  updateId?: string | number,
  isOrgLevelHvac?: boolean
) => (dispatch, getState) => {
  let updateSopSuccessAction = Actions.CREATE_SOP_SUCCESS;
  if (category === 'cost') {
    updateSopSuccessAction = Actions.CREATE_COST_SOP_SUCCESS;
  } else if (category === 'policy') {
    updateSopSuccessAction = Actions.CREATE_POLICY_SOP_SUCCESS;
  } else if (category === 'HVAC') {
    updateSopSuccessAction = Actions.CREATE_HVAC_SOP_SUCCESS;
  }

  const fields = (getState() as ApplicationState).sops.editById['new'].fields;
  const payload = category === 'HVAC' ? transformHvacSopFields(fields) : fields;

  dispatch({
    type: 'API_POST',
    path: `${apiBase}`,
    payload,
    // Check for stale SOP data using resource/entity ID
    // when fetched by entities/resourceIds
    // only available for create as of now
    // don't have time
    // TODO: extend this to other actions as needed
    loading: { type: Actions.CREATE_SOP_LOADING, updateId },
    success:
      isOrgLevelHvac && updateId
        ? [
            { type: updateSopSuccessAction, updateId },
            getOrganizationHvacData(Number(updateId)),
          ]
        : { type: updateSopSuccessAction, updateId },
    error: { type: Actions.CREATE_SOP_ERROR, updateId },
  });
};

const transformHvacSopFields = fields => {
  const mappedFields = mapHvacSopFieldsToCreate(fields);

  const payload = {
    id: mappedFields.id,
    title: mappedFields.title,
    description: mappedFields.description,
    organizationUnitId: mappedFields.organizationUnitId,
    equipmentTypeIds: mappedFields.equipmentTypeId,
    components: [
      {
        type: 'HVAC',
        hvacSettings: {
          overrideTimer: mappedFields.overrideTimer,
          enableEdm: mappedFields.enableEdm,
          enableLocalAdjustment: mappedFields.enableLocalAdjustment,
          thermostatDisplayUnits: mappedFields.thermostatDisplayUnits,
          setPointLimitDelta: mappedFields.setPointLimitDelta,
          hvacSchedules: mappedFields.hvacTimeSlots,
        },
      },
    ],
  };

  return payload;
};

// The SOP object returned by the BE is different from the structure expected by
// the create endpoint. In particular the `hvacSchedules` property has a
// `timetable: TimeTable` property when you fetch the SOPs, whereas the create
// just expects `rows` directly
const mapHvacSopFieldsToCreate = (hvacSopFields: EditableFields) => {
  const {
    organizationUnitId,
    title,
    description,
    equipmentTypeIds,
    components,
  } = hvacSopFields;
  const {
    type,
    hvacSettings: {
      enableEdm,
      enableLocalAdjustment,
      setPointLimitDelta,
      overrideTimer,
      thermostatDisplayUnits,
      hvacSchedules,
    },
  } = components[0] as HvacSop;
  const id = isDefined((hvacSopFields as any).id)
    ? (hvacSopFields as any).id
    : undefined;

  const determineHvacSopScheduleDefaultValue = (
    hvacSchedule: HvacSopTimeSlot,
    index: number
  ) => {
    const { hvacScheduleType } = hvacSchedule;

    // only first OCCUPIED schedule should be default: true
    // assumption is that the UNOCCUPIED schedule is sorted to be last
    if (hvacScheduleType === 'OCCUPIED' && index === 0) {
      return true;
    }

    return false;
  };

  const hvacTimeSlots = hvacSchedules.map((hvacSchedule, index) => {
    const {
      timetable,
      thermostatWorkingMode,
      minTemp,
      maxTemp,
      ...rest
    } = hvacSchedule;
    const isThermostatWorkingModeOff =
      thermostatWorkingMode === ThermostatWorkingMode.OFF;

    return {
      ...rest,
      thermostatWorkingMode,
      minTemp: isThermostatWorkingModeOff ? undefined : minTemp,
      maxTemp: isThermostatWorkingModeOff ? undefined : maxTemp,
      default: determineHvacSopScheduleDefaultValue(hvacSchedule, index),
      rows:
        isDefined(timetable) && isDefined(timetable.rows)
          ? timetable.rows
          : undefined,
    };
  });

  return {
    organizationUnitId,
    id,
    title,
    description,
    policyType: type,
    attachedTo: '',
    equipmentTypeId: equipmentTypeIds,
    thermostatDisplayUnits,
    enableEdm,
    enableLocalAdjustment,
    setPointLimitDelta,
    overrideTimer,
    hvacTimeSlots,
  };
};

export const update = (
  id: string | number,
  category: string,
  options?: {
    produceEdgeConfigByNetworkGroupIds?: (string | number | undefined)[];
    orgId?: number;
  }
) => (dispatch, getState) => {
  const fields = (getState() as ApplicationState).sops.editById[id].fields;
  const payload = category === 'HVAC' ? transformHvacSopFields(fields) : fields;

  let updateSopSuccessAction = Actions.UPDATE_SOP_SUCCESS;

  if (category == 'cost') {
    updateSopSuccessAction = Actions.UPDATE_COST_SOP_SUCCESS;
  } else if (category == 'policy') {
    updateSopSuccessAction = Actions.UPDATE_POLICY_SOP_SUCCESS;
  } else if (category === 'HVAC') {
    updateSopSuccessAction = Actions.UPDATE_HVAC_SOP_SUCCESS;
  }

  dispatch({
    type: 'API_PUT',
    path: `${apiBase}/${id}`,
    payload,
    loading: Actions.UPDATE_SOP_LOADING,
    success: options?.orgId
      ? [
          { type: updateSopSuccessAction, options },
          getOrganizationHvacData(options.orgId),
        ]
      : { type: updateSopSuccessAction, options },
    error: { type: Actions.UPDATE_SOP_ERROR, id },
  });
};

export const displayFormErrors = (id: string | number) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});
