import { Actions } from '../actions/run_configuration';
import * as R from 'ramda';

export interface RunConfiguration {
  showStartSequenceModal: boolean;
  showEndSequenceModal: boolean;
  disableStartSequence: boolean;
  disableRunTestButton: boolean;
}

const initialState = {
  showStartSequenceModal: false,
  showEndSequenceModal: false,
  disableRunTestButton: false,
  disableStartSequence: false,
};

const runConfiguration = (
  state: RunConfiguration = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.TOGGLE_OPEN_START_SEQUENCE_MODAL:
      return R.assoc('showStartSequenceModal', action.value, state);

    case Actions.TOGGLE_CLOSE_START_SEQUENCE_MODAL:
      return R.assoc('showStartSequenceModal', action.value, state);

    case Actions.TOGGLE_OPEN_END_SEQUENCE_MODAL:
      return R.assoc('showEndSequenceModal', action.value, state);

    case Actions.TOGGLE_CLOSE_END_SEQUENCE_MODAL:
      return R.assoc('showEndSequenceModal', action.value, state);

    case Actions.TOGGLE_DISABLE_RUN_TEST_BUTTON:
      return R.assoc('disableRunTestButton', action.value, state);

    case Actions.TOGGLE_ENABLE_RUN_TEST_BUTTON:
      return R.assoc('disableRunTestButton', action.value, state);

    case Actions.TOGGLE_DISABLE_START_SEQUENCE:
      return R.assoc('disableStartSequence', action.value, state);

    case Actions.TOGGLE_ENABLE_START_SEQUENCE:
      return R.assoc('disableStartSequence', action.value, state);

    default:
      return state;
  }
};
export default runConfiguration;
