import { FileDetail } from '@energybox/react-ui-library/dist/types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JsxElement } from 'typescript';
import { deleteFilesById, getFilesBySiteId } from '../../actions/files';
import Actions from '../../actions/FileActions';
import { ApplicationState } from '../../reducers';
import { DeleteInfo, FileInfo } from '../../reducers/files';
import { FileType } from '../../types/files';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

export const useFiles = (params: {
  siteId?: number | string;
  fileType: FileType;
  page?: string;
}): {
  isLoading: boolean;
  files: FileDetail[];
  getFiles: () => void;
} => {
  const { siteId, fileType } = params;
  const page = params.page ?? `${siteId}-${fileType}`;

  const dispatch = useDispatch();

  const getFiles = () =>
    dispatch(
      getFilesBySiteId({
        siteId: siteId || '',
        fileType,
        page,
      })
    );

  const filesInfo =
    useSelector<ApplicationState, FileInfo>(({ files }) => files.pages[page]) ||
    {};

  useEffect(() => {
    getFiles();
  }, []);

  return {
    isLoading: filesInfo.isLoading ?? false,
    files: filesInfo.files ?? [],
    getFiles,
  };
};

export const useDeleteFile = (params: {
  siteId?: number | string;
  fileType: FileType;
  page?: string;
}): {
  fileToDelete: FileDetail | null;
  setFileToDelete: (fileId: FileDetail | null) => void;
  onDelete: () => void;
  isLoading: boolean;
  error?: JSX.Element;
} => {
  const [fileToDelete, setFileToDelete] = useState<FileDetail | null>(null);
  const [errorElement, setErrorElement] = useState<JSX.Element>();
  const { siteId, fileType } = params;
  const page = params.page ?? `${siteId}-${fileType}`;
  const { getFiles } = useFiles({ siteId, fileType, page });

  const dispatch = useDispatch();

  const deleteInfo =
    useSelector<ApplicationState, DeleteInfo>(
      ({ files }) => files.pages[page]?.deleteAction
    ) || {};

  const onDelete = () => {
    if (!fileToDelete) return;
    dispatch(
      deleteFilesById({
        fileId: fileToDelete.fileId,
        page,
        callback: res => {
          if (res.error) return;
          setFileToDelete(null);
          getFiles();
        },
      })
    );
  };

  useEffect(() => {
    setErrorElement(
      renderAPIerror(
        {
          type: Actions.DELETE_FILES_BY_ID_ERROR,
          status: 0,
          message: deleteInfo.error?.['message'] ?? '',
        },
        Actions.DELETE_FILES_BY_ID_ERROR
      )
    );
  }, [deleteInfo.error]);

  return {
    fileToDelete,
    setFileToDelete,
    onDelete,
    isLoading: deleteInfo.isLoading ?? false,
    error: errorElement,
  };
};
