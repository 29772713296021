import { useChartZoomUtils } from '@energybox/react-ui-library/dist/hooks';
import {
  AggregationFilter,
  AggregationLevel,
  FilterTimePeriod,
  Locale,
  ZoomRange,
} from '@energybox/react-ui-library/dist/types';
import {
  determineDateRangeAfterZoomIn,
  generateXTicks,
  getDefaultAggregationInterval,
  getStartOfDay,
  getCurrentTime,
  genericDateFormat,
} from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';

import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { getDateLast15MinuteStep } from '../utils/dates';

export type ChartDetailOptions = {
  includeAggregationFilter?: boolean;
  timezone?: string;
  useCurrentTime?: boolean;
  useZoomUtils?: boolean;
};

export type ChartDetailInfo = {
  dateFilterTitle: string;
  dateFilterFromDate: string;
  dateFilterToDate: string;
  fromDate: string;
  toDate: string;
  xTicks: number[];
  setDateFilter: (filter: FilterTimePeriod) => void;
  zoomIn: () => void;
  zoom?: (zoomRange?: ZoomRange) => void; // for improving zoom perforcemance
  zoomRange: ZoomRange;
  zoomOut: () => void;
  isZoomedIn: boolean;
  setRefAreaStart: Dispatch<SetStateAction<string>>;
  setRefAreaEnd: Dispatch<SetStateAction<string>>;
  refAreaStart: string;
  refAreaEnd: string;
  aggregationFilter: AggregationFilter | undefined;
  setAggregationFilter: Dispatch<SetStateAction<AggregationFilter | undefined>>;
  isMonthly?: boolean;
  isCustomRange?: boolean;
};

const useChartDetails = (
  locale: Locale,
  options: ChartDetailOptions = {}
): ChartDetailInfo => {
  const {
    includeAggregationFilter = false,
    timezone,
    useCurrentTime,
    useZoomUtils,
  } = options;

  const currentTimeMs = useCurrentTime
    ? new Date().valueOf()
    : getDateLast15MinuteStep().valueOf();
  const currentTimeIso = DateTime.fromMillis(currentTimeMs)
    .setZone(timezone)
    .toISO();
  const startOfTodayIso = getStartOfDay(getCurrentTime(), timezone);

  const [dateFilterRange, setDateFilterRange] = useState({
    dateFilterFromDate: startOfTodayIso,
    dateFilterToDate: currentTimeIso,
  });
  const { dateFilterFromDate, dateFilterToDate } = dateFilterRange;
  const [dateRange, setDateRange] = useState({
    fromDate: startOfTodayIso,
    toDate: currentTimeIso,
  });
  const { fromDate, toDate } = dateRange;
  const [dateFilterTitle, setDateFilterTitle] = useState('Today');

  const timePeriod = useMemo(
    () => ({
      fromDate: new Date(fromDate),
      toDate: new Date(toDate),
    }),
    [fromDate, toDate]
  );

  const {
    zoomStartTime,
    zoomEndTime,
    zoomIn: zoomInFromZoomUtils,
    isZoomed,
    zoomReset,
    xTicks: xTicksFromZoomUtils,
  } = useChartZoomUtils({
    timePeriod: timePeriod,
    timezone,
  });
  const [refAreaStart, setRefAreaStart] = useState('');
  const [refAreaEnd, setRefAreaEnd] = useState('');
  const [aggregationFilter, setAggregationFilter] = useState<
    AggregationFilter | undefined
  >(
    includeAggregationFilter
      ? AggregationLevel.values[AggregationLevel.ONE_MINUTE]
      : undefined
  );
  const [isMonthly, setIsMonthly] = useState<boolean | undefined>();
  const [isCustomRange, setIsCustomRange] = useState<boolean | undefined>();

  const xTicks = useMemo(() => {
    return generateXTicks(
      DateTime.fromISO(fromDate).toMillis(),
      DateTime.fromISO(toDate).toMillis(),
      { ianaTimeZone: timezone }
    );
  }, [fromDate, toDate]);

  const updateAggregationFilter = (timeDelta: number) => {
    if (includeAggregationFilter) {
      const aggregationFilter = getDefaultAggregationInterval(timeDelta);
      setAggregationFilter(aggregationFilter);
    }
  };

  const setDateFilter = (filter: FilterTimePeriod) => {
    const { fromDate, toDate, title, isCustomRange, isMonthly } = filter;

    if (!fromDate || !toDate) {
      return;
    }
    const fromDateTime = DateTime.fromJSDate(fromDate).setZone(timezone);
    const fromDateMs = fromDateTime.toMillis();
    const fromDateIso = fromDateTime.toISO();
    const toDateTime = DateTime.fromJSDate(toDate).setZone(timezone);
    const toDateMs = toDateTime.toMillis();
    const toDateIso = toDateTime.toISO();
    const timeDifferenceMs = toDateMs - fromDateMs;

    const buttonTitle =
      title ||
      `${genericDateFormat(
        fromDate,
        locale.dateFormat,
        timezone
      )} - ${genericDateFormat(toDate, locale.dateFormat, timezone)}`;

    updateAggregationFilter(timeDifferenceMs);
    setDateFilterTitle(buttonTitle);
    setDateFilterRange({
      dateFilterFromDate: fromDateIso,
      dateFilterToDate: toDateIso,
    });
    setDateRange({ fromDate: fromDateIso, toDate: toDateIso });
    setIsMonthly(isMonthly);
    setIsCustomRange(isCustomRange);
  };

  const zoomOut = () => {
    const timeDifferenceMs =
      DateTime.fromISO(dateFilterToDate).toMillis() -
      DateTime.fromISO(dateFilterFromDate).toMillis();

    updateAggregationFilter(timeDifferenceMs);
    setDateRange({ fromDate: dateFilterFromDate, toDate: dateFilterToDate });
  };

  const zoomIn = () => {
    const refAreaStartNumber = parseInt(refAreaStart);
    const refAreaEndNumber = parseInt(refAreaEnd);

    if (refAreaStart === refAreaEnd || refAreaEnd === '') {
      setRefAreaStart('');
      setRefAreaEnd('');
      return;
    }

    const {
      fromDate: newFromDate,
      toDate: newToDate,
    } = determineDateRangeAfterZoomIn(refAreaStartNumber, refAreaEndNumber);

    updateAggregationFilter(newToDate - newFromDate);
    setDateRange({
      fromDate: DateTime.fromMillis(newFromDate).toISO(),
      toDate: DateTime.fromMillis(newToDate).toISO(),
    });
    setRefAreaStart('');
    setRefAreaEnd('');
  };

  const isZoomedIn =
    dateFilterFromDate !== fromDate || dateFilterToDate !== toDate;

  return {
    dateFilterTitle,
    dateFilterFromDate,
    dateFilterToDate,
    fromDate,
    toDate,
    xTicks,
    setDateFilter,
    zoom: zoomInFromZoomUtils,
    zoomRange: {
      from: zoomStartTime,
      to: zoomEndTime,
    },
    zoomIn,
    zoomOut: useZoomUtils ? zoomReset : zoomOut,
    isZoomedIn: useZoomUtils ? isZoomed : isZoomedIn,
    setRefAreaStart,
    setRefAreaEnd,
    refAreaStart,
    refAreaEnd,
    aggregationFilter,
    setAggregationFilter,
    isMonthly,
    isCustomRange,
  };
};

export default useChartDetails;
