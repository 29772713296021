import { Button } from '@energybox/react-ui-library/dist/components';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { clearSentinelsStore, getSentinels } from '../../actions/sentinels';
import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';
import history from '../../history';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import SentinelsTable, { SentinelTableColumns } from './SentinelsTable';
import TabLink from '../../components/TabLink';
import RemindersTable, { ReminderTableColumns } from './RemindersTable';
import { getReminders } from '../../actions/reminder';

interface Props {}

const sentinelTableColumns = [
  SentinelTableColumns.CONFIG,
  SentinelTableColumns.RECIPIENTS,
  SentinelTableColumns.TARGETS,
  SentinelTableColumns.MUTED_TARGETS,
  // SentinelTableColumns.CHANNEL_TYPE,
  SentinelTableColumns.RESUME_CONFIG,
  SentinelTableColumns.MUTE,
  // Declare the new column name for duplicate a sentinel select box
  SentinelTableColumns.DUBLICATE_SENTINEL,
];

const reminderTableColumns = [
  ReminderTableColumns.CONFIG,
  ReminderTableColumns.RECIPIENTS,
  ReminderTableColumns.SITES,
  ReminderTableColumns.EQUIPMENTS,
  ReminderTableColumns.RESUME_CONFIG,
  ReminderTableColumns.ACTIONS,
];

const environment = process.env.REACT_APP_SERVICE_ENV || '';
const azurePattern = new RegExp(/(az-china)/);

function SentinelsIndexPage(props: Props) {
  const dispatch = useDispatch();
  const path = history.location.pathname;
  const [shouldRefetchSentinels, setShouldRefetchSentinels] = useState(true);

  const sentinels = useSelector(({ sentinels }: ApplicationState) =>
    Object.values(sentinels.byId)
  );
  const getSentinelsIsLoading = useSelector(
    ({ sentinels }: ApplicationState) => sentinels.isLoading
  );
  const pendingMuteTargetsUpdate = useSelector(
    ({ sentinels }: ApplicationState) => sentinels.pendingMuteTargetsUpdates
  );
  const {
    reminders,
    isLoading: isReminderLoading,
    isInitialized,
  } = useSelector(({ reminder }: ApplicationState) => reminder);

  useEffect(() => {
    if (pendingMuteTargetsUpdate === 0) {
      setShouldRefetchSentinels(true);
    }
  }, [pendingMuteTargetsUpdate, setShouldRefetchSentinels]);

  useEffect(() => {
    if (!shouldRefetchSentinels) return;
    dispatch(clearSentinelsStore());
    dispatch(getSentinels());
    setShouldRefetchSentinels(false);
  }, [sentinels, dispatch, shouldRefetchSentinels, setShouldRefetchSentinels]);

  useEffect(() => {
    if (!isInitialized) {
      dispatch(getReminders());
    }
  }, []);

  const actions =
    path === Routes.SENTINELS ? (
      <>
        <Button onClick={() => history.push(`${Routes.SENTINELS}/create`)}>
          New Sentinel
        </Button>
      </>
    ) : (
      <>
        <Button onClick={() => history.push(`${Routes.REMINDER}/create`)}>
          New Reminder
        </Button>
      </>
    );

  return (
    <IndexManagementPageLayout
      tabs={
        <>
          <TabLink to={Routes.SENTINELS}>Sentinel</TabLink>
          {!azurePattern.test(environment) && (
            <TabLink to={Routes.REMINDER}>Reminder</TabLink>
          )}
        </>
      }
      actions={actions}
      content={
        path === Routes.SENTINELS ? (
          <SentinelsTable
            dataIsLoading={getSentinelsIsLoading}
            sentinels={sentinels}
            columns={sentinelTableColumns}
            hasSearch
          />
        ) : (
          <RemindersTable
            dataIsLoading={isReminderLoading}
            reminders={reminders}
            columns={reminderTableColumns}
            hasSearch
          />
        )
      }
    />
  );
}

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(SentinelsIndexPage);
