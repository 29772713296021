import {
  Loader,
  MultiSelect,
  MultiSelectItem,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import { User } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import styles from './MultiSelects.module.css';
import { UsersById } from '../../reducers/users';
import { getUsers } from '../../actions/users';

interface Props {
  onSelect: (equipmentTypeId: number) => void;
  onToggleSelectAll?: (ids: number[]) => void;
  onDeselect: (equipmentTypeId: number) => void;
  showSelectAll?: boolean;
  selectedUserIds: number[];
  disabled?: boolean;
}

const MultiSelectOrgAccess: React.FC<Props> = ({
  onSelect,
  onToggleSelectAll,
  onDeselect,
  showSelectAll = true,
  selectedUserIds,
  disabled,
}) => {
  const [query, setQuery] = useState('');

  const dispatch = useDispatch();

  const { usersById, users } = useSelector<
    ApplicationState,
    {
      usersById: UsersById;
      users: User[];
    }
  >(({ users }) => ({
    usersById: users.usersById,
    users: Object.values(users.usersById),
  }));

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const filteredUsers = users.filter(
    ({ firstName, lastName }) =>
      hasSubstr(firstName, query) || hasSubstr(lastName, query)
  );

  const toggleSelectAll = () => {
    onToggleSelectAll?.(
      selectedUserIds.length > 0 ? [] : filteredUsers.map(org => org.id) || []
    );
  };

  const onItemClick = id => {
    selectedUserIds.indexOf(id) === -1 ? onSelect(id) : onDeselect(id);
    setQuery('');
  };

  if (!users) {
    return <Loader size={12} />;
  }

  return (
    <div className={disabled ? styles.disabled : ''}>
      <MultiSelect
        doNotIncludeActions
        selectionComponentType="portalDropdown"
        onDeleteItem={(_, itemId) => onDeselect(Number(itemId))}
        items={selectedUserIds.map(selectedId => ({
          value: `${usersById[selectedId].firstName} ${usersById[selectedId].lastName}`,
          className: '',
          id: selectedId,
        }))}
        selectionComponent={
          <div className={styles.dropdown}>
            <div className={styles.searchBox}>
              <SearchBox
                error={filteredUsers.length === 0}
                withDropdown={false}
                query={query}
                width={'100%'}
                widthActive={'100%'}
                placeholder={'Search User'}
                onChange={setQuery}
                noClickOutsideDetection
              />
            </div>
            <div className={styles.dropdownList}>
              {showSelectAll && (
                <div className={styles.selectAll}>
                  <span
                    tabIndex={0}
                    onKeyPress={toggleSelectAll}
                    role="button"
                    onClick={toggleSelectAll}
                  >
                    {selectedUserIds.length > 0 ? 'Deselect All' : 'Select All'}
                  </span>
                </div>
              )}
              {filteredUsers.map(({ firstName, lastName, id }) => (
                <MultiSelectItem
                  isSelected={selectedUserIds.indexOf(id) > -1}
                  onSelect={() => onItemClick(id)}
                >
                  {firstName} {lastName}
                </MultiSelectItem>
              ))}
            </div>
          </div>
        }
        onSelection={onSelect}
      />
    </div>
  );
};

export default MultiSelectOrgAccess;
