import { Loader } from '@energybox/react-ui-library/dist/components';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';
import { useGetEdgeConfigUpdateByEdgeId } from '../../hooks/useGetEdgeConfigUpdate';
import styles from './LastNetworkGroupConfigUpdate.module.css';

type Props = {
  networkGroup: NetworkGroup;
};

const EdgeConfigStatus: React.FC<Props> = ({ networkGroup }) => {
  const edgeId = networkGroup?.edge?.id!;

  const { shouldEdgeConfigStillBeUpdating } = useGetEdgeConfigUpdateByEdgeId(
    edgeId
  );
  return (
    <div>
      <div>
        {shouldEdgeConfigStillBeUpdating ? (
          <div className={styles.loader}>
            <span className={styles.label}> Update Sent </span>
            <span>
              <Loader size={14} />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EdgeConfigStatus;
