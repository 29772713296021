import {
  MenuDropdown,
  MenuDropdownItem,
  Button,
  Card,
  CardActions,
  CardContent,
  CardTitle,
  Loader,
  Modal,
  Tab,
  Tabs,
} from '@energybox/react-ui-library/dist/components';
import {
  EnergyPro,
  EnergySource,
  FirmwareGatewayModel,
  CircuitBreaker,
  DistributionPanel,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as EnergyProActions,
  destroy,
  displayFormErrors,
  hideDeleteEnergyProModal,
  hideRemoveAllEnergyDevicesFromBusModal,
  hideUpdateEnergyProConfigModal,
  patch,
  removeAllEnergyDeviceFromBus,
  reset,
  showDeleteEnergyProModal,
  showRemoveAllEnergyDevicesFromBusModal,
  showUpdateEnergyProConfigModal,
  updateEnergyProConfiguration,
  updateField,
} from '../../../actions/energy_pros';
import history from '../../../history';
import { Routes } from '../../../routes';
import EditEnergyProForm from '../../../components/EditEnergyProForm';
import UpdateFirmwareModal from '../../../components/UpdateFirmwareModal';
import { ApplicationState } from '../../../reducers';
import { CircuitBreakersById } from '../../../reducers/circuit_breakers';
import { DistributionPanelsById } from '../../../reducers/distribution_panels';
import { EditEnergyPro } from '../../../reducers/energy_pros';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import DeviceOnlineState, {
  DisplayType,
} from '../../DeviceStatus/DeviceOnlineState';
import EnergyDeviceSensorsList from '../../EnergyDevices/EnergyDeviceSensorsList';
import styles from './EnergyProConfiguration.module.css';
import {
  reboot,
  clearGatewayBuffer as clearBuffer,
} from '../../../actions/gateways';
import { determineUserRoleInPlatform } from '../../../utils/user';
import { UserPlatformAccess } from '../../../types/user';

interface OwnProps {
  siteId: number;
  id: string;
  expandedId: string;
  toggleCardState: (id: string) => void;
  distributionPanelEnergySource: EnergySource;
  handleEnergyProChange: (eProId: number) => void;
}

interface Props extends OwnProps {
  userRole: UserPlatformAccess;
  onDelete: () => void;
  onRemoveAllEnergyDevices: (number) => void;
  editEnergyPro: EditEnergyPro;
  energyPro: EnergyPro;
  reboot: () => void;
  clearBuffer: () => void;

  showDeleteEnergyProModal: () => void;
  hideDeleteEnergyProModal: () => void;

  showRemoveAllEnergyDevicesFromBusModal: () => void;
  hideRemoveAllEnergyDevicesFromBusModal: () => void;

  showingDeleteModal: boolean;
  showingRemoveAllEnergyDevicesFromBusModal: boolean;

  onChange: (field: string, value: string | number) => void;
  patch: () => void;
  displayFormErrors: () => void;
  updateEnergyProConfiguration: () => void;
  showUpdateEnergyProConfigModal: () => void;
  hideUpdateEnergyProConfigModal: () => void;
  showingUpdateEnergyProConfigModal: boolean;
  reset: () => void;

  panelsById: DistributionPanelsById;
  breakersById: CircuitBreakersById;
}

interface State {
  activeTab: string;
  deleteBus: number;
  isUpdateFirmwareModalShowing: boolean;
}

const tabs = [
  {
    name: 'SETTINGS',
    label: 'Settings',
  },
  {
    name: 'CT_PORTS',
    label: 'CT Ports',
  },
];

class EnergyProConfiguration extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab:
        history.location.hash.indexOf(Routes.ENERGY_PRO_MAINS) > -1
          ? 'MAINS'
          : 'SETTINGS',
      deleteBus: -1,
      isUpdateFirmwareModalShowing: false,
    };
  }

  handleOpenUpdateFirmwareModal = () => {
    this.setState({ isUpdateFirmwareModalShowing: true });
  };

  handleCloseUpdateFirmwareModal = () => {
    this.setState({ isUpdateFirmwareModalShowing: false });
  };

  onUnexpandedCardClick = () => {
    const {
      id,
      toggleCardState,
      energyPro,
      handleEnergyProChange,
    } = this.props;

    toggleCardState(id);
    handleEnergyProChange(energyPro.id);
  };

  onEnergyProSave = () => {
    const { patch, editEnergyPro, displayFormErrors } = this.props;
    if (editEnergyPro && hasKeys(editEnergyPro.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  renderCardActions = () => {
    const { toggleCardState, editEnergyPro, reset } = this.props;
    return (
      <CardActions className={styles.cardActionsContainer}>
        <Button
          variant="text"
          onClick={() => {
            reset();
            toggleCardState('');
          }}
          children="Close"
        />
        <Button
          disabled={!editEnergyPro.isChanged}
          onClick={this.onEnergyProSave}
        >
          {editEnergyPro.isLoading ? (
            <Loader size={16} variant="secondary" />
          ) : (
            'Save'
          )}
        </Button>
      </CardActions>
    );
  };

  deletePrompt = () => {
    const {
      energyPro,
      onDelete,
      editEnergyPro,
      hideDeleteEnergyProModal,
    } = this.props;

    const actions = (
      <>
        <Button variant="text" onClick={hideDeleteEnergyProModal}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </>
    );

    return (
      <Modal onClose={hideDeleteEnergyProModal} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete{' '}
          {energyPro ? <b>{energyPro.title}</b> : 'this energy pro'}?
        </p>
        {editEnergyPro &&
          renderAPIerror(
            editEnergyPro.apiError,
            EnergyProActions.DELETE_ENERGY_PRO_ERROR
          )}
      </Modal>
    );
  };

  removeAllEnergyDevicesPrompt = () => {
    const {
      energyPro,
      onRemoveAllEnergyDevices,
      hideRemoveAllEnergyDevicesFromBusModal,
    } = this.props;

    const { deleteBus } = this.state;

    const actions = (
      <>
        <Button variant="text" onClick={hideRemoveAllEnergyDevicesFromBusModal}>
          Cancel
        </Button>
        <Button onClick={() => onRemoveAllEnergyDevices(deleteBus)}>
          Remove All
        </Button>
      </>
    );

    return (
      <Modal onClose={hideRemoveAllEnergyDevicesFromBusModal} actions={actions}>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to remove all Energy Devices from bus{' '}
          {deleteBus} of{' '}
          {energyPro ? <b>{energyPro.title}</b> : 'this energy pro'}?
        </p>
      </Modal>
    );
  };

  renderActionSelects = () => {
    const {
      userRole,
      energyPro,
      showDeleteEnergyProModal,
      showRemoveAllEnergyDevicesFromBusModal,
      showUpdateEnergyProConfigModal,
    } = this.props;
    const canUpdateFirmware = [
      UserPlatformAccess.GLOBAL_ADMIN,
      UserPlatformAccess.INSTALLER,
    ].includes(userRole);
    const optionalMenuDropdownItems: JSX.Element[] = [];

    if (canUpdateFirmware) {
      optionalMenuDropdownItems.push(
        <MenuDropdownItem
          key={'Update Firmware'}
          onSelect={this.handleOpenUpdateFirmwareModal}
        >
          <span>Update Firmware</span>
        </MenuDropdownItem>
      );
    }
    if (energyPro.bus1Device) {
      optionalMenuDropdownItems.push(
        <MenuDropdownItem
          key={'bus1'}
          isRed
          onSelect={() => {
            this.setState({ deleteBus: 1 });
            showRemoveAllEnergyDevicesFromBusModal();
          }}
        >
          <span>Remove All Energy Devices Bus 1</span>
        </MenuDropdownItem>
      );
    }
    if (energyPro.bus2Device) {
      optionalMenuDropdownItems.push(
        <MenuDropdownItem
          key={'bus2'}
          isRed
          onSelect={() => {
            this.setState({ deleteBus: 2 });
            showRemoveAllEnergyDevicesFromBusModal();
          }}
        >
          <span>Remove All Energy Devices Bus 2</span>
        </MenuDropdownItem>
      );
    }

    return (
      <MenuDropdown>
        <MenuDropdownItem
          key={'Update Configuration'}
          onSelect={showUpdateEnergyProConfigModal}
        >
          <span>Update Configuration</span>
        </MenuDropdownItem>

        <MenuDropdownItem key={'Reboot'} onSelect={this.props.reboot}>
          <span>Reboot</span>
        </MenuDropdownItem>
        <MenuDropdownItem
          key={'Clear Buffer'}
          onSelect={this.props.clearBuffer}
        >
          <span>Clear Buffer</span>
        </MenuDropdownItem>

        {optionalMenuDropdownItems}

        <MenuDropdownItem
          key={'delete'}
          isRed
          onSelect={showDeleteEnergyProModal}
        >
          <span>Delete Energy Pro</span>
        </MenuDropdownItem>
      </MenuDropdown>
    );
  };

  render() {
    const {
      siteId,
      id,
      expandedId,
      editEnergyPro,
      energyPro,
      onChange,
      showingDeleteModal,
      showingRemoveAllEnergyDevicesFromBusModal,
      showingUpdateEnergyProConfigModal,
      distributionPanelEnergySource,
      panelsById,
      breakersById,
    } = this.props;
    const { activeTab, isUpdateFirmwareModalShowing } = this.state;
    const cardIsOpen = id === expandedId;

    const distributionPanel: DistributionPanel | undefined = pathOr(
      undefined,
      [energyPro?.distributionPanelId],
      panelsById
    );

    const circuitBreakers:
      | CircuitBreaker[]
      | undefined = distributionPanel?.breakers?.map(b => {
      return breakersById[b.breaker.id];
    });

    return (
      <>
        {!cardIsOpen && !expandedId && (
          <div>
            <Card
              className={styles.listContainer}
              onClick={this.onUnexpandedCardClick}
            >
              <CardTitle>
                <span className={styles.cardListTitle}>
                  <div>Energy Pro: {energyPro ? energyPro.title : '...'}</div>

                  <DeviceOnlineState
                    displayType={DisplayType.STATUS_ONLY_WITHOUT_TEXT}
                    devices={[
                      {
                        id: energyPro.id,
                        uuid: energyPro.uuid,
                        vendor: energyPro.vendor,
                      },
                    ]}
                  />
                </span>
              </CardTitle>
            </Card>
          </div>
        )}
        {editEnergyPro && cardIsOpen && (
          <Card className={styles.expandedContainer}>
            <div className={styles.expandedContainerMargin}>
              <CardTitle>
                <div>
                  <span className={styles.expandedCardTitle}>
                    {energyPro.title}
                  </span>
                  <div className={styles.floatRight}>
                    {this.renderActionSelects()}
                  </div>
                </div>
                <div className={styles.tabsContainer}>
                  <Tabs>
                    {tabs.map(({ label, name }) => {
                      return (
                        <Tab
                          key={name}
                          active={activeTab === name}
                          onClick={() => {
                            this.setState({
                              activeTab: name,
                            });
                          }}
                        >
                          {label}
                        </Tab>
                      );
                    })}
                  </Tabs>
                </div>
              </CardTitle>
              <CardContent>
                <div className={styles.tabContent}>
                  {activeTab === 'SETTINGS' && (
                    <>
                      <EditEnergyProForm
                        siteId={siteId}
                        fields={editEnergyPro.fields}
                        onChange={onChange}
                        formErrorsVisible={editEnergyPro.formErrorsVisible}
                        formErrors={editEnergyPro.formErrors}
                        distributionPanelEnergySource={
                          distributionPanelEnergySource
                        }
                        firmwareVersion={energyPro?.firmwareVersion}
                      />
                      {renderAPIerror(
                        editEnergyPro.apiError,
                        EnergyProActions.PATCH_ENERGY_PRO_ERROR
                      )}
                    </>
                  )}

                  {activeTab === 'CT_PORTS' && (
                    <EnergyDeviceSensorsList
                      deviceType={energyPro.resourceType}
                      sensors={energyPro.sensors}
                      panelId={String(energyPro.distributionPanelId)}
                      energyDeviceId={energyPro.id}
                      circuitBreakers={circuitBreakers}
                    />
                  )}
                </div>
              </CardContent>
            </div>
            {this.renderCardActions()}
          </Card>
        )}
        {showingDeleteModal && this.deletePrompt()}
        {showingRemoveAllEnergyDevicesFromBusModal &&
          this.removeAllEnergyDevicesPrompt()}
        {energyPro && (
          <UpdateFirmwareModal
            device={energyPro}
            onClose={this.handleCloseUpdateFirmwareModal}
            isVisible={isUpdateFirmwareModalShowing}
            firmwareGatewayModel={FirmwareGatewayModel.ENERGYPRO}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (
  { energyPros, app, distributionPanels, circuitBreakers }: ApplicationState,
  { id, siteId }: OwnProps
) => {
  return {
    userRole: determineUserRoleInPlatform(
      app.currentUser,
      siteId,
      app.currentOrganizationId
    ),
    showingDeleteModal: (energyPros.editById[id] || {})
      .showDeleteEnergyProModal,
    showingRemoveAllEnergyDevicesFromBusModal: (energyPros.editById[id] || {})
      .showRemoveAllEnergyDevicesFromBusModal,
    showingUpdateEnergyProConfigModal: (energyPros.editById[id] || {})
      .showUpdateEnergyProConfigModal,
    editEnergyPro: energyPros.editById[id],
    energyPro: energyPros.energyProsById[id],
    panelsById: distributionPanels.distributionPanelsById,
    breakersById: circuitBreakers.circuitBreakersById,
  };
};

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  patch: () => dispatch(patch(id)),
  showDeleteEnergyProModal: () => dispatch(showDeleteEnergyProModal(id)),
  hideDeleteEnergyProModal: () => dispatch(hideDeleteEnergyProModal(id)),
  showRemoveAllEnergyDevicesFromBusModal: () =>
    dispatch(showRemoveAllEnergyDevicesFromBusModal(id)),
  hideRemoveAllEnergyDevicesFromBusModal: () =>
    dispatch(hideRemoveAllEnergyDevicesFromBusModal(id)),
  onRemoveAllEnergyDevices: (bus: number) => {
    dispatch(removeAllEnergyDeviceFromBus(id, bus));
  },
  onDelete: () => dispatch(destroy(id)),
  onChange: (field: string, value: string | number) =>
    dispatch(updateField(id, field, value)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
  updateEnergyProConfiguration: () =>
    dispatch(updateEnergyProConfiguration(id)),
  showUpdateEnergyProConfigModal: () =>
    dispatch(showUpdateEnergyProConfigModal(id)),
  reboot: () => dispatch(reboot(id)),
  clearBuffer: () => dispatch(clearBuffer(id)),
  hideUpdateEnergyProConfigModal: () =>
    dispatch(hideUpdateEnergyProConfigModal(id)),

  reset: () => dispatch(reset(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyProConfiguration);
