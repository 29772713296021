import {
  Modal,
  ModalContent,
  ModalTitle,
  ProgressBar,
} from '@energybox/react-ui-library/dist/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  enableStartSequence,
  hideEndSequenceModal,
} from '../../../actions/run_configuration';
import styles from './StartSequence.module.css';

export const EndSequenceModal = () => {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideEndSequenceModal());
      dispatch(enableStartSequence());
      reload();
    }, 30000);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress === 100) {
          clearInterval(timer);
          return prevProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(prevProgress + diff, 100);
      });
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Modal disableEscapeClose={true}>
      <ModalTitle>Complete Equipment Testing</ModalTitle>
      <ModalContent>
        <div className={styles.loadingBar}>
          <ProgressBar perceivedProgress={progress} />
          <span>{Math.round(progress)}% done</span>
        </div>
        <p>
          {' '}
          The system is reverting all CAMs to their previous modes and resetting
          all relays{' '}
        </p>
        <p>
          {' '}
          This process may take few minutes. Please do not close this window.
        </p>
      </ModalContent>
    </Modal>
  );
};

export default EndSequenceModal;
