import {
  EnergyPro,
  SentinelTarget,
  SentinelTargetType,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { fetchEnergyProsBySiteId } from '../../../../actions/legacyFetches';
import RolloutSelector from './RolloutSelector';
import styles from './StepRollout.module.css';

type Props = {
  site: Site;
  handleTargetsSelect: (sentinelTarget: SentinelTarget[]) => void;
  handleTargetDelete: (sentinelTargetIds: number[]) => void;
  handleSetResourceRelation: (arg: number, arg2: number[]) => void;
  selected: number[];
  isCollapsed: boolean;
  updateEquipmentList: (list: any) => void;
  upDatedSentielId: (sentinelId: any) => void;
};

type State = {
  energyPros: EnergyPro[];
  isLoading: boolean;
};

class StepRolloutEnergyProsHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      energyPros: [],
      isLoading: true,
    };
  }

  componentDidMount(): void {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({ isLoading: true });
    const { handleSetResourceRelation } = this.props;

    try {
      const { site } = this.props;

      const energyPros = await fetchEnergyProsBySiteId(site.id);

      handleSetResourceRelation(
        site.id,
        energyPros.map((g: EnergyPro) => g.id)
      );

      const selectedBySite = energyPros
        ? energyPros.reduce(
            (sum, item) =>
              this.props.selected.includes(item.id) ? sum + 1 : sum,
            0
          )
        : 0;

      const sentinelTargetTypeID = SentinelTargetType.ENERGYPRO;

      this.props.updateEquipmentList(energyPros);
      this.props.upDatedSentielId(sentinelTargetTypeID);

      this.setState({
        energyPros,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { energyPros = [], isLoading } = this.state;
    const {
      selected,
      isCollapsed,
      handleTargetsSelect,
      handleTargetDelete,
    } = this.props;

    if (isLoading) {
      return <div className={styles.tableLoading}>Loading...</div>;
    }

    const selectedBySite = energyPros
      ? energyPros.reduce(
          (sum, item) => (selected.includes(item.id) ? sum + 1 : sum),
          0
        )
      : 0;
    const total = energyPros.length;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Energy Pros</th>
              <th style={{ width: '25%' }}>{isCollapsed && 'Location'}</th>
              <th
                style={{ width: '15%' }}
                className={classNames(styles.actionHeader, styles.action)}
              >
                <RolloutSelector
                  isCollapsed={isCollapsed}
                  selected={selectedBySite}
                  totalSelected={total}
                  onDelete={() =>
                    handleTargetDelete(energyPros.map(item => item.id))
                  }
                  onSelect={() =>
                    handleTargetsSelect(
                      energyPros.map(item => ({
                        targetId: item.id,
                        targetType: SentinelTargetType.ENERGYPRO,
                      }))
                    )
                  }
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

export default StepRolloutEnergyProsHeader;
