import {
  User,
  GenericErrors,
  AccessResource,
  Region,
} from '@energybox/react-ui-library/dist/types';
import {
  mapScopeToAccessResource,
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';

import * as R from 'ramda';
import { Actions as UserActions } from '../actions/users';
import { Actions } from '../actions/user_groups';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface UserGroupUser {
  id: number;
  firstName: string;
  lastName: string;
}

export interface UserGroup {
  id: number;
  title: string;
  description: string;
  accessResources: AccessResource[];
  users: UserGroupUser[];
  region: Region;
}
export interface UserGroupUsers {
  isLoading: boolean;
  items: UserGroupUser[];
}

export type UserGroupsUsersByGroupId = {
  [id: number]: UserGroupUsers;
};

export type UserGroupsById = {
  [id: number]: UserGroup;
};

export type GroupTypes = 'userGroup' | 'installerGroup';

export enum Column {
  NAME = 'name',
}

type EditableFields = {
  title: string;
  description: string;
  region: Region;
};

export type EditGroup = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

export type EditUserGroupsById = {
  [id: string]: EditGroup;
};

export interface UserGroups {
  userGroupsById: UserGroupsById;
  userGroupsUsersByGroupId: UserGroupsUsersByGroupId;
  showNewGroupModal: boolean;
  editById: EditUserGroupsById;
  loadingStatusByAction: ActionsLoadingStatus;
  userGroupApiError: ApiError;
}

export type ActionsLoadingStatus = {
  [Actions.GET_LIST_LOADING]?: boolean;
};

const userGroupUserFromApiResponse = (data: any): UserGroupUser => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
});

const userGroupFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  accessResources: data.scopes.map(mapScopeToAccessResource),
  users: (data.users || []).map(userGroupUserFromApiResponse),
  region: data.region,
});

const newUserGroupFields = {
  title: '',
  description: '',
};

const newGroupInitialState = (validateFromAs?: GroupTypes) => ({
  isLoading: false,
  isChanged: false,
  fields: newUserGroupFields,
  formErrorsVisible: false,
  formErrors: formValidationErrors(
    validateFromAs || 'userGroup',
    newUserGroupFields
  ),
  apiError: {},
});

const initialState = {
  userGroupsById: {},
  userGroupsUsersByGroupId: {},
  showNewGroupModal: false,
  editById: {},
  loadingStatusByAction: {},
  userGroupApiError: {},
};

export const userGroups = (state = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_LIST_SUCCESS:
      return R.pipe(
        R.assoc(
          'userGroupsById',
          mapArrayToObject(mapValues(action.data, userGroupFromApiResponse))
        ),
        R.assocPath(['loadingStatusByAction', Actions.GET_LIST_LOADING], false)
      )(state);

    case Actions.GET_LIST_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_LIST_LOADING],
        true,
        state
      );

    case Actions.GET_SUCCESS:
      let userGroup = userGroupFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(
          ['userGroupsById', action.data.id],
          userGroupFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: {
            title: userGroup.title,
            description: userGroup.description,
            region: userGroup.region,
          },
          apiError: {},
        }),
        R.assoc('userGroupApiError', {})
      )(state);

    case Actions.GET_LOADING:
      return R.assoc('userGroupApiError', {}, state);

    case Actions.GET_ERROR:
      return R.assoc('userGroupApiError', action.data, state);

    case Actions.TOGGLE_NEW_USERGROUP_MODAL:
      return R.pipe(
        R.assocPath(
          ['editById', 'new'],
          newGroupInitialState(action.validateFromAs)
        ),
        R.assoc('showNewGroupModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedFields = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(['editById', action.id, 'fields'], updatedFields),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors(
            action.validateFromAs || 'userGroup',
            updatedFields
          )
        )
      )(state);

    case Actions.RESET_FIELDS:
      var fields = R.path(['userGroupsById', action.id], state);
      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors(action.validateFromAs || 'userGroup', fields)
        )
      )(state);

    case Actions.CREATE_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_SUCCESS:
      return R.pipe(
        R.assoc('showNewGroupModal', false),
        R.assocPath(
          ['userGroupsById', action.data.id],
          userGroupFromApiResponse(action.data)
        )
      )(state);

    case Actions.CREATE_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_SUCCESS:
      let patchedUserGroup = userGroupFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['userGroupsById', action.data.id], patchedUserGroup),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.PATCH_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.DELETE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_SUCCESS:
      return R.pipe(
        R.dissocPath(['userGroupsById', action.id]),
        R.dissocPath(['editById', action.id])
      )(state);

    case Actions.DELETE_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.UPDATE_SCOPE_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.UPDATE_SCOPE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['userGroupsById', action.id],
          userGroupFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.ADD_SCOPE_LOADING:
      return R.assocPath(
        ['editById', action.groupId, 'isLoading'],
        true,
        state
      );

    case Actions.ADD_SCOPE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['userGroupsById', action.groupId],
          userGroupFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.groupId, 'isLoading'], false)
      )(state);

    case Actions.DELETE_SCOPE_LOADING:
      return R.assocPath(
        ['editById', action.groupId, 'isLoading'],
        true,
        state
      );

    case Actions.DELETE_SCOPE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['userGroupsById', action.groupId],
          userGroupFromApiResponse(action.data)
        ),
        R.assocPath(['editById', action.groupId, 'isLoading'], false)
      )(state);

    case UserActions.ADD_USER_TO_GROUP_SUCCESS:
      return R.over(
        R.lensPath(['userGroupsById', action.groupId, 'users']),
        R.append(action.data),
        state
      );

    case UserActions.REMOVE_USER_FROM_GROUP_SUCCESS:
      return R.over(
        R.lensPath(['userGroupsById', action.groupId, 'users']),
        R.filter(({ id }: User) => id !== action.userId),
        state
      );

    default:
      return state;
  }
};

export default userGroups;
