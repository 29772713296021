import React from 'react';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import tileStyles from '../InspectionTile/InspectionTile.module.css';

type Props = {
  content: React.ReactNode;
};

const InfoIconTooltip: React.FC<Props> = ({ content }) => (
  <Tooltip
    content={content}
    extraClassNames={[tileStyles.tooltip]}
    childrenWrapperClassName={tileStyles.tooltip}
    tooltipTextClassName={tileStyles.tooltipText}
    arrowDirection="top"
    disableAutoAdjust
    underline={false}
  >
    <IoIosInformationCircleOutline size={20} />
  </Tooltip>
);

export default InfoIconTooltip;
