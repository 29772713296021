import {
  Checkbox,
  Button,
  FilterDropdown,
  FilterDropdownBasicItem,
  FilterDropdownTextItem,
} from '@energybox/react-ui-library/dist/components';
import {
  MutedTarget,
  SentinelTarget,
  MapTimeFormat,
} from '@energybox/react-ui-library/dist/types';
import { formatIanaTimeZone } from '@energybox/react-ui-library/dist/utils';
import { DateTimePicker } from '@material-ui/pickers/DateTimePicker';
import { addHours, differenceInMinutes, isBefore, parseISO } from 'date-fns';

import React, { useEffect, useState } from 'react';
import { useAppLocale } from '../../../../hooks/useAppDetails';
import { toMuteISOString } from '../../../../utils/string';
import styles from './DurationPicker.module.css';

enum MuteDuration {
  SIX_HOURS = '6 hours',
  TWENTY_FOUR_HOURS = '24 hours',
  THREE_DAYS = '3 days',
  ONE_WEEK = '1 week',
  INDEFINITE = 'Indefinite',
  CUSTOM = 'Custom Mute',
}

const durationDropdownItems = [
  MuteDuration.SIX_HOURS,
  MuteDuration.TWENTY_FOUR_HOURS,
  MuteDuration.THREE_DAYS,
  MuteDuration.ONE_WEEK,
  MuteDuration.INDEFINITE,
  MuteDuration.CUSTOM,
];

export const DurationOptionsMapping = {
  [MuteDuration.SIX_HOURS]: 6,
  [MuteDuration.TWENTY_FOUR_HOURS]: 24,
  [MuteDuration.THREE_DAYS]: 72,
  [MuteDuration.ONE_WEEK]: 168,
};

const DEFAULT_DURATION = MuteDuration.SIX_HOURS;

const HourDifferenceToDurationMapping = {
  [DurationOptionsMapping[MuteDuration.SIX_HOURS]]: MuteDuration.SIX_HOURS,
  [DurationOptionsMapping[MuteDuration.TWENTY_FOUR_HOURS]]:
    MuteDuration.TWENTY_FOUR_HOURS,
  [DurationOptionsMapping[MuteDuration.THREE_DAYS]]: MuteDuration.THREE_DAYS,
  [DurationOptionsMapping[MuteDuration.ONE_WEEK]]: MuteDuration.ONE_WEEK,
};

const calculateMuteTargetDuration = ({
  targetMutedFrom,
  targetMutedUntil,
}: MutedTarget) => {
  if (!targetMutedUntil) return MuteDuration.INDEFINITE;
  const fromDate = parseISO(targetMutedFrom);
  const toDate = parseISO(targetMutedUntil);
  if (isBefore(toDate, new Date())) return DEFAULT_DURATION;
  const minutesDifference = differenceInMinutes(toDate, fromDate);
  const duration = HourDifferenceToDurationMapping[minutesDifference / 60];
  if (duration === undefined) return MuteDuration.CUSTOM;
  return duration;
};

const getMutedStartEndTimestamps = (
  duration: MuteDuration,
  customFromDate?: Date,
  customToDate?: Date
) => {
  const fromDate = new Date();
  switch (duration) {
    case MuteDuration.CUSTOM:
      if (customFromDate && customToDate) {
        return {
          targetMutedFrom: toMuteISOString(customFromDate),
          targetMutedUntil: toMuteISOString(customToDate),
        };
      }
      return {
        targetMutedFrom: '',
      };
    case MuteDuration.INDEFINITE:
      return {
        targetMutedFrom: toMuteISOString(fromDate),
      };
    default:
      const toDate = addHours(fromDate, DurationOptionsMapping[duration]);
      return {
        targetMutedFrom: toMuteISOString(fromDate),
        targetMutedUntil: toMuteISOString(toDate),
      };
  }
};

export const createMuteTarget = (
  { targetId, targetType }: SentinelTarget,
  duration: MuteDuration,
  customFromDate?: Date,
  customToDate?: Date
): MutedTarget => {
  const { targetMutedFrom, targetMutedUntil } = getMutedStartEndTimestamps(
    duration,
    customFromDate,
    customToDate
  );
  return {
    targetId,
    targetMutedFrom,
    targetMutedUntil,
    targetType,
  };
};

type Props = {
  disabled?: boolean;
  sentinelId: number | string;
  target: SentinelTarget;
  mutedTarget?: MutedTarget;
  muteTarget: (sentinelId: number | string, mutedTarget: MutedTarget) => void;
  unmuteTarget: (sentinelId: number | string, mutedTarget: MutedTarget) => void;
  ianaTimeZoneCode: string;
};

const DurationPicker = ({
  disabled,
  sentinelId,
  target,
  mutedTarget,
  muteTarget,
  unmuteTarget,
  ianaTimeZoneCode,
}: Props) => {
  // UI Dropdown + checkbox state
  const [selectedDuration, setSelectedDuration] = useState(
    mutedTarget ? calculateMuteTargetDuration(mutedTarget) : DEFAULT_DURATION
  );
  const [isSelected, setIsSelected] = useState(mutedTarget !== undefined);
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);

  // check prop changes
  useEffect(() => {
    setIsSelected(mutedTarget !== undefined);
  }, [mutedTarget, setIsSelected]);

  // CUSTOM DURATION STATES
  const [customFromDate, setCustomFromDate] = useState<Date>(
    selectedDuration === MuteDuration.CUSTOM && mutedTarget
      ? parseISO(mutedTarget.targetMutedFrom)
      : new Date()
  );
  const [customToDate, setCustomToDate] = useState<Date>(
    selectedDuration === MuteDuration.CUSTOM &&
      mutedTarget &&
      mutedTarget.targetMutedUntil
      ? parseISO(mutedTarget.targetMutedUntil)
      : addHours(new Date(), 1)
  );

  const [openDatePicker, setOpenDatePicker] = useState(
    selectedDuration === MuteDuration.CUSTOM
  );
  const locale = useAppLocale();

  const muteAction = (target: SentinelTarget, duration: MuteDuration) => {
    if (duration === MuteDuration.CUSTOM) {
      setOpenDatePicker(true);
    } else {
      setOpenDatePicker(false);
      muteTarget(sentinelId, createMuteTarget(target, duration));
    }
    setSelectedDuration(duration);
  };

  const unmuteAction = (mutedTarget: MutedTarget) => {
    unmuteTarget(sentinelId, mutedTarget);
    setSelectedDuration(calculateMuteTargetDuration(mutedTarget));
  };

  const displayFromDate = mutedTarget
    ? formatIanaTimeZone(
        parseISO(mutedTarget.targetMutedFrom),
        locale.dateFormat,
        ianaTimeZoneCode
      )
    : undefined;
  const displayToDate =
    mutedTarget && mutedTarget.targetMutedUntil
      ? formatIanaTimeZone(
          parseISO(mutedTarget.targetMutedUntil),
          locale.dateFormat,
          ianaTimeZoneCode
        )
      : undefined;

  const displayFromTime = mutedTarget
    ? formatIanaTimeZone(
        parseISO(mutedTarget.targetMutedFrom),
        locale.timeFormat,
        ianaTimeZoneCode,
        true
      )
    : undefined;
  const displayToTime =
    mutedTarget && mutedTarget.targetMutedUntil
      ? formatIanaTimeZone(
          parseISO(mutedTarget.targetMutedUntil),
          locale.timeFormat,
          ianaTimeZoneCode,
          true
        )
      : undefined;

  return (
    <div>
      <div className={styles.root}>
        <Checkbox
          disabled={disabled}
          className={styles.durationCheckbox}
          onChange={() => {
            if (mutedTarget === undefined) {
              if (!isSelected) {
                muteAction(target, DEFAULT_DURATION);
              }
            } else {
              if (isSelected) {
                unmuteAction(mutedTarget);
              }
            }
            setIsSelected(!isSelected);
          }}
          checked={isSelected}
        />
        <div>
          <FilterDropdown
            variant="select"
            disabled={!isSelected}
            title={isSelected ? selectedDuration : ''}
            size="small"
            isDatePickerModalOpen={isDatePickerModalOpen}
          >
            {durationDropdownItems.map(duration => {
              return (
                <FilterDropdownTextItem
                  key={`dropdown${duration}`}
                  title={duration}
                  onClick={() => muteAction(target, duration)}
                  isActive={selectedDuration === duration}
                  closeOnClick={duration !== MuteDuration.CUSTOM}
                />
              );
            })}
            {openDatePicker ? (
              <>
                <CustomDatePicker
                  locale={locale}
                  fromDate={customFromDate}
                  toDate={customToDate}
                  setFromDate={setCustomFromDate}
                  setToDate={setCustomToDate}
                  setDatePickerModalOpen={setIsDatePickerModalOpen}
                />
                {!isBefore(customFromDate, customToDate) && (
                  <div className={styles.errorMessage}>
                    The start of the date range must precede the end.
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            {openDatePicker && (
              <FilterDropdownBasicItem
                className={styles.applyButton}
                closeOnClick
              >
                <Button
                  disabled={!isBefore(customFromDate, customToDate)}
                  onClick={() => {
                    const updatedFromDate = toMuteISOString(customFromDate);
                    const updatedToDate = toMuteISOString(customToDate);

                    muteTarget(sentinelId, {
                      targetId: target.targetId,
                      targetMutedFrom: updatedFromDate,
                      targetMutedUntil: updatedToDate,
                      targetType: target.targetType,
                    });
                  }}
                >
                  Apply
                </Button>
              </FilterDropdownBasicItem>
            )}
          </FilterDropdown>
        </div>
      </div>
      {displayFromDate && (
        <div className={styles.datesText}>
          <div style={{ whiteSpace: 'pre' }}>
            <div>
              {`${displayFromDate} `}
              {displayToDate ? `- ${displayToDate}` : ''}
            </div>
            <div>
              {`${displayFromTime} `}
              {displayToTime ? `- ${displayToTime}` : ''}
            </div>
          </div>
          {/* <div>
            <div>{displayToDate ? `- ${displayToDate}` : ''}</div>
            <div>{displayToTime ? `- ${displayToTime}` : ''}</div>
          </div> */}
        </div>
      )}
    </div>
  );
};

const CustomDatePicker = ({
  locale,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  setDatePickerModalOpen,
}) => {
  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);

  useEffect(() => {
    setDatePickerModalOpen(fromOpen || toOpen);
  }, [fromOpen, toOpen, setDatePickerModalOpen]);

  return (
    <div className={styles.datePickerContainer}>
      <DateTimePicker
        disablePast
        className={styles.datePicker}
        label="From"
        value={fromDate}
        onChange={newDate => newDate && setFromDate(newDate)}
        format={locale.dateTimeFormat}
        open={fromOpen}
        onOpen={() => setFromOpen(true)}
        onClose={() => setFromOpen(false)}
        ampm={locale.timeFormat === MapTimeFormat.HOUR_12}
      />
      <DateTimePicker
        disablePast
        className={styles.datePicker}
        label="To"
        value={toDate}
        onChange={newDate => newDate && setToDate(newDate)}
        format={locale.dateTimeFormat}
        open={toOpen}
        onOpen={() => setToOpen(true)}
        onClose={() => setToOpen(false)}
        ampm={locale.timeFormat === MapTimeFormat.HOUR_12}
      />
    </div>
  );
};

export default DurationPicker;
