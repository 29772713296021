import { WorkingMode } from '@energybox/react-ui-library/dist/types';
import {
  HvacControlBatch,
  HvacControlBatchUpdate,
  HvacControlMapping,
} from '../types/hvacControl';
import { hideDeleteControlModal, hideNewControlModal } from './controls';
import { edgeConfigStatusUpdate } from '../actions/edge_devices';
import { getOrganizationHvacData } from './sops';

const apiBase = '/api/v1/venstar/controls';
const newApiBase = '/api/v1/thermostat/controls';

export enum Actions {
  GET_HVAC_CONTROLS_BY_SITE_ID_LOADING = '@hvacControl/GET_HVAC_CONTROLS_BY_SITE_ID_LOADING',
  GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS = '@hvacControl/GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS',
  GET_HVAC_CONTROLS_BY_SITE_ID_ERROR = '@hvacControl/GET_HVAC_CONTROLS_BY_SITE_ID_ERROR',

  GET_HVAC_CONTROL_BY_EQUIPMENT_ID_LOADING = '@hvacControl/GET_HVAC_CONTROL_BY_EQUIPMENT_ID_LOADING',
  GET_HVAC_CONTROL_BY_EQUIPMENT_ID_SUCCESS = '@hvacControl/GET_HVAC_CONTROL_BY_EQUIPMENT_ID_SUCCESS',
  GET_HVAC_CONTROL_BY_EQUIPMENT_ID_ERROR = '@hvacControl/GET_HVAC_CONTROL_BY_EQUIPMENT_ID_ERROR',

  CREATE_HVAC_CONTROL_LOADING = '@hvacControl/CREATE_HVAC_CONTROL_LOADING',
  CREATE_HVAC_CONTROL_SUCCESS = '@hvacControl/CREATE_HVAC_CONTROL_SUCCESS',
  CREATE_HVAC_CONTROL_ERROR = '@hvacControl/CREATE_HVAC_CONTROL_ERROR',

  PATCH_HVAC_CONTROL_LOADING = '@hvacControl/PATCH_HVAC_CONTROL_LOADING',
  PATCH_HVAC_CONTROL_SUCCESS = '@hvacControl/PATCH_HVAC_CONTROL_SUCCESS',
  PATCH_HVAC_CONTROL_ERROR = '@hvacControl/PATCH_HVAC_CONTROL_ERROR',

  PATCH_HVAC_CONTROL_BATCH_LOADING = '@hvacControl/PATCH_HVAC_CONTROL_BATCH_LOADING',
  PATCH_HVAC_CONTROL_BATCH_SUCCESS = '@hvacControl/PATCH_HVAC_CONTROL_BATCH_SUCCESS',
  PATCH_HVAC_CONTROL_BATCH_ERROR = '@hvacControl/PATCH_HVAC_CONTROL_BATCH_ERROR',

  ADD_HVAC_CONTROL_BATCH_LOADING = '@hvacControl/ADD_HVAC_CONTROL_BATCH_LOADING',
  ADD_HVAC_CONTROL_BATCH_SUCCESS = '@hvacControl/ADD_HVAC_CONTROL_BATCH_SUCCESS',
  ADD_HVAC_CONTROL_BATCH_ERROR = '@hvacControl/ADD_HVAC_CONTROL_BATCH_ERROR',

  DELETE_HVAC_CONTROL_LOADING = '@hvacControl/DELETE_HVAC_CONTROL_LOADING',
  DELETE_HVAC_CONTROL_SUCCESS = '@hvacControl/DELETE_HVAC_CONTROL_SUCCESS',
  DELETE_HVAC_CONTROL_ERROR = '@hvacControl/DELETE_HVAC_CONTROLS_ERROR',

  PATCH_HVAC_CONTROL_WORKING_MODE_LOADING = '@hvacControl/PATCH_HVAC_CONTROL_WORKING_MODE_LOADING',
  PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS = '@hvacControl/PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS',
  PATCH_HVAC_CONTROL_WORKING_MODE_ERROR = '@hvacControl/PATCH_HVAC_CONTROLS_WORKING_MODE_ERROR',

  SHOW_EDIT_HVAC_CONTROL_MODAL = '@hvacControl/SHOW_EDIT_HVAC_CONTROL_MODAL',
  HIDE_EDIT_HVAC_CONTROL_MODAL = '@hvacControl/HIDE_EDIT_HVAC_CONTROL_MODAL',

  UPDATE_FIELD = '@hvacControl/UPDATE_FIELD',
}

export const getHvacControlsBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${newApiBase}/site/${siteId}`,
  loading: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getHvacControlByEquipmentId = (equipmentId: number | string) => ({
  type: 'API_GET',
  path: `${newApiBase}/equipment/${equipmentId}`,
  loading: {
    type: Actions.GET_HVAC_CONTROL_BY_EQUIPMENT_ID_LOADING,
    equipmentId,
  },
  success: {
    type: Actions.GET_HVAC_CONTROL_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId,
  },
  error: {
    type: Actions.GET_HVAC_CONTROL_BY_EQUIPMENT_ID_ERROR,
    equipmentId,
  },
});

export const createHvacControl = (
  payload: HvacControlMapping,
  options?: { produceEdgeConfig?: boolean }
) => {
  return {
    type: 'API_POST',
    path: `${newApiBase}`,
    payload: payload,
    loading: Actions.CREATE_HVAC_CONTROL_LOADING,
    success: [
      { type: Actions.CREATE_HVAC_CONTROL_SUCCESS, options },
      hideNewControlModal(),
    ],
    error: Actions.CREATE_HVAC_CONTROL_ERROR,
  };
};

export const patchHvacControl = (
  id: number | string,
  payload: HvacControlMapping,
  options?: {
    produceEdgeConfig?: boolean;
    oldNetworkGroupId?: number;
    newNetworkGroupId?: number;
  }
) => {
  const equipmentId = payload.equipmentId;

  return {
    type: 'API_PUT',
    path: `${newApiBase}/${id}`,
    payload: payload,
    loading: Actions.PATCH_HVAC_CONTROL_LOADING,
    success: [
      { type: Actions.PATCH_HVAC_CONTROL_SUCCESS, equipmentId, options },
      hideEditHvacControlModal(),
    ],
    error: Actions.PATCH_HVAC_CONTROL_ERROR,
  };
};

export const patchHvacControlBatch = (
  payload: HvacControlBatchUpdate,
  orgId: number
) => {
  return {
    type: 'API_PUT',
    path: `${newApiBase}/batch`,
    payload: payload,
    loading: Actions.PATCH_HVAC_CONTROL_BATCH_LOADING,
    success: [
      Actions.PATCH_HVAC_CONTROL_BATCH_SUCCESS,
      getOrganizationHvacData(orgId),
    ],
    error: Actions.PATCH_HVAC_CONTROL_BATCH_ERROR,
  };
};

export const addHvacControlBatch = (
  payload: HvacControlBatch[],
  orgId: number
) => {
  return {
    type: 'API_POST',
    path: `${newApiBase}/batch`,
    payload: payload,
    loading: Actions.ADD_HVAC_CONTROL_BATCH_LOADING,
    success: [
      Actions.ADD_HVAC_CONTROL_BATCH_SUCCESS,
      getOrganizationHvacData(orgId),
    ],
    error: Actions.ADD_HVAC_CONTROL_BATCH_ERROR,
  };
};

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const deleteHvacControl = (
  hvacControlId: number | string,
  equipmentId: number | string,
  options?: { produceEdgeConfig?: boolean; networkGroupId?: number },
  orgId?: number
) => ({
  type: 'API_DELETE',
  path: `${newApiBase}/${hvacControlId}`,
  loading: Actions.DELETE_HVAC_CONTROL_LOADING,
  success: [
    {
      type: Actions.DELETE_HVAC_CONTROL_SUCCESS,
      equipmentId,
      options,
    },
    hideDeleteControlModal(),
    getOrganizationHvacData(orgId!),
  ],
  error: Actions.DELETE_HVAC_CONTROL_ERROR,
});

export const patchHvacControlWorkingMode = (
  equipmentId: number | string,
  hvacControlId: number | string,
  workingMode: WorkingMode,
  edgeUuid: string | undefined,
  edgeId?: number | undefined
) => ({
  type: 'API_PATCH',
  path: `${newApiBase}/${hvacControlId}/working-mode`,
  payload: { workingMode, propagateConfig: true },
  success: [
    {
      type: Actions.PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS,
      hvacControlId,
      edgeUuid,
    },
    edgeConfigStatusUpdate(edgeId),
    getHvacControlByEquipmentId(equipmentId),
  ],
  error: {
    type: Actions.PATCH_HVAC_CONTROL_WORKING_MODE_ERROR,
    hvacControlId,
    edgeUuid,
  },
  loading: {
    type: Actions.PATCH_HVAC_CONTROL_WORKING_MODE_LOADING,
    hvacControlId,
    edgeUuid,
  },
});

export const showEditHvacControlModal = () => ({
  type: Actions.SHOW_EDIT_HVAC_CONTROL_MODAL,
});

export const hideEditHvacControlModal = () => ({
  type: Actions.HIDE_EDIT_HVAC_CONTROL_MODAL,
});
