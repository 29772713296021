import {
  Button,
  Card,
  CardActions,
  CardContent,
  FileUploadButton,
  Loader,
  Table,
} from '@energybox/react-ui-library/dist/components';
import { supportedBillFiletypeArray } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import { useEffect, useState } from 'react';

import styles from './UtilityBillCard.module.css';
import UploadCell from './UploadCell';
import {
  isReady,
  UploadFile,
  BillMetadata,
  isAllReady,
  initialBillMetadata,
} from '../../types/upload';
import { useUtilityBills } from '../../hooks/useUtilityBills';
import { useUploads } from '../../hooks/fileUpload/useUpload';
import FileDragAndDrop from '../../components/FileUploader/FileDragAndDrop/FileDragAndDrop';
import UtilityBillTable from '../../components/UtilityBillTable/UtilityBillTable';

type Props = {
  title: React.ReactNode;
  siteId: number;
  isUploadFromEdmPage?: boolean;
  setEnableSiteNav?: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadSiteId?: (siteId: number | undefined) => void;
  setIsUploadExists?: React.Dispatch<React.SetStateAction<boolean>>;
};

type UploadResultByFileIdx = {
  [idx: number]: string;
};

type UploadTracker = {
  results: UploadResultByFileIdx;
  count: number;
};

const UtilityBillCard: React.FC<Props> = ({
  title,
  siteId,
  isUploadFromEdmPage,
  setEnableSiteNav,
  setUploadSiteId,
  setIsUploadExists,
}) => {
  const {
    uploads,
    isSomeUploading,
    addUploads,
    removeUpload,
    changeUpload,
    batchUpload,
    clearUpload,
  } = useUploads(initialBillMetadata);
  const [statusDone, setStatusDone] = useState(false);
  const [uploadCounts, setUploadCounts] = useState(0);
  useEffect(() => {
    if (statusDone) setUploadCounts(uploadCounts + 1);
  }, [statusDone]);
  if (isUploadFromEdmPage && uploads.length && !statusDone) {
    setEnableSiteNav && setEnableSiteNav(true);
  } else {
    setEnableSiteNav && setEnableSiteNav(false);
  }

  const { getUtilityBills, utilityBills } = useUtilityBills(siteId);
  useEffect(() => {
    if (!isSomeUploading) {
      getUtilityBills();
    }
  }, [isSomeUploading]);

  useEffect(() => {
    if (uploads.length && isUploadFromEdmPage) {
      clearUpload();
    }
  }, [siteId]);
  // end

  // Define drop zone related value and methods
  const accept = supportedBillFiletypeArray.join(',');

  const handleLoadFile = e => addUploads(e.target.files);
  // end
  const cancelOrClose = () => {
    clearUpload();
    if (isUploadFromEdmPage) {
      setUploadSiteId && setUploadSiteId(undefined);
      if (uploadCounts > 0) {
        setIsUploadExists && setIsUploadExists(true);
      }
      for (let key in localStorage) {
        if (key.startsWith('UploadInfo')) {
          localStorage.removeItem(key);
        }
      }
    }
  };
  const edmUtilityBillHistory = (
    <div className={styles.historyCard}>
      {utilityBills.length > 0 && (
        <UtilityBillTable
          title={'Latest Utility Bill Uploads'}
          siteId={siteId}
          isUploadFromEdmPage={isUploadFromEdmPage}
        />
      )}
    </div>
  );

  return (
    <Card className={isUploadFromEdmPage ? styles.noBoxShadow : ''}>
      <CardContent className={isUploadFromEdmPage ? styles.cardFromModal : ''}>
        {!isUploadFromEdmPage && (
          <>
            <div className={styles.title}>{title}</div>
            <div className={styles.instruction}>
              Please select a utility bill and specify the billing period to
              start upload.
            </div>
          </>
        )}
        <div
          className={
            isUploadFromEdmPage ? styles.contentFromModal : styles.content
          }
        >
          <div
            className={
              isUploadFromEdmPage ? styles.modalUploader : styles.uploader
            }
          >
            <input
              className={styles.realUploadElement}
              id="upload"
              name="in"
              accept={accept}
              type="file"
              onChange={handleLoadFile}
              multiple
              disabled={isSomeUploading}
            />
            <FileDragAndDrop
              addUploads={addUploads}
              isUploading={isSomeUploading}
              accept={accept}
              className={styles.utilityBillDragAndDrop}
              children={
                <span>
                  Drag files to Upload or click on
                  <span className={styles.browseFiles}>
                    <FileUploadButton
                      multiple
                      handleFiles={addUploads}
                      nowrap
                      accept={accept}
                      variant="text"
                    >
                      <span className={styles.browseFilesText}>
                        {' '}
                        {`Browse Files`}
                      </span>
                    </FileUploadButton>
                  </span>
                </span>
              }
            />
          </div>
          {uploads.length > 0 && (
            <Table
              columns={[
                {
                  header: '',
                  cellContent: (file: UploadFile<BillMetadata>, idx) => (
                    <UploadCell
                      upload={file}
                      fileIdx={idx}
                      remove={removeUpload}
                      uploadDidChange={changeUpload}
                      isUndeletable={isSomeUploading}
                      siteId={siteId}
                      isUploadFromEdmPage={isUploadFromEdmPage}
                      setStatusDone={setStatusDone}
                    />
                  ),
                },
              ]}
              data={uploads}
              showHeaders={false}
            />
          )}
        </div>
      </CardContent>
      {isUploadFromEdmPage && edmUtilityBillHistory}
      {(uploads.length > 0 || isUploadFromEdmPage) && (
        <CardActions
          className={isUploadFromEdmPage ? styles.alignActionsOfEdm : ''}
        >
          <Button
            variant="text"
            disabled={isSomeUploading}
            onClick={cancelOrClose}
            children={isUploadFromEdmPage ? 'Close' : 'Cancel'}
          />

          <Button
            disabled={
              !isAllReady(uploads) || // only all files are with startDate and endDate
              isSomeUploading || // Cannot start new upload when uploads are in progress
              uploads.filter(isReady).length === 0 // For UI: Disable button when there are no uploadable files at all
            }
            onClick={() => {
              batchUpload();
              if (isUploadFromEdmPage) {
                localStorage.setItem('UploadInfo' + siteId, 'true');
              }
            }}
          >
            {isSomeUploading ? (
              <Loader size={16} variant="secondary" />
            ) : (
              'Save'
            )}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default UtilityBillCard;
