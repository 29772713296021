import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import {
  ControlBoard,
  LightSensorPort,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  isDefined,
  values,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getControlBoards } from '../../actions/control_boards';
import { ApplicationState } from '../../reducers';

const NONE_OPTION = {
  title: 'None',
  id: -1,
} as ControlBoard;

interface OwnProps {
  onSelect: (controlBoardId: number) => void;
  value?: number;
  error?: boolean;
  disabled?: boolean;
  customErrorText?: string;
  equipmentId: number;
  includeNone?: boolean;
  filterControlBoardsWithoutLightSensor?: boolean;
  preselectedControlBoardId?: number;
  isNewSelection?: boolean;
  isOrgLevel?: boolean;
  siteIdWithoutResourceId?: number;
}

interface Props extends OwnProps {
  getControlBoards: typeof getControlBoards;
  controlBoards?: ControlBoard[];
  siteId: number | undefined;
}

interface State {
  search: string;
}

class SelectControlBoard extends React.Component<Props, State> {
  state = {
    search: '',
  };

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const {
      siteId,
      onSelect,
      isNewSelection,
      preselectedControlBoardId,
      siteIdWithoutResourceId,
    } = this.props;

    const getSiteId = () => {
      if (siteIdWithoutResourceId) {
        return { siteIds: [siteIdWithoutResourceId] };
      } else {
        return undefined;
      }
    };
    this.props.getControlBoards(siteId ? { siteIds: [siteId] } : getSiteId());

    if (isNewSelection && preselectedControlBoardId) {
      onSelect(preselectedControlBoardId);
    }
  }

  filteredControlBoards = (controlBoards: ControlBoard[]) => {
    const {
      filterControlBoardsWithoutLightSensor,
      siteId,
      siteIdWithoutResourceId,
    } = this.props;
    const { search } = this.state;
    let prefilteredControlBoards = controlBoards;

    if (siteId || siteIdWithoutResourceId) {
      prefilteredControlBoards = prefilteredControlBoards.filter(c => {
        return (
          c.networkGroup?.siteId === siteId ||
          c.networkGroup?.siteId === siteIdWithoutResourceId
        );
      });
    }

    if (filterControlBoardsWithoutLightSensor) {
      prefilteredControlBoards = prefilteredControlBoards.filter(c => {
        return c.lightSensorPort !== LightSensorPort.NONE;
      });
    }

    return prefilteredControlBoards.filter((controlBoard: ControlBoard) => {
      if (search.length <= 2) return true;
      return hasSubstr(controlBoard.title, search);
    });
  };

  render() {
    const {
      isNewSelection,
      preselectedControlBoardId,
      controlBoards,
      customErrorText,
      value,
      onSelect,
      disabled,
      error,
      includeNone,
      isOrgLevel,
    } = this.props;

    if (!controlBoards) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;
      const selectedControlBoard = controlBoards.find(
        controlBoard => controlBoard.id === value
      );
      const filteredControlBoards = this.filteredControlBoards(controlBoards);
      const boardsToMap = includeNone
        ? [NONE_OPTION, ...filteredControlBoards]
        : filteredControlBoards;

      const isNoneSelected = includeNone && value === -1;

      return (
        <Select
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={
            isNoneSelected ? NONE_OPTION.title : selectedControlBoard?.title
          }
          value={isNoneSelected ? NONE_OPTION.id : selectedControlBoard?.id}
          disabled={
            disabled || (isNewSelection && isDefined(preselectedControlBoardId))
          }
          customErrorText={customErrorText}
          error={error}
          size={isOrgLevel ? 'small' : undefined}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
            error={filteredControlBoards.length === 0}
          />
          {boardsToMap.map((controlBoard: ControlBoard) => (
            <SelectItem
              key={controlBoard.id}
              isSelected={
                isNoneSelected
                  ? controlBoard.id === -1
                  : controlBoard.id === selectedControlBoard?.id
              }
              onSelect={() => onSelect(controlBoard.id)}
            >
              {controlBoard.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = (
  { controlBoards, sites }: ApplicationState,
  { equipmentId }: OwnProps
) => ({
  controlBoards: values(controlBoards.controlBoardsById),
  siteId: sites.resourceIdToSiteId[equipmentId],
});

const mapDispatchToProps = {
  getControlBoards,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectControlBoard);
