import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getBreakerTypes } from '../../actions/app';
import { ApplicationState } from '../../reducers';
import { BreakerType } from '../../reducers/app';

interface OwnProps {
  onSelect: (breakerTypeId: string) => void;
  value?: string;
  error: boolean;
  disabled?: boolean;
}

interface Props extends OwnProps {
  getBreakerTypes: typeof getBreakerTypes;
  breakerTypes?: BreakerType[];
}

interface State {
  search: string;
}

class SelectBreakerTypes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { breakerTypes, getBreakerTypes } = this.props;
    if (!breakerTypes) getBreakerTypes();
  }

  render() {
    const { breakerTypes, value, onSelect, error, disabled } = this.props;

    if (!breakerTypes) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;

      const listedBreakerTypes = (breakerTypes || []).filter(
        (eq: BreakerType) => {
          if (search.length <= 2) return true;
          return hasSubstr(eq.title, search);
        }
      );

      const selectedBreakerType = breakerTypes.find(eq => eq.id === value);

      return (
        <Select
          disabled={disabled}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedBreakerType?.title}
          value={selectedBreakerType?.id}
          error={error}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
          />
          {listedBreakerTypes.map((eq: BreakerType) => (
            <SelectItem
              key={eq.id}
              isSelected={eq.id === selectedBreakerType?.id}
              onSelect={() => onSelect(eq.id)}
            >
              {eq.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  breakerTypes: app.breakerTypes,
});

const mapDispatchToProps = {
  getBreakerTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectBreakerTypes);
