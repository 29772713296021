import { TempCheck } from '@energybox/react-ui-library/dist/icons';
import { openModal } from '../actions/modal';
import history from '../history';
import { Routes } from '../routes';
import { ReminderType } from '@energybox/react-ui-library/dist/types/Reminder';
import { Reminder, deleteReminder } from '../actions/reminder';

type Props = {
  type: ReminderType;
  size: number;
};

export function ReminderTypeIcon({ type, size }: Props) {
  switch (type) {
    case ReminderType.DAILY_CHECK:
      return <TempCheck size={size} />;
    case ReminderType.NONE:
    default:
      return null;
  }
}

export const deleteReminderModal = (
  openModalFn: typeof openModal,
  actionFn: typeof deleteReminder,
  reminder: Reminder,
  targetTitle: string
) => {
  openModalFn({
    title: 'Delete configuration',
    description: `Do you really want to delete the configuration ${targetTitle}?`,
    actionFn: () => {
      actionFn(reminder);
      history.push(Routes.REMINDER);
    },
  });
};
