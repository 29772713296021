import { Organization } from '@energybox/react-ui-library/dist/icons';
import {
  AccessResource,
  ResourceType,
  Role,
  User,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Card,
  CardContent,
  Columns as ColumnsLayout,
  Table,
} from '@energybox/react-ui-library/dist/components';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteScopeFromUser } from '../../actions/users';
import { ApplicationState } from '../../reducers';
import { Trash } from '@energybox/react-ui-library/dist/icons';

import styles from './ShowUserPage.module.css';
import { IconButton } from '@material-ui/core';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import { OrganizationById } from '../../reducers/organizations';
import { IoIosAddCircle } from 'react-icons/io';
import { AddOrgAccessModal } from '../AddOrgAccessModal';
import { isCustomer } from '@energybox/react-ui-library/dist/utils';

type Props = {
  user: User;
};

const AccessToOrganizations: React.FC<Props> = ({ user }) => {
  const { accessResources, id } = user;

  const [isGrantAccessModalShowing, showGrantAccessModal] = useState(false);

  const dispatch = useDispatch();
  const revokeAccessFromOrg = (orgId: number) =>
    dispatch(deleteScopeFromUser('' + id, orgId));

  const orgsById = useSelector<ApplicationState, OrganizationById>(
    ({ organizations }) => organizations.organizationsById
  );

  const filteredAccessResources = accessResources.filter(
    ({ resourceType, role }) =>
      resourceType === ResourceType.ORGANIZATION && role === Role.INSTALLER
  );

  const tableColumns: Columns<AccessResource>[] = [
    {
      header: 'Organization',
      width: '45%',
      cellContent: ({ title, resourceId }) => (
        <div className={styles.orgAccessCell}>
          <Organization size={20} />
          <div>
            {title}
            {isCustomer(orgsById[resourceId]?.organizationType) && ` (${id})`}
          </div>
        </div>
      ),
    },
    {
      header: 'User Rights',
      width: '45%',
      cellContent: ({ role }) => (
        <div className={styles.orgAccessCell}>{role}</div>
      ),
    },
    {
      header: '',
      width: '10%',
      cellStyle: { textAlign: 'right' },
      cellContent: ({ resourceId }) => (
        <IconButton
          className={styles.deleteIconButton}
          onClick={() => revokeAccessFromOrg(resourceId)}
        >
          <Trash />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Card>
        <CardContent>
          <ColumnsLayout template="0.82fr 0.18fr">
            <h4 className="m0">Access to Organizations</h4>

            <Button
              variant="outlined"
              onClick={() => showGrantAccessModal(true)}
              className={styles.addOrgAccessButton}
            >
              <IoIosAddCircle size="20" />
              Access to Organizations
            </Button>
          </ColumnsLayout>

          <div style={{ marginTop: '20px' }}>
            <Table columns={tableColumns} data={filteredAccessResources} />
          </div>
        </CardContent>
      </Card>
      {isGrantAccessModalShowing && (
        <AddOrgAccessModal
          user={user}
          onClose={() => showGrantAccessModal(false)}
        />
      )}
    </>
  );
};

export default AccessToOrganizations;
