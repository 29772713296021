import {
  AccessResource,
  Site,
  SiteGroup,
  Organization,
} from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { connect } from 'react-redux';
import { getOrganizations } from '../../actions/organizations';
import { getSites, updateQuery } from '../../actions/sites';
import {
  getSiteGroups,
  updateQuery as updateSiteGroupsQuery,
} from '../../actions/site_groups';
import {
  addScopeToUser,
  deleteScopeFromUser,
  getUser,
  updateUserScope,
} from '../../actions/users';
import ResourceAccess from '../../components/ResourceAccess';
import { ApplicationState } from '../../reducers';
import { siteList, Sites } from '../../reducers/sites';
import { siteGroupsList } from '../../reducers/site_groups';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  updateQuery: (query: string) => void;
  addScopeToUser: (resourceId: string, scope: any) => {};
  editScope: (userId: string, scope: any) => {};
  deleteScopeFromUser: (userId: string, scopeId: string) => void;
  organizations?: Organization[];
  sites: Site[];
  siteGroups: SiteGroup[];
  scopes: AccessResource[];
  query: Sites['query'];
}

interface State {
  searchOpen: boolean;
}

class UserSiteAccess extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchOpen: false,
    };
  }

  componentDidMount() {
    this.props.load();
  }

  onResourceAccessDelete = (resourceId: string) => {
    this.props.deleteScopeFromUser(this.props.id, resourceId);
  };

  toggleSearch = () => {
    this.setState({ searchOpen: !this.state.searchOpen });
  };

  onNewResourceSelect = (resourceId: string[]) => {
    this.setState({ searchOpen: false });
    this.props.addScopeToUser(this.props.id, { resourceId, role: 'VIEWER' });
  };

  onExistingResourceSelect = () => {};

  onResourceAccessChange = (scopeId, updatedPermission: string) => {
    const scope = {
      resourceId: [scopeId],
      role: updatedPermission,
    };
    this.props.editScope(this.props.id, scope);
  };

  render() {
    const { searchOpen = false } = this.state;
    const {
      query = '',
      sites = [],
      siteGroups = [],
      scopes = [],
      organizations = [],
      updateQuery,
    } = this.props;
    const headerTitle = 'Access to Sites';
    return (
      <ResourceAccess
        headerTitle={headerTitle}
        searchOpen={searchOpen}
        query={query}
        sites={sites}
        siteGroups={siteGroups}
        scopes={scopes}
        orgs={organizations}
        onResourceAccessDelete={this.onResourceAccessDelete}
        onQueryChange={updateQuery}
        toggleSearch={this.toggleSearch}
        onNewResourceSelect={this.onNewResourceSelect}
        onExistingResourceSelect={this.onExistingResourceSelect}
        onResourceAccessChange={this.onResourceAccessChange}
      />
    );
  }
}

const mapStateToProps = (
  { users, sites, siteGroups, organizations }: ApplicationState,
  { id }: OwnProps
) => ({
  scopes: users.usersById[parseInt(id)].accessResources,
  sites: siteList(sites),
  siteGroups: siteGroupsList(siteGroups),
  organizations: organizations.currentOrganization
    ? [organizations.currentOrganization]
    : [],
  query: sites.query,
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => {
    dispatch(getUser(id));
    dispatch(getSites());
    dispatch(getSiteGroups());
    dispatch(getOrganizations());
  },
  deleteScopeFromUser: (userId, scopeId) =>
    dispatch(deleteScopeFromUser(userId, scopeId)),
  addScopeToUser: (userId, scope) => dispatch(addScopeToUser(userId, scope)),
  editScope: (userId, scope) => dispatch(updateUserScope(userId, scope)),
  updateQuery: (query = '') => {
    dispatch(updateSiteGroupsQuery(query));
    dispatch(updateQuery(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSiteAccess);
