import {
  Button,
  Card,
  CardContent,
  CardTitle,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import { Gateway, SirenConfig } from '@energybox/react-ui-library/dist/types';
import { Checkbox } from '@material-ui/core';

import React from 'react';
import { connect } from 'react-redux';
import {
  activateSiren,
  changeSirenConfig,
  deactivateSiren,
  sirenMute,
  sirenOff,
  sirenOn,
} from '../../actions/gateways';
import ModalFormContent from '../../components/ModalFormContent';
import styles from './GatewaySirenActions.module.css';

interface OwnProps {
  gateway: Gateway;
}
interface Props extends OwnProps {
  sirenOn: (seconds: number) => void;
  sirenOff: () => void;
  sirenMute: (seconds: number) => void;
  activateSiren: () => void;
  deactivateSiren: () => void;
  onSirenConfigSubmit: (sirenConfig: SirenConfig) => void;
}

interface State {
  muteSeconds: number;
  onSeconds: number;
  sirenConfig: SirenConfig;
}

class GatewaySirenActions extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const gatewayConfig = props.gateway.gatewayConfig || {};
    this.state = {
      muteSeconds: 0,
      onSeconds: 0,

      sirenConfig: {
        max_time: gatewayConfig.sirenMaxTime || 0,
        mute_time: gatewayConfig.sirenMuteTime || 0,
        ev_delay: gatewayConfig.sirenEvDelay || 0,
        default_armed: gatewayConfig.sirenDefaultArmed || false,
      },
    };
  }

  onMuteSecondsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.indexOf('-') > -1) return;
    const value = Number(e.currentTarget.value);
    this.setState({
      muteSeconds: value,
    });
  };

  onOnSecondsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.indexOf('-') > -1) return;
    const value = Number(e.currentTarget.value);
    this.setState({
      onSeconds: value,
    });
  };

  render() {
    const {
      sirenMute,
      sirenOff,
      sirenOn,
      activateSiren,
      deactivateSiren,
      onSirenConfigSubmit,
    } = this.props;
    const {
      muteSeconds,
      onSeconds,

      sirenConfig,
    } = this.state;

    return (
      <>
        <Card>
          <CardContent>
            <CardTitle className={styles.mainCardTitle}>
              Siren Configuration
            </CardTitle>
            <div className={styles.displayColumns}>
              <ModalFormContent>
                <div>
                  <Label>Max On Time (seconds)</Label>
                </div>
                <div>
                  <InputField
                    type="text"
                    name="max_time"
                    autoComplete="Max tTme"
                    value={sirenConfig.max_time}
                    onChange={e => {
                      if (e.currentTarget.value.indexOf('-') > -1) return;
                      if (
                        e.currentTarget.value.length < 32 &&
                        typeof Number(e.currentTarget.value) === 'number' &&
                        !isNaN(Number(e.currentTarget.value))
                      ) {
                        const newState = { ...this.state };
                        newState.sirenConfig.max_time = Number(
                          e.currentTarget.value
                        );
                        this.setState(newState);
                      }
                    }}
                  />
                </div>
                <div>
                  <Label>Mute Time (seconds)</Label>
                </div>
                <div>
                  <InputField
                    type="text"
                    name="muteTime"
                    autoComplete="Mute Time"
                    value={sirenConfig.mute_time}
                    onChange={e => {
                      if (e.currentTarget.value.indexOf('-') > -1) return;
                      if (
                        e.currentTarget.value.length < 32 &&
                        typeof Number(e.currentTarget.value) === 'number' &&
                        !isNaN(Number(e.currentTarget.value))
                      ) {
                        const newState = { ...this.state };
                        newState.sirenConfig.mute_time = Number(
                          e.currentTarget.value
                        );
                        this.setState(newState);
                      }
                    }}
                  />
                </div>
                <div>
                  <Label>Delay (seconds)</Label>
                </div>
                <div>
                  <InputField
                    type="text"
                    name="muteTime"
                    autoComplete="Mute Time"
                    value={sirenConfig.ev_delay}
                    onChange={e => {
                      if (e.currentTarget.value.indexOf('-') > -1) return;
                      if (
                        e.currentTarget.value.length < 32 &&
                        typeof Number(e.currentTarget.value) === 'number' &&
                        !isNaN(Number(e.currentTarget.value))
                      ) {
                        const newState = { ...this.state };
                        newState.sirenConfig.ev_delay = Number(
                          e.currentTarget.value
                        );
                        this.setState(newState);
                      }
                    }}
                  />
                </div>
                <div>
                  <Label>Default Armed</Label>
                </div>
                <div>
                  <Checkbox
                    checked={sirenConfig.default_armed}
                    onChange={() => {
                      const newState = { ...this.state };
                      newState.sirenConfig.default_armed = !newState.sirenConfig
                        .default_armed;
                      this.setState(newState);
                    }}
                  />
                </div>
                <Button
                  onClick={() => {
                    onSirenConfigSubmit(this.state.sirenConfig);
                  }}
                >
                  Submit
                </Button>
              </ModalFormContent>
              <div className={styles.rightGrid}>
                <div className={styles.firstRow}>
                  <Label>Toggle (seconds)</Label>
                  <InputField
                    value={onSeconds}
                    placeholder={'seconds'}
                    onChange={this.onOnSecondsChange}
                    type="number"
                  />
                  <Button onClick={() => sirenOn(onSeconds)}> On </Button>
                  <Button onClick={sirenOff}> Off </Button>
                </div>
                <div className={styles.secondRow}>
                  <Label>Mute (seconds)</Label>
                  <InputField
                    value={muteSeconds}
                    placeholder={'mute'}
                    onChange={this.onMuteSecondsChange}
                    type="number"
                  />
                  <Button onClick={() => sirenMute(muteSeconds)}> Mute </Button>
                </div>
                <div className={styles.thirdRow}>
                  <Label>Siren Activate</Label>
                  <Button onClick={activateSiren}> Activate </Button>
                  <Button onClick={deactivateSiren}> Deactivate </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any, { gateway }: OwnProps) => {
  const gatewayId = String(gateway.id);
  return {
    onSirenConfigSubmit: (sirenConfig: SirenConfig) => {
      dispatch(changeSirenConfig(gatewayId, sirenConfig));
    },
    sirenOn: (seconds: number = 0) => {
      dispatch(sirenOn(gatewayId, seconds));
    },
    sirenOff: () => {
      dispatch(sirenOff(gatewayId));
    },
    sirenMute: (seconds: number = 0) => {
      dispatch(sirenMute(gatewayId, seconds));
    },
    activateSiren: () => {
      dispatch(activateSiren(gatewayId));
    },
    deactivateSiren: () => {
      dispatch(deactivateSiren(gatewayId));
    },
  };
};

export default connect(null, mapDispatchToProps)(GatewaySirenActions);
