import { EnergyLineChart } from '@energybox/react-ui-library/dist/components';
import React, { useMemo } from 'react';
import {
  CircuitBreakerFromApiResponse,
  Equipment,
  Site,
  ZippedTSEnergySeriesData,
} from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { EnergyLineChartType } from '@energybox/react-ui-library/dist/components/EnergyLineChart';
import { getTime } from 'date-fns';
import { pathOr } from 'ramda';
import { ChartDetailInfo } from '../../hooks/useChartDetails';
import { EnergyProSensorReading } from '../../reducers/subscribedEnergyPros';
import { useAppLocale } from '../../hooks/useAppDetails';
import { useGetEquipmentBySiteId } from '../../hooks/useEquipment';
import { useGetEnergyBySensorId } from '../../hooks/energy';
import { processedDataFromZippedTSEnergySeriesData } from '../../utils/timeSeries';
import { ApplicationState } from '../../reducers';
import ChartCardContainer from '../../components/ChartCard/ChartCardContainer';
import { withFilters } from './EnergyChartHOC/EnergyChartHOC';

interface ChartProps {
  chartDetails: ChartDetailInfo;
  sensor: EnergyProSensorReading;
  chartType: EnergyLineChartType;
  site: Site;
}

interface ChartCardProps extends ChartProps {
  breaker?: CircuitBreakerFromApiResponse;
}

interface ChartWithFilterProps extends ChartProps {
  header?: React.ReactNode;
}

const SensorEnergyChart: React.FC<ChartProps> = props => {
  const { chartDetails, sensor, site, chartType } = props;
  const locale = useAppLocale();

  useGetEquipmentBySiteId(site.id);

  const {
    xTicks,
    zoom,
    zoomRange,
    fromDate,
    toDate,
    aggregationFilter,
  } = chartDetails;

  const energyQueryParams = useMemo(
    () => ({
      id: sensor.id,
      from: fromDate,
      to: toDate,
      aggregationLevel: aggregationFilter?.aggregationLevel,
      seeSpilloverPoint: true,
    }),
    [sensor.id, fromDate, toDate, aggregationFilter?.aggregationLevel]
  );

  const { data, isLoading } = useGetEnergyBySensorId(
    sensor.id,
    energyQueryParams
  );

  const processedData = useMemo(() => {
    if (!data) return [];

    return processedDataFromZippedTSEnergySeriesData(data);
  }, [data]);

  return (
    <EnergyLineChart
      isLoading={isLoading}
      locale={locale}
      ianaTimeZoneCode={site.timeZone}
      fromDate={zoomRange.from}
      toDate={zoomRange.to}
      ticks={xTicks}
      data={processedData}
      zoom={zoom}
      hideThresholdLinesCheckbox
      hideNotificationsCheckbox
      hideCommentsCheckbox
      type={chartType}
      displayCurrentInLegend
      thresholdLines={[]}
    />
  );
};

const withCard = Component => (props: ChartCardProps) => {
  const { sensor, breaker, chartDetails } = props;
  const { zoomRange } = chartDetails;

  const equipment = useSelector<ApplicationState, Equipment | null>(
    ({ equipment }) => {
      return sensor && sensor.equipmentId
        ? equipment.equipmentById[sensor.equipmentId] ?? null
        : null;
    }
  );

  const data = useSelector<
    ApplicationState,
    ZippedTSEnergySeriesData[] | undefined
  >(({ energy }) => {
    return pathOr(undefined, ['energyBySensorId', sensor.id], energy);
  });

  const energyTotal = (): number => {
    if (!zoomRange) return 0;

    return (
      data
        ?.filter(r => {
          const timestamp = getTime(new Date(r.time));
          return timestamp >= zoomRange.from && timestamp <= zoomRange.to;
        })
        .reduce((sum, current) => sum + current.energy, 0) ?? 0
    );
  };

  const subTitle =
    breaker?.title && breaker?.description
      ? `${breaker?.title} / ${breaker?.description} / ${sensor.indexString}`
      : sensor.indexString;

  return (
    <ChartCardContainer
      title={equipment?.title ?? breaker?.title}
      subTitle={subTitle}
      energyTotal={energyTotal()}
    >
      <Component {...props} />
    </ChartCardContainer>
  );
};

export const SensorEnergyChartCard = withCard(SensorEnergyChart);
export const SensorEnergyChartWithFilter = withFilters<ChartWithFilterProps>(
  SensorEnergyChart
);

export default SensorEnergyChart;
