import {
  CardListHeader,
  CardListRowData,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  ComponentTypes,
  Sop,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  SORT_IGNORED_VALUES,
  classNames,
  genericTableSort,
  global,
} from '@energybox/react-ui-library/dist/utils';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import {
  convertWeekdays,
  mapWeekdays,
  sortWeekdays,
} from '../../utils/getWeekdayRange';
import { OrgSopAppliedCountsByEquipmentType } from '../../reducers/sop';
import {
  MenuDropdown,
  MenuDropdownItem,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import { PageContentHeader } from '../../components/Page';
import { Placeholder } from '../../types/global';
import theme from '../../theme';

export const getHeader = (level: string) => {
  const orgId = useCurrentOrganizationId();
  const siteLevelColumns = [
    {
      width: '1',
      content: 'SOP Level',
      comparator: (a: Sop, b: Sop, sortDirection: SortDirection) => {
        const determineOrgOrSiteLevel = (sop: Sop) => {
          const isOrgLevel = sop.organizationUnitId === orgId;
          return isOrgLevel ? 'Organization' : 'Site';
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          determineOrgOrSiteLevel
        );
      },
    },
  ];
  const orgLevelColumns = [
    {
      width: '1',
      content: 'Org SOP',
    },
    {
      width: '1',
      content: 'Site SOP',
    },
  ];
  const columnsByLevel = level === 'site' ? siteLevelColumns : orgLevelColumns;
  const columns = [
    {
      width: '3',
      content: 'Title/Description',
      comparator: (a: Sop, b: Sop, sortDirection: SortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
        ]);
      },
    },
    {
      width: '2',
      content: 'Task Type',
    },
    {
      width: '2',
      content: 'Days',
    },
    {
      width: '2',
      content: 'Attach to',
    },
    ...columnsByLevel,
    {
      width: '1',
      align: 'right',
      content: '',
    },
  ];
  return columns as CardListHeader[];
};

export const getFormData = (level, sopId, taskSops, orgId) => {
  const foundSOP = taskSops.find(sop => sop.id === sopId);
  const isOrgLevelSop = foundSOP?.organizationUnitId === orgId;
  if (foundSOP && foundSOP.components[0].type === 'TASK') {
    return {
      id: level === 'site' && isOrgLevelSop ? 'customize' : foundSOP.id,
      title: foundSOP.title,
      description: foundSOP.description,
      entityType: foundSOP.equipmentTypeIds,
      dayOfWeek: mapWeekdays(foundSOP.components[0].taskSettings.weekdays),
      timeOfDay: foundSOP.components[0].taskSettings.time,
    };
  } else {
    return {};
  }
};

const findOverrideSop = (sop, taskSops, orgId) => {
  return taskSops.find(item => {
    return (
      item.organizationUnitId !== orgId &&
      sop.equipmentTypeIds.reduce((match, equipTypeId) => {
        return item.equipmentTypeIds.includes(equipTypeId) || match;
      }, false)
    );
  });
};

const getOrgCountByEquipmentType = (
  equipmentTypeIds,
  equipmentTypeCounts = {}
) => {
  const equipTypeOrgCounts = equipmentTypeIds.map(equipmentTypeId => {
    const equipTypeOrgCount = equipmentTypeCounts[equipmentTypeId]?.reduce(
      (counts, equip) => {
        const count = equip.generic.filter(
          item => item.sopComponentType === 'TASK'
        ).length;
        return counts + count;
      },
      0
    );
    return { typeId: equipmentTypeId, count: equipTypeOrgCount };
  });
  return equipTypeOrgCounts;
};

const getSiteCountByEquipmentType = (
  equipmentTypeIds,
  equipmentTypeCounts = {}
) => {
  const equipTypeOrgCounts = equipmentTypeIds.map(equipmentTypeId => {
    const equipTypeOrgCount = equipmentTypeCounts[equipmentTypeId]?.reduce(
      (counts, equip) => {
        const count = equip.localised.filter(
          item => item.sopComponentType === 'TASK'
        ).length;
        return counts + count;
      },
      0
    );
    return { typeId: equipmentTypeId, count: equipTypeOrgCount };
  });
  return equipTypeOrgCounts;
};

export const getDisplayRowData = (
  level: 'site' | 'org',
  taskSop: Sop[],
  styles: any,
  params: {
    siteLevel?: {
      siteEquipmentTypeIds: number[];
      hasSiteAccess: boolean;
      hasOrgAccess: boolean;
      handleEditSop: (sopId: number) => void;
      handleDeleteSop: (sopId: number) => void;
    };
    orgLevel?: {
      equipmentTypeCounts: OrgSopAppliedCountsByEquipmentType;
      hasAccess: boolean;
      handleEditSop: (sopId: number) => void;
      handleDeleteSop: (sopId: number) => void;
    };
  }
) => {
  const isSiteLevel = level === 'site';
  const orgId = useCurrentOrganizationId();
  return (
    taskSop.map(item => {
      const itemComponents: ComponentTypes = item.components[0];
      const { weekdays } = itemComponents['taskSettings'];
      const weekdayString = convertWeekdays(weekdays);
      // site level vars
      const isOrgLevelSop = item.organizationUnitId === orgId;
      const hasOverride = findOverrideSop(item, taskSop, orgId);
      // org level vars
      const orgEquipmentCount = getOrgCountByEquipmentType(
        item.equipmentTypeIds,
        params.orgLevel?.equipmentTypeCounts
      );
      const siteEquipmentCount = getSiteCountByEquipmentType(
        item.equipmentTypeIds,
        params.orgLevel?.equipmentTypeCounts
      );
      return {
        key: `taskSOP_item${item.id}`,
        dataObject: item,
        isDisabled: isSiteLevel
          ? isOrgLevelSop && hasOverride !== undefined
          : false,
        content: (
          <>
            <Cell width="3" className={classNames(styles.cardInfoText)}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.description}>{item.description}</div>
            </Cell>

            <Cell width="2" className={classNames(styles.cardInfoText)}>
              <div className={styles.taskType}>
                {itemComponents['taskSettings'].taskType === 'DAILY_CHECK'
                  ? 'Daily Temp Check'
                  : ''}
              </div>
            </Cell>

            <Cell width="2" className={classNames(styles.cardInfoText)}>
              <div>
                <div>
                  {typeof weekdayString === 'string'
                    ? weekdayString
                    : sortWeekdays(weekdays).join(', ')}
                </div>
                <div>Check 1: {itemComponents['taskSettings'].time}</div>
              </div>
            </Cell>

            <Cell width="2" className={classNames(styles.cardInfoText)}>
              <div>
                {item.equipmentTypes.map(equipType => {
                  if (
                    isSiteLevel &&
                    isOrgLevelSop &&
                    !params.siteLevel?.siteEquipmentTypeIds.includes(
                      equipType.id
                    )
                  ) {
                    return (
                      <div
                        key={`taskSOP_item${item.id}_equipType${equipType.id}`}
                      >
                        {global.NOT_AVAILABLE}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={`taskSOP_item${item.id}_equipType${equipType.id}`}
                      >
                        {equipType.title}
                      </div>
                    );
                  }
                })}
              </div>
            </Cell>

            {!isSiteLevel && (
              <Cell width="1" className={classNames(styles.cardInfoText)}>
                <div>
                  {item.equipmentTypes.map(equipType => {
                    return (
                      <div
                        key={`taskSOP_item${item.id}_orgEquipCount${equipType.id}`}
                      >
                        {
                          orgEquipmentCount.find(
                            count => count.typeId === equipType.id
                          ).count
                        }
                      </div>
                    );
                  })}
                </div>
              </Cell>
            )}

            {!isSiteLevel && (
              <Cell width="1" className={classNames(styles.cardInfoText)}>
                <div>
                  {item.equipmentTypes.map(equipType => {
                    return (
                      <div
                        key={`taskSOP_item${item.id}_siteEquipCount${equipType.id}`}
                      >
                        {
                          siteEquipmentCount.find(
                            count => count.typeId === equipType.id
                          ).count
                        }
                      </div>
                    );
                  })}
                </div>
              </Cell>
            )}

            {!isSiteLevel && (
              <Cell width="1" className={styles.moreDetails}>
                {params.orgLevel?.hasAccess ? (
                  <MenuDropdown>
                    <MenuDropdownItem
                      onSelect={() => params.orgLevel?.handleEditSop(item.id)}
                    >
                      Edit
                    </MenuDropdownItem>
                    <MenuDropdownItem
                      isRed
                      onSelect={() => params.orgLevel?.handleDeleteSop(item.id)}
                    >
                      Delete
                    </MenuDropdownItem>
                  </MenuDropdown>
                ) : (
                  <></>
                )}
              </Cell>
            )}

            {isSiteLevel && (
              <Cell width="1" className={classNames(styles.cardInfoText)}>
                <div>{isOrgLevelSop ? 'Organisation' : 'Site'}</div>
              </Cell>
            )}

            {isSiteLevel &&
              (params.siteLevel?.hasSiteAccess ||
                params.siteLevel?.hasOrgAccess) && (
                <Cell width="1" className={styles.moreDetails}>
                  <MenuDropdown>
                    {isOrgLevelSop ? (
                      <MenuDropdownItem
                        onSelect={() =>
                          params.siteLevel?.handleEditSop(item.id)
                        }
                      >
                        Customize
                      </MenuDropdownItem>
                    ) : (
                      <>
                        <MenuDropdownItem
                          onSelect={() =>
                            params.siteLevel?.handleEditSop(item.id)
                          }
                        >
                          Edit
                        </MenuDropdownItem>
                        <MenuDropdownItem
                          isRed
                          onSelect={() =>
                            params.siteLevel?.handleDeleteSop(item.id)
                          }
                        >
                          Delete
                        </MenuDropdownItem>
                      </>
                    )}
                  </MenuDropdown>
                </Cell>
              )}
          </>
        ),
      } as CardListRowData;
    }) || []
  );
};

export const getCommonPageHeader = (
  taskSops,
  headerText,
  query,
  handleSearchChange,
  isMobile
) => {
  return (
    <PageContentHeader header={`${taskSops.length} ${headerText}`}>
      <SearchBox
        placeholder={Placeholder.seachBox}
        onChange={handleSearchChange}
        query={query}
        width={
          isMobile
            ? theme.size.table.searchBox.mobile
            : theme.size.table.searchBox.web
        }
        widthActive={
          isMobile
            ? theme.size.table.searchBox.mobile
            : theme.size.table.searchBox.web
        }
      />
    </PageContentHeader>
  );
};
