import { ApplicationState } from '../reducers';

export enum Actions {
  GET_SITE_GROUPS_SUCCESS = '@siteGroups/GET_SITE_GROUPS_SUCCESS',
  GET_SITE_GROUPS_ERROR = '@siteGroups/GET_SITE_GROUPS_ERROR',
  GET_SITE_GROUPS_LOADING = '@siteGroups/GET_SITE_GROUPS_LOADING',

  GET_SUCCESS = '@siteGroups/GET_SUCCESS',
  GET_ERROR = '@siteGroups/GET_ERROR',
  GET_LOADING = '@siteGroups/GET_LOADING',

  TOGGLE_NEW_SITEGROUP_MODAL = '@siteGroups/TOGGLE_NEW_SITEGROUP_MODAL',

  UPDATE_FIELD = '@siteGroups/UPDATE_FIELD',
  RESET_FIELDS = '@siteGroups/RESET_FIELDS',

  CREATE_LOADING = '@siteGroups/CREATE_LOADING',
  CREATE_SUCCESS = '@siteGroups/CREATE_SUCCESS',
  CREATE_ERROR = '@siteGroups/CREATE_ERROR',

  PATCH_LOADING = '@siteGroups/PATCH_LOADING',
  PATCH_ERROR = '@siteGroups/PATCH_ERROR',
  PATCH_SUCCESS = '@siteGroups/PATCH_SUCCESS',

  DELETE_LOADING = '@siteGroups/DELETE_LOADING',
  DELETE_SUCCESS = '@siteGroups/DELETE_SUCCESS',
  DELETE_ERROR = '@siteGroups/DELETE_ERROR',

  UPDATED_QUERY = '@siteGroups/UPDATED_QUERY',

  DISPLAY_FORM_ERRORS = '@siteGroups/DISPLAY_FORM_ERRORS',
}

export const getSiteGroups = () => ({
  type: 'API_GET',
  path: '/api/v1/site-groups',
  success: Actions.GET_SITE_GROUPS_SUCCESS,
  error: Actions.GET_SITE_GROUPS_ERROR,
  loading: Actions.GET_SITE_GROUPS_LOADING,
});

export const getGroup = (id: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/site-groups/${id}`,
  success: Actions.GET_SUCCESS,
  error: Actions.GET_ERROR,
  loading: Actions.GET_LOADING,
});

export const getGroupSites = (groupId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/sites?groupId=${groupId}`,
  success: { type: Actions.GET_SUCCESS, groupId },
  error: { type: Actions.GET_ERROR, groupId },
  loading: { type: Actions.GET_LOADING, groupId },
});

export const showNewModal = () => ({
  type: Actions.TOGGLE_NEW_SITEGROUP_MODAL,
  value: true,
});

export const hideNewModal = () => ({
  type: Actions.TOGGLE_NEW_SITEGROUP_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const updateField = (
  id: number | string,
  field: string,
  value: string | number[]
) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const create = () => (dispatch, getState: () => ApplicationState) => {
  dispatch({
    type: 'API_POST',
    path: '/api/v1/site-groups',
    payload: getState().siteGroups.editById['new']['fields'],
    loading: Actions.CREATE_LOADING,
    error: Actions.CREATE_ERROR,
    success: Actions.CREATE_SUCCESS,
  });
};

export const patch = (id: number | string) => (
  dispatch,
  getState: () => ApplicationState
) => {
  dispatch({
    type: 'API_PATCH',
    path: `/api/v1/site-groups/${id}`,
    payload: getState().siteGroups.editById[id]['fields'],
    loading: { type: Actions.PATCH_LOADING, id },
    success: { type: Actions.PATCH_SUCCESS, id },
    error: { type: Actions.PATCH_ERROR, id },
  });
};

export const reset = (id: number | string) => ({
  type: Actions.RESET_FIELDS,
  id,
});

export const destroy = (id: number | string) => ({
  type: 'API_DELETE',
  path: `/api/v1/site-groups/${id}`,
  loading: Actions.DELETE_LOADING,
  success: { type: Actions.DELETE_SUCCESS, id },
  error: Actions.DELETE_ERROR,
});

export const updateQuery = (query: string) => ({
  type: Actions.UPDATED_QUERY,
  query,
});
