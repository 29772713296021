export const getWeekDayRange = (days: string[]) => {
  const numbers = days.map(day => {
    switch (day) {
      case 'MON':
        return 0;
      case 'TUE':
        return 1;
      case 'WED':
        return 2;
      case 'THU':
        return 3;
      case 'FRI':
        return 4;
      case 'SAT':
        return 5;
      default:
        return 6;
    }
  });
  const dayStrings = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const validOutputs = ['Weekdays', 'Weekend', 'Everyday'];

  const randomDays = numbers.sort().map(number => dayStrings[number]);
  const convertdays = convertWeekdays(randomDays);

  if (validOutputs.includes(convertdays)) {
    return convertdays;
  } else {
    return randomDays
      .map(day => day.charAt(0) + day.slice(1).toLowerCase())
      .join(', ');
  }
};

export const mapWeekdays = weekdays => {
  const weekInShort = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  return weekdays.map(day => {
    return weekInShort.findIndex(el => el === day);
  });
};

export const sortWeekdays = weekdays => {
  const sortRef = { MON: 0, TUE: 1, WED: 2, THU: 3, FRI: 4, SAT: 5, SUN: 6 };
  const sorted = weekdays.sort((a, b) => {
    return sortRef[a] - sortRef[b];
  });
  return sorted;
};

export const convertWeekdays = weekdays => {
  const weekday = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
  const weekend = ['SAT', 'SUN'];
  const isWeekday = weekday.reduce((hasDay, day) => {
    return weekdays.includes(day) && hasDay;
  }, true);
  const isWeekend = weekend.reduce((hasDay, day) => {
    return weekdays.includes(day) && hasDay;
  }, true);
  const hasWeekday = weekday.reduce((hasDay, day) => {
    return weekdays.includes(day) || hasDay;
  }, false);
  const hasWeekend = weekend.reduce((hasDay, day) => {
    return weekdays.includes(day) || hasDay;
  }, false);
  if (isWeekday && isWeekend) {
    return 'Everyday';
  } else if (isWeekday && !isWeekend && !hasWeekend) {
    return 'Weekdays';
  } else if (isWeekend && !isWeekday && !hasWeekday) {
    return 'Weekend';
  } else {
    return weekdays;
  }
};
