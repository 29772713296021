import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SpaceActions,
  destroy,
  hideDeleteModal,
  unstageDelete,
} from '../../actions/spaces';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface Props {
  isVisible: boolean;
  primedForDelete: number;
  onDelete: (id: number) => void;
  onClose: () => void;
  unstageDelete: () => void;
  apiError: ApiError;
  spaceTitle?: string;
}

class DeleteSpaceModal extends React.Component<Props> {
  render() {
    if (!this.props.isVisible) return null;

    const { onDelete, onClose, apiError, spaceTitle } = this.props;

    const confirmDelete = (id: number) => {
      onDelete(id);
    };
    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>

        <Button
          onClick={() => {
            confirmDelete(this.props.primedForDelete);
          }}
        >
          Delete
        </Button>
      </>
    );
    return (
      <Modal actions={actions}>
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ textAlign: 'justify', textAlignLast: 'center' }}>
            <div>
              This space contains equipment, sensors, gateways and subspaces.
            </div>
            <div> When deleting this space all content will be relocated.</div>
            <div style={{ padding: '1rem 0' }}>
              Are you sure you want to delete{' '}
              {spaceTitle ? (
                <span style={{ fontWeight: 'bold' }}>{spaceTitle}</span>
              ) : (
                'this space'
              )}
              ?
            </div>
          </div>
        </div>
        {renderAPIerror(apiError, SpaceActions.DELETE_SPACE_ERROR)}
      </Modal>
    );
  }
}

const mapStateToProps = ({ spaces }: ApplicationState) => {
  return {
    spaceTitle:
      spaces.spacesById[spaces.primedForDelete] &&
      spaces.spacesById[spaces.primedForDelete].title,
    isVisible: spaces.showDeleteModal,
    primedForDelete: spaces.primedForDelete,
    apiError:
      spaces.editById[spaces.primedForDelete] &&
      spaces.editById[spaces.primedForDelete].apiError,
  };
};

const mapDispatchtoProps = (dispatch: any) => ({
  onDelete: id => {
    dispatch(destroy(id));
  },
  onClose: () => {
    dispatch(unstageDelete());
    dispatch(hideDeleteModal());
  },
  unstageDelete: () => {
    dispatch(unstageDelete());
  },
});

export default connect(mapStateToProps, mapDispatchtoProps)(DeleteSpaceModal);
