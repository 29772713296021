import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemOverrideActivityDataStatus } from '@energybox/react-ui-library/dist/types';
import { ApplicationState } from '../reducers';
import { getSystemOverrideActivityByControlId } from '../actions/controls';

const useGetSystemOverrideActivity = (
  controlId: number | undefined,
  fromDate: number,
  toDate: number
): SystemOverrideActivityDataStatus | undefined => {
  const dispatch = useDispatch();

  const systemOverrideData = useSelector<
    ApplicationState,
    SystemOverrideActivityDataStatus | undefined
  >(({ controls }) => {
    if (!controlId) return undefined;
    return controls.systemOverrideByControlId[controlId];
  });

  useEffect(() => {
    if (controlId) {
      dispatch(
        getSystemOverrideActivityByControlId(
          controlId,
          fromDate.valueOf(),
          toDate.valueOf()
        )
      );
    }
  }, [dispatch, controlId, fromDate, toDate]);

  return systemOverrideData;
};

export default useGetSystemOverrideActivity;
