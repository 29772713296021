import { ErrorIcon } from '@energybox/react-ui-library/dist/icons';
import styles from './accessDeniedError.module.css';

export const pageNotFoundError = () => {
  return (
    <div className={styles.body}>
      <span className={styles.error}>
        <ErrorIcon size={45} /> 404
      </span>
      <span className={styles.mainText}>Page/Resource Not Found</span>
      <span className={styles.subText}>
        The page/resource you are looking for does not exist. Please <br />
        check the URL or return to Home Page.
      </span>
    </div>
  );
};
