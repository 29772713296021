import {
  User,
  KioskSettings,
  Site,
} from '@energybox/react-ui-library/dist/types';
import {
  Card,
  CardContent,
  Label,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUserKioskSettings,
  updateUserKioskSettings,
} from '../../../actions/users';
import { useGetSites } from '../../../hooks/useSites';
import { kioskUpdateLoadingStatusSelector } from '../../../selectors/user/user';
import styles from './UserKioskSettings.module.css';

type Props = {
  user?: User;
};

const UserKioskSettings = ({ user }: Props) => {
  const dispatch = useDispatch();
  const [selectedSite, setSelectedSite] = useState<Site | undefined>(undefined);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [siteSearchValue, setSiteSearchValue] = useState('');

  const kioskUpdateIsLoading = useSelector(kioskUpdateLoadingStatusSelector);

  const { sitesById } = useGetSites();
  const sites = useMemo(() => {
    let result = Object.values(sitesById);
    if (siteSearchValue) {
      result = result.filter(site => {
        return site.title.toLowerCase().includes(siteSearchValue.toLowerCase());
      });
    }
    return result;
  }, [sitesById, siteSearchValue]);

  useEffect(() => {
    if (!user) return;
    if (shouldUpdate && !kioskUpdateIsLoading) {
      const fields: KioskSettings = {
        preferredSiteId: selectedSite?.id,
      };
      if (user.kiosk) {
        dispatch(updateUserKioskSettings(user.id, fields));
      } else {
        dispatch(createUserKioskSettings(user.id, fields));
      }
      setShouldUpdate(false);
    }
  }, [user, kioskUpdateIsLoading, shouldUpdate, selectedSite, dispatch]);

  useEffect(() => {
    const preferredSiteId = user?.kiosk?.preferredSiteId;
    if (preferredSiteId !== undefined)
      setSelectedSite(sitesById[preferredSiteId]);
  }, [sitesById, user]);

  return (
    <Card>
      <CardContent>
        <h4 className="m0">Kiosk Settings</h4>
        <div className={styles.inputGroup}>
          <Label>Site Preference</Label>
          <div>
            <Select
              title={selectedSite?.title}
              value={selectedSite?.id}
              onClick={() => setSiteSearchValue('')}
            >
              <SelectSearch
                placeholder="Type to search"
                value={siteSearchValue}
                onChange={e => setSiteSearchValue(e.currentTarget.value)}
              />

              {sites.map(site => (
                <SelectItem
                  key={site.id}
                  isSelected={site.id === selectedSite?.id}
                  onSelect={() => {
                    setSelectedSite(site);
                    setShouldUpdate(true);
                  }}
                >
                  {site.title}
                </SelectItem>
              ))}
            </Select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserKioskSettings;
