import {
  SortDirection,
  UsersById,
} from '@energybox/react-ui-library/dist/types';
import {
  values,
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  Card,
  CardContent,
  Table,
  TextCircle,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { connect } from 'react-redux';
import { removeUserFromGroup } from '../../actions/users';
import { getGroups } from '../../actions/user_groups';
import AccessResourceRecord from '../../components/AccessResourceRecord';
import { ApplicationState } from '../../reducers';
import { UserGroup } from '../../reducers/user_groups';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import { aggregateAccessForUserGroup } from '../../utils/user';
import { Trash } from '@energybox/react-ui-library/dist/icons';

import styles from './ShowUserPage.module.css';
import { IconButton } from '@material-ui/core';

interface OwnProps {
  userId: number;
  groupIds: number[];
  usersById: UsersById;
}

interface Props extends OwnProps {
  getAllGroups: typeof getGroups;
  removeFromGroup: (groupId) => void;
  allGroups: UserGroup[];
}

class InstallerEditInstallerGroups extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectSearch: '',
    };
  }

  componentDidMount() {
    this.props.getAllGroups();
  }

  displayedGroups() {
    const { allGroups, groupIds } = this.props;
    return allGroups.filter(({ id }: UserGroup) => groupIds.includes(id));
  }

  render() {
    const { removeFromGroup, usersById } = this.props;
    const userGroups = this.displayedGroups();

    const tableColumns = [
      {
        header: 'Installer Group Name',
        cellContent: ({ title, id }: UserGroup) => (
          <>
            <TextCircle>{`#${title ? title[0] : ''}`}</TextCircle>
            <Link
              style={{ marginLeft: '30px' }}
              to={`${Routes.USER_GROUPS}/${id}`}
            >
              {title}
            </Link>
          </>
        ),
        comparator: (
          a: UserGroup,
          b: UserGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        header: 'Access to Organizations',
        cellContent: (userGroup: UserGroup) => (
          <>
            {aggregateAccessForUserGroup(userGroup, usersById).map(resource => (
              <AccessResourceRecord key={resource.id} {...resource} />
            ))}
          </>
        ),
      },
      {
        header: '',
        width: '180px',
        cellStyle: { textAlign: 'right' },
        cellContent: ({ id }: UserGroup) => (
          <IconButton
            className={styles.deleteIconButton}
            onClick={() => removeFromGroup(id)}
          >
            <Trash />
          </IconButton>
        ),
      },
    ];

    return (
      <Card>
        <CardContent>
          <h4 className="m0">Groups ({userGroups.length})</h4>

          <div style={{ marginTop: '20px' }}>
            <Table columns={tableColumns} data={userGroups} />
          </div>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = ({ userGroups }: ApplicationState) => ({
  allGroups: values(userGroups.userGroupsById),
});

const mapDispatchToProps = (dispatch: any, { userId }: OwnProps) => ({
  getAllGroups: () => dispatch(getGroups()),
  removeFromGroup: groupId => dispatch(removeUserFromGroup(userId, groupId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallerEditInstallerGroups);
