import { ApplicationState } from '../../reducers';
import { Actions } from '../../actions/energy_pros';

export const energyProsByIdSelector = ({ energyPros }: ApplicationState) =>
  energyPros.energyProsById;

export const energyProIdsBySiteIdSelector = ({
  energyPros,
}: ApplicationState) => energyPros.energyProIdsBySiteId;

export const energyProsLoadingBySiteIdSelector = ({
  energyPros,
}: ApplicationState) =>
  energyPros.loadingStatusByAction[Actions.GET_ENERGY_PROS_BY_SITE_ID_LOADING];
