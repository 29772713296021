import React, { useEffect, useState } from 'react';
import { ProgressBar } from '@energybox/react-ui-library/dist/components';
import { global } from '@energybox/react-ui-library/dist/utils';
import styles from './LoaderLayer.module.css';

type Props = {
  isVisible: boolean;
  progress?: number;
  remainingSeconds?: number;
  isEdm?: boolean;
};

let visibilityTimeout;
const LoaderLayer: React.FC<Props> = ({
  isVisible,
  progress = 0,
  remainingSeconds = 0,
  isEdm,
}) => {
  /*
   * When parent claims that this loading layer should be hidden, give it 1 second delay.
   * This is useful when actual loading time is shorter than that of estimated.
   * With this delay, user sees this loading bar jumping to 100% for a second before it disappears.
   */
  const [isReallyVisible, setIsReallyVisible] = useState(isVisible);
  const [forceShowFullProgress, setForceShowFullProgress] = useState(false);
  useEffect(() => {
    if (isVisible) {
      clearTimeout(visibilityTimeout);
      setIsReallyVisible(true);
      setForceShowFullProgress(false);
    } else {
      setForceShowFullProgress(true);
      visibilityTimeout = setTimeout(() => {
        setIsReallyVisible(false);
        setForceShowFullProgress(false);
      }, 1000);
    }
  }, [isVisible]);

  if (!isReallyVisible || isNaN(progress)) {
    return null;
  }
  // show 99% instead of 100% and 1s instead of 0s when the request takes longer than estimated
  const perceivedProgress = forceShowFullProgress
    ? 100
    : Math.min(progress, 99);
  const perceivedRemainingSeconds = forceShowFullProgress
    ? 0
    : Math.max(remainingSeconds, 1);
  return (
    <div className={isEdm ? styles.loaderFromOrgLevel : styles.root}>
      <div className={styles.title}>{`${Math.round(perceivedProgress)}%`}</div>
      <div className={styles.description}>
        Please wait while we are compiling your{' '}
        {isEdm ? 'DemandManager' : 'inspection'} report...
      </div>
      <div className={styles.progressBarContainer}>
        <ProgressBar
          className={styles.progressBar}
          perceivedProgress={perceivedProgress}
          showDots
        />
        <div className={styles.remainingTime}>{`${
          perceivedProgress > 0
            ? perceivedRemainingSeconds
            : global.NOT_AVAILABLE
        } sec`}</div>
      </div>
    </div>
  );
};

export default LoaderLayer;
