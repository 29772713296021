import {
  Columns,
  TextCircle,
} from '@energybox/react-ui-library/dist/components';
import { pluralize } from '@energybox/react-ui-library/dist/utils/util';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

interface ListItem {
  title: string;
  displayTitle: string;
  linkTo?: string;
}

interface Props extends RouteComponentProps {
  history: any;
  limit?: number;
  resource: string;
  items: ListItem[];
  moreButtonLinkTo?: string;
}

interface MoreButtonProps {
  resource: string;
  count: number;
  limit: number;
  linkTo?: string;
}

const MoreButton = ({
  resource,
  count,
  limit,
  linkTo = '#',
}: MoreButtonProps) => {
  if (count === 0) {
    return <span>0 users</span>;
  } else if (count <= limit) {
    return (
      <Link to={linkTo}>
        {count} {pluralize(resource, count)}
      </Link>
    );
  } else {
    return (
      <Link to={linkTo}>
        +{count - limit} {pluralize(resource, count - limit)}
      </Link>
    );
  }
};

const BubblesList = ({
  history,
  resource,
  limit = 10,
  items,
  moreButtonLinkTo,
}: Props) => (
  <Columns template={`${22 * limit}px auto`}>
    <div>
      {items
        .map(({ title, displayTitle, linkTo }: ListItem) => (
          <TextCircle
            key={title}
            onClick={() => linkTo && history.push(linkTo)}
            title={title}
            children={displayTitle}
          />
        ))
        .slice(0, limit)}
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <MoreButton
        resource={resource}
        count={items.length}
        limit={limit}
        linkTo={moreButtonLinkTo}
      />
    </div>
  </Columns>
);

export default withRouter(BubblesList);
