export const handleImageUpload = async (
  e: any,
  id: string,
  uploadPicture: (id: string, formData: FormData) => void,
  handleError: (errorMessage: string) => void
) => {
  const formData = new FormData();
  const imageToUpload: File = e.target.files[0];
  let isImageValid = await validateImageDimensions(imageToUpload);

  if (!isImageValid) {
    handleError('Image width and height must be between 128px and 2048px');
  } else {
    formData.append('file', imageToUpload);
    uploadPicture(id, formData);
  }
};

export const validateImageDimensions = async (imageToUpload: File) => {
  let mockImg = new Image();
  mockImg.src = window.URL.createObjectURL(imageToUpload);

  const mockImgDimensions: {
    width: number;
    height: number;
  } = await new Promise(resolve => {
    mockImg.onload = () =>
      resolve({
        width: mockImg.width,
        height: mockImg.height,
      });
  });
  const isWidthValid =
    mockImgDimensions.width >= 128 && mockImgDimensions.width <= 2048;
  const isHeightValid =
    mockImgDimensions.height >= 128 && mockImgDimensions.height <= 2048;

  if (!isWidthValid || !isHeightValid) {
    return false;
  }
  return true;
};
