import {
  Button,
  Checkbox,
  Loader,
  Portal,
} from '@energybox/react-ui-library/dist/components';
import React, { useEffect, useState } from 'react';
import SignalStrength from '../SignalStrength/SignalStrength';
import styles from './LookupButton.module.css';
import { formatUuid } from '../../utils/devices';
import { global } from '@energybox/react-ui-library/dist/utils';

type Device = {
  uuid: string;
  signalStrength?: number | null;
};

type Props = {
  dropdownTitle: string;
  devices: Device[];
  currentUuid: string;
  onDeviceClick: (uuid: string) => void;
  buttonLabel?: string;
  onButtonClick?: () => void;
  isLoading?: boolean;
  active?: boolean;
  showSignalStrength?: boolean;
};

const LookupButton: React.FC<Props> = ({
  dropdownTitle,
  devices,
  currentUuid,
  onDeviceClick,
  buttonLabel,
  onButtonClick,
  isLoading,
  active = false,
  showSignalStrength = false,
}) => {
  const uuidLookupRef = React.useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [lookupPosition, setLookupPosition] = React.useState({
    top: uuidLookupRef.current?.getClientRects()[0].bottom || 0,
    right:
      window.innerWidth -
      (uuidLookupRef.current?.getClientRects()[0].right || 0),
  });

  useEffect(() => {
    if (isExpanded) {
      const { innerWidth } = window;
      setLookupPosition({
        top: (uuidLookupRef.current?.getClientRects()[0].bottom || 0) + 5,
        right:
          innerWidth - (uuidLookupRef.current?.getClientRects()[0].right || 0),
      });
    }
  }, [isExpanded]);

  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick();
    }
    setIsExpanded(!isExpanded);
  };

  const isSelected = (uuid: string) => currentUuid === formatUuid(uuid);

  // match with the
  const isMatch = (uuid: string) =>
    formatUuid(uuid)
      .toLowerCase()
      .slice(0, currentUuid.length) === currentUuid.toLowerCase();

  const filteredDevices =
    currentUuid.length > 0
      ? devices.filter(device => isMatch(device.uuid))
      : devices;

  return (
    <>
      <div ref={uuidLookupRef}>
        <Button variant="outlined" onClick={handleClick} disabled={!active}>
          {buttonLabel || 'Lookup UUID'}
        </Button>
      </div>
      <Portal id="uuid-lookup">
        <div
          className={styles.lookupPortal}
          style={{
            display: isExpanded ? 'block' : 'none',
          }}
          onClick={() => setIsExpanded(false)}
        >
          <div
            className={styles.lookupDropdown}
            style={{
              ...lookupPosition,
            }}
          >
            <b>{dropdownTitle}</b>
            <div className={styles.lookupList}>
              {isLoading && <Loader size={18} className={styles.loader} />}
              {!isLoading && filteredDevices.length === 0 && (
                <div style={{ padding: '1rem' }}>No devices found</div>
              )}
              {!isLoading &&
                filteredDevices.map(({ uuid, signalStrength }) => (
                  <div
                    key={uuid}
                    className={`${styles.lookupItem} ${
                      isSelected(uuid) ? styles.selected : ''
                    }`}
                    onClick={() => {
                      onDeviceClick(uuid);
                      setIsExpanded(false);
                    }}
                  >
                    <Checkbox checked={isSelected(uuid)} onChange={() => {}} />
                    <span>{formatUuid(uuid)}</span>
                    <div>
                      {showSignalStrength &&
                        (signalStrength ? (
                          <SignalStrength value={signalStrength} />
                        ) : (
                          global.NOT_AVAILABLE
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Portal>
    </>
  );
};

export default LookupButton;
