import {
  ApiError,
  ControlBoard,
  Gateway,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { connect } from 'react-redux';
import { getGateway } from '../../actions/gateways';
import { getSiteByResourceId } from '../../actions/sites';
import { ApplicationState } from '../../reducers';
import { EditGateway } from '../../reducers/gateways';
import ShowControlBoardPage from './GatewayDetailPages/ShowControlBoardPage';
import ShowEnergyProPage from './GatewayDetailPages/ShowEnergyProPage';
import ShowGatewayPage from './GatewayDetailPages/ShowGatewayPage';
import ShowThermostatPage from './GatewayDetailPages/ShowThermostatPage';
import { ThermostatModelType } from '@energybox/react-ui-library/dist/types/Device';
import { accessDeniedError } from '../../utils/ApiError/accessDeniedError';

interface OwnProps {
  id: string;
}

interface Props extends OwnProps {
  load: () => void;
  gateway?: Gateway | ControlBoard;
  editGateway?: EditGateway;
  siteApiError: ApiError;
}

// TODO we need to fix this against the model.
// Entity type is having issues currently, review all places used.
const resourceTypeToDetailsComponent = {
  [ResourceType.CONTROLBOARD]: (id, gateway) => (
    <ShowControlBoardPage controlBoard={gateway as ControlBoard} />
  ),
  [ResourceType.GATEWAY]: (id, gateway) => (
    <ShowGatewayPage gateway={gateway as Gateway} />
  ),
  [ResourceType.HUB]: (id, gateway) => (
    <ShowGatewayPage gateway={gateway as Gateway} />
  ),
  [ResourceType.ENERGYPRO]: (id, gateway) => (
    <ShowEnergyProPage id={id} gateway={gateway as Gateway} />
  ),
  [ResourceType.VENSTARTHERMOSTAT]: (id, gateway) => (
    <ShowThermostatPage id={id} type={ThermostatModelType.VENSTAR_THERMOSTAT} />
  ),
  [ResourceType.ENERGYBOXTHERMOSTAT]: (id, gateway) => (
    <ShowThermostatPage
      id={id}
      type={ThermostatModelType.ENERGYBOX_THERMOSTAT}
    />
  ),
};
class ShowGatewayPageContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.load();
  }

  render() {
    const { id, gateway, siteApiError } = this.props;
    if (!gateway) {
      if (Object.keys(siteApiError).length) {
        return accessDeniedError(siteApiError);
      } else {
        return <div>Loading...</div>;
      }
    }
    const resourceType = gateway.resourceType
      ? gateway.resourceType
      : gateway['model'];
    const renderFn = resourceTypeToDetailsComponent[resourceType];

    return <>{renderFn ? renderFn(id, gateway) : ''}</>;
  }
}

const mapStateToProps = (
  { gateways, sites }: ApplicationState,
  { id }: OwnProps
) => ({
  gateway: gateways.gatewaysById[parseInt(id)],
  editGateway: gateways.editById[parseInt(id)],
  siteApiError: sites.siteApiError,
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  load: () => {
    dispatch(getGateway(id));
    dispatch(getSiteByResourceId(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowGatewayPageContainer);
