import {
  BreakerType,
  CircuitBreaker,
  CircuitBreakerInPanel,
  GenericErrors,
  mapBreakerTypeToPoleNumber,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/circuit_breakers';
import { Actions as MBActions } from '../actions/main_breakers';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface EditableFields {
  id: number;
  title: string;
  description: string;
  equipmentId: number;
  type: BreakerType;
  rating: number;
  breakerColumn: number;
  breakerSlot: number;
  siteTotal: boolean;
  subpanel: boolean;
  // polePhases: PolePhase;
}

export const editableFieldKeys = [
  'id',
  'title',
  'description',
  'equipmentId',
  'type',
  'rating',
  'breakerColumn',
  'breakerSlot',
  'subpanel',
  'siteTotal',
];

export const editableFields = (circuitBreaker: object) =>
  R.pick(editableFieldKeys, circuitBreaker);

export type EditCircuitBreaker = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

export const newCircuitBreakerFields = {
  description: '',
  equipmentId: -1,
  title: '',
  type: '',
  breakerColumn: 1,
  breakerSlot: '',
  subpanel: false,
  siteTotal: true,
};

const newCircuitBreaker = {
  isLoading: false,
  isChanged: false,
  fields: newCircuitBreakerFields,
  formErrors: formValidationErrors('circuitBreaker', newCircuitBreakerFields),
  formErrorsVisible: false,
  apiError: {},
};

export type CircuitBreakersById = {
  [id: string]: CircuitBreaker;
};

export interface CircuitBreakers {
  circuitBreakersById: CircuitBreakersById;
  showNewCircuitBreakerModal: boolean;
  showEditCircuitBreakerModal: {
    id: number;
    value: boolean;
    mainBreaker?: number;
  };
  showEnergyProPhasorModal: { id: number; value: boolean };
  editById: EditById;
  showDeleteCircuitBreakerModal: boolean;
  primedForDelete: number;
  apiError: ApiError;
  previouslyCreatedCircuitBreaker?: EditableFields;
}

export const initialState = {
  circuitBreakersById: {},
  showNewCircuitBreakerModal: false,
  showEditCircuitBreakerModal: { id: -1, value: false },
  showEnergyProPhasorModal: { id: -1, value: false },
  editById: {},
  showDeleteCircuitBreakerModal: false,
  primedForDelete: -1,
  apiError: {},
  previouslyCreatedCircuitBreaker: undefined,
};

export type EditById = {
  [id: string]: EditCircuitBreaker;
};

export const circuitBreakerFromPanelApiResponse = (data: any) => ({
  id: data.breaker.id,
  title: data.breaker.title,
  description: data.breaker.description || '',
  equipmentId: data.breaker.equipmentId,
  type: data.breaker.type,
  rating: data.breaker.rating,
  breakerColumn: data.breakerColumn,
  breakerSlot: data.breakerSlotPosition,
  subpanel: data.breaker.subpanel || false,
  siteTotal: data.breaker.siteTotal || false,
  // polePhases: data.polePhases || [],
});

export const circuitBreakerFromBreakerApiResponse = (data: any) => ({
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  id: data.id,
  title: data.title,
  description: data.description || '',
  equipmentId: data.equipmentId,
  type: data.type,
  rating: data.rating,
  breakerColumn: data.breakerColumn,
  breakerSlot: data.breakerSlotPosition,
  subpanel: data.subpanel || false,
  siteTotal: data.siteTotal || false,
  // polePhases: data.polePhases || [],
});

const mapBreakersInPanelToEditById = (data: CircuitBreakerInPanel[]) => {
  const editByIdFormat = (circuitBreakerInPanel: CircuitBreakerInPanel) => ({
    id: circuitBreakerInPanel.breaker.id,
    fields: circuitBreakerFromPanelApiResponse(circuitBreakerInPanel),
    isLoading: false,
    formErrorsVisible: false,
    apiError: {},
  });

  return mapArrayToObject(mapValues(data, editByIdFormat));
};

const determineNewCircuitBreaker = (
  previousBreaker: EditableFields | undefined,
  isModalOpening: boolean
) => {
  if (isModalOpening && !!previousBreaker) {
    return {
      ...newCircuitBreaker,
      fields: {
        ...previousBreaker,
        title: '',
        description: '',
        equipmentId: -1,
        breakerSlot:
          previousBreaker.breakerSlot +
          mapBreakerTypeToPoleNumber[previousBreaker.type],
      },
    };
  } else return { ...newCircuitBreaker };
};

const circuitBreakers = (
  state: CircuitBreakers = initialState,
  action: any
) => {
  switch (action.type) {
    //NOTE: we don't use the traditional GET_CIRCUIT_BREAKERS and GET_CIRCUIT_BREAKER actions in the app.
    // We are instead utilizing the breakers data returning from getDistributionPanel, because that contains breaker slot positions info we need
    // We use the action MAP_BREAKERS_IN_PANEL_TO_BREAKERSTORE instead to store it in redux
    //Aleks recommended that we go this route-- I did keep the the traditional actions just in case, but they might need refactoring

    case Actions.GET_CIRCUIT_BREAKERS_SUCCESS:
      return R.pipe(
        R.assoc(
          'circuitBreakersById',
          mapArrayToObject(
            mapValues(action.data, circuitBreakerFromBreakerApiResponse)
          )
        )
      )(state);

    case Actions.GET_CIRCUIT_BREAKER_SUCCESS:
      let circuitBreaker = circuitBreakerFromBreakerApiResponse(action.data);

      return R.pipe(
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(circuitBreaker),
          apiError: {},
        })
      )(state);

    case Actions.TOGGLE_NEW_CIRCUIT_BREAKER_MODAL:
      const previousBreaker = R.path(
        ['previouslyCreatedCircuitBreaker'],
        state
      );
      const isModalOpening = action.value;
      const newBreaker = determineNewCircuitBreaker(
        previousBreaker,
        isModalOpening
      );

      return R.pipe(
        R.assocPath(['editById', 'new'], newBreaker),
        R.assoc('showNewCircuitBreakerModal', isModalOpening)
      )(state);

    case Actions.TOGGLE_EDIT_CIRCUIT_BREAKER_MODAL:
      return R.pipe(
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assoc('showEditCircuitBreakerModal', {
          id: action.id,
          value: action.value,
          mainBreaker: action.mainBreaker,
        })
      )(state);

    case Actions.TOGGLE_EPRO_PHASOR_MODAL:
      return R.pipe(
        R.assoc('showEnergyProPhasorModal', {
          id: action.id,
          value: action.value,
        })
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('circuitBreaker', updatedField)
        )
      )(state);

    case Actions.INSTALL_CIRCUIT_BREAKER_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.INSTALL_CIRCUIT_BREAKER_SUCCESS:
      const createdBreakerFields = R.path(['editById', 'new', 'fields'], state);
      return R.pipe(
        R.assoc('showNewCircuitBreakerModal', false),
        R.assoc(
          'editById',
          R.mergeRight(
            R.view(R.lensProp('editById'), state),
            mapBreakersInPanelToEditById(action.data.breakers)
          )
        ),
        R.assoc(
          'circuitBreakersById',
          mapArrayToObject(
            mapValues(action.data.breakers, circuitBreakerFromPanelApiResponse)
          )
        ),
        R.assoc('previouslyCreatedCircuitBreaker', createdBreakerFields),
        R.assocPath(['editById', 'new'], {})
      )(state);

    case Actions.INSTALL_CIRCUIT_BREAKER_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_CIRCUIT_BREAKER_LOADING:
      return R.assocPath(
        ['editById', action.breakerId, 'isLoading'],
        true,
        state
      );

    case Actions.PATCH_CIRCUIT_BREAKER_SUCCESS:
      const patchedCircuitBreaker = R.path(
        ['editById', action.breakerId, 'fields'],
        state
      );
      return R.pipe(
        R.assoc('showEditCircuitBreakerModal', { id: -1, value: false }),
        R.assocPath(['editById', action.breakerId, 'isChanged'], false),
        R.assocPath(['editById', action.breakerId, 'isLoading'], false),
        R.assocPath(
          ['circuitBreakersById', action.breakerId],
          patchedCircuitBreaker
        )
      )(state);

    case Actions.PATCH_CIRCUIT_BREAKER_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', action.breakerId, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', action.breakerId, 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_CIRCUIT_BREAKER: {
      var fields = editableFields(
        R.path(['circuitBreakersById', action.id], state)
      );

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('circuitBreaker', fields)
        )
      )(state);
    }

    case Actions.DELETE_CIRCUIT_BREAKER_LOADING:
      return R.assocPath(
        ['editById', action.breakerId, 'isLoading'],
        true,
        state
      );

    case Actions.DELETE_CIRCUIT_BREAKER_SUCCESS: {
      if (!!action.data.result) {
        return R.pipe(
          R.dissocPath(['circuitBreakersById', action.breakerId]),
          R.dissocPath(['editById', action.breakerId]),
          R.assoc('showDeleteCircuitBreakerModal', false),
          R.assoc('primedForDelete', -1)
        )(state);
      }
      return state;
    }

    case Actions.DELETE_CIRCUIT_BREAKER_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', action.breakerId, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', action.breakerId, 'isLoading'], false)
      )(state);

    case Actions.TOGGLE_DELETE_CIRCUIT_BREAKER_MODAL:
      return R.pipe(
        R.assoc('showDeleteCircuitBreakerModal', action.value),
        R.assoc('apiError', {})
      )(state);

    case Actions.PRIME_FOR_DELETE:
      return R.assoc('primedForDelete', action.id, state);

    case Actions.UNSTAGE_DELETE:
      return R.assoc('primedForDelete', -1, state);

    case Actions.MAP_BREAKERS_IN_PANEL_TO_BREAKERSTORE:
      return R.pipe(
        R.assoc(
          'editById',
          R.mergeRight(
            R.view(R.lensProp('editById'), state),
            mapBreakersInPanelToEditById(action.data)
          )
        ),
        R.assoc(
          'circuitBreakersById',
          mapArrayToObject(
            mapValues(action.data, circuitBreakerFromPanelApiResponse)
          )
        )
      )(state);

    case Actions.MOVE_BREAKER_IN_PANEL_LOADING:
      return R.assocPath(
        ['editById', action.breakerId, 'isLoading'],
        true,
        state
      );

    case Actions.MOVE_BREAKER_IN_PANEL_SUCCESS:
      return R.pipe(
        R.assocPath(['editById', action.breakerId, 'isChanged'], false),
        R.assocPath(['editById', action.breakerId, 'isLoading'], false),
        R.assoc(
          'circuitBreakersById',
          mapArrayToObject(
            mapValues(action.data.breakers, circuitBreakerFromPanelApiResponse)
          )
        )
      )(state);

    case Actions.MOVE_BREAKER_IN_PANEL_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', action.breakerId, 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', action.breakerId, 'isLoading'], false)
      )(state);

    case Actions.SELECT_NEWLY_CREATED_EQUIPMENT:
      return R.pipe(
        R.assocPath(
          ['editById', action.breakerId, 'fields', 'equipmentId'],
          action.data.id
        ),
        R.assocPath(['editById', action.breakerId, 'isChanged'], true)
      )(state);

    case MBActions.PATCH_MAIN_BREAKER_SUCCESS:
      return R.assoc(
        'showEditCircuitBreakerModal',
        { id: -1, value: false },
        state
      );

    default:
      return state;
  }
};

export default circuitBreakers;
