import {
  Button,
  Card,
  InputField,
  Label,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  ResourceType,
  vendors as gatewayVendors,
  vendors as sensorVendors,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getGatewayIdFromUuid } from '../../actions/gateways';
import {
  getNetworkGroupByEdgeSerialNumber,
  getNetworkGroupByEdgeUuid,
} from '../../actions/network_groups';
import { getSensorIdFromUuid } from '../../actions/sensors';
import { ApplicationState } from '../../reducers';
import { GatewayIdFromUuid } from '../../reducers/gateways';
import {
  NetworkGroupsByEdgeSerialNumber,
  NetworkGroupsByEdgeUuid,
} from '../../reducers/network_groups';
import { SensorIdFromUuid } from '../../reducers/sensors';
import { Routes } from '../../routes';
import ResourcePath from '../ResourcePath';
import styles from './ShowFindMySensorPage.module.css';
import { determineUserIsEBGlobalAdmin } from '../../utils/user';
import { accessDeniedError } from '../../utils/ApiError/accessDeniedError';

interface Props {
  getGatewayIdFromUuid: typeof getGatewayIdFromUuid;
  getSensorIdFromUuid: typeof getSensorIdFromUuid;
  getNetworkGroupByEdgeUuid: typeof getNetworkGroupByEdgeUuid;
  getNetworkGroupByEdgeSerialNumber: typeof getNetworkGroupByEdgeSerialNumber;
  sensorsByUuid: SensorIdFromUuid;
  gatewaysByUuid: GatewayIdFromUuid;
  networkGroupsByEdgeUuid: NetworkGroupsByEdgeUuid;
  networkGroupsByEdgeSerialNumber: NetworkGroupsByEdgeSerialNumber;
  showPage: boolean;
}

interface State {
  gatewayUuid: string;
  sensorUuid: string;
  vendorG: string;
  vendorS: string;
  edgeUuid: string;
  edgeSerialNumber: string;
  isSensorButtonClicked?: boolean;
  isGateWayButtonClicked?: boolean;
  isNgUuidButtonClicked?: boolean;
  isNgSnButtonClicked?: boolean;
  showPage?: boolean;
}

class ShowFindMySensorPage extends React.Component<
  Props & RouteComponentProps,
  State
> {
  constructor(props: Props & RouteComponentProps) {
    super(props);
    this.state = {
      gatewayUuid: '',
      sensorUuid: '',
      edgeUuid: '',
      edgeSerialNumber: '',
      vendorS: sensorVendors[0],
      vendorG: gatewayVendors[0],
      isSensorButtonClicked: false,
      isGateWayButtonClicked: false,
      isNgUuidButtonClicked: false,
      isNgSnButtonClicked: false,
      showPage: this.props.showPage,
    };
  }

  onSensorByUuidChange = e => this.setState({ sensorUuid: e.target.value });
  onGatewayByUuidChange = e => this.setState({ gatewayUuid: e.target.value });
  onGatewayVendorChange = v => this.setState({ vendorG: v });
  onSensorVendorChange = v => this.setState({ vendorS: v });
  onEdgeUuidChange = e => this.setState({ edgeUuid: e.target.value });
  onEdgeSerialNumberChange = e =>
    this.setState({ edgeSerialNumber: e.target.value });

  onSearchGateway = () => {
    const { gatewayUuid, vendorG } = this.state;
    if (gatewayUuid && vendorG) {
      this.props.getGatewayIdFromUuid(gatewayUuid, vendorG);
      this.setState({ isGateWayButtonClicked: true });
    }
  };
  onSearchSensor = () => {
    const { sensorUuid, vendorS } = this.state;
    if (sensorUuid && vendorS) {
      this.props.getSensorIdFromUuid(sensorUuid, vendorS);
      this.setState({ isSensorButtonClicked: true });
    }
  };
  onSearchNetworkGroupByUuid = () => {
    const { edgeUuid } = this.state;
    if (edgeUuid) {
      this.props.getNetworkGroupByEdgeUuid(edgeUuid);
      this.setState({ isNgUuidButtonClicked: true });
    }
  };
  onSearchNetworkGroupBySerialNumber = () => {
    const { edgeSerialNumber } = this.state;
    if (edgeSerialNumber) {
      this.props.getNetworkGroupByEdgeSerialNumber(edgeSerialNumber);
      this.setState({ isNgSnButtonClicked: true });
    }
  };

  render() {
    const {
      gatewaysByUuid,
      sensorsByUuid,
      networkGroupsByEdgeUuid,
      networkGroupsByEdgeSerialNumber,
    } = this.props;
    const {
      gatewayUuid,
      sensorUuid,
      edgeUuid,
      vendorS,
      vendorG,
      edgeSerialNumber,
      showPage,
    } = this.state;

    const foundSensorId = sensorsByUuid[sensorUuid];
    const foundGatewayId = gatewaysByUuid[gatewayUuid];
    const foundNetworkGroupIdViaUuid = networkGroupsByEdgeUuid[edgeUuid]?.id;
    const foundNetworkGroupIdViaSerialNumber =
      networkGroupsByEdgeSerialNumber[edgeSerialNumber]?.id;

    return showPage ? (
      <>
        <Card className={styles.root}>
          <div className={styles.cardContent}>
            <span>
              <Label>Search Entity By UUID</Label>
            </span>
            <span className={styles.balanceWidth}>
              <Label>Vendor</Label>
            </span>
            <span className={styles.uuidField}>
              <Label>UUID</Label>
            </span>
            <span>
              <Label>ID</Label>
            </span>
            <span>
              <Label>Location</Label>
            </span>
            <span></span>
          </div>
          <div className={styles.cardContent}>
            <span>Sensor</span>
            <span>
              <Select variant={'select'} title={vendorS}>
                {sensorVendors.map(vendor => (
                  <SelectItem
                    key={vendor}
                    isSelected={vendor === vendorS}
                    onSelect={() => this.onSensorVendorChange(vendor)}
                  >
                    {vendor}
                  </SelectItem>
                ))}
              </Select>
            </span>
            <span className={styles.uuidField}>
              <InputField
                name="sensorByUuid"
                onChange={this.onSensorByUuidChange}
                value={sensorUuid}
              />
            </span>
            <span>
              {!!foundSensorId && (
                <Link
                  target="_blank"
                  to={`${Routes.DEVICES}${Routes.SENSORS}/${foundSensorId}`}
                >
                  {foundSensorId}
                </Link>
              )}
            </span>
            <span>
              {!!foundSensorId && (
                <ResourcePath
                  openInNewTab={true}
                  resourceType={ResourceType.SPACE}
                  resourceId={foundSensorId}
                />
              )}
            </span>
            <div>
              <Button onClick={this.onSearchSensor}>Find Sensor</Button>
            </div>
            {!foundSensorId && this.state.isSensorButtonClicked && (
              <div className={styles.noEntryText}>No entry found.</div>
            )}
          </div>
          <div className={styles.cardContent}>
            <div>Device / Gateway</div>
            <div>
              <Select variant={'select'} title={vendorG}>
                {gatewayVendors.map(vendor => (
                  <SelectItem
                    key={vendor}
                    isSelected={vendor === vendorG}
                    onSelect={() => this.onGatewayVendorChange(vendor)}
                  >
                    {vendor}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className={styles.uuidField}>
              <InputField
                name="gatewayByUuid"
                onChange={this.onGatewayByUuidChange}
                value={gatewayUuid}
              />
            </div>
            <div>
              {!!foundGatewayId && (
                <Link
                  target="_blank"
                  to={`${Routes.DEVICES}${Routes.GATEWAYS}/${foundGatewayId}`}
                >
                  {foundGatewayId}
                </Link>
              )}
            </div>
            <div>
              {!!foundGatewayId && (
                <ResourcePath
                  openInNewTab={true}
                  resourceType={ResourceType.SPACE}
                  resourceId={foundGatewayId}
                />
              )}
            </div>
            <div>
              <Button onClick={this.onSearchGateway}>Find Gateway</Button>
            </div>
            {!foundGatewayId && this.state.isGateWayButtonClicked && (
              <div className={styles.noEntryText}>No entry found</div>
            )}
          </div>
          <div className={styles.cardContent}>
            <div> Network Group / Edge </div>
            <div className={styles.balanceWidth}>{global.NOT_AVAILABLE}</div>
            <div className={styles.uuidField}>
              <InputField
                name="networkGroupByUuid"
                onChange={this.onEdgeUuidChange}
                value={edgeUuid}
              />
            </div>
            <div>
              {!!foundNetworkGroupIdViaUuid && (
                <Link
                  target="_blank"
                  to={`${Routes.DEVICES}${Routes.NETWORK_GROUPS}/${foundNetworkGroupIdViaUuid}`}
                >
                  {foundNetworkGroupIdViaUuid}
                </Link>
              )}
            </div>
            <div>
              {!!foundNetworkGroupIdViaUuid && (
                <ResourcePath
                  openInNewTab={true}
                  resourceType={ResourceType.NETWORKGROUP}
                  resourceId={foundNetworkGroupIdViaUuid}
                />
              )}
            </div>
            <div>
              <Button onClick={this.onSearchNetworkGroupByUuid}>
                Find Network-Group
              </Button>
            </div>
            {!foundNetworkGroupIdViaUuid &&
              this.state.isNgUuidButtonClicked && (
                <div className={styles.noEntryText}>No entry found</div>
              )}
          </div>
        </Card>

        <Card className={styles.root}>
          <div className={styles.cardContent}>
            <div>
              <Label>Search Entity By Serial Number</Label>
            </div>
            <div className={styles.balanceWidth}>
              <Label>Vendor</Label>
            </div>
            <div>
              <Label>Serial Number</Label>
            </div>
            <div>
              <Label>ID</Label>
            </div>
            <div>
              <Label>Location</Label>
            </div>
            <div></div>
          </div>
          <div className={styles.cardContent}>
            <div>Edge</div>
            <div className={styles.balanceWidth}>{global.NOT_AVAILABLE}</div>
            <div>
              <InputField
                name="networkGroupBySerialNumber"
                onChange={this.onEdgeSerialNumberChange}
                value={edgeSerialNumber}
              />
            </div>
            <div>
              {!!foundNetworkGroupIdViaSerialNumber && (
                <Link
                  target="_blank"
                  to={`${Routes.DEVICES}${Routes.NETWORK_GROUPS}/${foundNetworkGroupIdViaSerialNumber}`}
                >
                  {foundNetworkGroupIdViaSerialNumber}
                </Link>
              )}
            </div>
            <div>
              {!!foundNetworkGroupIdViaSerialNumber && (
                <ResourcePath
                  openInNewTab={true}
                  resourceType={ResourceType.NETWORKGROUP}
                  resourceId={foundNetworkGroupIdViaSerialNumber}
                />
              )}
            </div>
            <div>
              <Button onClick={this.onSearchNetworkGroupBySerialNumber}>
                Find Network-Group
              </Button>
            </div>
            {!foundNetworkGroupIdViaSerialNumber &&
              this.state.isNgSnButtonClicked && (
                <div className={styles.noEntryText}>No entry found</div>
              )}
          </div>
        </Card>
      </>
    ) : (
      accessDeniedError(null, true)
    );
  }
}

const mapStateToProps = ({
  sensors,
  gateways,
  networkGroups,
  app,
}: ApplicationState) => ({
  sensorsByUuid: sensors.sensorIdFromUuid,
  gatewaysByUuid: gateways.gatewayIdFromUuid,
  networkGroupsByEdgeUuid: networkGroups.networkGroupsByEdgeUuid,
  networkGroupsByEdgeSerialNumber:
    networkGroups.networkGroupsByEdgeSerialNumber,
  showPage: determineUserIsEBGlobalAdmin(app.currentUser),
});

const mapDispatchToProps = {
  getSensorIdFromUuid,
  getGatewayIdFromUuid,
  getNetworkGroupByEdgeUuid,
  getNetworkGroupByEdgeSerialNumber,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowFindMySensorPage)
);
