import {
  GenericErrors,
  SensorParentResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  FormText,
  InputField,
  Label,
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import SelectEquipment from '../../containers/Selects/SelectEquipment';
import SelectSite from '../../containers/Selects/SelectSite';
import SelectSpace from '../../containers/Selects/SelectSpace';
import SelectVendor from '../../containers/Selects/SelectVendor';
import ModalFormContent from '../ModalFormContent';

interface Props {
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  title: string;
  description: string;
  uuid: string;
  vendor: string;
  siteId?: number;
  resourceId: number;
  isChanged?: boolean;
  attachedTo?: SensorParentResourceType;
  formErrorsVisible: boolean;
}

const EditSensorForm = ({
  onChange,
  title,
  description,
  uuid,
  vendor,
  siteId = -1,
  resourceId,
  isChanged,
  attachedTo = SensorParentResourceType.EQUIPMENT,
  formErrorsVisible,
  formErrors = {},
}: Props) => {
  const [siteIdInState, setSiteId] = React.useState(siteId);
  const [attachedToInState, setAttachedTo] = React.useState(attachedTo);
  const [formattedUuid, setFormattedUuid] = React.useState<string>(uuid);

  const onChangeUuid = value => {
    const formattedUuid = value 
      .replace(/[^0-9A-Fa-f]/g, '')
      ?.slice(0, 12)
      ?.toUpperCase()
      ?.match(/.{1,2}/g)
      ?.join(':') || '';
    setFormattedUuid(formattedUuid);
    onChange('uuid', formattedUuid);
  }

  React.useEffect(() => {
    // For when form is reset
    if (isChanged !== undefined && !isChanged) {
      if (siteId) setSiteId(siteId);
      if (attachedTo) setAttachedTo(attachedTo);
    }
    // eslint-disable-next-line
  }, [isChanged, siteId]);

  React.useEffect(() => {
    setFormattedUuid(uuid);
  }
  , [uuid]);

  return (
    <>
      <ModalFormContent>
        <div>
          <Label required>Sensor name</Label>
        </div>
        <div>
          <InputField
            type="text"
            name="title"
            autoComplete="title"
            value={title}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
            customErrorText={formErrors.title && formErrors.title[0]}
          />
        </div>

        <div>
          <Label>Description</Label>
        </div>
        <div>
          <InputField
            type="text"
            name="description"
            value={description}
            autoComplete="description"
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        <div>
          <Label required>UUID</Label>
        </div>
        <div>
          <InputField
            type="text"
            name="uuid"
            value={formattedUuid}
            autoComplete="uuid"
            onChange={e => onChangeUuid(e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.uuid}
            customErrorText={formErrors.uuid && formErrors.uuid[0]}
          />
        </div>

        <div>
          <Label required>Vendor</Label>
        </div>
        <div>
          <SelectVendor
            onSelect={value => onChange('vendor', value)}
            value={vendor}
            error={formErrorsVisible && !!formErrors.vendor}
            customErrorText={formErrors.vendor && formErrors.vendor[0]}
          />
        </div>
      </ModalFormContent>
      <ModalFormContent>
        <div>
          <Label required>Site</Label>
        </div>
        <div>
          <SelectSite
            onSelect={value => {
              setSiteId(value);
              onChange('resourceId', -1);
            }}
            value={siteIdInState}
          />
        </div>

        <div>
          <Label required>Attached To</Label>
        </div>
        <div>
          <RadioGroup>
            <RadioButton
              label={'Equipment'}
              value={'Equipment'}
              checked={String(attachedToInState) === 'Equipment'}
              onChange={() => {
                setAttachedTo(SensorParentResourceType.EQUIPMENT);
                onChange('resourceId', -1);
              }}
            />
            {
              <RadioButton
                label={'Space'}
                value={'Space'}
                checked={String(attachedToInState) === 'Space'}
                onChange={() => {
                  setAttachedTo(SensorParentResourceType.SPACE);
                  onChange('resourceId', -1);
                }}
              />
            }
          </RadioGroup>
        </div>

        <div>
          <Label required>{attachedToInState}</Label>
        </div>
        <div>
          {attachedToInState === SensorParentResourceType.SPACE && (
            <SelectSpace
              onSelect={value => onChange('resourceId', value)}
              value={resourceId}
              siteId={siteIdInState}
              error={formErrorsVisible && !!formErrors.resourceId}
              customErrorText={
                formErrors.resourceId && formErrors.resourceId[0]
              }
            />
          )}
          {attachedToInState === SensorParentResourceType.EQUIPMENT && (
            <SelectEquipment
              onSelect={value => onChange('resourceId', value)}
              value={resourceId}
              siteId={siteIdInState}
              error={formErrorsVisible && !!formErrors.resourceId}
              customErrorText={
                formErrors.resourceId && formErrors.resourceId[0]
              }
            />
          )}
        </div>
      </ModalFormContent>
      <ModalFormContent>
        <FormText>* Mandatory fields</FormText>
      </ModalFormContent>
    </>
  );
};

export default EditSensorForm;
