import { Scheduler } from '@energybox/react-ui-library/dist/types';
import { onChange } from './LightingEquipmentData';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { InputField } from '@energybox/react-ui-library/dist/components';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import styles from './EditModeOffset.module.css';

type Props = {
  activeControl: Scheduler;
  onChange: onChange;
  equipmentId: number;
};

const EditModeOffset: React.FC<Props> = ({
  activeControl,
  onChange,
  equipmentId,
}) => {
  const schedulerId = activeControl?.id;
  const schedulerControls = useSelector(({ schedulers }: ApplicationState) => {
    return schedulerId
      ? schedulers.editById[schedulerId]
      : schedulers.editById[`new${equipmentId}`];
  });
  const fields = schedulerControls?.fields;
  const beginDelta = fields?.lightSensorSettings?.beginDelta ?? '0';
  const endDelta = fields?.lightSensorSettings?.endDelta ?? '0';
  const lightTimetableId = fields?.lightSensorSettings?.timetableId;
  const controlBoardId = fields?.lightSensorSettings?.controlBoardId;
  const formErrorsVisible = schedulerControls?.formErrorsVisible;
  const formErrors = schedulerControls?.formErrors;

  const isTimetableSelected =
    isDefined(lightTimetableId) && lightTimetableId > 0;
  const isControlBoardSelected = controlBoardId !== -1;

  return (
    <div className={styles.biasFieldContainer}>
      <div>
        <InputField
          type="number"
          value={parseInt(beginDelta)}
          disabled={!isTimetableSelected || !isControlBoardSelected}
          onChange={({ currentTarget }) =>
            onChange(
              String(schedulerId),
              ['lightSensorSettings', 'beginDelta'],
              currentTarget.value
            )
          }
          error={
            formErrorsVisible &&
            isDefined(controlBoardId) &&
            !!formErrors?.['lightSensorSettings.beginDelta']
          }
          customErrorText={'Invalid'}
        />
      </div>
      <div>
        <InputField
          type="number"
          value={parseInt(endDelta)}
          disabled={!isTimetableSelected || !isControlBoardSelected}
          onChange={({ currentTarget }) =>
            onChange(
              String(schedulerId),
              ['lightSensorSettings', 'endDelta'],
              currentTarget.value
            )
          }
          error={
            formErrorsVisible &&
            isDefined(controlBoardId) &&
            !!formErrors?.['lightSensorSettings.endDelta']
          }
          customErrorText={'Invalid'}
        />
      </div>
    </div>
  );
};

export default EditModeOffset;
